import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DownloadService } from 'src/app/shared/download.service';
import * as moment from 'moment-timezone';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../../../transactions/store/actions';
import { TabService } from 'src/app/shared/services/tab.service';
import { Tab } from 'src/app/shared/model/tab.model';
import { InvoiceComponent } from 'src/app/transactions/invoice/invoice.component';
import { MsInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/ms-invoice/ms-invoice.component';
import { GstInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/gst-invoice/gst-invoice.component';
import { V2CreatePaymentComponent } from 'src/app/transactions/payment/v2-create-payment/v2-create-payment.component';
import { V2CreateReceiptComponent } from 'src/app/transactions/receipt/v2-create-receipt/v2-create-receipt.component';
import { CreateAdmAcmComponent } from 'src/app/transactions/adm-acm/create-adm-acm/create-adm-acm.component';
import { AddEntryJvComponent } from 'src/app/transactions/jv/add-entry-jv/add-entry-jv.component';
import { InvoiceRefundComponent } from 'src/app/transactions/invoice-refund/invoice-refund.component';
import * as FileSaver from 'file-saver'; 
import { TourComponent } from 'src/app/transactions/tour/tour.component';
import { CookiesService } from 'src/app/shared/cookies.service';
import { MiscInvoiceComponent } from 'src/app/transactions/misc-invoice/misc-invoice.component';
import { AddEntryPurchaseInvoiceComponent } from 'src/app/transactions/purchase-invoice/add-entry-purchase-invoice/add-entry-purchase-invoice.component';
import { ConsolidateInvoiceComponent } from 'src/app/transactions/consolidate-invoice/consolidate-invoice.component';

@Component({
	selector: 'app-subgroup-ledger',
	templateUrl: './subgroup-ledger.component.html',
	styleUrls: ['./subgroup-ledger.component.scss']
})
export class SubgroupLedgerComponent implements OnInit {
	p = 0;
	q = 0;
	leaderData: any;
	navigationSubscription;
	reInitialise: boolean;
	currentDate = moment().format('YYYY-MM-DD');
	currentTime = moment().format('HH:mm:ss');
	innerHeight: string;
	ascSorttdsAmt: boolean = true;
	ascSortRef: boolean = true;
	showMessage:boolean=false;
	alertType:String="";
	message:String=""
	clientData:any
	sendEmailForm:FormGroup
	isAdvancedSearchOpen: boolean = false;
	parentIndex=0;
	childIndex=0;
	isPrint: any;
	@ViewChild('tableHeader', {static:true}) tableHeader : ElementRef
	totalAmountObj: any;
	waterMark;
	@ViewChild('closeSendEmailModal') closebutton;
	@ViewChild('openEmailModal') openEmailModal;
	@ViewChild('openSubgroupModal', { static: true }) openSubgroupModal: ElementRef

	queryParams: any;
	accounting_company: any;
	constructor(
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private downloadService: DownloadService,
		private fb:FormBuilder,
		private store: Store<InitialState>,
		private tabService: TabService,
		private cookiesService : CookiesService

	) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});

		this.sendEmailForm = this.fb.group({
			toEmails: ["",Validators.required],
			bccEmails: [''],
			ccEmails:[''],
			subject:[''],
			body: ['']
		})
	
	}

	ngOnInit() {

		let queryParams = this.route.queryParams['value']
		this.queryParams =  queryParams
		if (queryParams.client != undefined) {
			localStorage.setItem('activeLedgerQueryParams', JSON.stringify(queryParams))
			this.getFilteredData(queryParams)
			this.isPrint = queryParams.print

		} else {
			this.getData()
		}
		this.reInitialise = true
		let client = JSON.parse(localStorage.getItem("activeLedgerQueryParams")).client
		this.getClient(client)
	}
	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}

	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.leaderData != undefined) {
			this.setHeight(this.p, this.q, event.target.innerHeight)
		}
	}

	openSubgroupLedgerModal() {
		const fromDate = this.leaderData?.duration?.from_date; 
		const toDate = this.leaderData?.duration?.to_date;

		let all_clients_records = this.leaderData.all_clients_records && this.leaderData.all_clients_records.length>0 ? this.leaderData.all_clients_records[0] : {};
		let clientRecords = all_clients_records.client_records;
		let display_name=  all_clients_records.display_name

		let recordsLength = 0;
		if(clientRecords && clientRecords.length>0) {
			for(let clientRecord of clientRecords) {
				if(clientRecord.records && clientRecord.records.length>0) {
					recordsLength += clientRecord.records.length;
				}
			}
		}

		if(recordsLength <= 1500) {
			this.downloadPdf(fromDate,toDate,display_name)
			return;
		} else {
			this.openSubgroupModal.nativeElement.click()
		}
	}

	onClickSubgroupModal() {
		let queryData = JSON.parse(localStorage.getItem('activeLedgerQueryParams'))
		queryData.schedule_download = true;
		const queryParams = queryData;
		 
			this.commonService.getRequestWithPdfData(`${environment.okountUrl}/reports/ledger/normal/grouping/download`, queryParams).subscribe((res: any) => {
					this.showMessage = true;
					this.alertType = 'success-box'
					this.message = 'Download Scheduled Successfully.'
					this.onNoClickSubgroupModal();
					setTimeout(() => {
						this.showMessage = false;
						let redirectUrl = `${environment.baseAccountUrl}/v2/#/report/schedule-reports`
						window.open(redirectUrl, '_blank');
					}, 1000)
				}, err => {
				console.log(err,'error onYesClickPdfModal')
			})		
	  }

	  onNoClickSubgroupModal() {
		this.openSubgroupModal.nativeElement.click()
	  }


	//ALL DATA
	getData() {
		this.commonService.getRequest(`${environment.okountUrl}/reports/ledger/grouping`).subscribe(res => {
			this.leaderData = res
			this.accounting_company =  res['company_details'];
			if(res && res['company_details'] && res['company_details'].tenant_logo) {
				this.waterMark = `url('${res['company_details'].tenant_logo}')`
			}
			if (this.leaderData != undefined) {
				this.setHeight(0, 0, window.innerHeight)
			}

		}, err => {
			console.error(err)
		})
	}

	downloadPdf(start_date, end_date,display_name) {
		let queryData = JSON.parse(localStorage.getItem('activeLedgerQueryParams'))
		this.commonService.downloadPDF(`${environment.okountUrl}/reports/ledger/normal/grouping/download`, queryData).subscribe((fileBlob:any) =>{
			let fileName = `${display_name} SUBGROUP LEDGER FROM ${start_date} TO ${end_date}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}

	setHeight(pIndex, innerPIndex, height) {
		if (height == undefined) {
			height = window.innerHeight
		}
		pIndex = Number(pIndex)
		innerPIndex = Number(innerPIndex)
		this.p = pIndex
		this.q = innerPIndex

		let parent = 0
		if (pIndex > 0) {
			parent = pIndex - 1
		}
		let child = 0
		if (innerPIndex > 0) {
			child = innerPIndex - 1
		}
		if (this.leaderData['all_clients_records'].length > 0) {
			if (this.leaderData['all_clients_records'][parent].client_records.length > 0) {
				let count=0
				for (const clientRecord of this.leaderData['all_clients_records'][parent].client_records
				) {
					for (const record of clientRecord.records) {
						if(record){
							count++;
						}
					}
				}

				if(count <= 5){
					this.innerHeight = 'auto';
				}else{
					this.innerHeight = height - 223 + 'px';
				}
			}
			else {
				this.innerHeight = height - 223 + 'px';
			}
		}
		else {
			this.innerHeight = height - 223 + 'px';
		}
	}

	//FILTERED DATA
	getFilteredData(queryParams) {
		this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/ledger/grouping`, queryParams).subscribe(res => {
			this.leaderData = res
			this.accounting_company =  res['company_details'];
			if(res && res['company_details'] && res['company_details'].tenant_logo) {
				this.waterMark = `url('${res['company_details'].tenant_logo}')`
			}
			if (this.leaderData != undefined) {
				this.totalAmountObj = this.getAmount()
				this.setHeight(0, 0, window.innerHeight)
			}
		}, err => {
			console.error(err);
		})
	}

	getAmount() {
		let amountObj = {
			debit: 0,
			credit: 0,
			diff: 0,
			openingBalanceType : '',
			openingBalance : 0,
			type : 'CR.'
		}
		let totalCredit = 0
		let totalDebit = 0
		let balance = 0
		let openingBalance = 0
		let openingBalanceType = 'debit'
		if (this.leaderData != undefined) {
			this.leaderData['all_clients_records'].forEach(data => {
				data['client_records'].forEach(client_record => {
					// client_record['records'].forEach(records => {
					// 	if(records.reference == 'opening_balance'){
					// 		openingBalance += records.debit ? Number(records.debit) : 0;
					// 		if(openingBalance == 0)	{
					// 			openingBalanceType = 'credit'
					// 		}			
					// 		openingBalance += records.credit ? Number(records.credit) : 0;				
					// 	}else{
					// 	}
						
					// });
					if(client_record.aggregated_name == "Opening Balance"){
						openingBalance += client_record.total_debit ? Number(client_record.total_debit) : 0;
						if(openingBalance == 0)	{
							openingBalanceType = 'credit'
						}			
						openingBalance += client_record.total_credit ? Number(client_record.total_credit) : 0;	
					}else{
						totalCredit += client_record.total_credit ? Number(client_record.total_credit) : 0;
						totalDebit += client_record.total_debit ? Number(client_record.total_debit) : 0;
						balance += client_record && client_record.balance ? Number(client_record.balance) : 0;
					}
				});
			});
			amountObj['credit'] = totalCredit
			amountObj['debit'] = totalDebit
			amountObj['balance'] = balance
			amountObj['diff'] = Number(totalCredit) -(Number(totalDebit) - Number(openingBalance))
			amountObj['openingBalance'] = Number(openingBalance)
			amountObj['openingBalanceType'] = openingBalanceType
			amountObj['type'] = 'CR.'
			if (amountObj['diff'] < 0) {
				amountObj['type'] = 'DR.'
			}
			amountObj['diff'] = Math.abs(amountObj['diff'])
		}
		return amountObj
	}

	exportAsXLSX(fromDate, toDate): void {
		this.spinner.show()
		let xlsxData = [];
		this.leaderData['all_clients_records'].forEach(data => {
			data['client_records'].forEach(client_record => {
				client_record['records'].forEach(records => {
					let obj = {};
					let newLineNarration = ''
					let i = 0
					if(!records.records && records.narration){
						for(const data of records.narration.split(',')){
							if(data !== ' '){
								if(i == 0){
									newLineNarration+=data
									i++
								}else{
									if(i!=3){
										newLineNarration+="\n"+data 
										i++
									}
	
								}
							}
						}
					}
					obj["DATE"] = `${moment(records.timestamph ? records.timestamph : records.date).format('DD/MM/YYYY')}`;
					obj["REFERENCE"] = records.transaction_ref_no;
					obj["BALANCE TYPE"] = client_record.aggregated_name;
					obj["NARRATION"] = newLineNarration;
					obj['VOUCHER TYPE'] = records.reference_type
					obj["DEBIT"] = records.debit ? Number(Number(records.debit).toFixed(2)) : "";
					obj["CREDIT"] = records.credit ? Number(Number(records.credit).toFixed(2)) : "";
					xlsxData.push(obj);

				});
			});
		});
		xlsxData.push({
			'NARRATION' : 'CLOSING BALANCE',
			'DEBIT' : this.totalAmountObj.type == 'DR.' ? this.totalAmountObj.diff : 0,
			'VOUCHER TYPE' : 'closing balance',
			'CREDIT': this.totalAmountObj.type == 'CR.' ?  this.totalAmountObj.diff : 0,
			
		})

		xlsxData.push({},{},{"DATE":`FROM ${fromDate} TO ${toDate} CLIENT: ${this.leaderData.all_clients_records[0].display_name}`})

		this.downloadService.exportAsXLSX(`${this.leaderData.all_clients_records[0].display_name} SUBGROUP LEDGER ${fromDate} - ${toDate}`, xlsxData);
		this.spinner.hide()
	}


	callPdfGeneratorApi(fromDate, toDate) {
		debugger
		window.scrollTo(0, 0)
		this.spinner.show()
		let url = 'print/account-book/ledger/subgroup-ledger'
		let queryString = location.hash.split('?')[1]
		if (queryString != undefined) {
			url = `${url}?print=true&${queryString}`
		}
		// if (queryString != undefined) {
		// 	url = `${url}?${queryString}`
		// }
		this.downloadService.callPdfGeneratorApi(`SUBGROUP LEDGER ${fromDate} - ${toDate}.pdf`, url,{})
		this.spinner.hide()

	}

		
	sendEmails(){
  	
		let toEmails = this.sendEmailForm.value.toEmails
		let bccEmails = this.sendEmailForm.value.bccEmails.split(",")
		let ccEmails = this.sendEmailForm.value.ccEmails.split(",")
        let body = this.sendEmailForm.value.body
		let subject = this.sendEmailForm.value.subject


		if(toEmails){
		  toEmails = toEmails.split(",")
		  let exclude = ""
		  if(sessionStorage.getItem("excludeArr")){
			  exclude = "&exclude="
			  let excludeArr = JSON.parse(sessionStorage.getItem("excludeArr"))
			  exclude += excludeArr.join(',')
		  }
		  window.scrollTo(0, 0)
		  this.spinner.show()
		  let url = 'print/account-book/ledger/subgroup-ledger'
		  let queryString = location.hash.split('?')[1]
		  if (queryString != undefined) {
			  if(exclude){
				  queryString+=exclude
			  }
			  url = `${url}?print=true&${queryString}`
		  }
		  let start_date = JSON.parse(localStorage.getItem("activeLedgerQueryParams")).start_date
		  let end_date  = JSON.parse(localStorage.getItem("activeLedgerQueryParams")).end_date
		
		  let emails = {
			to_addresses:toEmails,
			cc_addresses:ccEmails,
			bcc_addresses:bccEmails
		  }
	  
		  let ledger_criteria = {
			  email:emails,
			  start_date:start_date,
			  end_date:end_date,
			  name:this.clientData.name,
			  email_for:"ledger statement"
		  }
		
		  let data = {
			  page_url: url,
			  ledger_criteria:ledger_criteria,
			  queryParams: this.queryParams,
			  email_body:body,
			  subject: subject
		  }
	  
	  
		  this.commonService.postRequest(`${environment.okountUrl}/ledger/email`,data).subscribe((res : any)=> {
			this.showMessage = true;
			this.alertType = 'success-box'
			this.message = res.msg
			this.closebutton.nativeElement.click();
		  },err => {
			this.showMessage = true
			this.alertType = "error-box"
			this.message = err.msg
			this.closebutton.nativeElement.click();
			
		  })
		}
	  }

	  openModal(){
		//define email.
		this.sendEmailForm.controls.toEmails.setValue(this.clientData.primary_email)
		this.sendEmailForm.controls.bccEmails.setValue("")
		this.sendEmailForm.controls.ccEmails.setValue("")
		
		let userData = JSON.parse(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA'))
		let companyName;
		if(userData && userData['t_tenant_info'] && userData['t_tenant_info']['tenant_company_name']) {
			companyName = userData['t_tenant_info']['tenant_company_name'];
		}
		
		let subject = `Ledger statement For The Period ${this.leaderData.duration.from_date ? moment(this.leaderData.duration.from_date).format('MMMM DD, YYYY') : ''} To ${this.leaderData.duration.to_date ? moment(this.leaderData.duration.to_date).format('MMMM DD, YYYY') : ''}`;
		
		let body = `Dear Travel Partner,\n\nPlease find Attached herewith Ledger statement For The Period ${this.leaderData.duration.from_date ? moment(this.leaderData.duration.from_date).format('MMMM DD, YYYY') : ''} To ${this.leaderData.duration.to_date ? moment(this.leaderData.duration.to_date).format('MMMM DD, YYYY') : ''}`
		body += `\n\nPlease do not hesitate to contact us if you have any query or concern about attached document.\n\nIt's been a pleasure to serve you.\n\nBest Regards\n\n${this.accounting_company?.name?.toUpperCase()}\nAccounts Team`
		if(this.accounting_company && this.accounting_company.telephone){
			body += `\nContact Us on ${this.accounting_company.telephone}`
		}

		this.sendEmailForm.controls.subject.setValue(subject);
		this.sendEmailForm.controls.body.setValue(body)

		this.sendEmailForm.controls.body.setValue(body)
		this.openEmailModal.nativeElement.click();
	}
	
	hideError(){
		this.showMessage = false
	}

	// showCCEmail(){
	// 	this.ccEmail=true;
	// }
	// showBCCEmail(){
	// 	this.bccEmail= true;
	// }

	getClient(value) {
		if (value.length > 2) {
			this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
					this.clientData = res[0]
			}, err => {
				console.log(err)
			})
		}
	}


	openViewMode(reference, referenceType, inv_reference_type) {
		if (reference != undefined) {
			let url = '#/transactions/'
			let viewMode = 'view'
			let isInvoice = false
			let isMsInvoice = false
			let isGstInvoice = false
			let isPayment = false
			let isReceipt = false
			let isJv = false
			let isAdmAcm = false
			let isCreditNote = false
			let isTourInvoice = false
			let isMisc = false
			let isPurchaseInvoice = false
			let isConsolidateInovoice = false

			if (referenceType == 'invoice' && inv_reference_type == 'flight_invoice') {
				url = '#/transactions/invoice'
				viewMode = 'view'
				isInvoice = true 
				
			}
			if (referenceType == 'misc-invoice') {
				url = '#/transactions/ms/misc-invoice'
				viewMode = 'view'
				isMisc = true
			}

			if (referenceType == 'consolidate_invoice') {
				url = `#/transactions/consolidate-invoice/inv/view/${reference}`
				viewMode = 'view'
				isConsolidateInovoice = true
			}

			if (referenceType == 'invoice' && inv_reference_type == 'ms_invoice') {
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
				isMsInvoice = true
			}
			if (referenceType == 'invoice' && inv_reference_type == 'purchase_invoice') {
				viewMode = 'view'
				isPurchaseInvoice = true
			}
			if (referenceType == 'invoice' && inv_reference_type == 'gst_invoice') {
				url = '#/transactions/ms/gst-invoice'
				viewMode = 'view'
				isGstInvoice = true
			}
			if (referenceType == 'invoice' && inv_reference_type == 'tour_invoice') {
				url = '#/transactions/tour/save/sales'
				viewMode = 'view'
				isTourInvoice = true
			}
			if (referenceType == 'credit_note' && inv_reference_type == 'ms_invoice') {
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
				isMsInvoice = true
			}else if (referenceType == 'credit_note' && inv_reference_type == 'tour_invoice') {
				url = '#/transactions/tour/save/sales'
				viewMode = 'view'
				isTourInvoice = true
			} else if (referenceType == 'credit_note') {
				url = '#/transactions/credit-note'
				viewMode = 'view'
				isCreditNote =  true
			}
			if (referenceType == 'payment') {
				url = '#/transactions/payment'
				viewMode = 'view'
				isPayment = true
			}
			if (referenceType == 'receipt') {
				url = '#/transactions/receipt'
				viewMode = 'view'
				isReceipt = true
			}
			if (referenceType == 'jv') {
				url = '#/transactions/jv'
				viewMode = 'update'
				isJv = true
			}
			if (referenceType == 'adm' || referenceType == 'acm') {
				url = '#/transactions/adm-acm'
				viewMode = 'view'
				isAdmAcm = true
			}
			if (referenceType == 'misc-invoice') {
				url = '#/transactions/ms/misc-invoice'
				viewMode = 'view'
				isMisc = true
			}
			let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${url}/${viewMode}/${reference}`
			if(window.location.protocol != 'file:'){
				let title =''
				let referenceSplit = reference.split('-')
				title += referenceSplit[0]
				title +='...'
				title += referenceSplit[referenceSplit.length-1]
				if(isInvoice) {
					this.tabService.addTab(
						new Tab(InvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
						);
				} else if(isMisc) {
					this.tabService.addTab(
					  new Tab(MiscInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
					  );
				} else if(isConsolidateInovoice) {
						this.tabService.addTab(
							new Tab(ConsolidateInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
							);
				} else if(isMsInvoice) {
					this.tabService.addTab(
						new Tab(MsInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
						);
				}else if(isPurchaseInvoice) {
					this.tabService.addTab(
						new Tab(AddEntryPurchaseInvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
						);
				} else if(isCreditNote) {
					this.tabService.addTab(
						new Tab(InvoiceRefundComponent, `${title}`, { creditNoteNumber: reference, mode : 'view' }, reference)
						);
				} else if(isGstInvoice) {
					this.tabService.addTab(
						new Tab(GstInvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
						);
				} else if(isPayment) {
					this.tabService.addTab(
						new Tab(V2CreatePaymentComponent, `${title}`, { paymentRef: reference ,mode : 'view' }, reference)
						);
				}
				else if(isReceipt) {
					this.tabService.addTab(
						new Tab(V2CreateReceiptComponent, `${title}`, { receiptRef: reference ,mode : 'view' }, reference)
						);
				} else if(isAdmAcm) {
					this.tabService.addTab(
						new Tab(CreateAdmAcmComponent, `${title}`, { adm_acm_number: reference, mode : 'view' }, reference)
					);
				} else if(isJv) {
					this.tabService.addTab(
						new Tab(AddEntryJvComponent, `${title}`, { jv_code: reference }, reference)
						);
				} else if(isTourInvoice) {
					this.tabService.addTab(
						new Tab(TourComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
						);
				} else {
					window.open(path,'_blank')		
			}
			}
			else{
				var customeEventHandler = new CustomEvent("handle-window-open", {
					"detail": {
						hashPath : path
					}
				  });
				window.dispatchEvent(customeEventHandler)
			}
		}
	}

	// keyboard integration
	// Add #tableHeader on table header

	focusOnTableHeader() :void{
		if (this.tableHeader != undefined) {
			this.tableHeader.nativeElement.focus()
		}
	}

	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`).focus()
			this.childIndex = this.childIndex+1
		}else{
			if (this.parentIndex < this.leaderData['all_clients_records'][0].client_records.length -1) {
				this.parentIndex = this.parentIndex+1
				this.childIndex = -1
			}
			if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`).focus()
				this.childIndex = this.childIndex+1
			}else if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`).focus()
				this.childIndex = this.childIndex+1
			}
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`).focus()
			this.childIndex = this.childIndex-1
		}else{
			if (this.parentIndex > 0) {
				this.parentIndex = this.parentIndex-1
				if (this.leaderData['all_clients_records'][0].client_records[this.parentIndex] != undefined) {
					this.childIndex = this.leaderData['all_clients_records'][0].client_records[this.parentIndex].records.length
				}
			}else{
				this.childIndex = 0
			}
			if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`).focus()
				if (this.childIndex >0) {
					this.childIndex = this.childIndex-1
				}
			}else if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`).focus()
				if (this.childIndex >0) {
					this.childIndex = this.childIndex-1
				}
			}
		}
	}
	
	moveToIndex(parentIndex,childIndex){
		document.getElementById(`rowIndex_${parentIndex}_${childIndex}`).focus()
		this.parentIndex = parentIndex
		this.childIndex = childIndex
	}

	openAdvanceSearchModal(){
		this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'ledger'}));	
	}

}
