import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { InitialState } from 'src/app/store/reducer';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from '../../common-service.service';
import { GlobalVariable } from '../../global-variable';

@Component({
  selector: 'app-ticket-statement',
  templateUrl: './ticket-statement.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class TicketStatementComponent implements OnInit {

  ticketSearchForm: FormGroup;
  allSupplierArr: any;
  supplierArr: any;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
  maxDate = moment().format('YYYY-MM-DD')
  suppliers: [] ;
  keyword = 'name';
  @ViewChild('clientAC',{static:true}) clientAC;
  minDate: string;

  constructor(
    public commonService: CommonServiceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private store: Store<InitialState>
    ) { 
  
      this.store.select('sharedReducers').subscribe((items)=>{
        if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				} else {
					let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
					if(!current_financial_year) {
						current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
					}
					this.minDate = current_financial_year.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				}
        
    })
  }
  ngOnInit() {

    this.ticketSearchForm = this.formBuilder.group({
      start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate,this.checkDate('end_date')],
      selectedSupplier: ['', Validators.required],
      selectedSupplierName: ['', Validators.required],
      reference_type: ['invoice', Validators.required],
      is_card: ['']
    })

    this.getSupplierAirlines()
  }

  checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}


  search(values) {
    GlobalVariable.openModalStatus = false;
    if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
      values.start_date = ""
    }
    else {
      values.start_date = moment(values.start_date).format('YYYY-MM-DD')
    }
    if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date") {
      values.end_date = ""
    }
    else {
      values.end_date = moment(values.end_date).format('YYYY-MM-DD')
    }

    if(values.selectedSupplier != '' || values.selectedSupplier != null || values.selectedSupplier != undefined) {
      this.router.navigateByUrl(`/profit-report/supplier-wise/view?start_date=${values.start_date}&end_date=${values.end_date}&supplier=${values.selectedSupplier}&name=${values.selectedSupplierName.name}&reference_type=${values.reference_type}&is_card=${values.is_card}`)
    }
  }

  getSupplierAirlines() {
    this.commonService.getRequest(`${environment.okountUrl}/util/getsupplierairline`).subscribe(res => {
      this.allSupplierArr = res;
      this.supplierArr = res
    }, err => {
      console.error(err);
    })
  }

  reset() {
    GlobalVariable.openModalStatus = false;
    this.ticketSearchForm = this.formBuilder.group({
      start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate,this.checkDate('end_date')],
      selectedSupplier: ['', Validators.required],
      selectedSupplierName: ['', Validators.required],
      reference_type: ['invoice', Validators.required]
    })
  }

  clickSearchButton(){
		this.searchButton.nativeElement.click()
	}

  filterSuppliers(searchText) {
    if(searchText.length>2) {
      const propertiesToSearch = ["name", "account_code"];
      const lowercaseSearch = searchText.toLowerCase();
      
      const filteredArray = this.allSupplierArr.filter(item => {
        for (const prop of propertiesToSearch) {
          const lowercaseValue = item[prop] ? item[prop].toString().toLowerCase() : '';
    
          if (lowercaseValue.includes(lowercaseSearch)) {
            return true; 
          }
        }
        return false;
      });
      this.supplierArr = filteredArray;

    }else {
      this.supplierArr = this.allSupplierArr;
    }
  }

	selectAccount(value){
		try {
			this.ticketSearchForm.controls.selectedSupplier.setValue(value.account_code)
      this.ticketSearchForm.controls.selectedSupplierName.setValue(value.name)
		} catch (error) {

		}
	}

  focusClientAc(){
		this.clientAC.focus();
	}

  
}
