import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsDatepickerConfig, BsModalService } from 'ngx-bootstrap';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { CommonServiceService } from '../../common-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
// import { moment } from 'ngx-bootstrap/chronos/test/chain';
import * as moment from 'moment-timezone';
import { PaymentService } from 'src/app/transactions/payment/services/payment.service';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';

@Component({
	selector: 'app-outstanding-search',
	templateUrl: './outstanding-search.component.html',
	styleUrls: ['../global-search.component.scss']

})
export class OutstandingSearchComponent implements OnInit {
	modalRef: BsModalRef;
	outstandingFilterForm: FormGroup;
	accountArr = [];
	invoiceArr: any;
	clientArr: any;
	ledgerList: any;
	groupByledgerList: any;
	keyword = 'name';
	cityKeyword = 'city';
	cityArr: any;
	all = {
		name : "All",
		account_code : ""
	}
	branches = []
	salesPersonArr: any;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	@ViewChild('accountAC',{static:true}) accountAC;
	@ViewChild('dpYMD2',{static:true}) dpYMD2;
	@ViewChild('subledgerAC',{static:true}) subledgerAC;
	@ViewChild('salesprAC',{static:true}) salesprAC;
	@ViewChild('cityAC',{static:true}) cityAC;
	minDate = "2023-04-01"
    maxDate = moment().format('YYYY-MM-DD')
	constructor(
		public paymentService: PaymentService,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private store: Store<InitialState>,
		private router: Router) {
		this.store.select('sharedReducers').subscribe((items)=>{
			if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
				this.minDate = items.financeYear.start_date
				this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
							moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
			} else {
				let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
				if(!current_financial_year) {
					current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
				}
				this.minDate = current_financial_year.start_date
				this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
							moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
			}
			
	})	

	}

	ngOnInit() {

		// this.spinner.show();
		// setTimeout(() => {
		// 	this.spinner.hide();
		// }, 1000);
		this.outstandingFilterForm = this.formBuilder.group({
			account_type: ['client'],
			client: [''],
			client_name: [''],
			account_code: [''],
			start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate,this.checkDate('end_date')],
			category: [''],
			family: [''],
			subleger_type: [''],
			subledger: [''],
			subledger_name:[''],
			collection_list: [''],
			sales_person: [''],
			sales_person_name: [''],
			city_id: [''],
			city_name: [''],
			viewMode: ['normal'],
			client_id: [''],
			general: [''],
			employee: [''],
			airline: [''],
			supplier: [''],
			group_by_subleger_type: [''],
			group_by_sub_ledger: [''],
			selectedBranch: [''],
			view_type: ['true']

		})
		this.ledgerList = []
		this.ledgerList.push(this.all)
		this.setFormData()
		this.getAllBranches()
	}

	checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}

	setFormData() {
		let queryParams = localStorage.getItem('activeOutStandingQueryParams')
		if (queryParams != null) {
			debugger
			let parsedParams = JSON.parse(queryParams)
			console.log('Active Query Params', parsedParams)

			this.outstandingFilterForm.controls.subleger_type.setValue(parsedParams.subleger_type?parsedParams.subleger_type:'client')
			

            this.getClient(parsedParams.client ? parsedParams.client : "",true)
            this.getSalesPerson(parsedParams.sales_person ? parsedParams.sales_person : "",true)
            this.getLedgers(parsedParams.sub_ledger_code? parsedParams.sub_ledger_code : "",true)
            this.getCity(parsedParams.city_id? parsedParams.city_id : "",true)
			this.outstandingFilterForm.controls.account_type.setValue(parsedParams.account_type ? parsedParams.account_type : "client")
			// this.outstandingFilterForm.controls.client.setValue(parsedParams.client ? parsedParams.client : "")
			// this.outstandingFilterForm.controls.sales_person.setValue(parsedParams.sales_person)
			this.outstandingFilterForm.controls.start_date.setValue(moment(this.minDate).format('YYYY-MM-DD'))
			this.outstandingFilterForm.controls.end_date.setValue(moment(this.maxDate).format('YYYY-MM-DD'))
			this.outstandingFilterForm.controls.category.setValue(parsedParams.category?parsedParams.category:"")
			// this.outstandingFilterForm.controls.subledger.setValue(parsedParams.sub_ledger_code)
			// this.outstandingFilterForm.controls.city_id.setValue(parsedParams.city_id)
			this.outstandingFilterForm.controls.viewMode.setValue(parsedParams.viewMode?parsedParams.viewMode:'normal')
		}
	  }

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'customModal' })
		);
	}

	getClient(value,bindValue) {
		if (value.length > 2) {
			let searchType = this.outstandingFilterForm.value.account_type ? this.outstandingFilterForm.value.account_type : 'client'
			this.commonService.getOkount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.clientArr = res
				if(bindValue && this.clientArr.length>0){
					this.outstandingFilterForm.controls['client'].setValue(this.clientArr[0].account_code)
					this.outstandingFilterForm.controls['client_name'].setValue(this.clientArr[0].name)
				}
			}, err => {

			})
		}
	}
	getSalesPerson(value,bindValue) {
		if (value.length > 2) {
			this.commonService.getOkount(`employee/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.salesPersonArr = res
				if(bindValue && this.salesPersonArr.length>0){
					this.outstandingFilterForm.controls['sales_person'].setValue(this.salesPersonArr[0].account_code)
					this.outstandingFilterForm.controls['sales_person_name'].setValue(this.salesPersonArr[0].account_name)
				}
			}, err => {

			})
		}
	}

	reset() {
		GlobalVariable.openModalStatus = false;

		this.outstandingFilterForm = this.formBuilder.group({
			account_type: ['client'],
			client: [''],
			client_name: [''],
			account_code: [''],
			start_date: [this.minDate],
			end_date: [this.maxDate],
			category: [''],
			family: [''],
			subleger_type: [''],
			subledger: [''],
			subledger_name:[''],
			collection_list: [''],
			sales_person: [''],
			sales_person_name: [''],
			city_id: [''],
			city_name: [''],
			viewMode: ['normal'],
			client_id: [''],
			general: [''],
			employee: [''],
			airline: [''],
			supplier: [''],
			group_by_subleger_type: [''],
			group_by_sub_ledger: [''],
			selectedBranch: ['']

		})

		this.ledgerList = []
		this.ledgerList.push(this.all)
		localStorage.removeItem('activeOutStandingQueryParams')
	}

	clearData(formControl){
		this.outstandingFilterForm.controls[formControl].setValue("")
	}

	search(values) {
		debugger
		GlobalVariable.openModalStatus = false;
		if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
			values.start_date = ""
		}
		else {
			values.start_date = moment(values.start_date).format('YYYY-MM-DD')
		}
		if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date" || values.end_date == "Invalid date") {
			values.end_date = ""
		}
		else {
			values.end_date = moment(values.end_date).format('YYYY-MM-DD')
		}

		let clientCode= values.client
		if (values.client.account_code != undefined) {
			clientCode= values.client.account_code
		} 
		let salesPersonCode= values.sales_person
		if (values.sales_person.account_code != undefined) {
			salesPersonCode= values.sales_person.account_code
		} 
		let subLedgerCode= values.subledger
		if (values.subledger.account_code != undefined) {
			subLedgerCode= values.subledger.account_code
		} 

		if (values.subledger.name == "All") {
			subLedgerCode= 'All'
		} 

		let groupBySubLedgerCode= values.group_by_sub_ledger
		if (values.viewMode == 'summary') {
			groupBySubLedgerCode = 'all'
		}
		let cityId= values.city_id
		if (values.city_id.id != undefined) {
			cityId= values.city_id.id
		} 
		debugger
		if(values.selectedBranch) {
			this.router.navigateByUrl(`display/statement-account/outstanding-report?viewMode=${values.viewMode}&sub_ledger_code=${subLedgerCode}&start_date=${values.start_date}&end_date=${values.end_date}&city_id=${cityId}&client=${clientCode}&group_by_sub_ledger=${groupBySubLedgerCode}&sales_person=${salesPersonCode}&account_type=${values.account_type}&subledger_type=${values.subleger_type}&branch=${values.selectedBranch}&view_type=${values.view_type}`)
		} else {
		// this.router.navigateByUrl(`display/statement-account/outstanding-report?client=${clientCode}`)
		this.router.navigateByUrl(`display/statement-account/outstanding-report?viewMode=${values.viewMode}&sub_ledger_code=${subLedgerCode}&start_date=${values.start_date}&end_date=${values.end_date}&city_id=${cityId}&client=${clientCode}&group_by_sub_ledger=${groupBySubLedgerCode}&sales_person=${salesPersonCode}&account_type=${values.account_type}&subledger_type=${values.subleger_type}&view_type=${values.view_type}`)
		}
		// this.reset()
		// this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/invoice/filter`,values).subscribe(async (res)=>{
		// 	console.log(res)
		// 	debugger
		// 	let invoiceData = {
		// 		type : 'invoice',
		// 		invoiceData : res
		// 	}
		// 	await this.commonService.setData(invoiceData)

		// })
	}

	getLedgers(value,bindValue=false) {
		debugger
		try {
			let searchType = this.outstandingFilterForm.value.subleger_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.paymentService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
					this.ledgerList = res;
					if(bindValue && this.ledgerList.length>0){
						this.outstandingFilterForm.controls['subledger'].setValue(this.ledgerList[0].account_code)
						this.outstandingFilterForm.controls['subledger_name'].setValue(this.ledgerList[0].name)
					}
					this.ledgerList.unshift(this.all)

				});
			}
		} catch (error) {

		}
	}
	getLedgersGroupBy(value) {
		try {
			let searchType = this.outstandingFilterForm.value.group_by_subleger_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.paymentService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
					this.groupByledgerList = res;
				});
			}
		} catch (error) {

		}
	}

	getCity(value,bindValue=false) {
		if (value.length > 2) {
			this.commonService.getOkount(`location/allcity?city_name=${value.toUpperCase()}`).subscribe(res => {
				this.cityArr = res
				if(bindValue && this.cityArr.length>0){
					this.outstandingFilterForm.controls['city_id'].setValue(this.cityArr[0].city_id)
					this.outstandingFilterForm.controls['city_name'].setValue(this.cityArr[0].name)
				}
			}, err => {

			})
		}
	}


	

	selectAccount(value, formControlName) {
		try {
			this.paymentService.paymentEntryForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}

	selectOutStandingAccount(value, formControlName) {
		debugger
		try {

			this.outstandingFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}

	selectCity(value, formControlName) {
		try {
			this.outstandingFilterForm.controls[formControlName].setValue(value.city_id)
		} catch (error) {

		}
	}



	ngAfterViewInit(){
		this.commonService.selectedSearchInput.subscribe(index=>{
			if (index == 2) {
				this.focusAccountAc()
			}

			if(index == 5){
				this.focusSalesprAc()
			}

			if(index == 8){
				this.focusSubledgerAc()
			}
			if(index == 9){
				this.focusCityAc()
			}
		})
	}

	focusAccountAc(){
		this.accountAC.focus();
	}


	focusSubledgerAc(){
		this.subledgerAC.focus();
	}


	focusSalesprAc(){
		this.dpYMD2.hide()
		this.salesprAC.focus();
	}

	focusCityAc(){
		this.cityAC.focus();
	}



	clickSearchButton(){
		this.searchButton.nativeElement.click()
	}

	getAllBranches() {
        this.commonService.getOkount(`branch`).subscribe(res=> {
          if(res && res['data']) {
            this.branches = res['data'];
          }
        },err=> {
    
        })
      }


}
