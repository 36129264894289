import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError, of, empty } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class ProductServiceDetailsService {




	constructor(
		private http: Http,
		private httpClient: HttpClient,
	) {
	}

	// async retrieveInvoice() {
	// 	//TODO get pnr details from supplier
	// 	let invoiceSkeleton =  this.createInvoiceSkeleton()
	// 	let saveInvoiceSkeletonRes = await this.saveInvoiceSkeleton()
	// 	return saveInvoiceSkeletonRes
	// }

	createInvoiceSkeleton(mainDta) {
		let obj = {
			"services": {
				"invoice_no": "",
				"invoice_date": "",
				"limit_type": "",
				"affiliate_info": {
					"name": "",
					"code": "",
					"address": "",
					"company_name": "",
					"mobile": ""
				},
				"flight": {},
				"hotel": {},
				"insurance": {},
				"product_service_details": this.createProductServiceObj(mainDta)
			}
		}

		// console.log('HELLLLLLO', obj);

		return obj;
	}

	// saveInvoiceSkeleton(){
	// 	return new Promise((resolve, reject) => {
	// 		resolve({
	// 			message : "success"
	// 		})
	// 	});
	// }





	createProductServiceObj(mainDta) {
		let itemsarr = [];
		for (let i = 0; i < mainDta.length; i++) {
			const element = mainDta[i];
			let itemObj = {
				"name": element.name,
				"narration": element.narration,
				"remark": element.remark,
				"quantity": element.quantity,
				"rate": element.rate,
				"tax": element.tax,
				"amount": element.amount
			}
			itemsarr.push(itemObj)
		}
		return itemsarr
	}


	newInvoice(url, data) {
		let token = localStorage.getItem('token');
		const headers = new HttpHeaders({
			'Authorization': token ? token : ''
		});
		return this.httpClient.post(`${environment.okountUrl}/${url}`, data, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
				catchError((err) => {
					return throwError(err);
				}))
	}
	updateInvoice(url, data) {
		let token = localStorage.getItem('token');
		const headers = new HttpHeaders({
			'Authorization': token ? token : ''
		});
		return this.httpClient.put(`${environment.okountUrl}/${url}`, data, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
				catchError((err) => {
					return throwError(err);
				}))
	}


}
