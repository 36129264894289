import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { InitialState } from 'src/app/store/reducer';
import { OpenAdvanceSearchModal } from 'src/app/transactions/store/actions';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from '../../common-service.service';
import { GlobalVariable } from '../../global-variable';

@Component({
  selector: 'app-bill-wise-search',
  templateUrl: './bill-wise-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class BillWiseSearchComponent implements OnInit {
  supplierArr: any;
  clientArr: any;
  billWiseFilterForm: FormGroup;
  keyword = 'name';

  minDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
  maxDate = moment().format('YYYY-MM-DD')

  @ViewChild('accountAC',{static:true}) accountAC;
  @ViewChild('searchButton',{static:true}) searchButton : ElementRef;
  @ViewChild('closeModal', {static : true})  closeModal : ElementRef

  constructor(
    public commonService: CommonServiceService,
    private router: Router,
    private formBuilder: FormBuilder,
	private store: Store<InitialState>) {
		this.store.select('sharedReducers').subscribe((items)=>{
			if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
				this.minDate = items.financeYear.start_date
				this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
							moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
			} else {
				let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
				if(!current_financial_year) {
					current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
				}
				this.minDate = current_financial_year.start_date
				this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
							moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
			}
				  
		  }) }

  ngOnInit(): void {

    this.billWiseFilterForm = this.formBuilder.group({
			client: [''],
			client_name: [''],
            supplier: [''],
			supplier_name: [''],
			start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate, this.checkDate('end_date')],
			viewMode: ['normal'],
			include_invoice: [true],
			include_credit_note: [false]
		})


    this.getSupplierAirlines()
  }

  checkDate(formControlName){
	return (control: AbstractControl) => {
		let valid = true
		if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
			valid = false
		} else {
			if(this){
				if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
					valid = false
				}
			}
		}
		
		return !valid ? {[formControlName]: {value: control.value}} : null;
	};
}
  getSupplierAirlines() {
    this.commonService.getRequest(`${environment.okountUrl}/util/getsupplierairline`).subscribe(res => {
      this.supplierArr = res
    }, err => {
      console.error(err);
    })
  }

  getClient(value,bindValue = false) {
		if (value.length > 2) {
			this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.clientArr = res
				if(bindValue && this.clientArr.length>0){
					this.billWiseFilterForm.controls['client'].setValue(this.clientArr[0].account_code)
					this.billWiseFilterForm.controls['client_name'].setValue(this.clientArr[0].name)
				}
			}, err => {

			})
		}
	}

  clearData(formControl){
		this.billWiseFilterForm.controls[formControl].setValue("")
	}

  selectAccount(value,formControlName){
		try {
			this.billWiseFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}

  filterSuppliers(event) {
    if(event) {
      this.supplierArr.filter(acc => acc.name == event.name)
    }
  }


	selectSupplierAccount(value){
		try {
			this.billWiseFilterForm.controls.supplier.setValue(value.account_code)
      this.billWiseFilterForm.controls.supplier_name.setValue(value.name)
		} catch (error) {

		}
	}


	clickSearchButton(){
		this.searchButton.nativeElement.click()
	}

  search(values) {
	
		try {
		if (this.billWiseFilterForm.invalid) {
			return false
		}
		else{
			GlobalVariable.openModalStatus = false;
			if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
				values.start_date = ""
			}
			else {
				values.start_date = moment(values.start_date).format('YYYY-MM-DD')
			}
			if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date" || values.end_date == "Invalid date") {
				values.end_date = ""
			}
			else {
				values.end_date = moment(values.end_date).format('YYYY-MM-DD')
			}

			let clientCode= values.client
            let supplierCode = values.supplier

			let viewMode = values.viewMode
			if (viewMode == 'summary') {
				clientCode = ''
			}
			
			this.closeModalFn()
			this.router.navigateByUrl(`report/profit-report/bill-wise?viewMode=${viewMode}&start_date=${values.start_date}&end_date=${values.end_date}&client=${clientCode}&supplier=${supplierCode}&include_credit_note=${values.include_credit_note}` )

		}
		} catch (error) {
			console.log(error)
		}
	}


  	closeModalFn(){
		this.closeModal.nativeElement.click()
	}
	reset() {
		GlobalVariable.openModalStatus = false;
			this.billWiseFilterForm.reset()
	}
	focusAccountAc(): void {
		this.accountAC.focus();
	}
}
