import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { InvoiceServicesService } from '../flight/services/invoice-services.service';
import { MiscellaneousInvoiceService } from './services/miscellaneous-invoice.service';

@Component({
  selector: 'app-miscellaneous-invoice',
  templateUrl: './miscellaneous-invoice.component.html',
  styleUrls: ['./miscellaneous-invoice.component.scss']
})
export class MiscellaneousInvoiceComponent implements OnInit {

  @ViewChild("subLedgerAC", { static: true }) subLedgerAC;
  @ViewChild("supplierAc", { static: true }) supplierAc;
  @ViewChild("supplier2Ac", { static: true }) supplier2Ac;
  @ViewChild("clientAC", { static: true }) clientAC;
  @ViewChild("generalLedgerAc", { static: true }) generalLedgerAc;
  @ViewChild("employeeAC", { static: true }) employeeAC;
  @ViewChild("narrationArea", { static: true }) narrationArea;

  
  // bookingSupplierArr: any;
  bookingClientArr: any = [];
  bookingSupplierArr: any = [];
  bookingGenerlaAccountArr: any = [];
  
  isSuccess: boolean = false;
	showMessage: boolean = false;
	alertType = ''
	message: string = ``;

  invoiceMode = 'save'
  loggedInuser: any;
  keyword="name";
	generalAccountArr: any;
  initialValue = '';
  employeeList: any;
  invoice_number: any;
  uuid: unknown;
  invoice_no: any = '';
  invoiceData: any;
  payablesArr: any = [];
  selectedClient: any;
  selectedSupplier: any;
  showTravellerIndex;
  maximizedSection: boolean;
  @ViewChildren('openModal') openModal: QueryList<ElementRef>;
  @ViewChild("openSuccessModal", { static: true }) openSuccessModal;
  staticInputCount = 13
  totalInputInTravellers = 37
  newInvoice = true;
  invoice_config: any;
  companyAddress: any;

  @ViewChild('confirmDeleteModal', { static: true }) confirmDeleteModal: ElementRef;
  @ViewChild('closeDeleteModal', { static: true }) closeDeleteModal: ElementRef;

  constructor(
    private invoiceService : InvoiceServicesService,
    public miscellaneousInvoiceService : MiscellaneousInvoiceService,
    public commonService: CommonServiceService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
  ) { 
		this.route.paramMap.subscribe(params => {
			this.invoice_no = params['params'].invoice
			this.invoiceMode = params['params'].mode ? params['params'].mode : 'save'
		});
  }

  
  ngOnInit(){
  
    this.getPayableAccounts()
    // this.getBookingClient()
    this.getGeneralAccounts()
    this.getCompanyData()
    this.miscellaneousInvoiceService.initialiseFormData({},{})
    if(this.invoiceMode == 'save'){
      let loggedInUser = JSON.parse(localStorage.okountUserInfo);
      this.loggedInuser = loggedInUser
      this.miscellaneousInvoiceService.invoiceForm.controls.employee_name.setValue(
        loggedInUser.name
      );
      this.miscellaneousInvoiceService.invoiceForm.controls.employee.setValue(
        loggedInUser.accountcode
      );
    }else{
      if (this.invoiceMode == 'view' && this.invoice_no != undefined) {
        this.getMiscellineousInvoiceData(this.invoice_no)
      } 
    }
    this.ledgerSearch(this.miscellaneousInvoiceService.invoiceForm.value.subledger_code ? this.miscellaneousInvoiceService.invoiceForm.value.subledger_code :'')
    if (this.invoiceMode != 'view') {
      this.invoice_config = this.miscellaneousInvoiceService.invoice_config['hotel'] ? this.miscellaneousInvoiceService.invoice_config['hotel'] : null
      if(this.invoice_config){
        this.generalSearch(this.invoice_config.general_ledger)
      }
			var url = window.location.href.slice(window.location.href.indexOf('?') + 1);
			this.getuuid(url)
		}
    
  }
  getCompanyData() {
    return new Promise((resolve, reject) => {
			this.invoiceService.getMethod(`setting/address`).subscribe(
				res => {
					this.companyAddress = res
          resolve(res)
				},
				err => {
					reject({});
				}
			);
		});
  }

  async getuuid(url) {
		if (!url.includes('invoice_number') && this.invoiceMode != 'view') {
			this.uuid = await this.getInvoice()
			this.miscellaneousInvoiceService.invoiceForm.controls.invoice_number.setValue(this.uuid)
			window.history.replaceState({}, document.title, `/#/transactions/ms-invoice?invoice_number=${this.uuid}`);
		} else {
			console.log(url)
			if (url.split('=')[1] != undefined) {
				let invNo = url.split('=')[1]
				this.miscellaneousInvoiceService.invoiceForm.controls.invoice_number.setValue(invNo)
			}
		}
	}

  getInvoice() {
		return new Promise((resolve, reject) => {
			this.invoiceService.getMethod(`invoice/getuuid`).subscribe(
				res => {
					let invoiceNo;
					try {
						invoiceNo = res["uuid"]
						resolve(invoiceNo);
					} catch (error) {
						invoiceNo = res
						resolve(invoiceNo);
					}
				},
				err => {
					reject("");
				}
			);
		});
	}

  getMiscellineousInvoiceData(searchId: any) {
		try {
			let url = `invoice/miscellaneous/${searchId}`
      this.spinner.show()
			this.invoiceService.getMethod(url).subscribe(async (res) => {
				let invoiceData = res['data'][0];
				this.invoiceData = res['data'][0];
        // this.handleInvoiceForChange(this.invoiceData.products[0].invoice_type)
        let resp = await this.miscellaneousInvoiceService.bindInvoiceData(invoiceData, '')
        if(this.invoiceData.products[0].invoice_type == 'hotel'){
          this.staticInputCount = 13
        }else{
          this.staticInputCount = 12
        }
        this.clientSearch(this.invoiceData.products[0].client_code)
        this.supplierSearch(this.invoiceData.products[0].supplier_code)
        this.generalSearch(this.invoiceData.products[0].general_ledger_code)
        // this.ledgerSearch()
        this.spinner.hide()
			},
				err => {
					this.spinner.hide()
					console.log(err)
				})
		} catch (error) {
      this.spinner.hide()
			console.log('Error occured in getInvoice due to : ', error)
		}
	}
  // getBookingClient() {
	// 	try {
	// 		this.invoiceService.searchAccount(`client`).subscribe(data => {
	// 			this.bookingClientArr = data
	// 		}, err => {
	// 			throw new Error(JSON.stringify(err));
	// 		})
	// 	} catch (error) {
	// 		console.log('Error while getting Booking Client : ', error)

	// 	}
	// }
  getPayableAccounts() {
		try {
			this.invoiceService.searchAccount(`util/getsupplierairline`).subscribe(data => {
				this.payablesArr = data
			}, err => {
				throw new Error(JSON.stringify(err));
			})
		} catch (error) {
			console.log('Error while getting Booking Suppliers : ', error)

		}
	}

	getGeneralAccounts() {
		try {
			this.invoiceService.getMethod(`general/bank-od-cc`).subscribe(data => {
				this.generalAccountArr = data
				console.log(' Supplier Account : ', this.bookingSupplierArr)
			}, err => {
				this.generalAccountArr = []
				throw new Error(JSON.stringify(err));
			})
		} catch (error) {
			console.log('Error while getting Booking Suppliers : ', error)

		}
	}

  	onChangeSearch(value) {
    		// this.bookingSupplierArr =  this.bookingSupplierArrBase.map(item => (item.account_code.includes(value) || item.name.includes(value)) );
    		
    	}
    
    	async selectAffiliateEvent(data){
    	}
    
    	async selectEvent(data) {
    	}
    
    	onFocused(e) {
    		
    	}

  hideError() {
    this.showMessage = false
  }

  closeMaxModule() {
    this.commonService.minimizeModule(true);
  }

  selectAccount(value, formControlName) {
    try {
      this.miscellaneousInvoiceService.invoiceForm.controls[formControlName].setValue(
        value && value.account_code ? value.account_code : ''
      );
      this.miscellaneousInvoiceService.invoiceForm.controls[`${formControlName}_name`].setValue(
        value && value.name ? value.name : ''
      );
      if(formControlName == 'client_code'){
        this.selectedClient = value
        this.calcuateAllSC()
      }
      if(formControlName == 'supplier_code'){
        this.selectedSupplier = value
        this.calcuateAllSC()
      }
    } catch (error) {}
  }
  

  clientSearch(value) {
    try {
      let searchType = this.miscellaneousInvoiceService.invoiceForm.controls.client_account_type.value;
      if (searchType == null) {
        this.miscellaneousInvoiceService.invoiceForm.controls.client_account_type.setValue(
          "client"
        );
        searchType = this.miscellaneousInvoiceService.invoiceForm.controls.client_account_type.value;
      }
      if (value.length > 2 && searchType != null && searchType != "") {
        this.invoiceService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}`
          )
          .subscribe(
            (res) => {
              this.bookingClientArr = res;
              if(this.bookingClientArr[0] && this.invoiceMode == 'view'){
                this.selectAccount(this.bookingClientArr[0],'client_code')
              }
            },
            (error) => {}
          );
      }
    } catch (error) {
      console.error(error)
    }
  }

  generalSearch(value) {
    try {
     
      if (value.length > 2) {
        this.invoiceService
          .searchAccount(
            `general/autocomplete?name_like=${value.toUpperCase()}`
          )
          .subscribe(
            (res) => {
              this.bookingGenerlaAccountArr = res;
              if(this.bookingGenerlaAccountArr[0]){
                this.selectAccount(this.bookingGenerlaAccountArr[0],'general_ledger_code')
              }
            },
            (error) => {}
          );
      }
    } catch (error) {
      console.error(error)
    }
  }

  supplierSearch(value) {
    try {
      let searchType = this.miscellaneousInvoiceService.invoiceForm.controls.supplier_account_type.value;
      if (searchType == null) {
        this.miscellaneousInvoiceService.invoiceForm.controls.supplier_account_type.setValue(
          "supplier"
        );
        searchType = this.miscellaneousInvoiceService.invoiceForm.controls.supplier_account_type.value;
      }
      if (value.length > 2 && searchType != null && searchType != "") {
        this.invoiceService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}`
          )
          .subscribe(
            (res) => {
              this.bookingSupplierArr = res;
              if(this.bookingSupplierArr[0] && this.invoiceMode == 'view'){
                this.selectAccount(this.bookingSupplierArr[0],'supplier_code')
              }
            },
            (error) => {}
          );
      }
    } catch (error) {}
  }

  ledgerSearch(value) {
    try {
      let searchType = this.miscellaneousInvoiceService.invoiceForm.controls
        .subledger_type.value;
      if (searchType == null) {
        this.miscellaneousInvoiceService.invoiceForm.controls.subledger_type.setValue(
          "client"
        );
        searchType = this.miscellaneousInvoiceService.invoiceForm.controls
          .subledger_type.value;
      }
      if (value.length > 2 && searchType != null && searchType != "") {
        this.invoiceService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}`
          )
          .subscribe(
            (res) => {
              this.miscellaneousInvoiceService["ledgerList"] = res;
              if (value == "CC001A" || value == "CC001B") {
                this.selectAccount(res[0], "subledger_code");
                this.miscellaneousInvoiceService.invoiceForm.controls.subledger_code_name.setValue(
                  res[0].name
                );
              }
            },
            (error) => {}
          );
      }
    } catch (error) {}
  }

  employeeSearch(value) {
    try {
      if (value.length > 2) {
        this.invoiceService
          .searchAccount(
            `employee/autocomplete?name_like=${value.toUpperCase()}`
          )
          .subscribe(
            (res) => {
              this.employeeList = res;
            },
            (error) => {}
          );
      }
    } catch (error) {}
  }
  // onFocused(e) {}


  async saveProductInvoice() {
    if (this.invoiceMode == "view") {
      this.updateProduct();
    } else {
      this.spinner.show()
      let product_json_doc = this.miscellaneousInvoiceService.createMsProduct(
        this.miscellaneousInvoiceService.invoiceForm.value,this.miscellaneousInvoiceService.travellerForm.value
      );
      this.miscellaneousInvoiceService.postMethod(`invoice/miscellaneous`,product_json_doc).subscribe(async (data) => {
      // this.miscellaneousInvoiceService.postMethod(``,product_json_doc).subscribe(async (data) => {
        window.scroll(0, 0)
        this.showMessage = true;
        this.isSuccess = true
        this.alertType = 'success-box';
        this.message = `Invoice Saved with invoice no. : `
        this.invoice_number = data['invoice_no']
        this.miscellaneousInvoiceService.initialiseFormData({},{})
        let loggedInUser = JSON.parse(localStorage.okountUserInfo);
        this.loggedInuser = loggedInUser
        this.miscellaneousInvoiceService.invoiceForm.controls.employee_name.setValue(
          loggedInUser.name
        );
        this.miscellaneousInvoiceService.invoiceForm.controls.employee.setValue(
          loggedInUser.accountcode
        );
        this.miscellaneousInvoiceService.travellerList.controls = [];
        this.miscellaneousInvoiceService.addTraveller({})
        let obj = {
          invoice_no: 'New Invoice',
        };
        this.miscellaneousInvoiceService.fareList = []
        this.miscellaneousInvoiceService.fareList.push(obj);
        this.miscellaneousInvoiceService.calculateFromApi(0)
        this.uuid = await this.getInvoice()
        this.miscellaneousInvoiceService.invoiceForm.controls.invoice_number.setValue(this.uuid)
        window.history.replaceState({}, document.title, `/#/transactions/invoice?invoice_number=${this.uuid}`);
        this.spinner.hide()
        this.newInvoice = false;
        this.openSuccessModal.nativeElement.click()
        setTimeout(() => {
          this.commonService.selectedInput.next(1)
        }, 100);
      },err=> {
        window.scroll(0, 0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = err['error']['message']
        this.spinner.hide()
      })
      
    }
  }
  updateProduct() {
    this.spinner.show()
      let product_json_doc = this.miscellaneousInvoiceService.createMsProduct(
        this.miscellaneousInvoiceService.invoiceForm.value,this.miscellaneousInvoiceService.travellerForm.value
      );
      this.miscellaneousInvoiceService.putMethod(`invoice/miscellaneous/${this.invoice_no}`,product_json_doc).subscribe(async (data) => {
        window.scroll(0, 0)
        this.showMessage = true;
        this.isSuccess = true
        this.alertType = 'success-box';
        this.message = `Invoice Updated`
        this.spinner.hide()
      },err=> {
        window.scroll(0, 0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = err['error']['message']
        this.spinner.hide()
      })
      
  }
  calcuateAllSC(){
    let controls = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls
      for (let i = 0; i < controls.length; i++) {
        this.calcuateSC(i)
      }
  }

  calcuateSC(i){
    const invoiceData = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].value
    let gstRate = this.getGstRate(i)
    let client_igst = 0
    let client_sgst = 0
    let client_cgst = 0
    let clientTotalAmount = 0
    if(invoiceData.client_gst_type== 'T'){
      clientTotalAmount = this.calculateTotal("client",i)
    }
    if(invoiceData.client_gst_type== 'B'){
      clientTotalAmount = !isNaN(Number(invoiceData.client_basic)) ? Number(invoiceData.client_basic) : 0
    }
    if(invoiceData.client_gst_type== 'S'){
      clientTotalAmount = invoiceData.client_sc1_type == 'T' ? Number(invoiceData.client_sc1_amount) : 0
    }
    if(gstRate.client_igst_applicable){
      client_igst = Number((clientTotalAmount * gstRate.client_gst_rate/100).toFixed(2))
    }else{
      let rate = gstRate.client_gst_rate/2
      client_cgst = Number((clientTotalAmount * rate/100).toFixed(2))
      client_sgst = Number((clientTotalAmount * rate/100).toFixed(2))
    }
    let supplier_igst = 0
    let supplier_sgst = 0
    let supplier_cgst = 0
    let supplierTotalAmount = 0
    if(invoiceData.supplier_gst_type== 'T'){
      supplierTotalAmount = this.calculateTotal("supplier",i)
    }
    if(invoiceData.supplier_gst_type== 'B'){
      supplierTotalAmount = !isNaN(Number(invoiceData.supplier_basic)) ? Number(invoiceData.supplier_basic) : 0
    }
    if(invoiceData.supplier_gst_type== 'S'){
      supplierTotalAmount = invoiceData.supplier_sc1_type == 'T' ? Number(invoiceData.supplier_sc1_amount) : 0
    }
    if(gstRate.supplier_igst_applicable){
      supplier_igst = Number((supplierTotalAmount * gstRate.supplier_gst_rate/100).toFixed(2))
    }else{
      let rate = gstRate.supplier_gst_rate/2
      supplier_cgst = Number((supplierTotalAmount * rate/100).toFixed(2))
      supplier_sgst = Number((supplierTotalAmount * rate/100).toFixed(2))
    }
  
    // TODO : Fix gst calculation
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_cgst.setValue(client_cgst)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_sgst.setValue(client_sgst)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_igst.setValue(client_igst)

    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_cgst.setValue(supplier_cgst)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_sgst.setValue(supplier_sgst)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_igst.setValue(supplier_igst)
    return true
  }

  calculateTotal(calculation_key,i){
    const invoiceData = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].value
    
    let nonAccount = invoiceData[`${calculation_key}_non_account`]
    let basic = invoiceData[`${calculation_key}_basic`]
    let taxes = invoiceData[`${calculation_key}_taxes`]
    let commission_amount = invoiceData[`${calculation_key}_commission_amount`]
    let tds = invoiceData[`${calculation_key}_tds`]
    let sc1_type = invoiceData[`${calculation_key}_sc1_type`]
    let sc1_amount = invoiceData[`${calculation_key}_sc1_amount`]
    let sc2_type = invoiceData[`${calculation_key}_sc2_type`]
    let sc2_amount = invoiceData[`${calculation_key}_sc2_amount`]
    let sc3_type = invoiceData[`${calculation_key}_sc3_type`]
    let sc3_amount = invoiceData[`${calculation_key}_sc3_amount`]

    let total = Number(nonAccount) + Number(basic) + Number(taxes) - Number(commission_amount) + Number(tds)
    if(sc1_type == 'T'){
      total += Number(sc1_amount)
    }
    if(sc2_type == 'T'){
      total += Number(sc2_amount)
    }
    if(sc3_type == 'T'){
      total += Number(sc3_amount)
    }
    return !isNaN(Number(total)) ? Number(total) : 0
  }


  getGstRate(i) {
    let formValue = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].value
    let client_gst_rate = formValue.client_gst_conf ? formValue.client_gst_conf : 0
    let supplier_gst_rate = formValue.supplier_gst_conf ? formValue.supplier_gst_conf : 0
    let supplier_igst_applicable = this.selectedSupplier ? this.checkIsIgstApplicable(this.selectedSupplier.gst_number,this.selectedSupplier.state_id) : false
    let client_igst_applicable = this.selectedClient  ? this.checkIsIgstApplicable(this.selectedClient.gst_number,this.selectedClient.state_id) : false
    return {
      client_gst_rate,
      supplier_gst_rate,
      supplier_igst_applicable,
      client_igst_applicable
    }
  }

  checkIsIgstApplicable (otherGstNumber,otherState) {
    let accountingCompanyGstNumber = this.companyAddress && this.companyAddress.gst_number ? this.companyAddress.gst_number : ""
    let accountingCompanyState = this.companyAddress && this.companyAddress.state_id ? this.companyAddress.state_id : ''
    if (otherGstNumber && otherGstNumber.length > 0 && otherGstNumber != 'null') {
      //if other's company code is of same as of accounting company then non igst applicable 
      if (accountingCompanyGstNumber.substring(0, 2) == otherGstNumber.substring(0, 2)) {
          return false;
      } else {
          return true;
      }
  } else if (otherState && otherState.length > 0 && otherState != 'null') {
      //if other's company state is of same sate then non igst applicable
      if (accountingCompanyState == otherState) {
          return false;
      } else {
          return true;
      }
      //if other code/state both is undefined then consider it non igst applicable 
  } else {
      return false;
  }
  }
  async calculateCommission(base,tax,commType,commFor,commRate,tdsType,tdsRate,roundOffType = "NORMAL_ROUND_OFF",i){
    let res = await this.miscellaneousInvoiceService.calculateCommission(base,tax,commType,commFor,commRate,tdsType,tdsRate,roundOffType = "NORMAL_ROUND_OFF",i)
    return res
  }

  async  calculateNetAmount(i){
    var invoiceData = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].value
    let  supplierCommRes = await this.calculateCommission(invoiceData.supplier_basic, invoiceData.supplier_taxes, invoiceData.supplier_commission_type,'SUPPLIER',invoiceData.supplier_commission_value,invoiceData.supplier_tds_type,invoiceData.supplier_tds_conf,'NORMAL_ROUND_OFF',i)
    let  clientCommRes = await this.calculateCommission(invoiceData.client_basic, invoiceData.client_taxes, invoiceData.client_commission_type,'CLIENT',invoiceData.client_commission_value,invoiceData.client_tds_type,invoiceData.client_tds_conf,'NORMAL_ROUND_OFF',i)
    invoiceData = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].value
    let scRes = await this.calcuateSC(i);
    invoiceData = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].value
    let client_total = 0
    let supplier_total = 0
    let client_sc_amount = Number(invoiceData.client_sc1_amount)+Number(invoiceData.client_sc2_amount)+Number(invoiceData.client_sc3_amount)
    let supplier_sc_amount = Number(invoiceData.supplier_sc1_amount)+Number(invoiceData.supplier_sc2_amount)+Number(invoiceData.supplier_sc3_amount)
    client_total = Number(invoiceData.client_basic) + Number(invoiceData.client_taxes )- Number(invoiceData.client_commission_amount)+Number(client_sc_amount)
    +Number(invoiceData.client_igst) + Number(invoiceData.client_cgst) + Number(invoiceData.client_sgst)
    
    supplier_total = Number(invoiceData.supplier_basic) + Number(invoiceData.supplier_taxes )- Number(invoiceData.supplier_commission_amount)+Number(supplier_sc_amount)
    +Number(invoiceData.supplier_igst) + Number(invoiceData.supplier_cgst) + Number(invoiceData.supplier_sgst)
    if(invoiceData.invoice_type !='non_account' ){
      client_total += Number(invoiceData.client_tds_type =='C' ? invoiceData.client_tds : 0)
      supplier_total += Number(invoiceData.supplier_tds_type =='C' ? invoiceData.supplier_tds : 0)
    }
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_net_amount.setValue(Number(supplier_total).toFixed(2))
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_net_amount.setValue(Number(client_total).toFixed(2))
    
  }

  replicateClientAmount(i: any, fromFCN,toFCN) {
    let travellerList = this.miscellaneousInvoiceService.travellerForm.controls.travellerList
    let account_type = this.miscellaneousInvoiceService.invoiceForm.value.invoice_type
    if(
      account_type != 'non_account' && 
      fromFCN && 
      toFCN && 
      travellerList.controls[i] && 
      travellerList.controls[i].controls && 
      travellerList.controls[i].controls[fromFCN] &&
      travellerList.controls[i].controls[toFCN]
      ){
        travellerList.controls[i].controls[toFCN].setValue(travellerList.controls[i].controls[fromFCN].value)
      this.calculateNetAmount(i)
    }
  }

  moveToPreviousTraveller(i) {
		this.showTravellerIndex = i - 1
		// let traveller = this.miscellaneousInvoiceService.travellerForm.value.travellerList[i]
		// let index = this.showTravellerIndex*(traveller.totalIndexCount)+1+(this.sectorService.lastSectorTwoIndex)
		// this.commonService.selectedInput.next(index)
		// this.calculateFareFromApi(0)
	}
  moveToNextTraveller(i) {
		this.showTravellerIndex = i + 1
    //i*totalInputInTravellers+staticInputCount+35
    let nextStep = 1
    if(this.staticInputCount == 12){
      nextStep = 2
    }
		let index = this.showTravellerIndex*this.totalInputInTravellers+this.staticInputCount+nextStep
		this.commonService.selectedInput.next(index)
    if(i == this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls.length){
      this.returnToInvoice()
    }
	}

  returnToInvoice(){
		this.minimizeSection()
		// this.calculateFareFromApi(0)
		// this.saveTravellers()
	}

  openReplicateModal(e: Event,index) {
		e.stopPropagation();
		if (this.openModal["_results"][0]) {
			this.openModal["_results"][0].nativeElement.click()
			this.commonService.selectedInput.next(this.miscellaneousInvoiceService.lastIndexOfTraveller+7)
		}
	}

  replicateFormData(index) {
		let tempFormValue = Object.assign({}, this.miscellaneousInvoiceService.travellerForm.value.travellerList[index])
		let travellerListLength = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls.length
		for (let i = 0; i < travellerListLength; i++) {
      if(i >= index){
        const travellerList = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i];
        this.setTravellerData(travellerList, tempFormValue)
      }
		}
		// this.saveTravellers();
		if (this.showTravellerIndex < travellerListLength - 1) {
			this.moveToNextTraveller(index)
		}
	}

  setTravellerData(travellerList, tempFormValue) {
		let keys = Object.keys(tempFormValue)
		for (let i = 0; i < keys.length; i++) {
			const element = keys[i];
			if (element != 'room_name' && element != 'reference_number' && element != 'lead_pax_name' && element != 'pay_type' && element != 'ledger_card_no') {
				travellerList.controls[element].setValue(tempFormValue[element])
			}
		}
	}

  focusClientAc() {
    if (this.clientAC && this.clientAC.focus) {
      this.clientAC.focus()
    }
  }
  focusGeneralLedgerAc() {
    if (this.generalLedgerAc && this.generalLedgerAc.focus) {
      this.generalLedgerAc.focus()

    }
  }
  focusNarrationArea() {
    if (this.narrationArea != undefined) {
      this.narrationArea.focus();
    }
  }
  focusSupplierAc() {
    if (this.supplierAc != undefined) {
      this.supplierAc.focus();
    }
  }
  focusSupplier2Ac() {
    if (this.supplier2Ac != undefined) {
      this.supplier2Ac.focus();
    }
  }
  focusSubledgerAc() {
    if (this.subLedgerAC != undefined) {
      this.subLedgerAC.focus();
    }
  }

  focusEmployeeAc() {
    if (this.employeeAC != undefined) {
      this.employeeAC.focus();
    }
  }

  get DynamicFormControls() {
		const travellerList = <FormArray>this.miscellaneousInvoiceService.travellerForm.get("travellerList");
		return travellerList;
	}

  maximizeSection(index,nextStep =1) {
		this.maximizedSection = true
		this.showTravellerIndex = index
		this.commonService.selectedInput.next(this.showTravellerIndex*this.totalInputInTravellers+this.staticInputCount+nextStep)
	}

  minimizeSection() {
		this.maximizedSection = false
		this.showTravellerIndex = null
    this.miscellaneousInvoiceService.calculateFromApi(0)
		this.commonService.selectedInput.next(this.miscellaneousInvoiceService.lastIndexOfTraveller+1)
	}

  viewInvoice(invoice_no){
    this.closeSuccesModal()
    this.router.navigate([`/transactions/ms-invoice/view/${invoice_no}`])
  }
  openVoucher(invoice_number) {
		let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/ms-invoice-view;searchId=${invoice_number};type=miscellaneous`;
		window.open(url, "_blank");
	}

  viewJournal(invoice_no) {
    let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/view-invoice-journal/${invoice_no}`;
		window.open(url, "_blank");
	}

  async handleInvoiceForChange(value){
    if(value == 'hotel'){
      this.staticInputCount = 13
    }else{
      this.staticInputCount = 12
    }
    for (const traveller of this.miscellaneousInvoiceService.travellerList.controls) {
      traveller['controls'].client_tds_type.setValue(value == 'non_account' ? 'B' : 'C') 
      let res = await this.calculateNetAmount(this.miscellaneousInvoiceService.travellerList.controls.indexOf(traveller))
    }
    this.miscellaneousInvoiceService.calculateFromApi(0)
    this.invoice_config = this.miscellaneousInvoiceService.invoice_config[value] ? this.miscellaneousInvoiceService.invoice_config[value] : ""
    if(this.invoice_config && this.invoice_config.general_ledger){
      this.generalSearch(this.invoice_config.general_ledger)
    }else{
      this.selectAccount(null,'general_ledger_code')
    }
  }

  handleMaximizeSection(i){
    if(this.staticInputCount == 12){
      this.maximizeSection(i,2)
    }
  }

  closeSuccesModal(){
    this.showMessage = false;
    this.isSuccess = false
    this.alertType = 'success-box';
    this.message = ``
    this.newInvoice = true
    this.openSuccessModal.nativeElement.click()
  }
  resetSelection(formControlName) {
    this.miscellaneousInvoiceService.invoiceForm.controls[formControlName].setValue('')
    this.miscellaneousInvoiceService.invoiceForm.controls[`${formControlName}_name`].setValue('')
    if(formControlName == 'client_code'){
      this.bookingClientArr = []
    }
    if(formControlName == 'supplier_code'){
      this.bookingSupplierArr = []
    }
  }

  setServiceDate(e){
    if(e.target.value && moment(e.target.value).isValid()){
      this.miscellaneousInvoiceService.invoiceForm.controls.service_date.setValue(e.target.value)

    }
  }

  openDeleteConfirmModal() {
		this.confirmDeleteModal.nativeElement.click()
	}
	closeDeleteConfirmModal() {
		this.closeDeleteModal.nativeElement.click()
	}

  deleteInvoice(invoice_no) {
		this.closeDeleteConfirmModal()
		this.commonService.deleteRequest(`${environment.okountUrl}/invoice?invoice_no=${invoice_no}`).subscribe(res => {
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = `Invoice Deleted`
		}, err => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = `Failed to delete invoice.`
		})
	}
}
