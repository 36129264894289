import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, Validators, FormBuilder, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormCanDeactivate } from 'src/app/shared/form-can-deactivate/form-can-deactivate';
import * as moment from 'moment-timezone'

@Component({
	selector: 'app-update-employee',
	templateUrl: './update-employee.component.html',
	styleUrls: ['./update-employee.component.scss']
})
export class UpdateEmployeeComponent extends FormCanDeactivate implements OnInit {
	@Input() data
	@ViewChild('form')
	form: NgForm;
	rolelist: any;
	employeeId: any;

	submitted = false;
	baseUrl = environment.okountUrl;
	employeeForm: FormGroup;
	groupList: any;

	alertType = '';
	message: string = ``;
	countryArr: any = [];
	stateArr: any = [];
	cityArr: any = [];

	showMessage: boolean = false;
	employeeData: any;

	@ViewChild('confirmModal', { static: true }) confirmModal: ElementRef
	@ViewChild('closeModal', { static: true }) closeModal: ElementRef
	@ViewChild('confirmDeleteModal', { static: true }) confirmDeleteModal: ElementRef
	@ViewChild('closeDeleteModal', { static: true }) closeDeleteModal: ElementRef
	@ViewChild('confirmUpdateModal', { static: true }) confirmUpdateModal: ElementRef
	@ViewChild('closeUpdateModal', { static: true }) closeUpdateModal: ElementRef

	constructor(
		public commonService: CommonServiceService,
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private spinner: NgxSpinnerService
	) {
		super()
		this.route.paramMap.subscribe(params => {
			this.employeeId = params['params'].id
		});

		this.employeeForm = this.fb.group({
			client_code:['',Validators.required],
			account_name: ['' ,Validators.required],
			account_code: ['',Validators.required],
			cost_center: [''],
			department: [''],
			sub_department: [''],
			lob : [''],
			status: [''],
			channels:['']

		})

	}

	ngOnInit() {
		if(this.data) {
			this.employeeId = this.data.employee_id
		}
		if (this.employeeId != undefined) {
			this.getEmployee()

		}

	}

	openConfirmModal(): void {
		this.confirmModal.nativeElement.click()
	}
	closeConfirmModal(): void {
		this.closeModal.nativeElement.click()
	}


	getRoles() {
		return this.commonService.getRequest(`${environment.okountUrl}/role`).subscribe(res => {
			this.rolelist = res
			if (this.rolelist != undefined) {
				console.log('working')
			}
		}, err => {
			console.error(err)
		})
	}

	getEmployee() {
		this.commonService.getOkount(`corporate_employee?employee_id=${this.employeeId}`).subscribe(async (res) => {
			if (res[0] != undefined) {
				this.employeeData = res[0]
			}
			console.log(this.employeeData)
			 await this.getGroups()
			// await this.getCountries()
			// await this.getStates(this.employeeData.country_id)
			// await this.getCities(this.employeeData.state_id)
			// await this.getCityName(this.employeeData.city_id)
			await this.setEmployeeData()
			// await this.getRoles()
		})
	}
	setEmployeeData() {
		if (this.employeeForm != undefined && this.employeeData != undefined) {
			// if (this.employeeData.roles.length > 0) {
			// 	this.employeeForm.controls.role_id.setValue(this.employeeData.roles[0].id)
			// }
			this.employeeForm.controls.client_code.setValue(this.employeeData.client_code)
			this.employeeForm.controls.account_name.setValue(this.employeeData.account_name)
			this.employeeForm.controls.account_code.setValue(this.employeeData.account_code)
			this.employeeForm.controls.channels.setValue(this.employeeData.channels)
			this.employeeForm.controls.cost_center.setValue(this.employeeData.cost_center)
			this.employeeForm.controls.department.setValue(this.employeeData.department)
			this.employeeForm.controls.sub_department.setValue(this.employeeData.sub_department)
			this.employeeForm.controls.lob.setValue(this.employeeData.lob)
			this.employeeForm.controls.status.setValue(this.employeeData.status)
			// this.employeeForm.controls.country_id.setValue(this.employeeData.country_id)
			// this.employeeForm.controls.country.setValue(this.employeeData.country)
			// this.employeeForm.controls.gst_number.setValue(this.employeeData.gst_number)
			// this.employeeForm.controls.bank_name.setValue(this.employeeData.bank_name)
			// this.employeeForm.controls.telephone.setValue(this.employeeData.telephone)
			// this.employeeForm.controls.mobile_no.setValue(this.employeeData.mobile_no)
			// this.employeeForm.controls.primary_email.setValue(this.employeeData.primary_email)
			// this.employeeForm.controls.pan_number.setValue(this.employeeData.pan_number)
			// if (this.employeeData.birth_date != undefined && this.employeeData.birth_date != null) {
			// 	this.employeeForm.controls.birth_date.setValue(moment(this.employeeData.birth_date).format('YYYY-MM-DD'))
			// }
			// this.employeeForm.controls.user_name.setValue(this.employeeData.user_name)
			// this.employeeForm.controls.password.setValue(this.employeeData.password)
			// this.employeeForm.controls.ifsc_code.setValue(this.employeeData.ifsc_code)
			// this.employeeForm.controls.status.setValue(this.employeeData.status)

		}
	}

	getCountries() {
		this.commonService.getOkount(`location/country`).subscribe(res => {
			this.countryArr = res
		})
	}

	getStates(country_id) {
		let selectedCountry = this.countryArr.filter(option => {
			return option.location_id == Number(country_id);
		})
		if (selectedCountry.length > 0) {
			this.employeeForm.controls.country.setValue(selectedCountry[0].name)
		} else {
			this.employeeForm.controls.country.setValue('India')
		}
		console.log(selectedCountry)
		this.commonService.getOkount(`location/states?country_id=${country_id}`).subscribe(res => {
			this.stateArr = res
		})
	}
	getCities(state_id) {
		let selectedState = this.stateArr.filter(option => {
			return option.location_id == Number(state_id);
		})
		if (selectedState.length > 0) {
			this.employeeForm.controls.state_name.setValue(selectedState[0].name)
		}
		this.commonService.getOkount(`location/city?state_id=${state_id}`).subscribe(res => {
			this.cityArr = res
		})
	}
	getCityName(city_id) {
		let selectedCity = this.cityArr.filter(option => {
			return option.location_id == Number(city_id);
		})
		if (selectedCity.length > 0) {
			this.employeeForm.controls.city_name.setValue(selectedCity[0].name)
		}
	}

	openUpdateConfirmModal() {
		this.confirmUpdateModal.nativeElement.click()
		this.commonService.selectedInput.next(23)
	}

	closeUpdateConfirmModal() {
		this.closeUpdateModal.nativeElement.click()
	}

	updateEmployee() {

		this.submitted = true;
		this.closeUpdateConfirmModal()
		if (this.employeeForm.valid) {

			this.employeeForm.value['parent_id'] = 23;
			this.commonService.putRequest(`${this.baseUrl}/corporate_employee?employee_id=${this.employeeId}`, this.employeeForm.value).subscribe(res => {
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = 'Employee updated successfully.'
			}, error => {
				this.showMessage = true
				this.alertType = 'error-box';
				this.message = 'Failed to update Employee. Please try Again.'
			})
		}
		else {
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = 'Please fill mandatory fields.'
			return false;
		}
	}

	getGroups() {
		this.commonService.getOkount('v3account/getAllClients').subscribe(res => {
			this.groupList = res
			console.log('Group list : ', this.groupList)
		}, err => {

		})
	}
	hideError() {
		this.showMessage = false
	}

	closeWindow() {
		window.close();
	}
	openDeleteConfirmModal() {
		this.confirmDeleteModal.nativeElement.click()
		this.commonService.selectedInput.next(25)
	}

	closeDeleteConfirmModal() {
		this.closeDeleteModal.nativeElement.click()
	}


	deleteEmployee() {
		// this.submitted = true;
		let employeeId = this.employeeData.account_code
		let clientCode = this.employeeData.client_code
		this.closeDeleteConfirmModal()
		if (employeeId != '') {
			this.commonService.deleteRequest(`${this.baseUrl}/corporate_employee?account_code=${employeeId}&client_code=${clientCode}`).subscribe((res: any) => {
				this.employeeForm.reset()
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = `Employee Deleted.`
				this.spinner.hide();
			},
				err => {
					this.showMessage = true
					this.alertType = 'error-box';
					this.message = `Failed to delete employee.`
					this.spinner.hide();
				})
		} else {
			this.employeeForm.reset()
		}
	}

	openConfiguration(employee, employee_code) {
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/master/setting/employee/${employee}/${employee_code}`
		window.open(path,'_blank')
	}
	openActivity(){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/employee/${this.employeeData.account_code}`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}
}