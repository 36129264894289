
<div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
    <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
      <div class="d-flex flex-y-center">
        <img
          src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
            alertType=='success-box' ? 'green-sucess.svg' : 
            alertType=='warning-box' ? 'red-warning.svg' : 
            null }}"
          width="20px"
          height="20px"
          alt="alert-logo"
          class="mr-20"
        />
        <div class="ml-10">
          <h4 class="m-0 text-size-18 text-bold"
          [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
            {{alertType === "error-box"
            ? "Error"
            : alertType === "warning-box"
            ? "Warning"
            : alertType === "success-box"
            ? "Success"
            : null}}
          </h4>
          <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
           {{message}}
          </small>
        </div>
      </div>
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
        alt="close-btn"
        (click)="hideError()"
        className=" close-btn"
      />
    </div>
  </div>
  
  <button type="button" hidden #openSuccessModal data-toggle="modal" data-target="#successModal"></button>

			<!-- Modal -->
			<div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
				<div class="modal-dialog transaction-creation-modal">
					<!-- Modal content-->
					<div class="modal-content">
						<div class="modal-header">
							<span class="messageFont">
								<span>
									{{message}} 
								</span>
									<a *ngIf="invoice_number != ''" 
										href="javascript:void(0)"	
										(click)="openVoucher(invoice_number)">
										{{invoice_number}}
									</a>
                  <a *ngIf="invoice_number != ''" [title]="copyELementTitle" (click)="copytext(invoice_number)">

                    <i  class="fa fa-copy cursor-pointer"></i>
                  </a>
								</span>
								<button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
							</div>
						<div class="modal-body">
							
							<p>Do you want to create new invoice or  view newly created Invoice ?</p>
						</div>
						<div class="modal-footer justify-content-center">
							<button 
								tabIndex="1" 
								tabindex="1"
								type="button" 
								class="btn btn-primary bg-blue" 
								(click)="viewInvoice(invoice_number)">
								View
							</button>
							<button 
								tabIndex="2" 
								tabindex="2"
								type="button" 
								class="btn btn-primary btn-grey" 
								(click)="openVoucher(invoice_number)">
								Print
							</button>
							<button 
								tabIndex="3" 
								tabindex="3"
								type="button" 
								class="btn btn-primary bg-green"  
								(click)="closeSuccesModal()">
								Create New
							</button>
						</div>
					</div>

				</div>
			</div>

      
 <ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

 <div class="ledger-container">
   <ng-container>
       <div class="row tab-nav pl-0 pr-0">
           <!-- <div class="col-sm-4 pl-0">
               <ul class="list-inline list-unstyled p-0 m-0">
                   <li class="active page-title text-uppercase cursor"> View Client</li>
               </ul>
           </div> -->
           <div class="col-sm-12">
             <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
                 <li  
                     class="text-grey text-capitalize cursor  hover-text-primary transition"> Invoice</li>
                 <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
                 <li  
                     class="text-grey text-capitalize cursor  hover-text-primary transition"> Proforma</li>
                
                 <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
                 <li class="text-grey text-capitalize cursor text-primary transition"> {{invoiceMode}} Proforma</li>
             </ul>
         </div>
       </div>
    
       <form [formGroup]="proformaInvoiceForm" class="proforma-invoice-form" #form="ngForm">
           <div class="p-15 pt-3 custom-form w-100 mb-4">
            <div class="form-wrapper" style="display: flex;">
              <div class="input-wrapper text-field-group d-flex align-items-center pb-3" >
              <div class="text-input-field multiselect-dropdown-container">
              <label>Account</label>
              <div class="ng-autocomplete custom-ngc" attr.tabIndex="1" 
              tabIndex="1" >
              <input
              type="text" #accountAC oninput="this.value = this.value.toUpperCase()" 
              class="autocomplete-container" 
              formControlName="client_code_name"
              [nzAutocomplete]="clientAccount"
              (input)="onChangeSearch($event)"
              />

              <nz-autocomplete #clientAccount [nzBackfill]="true"  (selectionChange)="selectAccount($event, 'client_code')">
              <nz-auto-option  class="colr-1" *ngFor="let item of accountList" [nzValue]="item" [nzLabel]="item.name">
              <a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
              </nz-auto-option>                      
              </nz-autocomplete>

              </div>	
              </div>
              </div>
              <div class="input-wrapper text-field-group d-flex align-items-center pb-3" >
              <div class="text-input-field"> 
              <input formControlName="invoice_date" class="input-field" type="date"  tabIndex="2" attr.tabIndex="2" 
              placeholder="MM/DD/YYYY" autocomplete="off"/>
              <label>Invoice Date</label>
              </div>
              </div>
              <div class="input-wrapper text-field-group d-flex align-items-center pb-3" >
              <div class="text-input-field">
              <input class="input-field" type="text" tabIndex="3" attr.tabIndex="3"  formControlName="reference_number" autocomplete="off"/>
              <label>Reference</label>
              </div>
              </div>
              <div class="input-wrapper text-field-group d-flex align-items-center pb-3" >
              <div class="text-input-field">
              <input class="input-field" type="text" formControlName="invoice_number" readonly autocomplete="off"/>
              <label>Invoice Number</label>
              </div>
              </div>
              <div class="input-wrapper text-field-group d-flex align-items-center pb-3 " >
              <div class="text-input-field multiselect-dropdown-container">

              <label>Employee</label>
              <div class="ng-autocomplete custom-ngc" >
              <input type="text" oninput="this.value = this.value.toUpperCase()" 
              class="autocomplete-container" formControlName="employee_code_name"
              [nzAutocomplete]="employeeNameAc" 
              disabled
              />
              <nz-autocomplete #employeeNameAc [nzBackfill]="true">
              <nz-auto-option  class="colr-1" *ngFor="let item of employeeList" [nzValue]="item" [nzLabel]="item.name">
              <a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
              </nz-auto-option>                      
              </nz-autocomplete>

              </div>	
              </div>
              </div>
            </div>

               <form [formGroup]="serviceForm" *ngIf="pInvoiceArray != undefined">
                   <table class="table table-bordered">
                       <thead>
                           <tr>
                              <th>S.No.</th>
                              <th class="text-left w-30">Description</th>
                              <th class="text-center">Qty</th>
                              <th class="text-center">Rate</th>
                              <th class="text-center">Service Charge</th>
                              <th class="text-center">gst On</th>
                              <th class="text-center">Gst Rate</th>
                              <th class="text-center">IGST</th>
                              <th class="text-center">SGST</th>
                              <th class="text-center">CGST</th>
                              <th class="text-left">Total</th>
                              <th class="text-center">Action</th>
                           </tr>
                       </thead>
                       <tbody formArrayName="pInvoiceArray">
                         <tr *ngFor="let proformaInvoiceEntry of DynamicFormControls.controls; let i = index;">
                             <ng-container [formGroupName]="i">
                               <td>{{i+1}}</td>
                               <td> 
                                   <input attr.tabIndex="{{i*4+4}}" tabIndex="{{i*4+4}}"  
                                   oninput="this.value = this.value.toUpperCase()" class="inner-input-ui text-left" type="text"  (focus)="$event.target.select()"
                                    formControlName="description"
                                   [ngClass]="{'error-field': (proformaInvoiceEntry.controls.description.invalid && proformaInvoiceEntry.controls.description.dirty),
                                   'success-field' : proformaInvoiceEntry.controls.description.valid && proformaInvoiceEntry.controls.description.dirty}"
                                 />
                               </td>

                               <td align="center">
                                   <input attr.tabIndex="{{i*4+5}}" tabIndex="{{i*4+5}}"  oninput="this.value = this.value.toUpperCase()"
                                    class="inner-input-ui text-right" type="number" (focus)="$event.target.select()"
                                    formControlName="quantity" (input)="calculateRowWise($event, i)"
                                   [ngClass]="{'error-field': (proformaInvoiceEntry.controls.quantity.invalid && proformaInvoiceEntry.controls.quantity.dirty),
                                   'success-field' : proformaInvoiceEntry.controls.quantity.valid && proformaInvoiceEntry.controls.quantity.dirty}" />
                               </td>

                                  <td align="center">
                                      <input attr.tabIndex="{{i*4+6}}" tabIndex="{{i*4+6}}"  oninput="this.value = this.value.toUpperCase()"
                                      class="inner-input-ui text-right" type="text" 
                                      formControlName="rate" pattern="^\d*(\.\d{0,8})?$" (input)="calculateRowWise($event, i)"
                                      [ngClass]="{'error-field': (proformaInvoiceEntry.controls.rate.invalid && proformaInvoiceEntry.controls.rate.dirty),
                                      'success-field' : proformaInvoiceEntry.controls.rate.valid && proformaInvoiceEntry.controls.rate.dirty}" />
                                  </td> 
                                  <td align="center">
                                    <input attr.tabIndex="{{i*4+6}}" tabIndex="{{i*4+6}}"  oninput="this.value = this.value.toUpperCase()"
                                    class="inner-input-ui text-right" type="text" 
                                    formControlName="service_charge" pattern="^\d*(\.\d{0,8})?$" (input)="calculateRowWise($event, i)"
                                    [ngClass]="{'error-field': (proformaInvoiceEntry.controls.service_charge.invalid && proformaInvoiceEntry.controls.service_charge.dirty),
                                    'success-field' : proformaInvoiceEntry.controls.service_charge.valid && proformaInvoiceEntry.controls.service_charge.dirty}" />
                                </td> 
                                <td align="center">
                                  <select class="inner-select text-center" id="gst_on"  class="inner-input-ui text-right"  oninput="this.value = this.value.toUpperCase()" formControlName="gst_on" (change)="calculateRowWise($event, i)"
                                  [ngClass]="{'error-field': (proformaInvoiceEntry.controls.gst_on.invalid && proformaInvoiceEntry.controls.gst_on.dirty),
                                  'success-field' : proformaInvoiceEntry.controls.gst_on.valid && proformaInvoiceEntry.controls.gst_on.dirty}"  >
                                  <option >select</option>
                                    <option value="R">rate</option>
                                    <option value="S">service charge</option>
                                    <option value="T">T</option>
                                  </select>
                              </td> 
                              <td align="center">
                                <input attr.tabIndex="{{i*4+6}}" tabIndex="{{i*4+6}}"  oninput="this.value = this.value.toUpperCase()"
                                class="inner-input-ui text-right" type="text" 
                                formControlName="gst_rate" pattern="^\d*(\.\d{0,8})?$" (input)="calculateRowWise($event, i)"
                                [ngClass]="{'error-field': (proformaInvoiceEntry.controls.gst_rate.invalid && proformaInvoiceEntry.controls.gst_rate.dirty),
                                'success-field' : proformaInvoiceEntry.controls.gst_rate.valid && proformaInvoiceEntry.controls.gst_rate.dirty}" />
                            </td> 
                            <td align="center">
                              <input attr.tabIndex="{{i*4+6}}" tabIndex="{{i*4+6}}"  oninput="this.value = this.value.toUpperCase()"
                              class="inner-input-ui text-right" type="text" disabled
                              formControlName="igst" pattern="^\d*(\.\d{0,8})?$" (input)="calculateRowWise($event, i)"
                              [ngClass]="{'error-field': (proformaInvoiceEntry.controls.igst.invalid && proformaInvoiceEntry.controls.igst.dirty),
                              'success-field' : proformaInvoiceEntry.controls.igst.valid && proformaInvoiceEntry.controls.igst.dirty}" />
                          </td> 
                          <td align="center">
                            <input attr.tabIndex="{{i*4+6}}" tabIndex="{{i*4+6}}"  oninput="this.value = this.value.toUpperCase()"
                            class="inner-input-ui text-right" type="text" disabled
                            formControlName="sgst" pattern="^\d*(\.\d{0,8})?$" (input)="calculateRowWise($event, i)"
                            [ngClass]="{'error-field': (proformaInvoiceEntry.controls.sgst.invalid && proformaInvoiceEntry.controls.sgst.dirty),
                            'success-field' : proformaInvoiceEntry.controls.sgst.valid && proformaInvoiceEntry.controls.sgst.dirty}" />
                        </td> 
                        <td align="center">
                          <input attr.tabIndex="{{i*4+6}}" tabIndex="{{i*4+6}}"  oninput="this.value = this.value.toUpperCase()"
                          class="inner-input-ui text-right" type="text"  disabled
                          formControlName="cgst" pattern="^\d*(\.\d{0,8})?$" (input)="calculateRowWise($event, i)"
                          [ngClass]="{'error-field': (proformaInvoiceEntry.controls.cgst.invalid && proformaInvoiceEntry.controls.cgst.dirty),
                          'success-field' : proformaInvoiceEntry.controls.cgst.valid && proformaInvoiceEntry.controls.cgst.dirty}" />
                      </td> 

                                <td class="w-15">
                                    <input attr.tabIndex="{{i*4+7}}" tabIndex="{{i*4+7}}" class="inner-input-ui text-right" oninput="this.value = this.value.toUpperCase()"
                                    formControlName="amount" pattern="^\d*(\.\d{0,8})?$" disabled 
                                    [ngClass]="{'error-field': (proformaInvoiceEntry.controls.amount.invalid && proformaInvoiceEntry.controls.amount.dirty),
                                    'success-field' : proformaInvoiceEntry.controls.amount.valid && proformaInvoiceEntry.controls.amount.dirty}" />
                                </td>

                               <td class="edit-row text-center">
                                   <button  title="Delete" class="custom-table-btn bg-white border-0 p-0 text-bold hoverableShadow transition cursor"
                                   type="button" (click)="removeJvRow(i)" id="remove_jv_row_btn_{{i}}">
                                   <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-delete.svg" alt="" width="20px">
                                 </button>
                               </td>
                              </ng-container>
                           </tr>
                       </tbody>
                   </table>
                </form>
           </div>

           <div class="custom-table mt-3">
            <div class="table-actions d-flex justify-content-between">
                <div class="action d-flex flex-column" >
                      <span *ngIf="proformaInvoiceForm.value.narration_1">
                        <span class="text-grey">Narration 1 :</span> {{proformaInvoiceForm.value.narration_1}}
                      </span>
                      <span *ngIf="proformaInvoiceForm.value.narration_2">
                          <span class="text-grey">Narration 2 :</span>  {{proformaInvoiceForm.value.narration_2}}
                      </span>
                      <span *ngIf="proformaInvoiceForm.value.narration_3">
                          <span class="text-grey">Narration 3 :</span>  {{proformaInvoiceForm.value.narration_3}}
                      </span>
                      <span *ngIf="proformaInvoiceForm.value.narration_4">
                          <span class="text-grey">Narration 4 :</span>  {{proformaInvoiceForm.value.narration_4}}
                      </span>
                    </div>

                <div class="subtotal" [formGroup]="proformaInvoiceForm">
                    <div class="total-box" >
                            <span class="title-type" >Total Amount</span>
                            <span class="result-type">
                              {{proformaInvoiceForm.value.total| currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
                            </span>
                    </div>

                    <div class="total-box">
                      <div class="w-100 d-flex justify-content-between">
                        <div>
                          <span class="mb-0 title-type">Service Charge</span>
                        </div>
                        <div>
                          <input class="input-ui w-100 text-right" type="number" (input)="calculateNetAmount()" formControlName="service_charge" />
                        </div>
                      </div>
                    </div>


                    <div *ngIf="proformaInvoiceForm.value.total">
                    <div class="total-box">
                      <div class="w-100 d-flex justify-content-between">
                        <div>
                          <span class="mb-0 title-type">Gst</span>
                        </div>
                       
                        <div style="display: flex; width: 148px;">
                          <div class="w-100 mr-1">
                            <select class="inner-select text-center" id="gst_on" formControlName="gst_on" (change)="calculateNetAmount()">
                              <option value="">Select</option>
                              <option value="S">S</option>
                              <option value="T">T</option>
                            </select>
                          </div>
                          <div class="w-100">
                            <select class="inner-select text-center" formControlName="gst_rate" (change)="calculateNetAmount()">
                              <option value="">Select</option>
                              <option value="5">5</option>
                              <option value="12">12</option>
                              <option value="18">18</option>
                            </select>
                          </div>
                        </div>
                       
                      </div>
                    </div>

                    <div class="total-box" >
                      <span class="title-type" >IGST</span>
                      <span class="result-type">
                         {{proformaInvoiceForm.value.igst_amount| currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
                      </span>
                   </div>

                  <div class="total-box" >
                    <span class="title-type" >CGST</span>
                    <span class="result-type">
                      {{proformaInvoiceForm.value.cgst_amount| currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
                    </span>
                  </div>

                  <div class="total-box" >
                    <span class="title-type" >SGST</span>
                    <span class="result-type">
                      {{proformaInvoiceForm.value.sgst_amount| currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
                    </span>
                  </div>

                    <div class="total-box">
                      <div class="w-100 d-flex justify-content-between">
                        <div>
                          <span class="mb-0 title-type">Discount</span>
                        </div>
                        <div>
                          <input formControlName="discount" class="input-ui w-100  text-right" (input)="calculateNetAmount()" type="number"/>
                        </div>
                      </div>
                    </div>


                    <div class="total-box">
                      <span class="title-type">Net Amount</span>
                      <span class="result-type">
                        {{proformaInvoiceForm.value.total_payable| currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
                      </span>
                    </div>
                    
                    <div class="total-box">
                      <div class="w-100 d-flex justify-content-between">
                        <div>

                          <span class="title-type">TCS ON</span>
                        </div>
                        <div>
                          <select class="input-ui w-100  text-right" (input)="calculateNetAmount()"
                            formControlName="tcs_on">
                            <option value="total" title="total">Total</option>
                            <option value="total_payable" title="total_payable">Net Amount</option>
    
                          </select>
                        </div>
                      </div>
                    </div>
                    
                    <div class="total-box ">
                      <div class="w-100 d-flex justify-content-between">
                        <div>
                          <span class="title-type">TCS Rate</span>
                        </div>
                        <div>
                          <input class="input-ui w-100  text-right" type="number" formControlName="tcs_rate"
                            (input)="calculateNetAmount()" />
                        </div>
                      </div>
                    </div>
                    
                    
                    <div class="total-box">
                      <span class="title-type">Amount</span>
                      <input formControlName="tcs_amount" readonly class="result-type"type="text" disabled />
                    </div>
                    <div class="total-box">
                      <span class="title-type">Grand Total Amount</span>
                      <span class="title-type">
                        {{proformaInvoiceForm.value.grand_total| currency: currencyCode :'symbol':'1.0' : nativeLanguageCode }}
                      </span>
                    </div>
                  </div>
            
                </div>
            </div>

            <div class="text-input-group d-flex justify-content-between mt-3">
                <div class="input-fiel mt-10 w-50  mr-3">
                    <input 
                        oninput="this.value = this.value.toUpperCase()" class="input-ui w-100" id="narration"
                        formControlName="narration_5" type="text" placeholder="Narration"/>
                        <label 
                        for="narration" 
                       >Narration 5
                        </label>
                        <!-- <span class="error-ui" >Invalid special chars</span> -->
                </div>
                <div class="input-fiel mr-0 w-50 mt-10">
                    <input
                        oninput="this.value = this.value.toUpperCase()" class="input-ui w-100" id="remark"
                        formControlName="remark" type="text" placeholder="Remark" /><label for="remark">Remark</label>
                </div>
            </div>
            <!-- <div class="d-flex justify-content-center" *ngIf="balanceObj">
                <div *ngIf="balanceObj.credit" class="ml-2">
                    <span class="font-weight-bold">Credit Balance : </span> {{balanceObj?.credit | number : '0.1-1'}}
                </div>
            </div> -->
           

          </div>

         
       </form>
       <div class="btn-field bottom-btns-container full-width-btn-container d-flex align-item-center justify-content-end  ml-0 mr-0">  
        <button type="button" class="btn-style bg-purple mr-3" attr.tabIndex="{{lastInputIndex+1}}"
                tabIndex="{{lastInputIndex+1}}" (click)="openConfirmModal(lastInputIndex,3)" id="save_jv_btn">Save</button>
           <button type="button" attr.tabIndex="{{lastInputIndex+4}}" tabIndex="{{lastInputIndex+4}}" class="btn-style bg-green mr-3" (click)="reset()">Reset</button>
          <button type="button" class="btn-style bg-cyan mr-3"
           [disabled]="proformaInvoiceForm.invalid"
           attr.tabIndex="{{lastInputIndex+1}}" tabIndex="{{lastInputIndex+1}}" (click)="openUpdateConfirmModal()" id="modify_jv_btn">Modify</button>
           
           <button type="button" class="btn-style bg-red mr-3" (click)="openDeleteConfirmModal()" attr.tabIndex="{{lastInputIndex+2}}" tabIndex="{{lastInputIndex+2}}" id="delete_jv_btn">Delete</button>
           <button type="button" class="btn-style bg-brown mr-3" (click)="openVoucher(proformaInvoiceId)" attr.tabIndex="{{lastInputIndex+2}}" tabIndex="{{lastInputIndex+2}}" id="open_jv_btn" >Print</button>

           <!-- <button type="button" attr.tabIndex="{{lastInputIndex+3}}" tabIndex="{{lastInputIndex+3}}" (click)="openActivity()" class="btn-style bg-indigo mr-3" id="jv_activity_log_btn">Activity
           </button> -->

           <!-- <button type="button" attr.tabIndex="{{lastInputIndex+3}}" tabIndex="{{lastInputIndex+3}}"(click)="openSendEmailModal()" class="btn-style bg-blue-ivy" id="jv_email_btn">
               Email
           </button> -->
           
       </div>

   </ng-container>

</div>

<button type="button" hidden  #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button >

   <div id="deleteConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
     <div class="modal-dialog">
         <!-- Modal content-->
         <div class="modal-content">
             <div class="modal-header">
                 <h4 class="modal-title">Confirmation</h4>
                 <button type="button" #closeDeleteModal class="close" data-dismiss="modal" (click)="resetCurrentModalType()">&times;</button>
             </div>
             <div class="modal-body">
                 <p>Are you sure to delete ‎this entry ?</p>
             </div>
             <div class="modal-footer">
                 <button type="button" class="btn-style" attr.tabIndex="{{lastInputIndex+6}}" tabIndex="{{lastInputIndex+6}}"
                     (click)="closeDeleteConfirmModal()" data-dismiss="modal">No</button>
                     <button type="button" class="btn-style bg-red" attr.tabIndex="{{lastInputIndex+5}}" tabIndex="{{lastInputIndex+5}}"  (click)="deleteInvoice(proformaInvoiceId)" data-dismiss="modal">Yes</button>
             </div>
         </div>
     </div>
 </div>
 
 <button type="button" hidden   #confirmUpdateModal data-toggle="modal" data-target="#updateConfirmationModal"></button >
 
 <div id="updateConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
     <div class="modal-dialog">
         <!-- Modal content-->
         <div class="modal-content">
             <div class="modal-header">
                 <h4 class="modal-title">Confirmation</h4>
                 <button type="button" #closeUpdateModal class="close" data-dismiss="modal" (click)="resetCurrentModalType()">&times;</button>
             </div>
             <div class="modal-body">
                 <p>Are you sure to update ‎this entry ?</p>
             </div>
             <div class="modal-footer">
                 <button type="button" class="btn-style" attr.tabIndex="{{lastInputIndex+8}}" tabIndex="{{lastInputIndex+8}}"
                     (click)="closeUpdateConfirmModal()" data-dismiss="modal">No</button>
                     <button type="button" class="btn-style bg-green" attr.tabIndex="{{lastInputIndex+7}}" tabIndex="{{lastInputIndex+7}}" 
                     (click)="updateProduct()"
                        data-dismiss="modal">Yes</button>
             </div>
         </div>
     </div>
 </div>


   <button
   hidden
   #openEmailModal
   data-target="#emailModalOfInvoice"
   data-toggle="modal"
 ></button>
   <div
     class="modal"
     id="emailModalOfInvoice"
     role="dialog"
     aria-labelledby="emailModalLabel"
     data-backdrop="false"
   >
     <div class="modal-dialog" role="document">
       <div class="modal-content">
         <div class="modal-header">
           <div class="d-flex justify-content-center">
             <h4 class="modal-title">Send Email</h4>
           </div>
           <button
             type=" button"
             class="close"
             #closeSendEmailModal
             data-dismiss="modal"
             aria-label="Close"
           >
             <span aria-hidden="true">&times;</span>
           </button>
         </div>
         <div class="modal-body emailSenders">
           JV
           <form [formGroup]="sendEmailForm" style="margin-top: -10px">
             <div class="form-group text-input-field">
               <textarea
                 class="input-field ng-pristine ng-valid ng-touched text-size-12"
                 id="toEmail"
                 rows="1"
                 formControlName="toEmails"
                 required
               ></textarea>
               <label for="To" class="mb-0">To</label>
             </div>
             <div class="d-flex justify-content-end">
               <div *ngIf="sendEmailForm.controls.toEmails.invalid">
                 <div
                   *ngIf="sendEmailForm.controls.toEmails.errors?.required"
                   style="color: red"
                 >
                   To Email is required.
                 </div>
               </div>
             </div>
 
             <div class="form-group text-input-field ">
               <textarea
                 class="input-field ng-pristine ng-valid ng-touched text-size-12"
                 id="ccEmail"
                 style="max-height: 45px;"
                 formControlName="ccEmails"
               ></textarea>
               <label for="Cc" class="mb-0">Cc</label>
             </div>
 
             <div class="form-group text-input-field">
               <textarea
                 class="input-field ng-pristine ng-valid ng-touched text-size-12"
                 id="bccEmail"
                 style="max-height: 45px;"
                 formControlName="bccEmails"
               ></textarea>
               <label for="Bcc" class="mb-0">Bcc</label>
             </div>
             <div class="text-input-field">
                 <input formControlName="subject"  class="input-field text-size-12" type="text" 
                 autocomplete="off" />
                 <label>Subject</label>
             </div>
             <div class="form-group text-input-field">
              <textarea
                class="input-field ng-pristine ng-valid ng-touched text-size-12"
                rows="5"
                formControlName="body"
              ></textarea>
              <label for="body" class="mb-0">Mail Body</label>
              <input type="file" (change)="handleAttachment($event)" />

            </div>
             <div class="mt-3 d-flex justify-content-center align-item-center">
               <button type="button" class="btn-style mr-2" data-dismiss="modal">
                 Cancel
               </button>
               <button
                 type="button"
                 class="btn-style bg-green"
                 (click)="callTosendEmail()"
               >
                 Send
               </button>
             </div>
           </form>
         </div>
       </div>
     </div>
   </div>


   <button type="button" hidden #confirmModal data-toggle="modal" data-target="#confirmationModal"></button>

   <div class="modal fade" id="confirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Confirmation</h4>
          <button type="button" class="close" #closeModal data-dismiss="modal" aria-label="Close" >
  
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Add New Entry ?
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
          <button type="button" attr.tabIndex="{{lastInputIndex+2}}" tabIndex="{{lastInputIndex+2}}"
            class="btn-style" (click)="addProformaRow(null)">Yes</button>
          <button type="button" class="btn-style mr-2" attr.tabIndex="{{lastInputIndex+3}}"
            tabIndex="{{lastInputIndex+3}}" (click)="saveProductInvoice()">No</button>
        </div>
      </div>
    </div>
  </div>