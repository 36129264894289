
<div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
	<div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
	  <div class="d-flex flex-y-center">
		<img
		  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
			alertType=='success-box' ? 'green-sucess.svg' : 
			alertType=='warning-box' ? 'red-warning.svg' : 
			null }}"
		  width="20px"
		  height="20px"
		  alt="alert-logo"
		  class="mr-20"
		/>
		<div class="ml-10">
		  <h4 class="m-0 text-size-18 text-bold"
		  [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
			{{alertType === "error-box"
			? "Error"
			: alertType === "warning-box"
			? "Warning"
			: alertType === "success-box"
			? "Success"
			: null}}
		  </h4>
		  <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
		   {{message}}
		  </small>
		</div>
	  </div>
	  <img
		src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
		alt="close-btn"
		(click)="hideError()"
		className=" close-btn"
	  />
	</div>
  </div>
  
<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>


<button type="button" hidden #openSuccessModal data-toggle="modal" data-target="#successModal"></button>

			<!-- Modal -->
			<div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
				<div class="modal-dialog transaction-creation-modal">
					<!-- Modal content-->
					<div class="modal-content">
						<div class="modal-header">
							<span class="messageFont">
								<span>
									{{message}} 
								</span>
									<a *ngIf="invoice_number != ''" 
										href="javascript:void(0)"	
										(click)="openVoucher(invoice_number)">
										{{invoice_number}}
									</a>
									<i *ngIf="invoice_number != ''" [title]="copyELementTitle" (click)="copytext(invoice_number)" class="fa fa-copy cursor-pointer"></i>
								</span>
								<button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
							</div>
						<div class="modal-body">
							
							<p>Do you want to create new invoice or  view newly created Invoice ?</p>
						</div>
						<div class="modal-footer justify-content-center">
							<button 
								tabIndex="1" 
								tabindex="1"
								type="button" 
								class="btn btn-primary bg-blue" 
								(click)="viewInvoice(invoice_number)">
								View
							</button>
							<button 
								tabIndex="2" 
								tabindex="2"
								type="button" 
								class="btn btn-primary btn-grey" 
								(click)="openVoucher(invoice_number)">
								Print
							</button>
							<button 
								tabIndex="3" 
								tabindex="3"
								type="button" 
								class="btn btn-primary bg-green"  
								(click)="closeSuccesModal()">
								Create New
							</button>
						</div>
					</div>

				</div>
			</div>



<form [formGroup]="miscellaneousInvoiceService.invoiceForm" [ngClass]="{'d-none': !newInvoice}" class="ledger-container ">
	<div class="row tab-nav pl-0 pr-0">
		<!-- <div class="col-sm-4 pl-0">
			<ul class="list-inline list-unstyled p-0 m-0">
				<li class="active page-title text-uppercase cursor"> View Client</li>
			</ul>
		</div> -->
		<div class="col-sm-12">
		  <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
			<li class="text-grey text-capitalize cursor text-primary transition">Invoice</li>
			<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-right.svg" alt=""></li>
			<li class="text-grey text-capitalize cursor  hover-text-primary transition"> {{invoiceType}}</li>
			<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-right.svg" alt=""></li>
			<li class="text-grey text-capitalize cursor  hover-text-primary transition"> {{miscellaneousInvoiceService.invoiceForm.value.transaction_method}}</li>
		  </ul>
	  </div>
	</div>
<div class="pl-15 pr-15 custom-form form-container w-100 mb-4">
	<div class=" mb-0 mt-2 mb-fix miscellaneousInvoiceServiceContainer">
		<div class="invoice-container invoice-table">
			<div>
				<table class="table table-hovered table-bordered top-miscellaneous-bar w-100">
					<thead>
						<tr>
							<!-- <th style="width: 70px;"></th>
							<th style="width: 70px;">Invoice</th> -->
							<th style="width: 9%;">Acc. Type</th>
							<th style="width: 18%;" >Client<span class="text-danger">*</span></th>
							<th style="width: 18%;" *ngIf="IncomeSetOff=='true'" >General Ledger</th>
							<th style="width: 9%;">Acc. Type</th>
							<th style="width: 18%;" >Payable</th>
							<th style="width: 9%;" *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'visa' || 
						      	miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'  ">
								{{miscellaneousInvoiceService.invoiceForm.value.invoice_type}} Type
							</th>
							
							<th style="width: 10%;">Invoice Date</th>
							<th style="width: 14%;" *ngIf="miscellaneousInvoiceService.invoiceForm.value.reference_invoice_number">Reference</th>
							<th style="width: 14%;" *ngIf="invoiceMode == 'view'">Invoice Number</th>
							<th style="width: 14%;" *ngIf="miscellaneousInvoiceService.invoiceForm.value.transaction_method == 'purchase'">Reference Invoice</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<select class="inner-select" tabIndex="4" tabindex="4"
									formControlName="client_account_type" (change)="resetSelection('client_code')">
									<option value="">Select</option>
									<option value="client">Client</option>
									<option value="airline">Airline</option>
									<option value="supplier">Supplier</option>
									<option value="general">General</option>
								</select>
							</td>
							<!-- client -->
							<td tabIndex="5" tabindex="5" (focus)="focusClientAc()"
								style="text-align: left; width: 200px;">
								<ng-autocomplete [data]="bookingClientArr" #clientAC formControlName="client_code_name"
									[searchKeyword]="keyword" (selected)="selectAccount($event,'client_code', null, true)"
									(inputChanged)='clientSearch($event,true,true)' (inputFocused)='onFocused($event)'
									(inputCleared)="selectAccount($event,'client_code', null, true)"
									[itemTemplate]="itemTemplate" 
									>
									<!-- [ngClass]="{'validate-ng-error': (miscellaneousInvoiceService.invoiceForm.controls.client_code_name.invalid && miscellaneousInvoiceService.invoiceForm.controls.client_code_name.touched) || 
									(!miscellaneousInvoiceService.invoiceForm.controls.client_code_name.valid && sectorService.submitted)}" -->
								</ng-autocomplete>

								<ng-template #itemTemplate let-item>
									<a
									    innerHTML='<span class="colr-1 selected-ng">{{item.name}}<span *ngIf="item.account_code">({{item.account_code}})<span></span><br />'>
								    </a>
								</ng-template>

							</td>

							<td  *ngIf="IncomeSetOff=='true'"  tabIndex="6" tabindex="6" (focus)="focusGeneralLedgerAc()"
								style="text-align: left; width: 200px;">
								<ng-autocomplete [data]="bookingGenerlaAccountArr" #generalLedgerAc formControlName="general_ledger_code_name"
									[searchKeyword]="keyword" (selected)="selectAccount($event,'general_ledger_code', null, true)"
									(inputChanged)='generalSearch($event,false,true,true)' (inputFocused)='onFocused($event)'
									(inputCleared)="selectAccount($event,'general_ledger_code', null, true)"
									[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
								</ng-autocomplete>

								<ng-template #itemTemplate let-item>
									<a
										innerHTML='<span class="colr-1 selected-ng">{{item.name}}</span>'>
										<span *ngIf="item.account_code">({{item.account_code}})</span>
									</a>
								</ng-template>

								<ng-template #notFoundTemplate let-notFound>
									<div [innerHTML]="notFound"></div>
								</ng-template>

							</td>
							<td>
								<select class="inner-select" tabIndex="7" tabindex="7"
									formControlName="supplier_account_type" (change)="resetSelection('supplier_code')">
									<option value="">Select</option>
									<option value="client">Client</option>
									<option value="airline">Airline</option>
									<option value="supplier">Supplier</option>
									<option value="employee">Employee</option>
									<option value="general">General</option>
								</select>
							</td>
							<!-- supplier -->
							<td tabIndex="8" tabindex="8" (focus)="focusSupplierAc()"
								style="text-align: left; width: 200px;">

								<ng-autocomplete [data]="bookingSupplierArr" #supplierAc
									formControlName="supplier_code_name" [searchKeyword]="keyword"
									(selected)="selectAccount($event,'supplier_code',null,true)"
									(inputCleared)="selectAccount($event,'supplier_code',null,true)"
									(inputChanged)='supplierSearch($event,false,true)' (inputFocused)='onFocused($event)'
									[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
								</ng-autocomplete>
								<ng-template #itemTemplate let-item>
									<a
										innerHTML='<span class="colr-1 selected-ng">{{item.name}}<span *ngIf="item.account_code">({{item.account_code}})<span></span><br />'>
									</a>
								</ng-template>

								<ng-template #notFoundTemplate let-notFound>
									<div [innerHTML]="notFound"></div>
								</ng-template>
							</td>

							<td *ngIf="['hotel', 'visa'].includes(miscellaneousInvoiceService.invoiceForm.value.invoice_type)">
								<select class="inner-select" tabIndex="9" tabindex="9" *ngIf="isTenantIndian" (change)="invoiceMode != 'view' && calculateServiceChargeClientWise()"
									formControlName="type">
									<option value="">Select</option>
									<option value="normal" *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'visa'">NORMAL</option>
									<option value="outstation" *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'visa'">OUTSTATION</option>
									<option value="domestic" *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'">DOMESTIC</option>
									<option value="international" *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'">INTERNATIONAL</option>
								</select>

								<input *ngIf="!isTenantIndian" style="text-align: left;width: 200px;" tabIndex="9" tabindex="9" class="input-field" type="text" formControlName="type" />
							</td>
							
							<td>
								<input style="min-width: max-content; padding: 0;"
									min="{{minDate}}"  tabIndex="{{['hotel', 'visa'].includes(miscellaneousInvoiceService.invoiceForm.value.invoice_type) ? 10 : 9}}"
									max="{{maxDate}}"
									class="input-field" type="date" formControlName="invoice_date"
									(blur)="setServiceDate($event)"
									placeholder="MM/DD/YYYY" autocomplete="off" 
									[ngClass]="{'error-box': !miscellaneousInvoiceService.invoiceForm.controls.invoice_date.valid}"/>
							</td>
							<td *ngIf="miscellaneousInvoiceService.invoiceForm.value.reference_invoice_number">
								<input style="min-width: max-content; padding: 0;" class="input-field" type="text"
									formControlName="reference_invoice_number" disabled />
							</td>
							<td *ngIf="invoiceMode == 'view'">
								<input style="min-width: max-content; padding: 0;" class="input-field" type="text"
									formControlName="invoice_number" disabled />
							</td>
							<td *ngIf="miscellaneousInvoiceService.invoiceForm.value.transaction_method == 'purchase'">
								<input style="min-width: max-content; padding: 0;" class="input-field" type="text"
									formControlName="crn_against" disabled />
							</td>
						</tr>
					</tbody>
				</table>

			</div>

			<table class="table table-hovered table-bordered top-miscellaneous-bar">
				<thead>
					<tr>
						<th style="width: 115px;">Service Date</th>
						<th style="width: 100px;">Sub Ledger Type</th>
						<th>Sub-ledger</th>
						<th *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'">Hotel Name</th>
						<th *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'hotel' 
							&& miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'rail' 
							&& miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'bus' ">
							Supplier Trans. no.
						</th>
						<th style="width: 150px;" *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' || miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'bus'">PNR</th>
						<th *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'bus'">Ticket No.</th>
						<th *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail'">Train Name</th>
						<th *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'car'">Car Name</th>
						<th>Employee</th>
						<th style="width: 140px;" *ngIf="!isTenantIndian">Purchase currency</th>
						<th style="width: 40px;" *ngIf="!isTenantIndian">Rate({{currencyCode}}-{{miscellaneousInvoiceService.invoiceForm.value.purchase_currency}})</th>
						<th>{{miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 'Room(s)' : 'Traveller(s)'}}</th>
						<th *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' && isP2PEnabled">P2P</th>
						
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<input tabIndex="{{['hotel', 'visa'].includes(miscellaneousInvoiceService.invoiceForm.value.invoice_type) ? 11 : 10}}" 
							    class="input-ui inv-date-input" formControlName="service_date" type="date" autocomplete="off" />
						</td>

						<td>
							<select class="inner-select" tabIndex="{{['hotel', 'visa'].includes(miscellaneousInvoiceService.invoiceForm.value.invoice_type) ? 12 : 11}}" 
							    formControlName="subledger_type">
								<option value="">Select</option>
								<option value="client">Client</option>
								<option value="airline">Airline</option>
								<option value="supplier">Supplier</option>
								<option value="employee">Employee</option>
								<option value="general">General</option>
							</select>
						</td>
						<td style="text-align: left;width: 135px;" (focus)="focusSubledgerAc()"
						    tabIndex="{{['hotel', 'visa'].includes(miscellaneousInvoiceService.invoiceForm.value.invoice_type) ? 13 : 12}}"
							tabindex="{{['hotel', 'visa'].includes(miscellaneousInvoiceService.invoiceForm.value.invoice_type) ? 13 : 12}}">
							<ng-autocomplete [data]="miscellaneousInvoiceService.ledgerList" #subLedgerAC
								[searchKeyword]="keyword" (selected)="selectAccount($event,'subledger_code',null,true)"
								formControlName="subledger_code_name" (inputChanged)='ledgerSearch($event,true)'
								(inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
								(inputCleared)="selectAccount($event,'subledger_code',null,true)"
								[notFoundTemplate]="notFoundTemplate">
							</ng-autocomplete>


							<ng-template #itemTemplate let-item>
								<a
									innerHTML='<span class="colr-1 selected-ng">{{item.name}}<span *ngIf="item.account_code">({{item.account_code}})<span></span><br />'>
								</a>
							</ng-template>

							<ng-template #notFoundTemplate let-notFound>
								<div [innerHTML]="notFound"></div>
							</ng-template>

						</td>
						<td *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'"  (focus)="focusHotelAc()">
							<ng-container *ngIf="isP2PEnabled; else normalInput">
								<ng-autocomplete [data]="hotelsArr" #hotelAc
									[searchKeyword]="keyword" (selected)="selectAccount($event,'hotel',null,true)"
									formControlName="hotel_name" (inputChanged)='hotelSearch($event,true)'
									(inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
									(inputCleared)="selectAccount($event,'hotel',null,true)"
									[notFoundTemplate]="notFoundTemplate">
								</ng-autocomplete>

								<ng-template #itemTemplate let-item>
									<a
										innerHTML='<span class="colr-1 selected-ng">{{item.name}}<span *ngIf="item.account_code">({{item.account_code}})<span></span><br />'>
									</a>
								</ng-template>
	
								<ng-template #notFoundTemplate let-notFound>
									<div [innerHTML]="notFound"></div>
								</ng-template>

							</ng-container>
							<ng-template #normalInput>
								<input tabIndex="14" style="min-width: -webkit-fill-available;"
									type="text" class="field-input" formControlName="hotel_name"
									(blur)="setNarration()"
									oninput="this.value = this.value.toUpperCase()"
									name="hotel_name" />
							</ng-template>
							
						</td>
						<td *ngIf="['hotel', 'rail', 'bus'].includes(miscellaneousInvoiceService.invoiceForm.value.invoice_type) == false">
							<input tabIndex="{{miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'visa' ? 14 : 13}}" style="min-width: -webkit-fill-available;"
								type="text" class="field-input" formControlName="supplier_transaction_number"
								oninput="this.value = this.value.toUpperCase()"
								maxlength="9"
								name="supplier_transaction_number" />
						</td>

						<td *ngIf="['rail', 'bus'].includes(miscellaneousInvoiceService.invoiceForm.value.invoice_type)">
							<input tabIndex="13" tabindex="13" style="min-width: -webkit-fill-available; width: 150px;" 
								type="text" class="field-input" formControlName="pnr"
								oninput="this.value = this.value.toUpperCase()"
								(blur)="setNarration()"
								name="pnr" />
						</td>


						<td *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'bus' ">
							<input tabIndex="14" tabindex="14" style="min-width: -webkit-fill-available;"
								type="text" class="field-input" formControlName="ticket_no"
								oninput="this.value = this.value.toUpperCase()"
								name="ticket_no" />
						</td>

						<td *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail'">
							<input tabIndex="14" tabindex="14" style="min-width: -webkit-fill-available;"
								type="text" class="field-input" formControlName="train_name"
								(blur)="setNarration()"
								oninput="this.value = this.value.toUpperCase()"
								name="train_name" />
						</td>

						<td *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'car'">
							<input tabIndex="14" tabindex="14" style="min-width: -webkit-fill-available;"
								type="text" class="field-input" formControlName="car_name"
								(blur)="setNarration()"
								oninput="this.value = this.value.toUpperCase()"
								name="car_name" />
						</td>
						
						<td tabIndex="{{miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'insurance' ? 14 : 15}}" 
						tabindex="{{miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'insurance' ? 14 : 15}}" 
						class="text-center" (focus)="focusEmployeeAc()">
							<ng-autocomplete [data]="employeeList" #employeeAC [searchKeyword]="keyword"
								formControlName="employee_name" (selected)="selectAccount($event,'employee', null, true)"
								(inputChanged)='employeeSearch($event)' (inputFocused)='onFocused($event)'
								[itemTemplate]="itemTemplate"
								[disabled]="invoiceMode == 'view' && loggedInuser && loggedInuser.accountcode != 'ADMIN' && miscellaneousInvoiceService.invoiceForm.value.employee != ''"
								[notFoundTemplate]="notFoundTemplate">
							</ng-autocomplete>


							<ng-template #itemTemplate let-item>
								<a
									innerHTML='<span class="colr-1 selected-ng">{{item.name}}({{item.account_code}})</span><br />'>
								</a>
							</ng-template>

							<ng-template #notFoundTemplate let-notFound>
								<div [innerHTML]="notFound"></div>
							</ng-template>

							<span class="text-danger"
								*ngIf="miscellaneousInvoiceService.invoiceForm.value.employee == ''">Please Enter
								Employee</span>
						</td>
						<td *ngIf="!isTenantIndian">
							<select id="flightType"  class="input-ui inv-date-input" formControlName="purchase_currency"
								tabIndex="{{miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'insurance' ? 15 : 16}}" 
								tabindex="{{miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'insurance' ? 15 : 16}}"
							>
								<option value="">Select</option>
								<option value="AED">AED</option>
								<option value="CAD">CAD</option>
								<option value="USD">USD</option>
								<option value="INR">INR</option>
								<option value="EURO">EURO</option>
								<option value="POUND">POUND</option>
							</select>
						</td>
						<td *ngIf="!isTenantIndian">
							<input  type="text" class="field-input text-center"
							    tabIndex="{{miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'insurance' ? 16 : 17}}" 
								tabindex="{{miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'insurance' ? 16 : 17}}"
								formControlName="purchase_currency_rate"
							/>
						</td>
						<td >
							<input tabIndex="{{(isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'insurance') ? 15 :
							(isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'insurance') ? 16 : 
							(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'insurance') ? 17 : 18}}"
							style="min-width: -webkit-fill-available;"
								type="number" class="field-input text-center" (blur)="miscellaneousInvoiceService.travellerCountUpdate($event.target.value); invoiceMode != 'view' && calculateServiceChargeClientWise()" formControlName="number_of_pax" name="traveller" />
						</td>

						<td  align="middle" valign="center" *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' && isP2PEnabled">
							<span class="d-flex align-items-center justify-content-center flex-row">
								<input
									formControlName="is_p2p" style="height: 14px;"
									type="checkbox" />
							</span>
						</td>
						
					</tr>
				</tbody>
			</table>

			<table class="table table-hovered table-bordered top-miscellaneous-bar"  
				*ngIf="['rail', 'car', 'bus'].includes(miscellaneousInvoiceService.invoiceForm.value.invoice_type)">
				<thead>
					<tr>
						<ng-container *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ">
							<th style="width: 64px;" >SECTOR</th>
							<th style="width: 60px;" >Train Number</th>
							<th style="width: 60px;">Class</th>
						</ng-container>
						
						<th style="width: 150px;">DEPARTURE DATE</th>
						<th style="width: 150px;"> {{ miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? "Departure Station" : "BOARDING CITY" }} </th>
						<th style="width: 64px;" *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'rail' ">BOARDING POINT
					    </th>
						<th style="width: 150px;">ARRIVAL DATE</th>
						<th style="width: 150px;"> {{ miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? "ARRIVAL Station" : "Arrival City" }} </th>
						 <th style="width: 64px;" *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'rail' ">ARRIVAL POINT</th> 
						<th style="width: 64px;" *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'rail' ">
							{{miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'bus' ? 'BUS' :
							  miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'car' ? 'TRIP' : '' }} TYPE
						</th>
						<th style="width: 64px;" *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'car' ">
							Journey Distance(IN KM)
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<ng-container *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ">
							<td>
								<input tabIndex="{{isTenantIndian ? 17 : 19}}" tabindex="{{isTenantIndian ? 17 : 19}}" class="input-ui inv-date-input text-center" oninput="this.value = this.value.toUpperCase()"
									formControlName="sector" name="sector" type="text" placeholder="___/___"/>
							</td>
							<td>
								<input tabIndex="{{isTenantIndian ? 18 : 20}}" tabindex="{{isTenantIndian ? 18 : 20}}" class="input-ui inv-date-input text-center" oninput="this.value = this.value.toUpperCase()"
									formControlName="train_no" type="number" />
							</td>
							
							<td>
								<input tabIndex="{{isTenantIndian ? 19 : 21}}" tabindex="{{isTenantIndian ? 19 : 21}}" class="input-ui inv-date-input text-center" oninput="this.value = this.value.toUpperCase()"
									formControlName="class" type="text" />
							</td>
						</ng-container>
						<td>
							<input tabIndex="{{(isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail') ? 20 :
								(isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'rail') ? 17 : 
								(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail') ? 22 : 19}}" style="min-width: -webkit-fill-available;"
								type="date" class="field-input text-center" 
								name="departure_date" formControlName="departure_date" />
						</td>
						
						<td>
							<input tabIndex="{{(isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail') ? 21 :
							(isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'rail') ? 18 : 
							(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail') ? 23 : 20}}" 
							style="min-width: -webkit-fill-available;" oninput="this.value = this.value.toUpperCase()"
							    type="text" class="field-input text-center" formControlName="departure_station" name="departure_station" />
						</td>

						<td  *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'rail' ">
							<input tabIndex="{{isTenantIndian ? 19 : 21}}" class="input-ui inv-date-input text-center" oninput="this.value = this.value.toUpperCase()"
								formControlName="boarding_point" name="boarding_point" type="text"/>
						</td>

						<td>
							<input tabIndex="{{(isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail') ? 22 :
							(isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'rail') ? 20 : 
							(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail') ? 24 : 22}}" style="min-width: -webkit-fill-available;"
							    type="date" class="field-input text-center"
								name="arrival_date" formControlName="arrival_date"/>
						</td>

						<td>
							<input tabIndex="{{(isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail') ? 23 :
								(isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'rail') ? 21 : 
								(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail') ? 25 : 23}}" style="min-width: -webkit-fill-available;" oninput="this.value = this.value.toUpperCase()"
								type="text" class="field-input text-center" formControlName="arrival_station" name="arrival_station" />
						</td>

						<ng-container *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'rail' ">
							<td>
								<input tabIndex="{{isTenantIndian ? 22 : 24}}" class="input-ui inv-date-input text-center" oninput="this.value = this.value.toUpperCase()"
									formControlName="arrival_point" name="arrival_point" type="text"/>
							</td>
							<td>
								<input tabIndex="{{isTenantIndian ? 23 : 25}}" class="input-ui inv-date-input text-center" oninput="this.value = this.value.toUpperCase()"
									formControlName="journey_type" name="journey_type" type="text"/>
							</td>
						</ng-container>
						

						<td *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'car' ">
							<input tabIndex="{{isTenantIndian ? 24 : 26}}" class="input-ui inv-date-input text-center" oninput="this.value = this.value.toUpperCase()"
								formControlName="journey_distance" name="journey_distance" type="number"/>
						</td>
						
					</tr>
				</tbody>
			</table>

			<form [formGroup]="miscellaneousInvoiceService.travellerForm" *ngIf="miscellaneousInvoiceService.travellerList != undefined">

				<div class="traveller-outer-container"
					[ngClass]="maximizedSection ? 'traveller-outer-container section-maximized ' : 'traveller-outer-container  '">
					
						<div class="d-flex align-items-center justify-content-between" [ngClass]="maximizedSection ? ' section-head-full' : 'section-head text-grey'">
							<span [ngClass]="maximizedSection ? 'section-head text-black ' : 'section-head text-grey'">{{miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 'Room' : 'Traveller'}} Information</span><span (click)="minimizeSection()" *ngIf="maximizedSection" class="minimize"><i
								class="fas fa-compress-arrows-alt mr-3"></i>Minimize</span>
						</div>
					<div  class="traveller-section "
						[ngClass]="maximizedSection ? ' maximized-modal' : 'traveller-section'">
			
						<div class="traveller-col miscellaneousInvoiceServiceContainer">
							<table style="table-layout: fixed;" class="table table-hovered table-bordered w-100">
								<thead>
									<tr>
										<th style="width: 50px;">#</th>
										<th *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'">Room Name</th>
										<th *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'">Start Date</th>
										<th *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'">End Date</th>
										<th class="text-left" *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'rail'">Ref</th>
										<th *ngIf="corporate_employee=='false'" class="text-left" >Lead Traveller</th>
										<th *ngIf="corporate_employee=='true'" class="text-left" >Lead Traveller</th>

										<th *ngIf="corporate_employee=='true'" class="text-left" >E Code</th>
										<th *ngIf="supplier_trans=='true'" class="text-left" style="width: 130px;">Payable Trans. No.</th>
										<th *ngIf="supplier_trans=='true'" class="text-center"  style="width: 86px;">Payable Date</th>
										<ng-container *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail'">
											<th class="text-left">Coach</th>
											<th class="text-left">Seat No.</th>
											<th class="text-left">BERTH</th>
										</ng-container>
										<th>Pay Type</th>
										<th>Card</th>
										<th>Basic</th>
										<th>OT</th>
										<th>Total</th>
										<th style=" width: 150px;">Action</th>
									</tr>
								</thead>
								<tbody formArrayName="travellerList">
									<ng-container *ngFor="let travellers of DynamicFormControls.controls; let i = index">
										<ng-container [formGroupName]="i">
											<tr class=" _table_row">
												<td style="width: 30px; " class="text-center">
													{{i+1}}
												</td>
			
												<td *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'">
													<!-- <input
														tabIndex="{{i*(travellers.value.totalIndexCount)+3+(sectorService.lastSectorTwoIndex)}}"
														attr.tabIndex="{{i*(travellers.value.totalIndexCount)+3+(sectorService.lastSectorTwoIndex)}}"
														oninput="this.value = this.value.toUpperCase()"
														formControlName="txtPassengerName"
														class="traveller-input-field {{travellers.value.travellerStatus}}_input"
														type="text" /> -->
													<input 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+1}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+1}}" 
													oninput="this.value = this.value.toUpperCase()"
													type="text" style="min-width: -webkit-fill-available;" type="text"
													(focus)="maximizeSection(i,1,false)"
													class="field-input w-100" formControlName="room_name" />
												</td>

												<td *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'"> 
													<input 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+2}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+2}}" 
													type="date" style="min-width: -webkit-fill-available;" 
													(focus)="maximizeSection(i,2,false)" (blur)="invoiceMode != 'view' && calculateServiceChargeClientWise()"
													class="field-input w-100" formControlName="service_started" />
												</td>
												
												<td *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'">
													<input 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+3}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+3}}" 
													type="date" style="min-width: -webkit-fill-available;" 
													(focus)="maximizeSection(i,3,false)" (blur)="invoiceMode != 'view' && calculateServiceChargeClientWise()"
													class="field-input w-100" formControlName="service_ended" />
												</td>

												<td *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'rail'">
													<input 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 4 : 1)}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 4 : 1)}}"
													oninput="this.value = this.value.toUpperCase()"
													(focus)="maximizeSection(i,4,false)"
													type="text" class="w-100" maxlength="50" formControlName="reference_number"/>
												</td>
			
												<td *ngIf="corporate_employee=='false'">
													<input 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 5 : 
													miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 1 : 2)}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 5 : 
													miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 1 : 2)}}"
													oninput="this.value = this.value.toUpperCase()"
													(focus)="maximizeSection(i,5,false)"
													type="text" class="w-100" formControlName="lead_pax_name"/>
												</td>

												<td *ngIf="corporate_employee=='true'">
													<div class="autocomplete-container">
														<div class="w-100" tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 5 : 
														miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 1 : 2)}}"  
														attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 5 : 
														miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 1 : 2)}}"
														(focus)="focusCorporateEmployeeAc(i,5,false)" >
														<input #corporateEmployeeAc
														type="text" oninput="this.value = this.value.toUpperCase()"
														name="lead_pax_name"
														formControlName="lead_pax_name"
														(focus)="maximizeSection(i,5,false)"
													 (input)="getCorporateEmployee($event, i)"
													 [nzAutocomplete]="employeeAutoComplete"
													 maxlength="70" 
													 class="w-100"
													/>
												</div>
												<nz-autocomplete #employeeAutoComplete [nzBackfill]="true" (selectionChange)="selectEmployee($event, i)" >
													<nz-auto-option class="colr-1" *ngFor="let item of employeeArr" [nzValue]="item" [nzLabel]="item.account_name " >
														<a innerHTML='<span class="colr-1">{{item?.account_name}}
																({{item?.account_code}})</span>'>
														</a>                           
													</nz-auto-option>                      
											</nz-autocomplete>
												</div>
											</td>

											<td *ngIf="corporate_employee=='true'"><input
												oninput="this.value = this.value.toUpperCase()"
												formControlName="e_code" maxlength="70" disabled
												class="w-100"
												type="text" 
												/>
										</td>

										<td *ngIf="supplier_trans=='true'">
											<input 
											oninput="this.value = this.value.toUpperCase()"
											(focus)="maximizeSection(i,4,false)"
											type="text" class="w-100" maxlength="50" formControlName="payable_transaction_code"/>
										</td>

										<td *ngIf="supplier_trans=='true'"> 
													<input 
													type="date" style="width: 86px!important;"  
													(focus)="maximizeSection(i,2,false)" 
													class="field-input w-100" formControlName="payable_date" />
												</td>

												<ng-container *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail'">
													<td>
														<input 
														tabIndex="{{i*totalInputInTravellers+staticInputCount+2}}"  
														attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+2}}"
														oninput="this.value = this.value.toUpperCase()"
														type="text" class="w-100" formControlName="coach"/>
													</td>

													<td>
														<input 
														tabIndex="{{i*totalInputInTravellers+staticInputCount+3}}"  
														attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+3}}"
														oninput="this.value = this.value.toUpperCase()"
														type="text" class="w-100" formControlName="seat_no"/>
													</td>

													<td>
														<select 
															tabIndex="{{i*totalInputInTravellers+staticInputCount+4}}"  
															attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+4}}"
															class="select-input w-100 text-center" formControlName="berth">
																<option value="">Select</option>
																<option value="lower">LOWER</option>
																<option value="middle">MIDDLE</option>
																<option value="upper">UPPER</option>
																<option value="side_upper">SIDE UPPER</option>
																<option value="side_lower">SIDE LOWER</option>
													     </select>
													</td>

												</ng-container>

												<td>
													<select 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 6 : 
													miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 5 : 3)}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 6 : 
													miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 5 : 3)}}"
													(focus)="maximizeSection(i,6,false)"
													class="select-input w-100" formControlName="pay_type" (change)="handlePayTypeChange(i)">
														<option value="">Select</option>
														<option value="OUR_CC">Our CC</option>
														<option value="AGENT_CC">Agent CC</option>
													</select>
												</td>
												<td>
													<div class="autocomplete-container" 
													    tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 7 : 
															miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 6 : 4)}}"  
														attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 7 : 
															miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 6 : 4)}}">
														<div class="input-container">
															<input 
																type="text" #clientAC oninput="this.value = this.value.toUpperCase()" 
																class="autocomplete-container" name="ledger_card_name" 
																formControlName="ledger_card_name"
																[attr.disabled]="travellers.value.pay_type != 'OUR_CC' ? true : null"
																(input)="filterGeneralAccounts($event,i)"
																[nzAutocomplete]="ledgerCardAc"
															/>
									
															<nz-autocomplete #ledgerCardAc [nzBackfill]="true"  (selectionChange)="selectGeneralAccount($event,i)">
																<nz-auto-option  class="colr-1" *ngFor="let item of generalFilteredData" [nzValue]="item" [nzLabel]="item.name">
																	<a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
																</nz-auto-option>   
															</nz-autocomplete>
														</div>	
													</div>
												</td>
			
												<td>
													<input type="text" class="w-100 text-center" disabled value="{{travellers.value.client_basic}}" />
												</td>
												<td>
													<input type="text" class="w-100 text-center" disabled  value="{{travellers.value.client_taxes}}"/>
												</td>
												<td>
													<input type="text" class="w-100 text-center" disabled  value="{{travellers.value.client_net_amount}}"/>
												</td>
			
												<td class="hilight">
													<span class="action-col">
														<button title="Maximize" class=" custom-table-btn mr-2 bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button"
														(click)="maximizeSection(i,staticInputCount == calculateStaticCount(miscellaneousInvoiceService.invoiceForm.value.invoice_type) ? 2 : 1)" id="edit_traveller_{{i}}_btn">
														<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-edit-btn-fb.svg" alt="" width="20px">
													</button>
													<button title="Delete" class=" custom-table-btn mr-2 bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button"
													(click)="miscellaneousInvoiceService.removeTraveller(i)" >
														<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-delete.svg" alt="" width="20px">
													</button>
														<!-- <a (click)="maximizeSection(i,staticInputCount == 13 ? 2 : 1)" href=" javascript:void(0)"><i title="Edit"
																class="fas fa-user-edit"></i></a> -->
														<!-- <a (click)="miscellaneousInvoiceService.removeTraveller(i)" href="javascript:void(0)"><i title="Delete"
																class="fa fa-trash" aria-hidden="true"></i></a> -->
													</span>
												</td>
											</tr>
			
											<ng-container *ngIf="showTravellerIndex == i">

												<tr>
													<td colspan="11">
														<span class="section-head">Fare Breakup</span>
														<table class="table table-hovered table-bordered fare-breakup-table mb-0">
															<thead>
																<tr>
																	<th></th>
																	<th class="client">Client</th>
																	<th class="supplier">Supplier </th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td class="text-right">
																		Basic
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 8 : 
																     		miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 7 : 5)}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 7 : 
																	    	miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 7 : 5)}}"
																		formControlName="client_basic"
																			(input)="replicateClientAmount(i,'client_basic','supplier_basic')" class="traveller-input-field  text-right"
																			type="text" />
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 9 : 
																	    	miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 8 : 6)}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 9 : 
																	    	miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 8 : 6)}}"
																		formControlName="supplier_basic"
																			(input)="isTenantIndian ? calculateNetAmount(i) : replicateClientAmount(i,'supplier_basic','client_basic')" class="traveller-input-field  text-right"
																			type="text"  />
																	</td>
																</tr>
									
																<tr>
																	<td class="text-right">
																		Taxes
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 10 : 
																		    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 9 : 7)}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 10 : 
																	    	miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 9 : 7)}}"
																		 formControlName="client_taxes"
																		 (input)="replicateClientAmount(i,'client_taxes','supplier_taxes')"
																		 class="traveller-input-field  text-right"
																			type="text" />
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 11 : 
																		miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 10 : 8)}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 11 : 
																		miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 10 : 8)}}"
																		formControlName="supplier_taxes"
																		(input)="isTenantIndian ? calculateNetAmount(i) : replicateClientAmount(i,'supplier_taxes','client_taxes')" class="traveller-input-field  text-right"
																			type="text" />
																	</td>
																</tr>
																<tr>
																	<td class="text-right">
																		Commission
																	</td>
																	<td>
																		<div class="three-fields">
																			<select 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 12 : 
																			    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 11 : 9)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 12 : 
																		     	miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 11 : 9)}}"
																			class="inner-select-top w-50"
																				formControlName="client_commission_type"
																				(change)="calculateNetAmount(i)">
																				<option *ngIf="isTenantIndian" value="RB">RB</option>
																				<option *ngIf="isTenantIndian" value="RT">RT</option>
																				<option  value="VL">VL</option>
																			</select>
																			<input 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 13 : 
																			    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 12 : 10)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 13 : 
																		     	miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 12 : 10)}}"
																			style="width: 65px!important;"
																				formControlName="client_commission_value"
																				class="traveller-input-field  text-right w-25" type="text"
																				(input)="calculateNetAmount(i)" />
																			<input 
																			style="width: 120px!important;"
																				formControlName="client_commission_amount"
																				class="traveller-input-field  text-right w-25" type="text" readonly />
																		</div>
																	</td>
																	<td>
																		<div class="three-fields">
																			<select 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 14 : 
																			    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 13 : 11)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 14 : 
																		     	miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 13 : 11)}}"
																			class="inner-select-top w-50"
																				formControlName="supplier_commission_type"
																				(change)="calculateNetAmount(i)">
																				<option *ngIf="isTenantIndian" value="RB">RB</option>
																				<option *ngIf="isTenantIndian" value="RT">RT</option>
																				<option value="VL">VL</option>
																			</select>
																			<input 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 15 : 
																			    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 14 : 12)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 15 : 
																		     	miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 14 : 12)}}"
																			style="width: 65px!important;"
																				formControlName="supplier_commission_value"
																				class="traveller-input-field  text-right w-25" type="text"
																				(input)="calculateNetAmount(i)" />
																			<input style="width: 120px!important;"
																				formControlName="supplier_commission_amount"
																				class="traveller-input-field  text-right w-25" type="text" readonly />
																		</div>
																	</td>
									
																</tr>
																<tr>
																	<td class="text-right">
																		TDS
																	</td>
																	<td>
																		<div 
																			class="d-flex justify-content-between">
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">TDS On:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 16 : 
																			        miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 15 : 13)}}"  
																		     	attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 16 : 
																		     	    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 15 : 13)}}"
																				class="inner-select-top w-100"
																					(change)="calculateNetAmount(i)" formControlName="client_tds_type">
																					<option value="C" title="Commission">Commission</option>
																					<option value="B" title="Basic">Basic</option>
																				</select>
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">TDS Rate:</label>
																				<input 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 17 : 
																			        miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 16 : 14)}}"  
																		     	attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 17 : 
																		     	    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 16 : 14)}}"
																				 class="traveller-input-field"
																					type="number" formControlName="client_tds_conf"
																					(input)="calculateNetAmount(i)" />
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">Amount</label>
																				<input formControlName="client_tds" readonly
																			class="traveller-input-field  text-right" type="text" />
																			</div>
																		</div>
																	</td>
																	<td>
																		<div 
																			class="d-flex justify-content-between">
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">TDS On:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 18 : 
																			        miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 17 : 15)}}"  
																		     	attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 18 : 
																		     	    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 17 : 15)}}"
																				class="inner-select-top w-100"
																					(change)="calculateNetAmount(i)" formControlName="supplier_tds_type">
																					<option value="C" title="Commission">Commission</option>
																					<option value="B" title="Basic">Basic</option>
																				</select>
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">TDS Rate:</label>
																				<input 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 19 : 
																			        miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 18 : 16)}}"  
																		     	attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 19 : 
																		     	    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 18 : 16)}}"
																				 class="traveller-input-field"
																					type="number" formControlName="supplier_tds_conf"
																					(input)="calculateNetAmount(i)" />
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">Amount</label>
																				<input formControlName="supplier_tds" readonly
																			class="traveller-input-field  text-right" type="text" />
																			</div>
																		</div>
																	</td>
																</tr>

																<tr *ngIf="discount_add=='true'">
																	<td class="text-right">
																		Discount
																	</td>
																	<td>
																		<div class="three-fields">
																			<select 
																			class="inner-select-top w-50"
																				formControlName="client_discount_type"
																				(change)="calculateNetAmount(i)">
																				<option value="VL">VL</option>
																			</select>
																			<input 
																			style="width: 120px!important;"
																				formControlName="client_discount_amount"
																				class="traveller-input-field  text-right w-25" type="text"
																				(input)="calculateNetAmount(i)" />
																		</div>
																	</td>
																	<td>
																		<div class="three-fields">
																			<select 
																			class="inner-select-top w-50"
																				formControlName="supplier_discount_type"
																				(change)="calculateNetAmount(i)">
																				<option value="VL">VL</option>
																			</select>
																			<input 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 15 : 
																			    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 14 : 12)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 15 : 
																		     	miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 14 : 12)}}"
																			style="width: 120px!important;"
																				formControlName="supplier_discount_amount"
																				class="traveller-input-field  text-right w-25" type="text"
																				(input)="calculateNetAmount(i)" />
																		</div>
																	</td>
									
																</tr>
																<!-- service charge 1 -->
																<tr>
																	<td class="text-right" >
																		<div class="d-flex justify-content-between">
																			<div class="align-items-center mr-2">

																				<label class="mb-0" title="">Service Charge 1</label>
																				<div class="d-flex justify-content-between" >
																					<div >hsn</div>
																					<input [ngClass]="((travellers.value.client_cgst!=0 || travellers.value.client_sgst!=0 || travellers.value.client_igst!=0) && (!travellers.value.hsn) && (isGstNumberExist==true))?'validateError':''"
																					class="traveller-input-field  text-right w-50" type="text" formControlName="hsn" oninput="this.value = this.value.toUpperCase()" />
																				</div>
																			</div>

																		</div>
																	</td>
																	<td>
																		<div class="d-flex justify-content-between">
																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Taxable:</label>
																				<select  
																			      	style="width:40px!important"
																				    class="inner-select-top w-50 mr-1" 
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 20 : 
																						miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 19 : 17)}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 20 : 
																						miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 19 : 17)}}"
																					(change)="calculateNetAmount(i)"
																					formControlName="client_sc1_type">
																					<option value="T">T</option>
																					<!-- <option value="N">N</option> -->
																			    </select>
																			</div>
																			
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">AMT:</label>
																				<input 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 21 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 20 : 18)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 21 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 20 : 18)}}"
																				formControlName="client_sc1_amount"
																					(input)="calculateNetAmount(i)"
																					class="traveller-input-field  text-right w-50" type="text" />
																			</div>

																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">GST On:</label>
																					<select 
																					class="inner-select-top w-100"
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+22}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+22}}"
																						(change)="calculateNetAmount(i)" formControlName="client_gst_type">
																						<option title= 'S' value="S">SC1</option>
																						<option disabled title= 'B' value="B">B</option>
																						<option disabled title= 'T' value="T">OT</option>
																					</select>
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">GST Rate:</label>
																					<select 
																						class="traveller-input-field"
																						tabIndex="{{i*totalInputInTravellers+staticInputCount+23}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+23}}"
																						type="number" formControlName="client_gst_conf"
																						(change)="calculateNetAmount(i)" >
																						<option value= 0 >0</option>
																						<option value= 5 >5</option>
																						<option value=12 >12</option>
																						<option value=18 >18</option>
																						<option value=28 >28</option>
																					</select>
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="client_cgst" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="client_sgst" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="client_igst" class="traveller-input-field"
																						type="number" />
																				</div>
																		    </ng-container>

																		</div>
																	</td>
																	<td>
																		<div class="d-flex justify-content-between">
																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Taxable:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 24 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 22 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 21 : 19)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 24 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 22 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 21 : 19)}} "
																					style="width:40px!important"
																				    class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																					formControlName="supplier_sc1_type">
																					<option value="T">T</option>
																					<option value="N">N</option>
																				</select>
																			</div>
																			
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">AMT:</label>
																				<input 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 25 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 23 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 22 : 20)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 25 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 23 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 22 : 20)}}"
																					formControlName="supplier_sc1_amount"
																					(input)="calculateNetAmount(i)"
																					class="traveller-input-field  text-right w-50" type="text" />
																			</div>

																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">GST On:</label>
																					<select 
																					class="inner-select-top w-100"
																				     	tabIndex="{{i*totalInputInTravellers+staticInputCount+26}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+26}}"
																						(change)="calculateNetAmount(i)" formControlName="supplier_gst_type">
																						<option title= 'S' value="S">SC1</option>
																						<option disabled title= 'B' value="B">B</option>
																						<option disabled title= 'T' value="T">OT</option>
																					</select>
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">GST Rate:</label>
																					<select 
																					    tabIndex="{{i*totalInputInTravellers+staticInputCount+27}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+27}}"
																						class="traveller-input-field" type="number"
																						formControlName="supplier_gst_conf" 
																						(change)="calculateNetAmount(i)" >
																						<option value= 0 >0</option>
																						<option value= 5 >5</option>
																						<option value=12 >12</option>
																						<option value=18 >18</option>
																						<option value=28 >28</option>
																					</select>
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="supplier_cgst"
																						class="traveller-input-field" type="number" />
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="supplier_sgst"
																						class="traveller-input-field" type="number" />
																				</div>
																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="supplier_igst"
																						class="traveller-input-field" type="number" />
																				</div>
																		    </ng-container>
																			
																		</div>
																	</td>
																</tr>
									
																<!-- <tr *ngIf="(travellers.value.supplier_sc1_type == 'T' ||
																	travellers.value.client_sc1_type == 'T') && miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'hotel' && miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'car' && isTenantIndian">
																	<td class="text-right">
																		GST
																	</td>
																	
																	<td>
																		<div *ngIf="(travellers.value.client_sc1_type == 'T')"
																			class="d-flex justify-content-between">
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">GST On:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 23 : 21)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 23 : 21)}}"
																				class="inner-select-top w-100"
																					(change)="calculateNetAmount(i)" formControlName="client_gst_type">
																					<option title= 'S' value="S">SC1</option>
																					<option  title= 'B' value="B">B</option>
																					<option  title= 'T' value="T">OT</option>
																					</select>
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">GST Rate:</label>
																				<input 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 24 : 22)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 24 : 22)}}"
																				 class="traveller-input-field"
																					type="number" formControlName="client_gst_conf"
																					(input)="calculateNetAmount(i)" />
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">CGST</label>
																				<input disabled formControlName="client_cgst" class="traveller-input-field"
																					type="number" />
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0 ml-1 mr-1">SGST</label>
																				<input disabled formControlName="client_sgst" class="traveller-input-field"
																					type="number" />
																			</div>
																			<div style="width:24%" class="align-items-center">
																				<label class="mb-0">IGST</label>
																				<input disabled formControlName="client_igst" class="traveller-input-field"
																					type="number" />
																			</div>
																		</div>
																		
																	</td>
																	<td *ngIf="(travellers.value.supplier_sc1_type == 'T' )">
																		<div>
																			<div class="d-flex justify-content-between">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">GST On:</label>
																					<select 
																					tabIndex="{{i*totalInputInTravellers+staticInputCount + (miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 25 : 23)}}"  
																				    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount + (miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 25 : 23)}}"
																					class="inner-select-top w-100"
																						(change)="calculateNetAmount(i)" formControlName="supplier_gst_type">
																						<option title= 'S' value="S">SC1</option>
																						<option title= 'B' value="B">B</option>
																						<option title= 'T' value="T">OT</option>
																					</select>
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">GST Rate:</label>
																					<input 
																					tabIndex="{{i*totalInputInTravellers+staticInputCount + (miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 26 : 24)}}"  
																				    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount + (miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 26 : 24)}}"
																					(input)="calculateNetAmount(i)" 
																						class="traveller-input-field" type="number"
																						formControlName="supplier_gst_conf" />
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="supplier_cgst"
																						class="traveller-input-field" type="number" />
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="supplier_sgst"
																						class="traveller-input-field" type="number" />
																				</div>
																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="supplier_igst"
																						class="traveller-input-field" type="number" />
																				</div>
																			</div>
																		</div>
																	</td>
																</tr> -->


																<tr *ngIf="(travellers.value.supplier_sc1_type == 'T' || travellers.value.client_sc1_type == 'T') && !isTenantIndian">
																	<td class="text-right" >
																		{{vatAlternetname}}
																	</td>
																	<td>
																	    <div *ngIf="(travellers.value.client_sc1_type == 'T')"
																			class="d-flex justify-content-between">
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">{{vatAlternetname}} On:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 24 : 
																				    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 23 : 21)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 24 : 
																				    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 23 : 21)}}"
																				class="inner-select-top w-100"
																					(change)="calculateNetAmount(i)" formControlName="client_vat_type">
																					<option title= 'S' value="S">S</option>
																					<option title= 'B'value="B">B</option>
																					<option title= 'T' value="T">T</option>
																				</select>
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">{{vatAlternetname}} Rate:</label>
																				<input 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 25 : 
																				    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 24 : 22)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 25 : 
																				    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 24 : 22)}}"
																				 class="traveller-input-field"
																					type="number" formControlName="client_vat_conf"
																					(input)="calculateNetAmount(i)" />
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">{{vatAlternetname}} Amount</label>
																				<input disabled formControlName="client_vat_amount" class="traveller-input-field"
																					type="number" />
																			</div>
																		</div>
																	</td>

																	<td *ngIf="(travellers.value.supplier_sc1_type == 'T' )">
																		<div>
									
																			<div class="d-flex justify-content-between">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">{{vatAlternetname}} On:</label>
																					<select 
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 26 : 
																						miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 25 : 23)}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 26 : 
																						miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 25 : 23)}}"
																					class="inner-select-top w-100"
																						(change)="calculateNetAmount(i)" formControlName="supplier_vat_type">
																						<option title= 'S' value="S">S</option>
																						<option title= 'B' value="B">B</option>
																						<option title= 'T' value="T">T</option>
																					</select>
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">{{vatAlternetname}} Rate:</label>
																					<input 
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 27 : 
																						miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 26 : 24)}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 27 : 
																						miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 26 : 24)}}"
																					(input)="calculateNetAmount(i)" 
																						class="traveller-input-field" type="number"
																						formControlName="supplier_vat_conf" />
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">{{vatAlternetname}} AMOUNT</label>
																					<input disabled formControlName="supplier_vat_amount"
																						class="traveller-input-field" type="number" />
																				</div>
																			</div>
																		</div>
																	</td>
																</tr>
																<!-- service charge 2 -->
																<tr>
																	<td class="text-right">
																		<div class="d-flex justify-content-between">
																			<div class="align-items-center mr-2">

																				<label class="mb-0" title="">Service Charge 2</label>
																				<ng-container *ngIf="isTenantIndian">

																				<div class="d-flex justify-content-between" >
																					<div >hsn</div>
																					<input [ngClass]="((travellers.value.client_cgst_2!=0 || travellers.value.client_sgst_2!=0 || travellers.value.client_igst_2!=0 || (travellers.value.client_sc2_taxable=='T' && travellers.value.client_sc2_amount!=0)) && (!travellers.value.hsn_2)&&(isGstNumberExist==true))?'validateError':''"
																					class="traveller-input-field  text-right w-50" type="text" formControlName="hsn_2" oninput="this.value = this.value.toUpperCase()" />
																				</div>
																			</ng-container>
																			</div>

																		</div>
																	</td>
																	<td>
																		<div class="d-flex justify-content-between">
																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Taxable:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 28 : 
																			    	miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 27 : 25)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 28 : 
																				    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 27 : 25)}}"
																				style="width:40px!important" 
																					class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																					formControlName="client_sc2_taxable">
																					<option *ngIf="isTenantIndian" value="T">T</option>
																					<option value="N">N</option>
																				</select>
																			</div>

																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Type:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 29 : 
																			    	miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 28 : 26)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 29 : 
																				    miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 28 : 26)}}"
																				style="width:40px!important"
																					class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																					formControlName="client_sc2_type">
																					<option disabled value="OT">OT</option>
																					<option value="B">B</option>
																				</select>
																			</div>

																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">AMT:</label>
																				<input 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 30 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 29 : 27)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 30 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 29 : 27)}}"
																					formControlName="client_sc2_amount"
																						(input)="calculateNetAmount(i)"
																						class="traveller-input-field  text-right w-50" type="text" />

																			</div>
																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">GST On:</label>
																					<select class="inner-select-top w-100"
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+31}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+31}}"
																						(change)="calculateNetAmount(i)" formControlName="client_gst_type_2">
																						<option disabled title= 'S'value="S">SC1</option>
																						<option  title= 'B' value="B">B</option>
																						<option  disabled title= 'T' value="T">OT</option>
																					</select>
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">GST Rate:</label>
																					<select 
																						class="traveller-input-field" [attr.disabled]="travellers.value.client_sc2_taxable == 'T'?true:null"
																						tabIndex="{{i*totalInputInTravellers+staticInputCount+32}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+32}}"
																						type="number" formControlName="client_gst_conf_2"
																						(change)="calculateNetAmount(i)" >
																						<option value= 0 >0</option>
																						<option value= 5 >5</option>
																						<option value=12 >12</option>
																						<option value=18 >18</option>
																						<option value=28 >28</option>
																					</select>
																				
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="client_cgst_2" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="client_sgst_2" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="client_igst_2" class="traveller-input-field"
																						type="number" />
																				</div>
																			</ng-container>
																		</div>
																	</td>
																	<td>
																		<div class="d-flex justify-content-between">
																			<!-- <div class="align-items-center mr-2">
																				<label class="mb-0" title="">Taxable:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 33 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 31 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 30 : 28)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 33 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 31 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 30 : 28)}}"
																				style="width:40px!important"
																					class="inner-select-top w-50 mr-1"  (change)="calculateNetAmount(i)"
																					formControlName="supplier_sc2_taxable">
																					<option value="T">T</option>
																					<option value="N">N</option>
																				</select>
																			</div> 	 	 -->

																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Type:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 34 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 32 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 31 : 29)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 34 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 32 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 31 : 29)}}"
																				style="width:40px!important"
																					class="inner-select-top w-50 mr-1"  (change)="calculateNetAmount(i)"
																					formControlName="supplier_sc2_type">
																					<!-- <option value="OT">OT</option> -->
																					<option value="B">B</option>
																				</select>
																			</div>

																			
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">AMT:</label>
																				<input 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 35 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 33 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 32 : 30)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 35 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 33 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 32 : 30)}}"
																					formControlName="supplier_sc2_amount"
																					(input)="calculateNetAmount(i)"
																					class="traveller-input-field  text-right w-50" type="text" />
																			</div>

																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">GST On:</label>
																					<select 
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+36}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+36}}"
																					class="inner-select-top w-100" 
																						(change)="calculateNetAmount(i)" formControlName="supplier_gst_type_2">
																						<option disabled title= 'S' value="S">SC1</option>
																						<option title= 'B' value="B">B</option>
																						<option disabled title= 'T' value="T">OT</option>
																					</select>
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">GST Rate:</label>
																					<select 
																						class="traveller-input-field" [attr.disabled]="travellers.value.supplier_sc2_taxable == 'T'?true:null"
																						tabIndex="{{i*totalInputInTravellers+staticInputCount+37}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+37}}"
																						type="number" formControlName="supplier_gst_conf_2"
																						(change)="calculateNetAmount(i)" >
																						<option value= 0 >0</option>
																						<option value= 5 >5</option>
																						<option value=12 >12</option>
																						<option value=18 >18</option>
																						<option value=28 >28</option>
																					</select>
																				
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="supplier_cgst_2" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="supplier_sgst_2" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="supplier_igst_2" class="traveller-input-field"
																						type="number" />
																				</div>
																			</ng-container>
																				
																		</div>
																	</td>
																</tr>
																<!-- service charge 3 -->
																<tr>
																	<td class="text-right">
																		<div class="d-flex justify-content-between">
																			<div class="align-items-center mr-2">

																				<label class="mb-0" title="">Service Charge 3</label>
																				<ng-container *ngIf="isTenantIndian">
																				<div class="d-flex justify-content-between" >

																					<div >hsn</div>
																					<input [ngClass]="((travellers.value.client_cgst_3!=0 || travellers.value.client_sgst_3!=0 || travellers.value.client_igst_3!=0 || (travellers.value.client_sc3_taxable=='T' && travellers.value.client_sc3_amount!=0)) && (!travellers.value.hsn_3)&&(isGstNumberExist==true))?'validateError':''"
																					class="traveller-input-field  text-right w-50" type="text" formControlName="hsn_3" oninput="this.value = this.value.toUpperCase()" />
																				</div>
																				</ng-container>
																			</div>

																		</div>
																	</td>
																	<td>
																		<div class="d-flex justify-content-between">
																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Taxable:</label>
																				<select
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 38 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 34 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 33 : 31)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 38 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 34 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 33 : 31)}}"
																				style="width:40px!important" 
																					class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																					formControlName="client_sc3_taxable">
																					<option *ngIf="isTenantIndian" value="T">T</option>
																					<option value="N">N</option>
																				</select>
																			</div>

																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Type:</label>
																				<select
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 39 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 35 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 34 : 32)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 39 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 35 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 34 : 32)}}"
																				style="width:40px!important"
																					class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																					formControlName="client_sc3_type">
																					<option value="OT">OT</option>
																					<option disabled value="B">B</option>
																				</select>
																			</div>

																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">AMT:</label>
																				<input 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 40 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 36 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 35 : 33)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 40 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 36 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 35 : 33)}}"
																				formControlName="client_sc3_amount"
																					(input)="calculateNetAmount(i)"
																					class="traveller-input-field  text-right w-50" type="text" />
																			</div>
																			
																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">GST On:</label>
																					<select 
																					class="inner-select-top w-100" 
																					    tabIndex="{{i*totalInputInTravellers+staticInputCount+41}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+41}}"
																						(change)="calculateNetAmount(i)" formControlName="client_gst_type_3">
																						<option disabled title= 'S' value="S">SC1</option>
																						<option disabled title= 'B' value="B">B</option>
																						<option  title= 'T' value="T">OT</option>
																					</select>
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">GST Rate:</label>
																					<select 
																						class="traveller-input-field" [attr.disabled]="travellers.value.client_sc3_taxable == 'T'?true:null"
																						tabIndex="{{i*totalInputInTravellers+staticInputCount+42}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+42}}"
																						type="number" formControlName="client_gst_conf_3"
																						(change)="calculateNetAmount(i)" >
																						<option value= 0 >0</option>
																						<option value= 5 >5</option>
																						<option value=12 >12</option>
																						<option value=18 >18</option>
																						<option value=28 >28</option>
																					</select>
																				
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="client_cgst_3" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="client_sgst_3" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="client_igst_3" class="traveller-input-field"
																						type="number" />
																				</div>
																			</ng-container>
																		</div>
																	</td>
																	<td>
																		<div class="d-flex justify-content-between">
																			<!-- <div class="align-items-center mr-2">
																				<label class="mb-0" title="">Taxable:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 43 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 37 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 36 : 34)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 43 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 37 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 36 : 34)}}"
																				style="width:40px!important" 
																					class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																					formControlName="supplier_sc3_taxable">
																					<option value="T">T</option>
																					<option value="N">N</option>
																				</select>
																			</div> -->

																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Type:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 44 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 38 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 37 : 35)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 44 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 38 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 37 : 35)}}"
																				style="width:40px!important"
																					class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																					formControlName="supplier_sc3_type">
																					<option value="OT">OT</option>
																					<!-- <option value="B">B</option> -->
																				</select>
																			</div>
																			
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">AMT:</label>
																				<input 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 45 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 39 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 38 : 36)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+((isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 45 : 
																					(!isTenantIndian && miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') ? 39 : 
																					miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail' ? 38 : 36)}}"
																				formControlName="supplier_sc3_amount"
																					(input)="calculateNetAmount(i)"
																					class="traveller-input-field  text-right w-50" type="text" />
																			</div>

																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">GST On:</label>
																					<select 
																					class="inner-select-top w-100" 
																					    tabIndex="{{i*totalInputInTravellers+staticInputCount+46}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+46}}"
																						(change)="calculateNetAmount(i)" formControlName="supplier_gst_type_3">
																						<option disabled title= 'S' value="S">SC1</option>
																						<option disabled title= 'B' value="B">B</option>
																						<option title= 'T'value="T">OT</option>
																					</select>
																				</div>

																				<div style="width:24%"   class="align-items-center mr-2">
																					<label class="mb-0">GST Rate:</label>
																					<select [attr.disabled]="travellers.value.supplier_sc3_taxable == 'T'?true:null"
																					    tabIndex="{{i*totalInputInTravellers+staticInputCount+47}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+47}}"
																						class="traveller-input-field" 
																						type="number" formControlName="supplier_gst_conf_3"
																						(change)="calculateNetAmount(i)" >
																						<option value= 0 >0</option>
																						<option value= 5 >5</option>
																						<option value=12 >12</option>
																						<option value=18 >18</option>
																						<option value=28 >28</option>
																					</select>
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="supplier_cgst_3" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="supplier_sgst_3" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="supplier_igst_3" class="traveller-input-field"
																						type="number" />
																				</div>
																			</ng-container>
																		</div>
																	</td>
																</tr>

																<tr *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail'">
																	<td class="text-right">
																		Insurance
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+39}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+39}}"
																		 formControlName="client_travel_insurance" (input)="replicateClientAmount(i,'client_travel_insurance','supplier_travel_insurance')"
																		 class="traveller-input-field  text-right" type="text" />
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+40}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+40}}"
																		formControlName="supplier_travel_insurance"
																		(input)="calculateNetAmount(i)" class="traveller-input-field  text-right"
																		type="text" />
																	</td>
																</tr>

																<tr *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'rail'">
																	<td class="text-right">
																		Meal Charges
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+41}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+41}}"
																		 formControlName="client_meal_charges"
																		 (input)="replicateClientAmount(i,'client_meal_charges','supplier_meal_charges')"
																		 class="traveller-input-field  text-right" type="text" />
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+42}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+42}}"
																		formControlName="supplier_meal_charges"
																		(input)="calculateNetAmount(i)" class="traveller-input-field  text-right"
																		type="text" />
																	</td>
																</tr>

																<tr *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'car'">
																	<td class="text-right">
																		Toll Charges
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+41}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+41}}"
																		 formControlName="client_toll_charges"
																		 (input)="replicateClientAmount(i,'client_toll_charges','supplier_toll_charges')"
																		 class="traveller-input-field  text-right" type="text" />
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+42}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+42}}"
																		formControlName="supplier_toll_charges"
																		(input)="calculateNetAmount(i)" class="traveller-input-field  text-right"
																		type="text" />
																	</td>
																</tr>

																<tr *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'car'">
																	<td class="text-right">
																		Parking Charges
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+41}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+41}}"
																		 formControlName="client_parking_charges"
																		 (input)="replicateClientAmount(i,'client_parking_charges','supplier_parking_charges')"
																		 class="traveller-input-field  text-right" type="text" />
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+42}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+42}}"
																		formControlName="supplier_parking_charges"
																		(input)="calculateNetAmount(i)" class="traveller-input-field  text-right"
																		type="text" />
																	</td>
																</tr>

																<tr *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'car'">
																	<td class="text-right">
																		Permit Charges
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+41}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+41}}"
																		 formControlName="client_permit_charges"
																		 (input)="replicateClientAmount(i,'client_permit_charges','supplier_permit_charges')"
																		 class="traveller-input-field  text-right" type="text" />
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+42}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+42}}"
																		formControlName="supplier_permit_charges"
																		(input)="calculateNetAmount(i)" class="traveller-input-field  text-right"
																		type="text" />
																	</td>
																</tr>

																<tr *ngIf="miscellaneousInvoiceService.invoiceForm.value.transaction_method == 'purchase'">
																	<td class="text-right">
																		XXL Charges.
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+43}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+43}}"
																		formControlName="client_xxl_charges"
																		(input)="replicateClientAmount(i,'client_xxl_charges','supplier_xxl_charges')"
																		class="traveller-input-field  text-right" type="text" />
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+44}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+44}}"
																		formControlName="supplier_xxl_charges"
																		(input)="calculateNetAmount(i)" class="traveller-input-field  text-right"
																		type="text" />
																	</td>
																</tr>

																<tr *ngIf="miscellaneousInvoiceService.invoiceForm.value.transaction_method == 'purchase'">
																	<td class="text-right">
																		RAF Charges.
																	</td>

																	<td>
																		<div class="d-flex justify-content-between">
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">Amount</label>
																				<input 
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+45}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+45}}"
																					formControlName="client_raf_charges" (input)="calculateNetAmount(i)"
																					class="traveller-input-field  text-right" type="text" 
																				/>
																			</div>

																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="client_cgst_on_raf" class="traveller-input-field" />
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="client_sgst_on_raf" class="traveller-input-field"
	/>
																				</div>
																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="client_igst_on_raf" class="traveller-input-field" />
																				</div>
																			</ng-container>

																			<ng-container *ngIf="!isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">{{vatAlternetname}}</label>
																					<input disabled formControlName="client_vat_on_raf" class="traveller-input-field" />
																				</div>
																			</ng-container>
																			
																		</div>
																	</td>

																	<td>
																		<div class="d-flex justify-content-between">
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">Amount</label>
																				<input 
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+46}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+46}}"
																					formControlName="supplier_raf_charges" (input)="calculateNetAmount(i)"
																					class="traveller-input-field  text-right" type="text" 
																				/>
																			</div>

																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="supplier_cgst_on_raf" class="traveller-input-field" />
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="supplier_sgst_on_raf" class="traveller-input-field"
	/>
																				</div>
																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="supplier_igst_on_raf" class="traveller-input-field" />
																				</div>
																			</ng-container>

																			<ng-container *ngIf="!isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">{{vatAlternetname}}</label>
																					<input disabled formControlName="supplier_vat_on_raf" class="traveller-input-field" />
																				</div>
																			</ng-container>

																		</div>
																	</td>
																</tr>
									
																<tr>
																	<td class="text-right">
																		Net Amount
																	</td>
																	<td>
																		<input formControlName="client_net_amount" readonly
																			class="traveller-input-field  text-right" type="text" />
																	</td>
																	<td>
																		<input formControlName="supplier_net_amount" readonly
																			class="traveller-input-field  text-right" type="text" />
																	</td>
																</tr>
									
															</tbody>
														</table>
													</td>
													
												</tr>
												<tr>
													<td colspan="6">
														<div class="d-flex justify-content-center align-items-center mt-3">
															<!-- <button 
															tabIndex="{{i*totalInputInTravellers+staticInputCount+34}}"  
															attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+34}}"
															type="button" 
															class="btn-style mb-2 mr-2"
															*ngIf="showTravellerIndex > 0"
															(click)="moveToPreviousTraveller(showTravellerIndex)"
															>
																Previous Room
															</button> -->
															<button 
															tabIndex="{{i*totalInputInTravellers+staticInputCount+48}}"  
															attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+48}}"
															type="button" 
															class="btn-style mb-2 mr-2"
															*ngIf="showTravellerIndex < DynamicFormControls.controls.length - 1"
															(click)="openReplicateModal($event,i)"
															>
																Next Row
															</button>
				
															<button 
															tabIndex="{{i*totalInputInTravellers+staticInputCount+(showTravellerIndex < DynamicFormControls.controls.length - 1 ? 49 : 48)}}"  
															attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(showTravellerIndex < DynamicFormControls.controls.length - 1 ? 49 : 48)}}"
															type="button" 
															class="btn-style bg-green mb-2" 
															(click)="returnToInvoice()">
																Save & Return
															</button>
														</div>
													</td>
													<td colspan="1"></td>
												</tr>
											</ng-container>
			
										</ng-container>
									</ng-container>
								</tbody>
							</table>
						</div>
			
			
					</div>
				</div>
			
			</form>
			
			<button type="button" hidden #openModal data-toggle="modal" data-target="#replicateConfirm"></button>

			<!-- Modal -->
			<div id="replicateConfirm" class="modal fade" role="dialog" data-backdrop="false">
				<div class="modal-dialog">

					<!-- Modal content-->
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">Confirmation</h4>
							<button type="button" class="close" data-dismiss="modal">&times;</button>
						</div>
						<div class="modal-body">
							<p>Do you want to replicate all values to another {{miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 'Room(s)' : 'Traveller(s)'}}?</p>
						</div>
						<div class="modal-footer">
							<button 
								tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +7}}"  
								attr.tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +7}}"
								type="button" 
								class="btn-style" 
								(click)="replicateFormData(showTravellerIndex)" 
								data-dismiss="modal">
								Apply to {{miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' ? 'Room(s)' : 'Traveller(s)'}}
							</button>
							<button 
								tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +8}}"  
								attr.tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +8}}"
								type="button" 
								class="btn-style"  
								(click)="moveToNextTraveller(showTravellerIndex)" 
								data-dismiss="modal">
								No
							</button>
						</div>
					</div>

				</div>
			</div>

			<app-ms-fare-summary></app-ms-fare-summary>
</div>

	<div class="custom-table mt-3">
			<div class="table-actions d-flex justify-content-between">
				<div class="action d-flex flex-column">
					<span *ngIf="miscellaneousInvoiceService.invoiceForm.value.narration_1">
					<span class="text-grey">Narration 1 :</span> {{miscellaneousInvoiceService.invoiceForm.value.narration_1}}
					</span>
					<span *ngIf="miscellaneousInvoiceService.invoiceForm.value.narration_2">
						<span class="text-grey">Narration 2 :</span> {{miscellaneousInvoiceService.invoiceForm.value.narration_2}}
					</span>
					<span *ngIf="miscellaneousInvoiceService.invoiceForm.value.narration_3">
						<span class="text-grey">Narration 3 :</span>  {{miscellaneousInvoiceService.invoiceForm.value.narration_3}}
					</span>
					<span *ngIf="miscellaneousInvoiceService.invoiceForm.value.narration_4">
						<span class="text-grey">Narration 4 :</span>  {{miscellaneousInvoiceService.invoiceForm.value.narration_4}}
					</span>
				</div>
				<!-- <span></span> -->
				<div class="subtotal">
					<div class="total-box">
						<span class="title-type">Total Client Amount</span>
						<span class="result-type" *ngIf="miscellaneousInvoiceService.totalFareModel && miscellaneousInvoiceService.totalFareModel.length > 0 && miscellaneousInvoiceService.totalFareModel[0].client_net_amount">
							{{miscellaneousInvoiceService.totalFareModel[0].client_net_amount | currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
						</span>
					</div>
	
					<ng-container *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' && isTenantIndian">
						<div class="total-box ">
							<div class="w-100 d-flex justify-content-between">
									<div>
										<span class="mb-0 title-type">TCS ON</span>
									</div>
									<div>
										<select class="traveller-input-field" style="width:147px!important; height: 26px!important"  (input)="miscellaneousInvoiceService.calculateFromApi(0)" formControlName="tcs_on">
											<option value="total" title="total">Total</option>
											<option value="basic" title="basic">Basic</option>
										</select>
									</div>
								</div>
							</div>
							
							<div class="total-box ">
							<div class="w-100 d-flex justify-content-between">
								<div>
									<span class="mb-0 title-type">TCS Rate </span>
								</div>
								<div>
									<input class="traveller-input-field text-right" type="text" formControlName="tcs_rate" (input)="miscellaneousInvoiceService.calculateFromApi(0)" />
								</div>
								
							</div>
						
						</div>
						<div class="total-box">
							<div class="w-100 d-flex justify-content-between">
								<div>
									<span class="mb-0 title-type">Amount</span>
								</div>
								<div>
									<input formControlName="tcs_amount" readonly class="traveller-input-field  text-right" type="text" disabled/>
								</div>
							</div>
						</div>
					</ng-container>

					<div class="total-box text-danger ">
						<span class="title-type">Grand Total</span>
						<span class="result-type" *ngIf="miscellaneousInvoiceService.totalFareModel && miscellaneousInvoiceService.totalFareModel.length > 0 && 
							miscellaneousInvoiceService.totalFareModel[0].client_net_amount" >
							{{(miscellaneousInvoiceService.totalFareModel[0].client_net_amount + miscellaneousInvoiceService.invoiceForm.value.tcs_amount) | currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
						</span>
					</div>

					<div class="currencyWrapper mt-5" *ngIf="!isTenantIndian">
						<div class="row">
							<div class="col-4 input-fiel ">
								<select id="flightType" formControlName="sales_currency"  class="input-ui inv-date-input"  >
									<option value="">Select</option>
									<option value="AED">AED</option>
									<option value="CAD">CAD</option>
									<option value="USD">USD</option>
									<option value="INR">INR</option>
									<option value="EURO">EURO</option>
									<option value="POUND">POUND</option>
								</select>
								<label for="sales_currency">Sales Curr.</label>
							</div>
	
							<div class="col-4 input-fiel p-0">
								<input class="input-ui inv-number-input" id="s_currency_rate"
									type="text" formControlName="sales_currency_rate" (input)="changeSalesCurrencyAmount($event)"
								/>
								<label for="currency_rate">Sales Curr. Rate</label>
							</div>
	
							<div class="col-4 input-fiel ">
								<input  class="input-ui inv-number-input" id="sales_currency_amount" formControlName="sales_currency_amount"
									 type="text" disabled
								/>
								<label for="sales_currency_amount">Sales Amount</label>
							</div>
						</div>
						
					</div>

					<div *ngIf="salesCurrencyPrint=='true'" class="currencyWrapper mt-5">
                        <div class="row" style="justify-content: flex-end">
                            <div class="col-4 input-fiel ">
                                <select id="flightType" class="input-ui inv-date-input" formControlName="sales_currency" >
                                    <option value="">Select</option>
                                    <option value="AED">AED</option>
									<option value="CAD">CAD</option>
                                    <option value="USD">USD</option>
                                    <option value="INR">INR</option>
                                    <option value="EURO">EURO</option>
									<option value="POUND">POUND</option>
                                </select>
                                <label for="sales_currency">Sales Curr.</label>
                            </div>
    
                            <div class="col-4  input-fiel p-0">
                                <input oninput="this.value = this.value"  class="input-ui inv-number-input text-right" id="sales_currency_rate"
                                    formControlName="sales_currency_rate" type="text" 
                                />
                                <label for="sales_currency_rate">Sales Curr. Rate</label>
                            </div>
                        </div>
                       
                    </div>
				</div>
				
			</div>

			<div class="text-input-group d-flex justify-content-between mt-3">
				<div class="input-fiel mt-10 w-50  mr-3">
					<input tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller+1}}"
						attr.tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller+1}}"
						class="input-ui w-100" id="narration"
						formControlName="narration_5" type="text" placeholder="Narration" /><label
						for="narration" [ngClass]="{'error-label-narration': !miscellaneousInvoiceService.invoiceForm.controls.narration_5.valid && miscellaneousInvoiceService.invoiceForm.controls.narration_5.hasError('pattern')}">Narration</label>
					<span class="error-ui"
									*ngIf="!miscellaneousInvoiceService.invoiceForm.controls.narration_5.valid && miscellaneousInvoiceService.invoiceForm.controls.narration_5.hasError('pattern')">
									Invalid special chars</span>
				</div>
				<div class="input-fiel mr-0 w-50 mt-10">
					<input tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller+2}}"
						attr.tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller+2}}"
						oninput="this.value = this.value.toUpperCase()" class="input-ui w-100" id="remark"
						formControlName="remark" type="text" placeholder="Remark" /><label for="remark">Remark</label>
				</div>
			</div>
	</div>
				<!-- <div class="btn-field bottom-btns-container mt-30 mb-2 d-flex align-item-center justify-content-end ml-0 mr-0">   
				<button type="button" (click)="closeMaxModule()" class="btn-style mb-5 mr-3">
					Cancel
				</button> 
				<ng-container *ngIf="invoiceMode != 'view'">
					<button 
					tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +6}}"  
					attr.tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +6}}"
					 type="button" class="btn-style bg-green mb-5"
						(click)="saveProductInvoice()">
						Save
					</button>
				</ng-container>

		</div> -->
	</div>
	
</div>
<div *ngIf="(!invoiceData) || invoiceData.is_consolidate!=true" class="btn-field bottom-btns-container full-width-btn-container  d-flex align-item-center justify-content-end ml-0 mr-0">   
	<ng-container *ngIf="invoiceMode != 'view'">
		<div class="w-100 d-flex align-item-center">

			<div class="d-flex align-items-center flex-row" *ngIf="showRefundableCheckbox == true && miscellaneousInvoiceService.invoiceForm.value.transaction_method == 'purchase' ">
				<label for="clientRefundable" style="font-size: 12px!important;font-weight: 400;color: #000!important;" class="mb-0">Non Refundable</label>
				<input class="ml-10" formControlName="nonRefundable" id="clientRefundable" type="checkbox" style="height: 14px;"/>
			</div>

			<div class="d-flex align-items-center flex-row ml-auto">

				<div class="d-flex align-items-center flex-row  mr-20" *ngIf="billOfSupplyActive === true">
					<label for="clientBos" class="mb-0 clientRefLbl">Bill Of Supply</label>
					<input class="ml-10" formControlName="bill_of_supply" id="clientBos" type="checkbox" style="height: 14px;"/>
				</div>

				<div class="d-flex align-items-center flex-row  mr-20" >
					<label for="rcm_applicable" class="mb-0 clientRefLbl">RCM Applicable</label>
					<input class="ml-10" formControlName="rcm_applicable" id="rcm_applicable" type="checkbox" style="height: 14px;"/>
				</div>
				<div class="d-flex align-items-center flex-row  mr-20" >
					<label for="reverse_tds" class="mb-0 clientRefLbl">Reverse TDS</label>
					<input class="ml-10" formControlName="reverse_tds" id="reverse_tds" type="checkbox" style="height: 14px;"/>
				</div>
				
				<button type="button" (click)="closeMaxModule()" class="btn-style bg-green mr-3">
					Reset
				</button> 
			
				<button 
					tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +3}}"  
					attr.tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +3}}"
				    type="button" class="btn-style bg-purple mr-3"
					(click)="saveProductInvoice()" id="save_mis_invoice_btn">
					Save
				</button>

				<button *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'"
				type="button" class="btn-style bg-grey mr-3" (click)="newModel()">
				SRV Detail
				</button>
			</div>
		</div>
		
	</ng-container>
	<ng-container *ngIf="invoiceMode == 'view'" >
		
		<div class="w-100 d-flex align-item-center">

			<div class="d-flex align-items-center flex-row" *ngIf="showRefundableCheckbox == true && miscellaneousInvoiceService.invoiceForm.value.transaction_method == 'purchase' ">
				<label for="clientRefundable" style="font-size: 12px!important;font-weight: 400;color: #000!important;" class="mb-0">Non Refundable</label>
				<input class="ml-10" disabled formControlName="nonRefundable" id="clientRefundable" type="checkbox" style="height: 14px;"/>
			</div>


			<div class="ml-auto row justify-content-between">

				<div class="d-flex align-items-center flex-row  mr-20" *ngIf="billOfSupplyActive === true">
					<label for="clientBos" class="mb-0 clientRefLbl">Bill Of Supply</label>
					<input class="ml-10" formControlName="bill_of_supply" id="clientBos" type="checkbox" style="height: 14px;"/>
				</div>
				<div class="d-flex align-items-center flex-row  mr-20" >
					<label for="rcm_applicable" class="mb-0 clientRefLbl">RCM Applicable</label>
					<input class="ml-10" formControlName="rcm_applicable" id="rcm_applicable" type="checkbox" style="height: 14px;"/>
				</div>
				<div class="d-flex align-items-center flex-row  mr-20" >
					<label for="reverse_tds" class="mb-0 clientRefLbl">Reverse TDS</label>
					<input class="ml-10" formControlName="reverse_tds" id="reverse_tds" type="checkbox" style="height: 14px;"/>
				</div>
				
				<button 
					tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +3}}"  
					attr.tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +3}}"
					type="button" class="btn-style bg-cyan mr-3"
					*hasAccess="{'key': hasAccessUpdateCode}"
						(click)="openUpdateConfirmModal()" id="update_mis_invoice_btn">
						{{invoiceData && invoiceData.status == 'Draft' ? 'Save' : 'Modify'}}
				</button>
				<button 
					tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +7}}"  
					attr.tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +7}}"
					type="button" class="btn-style bg-red mr-3"
					*hasAccess="{'key': hasAccessDeleteCode}"
					(click)="openDeleteConfirmModal()" id="delete_mis_invoice_btn">
					Delete
				</button>
				<button *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'"
				type="button" class="btn-style bg-grey mr-3" (click)="newModel()">
				SRV Detail
				</button>
		
				<ng-container *ngIf="invoiceData && invoiceData.status != 'Draft'">
					<span style="position: relative; display: inline-block;" class="mr-3">
				<button 
					type="button" class="btn-style bg-denim" (click)="clickToPrintOptions()">
						Print
					</button>
					<span class="form-invoice-btn"  *ngIf="showPrintOptions">
						<button type="button" *ngIf="checkEInvoice==false || isTesoyBoult==false"
						(click)="openVoucher(invoice_no)" class="btn-style bg-denim mr-2">Invoice</button>
			
						<button type="button" *ngIf="isTenantIndian"
						(click)="checkWhetherEInvGeneratedOrNot(invoice_no)" class="btn-style bg-denim " >E-Invoice</button>
					</span>
				</span>
				<button *ngIf="miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel'"
				type="button" class="btn-style bg-green mr-3" (click)="voucher(invoice_no)">
					Voucher
				</button>
				
					<button type="button" *ngIf="((invoiceData.invoice_type && invoiceData.invoice_type=='sales')  &&  (invoiceData.product_type && invoiceData.product_type.toLowerCase() == 'miscellaneous'))" 
			(click)="createCreditNote(invoice_no)" class="btn-style bg-blue-ivy mr-3" id="create_credit_note_btn">Credit Note</button>

				
				<button type="button" class="btn-style bg-pine mr-3" *ngIf="invoiceData && invoiceData.bos_reference" 
					(click)="openBosReference()" id="open_bos_btn">
					{{invoiceData.bill_of_supply ? 'TAX INVOICE' : 'BILL OF SUPPLY'}}
				</button>
				
					<button type="button" class="btn-style bg-brown mr-3" (click)="viewJournal(invoice_no)" id="journal_ms_invoice_btn">
						Journal
					</button>
					<button type="button" *ngIf="salesCurrencyPrint=='true'"
                 (click)="openBill(invoice_no)" class="btn-style bg-denim mr-3" id="activity_invoice_log_btn">MultiCurrency Bill
            </button>
					<button type="button" class="btn-style bg-indigo mr-3" (click)="openActivity(invoice_no)">
						Activity
					</button>
					<button type="button" class="btn-style bg-rusty-red text-white"
					tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller+8}}" attr.tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller+8}}" 
					(click)="openSendEmailModal()" id="invoice_send_mail_btn" id="mis_invoice_send_mail_btn">Email</button>
				</ng-container>
			</div>
		</div>
		
	</ng-container>

	
</div>
</form>
<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateConfirmationModal"></button>

<div class="modal fade" id="updateConfirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Confirmation</h4>
                <button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close" (click)="resetCurrentOpenedModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>"update ‎this entry</b> ?
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
                <button type="button" class="btn-style mr-2" (click)="closeUpdateConfirmModal()">No</button>
                <button type="button" class="btn-style bg-blue" (click)="updateProduct()">Yes</button>
            </div>
        </div>
    </div>
</div>
<button type="button" hidden  #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button >

    <div class="modal fade" id="deleteConfirmationModal"  role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
				<h4>Confirmation</h4>

                <button type="button" class="close" #closeDeleteModal data-dismiss="modal" aria-label="Close" (click)="resetCurrentOpenedModal()">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to delete ‎this entry ?
            </div>
            <div class="modal-footer">
              <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
              <button type="button" class="btn-style mr-2" (click)="closeDeleteConfirmModal()">No</button>
              <button type="button" class="btn-style bg-red"  (click)="deleteInvoice(invoice_no)" >Yes</button>
            </div>
          </div>
        </div>
      </div>

	  <button
hidden
#openEmailModal
data-target="#emailModalInMiscellaneous"
data-toggle="modal"
></button>
<div
  class="modal"
  id="emailModalInMiscellaneous"
  role="dialog"
  aria-labelledby="emailModalLabel"
  data-backdrop="false"
>
  <div class="modal-dialog" role="document">
	<div class="modal-content">
	  <div class="modal-header">
		<div class="d-flex justify-content-center">
		  <h4 class="modal-title">Send Email</h4>
		</div>
		<button
		  type=" button"
		  class="close"
		  #closeSendEmailModal
		  data-dismiss="modal"
		  aria-label="Close"
		>
		  <span aria-hidden="true">&times;</span>
		</button>
	  </div>
	  <div class="modal-body emailSenders">
		<!-- <div class="d-flex justify-content-end align-item-center">
						  <span *ngIf="!ccEmail" (click)="showCCEmail()" class="text-size-14 mb-0 cursor  mr-2">Cc</span>
						  <span *ngIf="!bccEmail" (click)="showBCCEmail()" class="text-size-14 mb-0 cursor">Bcc</span>
					  </div> -->
		<form [formGroup]="sendEmailForm" style="margin-top: -10px">
		  <div class="form-group text-input-field">
			<textarea
			  class="input-field text-left ng-pristine  ng-valid ng-touched text-size-12"
			  id="toEmail"
			  rows="1"
			  formControlName="toEmails"
			  required
			></textarea>
			<label for="To" class="mb-0">To</label>
		  </div>
		  <div class="d-flex justify-content-end">
			<!-- <label for="To" class="text-size-14 mb-0">To</label> -->
			<div *ngIf="sendEmailForm.controls.toEmails.invalid">
			  <div
				*ngIf="sendEmailForm.controls.toEmails.errors?.required"
				style="color: red"
			  >
				To Email is required.
			  </div>
			</div>
		  </div>

		  <div class="form-group text-input-field mt-0">
			<textarea
			  class="input-field text-left ng-pristine ng-valid ng-touched text-size-12"
			  id="ccEmail"
			  style="max-height: 45px;"
			  formControlName="ccEmails"
			></textarea>
			<label for="Cc" class="mb-0">Cc</label>
		  </div>

		  <div class="form-group text-input-field">
			<textarea
			  class="input-field text-left ng-pristine ng-valid ng-touched text-size-12"
			  id="bccEmail"
			  style="max-height: 45px;"
			  formControlName="bccEmails"
			></textarea>
			<label for="Bcc" class="mb-0">Bcc</label>
		  </div>
		  <div class="text-input-field">
			<input formControlName="subject"  class="input-field text-left text-size-12" type="text" 
			autocomplete="off" />
			<label for="subject">Subject</label>
		</div>
		<div class="form-group text-input-field">
		 <textarea
		   class="input-field text-left ng-pristine ng-valid ng-touched text-size-12"
		   rows="5"
		   formControlName="body"
		 ></textarea>
		 <label for="body" class="mb-0">Mail Body</label>
		 <input type="file" (change)="handleAttachment($event)" />
	   </div>
		  <div class="d-flex justify-content-center align-item-center">
			<button type="button" class="btn-style mr-2" data-dismiss="modal">
			  Cancel
			</button>
			<button
			  type="button"
			  class="btn-style bg-green"
			  (click)="callTosendEmail()"
			>
			  Send
			</button>
		  </div>
		</form>
	  </div>
	</div>
  </div>
</div>


<button hidden #openMasterCreationModal data-target="#masterCreationModal"data-toggle="modal"></button>
<div
  class="modal"
  id="masterCreationModal"
  role="dialog"
  aria-labelledby="masterModalLabel"
  data-backdrop="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex justify-content-center">
          <h4 class="modal-title">Create {{currentMaster}}</h4>
        </div>
        <button
          type=" button"
          class="close"
          #closeMasterCreationModal
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
	    <app-master-creation [masterType]="currentMaster" [masterCreationModal]="masterCreationModal" [currentMasterName]="currentMasterName" 
		(callSetFormData)="setFormValues($event)"
		></app-master-creation>
     </div>
    </div>
</div>

<button type="button" hidden #generateEInvoiceModal data-toggle="modal" data-target="#openGenerateEInvoiceModal"></button>

<div class="modal fade" id="openGenerateEInvoiceModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Confirmation</h4>
                <button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                E-invoice not generated,do you want to generate? 
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-style mr-2" (click)="closeGenerateEInvoiceModal()">No</button>
                <button type="button" class="btn-style bg-red" (click)="generateEInvoice(invoice_no)">Yes</button>
            </div>
        </div>
    </div>
</div>

<button type="button" hidden #generateAdvanceModal data-toggle="modal" data-target="#openGenerateAdvanceModal"></button>

<div class="modal fade hotel-detail-popup" id="openGenerateAdvanceModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
	<form [formGroup]="miscellaneousInvoiceService.invoiceForm">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Hotel Details</h4>
                <button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
			<div class="modal-body">
			<div class="row">
				<div class=" col-md-12 input-field">
					<label >Address</label>
					<input class="traveller-input-field" placeholder="" type="text" formControlName="hotel_address" oninput="this.value = this.value.toUpperCase()" />
				</div>
			</div>
            
			<div class="row">
				<div class="input-field" >
					<label >Hotel Tel No.</label>
					<input class="traveller-input-field" placeholder="" type="text" formControlName="hotel_tel_no" oninput="this.value = this.value.toUpperCase()" />
				</div>
			</div>

			<div class="row">
				<div class="input-field col-md-6 pr-0">
					<label >Booking Plan</label>
					<input class="traveller-input-field" placeholder="" type="text" formControlName="booking_plan" oninput="this.value = this.value.toUpperCase()" />
				</div>
				<div class="input-field col-md-6">
					<label >Rent / Day</label>
					<input class="traveller-input-field" placeholder="" type="text" formControlName="room_rent_per_day" oninput="this.value = this.value.toUpperCase()" />
				</div>
		    </div>
			
			<div class="row">
				<div class="col-md-12 input-field">
					<label >Inclusions</label>
					<input class="traveller-input-field" placeholder="" type="text" formControlName="inclusions" oninput="this.value = this.value.toUpperCase()" />
				</div>
			</div>

			<div class="row">
				<div class="col-md-12 input-field">
					<label >Exclusions</label>
					<input class="traveller-input-field" placeholder="" type="text" formControlName="exclusions" oninput="this.value = this.value.toUpperCase()" />
				</div>
			</div>

			<div class="row">
				<div class="col-md-6 input-field pr-0">
					<label >Confirmation No</label>
					<input class="traveller-input-field" placeholder="" type="text" formControlName="confirmation_no" oninput="this.value = this.value.toUpperCase()" />
				</div>
				<div class="col-md-6 input-field">
					<label >Confirmed By</label>
					<input class="traveller-input-field" placeholder="" type="text" formControlName="confirmed_by" oninput="this.value = this.value.toUpperCase()" />
				</div>
			</div>

			<div class="row">
				<div class="col-md-6 input-field pr-0" >
					<label >Docs</label>
					<input class="traveller-input-field" placeholder="" type="text" formControlName="passport_no" oninput="this.value = this.value.toUpperCase()" />
				</div>
				<div class="col-md-3 input-field pr-0" >
					<label >Adult(s)</label>
					<input class="traveller-input-field" placeholder="" type="text" formControlName="adults" oninput="this.value = this.value.toUpperCase()" />
				</div>
				<div class="col-md-3 input-field" >
					<label >Child(s)</label>
					<input class="traveller-input-field" placeholder="" type="text" formControlName="childs" oninput="this.value = this.value.toUpperCase()" />
				</div>
			</div>
		
			<div class="row">
				<div class="input-field col-md-6 pr-0">
					<label >Payment Mode</label>
					<input class="traveller-input-field" placeholder="" type="text" formControlName="payment_mode" oninput="this.value = this.value.toUpperCase()" />
				</div>
				<div class="input-field  col-md-6" >
					<label >Issued By</label>
					<textarea class="traveller-input-field" placeholder="" type="text" formControlName="issued_by" oninput="this.value = this.value.toUpperCase()"></textarea>
				</div>
			</div>
			<!-- <button  type="button" class="btn-style bg-cyan mr-3"
					
						(click)="updateProduct()" id="update_mis_invoice_btn">
						Save
				</button> -->
			</div>
			
        </div>
    </div>
</form>
</div>
