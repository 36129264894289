
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
  <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
    <div class="d-flex flex-y-center">
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
          alertType=='success-box' ? 'green-sucess.svg' : 
          alertType=='warning-box' ? 'red-warning.svg' : 
          null }}"
        width="20px"
        height="20px"
        alt="alert-logo"
        class="mr-20"
      />
      <div class="ml-10">
        <h4 class="m-0 text-size-18 text-bold"
        [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
          {{alertType === "error-box"
          ? "Error"
          : alertType === "warning-box"
          ? "Warning"
          : alertType === "success-box"
          ? "Success"
          : null}}
        </h4>
        <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
         {{message}}
        </small>
      </div>
    </div>
    <img
      src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
      alt="close-btn"
      (click)="hideError()"
      className=" close-btn"
    />
  </div>
</div>

<div class="ledger-container  ">
  <div class="row tab-nav pl-0 pr-0">
    <!-- <div class="col-sm-4 pl-0">
        <ul class="list-inline list-unstyled p-0 m-0">
            <li class="active page-title text-uppercase cursor"> View Client</li>
        </ul>
    </div> -->
    <div class="col-sm-4 ">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
          <li [routerLink]="['/']"
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Master</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li [routerLink]="['/']"
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Employee</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li class="text-grey text-capitalize cursor text-primary transition"> Create Employee</li>
      </ul>
  </div>
</div>
 

    <form [formGroup]="employeeForm">
      <div class="custom-form form-container mt-4 pl-15 pr-15" style="    width: 600px;
      margin: 20px 0 ">


<div class="text-input-group d-flex justify-content-between">
      
  <div class="select-field text-input-field" >
    <select class="input-field" tabIndex="1" formControlName="client_code" [ngClass]="{'error-field': (!employeeForm.controls.client_code.valid && submitted),
      'success-field' : employeeForm.controls.client_code.valid && employeeForm.controls.client_code.dirty}">
      <option selected disabled>SELECT</option>
      <ng-container *ngIf="groupList != undefined">
        <option *ngFor="let item of groupList" value="{{item.account_code}}">{{item.name}}</option>
      </ng-container>
    </select>
    <label
      [ngClass]="{'error-label': employeeForm.controls.client_code.invalid && employeeForm.controls.client_code.dirty || (!employeeForm.controls.client_code.valid && submitted)}">Client
      <ng-container
        *ngIf="employeeForm.controls.client_code.invalid && employeeForm.controls.client_code.dirty || (!employeeForm.controls.client_code.valid && submitted)">
        <i class="fa fa-info-circle ml-2" title="Group Required" aria-hidden="true"></i>
      </ng-container>
    </label>
  </div>


</div>

      <div class="text-field-group d-flex">
        <div class="text-input-field mr-3">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="1"
            formControlName="account_name" type="text"
            [ngClass]="{'error-field': employeeForm.controls.account_name.invalid && employeeForm.controls.account_name.dirty || (!employeeForm.controls.account_name.valid && submitted) ,
              'success-field' : employeeForm.controls.account_name.valid && employeeForm.controls.account_name.dirty}" />
          <label
            [ngClass]="{'error-label': employeeForm.controls.account_name.invalid && employeeForm.controls.account_name.dirty || (!employeeForm.controls.account_name.valid && submitted)}">Employee Name

            <ng-container
              *ngIf="employeeForm.controls.account_name.invalid && employeeForm.controls.account_name.dirty || (!employeeForm.controls.account_name.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid Employee Name" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

      </div>

      <div class="text-input-group d-flex justify-content-between">

        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="account_code" 
             tabIndex="10" formControlName="account_code" type="text" [ngClass]="{'error-field': employeeForm.controls.account_code.invalid && employeeForm.controls.account_code.dirty  || (!employeeForm.controls.account_code.valid && submitted),
          'success-field' : employeeForm.controls.account_code.valid && employeeForm.controls.account_code.dirty}" />
          <label
            [ngClass]="{'error-label': employeeForm.controls.account_code.invalid && employeeForm.controls.account_code.dirty  || (!employeeForm.controls.account_code.valid && submitted)}">Employee Code<ng-container
              *ngIf="employeeForm.controls.account_code.invalid && employeeForm.controls.account_code.dirty  || (!employeeForm.controls.account_code.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid account_code" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

      <div class="text-input-field">
        <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="channels" 
           tabIndex="10" formControlName="channels" type="text" [ngClass]="{'error-field': employeeForm.controls.channels.invalid && employeeForm.controls.channels.dirty  || (!employeeForm.controls.channels.valid && submitted),
        'success-field' : employeeForm.controls.channels.valid && employeeForm.controls.channels.dirty}" />
        <label
          [ngClass]="{'error-label': employeeForm.controls.channels.invalid && employeeForm.controls.channels.dirty  || (!employeeForm.controls.channels.valid && submitted)}">Channels<ng-container
            *ngIf="employeeForm.controls.channels.invalid && employeeForm.controls.channels.dirty  || (!employeeForm.controls.channels.valid && submitted)">
            <i class="fa fa-info-circle ml-2" title="Invalid channels" aria-hidden="true"></i>
          </ng-container>
        </label>
      </div>
    </div>

      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="cost_center" tabIndex="9"
            formControlName="cost_center" type="text"  [ngClass]="{'error-field': employeeForm.controls.cost_center.invalid && employeeForm.controls.cost_center.dirty ,
          'success-field' : employeeForm.controls.cost_center.valid && employeeForm.controls.cost_center.dirty}" />
          <label
            [ngClass]="{'error-label': employeeForm.controls.cost_center.invalid && employeeForm.controls.cost_center.dirty}">Cost Center
            <ng-container *ngIf="employeeForm.controls.cost_center.invalid && employeeForm.controls.cost_center.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid Cost Center Number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="department" tabIndex="10" formControlName="department" type="text" [ngClass]="{'error-field': employeeForm.controls.department.invalid && employeeForm.controls.department.dirty  || (!employeeForm.controls.department.valid && submitted),
          'success-field' : employeeForm.controls.department.valid && employeeForm.controls.department.dirty}" />
          <label
            [ngClass]="{'error-label': employeeForm.controls.department.invalid && employeeForm.controls.department.dirty  || (!employeeForm.controls.department.valid && submitted)}">Department<ng-container
              *ngIf="employeeForm.controls.department.invalid && employeeForm.controls.department.dirty  || (!employeeForm.controls.department.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid Department" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
      </div>

      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="sub_department" tabIndex="9"
            formControlName="sub_department" type="text" [ngClass]="{'error-field': employeeForm.controls.sub_department.invalid && employeeForm.controls.sub_department.dirty ,
          'success-field' : employeeForm.controls.sub_department.valid && employeeForm.controls.sub_department.dirty}" />
          <label
            [ngClass]="{'error-label': employeeForm.controls.sub_department.invalid && employeeForm.controls.sub_department.dirty}">Sub Department
            <ng-container *ngIf="employeeForm.controls.sub_department.invalid && employeeForm.controls.sub_department.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid Sub Department Number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="lob" tabIndex="10" formControlName="lob" type="text" [ngClass]="{'error-field': employeeForm.controls.lob.invalid && employeeForm.controls.lob.dirty  || (!employeeForm.controls.lob.valid && submitted),
          'success-field' : employeeForm.controls.lob.valid && employeeForm.controls.lob.dirty}" />
          <label
            [ngClass]="{'error-label': employeeForm.controls.lob.invalid && employeeForm.controls.lob.dirty  || (!employeeForm.controls.lob.valid && submitted)}">LOB<ng-container
              *ngIf="employeeForm.controls.lob.invalid && employeeForm.controls.lob.dirty  || (!employeeForm.controls.lob.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid LOB Number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
      </div>

      <!-- <span class="info alert-warning"
        *ngIf="showMessage && alertType == 'success-box' && createdEmployeeId != ''">Employee created. <a
          [routerLink]="['/master/policy/default-employee-policy', createdEmployeeId]">Click here to update
          policy.</a></span> -->
</div>
<div class="btn-field bottom-btns-container mt-30 d-flex align-item-center justify-content-end  ml-0 mr-0"> 
         
  <div>
    <button type="button" class="btn-style bg-green mr-3" (click)="reset()">Reset</button>     
  <button type="button" class="btn-style  bg-purple" tabIndex="22" #confirmModal (click)="openConfirmModal()"
  data-toggle="modal" data-target="#confirmationModal">Save</button>
  </div>  
</div>
      
    </form>
</div>


<div class="modal fade" id="confirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4>Confirmation</h4>

        <button type="button" class="close" #closeModal data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Create {{employeeForm?.value?.account_name}} ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" data-dismiss="modal">Close</button>
        <button type="button" class="btn-style bg-green" (click)="submit()" tabIndex="24">Yes</button>
      </div>
    </div>
  </div>
</div>