import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HsnReportComponent } from './hsn-report/hsn-report.component';
import { B2bReportComponent } from './b2b-report/b2b-report.component';
import { B2csReportComponent } from './b2cs-report/b2cs-report.component';
import { DocsReportComponent } from './docs-report/docs-report.component';
import { GstReportComponent } from './gst-report.component';

const routes: Routes = [
  {
    path:'', component: GstReportComponent,
    children:[
      {path: 'b2b-report', component: B2bReportComponent},
      {path: 'b2cs-report', component: B2csReportComponent},
      {path: 'docs-report', component: DocsReportComponent},
      {path: 'hsn-report', component: HsnReportComponent},
      
      {
        path:'',
        redirectTo: 'b2b-report',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GstReportRoutingModule { }
