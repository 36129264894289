import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../store/actions';
import { AddEntryContraComponent } from '../add-entry-contra/add-entry-contra.component';
import { Tab } from 'src/app/shared/model/tab.model';
import { TabService } from 'src/app/shared/services/tab.service';
@Component({
	selector: 'app-view-contra',
	templateUrl: './view-contra.component.html',
	styleUrls: ['./view-contra.component.scss']
})
export class ViewContraComponent implements OnInit {
	contraArr: any;
	navigationSubscription;
	reInitialise: boolean;
	p;
	innerHeight: string;
	fromRoot = true;
	selector: string = '.main-panel';
	fromIndex: any = 0;
	toIndex: any = 30;
	onSearch;
	onSearchFilter = new Subject<string>();
	isAdvancedSearchOpen: boolean = false;
	currentIndex=0
	@ViewChild('tableHeader', {static:true}) tableHeader : ElementRef
	allcontraArr: any;
	advancedSearchSubscription : Subscription;
	duration: any;
	loader: boolean = false;
	queryParams: any;

	constructor(
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private store: Store<InitialState>,
		private router: Router,
		private tabService: TabService
	) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	}

	ngOnInit() {
		let queryParams = JSON.parse(JSON.stringify(this.route.queryParams['value']))
		if (queryParams.account != undefined) {
			localStorage.setItem('activeContraQueryParams',JSON.stringify(queryParams))
		} 
		this.fromIndex = 0;
		queryParams['limit'] = 30;
        queryParams['offset'] = this.fromIndex;
		this.onSearch = ''
        this.search(queryParams)
		this.queryParams = queryParams
		this.reInitialise=true
		this.onSearchFilter.pipe(
			debounceTime(300),
			distinctUntilChanged())
			.subscribe(value => {
			  this.filterData(value)
		});
	}
	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}

	ngAfterViewInit(){
		this.advancedSearchSubscription = this.commonService.advancedSearch.subscribe(status=>{
			console.log('Is modal opened : ',status)
			this.isAdvancedSearchOpen = status
		})
	}
	openAdvanceSearchModal(){
		this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'contra'}));	
	}

	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
		if (this.advancedSearchSubscription) {
			this.advancedSearchSubscription.unsubscribe();
		}
	}
	
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setHeight(event.target.innerHeight)
	}



	oldSearch(values) {
		this.loader = true;
		this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/contra`, values).subscribe(res => {
			this.contraArr =[]
			this.loader = false;
			this.duration = res['duration']
			this.allcontraArr = res['data']
			this.focusOnTableHeader()
			this.currentIndex = 0
			for (let i = 0; i <= 30; i++) {
				const element = this.allcontraArr[i];
				if (element != undefined) {
					this.commonService.selectedInput.next(1)
					this.toIndex = i
					this.contraArr.push(element)
				}
			}
			this.setHeight(window.innerHeight)
		})
	}
	async search(values) {
		let reqObj = Object.assign({},values)
		
		this.loader = true;
		let resp = await this.getData(reqObj)
		if(resp){
			this.loader = false;
			this.duration = resp['duration']
			this.allcontraArr = resp['data']
			this.contraArr = resp['data']
			this.focusOnTableHeader()
			this.currentIndex = 0
			this.setHeight(window.innerHeight)
			this.commonService.selectedInput.next(1)
		}
		
	}

	getData(values){
		return new Promise(async (resolve, reject) => {
			this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/contra`, values).subscribe(res => {
				resolve(res)
			},err=> {
				resolve(null)
			})
		})
	}

	async onScrollDown(event) {
		this.fromIndex +=30
		// let toIndex = Number(this.toIndex) + 10
		let queryParams = JSON.parse(JSON.stringify(this.queryParams))
		queryParams['limit'] = 30;
		queryParams['offset'] = this.fromIndex;
		let resp = await this.getData(queryParams)
		if(resp){
			this.contraArr = this.contraArr.concat(resp['data'])
			this.setHeight(window.innerHeight)
		}
	  }
	setHeight(height) {
		if (this.contraArr != undefined) {
			if (height == undefined) {
				height = window.innerHeight
			}
			if (this.contraArr.length <= 9) {
				this.innerHeight = 'auto'
			}
			else {
				this.innerHeight = height - 147 + 'px';
			}
		}
	}
	openContra(contra_code){
		if(window.location.protocol != 'file:'){
			let title =''
			let invoiceParts = contra_code.split('-')
			title += invoiceParts[0]
			title +='...'
			title += invoiceParts[invoiceParts.length-1]
			if(contra_code) {
				this.tabService.addTab(
					new Tab(AddEntryContraComponent, `${title}`, { contra_code: contra_code }, contra_code)
					);
				}
		   else {
			window.open(`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/contra/update/${contra_code}`);
		   } 
		}
	}
	

	focusOnTableHeader(){
		if(this.tableHeader != undefined){
			this.tableHeader.nativeElement.focus()
		}
	}
	
	// @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.currentIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex+1}`).focus()
			this.currentIndex = this.currentIndex+1
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.currentIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex-1}`).focus()
			this.currentIndex = this.currentIndex-1
		}
	}
	
	moveToIndex(i){
		document.getElementById(`rowIndex_${i+1}`).focus()
		this.currentIndex = i+1
	}

	  onScrollUp(event) {
	  }


	oldFilterData(value) {
		let tempArr = Object.assign([], this.allcontraArr)
		if (value.length >= 3) {
			let lowerCaseFilter = value.toLowerCase()
			let upperCaseFilter = value.toUpperCase()
			this.contraArr = tempArr.filter(
				contraData => {
					if (	(
								contraData.last_updated_by_name != undefined && 
								contraData.last_updated_by_name != null && 
								(	contraData.last_updated_by_name.includes(value) || 
									contraData.last_updated_by_name.includes(upperCaseFilter) ||
									contraData.last_updated_by_name.includes(lowerCaseFilter)
								)
							) ||
							(
								contraData.contra_code != undefined && 
								contraData.contra_code != null && 
								(	contraData.contra_code.includes(value) || 
									contraData.contra_code.includes(upperCaseFilter) ||
									contraData.contra_code.includes(lowerCaseFilter)
								)
							) || 
							(
								contraData.credit_amount != undefined && 
								contraData.credit_amount != null && 
								(	contraData.credit_amount.includes(value) || 
									contraData.credit_amount.includes(upperCaseFilter) ||
									contraData.credit_amount.includes(lowerCaseFilter)
								)
							) ||
							(
								contraData.debit_amount != undefined && 
								contraData.debit_amount != null && 
								(	contraData.debit_amount.includes(value) || 
									contraData.debit_amount.includes(upperCaseFilter) ||
									contraData.debit_amount.includes(lowerCaseFilter)
								)
							)
						) {
						return contraData
					}
					console.log(contraData)
				}
			)
			this.setHeight(window.innerHeight)
		} else{
			this.contraArr = []
			for (let i = 0; i <= 30; i++) {
				const element = this.allcontraArr[i];
				if (element != undefined) {
					this.toIndex = i
					this.contraArr.push(element)
				}
			}
			this.setHeight(window.innerHeight)
		}
	}

	async filterData(value) {
        if (value.length >= 0) {
            this.queryParams['freetext'] = value
            let queryParams = JSON.parse(JSON.stringify(this.queryParams))
            this.queryParams = queryParams
			this.fromIndex=0
            queryParams['limit'] = 30;
            queryParams['offset'] = this.fromIndex;
			this.loader = true
            let resp = await this.getData(queryParams)
			this.loader = false
            if(resp){
                this.contraArr = resp['data']
				let firstRow = document.getElementById('rowIndex_1') as HTMLElement
				if(firstRow){
					firstRow.scrollIntoView()
				}

            }
           
        this.setHeight(window.innerHeight)
        } else {
            delete this.queryParams['freetext']
            this.search(this.queryParams)
            this.setHeight(window.innerHeight)
        }
    }

}
