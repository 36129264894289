export const environment = {
  production: false,
	name : 'stage',
	IBEurl: 'https://stage.aadeshtrips.com',
	pdfGeneratorUrl : 'https://stage.aadeshtrips.com/puppet/pdfGenerator',
	okountUrl: 'https://app.stage.systacc.com/api/v1',
	baseAccountUrl : 'https://app.stage.systacc.com',
	accountManagement : 'https://accounts.stage.systacc.com',
	centri_token : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJzeXN0YWNjIiwiZXhwIjoxOTg5NDE0MTc5fQ.y1oyn1zqseHQKEMWSiq-NcIUU0VoXnaqTKkHTPR0tQs',
	centri_ws : 'wss://app.stage.systacc.com/connection/websocket',
	aws_asset_url : 'https://systacc-assets.s3.ap-south-1.amazonaws.com',
	sentry_dsn : 'https://008bcf25ca8a419497dc775c6cc95420@sentry.systacc.com/6',
	BRANCH_REF_SLUG: '{BRANCH_REF_SLUG}',
	roleId : '1',
	aadeshSystaccId: '464',//'477',
	aadeshAedSystaccId: '831',
	userManagementEndPoint : 'user-management',
	domain : 'stage.systacc.com',
	crptrSKey : '*#^*&#^&(&#&^',
	checkTenant:495,
	firebase: {
		apiKey: "AIzaSyBNM-scZ-lm_TxuEenhJ1nrd3K_cQxMm88",
		authDomain: "test-51957.firebaseapp.com",
		databaseURL: "https://test-51957.firebaseio.com",
		projectId: "test-51957",
		storageBucket: "test-51957.appspot.com",
		messagingSenderId: "1063233930220"
	},
	crs_id : {
		"AMADEUS_V4": 'AM',
		"6E": '6E',
		"SG": 'SG',
		"SG_CORPORATE": 'SG',
		"SG_COUPON": 'SG',
		"GO_CORPORATE": 'G8',
		"GO_SPECIAL": 'G8',
		"GOAIR": 'G8',
		"SABRE": 'SB',
		"GALILEO": '1G',
		"airasia": "AK"
	},
	//https://env.base/id-value?pnr=ABCDEF, sample - https://stage.aadeshtrips.com/edge/go-corporate/go/v4.0/retrieve
	pnrRetrieveUrls:{
		"base":"https://stage.aadeshtrips.com/edge/",
		// "base":"https://aadeshtravels.com/edge/",
		//SG Normal
		"website" : [
			//SG
			{
			id : "SPICEJET-RAJALA9549",
			url : "spicejet/spicejet/v4.0/retrieve",
			scode : "SG050"
			},
			//SG Corporate
			{
			id : "SPICEJET-RAJALB9549",
			url : "spicejet-corporate/spicejet/v4.0/retrieve",
			scode : "SG051"
			},
			 //SG Coupon
			{
			id : "SPICEJET-SGCPN_W022",
			url : "spicejet-coupon/spicejet/v4.0/retrieve",
			scode : "SG052"
			},
			 //Indigo
			{
			id : "INDIGO-OTI114",
			url : "indigo/indigo/v4.0/retrieve",
			scode : "6E670"
			},
			//GO
			{
			id : "GO-RXAPIAAD",
			url : "go/go/v4.0/retrieve",
			scode : "GO008"
			},
			 //GO Corporate
			{
			id : "GO-AMDIHMRA",
			url : "go-corporate/go/v4.0/retrieve",
			scode : "GO008"
			},
			//Go Special
			{
				id : "GO-GSAADESH",
				url : "go-special/go/v4.0/retrieve",
				scode : "G8008"
				},
				
				// go-aadesh/go/v4.0/retrieve
				{
					id : "GO-RAJ80025-( AADESH )",
					url : "go-aadesh/go/v4.0/retrieve",
					scode : "G8009"
				},
			//Air Arabia
			{
			id : "AIRARABIA",
			url : "airarabia/airarabia/v1.0/retrieve",
			scode : "G9514"
			},
			//Trujet
			{
			id : "TRUJET",
			url : "trujet/trujet/v1.0/retrieve",
			scode : "2T100"
			},
			{
			id: "AIR ASIA",
			url: "airAsia/airAsia/v1.0/retrieve",
			scode: "AK808"
			},
			{
			id : "Other",
			url : "",
			scode : ""
			}],
		//Amadeus
		"1A": [
			{
				"id": "AMADEUS-RAJVS3137",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "B", //invoice type BSP
				"bookingSupplier" : "P  799" 
			},
			{
				"id": "AMADEUS-RAJVS3296",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SB000A" 
			},
			{
				"id": "AMADEUS-AMDAK38DR",
				"url": "",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SA0007" 
			},
			{
				"id": "AMADEUS-AMDI228CI",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SS0012" 
			},
			{
				"id": "AMADEUS-AMDI228CQ",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SL0000" 
			},
			{
				"id": "AMADEUS-AMDI228DC",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SF0010" 
			},
			{
				"id": "AMADEUS-AMDI228DE",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SR000C" 
			},
			{
				"id": "AMADEUS-AMDI228DF",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SR000C" 
			},
			{
				"id": "AMADEUS-AMDI228DV",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "ST0000" 
			},
			{
				"id": "AMADEUS-BDQI228AX",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SR000C" 
			},
			{
				"id": "AMADEUS-BOMI228LH",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "ST0000" 
			},
			{
				"id": "AMADEUS-BOMI228FH",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SB0007" 
			},
			{
				"id": "AMADEUS-DELI228RT",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "ST0026" 
			},
			{
				"id": "AMADEUS-RAJUG38AS",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SS0000" 
			},
			{
				"id": "AMADEUS-STVI228AC",
				"url": "amadeus/amadeus/v4.0/retrieve",
				"scode": "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SS0026" 
			},
			{
				'id' : "Other",
				'url' : "",
				'scode' : "",
				"type": "",
				"bookingSupplier" : ""
			}
		],
		//Sabre
		"1B": [
			{
				id: "SABRE-2MXD",
				url: "sabre/sabre/v1.0/retrieve",
				scode: "AI098",
				"type": "B", //invoice type BSP 
				"bookingSupplier" : "P  799" 
			},
			{
				id: "SABRE-1A7D",
				url: "sabre/sabre/v1.0/retrieve",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "ST0026" 
			},
			{
				id: "SABRE-31YD",
				url: "sabre/sabre/v1.0/retrieve",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SY0001" 
			},
			{
				id: "SABRE-3PUD",
				url: "sabre/sabre/v1.0/retrieve",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SB0007" 
			},
			{
				id: "SABRE-6L5D",
				url: "sabre/sabre/v1.0/retrieve",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "ST0000" 
			},
			{
				id: "SABRE-6VT8",
				url: "sabre/sabre/v1.0/retrieve",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SS0012" 
			},
			{
				id: "SABRE-83CD",
				url: "sabre/sabre/v1.0/retrieve",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SA0007" 
			},
			{
				id: "SABRE-O4VD",
				url: "sabre/sabre/v1.0/retrieve",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SB000A" 
			},
			{
				id: "SABRE-8EYJ",
				url: "sabre/sabre/v1.0/retrieve",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SR000C" 
			},
			{
				'id' : "Other",
				'url' : "",
				'scode' : "",
				"type": "",
				"bookingSupplier" : ""
			}
		],
		"1G": [
			{
				id: "1UE",
				url: "galileo/galileo/v1.0/retrieve",
				scode: "",
				"type": "B", //invoice type BSP 
				"bookingSupplier" : "P  799" 
			},
			{
				id: "6R30",
				url: "",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "ST0000" 
			},
			{
				id: "5H1O",
				url: "",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SS0012" 
			},
			{
				id: "55B6",
				url: "",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SB0007" 
			},
			{
				id: "3OB0",
				url: "",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SS0026" 
			},
			{
				id: "YT4",
				url: "",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SR000C" 
			},
			{
				id: "U8K",
				url: "",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SR000C" 
			},
			{
				id: "I4I",
				url: "",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SB000A" 
			},
			{
				id: "O9B",
				url: "",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "ST0026" 
			},
			{
				id: "7RU2",
				url: "",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SY0001" 
			},
			{
				id: "5Y93",
				url: "",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "SA0007" 
			},
			{
				id: "6Y3W",
				url: "",
				scode: "",
				"type": "C",//invoice type CR
				"bookingSupplier" : "ST0000" 
			},
			{
				'id' : "Other",
				'url' : "",
				'scode' : "",
				"type": "",
				"bookingSupplier" : ""
			}
			
		]
	},
	BHUPESH_TENANT : '628,629,630,631,596'
};
