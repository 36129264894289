import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceServicesService } from '../flight/services/invoice-services.service';
import { LoaderService } from 'src/app/shared/loader.service';
import { NumberToWordsPipe } from 'src/app/shared/pipe/print-invoice.pipe';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver'
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-ms-invoice-view',
  templateUrl: './ms-invoice-view.component.html',
  styleUrls: ['./ms-invoice-view.component.scss']
})
export class MsInvoiceViewComponent implements OnInit {


	invoiceData: any;
	productData: any;
	accountList: any;
	handlingCharges: any = 0;
	tdsRecv: any = 0;
	round_off: any = 0;
	showBox;
	netReceived: any;
	serviceCharge: any;
	totalInwords: any;

	isNonAccount = false
	totalNonAccountAmount: number;
	fareData: any;
	waterMark;
	searchId: any;

	@Input() reference_code;
	@Input() preFetchedData;
	@Output() callSaveFetchedData: EventEmitter<any> = new EventEmitter()
	basFare = 0;
	ot = 0;
	subTotalFare = 0;
	nativeLanguage: any
	updateBillingDetailsForm: any;
	displayBillingDetails : any
	@ViewChild('openBillingDetailsModal', { static: true }) openBillingDetailsModal: ElementRef
	showPartyName: boolean = true;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private loaderService: LoaderService,
		private invoiceService: InvoiceServicesService,
		public commonService: CommonServiceService,
		public sanitizer: DomSanitizer,
		private fb: FormBuilder
	) { }

	ngOnInit() {
		this.loaderService.show()
		let searchId = this.route.snapshot.params['searchId'];
		this.searchId = searchId;
		if(!this.preFetchedData){
			if(this.reference_code){
				searchId = this.reference_code
			}
			if (searchId != undefined) {
				document.title = `Invoice - ${searchId}`
			}
			let type = this.route.snapshot.params['type'];
			this.getInvoiceData(searchId, type)
		}else{
			this.invoiceData = this.preFetchedData
			this.bindData()
		}
		this.getAccountingCompany()

		let queryParams = this.route.queryParams['value'];
		if(queryParams && queryParams.hide_party && queryParams.hide_party == 'true') {
			this.showPartyName = false;
		}

		this.updateBillingDetailsForm = this.fb.group({
			"invoice_no": [this.searchId],
			"display_billing_details": [''],
			'inv_in_native_lang': [false]
		})
	}
	async bindData(){
		this.getSubTotalFare(this.invoiceData)
		this.productData = this.invoiceData.products[0]
		this.totalNonAccountAmount = 0
		if(this.productData.invoice_type == 'non_account'){
			this.isNonAccount = true
		}
		let resp = await this.getTotalAmount(this.invoiceData)
		this.waterMark = `url('${this.invoiceData?.accounting_company?.tenant_logo}')`
	}
	getInvoiceData(searchId: any, type: any) {
		try {
			this.commonService.getRequest(`${environment.okountUrl}/invoice/miscellaneous/download/${searchId}?preview=true`).subscribe(async (res) => {
				this.invoiceData = this.transform(res)
				// this.invoiceData = res['data'][0]
				
				try {
					this.callSaveFetchedData.emit({
						data : this.invoiceData,
						invoice_no : searchId
					})
				} catch (error) {
					
				}
				// this.bindData()
				
			},
				err => {
					this.loaderService.hide()
					console.log(err)
				})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
		}
	}
	transform(html) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}
	getSubTotalFare(invoiceData: any) {
		this.basFare 
		this.ot
		this.subTotalFare
		for(const data of  invoiceData.products){
			this.basFare+=Number(data.client_basic)
			this.ot+=this.getOT(data)
			this.subTotalFare+=this.getTotal(data)
		}

	}

	getTotalAmount(invoiceData){
		this.commonService.postRequest(`${environment.okountUrl}/invoice/miscellaneous-fare`,invoiceData).subscribe(async (res : any)=> {
			console.log('calculationRes',res)
			this.fareData = res
			this.tdsRecv = 	res.client_tds ? res.client_tds : 0
			this.totalNonAccountAmount = Number(res.client_non_account) + Number(res.client_basic)
			this.serviceCharge = 	res.client_sc1_amount ? res.client_sc1_amount : 0
			this.netReceived = 	res.client_net_amount ? res.client_net_amount  : 0
			this.round_off = 	((this.netReceived - Math.round(this.netReceived))* -1).toFixed(2)
			let numberToBeConverted = Number(this.netReceived)
			this.totalInwords = ""
			if (numberToBeConverted < 0) {
				numberToBeConverted = numberToBeConverted * -1
				this.totalInwords = "Minus "
			}
			this.totalInwords += new NumberToWordsPipe().transform(Number(numberToBeConverted).toFixed(0))

			this.loaderService.hide()
			try {
				setTimeout(() => {
					document.querySelector("#print-section").classList.add("voucher-container")
				}, 1000);
			} catch (error) {
				
			}
		  },err=> {
			this.loaderService.hide()
		  })
	}
	
	getNumberToWord(value) {
		if (value != undefined) {
			return new NumberToWordsPipe().transform(Number(value).toFixed(2))
		}
	}
	getSSRamount(ssrs, type) {
		if (ssrs != undefined) {
			let mealAmount = 0
			let seatAmount = 0
			let baggageAmount = 0
			for (let i = 0; i < ssrs.length; i++) {
				const ssr = ssrs[i];
				mealAmount += Number(ssr.meal.amount)
				baggageAmount += Number(ssr.baggage.amount)
				seatAmount += Number(ssr.seat.amount)
			}
			if (type == 'seat') {
				return seatAmount
			} else if (type == 'meal') {
				return mealAmount
			} else if (type == 'baggage') {
				return baggageAmount
			}
		}
	}

	getOT(productData) {
		return Number(productData.client_taxes) + this.getServiceCharge(productData,'N')
	}
	getTotal(productData) {
		let fare = productData.client_basic ? productData.client_basic : 0;
		let taxes = productData.client_taxes ? productData.client_taxes : 0;
		return Number(fare) + Number(taxes) + this.getServiceCharge(productData,'N')
	}

	getSC(scType, invoiceData){
		let amount = 0
		for (const product of invoiceData.products) {
			amount += this.getServiceCharge(product,scType)
		}
		return amount
	}

	getServiceCharge(product,scType){
		let amount = 0
		amount += product.client_sc1_amount && product.client_sc1_type == scType ? Number(product.client_sc1_amount) : 0
			
		amount += product.client_sc2_amount && product.client_sc2_type == scType ? Number(product.client_sc2_amount) : 0
		
		amount += product.client_sc3_amount && product.client_sc3_type == scType ? Number(product.client_sc3_amount) : 0
		return amount;
	}

	printInvoice() {
		window.print()
	}
	// getBookingRefNo(nar){
	// 	return nar.split(',').filter(e => e.includes('AT'))[1].split(':')[1]
	// }

	downloadPdf(searchId: any) {
		this.commonService.downloadPDF(`${environment.okountUrl}/invoice/miscellaneous/download/${searchId}?preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `Invoice-${searchId}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}

	selectLanguage(event) {
		this.getInvoiceData(this.searchId,  '')
	}

	getAccountingCompany() {
		try {
			this.invoiceService.getMethod('util/accounting-company').subscribe(async (res: any) => {
				if(res && res.native_language_code) {
					this.nativeLanguage =  res.native_language_code;
				}
			},
			err => {
			})
		} catch (error) {
			console.log('Error occured in get company due to : ', error)
		}
	}
	
	updateDisplayBilling(){
		let reqObj = {
			invoice_no : this.updateBillingDetailsForm.value.invoice_no,
			display_billing_details : this.updateBillingDetailsForm.value.display_billing_details
		}
		
		this.displayBillingDetails = 'updating...'
		this.commonService.putRequest(`${environment.okountUrl}/invoice/ms-invoice/display_billing_details`,reqObj).subscribe(async(res: any) =>{
			this.openBillingDetailsModal.nativeElement.click()
			let type = this.route.snapshot.params['type'];
			this.getInvoiceData(this.searchId, type)
		},err =>{
			console.log(err)
		})
	}

	openBillingDetails() {
		this.openBillingDetailsModal.nativeElement.click();
	}

}

