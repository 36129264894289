import { Component, OnInit } from '@angular/core';

import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DownloadService } from 'src/app/shared/download.service';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../../../transactions/store/actions';
import { TabService } from 'src/app/shared/services/tab.service';
import { NormalLedgerComponent } from '../normal-ledger/normal-ledger.component';
import { Tab } from 'src/app/shared/model/tab.model';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-outstanding-summary-ledger',
  templateUrl: './outstanding-summary-ledger.component.html',
  styleUrls: ['./outstanding-summary-ledger.component.scss']
})
export class OutstandingSummaryLedgerComponent implements OnInit {
	isPrint:any;
  outStandingData:any
  innerHeight: string;
  reInitialise: boolean;
  duration:any
  navigationSubscription;

	currentDate = moment().format('YYYY-MM-DD');
	currentTime = moment().format('HH:mm:ss');
  p=0;
	q=0;
  currentIndex=0
  queryParams: any;
  constructor(
    private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private downloadService : DownloadService,
    private store: Store<InitialState>,
	private tabService : TabService
  ) { 
    this.spinner.show()
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
  }

  ngOnInit() {
    let queryParams = this.route.queryParams['value']
	this.queryParams = queryParams;

		if (queryParams.client != undefined) {
			localStorage.setItem('activeOutStandingQueryParams',JSON.stringify(queryParams))
			this.isPrint = queryParams.print
			this.getFilteredData(queryParams)
		} else {
			this.getData()
		}
		this.reInitialise = true
  }

  ngOnDestroy() {
	// avoid memory leaks here by cleaning up after ourselves. If we  
	// don't then we will continue to run our initialiseInvites()   
	// method on every navigationEnd event.
	if (this.navigationSubscription) {
		this.navigationSubscription.unsubscribe();
	}
}

  initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}

  getFilteredData(queryParams) {
		this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/outstanding`, queryParams).subscribe(res => {
			this.duration = res['duration']
			this.outStandingData = res
			this.spinner.hide()
			
			if (this.outStandingData != undefined) {
				this.setHeight(0,0,window.innerHeight)
			}
		}, err => {
			this.spinner.hide()
			console.error(err);
		})
	}

  getData() {
		this.commonService.getRequest(`${environment.okountUrl}/reports/outstanding`).subscribe(res => {
			this.outStandingData = res
			this.spinner.hide()
			if (this.outStandingData != undefined) {
				this.setHeight(0,0,window.innerHeight)
			}
		}, err => {
			this.spinner.hide()
			console.error(err)
		})
	}

  openAdvanceSearchModal(){
		this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'ledger'}));	
	}

  setHeight(pIndex,innerPIndex,height){
		if (height == undefined) {
			height = window.innerHeight
		}
		pIndex = Number(pIndex)
		innerPIndex = Number(innerPIndex)
		this.p = pIndex
		this.q = innerPIndex

		let parent = 0
		if (pIndex > 0) {
			parent = pIndex - 1
		}
		if (this.outStandingData['data'].length > 0) {
			if (this.outStandingData['data'].length <= 3) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = height - 140 + 'px';
			}
		}
		else {
			this.innerHeight = height - 140 + 'px';
		}
	}

  // @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.currentIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex+1}`).focus()
			this.currentIndex = this.currentIndex+1
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.currentIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex-1}`).focus()
			this.currentIndex = this.currentIndex-1
		}
	}
	
	moveToIndex(i){
		document.getElementById(`rowIndex_${i+1}`).focus()
		this.currentIndex = i+1
	}

  exportAsXLSX(fromDate, toDate):void {
		this.spinner.show()
		let xlsxData = [];
		this.outStandingData.data.forEach(element => {			
			let obj = { };
			obj["Account Code"] = `${element.account_code}`;
			obj["Account"] = element.display_name;
			obj["City Name"] = element.city_name
			obj["Debit"] = element.total_debit ? Number(Number(element.total_debit).toFixed(2)) : 0;
			obj['Credit'] = element.total_credit ? Number(Number(element.total_credit).toFixed(2)) : 0;
			obj["Balance"] = element.outstanding ? Number(Number(element.outstanding).toFixed(2)) : 0;
			xlsxData.push(obj);
			
		});

		xlsxData.push({});

		let obj1 = {}
		obj1["Account Code"] = '';
		obj1["Account"] = 'SubTotal';
		obj1["City Name"] = '';
		obj1["Debit"] = this.outStandingData['summary']['debitTotal'] ? Number(Number(this.outStandingData['summary']['debitTotal']).toFixed(2)) : 0;
		obj1['Credit'] = this.outStandingData['summary']['creditTotal'] ? Number(Number(this.outStandingData['summary']['creditTotal']).toFixed(2)) : 0;
		obj1["Balance"] = this.outStandingData['summary']['balanceTotal'] ? Number(Number(this.outStandingData['summary']['balanceTotal']).toFixed(2)) : 0;
		xlsxData.push(obj1);


		this.downloadService.exportAsXLSX(`CLIENT OUTSTANDING ${fromDate} - ${toDate}`, xlsxData);
		this.spinner.hide()
	}

	callPdfGeneratorApi(fromDate, toDate){
		window.scrollTo(0,0)
		this.spinner.show()
		this.spinner.show()
		let url = 'print/account-book/ledger/summary-ledger'
		let queryString = location.hash.split('?')[1]
		if (queryString != undefined) {
			url = `${url}?print=true&${queryString}`
		}

		// let url = 'print-pdf/pdf-client-outstanding'
		// let queryString = location.hash.split('?')[1]
		// if (queryString != undefined) {
		// 	url = `${url}?${queryString}`
		// }
		this.downloadService.callPdfGeneratorApi(`CLIENT OUTSTANDING ${fromDate} - ${toDate}.pdf`,url,{})	
		this.spinner.hide()
	}


	downloadPdf(start_date, end_date) {
		this.commonService.downloadPDF(`${environment.okountUrl}/reports/outstanding/download`, this.queryParams).subscribe((fileBlob:any) =>{
			let fileName = `SUMMARY LEDGER REPORT ${start_date} - ${end_date}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}


  openLedger(account_code){
		let queryParams = Object.assign({},this.route.queryParams['value'])
		if (queryParams.client == undefined) {
			queryParams = {
				"client" : ""
			}
		}
		if (account_code != undefined) {
			queryParams['client'] = account_code
		}
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/display/account-book/ledger/normal-ledger?client=`+account_code
		if(window.location.protocol != 'file:'){
			if(account_code) {
				this.tabService.addTab(
					new Tab(NormalLedgerComponent, `Ledger-${account_code}`, { client: account_code , viewMode : 'normal', 
					start_date: queryParams.start_date ? queryParams.start_date : '', end_date: queryParams.end_date ? queryParams.end_date : '' , bf_balance: queryParams.bf_balance ? queryParams.bf_balance : false 
				}, `Normal Ledger - ${account_code}`)
				);
			} else {
				window.open(path,"_blank");
			}
		}
		else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}

		// http://localhost:4200/#/display/account-book/ledger/normal-ledger?client=C0065
		// window.open(`/#/display/account-book/ledger/normal-ledger?{queryParams: queryParams}`, "_blank");
		// this.router.navigate(['/display/account-book/ledger/normal-ledger'], { queryParams: queryParams });
	}

}
