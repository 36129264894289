import { Component, OnInit, ViewChild, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment-timezone';
import { DownloadService } from 'src/app/shared/download.service';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { InvoiceComponent } from 'src/app/transactions/invoice/invoice.component';
import { MsInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/ms-invoice/ms-invoice.component';
import { GstInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/gst-invoice/gst-invoice.component';
import { V2CreatePaymentComponent } from 'src/app/transactions/payment/v2-create-payment/v2-create-payment.component';
import { V2CreateReceiptComponent } from 'src/app/transactions/receipt/v2-create-receipt/v2-create-receipt.component';
import { CreateAdmAcmComponent } from 'src/app/transactions/adm-acm/create-adm-acm/create-adm-acm.component';
import { AddEntryJvComponent } from 'src/app/transactions/jv/add-entry-jv/add-entry-jv.component';
import { Tab } from 'src/app/shared/model/tab.model';
import { TabService } from 'src/app/shared/services/tab.service';
import { MiscInvoiceComponent } from 'src/app/transactions/misc-invoice/misc-invoice.component';
import { TourComponent } from 'src/app/transactions/tour/tour.component';
import { ConsolidateInvoiceComponent } from 'src/app/transactions/consolidate-invoice/consolidate-invoice.component';
@Component({
  selector: 'app-b2b-report',
  templateUrl: './b2b-report.component.html',
  styleUrls: ['../gst-report.component.scss']

  // styleUrls: ['./b2b-report.component.scss']
})
export class B2bReportComponent implements OnInit {

  p;
  navigationSubscription
  reInitialise: boolean;
  currentDate =  moment().format('YYYY-MM-DD')
  count = 0
  receiver_count: any = "200" ;
  isAdvancedSearchOpen: boolean = false;
  currentIndex=0
  queryParams: any;
  @Input() gstArr :any;
  @Input() loader;
  @Input() isError;


  fromRoot = true;
  infiniteSelector: string = '.main-panel';

  @ViewChild('tableHeader', {static:true}) tableHeader : ElementRef;
  @Output() callOnScrollDownB2bGst : EventEmitter<any> = new EventEmitter();

  innerHeight: string;
  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public commonService: CommonServiceService,
		private downloadService : DownloadService,
    private router: Router,
	private tabService: TabService) {
    // this.spinner.show()
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  ngOnInit() {
    this.count++
    this.queryParams = this.route.queryParams['value']
	this.setHeight(window.innerHeight)
    this.reInitialise = true
  }


	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setHeight(event.target.innerHeight)
	}

  setHeight(height) {
		if (this.gstArr != undefined) {
			if (height == undefined) {
				height = window.innerHeight
			}
			if (this.gstArr['data'].length <= 9) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = height - 140 + 'px';
			}
		}
  }
  
  initialiseInvites() {
    if (this.reInitialise) {
      this.ngOnInit()
    }
  }
  ngOnDestroy() {

    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  //ALL DATA
  getData() {
    this.commonService.getRequest(`${environment.okountUrl}/reports/gst`).subscribe(res => {
      this.gstArr = res['data']
      let gstData = {
        name: 'b2b',
        data: {
          start_date : res['duration'].from_date,
          end_date : res['duration'].to_date
        },
        company_details : res['company_details']
      }
      this.commonService.showGstPage(gstData)
      this.spinner.hide()
      this.setHeight(window.innerHeight)
    }, err => {
			this.spinner.hide()
      console.error(err)
    })
  }
  //FILTERED DATA
  getFilteredData(queryParams) {
    this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/gst`, queryParams).subscribe(res => {
      this.gstArr = res
      let gstData = {
        name: queryParams.gst_type ? queryParams.gst_type : 'b2b',
        data: {
          start_date : res['duration'].from_date,
          end_date : res['duration'].to_date
        },
        company_details : res['company_details']
      }
      this.commonService.showGstPage(gstData)
      this.spinner.hide()
      this.setHeight(window.innerHeight)

    }, err => {
			this.spinner.hide()
      console.error(err);
    })
  }

  openViewMode(reference, referenceType) {
			let isInvoice = false
			let isMsInvoice = false
			let isGstInvoice = false
			let isPayment = false
			let isReceipt = false
		    let isAdmAcm = false
			let isJv = false
			let isMisc = false
			let isTourInvoice = false
			let isConsolidateInovoice = false

		if (reference != undefined) {
			let url = '#/transactions/'
			let viewMode = 'view'
			if (referenceType == 'invoice') {
				url = '#/transactions/invoice'
				viewMode = 'view'
				if(!reference.includes('MIN') && !reference.includes('MZC') && !reference.includes('GSP') && !reference.includes('GSS')) {
					isInvoice = true 
				} 
			}
			if(referenceType == 'ms-invoice'){
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
				isMsInvoice = true
			}
			if (referenceType == 'invoice' && (reference.includes('MIN') || reference.includes('MZC'))) {
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
				if(!reference.includes('GSP') && !reference.includes('GSS')) {
					isMsInvoice = true
				} 
			}
			if (referenceType == 'invoice' && (reference.includes('GSP') || reference.includes('GSS'))) {
				url = '#/transactions/ms/gst-invoice'
				viewMode = 'view'
				if(!reference.includes('MIN') && !reference.includes('MZC')) {
					isGstInvoice = true
				} 
			}
			if (referenceType == 'credit_note') {
				url = '#/transactions/credit-note'
				viewMode = 'view'
			}
			if (referenceType == 'consolidate') {
				url = `#/transactions/consolidate-invoice/inv/view/${reference}`
				viewMode = 'view'
				isConsolidateInovoice = true
			}
			if (referenceType == 'payment') {
				url = '#/transactions/payment'
				viewMode = 'view'
				isPayment = true
			}
			if (referenceType == 'receipt') {
				url = '#/transactions/receipt'
				viewMode = 'view'
				isReceipt = true
			}
			if (referenceType == 'jv') {
				url = '#/transactions/jv'
				viewMode = 'update'
				isJv = true
			}
			if (referenceType == 'adm' || referenceType == 'acm') {
				url = '#/transactions/adm-acm'
				viewMode = 'view'
				isAdmAcm = true
			}
			if (referenceType == 'misc-invoice' ) {
				url = '#/transactions/ms/misc'
				viewMode = 'view'
				isMisc = true
			}
			if (referenceType == 'tour' ) {
				url = '#/transactions/ms/misc'
				viewMode = 'view'
				isTourInvoice = true
			}
			

			let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${url}/${viewMode}/${reference}`
			if(window.location.protocol != 'file:'){
			let title =''
			let referenceSplit = reference.split('-')
			title += referenceSplit[0]
			title +='...'
			title += referenceSplit[referenceSplit.length-1]
			if(isInvoice) {
			this.tabService.addTab(
				new Tab(InvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
				);
			} else if(isMisc) {
				this.tabService.addTab(
					new Tab(MiscInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
					);
			}else if(isMsInvoice) {
			this.tabService.addTab(
				new Tab(MsInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
				);
			}else if(isConsolidateInovoice) {
				this.tabService.addTab(
					new Tab(ConsolidateInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
					);
			} else if(isGstInvoice) {
			this.tabService.addTab(
				new Tab(GstInvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
				);
			} else if(isPayment) {
			this.tabService.addTab(
				new Tab(V2CreatePaymentComponent, `${title}`, { paymentRef: reference ,mode : 'view' }, reference)
				);
			}
			else if(isReceipt) {
				this.tabService.addTab(
				new Tab(V2CreateReceiptComponent, `${title}`, { receiptRef: reference ,mode : 'view' }, reference)
				);
			} else if(isAdmAcm) 
			{
			this.tabService.addTab(
				new Tab(CreateAdmAcmComponent, `${title}`, { adm_acm_number: reference, mode : 'view' }, reference)
			);

			}else if(isJv) {
			this.tabService.addTab(
				new Tab(AddEntryJvComponent, `${title}`, { jv_code: reference }, reference)
				);
			}else if(isTourInvoice) {
				this.tabService.addTab(
					new Tab(TourComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
					);
				} else {
			window.open(path,'_blank')		
			}
      }else{
        var customeEventHandler = new CustomEvent("handle-window-open", {
					"detail": {
						hashPath : path
					}
				  });
				window.dispatchEvent(customeEventHandler)
      }
		}
  }
  
  // keyboard integration
	// Add #tableHeader on table header

	focusOnTableHeader(){
		if(this.tableHeader != undefined){
			this.tableHeader.nativeElement.focus()
		}
	}
	
	// @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.currentIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex+1}`).focus()
			this.currentIndex = this.currentIndex+1
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.currentIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex-1}`).focus()
			this.currentIndex = this.currentIndex-1
		}
	}
	
	moveToIndex(i){
		document.getElementById(`rowIndex_${i+1}`).focus()
		this.currentIndex = i+1
	}

  callPdfGeneratorApi(fromDate, toDate){
		window.scrollTo(0,0)
		this.spinner.show()
		this.spinner.show()
		let url = 'print-pdf/pdf-gst-b2b'
		let queryString = location.hash.split('?')[1]
		if (queryString != undefined) {
			url = `${url}?${queryString}`
		}
		this.downloadService.callPdfGeneratorApi(`B2B GST ${fromDate} - ${toDate}.pdf`,url,{})	
		this.spinner.hide()
	}

	async onScrollDown(event) {
		this.callOnScrollDownB2bGst.emit()
	}

	onScrollUp(event) {}

}
