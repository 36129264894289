<div class="notification-container fade show">
    <div class="notification-header d-flex align-item-center justify-content-between">
        <div class="d-flex align-item-center">
            <!-- <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/menues/active-notification.svg" alt="" srcset="" class="mr-10"> -->
            <img src="{{commonService.aws_asset_url}}/_assets/icons/bells.svg" width="15px" height="" alt="" srcset="" class="mr-10"/>
            <h4 class="notification-title text-bold text-black">
                 Notifications
            </h4>
        </div>
        <span class="notification-close cursor" (click)="closeNotification()">&times;</span>
    </div>
    <div class="notification-body" *ngIf="notificationArr">
        <!-- card 1 -->
        <div [ngClass]="{'invoice-notify-card': item.type == 'invoice'}" class="notify-card  align-items-center d-flex mt-1 notification-card w-100 pb-10 pt-20 cursor" *ngFor="let item of notificationArr"
            (click)="openRelatedPath(item.meta_info)">
            <div class="notification-icon">
                <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/orange-invoice.svg" width="100%" height="100%"
                    style="object-fit: contain;" alt="" srcset="">
            </div>
            <div class="notification-content"> 
                <div class="d-flex justify-content-between align-item-center mb-1">
                <div>
                    <small class="text-size-10 notify-tag " [ngClass]="{'invoice-tag': item.type == 'invoice'}" >Credit Note </small>
                </div>
                <div class="notification-time transition">
                    <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/grey-time.svg" width="10px" height="10px"
                        style="object-fit: contain;" alt="" srcset="">
                        <small class="text-size-12 ml-1">{{item.timestamp | date : 'dd/MM/yyyy HH:mm'}}</small>
                    </div>
            </div>
                <p class="notification-text">{{item.message}}</p>
               
            </div>
        </div>
    </div>
    <!-- <div class="notification-footer mt-2">
        <p class="see-all m-0 text-center">See all notifications</p>
    </div> -->
    <div *ngIf="!notificationArr" class="align-items-center d-flex mt-1 notification-card w-100 cursor">
        <div class="col-1 notification-icon">

        </div>
        <div class="col-11 notification-content">
            <span class="notification-text">Loading</span>
        </div>
    </div>
</div>