import { Component, OnInit, Output, EventEmitter, ViewChildren, QueryList, ElementRef, Input,ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { InvoiceRequestBuilderRefundService } from '../services/invoice-request-builder-refund.service';
import { InvoiceRefundService } from '../services/invoice-refund.service';
import { SectorRefundService } from '../services/sector-refund.service';
import { TotalFareRefundModel } from '../models/models/total-fare-refund-model';
import { Subscription } from 'rxjs';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-traveller-info-refund',
  templateUrl: './traveller-info-refund.component.html',
  styleUrls: ['../flight-refund.component.scss']
})
export class TravellerInfoRefundComponent implements OnInit {
	corporate_employee: string = "false"
	discount_add: string = "false"
	expandTraveller: boolean = false
	maximizedSection: boolean = false
	supplier_trans: string = "false"
	valid: boolean;
	showTravellerIndex: any;
	bookingSupplierArr: any = [];
	employeeArr: any =[]
	fareSubscription : Subscription;
	@Output() callSaveTraveller: EventEmitter<any> = new EventEmitter();

	@ViewChildren('openModal') openModal: QueryList<ElementRef>;
	@ViewChild("supplierAc", { static: true }) supplierAc;
	@ViewChild('ledgerCardAc', {static: true}) ledgerCardAc;
	@ViewChild('associateSupplierAc', {static: true}) associateSupplierAc;
	@Input() invoiceNumber
	@Input() invoice_root_data
	invoiceSummary: any;
	showInvoiceSummaryDetail = false
	count = 0
	selectedTravellerType: string;
	generalAccountArr:any = []
	generalFilteredData: any = []
	keyword = 'name';
	initialValue = ''
	@Input() sectorService;
	isTenantIndian: boolean = true;
	currencyCode : string = "INR";
	nativeLanguageCode = '';
	currentSelectedAffiliate: any;
	mealAssist: string = 'false'
	replicateClientXXLL	: string = "false"
	associateSupplierArr: any;
	associateSupplierFilteredData: any;
	associate_supp; string = "false"

	
	constructor(
		// public sectorService: SectorRefundService,

		private fb: FormBuilder,
		public requestBuilder: InvoiceRequestBuilderRefundService,
		public invoiceService: InvoiceRefundService,
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,

	) { }

	async ngOnInit() {
		let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
		let inv_config = globalSettingConfig.filter(el=>el.group=='TRANSACTION')
		if(inv_config.length){
			let corporate_employee = inv_config[0].json_doc.value.filter(el=>el.key=='corporate_employee')
			if(corporate_employee.length){
				this.corporate_employee = corporate_employee[0].value
			}
			let associateSuppConfig = inv_config[0].json_doc.value.filter(el=>el.key=='Associate Supplier')
			if(associateSuppConfig.length){
				this.associate_supp = associateSuppConfig[0].value
			}
		}
		if(inv_config.length){
			let payable_trans = inv_config[0].json_doc.value.filter(el=>el.key=='PAYABLE TRANSACTION')
			if(payable_trans.length){
				this.supplier_trans = payable_trans[0].value
			}
			let replicateXXLCOnfig = inv_config[0].json_doc.value.filter(el=>el.key=='Replicate XXl')
			if(replicateXXLCOnfig.length){
				this.replicateClientXXLL = replicateXXLCOnfig[0].value
			}
			
		}
		if(inv_config.length){
			let discount_config = inv_config[0].json_doc.value.filter(el=>el.key=='DISCOUNT')
			if(discount_config.length){
				this.discount_add = discount_config[0].value
			}
		}
		let flight_config = globalSettingConfig.filter(el=>el.group=='FLIGHT_INV')
		if(flight_config.length){
			let ssr_change = flight_config[0].json_doc.value.filter(el=>el.key=='add_bagg_to_meal_assist')
			if(ssr_change.length){
				this.mealAssist = ssr_change[0].value
			}
		}

		this.isTenantIndian = this.commonService.checkTenantIndian()
		console.log("isTenantIndian",this.isTenantIndian)
		this.getBookingSupplier('',  null)
		this.getGeneralAccounts()
		this.fareSubscription = this.sectorService.componentMethodCalled$.subscribe(data => {
			this.calculateFareFromApi(data,false);
		}, error => {
		})
		this.getAccountingCompany()

	}
	ngOnDestroy() {
		this.fareSubscription.unsubscribe();
	}
	async showTravellerFull(i) {
		if (i != this.showTravellerIndex) {
			this.showTravellerIndex = i
			
			if (this.sectorService.travellerForm.value.travellerList[i] != undefined && this.sectorService.travellerForm.value.travellerList[i].isCancel == false) {
				this.showTravellerFull(i+1)
			}
		}
		let traveller = this.sectorService.travellerForm.value.travellerList[i]
		let travellerInvoiceSummary = await this.getTravellerInvoiceSummary(traveller.prev_ticket_no ? traveller.prev_ticket_no : traveller.txtTicketNumber)
		let index = (i)*(traveller.totalIndexCount)+9
		this.commonService.selectedInput.next(index)
		console.log('Selected Traveller : ',this.showTravellerIndex)
	}
	// getBookingSupplier() {
	// 	try {
	// 		this.invoiceService.searchAccount(`util/getsupplierairline`).subscribe(data => {
	// 			this.bookingSupplierArr = data
	// 			console.log('Booking Supplier : ', this.bookingSupplierArr)
	// 		}, err => {
	// 			throw new Error(JSON.stringify(err));
	// 		})
	// 	} catch (error) {
	// 		console.log('Error while getting Booking Suppliers : ', error)

	// 	}
	// }

	async getBookingSupplier(event, currentIndex) {
		let val = event && event.target.value

		if(event && currentIndex != null) {
			if(!val || val == null || val == undefined) {
				const traveller = this.sectorService.travellerForm.controls.travellerList.controls[currentIndex];
				traveller.controls['txtbookingSupplier'].setValue('')
				traveller.controls['txtbookingSupplierCode'].setValue('')
				traveller.controls['txtbookingSupplierIsBranch'].setValue(false)
				let reqObj = {
					clientCode : this.sectorService.affiliate_code,
					airlineCode : this.sectorService.invoiceForm.value.airline,
					date : this.invoice_root_data.invdate ? this.invoice_root_data.invdate : null,
				}
				let resp = await this.sectorService.getDiscRecallSetting(reqObj,currentIndex)
				setTimeout(() => {
					this.calculateFareFromApi(0)
				}, 100);
			}
		};

		try {
			let url = 'util/getsupplierairline'
			if(val && val.length > 2){
				url = `util/getsupplierairline?name=${val}`
			}
			this.invoiceService.searchAccount(url).subscribe(data => {
				this.bookingSupplierArr = data
				console.log('Booking Supplier : ', this.bookingSupplierArr)
			}, err => {
				throw new Error(JSON.stringify(err));
			})
		} catch (error) {
			console.log('Error while getting Booking Suppliers : ', error)

		} 


	}

	setBookingSupplier(event,index){
		let invoiceType = this.sectorService.invoiceForm.controls.invoiceType.value
		if (invoiceType == 'C') {
			this.sectorService.travellerForm.controls.travellerList.controls[index].controls.txtbookingSupplier.setValidators(Validators.required)
			this.sectorService.travellerForm.controls.travellerList.controls[index].controls.txtbookingSupplierName.setValidators(Validators.required)

		}else{
			this.sectorService.travellerForm.controls.travellerList.controls[index].controls.txtbookingSupplier.setValidators(null)
			this.sectorService.travellerForm.controls.travellerList.controls[index].controls.txtbookingSupplierName.setValidators(null)
		}
		this.sectorService.travellerForm.controls.travellerList.controls[index].controls.txtbookingSupplierIsBranch.setValidators(null)
		// this.sectorService.travellerForm.controls.travellerList.controls[index].controls.txtbookingSupplier.setValue(null)
		// this.sectorService.travellerForm.controls.travellerList.controls[index].controls.txtbookingSupplierName.setValue(null)

		this.calculateFareFromApi(this.sectorService.fareList.length-1)
		this.sectorService.travellerForm.controls.travellerList.controls[index].controls.ledger_card_no.setValue('')
		this.sectorService.travellerForm.controls.travellerList.controls[index].controls.ledger_card_name.setValue('')
		if(event.target.value == 'OUR_CC'){
			this.getGeneralAccounts()
		}

		if(event.target.value != 'OUR_CC') {
			let traveller = this.sectorService.travellerForm.controls.travellerList.controls[index]
			traveller.controls.ledger_card_name.setValue('')
			traveller.controls.ledger_card_no.setValue('')
		}

	}
	async selectBookingSupplier(nzEvent, currentIndex ){
		let nzValue = nzEvent.nzValue;
		let event = nzValue;

		
		if(event){
			let travellersControl = this.sectorService.travellerForm.controls.travellerList.controls
			for (const traveller of travellersControl) {
				let index = this.sectorService.travellerForm.controls.travellerList.controls.indexOf(traveller)
				if(index >= currentIndex){
				traveller.controls['txtbookingSupplier'].setValue(event.account_code)
				traveller.controls['txtbookingSupplierName'].setValue(event.name)
				traveller.controls['txtbookingSupplierIsBranch'].setValue(event.is_branch)
				traveller.controls['associate_supplier'].setValue('')
			traveller.controls['associate_supplier_name'].setValue('')
				let reqObj = {
					clientCode : this.sectorService.affiliate_code,
					airlineCode : event.account_code,
					date : this.invoice_root_data.invdate ? this.invoice_root_data.invdate : null,
				}
				let resp = await this.sectorService.getDiscRecallSetting(reqObj,index)
				this.calculateFareFromApi(0)
				}
				
			}
			
		}else{
			const traveller = this.sectorService.travellerForm.controls.travellerList.controls[currentIndex];
			traveller.controls['txtbookingSupplier'].setValue('')
			traveller.controls['txtbookingSupplierName'].setValue('')
			traveller.controls['txtbookingSupplierIsBranch'].setValue(false)
			traveller.controls['associate_supplier'].setValue('')
			traveller.controls['associate_supplier_name'].setValue('')
			let reqObj = {
				clientCode : this.sectorService.affiliate_code,
				airlineCode : this.sectorService.invoiceForm.value.airline,
				date : this.invoice_root_data.invdate ? this.invoice_root_data.invdate : null,
			}
			let resp = await this.sectorService.getDiscRecallSetting(reqObj,currentIndex)
			setTimeout(() => {
				this.calculateFareFromApi(0)
			}, 100);
		}
	}

	selectAssociateSupplierAccount(event, currentIndex){
		if(event && event.nzValue) {
			let value = event.nzValue;
			if(value.name) {
				this.sectorService.travellerForm.controls.travellerList.controls[currentIndex].controls['associate_supplier_name'].setValue(value.name)
			}
			if(value.account_code) {
				this.sectorService.travellerForm.controls.travellerList.controls[currentIndex].controls['associate_supplier'].setValue(value.account_code)
			}
		}
	}

	getLedgerCardName(ledger_card_no){
		if(this.generalAccountArr && this.generalAccountArr.length>0){
			for(let item of this.generalAccountArr){
				if(item['account_code']==ledger_card_no){
					return item.name
				}
			}
		}

		return ''

	}

	getGeneralAccounts() {
		try {
			this.invoiceService.getMethod(`general/bank-od-cc`).subscribe(data => {
				this.generalAccountArr = data
				this.generalFilteredData = data;
			}, err => {
				this.generalAccountArr = []
				this.generalFilteredData = []
				throw new Error(JSON.stringify(err));
			})
		} catch (error) {
			console.log('Error while getting Booking Suppliers : ', error)

		}
	}
	maximizeSection(i) {
		this.maximizedSection = true
		this.showTravellerIndex = i
		this.showTravellerFull(i)
		// this.saveTravellers();

		
	}
	minimizeSection() {
		this.maximizedSection = false
		this.showTravellerIndex = null
		// this.saveTravellers();
		this.commonService.selectedInput.next(this.sectorService.lastIndexOfTraveller + 3)
	}




	addTraveller() {
		this.sectorService.addTraveller()
	}
	removeTraveller(i) {
		this.sectorService.travellerList.removeAt(i)
		this.calculateFareFromApi(this.sectorService.fareList.length-1,true)
	}

	get DynamicFormControls() {
		const travellerList = <FormArray>this.sectorService.travellerForm.get("travellerList");
		return travellerList;
	}


	moveToNextTraveller(i) {
		this.showTravellerFull(i+1)
		// let traveller = this.sectorService.travellerForm.value.travellerList[i+1]
		// //fix me
		// let index = (i+1)*(traveller.totalIndexCount)+7
		// this.commonService.selectedInput.next(index)
		this.calculateFareFromApi(this.sectorService.fareList.length-1,true)
	}
	moveToPreviousTraveller(i) {
		this.showTravellerFull(i-1)
		this.calculateFareFromApi(this.sectorService.fareList.length-1,true)
	}

	replicateFormData(index) {
		let tempFormValue = Object.assign({}, this.sectorService.travellerForm.value.travellerList[index])
		let travellerListLength = this.sectorService.travellerForm.controls.travellerList.controls.length
		let currentTravellerType = tempFormValue.selType
		let nextIndex = 0;
		let flag = false
		for (let i = 0; i < travellerListLength; i++) {
			const travellerList = this.sectorService.travellerForm.controls.travellerList.controls[i];
			if (tempFormValue.travellerStatus == travellerList.value.travellerStatus && travellerList.value.selType == tempFormValue.selType) {
				if (!travellerList.value.isConjuction) {
					this.setTravellerData(travellerList, tempFormValue)
				}
			}
			if (currentTravellerType == 'ADT' && travellerList.value.selType == 'CHD' && flag == false ) {
				nextIndex = i;
				flag = true;
			}
			if ((currentTravellerType == 'ADT' || currentTravellerType == 'CHD') && travellerList.value.selType == 'INF' && flag == false ) {
				nextIndex = i;
				flag = true;
			}
			this.calculateTotal(i)
		}
		this.saveTravellers();
		if (flag == false) {
			this.minimizeSection();
		}else{
			this.maximizeSection(nextIndex)
		}
	}
	setTravellerData(travellerList, tempFormValue) {
		let keys = Object.keys(tempFormValue)
		for (let i = 0; i < keys.length; i++) {
			const element = keys[i];
			if (element != 'selType' && element != 'selTitle' && element != 'txtPassengerName' && element != 'txtTicketNumber' && element != 'sectorList' &&  element != 'isCancel' && element != "txtAddons" && element != 'txtAmount') {
				travellerList.controls[element].setValue(tempFormValue[element])
			}
		}
	}

	calculateTotal(i) {
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let baseFare = Number(travellerData['txtBaseFare'])
		let YQ = Number(travellerData['txtYQ'])
		let YR = Number(travellerData['txtYR'])
		let K3 = Number(travellerData['txtK3'])
		let OC = Number(travellerData['txtOC'])
		let OT = Number(travellerData['txtOT'])
		let txtAddons = this.getAddonsTotal(travellerData['sectorList'])
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let addons = this.getLocalAddonsTotal(travellerData['sectorList'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total.toFixed(2))
		let total = baseFare + YQ + YR + K3 + OC + OT + txtAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(txtAddons.toFixed(2))
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total.toFixed(2))
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(addons.toFixed(2))

		this.calculateCommission(i)
		this.calculateFareFromApi(this.sectorService.fareList.length-1)
	}

	getAddonsTotal(sectors) {
		let totalAmount = 0
		for (const key in sectors) {
			if (sectors.hasOwnProperty(key)) {
				const element = sectors[key];
				if (element['txtSeatCharges'] != "") {
					totalAmount += Number(element['txtSeatCharges']) /  Number(this.invoice_root_data.currency_rate||1)
				}
				if (element['txtMealCharges'] != "") {
					totalAmount += Number(element['txtMealCharges']) /  Number(this.invoice_root_data.currency_rate||1)
				}
				if (element['txtBagCharges'] != "") {
					totalAmount += Number(element['txtBagCharges']) /  Number(this.invoice_root_data.currency_rate||1)
				}
				if (element['txtAdditionalBaggageCharges'] != "") {
					totalAmount += Number(element['txtAdditionalBaggageCharges']) /  Number(this.invoice_root_data.currency_rate||1)
				}
				if (element['txtAncillaryCharges'] != "") {
					totalAmount += Number(element['txtAncillaryCharges']) /  Number(this.invoice_root_data.currency_rate||1)
				}
			}
		}
		return totalAmount
	}

	setFare(event,formControlName){
		if (event.target.checked == true) {
			let tempFormValue = Object.assign({}, this.sectorService.travellerForm.value.travellerList[0])
			let travellerListLength = this.sectorService.travellerForm.controls.travellerList.controls.length
			for (let i = 0; i < travellerListLength; i++) {
				const traveller = this.sectorService.travellerForm.controls.travellerList.controls[i];
				if (traveller.value.isConjuction == false) {
					traveller.controls[formControlName].setValue(tempFormValue[formControlName])
				}
			}

			this.calculateFareFromApi(0)
		}
	}



	async calculateCommission(i) {
		if (this.sectorService.travellerForm.controls.travellerList.value[i] == undefined) {
			return false;
		}
		let sectorList = this.sectorService.travellerForm.controls.travellerList.value[i].sectorList
		let sectorLength = 0
		for (const key in sectorList) {
			if (sectorList.hasOwnProperty(key)) {
				const element = sectorList[key];
				if (element.isCancelRequest != undefined && element.isCancelRequest == true) {
					sectorLength ++
				}
			}
		}
		let isConjuction = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.isConjuction.value

		let clientIataType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientIataType.value
		let clientPlbType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientPlbType.value
		let clientCbType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientCbType.value
		let clientDsType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientDsType.value


		let supplierIataType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierIataType.value
		let supplierPlbType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierPlbType.value
		let supplierCbType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierCbType.value
		let supplierDsType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierDsType.value

		await this.getAmountForComissionCalculatation(isConjuction, 'IATA', 'CLIENT', clientIataType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'PLB', 'CLIENT', clientPlbType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'CASHBACK', 'CLIENT', clientCbType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'DISCOUNT', 'CLIENT', clientDsType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'IATA', 'SUPPLIER', supplierIataType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'PLB', 'SUPPLIER', supplierPlbType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'CASHBACK', 'SUPPLIER', supplierCbType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'DISCOUNT', 'SUPPLIER', supplierDsType, i,sectorLength)
	}


	getAmountForComissionCalculatation(isConjuction, type, csType, cType, i,sectorLength) {
		let loopTotal = 0;
		let totalIata = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierIataAmount.value)
		let totalPlb = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierPlbAmount.value)
		let totalCB = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierCbAmount.value)
		let totalDS = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierDsAmount.value)

		let totalIataCus = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientIataAmount.value)
		let totalPlbCus = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientPlbAmount.value)
		let totalCBCus = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientCbAmount.value)
		let totalDSCus = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientDsAmount.value)

		let supplierIataValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierIataValue.value)
		let supplierPlbValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierPlbValue.value)
		let supplierCBValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierCbValue.value)
		let supplierDSValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierDsValue.value)

		let clientIataValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientIataValue.value)
		let clientPlbValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientPlbValue.value)
		let clientCBValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientCbValue.value)
		let clientDSValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientDsValue.value)

		if (!isConjuction) {
			if (cType == "RB") {
				loopTotal += Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.value);
			}
			else if (cType == "RY") {
				loopTotal += Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.value) +
					Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYQ.value);
			}
			else if (cType == "RR") {
				loopTotal += Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.value) +
					Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYR.value);
			}
			else if (cType == "RQ") {
				loopTotal += Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYQ.value);
			}
			else if (cType == "RT") {
				loopTotal += Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.value) +
					Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYQ.value) +
					Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYR.value) +
					Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtK3.value) +
					Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtClientSC2Amount.value)
			}
			else if (cType == "VL" || cType == "VLN") {
				loopTotal = 1
			}
		}
		if (csType == "CLIENT") {
			if (type == "IATA" ) {
				totalIataCus = (Number(loopTotal) / 100) * Number(clientIataValue);
				if (cType == "VL") {
					totalIataCus = Number(totalIataCus)
				}
				this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientIataAmount.setValue(totalIataCus.toFixed(2))
			}
			else if (type == "PLB" ) {
				totalPlbCus = (Number(loopTotal) - Number(totalIataCus)) * Number(clientPlbValue) / 100;
				if (cType == "VL") {
					totalPlbCus = Number(totalPlbCus)
				}
				this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientPlbAmount.setValue(totalPlbCus.toFixed(2))
			}
			else if (type == "CASHBACK" ) {
				totalCBCus = ((Number(loopTotal) - (Number(totalIataCus) + Number(totalPlbCus))) / 100) * Number(clientCBValue) * sectorLength;
				if (cType == "VL") {
					totalCBCus = Number(clientCBValue) * sectorLength
				}
				if (cType == "VLN") {
					totalCBCus = Number(clientCBValue)
				}
				this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientCbAmount.setValue(totalCBCus.toFixed(2))
			}
			else if (type == "DISCOUNT" ) {
					totalDSCus = Number(clientDSValue) * sectorLength
				
				if (cType == "VLN") {
					totalDSCus = Number(clientDSValue)
				}
				this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientDsAmount.setValue(totalDSCus.toFixed(2))
			}
		}
		else if (csType == "SUPPLIER") {
			if (type == "IATA" ) {
				totalIata = (Number(loopTotal) / 100) * Number(supplierIataValue);
				// FIXME : NNED TO REMOVE INFANT TOTAL 
				if (cType == "VL") {
					totalIata = Number(totalIata)
				}
				totalIata = Math.round(totalIata)
				this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierIataAmount.setValue(totalIata.toFixed(2))

			}
			else if (type == "PLB" ) {
				totalPlb = (Number(loopTotal) - Number(totalIata)) * Number(supplierPlbValue) / 100;
				if (cType == "VL") {
					totalPlb = Number(totalPlb)
				}
				this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierPlbAmount.setValue(totalPlb.toFixed(2))

			}
			else if (type == "CASHBACK" && loopTotal != 0) {
				totalCB = ((Number(loopTotal) - (Number(totalIata) + Number(totalPlb))) / 100) * Number(supplierCBValue)*sectorLength;
				if (cType == "VL") {
					totalCB = Number(supplierCBValue)*sectorLength
				}
				if (cType == "VLN") {
					totalCB = Number(supplierCBValue)
				}
				this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierCbAmount.setValue(totalCB.toFixed(2))

			}else if (type == "DISCOUNT" ) {
					totalDS = Number(supplierDSValue)*sectorLength
				if (cType == "VLN") {
					totalDS = Number(supplierDSValue)
				}
				this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierDsAmount.setValue(totalDS.toFixed(2))
			}

		}
	}


	async calculateFareFromApi(data,manual=false) {
		
		let i = data;
		let type  = '';
		let url = `creditNote/fare`
		if(data.hasOwnProperty('index')) {
			i = data['index'];
			type = data['type'];
			if (type == 'invoice' || (this.sectorService.fareList[i].invoice_no && (this.sectorService.fareList[i].invoice_no.toString().includes('INV') || this.sectorService.fareList[i].invoice_no.toString().includes('IW')))) {
				url  = `invoice/fare`
			}
			else {
				url  = `creditNote/fare`
			}
		}		
		else if (i == 0) {
			url = `invoice/fare`
			if (this.sectorService.fareList[i].toString().includes('CRN') || this.sectorService.fareList[i].toString() == 'Credit Note'  ||  (this.sectorService.fareList[i].invoice_no &&   this.sectorService.fareList[i].invoice_no.toString().includes('CRN'))) {
				url  = `creditNote/fare`
			}
		}
		let date = this.invoice_root_data.invdate
		if (url.includes('creditNote')) {
			date = this.invoice_root_data.refund_date
		}
		// if (this.sectorService.travellerForm.value.travellerList.length == 0) {
		// 	return false
		// }
		let ref_no = ""
		let fareObj = this.requestBuilder.createFareObj(this.sectorService.travellerForm.value,this.sectorService.invoiceForm.value,this.sectorService.affiliate_code,date,this.invoice_root_data.invdate,ref_no,this.invoice_root_data.rcm_applicable,this.invoice_root_data.no_tds_return_client)
		// if (i > 0) {

		if(type == 'invoice' || ((this.sectorService.fareList[i].toString().includes('CRN') || (this.sectorService.fareList[i].invoice_no && this.sectorService.fareList[i].invoice_no.toString().includes('CRN'))) && this.sectorService.invoiceTravellerForm[Number(i)-1] != undefined)) {
			let travellerForm = this.sectorService.invoiceTravellerForm[Number(i)]
			if (travellerForm == undefined) {
				travellerForm = this.sectorService.invoiceTravellerForm[Number(i-1)]
			}
			fareObj = this.requestBuilder.createFareObj(travellerForm.value,this.sectorService.invoiceForm.value,this.sectorService.affiliate_code,date,this.invoice_root_data.invdate,ref_no,this.invoice_root_data.rcm_applicable,this.invoice_root_data.no_tds_return_client)
			if (data.use_form == 'travellerForm' || manual) {
				fareObj = this.requestBuilder.createFareObj(this.sectorService.travellerForm.value,this.sectorService.invoiceForm.value,this.sectorService.affiliate_code,date,this.invoice_root_data.invdate,ref_no,this.invoice_root_data.rcm_applicable,this.invoice_root_data.no_tds_return_client)
			}
		}
		// }
		if (this.sectorService.fareList[i] && this.sectorService.fareList[i].toString()=='Credit Note') {
			fareObj = this.requestBuilder.createFareObj(this.sectorService.travellerForm.value,this.sectorService.invoiceForm.value,this.sectorService.affiliate_code,date,this.invoice_root_data.invdate,ref_no,this.invoice_root_data.rcm_applicable,this.invoice_root_data.no_tds_return_client)
		}
		
		if (fareObj.length > 0) {
			for (const key in fareObj) {
				if (fareObj.hasOwnProperty(key)) {
					this.calculateCommission(key)
				}
			}
		}
		console.log(`fareOBj with url ${url}  call at index ${i} : `,fareObj)
		let fareREs = await this.getFare(url ,fareObj , i)
	}

	getFare (url , fareObj, i) {
		return new Promise((resolve, reject) => {
			this.invoiceService.postMethod(url, fareObj).subscribe(res => {
				console.log(`response for url ${url} : `,res)
				if (this.sectorService.totalFareModel.length == 0) {
					this.sectorService.totalFareModel.push(new TotalFareRefundModel(res))
				}else{
					this.sectorService.totalFareModel[i] = new TotalFareRefundModel(res)
				}
				if (this.sectorService.fareList[i].toString()=='Credit Note') {
					this.sectorService.totalFareModel[i] = new TotalFareRefundModel(res)
					
				}
				resolve(res)
				this.sectorService.saveProductSource.next(true)
				
			},
				err => {
					resolve("")
				})
			})

	}

	async saveTravellers() {
		
		// let getAmount = 	await this.calculateFareFromApi(this.sectorService.fareList.length-1,true)
		let requestObj = {
			travellers : this.sectorService.travellerForm.value,
			sectors : this.sectorService.sectorForm.value,
			invoiceForm : this.sectorService.invoiceForm.value
		}
		this.callSaveTraveller.emit(requestObj)
		
		// let travellersObj = await  this.requestBuilder.createTravellers(this.sectorService.travellerForm.value)
		// var url = window.location.href.slice(window.location.href.indexOf('?') + 1);
		// if (url.split('=')[1] != undefined) {
		// 	let invNo = url.split('=')[1]
		// 	let requestObj = {
		// 		"json_doc" : travellersObj,
		// 		"uuid" : invNo
		// 	}
		// 	this.invoiceService.postMethod('invoice/traveller/save',requestObj).subscribe(res=>{
		// 		console.log('Travellers save response - SUCCESS : ',res)
		// 	},err=>{
		// 		console.log('Travellers save response - ERROR : ',err)
		// 	})
		// }
	}

	selectTraveller(event,index){
		if (index == 'all') {
			for (let i = 0; i < this.sectorService.travellerForm.controls.travellerList.controls.length; i++) {
				const element = this.sectorService.travellerForm.controls.travellerList.controls[i];
				if (element.value.travellerStatus != 'full_cancelled') {
					element.controls.isCancel.setValue(event.target.checked)
				}
			}
		}else{
			this.sectorService.travellerForm.controls.travellerList.controls[index].controls.isCancel.setValue(event.target.checked)
		}
		this.calculateFareFromApi(this.sectorService.fareList.length-1,true)
		this.saveTravellers()
	}

	isCancelled(ticketNo,travellerIndex){
		let isCancelled = this.sectorService.cancelledTicket.filter(option => {
			return option.ticket_no == ticketNo;
		});
		if (isCancelled.length > 0) {
			this.sectorService.travellerForm.controls.travellerList.controls[travellerIndex].controls.isCancel.setValue(false)
			return true
		}else{
			return false
		}
	}

	returnToInvoice(){
		this.minimizeSection()
		this.calculateFareFromApi(this.sectorService.fareList.length-1,true)
		this.saveTravellers()
	}

	setTabIndex(event){
		let travellers = this.sectorService.travellerForm.value.travellerList
		if (travellers.length == 1) {
			this.commonService.selectedInput.next(Number(event.target.tabIndex)+2)
		}
	}

	openReplicateModal(e: Event,index) {
		e.stopPropagation();
		if (this.openModal["_results"][0]) {
			this.openModal["_results"][0].nativeElement.click()
			if (this.sectorService.travellerForm.value.travellerList[index]) {
				if (this.sectorService.travellerForm.value.travellerList[index].selType == 'ADT') {
					this.selectedTravellerType = 'Adult'
				}else if (this.sectorService.travellerForm.value.travellerList[index].selType == 'CHD') {
					this.selectedTravellerType = 'Child'
				}else if (this.sectorService.travellerForm.value.travellerList[index].selType == 'INF') {
					this.selectedTravellerType = 'Infants'
				}
			}
			this.commonService.selectedInput.next(this.sectorService.lastIndexOfTraveller+1)
		}
	  }

	  getTravellerInvoiceSummary(ticket_no){
		try {
			this.showInvoiceSummaryDetail = false
			return new Promise((resolve, reject) => {
				this.commonService.getRequest(`${environment.okountUrl}/creditNote/traveller-summary?invoice-code=${this.invoiceNumber}&ticket-number=${ticket_no}`).subscribe(res=>{
					this.invoiceSummary = res
					console.log(res)
					resolve(res)
				},err=>{
					console.log(err)
					resolve(err)
				})
			})
		} catch (error) {
			
		}
		
	}

	showInvoiceSummary(){
		this.showInvoiceSummaryDetail = !this.showInvoiceSummaryDetail
	}

	viewPrintScreen(reference,i){
		if (reference != 'Credit Note') {
			if (i==0) {
				if (reference.includes('CRN')) {
					let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}any#/transactions/print-credit-note/${reference};type=flight`;
					if(window.location.protocol != 'file:'){
						window.open(path,'_blank')
					}else{
						var customeEventHandler = new CustomEvent("handle-window-open", {
							"detail": {
								hashPath : path
							}
						  });
						window.dispatchEvent(customeEventHandler)
					}
				}else{
					let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/invoice-view;searchId=${reference};type=flight`;
					if(window.location.protocol != 'file:'){
						window.open(path,'_blank')
					}else{
						var customeEventHandler = new CustomEvent("handle-window-open", {
							"detail": {
								hashPath : path
							}
						  });
						window.dispatchEvent(customeEventHandler)
					}
				}
			}else{
				let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/print-credit-note/${reference};type=flight`;
				if(window.location.protocol != 'file:'){
					window.open(path,'_blank')
				}else{
					var customeEventHandler = new CustomEvent("handle-window-open", {
						"detail": {
							hashPath : path
						}
					  });
					window.dispatchEvent(customeEventHandler)
				}
			}
		}
	}

	checkTicketNumber(ticketNumber,i){

		this.commonService.getRequest(`${environment.okountUrl}/invoice/check-ticket-number?ticket_no=${ticketNumber}&reference_type=credit_note`).subscribe((res : any)=>{
			
			if (res.length > 0) {
				let messageObj = {
					isInvalid : true,
					showMessage : true,
					alertType : 'warning-box',
					message : `Ticket Number ${ticketNumber} already exists in invoice ${res[0].invoice_no} .`
				}
				this.sectorService.travellerForm.controls.travellerList[i]
			}else{
				let messageObj = {
					isInvalid : false,
					showMessage : false,
					alertType : 'warning-box',
					message : ``
				}
				this.sectorService.travellerForm.controls.travellerList[i]
			}
			
			
		},err=>{
			console.log(err)
		})
	}

	calculateServiceCharge(i, chargeAmountContorl, chargeValueControl) {
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls[chargeAmountContorl].setValue(this.sectorService.travellerForm.controls.travellerList.controls[i].controls[chargeValueControl].value)

	}
	closeWindow() {
		window.close();
	}
	onFocused(event) {
		this.bookingSupplierArr = []
	}
	focusSupplierAc() {
		if (this.supplierAc != undefined) {
		  this.supplierAc.focus();
		}
	  }
	  focusAssociateSupplierAc(index) {
		if (this.associateSupplierAc != undefined) {
			this.associateSupplierAc.focus()
		}
	  }

	focusGeneralAc() {
	if (this.ledgerCardAc != undefined) {
		this.ledgerCardAc.focus();
	}
	}


	async selectGeneralAccount(nzEvent,currentIndex){
		let nzValue = nzEvent && nzEvent.nzValue;
		let event =  nzValue;
		if(event) {
			let traveller = this.sectorService.travellerForm.controls.travellerList.controls[currentIndex]
			if(traveller) {
				traveller.controls['ledger_card_name'].setValue(event.name)
				traveller.controls['ledger_card_no'].setValue(event.account_code)
			}
		}
	}

	filterGeneralAccounts(event, currentIndex) {
		let value = event.target.value;
		if(value) {
			let accounts = this.generalAccountArr.filter(account => (account.name.includes(value) || account.account_code.includes(value)))
			this.generalFilteredData = accounts;
		} else {
			this.generalFilteredData = this.generalAccountArr;
			let traveller = this.sectorService.travellerForm.controls.travellerList.controls[currentIndex]
			if(traveller) {
				traveller.controls['ledger_card_name'].setValue('')
				traveller.controls['ledger_card_no'].setValue('')
			}
		}
	}


	onGeneralFocused() {
		this.generalAccountArr = []
	}

	calculateYQ(i){
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let txtYQ = Number(travellerData['localYQ']) / Number(this.invoice_root_data.currency_rate)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYQ.setValue(txtYQ.toFixed(2))

		
		let baseFare = Number(travellerData['txtBaseFare'])
		let YR = Number(travellerData['txtYR'])
		let K3 = Number(travellerData['txtK3'])
		let OC = Number(travellerData['txtOC'])
		let OT = Number(travellerData['txtOT'])
		let addons = this.getAddonsTotal(travellerData['sectorList'])
		let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(Number(Number(localAddons).toFixed(2)))
		let total = baseFare + txtYQ + YR + K3 + OC + OT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(addons.toFixed(2))
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total.toFixed(2))
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total.toFixed(2))
		this.calculateCommission(i)
		this.calculateFareFromApi(0)

	}
	calculateBase(i){
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let txtBaseFare = Number(travellerData['localBaseFare']) / Number(this.invoice_root_data.currency_rate)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.setValue(txtBaseFare.toFixed(2))


		let YQ = Number(travellerData['txtYQ'])
		let YR = Number(travellerData['txtYR'])
		let K3 = Number(travellerData['txtK3'])
		let OC = Number(travellerData['txtOC'])
		let OT = Number(travellerData['txtOT'])
		let addons = this.getAddonsTotal(travellerData['sectorList'])
		let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(Number(Number(localAddons).toFixed(2)))
		let total = txtBaseFare + YQ + YR + K3 + OC + OT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(addons.toFixed(2))
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total.toFixed(2))
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total.toFixed(2))
		this.calculateCommission(i)
		this.calculateFareFromApi(0)

	}
	calculateYR(i){
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let txtYR = Number(travellerData['localYR']) / Number(this.invoice_root_data.currency_rate)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYR.setValue(txtYR.toFixed(2))

		let baseFare = Number(travellerData['txtBaseFare'])
		let YQ = Number(travellerData['txtYQ'])
		let K3 = Number(travellerData['txtK3'])
		let OC = Number(travellerData['txtOC'])
		let OT = Number(travellerData['txtOT'])
		let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(Number(Number(localAddons).toFixed(2)))
		let addons = this.getAddonsTotal(travellerData['sectorList'])
		let total = baseFare + YQ + txtYR + K3 + OC + OT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(addons.toFixed(2))
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total.toFixed(2))
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total.toFixed(2))
		this.calculateCommission(i)
		this.calculateFareFromApi(0)

	}
	calculateK3(i){
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let txtK3 = Number(travellerData['localK3']) / Number(this.invoice_root_data.currency_rate)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtK3.setValue(txtK3.toFixed(2))

		let baseFare = Number(travellerData['txtBaseFare'])
		let YQ = Number(travellerData['txtYQ'])
		let YR = Number(travellerData['txtYR'])
		let OC = Number(travellerData['txtOC'])
		let OT = Number(travellerData['txtOT'])
		let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(Number(Number(localAddons).toFixed(2)))
		let addons = this.getAddonsTotal(travellerData['sectorList'])
		let total = baseFare + YQ + YR + txtK3 + OC + OT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(addons.toFixed(2))
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total.toFixed(2))
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total.toFixed(2))
		this.calculateCommission(i)
		this.calculateFareFromApi(0)

	}
	calculateOC(i){
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let txtOC = Number(travellerData['localOC']) / Number(this.invoice_root_data.currency_rate)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtOC.setValue(txtOC.toFixed(2))

		let baseFare = Number(travellerData['txtBaseFare'])
		let YQ = Number(travellerData['txtYQ'])
		let YR = Number(travellerData['txtYR'])
		let K3 = Number(travellerData['txtK3'])
		let OT = Number(travellerData['txtOT'])
		let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(Number(Number(localAddons).toFixed(2)))
		let addons = this.getAddonsTotal(travellerData['sectorList'])
		let total = baseFare + YQ + YR + K3 + txtOC + OT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(addons.toFixed(2))
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total.toFixed(2))
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total.toFixed(2))
		this.calculateCommission(i)
		this.calculateFareFromApi(0)

	}
	calculateOT(i){
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let txtOT = Number(travellerData['localOT']) / Number(this.invoice_root_data.currency_rate)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtOT.setValue(txtOT.toFixed(2))

		let baseFare = Number(travellerData['txtBaseFare'])
		let YQ = Number(travellerData['txtYQ'])
		let YR = Number(travellerData['txtYR'])
		let K3 = Number(travellerData['txtK3'])
		let OC = Number(travellerData['txtOC'])
		let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(Number(Number(localAddons).toFixed(2)))
		let addons = this.getAddonsTotal(travellerData['sectorList'])
		let total = baseFare + YQ + YR + K3 + OC + txtOT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(Number(Number(addons).toFixed(2)))
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(Number(Number(total).toFixed(2)))
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(Number(Number(local_total).toFixed(2)))
		this.calculateCommission(i)
		this.calculateFareFromApi(0)

	}

	getLocalAddonsTotal(sectors) {
		let totalAmount = 0
		for (const key in sectors) {
			if (sectors.hasOwnProperty(key)) {
				const element = sectors[key];
				if (element['txtSeatCharges'] != "") {
					totalAmount += Number(element['txtSeatCharges']) 
				}
				if (element['txtMealCharges'] != "") {
					totalAmount += Number(element['txtMealCharges']) 
				}
				if (element['txtBagCharges'] != "") {
					totalAmount += Number(element['txtBagCharges']) 
				}
				if (element['txtAdditionalBaggageCharges'] != "") {
					totalAmount += Number(element['txtAdditionalBaggageCharges']) 
				}
				if (element['txtAncillaryCharges'] != "") {
					totalAmount += Number(element['txtAncillaryCharges']) 
				}
			}
		}
		return totalAmount
	}

	getAccountingCompany() {
		try {
			this.invoiceService.getMethod('util/accounting-company').subscribe(async (res) => {
				if(res) {
					if(res['currency_code']) {
						this.currencyCode = res['currency_code'];
					}
					if(res['native_language_code'] && res['currency_code'] == 'AED') {
						this.nativeLanguageCode = res['native_language_code'];
					}
				}
			},
			err => {
				this.spinner.hide()
			})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
		}
	}
	async getCoporateEmployee(event, currentIndex) {
		let val;
		if(event) {
			val = event.target.value;
		}
		if(event && currentIndex != null) {
			if(!val || val == null || val == undefined) {
				const traveller = this.sectorService.travellerForm.controls.travellerList.controls[currentIndex];
				traveller.controls['txtPassengerName'].setValue('')
				traveller.controls['txtEmployeeName'].setValue('')
				traveller.controls['txtEmployeeCode'].setValue('')
				traveller.controls['txtEmployeeCostCenter'].setValue('')
				
				// setTimeout(() => {
				// 	this.calculateFareFromApi(0)
				// }, 100);
			}
		};
		let client_code = this.sectorService.affiliate_code
		try {
			let url = `util/getCorporateEmployee?&client=${client_code}`
			if(val && val.length > 2){
				url = `util/getCorporateEmployee?name=${val}&client=${client_code}`
			}
	
			this.invoiceService.searchAccount(url).subscribe(data => {
				this.employeeArr = data["data"]
				// this.bookingSupplierArrBase = data;
			}, err => {
				throw new Error(JSON.stringify(err));
			})
		} catch (error) {
			console.log('Error while getting Booking Suppliers : ', error)

		} 
	}

	replicateClientXXLAmount(i){
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtSupplierXXLAmount.setValue(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtClientXXLAmount.value)
	}
	
	async filterAssociateSuppliers(event, currentIndex) {
		let value = event.target.value;
		if(!this.associateSupplierArr || this.associateSupplierArr == undefined || this.associateSupplierArr == null) {
			await this.getAssociateSuppliers(this.sectorService.travellerForm.controls.travellerList.controls[currentIndex].controls['txtbookingSupplierName'].value)
		}
		if(value) {
			let accounts = this.associateSupplierArr.filter(account => (account.name.includes(value) || account.account_code.includes(value)))
			this.associateSupplierFilteredData = accounts;
		} else {
			this.associateSupplierFilteredData = this.associateSupplierArr;
			let traveller = this.sectorService.travellerForm.controls.travellerList.controls[currentIndex]
			if(traveller) {
				traveller.controls['associate_supplier_name'].setValue('')
				traveller.controls['associate_supplier'].setValue('')
				traveller.controls['associate_supplier'].setValidators(null)
				traveller.controls['associate_supplier_name'].setValidators(null)
				this.sectorService.travellerForm.updateValueAndValidity()

			}
		}
		
	}
	getAssociateSuppliers(value) {
		try {
			this.invoiceService.getMethod(`general/getAssociateSupplier?company_name=${value}`).subscribe(data => {
				this.associateSupplierArr = data
				this.associateSupplierFilteredData = data;
			}, err => {
				this.associateSupplierArr = []
				this.associateSupplierFilteredData = []
				throw new Error(JSON.stringify(err));
			})
		} catch (error) {
			console.log('Error while getting Booking Suppliers : ', error)
		}
	}

}
