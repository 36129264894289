import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { CommonServiceService } from '../../common-service.service';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { GlobalVariable } from '../../global-variable';
import { ThrowStmt } from '@angular/compiler';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';
@Component({
  selector: 'app-credit-note-search',
  templateUrl: './credit-note-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class CreditNoteSearchComponent implements OnInit {
	creditnoteFilterForm: FormGroup;
	colorTheme = 'theme-orange';
	clientArr: any;
	keyword = 'name';
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	@ViewChild('clientAC',{static:true}) clientAC;
	modalRef: BsModalRef;
	minDate = "2023-04-01"
	maxDate = moment().format('YYYY-MM-DD')
  constructor(
	    private router: Router,
		private modalService: BsModalService, 
		public commonService: CommonServiceService, 
    private formBuilder: FormBuilder,
	private store: Store<InitialState>
  ) {
	this.store.select('sharedReducers').subscribe((items)=>{
		if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
			this.minDate = items.financeYear.start_date
			this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
						moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
		} else {
			let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
			if(!current_financial_year) {
				current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
			}
			this.minDate = current_financial_year.start_date
			this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
						moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
		}
		
})
   }

  ngOnInit() {
    this.creditnoteFilterForm = this.formBuilder.group({
			client: [''],
			client_name:[''],
			start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate,  this.checkDate('end_date')],
			creditNote_no: [''],
			booking_ref_no: [''],
			pnr: [''],
			ticket_no: [''],
			pax_name: [''],
			narration: [''],
			from_amount: [''],
			to_amount: ['']
		})
	this.setFormData()
  }

	checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}
	
  setFormData() {
	let queryParams = localStorage.getItem('activeCreditNoteQueryParams')
	if (queryParams != null) {
		let parsedParams = JSON.parse(queryParams)
		console.log('Active Query Params', parsedParams)
		// this.creditnoteFilterForm.controls.client.setValue(parsedParams.client)
		this.getClient(parsedParams.client ? parsedParams.client : "",true)

		this.creditnoteFilterForm.controls.start_date.setValue(moment(this.minDate).format('YYYY-MM-DD'))
		this.creditnoteFilterForm.controls.end_date.setValue(moment(this.maxDate).format('YYYY-MM-DD'))
	
		this.creditnoteFilterForm.controls.creditNote_no.setValue(parsedParams.creditNote_no?parsedParams.creditNote_no:"")
		this.creditnoteFilterForm.controls.booking_ref_no.setValue(parsedParams.booking_ref_no?parsedParams.booking_ref_no:"")
		this.creditnoteFilterForm.controls.pnr.setValue(parsedParams.pnr?parsedParams.pnr:"")
		this.creditnoteFilterForm.controls.ticket_no.setValue(parsedParams.ticket_no?parsedParams.ticket_no:"")
		this.creditnoteFilterForm.controls.pax_name.setValue(parsedParams.pax_name?parsedParams.pax_name:"")
		this.creditnoteFilterForm.controls.narration.setValue(parsedParams.narration?parsedParams.narration:"")
		this.creditnoteFilterForm.controls.from_amount.setValue(parsedParams.from_amount?parsedParams.from_amount:"")
		this.creditnoteFilterForm.controls.to_amount.setValue(parsedParams.to_amount?parsedParams.to_amount:"")
	}
  }
  openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'customModal' })
		);
	}

	getClient(value,bindValue=false) {
		if(!value) {
			this.creditnoteFilterForm.controls['client'].setValue('')
			this.creditnoteFilterForm.controls['client_name'].setValue('')
		}
		if (value.length > 2) {
			this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.clientArr = res
				if(bindValue && this.clientArr.length>0){
					this.creditnoteFilterForm.controls['client'].setValue(this.clientArr[0].account_code)
					this.creditnoteFilterForm.controls['client_name'].setValue(this.clientArr[0].name)
				}
			}, err => {
	
			})
		}
	}

	search(values){
		GlobalVariable.openModalStatus = false;
		if ((values.start_date == null || values.start_date == "" || values.start_date == "Invalid date" || values.start_date == "Invalid date")) {
			values.start_date = ""
		}else{
			values.start_date = moment(values.start_date).format('YYYY-MM-DD')
		}
		if((values.end_date == null || values.end_date == "" || values.end_date == "Invalid date" || values.end_date == "Invalid date")){
			values.end_date = ""
		}
		else{
			values.end_date = moment(values.end_date).format('YYYY-MM-DD')
		}

		if((values.from_amount == null)){
			values.from_amount = ""
		}
		if((values.to_amount == null)){
			values.to_amount = ""
		}
		
		let clientCode= values.client
		if (values.client.account_code != undefined) {
			clientCode= values.client.account_code
		} 
		this.router.navigateByUrl(`/transactions/search-credit-note?client=${clientCode}&start_date=${values.start_date}&end_date=${values.end_date}&creditNote_no=${values.creditNote_no}&booking_ref_no=${values.booking_ref_no}&pnr=${values.pnr}&ticket_no=${values.ticket_no}&pax_name=${values.pax_name}&narration=${values.narration}&from_amount=${values.from_amount}&to_amount=${values.to_amount}`)
		// this.reset()
	}

  reset() {
	GlobalVariable.openModalStatus = false;
	this.creditnoteFilterForm = this.formBuilder.group({
		client: [''],
		client_name:[''],
		start_date: [this.minDate],
		end_date: [this.maxDate],
		creditNote_no: [''],
		booking_ref_no: [''],
		pnr: [''],
		ticket_no: [''],
		pax_name: [''],
		narration: [''],
		from_amount: [''],
		to_amount: ['']
	})
	localStorage.removeItem('activeCreditNoteQueryParams')
	  }


	ngAfterViewInit(){
		this.commonService.selectedSearchInput.subscribe(index=>{
			if (index == 1) {
				this.focusClientAc()
			}
		})
	}


	selectAccount(value,formControlName){
		try {
			this.creditnoteFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}

	focusClientAc(){
		this.clientAC.focus();
	}

	  clickSearchButton(){
		this.searchButton.nativeElement.click()
	}
	clearData(formControl){
		this.creditnoteFilterForm.controls[formControl].setValue("")
	}
}
