<div class="container mt-4 ml-2">


    <div class="heading-box">
      <!-- <span class="hr-line"></span> -->
      <div class="heading">Update Client Master</div>
      <span class="hr-line"></span>
    </div>
  
    <div class="custom-form d-flex justify-content-between">
      <div class="left-col">
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>GST Registered</option>
          </select>
          <label>Category</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>ZZZZ</option>
          </select>
          <label>Family</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>Daxesh Joshi</option>
          </select>
          <label>Sales Person</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>Running Account</option>
          </select>
          <label>Collection List</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>Ahmedabad</option>
          </select>
          <label>City</label>
        </div>
  
        <div class="text-input-field">
          <input class="input-field" type="text" />
          <label>Credit Limit</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>Yes</option>
          </select>
          <label>Charge TDS</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>Yes</option>
          </select>
          <label>Collect GST Tax</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>Yes</option>
          </select>
          <label>Match Invoices After Receipts</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>No</option>
          </select>
          <label>LOCK Billing</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>Yes</option>
          </select>
          <label>Want DUAL Code</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>Client</option>
          </select>
          <label>Bill Print Favouring</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>Show</option>
          </select>
          <label>Discount Print In Invoice</label>
        </div>
  
      </div>
  
      <div class="right-col">
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>Nearest 1</option>
          </select>
          <label>Invoice Round Off</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>No Mix</option>
          </select>
          <label>Print Service Charges #1</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>No Mix</option>
          </select>
          <label>Print Service Charges #2</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>No Mix</option>
          </select>
          <label>Print Service Charges #3</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>No</option>
          </select>
          <label>Print Service Tax %</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>No</option>
          </select>
          <label>Recall Service Charges #1 In Refunds</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>No</option>
          </select>
          <label>Recall Service Charges #2 In Refunds</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>No</option>
          </select>
          <label>Recall Service Charges #3 In Refunds</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>Show Disc</option>
          </select>
          <label>Discount Show/Hide Tkt Invoice</label>
        </div>
  
        <div class="select-field text-input-field">
          <select class="input-field">
            <option>Show Serv</option>
          </select>
          <label>Srv. Chrgs Show/Hide Tkt Invoice</label>
        </div>
  
  
      </div>
  
  
  
    </div>
  
    <div class="action-box d-flex justify-content-center mt-4 mb-4">
      <button type="button" class="btn-style mr-3">Save</button>
      <button type="button" class="btn-style">Exit</button>
    </div>
  </div>
  