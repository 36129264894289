export class consolidateFareSummaryModel {
  "supplier_non_account" = 0;
  "supplier_basic" = 0;
  "supplier_taxes" = 0;
  "supplier_commission_amount" = 0;
  "supplier_tds" = 0;
  "supplier_tds_amount" = 0;
  "supplier_sc1_amount" = 0;
  "supplier_sc2_amount" = 0;
  "supplier_sc3_amount" = 0;
  "supplier_gst" = 0;
  "supplier_vat_amount"=0;
  "supplier_net_amount" = 0;

  "client_non_account" = 0;
  "client_basic" = 0;
  "client_taxes" = 0;
  "client_commission_amount" = 0;
  "client_tds" = 0;
  "client_tds_amount" = 0;
  "client_sc1_amount" = 0;
  "client_sc2_amount" = 0;
  "client_sc3_amount" = 0;
  "client_gst" = 0;
  "client_vat_amount"=0;
  "client_sub_total" = 0;

  "client_xxl" = 0;
  "client_raf" = 0;
  "client_gst_on_raf" = 0;
  "supplier_xxl" = 0;
  "supplier_raf" = 0;
  "supplier_gst_on_raf" = 0;


  constructor(fare) {
    {
      if (fare != null) {
        this.supplier_non_account = fare.supplier_non_account || 0;
        this.supplier_basic = fare.s_basic || 0;
        this.supplier_taxes = fare.s_taxes || 0;
        this.supplier_commission_amount = fare.s_commission_amount || 0;
        this.supplier_tds = fare.s_tds || 0;
        this.supplier_tds_amount = fare.s_tds_amount || 0;
        this.supplier_sc1_amount = fare.s_sc1_amount || 0;
        this.supplier_sc2_amount = fare.s_sc2_amount || 0;
        this.supplier_sc3_amount = fare.s_sc3_amount || 0;
        this.supplier_gst = (fare.s_cgst || 0) + (fare.supplier_sgst || 0) + (fare.s_igst || 0);
        this.supplier_vat_amount = fare.s_vat_amount||0
        this.supplier_net_amount = fare.supplier_net_amount || 0;

        this.client_non_account = fare.c_non_account || 0;
        this.client_basic = fare.c_basic || 0;
        this.client_taxes = fare.c_taxes || 0;
        this.client_commission_amount = fare.c_commission_amount || 0;
        this.client_tds = fare.c_tds || 0;
        this.client_tds_amount = fare.c_tds_amount || 0;
        this.client_sc1_amount = fare.c_sc1_amount || 0;
        this.client_sc2_amount = fare.c_sc2_amount || 0;
        this.client_sc3_amount = fare.c_sc3_amount || 0;
        this.client_gst = (fare.c_cgst || 0) + (fare.c_sgst || 0) + (fare.c_igst || 0);
        this.client_xxl = fare.c_xxl || 0
        this.client_raf = fare.c_raf || 0
        this.client_gst_on_raf = (fare.c_cgst_on_raf || 0) + (fare.c_sgst_on_raf || 0) + (fare.c_igst_on_raf || 0);
        this.supplier_xxl = fare.s_xxl || 0
        this.supplier_raf = fare.s_raf || 0
        this.supplier_gst_on_raf = (fare.s_cgst_on_raf || 0) + (fare.s_sgst_on_raf || 0) + (fare.s_igst_on_raf || 0);
        this.client_vat_amount = fare.c_vat_amount || 0;
        this.client_sub_total = fare.client_net_amount || 0;
      } else {
        this.supplier_non_account = 0;
        this.supplier_basic = 0;
        this.supplier_taxes = 0;
        this.supplier_commission_amount = 0;
        this.supplier_tds = 0;
        this.supplier_sc1_amount = 0;
        this.supplier_sc2_amount = 0;
        this.supplier_sc3_amount = 0;
        this.supplier_gst = 0;
        this.supplier_vat_amount = 0;
        this.supplier_net_amount = 0;

        this.client_non_account = 0;
        this.client_basic = 0;
        this.client_taxes = 0;
        this.client_commission_amount = 0;
        this.client_tds = 0;
        this.client_sc1_amount = 0;
        this.client_sc2_amount = 0;
        this.client_sc3_amount = 0;
        this.client_gst = 0;
        this.client_vat_amount = 0;
        this.client_sub_total = 0;
      }
    }
  }
}
