<div class="ledger-container p-0" #tableHeader tabIndex="0" style="margin-top: -3px;">

    <div class="table-container b2cs-tbl">
        <div class="table b2cs-report-table">

            <nz-table class="table  fixed-tbody-height"
                #gstData [nzData]="gstArr && gstArr['data']"
                [nzLoading]="loader"
                [nzFrontPagination]="false"
            >
                <thead>
                    <tr>
                        <th style="width: 15px;" class="text-left">Type</th>
                        <th style="width: 20px; text-transform: capitalize!important;"  class="text-left">PoS </th>
                        <th style="width: 20px;"  class="text-left">Rate</th>
                        <th style="width: 100px;"  class="text-right">Tax val</th>
                        <th style="width: 20px;"  class="text-right">Cess Amount</th>
                        <th style="width: 20px;"  class="text-right">E-Commerce GSTIN</th>
                    </tr>   
                </thead>
                <ng-container *ngIf="gstData && gstData['data'] && gstData['data'].length>0 else noResult">
                    <tbody class="main-panel" (window:keydown)="onKeyDown($event)" [ngStyle]="{height: 'calc(100vh - 181px)'}">
                        <tr *ngFor="let data of gstData['data'] let i = index" [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}"
                            class="field-table-input">
                            <td style="width: 15px;"  class="text-left text-grey" (click)="moveToIndex(i)">{{data?.type}}</td>
                            <td style="width: 20px;"  class="text-left text-grey" (click)="moveToIndex(i)">{{data?.place_of_supply}}</td>
                            <td style="width: 20px;"  class="text-left text-grey" (click)="moveToIndex(i)">{{data?.rate}}</td>
                            <td style="width: 100px;"  class="text-right text-black font-500" (click)="moveToIndex(i)">{{data?.taxable_value | number :
                                '1.2-2'}}</td>
                            <td style="width: 20px;"  class="text-right text-black font-500 " (click)="moveToIndex(i)">{{data?.cess_amount | number : '1.2-2'}}</td>
                            <td style="width: 20px;"  class="text-right text-black font-500" (click)="moveToIndex(i)">{{data?.e_commerce_gstin}}</td>
    
                        </tr>
                    </tbody>
                    <tfoot *ngIf="gstArr && gstArr['summary']" class="table-tfoot-bg">
                        <tr>
                            <td style="width: 15px;"  class="">
                                <span class="font-500" >Total rec.: </span>
                            </td>
                            <td style="width: 20px;"  class=""><span class="text-right font-500">{{gstArr['summary']?.total_record }}</span></td>
                            <td style="width: 20px;"  class=""></td>
                            <td style="width: 100px;"  class="text-right grnd-total">{{gstArr['summary']?.taxable_amount | number : '1.2-2' }}</td>
                            <td style="width: 20px;"  class="text-right grnd-total">{{gstArr['summary']?.cess_amount | number : '1.2-2' }}</td>
                            <td style="width: 20px;" class=""></td>
                        </tr>
                    </tfoot>
                </ng-container>
                <ng-template #noResult>
                    <div *ngIf="!loader" class="w-100 text-center" style="padding: 20px;">
                        <nz-empty nzNotFoundContent="No result Found"></nz-empty>
                    </div>
                </ng-template>
                
            </nz-table>   
        </div>
    </div>

</div>