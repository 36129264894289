import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportComponent } from './report.component';
import { FlownReportComponent } from './flown-report/flown-report.component';
import { EInvListComponent } from './e-inv-list/e-inv-list.component';
import { PsrReportComponent } from './psr-report/psr-report.component';
import { UnpaidReportComponent } from './unpaid-report/unpaid-report.component';
import { PsrDetailedReportComponent } from './psr-detailed-report/psr-detailed-report.component';
import { AdjustBalanceComponent } from './adjust-balance/adjust-balance.component';
import { ClientUnpaidReportComponent } from './client-unpaid-report/client-unpaid-report.component';
import { ViewAdjustedInvoiceComponent } from './adjust-balance/view-adjusted-invoice/view-adjusted-invoice.component';
import { ViewAdjustedReceiptComponent } from './adjust-balance/view-adjusted-receipt/view-adjusted-receipt.component';
import { ViewBalanceSheetComponent } from './balance-sheet/view-balance-sheet/view-balance-sheet.component';
import { AdjustB2csDataComponent } from './adjust-b2cs-data/adjust-b2cs-data.component';
import { MisReportComponent } from './mis-report/mis-report.component';
import { ClientSalesReportComponent } from './client-sales-report/client-sales-report.component';
import { TdsReportDetailedComponent } from './tds-report-detailed/tds-report-detailed.component';
import { TcsReportDetailedComponent } from './tcs-report-detailed/tcs-report-detailed.component';
import { MisReportV2Component } from './mis-report-v2/mis-report-v2.component';
import { VerticalPNLComponent } from './vertical-pnl/vertical-pnl.component';


const routes: Routes = [
  {
    path : '', component : ReportComponent,
    children : [
      { path: 'trial-balance', loadChildren: './trial-balance/trial-balance.module#TrialBalanceModule'},
      { path: 'aging-report', loadChildren: './aging-report/aging-report.module#AgingReportModule'},
      { path: 'profit-report', loadChildren: './profit-report/profit-report.module#ProfitReportModule'},
      { path: 'plb', loadChildren: './airline-plb-commission/airline-plb-commission.module#AirlinePlbCommissionModule'},
      { path: 'balance-sheet/view', component: ViewBalanceSheetComponent},
      { path: 'profit-loss/view', component: ViewBalanceSheetComponent},
      { path: 'flown-report', component: FlownReportComponent},
      { path: 'e-inv-list', component: EInvListComponent},
      { path: 'vertical-pnl', component:VerticalPNLComponent},
      { path: 'psr-report', component: PsrReportComponent},
      { path: 'psr-detailed-report', component: PsrDetailedReportComponent},
      { path: 'unpaid-report', component: UnpaidReportComponent},
      { path: 'client-unpaid-report', component: ClientUnpaidReportComponent},
      { path: 'adjust-balance', component: AdjustBalanceComponent},
      { path: 'adjusted-invoices', component: ViewAdjustedInvoiceComponent},
      { path: 'adjusted-receipts', component: ViewAdjustedReceiptComponent},
      { path: 'b2c-adjustment', component: AdjustB2csDataComponent},
      { path: 'mis-report', component: MisReportComponent},
      { path: 'mis-report-v2', component: MisReportV2Component},
      { path: 'client-sales-report', component: ClientSalesReportComponent},
      { path: 'tds-report-detailed', component: TdsReportDetailedComponent },
      { path: 'tcs-report-detailed', component: TcsReportDetailedComponent},
      {
				path: '',
				redirectTo: 'trial-balance',
				pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
