import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.scss']
})
export class CreateEmployeeComponent implements OnInit {
	rolelist: any;
	
	submitted = false;
	baseUrl = environment.okountUrl;
	employeeForm: FormGroup;
	groupList: any;
	showMessage: boolean = false;
	alertType = '';
	message: string = ``;
	countryArr: any = [];
	stateArr: any = [];
	cityArr: any = [];
	createdEmployeeId=""

	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef

	constructor(public commonService: CommonServiceService, private fb: FormBuilder, private spinner: NgxSpinnerService) {

	 }

	ngOnInit() {

		this.spinner.show();
		setTimeout(() => {
			this.spinner.hide();
		}, 1000);
		this.employeeForm = this.fb.group({
			
			// parent_id: ['', Validators.required],
			client_code:['',Validators.required],
			account_name: ['' ,Validators.required],
			account_code: ['',Validators.required],
			cost_center: [''],
			department: [''],
			sub_department: [''],
			lob : [''],
			channels:['']
		})

		this.getGroups()

	}

	openConfirmModal() :  void{
		this.confirmModal.nativeElement.click()
	}
	closeConfirmModal() :  void{
		this.closeModal.nativeElement.click()
	}

	

	reset() {
		this.employeeForm.reset();
	  }


	submit() {

		this.submitted = true;
		this.closeConfirmModal()
		if (this.employeeForm.valid) {
			//this.employeeForm.value['parent_id'] = 23;
			console.log('form is working')
			this.commonService.postRequest(`${this.baseUrl}/corporate_employee`, this.employeeForm.value).subscribe(res => {
				this.showMessage = true
				this.alertType = 'success-box';
				this.createdEmployeeId = res[0].id ? res[0].id : ""
				this.message = 'Employee created successfully.'
			}, error => {
				this.showMessage = true
				this.alertType = 'error-box';
				this.createdEmployeeId =  ""
				this.message = 'Failed to create Employee. Please try Again.'
			})
		}
		else {
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = 'Please fill mandatory fields.'
			return false;
		}
	}

	getGroups() {
		this.commonService.getOkount('v3account/getAllClients').subscribe(res => {
			this.groupList = res
			console.log('Group list : ', this.groupList)
		}, err => {

		})
	}

	hideError() {
		this.showMessage = false
	}

}

