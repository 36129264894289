import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonServiceService } from '../../common-service.service';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/app/transactions/payment/services/payment.service';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';
import * as FileSaver from 'file-saver'; 

@Component({
  selector: 'app-history-ledger-search',
  templateUrl: './history-ledger-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class HistoryLedgerSearchComponent implements OnInit {

	modalRef: BsModalRef;
	ledgerFilterForm: FormGroup;
	clientArr: any;
	salesPersonArr: any;
	keyword = 'name';
	cityKeyword = 'city';

	ledgerList: any;
	groupByledgerList: any;
	cityArr: any;
	all = {
		name : "All",
		account_code : ""
	}
  alertMessage =''

	allGroupArr:any = []
	// @ViewChild('accounttype',{static:true}) accounttype;
	@ViewChild('accountAC',{static:true}) accountAC;
	@ViewChild('clientGroup',{static:true}) clientGroup;

	@ViewChild('subledgerAC',{static:true}) subledgerAC;
	@ViewChild('salesprAC',{static:true}) salesprAC;
	@ViewChild('cityAC',{static:true}) cityAC;
	@ViewChild('dpYMD2',{static:true}) dpYMD2;
	@ViewChild('dpYMD1',{static:true}) dpYMD1;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	@ViewChild('closeModal', {static : true})  closeModal : ElementRef
	constructor(
		public paymentService: PaymentService,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private router: Router,private store: Store<InitialState>) {
			this.store.select('sharedReducers').subscribe((items)=>{
		})
	}

	ngOnInit() {

		this.ledgerFilterForm = this.formBuilder.group({
			account_type: ['client'],
			client: [''],
			client_name: [''],
			group_name:[''],
			group:[''],
			sales_person: [''],
			sales_person_name: [''],
			start_date: [''],
			end_date: [moment().format('YYYY-MM-DD')],
			pay_rec_date :[moment().format('YYYY-MM-DD')],
			credit_note_date :[moment().format('YYYY-MM-DD')],
		})
		this.ledgerList = []
		this.ledgerList.push(this.all)
		this.getAllGroups()
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'customModal' })
		);
	}

	closeModalFn(){
		this.closeModal.nativeElement.click()
	}
	getClient(value,bindValue = false) {
		if(!value) {
			this.ledgerFilterForm.controls['client'].setValue('')
			this.ledgerFilterForm.controls['client_name'].setValue('')
		}
		if (value.length > 2) {
			let searchType = this.ledgerFilterForm.value.account_type
			this.commonService.getOkount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.clientArr = res
				if(bindValue && this.clientArr.length>0){
					this.ledgerFilterForm.controls['client'].setValue(this.clientArr[0].account_code)
					this.ledgerFilterForm.controls['client_name'].setValue(this.clientArr[0].name)
				}
			}, err => {

			})
		}
	}
	getSalesPerson(value,bindValue=false) {
		if(!value) {
			this.ledgerFilterForm.controls['sales_person'].setValue('')
			this.ledgerFilterForm.controls['sales_person_name'].setValue('')
		}
		if (value.length > 2) {
			this.commonService.getOkount(`employee/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.salesPersonArr = res
				if(bindValue && this.salesPersonArr.length>0){
					this.ledgerFilterForm.controls['sales_person'].setValue(this.salesPersonArr[0].account_code)
					this.ledgerFilterForm.controls['sales_person_name'].setValue(this.salesPersonArr[0].name)
				}
			}, err => {

			})
		}
	}
	getCity(value) {
		if(!value) {
			this.ledgerFilterForm.controls['city_id'].setValue('')
		}
		if (value.length > 2) {
			this.commonService.getOkount(`location/allcity?city_name=${value.toUpperCase()}`).subscribe(res => {
				this.cityArr = res
			}, err => {

			})
		}
	}
	
	clearData(formControl){
		this.ledgerFilterForm.controls[formControl].setValue("")
		this.ledgerFilterForm.controls.client.setValidators(Validators.required)
	}

	clearGroup(event,formControl){
		if(event.target.value == 'none') {
			this.ledgerFilterForm.controls['client'].setValue('')
			this.ledgerFilterForm.controls['client'].setValidators(null)
			this.ledgerFilterForm.controls['client'].setErrors(null)
			this.ledgerFilterForm.controls['client_name'].setValue('')
			this.ledgerFilterForm.controls['client_name'].setValidators(null)
			this.ledgerFilterForm.controls['client_name'].setErrors(null)
		}else {
			this.ledgerFilterForm.controls['client_name'].setValidators(Validators.required)
		}
	}

	updateViewMode(event){
		if(event.target.value!='')
		{
			this.ledgerFilterForm.controls['viewMode'].setValue('summary')
		}
		else {this.ledgerFilterForm.controls['viewMode'].setValue('normal')}
	}

	getFilteredData(queryParams) {
		this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/ledger`, queryParams).subscribe(res => {
			console.log(res);
		}, err => {
			console.error(err);
		})
	}


	getLedgers(value,bindValue = false) {
		try {
			let searchType = this.ledgerFilterForm.value.subledger_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.paymentService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
					this.ledgerList = res;
					if(bindValue && this.ledgerList.length>0){
						this.ledgerFilterForm.controls['sub_ledger_code'].setValue(this.ledgerList[0].account_code)
						this.ledgerFilterForm.controls['sub_ledger_code_name'].setValue(this.ledgerList[0].name)
					}
					this.ledgerList.unshift(this.all)
				});
			}
		} catch (error) {

		}
	}
	getLedgersGroupBy(value) {
		try {
			let searchType = this.ledgerFilterForm.value.group_by_subledger_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.paymentService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
					this.groupByledgerList = res;
				});
			}
		} catch (error) {

		}
	}

	getAllGroups(){
        this.commonService.getOkount('v3account/getAllClientGroupName').subscribe(res => {
            this.allGroupArr = res
        }, err => {
        })
    }

	selectAccount(value,formControlName){
		try {
			this.ledgerFilterForm.controls[formControlName].setValue(value.account_code)
      this.ledgerFilterForm.controls[`${formControlName}_name`].setValue(value.name)
		} catch (error) {

		}
	}

	selectGroup(value,formControlName){
		try {
			this.ledgerFilterForm.controls[formControlName].setValue(value['id'])
		} catch (error) {

		}
	}

	getValue(v){
		console.log(v)
	}

	focusAccountAc(){
		this.accountAC.focus();
	}

	focusClientGroup(){
		this.clientGroup.focus();
	}


  downloadPdf() {
    this.spinner.show()
    let client_name = this.ledgerFilterForm.controls.client_name.value.name ? this.ledgerFilterForm.controls.client_name.value.name : this.ledgerFilterForm.controls.client_name.value;

    let requestObj = {
      start_date : moment(this.ledgerFilterForm.controls.start_date.value).format('YYYY-MM-DD'),
      end_date : moment(this.ledgerFilterForm.controls.end_date.value).format('YYYY-MM-DD'),
      pay_rec_date : moment(this.ledgerFilterForm.controls.pay_rec_date.value).format('YYYY-MM-DD'),
      credit_note_date : moment(this.ledgerFilterForm.controls.credit_note_date.value).format('YYYY-MM-DD'),
      client : this.ledgerFilterForm.controls.client.value,
      account_type : this.ledgerFilterForm.controls.account_type.value,
      group : this.ledgerFilterForm.controls.group.value,
      download_type: 'pdf',
      ignore_financial_check: true,
      schedule_download: true
    }

     this.commonService.getRequestWithPdfData(`${environment.okountUrl}/reports/ledger/normal/download`, requestObj).subscribe((res: any) => {
      this.alertMessage = 'Download Scheduled Successfully.'
      setTimeout(() => {
        let redirectUrl = `${environment.baseAccountUrl}/v2/#/report/schedule-reports`
        window.open(redirectUrl, '_blank');
      }, 1000)
    }, err => {
      console.log(err)
    })		


     this.spinner.hide()
  }

  downloadExcel() {
    this.spinner.show()
    let client_name = this.ledgerFilterForm.controls.client_name.value.name ? this.ledgerFilterForm.controls.client_name.value.name : this.ledgerFilterForm.controls.client_name.value;

    let requestObj = {
      start_date : moment(this.ledgerFilterForm.controls.start_date.value).format('YYYY-MM-DD'),
      end_date : moment(this.ledgerFilterForm.controls.end_date.value).format('YYYY-MM-DD'),
      pay_rec_date : moment(this.ledgerFilterForm.controls.pay_rec_date.value).format('YYYY-MM-DD'),
      credit_note_date : moment(this.ledgerFilterForm.controls.credit_note_date.value).format('YYYY-MM-DD'),
      client : this.ledgerFilterForm.controls.client.value,
      account_type : this.ledgerFilterForm.controls.account_type.value,
      group : this.ledgerFilterForm.controls.group.value,
      download_type: 'xlsx',
      ignore_financial_check: true,
    }

    requestObj['download_type'] = 'xlsx';
    requestObj['ignore_financial_check'] = true;
    this.alertMessage = 'Please wait! Excel download is in progress.'
    this.commonService.getRequestWithPdfData(`${environment.okountUrl}/reports/ledger/normal/download`, requestObj).subscribe((fileBlob:any) =>{
			let fileName = `${client_name} NORMAL LEDGER FROM ${requestObj.start_date} TO ${requestObj.end_date}.${requestObj['download_type'] == 'xlsx' ? 'xlsx' : 'pdf'}` 			
			let blob = new Blob([fileBlob], { 
				type: requestObj['download_type'] == 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
      this.alertMessage = '';
	   })
     this.spinner.hide()
  }




}
