export class TotalFareRefundModel {
    "base" = 0;
    "yq" = 0;
    "yr" = 0;
    "k3" = 0;
    "oc" = 0;
    "ot" = 0;
    "ssr" = 0;
    "tds_paid" = 0;
    "tds_recieved" = 0;
    "gst_paid" = 0;
    "gst_recieved" = 0;
    "commission_paid" = 0;
    "commission_received" = 0;
    "discount_paid" = 0;
    "discount_received" = 0;
    "net_paid" = 0;
    "net_received" = 0;
    "client_xxl" = 0;
    "client_raf" = 0;
    "client_raf_gst" = 0;
    "client_raf_vat" = 0;
    "supplier_xxl" = 0;
    "supplier_raf" = 0;
    "supplier_raf_gst" = 0;
    "supplier_raf_vat" = 0;
    "supplier_service_charges": 0;
    "client_service_charges": 0;
    "round_off" = 0;
    "client_normal_service_charges" = 0;
    "supplier_normal_service_charges" = 0;
    "vat_paid" = 0;
    "vat_received" = 0;

    constructor(fare) {
        if (fare != null) {
        this.base = fare.base || 0;
        this.yq = fare.yq || 0;
        this.yr = fare.yr || 0;
        this.k3 = fare.k3 || 0;
        this.oc = fare.oc || 0;
        this.ot = fare.ot || 0;
        this.ssr = fare.ssr || 0;
        this.tds_paid = fare.tds_paid || 0;
        this.tds_recieved = fare.tds_recieved || 0;
        this.gst_paid = fare.gst_paid || 0;
        this.gst_recieved = fare.gst_recieved || 0;
        this.commission_paid = fare.commission_paid || 0;
        this.commission_received = fare.commission_received || 0;
        this.discount_paid = fare.discount_paid || 0;
        this.discount_received = fare.discount_received || 0;
        this.supplier_service_charges = fare.supplier_service_charges || 0;
        this.client_service_charges = fare.client_service_charges || 0;
        this.net_paid = fare.net_paid || 0;
        this.net_received = fare.net_received || 0;
        this.client_xxl = fare.client_xxl || 0;
        this.client_raf = fare.client_raf || 0;
        this.client_raf_gst = fare.client_raf_gst || 0;
        this.client_raf_vat = fare.client_raf_vat || 0;
        this.supplier_xxl = fare.supplier_xxl || 0;
        this.supplier_raf = fare.supplier_raf || 0;
        this.supplier_raf_gst = fare.supplier_raf_gst || 0;
        this.supplier_raf_vat = fare.supplier_raf_vat || 0;
        this.round_off = fare.round_off || 0;
        this.client_normal_service_charges = fare.client_normal_service_charges || 0;
        this.supplier_normal_service_charges = fare.supplier_normal_service_charges || 0;
        this.vat_paid = fare.vat_paid || 0
        this.vat_received = fare.vat_received || 0
        }else{
        this.base = 0;
        this.yq = 0;
        this.yr = 0;
        this.k3 = 0;
        this.oc = 0;
        this.ot = 0;
        this.ssr = 0;
        this.tds_paid = 0;
        this.tds_recieved = 0;
        this.gst_paid = 0;
        this.gst_recieved = 0;
        this.commission_paid = 0;
        this.commission_received = 0;
        this.discount_paid = 0;
        this.discount_received = 0;
        this.net_paid = 0;
        this.net_received = 0;
        this.client_xxl = 0;
        this.client_raf = 0;
        this.client_raf_gst = 0;
        this.client_raf_vat = 0;
        this.supplier_xxl = 0;
        this.supplier_raf = 0;
        this.supplier_raf_gst = 0;
        this.supplier_raf_vat = 0;
        this.supplier_service_charges =  0;
        this.client_service_charges =  0;
        this.round_off = 0;
        this.client_normal_service_charges = 0;
        this.supplier_normal_service_charges = 0;
        this.vat_paid = 0
        this.vat_received = 0
        }
    }
}