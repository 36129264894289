import {ComponentCanDeactivate} from '../can-deactivate/component-can-deactivate';
import {NgForm} from "@angular/forms";

export abstract class FormCanDeactivate extends ComponentCanDeactivate{

 abstract get form():NgForm;
 
 canDeactivate():boolean{
     if (this.form != undefined) {
         return this.form.submitted || !this.form.dirty
     }else{
         return false
     }
  }
}