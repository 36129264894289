import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { CommonServiceService } from '../../common-service.service';
import * as moment from 'moment-timezone';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';

@Component({
	selector: 'app-jv-search',
	templateUrl: './jv-search.component.html',
	styleUrls: ['../global-search.component.scss']
})
export class JvSearchComponent implements OnInit {
	paymentFilterForm: FormGroup;
	accountArr: any;
	keyword = 'name';
	subLedger: any;

	@ViewChild('subledgerAC', { static: true }) subledgerAC;
	@ViewChild('searchButton', { static: true }) searchButton: ElementRef;
	@ViewChild('clientAC',{static:true}) clientAC;
	minDate = "2023-04-01"
    maxDate = moment().format('YYYY-MM-DD')   
	constructor(
		public commonService: CommonServiceService,
		private formBuilder: FormBuilder,
		private router: Router,
		private store: Store<InitialState>
	) { 
		this.store.select('sharedReducers').subscribe((items)=>{
			if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
				this.minDate = items.financeYear.start_date
				this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
							moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
			} else {
				let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
				if(!current_financial_year) {
					current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
				}
				this.minDate = current_financial_year.start_date
				this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
							moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
			}
			
	})
	}

	ngOnInit() {
		this.paymentFilterForm = this.formBuilder.group({
			account_name: [''],
			account: [''],
			journal_code: [''],
			start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate, this.checkDate('end_date')],
			remark: [''],
			narration: [''],
			subledger_code: [''],
			sub_ledger_name: [''],
		})
		this.setFormData()
	}

	checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}

	setFormData() {
		let queryParams = localStorage.getItem('activeJVQueryParams')
		if (queryParams != null) {
			let parsedParams = JSON.parse(queryParams)
			// this.paymentFilterForm.controls.account.setValue(parsedParams.account)
		    this.getAccount(parsedParams.account ? parsedParams.account : "",true)
		    this.getSubLedgerAccount(parsedParams.subledger_code ? parsedParams.subledger_code : "",true)

			this.paymentFilterForm.controls.start_date.setValue(moment(this.minDate).format('YYYY-MM-DD'))
			this.paymentFilterForm.controls.end_date.setValue(moment(this.maxDate).format('YYYY-MM-DD'))

			this.paymentFilterForm.controls.journal_code.setValue(parsedParams.journal_code?parsedParams.journal_code:"")
			this.paymentFilterForm.controls.remark.setValue(parsedParams.remark?parsedParams.remark:"")
			this.paymentFilterForm.controls.narration.setValue(parsedParams.narration?parsedParams.narration:"")
			// this.paymentFilterForm.controls.subledger_code.setValue(parsedParams.subledger_code)
		}
	}

	search(values) {
		GlobalVariable.openModalStatus = false;
		if ((values.start_date == null || values.start_date == "" || values.start_date == "Invalid date" || values.start_date == "Invalid date")){
			values.start_date = ""
		}else{
			values.start_date = moment(values.start_date).format('YYYY-MM-DD')
		}
		if(values.end_date == null || values.end_date == "" || values.end_date == "Invalid date" || values.end_date == "Invalid date") {
			values.end_date = ""
		}
		else {
			values.end_date = moment(values.end_date).format('YYYY-MM-DD')
		}

		// let clientCode= ''
		// if (values.client != '') {
		// 	clientCode= values.client.account_code
		// } 
		let account = values.account
		if (values.account.account_code != undefined) {
			account = values.account.account_code
		}
		this.router.navigateByUrl(`/transactions/jv/view?account=${account}&journal_code=${values.journal_code}&remark=${values.remark}&start_date=${values.start_date}&end_date=${values.end_date}&narration=${values.narration}&subledger_code=${values.subledger_code}`)
		// this.reset()
	}


	getAccount(value,bindValue=false) {
		if(!value) {
			this.paymentFilterForm.controls['account'].setValue('')
			this.paymentFilterForm.controls['account_name'].setValue('')
		}
		if (value.length > 2) {
			this.commonService.getOkount(`v3account/ledgerautocomplete?name=${value.toUpperCase()}`).subscribe(res => {
				this.accountArr = res
				if(bindValue && this.accountArr.length>0){
					this.paymentFilterForm.controls['account'].setValue(this.accountArr[0].account_code)
					this.paymentFilterForm.controls['account_name'].setValue(this.accountArr[0].name)
				}
			}, err => {

			})
		}
	}


	getSubLedgerAccount(value,bindValue=false) {
		if(!value) {
			this.paymentFilterForm.controls['subledger_code'].setValue('')
			this.paymentFilterForm.controls['sub_ledger_name'].setValue('')
		}
		if (value.length > 2) {
			this.commonService.getOkount(`v3account/ledgerautocomplete?name=${value.toUpperCase()}`).subscribe(res => {
				this.subLedger = res
				if(bindValue && this.subLedger.length>0){
					this.paymentFilterForm.controls['subledger_code'].setValue(this.subLedger[0].account_code)
					this.paymentFilterForm.controls['sub_ledger_name'].setValue(this.subLedger[0].name)
				}
			}, err => {

			})
		}
	}

	reset() {
		GlobalVariable.openModalStatus = false;
		this.paymentFilterForm = this.formBuilder.group({
			account: [''],
			journal_code: [''],
			start_date: [this.minDate],
			end_date: [this.maxDate],
			remark: [''],
			narration: [''],
			subledger_code: ['']
		})
		localStorage.removeItem('activeJVQueryParams')

	}

	ngAfterViewInit(){
		this.commonService.selectedSearchInput.subscribe(index=>{
			if(index == 6){
				this.focusSubledgerAc()
			}
			if (index == 1) {
				this.focusClientAc()
			}
		})
	}


	selectAccount(value,formControlName){
		try {
			this.paymentFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}
	focusSubledgerAc() {
		this.subledgerAC.focus();
	}

	clickSearchButton() {
		this.searchButton.nativeElement.click()
	}

	focusClientAc(){
		this.clientAC.focus();
	}

	clearData(formControl){
		this.paymentFilterForm.controls[formControl].setValue("")
	}

	// /transactions/jv/view
}
