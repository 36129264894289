import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { CommonServiceService } from '../common-service.service';

type IKNOWISNUMBER = any;
type IKNOWISSTRING = any;

@Directive({
	selector: '[tabIndex]'
})
export class TabDirective {


	private _index: number;
	get index(): IKNOWISNUMBER {
		return this._index;
	}
	@Input('tabIndex')
	set index(i: IKNOWISSTRING) {
		this._index = parseInt(i);
	}

	constructor(
		private el: ElementRef,
		public commonService: CommonServiceService
	) { }

	ngOnInit() {
		this.commonService.selectedInput
			.subscribe((i) => {
				if (i === this.index && this.el.nativeElement.focus) {
					this.el.nativeElement.focus();
					if(this.el.nativeElement.select){
						this.el.nativeElement.select();
					}
				}
			});
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(e: any) {
		if (e.shiftKey && e.keyCode == 13) {
			e.preventDefault();
			this.commonService.selectedInput.next(this.index - 1)
		} else if (e.keyCode === 13 && this.el.nativeElement.nodeName != 'TEXTAREA') {
			e.preventDefault();
			// let nextTabIndex = this.el.nativeElement.nextElementSibling.tabIndex
			// if (nextTabIndex == -1) {
			// 	nextTabIndex = this.el.nativeElement.nextElementSibling.lastElementChild.tabIndex
			// }
			if (this.el.nativeElement.nodeName == 'BUTTON') {
				this.el.nativeElement.click()
			}else{
				this.commonService.selectedInput.next(this.index + 1)
			}
		}
	}
	// @HostListener('change', ['$event'])
	// onChange(e: any) {
	// 	debugger
	// 	let control = e.srcElement.offsetParent.nextElementSibling.lastElementChild
	// 	if (control) {
	// 		if (control.nodeName == 'INPUT' ||
	// 			control.nodeName == 'SELECT' ||
	// 			control.nodeName == 'BUTTON' ||
	// 			control.nodeName == 'TEXTAREA') {
	// 				control.focus();
	// 				return;
	// 		}
	// 	}
	// 	if (e.shiftKey && e.keyCode == 13) {
	// 		this.commonService.selectedInput.next(this.index - 1)
	// 	} else if (e.keyCode === 13) {
	// 		this.commonService.selectedInput.next(this.index + 1)
	// 		e.preventDefault();
	// 	}
	// }




}
