import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormGroup, Validators, FormBuilder, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormCanDeactivate } from 'src/app/shared/form-can-deactivate/form-can-deactivate';
import { CookiesService } from 'src/app/shared/cookies.service';

@Component({
	selector: 'app-update-bank',
	templateUrl: './update-bank.component.html',
	styleUrls: ['./update-bank.component.scss']
})
export class UpdateBankComponent extends FormCanDeactivate implements OnInit {
	@Input() data
	@ViewChild('form')
 	form: NgForm;
	generalId: any;

	submitted = false;
	baseUrl = environment.okountUrl;
	bankForm: FormGroup;
	groupList: any;

	alertType = '';
	message: string = ``;
	countryArr: any = [];
	stateArr: any = [];
	cityArr: any = [];
	remainingAmount = 0
    amountType = 'credit'
	showMessage: boolean = false;
	generalData: any;
	checkBankBox: boolean = false
	isTenantIndian: boolean = false;
	isHQBranch: boolean = false;
	branches: any = []
	vatAlternetname: string = 'VAT'

	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef
	@ViewChild('confirmDeleteModal',{static:true}) confirmDeleteModal : ElementRef
	@ViewChild('closeDeleteModal',{static:true}) closeDeleteModal : ElementRef
	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef
	@ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef

	@ViewChild('addBankToPolicyConfirmation',{static:true}) addBankToPolicyConfirmation : ElementRef
	@ViewChild('closeBankUpdationModal',{static:true}) closeBankUpdationModal : ElementRef
	@ViewChild('confirmOBModal',{static:true}) confirmOBModal : ElementRef;
    @ViewChild('closeOBModal',{static:true}) closeOBModal : ElementRef;

	constructor(
		public commonService: CommonServiceService,
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private spinner: NgxSpinnerService,
		private cookieService: CookiesService
	) {
		super()
		this.route.paramMap.subscribe(params => {
			this.generalId = params['params'].id
		});

		this.bankForm = this.fb.group({
			parent_id : ['' , Validators.required],
			account_name : ['' , Validators.required] ,
			ifsc_code: [''],
			swift_code:[''],
			account_number: [''],
			balance : [''],
			balance_type: ['debit'],
			state_id : [''],
			state_name : [''],
			city_id : [''],
			city_name : [''],
			gst_number: [''],
			vat_number: [''],
			hierarchy: [''],
			sub_type: ['bank'],
			status: ['active'],
			branch:{}
		})
	}

	async ngOnInit() {
		if(this.data) {
			this.generalId = this.data.general_id
		}
		if (this.generalId != undefined) {
			this.getGeneral()
		}
		this.getStates('101')
		this.isHQBranch = this.commonService.checkHQBranch()

		if(this.isHQBranch==true){
			this.getAllBranches()
			
			// this.branchForm = this.fb.group({
			// 	formObj
			// })
		}
		
		this.isTenantIndian = this.commonService.checkTenantIndian()
		if(!this.isTenantIndian){
			let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
			let inv_config = globalSettingConfig.filter(el=>el.group=='VAT')
		if(inv_config.length){
			let vat_config = inv_config[0].json_doc.value.filter(el=>el.key=='vat_rate')
			let vat_detail,vat_name
			if(vat_config.length){
				 vat_detail = vat_config[0].value
			}
			if(vat_detail && vat_detail.length ){
				vat_name = vat_detail.filter(el=>el.key=='effective_name')
			}
			if(vat_name && vat_name.length){
				this.vatAlternetname = vat_name[0].value
			}
		}
		}
	}
	
	
	getStates(country_id) {
		this.commonService.getOkount(`location/states?country_id=${country_id}`).subscribe(res => {
			this.stateArr = res
		})
	}

	getCities(state_id) {
		let selectedState = this.stateArr.filter(option => {
			return option.location_id == Number(state_id);
		})
		if (selectedState.length > 0) {
			this.bankForm.controls.state_name.setValue(selectedState[0].name)
		}
		this.commonService.getOkount(`location/city?state_id=${state_id}`).subscribe(res => {
			this.cityArr = res
		})
	}

  	selectState(state_id) {
		let selectedState = this.stateArr.filter(option => {
			return option.location_id == Number(state_id);
		})
		if (selectedState.length > 0) {
			this.bankForm.controls.state_name.setValue(selectedState[0].name)
		}
	}

	getGeneral() {
		this.commonService.getOkount(`general?general_id=${this.generalId}`).subscribe(async (res) => {
			if (res[0] != undefined) {
				this.generalData = res[0]
			}
			await this.getGroups()
			await this.getCities(this.generalData.state_id)
			await this.setGeneralData()
		})
	}
	setGeneralData() {
		if (this.bankForm != undefined && this.generalData != undefined) {
			this.bankForm.controls.parent_id.setValue(this.generalData.parent_id)
			this.bankForm.controls.account_name.setValue(this.generalData.account_name)
			this.bankForm.controls.balance.setValue(this.generalData.balance)
			this.bankForm.controls.state_id.setValue(this.generalData.state_id)
			this.bankForm.controls.state_name.setValue(this.generalData.state_name)
            this.bankForm.controls.city_id.setValue(this.generalData.city_id)
			this.bankForm.controls.city_name.setValue(this.generalData.city_name)
			this.bankForm.controls.gst_number.setValue(this.generalData.gst_number)
			this.bankForm.controls.vat_number.setValue(this.generalData.vat_number)
			this.bankForm.controls.balance_type.setValue(this.generalData.balance_type)
			this.bankForm.controls.hierarchy.setValue(this.generalData.hierarchy)
			this.bankForm.controls.status.setValue(this.generalData.status)
			this.bankForm.controls.ifsc_code.setValue(this.generalData.ifsc_code)
			this.bankForm.controls.swift_code.setValue(this.generalData.swift_code)
			this.bankForm.controls.account_number.setValue(this.generalData.account_number)
		}
	}

	openUpdateConfirmModal(){
		this.confirmUpdateModal.nativeElement.click()
		this.commonService.selectedInput.next(10)
	}

	closeUpdateConfirmModal(){
		this.closeUpdateModal.nativeElement.click()
	}


	updateGeneral() {
		debugger
		this.submitted = true;
		this.closeUpdateConfirmModal()
		if (this.bankForm.valid) {
			this.commonService.putRequest(`${this.baseUrl}/general?general_id=${this.generalId}`, this.bankForm.value).subscribe(res => {
				this.updateOpeningBalanceAdjustAcc()
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = 'Bank updated successfully.'
			}, error => {
				this.showMessage = true
				this.alertType = 'error-box';
				this.message = 'Failed to update Bank. Please try Again.'
			})
		}
		else {
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = 'Please fill mandatory fields.'
			return false;
		}
	}

	getGroups() {
		this.commonService.getOkount('v3account/getallsuperparents').subscribe(res => {
			this.groupList = res
		}, err => {

		})
	}
	hideError() {
		this.showMessage = false
	}

	closeWindow() {
		window.close();
	}

	openDeleteConfirmModal(){
		this.confirmDeleteModal.nativeElement.click()
		this.commonService.selectedInput.next(12)
	}

	closeDeleteConfirmModal(){
		this.closeDeleteModal.nativeElement.click()
	}
	openBankUpdateInTransactionConfirmModal(){
		this.checkBankBox = true
		this.addBankToPolicyConfirmation.nativeElement.click()
	}

	closeBankUpdateInTransactionConfirmModal(){
		this.checkBankBox = false
		this.closeBankUpdationModal.nativeElement.click()
	}

	updateBankInAllTransactions() {
		let tenant_data = this.cookieService.getCookie('SYSTACC_USER_LOGIN_DATA')
		if(tenant_data){
		  tenant_data = JSON.parse(tenant_data)
		}
		let company = tenant_data && tenant_data['t_tenant_info'] && tenant_data['t_tenant_info']['tenant_company_name']
		this.commonService.postRequest(`${this.baseUrl}/setting/voucher-banks?tenant=${company}`, this.bankForm.value).subscribe(res => {
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = 'Bank Added successfully.'
		}, error => {
			this.checkBankBox = false
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Failed to update bank data in all transactions. Please try Again.'
		})
	}


	deleteGeneral(){
		let generaLid = this.generalData.account_code
		this.closeDeleteConfirmModal()
		if(generaLid != ''){
			this.commonService.deleteRequest(`${this.baseUrl}/general?account_code=${generaLid}`).subscribe((res: any) => {
				this.bankForm.reset()
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = `General Deleted.`
				this.spinner.hide();
			},
				err => {
					this.showMessage = true
					this.alertType = 'error-box';
					this.message = `Failed to delete general.`
					this.spinner.hide();
				})
		} else {
			this.bankForm.reset()
		}
	}


	openConfiguration(general_id) {
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/master/policy/default-general-policy/${general_id}`
		window.open(path,'_blank')
	}
	openActivity(){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/general/${this.generalData.account_code}`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

	setHierarchy(parentId,onload){
		let hierarchy = this.bankForm.value.hierarchy
		if(!onload || !hierarchy){
			let parentAccount = this.groupList && this.groupList.filter(data=> {
				return data.id == parentId
			})
			if(parentAccount && parentAccount.length > 0){
				hierarchy = Number(parentAccount[0].hierarchy)+1
			}
		}
		this.bankForm.controls.hierarchy.setValue(hierarchy)
	}

	getCityName(city_id) {
		let selectedCity = this.cityArr.filter(option => {
			return option.location_id == Number(city_id);
		})
		if (selectedCity.length > 0) {
			this.bankForm.controls.city_name.setValue(selectedCity[0].name)
		}
	}
	getAllBranches() {
        this.commonService.getOkount(`branch`).subscribe(res=> {
          if(res && res['data']) {
            this.branches = res['data'];
			let formObj = this.branches.reduce((acc,curr)=>{
				if(curr.branch_type=='BRANCH'){
					acc[curr.branch_name]=false
				}
				return acc
			},{})
			console.log("formObj",formObj)
			this.bankForm.controls.branch.setValue(formObj)
          }
        },err=> {
    
        })
      }

	handleValueChange(e,branchName){
		console.log("branchName",this.bankForm.controls.branch)
		this.bankForm.controls.branch.value[branchName]=e.currentTarget.checked
	  }
	  async checkBalance(){
        let opening_amount = await  this.getOpeningBalanceData()
        if(this.bankForm.controls.balance_type.value=='debit') opening_amount['debit']= Number(opening_amount['debit']) + Number(this.bankForm.controls.balance.value)
        if(this.bankForm.controls.balance_type.value=='credit') opening_amount['credit']= Number(opening_amount['credit']) + Number(this.bankForm.controls.balance.value)
            console.log("opening_amount",opening_amount)
        if(opening_amount && (opening_amount['debit']!=opening_amount['credit'])) {
            this.remainingAmount = Math.abs(Number((opening_amount['debit'] - opening_amount['credit']).toFixed(2)))
            this.amountType = opening_amount['debit']>opening_amount['credit'] ? 'credit' : 'debit'
			let res = await this.adjustAccOpeningBalance()
            if(res['balance']!=this.remainingAmount || this.amountType!=res['balance_type']){
                this.openOBConfirmModal()
			}else{
				this.updateOpeningBalancecombine()
			}
        }else{
            this.updateOpeningBalancecombine()
        }
    }

	adjustAccOpeningBalance(){
        return new Promise(async (resolve, reject) => {
        this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/globalsearch/opening_balance`, {client: 'OB0000165'}).subscribe(res => {
            resolve(res)
        },err=>{
            console.log("err",err)
            resolve(null)
        })
    })
    }
	getOpeningBalanceData(){
        return new Promise(async (resolve, reject) => {
            this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/openingBalanceTotal`, {"account_code":this.generalData.account_code}).subscribe(res => {
                resolve(res)
            },err=> {
                resolve(null)
            })
        })
    }
	openOBConfirmModal(){
		this.confirmOBModal.nativeElement.click()
	}
	 updateOpeningBalancecombine(){
        this.closeOBConfirmModal()
        this.updateGeneral()
    }
	closeOBConfirmModal(){
		this.closeOBModal.nativeElement.click()
	}
	updateOpeningBalanceAdjustAcc() {
        let req_body = {
            balance: this.remainingAmount,
            balance_type: this.amountType,
            account_code: 'OB0000165'
        }
        this.commonService.putRequest(`${environment.okountUrl}/client/updateOpeningBalanceAdjust`, req_body).subscribe(res => {
           
            console.log(res)
        }, err => {
        })

    }
}
	
