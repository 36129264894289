import { Component, ElementRef, EventEmitter, Input, OnInit,AfterViewInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonServiceService } from '../common-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-master-creation',
  templateUrl: './master-creation.component.html',
  styleUrls: ['./master-creation.component.scss']
})
export class MasterCreationComponent implements AfterViewInit {
  @Input() masterType;
  submitted: boolean = false;
  groupList: any;
  showMessage: boolean = false;
  createdMasterCode: ''
	alertType = '';
  keyword = 'name';
	message: string = ``;
  stateArr: any = [];
  masterForm: FormGroup;
  @ViewChild("confirmCreateModal", { static: true }) confirmCreateModal : ElementRef;
  @ViewChild("closeCreateModal", { static: true }) closeCreateModal : ElementRef;
  @ViewChild("accountAc", { static: true }) accountAc;
  @Output() callSetFormData : EventEmitter<any> = new EventEmitter();
  @Output() closeModal : EventEmitter<any> = new EventEmitter();


  @Input() masterCreationModal;
  @Input() currentMasterName;
  constructor(private fb: FormBuilder, public commonService: CommonServiceService, private elementRef: ElementRef ) {
    
  }
  

  ngOnInit(): void {
    this.masterForm = this.fb.group({
			parent_id: ['28', Validators.required],
			address_1: [''],
			gst_number: [''],
			gst_expiry_date: [''],
			mobile_no: [''],
			primary_email: [''],
			name: ['', Validators.required],
      account_name: [''],
      "2_ltr_code": [''],
			"3_ltr_code": [''],
      state_id: [''],
			state_name: ['']
		})
    
    this.getStates('101')
    this.getGroups()

    if(this.masterType && this.masterType == 'client'){
      this.getAccountingCompany()
    }
  }

  ngAfterViewInit() {
    const inputElement = this.elementRef.nativeElement.querySelector('#master_name');
    console.log('inputElement',inputElement);
    
    inputElement.focus();
}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.currentMasterName && this.masterForm && this.masterForm.controls) {
      let currentValue = changes.currentMasterName.currentValue
      this.masterForm.controls.name.setValue(currentValue)
    }

    if(changes && changes.masterType && this.masterForm && this.masterForm.controls) {
      let masterTypeCurrValue = changes.masterType.currentValue;
      let selectedParentId;
      if(masterTypeCurrValue == 'client' ) {
        let sundryDebtorsAccount = this.groupList.find(item => item.name.toLowerCase() == 'sundry debtors')
        selectedParentId = sundryDebtorsAccount.id;
        this.getAccountingCompany()
      }else if(masterTypeCurrValue == 'supplier') {
        let sundryCreditorsAccount = this.groupList.find(item => item.name.toLowerCase() == 'sundry creditors')
        selectedParentId = sundryCreditorsAccount.id;
      }
      this.masterForm.controls.parent_id.setValue(selectedParentId)
    }
  }


  saveMaster() {
    this.submitted = true;
    this.openConfirmationModal();
    if(this.masterForm.invalid) {
      this.showMessage = true
      this.alertType = 'warning-box';
      this.message = 'Please fill mandatory fields.'
      return false;
    }

    let formData = this.formatDataForCreation()
    console.log("formData",formData)

    let url = `${environment.okountUrl}/${this.masterType ? this.masterType.toLowerCase() : ''}`
    this.commonService.postRequest(url, formData).subscribe(res => {
      this.callSetFormData.emit(res[0])
      this.resetForm();
      this.createdMasterCode = res[0].account_code ?  res[0].account_code : ''
      // this.masterCreationModal.nativeElement.click()
      this.closeModal.emit()
    }, error => {
      this.showMessage = true
      this.alertType = 'error-box';
      this.createdMasterCode = ""
      this.message = `Failed to create ${this.masterType}.`
    })
    
  }


  openConfirmationModal() {
    this.confirmCreateModal.nativeElement.click()
  }

  getGroups() {
		this.commonService.getOkount('v3account/getallsuperparents').subscribe(res => {
			this.groupList = res
		}, err => {

		})
	}
  
  hideError() {
		this.showMessage = false
	}

  formatDataForCreation() {
    let formValues = this.masterForm.value;

    let clientObj = {
      "parent_id" : formValues.parent_id ? formValues.parent_id : null,
      "name": formValues.name ? formValues.name : "",
      "gst_number" : formValues.gst_number ? formValues.gst_number : null,
      "gst_expiry_date" : formValues.gst_expiry_date ? formValues.gst_expiry_date : null,
      "primary_email" : formValues.primary_email ? formValues.primary_email : null,
      "mobile_no": formValues.mobile_no ? formValues.mobile_no : null
    }
    
    if(this.masterType == 'client') {
      clientObj['state_id'] = formValues['state_id'] ? formValues['state_id'] : null
      clientObj['state_name'] = formValues['state_name'] ? formValues['state_name'] : null
      clientObj['country_id'] = '101'
      clientObj['country'] = 'India'

    }
    if(this.masterType == 'supplier' || this.masterType == 'client' || this.masterType == 'hotel') {
      return clientObj;
    }

    let obj = {
      "parent_id" : formValues.parent_id ? formValues.parent_id : null,
      "account_name": formValues.name ? formValues.name : ""
    }

    if(this.masterType == 'airline') {
      obj['2_ltr_code'] = formValues['2_ltr_code'] ? formValues['2_ltr_code'] : null
      obj['3_ltr_code'] = formValues['3_ltr_code'] ? formValues['3_ltr_code'] : null
    }
    return obj;

  }

  resetForm() {
    this.masterForm = this.fb.group({
			parent_id: ['28', Validators.required],
			address_1: [''],
			gst_number: [''],
			gst_expiry_date: [''],
			mobile_no: [''],
			primary_email: [''],
			name: ['', Validators.required],
      account_name: [''],
      "2_ltr_code": [''],
			"3_ltr_code": [''],
      state_id: [''],
			state_name: ['']
		})
  }

  getStates(country_id) {
		this.commonService.getOkount(`location/states?country_id=${country_id}`).subscribe(res => {
			this.stateArr = res
		})
	}

  focusAccountAc(){
    if(this.accountAc && this.accountAc.focus) {
      this.accountAc.focus();
    }
  }
  
  getCities(state_id) {
		let selectedState = this.stateArr.filter(option => {
			return option.location_id == Number(state_id);
		})
		if (selectedState.length > 0) {
			this.masterForm.controls.state_name.setValue(selectedState[0].name)
		}
	}

  getAccountingCompany() {
		try {
			this.commonService.getOkount('util/accounting-company').subscribe(async (res) => {
				if(res) {
					if(res['state_name']) {
						this.masterForm.controls.state_name.setValue(res['state_name'])
					}
          if(res['state_id']) {
						this.masterForm.controls.state_id.setValue(res['state_id'])
					}
				}
			})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
		}
	}
}
