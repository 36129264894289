import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReceiptComponent } from './receipt.component';
import { CreateReceiptComponent } from './create-receipt/create-receipt.component';
import { AddEntryReceiptComponent } from './add-entry-receipt/add-entry-receipt.component';
import { ViewReceiptComponent } from './view-receipt/view-receipt.component';
import { UpdateReceiptComponent } from './update-receipt/update-receipt.component';
import { PrintReceiptComponent } from './print-receipt/print-receipt.component';
import { V2CreateReceiptComponent } from './v2-create-receipt/v2-create-receipt.component';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';


const routes: Routes = [
	{
		path: '', component: V2CreateReceiptComponent, canActivate:[AuthGuardService]
	},
	{ path: 'view', component : ViewReceiptComponent, canActivate:[AuthGuardService]},
	{ path: 'update/:id', component : UpdateReceiptComponent, canActivate:[AuthGuardService]},
	{ path: 'print/:id', component : PrintReceiptComponent},

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ReceiptRoutingModule { }
