<form [formGroup]="bankingFilterForm">

	<div class="flexbox aff-info">
		<div class="flexbox aff-info-inner">

			<div class="flexbox">

				<div class="input-fiel">
					<input class="input-ui" formControlName="start_date" id="from_date" type="date" searchtabindex="1"
						min="{{minDate}}" max="{{maxDate}}"
						[ngClass]="{'error-box': !bankingFilterForm.controls.start_date.valid}"
						  />
					<label for="from_date">Starting Date</label>
				</div>
				<div class="input-fiel">
					<input class="input-ui" formControlName="end_date" id="to_date" type="date" searchtabindex="2"
						 min="{{minDate}}" max="{{maxDate}}"
						[ngClass]="{'error-box': !bankingFilterForm.controls.end_date.valid}"
					   />
					<label for="to_date">Ending Date</label>
				</div>

			</div>

			<div class="flexBox mt-3">
				<div class="input-fiel" searchtabindex="3" (focus)="focusSalesprAc()">
					<label class="ngauto-label" for="affname">Bank Account</label>
					<div class="ng-autocomplete">
						<ng-autocomplete [data]="salesPersonArr" #salesprAC [searchKeyword]="keyword"
							(inputChanged)='getSalesPerson($event,false)' [itemTemplate]="itemTemplate"
							formControlName="account_name" (selected)="selectAccount($event,'account_code')"
							[notFoundTemplate]="notFoundTemplate">
						</ng-autocomplete>
						<ng-template #itemTemplate let-item>
							<a innerHTML='<span class="colr-1">{{item.name}}
                                  ({{item.account_code}})</span>'>
							</a>
						</ng-template>

						<ng-template #notFoundTemplate let-notFound>
							<div [innerHTML]="notFound"></div>
						</ng-template>
					</div>
				</div>
			</div>


			<!-- <span class="sub-head ">Report Style</span> -->

			<div class="flexBox d-flex mt-3 ml-2 mr-2 justify-content-between">
				<div class="input-fiel">
					<label class="radio-label" for="normalView">Cleared</label>
					<input class="radio-ui" id="normalView" type="radio" name="viewMode" value="cleared"
						searchtabindex="4" formControlName="viewMode" />
				</div>

				<div class="input-fiel">
					<label class="radio-label" for="summaryView">Un-Cleared</label>
					<input class="radio-ui" id="summaryView" type="radio" name="viewMode" value="uncleared"
						searchtabindex="5" formControlName="viewMode" />
				</div>

				<div class="input-fiel">
					<label class="radio-label" for="detailedView">Both</label>
					<input class="radio-ui" id="detailedView" type="radio" name="viewMode" value="both"
						searchtabindex="6" formControlName="viewMode" />
				</div>

				<div class="input-fiel">
					<label class="radio-label" for="subgroupView">Mismatch</label>
					<input class="radio-ui" id="subgroupView" type="radio" name="viewMode" value="mismatch"
						searchtabindex="7" formControlName="viewMode" />
				</div>
			</div>


			<div class="d-flex mt-5 justify-content-center">
				<div class="input-fiel">
					<button type="button" class="btn-style bg-purple mr-3" (click)="search(bankingFilterForm.value)"
						searchtabindex="8" #searchButton (keyup.enter)="clickSearchButton()" data-dismiss="modal"
						[disabled]="bankingFilterForm.invalid">
						Search</button>
					<button type="button" class="btn-style bg-green" (click)="reset()">RESET</button>
				</div>
			</div>

		</div>

	</div>

</form>