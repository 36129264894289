import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { LedgerComponent } from './ledger.component';
import { NormalLedgerComponent } from './normal-ledger/normal-ledger.component';
import { SummaryLedgerComponent } from './summary-ledger/summary-ledger.component';
import { DetailedLedgerComponent } from './detailed-ledger/detailed-ledger.component';
import { SubgroupLedgerComponent } from './subgroup-ledger/subgroup-ledger.component';
import { LedgerFilterSearchComponent } from './ledger-filter-search/ledger-filter-search.component';


const routes: Routes = [
    {
    path:'', component: LedgerComponent,
    children:[
      {path: 'search-ledger', component: LedgerFilterSearchComponent},
      {path: 'normal-ledger', component: NormalLedgerComponent},
      {path: 'summary-ledger', component: SummaryLedgerComponent},
      {path: 'detailed-ledger', component: DetailedLedgerComponent},
      {path: 'subgroup-ledger', component: SubgroupLedgerComponent},
      {
        path:'',
        redirectTo: 'normal-ledger',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LedgerRoutingModule { }
