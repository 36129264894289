import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';

import * as moment from 'moment-timezone';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { DownloadService } from 'src/app/shared/download.service';
import { Tab } from 'src/app/shared/model/tab.model';
import { TabService } from 'src/app/shared/services/tab.service';
import { InvoiceComponent } from 'src/app/transactions/invoice/invoice.component';
import { InitialState } from 'src/app/store/reducer';
import { Store } from '@ngrx/store';
import { OpenAdvanceSearchModal } from 'src/app/transactions/store/actions';
import * as FileSaver from 'file-saver'
@Component({
	selector: 'app-flown-report',
	templateUrl: './flown-report.component.html',
	styleUrls: ['./flown-report.component.scss']
})
export class FlownReportComponent implements OnInit {
	fromRoot = true;
	isPrint:any;
	selector: string = '.main-panel';
	fromIndex: any = 0;
	dataLimit: any = 100;
	toIndex: any = 30;
	allInvoiceArr: any;
	showPrintData : boolean = false;
	invoiceFilterForm: FormGroup;
	innerHeight: string;
	invoiceArr= [];
	clientArr: any;
	keyword = 'name';
	navigationSubscription
	reInitialise = false;
	p;
	valid: boolean;
	onSearch;
	onSearchFilter = new Subject<string>();

	isAdvancedSearchOpen: boolean = false;
	currentIndex = 0
	@ViewChild('tableHeader', { static: true }) tableHeader: ElementRef
	advancedSearchSubscription: Subscription;
	queryData: any;
	companyDetail: any;
	currentDate = new Date()
	loader: boolean = false;
	inv_duration: any;
	travel_duration: any;
	queryParams: any;
	constructor(
		private formBuilder: FormBuilder,
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private route: ActivatedRoute,
		private router: Router,
		private downloadService: DownloadService,
		private tabService: TabService,
		private store: Store<InitialState>,
	) {

		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	}

	ngOnInit() {

		let queryParams = JSON.parse(JSON.stringify(this.route.queryParams['value']))
		this.queryData = Object.assign({},queryParams)
		if (queryParams.account_type != undefined) {
			localStorage.setItem('activeFlownReportQueryParams', JSON.stringify(queryParams))
		} 
		this.fromIndex = 0;
		queryParams['apply_limit'] = true
		queryParams['limit'] = 30;
        queryParams['offset'] = this.fromIndex;
		this.onSearch = ''
        this.search(queryParams)
		this.queryParams = queryParams
		this.reInitialise=true
		this.onSearchFilter.pipe(
			debounceTime(300),
			distinctUntilChanged())
			.subscribe(value => {
			  this.filterData(value)
		});


	}

	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}

	ngAfterViewInit() {
		this.advancedSearchSubscription = this.commonService.advancedSearch.subscribe(status => {
			console.log('Is modal opened : ', status)
			// this.isAdvancedSearchOpen = status
		})
	}
	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
		if (this.advancedSearchSubscription) {
			this.advancedSearchSubscription.unsubscribe()
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setHeight(event.target.innerHeight)
	}


	setHeight(height) {
		if (this.invoiceArr != undefined) {
			if (height == undefined) {
				height = window.innerHeight
			}
			if (this.invoiceArr.length <= 9) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = height - 180 + 'px';
			}
		}
	}

	async search(values) {
		let reqObj = Object.assign({},values)
		
		this.loader = true;
		let resp = await this.getData(reqObj)
		if(resp){
			this.loader = false;
			this.inv_duration = resp['inv_date_duration']
			this.travel_duration = resp['trvl_date_duration']
			this.invoiceArr = resp['data']
			this.allInvoiceArr = resp['data']
			this.focusOnTableHeader()
			this.currentIndex = 0
			this.setHeight(window.innerHeight)
			// this.commonService.selectedInput.next(1)
			let firstRow = document.getElementById('rowIndex_1') as HTMLElement
			if(firstRow){
				firstRow.scrollIntoView()
			}
		}
		
	}

	

	getData(values){
		return new Promise(async (resolve, reject) => {
			this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/invoice_fare_detail`, values).subscribe(res => {
				resolve(res)
			},err=> {
				resolve(null)
			})
		})
	}

	async onScrollDown(event) {
		console.log('onScrollDown')
		this.fromIndex +=30
		// let toIndex = Number(this.toIndex) + 10
		let queryParams = JSON.parse(JSON.stringify(this.queryParams))
		queryParams['limit'] = 30;
		queryParams['offset'] = this.fromIndex;
		let resp = await this.getData(queryParams)
		if(resp){
			this.invoiceArr = this.invoiceArr.concat(resp['data'])
			this.setHeight(window.innerHeight)
		}
	}

	openAdvanceSearchModal(){
		this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'flown-report'}));	
	}

	getLimitData(offset) {
		let url = `${environment.okountUrl}/globalsearch/invoice_fare_detail`
		let value = Object.assign({},this.queryData)
		this.loader = true;
		value['limit'] = this.dataLimit
		value['offset'] = offset
		value['apply_limit'] = true
		this.commonService.getRequestWithQueryParamsNoLoader(`${url}`, value).subscribe(res => {
			for (const key in res['data']) {
				if (Object.prototype.hasOwnProperty.call(res['data'], key)) {
					const element = res['data'][key];
					if (element != undefined) {
						// this.toIndex = i
						if (this.invoiceArr == undefined) {
							this.invoiceArr = []
						}
						this.invoiceArr.push(element)
					}
				}
			}
			this.loader = false;
			this.focusOnTableHeader()
			this.currentIndex = 0
			this.setHeight(window.innerHeight)
			this.valid = true

		})
	}

	// onScrollDown(event) {
	// 	this.fromIndex = this.fromIndex + this.dataLimit
	// 	let toIndex = Number(this.toIndex) + 30
	// 	this.getLimitData(this.fromIndex)
	// 	// for (let i = this.fromIndex; i <= toIndex; i++) {
	// 	// 	debugger
	// 	// 	const element = this.allInvoiceArr[i];
	// 	// 	if (element != undefined) {
	// 	// 		this.toIndex = i
	// 	// 		this.invoiceArr.push(element)
	// 	// 	}
	// 	// }
	// 	// this.toIndex = toIndex
	// }

	onScrollUp(event) {
	}

	openInvoice(invoice_number) {
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/invoice/view/${invoice_number}`

		if(window.location.protocol != 'file:'){
			if(invoice_number) {
				let title =''
				let newInvoiceParts = invoice_number.split('-')
				title += newInvoiceParts[0]
				title +='...'
				title += newInvoiceParts[newInvoiceParts.length-1]
				this.tabService.addTab(
					new Tab(InvoiceComponent, `${title}`, { invoice: invoice_number ,mode : 'view' }, invoice_number)
					);
			} else {
				window.open(path,'_blank')
			}
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

	focusOnTableHeader() {
		if (this.tableHeader != undefined) {
			this.tableHeader.nativeElement.focus()
		}
	}

	// @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext() {
		if (document.getElementById(`rowIndex_${this.currentIndex + 1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex + 1}`).focus()
			this.currentIndex = this.currentIndex + 1
		}
	}
	moveToPrev() {
		if (document.getElementById(`rowIndex_${this.currentIndex - 1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex - 1}`).focus()
			this.currentIndex = this.currentIndex - 1
		}
	}

	moveToIndex(i) {
		document.getElementById(`rowIndex_${i + 1}`).focus()
		this.currentIndex = i + 1
	}


	oldFilterData(value) {
		let tempArr = Object.assign([], this.allInvoiceArr)
		if (value.length >= 3) {
			let lowerCaseFilter = value.toLowerCase()
			let upperCaseFilter = value.toUpperCase()
			this.invoiceArr = tempArr.filter(
				invoiceData => {
					if ((
						invoiceData.affiliate_name != undefined &&
						invoiceData.affiliate_name != null &&
						(invoiceData.affiliate_name.includes(value) ||
							invoiceData.affiliate_name.includes(upperCaseFilter) ||
							invoiceData.affiliate_name.includes(lowerCaseFilter)
						)
					) ||
						(
							invoiceData.invoice_no != undefined &&
							invoiceData.invoice_no != null &&
							(
								invoiceData.invoice_no.includes(value) ||
								invoiceData.invoice_no.includes(upperCaseFilter) ||
								invoiceData.invoice_no.includes(lowerCaseFilter)
							)
						) ||
						(
							invoiceData.total != undefined &&
							invoiceData.total != null &&
							(
								invoiceData.total.toString().includes(value) ||
								invoiceData.total.toString().includes(upperCaseFilter) ||
								invoiceData.total.toString().includes(lowerCaseFilter)
							)
						) //Convert Number to string.

					) {
						return invoiceData
					}
					console.log(invoiceData)
				}
			)
			this.setHeight(window.innerHeight)
		} else {
			this.invoiceArr = []
			for (let i = 0; i <= 30; i++) {
				const element = this.allInvoiceArr[i];
				if (element != undefined) {
					this.toIndex = i
					this.invoiceArr.push(element)
				}
			}
			this.setHeight(window.innerHeight)
		}
	}

	async filterData(value) {
        if (value.length >= 0) {
            this.queryParams['freetext'] = value
            let queryParams = JSON.parse(JSON.stringify(this.queryParams))
            this.queryParams = queryParams
			this.fromIndex=0
            queryParams['limit'] = 30;
            queryParams['offset'] = this.fromIndex;
			this.loader = true
            let resp = await this.getData(queryParams)
			this.loader = false
            if(resp){
                this.invoiceArr = resp['data']
				let firstRow = document.getElementById('rowIndex_1') as HTMLElement
				if(firstRow){
					firstRow.scrollIntoView()
				}

            }
           
        this.setHeight(window.innerHeight)
        } else {
            delete this.queryParams['freetext']
            this.search(this.queryParams)
            this.setHeight(window.innerHeight)
        }
    }


	exportAsXLSX(): void {
		this.spinner.show()
		let xlsxData = [];
		this.invoiceArr.forEach(data => {
			let obj = {};
			obj["Ticket Number"] = data.ticket_no
			obj["PNR"] = data.pnr
			obj["GDS"] = data.gds_name ? data.gds_name : ''
			obj["Supplier"] = data.supplier_account_name == '' ? data.airline_account_name : data.supplier_account_name
			obj["Issue Date"] = data.created_on ? moment(data.created_on).format('DD/MM/YYYY') : ''
			obj["Invoice Date"] = data.invoice_date ? moment(data.invoice_date).format('DD/MM/YYYY') : ''
			obj["Invoice No."] = data.reference_type == 'invoice' ? data.reference : data.reference_invoice
			obj["Credit Note No."] = data.reference_type == 'credit_note' ? data.reference : ''
			obj["Passenger Name"] = `${data.title} ${data.name}`
			obj["Travelling Date"] = data.travelling_date ? moment(data.travelling_date).format('DD/MM/YYYY') : ''
			obj["Fare Amount"] = Number(data.net_received)
			obj["Basic"] = Number(data.base)
			obj["YQ"] = Number(data.yq)
			obj["YR"] = Number(data.yr)
			obj["K3"] = Number(data.k3)
			obj["OC"] = Number(data.oc)
			obj["OT"] = Number(data.ot)

			xlsxData.push(obj);


		});
		let headerArr = [
			"Ticket Number",
			"PNR",
			"GDS",
			"Supplier",
			"Issue Date",
			"Invoice Date",
			"Invoice No.",
			"Credit Note No.",
			"Passenger Name",
			"Travelling Date",
			"Fare Amount",
			"Basic",
			"YQ",
			"YR",
			"K3",
			"OC",
			"OT"
		]

		this.downloadService.exportAsXLSX(`Flown Report :`, xlsxData);
		this.spinner.hide()
	}

	callPdfGeneratorApi() {
		this.showPrintData = true
		setTimeout(() => {
			window.print()
		this.showPrintData = false
		}, 100);
	}

	downloadPdf() {
		this.commonService.downloadPDF(`${environment.okountUrl}/globalsearch/invoice_fare_detail/download`, this.queryData).subscribe((fileBlob:any) =>{
			let fileName = `Flown Report.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}
	

}
