
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
    <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
      <div class="d-flex flex-y-center">
        <img
          src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
            alertType=='success-box' ? 'green-sucess.svg' : 
            alertType=='warning-box' ? 'red-warning.svg' : 
            null }}"
          width="20px"
          height="20px"
          alt="alert-logo"
          class="mr-20"
        />
        <div class="ml-10">
          <h4 class="m-0 text-size-18 text-bold"
          [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
            {{alertType === "error-box"
            ? "Error"
            : alertType === "warning-box"
            ? "Warning"
            : alertType === "success-box"
            ? "Success"
            : null}}
          </h4>
          <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
           {{message}}
          </small>
        </div>
      </div>
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
        alt="close-btn"
        (click)="hideError()"
        className=" close-btn"
      />
    </div>
  </div>
  

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<button type="button" hidden #openSuccessModal data-toggle="modal" data-target="#successModal"></button>
			<!-- Modal -->
    <div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
        <div class="modal-dialog transaction-creation-modal">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <span class="messageFont">
                        <span>
                            {{message}} 
                        </span>
                            <a *ngIf="invoice_number != ''" 
                                href="javascript:void(0)"	
                                (click)="openInvoice(invoice_number)">
                                {{invoice_number}}
                            </a>
                            <i *ngIf="invoice_number != ''" [title]="copyELementTitle" (click)="copytext(invoice_number)" class="fa fa-copy cursor-pointer"></i>
                        </span>
                        <button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
                    </div>
                <div class="modal-body">
                    <p>Do you want to create new invoice or  view newly created Invoice ?</p>
                </div>
                <div class="modal-footer justify-content-center">
                    <button 
                        tabIndex="1" 
                        attr.tabindex="1"
                        type="button" 
                        class="btn btn-primary bg-blue" 
                        (click)="viewCreditNote(invoice_number)">
                        View
                    </button>
                    <button 
                        tabIndex="2" 
                        attr.tabindex="2"
                        type="button" 
                        class="btn btn-primary btn-grey" 
                        (click)="openInvoice(invoice_number)">
                        Print
                    </button>
                    <button 
                        tabIndex="3" 
                        attr.tabindex="3"
                        type="button" 
                        class="btn btn-primary bg-green"  
                        (click)="closeSuccesModal()">
                        Create New
                    </button>
                </div>
            </div>

        </div>
    </div>

<div class="ledger-container " [ngClass]="{'d-none': !newInvoice}">
    <div class="row tab-nav pl-0 pr-0">
        <!-- <div class="col-sm-4 pl-0">
            <ul class="list-inline list-unstyled p-0 m-0">
                <li class="active page-title text-uppercase cursor"> View Client</li>
            </ul>
        </div> -->
        <div class="col-sm-12 ">
          <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
              <li  
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> Refund</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li  
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> View </li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li class="text-grey text-capitalize cursor text-primary transition"> Edit </li>
          </ul>
      </div>
    
    </div>

    <form [formGroup]="nestedForm" #form="ngForm"  class="credit-note-form">

       
        <div class=" custom-form form-container w-100 pt-10">
            <div class="flexbox aff-info pl-15 pr-15">
                <div class="flexbox aff-info-inner ">
    
                    <div class="flexbox">
                        <!-- <div class="input-field">
                    <input  oninput="this.value = this.value.toUpperCase()" class="input-ui" id="affname" type="text" placeholder="" />
                   </div> -->
    
                        <div class="form-group d-flex">
                            <div tabIndex="1" attr.tabIndex="1" class="input-fiel form-group ng-field">
                                <label class="ngauto-label" for="affname">Account</label>
                                <div class="autocomplete-container">
                                    <div class="input-container">
                                    <input
                                        type="text" #accountAC oninput="this.value = this.value.toUpperCase()" 
                                        id="affname" name="affiliate_name" 
                                        formControlName="affiliate_name"
                                        (input)="onChangeSearch($event)"
                                        [nzAutocomplete]="clientAc"
                                    />
            
                                    <nz-autocomplete #clientAc [nzBackfill]="true"  (selectionChange)="selectEvent($event)">
                                        <nz-auto-option  class="colr-1" *ngFor="let item of affiliateArr" [nzValue]="item" [nzLabel]="item.name">
                                            <a innerHTML='<span class="colr-1">{{item.name}}
                                                ({{item.account_code}})</span><br />
                                               <span class="colr-2">{{item.primary_email}}</span><br />'>
                                            </a>                    
                                        </nz-auto-option>                      
                                    </nz-autocomplete>
                                </div>
                            </div>
                            </div>
    
                            <!-- <div class="input-fiel flex-column d-flex"
                                [ngClass]="{'has-error': (nestedForm.controls.billaddress.errors?.required && nestedForm.controls.billaddress.dirty) , 'has-success': !nestedForm.controls.billaddress.errors?.required }">
    
    
                                <input oninput="this.value = this.value.toUpperCase()" placeholder="Billing Address"
                                    id="billaddress" disabled formControlName="billaddress" class="input-ui" />
                                <label for="billaddress">Billing Address</label>
    
    
    
                                <span class="error-ui mt-0 ml-2"
                                    *ngIf="nestedForm.controls.billaddress.dirty && nestedForm.controls.billaddress.errors?.required">
                                    This field is required.</span> -->
                                <!-- <li *ngIf="form.controls.content.dirty">Start writing from second line</li> -->
    
                            <!-- </div> -->
    
                            <div class="input-fiel" *ngIf="invoiceMode != 'view'">
                                <input oninput="this.value = this.value.toUpperCase()" class="input-ui inv-date-input"
                                    formControlName="invdate" id="invdate" type="date" disabled
                                    placeholder="Invoice Issue Date" />
                                <label for="invdate">Invoice Date</label>
    
                            </div>
                            <div class="input-fiel">
                                <input tabIndex="2" attr.tabIndex="2" oninput="this.value = this.value.toUpperCase()"
                                    class="input-ui inv-date-input" formControlName="refund_date" id="refund_date"
                                    min="{{minDate}}" 
                                    max="{{maxDate}}"
                                    type="date" (change)="refundDateChange()" 
                                    [ngClass]="{'error-box': !nestedForm.controls.refund_date.valid}"/>
                                <label for="refund_date" *ngIf="invoiceMode != 'view'">Refund Date</label>
                                <label for="refund_date" *ngIf="invoiceMode == 'view'">Date</label>
                            </div>

                            <div class="input-fiel" *ngIf="invoiceMode == 'view'">
                                <input oninput="this.value = this.value.toUpperCase()" class="input-ui inv-date-input"
                                    formControlName="booking_ref_no" id="booking_ref_no" disabled
                                    placeholder="Booking Ref No" />
                                <label for="invdate">Booking Ref No</label>
    
                            </div>
                            <ng-container *ngIf="!isTenantIndian">
                                <div class="mr-3" >
                                    <div class="input-fiel">
                                        <select tabIndex="3" attr.tabIndex="3" id="flightType" style="min-width: 160px;"  class="input-ui" formControlName="purchase_currency"  (change)="onpurchaseCurrencyChange($event.target.value)" >
                                            <option value="">Select</option>
                                            <option value="AED">AED</option>
                                            <option value="CAD">CAD</option>
                                            <option value="USD">USD</option>
                                            <option value="INR">INR</option>
                                            <option value="EURO">EURO</option>
                                            <option value="POUND">POUND</option>
                                        </select>
                                        <label for="purchase_currency">Purchase Curr.</label>
                                    </div>
                                </div>
                                <div class="" >
                                    <div class="input-fiel">
                                        <input tabIndex="4" attr.tabIndex="4" oninput="this.value = this.value" class="input-ui inv-number-input" id="currency_rate"
                                        formControlName="currency_rate" type="text"
                                        />
                                        <label for="currency_rate">Rate({{nestedForm.value.purchase_currency}}-{{currencyCode}})</label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
    
                        <!-- <div class="input-field">
                    <input  oninput="this.value = this.value.toUpperCase()" class="input-ui" id="affcode" type="text" placeholder="Affiliate Code" /><label for="affcode">Affiliate Code</label>
                   </div> -->
                    </div>
    
    
    
                </div>
    
                <div class="flexbox inv-detail">
                    <div class="input-fiel mr-0">
                        <input oninput="this.value = this.value.toUpperCase()" class="input-ui inv-number-input" id="uuid"
                            formControlName="uuid"  type="text" placeholder="Invoice Number" />
                        <label for="uuid" *ngIf="invoiceMode != 'view'">Invoice Number</label>
                        <label for="uuid" *ngIf="invoiceMode == 'view'">Credit Note No.</label>
                    </div>
                    <div *ngIf="invoiceMode=='create'">   
                        <button type="button" class="btn-style bg-green "  (click)="retrieveInvoice(nestedForm.value.uuid)"
                    >Retrieve</button>
                    </div>
    
    
                    <!-- <div class="inv-total">
                        <span class="tot-txt">Total Amount</span>
                        <span class="grand-amt">₹ {{ gstAmount+summedAmount}}</span>
                    </div> -->
                </div>
    
            </div>
            <div class="inner-nav mt-2 pl-15" [ngClass]="fixedModule ? 'inner-nav mt-2 fixed-content' : 'inner-nav mt-2'">

                <div>

                    <app-flight-refund (callProductEvent)="makeProduct($event)" [invoice_root_data]="nestedForm.value" 
                    [sectorService]="sectorService" [invoice_no]="invoice_no" [invoiceMode] = "invoiceMode" >
                    </app-flight-refund>


                </div>

            </div>




            <div class="custom-table mt-3 pl-15 ">
                <table class="table table-bordered outer-table-group d-none" formArrayName="paymentdetails">
                    <thead>
                        <tr>
                            <th style="max-width: 50px;">#</th>
                            <th>Product / Service</th>
                            <th>HSN/SAC</th>
                            <th>Narration</th>
                            <th>Qty</th>
                            <th>Rate</th>
                            <th>Amount</th>
                            <!-- <th>Tax <small>(in %)</small></th> -->
                            <th>Tax</th>
                            <th style="max-width: 50px;"
                                [ngClass]="{'table-scroll-fix': paymentArray.controls.length >5 }" class=""></th>
                        </tr>
                    </thead>

                    <tbody class="outer-table-group-tbody">
                        <tr *ngFor="let group of paymentArray.controls; let i = index; " [formGroupName]="i">
                            <td style="max-width: 50px"> {{i+1}} </td>
                            <td>
                                <span class="custom-tr-input">
                                    <input oninput="this.value = this.value.toUpperCase()" type="text" name="name"
                                        formControlName="name" />
                                    <span class="error-ui"
                                        *ngIf="group['controls'].name.dirty && group['controls'].name.errors?.required">
                                        This field is required
                                    </span>
                                </span>
                            </td>
                            <td>
                                <span class="custom-tr-input">
                                    <input oninput="this.value = this.value.toUpperCase()" type="text" name="hsc_sac"
                                        formControlName="hsc_sac" />
                                    <span class="error-ui"
                                        *ngIf="group['controls'].hsc_sac.dirty && group['controls'].hsc_sac.errors?.required">
                                        This field is required
                                    </span>
                                </span>
                            </td>

                            <td>
                                <span class="custom-tr-input">
                                    <input oninput="this.value = this.value.toUpperCase()" type="text" name="narration"
                                        formControlName="narration" />
                                    <span class="error-ui"
                                        *ngIf="group['controls'].narration.dirty && group['controls'].narration.errors?.required">
                                        This field is required
                                    </span>
                                </span>
                            </td>

                            <td>
                                <span class="custom-tr-input">
                                    <input class="text-right" oninput="this.value = this.value.toUpperCase()"
                                        type="text" name="quantity" formControlName="quantity"
                                        (input)="perItemCalculation(i)" />
                                    <span class="error-ui"
                                        *ngIf="group['controls'].quantity.touched && group['controls'].quantity.errors?.required">
                                        This field is required
                                    </span>
                                    <span class="error-ui"
                                        *ngIf="!group['controls'].quantity.valid && group['controls'].quantity.hasError('pattern')">
                                        Only Numbers Allowed</span>

                                </span>
                            </td>

                            <td>
                                <span class="custom-tr-input">
                                    <input class="text-right" oninput="this.value = this.value.toUpperCase()"
                                        type="text" name="rate" formControlName="rate"
                                        (input)="perItemCalculation(i)" />
                                    <span class="error-ui"
                                        *ngIf="group['controls'].rate.touched && group['controls'].rate.errors?.required">
                                        This field is required
                                    </span>
                                    <span class="error-ui"
                                        *ngIf="!group['controls'].rate.valid && group['controls'].rate.hasError('pattern')">
                                        Only Numbers Allowed</span>
                                </span>
                            </td>

                            <td>
                                <span class="custom-tr-input ">
                                    <input class="text-right" oninput="this.value = this.value.toUpperCase()"
                                        type="text" name="amount" formControlName="amount"
                                        (input)="perItemAmountCalculation(i)" />
                                    <span class="error-ui"
                                        *ngIf="group['controls'].amount.touched && group['controls'].amount.errors?.required">
                                        This field is required
                                    </span>
                                    <span class="error-ui"
                                        *ngIf="!group['controls'].amount.valid && group['controls'].amount.hasError('pattern')">
                                        Only Numbers Allowed</span>
                                </span>
                            </td>

                            <td>
                                <span class="custom-tr-input">
                                    <input class="text-right" oninput="this.value = this.value.toUpperCase()"
                                        type="text" name="tax" formControlName="tax" (input)="calculateGst()" />
                                    <span class="error-ui"
                                        *ngIf="group['controls'].tax.dirty && group['controls'].tax.errors?.required">
                                        This field is required
                                    </span>
                                </span>
                            </td>


                            <td style="max-width: 50px;" class="delete-row" (click)="removepayment(i)">
                                <!-- <button type="button"  > -->
                                <i class="fa fa-trash" aria-hidden="true">

                                </i>

                                <!-- </button> -->
                            </td>

                        </tr>
                    </tbody>

                </table>



                <div class="table-actions  justify-content-between d-none">
                    <div class="action">


                        <button type="button" class="btn-ui bg-green mr-3" (click)="addpayment()">Add row</button>
                        <button type="button" class="btn-ui bg-blue mr-3">Add Subtotal</button>
                        <button type="button" class="btn-ui bg-red" (click)="clearalllines()">Clear all lines</button>
                    </div>

                    <div class="subtotal">
                        <!-- <div class="total-box">
                        <span class="title-type">Subtotal</span>
                        <span class="result-type">&#8377; 0.00</span>
                    </div> -->

                        <div class="total-box">
                            <span class="title-type">Total</span>
                            <span class="result-type">&#8377;
                                {{summedAmount}}
                            </span>
                        </div>

                        <div class="total-box">
                            <span class="title-type">Balance Due</span>
                            <span class="result-type">&#8377; 0.00</span>
                        </div>

                        <div class="total-box">
                            <span class="title-type">GST total</span>
                            <span class="result-type">&#8377;
                                {{ gstAmount}}
                            </span>
                        </div>

                        
                    </div>

                      
                    
                   
                </div>

                <div class="text-input-group d-flex justify-content-between mt-3">
                    <div class="input-fiel mr-2 w-100">
                        <input tabIndex="{{sectorService.lastIndexOfTraveller+3}}"
                            attr.tabIndex="{{sectorService.lastIndexOfTraveller+3}}"
                            class="input-ui w-100" id="narration"
                            formControlName="narration_5" type="text" placeholder="Narration" />
                            <label 
                                for="narration" 
                                [ngClass]="{'error-label-narration': !nestedForm.controls.narration_5.valid && nestedForm.controls.narration_5.hasError('pattern')}">
                                Narration 5
                            </label>
                            <span 
                                class="error-ui"
                                *ngIf="!nestedForm.controls.narration_5.valid && nestedForm.controls.narration_5.hasError('pattern')">
                                Invalid special chars
                            </span>
                    </div>
                    <div class="input-fiel mr-0 w-100">
                        <input tabIndex="{{sectorService.lastIndexOfTraveller+4}}"
                            attr.tabIndex="{{sectorService.lastIndexOfTraveller+4}}"
                            oninput="this.value = this.value.toUpperCase()" class="input-ui w-100" id="remark"
                            formControlName="remark" type="text" placeholder="Remark" /><label
                            for="remark">Remark</label>
                    </div>
                </div>

                <div *ngIf="!isTenantIndian" class="currencyWrapper mt-5">
                    <div class="row">
                        <div class="col-8"></div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-4 input-fiel" style="margin-right: 0!important;">
                                    <select id="flightType"   class="input-ui inv-date-input" formControlName="sales_currency" >
                                        <option value="">Select</option>
                                        <option value="AED">AED</option>
                                        <option value="CAD">CAD</option>
                                        <option value="USD">USD</option>
                                        <option value="INR">INR</option>
                                        <option value="EURO">EURO</option>
                                        <option value="POUND">POUND</option>
                                    </select>
                                    <label for="sales_currency">Sales Curr.</label>
                                </div>
        
                                <div class="col-4  input-fiel p-0" style="margin-right: 0!important;">
                                    <input oninput="this.value = this.value" class="input-ui inv-number-input" id="sales_currency_rate" formControlName="sales_currency_rate"
                                    type="text" (input)="changeSalesCurrentAmount($event)" type="text"/>
                                    <label for="sales_currency_rate">Sales Curr. Rate</label>
                                </div>
        
                                <div class="col-4 input-fiel " style="margin-right: 0!important;">
                                    <input  disabled class="input-ui inv-number-input" id="sales_currency_amount" formControlName="sales_currency_amount" type="text"  />
                                    <label for="sales_currency_amount">Sales Amount</label>
                                </div>
                            </div>
                        </div>
                    </div>

                   
                </div>  

            </div>
        </div>
        <div *ngIf="(!creditNoteData) || creditNoteData.is_consolidate!=true" class="btn-field bottom-btns-container full-width-btn-container mt-30   d-flex align-item-center justify-content-end  ml-0 mr-0">  
            <!-- <button class="btn-ui mr-3" (click)="getValue(nestedForm.value)" > SAVE</button> -->
                                 
            <ng-container *ngIf="invoiceMode != 'view'">
                <div class="w-100 d-flex align-item-center">
                    <div class="d-flex align-items-center flex-row" *ngIf="showRefundableCheckbox === true">
                        <label for="clientRefundable" class="mb-0 clientRefLbl">Non Refundable</label>
                        <input class="ml-10" formControlName="nonRefundable" id="clientRefundable" type="checkbox" style="height: 14px;"/>
                    </div>

                    <div class="d-flex align-items-center flex-row  mr-20" *ngIf="billOfSupplyActive === true">
                        <label for="clientBos" class="mb-0 clientRefLbl">Bill Of Supply</label>
                        <input class="ml-10" formControlName="bill_of_supply" id="clientBos" type="checkbox" style="height: 14px;"/>
                    </div>
                    <div class="d-flex align-items-center flex-row  mr-20" >
                        <label for="rcm_applicable" class="mb-0 clientRefLbl">RCM Applicable</label>
                        <input class="ml-10" formControlName="rcm_applicable" id="rcm_applicable" type="checkbox" style="height: 14px;"/>
                    </div>
                    <div class="d-flex align-items-center flex-row  mr-20" >
                        <label for="reverse_tds" class="mb-0 clientRefLbl">Reverse TDS</label>
                        <input class="ml-10" formControlName="reverse_tds" id="reverse_tds" type="checkbox" style="height: 14px;"/>
                    </div>
                    
                    <button type="button" class="ml-auto btn-style bg-purple"
                        tabIndex="{{sectorService.lastIndexOfTraveller+5}}"
                        attr.tabIndex="{{sectorService.lastIndexOfTraveller+5}}"
                        (click)="saveCreditNote(nestedForm.value)" id="save_credit_note_btn"> Save</button>
                </div>
            </ng-container>
           
            <ng-container *ngIf="invoiceMode == 'view'">
                <div class="w-100 d-flex align-item-center">
                    <div class="d-flex align-items-center flex-row" *ngIf="showRefundableCheckbox === true">
                        <label for="clientRefundable" class="mb-0 clientRefLbl">Non Refundable</label>
                        <input class="ml-10" disabled formControlName="nonRefundable" id="clientRefundable" type="checkbox" style="height: 14px;"/>
                    </div>

                    <div class="d-flex align-items-center flex-row  mr-20" *ngIf="billOfSupplyActive === true">
                        <label for="clientBos" class="mb-0 clientRefLbl">Bill Of Supply</label>
                        <input class="ml-10" formControlName="bill_of_supply" id="clientBos" type="checkbox" style="height: 14px;"/>
                    </div>
                    <div class="d-flex align-items-center flex-row  mr-20" >
                        <label for="rcm_applicable" class="mb-0 clientRefLbl">RCM Applicable</label>
                        <input class="ml-10" formControlName="rcm_applicable" id="rcm_applicable" type="checkbox" style="height: 14px;"/>
                    </div>
                    <div class="d-flex align-items-center flex-row  mr-20" >
                        <label for="reverse_tds" class="mb-0 clientRefLbl">Reverse TDS</label>
                        <input class="ml-10" formControlName="reverse_tds" id="reverse_tds" type="checkbox" style="height: 14px;"/>
                    </div>

                    <div class="ml-auto">
                        <button tabIndex="{{sectorService.lastIndexOfTraveller+7}}"
                        attr.tabIndex="{{sectorService.lastIndexOfTraveller+7}}" type="button"
                        *hasAccess="{'key' : 'F0003013'}"                    
                        (click)="openUpdateConfirmModal()" class="btn-style bg-cyan mr-3" id="modify_credit_note_btn">Modify</button>
                        <button tabIndex="{{sectorService.lastIndexOfTraveller+10}}"
                        *hasAccess="{'key' : 'F0003014'}"
                        attr.tabIndex="{{sectorService.lastIndexOfTraveller+10}}" type="button"
                        (click)="openDeleteConfirmModal()" class="btn-style mr-3 bg-red" id="delete_credit_note_btn">Delete</button>
                        <button tabIndex="{{sectorService.lastIndexOfTraveller+6}}"
                        attr.tabIndex="{{sectorService.lastIndexOfTraveller+6}}" type="button"
                        class="btn-style bg-denim mr-3" [routerLink]="['/transactions/invoice','view', invoice_code]">
                        View Invoice</button>  
                        <span style="position: relative; display: inline-block;" class="mr-3">
                    <button tabIndex="{{sectorService.lastIndexOfTraveller+8}}"
                        attr.tabIndex="{{sectorService.lastIndexOfTraveller+8}}" type="button"
                        (click)="clickToPrintOptions()" class="btn-style bg-blue-ivy">Print</button>
                        <span class="form-invoice-btn"  *ngIf="showPrintOptions">
                                <button type="button"
                                tabIndex="{{sectorService.lastIndexOfTraveller+5}}" attr.tabIndex="{{sectorService.lastIndexOfTraveller+5}}" 
                                (click)="openInvoice(invoice_no)" class="btn-style bg-denim mr-2">Credit Note</button>
    
                                <button type="button" *ngIf="isTenantIndian"
                                (click)="checkWhetherEInvGeneratedOrNot(invoice_no)" class="btn-style bg-denim ">E-Invoice</button>
                            </span>
                        </span>
                    <button tabIndex="{{sectorService.lastIndexOfTraveller+9}}"
                        attr.tabIndex="{{sectorService.lastIndexOfTraveller+9}}" type="button"
                        (click)="viewJournal(invoice_no)" class="btn-style mr-3 bg-brown" id="journal_credit_note_btn">Journal
                    </button>
                   
                    <button type="button" class="btn-style bg-rusty-red text-white"
                    tabIndex="{{sectorService.lastIndexOfTraveller+11}}" attr.tabIndex="{{sectorService.lastIndexOfTraveller+11}}" 
                        (click)="openSendEmailModal()" id="credit_note_send_mail_btn">Email</button>
                    </div>
                </div>
               
            </ng-container>

        </div>
        <!-- </div> -->
    </form>
</div>


<button type="button" hidden #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button>

<div class="modal fade" id="deleteConfirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" #closeDeleteModal data-dismiss="modal" aria-label="Close" (click)="resetCurrentOpendModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>"delete ‎this entry"</b> ?
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
                <button type="button" class="btn-style mr-2" (click)="closeDeleteConfirmModal()" id="no_delete_credit_note_confirm_modal_btn">No</button>
                <button type="button" class="btn-style bg-purple" (click)="deleteCreditNote(invoice_no)" id="yes_delete_credit_note_confirm_modal_btn">Yes</button>
            </div>
        </div>
    </div>
</div>

<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateConfirmationModal"></button>

<div class="modal fade" id="updateConfirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close" (click)="resetCurrentOpendModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>" update ‎this entry " </b> ?
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
                <button type="button" class="btn-style mr-2" (click)="closeUpdateConfirmModal()" id="no_update_credit_note_confirm_modal_btn">No</button>
                <button type="button" class="btn-style bg-purple"
                    (click)="updateCreditNote(nestedForm.value)" id="yes_update_credit_note_confirm_modal_btn">Yes</button>
            </div>
        </div>
    </div>
</div>


 <!-- Email Modal for sending mail -->
 <button
 hidden
 #openEmailModal
 data-target="#emailModalOfCreditNote"
 data-toggle="modal"
 data-backdrop="false"
></button>
 <div
   class="modal"
   id="emailModalOfCreditNote"
   role="dialog"
   aria-labelledby="emailModalLabel"
 >
   <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header">
         <div class="d-flex justify-content-center">
           <h4 class="modal-title">Send Email</h4>
         </div>
         <button
           type=" button"
           class="close"
           #closeSendEmailModal
           data-dismiss="modal"
           aria-label="Close"
         >
           <span aria-hidden="true">&times;</span>
         </button>
       </div>
       <div class="modal-body emailSenders">
         <!-- <div class="d-flex justify-content-end align-item-center">
                           <span *ngIf="!ccEmail" (click)="showCCEmail()" class="text-size-14 mb-0 cursor  mr-2">Cc</span>
                           <span *ngIf="!bccEmail" (click)="showBCCEmail()" class="text-size-14 mb-0 cursor">Bcc</span>
                       </div> -->
         <form [formGroup]="sendEmailForm" style="margin-top: -10px">
           <div class="form-group text-input-field">
             <textarea
               class="input-field ng-pristine ng-valid ng-touched text-size-12"
               id="toEmail"
               rows="1"
               formControlName="toEmails"
               required
             ></textarea>
             <label for="To" class="mb-0">To</label>
           </div>
           <div class="d-flex justify-content-end">
             <!-- <label for="To" class="text-size-14 mb-0">To</label> -->
             <div *ngIf="sendEmailForm.controls.toEmails.invalid">
               <div
                 *ngIf="sendEmailForm.controls.toEmails.errors?.required"
                 style="color: red"
               >
                 To Email is required.
               </div>
             </div>
           </div>

           <div class="form-group text-input-field ">
             <textarea
               class="input-field ng-pristine ng-valid ng-touched text-size-12"
               id="ccEmail"
               style="max-height: 45px;"
               formControlName="ccEmails"
             ></textarea>
             <label for="Cc" class="mb-0">Cc</label>
           </div>

           <div class="form-group text-input-field">
             <textarea
               class="input-field ng-pristine ng-valid ng-touched text-size-12"
               id="bccEmail"
               style="max-height: 45px;"
               formControlName="bccEmails"
             ></textarea>
             <label for="Bcc" class="mb-0">Bcc</label>
           </div>
           <div class="text-input-field">
            <input formControlName="subject"  class="input-field text-size-12" type="text" 
            autocomplete="off" />
            <label>Subject</label>
        </div>
        <div class="form-group text-input-field">
         <textarea
           class="input-field ng-pristine ng-valid ng-touched text-size-12"
           rows="5"
           formControlName="body"
         ></textarea>
         <label for="body" class="mb-0">Mail Body</label>
         <input type="file" (change)="handleAttachment($event)" />
       </div>
           <div class="mt-3 d-flex justify-content-center align-item-center">
             <button type="button" class="btn-style mr-2" data-dismiss="modal">
               Cancel
             </button>
             <button
               type="button"
               class="btn-style bg-green"
               (click)="callTosendEmail()"
             >
               Send
             </button>
           </div>
         </form>
       </div>
     </div>
   </div>
 </div>
<!-- </div> -->

<button type="button" hidden #generateEInvoiceModal data-toggle="modal" data-target="#openGenerateEInvoiceModal"></button>

<div class="modal fade" id="openGenerateEInvoiceModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Confirmation</h4>
                <button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                E-invoice not generated,do you want to generate? 
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-style mr-2" (click)="closeGenerateEInvoiceModal()">No</button>
                <button type="button" class="btn-style bg-red" (click)="generateEInvoice(invoice_no)">Yes</button>
            </div>
        </div>
    </div>
</div>