import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BankComponent } from './bank.component';
import { CreateBankComponent } from './create-bank/create-bank.component';
import { UpdateBankComponent } from './update-bank/update-bank.component';
import { BankRoutingModule } from './bank-routing.module';
import { ViewBankComponent } from './view-bank/view-bank.component'

@NgModule({
  declarations: [BankComponent, CreateBankComponent,  UpdateBankComponent, ViewBankComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BankRoutingModule
  ]
})
export class BankModule { }
