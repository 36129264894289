import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddEntryProformaComponent } from './add-entry-proforma/add-entry-proforma.component';
import { ProformaInvoiceRoutingModule } from './proforma-invoice-routing.module';
import { ProformaInvoiceComponent } from './proforma-invoice.component';
import { RouterModule } from '@angular/router';
import { PrintProformaComponent } from './print-proforma/print-proforma.component';


@NgModule({
  declarations: [ProformaInvoiceComponent, AddEntryProformaComponent, PrintProformaComponent],
  imports: [
    CommonModule,
    ProformaInvoiceRoutingModule,
    SharedModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  providers : [],
  exports: [AddEntryProformaComponent]
})
export class ProformaInvoiceModule { }
