
<div  class="scroll-wrapper">
<form [formGroup]="ledgerFilterForm">
	<div class="flexbox aff-info">
		<div class="flexbox aff-info-inner">
			<div class="flexbox mb-3">
				<div class="input-fiel">
					<input class="input-ui" formControlName="start_date" id="from_date" type="date"
					    min="{{minDate}}" max="{{maxDate}}"
						[ngClass]="{'error-box': !ledgerFilterForm.controls.start_date.valid}"
						searchtabindex="1"   />
					<label for="from_date">Starting Date</label>
				</div>
				<div class="input-fiel">
					<input class="input-ui" formControlName="end_date" id="to_date" type="date" searchtabindex="2" 
					min="{{minDate}}" max="{{maxDate}}"
					[ngClass]="{'error-box': !ledgerFilterForm.controls.end_date.valid}"
						  />
					<label for="to_date">Ending Date</label>
				</div>
			</div>


			<div class="flexbox mb-3">

				<div class="input-fiel">
					<input class="input-ui" formControlName="credit_note_date" id="credit_note_date" type="date" 
					    min="{{minDate}}" max="{{maxDate}}"
						searchtabindex="3" [ngClass]="{'error-box': !ledgerFilterForm.controls.credit_note_date.valid}"/>
					<label for="from_date">Credit Note</label>
				</div>
				<div class="input-fiel">
					<input class="input-ui" formControlName="pay_rec_date" id="pay_rec_date" type="date" 
					    min="{{minDate}}" max="{{maxDate}}"
						searchtabindex="4" [ngClass]="{'error-box': !ledgerFilterForm.controls.pay_rec_date.valid}" />
					<label for="to_date">Pay || Rec</label>
				</div>

			</div>

			<div class="flexbox">
				<div class="select-field text-input-fiel">
					<select class="input-field" searchtabindex="5" formControlName="account_type" (change)="clearGroup($event,'group')">
						<option value="none">None</option>
						<option value="client">Client</option>
						<option value="airline">Airline</option>
						<option value="supplier">Supplier</option>
						<option value="general">General</option>
					</select>
					<label for="subLedger">Account Type</label>

				</div>

				<div class="select-field text-input-fiel" *ngIf="ledgerFilterForm.value.account_type=='client'">
					<select class="input-field" searchtabindex="6" formControlName="group" (change)="updateViewMode($event)" >
						
						<ng-container *ngFor="let item of allGroupArr let i=index;">

							<option value="" *ngIf="i==0">NONE</option>
						   <option [value]="item.id">{{item.client_group_name}}</option>
						</ng-container>
					</select>
					<label for="group">Client Group</label>
				</div>
				
				<div class="input-fiel form-group ng-field" searchtabindex="7" (focus)="focusAccountAc()">
					<label class="ngauto-label" for="affname">Account</label>
					<div class="ng-autocomplete">
						<ng-autocomplete [data]="clientArr" #accountAC (inputCleared)="clearData('client')"
							[searchKeyword]="keyword" (inputChanged)='getClient($event,false)'  disabled="{{ledgerFilterForm.value.account_type == 'none' || ledgerFilterForm.value.viewMode == 'summary'}}"

							historyIdentifier="clientArr" [itemTemplate]="itemTemplate" formControlName="client_name"
							(selected)="selectAccount($event,'client')" [notFoundTemplate]="notFoundTemplate">

						</ng-autocomplete>
						<ng-template #itemTemplate let-item>
							<a innerHTML='<span class="colr-1">{{item.name}}
                                ({{item.account_code}})</span><br />
                            <span class="colr-2">{{item.primary_email}}</span><br />'>
							</a>
						</ng-template>
						<ng-template #notFoundTemplate let-notFound>
							<div [innerHTML]="notFound"></div>
						</ng-template>
					</div>
				</div>
			</div>




			<div class="flexBox d-flex mt-3 ">
				<div class="select-field text-input-fiel">
					<select class="input-field" searchtabindex="8" formControlName="subledger_type">
						<option value="client">Client</option>
						<option value="airline">Airline</option>
						<option value="supplier">Supplier</option>
						<option value="employee">Employee</option>
						<option value="general">General</option>
					</select>
					<label for="subLedger">Sub Ledger Type</label>

				</div>

				<div class="input-fiel" searchtabindex="9" (focus)="focusSubledgerAc()">
					<label class="ngauto-label" for="uuid">Sub Ledger</label>
					<div class="ng-autocomplete">
						<ng-autocomplete [data]="ledgerList" #subledgerAC [searchKeyword]="keyword"
							(inputChanged)='getLedgers($event,false)' (inputCleared)="clearData('sub_ledger_code')"
							(selected)="selectAccount($event,'sub_ledger_code')" [itemTemplate]="itemTemplate"
							formControlName="sub_ledger_code_name" [notFoundTemplate]="notFoundTemplate">
						</ng-autocomplete>


						<ng-template #itemTemplate let-item>
							<a
								innerHTML='<span class="colr-1">{{item?.name}}
                         <ng-container  *ngIf="item.account_code != null">({{item?.account_code}})</ng-container> </span>'>
							</a>
						</ng-template>

						<ng-template #notFoundTemplate let-notFound>
							<div [innerHTML]="notFound"></div>
						</ng-template>
					</div>
				</div>



			</div>


			<div class="flexBox d-flex mt-3">
				<div class="input-fiel" searchtabindex="10" (focus)="focusSalesprAc()">
					<label class="ngauto-label" for="affname">Sales Person</label>
					<div class="ng-autocomplete">
						<ng-autocomplete [data]="salesPersonArr" #salesprAC (inputCleared)="clearData('sales_person')"
							[searchKeyword]="keyword" (inputChanged)='getSalesPerson($event,false)'
							(selected)="selectAccount($event,'sales_person')" [itemTemplate]="itemTemplate"
							formControlName="sales_person_name" [notFoundTemplate]="notFoundTemplate">
						</ng-autocomplete>
						<ng-template #itemTemplate let-item>
							<a innerHTML='<span class="colr-1">{{item.name}}
                                  ({{item.account_code}})</span><br />
                              <span class="colr-2">{{item.primary_email}}</span><br />'>
							</a>
						</ng-template>

						<ng-template #notFoundTemplate let-notFound>
							<div [innerHTML]="notFound"></div>
						</ng-template>
					</div>
				</div>

				<div class="input-fiel">
					<input class="input-ui" id="uuid" type="text" searchtabindex="11" formControlName="category"
						placeholder="Category" /><label for="uuid">Category</label>
				</div>
			</div>


			<div class="flexBox d-flex mt-3 ">
				<div class="input-fiel form-group ng-field" searchtabindex="12" (focus)="focusCityAc()">
					<label class="ngauto-label" id="citylabel" for="uuid">City</label>
					<div class="ng-autocomplete">
						<ng-autocomplete #cityAC formControlName="city_id" [data]="cityArr"
							(inputCleared)="clearData('city_id')" [searchKeyword]="cityKeyword"
							(inputChanged)='getCity($event)' [itemTemplate]="cityItem"
							[notFoundTemplate]="cityNotFoundTemplate">
						</ng-autocomplete>
						<ng-template #cityItem let-cityDetail>
							<a
								innerHTML='<span class="colr-1">{{cityDetail.city}} ({{cityDetail.country}})</span><br />'>
							</a>
						</ng-template>

						<ng-template #cityNotFoundTemplate let-notFound>
							<div [innerHTML]="notFound"></div>
						</ng-template>
					</div>
				</div>
				<div class="select-field text-input-fiel">
					<select class="input-field" formControlName="balance_type" searchtabindex="13">
						<option value="true">YES</option>
						<option value="false">NO</option>
					</select>
					<label for="uuid">B/F Balance</label>
				</div>

			</div>

			<div class="flexbox mt-3">
				<div class="select-field text-input-fiel">
				  <select class="input-field"  formControlName="selectedBranch" id="selectedBranch">
					<option value="">Select</option>
					<option value="">None</option>
					<option value="all">All</option>
					<option *ngFor="let item of branches" value="{{item.tenant_id}}">{{item?.branch_name ? item.branch_name : "HQ"}}
					</option>
				  </select>
				  <label for="selectedBranch">Branch</label>
				</div>
			  </div>


			<span class="report-style-heading border-bottom mr-10 mt-10 mb-10 pb-10">Report Style</span>

			<div class="flexBox d-flex mt-1 ml-2 mr-2 justify-content-between">

				<div class="input-fiel" *ngIf="ledgerFilterForm.value.account_type!='none' ">
					<label class="radio-label" for="summary">Summary</label>
					<input class="radio-ui" id="summaryView" type="radio" name="viewMode" value="summary"
						[searchtabindex]="14" formControlName="viewMode" />
				</div>
				<div class="input-fiel" *ngIf="ledgerFilterForm.value.account_type!='none' ">
					<label class="radio-label" for="normalView">Normal</label>
					<input class="radio-ui" id="normalView" type="radio" name="viewMode" value="normal"
						[searchtabindex]="(ledgerFilterForm.value.account_type=='client' && ledgerFilterForm.value.group!='')?15:14" formControlName="viewMode" />
				</div>
				<!-- 
				<div class="input-fiel">
					<label class="radio-label" for="summaryView">Summary</label>
					<input class="radio-ui" id="summaryView" type="radio" name="viewMode" value="summary"
						searchtabindex="14" formControlName="viewMode" />
				</div> -->

				<div class="input-fiel">
					<label class="radio-label" for="detailedView">Detailed</label>
					<input class="radio-ui" id="detailedView" type="radio" name="viewMode" value="detailed"
					[searchtabindex]="(ledgerFilterForm.value.account_type=='client' && ledgerFilterForm.value.group!='')?16:15" formControlName="viewMode" />
				</div>


				<ng-container *ngIf="ledgerFilterForm.value.account_type!='none' else SubgroupOption">
					<div class="input-fiel">
						<label class="radio-label" for="subgroupView">Sub Grouping</label>
						<input class="radio-ui" id="subgroupView" type="checkbox" name="grouping" value="subgroup"
						[searchtabindex]="(ledgerFilterForm.value.account_type=='client' && ledgerFilterForm.value.group!='')?17:16" formControlName="grouping" />
					</div>
				</ng-container>

				<ng-template #SubgroupOption>
					<div class="select-field text-input-fiel">
						<select class="input-field" formControlName="subgroup_type">
							<option value="">Select</option>
							<option value="client">Client</option>
							<option value="supplier">Supplier</option>
						</select>
					</div>
				</ng-template>

				
			</div>


			<div class="d-flex mt-5 justify-content-center">
				<div class="input-fiel">
					<button id="submitSearchForm" class="btn-style bg-green mr-3"
						[disabled]="ledgerFilterForm.invalid || (ledgerFilterForm.value.client == '' && ledgerFilterForm.value.account_type != 'none' && ledgerFilterForm.value.viewMode != 'summary') || (ledgerFilterForm.value.account_type == 'none' && !ledgerFilterForm.value.subgroup_type)"
						[ngClass]="{disbaledBtn: ledgerFilterForm.invalid || (ledgerFilterForm.value.client == '' && ledgerFilterForm.value.account_type != 'none' && ledgerFilterForm.value.viewMode != 'summary') || (ledgerFilterForm.value.account_type == 'none' && !ledgerFilterForm.value.subgroup_type)} "
						(click)="search(ledgerFilterForm.value)" [searchtabindex]="(ledgerFilterForm.value.account_type=='client' && ledgerFilterForm.value.group!='')?18:17" #searchButton
						(keyup.enter)="clickSearchButton()"> Search</button>
					<button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
					<button type="button" hidden class="btn-style" #closeModal data-dismiss="modal"></button>
				</div>
			</div>

		</div>

	</div>

</form>
</div>