import { AfterViewInit, Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ShortcutEventOutput, KeyboardShortcutsComponent, ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CommonServiceService } from './shared/common-service.service';
import { environment } from 'src/environments/environment';
import { CommonDataService } from './shared/services/common-data.service';
import { GlobalData } from './shared/services/globla-data';
import { CentrifugeService } from './shared/services/centrifuge.service';
import { CookiesService } from './shared/cookies.service';
import { InitialState } from "src/app/store/reducer";
import { NgRedux } from "@angular-redux/store";
import { Store } from '@ngrx/store';
import { Tab } from './shared/model/tab.model';
import { TabService } from './shared/services/tab.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})


export class AppComponent {
	title = 'account-web-client';
	public previousUrl: string = undefined;
	public currentUrl: string = undefined;
	private appTitle = document.getElementById('appTitle')
	public currentModal = ""
	// public logoutModal = "logout"
	hasAccessToken: boolean;
	showHeader: boolean = true;
	showLeftBar: boolean = true;
	showNotificationArea: any;
	notificationCount = 1;
	notificationArr;
	ICentrifugeConfig: any;
	newNotification=[]
	tabs = new Array<Tab>();
  	selectedTab: number = 0;
	showTabs = false;
	loaderTemplate: any;
	showRefreshModal: boolean = false;
	myAppURL;
	appVersionFromApi;
	currentVersion;
	isModalOpen = false
	allowShortcuts = true;
	minDate = '';
	maxDate = '';
	dateFilterForm: FormGroup;
	@ViewChild('refreshForm') refreshForm: ElementRef;
	@ViewChild('newRelease') openModal:ElementRef;
	@ViewChild('globalDateModal') globalDateModal:ElementRef;
	@ViewChild('startDateInput') startDateInput: ElementRef;


	  constructor(
		private router : Router,
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private commonDataService : CommonDataService,
		private centrifugeService : CentrifugeService,
		private cookiesService : CookiesService,
		private store: Store<InitialState>,
		private tabService : TabService,
		private cookieService : CookiesService,
		public sanitizer: DomSanitizer,
		private fb: FormBuilder
		) {
			
		if(this.cookieService.getCookie('SYSTACC_ACCESS_TOKEN')){
			localStorage.setItem('okount_token',this.cookieService.getCookie('SYSTACC_ACCESS_TOKEN'))
		}
		// if(this.cookieService.getCookie('SYSTACC_FINANCIAL_YEAR')){
		// 	localStorage.setItem('current_financial_year',this.cookieService.getCookie('SYSTACC_FINANCIAL_YEAR'))
		// 	sessionStorage.setItem('current_financial_year',this.cookieService.getCookie('SYSTACC_FINANCIAL_YEAR'))

		// }
		if(this.cookieService.getCookie('SYSTACC_USER_LOGIN_DATA')){
			let userData = JSON.parse(this.cookieService.getCookie('SYSTACC_USER_LOGIN_DATA'))
			let userInfo = {
				roles : userData.roles,
				name : userData.t_user_name,
				id : userData.t_user_id,
				accountcode : userData.user_code,
			}
			localStorage.setItem('okountUserInfo',JSON.stringify(userInfo))
		}

		this.sanitizer = sanitizer; 
		if (localStorage.getItem('okountUserInfo') != null && localStorage.getItem('okountUserInfo') != undefined && localStorage.getItem('accessData') != undefined) {
			let userInfo = JSON.parse(localStorage.getItem('okountUserInfo'))
			// await this.getFeatureData(userInfo.roles[0].roleid)
			this.commonService.getRequestNoloader(`${environment.okountUrl}/featureaccess/role?id=${userInfo.roles[0].roleid}`).subscribe(res=>{
				localStorage.setItem('accessData',JSON.stringify(res))
				GlobalData.setAccessData(res);
			}, error =>{
				console.log(error);
			})
		}

		if(window && window.location && window.location.hash){
			let splitHash = window.location.hash.split('?')
			if(splitHash && splitHash.length > 1){
				let queryString = splitHash[1]
				let queryParams = new URLSearchParams(queryString)
				if(queryParams && queryParams.get('access_token')){
					console.log('queryParams : ',queryParams.get('access_token'))
					this.cookieService.setCookie('SYSTACC_ACCESS_TOKEN',queryParams.get('access_token'))
					localStorage.setItem('okount_token',queryParams.get('access_token'))
					let jwt = this.commonService.parseJwt(queryParams.get('access_token'));
					if(jwt && jwt['DISABLE_SHORTCUTS'] && jwt['DISABLE_SHORTCUTS'] === true) {
						this.allowShortcuts = false;
					}
				}
				if(queryParams && queryParams.get('ubr_id')){
					this.hasAccessToken = false;
				}
			}
		}
		
		this.store.select('sharedReducers').subscribe((items)=>{
				if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				} else {
					let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
					if(!current_financial_year) {
						current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
					}
					this.minDate = current_financial_year.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				}

			if(items.loaderTemplate ){
				this.loaderTemplate = this.sanitizer.bypassSecurityTrustHtml(items.loaderTemplate)
			}		
		})

		router.events.subscribe( (event) => {
			if(event['url'] != undefined) {
			}    
			if (event instanceof NavigationEnd) {
				this.previousUrl = this.currentUrl;
				this.currentUrl = event['url'];
				// this.route.paramMap.subscribe(params => {
				// 	console.log(params['params'])
				// });
				// let params = this.getParams(this.currentUrl)
				let title = 'SYSTACC - An Accounting Software for Travel Agencies'
				if(this.currentUrl.includes('dashboard') || (event['urlAfterRedirects'] && event['urlAfterRedirects'].includes('dashboard'))){
					window.location.href = `${environment.accountManagement}`
				}
				if (this.currentUrl.includes('transactions/payment')) {
					title = 'Payment'
					this.currentModal = 'payment'
					if (this.currentUrl.includes('print')) {
						// title = 'AccountWebClient'
					}
				}
				if (this.currentUrl.includes('transactions/invoice')) {
					title = 'Invoice'
					this.currentModal = 'invoice'
					if (this.currentUrl.includes('transactions/invoice/view')) {
						let urlArr = this.currentUrl.split('/')
						let invoiceNo = urlArr.pop()
						title = `Invoice - ${invoiceNo}`
						if(invoiceNo.includes('BSI')) {
							title = `BOS Invoice - ${invoiceNo}`
						}
					}
				}
				if (this.currentUrl.includes('transactions/search-invoice')) {
					this.currentModal = 'invoice'
					title = 'Invoice'
				}
				if (this.currentUrl.includes('display/account-book/ledger')) {
					this.currentModal = 'ledger'
					title = 'LEDGER REPORT'
				}
				if (this.currentUrl.includes('report/psr-report')) {
					this.currentModal = 'psr-report'
					title = 'PSR REPORT'
				}
				if (this.currentUrl.includes('report/psr-detailed-report')) {
					this.currentModal = 'psr-report'
					title = 'PSR DETAILED'
				}
				if (this.currentUrl.includes('report/plb')) {
					this.currentModal = 'plb-report'
					title = 'PLB REPORT'
				}
				if (this.currentUrl.includes('display/statutory-report/gst-report')) {
					this.currentModal = 'gstreport'
				}
				if (this.currentUrl.includes('gst-report/b2b-report')) {
					title = 'B2B GST REPORT'
				}
				if (this.currentUrl.includes('gst-report/b2cs-report')) {
					title = 'B2Cs GST REPORT'
				}
				if (this.currentUrl.includes('gst-report/hsn-report')) {
					title = 'HSN  REPORT'
				}
				if (this.currentUrl.includes('gst-report/docs-report')) {
					title = 'DOCS  REPORT'
				}
				if (this.currentUrl.includes('/report/adjust-balance')) {
					title = 'Adjustment'
				}
				// if (this.currentUrl.includes('transactions/credit-note')) {
				// 	title = 'Credit Note'
				// }
				if (this.currentUrl.includes('display/statutory-report/tcs-report')) {
					this.currentModal = 'tcsreport'
					title = 'TCS'
				}
				if (this.currentUrl.includes('display/statutory-report/tds-report')) {
					this.currentModal = 'tdsreport'
					title = 'CLIENT TDS'
				}
				if (this.currentUrl.includes('display/day-book-report')) {
					this.currentModal = 'daybook'
					title = 'DAY BOOK REPORT'
				}
				if (this.currentUrl.includes('display/statement-account/outstanding-report')) {
					this.currentModal = 'outstanding'
					title = 'CLIENT STATEMENT'
				}
				if (this.currentUrl.includes('display/account-book/ledger')) {
					title = 'LEDGER'
				}
				if (this.currentUrl.includes('master/client')) {
					this.currentModal = 'client'
					title = 'CLIENT'
				}
				if (this.currentUrl.includes('master/general')) {
					this.currentModal = 'general'
					title = 'GENERAL'
				}
				if (this.currentUrl.includes('master/bank')) {
					this.currentModal = 'general'
					title = 'BANK'
				}
				if (this.currentUrl.includes('master/tour')) {
					this.currentModal = 'general'
					title = 'TOUR'
				}
				if (this.currentUrl.includes('master/employee')) {
					this.currentModal = 'employee'
					title = 'EMPLOYEE'
				}
				if (this.currentUrl.includes('master/airline')) {
					this.currentModal = 'airline'
					title = 'AIRLINE'
				}
				if (this.currentUrl.includes('master/supplier')) {
					this.currentModal = 'supplier'
					title = 'SUPPLIER'
				}
				if (this.currentUrl.includes('transactions/receipt')) {
					this.currentModal = 'receipt'
					title = 'RECEIPT'
				}
				if (this.currentUrl.includes('transactions/jv')) {
					this.currentModal = 'jv'
					title = 'JV'
				}
				if (this.currentUrl.includes('transactions/search-credit-note') || this.currentUrl.includes('transactions/credit-note')) {
					this.currentModal = 'credit-note'
					title = 'CREDIT NOTE'
				}
				if (this.currentUrl.includes('report/balance-sheet')) {
					title = 'BALANCE SHEET'
				}
				if (this.currentUrl.includes('email-screen/progress')) {
					this.currentModal = 'email-status'
					title = 'Email Status'
				}
				if (this.currentUrl.includes('report/profit-loss')) {
					this.currentModal = 'profit-loss'
					title = 'PROFIT & LOSS'
				}
				if (this.currentUrl.includes('report/trial-balance')) {
					this.currentModal = 'trial-balance'
					title = 'TRIAL BALANCE'
				}
				if (this.currentUrl.includes('/report/client-unpaid-report')) {
					this.currentModal = 'unpaid-report'
					title = 'UNPAID REPORT'
				}
				if (this.currentUrl.includes('/report/profit-report/bill-wise')) {
					this.currentModal = 'bill-wise-profit-report'
					title = 'BILL WISE PROFITABILITY'
				}
				if (this.currentUrl.includes('/profit-report/supplier-wise')) {
					this.currentModal = 'supplier-wise-ticket'
					title = 'SUPPLIER TICKET STATEMENT'
				}
				if (this.currentUrl.includes('/report/aging-report/')) {
					this.currentModal = 'aging-report'
					title = 'AGING REPORT'
				}
				if (this.currentUrl.includes('/report/flown-report')) {
					this.currentModal = 'flown-report'
					title = 'FLOWN REPORT'
				}
				if (this.currentUrl.includes('transactions/search-adm-acm')) {
					this.currentModal = 'adm_acm'
					title = 'ADM ACM'
				}
				if (this.currentUrl.includes('report/b2c-adjustment')) {
					this.currentModal = 'b2c-adjustment'
					title = 'B2C-ADJUSTMENT'
				}
				if (this.currentUrl.includes('report/mis-report')) {
					this.currentModal = 'mis-report'
					title = 'MIS REPORT'
				}
				if (this.currentUrl.includes('report/client-sales-report')) {
					this.currentModal = 'client-sales-report'
					title = 'CLIENT SALES REPORT'
				}
				if (
					this.currentUrl.includes('transactions/invoice-view;searchId') ||
					this.currentUrl.includes('transactions/print-credit-note')  || 
					this.currentUrl.includes('transactions/payment/print/')  || 
					this.currentUrl.includes('transactions/receipt/print/') ||
					this.currentUrl.includes('print/account-book/ledger/outstanding-summary-ledger') ||
					this.currentUrl.includes('print-pdf/pdf-summary-ledger') ||
					this.currentUrl.includes('print/account-book/ledger/summary-ledger') ||
					this.currentUrl.includes('print/account-book/ledger/normal-ledger') ||
					this.currentUrl.includes('print/account-book/ledger/detailed-ledger')  ||
					this.currentUrl.includes('print/account-book/ledger/subgroup-ledger')||
					this.currentUrl.includes('print-pdf/pdf-client-outstanding')||
					this.currentUrl.includes('transactions/print-transactions') || 
					this.currentUrl.includes('transactions/ms-invoice-view') ||
					this.currentUrl.includes('transactions/tour/print')

				) {
					this.showHeader = false
					this.showLeftBar = false
				}else{
					this.showHeader = true
				}
				if(this.cookiesService.getCookie("SYSTACC_IS_DEMO") == 'true') title = `DEMO | ${title}`
				this.appTitle.innerText = title
				// console.log(`Page loaded with URL : ${this.currentUrl} at : ${new Date()}`)
			};  
		  })
		
	}

	async ngOnInit() {
		if (localStorage.getItem('okountUserInfo') != null && localStorage.getItem('okountUserInfo') != undefined && localStorage.getItem('accessData') != undefined) {
			let userInfo = JSON.parse(localStorage.getItem('okountUserInfo'))
			let accessData = localStorage.getItem('accessData') ? JSON.parse(localStorage.getItem('accessData')) : null
			accessData = accessData ? accessData : GlobalData.getAccessData();
			if(accessData == undefined){
				accessData = await this.commonService.getFeatureData(userInfo.roles[0].roleid)
			}
			if(accessData){
				this.commonService.setMenuStatus(true)
			}
		}
		
		this.tabService.tabSub.subscribe(tabs => {
			this.tabs = tabs;
			this.selectedTab = tabs.findIndex(tab => tab.active);
			
			console.log('TABS : ',tabs,this.selectedTab,this.showLeftBar)
		});
		if (localStorage.getItem('okount_token') != null && localStorage.getItem('okount_token') != undefined) {
			this.commonService.showLogout(true)
		}else{
			this.commonService.showLogout(false)
		}
		this.commonService.logOutStatus.subscribe(isLoggedIn => {
			console.log(isLoggedIn)
			this.hasAccessToken = isLoggedIn
		})
		this.commonService.toggleTab.subscribe(showtab =>{
			console.log('tab changed',showtab)
			this.showTabs = showtab
		})
		let systaccUrl = environment.baseAccountUrl+`/v3/`
		// let systaccUrl = 'http://localhost:4300'+`/v3/`+`${window.location.hash}`
		if(this.cookiesService.getCookie("isSystacc") == "true" && !window.location.href.includes(systaccUrl)){
			this.switchVersion(true)
		}
		

		this.getNotifications();

		try {
			this.myAppURL = window.location.href;
			this.isModalOpen = false
			if (this.centrifugeService.handler && this.centrifugeService.connected) {
				this.centrifugeService.handler.disconnect();
			}
			this.subscribeVersionCentrifuge();
			
			this.appVersionFromApi = ""
			this.currentVersion =  this.cookieService.getCookie("app-version")
			let latestVersion = await this.getVersionControlData()
			if(latestVersion && latestVersion != "" && latestVersion != this.currentVersion){
				this.appVersionFromApi = latestVersion
				this.openVersionControlModal()
			}
		} catch (error) {
			console.error(error)
		}

		this.connectToCentri('account_prod_notification')
		this.dateFilterForm = this.fb.group({
			start_date: [this.minDate, Validators.required],
			end_date: [this.maxDate, Validators.required],
		})

		this.commonService.selectedInput.next(1)

	}	

	getNotifications() {
		this.commonService.getRequest(`${environment.baseAccountUrl}/reconciliation/api/core/notification`).subscribe(res=> {
			this.notificationArr = res['data']
			this.notificationCount = res['data'].length
		},err=> {

		})
	}
	

	// @ViewChild(KeyboardShortcutsComponent) private keyboard: KeyboardShortcutsComponent;  

	shortcuts: ShortcutInput[] = [];
	ngAfterViewInit() {
		// this.keyboard.select("alt + f").subscribe(e => {
		// 	debugger;
		// 	console.log(e)
		// });  


		if (this.startDateInput) {
			this.startDateInput.nativeElement.focus();
		}

		if(this.hasAccessToken){
			
			let splitHash = window.location.hash.split('?')
			if(splitHash && splitHash.length > 1){ 
				let queryString = splitHash[1]
				let queryParams = new URLSearchParams(queryString)
				if(queryParams && queryParams.get('access_token')){
					let jwt = this.commonService.parseJwt(queryParams.get('access_token'));
					if(jwt && jwt['DISABLE_SHORTCUTS'] && jwt['DISABLE_SHORTCUTS'] === true) {
						this.allowShortcuts = false;
					}
				}
			}
				
			this.allowShortcuts  && this.shortcuts.push(			
				// {
				// 	key: ["f2"],
				// 	allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				// 	label: "Search",
				// 	description: "Search",
					
				// 	command: (e)=>{
				// 		this.commonService.setDropDownStatus(true)
				// 	},
				// 	preventDefault: true
				// },
				{
					key: ["f11"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Search",
					description: "Reconciliation",
					
					command: (e)=>{
						this.router.navigate(['/utilities/upload-reports'])
					},
					preventDefault: true
				},
				{
					key: ["alt + f"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Search",
					description: "Search",
					
					command: (e)=>{
						this.commonService.setSearchModal(this.currentModal)
					},
					preventDefault: true
				},
				{
					key: ["alt + enter"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Submit",
					description: "Search",
					
					command: (e)=>{
						var element = document.getElementById("submitSearchForm")
						if(element && !element['disabled']){
							element.click()
							element.setAttribute('disabled','true')
							setTimeout(() => {
								element.removeAttribute('disabled')
							}, 100,element);
						}
					},
					preventDefault: true
				},
				{
					key: ["alt + r"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Submit",
					description: "Reset",
					
					command: (e)=>{
						var element = document.getElementById("resetSearchForm")
						if(element && !element['disabled']){
							element.click()
							element.setAttribute('disabled','true')
							setTimeout(() => {
								element.removeAttribute('disabled')
							}, 100,element);
						}
					},
					preventDefault: true
				},
				{
					key: ["f8"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Help",
					description: "Invoice",
					
					command: (e)=>{
						this.router.navigate(['/transactions/invoice'])
					},
					preventDefault: true
				},
				{
					key: ["alt + m"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Help",
					description: "MS - Invoice",
					
					command: (e)=>{
						this.router.navigate(['/transactions/ms/ms-invoice'])
					},
					preventDefault: true
				},
				{
					key: ["f9"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Help",
					description: "View Credit Note",
					
					command: (e)=>{
						this.router.navigate(['/transactions/search-credit-note'])
					},
					preventDefault: true
				},
				{
					key: ["alt + f9"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Help",
					description: "Create Credit Note",
					
					command: (e)=>{
						this.commonService.setSearchModal('create_credit_note')
						// this.router.navigate(['display/account-book/ledger/normal-ledger'])
					},
					preventDefault: true
				},
				{
					key: ["f2"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Global Date",
					description: "Global Dates Filter",
					command: (e)=>{
						this.openStartAndEndDateModal()
					},
					preventDefault: true
				},
				{
					key: ["f5"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Help",
					description: "Payment",
					
					command: (e)=>{
						this.router.navigate(['/transactions/payment'])
					},
					preventDefault: true
				},
				{
					key: ["f6"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Help",
					description: "Receipt",
					
					command: (e)=>{
						this.router.navigate(['/transactions/receipt'])
					},
					preventDefault: true
				},	{
					key: ["f7"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Help",
					description: "JV",
					
					command: (e)=>{
						this.router.navigate(['/transactions/jv/create'])
					},
					preventDefault: true
				},
				{
					key: ["alt + shift + l"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Help",
					description: "View Ledger",
					
					command: (e)=>{
						this.commonService.setSearchModal('ledger')
						// this.router.navigate(['display/account-book/ledger/normal-ledger'])
					},
					preventDefault: true
				},
				{
					key: ["alt + shift + o"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Help",
					description: "OutStanding Report",
					
					command: (e)=>{
						this.router.navigate(['display/statement-account/outstanding-report'])
					},
					preventDefault: true
				},
				{
					key: ["alt + shift + d"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Help",
					description: "Day Book",
					
					command: (e)=>{
						this.router.navigate(['display/day-book-report'])
					},
					preventDefault: true
				},
				{
					key: ["alt + shift + g"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Help",
					description: "GST Statement",
					
					command: (e)=>{
						this.router.navigate(['display/statutory-report/gst-report/b2b-report'])
					},
					preventDefault: true
				},
				// {
				// 	key: ["alt + shift + t"],
				// 	allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				// 	label: "Help",
				// 	description: "TDS Statement",
					
				// 	command: (e)=>{
				// 		this.router.navigate(['display/statutory-report/tds-report'])
				// 	},
				// 	preventDefault: true
				// },
				{
					key: ["alt + shift + r"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Help",
					description: "Role",
					
					command: (e)=>{
						this.router.navigate(['user-management/role/create-role'])
					},
					preventDefault: true
				},
				{
					key: ["alt + shift + p"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Help",
					description: "Privilages",
					
					command: (e)=>{
						this.router.navigate(['user-management/feature'])
					},
					preventDefault: true
				},
				{
					key: ["alt + t"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Tabs Navigation",
					description: "Next Tab",
					
					command: (e)=>{
						let nextTab = this.selectedTab+1 < this.tabs.length && this.tabs.length > 0 ? this.selectedTab+1 : -1
						if(nextTab < 0){
							this.commonService.toggleTabs(false)
							this.tabService.setActive(-1)
						}else{
							this.commonService.toggleTabs(true)
							this.tabService.setActive(nextTab)

						}
					},
					preventDefault: true
				},
				{
					key: ["alt + shift + t"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Tabs Navigation",
					description: "Previous Tab",
					
					command: (e)=>{
						let nextTab = this.selectedTab-1 > -2 ? this.selectedTab-1 : this.tabs.length-1
						if(nextTab < 0){
							this.commonService.toggleTabs(false)
							this.tabService.setActive(-1)
						}else{
							this.commonService.toggleTabs(true)
							this.tabService.setActive(nextTab)

						}
					},
					preventDefault: true
				},
				{
					key: ["alt + x"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Tabs Navigation",
					description: "Close Active Tab",
					
					command: (e)=>{
						this.tabService.removeTab(this.selectedTab)
					},
					preventDefault: true
				},
				{
					key: ["alt + shift + x"],
					allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
					label: "Tabs Navigation",
					description: "Close All Tabs",
					
					command: (e)=>{
						this.tabService.removeAllTab()
						this.tabService.setActive(-1)
						this.commonService.toggleTabs(false)
					},
					preventDefault: true
				}
			);
		}
	}

	public getParams = function (url) {
		var params = {};
		var parser = document.createElement('a');
		parser.href = url;
		var query = parser.search.substring(1);
		var vars = query.split('&');
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=');
			params[pair[0]] = decodeURIComponent(pair[1]);
		}
		return params
	};

	showNotificationAreaFn(){
		this.showNotificationArea = !this.showNotificationArea
	}

	connectToCentri(channelName){
		try {
			this.ICentrifugeConfig = {
				url : environment.centri_ws,
				token : environment.centri_token,
				user : "Daud",
				timestamp : Date.now() / 1000 | 0,
				debug : true,
				reconnect : true
			}
			this.centrifugeService.connect(this.ICentrifugeConfig)
			
			this.centrifugeService.handler.subscribe(channelName, (ctx)=> {
				console.log('ctx', ctx);
				
				this.handleNewNotification(ctx.data)
			});
			// this.centrifuge.connect();
		} catch (error) {
			console.log(error);
		}
	}
	handleNewNotification(data: any) {
		this.newNotification.push(data)
		this.launch_toast()
		setTimeout(() => {
			this.closeToast(data)
		}, 6000,data);
	}

	// toast
    launch_toast() {
		let url = `${this.commonService.aws_asset_url}/_assets/notification_tone/notification-sound.mp3`
		var x = document.getElementById("toast")
		if(x){
			x.className = "show";
			setTimeout(function(){ x.className = x.className.replace("show", ""); }, 6000,x);
		}
		var audio = new Audio(url);
		audio.currentTime = 0; 
		audio.play();
	}
  
	closeToast(item){
		this.newNotification.splice(this.newNotification.indexOf(item),1)
	}
	switchVersion(isBeta){
		this.cookiesService.setCookie("isSystacc",isBeta,null)
		let systaccUrl = environment.baseAccountUrl+`/v3/`
		let baseUrl = environment.baseAccountUrl
		// let systaccUrl = 'http://localhost:4300'+`/v3/`+`${window.location.hash}`
		// let baseUrl = 'http://localhost:4200'+`/${window.location.hash}`
		if(isBeta && !window.location.href.includes(systaccUrl)){
			window.location.href = systaccUrl
		}else{
			window.location.href = baseUrl
		}
	}

	subscribeVersionCentrifuge ()  {
		try {
			this.ICentrifugeConfig = {
				url : environment.centri_ws,
				token : environment.centri_token,
				user : "Daud",
				timestamp : Date.now() / 1000 | 0,
				debug : true,
				reconnect : true
			}
			this.centrifugeService.connect(this.ICentrifugeConfig)
			let channelName = 'systacc_version_control';
			this.centrifugeService.handler.subscribe(channelName, (ctx)=> {
				let data = ctx.data
				console.log(data);
				
				if(data && data.payload && data.payload.data && data.payload.data.response && data.payload.data.response.source && data.payload.data.response.version) {
					let responseData = data.payload.data.response;
					let currentVersion = this.cookieService.getCookie('app-version')
					if(responseData['version'] && responseData['version'] != currentVersion){
						this.appVersionFromApi = responseData['version'];
						this.openVersionControlModal();
					}
				}
			   
			});
		} catch (error) {
			console.log(error);
		}
	  }
	
	getVersionControlData () {
		return new Promise((resolve, reject) => {
		this.commonService.getRequestNoloader(`${environment.okountUrl}/version?source=systacc`).subscribe(res=>{
			if(res && res['data'] && res['data']['version']) {
				resolve(res['data']['version']);
			}else{
				resolve("")
			}
		},error =>{
			this.appVersionFromApi = ""
		    resolve("")
			console.log(error)
		})
	  })
	}

	forceReload() {
		this.refreshForm.nativeElement.submit();
	}

	setVersion(){
	   this.isModalOpen = false
	   this.cookieService.setCookie("app-version", this.appVersionFromApi);
	   this.forceReload();
	}

	openVersionControlModal(){
		this.isModalOpen = true
		this.openModal.nativeElement.click()
	}

	openStartAndEndDateModal() {
		this.globalDateModal.nativeElement.click();
	}

	onSearchDates() {
		let start_date = this.dateFilterForm.controls.start_date.value ? moment(this.dateFilterForm.controls.start_date.value).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
		let end_date = this.dateFilterForm.controls.end_date.value ? moment(this.dateFilterForm.controls.end_date.value).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
		var currentUrl = window.location.href;

		currentUrl = currentUrl.replace(/(start_date=)[^&]+/, '$1' + start_date);
		currentUrl = currentUrl.replace(/(end_date=)[^&]+/, '$1' + end_date);

		window.location.href = currentUrl;
	}

}

