import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StatementAccountRoutingModule } from './statement-account-routing.module';
import { StatementAccountComponent } from './statement-account.component';
import { OutstandingReportComponent } from './outstanding-report/outstanding-report.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [StatementAccountComponent, OutstandingReportComponent],
  imports: [
    CommonModule,
    SharedModule,
    StatementAccountRoutingModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ]
})
export class StatementAccountModule { }
