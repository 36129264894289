import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MiscellaneousInvoiceRoutingModule } from './miscellaneous-invoice-routing.module';
import { MiscellaneousInvoiceComponent } from './miscellaneous-invoice.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { MiscellaneousInvoiceService } from './services/miscellaneous-invoice.service';
import { SectorService } from '../flight/services/sector.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MsFareSummaryComponent } from './ms-fare-summary/ms-fare-summary.component';
import { MsInvoiceComponent } from './ms-invoice/ms-invoice.component';
import { GstInvoiceComponent } from './gst-invoice/gst-invoice.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';



@NgModule({
  declarations: [MiscellaneousInvoiceComponent, MsFareSummaryComponent, MsInvoiceComponent, GstInvoiceComponent],
  imports: [
    CommonModule,
    MiscellaneousInvoiceRoutingModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    FormsModule, 
    ReactiveFormsModule,
    KeyboardShortcutsModule.forRoot()
  ],
  exports : [MsInvoiceComponent],
  providers : [CommonServiceService,MiscellaneousInvoiceService]
})
export class MiscellaneousInvoiceModule { }
