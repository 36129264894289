import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { EventService } from './services/event.service';
import * as moment from 'moment';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { InvoiceServicesService } from '../invoice/flight/services/invoice-services.service';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TabService } from 'src/app/shared/services/tab.service';
import { CookiesService } from 'src/app/shared/cookies.service';
import * as FileSaver from 'file-saver'; 

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  providers: [EventService]
})


export class EventComponent implements OnInit {
  @Input() data;

  @ViewChild("subLedgerAC", { static: true }) subLedgerAC;
  @ViewChild("supplierAc", { static: true }) supplierAc;
  @ViewChild("supplier2Ac", { static: true }) supplier2Ac;
  @ViewChild("clientAC", { static: true }) clientAC;
  @ViewChild("generalLedgerAc", { static: true }) generalLedgerAc;
  @ViewChild("employeeAC", { static: true }) employeeAC;
  @ViewChild("ledgerCardAC", { static: true }) ledgerCardAC;
  @ViewChild("narrationArea", { static: true }) narrationArea;
  @ViewChild("openMasterCreationModal", { static: true }) masterCreationModal : ElementRef;
  @ViewChild("closeMasterCreationModal", { static: true }) closeMasterCreationModal : ElementRef;

  // bookingSupplierArr: any;
  bookingClientArr: any = [];
  bookingSupplierArr: any = [];
  bookingGenerlaAccountArr: any = [];
  
  isSuccess: boolean = false;
	showMessage: boolean = false;
	alertType = ''
	message: string = ``;

  invoiceMode = 'save'
  loggedInuser: any;
  keyword="name";
	generalAccountArr: any;
  initialValue = '';
  employeeList: any;
  invoice_number: any;
  uuid: unknown;
  invoice_no: any = '';
  invoiceData: any;
  payablesArr: any = [];
  selectedClient: any;
  selectedSupplier: any;
  showTravellerIndex;
  maximizedSection: boolean;
  @ViewChildren('openModal') openModal: QueryList<ElementRef>;
  @ViewChild("openSuccessModal", { static: true }) openSuccessModal;
  staticInputCount = 10
  totalInputInTravellers = 43
  newInvoice = true;
  invoice_config: any;
  companyAddress: any;

  @ViewChild('confirmDeleteModal', { static: true }) confirmDeleteModal: ElementRef;
  @ViewChild('closeDeleteModal', { static: true }) closeDeleteModal: ElementRef;
  @ViewChild('confirmUpdateModal', { static: true }) confirmUpdateModal: ElementRef
	@ViewChild('closeUpdateModal', { static: true }) closeUpdateModal: ElementRef
  @ViewChild('closeSendEmailModal') closebutton;
	@ViewChild('openEmailModal') openEmailModal;
  @ViewChild('generateEInvoiceModal', {static: true}) generateEInvoiceModal: ElementRef 
  queryParams: any;
  invoiceType = 'event';
  transactionType: any;
  reInitialise: any;
  navigationSubscription: any;
	shortcuts: ShortcutInput[] = [];
  currentOpenedModal = ''
  sendEmailForm:FormGroup
  minDate; 
	maxDate;
  copyELementTitle = 'Click to Copy'
  hasError: boolean = false;
  currentMaster: any;
  currentMasterName: any;
  showCreateButton;
  currentFormControlName;
  isTenantIndian: boolean = false;
	currencyCode : string = "INR";
	nativeLanguageCode = '';
	showRefundableCheckbox: boolean = false;
  showPrintOptions: boolean = false
  isTesoyBoult: boolean  = false;
  checkEInvoice: boolean = false;
  sub_service: any = []
  billOfSupplyActive = false;
  discount_add: string = 'false'

  constructor(
    private formBuilder: FormBuilder,
    private invoiceService : InvoiceServicesService,
    public eventService : EventService,
    public commonService: CommonServiceService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    public tabService: TabService,
    private cookieService : CookiesService
  ) { 
		this.route.paramMap.subscribe(params => {
			this.invoice_no = params['params'].invoiceNumber
			this.transactionType = params['params'].transaction_type

      if(this.transactionType) {
          this.invoiceMode = 'save'
      } else {
        this.invoiceMode = 'view'
      }

      this.sendEmailForm = this.formBuilder.group({
        toEmails: ["",Validators.required],
        bccEmails: [''],
        ccEmails:[''],
        subject:[''],
        body:['']
      })
		});
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
  }

  initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}

  ngOnDestroy() {
		if(this.navigationSubscription){
			this.navigationSubscription.unsubscribe();
		}
	}
  
  async ngOnInit(){
       if(this.data) {
        this.invoice_no = this.data.invoice,
        this.invoiceMode = this.data.mode
        this.checkWhetherEInvGenerated(this.invoice_no)
       }

    this.isTenantIndian = this.commonService.checkTenantIndian()
    if(this.isTenantIndian) {
      this.staticInputCount = 10
    }else {
      this.staticInputCount = 12
    }
  
    this.getGeneralAccounts()
    this.getCompanyData()
    let data = {
      invoice_type : this.invoiceType,
      transaction_method : this.transactionType
    }
    this.eventService.initialiseFormData(data,{})
    this.eventService.initialiseTraveller()
    if(this.invoiceMode == 'save'){
      let loggedInUser = JSON.parse(localStorage.okountUserInfo);
      this.loggedInuser = loggedInUser
      this.eventService.invoiceForm.controls.employee_name.setValue(
        loggedInUser.name
      );
      this.eventService.invoiceForm.controls.employee_code.setValue(
        loggedInUser.accountcode
      );
      this.getAccountingCompany();
    }else{
      if (this.invoiceMode == 'view' && this.invoice_no != undefined) {
        this.getTourInvoiceData(this.invoice_no)
      } 
    }

    let empConfiguration = this.commonService.employeeConfiguration;
    if(!empConfiguration || empConfiguration.length == 0){
      let loggedInUser = JSON.parse(localStorage.okountUserInfo);
      empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
    }
    
    this.eventService.invoiceForm.controls.invoice_date.setValidators([Validators.required])
		this.eventService.invoiceForm.controls.invoice_date.updateValueAndValidity()

    let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
    let transaction_config = globalSettingConfig.filter(el=>el.group=='TRANSACTION')
    if(transaction_config && transaction_config.length>0){
      let discount_config = transaction_config[0].json_doc.value.filter(el=>el.key=='DISCOUNT')
      if(discount_config.length){
        this.discount_add = discount_config[0].value
      }
    }
    if (this.invoiceMode != 'view') {
      this.invoice_config = this.eventService.invoice_config[this.invoiceType] ? this.eventService.invoice_config[this.invoiceType] : null
      if(this.invoice_config){
        this.generalSearch(this.invoice_config.general_ledger,true,true, true)
      }
			var url = window.location.href.slice(window.location.href.indexOf('?') + 1);
			this.getuuid(url)
      let queryParams = this.route.queryParams['value']
      if(queryParams){
        this.queryParams = queryParams
      }
     
		}

    //this.showRefundableCheckbox = this.commonService.checkCreditNoteNonRefundableOrNot()

    this.reInitialise = true
   
    setTimeout(() => {
      this.commonService.selectedInput.next(4)
    }, 100);

  }

  forceReload() {
		this.ngOnInit();
	}

  async initDateValidation (){
    this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
		try {
      let empConfiguration = this.commonService.employeeConfiguration;
			if(!empConfiguration || empConfiguration.length == 0){
				let loggedInUser = JSON.parse(localStorage.okountUserInfo);
				empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
			}
			let config = this.commonService.getConfigurationByKey(this.invoiceType)
			if(config){
				let dateConfig = config['value'].filter(data => data.key == `minimum_${this.invoiceMode == 'view' ? 'update' : 'creation'}_date`)
				let daysConfig = config['value'].filter(data => data.key == `minimum_${this.invoiceMode == 'view' ? 'update' : 'creation'}_days`)
				let dateWithMinDate = moment(dateConfig ? dateConfig[0].value : '')
        let dateWithMinDays = dateConfig && daysConfig[0].value ? moment().subtract(Number(daysConfig[0].value), 'days') : dateWithMinDate
				if(dateWithMinDays.isBefore(dateWithMinDate)){
					this.minDate = dateWithMinDate.format('YYYY-MM-DD')
				}else{
					this.minDate = dateWithMinDays.format('YYYY-MM-DD')
				}
			}
		} catch (error) {
			
		}
		this.maxDate = moment().format('YYYY-MM-DD')
    this.eventService.invoiceForm.controls.invoice_date.setValidators([Validators.required,this.validateDate()])
    
    this.eventService.invoiceForm.controls.general_ledger_code.setValidators([Validators.required])
    this.eventService.invoiceForm.controls.general_ledger_code_name.setValidators([Validators.required])
		this.eventService.invoiceForm.controls.invoice_date.updateValueAndValidity()
  }

  validateDate(){
		return (control: AbstractControl) => {
			let valid = true
			if(this){
				if (moment(control.value).isBefore(this.minDate) || moment(control.value).isAfter(this.maxDate)) {
					if(this.invoiceMode == 'view' && this.invoiceData && this.invoiceData.invoice_date && moment(control.value).isSame(moment(this.invoiceData.invoice_date))){
            valid = true
          }else{
            valid = false
          }
				}
			}
			return !valid ? {invoice_date: {value: control.value}} : null;
		};
	}

  ngAfterViewInit(){

		this.shortcuts.push(			
			{
				key: "alt + s",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Save mis Entry",
				description: "Save mis Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("save_mis_invoice_btn")){

				document.getElementById("save_mis_invoice_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + e",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Update Mis Entry",
				description: "Update Mis Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("update_mis_invoice_btn")){

				document.getElementById("update_mis_invoice_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + d",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Mis Entry",
				description: "Delete Mis Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("delete_mis_invoice_btn")){

				document.getElementById("delete_mis_invoice_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + j",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Open Journal",
				description: "Open Journal",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("journal_ms_invoice_btn")){
				document.getElementById("journal_ms_invoice_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + plus",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Add New Row",
				description: "Add New Row",
				
				command: (e)=>{
        let updatedCount = this.eventService.invoiceForm.value.number_of_pax+1
        this.eventService.invoiceForm.controls.number_of_pax.setValue(updatedCount)
        this.eventService.travellerCountUpdate(updatedCount)
				console.log(e)	
        setTimeout(() => {
          document.getElementsByTagName('body')[0].click()
          }, 100);
					},
					preventDefault: true
			},
      {
				key: "alt + -",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Remove Last Row",
				description: "Remove Last Row",
				
				command: (e)=>{
          if(this.eventService.invoiceForm.value.number_of_pax>0){
           let updatedCount = this.eventService.invoiceForm.value.number_of_pax-1
           this.eventService.invoiceForm.controls.number_of_pax.setValue(updatedCount)
            this.eventService.removeTraveller(updatedCount)
          }
          setTimeout(() => {
            document.getElementsByTagName('body')[0].click()
            }, 100);
					},
					preventDefault: true
			},
      {
				key: "alt + y",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On Yes Button",
				description: "Clicked On Yes Button",
				
				command: (e)=>{
				if(this.currentOpenedModal=='update'){
					this.updateProduct()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}else if(this.currentOpenedModal=='delete'){
					this.deleteInvoice(this.invoice_no)
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
				this.currentOpenedModal = ''
					},
					preventDefault: true
			},
			{
				key: "alt + n",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On No Button",
				description: "Clicked On No Button",
				
				command: (e)=>{
				if(this.currentOpenedModal=='update'){
					this.closeUpdateConfirmModal()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}else if(this.currentOpenedModal=='delete'){
					this.closeDeleteConfirmModal()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
				this.currentOpenedModal = ''
					},
					preventDefault: true
			},
      {
				key: ["alt + 1","alt + 2","alt + 3","alt + 4","alt + 5","alt + 6","alt + 7","alt + 8","alt + 9"],
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Edit  Traveller",
				description: "Edit  Traveller",
				
				command: (e)=>{
				console.log(e)
				   if(document.getElementById(`edit_traveller_${Number(e.event.key)-1}_btn`)){
					document.getElementById(`edit_traveller_${Number(e.event.key)-1}_btn`).click()
				   }
           setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
					},
					preventDefault: true
					
			},
      {
				key: "f10",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Send mail",
				description: "Send mail",
				command: (e)=>{
				console.log(e)	
				if(document.getElementById('mis_invoice_send_mail_btn') && this.invoiceMode=='view'){
					document.getElementById('mis_invoice_send_mail_btn').click()
					setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
				}
			},
					preventDefault: true
			},
      
	  )
	}
  
  getCompanyData() {
    return new Promise((resolve, reject) => {
			this.invoiceService.getMethod(`setting/address`).subscribe(
				res => {
					this.companyAddress = res
          resolve(res)
				},
				err => {
					reject({});
				}
			);
		});
  }

  async getuuid(url) {
		if (!url.includes('invoice_number') && this.invoiceMode != 'view') {
			this.uuid = await this.getInvoice()
			this.eventService.invoiceForm.controls.invoice_number.setValue(this.uuid)
			window.history.replaceState({}, document.title, `/${window.location.hash}?invoice_number=${this.uuid}`);
		} else {
			console.log(url)
			if (url.split('=')[1] != undefined) {
				let invNo = url.split('=')[1]
				this.eventService.invoiceForm.controls.invoice_number.setValue(invNo)
			}
		}
	}

  getInvoice() {
		return new Promise((resolve, reject) => {
			this.invoiceService.getMethod(`invoice/getuuid`).subscribe(
				res => {
					let invoiceNo;
					try {
						invoiceNo = res["uuid"]
						resolve(invoiceNo);
					} catch (error) {
						invoiceNo = res
						resolve(invoiceNo);
					}
				},
				err => {
					reject("");
				}
			);
		});
	}

  getTourInvoiceData(searchId: any) {
		try {
			let url = `invoice/tour/${searchId}`
      this.spinner.show()
			this.invoiceService.getMethod(url).subscribe(async (res) => {
				let invoiceData = res['data'][0];
          if(invoiceData && invoiceData.accounting_company) {
              if(invoiceData['accounting_company'].currency_code) {
                this.currencyCode = invoiceData['accounting_company'].currency_code;
              }
              if(invoiceData['accounting_company'].native_language_code && invoiceData['accounting_company'].currency_code == 'AED') {
                this.nativeLanguageCode = invoiceData['accounting_company'].native_language_code;
              }
          }
       
          this.invoiceData = res['data'][0];
          // this.invoiceType = this.invoiceData.products[0].invoice_type
          let resp = await this.eventService.bindInvoiceData(invoiceData)
          this.transactionType = this.invoiceData.transaction_method
          this.initDateValidation()
          if(this.isTenantIndian) {
            this.staticInputCount = 10
          }else {
            this.staticInputCount = 12
          }
          this.clientSearch(this.invoiceData.account_code,false, false)

          if(this.invoiceMode == 'view' && this.invoiceData.bill_of_supply == false && !this.invoiceData.bos_reference) {
            let clientConfiguration:any = await this.commonService.getClientConfiguration(this.invoiceData.account_code);
            if(clientConfiguration && clientConfiguration.length > 0) {
              let bos = clientConfiguration.find(item => item.key == 'enable_bill_of_supply')
              if(bos && bos.value == 'TRUE') {
                this.billOfSupplyActive = true;
              }
            }
          } else {
            this.billOfSupplyActive = false;
          }

          this.generalSearch(this.invoiceData.general_ledger_code,true,false, false)
          this.ledgerSearch(this.invoiceData.subledger_code, false)
          this.spinner.hide()
			},
				err => {
					this.spinner.hide()
				})
		} catch (error) {
      this.spinner.hide()
		}
	}


	getGeneralAccounts() {
		try {
			this.invoiceService.getMethod(`general/bank-od-cc`).subscribe(data => {
				this.generalAccountArr = data
			}, err => {
				this.generalAccountArr = []
				throw new Error(JSON.stringify(err));
			})
		} catch (error) {

		}
	}

  	onChangeSearch(value) {
    		// this.bookingSupplierArr =  this.bookingSupplierArrBase.map(item => (item.account_code.includes(value) || item.name.includes(value)) );
    		
    	}
    
    	async selectAffiliateEvent(data){
    	}
    
    	async selectEvent(data) {
    	}
    
    	onFocused(e) {
    		
    	}

  hideError() {
    this.showMessage = false
  }

  closeMaxModule() {
    this.commonService.minimizeModule(true);
  }

    setNarration(){
      let services = this.eventService.travellerForm.controls.travellerList.controls
      let travellerListLength = services.length
      let firstService = travellerListLength && services[0].controls

      var narrLine1= ''
      if (travellerListLength > 1) {
        narrLine1 = firstService.lead_pax_name.value + ' + ' + (travellerListLength - 1);
      } else {
        if(firstService.lead_pax_name && firstService.lead_pax_name.value)
          narrLine1 = firstService.lead_pax_name.value;
      }     
      
      var narrLine2 = ''
      var narrArr2 = []
     
      for(let i=0; i<2; i++){
        let serviceAtIndex = services[i] && services[i].controls ;
        if(serviceAtIndex && serviceAtIndex.service_name && serviceAtIndex.service_name.value){
          narrArr2.push(serviceAtIndex.service_name.value.toUpperCase())
        }
      }
      
      narrLine2= narrArr2.join(' + ');
      if(travellerListLength>2){
        narrLine2 += ` + MORE `
      }

      var narrLine3= `${firstService.service_name.value.toUpperCase()}: ${moment(firstService.service_date.value).format('DD-MM-YYYY')}`;
      var narrLine4 = `ISSUED OFFLINE : ${this.eventService.invoiceForm.controls.employee_name.value.toUpperCase()}`

      this.eventService.invoiceForm.controls.narration_1.setValue(narrLine1)
      this.eventService.invoiceForm.controls.narration_2.setValue(narrLine2)
      this.eventService.invoiceForm.controls.narration_3.setValue(narrLine3)
      this.eventService.invoiceForm.controls.narration_4.setValue(narrLine4)
  }

  getMasterConfiguration(payableAccountCode, travellerIndex, accountFor){
			this.commonService.getRequest(`${environment.okountUrl}/master_settings?account_code=${payableAccountCode}`).subscribe((res: any) => {
				if(res && res.length > 0){
          let data = res[0];
          if(accountFor == 'payable') {
            let tds = data && data.json_doc && data.json_doc.value && data.json_doc.value.find(item => item.key == 'tds')
            let gst = data && data.json_doc && data.json_doc.value && data.json_doc.value.find(item => item.key == 'gst')
            if(gst){
              let gstRate = gst.value.find(item => item.key == 'rate')
              if(gstRate && gstRate.value) {
                this.eventService.travellerForm.controls.travellerList.controls[travellerIndex].controls.supplier_gst_conf.setValue(gstRate.value)
              }
            } else {
              this.eventService.travellerForm.controls.travellerList.controls[travellerIndex].controls.supplier_gst_conf.setValue(0)
            }
            if(tds){
              let tdsRate = tds.value.find(item => item.key == 'rate')
              if(tdsRate && tdsRate.value) {
                this.eventService.travellerForm.controls.travellerList.controls[travellerIndex].controls.supplier_tds_conf.setValue(tdsRate.value)
              }
            } else {
              this.eventService.travellerForm.controls.travellerList.controls[travellerIndex].controls.supplier_tds_conf.setValue(0)
            }

          this.calculateNetAmount(travellerIndex);
          
          }else if(accountFor == 'client' && this.isTenantIndian) {
            if(this.invoiceMode != 'view') {
              let billOfSupplyObj = data && data.json_doc && data.json_doc.value && data.json_doc.value.find(item => item.key == 'enable_bill_of_supply')
              if(billOfSupplyObj){
                let value = billOfSupplyObj.value;
                if(value == 'TRUE') {
                  this.billOfSupplyActive = true;
                }
              }
            } else {
              this.billOfSupplyActive = false;
            }
           
            // let tcs = data && data.json_doc && data.json_doc.value && data.json_doc.value.find(item => item.key == 'tcs')
            // if(tcs){
            //   let tcsRate = tcs.value.find(item => item.key == 'rate')
            //   if(tcsRate && tcsRate.value) {
            //     this.eventService.invoiceForm.controls.tcs_rate.setValue(tcsRate.value)
            //   }
            // } else {
            //   this.eventService.invoiceForm.controls.tcs_rate.setValue(0)
            // }
          }
				} 
			},err=> {
		})
  }

  selectAccount(event, formControlName, index, getMaster) {
    try {
      
      let value = event && event.nzValue ? event.nzValue : event;
      
      if(value && value.account_code) {
        if(formControlName == 'ledger_card_no') {
          this.eventService.travellerForm.controls.travellerList.controls[index].controls.ledger_card_no.setValue(value.account_code ? value.account_code : '')
          this.eventService.travellerForm.controls.travellerList.controls[index].controls.ledger_card_name.setValue(value.name ? value.name : '')
          return;
        }
        if(formControlName == 'payable_code') {
          this.eventService.travellerForm.controls.travellerList.controls[index].controls.payable_code.setValue(value.account_code ? value.account_code : '')
          this.eventService.travellerForm.controls.travellerList.controls[index].controls.payable_account_name.setValue(value.name ? value.name : '')
        
          let payableAccountCode = this.eventService.travellerForm.controls.travellerList.controls[index].controls.payable_code.value
          if(payableAccountCode && getMaster) this.getMasterConfiguration(payableAccountCode, index, 'payable')
          return;
        }
        this.eventService.invoiceForm.controls[formControlName].setValue(
          value && value.account_code ? value.account_code : ''
        );
        this.eventService.invoiceForm.controls[`${formControlName}_name`].setValue(
          value && value.name ? value.name : ''
        );

        if(formControlName == 'client_code'){
          this.selectedClient = value
          if(value && value.account_code && getMaster){
            this.getMasterConfiguration(value.account_code ? value.account_code : '', null, 'client')
            this.eventService.calculateFromApi(0)
          }
          this.calcuateAllSC()
        }
        if(formControlName == 'supplier_code'){
          this.selectedSupplier = value
          this.calcuateAllSC()
        }
      } else {
        if(value && value.nzLabel && value.nzLabel.toLowerCase() == 'create new' && this.currentMasterName && this.invoiceMode != 'view') {
            this.openModalMasterCreation(formControlName)
        }
      }
      } catch (error) {}
      
      
  }
  

  clientSearch(event,withStatus, getMaster) {
    try {
      let value = event && event.target && event.target.value ? event.target.value : event;

      let searchType = this.eventService.invoiceForm.controls.client_account_type.value;
      if (searchType == null) {
        this.eventService.invoiceForm.controls.client_account_type.setValue(
          "client"
        );
        searchType = this.eventService.invoiceForm.controls.client_account_type.value;
      }
      if (value.length > 2 && searchType != null && searchType != "") {
        this.currentMasterName = value;
        this.currentFormControlName = 'client_code'
        this.currentMaster = searchType;

        let status = ''
        if(withStatus){
          status = '&status=active'
        }
        this.invoiceService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`
          )
          .subscribe(
            (res) => {
              this.bookingClientArr = res;
              if(!this.bookingClientArr || this.bookingClientArr.length == 0) {
                this.showCreateButton = true;
               } else {
                 this.showCreateButton = false;
              }
              if(this.bookingClientArr[0] && this.invoiceMode == 'view'){
                this.selectAccount(this.bookingClientArr[0],'client_code', null, getMaster)
              }
              
            },
            (error) => {}
          );
      }
    } catch (error) {
      console.error(error)
    }
  }

  generalSearch(event,isSelect,withStatus, getMaster) {
    try {
      let value = event && event.target && event.target.value ? event.target.value : event;

      if (value.length > 2) {
        this.currentMasterName = value;
        this.currentFormControlName = 'general_ledger_code_name'
        this.currentMaster = 'general';
       
        let status = '';
        if(withStatus){
          status = '&status=active'
        }
        this.invoiceService
          .searchAccount(
            `general/autocomplete?name_like=${value.toUpperCase()}${status}`
          )
          .subscribe(
            (res) => {
              this.bookingGenerlaAccountArr = res;
              if(!this.bookingGenerlaAccountArr || this.bookingGenerlaAccountArr.length == 0) {
                  this.showCreateButton = true;
                 } else {
                   this.showCreateButton = false;
                }
              if(this.bookingGenerlaAccountArr[0] && isSelect){
                this.selectAccount(this.bookingGenerlaAccountArr[0],'general_ledger_code', null, getMaster)
              }
            },
            (error) => {}
          );
      }
    } catch (error) {
      console.error(error)
    }
  }

  supplierSearch(value,withStatus, getMaster) {
    try {
      let searchType = this.eventService.invoiceForm.controls.supplier_account_type.value;
      if (searchType == null) {
        this.eventService.invoiceForm.controls.supplier_account_type.setValue(
          "supplier"
        );
        searchType = this.eventService.invoiceForm.controls.supplier_account_type.value;
      }
      if (value.length > 2 && searchType != null && searchType != "") {
        let status = '';
        if(withStatus){
          status = '&status=active'
        }
        this.invoiceService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`
          )
          .subscribe(
            (res) => {
              this.bookingSupplierArr = res;
              if(this.bookingSupplierArr[0] && this.invoiceMode == 'view'){
                this.selectAccount(this.bookingSupplierArr[0],'supplier_code', null, getMaster)
              }
            },
            (error) => {}
          );
      }
    } catch (error) {}
  }

  ledgerSearch(event, getMaster) {
    try {
      let value = event && event.target && event.target.value ? event.target.value : event;

      let searchType = this.eventService.invoiceForm.controls
        .subledger_type.value;
      
      if (searchType == null) {
        this.eventService.invoiceForm.controls.subledger_type.setValue(
          "client"
        );
        searchType = this.eventService.invoiceForm.controls
          .subledger_type.value;
      }
      if (value.length > 2 && searchType != null && searchType != "") {
        this.currentMasterName = value;
        this.currentFormControlName = 'subledger_code'
        this.currentMaster = searchType;

        let status = '';
        if(this.invoiceMode != 'view'){
          status = '&status=active'
        }
        this.invoiceService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`
          )
          .subscribe(
            (res) => {
              this.eventService["ledgerList"] = res;

              if(!this.eventService["ledgerList"] || this.eventService["ledgerList"].length == 0) {
                this.showCreateButton = true;
               } else {
                 this.showCreateButton = false;
                 this.currentMaster = ''
              }

              if (value == "CC001A" || value == "CC001B") {
                this.selectAccount(res[0], "subledger_code", null, getMaster);
                this.eventService.invoiceForm.controls.subledger_code_name.setValue(
                  res[0].name
                );
              }
              if(this.invoiceMode == 'view') {
                this.selectAccount(res[0], "subledger_code", null, getMaster);
              }
            },

            
            (error) => {}
          );
      }
    } catch (error) {}
  }

  employeeSearch(value) {
    try {
      if (value.length > 2) {
        this.invoiceService
          .searchAccount(
            `employee/autocomplete?name_like=${value.toUpperCase()}`
          )
          .subscribe(
            (res) => {
              this.employeeList = res;
            },
            (error) => {}
          );
      }
    } catch (error) {}
  }
  // onFocused(e) {}

  payableSearch(value, index, getMaster) {
    try {
      let searchType = this.eventService.travellerForm.controls.travellerList.controls[index].controls.payable_account_type.value;

      if (value.length > 2 && searchType != null && searchType != "") {
        let status = ''
        this.invoiceService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`
          )
          .subscribe(
            (res) => {
              this.payablesArr = res;
              if(this.payablesArr[0] && this.invoiceMode == 'view'){
                this.selectAccount(this.payablesArr[0],'payable_code', null, getMaster)
              }
            },
            (error) => {}
          );
      }
    } catch (error) {
    }
  }



  async saveProductInvoice() {
    if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.eventService.invoiceForm.controls.invoice_date.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to create the invoice.'
				return false;
			}
		}
    await this.initDateValidation()
    if (this.invoiceMode == "view") {
      this.updateProduct();
    } else {
      const invalid = [];
      const controls = this.eventService.invoiceForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }

      const travellerControls = this.eventService.travellerForm.controls.travellerList.controls;
      for(let item of travellerControls) {
        let itemControls = item.controls
        for (const name in itemControls) {
          if (itemControls[name].invalid) {
            invalid.push(name);
          }
        }
      }

      if(invalid.length > 0) {
        window.scroll(0,0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
        this.spinner.hide()
        return false
      }

  
      this.spinner.show()

      if(this.eventService.invoiceForm.value.bill_of_supply === true) {
        let checkBosApplicableOrNot = this.checkBosApplicableOrNot(this.eventService.travellerForm.value)
        if(!checkBosApplicableOrNot) {
          this.showMessage = true
          this.spinner.hide()
          this.alertType = "error-box"
          this.message = 'Bill of supply Not Applicable.'
          return false;
        }
      }
      
      let product_json_doc = this.eventService.createMsProduct(
        this.eventService.invoiceForm.value,this.eventService.travellerForm.value
      );

      if(this.invoiceMode != 'view' && this.eventService.invoiceForm.value.bill_of_supply === true) { 
        let billOfSupplyRequest = this.eventService.createMsProductForBillSupply(
          this.eventService.invoiceForm.value,this.eventService.travellerForm.value
        );
        product_json_doc['billOfSupplyRequest'] = billOfSupplyRequest
      } 
      this.eventService.postMethod(`invoice/tour`,product_json_doc).subscribe(async (data) => {
      // this.eventService.postMethod(``,product_json_doc).subscribe(async (data) => {
        window.scroll(0, 0)
        this.showMessage = false;
        this.isSuccess = true
        this.alertType = 'success-box';
        this.message = `Invoice Saved with invoice no. : `
        this.invoice_number = data['invoice_number']
        this.eventService.initialiseFormData({},{})
        let loggedInUser = JSON.parse(localStorage.okountUserInfo);
        this.loggedInuser = loggedInUser
        this.eventService.invoiceForm.controls.employee_name.setValue(
          loggedInUser.name
        );
        this.eventService.invoiceForm.controls.employee_code.setValue(
          loggedInUser.accountcode
        );
        this.eventService.travellerList.controls = [];
        this.eventService.addTraveller({})
        let obj = {
          invoice_no: 'New Invoice',
        };
        this.eventService.fareList = []
        this.eventService.fareList.push(obj);
        this.eventService.calculateFromApi(0)
        this.uuid = await this.getInvoice()
        this.eventService.invoiceForm.controls.invoice_number.setValue(this.uuid)
        window.history.replaceState({}, document.title, `/${window.location.hash}?invoice_number=${this.uuid}`);
        this.spinner.hide()
        this.newInvoice = false;
        this.openSuccessModal.nativeElement.click()
        setTimeout(() => {
          this.commonService.selectedInput.next(1)
        }, 100);
      },err=> {
        window.scroll(0, 0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = err['error']['message']
        this.spinner.hide()
      })
      
    }
  }
  updateProduct() {
    const invalid = [];
      const controls = this.eventService.invoiceForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      const travellerControls = this.eventService.travellerForm.controls.travellerList.controls;
      for(let item of travellerControls) {
        let itemControls = item.controls
        for (const name in itemControls) {
          if (itemControls[name].invalid) {
            invalid.push(name);
          }
        }
      }
      if(invalid.length > 0) {
        window.scroll(0,0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
        this.spinner.hide()
        this.closeUpdateConfirmModal()
        return false
    }
    this.closeUpdateConfirmModal()
    this.spinner.show()

      const invoiceFormValue = JSON.parse(JSON.stringify(this.eventService.invoiceForm.value))
      let product_json_doc = this.eventService.createMsProduct(
        invoiceFormValue,this.eventService.travellerForm.value,
      );

      if(!this.invoiceData.bos_reference && this.eventService.invoiceForm.value.bill_of_supply === true) { 
        let billOfSupplyRequest = JSON.parse(JSON.stringify(product_json_doc))
        let originalRequest = this.eventService.createMsProductForBillSupply(
          this.eventService.invoiceForm.value,this.eventService.travellerForm.value
        );

        product_json_doc = originalRequest;
        product_json_doc['billOfSupplyRequest'] = billOfSupplyRequest;
        product_json_doc['billOfSupplyRequest']['bill_of_supply'] = true;

      } 

      this.eventService.putMethod(`invoice/tour/${this.invoice_no}`,product_json_doc).subscribe(async (data) => {
        window.scroll(0, 0)
        this.showMessage = true;
        this.isSuccess = true
        this.alertType = 'success-box';
        this.message = `Invoice Updated`

        if(product_json_doc['billOfSupplyRequest'] && product_json_doc['billOfSupplyRequest']['bill_of_supply'] === true) {
          this.forceReload();
          return;
        } 
        
        this.spinner.hide()
      },err=> {
        window.scroll(0, 0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = err['error']['message']
        this.spinner.hide()
      })
      
  }
  calcuateAllSC(){
    let controls = this.eventService.travellerForm.controls.travellerList.controls
      for (let i = 0; i < controls.length; i++) {
        this.calcuateSC(i)
      }
  }

  calcuateSC(i){
    const invoiceData = this.eventService.travellerForm.controls.travellerList.controls[i].value
    let gstRate = this.getGstRate(i)
    let client_igst = 0
    let client_igst_2 = 0
    let client_igst_3 = 0
    let client_sgst = 0
    let client_sgst_2 = 0
    let client_sgst_3 = 0
    let client_cgst = 0
    let client_cgst_2 = 0
    let client_cgst_3 = 0
    let clientTotalAmount = 0
    let clientTotalAmount_2 = 0
    let clientTotalAmount_3 = 0
    let client_vat_amount = 0
    let client_igst_on_raf = 0
    let client_cgst_on_raf = 0
    let client_sgst_on_raf = 0
    let client_vat_on_raf = 0
    
    let clientRafAmount =  Number(invoiceData.client_raf_charges ? invoiceData.client_raf_charges : 0);
   
    if(invoiceData.client_gst_type== 'S' || (!this.isTenantIndian && invoiceData.client_vat_type== 'S')){
      clientTotalAmount = invoiceData.client_sc1_type == 'T' ? Number(invoiceData.client_sc1_amount) : 0
    }

    if(invoiceData.client_gst_type== 'B' || (!this.isTenantIndian && invoiceData.client_vat_type== 'B')){
      clientTotalAmount = Number(!isNaN(Number(invoiceData.client_basic)) ? Number(invoiceData.client_basic) : 0)+Number(invoiceData.client_sc2_type == 'B'?invoiceData.client_sc2_amount:0)+Number(invoiceData.client_sc3_type == 'B'?invoiceData.client_sc3_amount:0)
    }
   
    if(invoiceData.client_gst_type== 'T' || (!this.isTenantIndian && invoiceData.client_vat_type== 'T')){
      clientTotalAmount = Number(!isNaN(Number(invoiceData.client_taxes)) ? Number(invoiceData.client_taxes) : 0)+Number(invoiceData.client_sc2_type == 'T'?invoiceData.client_sc2_amount:0)+Number(invoiceData.client_sc3_type == 'T'?invoiceData.client_sc3_amount:0)
    }

    // if (invoiceData.client_sc2_taxable == 'T') {
    if(invoiceData.client_gst_type_2== 'T'){
      // clientTotalAmount_2 = this.calculateTotal("client",i)
      clientTotalAmount_2 = Number(!isNaN(Number(invoiceData.client_taxes)) ? Number(invoiceData.client_taxes) : 0)+Number(invoiceData.client_sc2_type == 'OT'?invoiceData.client_sc2_amount:0)+Number(invoiceData.client_sc3_type == 'OT'?invoiceData.client_sc3_amount:0)

    }
    if(invoiceData.client_gst_type_2== 'B'){
      clientTotalAmount_2 = Number(!isNaN(Number(invoiceData.client_basic)) ? Number(invoiceData.client_basic) : 0)+Number(invoiceData.client_sc2_type == 'B'?invoiceData.client_sc2_amount:0)+Number(invoiceData.client_sc3_type == 'B'?invoiceData.client_sc3_amount:0)
    }
    if(invoiceData.client_gst_type_2== 'S'){
      // clientTotalAmount_2 = invoiceData.client_sc2_type == 'T' ? Number(invoiceData.client_sc2_amount) : 0
      clientTotalAmount_2 = invoiceData.client_sc1_type == 'T' ? Number(invoiceData.client_sc1_amount) : 0
    }
    // }
    // if (invoiceData.client_sc3_taxable == 'T') {
      if (invoiceData.client_gst_type_3 == 'T') {
        // clientTotalAmount_3 = this.calculateTotal("client",i)
        clientTotalAmount_3 = Number(!isNaN(Number(invoiceData.client_taxes)) ? Number(invoiceData.client_taxes) : 0)+Number(invoiceData.client_sc2_type == 'OT'?invoiceData.client_sc2_amount:0)+Number(invoiceData.client_sc3_type == 'OT'?invoiceData.client_sc3_amount:0)
      }
      if (invoiceData.client_gst_type_3 == 'B') {
        clientTotalAmount_3 = Number(!isNaN(Number(invoiceData.client_basic)) ? Number(invoiceData.client_basic) : 0)+Number(invoiceData.client_sc2_type == 'B'?invoiceData.client_sc2_amount:0)+Number(invoiceData.client_sc3_type == 'B'?invoiceData.client_sc3_amount:0)
      }
      if (invoiceData.client_gst_type_3 == 'S') {
        // clientTotalAmount_3 = invoiceData.client_sc3_type == 'T' ? Number(invoiceData.client_sc3_amount) : 0
        clientTotalAmount_3 = invoiceData.client_sc1_type == 'T' ? Number(invoiceData.client_sc1_amount) : 0
      }
    // }
    if(this.isTenantIndian){
    if(gstRate.client_igst_applicable){
      client_igst = Number((clientTotalAmount * gstRate.client_gst_rate/100).toFixed(2))
      client_igst_2 = Number((clientTotalAmount_2 * gstRate.client_gst_rate_2/100).toFixed(2))
      client_igst_3 = Number((clientTotalAmount_3 * gstRate.client_gst_rate_3/100).toFixed(2))
      client_igst_on_raf = Number((clientRafAmount * 18/100).toFixed(2))
    }else{
      let rate = gstRate.client_gst_rate/2
      let rate_2 = gstRate.client_gst_rate_2/2
      let rate_3 = gstRate.client_gst_rate_3/2
      client_cgst = Number((clientTotalAmount * rate/100).toFixed(2))
      client_sgst = Number((clientTotalAmount * rate/100).toFixed(2))
      client_cgst_2 = Number((clientTotalAmount_2 * rate_2/100).toFixed(2))
      client_sgst_2 = Number((clientTotalAmount_2 * rate_2/100).toFixed(2))
      client_cgst_3 = Number((clientTotalAmount_3 * rate_3/100).toFixed(2))
      client_sgst_3 = Number((clientTotalAmount_3 * rate_3/100).toFixed(2))
      client_cgst_on_raf = Number((clientRafAmount * 9/100).toFixed(2))
      client_sgst_on_raf = Number((clientRafAmount * 9/100).toFixed(2))

    }
    }else{
      client_vat_amount = Number((clientTotalAmount * gstRate.client_vat_rate/100).toFixed(2))
      client_vat_on_raf = Number((clientRafAmount * gstRate.client_vat_rate/100).toFixed(2))
    }
    let supplier_igst = 0
    let supplier_igst_2 = 0
    let supplier_igst_3 = 0
    let supplier_sgst = 0
    let supplier_sgst_2 = 0
    let supplier_sgst_3 = 0
    let supplier_cgst = 0
    let supplier_cgst_2 = 0
    let supplier_cgst_3 = 0
    let supplierTotalAmount = 0
    let supplierTotalAmount_2 = 0
    let supplierTotalAmount_3 = 0
    let supplier_vat_amount = 0
    let supplier_igst_on_raf = 0
    let supplier_cgst_on_raf = 0
    let supplier_sgst_on_raf = 0
    let supplier_vat_on_raf = 0

    let supplierRafAmount =  Number(invoiceData.supplier_raf_charges ? invoiceData.supplier_raf_charges : 0);

    if(invoiceData.supplier_gst_type== 'S' || (!this.isTenantIndian && invoiceData.supplier_vat_type== 'S')){
      supplierTotalAmount = invoiceData.supplier_sc1_type == 'T' ? Number(invoiceData.supplier_sc1_amount) : 0
    }

    if(invoiceData.supplier_gst_type== 'T' || (!this.isTenantIndian && invoiceData.supplier_vat_type== 'T')){
     // supplierTotalAmount = this.calculateTotal("supplier",i)
     supplierTotalAmount =!isNaN(Number(invoiceData.supplier_taxes)) ? Number(invoiceData.supplier_taxes) : 0
    }
    if(invoiceData.supplier_gst_type== 'B' || (!this.isTenantIndian && invoiceData.supplier_vat_type== 'B')){
      supplierTotalAmount = !isNaN(Number(invoiceData.supplier_basic)) ? Number(invoiceData.supplier_basic) : 0
    }
   
    if (invoiceData.supplier_sc2_taxable == 'T') {
      if (invoiceData.supplier_gst_type_2 == 'T') {
        //supplierTotalAmount_2 = this.calculateTotal("supplier",i)
        supplierTotalAmount = !isNaN(Number(invoiceData.supplier_taxes)) ? Number(invoiceData.supplier_taxes) : 0
      }
      if (invoiceData.supplier_gst_type_2 == 'B') {
        supplierTotalAmount_2 = !isNaN(Number(invoiceData.supplier_basic)) ? Number(invoiceData.supplier_basic) : 0
      }
      if (invoiceData.supplier_gst_type_2 == 'S') {
        //supplierTotalAmount_2 = invoiceData.supplier_sc2_type == 'T' ? Number(invoiceData.supplier_sc2_amount) : 0
        supplierTotalAmount = invoiceData.supplier_sc1_type == 'T' ? Number(invoiceData.supplier_sc1_amount) : 0
      }
    }
    if (invoiceData.supplier_sc3_taxable == 'T') {
      if (invoiceData.supplier_gst_type_3 == 'T') {
        // supplierTotalAmount_3 = this.calculateTotal("supplier",i)
        supplierTotalAmount = !isNaN(Number(invoiceData.supplier_taxes)) ? Number(invoiceData.supplier_taxes) : 0
      }
      if (invoiceData.supplier_gst_type_3 == 'B') {
        supplierTotalAmount_3 = !isNaN(Number(invoiceData.supplier_basic)) ? Number(invoiceData.supplier_basic) : 0
      }
      if (invoiceData.supplier_gst_type_3 == 'S') {
        //supplierTotalAmount_3 = invoiceData.supplier_sc3_type == 'T' ? Number(invoiceData.supplier_sc3_amount) : 0
        supplierTotalAmount = invoiceData.supplier_sc1_type == 'T' ? Number(invoiceData.supplier_sc1_amount) : 0
      }
    }
    if(this.isTenantIndian){
    if(gstRate.supplier_igst_applicable){
      supplier_igst = Number((supplierTotalAmount * gstRate.supplier_gst_rate/100).toFixed(2))
      supplier_igst_2 = Number((supplierTotalAmount_2 * gstRate.supplier_gst_rate_2/100).toFixed(2))
      supplier_igst_3 = Number((supplierTotalAmount_3 * gstRate.supplier_gst_rate_3/100).toFixed(2))
      supplier_igst_on_raf = Number((supplierRafAmount * 18/100).toFixed(2))
    }else{
      let rate = gstRate.supplier_gst_rate/2
      let rate_2 = gstRate.supplier_gst_rate_2/2
      let rate_3 = gstRate.supplier_gst_rate_3/2
      supplier_cgst = Number((supplierTotalAmount * rate/100).toFixed(2))
      supplier_sgst = Number((supplierTotalAmount * rate/100).toFixed(2))
      supplier_cgst_2 = Number((supplierTotalAmount_2 * rate_2/100).toFixed(2))
      supplier_sgst_2 = Number((supplierTotalAmount_2 * rate_2/100).toFixed(2))
      supplier_cgst_3 = Number((supplierTotalAmount_3 * rate_3/100).toFixed(2))
      supplier_sgst_3 = Number((supplierTotalAmount_3 * rate_3/100).toFixed(2))
      supplier_cgst_on_raf = Number((supplierRafAmount * 9/100).toFixed(2))
      supplier_sgst_on_raf = Number((supplierRafAmount * 9/100).toFixed(2))
    }
  }else{
    supplier_vat_amount = Number((supplierTotalAmount * gstRate.supplier_vat_rate/100).toFixed(2))
    supplier_vat_on_raf = Number((supplierRafAmount * gstRate.supplier_vat_rate/100).toFixed(2))

  }

  
    // TODO : Fix gst calculation
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_cgst.setValue(client_cgst)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_sgst.setValue(client_sgst)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_igst.setValue(client_igst)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_cgst_2.setValue(client_cgst_2)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_sgst_2.setValue(client_sgst_2)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_igst_2.setValue(client_igst_2)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_cgst_3.setValue(client_cgst_3)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_sgst_3.setValue(client_sgst_3)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_igst_3.setValue(client_igst_3)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_igst_on_raf.setValue(client_igst_on_raf)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_cgst_on_raf.setValue(client_cgst_on_raf)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_sgst_on_raf.setValue(client_sgst_on_raf)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_vat_on_raf.setValue(client_vat_on_raf)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_vat_amount.setValue(client_vat_amount)
    
    

    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_cgst.setValue(supplier_cgst)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_sgst.setValue(supplier_sgst)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_igst.setValue(supplier_igst)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_cgst_2.setValue(supplier_cgst_2)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_sgst_2.setValue(supplier_sgst_2)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_igst_2.setValue(supplier_igst_2)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_cgst_3.setValue(supplier_cgst_3)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_sgst_3.setValue(supplier_sgst_3)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_igst_3.setValue(supplier_igst_3)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_igst_on_raf.setValue(supplier_igst_on_raf)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_cgst_on_raf.setValue(supplier_cgst_on_raf)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_sgst_on_raf.setValue(supplier_sgst_on_raf)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_vat_on_raf.setValue(supplier_vat_on_raf)
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_vat_amount.setValue(supplier_vat_amount)
    return true
  }

  calculateTotal(calculation_key,i){
    const invoiceData = this.eventService.travellerForm.controls.travellerList.controls[i].value
    
    let nonAccount = invoiceData[`${calculation_key}_non_account`]
    let basic = invoiceData[`${calculation_key}_basic`]
    let taxes = invoiceData[`${calculation_key}_taxes`]
    let commission_amount = invoiceData[`${calculation_key}_commission_amount`]
    let discount_amount = invoiceData[`${calculation_key}_discount_amount`]
    let tds = invoiceData[`${calculation_key}_tds_amount`]
    let sc1_type = invoiceData[`${calculation_key}_sc1_type`]
    let sc1_amount = invoiceData[`${calculation_key}_sc1_amount`]
    let sc2_type = invoiceData[`${calculation_key}_sc2_type`]
    let sc2_amount = invoiceData[`${calculation_key}_sc2_amount`]
    let sc3_type = invoiceData[`${calculation_key}_sc3_type`]
    let sc3_amount = invoiceData[`${calculation_key}_sc3_amount`]
    let sc2_taxable = invoiceData[`${calculation_key}_sc2_taxable`]
    let sc3_taxable = invoiceData[`${calculation_key}_sc3_taxable`]


    
    let total = Number(nonAccount) + Number(basic) + Number(taxes) - Number(commission_amount)  - Number(discount_amount) + Number(tds) + Number(sc2_amount) + Number(sc3_amount)
    if(sc1_type == 'T'){
      total += Number(sc1_amount)
   }

    if(sc2_taxable == 'T'){
      total += Number(sc2_amount?sc2_amount:0)
    }

    if(sc3_taxable == 'T'){
      total += Number(sc3_amount?sc3_amount:0)
    }
    return !isNaN(Number(total)) ? Number(total) : 0
  }


  getGstRate(i) {
    let formValue = this.eventService.travellerForm.controls.travellerList.controls[i].value
    let client_gst_rate = formValue.client_gst_conf ? formValue.client_gst_conf : 0
    let client_gst_rate_2 = formValue.client_gst_conf_2 ? formValue.client_gst_conf_2 : 0
    let client_gst_rate_3 = formValue.client_gst_conf_3 ? formValue.client_gst_conf_3 : 0
    let supplier_gst_rate = formValue.supplier_gst_conf ? formValue.supplier_gst_conf : 0
    let supplier_gst_rate_2 = formValue.supplier_gst_conf_2 ? formValue.supplier_gst_conf_2 : 0
    let supplier_gst_rate_3 = formValue.supplier_gst_conf_3 ? formValue.supplier_gst_conf_3 : 0
    let supplier_igst_applicable = this.selectedSupplier && this.selectedSupplier.country_id==101  ? this.checkIsIgstApplicable(this.selectedSupplier.gst_number,this.selectedSupplier.state_name) : false
    let client_igst_applicable = this.selectedClient && this.selectedClient.country_id==101   ? this.checkIsIgstApplicable(this.selectedClient.gst_number,this.selectedClient.state_name) : false
    let client_vat_rate = formValue.client_vat_conf ? formValue.client_vat_conf : 0
    let supplier_vat_rate = formValue.supplier_vat_conf ? formValue.supplier_vat_conf : 0
    return {
      client_gst_rate,
      client_gst_rate_2,
      client_gst_rate_3,
      supplier_gst_rate,
      supplier_gst_rate_2,
      supplier_gst_rate_3,
      supplier_igst_applicable,
      client_igst_applicable,
      client_vat_rate,
      supplier_vat_rate
    }
  }

  checkIsIgstApplicable (otherGstNumber,otherState) {
    let accountingCompanyGstNumber = this.companyAddress && this.companyAddress.gst_number ? this.companyAddress.gst_number : ""
    let accountingCompanyState = this.companyAddress && this.companyAddress.state_id ? this.companyAddress.state_id : ''
    if (otherGstNumber && otherGstNumber.length > 0 && otherGstNumber != 'null') {
      //if other's company code is of same as of accounting company then non igst applicable 
      if (accountingCompanyGstNumber.substring(0, 2) == otherGstNumber.substring(0, 2)) {
          return false;
      } else {
          return true;
      }
  } else if (otherState && otherState.length > 0 && otherState != 'null') {
      //if other's company state is of same sate then non igst applicable
      if (accountingCompanyState == otherState) {
          return false;
      } else {
          return true;
      }
      //if other code/state both is undefined then consider it non igst applicable 
  } else {
      return false;
  }
  }
  async calculateCommission(base,tax,commType,commFor,commRate,tdsType,tdsRate,roundOffType = "NORMAL_ROUND_OFF",i){
    let res = await this.eventService.calculateCommission(base,tax,commType,commFor,commRate,tdsType,tdsRate,roundOffType = "NORMAL_ROUND_OFF",i)
    return res
  }

  async  calculateNetAmount(i){
    var invoiceData = this.eventService.travellerForm.controls.travellerList.controls[i].value
    let  supplierCommRes = await this.calculateCommission(invoiceData.supplier_basic, invoiceData.supplier_taxes, invoiceData.supplier_commission_type,'SUPPLIER',invoiceData.supplier_commission_value,invoiceData.supplier_tds_type,invoiceData.supplier_tds_conf,'NORMAL_ROUND_OFF',i)
    let  clientCommRes = await this.calculateCommission(invoiceData.client_basic, invoiceData.client_taxes, invoiceData.client_commission_type,'CLIENT',invoiceData.client_commission_value,invoiceData.client_tds_type,invoiceData.client_tds_conf,'NORMAL_ROUND_OFF',i)
    invoiceData = this.eventService.travellerForm.controls.travellerList.controls[i].value
    let scRes = await this.calcuateSC(i);
    invoiceData = this.eventService.travellerForm.controls.travellerList.controls[i].value
    let client_total = 0
    let client_net_amount = 0
    let supplier_total = 0
    let supplier_net_amount = 0
    let client_sc_amount = Number(invoiceData.client_sc1_amount)+Number(invoiceData.client_sc2_amount)+Number(invoiceData.client_sc3_amount)

    let supplier_sc_amount = Number(invoiceData.supplier_sc1_amount)+Number(invoiceData.supplier_sc2_amount)+Number(invoiceData.supplier_sc3_amount)
    
    if(invoiceData.pay_type != undefined && invoiceData.pay_type.trim && invoiceData.pay_type != 'AGENT_CC' ){
      client_total += Number(invoiceData.client_basic) + Number(invoiceData.client_taxes )
    }
    client_net_amount = client_total - 
                    Number(invoiceData.client_commission_amount)-Number(invoiceData.client_discount_amount)+Number(client_sc_amount) +
                    Number(invoiceData.invoice_type !='non_account' && invoiceData.client_tds ? invoiceData.client_tds : 0) + 
                    Number(invoiceData.client_igst) + Number(invoiceData.client_cgst) + Number(invoiceData.client_sgst)+
                    Number(invoiceData.client_igst_2) + Number(invoiceData.client_cgst_2) + Number(invoiceData.client_sgst_2)+
                    Number(invoiceData.client_igst_3) + Number(invoiceData.client_cgst_3) + Number(invoiceData.client_sgst_3) +
                    Number(invoiceData.client_vat_amount) - Number(invoiceData.client_xxl_charges) - Number(invoiceData.client_raf_charges) - Number(invoiceData.client_vat_on_raf) -
                    Number(invoiceData.client_cgst_on_raf) - Number(invoiceData.client_sgst_on_raf) - Number(invoiceData.client_igst_on_raf);
    
    if(invoiceData.pay_type != undefined && invoiceData.pay_type.trim && invoiceData.pay_type.trim().length == 0 ){
      supplier_total += Number(invoiceData.supplier_basic) + Number(invoiceData.supplier_taxes )
      supplier_net_amount = supplier_total - 
      Number(invoiceData.supplier_commission_amount)  -Number(invoiceData.supplier_discount_amount)  + 
      Number(invoiceData.invoice_type !='non_account' && invoiceData.supplier_tds ? invoiceData.supplier_tds : 0) + Number(supplier_sc_amount) + 
      Number(invoiceData.supplier_igst) + Number(invoiceData.supplier_cgst) + Number(invoiceData.supplier_sgst)+
      Number(invoiceData.supplier_igst_2) + Number(invoiceData.supplier_cgst_2) + Number(invoiceData.supplier_sgst_2)+
      Number(invoiceData.supplier_igst_3) + Number(invoiceData.supplier_cgst_3) + Number(invoiceData.supplier_sgst_3) + 
      Number(invoiceData.supplier_vat_amount) - Number(invoiceData.supplier_xxl_charges) - Number(invoiceData.supplier_raf_charges) - Number(invoiceData.supplier_vat_on_raf) -
      Number(invoiceData.supplier_cgst_on_raf) - Number(invoiceData.supplier_sgst_on_raf) - Number(invoiceData.supplier_igst_on_raf)
    }
    
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.supplier_net_amount.setValue(Number(supplier_net_amount).toFixed(2))
    this.eventService.travellerForm.controls.travellerList.controls[i].controls.client_net_amount.setValue(Number(client_net_amount).toFixed(2))

  }

  async handlePayTypeChange(event, index){
    let calcLocalRes = await this.calculateNetAmount(index)
    let calcApiRes = await this.eventService.calculateFromApi(0)
    if(event.target.value == 'OUR_CC'){
			this.getGeneralAccounts()
		}
		if(event.target.value != 'OUR_CC') {
			let traveller = this.eventService.travellerForm.controls.travellerList.controls[index]
			traveller.controls.ledger_card_name.setValue('')
			traveller.controls.ledger_card_no.setValue('')
		}
  }

  replicateClientAmount(i: any, fromFCN,toFCN) {
    let travellerList = this.eventService.travellerForm.controls.travellerList
    let account_type = this.eventService.invoiceForm.value.invoice_type
    if(
      account_type != 'non_account' && 
      fromFCN && 
      toFCN && 
      travellerList.controls[i] && 
      travellerList.controls[i].controls && 
      travellerList.controls[i].controls[fromFCN] &&
      travellerList.controls[i].controls[toFCN]
      ){
        let purchase_currency = this.eventService.invoiceForm.controls.purchase_currency.value;
        let purchase_currency_rate = Number(this.eventService.invoiceForm.controls.purchase_currency_rate.value);
        
        if((fromFCN == 'client_basic' || fromFCN == 'client_taxes' || fromFCN == 'supplier_basic' || fromFCN == 'supplier_taxes') && !this.isTenantIndian && purchase_currency && purchase_currency_rate) {
          if(fromFCN == 'client_basic' || fromFCN == 'client_taxes') {
            travellerList.controls[i].controls[toFCN].setValue((travellerList.controls[i].controls[fromFCN].value * purchase_currency_rate).toFixed(2));
          } else {
            travellerList.controls[i].controls[toFCN].setValue((travellerList.controls[i].controls[fromFCN].value / purchase_currency_rate).toFixed(2));
          }
        }  else {
          travellerList.controls[i].controls[toFCN].setValue(travellerList.controls[i].controls[fromFCN].value)
        }
      this.calculateNetAmount(i)
    }
  }

  moveToPreviousTraveller(i) {
		this.showTravellerIndex = i - 1
	}
  moveToNextTraveller(i) {
		this.showTravellerIndex = i + 1
    let nextStep = 1
    let staticCount = 10;
    
    if(this.isTenantIndian) {
      staticCount = 10
    } else {
      staticCount = 12
    }

    if(this.staticInputCount == staticCount){
      nextStep = 2
    }
		
    if(i == this.eventService.travellerForm.controls.travellerList.controls.length){
      this.returnToInvoice()
    }
	}

  returnToInvoice(){    
		this.minimizeSection()
	
	}

  openReplicateModal(index) {
		// e.stopPropagation();

    let travellerListLength = this.eventService.travellerForm.controls.travellerList.controls.length 
    if(this.showTravellerIndex < travellerListLength - 1){
      this.moveToNextTraveller(index)
    } else {
      if (this.openModal["_results"][0]) {
        this.openModal["_results"][0].nativeElement.click()
      }
    }
	}

  replicateFormData(index) {
    let count = index + 1

    let travellerListLength = this.eventService.travellerForm.controls.travellerList.controls.length
    
     if(this.showTravellerIndex < travellerListLength - 1){
        this.moveToNextTraveller(index)
    } else {
      this.eventService.travellerCountUpdate(travellerListLength+1)
      this.minimizeSection( )
      this.maximizeSection(travellerListLength+1)  
    }

		// let tempFormValue = Object.assign({}, this.eventService.travellerForm.value.travellerList[index])
		// let travellerListLength = this.eventService.travellerForm.controls.travellerList.controls.length
		// for (let i = 0; i < travellerListLength; i++) {
    //   const travellerList = this.eventService.travellerForm.controls.travellerList.controls[i];
    //   if(i >= index){
    //     this.setTravellerData(travellerList, tempFormValue)
    //   }else {
    //     this.setTravellerData({}, tempFormValue)
    //   }
		// }
		// if (this.showTravellerIndex < travellerListLength - 1) {
		// 	this.moveToNextTraveller(index)
		// }
	}

  setTravellerData(travellerList, tempFormValue) {
		let keys = Object.keys(tempFormValue)
		for (let i = 0; i < keys.length; i++) {
			const element = keys[i];
			if (element != 'room_name' && element != 'reference_number' && element != 'lead_pax_name' && element != 'pay_type' && element != 'ledger_card_no' &&  element != 'ledger_card_name') {
				travellerList.controls[element].setValue(tempFormValue[element])
			}
		}
	}

  focusClientAc() {
    if (this.clientAC && this.clientAC.focus) {
      this.clientAC.focus()
    }
  }
  focusGeneralLedgerAc() {
    if (this.generalLedgerAc && this.generalLedgerAc.focus) {
      this.generalLedgerAc.focus()

    }
  }
  focusNarrationArea() {
    if (this.narrationArea != undefined) {
      this.narrationArea.focus();
    }
  }
  focusSupplierAc() {
    if (this.supplierAc != undefined) {
      this.supplierAc.focus();
    }
  }
  focusSupplier2Ac() {
    if (this.supplier2Ac != undefined) {
      this.supplier2Ac.focus();
    }
  }
  focusSubledgerAc() {
    if (this.subLedgerAC != undefined && this.subLedgerAC.focus) {
      this.subLedgerAC.focus();
    }
  }

  focusEmployeeAc() {
    if (this.employeeAC != undefined) {
      this.employeeAC.focus();
    }
  }

  focusLedgerCardAc() {

  }


  get DynamicFormControls() {
		const travellerList = <FormArray>this.eventService.travellerForm.get("travellerList");
		return travellerList;
	}

  maximizeSection(index,nextStep =1) {
		this.maximizedSection = true
		this.showTravellerIndex = index
	}

  minimizeSection() {
		this.maximizedSection = false
		this.showTravellerIndex = null
    this.eventService.calculateFromApi(0)

    this.setNarration()
    this.changeSalesCurrencyAmount(null);
	}

  viewInvoice(invoice_no){
    this.closeSuccesModal()
    this.router.navigate([`/transactions/event/view/${invoice_no}`])
  }
  openVoucher(invoice_number) {
		let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/event/print;searchId=${invoice_number}`;
		window.open(url, "_blank");
	}

  openActivity(invoice_no) {
		let pathurl=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/event/${invoice_no}`

		if(window.location.protocol != 'file:'){
			window.open(pathurl,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : pathurl
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

  viewJournal(invoice_no) {
    let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/view-invoice-journal/${invoice_no}`;
		window.open(url, "_blank");
	}

  async handleInvoiceForChange(value){
    for (const traveller of this.eventService.travellerList.controls) {
      traveller['controls'].client_tds_type.setValue(value == 'non_account' ? 'B' : 'C') 
      let res = await this.calculateNetAmount(this.eventService.travellerList.controls.indexOf(traveller))
    }
    this.eventService.calculateFromApi(0)
    this.invoice_config = this.eventService.invoice_config[value] ? this.eventService.invoice_config[value] : ""
    if(this.invoice_config && this.invoice_config.general_ledger){
      this.generalSearch(this.invoice_config.general_ledger,true,true, false)
    }else{
      this.selectAccount(null,'general_ledger_code', null, false)
    }
  }

  handleMaximizeSection(i){
    let staticCount = 10;
    if(this.isTenantIndian) {
      staticCount = 10
    } else {
      staticCount = 12
    }

    if(this.staticInputCount == staticCount){
      this.maximizeSection(i,2)
    }
  }

  closeSuccesModal(){
    this.showMessage = false;
    this.isSuccess = false
    this.alertType = 'success-box';
    this.message = ``
    this.newInvoice = true
    this.openSuccessModal.nativeElement.click()
  }
  resetSelection(formControlName) {
    this.eventService.invoiceForm.controls[formControlName].setValue('')
    this.eventService.invoiceForm.controls[`${formControlName}_name`].setValue('')
    if(formControlName == 'client_code'){
      this.bookingClientArr = []
    }
    if(formControlName == 'supplier_code'){
      this.bookingSupplierArr = []
    }
  }

  setServiceDate(e){
    if(e.target.value && moment(e.target.value).isValid()){
      this.eventService.invoiceForm.controls.start_date.setValue(e.target.value)

    }
  }

  resetCurrentOpenedModal(){
    this.currentOpenedModal = ''
  }
  openDeleteConfirmModal() {
    if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.invoiceData.invoice_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.eventService.invoiceForm.controls.invoice_date.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to delete the invoice.'
				return false;
			}
		}

    this.closeOpenedModals('delete')
    this.currentOpenedModal = 'delete'
		this.confirmDeleteModal.nativeElement.click()
	}
	closeDeleteConfirmModal() {
    this.currentOpenedModal = ''
		this.closeDeleteModal.nativeElement.click()
	}

  openUpdateConfirmModal() {
    if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.invoiceData.invoice_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.eventService.invoiceForm.controls.invoice_date.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to delete the invoice.'
				return false;
			}
		}

    if(this.eventService.invoiceForm.value.bill_of_supply === true) {
      let checkBosApplicableOrNot = this.checkBosApplicableOrNot(this.eventService.travellerForm.value)
      if(!checkBosApplicableOrNot) {
        this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Bill of supply Not Applicable.'
				return false;
      }
    }

    this.closeOpenedModals('update')
		this.currentOpenedModal = 'update'
		this.confirmUpdateModal.nativeElement.click()
	}
	closeUpdateConfirmModal() {
		this.currentOpenedModal = ''
		this.closeUpdateModal.nativeElement.click()
	}

  closeOpenedModals(modalName){
		if(modalName!='delete') this.closeDeleteConfirmModal()
		if(modalName!='update') this.closeUpdateConfirmModal()
	}

  deleteInvoice(invoice_no) {
		this.closeDeleteConfirmModal()
		this.commonService.deleteRequest(`${environment.okountUrl}/invoice?invoice_no=${invoice_no}`).subscribe(res => {
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = `Invoice Deleted`
		}, err => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = `Failed to delete invoice.`
		})
	}

  openSendEmailModal(){
		//define email.
    let primary_email = ''
    let secondary_email = ''
    let subject = `INVOICE NO. ${this.invoice_no}`
    let body = `Dear Travel Partner,\n\nPlease find Attached Invoice ${this.invoice_no}`
    body+=`\n\nPlease do not hesitate to contact us if you have any query or concern about attached document.\n\nIt's been a pleasure to serving you.\n\nBest Regards\n\n${this.invoiceData.accounting_company?.name}\nAccounts Team`
    if(this.invoiceData.accounting_company && this.invoiceData.accounting_company.telephone){
			body += `\nContact Us on ${this.invoiceData.accounting_company.telephone}`
		}
    if(this.invoiceData && this.invoiceData.client){
      primary_email = this.invoiceData.client.hasOwnProperty('primary_email')?this.invoiceData.client.primary_email:''
      secondary_email = this.invoiceData.client.hasOwnProperty('secondary_email')?this.invoiceData.client.secondary_email:''
    }
    this.sendEmailForm.controls.toEmails.setValue(primary_email)
    this.sendEmailForm.controls.bccEmails.setValue("")
    this.sendEmailForm.controls.ccEmails.setValue(secondary_email?.split(',').join('\n'))
    this.sendEmailForm.controls.subject.setValue(subject)
		this.sendEmailForm.controls.body.setValue(body)
    this.openEmailModal.nativeElement.click();
	}

	callTosendEmail(){
  	
		let toEmails = this.sendEmailForm.value.toEmails
		let bccEmails = this.sendEmailForm.value.bccEmails?.split(",")
		let ccEmails = this.sendEmailForm.value.ccEmails?.split(",")
    let subject = this.sendEmailForm.value.subject
    let body = this.sendEmailForm.value.body
    let clientName = this.invoiceData && this.invoiceData.client && this.invoiceData.client.name?this.invoiceData.client.name:''
		if(toEmails){
      
				toEmails = toEmails.split(",")
				window.scrollTo(0, 0)
				let emails = {
				  to_addresses:toEmails,
				  cc_addresses:ccEmails,
				  bcc_addresses:bccEmails
				}
				let pageUrl = `transactions/event/print;searchId=${this.invoice_no}`
				let data = {
					  email:emails,
					  name:clientName,
					  page_url:pageUrl,
            subject:subject,
            email_body:body,
            document_num:this.invoice_no
				  }
				this.commonService.postRequest(`${environment.okountUrl}/send_mail?transaction_type=tour_invoice`,data).subscribe((res : any)=> {
				  this.showMessage = true;
				  this.alertType = 'success-box'
				  this.message = res.msg
				  this.closebutton.nativeElement.click();
				},err => {
				  this.showMessage = true
				  this.alertType = "error-box"
				  this.message = err.msg
				  this.closebutton.nativeElement.click();
				  
				})
			  }
	
	  }


  closeWindow() {
		window.close();
	}

  copytext(val: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.copyELementTitle = 'Copied'
	}

  closeMasterModal() {
    this.closeMasterCreationModal.nativeElement.click()
   }


   openModalMasterCreation(formControlName) {
    if(formControlName == 'client_code') {
        let selectedType = this.eventService.invoiceForm.controls.client_account_type.value;
        this.eventService.invoiceForm.controls.client_code_name.setValue(this.currentMasterName)
        this.currentMaster = selectedType;
        this.currentFormControlName = formControlName
      }else if(formControlName == 'general_ledger_code') {
        this.currentMaster = 'general'
        this.currentFormControlName = formControlName
        this.eventService.invoiceForm.controls.general_ledger_code_name.setValue(this.currentMasterName)
      }else if(formControlName == 'subledger_code') {
        let selectedType = this.eventService.invoiceForm.controls.subledger_type.value;
        this.currentMaster = selectedType;
        this.currentFormControlName = formControlName;
        this.eventService.invoiceForm.controls.subledger_code_name.setValue(this.currentMasterName)
      }

      if(this.currentMaster) {
        this.masterCreationModal.nativeElement.click()
      }
    }

  setFormValues(data) {
    let name = 'name'
    if(this.currentMaster == 'general' || this.currentMaster == 'airline') {
      name = 'account_name';
    }

    this.showMessage = true;
    this.alertType = 'success-box';
    this.message = `${this.currentMaster} created successfully with account code. : ${data.account_code}`
    
    let formControl =  this.currentFormControlName;
    if(formControl) {
      this.eventService.invoiceForm.controls[formControl].setValue(data.account_code ?  data.account_code : '')
      this.eventService.invoiceForm.controls[`${formControl}_name`].setValue(data[name] ?  data[name] : '')
    }
  }
 
  changeSalesCurrencyAmount(event) {
    let value =  this.eventService.invoiceForm.controls.sales_currency_rate.value;
    let rate =  Number(value ? value : 0);
    if(rate) {
      let client_net_amount =  this.eventService.totalFareModel[0].client_net_amount;
      let tcs_amount = Number(this.eventService.invoiceForm.controls.tcs_amount.value ? this.eventService.invoiceForm.controls.tcs_amount.value : 0);
      let grandTotal = Number(client_net_amount) + Number(tcs_amount ? tcs_amount : 0);
      this.eventService.invoiceForm.controls.sales_currency_amount.setValue((grandTotal * rate).toFixed(2));
    } else {
      this.eventService.invoiceForm.controls.sales_currency_amount.setValue('');
    }
   }

   getAccountingCompany() {
    try {
      this.invoiceService.getMethod('util/accounting-company').subscribe(async (res) => {
        if(res) {
          if(res['currency_code']) {
            this.currencyCode = res['currency_code'];
          }
          if(res['native_language_code'] && res['currency_code'] == 'AED') {
            this.nativeLanguageCode = res['native_language_code'];
          }
        }
      },
      err => {
        this.spinner.hide()
      })
    } catch (error) {
      console.log('Error occured in getInvoice due to : ', error)
    }
  }

  clickToPrintOptions() {
		this.showPrintOptions = !this.showPrintOptions;
	}

  checkWhetherEInvGeneratedOrNot(invoice) {
		this.commonService.getRequest(`${environment.okountUrl}/e_invoicing/check-invoice?invoiceNumber=${invoice}`).subscribe((res : any)=>{
			if(res && res['message'] && res['message'] == 'not_generated') {
				this.generateEInvoiceModal.nativeElement.click()
				return false;
			} 

			this.getPdfOfEInvoice(invoice)
		},err=>{
			console.log(err)
		})
	}

  closeGenerateEInvoiceModal(){
		this.generateEInvoiceModal.nativeElement.click()
	}

  getPdfOfEInvoice(invoice) {
		this.spinner.show()
		this.commonService.downloadPDF(`${environment.okountUrl}/e_invoicing/print/miscellaneous?invoiceNumber=${invoice}&type=tour_invoice&preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `E-invoice-${invoice}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			this.spinner.hide()
			FileSaver.saveAs(blob, fileName);
	   }, err => {
		    this.spinner.hide()
	   })
	}

  generateEInvoice(invoice) {
		this.spinner.show()
		this.commonService.postRequest(`${environment.okountUrl}/e_invoicing/miscellaneous?invoiceNumber=${invoice}&type=tour_invoice`, null).subscribe((res : any)=> {
			this.showMessage = true;
			this.alertType = 'success-box'
			this.getPdfOfEInvoice(invoice);
		},err => {
			this.showMessage = true
			this.spinner.hide()
			this.alertType = "error-box"
			this.message = err.error && err.error.message ? err.error.message : ''
		})

	}
  checkWhetherEInvGenerated(invoice) {
		this.commonService.getRequest(`${environment.okountUrl}/e_invoicing/check-invoice?invoiceNumber=${invoice}`).subscribe((res : any)=>{
			if(res && res['message'] && res['message'] == 'not_generated') {
				this.checkEInvoice= false;
			} else{
				this.checkEInvoice= true
			}
			if(this.cookieService.getCookie('SYSTACC_USER_LOGIN_DATA')) {
				let userData = JSON.parse(this.cookieService.getCookie('SYSTACC_USER_LOGIN_DATA'))
        if(userData.tenant_id && environment.BHUPESH_TENANT.split(',').includes(typeof userData.tenant_id=='number'?userData.tenant_id.toString():userData.tenant_id)){
          this.isTesoyBoult = true
			}
		}
		console.log("isTesoyBoult",this.isTesoyBoult,"checkEInvoice",this.checkEInvoice)
		},err=>{
			console.log(err)
			return false
		})
	}
  getSubService(service){
    this.sub_service = this.eventService.sub_service_type[`${service}`]
  }

  openBosReference() {
    if(this.invoiceData && this.invoiceData.bos_reference) {
      let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/event/view/${this.invoiceData.bos_reference}`;
      window.open(url, "_blank");
    }
  }

   checkBosApplicableOrNot(product_json_doc) {
    if(product_json_doc.travellerList.length>0) {
      for(let item of product_json_doc.travellerList) {
        let gst_amount = Number(item.client_igst) + Number(item.client_cgst) + Number(item.client_sgst);
        let secondGstAmount = Number(item.client_igst_2) + Number(item.client_cgst_2) + Number(item.client_sgst_2);
        let thirdGstAmount = Number(item.client_igst_3) + Number(item.client_cgst_3) + Number(item.client_sgst_3);
        if(((item.client_gst_type == 'S' && Number(gst_amount) > 0) == false) || secondGstAmount>0 || thirdGstAmount>0) {
          return false;
        }
      }
      return true;
    }
  }

}
