<div class="left-side-bar w-100per bg-white transition position-relative" #leftSideMenu
    [ngClass]="{'minimize-menues': minimize == true,'maximze-menues': maximize == true }">
    <ul class="pl-0 mt-0 menues">

        <!-- <li [routerLink]="['/']" (mouseover)="dashbardImgSrc = 'assets/Icons/{{darkMode == true ? 'dark' : 'light'}}-mode/menues/active-dashboard.svg'"
            (mouseout)="dashbardImgSrc = 'assets/Icons/{{darkMode == true ? 'dark' : 'light'}}-mode/menues/inactive-dashboard.svg'"
            class="menue-item  cursor transition d-flex  flex-y-center text-size-12  text-uppercase">
            <div [routerLink]="['/']" class="menu-icon img-container">
                <img [src]="dashbardImgSrc" alt="">
            </div>
            <div (mouseover)="dashbardImgSrc = 'assets/Icons/{{darkMode == true ? 'dark' : 'light'}}-mode/menues/active-dashboard.svg'"
                (mouseout)="dashbardImgSrc = 'assets/Icons/{{darkMode == true ? 'dark' : 'light'}}-mode/menues/inactive-dashboard.svg'"
                class="menues-box ">
                <div class="d-flex justify-content-between align-item-center">
                    <span [routerLink]="['/']"
                        class="d-block menue-item-title text-grey text-bold transition">DASHBOARD</span>
                </div>
            </div>
        </li> -->
        <ng-container *ngIf="menuItems">
           <ng-container *ngFor="let parent of menuItems.features">
            <li *hasAccess="{'key' : parent.feature_code}"
            class="menue-item  cursor transition  text-size-12 text-uppercase">
            <div class="d-flex" >
                <div class="menu-icon img-container" (click)="handleParentMenu(parent)">
                    <img *ngIf="parent.feature_code == activeMenu" src="{{commonService.aws_asset_url}}/_assets/icons/active-{{parent.feature_name}}_v2.svg" alt="">
                    <img *ngIf="parent.feature_code != activeMenu" src="{{commonService.aws_asset_url}}/_assets/icons/inactive-{{parent.feature_name}}_v2.svg" alt="">
                </div>
                <div class="menues-box " [ngClass]="{'d-block': parent.feature_code == activeParent}">
                    <div class="d-flex justify-content-between align-item-center" (click)="handleParentMenu(parent)">
                        <span
                            class="d-block menue-item-title text-white text-bold transition" [ngClass]="{'text-primary': parent.feature_code == activeMenu}">{{parent.feature_name}}</span>
                        <img src="{{commonService.aws_asset_url}}/_assets/icons/grey-angle-down.svg" alt="" class="ml-10">
                    </div>

                    <ng-container
                        *ngIf="parent.url == null && parent.sub_features && parent.sub_features.length > 0 && parent.is_menu">
                        <ul class="sub-menues one list-unstyled pl-0" [ngClass]="{'d-block': parent.feature_code == activeParent}">
                            <ng-container *ngFor="let subMenu of parent.sub_features">
                              <ng-container *ngIf="subMenu.is_menu">
                                <ng-container *hasAccess="{'key' : subMenu.feature_code}">
                                    <li *ngIf="subMenu.url == null && subMenu.sub_features && subMenu.sub_features.length > 0 && subMenu.is_menu"
                                        class="sub-menue-item   cursor transition  text-size-12 position-relative  text-uppercase">
                                        <div class="d-flex justify-content-between flex-y-center " (click)="handleFirstLevelMenu(subMenu.feature_code)">
                                            <span
                                                class="d-block text-grey text-bold transition">{{subMenu.feature_name}}</span>
                                            <img src="{{commonService.aws_asset_url}}/_assets/icons/grey-angle-down.svg" alt=""
                                                width="15px" class="mr-10">
                                        </div>
                                        <ul class="sub-menues two list-unstyled pl-0" [ngClass]="{'d-block': subMenu.feature_code == activeFirstLevel}">
                                            <ng-container *ngFor="let subSubMenu of subMenu.sub_features">
                                                <ng-container *hasAccess="{'key' : subSubMenu.feature_code}">
                                                    <li  *ngIf="subSubMenu.url == null && subSubMenu.sub_features && subSubMenu.sub_features.length > 0 && subSubMenu.is_menu"
                                                        class="sub-menue-item    cursor transition  text-size-12 position-relative  text-uppercase" >
                                                        <div class="d-flex justify-content-between  flex-y-center" (click)="handleSecondLevelMenu(subSubMenu.feature_code)">
                                                            <span class="d-block text-grey text-bold transition">
                                                                {{subSubMenu.feature_name}} </span>
                                                            <img src="{{commonService.aws_asset_url}}/_assets/icons/grey-angle-down.svg"
                                                                alt="" width="15px" class="mr-10">
                                                        </div>
                                                        <ul class="sub-menues two list-unstyled pl-0" [ngClass]="{'d-block': subSubMenu.feature_code == activeSecondLevel}">
                                                            <ng-container
                                                                *ngFor="let subSubSubMenu of subSubMenu.sub_features">
                                                                <li (click)="hanldeActivePage(parent.feature_code,subSubSubMenu)" *hasAccess="{'key' : subSubSubMenu.feature_code}"
                                                                    class="sub-menue-item    cursor transition  text-size-12 position-relative  text-uppercase">
                                                                    <div
                                                                        class="d-flex justify-content-between  flex-y-center"  (click)="handleThirdLevelMenu(subSubSubMenu.feature_code)">
                                                                        <span
                                                                            class="d-block text-grey text-bold transition">
                                                                            {{subSubSubMenu.feature_name}}</span>
                                                                        <img src="{{commonService.aws_asset_url}}/_assets/icons/grey-angle-down.svg"
                                                                            alt="" width="15px" class="mr-10">
                                                                    </div>
                                                                    <ul class="sub-menues two list-unstyled pl-0" [ngClass]="{'d-block': subSubSubMenu.feature_code == activeThirdLevel }">
                                                                        <ng-container
                                                                            *ngFor="let subSubSubSubMenu of subSubSubMenu.sub_features">
                                                                            <li (click)="hanldeActivePage(parent.feature_code, subSubSubSubMenu)" *hasAccess="{'key' : subSubSubSubMenu.feature_code}"
                                                                                class="sub-menue-item    cursor transition  text-size-12 position-relative  text-uppercase">
                                                                                <div
                                                                                    class="d-flex justify-content-between  flex-y-center">
                                                                                    <span
                                                                                        class="d-block text-grey text-bold transition">
                                                                                        {{subSubSubSubMenu.feature_name}} </span>
                                                                                    <img src="{{commonService.aws_asset_url}}/_assets/icons/grey-angle-down.svg"
                                                                                        alt="" width="15px" class="mr-10">
                                                                                </div>
                                                                            </li>
                                                                        </ng-container>
                                                                    </ul>
                                                                </li>
                                                                <li *ngIf="subSubSubMenu.url != null" (click)="hanldeActivePage(parent.feature_code, subSubSubMenu)"
                                                                class="sub-menue-item  cursor transition  text-size-12 position-relative  text-uppercase">
                                                                <div class="d-flex justify-content-between  flex-y-center">
                                                                    <span
                                                                        class="d-block text-grey text-bold transition">{{subSubSubMenu.feature_name}}</span>
                                                                </div>
                                                            </li>
                                                            </ng-container>
                                                        </ul>
                                                    </li>
                                                    <li *ngIf="subSubMenu.url != null" (click)="hanldeActivePage(parent.feature_code, subSubMenu)"
                                                        class="sub-menue-item  cursor transition  text-size-12 position-relative  text-uppercase">
                                                        <div class="d-flex justify-content-between  flex-y-center">
                                                            <span
                                                                class="d-block text-grey text-bold transition">{{subSubMenu.feature_name}}</span>
                                                        </div>
                                                    </li>
    
                                                </ng-container>
                                            </ng-container>
                                        </ul>
                                    </li>
                                    <li *ngIf="subMenu.url != null" (click)="hanldeActivePage(parent.feature_code, subMenu)"
                                        class="sub-menue-item  cursor transition  text-size-12 position-relative  text-uppercase">
                                        <div class="d-flex justify-content-between  flex-y-center">
                                            <span
                                                class="d-block text-grey text-bold transition">{{subMenu.feature_name}}</span>
                                        </div>
                                    </li>
                                </ng-container>
                              </ng-container>

                            </ng-container>
                        </ul>
                    </ng-container>
                </div>
            </div>
        </li>
           </ng-container>
        </ng-container>
    </ul>
    <div class="bottm-menues-container position-absolute bottom-0 w-100per">
        <!-- <div class="d-flex flex-x-center">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div> -->
        <ul class="pl-0 mt-0 bottom-menues">
            <!-- <li (mouseover)="notificationImgSrc = 'assets/Icons/active-notification.svg'"
                (mouseout)="notificationImgSrc = 'assets/Icons/inactive-notification.svg'"
                (click)="showNotificationArea()"
                class="menue-item  cursor transition d-flex  flex-y-center text-size-12 position-relative  text-uppercase">
                <span *ngIf="notificationCount > 0 && minimize == true"
                    class="notificationCount position-absolute">{{notificationCount}}</span>
                <div class="menu-icon img-container">
                    <img [src]="notificationImgSrc" alt="">
                   
                </div>
                <div [ngClass]="{'d-none': maximize == true }"
                    class="position-absolute toolTip text-primary text-bold transition">
                    Notification
                </div>
                <span [ngClass]="{'d-none': minimize == true,'d-block': maximize == true }"
                    class="d-block ml-10 text-grey text-bold transition">Notification <span
                        *ngIf="notificationCount > 0 && maximize == true"
                        class="notificationCount">{{notificationCount}}</span></span>
            </li> -->

            <!-- <li (mouseover)="settingImgSrc = 'assets/Icons/active-settings.svg'"
                (mouseout)="settingImgSrc = 'assets/Icons/inactive-settings.svg'"
                
                class="menue-item  cursor transition d-flex  flex-y-center text-size-12 position-relative  text-uppercase">
                <div class="menu-icon img-container">
                    <img [src]="settingImgSrc" alt="">
                </div>
                <div [ngClass]="{'d-none': maximize == true }"
                    class="position-absolute toolTip text-primary text-bold transition">
                    CONFIGURATION
                </div>
                <span [ngClass]="{'d-none': minimize == true,'d-block': maximize == true }"
                    class="d-block ml-10 text-grey text-bold transition">CONFIGURATION</span>
            </li> -->

            <!-- <li (mouseover)="darkMode == true ? logoutImgSrc = 'assets/Icons/dark-mode/menues/active-logout.svg' : logoutImgSrc = 'assets/Icons/active-logout.svg'"
                (mouseout)="darkMode == true ? logoutImgSrc = 'assets/Icons/dark-mode/menues/inactive-logout.svg' : logoutImgSrc = 'assets/Icons/inactive-logout.svg'"
                (click)="setLogoutMOdalStatus(true)"
                class="menue-item  cursor transition d-flex  flex-y-center text-size-12 position-relative  text-uppercase">
                <div class="menu-icon img-container">
                    <img [src]="logoutImgSrc" alt="">
                </div>
                <div [ngClass]="{'d-none': maximize == true }"
                    class="position-absolute toolTip text-primary text-bold transition">
                    LOGOUT
                </div>
                <span [ngClass]="{'d-none': minimize == true,'d-block': maximize == true }"
                    class="d-block ml-10 text-grey text-bold transition">LOGOUT</span>
            </li> -->
        </ul>
        <!-- <ul class="pl-0 mt-0 ">

            <li
                class="menue-item financialYear-menue cursor transition d-flex  flex-y-center text-size-12 position-relative  text-uppercase">
                <div class="menu-icon img-container">
                    <img src="{{commonService.aws_asset_url}}/_assets/icons/active-calender.svg" alt="">
                </div>
                <div [ngClass]="{'d-none': maximize == true }"
                    class="position-absolute toolTip text-primary text-bold transition">
                    <small class="text-capitalize text-size-10 text-grey d-block text-bold" >Financial Year</small>
                    <div class="d-flex justify-content-between align-item-center">
                        <select style="border: 0!important;"                           
                        class="d-block text-primary text-bold transition" (change)="setFinancialYear($event)">
                        <ng-container *ngFor="let fy of financialYears">
                            <option  value="{{fy.value}}" [selected]="financialYear == fy.value">{{fy.key}}</option>
                        </ng-container>
                    </select>
                    <img src="{{commonService.aws_asset_url}}/_assets/icons/white-angle-down.svg" alt="" class="ml-10">
                       </div>
                </div>
                <div class=" ml-10" [ngClass]="{'d-none': minimize == true,'d-block': maximize == true }">
                    <small class="text-capitalize text-size-10 text-grey d-block">Financial Year: </small>
                       <div class="d-flex justify-content-between align-item-center">
                        <select style="border: 0!important;"                           
                        class="d-block text-primary text-bold transition" (change)="setFinancialYear($event)">
                        <ng-container *ngFor="let fy of financialYears">
                            <option  value="{{fy.value}}" [selected]="financialYear == fy.value">{{fy.key}}</option>
                        </ng-container>
                       
                    </select>
                    <img src="{{commonService.aws_asset_url}}/_assets/icons/grey-angle-down.svg" alt="" class="ml-10">
                       </div>

                </div>
            </li>
        </ul> -->
        <!-- <button onclick="playSound()">Play File</button> -->
        <!-- <audio src="assets/notification-sound.mp3"></audio> -->
        <!-- <button (click)="launch_toast('../../.assets/notification-sound.mp3')">Toast</button> -->
        <div class="footer">
            <img (click)="maximizeLeftSideBarMenues()"
                [ngClass]="{'d-block': minimize == true,'d-none': maximize == true }"
                src="{{commonService.aws_asset_url}}/_assets/icons/close-menu-toggle-icon.svg" width="30px"
                class="shadow-2 cursor bdr-radius-50 transform-y-50per " style="transform: translateY(-70%)!important;" alt="" />
            <img (click)="minimizeLeftSideBarMenues()"
                [ngClass]="{'d-none': minimize == true,'d-block': maximize == true }"
                src="{{commonService.aws_asset_url}}/_assets/icons/open-menu-toggle-icon.svg" width="30px"
                class="shadow-2 cursor bdr-radius-50 transform-y-50per " style="transform: translateY(-70%)!important;" alt="" />
        </div>
    </div>
</div>