import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BankComponent } from './bank.component';
import { CreateBankComponent } from './create-bank/create-bank.component';
import { UpdateBankComponent } from './update-bank/update-bank.component';
import { ViewBankComponent } from './view-bank/view-bank.component';

const routes: Routes = [
  {
		path: '', component: BankComponent,
		children: [
			{ path: 'create-bank', component: CreateBankComponent },
			{ path: 'view-bank', component: ViewBankComponent },
			{ path: 'update-bank/:id', component: UpdateBankComponent },
      		{
				path: '',
				redirectTo: 'create-bank',
				pathMatch: 'full'
			}
		]
	},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BankRoutingModule { }
