import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';

// import { UpdateSupplierComponent } from './update-supplier/update-supplier.component';
import { Routes, RouterModule } from '@angular/router';
// import { ViewSupplierComponent } from './view-supplier/view-supplier.component';
import { SupplierComponent } from './supplier.component';
import { ViewSupplierComponent } from './view-supplier/view-supplier.component';
import { UpdateSupplierComponent } from './update-supplier/update-supplier.component';
import { CreateSupplierComponent } from './create-supplier/create-supplier.component';

const routes: Routes = [

	{
		path: '', component: SupplierComponent,
		children: [
			{ path: 'create-supplier', component: CreateSupplierComponent },
			{ path: 'view-supplier', component: ViewSupplierComponent },
      		{ path: 'update-supplier/:id', component: UpdateSupplierComponent },
      
      		{
				path: '',
				redirectTo: 'view-supplier',
				pathMatch: 'full'
			}
		]
	},
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SupplierRoutingModule { }
