import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentRoutingModule } from './payment-routing.module';
import { PaymentComponent } from './payment.component';
import { AddPaymentRecordComponent } from './add-payment-record/add-payment-record.component';
import { PaymentService } from './services/payment.service';
import { PaymentV2Service} from './services/paymentv2.service';

import { SharedModule } from 'src/app/shared/shared.module';
import { UpdatePaymentComponent } from './update-payment/update-payment.component';
import { ViewPaymentComponent } from './view-payment/view-payment.component';
import { CreatePaymentComponent } from './create-payment/create-payment.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { V2CreatePaymentComponent } from './v2-create-payment/v2-create-payment.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [PaymentComponent, AddPaymentRecordComponent, UpdatePaymentComponent, ViewPaymentComponent, CreatePaymentComponent, V2CreatePaymentComponent],
  imports: [
    CommonModule,
    PaymentRoutingModule,
    SharedModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  providers : [PaymentService,PaymentV2Service]
})
export class PaymentModule { }
