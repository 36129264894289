
<form [formGroup]="sectorService.invoiceForm">
	<div formGroupName="gstDetail" class="sector-generated">
		<table class="table table-hovered table-bordered">
			<thead>
				<tr>
                    <th>company name</th>
                    <th>email</th>
                    <th>gstNumber</th>
                    <th>phone</th>
				</tr>
			</thead>

			<tbody>
				<ng-container>
					<td><input  oninput="this.value = this.value.toUpperCase()" class="traveller-input-field" type="text"  
                        formControlName="companyName" />
                    <span *ngIf="!sectorService.invoiceForm.controls.gstDetail.controls.companyName.valid" class="gst-input">please enter valid company name</span>

                    </td>

                    <td><input  oninput="this.value = this.value.toUpperCase()" class="traveller-input-field" type="text"  
                        formControlName="email" />
                    <span *ngIf="!sectorService.invoiceForm.controls.gstDetail.controls.email.valid" class="gst-input">Please enter valid email</span>

                    </td>
                    <td><input  oninput="this.value = this.value.toUpperCase()" class="traveller-input-field" type="text"  
                        formControlName="gstNumber" />
                    <span *ngIf="!sectorService.invoiceForm.controls.gstDetail.controls.gstNumber.valid" class="gst-input">Please enter valid gst number</span>
                        
                    </td>
                    <td><input  oninput="this.value = this.value.toUpperCase()" class="traveller-input-field" type="text"  
                        formControlName="phone" />
                    <span *ngIf="!sectorService.invoiceForm.controls.gstDetail.controls.phone.valid" class="gst-input">Please enter valid mobile number</span>

                    </td>

				</ng-container>
			</tbody>
		</table>
	</div>
</form>





