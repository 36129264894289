<div class="ledger-container p-0" #tableHeader tabIndex="0" style="margin-top: -3px;">
    <div class="table-container hsn-tbl">
        <div class="table table-section">
            <nz-table class="table fixed-tbody-height"
                #gstData [nzData]="gstArr && gstArr['data']"
                [nzLoading]="loader"
                [nzFrontPagination]="false"
            >
                <thead>
                    <tr>
                        <th style ="width: 86px;min-width: 86px;"  class="text-left">HSN</th>
                        <th   class="text-left">Description</th>
                        <th  style ="min-width: 40px;" class="text-left">UQC</th>
                        <th style ="width: 112px;min-width: 112px;"  class="text-right">Total Quantity</th>
                        <th style ="width: 112px;min-width: 112px;"  class="text-right">Rate</th>
                        <th  style ="width: 90px;min-width: 90px;" class="text-right">Total Value</th>
                        <th  style ="width: 112px;min-width: 112px;"  class="text-right">Taxable Value </th>
                        <th  style ="width: 162px;min-width: 162px;"  class="text-right">Integrated Tax Amount</th>
                        <th  style ="width: 146px;min-width: 146px;" class="text-right">Central Tax Amount</th>
                        <th style ="width: 146px;min-width: 146px;" class="text-right">State/UT Tax Amount</th>
                        <th style ="width: 100px;min-width: 100px;"  class="text-right">Cess Amount</th>
                    </tr>
                </thead>

                <ng-container *ngIf="gstData && gstData['data'] && gstData['data'].length>0 else noResult">
                    <tbody class="main-panel" (window:keydown)="onKeyDown($event)" [ngStyle]="{height: 'calc(100vh - 184px)'}">
                        <tr *ngFor="let data of gstArr['data'] let i = index" [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}"
                            class="field-table-input">
                            <td style ="width: 86px;min-width: 86px;" class="text-left text-black font-500 " (click)="moveToIndex(i)">{{data?.hsn}}</td>
                            <td class="text-left text-grey" (click)="moveToIndex(i)">{{data?.description}}</td>
                            <td style ="min-width: 40px;" class="text-left text-grey" (click)="moveToIndex(i)">{{data?.ucq}}</td>
                            <td style ="width: 112px;min-width: 112px;" class="text-right text-grey" (click)="moveToIndex(i)">{{data?.quantity}}
                            </td>
                            <td style ="width: 112px;min-width: 112px;" class="text-right text-grey" (click)="moveToIndex(i)">{{data?.rate}}
                            </td>
                            <td style ="width: 90px;min-width: 90px;"  class="text-right text-grey " (click)="moveToIndex(i)">
                                {{data?.total_value | number : '1.2-2'}}</td>
                            <td style ="width: 112px;min-width: 112px;"  class="text-right text-grey" (click)="moveToIndex(i)">
                                {{data?.taxable_value | number : '1.2-2'}}</td>
                            <td style ="width: 162px;min-width: 162px;"  class="text-right text-black font-500" (click)="moveToIndex(i)">
                                {{data?.integrated_tax_amount | number : '1.2-2'}}</td>
                            <td style ="width: 146px;min-width: 146px;" class="text-right text-black font-500" (click)="moveToIndex(i)">
                                {{data?.central_tax_amount | number : '1.2-2'}}</td>
                            <td  style ="width: 146px;min-width: 146px;" class="text-right text-black font-500" (click)="moveToIndex(i)">
                                {{data?.state_tax_amount | number : '1.2-2'}}</td>
                            <td style ="width: 100px;min-width: 100px;" class="text-right text-black font-500" (click)="moveToIndex(i)">
                                {{data?.cess_amount | number : '1.2-2'}}</td>
    
                        </tr>
                    </tbody>
                    <tfoot *ngIf="gstArr && gstArr['summary']" class="table-tfoot-bg">
                        <tr>
                            <td style ="width: 86px;min-width: 86px;" colspan="2" class="">
                               <span class="font-500" >Total rec.: </span>
                            </td>
                            <td  class=""> <span class="text-right font-500">{{gstArr['summary']?.total_record }}</span></td>
                            <td style ="width: 40px;min-width: 40px;" class=""></td>
                            <td style ="width: 90px;min-width: 90px;" class=""></td>
                            <td style ="width: 112px;min-width: 112px;" class="text-right grnd-total">{{gstArr['summary']?.total_value | number : '1.2-2'}}</td>
                            <td style ="width: 112px;min-width: 112px;"  class="text-right grnd-total">{{gstArr['summary']?.taxable_value | number : '1.2-2'}}</td>
                            <td style ="width: 162px;min-width: 162px;"  class="text-right grnd-total">{{gstArr['summary']?.integrated_tax_amount | number : '1.2-2'}}</td>
                            <td style ="width: 146px;min-width: 100px;" class="text-right grnd-total">{{gstArr['summary']?.central_tax_amount | number : '1.2-2'}}</td>
                            <td  style ="width: 146px;min-width: 146px;" class="text-right grnd-total">{{gstArr['summary']?.state_tax_amount | number : '1.2-2'}}</td>
                            <td style ="width: 100px;min-height: 100px;" class="text-right grnd-total">{{gstArr['summary']?.cess_amount | number : '1.2-2'}}</td>
                        </tr>
                    </tfoot>
                </ng-container>

                <ng-template #noResult>
                    <div *ngIf="!loader" class="w-100 text-center" style="padding: 20px;">
                        <nz-empty nzNotFoundContent="No result Found"></nz-empty>
                    </div>
                </ng-template>
            </nz-table>
        </div>
    </div>

</div>