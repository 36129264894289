<form [formGroup]="gstreportFilterForm">
  <div class="flexbox aff-info">
    <div class="flexbox aff-info-inner">
      <div class="flexbox ">
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="1" formControlName="start_date" id="from_date" type="date"
              min="{{minDate}}" max="{{maxDate}}"
              [ngClass]="{'error-box': !gstreportFilterForm.controls.end_date.valid}"
              />
          <label for="from_date">Starting Date</label>

        </div>
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="2" formControlName="end_date" id="to_date" type="date"
              min="{{minDate}}" max="{{maxDate}}"
              [ngClass]="{'error-box': !gstreportFilterForm.controls.end_date.valid}"
              />
          <label for="to_date">Ending Date</label>
        </div>
      </div>

      <div class="flexBox d-flex mt-20 ml-2 mr-2">

        <div class="input-fiel ">
          <label class="radio-label d-flex align-items-center" for="summary-view">
          <input searchtabindex="3" formControlName="viewMode" name="viewMode" value="summary" id="summary-view" type="radio" (change)="handleChange($event)"/>
         <span class="ml-2">summary</span></label>
        </div>

        <div class="input-fiel ">
          <label class="radio-label d-flex align-items-center" for="detail-view">
          <input searchtabindex="3" formControlName="viewMode" name="viewMode" value="detail" id="detail-view" type="radio" (change)="handleChange($event)"/>
         <span class="ml-2">detailed</span></label> 
        </div>

          

          <!-- <div class="select-input text-input-fiel">
            <select class="input-field"  id="type_of_pay" name="type_of_pay" formControlName="type_of_pay">
                  <option value="">Select</option>
                  <option value="OUR_CC">Our CC</option>
                  <option value="AGENT_CC">Agent CC</option>
					  </select>
            <label for="type_of_pay">Pay Type</label>
          </div> -->
      </div>
      <div class="mt-20">
        <div class="select-field  text-input-fiel">
          <label for="airline" class="ngauto-label">Airline</label>
          <div class="ng-autocomplete">
              <ng-autocomplete [data]="selectionArr" #airlineAC (inputCleared)="selectAccount($event)"
                [searchKeyword]="keyword" (inputChanged)='filterAirlineAccounts($event)'
                historyIdentifier="airlineArr" [itemTemplate]="itemTemplate" formControlName="selectedAirlineName"
                (selected)="selectAccount($event)" [notFoundTemplate]="notFoundTemplate" [disabled]="gstreportFilterForm.controls.viewMode.value == 'summary'">
  
              </ng-autocomplete>
              <ng-template #itemTemplate let-item>
                <a innerHTML='<span class="colr-1">{{item.name}}
                                  ({{item.account_code}})</span><br />
                              <span class="colr-2">{{item.primary_email}}</span><br />'>
                </a>
              </ng-template>
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
      </div>
      

       <!-- <div class="select-field text-input-fiel">
        <select class="input-field" searchtabindex="13" formControlName="invoice_type">
            <option value="flight">Flight</option>
            <option value="hotel">Hotel</option>
            <option value="visa">Visa</option>
            <option value="insurance">Insurance</option>
            <option value="non_account">GST</option>
        </select>
        <label for="subLedger">Invoice Type</label>

    </div> -->

      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
            searchtabindex="3" #searchButton (keyup.enter)="clickSearchButton()"
            [disabled]="gstreportFilterForm.invalid"
            (click)="search(gstreportFilterForm.value)">
            Search</button>
          <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
        </div>
      </div>
    </div>
  </div>
</form>