<form [formGroup]="airlineFilterForm">

  <div class="flexbox aff-info">
    <div class="flexbox aff-info-inner">

      <div class="flexBox d-flex">
        <div class="input-fiel form-group ng-field" searchtabindex="1" (focus)="focusClientAc()">
          <label class="ngauto-label" for="affname">Account</label>
          <div class="ng-autocomplete">
            <ng-autocomplete #clientAC [data]="accountArr" [searchKeyword]="keyword"
              (inputChanged)='getAccount($event,false)' historyIdentifier="accountArr" [itemTemplate]="itemTemplate"
              formControlName="account_name" (selected)="selectAccount($event,'account')"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                                ({{item.account_code}})</span><br />'>
              </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>

        <div class="input-fiel">
          <input class="input-ui" id="account_c" oninput="this.value = this.value.toUpperCase()" type="text"
            searchtabindex="2" formControlName="account_code" placeholder="Invoice Number" /><label
            for="account_c">Account Code</label>
        </div>
      </div>

      <div class="flexbox mt-3">
        <div class="input-fiel form-group ng-field" searchtabindex="3" (focus)="focusSubledgerAc()">
          <label class="ngauto-label" for="affname">Sub Ledger</label>
          <div class="ng-autocomplete">
            <ng-autocomplete [data]="parentAccount" #subledgerAC [searchKeyword]="keyword"
              (inputChanged)='getParentAccount($event,false)' historyIdentifier="parentAccount"
              [itemTemplate]="itemTemplate" formControlName="parent_account_name"
              (selected)="selectAccount($event,'parent_account')" [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                                ({{item.account_code}})</span><br />'>
              </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" searchtabindex="4" #searchButton
           data-dismiss="modal" (keyup.enter)="clickSearchButton()" (click)="search(airlineFilterForm.value)"> Search</button>
          <button type="button" id="resetSearchForm" class="btn-style bg-green">RESET</button>
        </div>
      </div>

    </div>

  </div>
</form>