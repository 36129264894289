import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { CommonServiceService } from '../../common-service.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';
@Component({
  selector: 'app-receipt-search',
  templateUrl: './receipt-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class ReceiptSearchComponent implements OnInit {

  modalRef: BsModalRef;
	receiptFilterForm: FormGroup;
	receiptArr: any;
	clientArr: any;
	keyword = 'name';
	fromAccount: any;
	toAccount: any;
	subLedger: any;

	@ViewChild('fraccountAC',{static:true}) fraccountAC;
	@ViewChild('toaccountAC',{static:true}) toaccountAC;
	@ViewChild('subledgerAC',{static:true}) subledgerAC;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;

	minDate = "2023-04-01"
    maxDate = moment().format('YYYY-MM-DD')
	constructor(
		private modalService: BsModalService, 
		private formBuilder: FormBuilder, 
		public commonService: CommonServiceService, 
		private spinner: NgxSpinnerService,
		private router: Router,
		private store: Store<InitialState>) {
			this.store.select('sharedReducers').subscribe((items)=>{
				if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				} else {
					let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
					if(!current_financial_year) {
						current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
					}
					this.minDate = current_financial_year.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				}
				
		})
	}

	ngOnInit() {

		// this.spinner.show();
		// setTimeout(() => {
		// 	this.spinner.hide();
		// }, 1000);
		this.receiptFilterForm = this.formBuilder.group({
      		from_account_name: [''],
      		from_account: [''],
			to_account_name: [''],
			to_account: [''],
			start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate,this.checkDate('end_date')],
			receipt_no: [''],
			from_amount: [''],
			to_amount: [''],
			narration: [''],
			sub_ledger_name: [''],
			sub_ledger:[''],
			active:['true']
		})
		this.setFormData()
	}

	checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}

	setFormData() {
		let queryParams = localStorage.getItem('activeReceiptQueryParams')
		debugger
		if (queryParams != null) {
			let parsedParams = JSON.parse(queryParams)
			// this.receiptFilterForm.controls.from_account.setValue(parsedParams.from_account)
			this.getFromAccount(parsedParams.from_account ? parsedParams.from_account : "",true)
		    this.getToAccount(parsedParams.to_account ? parsedParams.to_account : "",true)
		    this.getSubLedgerAccount(parsedParams.sub_ledger ? parsedParams.sub_ledger : "",true)
			
			this.receiptFilterForm.controls.start_date.setValue(moment(this.minDate).format('YYYY-MM-DD') )
			this.receiptFilterForm.controls.end_date.setValue(moment(this.maxDate).format('YYYY-MM-DD'))
			// this.receiptFilterForm.controls.to_account.setValue(parsedParams.to_account)
			this.receiptFilterForm.controls.receipt_no.setValue(parsedParams.receipt_no?parsedParams.receipt_no:"")
			this.receiptFilterForm.controls.from_amount.setValue(parsedParams.from_amount?parsedParams.from_amount:"")
			this.receiptFilterForm.controls.to_amount.setValue(parsedParams.to_amount?parsedParams.to_amount:"")
			this.receiptFilterForm.controls.narration.setValue(parsedParams.narration?parsedParams.narration:"")
			// this.receiptFilterForm.controls.sub_ledger.setValue(parsedParams.sub_ledger)
			this.receiptFilterForm.controls.active.setValue(parsedParams.active?parsedParams.active:'true')
		}
	}

	changeAccountStatus(e) {
		this.receiptFilterForm.get('active').setValue(e.target.value, {
			onlySelf: true
		})
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'customModal' })
		);
	}

	getFromAccount(value,bindValue=false) {
		if(!value) {
			this.receiptFilterForm.controls['from_account'].setValue('')
			this.receiptFilterForm.controls['from_account_name'].setValue('')
		}

		if (value.length > 2) {
			this.commonService.getOkount(`v3account/ledgerautocomplete?name=${value.toUpperCase()}`).subscribe(res => {
				this.fromAccount = res
				if(bindValue && this.fromAccount.length>0){
					this.receiptFilterForm.controls['from_account'].setValue(this.fromAccount[0].account_code)
					this.receiptFilterForm.controls['from_account_name'].setValue(this.fromAccount[0].name)
				}
			}, err => {
	
			})
		}
	}

	getToAccount(value,bindValue=false) {
		if(!value) {
			this.receiptFilterForm.controls['to_account'].setValue('')
			this.receiptFilterForm.controls['to_account_name'].setValue('')
		}
		if (value.length > 2) {
			this.commonService.getOkount(`v3account/ledgerautocomplete?name=${value.toUpperCase()}`).subscribe(res => {
				this.toAccount = res
				if(bindValue && this.toAccount.length>0){
					this.receiptFilterForm.controls['to_account'].setValue(this.toAccount[0].account_code)
					this.receiptFilterForm.controls['to_account_name'].setValue(this.toAccount[0].name)
				}
			}, err => {
	
			})
		}
	}

	getSubLedgerAccount(value,bindValue=false) {
		if(!value) {
			this.receiptFilterForm.controls['sub_ledger'].setValue('')
			this.receiptFilterForm.controls['sub_ledger_name'].setValue('')
		}
		if (value.length > 2) {
			this.commonService.getOkount(`v3account/ledgerautocomplete?name=${value.toUpperCase()}`).subscribe(res => {
				this.subLedger = res
				if(bindValue && this.subLedger.length>0){
					this.receiptFilterForm.controls['sub_ledger'].setValue(this.subLedger[0].account_code)
					this.receiptFilterForm.controls['sub_ledger_name'].setValue(this.subLedger[0].name)
				}
			}, err => {
	
			})
		}
	}
	
	reset() {
		GlobalVariable.openModalStatus = false;
		this.receiptFilterForm = this.formBuilder.group({
			from_account_name: [''],
			from_account:[''],
			to_account_name: [''],
			to_account:[''],
			start_date: [this.minDate],
			end_date: [this.maxDate],
			receipt_no: [''],
			from_amount: [''],
			to_amount: [''],
			narration: [''],
			sub_ledger_name: [''],
			sub_ledger:['']
	  })
	  localStorage.removeItem('activeReceiptQueryParams')
	  }

	search(values){
		GlobalVariable.openModalStatus = false;
		if ((values.start_date == null || values.start_date == "" || values.start_date == "Invalid date" || values.start_date == "Invalid date")){
			values.start_date = ""
		}else{
			values.start_date = moment(values.start_date).format('YYYY-MM-DD')
		}
		
		if(values.end_date == null || values.end_date == "" || values.end_date == "Invalid date" || values.end_date == "Invalid date"){
			values.end_date = ""
		}
		else{
			values.end_date = moment(values.end_date).format('YYYY-MM-DD')
		}

		let fromAccount= values.from_account
		if (values.from_account.account_code != undefined) {
			fromAccount= values.from_account.account_code
		} 
		let toAccount= values.to_account
		if (values.to_account.account_code != undefined) {
			toAccount= values.to_account.account_code
		} 
		let subLedger= values.sub_ledger
		if (values.sub_ledger.account_code != undefined) {
			subLedger= values.sub_ledger.account_code
		} 
		let active = values.active
		this.router.navigateByUrl(`/transactions/receipt/view?from_account=${fromAccount}&to_account=${toAccount}&start_date=${values.start_date}&end_date=${values.end_date}&receipt_no=${values.receipt_no?values.receipt_no:''}&from_amount=${values.from_amount?values.from_amount:''}&to_amount=${values.to_amount?values.to_amount:''}&narration=${values.narration}&sub_ledger=${subLedger}&active=${active}`)
		// this.reset()
	}

	ngAfterViewInit(){
		this.commonService.selectedSearchInput.subscribe(index=>{
			if (index == 1) {
				this.focusfrAccountAc()
			}

			if(index == 2){
				this.focustoAccountAc()
			}

			if(index == 6){
				this.focusSubledgerAc()
			}
		})
	}

	selectAccount(value,formControlName){
		try {
			this.receiptFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}

	focusfrAccountAc(): void {
		this.fraccountAC.focus();
	}

	focustoAccountAc(): void {
		this.toaccountAC.focus();
	}

	focusSubledgerAc(){
		this.subledgerAC.focus();
	}

	clickSearchButton(){
		this.searchButton.nativeElement.click()
	}
	clearData(formControl){
		this.receiptFilterForm.controls[formControl].setValue("")
	}
}
