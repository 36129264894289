import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConsolidateInvoiceRoutingModule } from './consolidate-invoice-routing.module';
import { ConsolidateInvoiceComponent } from './consolidate-invoice.component'; 
import { ConsolidateFareSummaryComponent } from './consolidate-fare-summary/consolidate-fare-summary.component';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
//import { TourService } from './services/tour.service';
// import { PrintTourInvoiceComponent } from './print-tour-invoice/print-tour-invoice.component';


@NgModule({
  declarations: [ConsolidateInvoiceComponent,ConsolidateFareSummaryComponent],
  imports: [
    CommonModule,
    ConsolidateInvoiceRoutingModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    FormsModule, 
    ReactiveFormsModule,
    KeyboardShortcutsModule.forRoot()
  ],
  exports: [ConsolidateInvoiceComponent],
  providers : [CommonServiceService]
})
export class ConsolidateInvoiceModule { }
