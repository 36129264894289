import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';

import * as moment from 'moment-timezone';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { DownloadService } from 'src/app/shared/download.service';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../transactions/store/actions';
import { Tab } from 'src/app/shared/model/tab.model';
import { TabService } from 'src/app/shared/services/tab.service';
import { PsrDetailedReportComponent } from '../psr-detailed-report/psr-detailed-report.component';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-psr-report',
	templateUrl: './psr-report.component.html',
	styleUrls: ['./psr-report.component.scss']
})
export class PsrReportComponent implements OnInit {
	isPrint:any;
	fromRoot = true;
	infiniteSelector: string = '.main-panel';
	fromIndex: any = 0;
	dataLimit: any = 100;
	toIndex: any = 30;
	allInvoiceArr: any;
	showPrintData: boolean = false;
	invoiceFilterForm: FormGroup;
	innerHeight: string;
	invoiceArr: any;
	keyword = 'name';
	navigationSubscription
	reInitialise = false;
	p;
	valid: boolean;
	isAdvancedSearchOpen: boolean = false;
	currentIndex = 0
	@ViewChild('tableHeader', { static: true }) tableHeader: ElementRef
	advancedSearchSubscription: Subscription;
	queryData: any;
	companyDetail: any;
	currentDate = new Date()
	duration: any;

	totalAmountObj: any;
	queryParams: any;
	loader: boolean = false;
	onSearch;
	onSearchFilter = new Subject<string>();

	constructor(
		private formBuilder: FormBuilder,
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private route: ActivatedRoute,
		private router: Router,
		private downloadService: DownloadService,
		private store: Store<InitialState>,
		private tabService: TabService
	) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	}

	ngOnInit() {
		this.spinner.show();
		setTimeout(() => {
			this.spinner.hide();
		}, 1000);
		let queryParams = this.route.queryParams['value']
		this.queryParams = queryParams;
		this.queryData = Object.assign({}, queryParams)
		this.search()
		localStorage.setItem('activeInvoiceQueryParams', JSON.stringify(queryParams))

		this.onSearchFilter.pipe(
			debounceTime(300),
			distinctUntilChanged())
			.subscribe(value => {
				this.filterData(value)
			});
			
		this.reInitialise = true
	}

	search() {
		let url = `${environment.okountUrl}/reports/reconciliation/psr`
		this.loader = true;
		this.commonService.getRequestWithQueryParamsNoLoader(`${url}`, this.queryData).subscribe(res => {
			this.loader = false;
			this.companyDetail = res['company_details']
			this.allInvoiceArr = res['data']
			this.invoiceArr = res['data']
			this.duration = res['duration']
			this.valid = true
			this.totalAmountObj = this.getAmount(res['data'])
			this.setHeight(window.innerHeight)
		})
	}

	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}

	ngAfterViewInit() {
		this.advancedSearchSubscription = this.commonService.advancedSearch.subscribe(status => {
			console.log('Is modal opened : ', status)
			// this.isAdvancedSearchOpen = status
		})
	}
	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
		if (this.advancedSearchSubscription) {
			this.advancedSearchSubscription.unsubscribe()
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setHeight(event.target.innerHeight)
	}

	getData() {
		this.commonService.getOkount(`v3/view-all-invoice`).subscribe(res => {
			this.invoiceArr = res
			this.focusOnTableHeader()
			this.currentIndex = 0
			this.valid = true
			this.setHeight(window.innerHeight)
		}, err => {
			console.log('Error  : ', err)
		})
	}
	
	async selectEvent(data) {
		// let ledgerDetails = await this.getLedgerDetails(data.ledger_id)
		if (data.hasOwnProperty('id')) {
			let billingAddress = data.address_1 + " " + data.address_2 + " " + data.address_3;
			this.invoiceFilterForm.controls.client.setValue(billingAddress ? billingAddress : '')
		}
	}
	// openInvoice(invoice_number){

	// 	// invoice/:mode/:invoice
	// 	window.open(`/transactions/invoice-view;searchId=${invoice_number};type=flight`, "_blank");
	// 	window.open(`/transactions/invoice-view;searchId=${invoice_number};type=flight`, "_blank");
	// }

	setHeight(height) {
		
		if (this.invoiceArr != undefined) {
			if (height == undefined) {
				height = window.innerHeight
			}
			if (this.invoiceArr.length <= 20) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = height - 210 + 'px';
			}
		}
	}

	focusOnTableHeader() {
		if (this.tableHeader != undefined) {
			this.tableHeader.nativeElement.focus()
		}
	}

	// @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext() {
		if (document.getElementById(`rowIndex_${this.currentIndex + 1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex + 1}`).focus()
			this.currentIndex = this.currentIndex + 1
		}
	}
	moveToPrev() {
		if (document.getElementById(`rowIndex_${this.currentIndex - 1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex - 1}`).focus()
			this.currentIndex = this.currentIndex - 1
		}
	}

	moveToIndex(i) {
		document.getElementById(`rowIndex_${i + 1}`).focus()
		this.currentIndex = i + 1
	}

	exportAsXLSX(): void {
		this.spinner.show()
		let xlsxData = [];
		this.invoiceArr.forEach(data => {
			let obj = {};
			obj["AIRLINE DETAIL"] = data.account_name
			obj["SALES"] = Number(data.sales)
			obj["REFUNDS"] = Number(data.refunds)
			obj["ADM"] = Number(data.adm)
			obj["ACM"] = Number(data.acm)
			obj["NET PAYABLE"] = Number(data.net_payable)
			obj["TDS"] = Number(data.tds_amount ? data.tds_amount : 0)
			xlsxData.push(obj);
		});
		let obj1 = {};
			obj1["AIRLINE DETAIL"] = ""
			obj1["SALES"] = 0
			obj1["REFUNDS"] = 0
			obj1["ADM"] = 0
			obj1["ACM"] = 0
			obj1["NET PAYABLE"] = 0
			obj1["TDS"] = 0
			xlsxData.push(obj1);
		let obj = {}
			obj["AIRLINE DETAIL"] = ""
			obj["SALES"] = Number(this.totalAmountObj['sales'])
			obj["REFUNDS"] = Number(this.totalAmountObj['refunds'])
			obj["ADM"] = Number(this.totalAmountObj['adm'])
			obj["ACM"] = Number(this.totalAmountObj['acm'])
			obj["NET PAYABLE"] = Number(this.totalAmountObj['net_payable'])
			obj["TDS"] = Number(this.totalAmountObj['tds_amount'])
			xlsxData.push(obj);

		this.downloadService.exportAsXLSX(`PSR Report :`, xlsxData);
		this.spinner.hide()
	}

	downloadPdf(start_date, end_date) {
		this.commonService.downloadPDF(`${environment.okountUrl}/reports/reconciliation/psr/download`, this.queryParams).subscribe((fileBlob:any) =>{
			let fileName = `PSR REPORT FROM ${start_date} TO ${end_date}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}

	callPdfGeneratorApi() {
		this.showPrintData = true
		setTimeout(() => {
			window.print()
			this.showPrintData = false
		}, 100);
	}


	getAmount(data) {
		let amountObj = {
			sales: 0,
			refund: 0,
			adm: 0,
			acm: 0,
			net_payable: 0,
			tds_amount: 0,
		}
		let totalSales = 0
		let totalRefunds = 0
		let totalAdm = 0
		let totalAcm = 0
		let totalNetPayable = 0
		let totalTdsAmount = 0
		if (data != undefined) {
			data.forEach(element => {
				totalSales += Number(element['sales'])
				totalRefunds += Number(element['refunds'])
				totalAdm += Number(element['adm'])
				totalAcm += Number(element['acm'])
				totalNetPayable += Number(element['net_payable'])
				totalTdsAmount += Number(element['tds_amount'] ? element['tds_amount'] : 0)
			});
			amountObj['sales'] = totalSales
			amountObj['refunds'] = totalRefunds
			amountObj['adm'] = totalAdm
			amountObj['acm'] = totalAcm
			amountObj['net_payable'] = totalNetPayable
			amountObj['tds_amount'] = totalTdsAmount
			return amountObj
		}
	}

	openDetailedPsr(airlineCode){
		if(airlineCode) {
			console.log(airlineCode,this.queryData.start_date ,this.queryData.end_date );
			
			this.tabService.addTab(
				new Tab(PsrDetailedReportComponent, `PSR-${airlineCode}`, { airlineCode: airlineCode, start_date: this.queryData.start_date, end_date: this.queryData.end_date }, `PSR-${airlineCode}`)
				);
		} else {
			let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/report/psr-detailed-report?start_date=${this.queryData.start_date}&end_date=${this.queryData.end_date}&airline_code=${airlineCode}`
		    window.open(path)
		}
	}

	openAdvanceSearchModal(){
		this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'psr-report'}));	
	}

	filterData(value) {
		let tempArr = Object.assign([], this.allInvoiceArr)
		if(value.length > 3){
			let lowerCaseFilter = value.toLowerCase()
			let upperCaseFilter = value.toUpperCase()
			
			this.invoiceArr = tempArr.filter(
				clientData => {
					if (	
							(
								clientData.account_name != undefined && 
								clientData.account_name != null && 
								(	
									clientData.account_name.includes(value) || 
									clientData.account_name.includes(upperCaseFilter) ||
									clientData.account_name.includes(lowerCaseFilter)
								)
							) ||
							(
								clientData.acm != undefined && 
								clientData.acm != null && 
								(	
									clientData.acm.toString().includes(value) || 
									clientData.acm.toString().includes(upperCaseFilter) ||
									clientData.acm.toString().includes(lowerCaseFilter)
								)
							) ||
							(
								clientData.adm != undefined && 
								clientData.adm != null && 
								(	
									clientData.adm.toString().includes(value) || 
									clientData.adm.toString().includes(upperCaseFilter) ||
									clientData.adm.toString().includes(lowerCaseFilter)
								)
							) || 
							(
								clientData.sales != undefined && 
								clientData.sales != null && 
								(	
									clientData.sales.toString().includes(value) || 
									clientData.sales.toString().includes(upperCaseFilter) ||
									clientData.sales.toString().includes(lowerCaseFilter)
								)
							) ||
							(
								clientData.refunds != undefined && 
								clientData.refunds != null && 
								(	
									clientData.refunds.toString().includes(value) || 
									clientData.refunds.toString().includes(upperCaseFilter) ||
									clientData.refunds.toString().includes(lowerCaseFilter)
								)
							) ||
							(
								clientData.net_payable != undefined && 
								clientData.net_payable != null && 
								(	
									clientData.net_payable.toString().includes(value) || 
									clientData.net_payable.toString().includes(upperCaseFilter) ||
									clientData.net_payable.toString().includes(lowerCaseFilter)
								)
							)
						) {
						return clientData
					}
				}
			)
		}else{
			this.invoiceArr = [];
			for (let i = 0; i <= this.allInvoiceArr.length; i++) {
				const element = this.allInvoiceArr[i];
				if (element != undefined) {
					this.invoiceArr.push(element)
				}
			}
		}
		

	}

}
