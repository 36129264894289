<div class="ledger-container balancesheet-page" *ngIf="valid">
  <div class="logo-wrapper" *ngIf="showWaterMark && waterMark"><div id="waterMark" [ngStyle]="{background: waterMark}"></div></div>
  <div class="row tab-nav pl-0 pr-0">
    <div class="col-sm-4 ">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
        <li [routerLink]="['/']"
          class="text-grey text-capitalize cursor  hover-text-primary transition"> Analysis</li>
        <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
        <li class="text-grey text-capitalize cursor text-primary transition" *ngIf="reportType == 'balance_sheet'"> Balance Sheet</li>
        <li class="text-grey text-capitalize cursor text-primary transition" *ngIf="reportType == 'profit_loss'"> Profit &amp; Loss</li>
      </ul>
    </div>
    <div class="col-sm-4 text-center">
      <span class="rec-date d-block text-center" *ngIf="duration">
          <div class="noWrap justify-content-around">
              <ng-container >
                  <div class="cname text-bold"><span *ngIf="duration?.from" class="text-bold">From</span>
                      <h5 style="margin-right: 5px;" class="mb-0  d-inline">
                          <span class="date text-bold text-orange" *ngIf="duration?.from"
                              >
                              {{duration?.from}}
                          </span>
                          <span class="date text-bold" *ngIf="duration?.from">
                              To
                          </span>
                          <span class="date text-bold text-orange">
                              {{duration?.to}}
                          </span>
                      </h5>
                      <button title="Custom Search" class="btn   save-as text-bold bg-whiteSmoke " 
                        type="button"  (click)="openAdvanceSearchModal()">
                          <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
                      </button>
                  </div>
              </ng-container>								
          </div>
      </span>
  </div>
  </div>

  <div class="page-sub-header align-item-center  d-flex w-100 mb-1 justify-content-between mt-3 pl-15 pr-15">
    <div class="heading-box text-left mb-0 d-block">
      <span class="title ml-0 text-primary">
        {{balanceSheetData?.accounting_company?.name}}
      </span>
      <!-- <small class="d-block">Amin Marg, Rajkot, 360-005</small> -->
      <div class="curr" style="font-size: 12px;"><span class="label-span">Currency : </span><span class="det-info">INR
          (&#8377;)</span></div>
          <h5 class="  d-none">
            <span class="date text-bold text-black" *ngIf="duration?.from"
                >
                {{duration?.from}}
            </span>
            <span class="date text-bold" *ngIf="duration?.from">
                To
            </span>
            <span class="date text-bold text-black">
                {{duration?.to}}
            </span>
        </h5>
    </div>
    <div class="text-center print-d-none " style="margin-right: 74px;">
      <h5 class="text-black m-0" *ngIf="reportType == 'balance_sheet'">Balance Sheet
      </h5>
      <h5 class="text-black m-0" *ngIf="reportType == 'profit_loss'">Profit &amp; Loss
      </h5>
    </div>
    <div class="action  ">
      <button type="button" (click)="print()" class="text-bold btn bg-whiteSmoke text-green mr-1"> <i
          class="fa fa-print cursor "></i> Print</button>
          <button type="button" (click)="download()" class="text-bold btn bg-whiteSmoke text-blue mr-1"> <i
            class="fa fa-print cursor "></i> Download Excel</button>
    </div>
  </div>
  <div *ngIf="columnlayout" class="bal-balance-sheet table-section pl-15 pr-15 column-view">
    <table class="w-100  table-border">
      <thead>
        <tr style="display: table; width: 100%;">
          <ng-container *ngIf="reportType == 'balance_sheet'">
            <th class="text-left text-bold page-top-space" style="width: 50%;background:#4880af;color: #ffff;">ASSESTS</th>
            <th class="text-left text-bold page-top-space pl-15" style="width: 50%;background:#4880af;color: #ffff;">LIABILITIES</th>
          </ng-container>
          <ng-container *ngIf="reportType == 'profit_loss'">
            <th class="text-left text-bold page-top-space income-color" style="width: 50%;background:#4880af;color: #ffff;">PARTICULARS</th>
            <th class="text-left text-bold page-top-space expense-color pl-15" style="width: 50%;background:#4880af;color: #ffff;">PARTICULARS</th>
          </ng-container>
        </tr>
      </thead>
      <tbody class="main-balancesheet-body" (window:keydown)="onKeyDown($event)">
        <tr>
          <td style="width:50%;vertical-align:top!important;">
            <table class="w-100 " style="border-bottom: 0;">
              <tbody class="scrollable-tbody">
                <tr>
                  <td style="vertical-align: top!important;">
                   <table class="w-100per">
                       <tbody>
                         <ng-container *ngFor="let assets of balanceSheetData['assets']['account']; let i = index; ">
                         <tr class="bal-block-item bal-row-item bal-shadow" id="left_{{i}}">
                           <td class="bal-border-bottom pl-15 pr-10 pb-10">
                              <div [attr.tabIndex]="i+1" class="sub-head d-flex align-item-center justify-content-between account-name-div" 
                              [ngClass]="{'pl-15': false }"
                                (click)="openDetailedScreen(assets, assets.account,'left_'+i)"
                                (keyup.enter)="openDetailedScreen(assets, assets.account,'left_'+i)">
                              <span class="text-bold" >
                                <span style="font-weight: 500;display: inline-block;">
                                  <ng-container >
                                    <i class="fas fa-plus-square mr-1" *ngIf="!assets.showData && assets.hierarchy <=1"></i>
                                    <i class="far fa-minus-square mr-1" *ngIf="assets.showData && assets.hierarchy <=1"></i>
                                  </ng-container>
                                  {{assets?.name}} 
                                </span>
                              </span>
                              <span class="amt-column text-bold text-right">{{assets?.balance | number : '1.2-2'}}</span>
                            </div>
                            <ng-container 
                                *ngTemplateOutlet="dataTemplate;context: {'data':assets,index:i,side:'left_'+i}">
                            </ng-container>
                          </td>
                        </tr>
                      </ng-container>
                       </tbody>
                   </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td style="width:50%;vertical-align:top!important;">
            <table class="w-100  border-left" style="border-bottom: 0;">
              <tbody class="scrollable-tbody ">
                <ng-container *ngFor="let liabilities of balanceSheetData['liabilities']['account'];  let i = index; ">
                <tr class="bal-block-item bal-row-item bal-shadow" id="right_{{i}}" >
                  <td class="bal-border-bottom pl-15 pr-10 pb-10">
                      <div [attr.tabIndex]="i+1" class="sub-head d-flex align-item-center justify-content-between account-name-div"
                        [ngClass]="{'pl-15': false }"
                        (keyup.enter)="openDetailedScreen(liabilities, liabilities.account,'right_'+i)"
                        (click)="openDetailedScreen(liabilities, liabilities.account,'right_'+i)">
                        <span class="text-bold">
                          <span style="font-weight: 500;display: inline-block;">
                            <ng-container >
                              <i class="fas fa-plus-square mr-1" *ngIf="!liabilities.showData && liabilities.hierarchy <=1"></i>
                              <i class="far fa-minus-square mr-1" *ngIf="liabilities.showData && liabilities.hierarchy <=1"></i>
                            </ng-container>
                            {{liabilities?.name}}
                          </span>
                        </span>
                        <span class="amt-column text-bold text-right">{{liabilities?.balance | number : '1.2-2'}}</span>
                      </div>
                      <ng-container 
                         *ngTemplateOutlet="dataTemplate;context: {'data':liabilities,index:i,side:'right_'+i}">
                      </ng-container>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
             
            </table>
          </td>
        </tr>
          <tr class="d-none">
            <td style="vertical-align: top!important;">
              <div class="sub-total-col d-flex justify-content-between">
                <div class="alignLeft d-flex">
                  <div class="amt-total text-bold text-black">Total</div>
                </div>
                <div class="alignRight d-flex">
                  <div class="amt-total text-bold text-black">{{balanceSheetData?.assets?.balance | number :
                    '1.2-2'}}</div>
                </div>
              </div>
            </td>
              <td>
                <div class="sub-total-col d-flex justify-content-between">
                  <div class="alignRight d-flex">
                    <div class="amt-total text-bold text-black">Total</div>
                  </div>
                  <div class="alignRight d-flex">
                    <div class="amt-total text-bold text-black">{{balanceSheetData?.liabilities?.balance | number :
                      '1.2-2'}}</div>
                  </div>
                </div>
              </td>
          </tr>
      </tbody>
      <tfoot class="bg-white">
        <tr>
          <td style="width: 50%;vertical-align: top!important;" >
            <div class="sub-total-col d-flex justify-content-between">
              <div class="alignLeft d-flex ">
                <div class="amt-total text-bold text-black">Total</div>
              </div>
              <div class="alignRight d-flex ">
                <div class="amt-total text-bold text-black">{{balanceSheetData?.assets?.balance | number :
                  '1.2-2'}}</div>
              </div>
            </div>
          </td>
            <td style="width: 50%;vertical-align: top!important;"  >
              <div class="sub-total-col d-flex justify-content-between">
                <div class="alignRight d-flex ">
                  <div class="amt-total text-bold text-black">Total</div>
                </div>
                <div class="alignRight d-flex">
                  <div class="amt-total text-bold text-black">{{balanceSheetData?.liabilities?.balance | number :
                    '1.2-2'}}</div>
                </div>
              </div>
            </td>
        </tr>
        <tr *ngIf="reportType == 'profit_loss'">
          <td style="width: 50%;vertical-align: top!important;" >
          </td>
            <td style="width: 50%; vertical-align: top!important;"  >
              <div class="d-flex pl-txt text-bold align-item-center justify-content-center" style="font-size: 14px;">
                <span>
                  <span>Net</span>
                  <span class="profit ml-1 mr-1" *ngIf="balanceSheetData.net.type == 'PROFIT'"> Profit </span>
                  <span class="loss ml-1 mr-1" *ngIf="balanceSheetData.net.type == 'LOSS'"> Loss </span>
                  for the year
                </span>
                <span class="pl-amount ml-5" style="font-weight: 600; color: #000;">{{balanceSheetData?.net?.balance | number : '1.2-2'}}</span>
              </div>
            </td>
        </tr>
      </tfoot>   
    </table>
  </div>
</div>

<ng-template #dataTemplate let-data="data" let-index="index" let-side="side">
  <ng-container *ngIf="data['account'].length > 0 && data.showData">
  <table class=" w-100">
    <tbody>
        <tr  *ngFor="let child of data['account'] let i = index;" id="{{side}}_{{i}}" >
          <td class="p-0">
          <table class="sub-info-col table w-100">
            <thead>
            </thead>
            <tbody>
              <tr class="bal-block-item account-name-div" [attr.tabIndex]="i+1"  (keyup.enter)="openDetailedScreen(child, child.account,side+'_'+i)" (click)="openDetailedScreen(child, child.account,side+'_'+i)">
                <td>
                  <span class="list-two">
                    <i class="fas fa-plus-square mr-1" *ngIf="!child.showData && child.hierarchy <=1"></i>
                    <i class="far fa-minus-square mr-1" *ngIf="child.showData && child.hierarchy <=1"></i>
                    {{child?.name}}  
                  </span>                
                </td>
                <td class="list-two amt-column p-0">{{child?.balance | number : '1.2-2'}}</td>
                <td class="amt-column p-0"></td>
              </tr>
              <tr class="bal-block-item"  *ngIf="child.hasChild">
                <td colspan="2" style="padding-left: 15px;" class="pr-0">
                  <ng-container>
                    <table class="table sub-child w-100" >
                     <tbody>
                      <ng-container 
                      *ngTemplateOutlet="dataTemplate;context: {'data':child,index:i,'side':side+'_'+i}">
                    </ng-container>
                     </tbody>
                    </table>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
          </td>
        </tr>
    </tbody>
  </table>
  </ng-container>
  <!-- <ng-container *ngIf="data['account'].length == 0">
    <div class="sub-info-col d-flex justify-content-between">
      <div class="head-info-left"></div>
      <div class="alignRight d-flex">
        <div class="amt-right">
          <ng-container *ngIf="data.balance != null else zeroAmount">
            {{data?.balance | number : '1.2-2'}}
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container> -->
</ng-template>

<ng-template #zeroAmount>{{0 | number : '1.2-2'}}</ng-template>