<div class="ledger-container pb-20">
  <!-- <div class="tab-nav d-flex align-item-center justify-content-between">
    <ul class="list-inline list-unstyled p-0 m-0">
      <li class="active page-title   text-uppercase"> Client</li>
    </ul>
    <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
      <li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition"> Dashboard
      </li>
      <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
      <li class="text-grey text-capitalize cursor text-primary transition"> View Client</li>
    </ul>
  </div> -->
  <div class="row tab-nav pl-0 pr-0  align-item-center">
    <!-- <div class="col-sm-4 pl-0">
        <ul class="list-inline list-unstyled p-0 m-0">
            <li class="active page-title text-uppercase cursor"> View Client</li>
        </ul>
    </div> -->
    <div class="col-sm-4 ">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
          <li [routerLink]="['/']"
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Master</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li [routerLink]="['/']"
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Client</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li class="text-grey text-capitalize cursor text-primary transition"> View Client</li>
      </ul>
  </div>
    <div class="col-sm-4 text-center">
        <span class="rec-date d-block text-center">
            <div class="noWrap justify-content-around">
              <ng-container >
                <div class="cname text-bold"><span class="text-bold">Displaying all records</span>
                   
                    <button title="Custom Search" class="btn   save-as text-bold bg-whiteSmoke " 
    type="button" (click)="openAdvanceSearchModal()" >
    <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
</button>
                </div>
            </ng-container>								
            </div>
        </span>
    </div>
    <div class="col-sm-4  d-flex align-item-center justify-content-end">
      <div class="page-search-box input-group  d-flex align-item-center"
      style="height: fit-content; text-align: left;">
      <input type="text" class="form-control" [(ngModel)]="onSearch" oninput="this.value = this.value.toUpperCase()"
        (ngModelChange)="this.onSearchFilter.next($event)" placeholder="search" style=" text-align: left" />
      <!-- <div class="save-button">
              <button title="Search Receipts..."
                  class="btn mr-1  save-as text-bold bg-whiteSmoke text-green dropdown-toggle" type="button"
                  (click)="openModal()">
                  <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/purple-magnifying-glass.svg" alt="">

              </button>

          </div> -->
         <!-- <li _ngcontent-qvt-c8="" class="excel" (click)="
         exportClientAsXLSX(
         )" ><i _ngcontent-qvt-c8="" aria-hidden="true" class="fa fa-file-excel-o fa-excel"></i></li> -->
        
    </div>
    <div class="dropdown-btn position-relative" >
      <button title="More..." class="btn-style mt-0 more-btn">
        <span class="dot"></span><span class="dot"></span
        ><span class="dot"></span>
      </button>
      <ul class="list-unstyled pl-0 dropdown-box">
        <li (click)="
        exportClientAsXLSX(
        )">
          <i   aria-hidden="true" class="fa fa-file-excel-o fa-excel"></i>
          <span class="ml-2">Excel File</span>

  <button
  hidden
  #openEmailModal
  data-target="#emailModal"
  data-toggle="modal"
></button>
        </li>
  
      </ul>
    </div>
    </div>
</div>

  <!-- <div class="heading-box" style="margin-bottom: 10px;">
    <div class="heading">View Client</div>
    <span class="hr-line"></span>
  </div>
  <div class="row mb-1 justify-content-end mr-1 local-search">
    <div id="custom-search-input">
      <div class="input-group col-md-12" style="height: fit-content">
        <input type="text" class="form-control" [(ngModel)]="onSearch" oninput="this.value = this.value.toUpperCase()"
          (ngModelChange)="this.onSearchFilter.next($event)" placeholder="search"
          style="margin: 2px; text-align: left" />
      </div>
    </div>
  </div> -->
  <div class="table-container" (window:resize)="onResize($event)">
    <div class="table " style="    margin-top: -5px;">
      
     

      <nz-table #clientData [nzData]="viewClient"
                [nzLoading]="loader"
                [nzFrontPagination]="false"
            >

            <thead>
              <tr #tableHeader tabIndex="0">
                <th style="max-width: 270px;" class="text-left">Account</th>
                <th style="max-width: 120px;" class="text-left">Account Code</th>
                <th style="max-width: 190px;" class="text-left">Email</th>
                <th style="max-width: 100px;" class="text-left">Mobile</th>
                <th class="text-left">Address</th>
                <th style="max-width: 70px;" class="text-right" style="max-width: 106px;">Action</th>
              </tr>
            </thead>

            <ng-container *ngIf="clientData && clientData.data && clientData.data.length>0 else noResult">
              <tbody 
                [ngStyle]="{height: innerHeight}" 
                class="main-panel" 
                infiniteScroll 
                (window:keydown)="onKeyDown($event)"
                [fromRoot]="fromRoot" 
                [infiniteScrollDistance]="1" 
                [infiniteScrollUpDistance]="1"
                [infiniteScrollThrottle]="200" 
                [infiniteScrollContainer]="selector" 
                (scrolled)="onScrollDown($event)"
                (scrolledUp)="onScrollUp($event)">
                <ng-container *ngFor="let item of clientData['data'] ; let i = index">
                  <tr  class="field-table-input custom-table-row" 
                  [attr.tabIndex]="i+1" 
                  id="rowIndex_{{i+1}}"
                  (keyup.enter)="open_client(item.id, item.account_code, item.name)">
                  <td style="max-width: 270px;" class="text-left text-grey refer font-500" (click)="moveToIndex(i)">
                  
                    <div class="d-flex flex-column">
                      <span class="font-500">{{item?.name}}</span>
                      <span class="font-500" *ngIf=" item && item.contact_person">( {{item?.contact_person}} )</span>
                    </div>
                  </td>

                  <td style="    max-width: 120px;" class="text-left text-grey " (click)="moveToIndex(i)">
                    {{item?.account_code}}</td>
                  <td style="word-wrap: break-word;    max-width: 190px;" class="text-left text-grey "
                    (click)="moveToIndex(i)">
                    {{item?.primary_email}}</td>
                  <td style="    max-width: 100px;" class="text-left text-grey " (click)="moveToIndex(i)">
                    {{item?.mobile_no}}</td>
                  <td style="word-wrap: break-word; " class="text-left text-grey " (click)="moveToIndex(i)">
                    {{item?.address_1}}</td>

                  <td style="max-width: 70px;" class="text-right   ">
                    <div class="d-flex align-item-center justify-content-end">
                      <button title="Edit" class=" custom-table-btn bg-white border-0 p-0 text-bold mr-2 hoverableShadow transition cursor" type="button"
                        (click)="open_client(item.id, item.account_code, item.name)">
                        <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-edit-btn.svg" alt="" width="20px">
                      </button>
                      <button  title="Edit Opening Balance" class="custom-table-btn bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button"
                        (click)="openConfirmModal(item)">
                        <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-opening-balance.svg" alt="" width="20px">
                        
                      </button>
                    </div>
                    
                  </td>
                  </tr>
                </ng-container>
              </tbody>
            </ng-container>

            <ng-template #noResult>
              <div *ngIf="!loader" class="w-100 text-center" style="padding: 20px;">
                  <nz-empty nzNotFoundContent="No result Found"></nz-empty>
              </div>
          </ng-template>

          </nz-table>
    </div>
  </div>
</div>

<button hidden #confirmModal data-toggle="modal" data-target="#confirmationModal"></button>
<div class="modal fade" id="confirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding: 7px 21px;">
        <span style="font-weight: 600; font-size: 16px;" class="text-primary">Opening Balance</span>
        <button type="button" class="close " #closeModal data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="balanceForm" *ngIf="balanceForm">
        <div class="modal-body">
          <ng-container *ngFor="let openbalanceform of balanceForm['controls']['cash']['controls']">
            <div [formGroup]="openbalanceform">
              <ng-container *ngIf="!isTenantIndian">
              <div class="text-input-group d-flex justify-content-between">

                <div class="text-input-field mr-2" >
                  <select id="selectscroll" class="input-field" tabIndex="1" formControlName="currency" (change)="currencyChange($event.target.value)"  >
                    <option value="INR">INR</option>
                    <option value="CAD">CAD</option>
                      <option value="USD">USD</option>
                      <option value="AED">AED</option>
                      <option value="EURO">EURO</option>
                  </select>
                  <label>CURRENCY</label>
                </div>
                <div class="text-input-field mr-2">
                  <input class="input-field"  oninput="this.value = this.value.toUpperCase()"
                    name="currency"  formControlName="currency_rate" type="text" maxlength="10"
                    [ngClass]="{'error-field': openbalanceform.controls.currency_rate.invalid && openbalanceform.controls.currency_rate.dirty,
                'success-field' : openbalanceform.controls.currency_rate.valid && openbalanceform.controls.currency_rate.dirty}" />
                  <label
                    [ngClass]="{'error-label': openbalanceform.controls.currency_rate.invalid && openbalanceform.controls.currency_rate.dirty}">CURRENCY RATE<ng-container *ngIf="openbalanceform.controls.currency_rate.invalid && balanceForm.controls.currency_rate.dirty">
                      <i class="fa fa-info-circle ml-2" title="Invalid pan number" aria-hidden="true"></i>
                    </ng-container>
                  </label>
                </div>
                
                  <div class="text-input-field" >
                    <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="local_balance" tabIndex="13"
                  formControlName="local_balance"  type="text" [ngClass]="{'error-field': openbalanceform.controls.local_balance.invalid && openbalanceform.controls.local_balance.dirty,
                'success-field' : openbalanceform.controls.local_balance.valid && openbalanceform.controls.local_balance.dirty}" (input)="changeCurrentAmount($event)" />
                <label
                  [ngClass]="{'error-label': openbalanceform.controls.local_balance.invalid && openbalanceform.controls.local_balance.dirty}">AMOUNT
                  <ng-container *ngIf="openbalanceform.controls.local_balance.invalid && openbalanceform.controls.local_balance.dirty">
                    <i class="fa fa-info-circle ml-2" title="Invalid amount" aria-hidden="true"></i>
                  </ng-container>
                </label>
                  </div>
                </div>
              </ng-container>
                
                <div class="text-input-group d-flex justify-content-between">
                <div class="text-input-field mr-2">
                  <input class="input-field" id="balance"  name="balance" [readonly]="!isTenantIndian"
                    formControlName="balance"  type="number" min="0" />
                  <label for="balance">Balance
                  </label>
                </div>
                <div style="width: 100px;" class="select-field text-input-field mr-2">
                  <select class="input-field" formControlName="balance_type" style="padding: 2px;">
                    <option value="debit">DR</option>
                    <option value="credit">CR</option>
                  </select>
                </div>
                <div class="text-input-field mr-2">
                  <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="balance"
                    formControlName="account_code" type="text" disabled />
                  <label> Account
                  </label>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="showEditOptions">
            <span class="fail-notify" *ngIf="notUpdated == true "><i class="far fa-times-circle mr-2"></i>Failed to
              Update!</span>
            <span class="success-notify" *ngIf="isUpdated == true"><i class="far fa-check-circle mr-2"></i>Opening balance
              updated successfully!</span>
  
            <span class="fail-notify" *ngIf="notDeleted == true "><i class="far fa-times-circle mr-2"></i>Failed to
              Delete!</span>
            <span class="success-notify" *ngIf="isDeleted == true"><i class="far fa-check-circle mr-2"></i>Opening balance
              deleted successfully!</span>
            <div class="action-box mt-5 d-flex justify-content-center">
              <button type="button" class="btn-style mr-3" data-dismiss="modal">Cancel</button>
              <button type="button" class="btn-style bg-red" (click)="deleteBalance()">Delete</button>
              <button type="button" class="btn-style bg-green ml-3" [disabled]="!balanceForm.valid"
                (click)="checkBalance()">Save</button>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</div>

<button type="button" hidden #confirmUpdateOBModal data-toggle="modal" data-target="#updateconfirmationOBModal"></button>
<div id="updateconfirmationOBModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeUpdateModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        There is a mismatch of amount {{remainingAmount}} in your opening balance. It will be adjusted in the Opening Balance Adjustment account. Would you like to proceed?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" 
        data-dismiss="modal">Close</button>
        <button type="button" class="btn-style bg-green" (click)="updateOpeningBalancecombine()" >UPDATE</button>
      </div>
    </div>
  </div>
</div>