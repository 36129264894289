export class GlobalData {

    public static accessData: any ;

    public static getAccessData() {
		return this.accessData;
	}

	public static setAccessData(data) {
		this.accessData = data;
	}

}