import { AirportDetailRefundModel } from './airport-detail-refund-model';
import { BaggageInfoRefundModel } from './baggage-info-refund-model';


export class LegRefundModel {

    airline_code : string;
    airline_name : string;
    airline_xl_code : string;
    flight_no : string;
    service_class : string;
    airline_pnr : string;
    duration : string;
    departure_airport_details : AirportDetailRefundModel;
    arrival_airport_details : AirportDetailRefundModel;
    baggage_info : BaggageInfoRefundModel;
    
}