import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonServiceService } from '../../common-service.service';
import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';

@Component({
  selector: 'app-trial-balance-search',
  templateUrl: './trial-balance-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class TrialBalanceSearchComponent implements OnInit {
      trialBalanceFilterForm: FormGroup;
      // daybookArr: any;
      @ViewChild('searchButton',{static:true}) searchButton : ElementRef;
      minDate = "2023-04-01"
      maxDate = moment().format('YYYY-MM-DD')
      branches = []
      constructor( public commonService: CommonServiceService,
        private formBuilder: FormBuilder , private router: Router,private store: Store<InitialState>) { 
          this.store.select('sharedReducers').subscribe((items)=>{
            if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
              this.minDate = items.financeYear.start_date
              this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
                          moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            } else {
              let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
              if(!current_financial_year) {
                current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
              }
              this.minDate = current_financial_year.start_date
              this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
                          moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            }
        })
      }
      ngOnInit() {

        this.trialBalanceFilterForm = this.formBuilder.group({
          start_date: [this.minDate, this.checkDate('start_date')],
		    	end_date: [this.maxDate,this.checkDate('end_date')],
          selectedBranch: ['']
        })
        this.setFormData()
        this.getAllBranches()
      }


    checkDate(formControlName){
      return (control: AbstractControl) => {
        let valid = true
        if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
          valid = false
        } else {
          if(this){
            if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
              valid = false
            }
          }
        }
        return !valid ? {[formControlName]: {value: control.value}} : null;
      };
    }

      setFormData() {
        this.trialBalanceFilterForm.controls.start_date.setValue(moment(this.minDate).format('YYYY-MM-DD'))
        this.trialBalanceFilterForm.controls.end_date.setValue(moment(this.maxDate).format('YYYY-MM-DD'))
        // localStorage.setItem("activeTrialBalanceQueryParams",JSON.stringify({
        //   "start_date":this.minDate,
        //   "end_date":this.maxDate
        // }))
      }

      search(values){
        GlobalVariable.openModalStatus = false;
        if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
          values.start_date = ""
        }
        else{
          values.start_date = moment(values.start_date).format('YYYY-MM-DD')
        }
        if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date" || values.end_date == "Invalid date") {
          values.end_date = ""
        }
        else{
          values.end_date = moment(values.end_date).format('YYYY-MM-DD')
        }
		    localStorage.setItem("activeTrialBalanceQueryParams",JSON.stringify({
          "start_date":values.start_date,
          "end_date":values.end_date,
          "selectedBranch" : values.selectedBranch
        }))
        if(values.selectedBranch) {
            this.router.navigateByUrl(`report/trial-balance/group-view?start_date=${values.start_date}&end_date=${values.end_date}&selectedBranch=${values.selectedBranch}`)
        } else {
          this.router.navigateByUrl(`report/trial-balance/view?start_date=${values.start_date}&end_date=${values.end_date}`)
        }
        }

      reset(){
        GlobalVariable.openModalStatus = false;
        this.trialBalanceFilterForm = this.formBuilder.group({
          start_date: [this.minDate],
          end_date: [this.maxDate],
          selectedBranch: ['']
        })
        localStorage.removeItem('activeTrialBalanceQueryParams')
      }

      clickSearchButton(){
        this.searchButton.nativeElement.click()
      }

      getAllBranches() {
        this.commonService.getOkount(`branch`).subscribe(res=> {
          if(res && res['data']) {
            this.branches = res['data'];
          }
        },err=> {
    
        })
      }
    

}
