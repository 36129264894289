  <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
    <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
      <div class="d-flex flex-y-center">
        <img
          src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
            alertType=='success-box' ? 'green-sucess.svg' : 
            alertType=='warning-box' ? 'red-warning.svg' : 
            null }}"
          width="20px"
          height="20px"
          alt="alert-logo"
          class="mr-20"
        />
        <div class="ml-10">
          <h4 class="m-0 text-size-18 text-bold"
          [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
            {{alertType === "error-box"
            ? "Error"
            : alertType === "warning-box"
            ? "Warning"
            : alertType === "success-box"
            ? "Success"
            : null}}
          </h4>
          <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
           {{message}}
          </small>
        </div>
      </div>
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
        alt="close-btn"
        (click)="hideError()"
        className=" close-btn"
      />
    </div>
  </div>
  
  <button type="button" hidden  #openSuccessModal data-toggle="modal" data-target="#successModal"></button>
  <div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
      <div class="modal-dialog transaction-creation-modal">
          <div class="modal-content">
              <div class="modal-header">
                  <span class="messageFont">
                      <span>
                          {{message}} 
                      </span>
                      <a 
                          *ngIf="createdGeneralCode != ''"
                           href="javascript:void(0)"
                          (click)="viewGeneral(createdGeneralId)">
                              {{createdGeneralCode}}
                      </a>
                  </span>
              <button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
              </div>
  
              <div class="modal-body">
                  <p>Do you want to create new Bank or view newly created Bank ?</p>
              </div>
            
              <div class="modal-footer justify-content-center" >
                  <button tabIndex="1" tabindex="1" type="button" class="btn-style bg-blue" (click)="viewGeneral(createdGeneralId)">
                      View
                  </button>
                  <button tabIndex="3" tabindex="3" type="button" class="btn-style bg-green" (click)="closeSuccesModal()">
                      Create New
                  </button>
              </div>
          </div>
      </div>
  </div>
  
  <div class="ledger-container  ">
    <div class="row tab-nav d-flex align-item-center justify-content-between">
      <div class="col-md-4">
        <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
          <li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition"> Dashboard
          </li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li class="text-grey text-capitalize cursor text-primary transition">Create Bank</li>
        </ul>
      </div>
      <!-- <ul class="list-inline list-unstyled p-0 m-0">
        <li class="active page-title   text-uppercase"> Bank</li>
      </ul> -->
     
    </div>
    <div class="custom-form form-container mt-4 pl-15 pr-15" style="    width: 600px;
    margin: 20px 0 ;">
  
      <form [formGroup]="bankForm" [ngClass]="{'d-none': !newGeneral}">
        <div class="text-field-group d-flex">
          <div class="select-field text-input-field mr-2 col-10 p-0">
            <select class="input-field h-100" oninput="this.value = this.value.toUpperCase()" tabIndex="1" (change)="setHierarchy($event.target.value,false)"
              formControlName="parent_id" [ngClass]="{'error-field': (!bankForm.controls.parent_id.valid && submitted),
              'success-field' : bankForm.controls.parent_id.valid && bankForm.controls.parent_id.dirty}">
              <option selected disabled>SELECT</option>
              <option *ngFor="let item of groupList" value="{{item.id}}">{{item.name}}</option>
            </select>
            <label
              [ngClass]="{'error-label': bankForm.controls.parent_id.invalid && bankForm.controls.parent_id.dirty || (!bankForm.controls.parent_id.valid && submitted)}">Group
              <ng-container
                *ngIf="bankForm.controls.parent_id.invalid && bankForm.controls.parent_id.dirty || (!bankForm.controls.parent_id.valid && submitted)">
                <i class="fa fa-info-circle ml-2" title="Group Required" aria-hidden="true"></i>
              </ng-container>
            </label>
          </div>
          <div class="text-input-field">
            <input class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="2"
              formControlName="hierarchy" type="text" />
            <label>
              Hierarchy
            </label>
          </div>
        </div>
        <div class="text-field-group d-flex justify-content-between">
          <div class="text-input-field" [ngClass]="{'mr-2': isHQBranch}">
            <input class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="2"
              formControlName="account_name" type="text" [ngClass]="{'error-field': bankForm.controls.account_name.invalid && bankForm.controls.account_name.dirty || (!bankForm.controls.account_name.valid && submitted) ,
                'success-field' : bankForm.controls.account_name.valid && bankForm.controls.account_name.dirty}" />
            <label
              [ngClass]="{'error-label': bankForm.controls.account_name.invalid && bankForm.controls.account_name.dirty || (!bankForm.controls.account_name.valid && submitted)}">Account
  
              <ng-container
                *ngIf="bankForm.controls.account_name.invalid && bankForm.controls.account_name.dirty || (!bankForm.controls.account_name.valid && submitted)">
                <i class="fa fa-info-circle ml-2" title="Invalid telephone Number" aria-hidden="true"></i>
              </ng-container>
            </label>
          </div>
  
          <div style="width: 150px;" class="select-field text-input-field" *ngIf="isHQBranch">
            <select class="input-field" formControlName="sync_in_branches">
              <option value="">Select</option>
              <option value="true">YES</option>
              <option value="false">NO</option>
            </select>
            <label>All Branches</label>
          </div>
          
        </div>

        <div class="text-input-group d-flex justify-content-between">
                <div class="text-input-field mr-2">
                  <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="account_number" tabIndex="14"
                    formControlName="account_number" type="text" />
                  <label>A/c Number</label>
                </div>

                <div class="text-input-field">
                    <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="ifsc_code" tabIndex="13"
                    formControlName="ifsc_code" type="text" />
                    <label>IFSC</label>
                </div>
        </div>
        
  
        <div class="text-input-group d-flex justify-content-between">
          <div class="text-input-field mr-2">
            <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="5"
              formControlName="state_id" (change)="selectState($event.target.value)">
              <option *ngFor="let state of stateArr" value="{{state.location_id}}">{{state.name}}</option>
            </select>
            <label>State</label>
          </div>

          <div class="text-input-field">
            <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="6"
              formControlName="city_id" (change)="getCityName($event.target.value)">
              <option *ngFor="let city of cityArr" value="{{city.location_id}}">{{city.name}}</option>
            </select>
            <label>City</label>
          </div>
  
        </div>
  
        <div class="text-field-group d-flex">
            <div class="w-50 text-input-field mr-2" *ngIf="isTenantIndian">
                <input class="input-field" onblur="this.value = this.value.toUpperCase()" name="gst_number" tabIndex="7"
                  formControlName="gst_number" type="text" maxlength="15"
                  pattern="^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$"
                  [ngClass]="{'error-field': bankForm.controls.gst_number.invalid && bankForm.controls.gst_number.dirty || (!bankForm.controls.gst_number.valid && submitted),
                'success-field' : bankForm.controls.gst_number.valid && bankForm.controls.gst_number.dirty}" />
                <label
                  [ngClass]="{'error-label': bankForm.controls.gst_number.invalid && bankForm.controls.gst_number.dirty} || (!bankForm.controls.gst_number.valid && submitted)">
                  GST NUMBER
                  <ng-container
                    *ngIf="bankForm.controls.gst_number.invalid && bankForm.controls.gst_number.dirty || (!bankForm.controls.gst_number.valid && submitted)">
                    <i class="fa fa-info-circle ml-2" title="Invalid GST Number ( ex: 12ABCDE1234F1Z3)"
                      aria-hidden="true"></i>
                  </ng-container>
                </label>
            </div>

            <div class="w-50 text-input-field mr-2" *ngIf="!isTenantIndian">
              <input class="input-field" onblur="this.value = this.value.toUpperCase()" name="vat_number" tabIndex="7"
                formControlName="vat_number" type="text" maxlength="20"
                [ngClass]="{'error-field': bankForm.controls.vat_number.invalid && bankForm.controls.vat_number.dirty || (!bankForm.controls.vat_number.valid && submitted),
              'success-field' : bankForm.controls.vat_number.valid && bankForm.controls.vat_number.dirty}" />
              <label
                [ngClass]="{'error-label': bankForm.controls.vat_number.invalid && bankForm.controls.vat_number.dirty} || (!bankForm.controls.vat_number.valid && submitted)">
                {{vatAlternetname}} NUMBER
              </label>
          </div>


            <div class="w-50 d-flex">
              <div class="text-input-field mr-2">
                <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="balance" tabIndex="3"
                  formControlName="balance" pattern="^\d*(\.\d{0,8})?$" type="text" [ngClass]="{'error-field': bankForm.controls.balance.invalid && bankForm.controls.balance.dirty,
                    'success-field' : bankForm.controls.balance.valid && bankForm.controls.balance.dirty}" />
                <label
                  [ngClass]="{'error-label': bankForm.controls.balance.invalid && bankForm.controls.balance.dirty}">Opening
                  Balance
                  <ng-container *ngIf="bankForm.controls.balance.invalid && bankForm.controls.balance.dirty">
                    <i class="fa fa-info-circle ml-2" title="Invalid opening balance" aria-hidden="true"></i>
                  </ng-container>
                </label>
              </div>
      
              <div class="w-25 select-field text-input-field">
                <select style="width: 60px;" class="input-field" tabIndex="4" formControlName="balance_type">
                  <option value="debit" selected>DR</option>
                  <option value="credit">CR</option>
                </select>
              </div>
            </div>
        
        </div>

        <div class="text-input-group d-flex justify-content-between">
          <div class="text-input-field mr-2">
            <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="swift_code"
                    formControlName="swift_code" type="text" />
            <label>SWIFT CODE</label>
          </div>
  
        </div>
  
      

      </form>

      <div class="select-field text-input-field" *ngIf="isHQBranch">
        <label for="selectedBranch" style="font-size: 13px; font-weight: 500!important;">Branch</label>
        <ng-container *ngFor="let item of branches">
              <div *ngIf="item?.branch_type=='BRANCH'" class="input-fiel" style="display: flex;align-items: center;">
                <input class="radio-ui mr-2" type="checkbox"  (change)="handleValueChange($event,item?.branch_name)"  />
                <label class="radio-label" style="transform: none;font-size: 12px;margin-bottom: 0;" >{{item?.branch_name}}</label>
              </div>
        </ng-container>
      </div>

    </div>
    <div class="action-box btn-field bottom-btns-container mt-4 d-flex justify-content-end">
      <div>
        <button type="button" class="btn-style bg-green mr-3" (click)="reset()">Reset</button>
      <button type="button" class="btn-style bg-purple" tabIndex="5" #confirmModal
        (keyup.enter)="openConfirmModal()" data-toggle="modal" data-target="#confirmationModal">Save</button>
      </div>
      
      
    </div>
  </div>
  
  
  <!-- Modal -->
  <div class="modal fade" id="confirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Confirmation</h4>
          <button type="button" class="close" #closeModal data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Create {{bankForm?.value?.account_name}} ?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn-style mr-3" data-dismiss="modal">Close</button>
          <button type="button" class="btn-style bg-purple" (click)="checkBalance()" (keyup.enter)="checkBalance()" tabIndex="6">Yes</button>
        </div>
      </div>
    </div>
  </div>
  <button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateconfirmationModal"></button>
<div id="updateconfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeUpdateModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        There is a mismatch of amount {{remainingAmount}} in your opening balance. It will be adjusted in the Opening Balance Adjustment account. Would you like to proceed?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" 
        data-dismiss="modal">Close</button>
        <button type="button" class="btn-style bg-green" (click)="updateOpeningBalancecombine()" >CREATE</button>
      </div>
    </div>
  </div>
</div>