import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UpdatePaymentComponent } from './update-payment/update-payment.component';
import { ViewPaymentComponent } from './view-payment/view-payment.component';
import { PrintPaymentComponent } from './print-payment/print-payment.component';
import { V2CreatePaymentComponent } from './v2-create-payment/v2-create-payment.component';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';


const routes: Routes = [
	{
		path: '', component: V2CreatePaymentComponent, canActivate:[AuthGuardService]
	},

	{ path: 'update/:id', component: UpdatePaymentComponent, canActivate:[AuthGuardService] },
	{ path: 'view', component: ViewPaymentComponent, canActivate:[AuthGuardService] },
	{ path: 'print/:id', component : PrintPaymentComponent},

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PaymentRoutingModule { }
