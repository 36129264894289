<div class="ledger-container " #tableHeader tabIndex="0" [ngClass]="{ 'ledger-print-section': isPrint == 'true' }">
	<!-- <div class="alert alert-success msgSuccess" >
       messageSuccess
    </div>
    <div class="alert alert-danger msgError" >
       messageError
    </div> -->
	<!-- <div class="heading-box mt-3 ml-2">
        <div class="heading">Outstanding Report</div>
        <span class="hr-line"></span>
      </div> -->

	<div *ngIf="outStandingData != undefined">
		<div class="voucher-container">
			<div class="row leader-header tab-nav pl-0 pr-0">
				<div class="col-md-4">
					<div *ngIf="isPrint">
					 <span class="text-bold text-black text-uppercase" style="font-size: 16px;">{{outStandingData?.company_details?.name}}</span><br>
					 <p class="address_line mb-0 text-grey text-uppercase" style="font-size: 14px;"><span style="font-size: 14px;"  class="d-block">{{outStandingData?.company_details?.address_1}}</span>
					   <span style="font-size: 14px;">{{outStandingData?.company_details?.address_2}}</span>  <span class="ml-2" style="font-size: 14px;">{{outStandingData?.company_details?.city_name}}</span> <span style="font-size: 14px;" class="ml-2">{{outStandingData?.company_details?.address_3}}</span></p>
					</div>
					<ul
					  class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0"
					>
					  <li
						[routerLink]="['/']"
						class="text-grey text-capitalize cursor hover-text-primary transition"
						[ngClass]="{ 'd-none': isPrint == 'true' }"
					  >
						Analysis
					  </li>
					  <li>
						<img
						  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg"
						  alt=""
						  [ngClass]="{ 'd-none': isPrint == 'true' }"
						/>
					  </li>
					  <li
						class="text-grey text-capitalize cursor text-primary transition"
						[ngClass]="{ 'd-none': isPrint == 'false' }"
						[ngClass]="{ 'd-none': isPrint == 'true' }"
					  >
					  Summary Ledger:
					  </li>
					</ul>
				  </div>
				<div class="col-md-4 text-center">
				  <h4 style="font-size: 18px;" *ngIf="isPrint" class="text-uppercase text-bold text-black mt-2">
					Summary Ledger Statement<br>
				  </h4>
				  <span class="rec-date d-block text-center">
					<div class="noWrap justify-content-around">
					  <ng-container>
						<div class="cname text-bold">
						  <span  [ngClass]="{ 'font-size-16': isPrint == 'true' }"  *ngIf="duration.from_date" 
							>From</span
						  >
						  <h5 style="margin-right: 5px;" class="mb-0 d-inline">
							<span [ngClass]="{ 'font-size-16': isPrint == 'true' }" 
							  class="date text-bold text-orange"
							  *ngIf="duration.from_date"
							>
							  {{ duration.from_date }}
							</span>
							<span [ngClass]="{ 'font-size-16': isPrint == 'true' }"  class="date text-bold" *ngIf="duration.from_date">
							  To
							</span>
							<span [ngClass]="{ 'font-size-16': isPrint == 'true' }"  class="date text-bold"> Till Date </span>
							<span [ngClass]="{ 'font-size-16': isPrint == 'true' }"  class="date text-bold text-orange">
							  {{ duration.to_date }}
							</span>
						  </h5>
						  <button [ngClass]="{ 'd-none': isPrint == 'true' }" title="Custom Search" class="btn   save-as text-bold bg-whiteSmoke " 
								type="button" (click)="openAdvanceSearchModal()" >
								<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
							</button>
						</div>
					  </ng-container>
					</div>
				  </span>
				</div>
				<div class="col-md-4 d-flex align-item-center justify-content-end">
				  <div class="text-right mt-2" *ngIf="isPrint">
					<span class="d-block text-black" [ngClass]="{ 'font-size-14': isPrint == 'true' }">{{currentDate | date : 'dd/MM/yyyy'}}</span>
					<span class="d-block text-grey"  [ngClass]="{ 'font-size-14': isPrint == 'true' }"> {{currentTime}}</span>
				  </div>
				  <div
				  *ngIf="!isPrint"
				  class="page-search-box input-group d-flex align-item-center"
				  style="height: fit-content; text-align: left"
				>
				  <input
					type="text"
					class="form-control"
					oninput="this.value = this.value.toUpperCase()"
					placeholder="search"
					style="text-align: left"
				  />
				</div>
				  <div *ngIf="!isPrint" class="dropdown-btn position-relative">
					<button title="More..." class="btn-style mt-0 more-btn">
					  <span class="dot"></span><span class="dot"></span
					  ><span class="dot"></span>
					</button>
					<ul class="list-unstyled pl-0 dropdown-box">
					  <li   (click)="downloadPdf(outStandingData?.duration?.from_date  ,outStandingData?.duration?.to_date)">
						<img
						  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg"
						  alt=""
						/>
						<span class="ml-2">Save As Pdf</span>
					  </li>
					  <li   (click)="exportAsXLSX(outStandingData?.duration?.from_date  ,outStandingData?.duration?.to_date)">
						
						<span class="ml-2">Excel</span>
					  </li>
					</ul>
				  </div>
				</div>
			  </div>

			<!-- row -->


			<div class="table-section mr-2"  [ngClass]="{ 'table-print-section': isPrint == 'true' }">
				<table class="table table striped">
					<thead>
						<tr *ngIf="isPrint" style="border-bottom: none!important;">
							<th colspan="6" class="page-top-space">
							</th>
						  </tr>
						<tr class="transition">
							<!-- <th class="text-left">Date </th>  -->

							<th  style="max-width: 175px " class="text-left doc-no">Account Code

							</th>
							<th class="text-left">Account

							</th>
							<th style="max-width: 175px " class="text-left doc-no">City Name</th>
							<th class="text-right debit" style="min-width: 180px; max-width: 190px">Debit

							</th>
							<th class="text-right credit" style="min-width: 180px; max-width: 190px">Credit</th>
							<th class="text-right balance" style="max-width: 155px">Balance
							</th>

						</tr>
					</thead>
					<tbody class="scroll-body main-panel" [ngStyle]="{height: innerHeight}"
						(window:keydown)="onKeyDown($event)">
						<tr class="opening-bal" *ngIf="isPrint">
							<td style="max-width: 175px " class="text-left doc-no"></td>
							<td  ><p class="text-bold text-black undl text-left mb-0">OPENING BALANCE</p></td>
							<td style="max-width: 175px " class="text-left doc-no" ></td>
							<td class="text-right text-red debit"  style="min-width: 180px; max-width: 190px;">
							  <span 
							  class="bal-amount debit-amt text-bold"
							  
							>
								12,99,786.00
							</span>
							</td>
							<td class="text-right text-green credit"  style="min-width: 180px; max-width: 190px;">
							  <span
							  class="bal-amount text-green credit-amt text-bold"
							>
							
							  0
							</span>
							</td>
							<td class="text-right text-grey balance" style="min-width: 135px;max-width: 150px;" ></td>
						  </tr>
						<ng-container *ngFor="let data of outStandingData['data'] let i = index">
							<!-- <span class="client-name">{{data['display_name']}}<br> 
							</span> -->
							<!-- <tr class="alert-warning">
								<td colspan="6">
									<div class="row w-100">
										
										
									</div>
								</td>
							</tr> -->
							<!-- <tr *ngFor="let record of data['client_records']" >
							   
								<td class="text-left">{{record?.date | date : 'dd/MM/yyyy'}}</td>
								<td class="text-center">{{record?.reference}}</td>
									<td class="text-left">
										<ng-container *ngFor="let narration of record.narration">
											<ng-container *ngIf="narration.trim() != ''">
												{{narration}} <br>
											</ng-container>
										</ng-container>
								<td class="text-right">{{record?.amount_debit}}</td>
								<td class="text-right">{{record?.amount_credit}}</td>
								<td class="text-right">{{record?.balance}}</td>
							</tr> -->
							<tr [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}"
								(keyup.enter)="openLedger(data['account_code'])"
								[ngClass]="{'borderOrange' : data?.total_debit> 0 ,'borderGreen' : data?.total_credit> 0}">

								<!-- <td class="grand text-left"> To receive : INR {{data?.outstanding | number : '1.2-2'}}</td> -->
								<td (click)="moveToIndex(i)"  style="max-width: 175px " class="text-left doc-no">
									{{data['account_code']}}
								</td>
								<td (click)="moveToIndex(i)" class="text-left  refer" >
									<span (click)="openLedger(data['account_code'])">
										{{data['display_name']}}
									</span>
								</td>
								<!-- <td  class="grand text-left"> {{data?.narration}}</td> -->
								<!-- <td ></td> -->
								<!-- <td  class="text-center"></td> -->
								<td style="max-width: 175px " class="text-left doc-no">{{data?.city_name}}</td>
								<td (click)="moveToIndex(i)" class=" text-right text-red debit"
									style="min-width: 170px; max-width: 190px;">{{data?.total_debit | number : '1.2-2'}}
								</td>
								<td (click)="moveToIndex(i)" class=" text-right text-green credit"
									style="min-width: 170px;    max-width: 190px;">{{data?.total_credit | number :
									'1.2-2'}}</td>
								<td (click)="moveToIndex(i)" class=" text-right text-blue balance"
									style="min-width: 135px;max-width: 150px;">
									{{data?.outstanding | number : '1.2-2'}}</td>
							</tr>

						</ng-container>
						
						  <tr *ngIf="isPrint">
							<td colspan="6" class="text-left text-grey" >
							 
								<p class="text-bold text-black mb-0">Amount Receivable: <span class="ml-2 text-bold">31,365.00</span></p>
							</td>
						  </tr>
						  <tr *ngIf="isPrint"  style="    border-bottom: none!important;">
							<td colspan="6" class="text-right text-grey" >
							  <h5 class="mb-0 text-bold" style="font-size: 16px;">For {{outStandingData?.company_details?.name}}</h5>
							</td>
						  </tr>
						<tr *ngIf="outStandingData['data'].length == 0">
							<div class="w-100 text-center" style="padding: 20px;">
								<span><img class="not-found-img" src="{{commonService.aws_asset_url}}/_assets/images/problem.png"></span> <br>
								<span class="not-found-text">No matching items found</span>
							</div>
						</tr>
					</tbody>

					<tfoot>
						<tr class="">
							<td class="text-left doc-no" style="max-width: 175px "></td>
							<td class="text-left text-grey"><p class="text-bold text-black mt-2 mb-0">SUBTOTAL </p></td>
							<td class="text-left text-grey" style="max-width: 175px "></td>
							
							<td class="text-right ledger-subtotal text-red" style="min-width: 170px; max-width: 190px;">
							  <span class="bal-amount text-red debit-amt text-bold">
							    {{outStandingData['summary']['debitTotal']}}
							  </span>
							</td>
							
							<td class="text-right ledger-subtotal text-green" style="min-width: 170px;    max-width: 190px;">
							    <span class="bal-amount text-green credit-amt text-bold">
							       {{outStandingData['summary']['creditTotal']}}
							    </span>
							</td>
							
							<td class="text-right text-grey balance" style="min-width: 135px;max-width: 150px;">
								<span class="bal-amount text-blue credit-amt text-bold">
									{{outStandingData['summary']['balanceTotal']}}
								</span>
							</td>

						  </tr>
					</tfoot>
				</table>

			</div>

		</div>
	</div>

</div>