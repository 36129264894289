import { Injectable } from '@angular/core';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class XlsDownloadService {
  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    debugger;
    let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // let bold = worksheet.add_format({'bold': true})
    // let format = worksheet.add_format()
    // format.set_font_size(10)

    // let workbook: XLSX.WorkBook;
    let workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    // workbook.Workbook.Views.fill.

    let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // excelBuffer.formats.set_font_size(10);
    // excelBuffer.write('A1:Z1', 'bold')
    // workbook.Workbook.Views.fill[0] = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFFFFF00' },
    //   bgColor: { argb: 'FF0000FF' }
    // }
    // workbook.Workbook.Views.fill[0].border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  
    
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFileGST(xlsData, excelFileName: string): void {

    // let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    
    // let ws1 : XLSX.WorkSheet = XLSX.utils.json_to_sheet(xlsData['']);
    // let ws2 : XLSX.WorkSheet = XLSX.utils.json_to_sheet(B2CS);
    // let ws3 : XLSX.WorkSheet = XLSX.utils.json_to_sheet(HSN);
    // let ws4 : XLSX.WorkSheet = XLSX.utils.json_to_sheet(DOCS);
    // let ws5 : XLSX.WorkSheet = XLSX.utils.json_to_sheet(CDNR);

 

    let wb = { SheetNames: [], Sheets: {} };
    // let workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    for (const xls of xlsData) {
      let ws : XLSX.WorkSheet = XLSX.utils.json_to_sheet(xls['data']);
      wb.SheetNames.push(xls['sheet']); wb.Sheets[xls['sheet']] = ws;
    }
    // wb.SheetNames.push("B2B"); wb.Sheets["B2B"] = ws1; // First workbook is named "Sheet1", you can change it
    // wb.SheetNames.push("B2CS"); wb.Sheets["B2CS"] = ws2; 
    // wb.SheetNames.push("HSN"); wb.Sheets["HSN"] = ws3; 
    // wb.SheetNames.push("DOCS"); wb.Sheets["DOCS"] = ws4; 
    // wb.SheetNames.push("CDNR"); wb.Sheets["CDNR"] = ws5; 

    let excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    // excelBuffer.formats.set_font_size(10);
    // excelBuffer.write('A1:Z1', 'bold')
    // workbook.Workbook.Views.fill[0] = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFFFFF00' },
    //   bgColor: { argb: 'FF0000FF' }
    // }
    // workbook.Workbook.Views.fill[0].border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }


    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
