<form [formGroup]="paymentFilterForm">

  <div class="flexbox aff-info">
    <div class="flexbox aff-info-inner">



      <div class="flexBox d-flex ">

        <div class="input-fiel form-group ng-field" searchtabindex="1" (focus)="focusClientAc()">
          <label class="ngauto-label" for="affname">Account</label>
          <div class="ng-autocomplete">
            <ng-autocomplete #clientAC [data]="accountArr" [searchKeyword]="keyword"
              (inputChanged)='getAccount($event,false)' historyIdentifier="accountArr" [itemTemplate]="itemTemplate"
              (inputCleared)="clearData('client')"
              formControlName="account_name" (selected)="selectAccount($event,'account')"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                                  ({{item.account_code}})</span><br />'>
              </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>


        <div class="input-fiel">
          <input class="input-ui" id="uidno" type="text" searchtabindex="2" formControlName="journal_code"
            placeholder=" JV Code" /><label for="uidno">JV Code</label>
        </div>
      </div>


      <div class="flexbox mt-3">
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="3" formControlName="start_date" id="date" type="date"
              min="{{minDate}}" max="{{maxDate}}"
              [ngClass]="{'error-box': !paymentFilterForm.controls.start_date.valid}"
              style="width: 140px;" />
          <label for="date">From Date</label>

        </div>

        <div class="input-fiel">
          <input class="input-ui" searchtabindex="4" formControlName="end_date" id="date" type="date" 
            min="{{minDate}}" max="{{maxDate}}"
            [ngClass]="{'error-box': !paymentFilterForm.controls.end_date.valid}"
             style="width: 140px;" />
          <label for="date">To Date</label>

        </div>
        <div class="input-fiel">
          <input class="input-ui" id="paymentNo" oninput="this.value = this.value.toUpperCase()" type="text"
            searchtabindex="5" formControlName="narration" placeholder="Narration" /><label
            for="paymentNo">Narration</label>
        </div>


      </div>



      <div class="flexbox mt-3">

        <div class="input-fiel">
          <input class="input-ui" id="remarks" searchtabindex="6" formControlName="remark" placeholder="Remark" /><label
            for="remarks">Remark</label>
        </div>


        <!-- <div class="input-fiel">
            <input class="input-ui" id="subledger"  searchtabindex="6" formControlName="subledger_code"
              placeholder="Subledger" /><label for="subledger">Subledger</label>
          </div> -->

        <div class="input-fiel form-group ng-field" searchtabindex="7" (focus)="focusSubledgerAc()">
          <label class="ngauto-label" for="affname">Sub Ledger</label>
          <div class="ng-autocomplete">
            <ng-autocomplete [data]="subLedger" #subledgerAC [searchKeyword]="keyword"
              (inputChanged)='getSubLedgerAccount($event,false)' historyIdentifier="subLedger"
              [itemTemplate]="itemTemplate" formControlName="sub_ledger_name"
              (selected)="selectAccount($event,'subledger_code')" [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                                  ({{item.account_code}})</span><br />'>
              </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>

      </div>

      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style bg-green mr-3" data-dismiss="modal"
            searchtabindex="8" #searchButton (keyup.enter)="clickSearchButton()"
            [disabled]="paymentFilterForm.invalid"
            (click)="search(paymentFilterForm.value)"> Search</button>
          <button type="button" class="btn-style bg-green"  id="resetSearchForm" (click)="reset()">RESET</button>
        </div>
      </div>

    </div>

  </div>
</form>