import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlightRefundRoutingModule } from './flight-refund-routing.module';
import { FlightRefundComponent } from './flight-refund.component';
import { TravellerInfoRefundComponent } from './traveller-info-refund/traveller-info-refund.component';
import { TotalFareInfoRefundComponent } from './total-fare-info-refund/total-fare-info-refund.component';
import { SectorInfoRefundComponent } from './sector-info-refund/sector-info-refund.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { InvoiceServicesService } from '../../invoice/flight/services/invoice-services.service';
import { ProductDetailRefundService } from './services/product-detail-refund.service';
import { InvoiceRefundService } from './services/invoice-refund.service';
import { InvoiceRequestBuilderRefundService } from './services/invoice-request-builder-refund.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';


@NgModule({
  declarations: [FlightRefundComponent, TravellerInfoRefundComponent, TotalFareInfoRefundComponent, SectorInfoRefundComponent],
  imports: [
    CommonModule,
    FlightRefundRoutingModule,
    SharedModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [FlightRefundComponent],
  providers :  [InvoiceServicesService,ProductDetailRefundService,InvoiceRefundService,InvoiceRequestBuilderRefundService]
})
export class FlightRefundModule { }
