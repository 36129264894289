import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { PaymentService } from '../services/payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonServiceService } from 'src/app/shared/common-service.service';

@Component({
	selector: 'app-add-payment-record',
	templateUrl: './add-payment-record.component.html',
	styleUrls: ['./add-payment-record.component.scss']
})
export class AddPaymentRecordComponent implements OnInit {
	keyword = 'name';

	@ViewChild('amountField',{static : true}) amountField: ElementRef;
	@Input() isUpdate;

	@ViewChild('accountAC',{static : true}) accountAC;
	@ViewChild('subLedgerAC',{static : true}) subLedgerAC;
	@ViewChild('accountType',{static:true}) accountType : ElementRef
	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('updateConfirmModal',{static:true}) updateConfirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef

	@Output() callExitEntry: EventEmitter<any> = new EventEmitter();
	accountAutoComplete: boolean = false;
	constructor(
		public paymentService: PaymentService,
		private spinner: NgxSpinnerService,
		public commonService: CommonServiceService
	) { }

	ngOnInit() {
		console.log('Is update : ' ,this.isUpdate)
		this.commonService.selectedInput.next(0)
		if (this.isUpdate) {
			if (this.paymentService.paymentEntryForm.value.account_code_to_type != '') {
				this.getAccounts(this.paymentService.paymentEntryForm.value.account_code_to_type)
			}
	
			if (this.paymentService.paymentEntryForm.value.sub_ledger_code_1_type != '') {
				this.getLedgers(this.paymentService.paymentEntryForm.value.sub_ledger_code_1_type)
			}
		}
		if (this.accountType != undefined) {
			this.accountType.nativeElement.focus()
		}


	}

	openConfirmModal() :  void{
		this.confirmModal.nativeElement.click()
		this.commonService.selectedInput.next(21)
	}

	openUpdateConfirmModal() :  void{
		this.updateConfirmModal.nativeElement.click()
		this.commonService.selectedInput.next(23)
	}
	closeConfirmModal() :  void{
		this.closeModal.nativeElement.click()
	}
	focusAccount(e): void {
		e.stopPropagation();
		this.accountAC.focus();
	}
	focusSubLedger(e): void {
		e.stopPropagation();
		this.subLedgerAC.focus();
	}


	async saveAndEditNext(formValue){
		this.spinner.show()
		this.closeConfirmModal()
		let response = await this.addEntry(formValue)
		if (response != false) {
			this.resetFormData()
		}
	}
	async saveAndExit(formValue){
		this.spinner.show()
		this.closeConfirmModal()
		let response = await this.addEntry(formValue)
		if (response != false) {
			this.exitEntryPage()
		}
	}
	async updateAndExit(formValue){
		this.spinner.show()
		this.closeConfirmModal()
		let response = await this.updateEntry(formValue)
		if (response != false) {
			this.paymentService.isEdit = false
			this.exitEntryPage()
		}
	}
	async deleteAndExit(){
		this.spinner.show()
		this.closeConfirmModal()
		let response = await this.deleteEntry()
		if (response != false) {
			this.paymentService.isEdit = false
			this.exitEntryPage()
		}
	}


	addEntry(formValue) {
		if (this.paymentService.paymentEntryForm.invalid || formValue.amount < 1) {
			this.spinner.hide()
			return false
		}
		formValue['payment_id'] = this.paymentService.paymentForm.value.payment_id
		let loggedInUser = 	JSON.parse(localStorage.getItem('okountUserInfo'))
		formValue['last_modified_by'] = loggedInUser.id
		formValue['last_modified_by_name'] = loggedInUser.name
		return new Promise((resolve, reject) => {
			this.paymentService.postMethod(`paymententryv2`,formValue).subscribe(
				async (res) => {
					let response = res
					console.log('Response : ', response)
					let entryRes = await this.getEntries()
					resolve(response)
				},
				err => {
					let entryRes =  this.getEntries()
					reject("");
				}
			);
	});
	}
	updateEntry(formValue) {
		if (this.paymentService.paymentEntryForm.invalid || formValue.amount < 1) {
			this.spinner.hide()
			return false
		}
		let payment_entry_id = localStorage.getItem('selected_payment_entry')
		formValue['payment_id'] = this.paymentService.paymentForm.value.payment_id
		let loggedInUser = 	JSON.parse(localStorage.getItem('okountUserInfo'))
		formValue['last_modified_by'] = loggedInUser.id
		formValue['last_modified_by_name'] = loggedInUser.name
		return new Promise((resolve, reject) => {
			this.paymentService.putMethod(`paymententryv2?payment_entry_id=${payment_entry_id}`,formValue).subscribe(
				async (res) => {
					let response = res
					console.log('Response : ', response)
					let entryRes = await this.getEntries()
					resolve(response)
				},
				err => {
					let entryRes = this.getEntries()
					reject("");
				}
			);
	});
	}


	deleteEntry() {
		let payment_entry_id = localStorage.getItem('selected_payment_entry')
		return new Promise((resolve, reject) => {
			this.paymentService.deleteMethod(`paymententryv2?payment_entry_id=${payment_entry_id}`).subscribe(
				async (res) => {
					let response = res
					console.log('Response : ', response)
					let entryRes = await this.getEntries()
					resolve(response)
				},
				err => {
					let entryRes =  this.getEntries()
					reject("");
				}
			);
	});
	}

	getEntries() {
		let payment_id = this.paymentService.paymentForm.value.payment_id
		return new Promise((resolve, reject) => {
			if (payment_id != '') {
				this.paymentService.getMethod(`paymententryv2/getallbypaymentid?payment_id=${payment_id}`).subscribe((res: any) => {
					this.paymentService.paymentEntries = res
					this.commonService.selectedInput.next(4)
					resolve(res)
					this.spinner.hide();
				},
					err => {
						resolve(err)
						this.spinner.hide();
					})
			} else {
				resolve("")
				this.paymentService.paymentEntries = []
			}
		})
	}

	getTotalAmount(){
		let payment_id = this.paymentService.paymentForm.value.payment_id
		this.paymentService.getMethod(`paymententryv2/getTotal?payment_entry_id=${payment_id}`).subscribe(
			res => {
				let response = res
				this.paymentService.paymentForm.controls.amount.setValue(response['totalAmount'])
				console.log('Response : ', response)
			},
			err => {
			}
		);
		console.log()
	}
	async exitEntryPage(){
		this.spinner.show()
		await this.getTotalAmount()
		this.resetFormData()
		this.callExitEntry.emit(false)
		// this.paymentService.showAddPaymentScreen = false

	}

	resetFormData(){
		
		//Reset Amount
		this.paymentService.paymentEntryForm.controls.amount.setValue(0)

		//Reset Check Data 
		this.paymentService.paymentEntryForm.controls.cheque_no.setValue("")
		this.paymentService.paymentEntryForm.controls.cheque_date.setValue(null)


		//Reset Narrations
		this.paymentService.paymentEntryForm.controls.narration_1.setValue("")
		this.paymentService.paymentEntryForm.controls.narration_2.setValue("")
		this.paymentService.paymentEntryForm.controls.narration_3.setValue("")
		this.paymentService.paymentEntryForm.controls.narration_4.setValue("")
		this.paymentService.paymentEntryForm.controls.narration_5.setValue("")

		//Reset Remarks
		this.paymentService.paymentEntryForm.controls.remark_1.setValue("")
		this.paymentService.paymentEntryForm.controls.remark_2.setValue("")
		this.paymentService.paymentEntryForm.controls.remark_3.setValue("")
		this.paymentService.paymentEntryForm.controls.remark_4.setValue("")
		this.paymentService.paymentEntryForm.controls.remark_5.setValue("")

		this.spinner.hide()
	}

	getAccounts(url){
		this.paymentService.getMethod(url).subscribe(res=>{
			this.paymentService.accountList = res
		},err=>{

		})
	}
	getLedgers(url){
		this.paymentService.getMethod(url).subscribe(res=>{
			this.paymentService.ledgerList = res
		},err=>{

		})
	}
	onValueChange(event){
		this.amountField.nativeElement.focus()
	}

	onChangeSearch(value) {
		try {
			let searchType = this.paymentService.paymentEntryForm.value.account_code_to_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.paymentService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.paymentService.accountList = res;
				});
			}
		} catch (error) {

		}
	}

	onFocused(e) {

	}


	ledgerSearch(value){
		try {
			let searchType = this.paymentService.paymentEntryForm.value.sub_ledger_code_1_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.paymentService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.paymentService.ledgerList = res;
				});
			}
		} catch (error) {

		}
	}

	selectAccount(value,formControlName){
		try {
			this.paymentService.paymentEntryForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}

	setAccountAutoCompleteStatus(status){
		this.accountAutoComplete = status
	}

	resetSelection(formControlName){
		this.paymentService.paymentEntryForm.controls[formControlName].setValue('')
		this.paymentService.paymentEntryForm.controls[`${formControlName}_nmae`].setValue('')
		if(formControlName=='sub_ledger_code_1'){
			this.paymentService.ledgerList = []	
		}else{
			this.paymentService.accountList= []
		}
	}



}
