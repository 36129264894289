import { Type } from '@angular/core';

export class Tab {
  public id: number;
  public title: string;
  public tabData: any;
  public active: boolean;
  public component: Type<any>;
  public title_hover: any;

  constructor(component: Type<any>, title: string, tabData: any, title_hover: any) {
    this.tabData = tabData;
    this.component = component;
    this.title = title;
    this.title_hover = title_hover;
    this.active = tabData && tabData.active ? true : false
  }
}
