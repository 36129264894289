import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContraRoutingModule } from './contra-routing.module';
import { ContraComponent } from './contra.component';
import { CreateContraComponent } from './create-contra/create-contra.component';
import { ViewContraComponent } from './view-contra/view-contra.component';
import { AddEntryContraComponent } from './add-entry-contra/add-entry-contra.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContraService } from './services/contra.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonServiceService } from 'src/app/shared/common-service.service';


@NgModule({
  declarations: [ContraComponent, CreateContraComponent, ViewContraComponent, AddEntryContraComponent],
  imports: [
    CommonModule,
    ContraRoutingModule,
    SharedModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  providers : [ContraService],
  exports: [AddEntryContraComponent]
})
export class ContraModule { }
