<form [formGroup]="daybookFilterForm">
  <div class="flexbox aff-info">
    <div class="flexbox aff-info-inner">
      <div class="flexbox ">
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="1" formControlName="start_date" id="from_date" type="date"
            min="{{minDate}}" max="{{maxDate}}"
            [ngClass]="{'error-box': !daybookFilterForm.controls.start_date.valid}"
              />
          <label for="from_date">Starting Date</label>
        </div>
        <div class="input-fiel mr-0">
          <input class="input-ui" searchtabindex="2" formControlName="end_date" id="to_date" type="date"
            min="{{minDate}}" max="{{maxDate}}"
            [ngClass]="{'error-box': !daybookFilterForm.controls.end_date.valid}"
              />
          <label for="to_date">Ending Date</label>
        </div>
      </div>

      <div class="flexBox d-flex  daybookclientlist">
        <div class="input-fiel">
          <label class="radio-label" for="clientCheckBox">Client</label>
          <input class="radio-ui" id="clientCheckBox" type="checkbox" name="client" value="subgroup"
            searchtabindex="3" formControlName="client" />
        </div>
        <div class="input-fiel">
          <label class="radio-label" for="supplierCheckBox">Supplier</label>
          <input class="radio-ui" id="supplierCheckBox" type="checkbox" name="supplier" value="subgroup"
            searchtabindex="4" formControlName="supplier" />
        </div>
        <div class="input-fiel">
          <label class="radio-label" for="airlineCheckBox">Airline</label>
          <input class="radio-ui" id="airlineCheckBox" type="checkbox" name="airline" value="subgroup"
            searchtabindex="5" formControlName="airline" />
        </div>
        <div class="input-fiel">
          <label class="radio-label" for="generalCheckBox">General</label>
          <input class="radio-ui" id="generalCheckBox" type="checkbox" name="general" value="subgroup"
            searchtabindex="6" formControlName="general" />
        </div>
        <div class="input-fiel">
          <label class="radio-label" for="bankCheckBox">Bank</label>
          <input class="radio-ui" id="bankCheckBox" type="checkbox" name="bank" value="subgroup"
            searchtabindex="6" formControlName="bank" />
        </div>
      </div>

      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
            searchtabindex="8" #searchButton (keyup.enter)="clickSearchButton()"
            [disabled]="daybookFilterForm.invalid"
            (click)="search(daybookFilterForm.value)"> Search</button>
          <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
        </div>
      </div>
    </div>
  </div>
</form>