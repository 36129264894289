import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeneralComponent } from './general.component';
import { GeneralRoutingModule } from './general-routing.module';
import { SharedModule } from './../../shared/shared.module';
import { CreateGeneralComponent } from './create-general/create-general.component';
import { ViewGeneralComponent } from './view-general/view-general.component';
import { UpdateGeneralComponent } from './update-general/update-general.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CommonServiceService } from 'src/app/shared/common-service.service';


@NgModule({
  declarations: [GeneralComponent, CreateGeneralComponent, ViewGeneralComponent, UpdateGeneralComponent ],
  imports: [
    CommonModule,
    SharedModule,
    GeneralRoutingModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [UpdateGeneralComponent]
})
export class GeneralModule { }
