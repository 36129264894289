import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonServiceService } from '../../common-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment-timezone';
import { GlobalVariable } from '../../global-variable';
@Component({
  selector: 'app-account-search',
  templateUrl: './account-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class AccountSearchComponent implements OnInit {
  airlineFilterForm: FormGroup;
  keyword = 'name';
  accountArr : any;
  parentAccount : any;

  @ViewChild('subledgerAC',{static:true}) subledgerAC;
  @ViewChild('searchButton',{static:true}) searchButton : ElementRef;
  @ViewChild('clientAC',{static:true}) clientAC;

  constructor(
    public commonService: CommonServiceService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.airlineFilterForm = this.formBuilder.group({
      account: [''],
      account_name: [''],
      account_code: [''],
      parent_account : [''],
      parent_account_name : ['']


    })
	this.setFormData()
  }


  setFormData(){
	let queryParams = localStorage.getItem('activeAccountQueryParams')
	if(queryParams!=null){
		let parsedParams = JSON.parse(queryParams)
        this.getAccount(parsedParams.account ? parsedParams.account : "",true)
        this.getParentAccount(parsedParams.parent_account ? parsedParams.parent_account : "",true)
		this.airlineFilterForm.controls.account_code.setValue(parsedParams.account_code?parsedParams.account_code:"")
	}

  }

  getAccount(value,bindValue=false) {
		if (value.length > 2) {
			this.commonService.getOkount(`v3account/ledgerautocomplete?name=${value.toUpperCase()}`).subscribe(res => {
				this.accountArr = res
				if(bindValue && this.accountArr.length>0){
					this.airlineFilterForm.controls['account'].setValue(this.accountArr[0].account_code)
					this.airlineFilterForm.controls['account_name'].setValue(this.accountArr[0].name)
				}
			}, err => {
	
			})
		}
	}
  getParentAccount(value,bindValue=false) {
		if (value.length > 2) {
			this.commonService.getOkount(`v3account/ledgerautocomplete?name=${value.toUpperCase()}`).subscribe(res => {
				this.parentAccount = res
				if(bindValue && this.parentAccount.length>0){
					this.airlineFilterForm.controls['parent_account'].setValue(this.parentAccount[0].account_code)
					this.airlineFilterForm.controls['parent_account_name'].setValue(this.parentAccount[0].name)
				}
			}, err => {
	
			})
		}
	}


  search(values){
	  debugger
    	GlobalVariable.openModalStatus = false;
		if (values.date == "" || values.date == "Invalid Date" || values.date == "Invalid date") {
			values.date = ""
		}
		else{
			values.date = moment(values.date).format('YYYY-MM-DD')
		}

		let accountCode= values.account
		if (values.account.account_code != undefined) {
			accountCode= values.account.account_code
		} 
		let parentAccount= values.parent_account
		if (values.parent_account.account_code != undefined) {
			parentAccount= values.parent_account.account_code
		} 
		this.router.navigateByUrl(`/setup/account/view?account=${accountCode}&account_code=${values.account_code}&parent_account=${parentAccount}`)
	}

	ngAfterViewInit(){
		this.commonService.selectedSearchInput.subscribe(index=>{
			if (index == 1) {
				this.focusClientAc()
			}
			if(index == 3){
				this.focusSubledgerAc()
			}

		})
	}

	focusClientAc(){
		this.clientAC.focus();
	}

	focusSubledgerAc(){
		this.subledgerAC.focus();
	}

	clickSearchButton(){
		this.searchButton.nativeElement.click()
	}

	selectAccount(value,formControlName){
		try {
			this.airlineFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}

}
