<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
<div class="ledger-container"  [ngClass]="{ 'ledger-print-section': isPrint == 'true' }">
 
 
  <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
    <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
      <div class="d-flex flex-y-center">
        <img
          src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
            alertType=='success-box' ? 'green-sucess.svg' : 
            alertType=='warning-box' ? 'red-warning.svg' : 
            null }}"
          width="20px"
          height="20px"
          alt="alert-logo"
          class="mr-20"
        />
        <div class="ml-10">
          <h4 class="m-0 text-size-18 text-bold"
          [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
            {{alertType === "error-box"
            ? "Error"
            : alertType === "warning-box"
            ? "Warning"
            : alertType === "success-box"
            ? "Success"
            : null}}
          </h4>
          <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
           {{message}}
          </small>
        </div>
      </div>
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
        alt="close-btn"
        (click)="hideError()"
        className=" close-btn"
      />
    </div>
  </div>

  <div>
    <div class="" id="print-section">
      <div>
        <div style="background: #fff!Important;"  class="row leader-header tab-nav pl-0 pr-0">
          <div class="col-sm-4 pr-0">
            <div *ngIf="isPrint">
            </div>
            <ul
              class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0"
            >
              <li
                class="text-grey text-capitalize cursor text-primary transition"
                [ngClass]="{ 'd-none': isPrint == 'false' }"
                [ngClass]="{ 'd-none': isPrint == 'true' }"
              >
               GST- B2CS Adjustment
              </li>
            </ul>
          </div>
          <div class="col-sm-4 text-center">
            <h4 style="font-size: 17px;" *ngIf="isPrint" class="text-uppercase text-bold text-black mt-2">
              B2CS Statement<br>
            </h4>
            <span class="rec-date d-block text-center">
              <div class="noWrap justify-content-around">
                <ng-container>
                  <div class="cname text-bold">
                    <span  [ngClass]="{ 'font-size-15': isPrint == 'true' }"  *ngIf="duration && duration.from_date" 
                      >From</span
                    >
                    <h5 style="margin-right: 5px;" class="mb-0 d-inline">
                      <span [ngClass]="{ 'font-size-15': isPrint == 'true' }" 
                        class="date text-bold text-orange"
                        *ngIf="duration && duration.from_date"
                      >
                        {{ duration && duration.from_date }}
                      </span>
                      <span [ngClass]="{ 'font-size-15': isPrint == 'true' }"  class="date text-bold" *ngIf="duration && duration.from_date">
                        To
                      </span>
                      <span [ngClass]="{ 'font-size-15': isPrint == 'true' }"  class="date text-bold text-orange">
                        {{ duration && duration.to_date }}
                      </span>
                    </h5>
                  </div>
                </ng-container>
              </div>
            </span>
          </div>
          <div class="col-sm-4 d-flex align-item-center justify-content-end">
            <div class="text-right mt-2" *ngIf="isPrint">
              <span class="d-block text-black" [ngClass]="{ 'font-size-12': isPrint == 'true' }">{{currentDate | date : 'dd/MM/yyyy'}}</span>
              <span class="d-block text-grey"  [ngClass]="{ 'font-size-12': isPrint == 'true' }"> {{currentTime}}</span>
            </div>
			<div
			class="page-search-box input-group d-flex align-item-center mr-10" 
      [ngClass]="{ 'd-none': isPrint == 'true' }"
			style="height: fit-content; text-align: left"
		  >
			<input
			  type="text"
			  class="form-control"
			  [(ngModel)]="onSearch"
			  oninput="this.value = this.value.toUpperCase()"
			  (ngModelChange)="this.onSearchFilter.next($event)"
			  placeholder="search"
			  style="text-align: left"
			/>
		  </div>
            <div class="mt-2 align-item-center">
                      
            </div>
          </div>
        </div>

      </div>
      <div
      >
      <div class="table-section">
        <table class="table table striped" >
          <thead #tableHeader tabIndex="0">
            <tr *ngIf="isPrint" style="border-bottom: none!important;">
              <th colspan="7" class="page-top-space">
              </th>
            </tr>
            <tr class="transition">
              <th class="text-left date" style="max-width: 105px">Date</th>
              <th style="max-width: 160px " class="text-left doc-no ">
                <span class="text-bold"
                  >Doc No
                </span>
              </th>
              <th class="text-left particular">Account Description</th>
              <th class="text-left particular">Account</th>
              <th class="text-right voucher"><span class="text-center d-block text-bold"> Voucher</span></th>
              <th class="text-right balance" style="max-width: 70px">
               <span class="text-right d-block text-bold" > Balance</span>
              </th>
              <th class="text-center" style="max-width: 122px">
                <span >Adjust</span>
               </th>
            </tr>
          </thead>
          <tbody
            class="scroll-body main-panel"
            [ngStyle]="{ height: innerHeight }"
            (window:keydown)="onKeyDown($event)"
          >
                <tr class="table-row-break"
                    *ngFor="let record of gstB2CSArr; let i = index"
                    (keyup.enter)="openViewMode(record?.reference, record?.reference_type)"
                  >

                  <td
                    style="max-width: 105px"
                    class="text-left text-grey date"
                  >
                    {{ record?.invoice_date | date: "dd/MM/yyyy" }}
                  </td>
                  <td
                    style="max-width: 160px "
                    class="text-left transition text-bold cursor doc-no refer"
                  >
                    <a
                      [ngClass]="{
                        ' underline-none':
                          record.reference != null &&
                          record.reference != undefined
                      }"
                      (click)="openViewMode(record?.reference, record?.reference_type)"
                     
                      style="font-weight: 600;">{{ record?.reference }}</a
                    >
                    <a class="d-block" *ngIf="record?.booking_ref_no" style="font-weight: 600;">{{record?.booking_ref_no}}</a>
                  </td>

                  <td  class="text-left text-grey particular">
                    <ng-container *ngFor="let narration of record?.narration.split(',')">
                      <ng-container *ngIf="narration.trim() != '' &&  narration.trim() != '|'">
                        <span class="text-grey d-block">{{ narration }} </span>
                      </ng-container>
                    </ng-container>
                  </td>

                  <td class="text-left text-grey">{{record?.affiliate_name}}</td>

                  <td  class="text-right text-grey voucher">
                    <span class="text-center d-block">
                      {{ record?.reference_type }}
                    </span>
                  </td>
                  
                  <td
                    class="text-right text-blue balance"
                    style="max-width: 70px"
                  >
                    {{ record?.invoice_amount | number :'1.2-2'}}
                  </td>

                  <td class="text-center mt-2" style="max-width: 122px">
                    <ng-container *ngIf="record.taxable == 'YES' else checkboxD">
                      <img width="20px" height="20px" alt="alert-logo" 
                        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-sucess.svg">
                    </ng-container>
                    <ng-template #checkboxD>
                      <input class="radio-ui" id="is_adjusted_{{record.reference}}" type="checkbox"
                         (change)="onItemChecked(record, $event)" />
                    </ng-template>
                   </td>
                </tr>
      
            <tr *ngIf="gstB2CSArr && gstB2CSArr.length == 0">
              <div class="w-100 text-center" style="padding: 20px">
                <span
                  ><img
                    class="not-found-img"
                    src="{{commonService.aws_asset_url}}/_assets/images/problem.png"
                /></span>
                <br />
                <span class="not-found-text">No result found</span>
              </div>
            </tr>
          </tbody>
          
        </table>
      </div>
      
      </div>
      <div class="balance-box">
        <div style="display: flex;align-self: center;">
          <button class="btn-style bg-purple ml-10" (click)="saveSelectedData()">
            Save
          </button>   
        </div>
        <div class="balance-container">
          <div class="bal-item text-bold">
            <span>Balance</span>
            <span class="bal-amount debit-amt text-bold" >
              {{totalAmountObj && totalAmountObj.total_amount | number: "1.2-2" }}
            </span>
          </div>
          <div class="bal-item text-bold">
            <span>Total Taxable Amount</span>
            <span class="bal-amount debit-amt text-bold">
              {{totalAmountObj && totalAmountObj.total_taxable | number: "1.2-2" }}
            </span>
          </div>
          <div class="bal-item text-bold">
            <span>Total Remaining Amount</span>
            <span class="bal-amount debit-amt text-bold">
              {{ totalAmountObj && totalAmountObj.remaining_balance | number: "1.2-2" }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

