export enum ActionTypes {
    SetOpenAdvanceSearchModal = '[Open Advance search modal]',
    SetNarrtionsArr = '[narration_1,narration_2,narration_3,narration_4,narration_5]'
  
  }
  
  
  import { Action } from '@ngrx/store';
    
  export class OpenAdvanceSearchModal implements Action {
    readonly type =  ActionTypes.SetOpenAdvanceSearchModal;
    //add an optional payload
    constructor(public payload: any) {}
  }

  export class SetNarrationsArr implements Action {
    readonly type =  ActionTypes.SetNarrtionsArr;
    //add an optional payload
    constructor(public payload: any) {}
  }


  

  
  
  