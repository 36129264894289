import { Component, OnInit ,ElementRef,ViewChild} from '@angular/core';
import { Store } from '@ngrx/store';
import { NgRedux } from "@angular-redux/store";
import { InitialState } from 'src/app/store/reducer';
import * as moment from 'moment-timezone';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { CommonServiceService } from '../../common-service.service';
import { Router } from '@angular/router';
import { GlobalVariable } from '../../global-variable';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-missing-transactions-search',
  templateUrl: './missing-transactions-search.component.html',
  styleUrls: ['./missing-transactions-search.component.scss']
})
export class MissingTransactionsSearchComponent implements OnInit {
  clientArr: any;
	minDate = "2023-04-01"
  maxDate = moment().format('YYYY-MM-DD')
  missingTransactionFilterForm: FormGroup;
  keyword = 'name';
  @ViewChild('searchButton',{static:true}) searchButton : ElementRef;
  @ViewChild('clientAC',{static:true}) clientAC;
  constructor(
    private store: Store<InitialState>,
    private formBuilder: FormBuilder,
    public commonService: CommonServiceService,
    private router: Router
  ) { 

    this.store.select('sharedReducers').subscribe((items)=>{

      if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
        this.minDate = items.financeYear.start_date
        this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
                    moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      } else {
        let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
        if(!current_financial_year) {
          current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
        }
        this.minDate = current_financial_year.start_date
        this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
                    moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      }
        
    })
  }

  ngOnInit(): void {

    this.missingTransactionFilterForm = this.formBuilder.group({
			start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate,this.checkDate('end_date')]
      
		})

    this.setFormData()

  }


  checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}

    getClient(value,bindValue=false) {
      if (value.length > 2) {
        this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
          this.clientArr = res
          //if(bindValue && this.clientArr.length>0){
           // this.missingTransactionFilterForm.controls['client'].setValue(this.clientArr[0].account_code)
           // this.missingTransactionFilterForm.controls['client_name'].setValue(this.clientArr[0].name)
          //}
        }, err => {
    
        })
      }
    }
      setFormData() {
        let queryParams = localStorage.getItem('activeMissingTransactionQueryParams')
        if (queryParams != null) {
          let parsedParams = JSON.parse(queryParams)
          if (parsedParams.start_date != "") {
            this.missingTransactionFilterForm.controls.start_date.setValue(moment(parsedParams.start_date).format('YYYY-MM-DD'))
          }
          if (parsedParams.end_date != "") {
            this.missingTransactionFilterForm.controls.end_date.setValue(moment(parsedParams.end_date).format('YYYY-MM-DD'))
          }

        }
      }

      search(values){
        GlobalVariable.openModalStatus = false;
        if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
          values.start_date = ""
        }
        else{
          values.start_date = moment(values.start_date).format('YYYY-MM-DD')
        }
        if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date" || values.end_date == "Invalid date") {
          values.end_date = ""
        }
        else{
          values.end_date = moment(values.end_date).format('YYYY-MM-DD')
        }

        localStorage.setItem("activeMissingTransactionQueryParams",JSON.stringify(values))
        this.router.navigateByUrl(`utilities/missing-transactions?start_date=${values.start_date}&end_date=${values.end_date}`)
        //  this.reset()
        }

      getFilteredData(queryParams) {
        this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/daybook`, queryParams).subscribe(res => {
            console.log(res);
        }, err => {
            console.error(err);
        })
    }

      reset(){
        GlobalVariable.openModalStatus = false;
        this.missingTransactionFilterForm = this.formBuilder.group({
          start_date: [this.minDate],
          end_date: [this.maxDate]
        })
        localStorage.removeItem('activeMissingTransactionQueryParams')
      }

      clickSearchButton(){
        this.searchButton.nativeElement.click()
      }

      focusClientAc(){
        this.clientAC.focus();
      }

      selectAccount(value,formControlName){
        try {
          this.missingTransactionFilterForm.controls[formControlName].setValue(value.account_code)
        } catch (error) {
    
        }
      }
}
