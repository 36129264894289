import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StatementAccountComponent } from './statement-account.component';
import { OutstandingReportComponent } from './outstanding-report/outstanding-report.component';


const routes: Routes = [
  {
    path: '', component: StatementAccountComponent,
    children:[
      {path: 'outstanding-report', component: OutstandingReportComponent},
      {
        path:'',
        redirectTo: 'outstanding-report',
        pathMatch: 'full'
      }
    ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatementAccountRoutingModule { }
