
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
	<div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
	  <div class="d-flex flex-y-center">
		<img
		  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
			alertType=='success-box' ? 'green-sucess.svg' : 
			alertType=='warning-box' ? 'red-warning.svg' : 
			null }}"
		  width="20px"
		  height="20px"
		  alt="alert-logo"
		  class="mr-20"
		/>
		<div class="ml-10">
		  <h4 class="m-0 text-size-18 text-bold"
		  [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
			{{alertType === "error-box"
			? "Error"
			: alertType === "warning-box"
			? "Warning"
			: alertType === "success-box"
			? "Success"
			: null}}
		  </h4>
		  <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
		   {{message}}
		  </small>
		</div>
	  </div>
	  <img
		src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
		alt="close-btn"
		(click)="hideError()"
		className=" close-btn"
	  />
	</div>
  </div>
  
<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>


<button type="button" hidden #openSuccessModal data-toggle="modal" data-target="#successModal"></button>

			<!-- Modal -->
			<div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
				<div class="modal-dialog transaction-creation-modal">
					<!-- Modal content-->
					<div class="modal-content">
						<div class="modal-header">
							<span class="messageFont">
								<span>
									{{message}} 
								</span>
									<a *ngIf="invoice_number != ''" 
										href="javascript:void(0)"	
										(click)="openVoucher(invoice_number)">
										{{invoice_number}}
									</a>
									<a *ngIf="invoice_number != ''" [title]="copyELementTitle" (click)="copytext(invoice_number)">

										<i  class="fa fa-copy cursor-pointer"></i>
									</a>
								</span>
								<button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
							</div>
						<div class="modal-body">
							
							<p>Do you want to create new invoice or  view newly created Invoice ?</p>
						</div>
						<div class="modal-footer justify-content-center">
							<button 
								tabIndex="1" 
								tabindex="1"
								type="button" 
								class="btn btn-primary bg-blue" 
								(click)="viewInvoice(invoice_number)">
								View
							</button>
							<button 
								tabIndex="2" 
								tabindex="2"
								type="button" 
								class="btn btn-primary btn-grey" 
								(click)="openVoucher(invoice_number)">
								Print
							</button>
							<button 
								tabIndex="3" 
								tabindex="3"
								type="button" 
								class="btn btn-primary bg-green"  
								(click)="closeSuccesModal()">
								Create New
							</button>
						</div>
					</div>

				</div>
			</div>



<form [formGroup]="tourService.invoiceForm" [ngClass]="{'d-none': !newInvoice}" class="ledger-container ">
	<div class="row tab-nav pl-0 pr-0">
		<!-- <div class="col-sm-4 pl-0">
			<ul class="list-inline list-unstyled p-0 m-0">
				<li class="active page-title text-uppercase cursor"> View Client</li>
			</ul>
		</div> -->
		<div class="col-sm-12 ">
		  <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
			<li class="text-grey text-capitalize cursor text-primary transition">Invoice</li>
			<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-right.svg" alt=""></li>
			<li class="text-grey text-capitalize cursor  hover-text-primary transition"> Tour</li>
			<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-right.svg" alt=""></li>
			<li class="text-grey text-capitalize cursor  hover-text-primary transition"> {{transactionType}}</li>
		  </ul>
	  </div>
	</div>
<div class="pl-15 pr-15 custom-form form-container w-100 mb-4">
	<div class=" mb-0 mt-2 mb-fix tourInvoiceServiceContainer">
		<div class="invoice-container invoice-table">
			<div>
				<table class="table table-hovered table-bordered top-miscellaneous-bar w-100">
					<thead>
						<tr>
							<th style="width: 9%;">Acc. Type</th>
							<th style="width: 18%;">Account</th>
							<th style="width: 8%;">Inv Type</th>
							<th style="width: 12%;" *ngIf="tourService.invoiceForm.value.sub_invoice_type == 'tour'">Tour Account</th>
							<th *ngIf="IncomeSetOff=='true'">General Ledger</th>
							<th style="width: 9%;">Sub Ledger Type</th>
							<th style="width: 14%;">Sub-ledger</th>
							<th style="width: 10%;">Invoice Date</th>
							<th style="width: 14%;" *ngIf="!isTenantIndian">Purchase currency</th>
							<th style="width: 4%;" *ngIf="!isTenantIndian">Rate({{currencyCode}}-{{tourService.invoiceForm.value.purchase_currency}})</th>
							<th style="width: 14%;" *ngIf="tourService.invoiceForm.value.reference_invoice_number">Reference</th>
							<th style="width: 14%;">Invoice Number</th>
							<th style="width: 14%;">Employee</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<select class="inner-select" tabIndex="4" tabindex="4"
									formControlName="client_account_type" (change)="resetSelection('client_code')">
									<option value="">Select</option>
									<option value="client">Client</option>
									<option value="airline">Airline</option>
									<option value="supplier">Supplier</option>
									<option value="general">General</option>
								</select>
							</td>
							<!-- client -->
							<td style="text-align: left; width: 200px;">
								<!-- <ng-autocomplete [data]="bookingClientArr" #clientAC formControlName="client_code_name"
									[searchKeyword]="keyword" (selected)="selectAccount($event,'client_code', null, true)"
									(inputChanged)='clientSearch($event,true, true)' (inputFocused)='onFocused($event)'
									(inputCleared)="selectAccount($event,'client_code', null, true)"
									[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
								</ng-autocomplete>

								<ng-template #itemTemplate let-item>
									<a innerHTML='<span class="colr-1">{{item?.name}}
										({{item?.account_code}})</span>'>
									</a>
								</ng-template>

								<ng-template #notFoundTemplate let-notFound>
									<div [innerHTML]="notFound"></div>
								</ng-template> -->

								<div class="autocomplete-container" >
									<div class="input-container"  (focus)="focusClientAc()">
										<input tabIndex="5" tabindex="5"
											type="text" #clientAC oninput="this.value = this.value.toUpperCase()" 
											class="autocomplete-container" name="client_code_name" 
											formControlName="client_code_name"
											(input)="clientSearch($event,true, true)"
											[nzAutocomplete]="accountCodeAuto"
										/>
				
										<nz-autocomplete #accountCodeAuto [nzBackfill]="true"  (selectionChange)="selectAccount($event,'client_code', null, true)">
											<nz-auto-option  class="colr-1" *ngFor="let item of bookingClientArr" [nzValue]="item" [nzLabel]="item.name">
												<a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
											</nz-auto-option>   
											<nz-auto-option *ngIf="showCreateButton && currentFormControlName == 'client_code'" class="colr-1" nzValue="" style="text-align: center"
												nzLabel="Create New">
												<span>Create New</span>                          
											</nz-auto-option>                          
										</nz-autocomplete>
									</div>	
								</div>

							</td>

							<td>
								<select class="inner-select"
									formControlName="sub_invoice_type" >
									<option value="normal">Normal</option>
									<option value="tour">Tour</option>
								</select>
							</td>
							<!-- tour -->
							<td *ngIf="tourService.invoiceForm.value.sub_invoice_type == 'tour'"  (focus)="focusTourAc()"
								style="text-align: left; width: 200px;">
								<ng-autocomplete [data]="bookingTourArr" #tourAC formControlName="tour_code_name"
									[searchKeyword]="keyword" (selected)="selectAccount($event,'tour_code', null,true)"
									(inputChanged)='tourSearch($event,true,true)' (inputFocused)='onFocused($event)'
									(inputCleared)="selectAccount($event,'tour_code', null,true)"
									[itemTemplate]="itemTemplate" 
									>
									<!-- [ngClass]="{'validate-ng-error': (miscellaneousInvoiceService.invoiceForm.controls.client_code_name.invalid && miscellaneousInvoiceService.invoiceForm.controls.client_code_name.touched) || 
									(!miscellaneousInvoiceService.invoiceForm.controls.client_code_name.valid && sectorService.submitted)}" -->
								</ng-autocomplete>

								<ng-template #itemTemplate let-item>
									<a
									    innerHTML='<span class="colr-1 selected-ng">{{item.name}}<span *ngIf="item.account_code">({{item.account_code}})<span></span><br />'>
								    </a>
								</ng-template>

							</td>

							<td *ngIf="IncomeSetOff=='true'" (focus)="focusGeneralLedgerAc()"
								style="text-align: left; width: 200px;">
								<!-- <ng-autocomplete [data]="bookingGenerlaAccountArr" #generalLedgerAc formControlName="general_ledger_code_name"
									[searchKeyword]="keyword" (selected)="selectAccount($event,'general_ledger_code', null, true)"
									(inputChanged)='generalSearch($event,false,true, true)' (inputFocused)='onFocused($event)'
									(inputCleared)="selectAccount($event,'general_ledger_code', null, true)"
									[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
								</ng-autocomplete>

								<ng-template #itemTemplate let-item>
									<a innerHTML='<span class="colr-1">{{item?.name}}
										({{item?.account_code}})</span>'>
									</a>
								</ng-template>

								<ng-template #notFoundTemplate let-notFound>
									<div [innerHTML]="notFound"></div>
								</ng-template> -->

								<div class="autocomplete-container" >
									<div class="input-container"  (focus)="focusGeneralLedgerAc()">
										<input tabIndex="6" tabindex="6" 
											type="text" #generalLedgerAc oninput="this.value = this.value.toUpperCase()" 
											class="autocomplete-container" name="general_ledger_code_name" 
											formControlName="general_ledger_code_name"
											(input)="generalSearch($event,false,true, true)"
											[nzAutocomplete]="generalLedgerAutocomplete"
										/>
				
										<nz-autocomplete #generalLedgerAutocomplete [nzBackfill]="true"  (selectionChange)="selectAccount($event,'general_ledger_code', null, true)">
											<nz-auto-option  class="colr-1" *ngFor="let item of bookingGenerlaAccountArr" [nzValue]="item" [nzLabel]="item.name">
												<a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
											</nz-auto-option>   
											<nz-auto-option *ngIf="showCreateButton && currentFormControlName == 'general_ledger_code_name'" class="colr-1" nzValue="" style="text-align: center" nzLabel="Create New">
												<span>Create New</span>                          
											</nz-auto-option>                          
										</nz-autocomplete>
									</div>	
								</div>


							</td>
							<td>
								<select class="inner-select" tabIndex="7" tabindex="7" formControlName="subledger_type">
									<option value="">Select</option>
									<option value="client">Client</option>
									<option value="airline">Airline</option>
									<option value="supplier">Supplier</option>
									<option value="employee">Employee</option>
									<option value="general">General</option>
								</select>
							</td>
							<td style="text-align: left;
							width: 135px;"  >
								<!-- <ng-autocomplete [data]="tourService.ledgerList" #subLedgerAC
									[searchKeyword]="keyword" (selected)="selectAccount($event,'subledger_code', null, true)"
									formControlName="subledger_code_name" (inputChanged)='ledgerSearch($event, true)'
									(inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
									(inputCleared)="selectAccount($event,'subledger_code', null, true)"
									[notFoundTemplate]="notFoundTemplate">
								</ng-autocomplete>
	
	
								<ng-template #itemTemplate let-item>
									<a
										innerHTML='<span class="colr-1 selected-ng">{{item.name}}({{item.account_code}})</span><br />'>
									</a>
								</ng-template>
	
								<ng-template #notFoundTemplate let-notFound>
									<div [innerHTML]="notFound"></div>
								</ng-template> -->
	
								<div class="autocomplete-container" >
									<div class="input-container"  (focus)="focusSubledgerAc()">
										<input tabIndex="8" tabindex="8"
											type="text" #subLedgerAC oninput="this.value = this.value.toUpperCase()" 
											class="autocomplete-container" name="subledger_code_name" 
											formControlName="subledger_code_name"
											(input)="ledgerSearch($event, true)"
											[nzAutocomplete]="subLedgerAutocomplete"
										/>
				
										<nz-autocomplete #subLedgerAutocomplete [nzBackfill]="true"  (selectionChange)="selectAccount($event,'subledger_code', null, true)">
											<nz-auto-option  class="colr-1" *ngFor="let item of tourService.ledgerList" [nzValue]="item" [nzLabel]="item.name">
												<a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
											</nz-auto-option>   
											<nz-auto-option *ngIf="showCreateButton && currentFormControlName == 'subledger_code'" class="colr-1" nzValue="" style="text-align: center" nzLabel="Create New">
												<span>Create New</span>                          
											</nz-auto-option>                          
										</nz-autocomplete>
									</div>	
								</div>

							</td>
							
							<td>
								<input tabIndex="9" tabindex="9" style="min-width: max-content; padding: 0;"
									min="{{minDate}}" 
									max="{{maxDate}}"
									class="input-field" type="date" formControlName="invoice_date"
									(blur)="setServiceDate($event)"
									placeholder="MM/DD/YYYY" autocomplete="off" 
									[ngClass]="{'error-box': tourService.invoiceForm.controls.invoice_date.dirty && !tourService.invoiceForm.controls.invoice_date.valid}"/>
							</td>
							<td *ngIf="!isTenantIndian">
								<select id="flightType" tabIndex="10" tabindex="10" class="input-ui inv-date-input" formControlName="purchase_currency">
									<option value="">Select</option>
									<option value="AED">AED</option>
									<option value="CAD">CAD</option>
									<option value="USD">USD</option>
									<option value="INR">INR</option>
									<option value="EURO">EURO</option>
									<option value="POUND">POUND</option>
								</select>
							</td>
							<td *ngIf="!isTenantIndian">
								<input tabIndex="11" tabindex="11" type="text" class="field-input text-center" name=""  formControlName="purchase_currency_rate"/>
							</td>
							<td>
								<ng-container  *ngIf="invoiceMode == 'save'; else invoiceNumber">-</ng-container>
								<ng-template #invoiceNumber>
									<span style="min-width: max-content; padding: 0;">{{tourService.invoiceForm.value.invoice_number}}</span>
								</ng-template>
							</td>
							<td tabIndex="{{isTenantIndian ? 10 : 12}}" tabindex="{{isTenantIndian ? 10 : 12}}" class="text-center" (focus)="focusEmployeeAc()">

								<ng-autocomplete [data]="employeeList" #employeeAC [searchKeyword]="keyword"
									formControlName="employee_name" (selected)="selectAccount($event,'employee', null, true)"
									(inputChanged)='employeeSearch($event)' (inputFocused)='onFocused($event)'
									[itemTemplate]="itemTemplate"
									[disabled]="true"
									[notFoundTemplate]="notFoundTemplate">
								</ng-autocomplete>
	
	
								<ng-template #itemTemplate let-item>
									<a
										innerHTML='<span class="colr-1 selected-ng">{{item.name}}({{item.account_code}})</span><br />'>
									</a>
								</ng-template>
	
								<ng-template #notFoundTemplate let-notFound>
									<div [innerHTML]="notFound"></div>
								</ng-template>
	
								<span class="text-danger"
									*ngIf="tourService.invoiceForm.value.employee == ''">Please Enter
									Employee</span>
							</td>
						</tr>
					</tbody>
				</table>

			</div>
			<div style="display: flex;justify-content: center;margin: 10px 0;"> 
				<button *ngIf="invoiceMode != 'view' && tourService.invoiceForm.value.sub_invoice_type == 'tour'" type="button" class="btn-style bg-indigo mr-3" (click)="retrieveTour()">
					Retrieve 
				</button>
			</div>


			<form [formGroup]="tourService.travellerForm" *ngIf="tourService.travellerList != undefined && tourService.invoiceForm.value.sub_invoice_type !== 'tour'">

				<div class="traveller-outer-container"
					[ngClass]="maximizedSection ? 'traveller-outer-container section-maximized ' : 'traveller-outer-container  '">
					
						<div class="d-flex align-items-center justify-content-between" [ngClass]="maximizedSection ? ' section-head-full' : 'section-head text-grey'">
							<span [ngClass]="maximizedSection ? 'section-head text-black ' : 'section-head text-grey'">Services</span><span (click)="minimizeSection()" *ngIf="maximizedSection" class="minimize"><i
								class="fas fa-compress-arrows-alt mr-3"></i>Minimize</span>
						</div>
					<div  class="traveller-section "
						[ngClass]="maximizedSection ? ' maximized-modal' : 'traveller-section'">
			
						<div class="traveller-col tourInvoiceServiceContainer">
							<table style="table-layout: fixed;" class="table table-hovered table-bordered w-100">
								<thead>
									<tr>
										<th style="width: 25px;">#</th>
										<th>Service</th>
										<th >Start Date</th>
										<th >End Date</th>
										<th *ngIf="corporate_employee=='false'" class="text-left" style=" width: 150px;">Lead Traveller</th>
										<th *ngIf="corporate_employee=='true'" class="text-left" style=" width: 150px;">Lead Traveller</th>
										<th *ngIf="corporate_employee=='true'" class="text-left" style=" width: 70px;">E CODE</th>
										<th class="text-left" style=" width: 30px;">QTY</th>
										<th class="text-left" >Ref</th>
										<th *ngIf="supplier_trans=='true'" class="text-left" style="width: 110px;">Payable Trans. No.</th>
										<th *ngIf="supplier_trans=='true'" class="text-center" >Payable Date</th>
										<th style=" width: 75px;">Pay Type</th>
										<th style=" width: 120px;">Card</th>
										<th style=" width: 75px;">Payable Type</th>
										<th style=" width: 140px;">Payable</th>
										<th *ngIf="associate_supp=='true'" style="width:130px;" >Associate Supplier</th>
										<th *ngIf="salesPersonConfig=='true'" class="text-left">sales executive</th>
										<th *ngIf="referredByConfig=='true'" class="text-left">Referred By</th>
										<th *ngIf="costCenterConfig=='true'" class="text-left">COST CENTER</th>
										<!-- <th  class="text-right">Basic</th>
										<th  class="text-right">Tax</th> -->
										
										<!-- <th  class="text-right">TDS</th>
										<th  class="text-right" *ngIf="isTenantIndian">GST</th>
										<th  class="text-right" *ngIf="!isTenantIndian">{{vatAlternetname}}</th> -->
										<!-- <th  class="text-right">Total</th> -->
										<th >Action</th>
									</tr>
								</thead>
								<tbody formArrayName="travellerList">
									<ng-container *ngFor="let travellers of DynamicFormControls.controls; let i = index">
										<ng-container [formGroupName]="i">
											<tr class=" _table_row">
												<td style="width: 30px; " class="text-center">
													{{i+1}}
												</td>
												<td>
													<select 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+1}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+1}}" 
													type="text" style="min-width: -webkit-fill-available;" type="text"
													class="field-input w-100" formControlName="service_name" >
													<option value="">Select</option>
													<option value="flight">Flight</option>
													<option value="hotel">Hotel</option>
													<option value="taxi">Taxi</option>
													<option value="insurance">Insurance</option>
													<option value="visa">Visa</option>
													<option value="other">Other</option>
													</select>
												</td>
												<td>
													<input 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+2}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+2}}" 
													type="date" style="min-width: -webkit-fill-available;" 
													(focus)="maximizeSection(i,1)"
													class="field-input w-100" formControlName="start_date" />
												</td>
												
												<td>
													<input 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+3}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+3}}" 
													type="date" style="min-width: -webkit-fill-available;" 
													(focus)="maximizeSection(i,1)"
													class="field-input w-100" formControlName="end_date" />
												</td>
												
												<td *ngIf="corporate_employee=='false'">
													<input 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+4}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+4}}"
													oninput="this.value = this.value.toUpperCase()"
													type="text" class="w-100" formControlName="lead_pax_name" />
												</td>

												<td *ngIf="corporate_employee=='true'">
													<div class="autocomplete-container">
														<div class="w-100" (focus)="focusCorporateEmployeeAc()"  >
													<input #corporateEmployeeAc
													tabIndex="{{i*totalInputInTravellers+staticInputCount+4}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+4}}"
													oninput="this.value = this.value.toUpperCase()"
													type="text" class="w-100" formControlName="lead_pax_name"
													(input)="getCorporateEmployee($event, i)"
													 [nzAutocomplete]="employeeAutoComplete" />
													</div>
													<nz-autocomplete #employeeAutoComplete [nzBackfill]="true" (selectionChange)="selectEmployee($event, i)" >
														<nz-auto-option class="colr-1" *ngFor="let item of employeeArr" [nzValue]="item" [nzLabel]="item.account_name " >
															<a innerHTML='<span class="colr-1">{{item?.account_name}}
																	({{item?.account_code}})</span>'>
															</a>                           
														</nz-auto-option>                      
												</nz-autocomplete>
													</div>
												</td>

												<td *ngIf="corporate_employee=='true'"><input
													oninput="this.value = this.value.toUpperCase()"
													formControlName="e_code" maxlength="70" disabled
													class="w-100"
													type="text" 
													/>
											</td>
												<td>
													<input 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+5}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+5}}"
													(focus)="handleMaximizeSection(i)"
													type="text" class="w-100" maxlength="15" formControlName="quantity"/>
												</td>
												<td>
													
													<input 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+6}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+6}}"
													(focus)="handleMaximizeSection(i)"
													type="text" class="w-100" maxlength="15" formControlName="reference_number"/>
												</td>
			
												<td *ngIf="supplier_trans=='true'">
													<input 
													oninput="this.value = this.value.toUpperCase()"
													(focus)="maximizeSection(i,4)"
													type="text" class="w-100" maxlength="50" formControlName="payable_transaction_code"/>
												</td>
		
												<td *ngIf="supplier_trans=='true'"> 
															<input 
															type="date" style="width: 86px!important;" 
															(focus)="maximizeSection(i,2)" 
															class="field-input w-100" formControlName="payable_date" />
														</td>
		
			
			
												<td>
													<select 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+7}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+7}}"
													class="select-input w-100" formControlName="pay_type" (change)="handlePayTypeChange($event,i)">
														<option value="">Select</option>
														<option value="OUR_CC">Our CC</option>
														<option value="AGENT_CC">Agent CC</option>
													</select>
												</td>
												
												<td tabIndex="{{i*totalInputInTravellers+staticInputCount+8}}"  
												attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+8}}"
												(focus)="focusLedgerCardAc()"
													style="text-align: left;">
													<ng-autocomplete [data]="generalAccountArr" #ledgerCardAC formControlName="ledger_card_name"
														[searchKeyword]="keyword" (selected)="selectAccount($event,'ledger_card_no', i, true)"
														(inputFocused)='onFocused($event)'
														(inputCleared)="selectAccount($event,'ledger_card_no', i, true)"
														[disabled]="travellers.value.pay_type != 'OUR_CC' "
														[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
													</ng-autocomplete>

													<ng-template #itemTemplate let-item>
														<a innerHTML='<span class="colr-1">{{item?.name}}
															({{item?.account_code}})</span>'>
														</a>
													</ng-template>

													<ng-template #notFoundTemplate let-notFound>
														<div [innerHTML]="notFound"></div>
													</ng-template>

												</td>

												<td>
													<select 
													tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC' ? 8 : 9 )}}"  
													attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC' ? 8 : 9 )}}"
													class="select-input w-100" formControlName="payable_account_type">
													<option value="">Select</option>
													<option value="client">Client</option>
													<option value="airline">Airline</option>
													<option value="supplier">Supplier</option>
													<option value="employee">Employee</option>
													<option value="general">General</option>
													</select>
												</td>

												<td tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 9 : 10)}}"  
												attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 9 : 10)}}"
													style="text-align: left;" [ngClass]="{'error-box': travellers.controls.payable_account_name.dirty && !travellers.controls.payable_account_name.valid}">
													
													<ng-autocomplete [data]="payablesArr" formControlName="payable_account_name"
														[searchKeyword]="keyword" (selected)="selectAccount($event,'payable_code', i, true)"
														(inputChanged)="payableSearch($event, i, true)" (inputFocused)='onFocused($event)'
														(inputCleared)="selectAccount($event,'payable_code', i, true)"
														[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
													</ng-autocomplete>

													<ng-template #itemTemplate let-item>
														<a innerHTML='<span class="colr-1">{{item?.name}}
															({{item?.account_code}})</span>'>
														</a>
													</ng-template>

													<ng-template #notFoundTemplate let-notFound>
														<div [innerHTML]="notFound"></div>
													</ng-template>

												</td>

												<td *ngIf="associate_supp=='true'"
													style="text-align: left;" [ngClass]="{'error-box': travellers.controls.associate_payable_account_name.dirty && !travellers.controls.associate_payable_account_name.valid}">
													
													<ng-autocomplete [data]="associatePayablesArr" formControlName="associate_payable_account_name"
													[disabled]="!travellers.value.payable_account_is_branch"
														[searchKeyword]="keyword" (selected)="selectAccount($event,'associate_payable_code', i, true)"
														(inputChanged)="associatePayableSearch($event, i)" (inputFocused)='onFocused($event)'
														(inputCleared)="selectAccount($event,'associate_payable_code', i, true)"
														[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
													</ng-autocomplete>

													<ng-template #itemTemplate let-item>
														<a innerHTML='<span class="colr-1">{{item?.name}}
															({{item?.account_code}})</span>'>
														</a>
													</ng-template>

													<ng-template #notFoundTemplate let-notFound>
														<div [innerHTML]="notFound"></div>
													</ng-template>

												</td>
												<td *ngIf="salesPersonConfig=='true'">
													<input #salesPersonAC style="text-transform: uppercase;width: 100%" 
														type="text" 
														oninput="this.value = this.value.toUpperCase()" 
														name="sales_person_name"
														formControlName="sales_person_name"
														(input)="salesPersonSearch($event)"
														[nzAutocomplete]="salesPersonAuto"
														(blur)="validateSalesPerson(salesPersonAC.value,1)"
													/>

													<nz-autocomplete #salesPersonAuto [nzBackfill]="true" (selectionChange)="selectSalesPerson($event,'sales_person_name',i)">
														<nz-auto-option  class="colr-1" *ngFor="let item of salesPersonArr" [nzValue]="item" [nzLabel]="item.t_user_name"
														>
															<a innerHTML='<span class="colr-1 selected-ng">{{item.t_user_name}}({{item.user_code}})</span><br />'></a>                           
														</nz-auto-option>                      
													</nz-autocomplete>
												</td>
												<td *ngIf="referredByConfig=='true'">
													<input #referedByAC style="text-transform: uppercase;width: 100%" 
														type="text" 
														oninput="this.value = this.value.toUpperCase()" 
														name="refered_by_name"
														formControlName="refered_by_name"
														(input)="referedBySearch($event)"
														[nzAutocomplete]="referedByAuto"
														(blur)="validateReferedby(referedByAC.value,1)"
													/>
						
													<nz-autocomplete #referedByAuto [nzBackfill]="true" (selectionChange)="selectReferedBy($event,'refered_by_name',i)">
														<nz-auto-option  class="colr-1" *ngFor="let item of referedByArr" [nzValue]="item" [nzLabel]="item.name"
														 >
															<a innerHTML='<span class="colr-1 selected-ng">{{item.name}}({{item.account_code}})</span><br />'></a>                           
														</nz-auto-option>                      
													</nz-autocomplete>
												</td>
												<td *ngIf="costCenterConfig=='true'">
													<input #costCenterAC style="text-transform: uppercase;width: 100%" 
														type="text" 
														oninput="this.value = this.value.toUpperCase()" 
														name="invoice_cost_center"
														formControlName="invoice_cost_center"
														(input)="costCenterSearch($event)"
														[nzAutocomplete]="costCenterAuto"
														(blur)="validateCostCenter(costCenterAC.value,1)"
													/>
													<nz-autocomplete #costCenterAuto [nzBackfill]="true" >
														<nz-auto-option  class="colr-1" *ngFor="let item of groupList" [nzValue]="item.name" [nzLabel]="item.name"
														>
															<a innerHTML='<span class="colr-1 selected-ng">{{item.name}}</span><br />'></a>                           
														</nz-auto-option>                      
													</nz-autocomplete>
												</td>
												<!-- <td>
													<input type="text" class="w-100 text-right" disabled value="{{travellers.value.client_basic}}" />
												</td>
												<td>
													<input type="text" class="w-100 text-right" disabled  value="{{travellers.value.client_taxes}}"/>
												</td> -->
												<!-- <td>
													<input type="text" class="w-100 text-right" disabled  value="{{travellers.value.client_tds_amount}}"/>
												</td>
												<td *ngIf="isTenantIndian">
													<input type="text" class="w-100 text-right" disabled  value="{{travellers.value.client_sgst + travellers.value.client_cgst + travellers.value.client_igst}}"/>
												</td>
												<td *ngIf="!isTenantIndian">
													<input type="text" class="w-100 text-right" disabled  value="{{travellers.value.client_vat_amount}}"/>
												</td> -->
												<!-- <td>
													<input type="text" class="w-100 text-right" disabled  value="{{travellers.value.client_net_amount}}"/>
												</td> -->
			
												<td class="hilight">
													<span class="action-col">
														<button title="Maximize" class=" custom-table-btn mr-2 bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button"
														(click)="maximizeSection(i,staticInputCount == 13 ? 2 : 1)" id="edit_traveller_{{i}}_btn">
														<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-edit-btn.svg" alt="" width="20px">
													</button>
													<button title="Delete" class=" custom-table-btn mr-2 bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button"
													(click)="tourService.removeTraveller(i)" >
														<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-delete.svg" alt="" width="20px">
													</button>
													</span>
												</td>
											</tr>
			
											<ng-container *ngIf="showTravellerIndex == i">

												<tr>
													<td colspan="6">
														<div>
															<span class="section-head text-left">SERVICE DETAILS</span>
															<input formControlName="service_details_1"
															[ngClass]="{'error-field': !travellers.controls.service_details_1.valid && travellers.controls.service_details_1.hasError('pattern')}"
															
															tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 10 : 11)}}"  
															attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 10 : 11)}}"
															type="text"
															maxlength="50"
															class="border-bottom-0 d-block p-2 w-100 bbrr bblr">
															<input formControlName="service_details_2"
															[ngClass]="{'error-field': !travellers.controls.service_details_2.valid && travellers.controls.service_details_2.hasError('pattern')}"
															
															tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 11 : 12)}}"  
															attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 11 : 12)}}"
															type="text"
															maxlength="50"
															class="border-bottom-0 border-top-0 d-block mt-0 p-2 w-100 br0">
															<input formControlName="service_details_3"
															[ngClass]="{'error-field': !travellers.controls.service_details_3.valid && travellers.controls.service_details_3.hasError('pattern')}"
															
															tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 12 : 13)}}"  
															attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 12 : 13)}}"
															type="text"
															maxlength="50"
															class="border-bottom-0 border-top-0 d-block mt-0 p-2 w-100 br0">
															<input formControlName="service_details_4"
															[ngClass]="{'error-field': !travellers.controls.service_details_4.valid && travellers.controls.service_details_4.hasError('pattern')}"
															
															tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 13 : 14)}}"  
															attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 13 : 14)}}"
															type="text"
															maxlength="50"
															class="border-bottom-0 border-top-0 d-block mt-0 p-2 w-100 br0">
															<input formControlName="service_details_5"
															[ngClass]="{'error-field': !travellers.controls.service_details_5.valid && travellers.controls.service_details_5.hasError('pattern')}"
															
															tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 14 : 15)}}"  
															attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 14 : 15)}}"
															type="text"
															maxlength="50"
															class="border-top-0 d-block p-2 w-100 btrr btlr">
															<span class="text-dark-orange"
																*ngIf=" (
																			!travellers.controls.service_details_1.valid && travellers.controls.service_details_1.hasError('pattern')
																		) ||
																		(
																			!travellers.controls.service_details_2.valid && travellers.controls.service_details_2.hasError('pattern')
																		) ||
																		(
																			!travellers.controls.service_details_3.valid && travellers.controls.service_details_3.hasError('pattern')
																		) ||
																		(
																			!travellers.controls.service_details_4.valid && travellers.controls.service_details_4.hasError('pattern')
																		) ||
																		(
																			!travellers.controls.service_details_5.valid && travellers.controls.service_details_5.hasError('pattern')
																		)
																	">
																Invalid special chars</span>
														</div>
													</td>
													<td colspan="11">
														<span class="section-head text-left">Fare Breakup</span>
														<table class="table table-hovered table-bordered fare-breakup-table mb-0">
															<thead>
																<tr>
																	<th></th>
																	<th class="client">Client</th>
																	<th class="supplier">Supplier </th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td class="text-right">
																		Basic
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 15 : 16)}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 15 : 16)}}"
																		formControlName="client_basic"
																			(input)="replicateClientAmount(i,'client_basic','supplier_basic')" class="traveller-input-field  text-right"
																			type="text" />
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 16 : 17)}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 16 : 17)}}"
																		formControlName="supplier_basic"
																		(input)="isTenantIndian ? calculateNetAmount(i) : replicateClientAmount(i,'supplier_basic','client_basic')" class="traveller-input-field  text-right"
																			type="text"  />
																	</td>
																</tr>
									
																<tr>
																	<td class="text-right">
																		Taxes
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 17 : 18)}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 17 : 18)}}"
																		 formControlName="client_taxes"
																		 (input)="replicateClientAmount(i,'client_taxes','supplier_taxes')"
																		 class="traveller-input-field  text-right"
																			type="text" />
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 18 : 19)}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 18 : 19)}}"
																		formControlName="supplier_taxes"
																		(input)="isTenantIndian ? calculateNetAmount(i) : replicateClientAmount(i,'supplier_taxes','client_taxes')"  class="traveller-input-field  text-right"
																			type="text"  />
																	</td>
																</tr>
																<tr>
																	<td class="text-right">
																		Commission
																	</td>
																	<td>
																		<div class="three-fields">
																			<select 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 19 : 20)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 19 : 20)}}"
																			class="inner-select-top w-50"
																				formControlName="client_commission_type"
																				(change)="calculateNetAmount(i)">
																				<option *ngIf="isTenantIndian" value="RB">RB</option>
																				<option *ngIf="isTenantIndian" value="RT">RT</option>
																				<option value="VL">VL</option>
																			</select>
																			<input 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 20 : 21)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 20 : 21)}}"
																			style="width: 65px!important;"
																				formControlName="client_commission_value"
																				class="traveller-input-field  text-right w-25" type="text"
																				(input)="calculateNetAmount(i)" />
																			<input 
																			style="width: 120px!important;"
																				formControlName="client_commission_amount"
																				class="traveller-input-field  text-right w-25" readonly type="text" />
																		</div>
																	</td>
																	<td>
																		<div class="three-fields">
																			<select 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 21 : 22)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 21 : 22)}}"
																			class="inner-select-top w-50"
																				formControlName="supplier_commission_type"
																				(change)="calculateNetAmount(i)">
																				<option *ngIf="isTenantIndian" value="RB">RB</option>
																				<option *ngIf="isTenantIndian" value="RT">RT</option>
																				<option value="VL">VL</option>
																			</select>
																			<input 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 22 : 23)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 22 : 23)}}"
																			style="width: 65px!important;"
																				formControlName="supplier_commission_value"
																				class="traveller-input-field  text-right w-25" type="text"
																				(input)="calculateNetAmount(i)" />
																			<input style="width: 120px!important;"
																				formControlName="supplier_commission_amount"
																				class="traveller-input-field  text-right w-25" type="text" readonly />
																		</div>
																	</td>
									
																</tr>
																<tr>
																	<td class="text-right">
																		TDS
																	</td>
																	<td>
																		<div 
																			class="d-flex justify-content-between">
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">TDS On:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 23 : 24)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 23 : 24)}}"
																				class="inner-select-top w-100"
																					(change)="calculateNetAmount(i)" formControlName="client_tds_type">
																					<option value="C" title="Commission">COMM.</option>
																					<option value="B" title="Basic">Basic</option>
																				</select>
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">TDS Rate:</label>
																				<input 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 24 : 25)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 24 : 25)}}"
																				 class="traveller-input-field"
																					type="number" formControlName="client_tds_conf"
																					(input)="calculateNetAmount(i)" />
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">Amount</label>
																				<input formControlName="client_tds_amount" readonly
																			class="traveller-input-field  text-right" type="text" />
																			</div>
																		</div>
																	</td>
																	<td>
																		<div 
																			class="d-flex justify-content-between">
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title=""> On:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 25: 26)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 25: 26)}}"
																				class="inner-select-top w-100"
																					(change)="calculateNetAmount(i)" formControlName="supplier_tds_type">
																					<option value="C" title="Commission">COMM.</option>
																					<option value="B" title="Basic">Basic</option>
																				</select>
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0"> Rate:</label>
																				<input 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 26 : 27)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 26 : 27)}}"
																				 class="traveller-input-field"
																					type="number" formControlName="supplier_tds_conf"
																					(input)="calculateNetAmount(i)" />
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">Amount</label>
																				<input formControlName="supplier_tds_amount" readonly
																			class="traveller-input-field  text-right" type="text" />
																			</div>
																		</div>
																	</td>
																</tr>

																<tr *ngIf="discount_add=='true'">
																	<td class="text-right">
																		discount
																	</td>
																	<td>
																		<div class="three-fields">
																			<select 
																			class="inner-select-top w-50"
																				formControlName="client_discount_type"
																				(change)="calculateNetAmount(i)">
																				<option value="VL">VL</option>
																			</select>
																			<input 
																			style="width: 120px!important;"
																				formControlName="client_discount_amount"
																				class="traveller-input-field  text-right w-25" type="text"
																				(input)="calculateNetAmount(i)" />
																		</div>
																	</td>
																	<td>
																		<div class="three-fields">
																			<select 
																			class="inner-select-top w-50"
																				formControlName="supplier_discount_type"
																				(change)="calculateNetAmount(i)">
																				<option value="VL">VL</option>
																			</select>
																			<input 
																			style="width: 120px!important;"
																				formControlName="supplier_discount_amount"
																				class="traveller-input-field  text-right w-25" type="text"
																				(input)="calculateNetAmount(i)" />
																			
																		</div>
																	</td>
									
																</tr>

																<!-- service charge 1 -->
																<tr>
																	<td class="text-right">
																		<div class="d-flex justify-content-between">
																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Service Charge 1</label>
																				<div class="d-flex justify-content-between" >
																					<div >hsn</div>
																					<input [ngClass]="((travellers.value.client_cgst!=0 || travellers.value.client_sgst!=0 || travellers.value.client_igst!=0) && (!travellers.value.hsn) && (isGstNumberExist==true))?'validateError':''"
																					class="traveller-input-field  text-right w-50" type="text" formControlName="hsn" oninput="this.value = this.value.toUpperCase()" />
																				</div>
																			</div>
																		</div>
																	</td>
																	<td>
																		<div class="d-flex justify-content-between">
																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Taxable:</label>
																			<select 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 27 : 28)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 27 : 28)}}"
																			style="width:40px!important"
																				class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																				formControlName="client_sc1_type">
																				<option value="T">T</option>
																				<option value="N">N</option>
																			</select>
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">AMT:</label>
																			<input 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 28 : 29)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 28 : 29)}}"
																			formControlName="client_sc1_amount"
																			(change)="changeAmount('client_sc1_amount',i)"
																				class="traveller-input-field  text-right w-100" type="text" />
																		</div>
																		<ng-container *ngIf="isTenantIndian">
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">GST On:</label>
																				<select 
																				class="inner-select-top w-100"
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'?  29:30)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 29:30)}}"
																					(change)="calculateNetAmount(i)" formControlName="client_gst_type">
																					<option title= 'S' value="S">SC1</option>
																					<option disabled title= 'B' value="B">B</option>
																					<option disabled title= 'TOTAL' value="TOTAL">TOTAL</option>
																				</select>
																			</div>

																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">GST Rate:</label>
																				<select 
																					class="traveller-input-field"
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 30:31)}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 30:31)}}"
																					type="number" formControlName="client_gst_conf"
																					(change)="calculateNetAmount(i)" >
																					<option value= 0 >0</option>
																					<option value= 5 >5</option>
																					<option value=12 >12</option>
																					<option value=18 >18</option>
																					<option value=28 >28</option>
																				</select>
																			</div>

																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">CGST</label>
																				<input disabled formControlName="client_cgst" class="traveller-input-field"
																					type="number" />
																			</div>

																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0 ml-1 mr-1">SGST</label>
																				<input disabled formControlName="client_sgst" class="traveller-input-field"
																					type="number" />
																			</div>

																			<div style="width:24%" class="align-items-center">
																				<label class="mb-0">IGST</label>
																				<input disabled formControlName="client_igst" class="traveller-input-field"
																					type="number" />
																			</div>
																		</ng-container>
																		</div>
																	</td>
																	<td>
																		<div class="d-flex justify-content-between">
																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Taxable:</label>
																			<select 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 31:32)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 31:32)}}"
																			style="width:40px!important"
																				class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																				formControlName="supplier_sc1_type">
																				<option value="T">T</option>
																				<option value="N">N</option>
																			</select>
																		</div>
																		<div style="width:24%" class="align-items-center mr-2">
																			<label class="mb-0" title="">AMT:</label>
																			<input 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 32:33)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 32 : 33)}}"
																			formControlName="supplier_sc1_amount"
																			(change)="changeAmount('supplier_sc1_amount',i)"
																				class="traveller-input-field  text-right w-100" type="text" />
																			</div>
																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">GST On:</label>
																					<select 
																					class="inner-select-top w-100"
																				     	tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 33 : 34)}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 33 : 34)}}"
																						(change)="calculateNetAmount(i)" formControlName="supplier_gst_type">
																						<option title= 'S' value="S">SC1</option>
																						<option diabled title= 'B' value="B">B</option>
																						<option disabled title= 'T' value="T">OT</option>
																					</select>
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">GST Rate:</label>
																					<select 
																					    tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 34 : 35)}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 34 : 35)}}"
																						class="traveller-input-field" type="number"
																						formControlName="supplier_gst_conf" 
																						(change)="calculateNetAmount(i)" >
																						<option value= 0 >0</option>
																						<option value= 5 >5</option>
																						<option value=12 >12</option>
																						<option value=18 >18</option>
																						<option value=28 >28</option>
																					</select>
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="supplier_cgst"
																						class="traveller-input-field" type="number" />
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="supplier_sgst"
																						class="traveller-input-field" type="number" />
																				</div>
																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="supplier_igst"
																						class="traveller-input-field" type="number" />
																				</div>
																		    </ng-container>
																		</div>
																	</td>
																</tr>
									
																<tr *ngIf="(travellers.value.supplier_sc1_type == 'T' || travellers.value.client_sc1_type == 'T') && !isTenantIndian">
																	<td class="text-right" *ngIf="isTenantIndian">
																		GST
																	</td>
																	<td class="text-right" *ngIf="!isTenantIndian">
																		{{vatAlternetname}}
																	</td>
									
																	<td>

																		<div *ngIf="(travellers.value.client_sc1_type == 'T') && (!isTenantIndian)"
																			class="d-flex justify-content-between">
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">{{vatAlternetname}} On:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 29 : 30)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 29 : 30)}}"
																				class="inner-select-top w-100"
																					(change)="calculateNetAmount(i)" formControlName="client_vat_type">
																					<option value="S">S</option>
																					<option value="B">B</option>
																					<option value="T">T</option>
																				</select>
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">Rate:</label>
																				<input 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 30 : 31)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 30 : 31)}}"
																				 class="traveller-input-field"
																					type="number" formControlName="client_vat_conf"
																					(input)="calculateNetAmount(i)" />
																			</div>
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0">Amount</label>
																				<input disabled formControlName="client_vat_amount" class="traveller-input-field"
																					type="number" />
																			</div>
																		</div>

																	</td>
																	
																	<td *ngIf="(travellers.value.supplier_sc1_type == 'T' )&& (!isTenantIndian) ">
																		<div>
									
																			<div class="d-flex justify-content-between">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">{{vatAlternetname}} On:</label>
																					<select 
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 33 : 34)}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 33 : 34)}}"
																					class="inner-select-top w-100"
																						(change)="calculateNetAmount(i)" formControlName="supplier_vat_type">
																						<option value="S">S</option>
																						<option value="B">B</option>
																						<option value="T">T</option>
																					</select>
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">Rate:</label>
																					<input 
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 34 : 35)}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 34 : 35)}}"
																					(input)="calculateNetAmount(i)" 
																						class="traveller-input-field" type="number"
																						formControlName="supplier_vat_conf" />
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">Amount</label>
																					<input disabled formControlName="supplier_vat_amount"
																						class="traveller-input-field" type="number" />
																				</div>
																			</div>
																		</div>
																	</td>
									
																</tr>
																<!-- service charge 2 -->
																<tr>
																	<td class="text-right">
																		<div class="align-items-center mr-2">
																			<label class="mb-0" title="">Service Charge 2</label>
																			<ng-container *ngIf="isTenantIndian">
																			<div class="d-flex justify-content-between" >
																				<div >hsn</div>
																				<input [ngClass]="((travellers.value.client_cgst_2!=0 || travellers.value.client_sgst_2!=0 || travellers.value.client_igst_2!=0 || (travellers.value.client_sc2_taxable=='T' && travellers.value.client_sc2_amount!=0)) && (!travellers.value.hsn_2)&&(isGstNumberExist==true))?'validateError':''"
																				class="traveller-input-field  text-right w-50" type="text" formControlName="hsn_2" oninput="this.value = this.value.toUpperCase()" />
																			</div>
																		</ng-container>
																		</div>
																	</td>
																	<td>
																		<div class="d-flex justify-content-between">
																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Taxable:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 35 : 36)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 35 : 36)}}"
																				style="width:40px!important"
																					class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																					formControlName="client_sc2_taxable">
																					<option *ngIf="isTenantIndian" value="T">T</option>
																					<option value="N">N</option>
																				</select>
																		</div>

																		<div class="align-items-center mr-2">
																			<label class="mb-0" title="">Type:</label>
																			<select 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 36 : 37)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 36 : 37)}}"
																			style="width:40px!important"
																				class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																				formControlName="client_sc2_type">
																				<option value="B">B</option>
																				<!-- <option value="T">T</option> -->
																			</select>
																		</div>

																		<div style="width:24%" class="align-items-center mr-2">
																			<label class="mb-0" title="">AMT:</label>
																			<input 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 37: 38)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 37 : 38)}}"
																			formControlName="client_sc2_amount"
																				(input)="calculateNetAmount(i)"
																				class="traveller-input-field  text-right w-100" type="text" />
																			</div>
																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">GST On:</label>
																					<select class="inner-select-top w-100"
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 38 : 39)}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 38 : 39)}}"
																						(change)="calculateNetAmount(i)" formControlName="client_gst_type_2">
																						<option disabled title= 'S'value="S">SC1</option>
																						<option  title= 'B' value="B">B</option>
																						<option disabled title= 'TOTAL' value="TOTAL">TOTAL</option>
																					</select>
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">GST Rate:</label>
																					<select 
																						class="traveller-input-field" [attr.disabled]="travellers.value.client_sc2_taxable == 'T'?true:null"
																						tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 39 : 40)}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 39 : 40)}}"
																						type="number" formControlName="client_gst_conf_2"
																						(change)="calculateNetAmount(i)" >
																						<option value= 0 >0</option>
																						<option value= 5 >5</option>
																						<option value=12 >12</option>
																						<option value=18 >18</option>
																						<option value=28 >28</option>
																					</select>
																				
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="client_cgst_2" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="client_sgst_2" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="client_igst_2" class="traveller-input-field"
																						type="number" />
																				</div>
																			</ng-container>
																		</div>
																	</td>
																	<td>
																		<div class="d-flex justify-content-between">
																			<!-- <div class="align-items-center mr-2">
																				<label class="mb-0" title="">Taxable:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 40 : 41)}}"  
																				attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 40 : 41)}}"
																				style="width:40px!important"
																					class="inner-select-top w-50 mr-1"  (change)="calculateNetAmount(i)"
																					formControlName="supplier_sc2_taxable">
																					<option value="T">T</option>
																					<option value="N">N</option>
																				</select>
																			</div> -->
																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Type:</label>
																			<select 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 41 : 42)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 41 : 42)}}"
																			style="width:40px!important"
																				class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																				formControlName="supplier_sc2_type">
																				<option value="B">B</option>
																			</select>
																		</div>
																		<div style="width:24%" class="align-items-center mr-2">
																			<label class="mb-0" title="">AMT:</label>
																			<input 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 42 : 43)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 42 : 43)}}" 
																			formControlName="supplier_sc2_amount"
																				(input)="calculateNetAmount(i)"
																				class="traveller-input-field  text-right w-100" type="text" />
																			</div>
																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">GST On:</label>
																					<select 
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 43 : 44)}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 43 : 44)}}"
																					class="inner-select-top w-100" 
																						(change)="calculateNetAmount(i)" formControlName="supplier_gst_type_2">
																						<option disabled title= 'S' value="S">SC1</option>
																						<option title= 'B' value="B">B</option>
																						<option disabled title= 'T' value="T">OT</option>
																					</select>
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">GST Rate:</label>
																					<select 
																						class="traveller-input-field" [attr.disabled]="travellers.value.supplier_sc2_taxable == 'T'?true:null"
																						tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 44 : 45)}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 44 : 45)}}"
																						type="number" formControlName="supplier_gst_conf_2"
																						(change)="calculateNetAmount(i)" >
																						<option value= 0 >0</option>
																						<option value= 5 >5</option>
																						<option value=12 >12</option>
																						<option value=18 >18</option>
																						<option value=28 >28</option>
																					</select>
																				
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="supplier_cgst_2" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="supplier_sgst_2" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="supplier_igst_2" class="traveller-input-field"
																						type="number" />
																				</div>
																			</ng-container>
																		</div>
																	</td>
																</tr>
																<!-- service charge 3 -->
																<tr>
																	<td class="text-right">
																		<div class="d-flex justify-content-between">
																			<div class="align-items-center mr-2">

																				<label class="mb-0" title="">Service Charge 3</label>
																				<ng-container *ngIf="isTenantIndian">
																				<div class="d-flex justify-content-between" >

																					<div >hsn</div>
																					<input [ngClass]="((travellers.value.client_cgst_3!=0 || travellers.value.client_sgst_3!=0 || travellers.value.client_igst_3!=0 || (travellers.value.client_sc3_taxable=='T' && travellers.value.client_sc3_amount!=0)) && (!travellers.value.hsn_3)&&(isGstNumberExist==true))?'validateError':''"
																					class="traveller-input-field  text-right w-50" type="text" formControlName="hsn_3" oninput="this.value = this.value.toUpperCase()" />
																				</div>
																				</ng-container>
																			</div>

																		</div>
																	</td>
																	<td>
																		<div class="d-flex justify-content-between">
																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Taxable:</label>
																			<select 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 45 : 46)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 45 : 46)}}"
																			style="width:40px!important"
																				class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																				formControlName="client_sc3_taxable">
																				<option *ngIf="isTenantIndian" value="T">T</option>
																				<option value="N">N</option>
																			</select>
																		</div>
																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Type:</label>
																			<select
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 46 : 47)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 46 : 47)}}"
																			style="width:40px!important"
																				class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																				formControlName="client_sc3_type">
																				<option value="OT">OT</option>
																			</select>
																		</div>

																		<div style="width:24%" class="align-items-center mr-2">
																			<label class="mb-0" title="">AMT:</label>
																			
																			<input 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 47 : 48)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 47 : 48)}}" 
																			formControlName="client_sc3_amount"
																				(input)="calculateNetAmount(i)"
																				class="traveller-input-field  text-right w-100" type="text" />
																			</div>
																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">GST On:</label>
																					<select 
																					class="inner-select-top w-100" 
																					    tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 48 : 49)}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 48 : 49)}}"
																						(change)="calculateNetAmount(i)" formControlName="client_gst_type_3">
																						<option disabled title= 'S' value="S">SC1</option>
																						<option disabled title= 'B' value="B">B</option>
																						<option  title= 'TOTAL' value="TOTAL">TOTAL</option>
																					</select>
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">GST Rate:</label>
																					<select 
																						class="traveller-input-field" [attr.disabled]="travellers.value.client_sc3_taxable == 'T'?true:null"
																						tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 49 : 50)}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 49 : 50)}}"
																						type="number" formControlName="client_gst_conf_3"
																						(change)="calculateNetAmount(i)" >
																						<option value= 0 >0</option>
																						<option value= 5 >5</option>
																						<option value=12 >12</option>
																						<option value=18 >18</option>
																						<option value=28 >28</option>
																					</select>
																				
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="client_cgst_3" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="client_sgst_3" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="client_igst_3" class="traveller-input-field"
																						type="number" />
																				</div>
																			</ng-container>
																		</div>
																	</td>
																	<td>
																		<div class="d-flex justify-content-between">
																			<!-- <div class="align-items-center mr-2">
																				<label class="mb-0" title="">Taxable:</label>
																				<select 
																				tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 50 : 51)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 50 : 51)}}"
																				style="width:40px!important" 
																					class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																					formControlName="supplier_sc3_taxable">
																					<option value="T">T</option>
																					<option value="N">N</option>
																				</select>
																			</div> -->

																			<div class="align-items-center mr-2">
																				<label class="mb-0" title="">Type:</label>
																			<select 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 51 : 52)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 51 : 52)}}"
																			style="width:40px!important"
																				class="inner-select-top w-50 mr-1" (change)="calculateNetAmount(i)"
																				formControlName="supplier_sc3_type">
																				<option value="OT">OT</option>
																			</select>
																		</div>
																			
																		<div style="width:24%" class="align-items-center mr-2">
																			<label class="mb-0" title="">AMT:</label>
																			<input 
																			tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 52 : 53)}}"  
																			attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 52 : 53)}}" 
																			formControlName="supplier_sc3_amount"
																				(input)="calculateNetAmount(i)"
																				class="traveller-input-field  text-right w-100" type="text" />
																			</div>
																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0" title="">GST On:</label>
																					<select 
																					class="inner-select-top w-100" 
																					    tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 53 : 54)}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 53 : 54)}}"
																						(change)="calculateNetAmount(i)" formControlName="supplier_gst_type_3">
																						<option disabled title= 'S' value="S">SC1</option>
																						<option disabled title= 'B' value="B">B</option>
																						<option title= 'TOTAL'value="TOTAL">TOTAL</option>
																					</select>
																				</div>

																				<div style="width:24%"   class="align-items-center mr-2">
																					<label class="mb-0">GST Rate:</label>
																					<select [attr.disabled]="travellers.value.supplier_sc3_taxable == 'T'?true:null"
																					    tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 54 : 55)}}"  
																					    attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 54 : 55)}}"
																						class="traveller-input-field" 
																						type="number" formControlName="supplier_gst_conf_3"
																						(change)="calculateNetAmount(i)" >
																						<option value= 0 >0</option>
																						<option value= 5 >5</option>
																						<option value=12 >12</option>
																						<option value=18 >18</option>
																						<option value=28 >28</option>
																					</select>
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="supplier_cgst_3" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="supplier_sgst_3" class="traveller-input-field"
																						type="number" />
																				</div>

																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="supplier_igst_3" class="traveller-input-field"
																						type="number" />
																				</div>
																			</ng-container>
																		</div>
																	</td>
																</tr>

																<tr *ngIf="tourService.invoiceForm.value.transaction_method == 'purchase'">
																	<td class="text-right">
																		XXL Charges.
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 55 : 56)}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 55 : 56)}}"
																		formControlName="client_xxl_charges"
																		(input)="replicateClientXXLL=='true'?replicateClientAmount(i,'client_xxl_charges','supplier_xxl_charges'):calculateNetAmount(i)"
																		class="traveller-input-field  text-right" type="text" />
																	</td>
																	<td>
																		<input 
																		tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 56 : 57)}}"  
																		attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 56 : 57)}}"
																		formControlName="supplier_xxl_charges"
																		(input)="calculateNetAmount(i)" class="traveller-input-field  text-right"
																		type="text" />
																	</td>
																</tr>

																<tr *ngIf="tourService.invoiceForm.value.transaction_method == 'purchase' ">
																	<td class="text-right">
																		RAF Charges.
																	</td>

																	<td>
																		<div class="d-flex justify-content-between">
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">Amount</label>
																				<input 
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 57 : 58)}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 57 : 58)}}"
																					formControlName="client_raf_charges" (change)="changeAmount('client_raf_charges',i)"
																					class="traveller-input-field  text-right" type="text" 
																				/>
																			</div>

																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="client_cgst_on_raf" class="traveller-input-field" />
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="client_sgst_on_raf" class="traveller-input-field"
	/>
																				</div>
																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="client_igst_on_raf" class="traveller-input-field" />
																				</div>
																			</ng-container>

																			<ng-container *ngIf="!isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">{{vatAlternetname}}</label>
																					<input disabled formControlName="client_vat_on_raf" class="traveller-input-field" />
																				</div>
																			</ng-container>
																			
																		</div>
																	</td>

																	<td>
																		<div class="d-flex justify-content-between">
																			<div style="width:24%" class="align-items-center mr-2">
																				<label class="mb-0" title="">Amount</label>
																				<input 
																					tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 58 : 59)}}"  
																					attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 58 : 59)}}"
																					formControlName="supplier_raf_charges" (change)="changeAmount('supplier_raf_charges',i)"
																					class="traveller-input-field  text-right" type="text" 
																				/>
																			</div>

																			<ng-container *ngIf="isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">CGST</label>
																					<input disabled formControlName="supplier_cgst_on_raf" class="traveller-input-field" />
																				</div>
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0 ml-1 mr-1">SGST</label>
																					<input disabled formControlName="supplier_sgst_on_raf" class="traveller-input-field"
	/>
																				</div>
																				<div style="width:24%" class="align-items-center">
																					<label class="mb-0">IGST</label>
																					<input disabled formControlName="supplier_igst_on_raf" class="traveller-input-field" />
																				</div>
																			</ng-container>

																			<ng-container *ngIf="!isTenantIndian">
																				<div style="width:24%" class="align-items-center mr-2">
																					<label class="mb-0">{{vatAlternetname}}</label>
																					<input disabled formControlName="supplier_vat_on_raf" class="traveller-input-field" />
																				</div>
																			</ng-container>

																		</div>
																	</td>
																</tr>
									
																<tr>
																	<td class="text-right">
																		Net Amount
																	</td>
																	<td>
																		<input formControlName="client_net_amount" readonly
																			class="traveller-input-field  text-right" type="text" />
																	</td>
																	<td>
																		<input formControlName="supplier_net_amount" readonly
																			class="traveller-input-field  text-right" type="text" />
																	</td>
																</tr>
									
															</tbody>
														</table>
													</td>
												</tr>
												<tr>
													<td colspan="7"></td>
													<td colspan="9">
														<div class="d-flex justify-content-center align-items-center mt-3">
															
															<button 
															tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 59 : 60)}}"  
															attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(travellers.value.pay_type != 'OUR_CC'? 59 : 60)}}"
															type="button" 
															class="btn-style mb-2 mr-2"
															(click)="openReplicateModal(showTravellerIndex)"
															>
																Next Service
															</button>
				
															<button 
															tabIndex="{{i*totalInputInTravellers+staticInputCount+(showTravellerIndex < DynamicFormControls.controls.length - 1 ? 60 : 61)}}"  
															attr.tabIndex="{{i*totalInputInTravellers+staticInputCount+(showTravellerIndex < DynamicFormControls.controls.length - 1 ? 60 : 61)}}"
															type="button" 
															class="btn-style bg-green mb-2" 
															(click)="openReplicateModal(i)">
															Save & Return To Summary
															</button>
														</div>
													</td>
													
												</tr>
											</ng-container>
			
										</ng-container>
									</ng-container>
								</tbody>
							</table>
						</div>
			
			
					</div>
				</div>
			
			</form>

			<div class="retrive-wrapper" *ngIf="tourService.invoiceForm.value.sub_invoice_type === 'tour'" >
				<table class="table table striped"  style="border: 1px solid #dee2e6 !important;">
					<thead #tableHeader tabIndex="0">
					  <tr class="transition" style="vertical-align: middle;">
						<th class="text-left date " style="max-width: 105px;background-color: #edf0ff!important;font-weight: 500;">Date</th>
		  
						<th style="max-width: 175px;background-color: #edf0ff !important" class="text-left doc-no">
						  <span style="font-weight: 500;display: inline-block;">Doc No</span>
						</th>
						<th class="text-left particular" style="background-color: #edf0ff!important;font-weight: 500;">Account Description</th>

						<th class="text-right voucher" style="background-color: #edf0ff!important">
							<span class="text-center d-block" style="font-weight: 500;display: inline-block;"> Voucher</span>
						</th>
						
						<th class="text-right balance" style="max-width: 155px;background-color: #edf0ff!important">
						 	<span class="text-right d-block" style="font-weight: 500;display: inline-block;"> Amount</span>
						</th>
						<!-- <th class="text-right balance" style="max-width: 155px;background-color: #edf0ff!important">
							<span class="text-right d-block" style="font-weight: 500;display: inline-block;"> Action</span>
						</th> -->
	   
							 <!-- <i  *ngIf="ascSorttdsAmt" (click)="tdsamtSort(i)" class="fas fa-sort-numeric-up"></i>
											 <i  *ngIf="!ascSorttdsAmt" (click)="tdsamtSort(i)" class="fas fa-sort-numeric-down-alt"></i> -->
					  </tr>
					</thead>
					<tbody class="scroll-body main-panel">
						<tr class="table-row-break"
					  *ngFor="let record of retrieveData; let j = index"
					  [attr.tabIndex]="j"
					  id="rowIndex_{{ j }}"
					  (keyup.enter)="
						openViewMode(record.invoice_no, record.invoice_type)
					  "
					>
	
					  <td
					  (click)="moveToIndex(j)"
					  style="max-width: 105px"
					  class="text-left text-grey date"
					>
					  {{ record?.invoice_date | date: "dd/MM/yyyy" }}
					  <ng-container >
						{{dataLoadedFn(j)}}
					  </ng-container>
					</td>
					<td
					  style="max-width: 175px "
					  (click)="moveToIndex( j)"
					  class="text-left transition text-bold cursor doc-no refer"
					>
					  <a (click)="
						  openViewMode(record.invoice_no, record.invoice_type)
						"
						>{{ record?.invoice_no }}</a
					  >
					  <a class="d-block" *ngIf="record?.x_field_1 && record.x_field_1!='null' ">{{record?.x_field_1}}</a>
					</td>
					<td (click)="moveToIndex(j)" class="text-left text-grey particular">
						  <span class="text-grey d-block">{{ record.narration_1 }} </span>
						  <span class="text-grey d-block">{{ record.narration_2 }} </span>
						  <span class="text-grey d-block">{{ record.narration_3 }} </span>
						  <span class="text-grey d-block">{{ record.narration_4 }} </span>
						  <span class="text-grey d-block">{{ record.narration_5 }} </span>
	
					  <!-- <span class="d-block">HARkesh Saini +4</span>
					  <span class="d-block">Del-BOM-AND-BOM-DEL | TKT No. - 12345678901234</span>
					  <span class="d-block">PNR - HSTPWSA | Flt No. - 6E-337 | TRV DT. - 02-01-2022</span>
					  <span class="d-block">narration - testing.... </span> -->
					</td>
					<td  class="text-right text-grey voucher">
	
					  <span class="text-center d-block" >
						{{   record?.invoice_type }}
					  </span>
					  
					</td>
	
					<!-- <td (click)="moveToIndex(i,j)" class="text-right" *ngIf="record.reference_type">{{record?.amount_debit}}</td> -->
				   
				   
					<td
					  (click)="moveToIndex(j)"
					  class="text-right text-blue balance"
					  style="max-width: 150px"
					>
					  {{ record?.total | number :'1.2-2'}}
					</td>
					</tr>
					</tbody>
					<tfoot>
						<tr >
							<td class="inner-supplier-table" style="width: 40px;text-align: center;border-right:0 " colspan="3"></td>
							<td class="inner-supplier-table" style="width: 270px; text-align: right; border-left:0">TOTAL</td>
							<td class="inner-supplier-table" style="width: 180px; text-align: right; ">{{totalInvoiceAmount}}</td>
							<!-- <td class="inner-supplier-table" style="width: 40px;text-align: center;border-right:0 " ></td> -->
						</tr>
					</tfoot>
				</table>
			</div>
			<!-- <div class="total-details consolidate-total-details">
				<table width="100%" class="table table-hovered table-bordered">
					<tfoot>
						<tr >
							<td class="inner-supplier-table" style="width: 40px;text-align: center;border-right:0 " colspan="2"></td>
							<td class="inner-supplier-table" style="width: 270px; text-align: right; border-left:0">TOTAL</td>
							<td class="inner-supplier-table" style="width: 180px; text-align: right; ">{{totalInvoiceAmount}}</td>
						</tr>
					</tfoot>
				</table>
			</div> -->
			
			
			<button type="button" hidden #openModal data-toggle="modal" data-target="#replicateConfirm"></button>

			<!-- Modal -->
			<div id="replicateConfirm" class="modal fade" role="dialog" data-backdrop="false">
				<div class="modal-dialog">

					<!-- Modal content-->
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">Confirmation</h4>
							<button type="button" class="close" data-dismiss="modal">&times;</button>
						</div>
						<div class="modal-body">
							<p>Do you want to add another service?</p>
						</div>
						<div class="modal-footer">
							<button 
								tabIndex="{{tourService.lastIndexOfTraveller +7}}"  
								attr.tabIndex="{{tourService.lastIndexOfTraveller +7}}"
								type="button" 
								class="btn-style" 
								(click)="replicateFormData(showTravellerIndex)" 
								data-dismiss="modal">
								yes
							</button>
							<button 
								tabIndex="{{tourService.lastIndexOfTraveller +8}}"  
								attr.tabIndex="{{tourService.lastIndexOfTraveller +8}}"
								type="button" 
								class="btn-style"  
								(click)="returnToInvoice()" 
								data-dismiss="modal">
								No
							</button>
						</div>
					</div>

				</div>
			</div>

			<app-tour-fare-summary *ngIf="tourService.invoiceForm.value.sub_invoice_type !== 'tour'" [tourService]="tourService"></app-tour-fare-summary>
       </div>
	   <div class="custom-table mt-3">

		<div class="table-actions d-flex justify-content-between">
			<div class="action d-flex flex-column">
				<span *ngIf="tourService.invoiceForm.controls.narration_1.value">
				   <span class="text-grey">Narration 1 :</span> {{tourService.invoiceForm.controls.narration_1.value}}
				</span>
				<span *ngIf="tourService.invoiceForm.controls.narration_2.value">
					<span class="text-grey">Narration 2 :</span> {{tourService.invoiceForm.controls.narration_2.value}}
				</span>
				<span *ngIf="tourService.invoiceForm.controls.narration_3.value">
					<span class="text-grey">Narration 3 :</span>  {{tourService.invoiceForm.controls.narration_3.value}}
				</span>
				<span *ngIf="tourService.invoiceForm.controls.narration_4.value">
					<span class="text-grey">Narration 4 :</span>  {{tourService.invoiceForm.controls.narration_4.value}}
				</span>
			</div>
		
			<div class="subtotal">
				<table class="table table-hovered  mb-0" *ngIf="tourService.invoiceForm.value.sub_invoice_type === 'tour'" style="border-top:0;">
					<tbody>
						<!-- GST ON TOTAL -->
						<tr *ngIf="isTenantIndian">
							<td class="text-right" >
								<div class="d-flex justify-content-between">
									<div class="align-items-center mr-2">
						
										<label class="mb-0" title="">GST <small>(on Total)</small></label>
										<div class="d-flex justify-content-between" >
											<div class="mr-1">HSN</div>
											<input 
												(input)="calculateSC()"
											class="traveller-input-field  text-right" type="text" formControlName="hsn" oninput="this.value = this.value.toUpperCase()" />
										</div>
									</div>
						
								</div>
							</td>
							<td class="pr-0">
								<div class="d-flex justify-content-between">
						
									<ng-container 
										>
										<div style="width:15%" class="align-items-center mr-2">
											<label class="mb-0">Rate:</label>
											<select 
												class="inner-select-top w-100"
												type="number" 
												formControlName="c_gst_conf"
												(change)="calculateSC()"
												>
												<option value= 0 >0</option>
												<option value= 5 >5</option>
												<option value=12 >12</option>
												<option value=18 >18</option>
												<option value=28 >28</option>
											</select>
										</div>
						
										<div style="width:24%" class="align-items-center mr-2">
											<label class="mb-0">CGST</label>
											<input disabled formControlName="c_cgst"  class="traveller-input-field text-right"
												type="number" />
										</div>
						
										<div style="width:24%" class="align-items-center mr-2">
											<label class="mb-0 ml-1 mr-1">SGST</label>
											<input disabled formControlName="c_sgst" class="traveller-input-field text-right"
												type="number" />
										</div>
						
										<div style="width:24%" class="align-items-center mr-2">
											<label class="mb-0">IGST</label>
											<input disabled formControlName="c_igst" class="traveller-input-field text-right"
												type="number" />
										</div>
										<div style="width:57%" class="align-items-center mr-2">
											<label class="mb-0">&nbsp;</label>
											<input disabled formControlName="c_net_gst" class="traveller-input-field text-right"
											(input)="calculateSC()"
												type="number" />
										</div>
									</ng-container>
						
								</div>
							</td>
						</tr>
						<!-- SERVICE CHARGE -->
						<tr>
							<td class="text-right" >
								
							</td>
							<td class="pr-0">
								<div class="d-flex justify-content-end">
									<div style="width:39%"  class="align-items-center mr-3 text-right">
										<strong>Service Charge</strong>
									</div>
									<div style="width:24%" class="align-items-center mr-2">
										<select 
											class="inner-select-top w-100"
											type="number" 
											formControlName="c_sc1_type"
											(change)="calculateSC()"
											>
											<option value="VL" >VL</option>
											<option value="P" >%</option>
										</select>
									</div>
									<div style="width:24%" class="align-items-center mr-2">
										
										<input 
											class="traveller-input-field  text-right" type="number" formControlName="c_sc1_value" 
											(input)="calculateSC()"
											oninput="this.value = this.value.toUpperCase()" />
									</div>
									
									<div style="width:57%" class="align-items-center mr-2">
										<input disabled formControlName="c_sc1_amount" class="traveller-input-field text-right"
											type="number" />
									</div>
								</div>
							</td>
						</tr>
						<!-- GST on SERVICE CHARGE -->
						<tr *ngIf="isTenantIndian">
							<td class="text-right" >
								<div class="d-flex justify-content-between">
									<div class="align-items-center mr-2">
						
										<label class="mb-0" title="">GST <small>(on SC)</small></label>
										<div class="d-flex justify-content-between" >
											<div class="mr-1">HSN</div>
											<input 
											class="traveller-input-field  text-right" type="text" formControlName="hsn_2" oninput="this.value = this.value.toUpperCase()" />
										</div>
									</div>
						
								</div>
							</td>
							<td class="pr-0">
								<div class="d-flex justify-content-between">
						
									<ng-container 
										*ngIf="isTenantIndian">
										<div style="width:15%" class="align-items-center mr-2">
											<label class="mb-0">Rate:</label>
											<select 
												class="inner-select-top w-100"
												type="number" 
												formControlName="c_gst_conf_2"
												(change)="calculateSC()"
												>
												<option value= 0 >0</option>
												<option value= 5 >5</option>
												<option value=12 >12</option>
												<option value=18 >18</option>
												<option value=28 >28</option>
											</select>
										</div>
						
										<div style="width:24%" class="align-items-center mr-2">
											<label class="mb-0">CGST</label>
											<input disabled formControlName="c_cgst_2" class="traveller-input-field text-right"
												type="number" />
										</div>
						
										<div style="width:24%" class="align-items-center mr-2">
											<label class="mb-0 ml-1 mr-1">SGST</label>
											<input disabled formControlName="c_sgst_2" class="traveller-input-field text-right"
												type="number" />
										</div>
						
										<div style="width:24%" class="align-items-center mr-2">
											<label class="mb-0">IGST</label>
											<input disabled formControlName="c_igst_2" class="traveller-input-field text-right"
												type="number" />
										</div>
										<div style="width:57%" class="align-items-center mr-2">
											<label class="mb-0">&nbsp;</label>
											<input disabled formControlName="c_net_gst_2" class="traveller-input-field text-right"
												type="number" />
										</div>
									</ng-container>
						
								</div>
							</td>
						</tr>
						<!-- TCS CHARGE -->
						<tr>
							<td class="text-right" >
								
							</td>
							<td class="pr-0">
								<div class="d-flex justify-content-end">
									<div style="width:63%" class="align-items-center mr-4 text-right">
										<strong>TCS</strong>
									</div>
									<div style="width:24%" class="align-items-center mr-2">
										<select 
											class="inner-select-top w-100"
											type="number" 
											formControlName="tcs_rate"
											(change)="calculateSC()"
											>
											<option value="0" >0%</option>
											<option value="5" >5%</option>
											<option value="20" >20%</option>
										</select>
									</div>
									
									<div style="width:57%" class="align-items-center mr-2">
										<input disabled formControlName="tcs_amount" class="traveller-input-field text-right"
											type="number" />
									</div>
								</div>
							</td>
						</tr>

						<!-- NET TOTAL -->
						<tr>
							<td class="text-right" >
								
							</td>
							<td class="pr-0">
								<div class="d-flex justify-content-end">
									<div style="width:63%" class="align-items-center mr-4 text-right">
										<strong>Net Total</strong>
									</div>
									<div style="width:24%" class="align-items-center mr-2">
									</div>
									
									<div style="width:57%" class="align-items-center mr-2">
										<input disabled formControlName="total" class="traveller-input-field text-right"
											type="number" />
									</div>
								</div>
							</td>
						</tr>
						
					</tbody>
				</table>
				<div class="total-box" *ngIf="tourService.invoiceForm.value.sub_invoice_type !== 'tour'">
					<span class="title-type">Total Client Amount</span>
					<span class="result-type" *ngIf="tourService.totalFareModel && tourService.totalFareModel.length > 0 && tourService.totalFareModel[0].client_net_amount">
						{{(tourService.totalFareModel[0].client_net_amount?tourService.totalFareModel[0].client_net_amount:0) | currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
					</span>
				</div>

				<div *ngIf="isTenantIndian && tourService.invoiceForm.value.sub_invoice_type !== 'tour'">
					<div class="total-box ">
						<div class="w-100 d-flex justify-content-between">
							<div>
								<span class="mb-0 title-type">Calculate TCS</span>
							</div>
							<div  class="switch" >
								<input id="view-toggle" class="check-toggle check-toggle-round-flat" type="checkbox"
								[checked]="CalculateTcs" (change)="selectMode($event.target.checked)">
								<label style="transform: none" for="view-toggle"></label>
								<span class="on" value=flase></span>
								<span class="off" value=true></span>
							</div>
						</div>
					</div>
					<ng-container *ngIf="CalculateTcs==true">
					<div class="total-box ">
						<div class="w-100 d-flex justify-content-between">
							<div>
								<span class="mb-0 title-type">TCS ON</span>
							</div>
							<!-- <label class="mb-0 title-type">TCS ON</label> -->
							<div>
								<select class="traveller-input-field" style="width:147px; height: 26px!important"  (input)="tourService.calculateFromApi(0)" formControlName="tcs_on">
									<option value="total" title="total">Total</option>
									<option value="basic" title="basic">Basic</option>
								</select>

							</div>
							<!-- <div>
								<input class="traveller-input-field text-right" type="number" formControlName="tcs_on"
									(input)="tourService.calculateFromApi(0)" />
							</div> -->
						
						</div>
					
					</div>
					<div class="total-box ">
						<div class="w-100 d-flex justify-content-between">
							<div>
								<span class="mb-0 title-type">TCS Rate(Upto 7Lakh)</span>
							</div>
							<div>
								<input class="traveller-input-field text-right" type="number" formControlName="tcs_rate" (input)="tourService.calculateFromApi(0)" />
							</div>
							
						</div>
					</div>
					<div class="total-box ">
						<div class="w-100 d-flex justify-content-between">
							<div>
								<span class="mb-0 title-type">TCS Rate(Above 7Lakh) </span>
							</div>
							<div>
								<input class="traveller-input-field text-right" type="text" formControlName="tcs_rate_above_7" (input)="tourService.calculateFromApi(0)" />
							</div>
						</div>
					</div>
					<div class="total-box">
						<div class="w-100 d-flex justify-content-between">
							<div>
								<span class="mb-0 title-type">Amount</span>
							</div>
							<div>
								<input formControlName="tcs_amount" readonly class="traveller-input-field  text-right" type="text" disabled/>
							</div>
						</div>
					</div>
				</ng-container>
		     	</div>

				<div *ngIf="tourService.invoiceForm.value.sub_invoice_type !== 'tour'" class="total-box text-danger ">
					<span class="title-type">Grand Total</span>
					<span class="result-type" *ngIf="tourService.totalFareModel && tourService.totalFareModel.length > 0 && 
						tourService.totalFareModel[0].client_net_amount" >
					    {{(tourService.totalFareModel[0].client_net_amount + tourService.invoiceForm.value.tcs_amount) | currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
					</span>
				</div>
				
				<div class="currencyWrapper mt-5" *ngIf="!isTenantIndian && tourService.invoiceForm.value.sub_invoice_type !== 'tour'">
					<div class="row">
						<div class="col-4 input-fiel ">
							<select id="flightType" formControlName="sales_currency"  class="input-ui inv-date-input"  >
								<option value="">Select</option>
								<option value="AED">AED</option>
								<option value="CAD">CAD</option>
								<option value="USD">USD</option>
								<option value="INR">INR</option>
								<option value="EURO">EURO</option>
								<option value="POUND">POUND</option>
							</select>
							<label for="sales_currency">Sales Curr.</label>
						</div>

						<div class="col-4  input-fiel p-0">
							<input class="input-ui inv-number-input" id="currency_rate"
								type="text" formControlName="sales_currency_rate" (input)="changeSalesCurrencyAmount($event)"
							/>
							<label for="sales_currency_rate">Sales Curr. Rate</label>
						</div>

						<div class="col-4 input-fiel ">
							<input  class="input-ui inv-number-input" id="sales_currency_amount"
								 type="text" disabled formControlName="sales_currency_amount"
							/>
							<label for="sales_currency_amount">Sales Amount</label>
						</div>
					</div>
				</div>
				<div *ngIf="salesCurrencyPrint=='true' && tourService.invoiceForm.value.sub_invoice_type !== 'tour'" class="currencyWrapper mt-5">
					<div class="row" style="justify-content: flex-end">
						<div class="col-4 input-fiel ">
							<select id="flightType" class="input-ui inv-date-input" formControlName="sales_currency" >
								<option value="">Select</option>
								<option value="AED">AED</option>
								<option value="USD">USD</option>
								<option value="INR">INR</option>
								<option value="EURO">EURO</option>
								<option value="POUND">POUND</option>
							</select>
							<label for="sales_currency">Sales Curr.</label>
						</div>

						<div class="col-4  input-fiel p-0">
							<input oninput="this.value = this.value"  class="input-ui inv-number-input text-right" id="sales_currency_rate"
								formControlName="sales_currency_rate" type="text" 
							/>
							<label for="sales_currency_rate">Sales Curr. Rate</label>
						</div>
					</div>
				   
				</div>
			</div>
		</div>
		
		<div class="text-input-group d-flex justify-content-between mt-3">
			<!-- <div class="input-fiel mt-10 w-50">
				<input tabIndex="{{sectorService.lastIndexOfTraveller+3}}"
					attr.tabIndex="{{sectorService.lastIndexOfTraveller+3}}"
					oninput="this.value = this.value.toUpperCase()" class="input-ui w-100" id="narration"
					formControlName="narration_4" type="text" placeholder="Narration" /><label
					for="narration">Narration 4</label>
			</div> -->
			<div class="input-fiel mt-10 w-50  mr-3">
				<input tabIndex="{{tourService.lastIndexOfTraveller+3}}"
					attr.tabIndex="{{tourService.lastIndexOfTraveller+3}}"
					oninput="this.value = this.value.toUpperCase()" class="input-ui w-100" id="narration"
					formControlName="narration_5" type="text" placeholder="Narration" /><label
					for="narration" [ngClass]="{'error-label-narration': !tourService.invoiceForm.controls.narration_5.valid && tourService.invoiceForm.controls.narration_5.hasError('pattern')}">Narration 5</label>
				<span class="error-ui"
								*ngIf="!tourService.invoiceForm.controls.narration_5.valid && tourService.invoiceForm.controls.narration_5.hasError('pattern')">
								Invalid special chars</span>
			</div>
			<div class="input-fiel mr-0 w-50 mt-10">
				<input tabIndex="{{tourService.lastIndexOfTraveller+4}}"
					attr.tabIndex="{{tourService.lastIndexOfTraveller+4}}"
					oninput="this.value = this.value.toUpperCase()" class="input-ui w-100" id="remark"
					formControlName="remark" type="text" placeholder="Remark" /><label for="remark">Remark</label>
			</div>
		</div>

		</div>
	</div>
</div>

<div class="btn-field bottom-btns-container full-width-btn-container  d-flex align-item-center justify-content-end ml-0 mr-0">   
	
	<ng-container *ngIf="invoiceMode != 'view'">
		<div class="w-100 d-flex align-item-center">
 
			<div class="d-flex align-items-center flex-row" *ngIf="showRefundableCheckbox == true && tourService.invoiceForm.value.transaction_method == 'purchase' ">
				<label for="clientRefundable" style="font-size: 12px!important;font-weight: 400;color: #000!important;" class="mb-0">Non Refundable</label>
				<input class="ml-10" formControlName="nonRefundable" id="clientRefundable" type="checkbox" style="height: 14px;"/>
			</div>

			<div class="d-flex align-items-center flex-row ml-auto">

				<button type="button" (click)="closeMaxModule()" class="btn-style bg-green mr-3">
					Reset
				</button> 
				
				<button 
					tabIndex="{{tourService.lastIndexOfTraveller +6}}"  
					attr.tabIndex="{{tourService.lastIndexOfTraveller +6}}"
				 type="button" class="btn-style bg-purple"
					(click)="saveProductInvoice()" id="save_mis_invoice_btn">
					Save
				</button>
				<span style="position: relative; display: inline-block;" *ngIf="isAadesh==false">
					<div class="d-flex align-items-center flex-row" style="margin-left: 14px;">
						<button (click)="toggleMenu()" class="hamburger-menu">
							&#9776; <!-- Hamburger icon -->
						</button>
					</div>
					
					<span class="rcm-invoice-btn" *ngIf="menuVisible">
						<div class="d-flex align-item-center">
							<input  formControlName="rcm_applicable" id="rcm_applicable" type="checkbox"  />
							<label for="rcm_applicable" class="mb-0 ml-10 clientRefLbl">RCM Applicable</label>
						</div>

						<div class="d-flex align-item-center">
							<input formControlName="reverse_tds" id="reverse_tds" type="checkbox"  />
							<label for="reverse_tds" class="mb-0 ml-10 clientRefLbl">Reverse TDS</label>
						</div>
					</span>
				</span>
			</div>
			
		</div>
	</ng-container>

	<ng-container *ngIf="invoiceMode == 'view'">
		<div class="w-100 d-flex align-item-center">
 
			<div class="d-flex align-items-center flex-row" *ngIf="showRefundableCheckbox == true && tourService.invoiceForm.value.transaction_method == 'purchase' ">
				<label for="clientRefundable" style="font-size: 12px!important;font-weight: 400;color: #000!important;" class="mb-0">Non Refundable</label>
				<input class="ml-10" disabled formControlName="nonRefundable" id="clientRefundable" type="checkbox" style="height: 14px;"/>
			</div>
			
			<div class="ml-auto row justify-content-between">

				<div class="d-flex align-items-center flex-row  mr-20" *ngIf="billOfSupplyActive === true">
					<label for="clientBos" class="mb-0 clientRefLbl">Bill Of Supply</label>
					<input class="ml-10" formControlName="bill_of_supply" id="clientBos" type="checkbox" style="height: 14px;"/>
				</div>

				<button 
				tabIndex="{{tourService.lastIndexOfTraveller +6}}"  
				attr.tabIndex="{{tourService.lastIndexOfTraveller +6}}"
				type="button" class="btn-style bg-cyan mr-3"
					(click)="openUpdateConfirmModal()" id="update_mis_invoice_btn">
					Modify
				</button>
				<button 
				tabIndex="{{tourService.lastIndexOfTraveller +7}}"  
				attr.tabIndex="{{tourService.lastIndexOfTraveller +7}}"
				type="button" class="btn-style bg-red mr-3"
					(click)="openDeleteConfirmModal()" id="delete_mis_invoice_btn">
					Delete
				</button>
				<span style="position: relative; display: inline-block;" class="mr-3">
					<button type="button" class="btn-style bg-denim" (click)="clickToPrintOptions()">
						Print
					</button>
					<span class="form-invoice-btn"  *ngIf="showPrintOptions">
						<button type="button" *ngIf="checkEInvoice==false || isTesoyBoult==false"
						(click)="openVoucher(invoice_no)" class="btn-style bg-denim mr-2">Invoice</button>
			
						<button type="button" *ngIf="isTenantIndian"
						(click)="checkWhetherEInvGeneratedOrNot(invoice_no)" class="btn-style bg-denim " >E-Invoice</button>
					</span>
				</span>

				<button type="button" class="btn-style bg-pine mr-3" *ngIf="invoiceData && invoiceData.bos_reference" 
					(click)="openBosReference()" id="open_bos_btn">
					{{invoiceData.bill_of_supply ? 'TAX INVOICE' : 'BILL OF SUPPLY'}}
				</button>
				
				
				<button type="button" class="btn-style bg-brown mr-3" (click)="viewJournal(invoice_no)" id="journal_ms_invoice_btn">
					Journal
				</button>
				<button type="button" *ngIf="salesCurrencyPrint=='true'"
                 (click)="openBill(invoice_no)" class="btn-style bg-denim mr-3" id="activity_invoice_log_btn">MultiCurrency Bill
            </button>
				<button type="button" class="btn-style bg-indigo mr-3" (click)="openActivity(invoice_no)">
					Activity 
				</button>
				<button type="button" class="btn-style bg-rusty-red text-white"
				tabIndex="{{tourService.lastIndexOfTraveller+8}}" attr.tabIndex="{{tourService.lastIndexOfTraveller+8}}" 
				  (click)="openSendEmailModal()" id="invoice_send_mail_btn" id="mis_invoice_send_mail_btn">Email</button>
				  <span style="position: relative; display: inline-block;" *ngIf="isAadesh==false">
					<div class="d-flex align-items-center flex-row" style="margin-left: 14px;">
						<button (click)="toggleMenu()" class="hamburger-menu">
							&#9776; <!-- Hamburger icon -->
						</button>
					</div>
					
					<span class="rcm-invoice-btn" *ngIf="menuVisible">
						<div class="d-flex align-item-center">
							<input  formControlName="rcm_applicable" id="rcm_applicable" type="checkbox"  />
							<label for="rcm_applicable" class="mb-0 ml-10 clientRefLbl">RCM Applicable</label>
						</div>

						<div class="d-flex align-item-center">
							<input formControlName="reverse_tds" id="reverse_tds" type="checkbox"  />
							<label for="reverse_tds" class="mb-0 ml-10 clientRefLbl">Reverse TDS</label>
						</div>
					</span>
				</span>
			</div>
		</div>
		
	</ng-container>

	
</div>
</form>
<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateConfirmationModal"></button>

<div class="modal fade" id="updateConfirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Confirmation</h4>
                <button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close" (click)="resetCurrentOpenedModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>update this entry</b> ?
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
                <button type="button" class="btn-style mr-2" (click)="closeUpdateConfirmModal()">No</button>
                <button type="button" class="btn-style bg-blue" (click)="updateProduct()">Yes</button>
            </div>
        </div>
    </div>
</div>
<button type="button" hidden  #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button >

    <div class="modal fade" id="deleteConfirmationModal"  role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
				<h4>Confirmation</h4>

                <button type="button" class="close" #closeDeleteModal data-dismiss="modal" aria-label="Close" (click)="resetCurrentOpenedModal()">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to delete this entry ?
            </div>
            <div class="modal-footer">
              <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
              <button type="button" class="btn-style mr-2" (click)="closeDeleteConfirmModal()">No</button>
              <button type="button" class="btn-style bg-red"  (click)="deleteInvoice(invoice_no)" >Yes</button>
            </div>
          </div>
        </div>
      </div>

	  <button
hidden
#openEmailModal
data-target="#emailModalInMiscellaneous"
data-toggle="modal"
></button>
<div
  class="modal"
  id="emailModalInMiscellaneous"
  role="dialog"
  aria-labelledby="emailModalLabel"
  data-backdrop="false"
>
  <div class="modal-dialog" role="document">
	<div class="modal-content">
	  <div class="modal-header">
		<div class="d-flex justify-content-center">
		  <h4 class="modal-title">Send Email</h4>
		</div>
		<button
		  type=" button"
		  class="close"
		  #closeSendEmailModal
		  data-dismiss="modal"
		  aria-label="Close"
		>
		  <span aria-hidden="true">&times;</span>
		</button>
	  </div>
	  <div class="modal-body emailSenders">
		<!-- <div class="d-flex justify-content-end align-item-center">
						  <span *ngIf="!ccEmail" (click)="showCCEmail()" class="text-size-14 mb-0 cursor  mr-2">Cc</span>
						  <span *ngIf="!bccEmail" (click)="showBCCEmail()" class="text-size-14 mb-0 cursor">Bcc</span>
					  </div> -->
		<form [formGroup]="sendEmailForm" style="margin-top: -10px">
		  <div class="form-group text-input-field">
			<textarea
			  class="input-field text-left ng-pristine  ng-valid ng-touched text-size-12"
			  id="toEmail"
			  rows="1"
			  formControlName="toEmails"
			  required
			></textarea>
			<label for="To" class="mb-0">To</label>
		  </div>
		  <div class="d-flex justify-content-end">
			<!-- <label for="To" class="text-size-14 mb-0">To</label> -->
			<div *ngIf="sendEmailForm.controls.toEmails.invalid">
			  <div
				*ngIf="sendEmailForm.controls.toEmails.errors?.required"
				style="color: red"
			  >
				To Email is required.
			  </div>
			</div>
		  </div>

		  <div class="form-group text-input-field mt-0">
			<textarea
			  class="input-field text-left ng-pristine ng-valid ng-touched text-size-12"
			  id="ccEmail"
			  style="max-height: 45px;"
			  formControlName="ccEmails"
			></textarea>
			<label for="Cc" class="mb-0">Cc</label>
		  </div>

		  <div class="form-group text-input-field">
			<textarea
			  class="input-field text-left ng-pristine ng-valid ng-touched text-size-12"
			  id="bccEmail"
			  style="max-height: 45px;"
			  formControlName="bccEmails"
			></textarea>
			<label for="Bcc" class="mb-0">Bcc</label>
		  </div>
		  <div class="text-input-field">
			<input formControlName="subject"  class="input-field text-left text-size-12" type="text" 
			autocomplete="off" />
			<label for="subject">Subject</label>
		</div>
		<div class="form-group text-input-field">
		 <textarea
		   class="input-field text-left ng-pristine ng-valid ng-touched text-size-12"
		   rows="5"
		   formControlName="body"
		 ></textarea>
		 <label for="body" class="mb-0">Mail Body</label>
		 <input type="file" (change)="handleAttachment($event)" />

	   </div>
		  <div class="d-flex justify-content-center align-item-center">
			<button type="button" class="btn-style mr-2" data-dismiss="modal">
			  Cancel
			</button>
			<button
			  type="button"
			  class="btn-style bg-green"
			  (click)="callTosendEmail()"
			>
			  Send
			</button>
		  </div>
		</form>
	  </div>
	</div>
  </div>
</div>


<button hidden #openMasterCreationModal data-target="#masterCreationModal"data-toggle="modal"></button>
<div
  class="modal"
  id="masterCreationModal"
  role="dialog"
  aria-labelledby="masterModalLabel"
  data-backdrop="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex justify-content-center">
          <h4 class="modal-title">Create {{currentMaster}}</h4>
        </div>
        <button
          type=" button"
          class="close"
          #closeMasterCreationModal
          data-dismiss="modal"
          aria-label="Close"
		  (click)="closeMasterModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
	    <app-master-creation [masterType]="currentMaster" [masterCreationModal]="masterCreationModal" [currentMasterName]="currentMasterName" 
		(callSetFormData)="setFormValues($event)" (closeModal)="closeMasterModal()"
		></app-master-creation>
     </div>
    </div>
</div>

<button type="button" hidden #generateEInvoiceModal data-toggle="modal" data-target="#openGenerateEInvoiceModal"></button>

<div class="modal fade" id="openGenerateEInvoiceModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Confirmation</h4>
                <button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                E-invoice not generated,do you want to generate? 
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-style mr-2" (click)="closeGenerateEInvoiceModal()">No</button>
                <button type="button" class="btn-style bg-red" (click)="generateEInvoice(invoice_no)">Yes</button>
            </div>
        </div>
    </div>
</div>