import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrintEventInvoiceComponent } from './print-event-invoice/print-event-invoice.component';
import { EventComponent } from './event.component';


const routes: Routes = [
	
	{
		path: 'print', component: PrintEventInvoiceComponent
	},
	{
		path: 'save/:transaction_type', component: EventComponent
	},
	{
	 	path: 'view/:invoiceNumber',  component: EventComponent,
    },
	{
		path: 'view/:invoiceNumber',  component: EventComponent,
   }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class EventRoutingModule { }
