<form [formGroup]="ledgerFilterForm">
    <div class="flexbox aff-info">
      <div class="flexbox aff-info-inner">
        <div class="flexbox ">
        </div>

        <div class="flexbox mb-3">

            <div class="input-fiel">
                <input class="input-ui" formControlName="start_date" id="from_date" type="date"
                    [ngClass]="{'error-box': !ledgerFilterForm.controls.start_date.valid}"
                    searchtabindex="1"   />
                <label for="from_date">Starting Date</label>
            </div>
            <div class="input-fiel">
                <input class="input-ui" formControlName="end_date" id="to_date" type="date" searchtabindex="2" 
                [ngClass]="{'error-box': !ledgerFilterForm.controls.end_date.valid}"
                      />
                <label for="to_date">Ending Date</label>
            </div>

        </div>

        <div class="alert-message-container mr-3  mb-3" *ngIf="alertMessage">
            <div class="alert-message">
              {{ alertMessage }}
            </div>
          </div>

        <div class="d-flex mt-5 justify-content-center">
          <div class="input-fiel">
            <button type="button" class="btn-style bg-purple mr-3" (click)="downloadExcel()">EXCEL</button>
            <!-- <button type="button" class="btn-style bg-green" (click)="downloadPdf()">PDF</button> -->
          </div>
        </div>
      </div>
    </div>
  </form>