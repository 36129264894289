
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
    <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
      <div class="d-flex flex-y-center">
        <img
          src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
            alertType=='success-box' ? 'green-sucess.svg' : 
            alertType=='warning-box' ? 'red-warning.svg' : 
            null }}"
          width="20px"
          height="20px"
          alt="alert-logo"
          class="mr-20"
        />
        <div class="ml-10">
          <h4 class="m-0 text-size-18 text-bold"
          [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
            {{alertType === "error-box"
            ? "Error"
            : alertType === "warning-box"
            ? "Warning"
            : alertType === "success-box"
            ? "Success"
            : null}}
          </h4>
          <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
           {{message}}
          </small>
        </div>
      </div>
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
        alt="close-btn"
        (click)="hideError()"
        className=" close-btn"
      />
    </div>
  </div>
  
<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<button type="button" hidden  #openSuccessModal data-toggle="modal" data-target="#successModal"></button>

<!-- Modal -->
<div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
    <div class="modal-dialog transaction-creation-modal">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <span class="messageFont">
                    <span>
                        {{message}} 
                    </span>
                    <a 
                        *ngIf="invoice_number != ''"
                         href="javascript:void(0)"
                        (click)="openVoucher(invoice_number)">
                            {{invoice_number}}
                    </a>
                    <i *ngIf="invoice_number != ''" [title]="copyELementTitle" (click)="copytext(invoice_number)" class="fa fa-copy cursor-pointer"></i>
                </span>
            <button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
            </div>

            <div class="modal-body">
                <p>Do you want to create new invoice or  view newly created Invoice ?</p>
            </div>
          
            <div class="modal-footer justify-content-center" >
                <button tabIndex="1" tabindex="1" type="button" class="btn-style bg-blue" (click)="viewInvoice(invoice_number)">
                    View
                </button>
                <button tabIndex="2" tabindex="2" type="button" class="btn-style btn-grey" (click)="openVoucher(invoice_number)">
                    Print
                </button>
                <button tabIndex="3" tabindex="3" type="button" class="btn-style bg-green" (click)="closeSuccesModal()">
                    Create New
                </button>
            </div>
        </div>

    </div>
</div>



<form [formGroup]="miscellaneousInvoiceService.invoiceForm" [ngClass]="{'d-none': !newInvoice}">
    <div class="ledger-container miscellaneousInvoiceServiceContainer">
        <div class="row tab-nav pl-0 pr-0">
            <!-- <div class="col-sm-4 pl-0">
                <ul class="list-inline list-unstyled p-0 m-0">
                    <li class="active page-title text-uppercase cursor"> View Client</li>
                </ul>
            </div> -->
            <div class="col-sm-6 ">
                <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
                    <li class="text-grey text-capitalize cursor text-primary transition">Invoice</li>
                    <li *ngIf="invoiceType"><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-right.svg" alt=""></li>
                    <li *ngIf="invoiceType" class="text-grey text-capitalize cursor  hover-text-primary transition"> {{invoiceType}}</li>
                    <li *ngIf="transactionType"><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-right.svg" alt=""></li>
                    <li *ngIf="transactionType" class="text-grey text-capitalize cursor  hover-text-primary transition"> {{transactionType}}</li>
                </ul>
          </div>
          <div class="col-sm-6 text-right">
          </div>
        </div>
        <div class="invoice-container invoice-table pl-15 pr-15 custom-form form-container w-100 mb-4">
            
            <div class=" ">
                <table class="table table-hovered table-bordered top-miscellaneous-bar w-100">
                    <thead>
                        <tr>
                            <!-- <th style="width: 100px;"></th>
                            <th style="width: 70px;">Invoice</th> -->
                            <th style="width: 100px;">Acc. Type</th>
                            <th>Client</th>
                            <th>General Ledger</th>
                            <th style="width: 150px;">Sub Ledger Type</th>
                            <th>Sub-ledger</th>
                            <th>Employee</th>

                            <th style="width: 100px;">Invoice Date</th>
                            <th style="width: 100px;">Invoice Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <!-- <td>
                                <span>{{miscellaneousInvoiceService.invoiceForm.value.transaction_method}}</span>
                            </td>
                            <td>
                                <span>GST</span> -->
                                <!-- <select class="inner-select" tabIndex="2" tabindex="2" formControlName="invoice_type">
                                    <option value="non_account">GST</option>
                                </select> -->
                            <!-- </td> -->
                            <td>
                                <select class="inner-select" tabIndex="3" tabindex="3"
                                    formControlName="client_account_type" (change)="resetSelection('client_code')">
                                    <option value="">Select</option>
                                    <option value="client">Client</option>
                                    <option value="airline">Airline</option>
                                    <option value="supplier">Supplier</option>
                                    <!-- <option value="employee">Employee</option> -->
                                    <option value="general">General</option>
                                </select>
                            </td>
                            <!-- client -->
                            <td tabIndex="4" tabindex="4" (focus)="focusClientAc()"
                                style="text-align: left; width: 300px;">
                                <ng-autocomplete [data]="bookingClientArr" #clientAC formControlName="client_code_name"
                                    [searchKeyword]="keyword" (selected)="selectAccount($event,'client_code')"
                                    (inputChanged)='clientSearch($event,true,false)' (inputFocused)='onFocused($event)'
                                    (inputCleared)="selectAccount($event,'client_code')" [itemTemplate]="itemTemplate"
                                    [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>

                                <ng-template #itemTemplate let-item>
                                    <a innerHTML='<span class="colr-1">{{item?.name}}
										({{item?.account_code}})</span>'>
                                    </a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>

                            </td>

                            <td tabIndex="5" tabindex="5" (focus)="focusGeneralLedgerAc()"
                                style="text-align: left; width: 280px;">
                                <ng-autocomplete [data]="bookingGenerlaAccountArr" #generalLedgerAc
                                    formControlName="general_ledger_code_name" [searchKeyword]="keyword"
                                    (selected)="selectAccount($event,'general_ledger_code')"
                                    (inputChanged)='generalSearch($event,false,true)' (inputFocused)='onFocused($event)'
                                    (inputCleared)="selectAccount($event,'general_ledger_code')"
                                    [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>

                                <ng-template #itemTemplate let-item>
                                    <a innerHTML='<span class="colr-1">{{item?.name}}
										({{item?.account_code}})</span>'>
                                    </a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>

                            </td>
                            <td>
                                <select class="inner-select" tabIndex="6" tabindex="6" formControlName="subledger_type">
                                    <option value="">Select</option>
                                    <option value="client">Client</option>
                                    <option value="airline">Airline</option>
                                    <option value="supplier">Supplier</option>
                                    <option value="employee">Employee</option>
                                    <option value="general">General</option>
                                </select>
                            </td>
                            <td style="text-align: left;
                            width: 200px;" tabIndex="7" tabindex="7" (focus)="focusSubledgerAc()">
                                <ng-autocomplete [data]="miscellaneousInvoiceService.ledgerList" #subLedgerAC
                                    [searchKeyword]="keyword" (selected)="selectAccount($event,'subledger_code')"
                                    formControlName="subledger_code_name" (inputChanged)='ledgerSearch($event,true)'
                                    (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                    [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>


                                <ng-template #itemTemplate let-item>
                                    <a
                                        innerHTML='<span class="colr-1 selected-ng">{{item.name}}({{item.account_code}})</span><br />'>
                                    </a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>

                            </td>
                            <td tabIndex="8" tabindex="8" (focus)="focusEmployeeAc()">

                                <ng-autocomplete [data]="employeeList" #employeeAC [searchKeyword]="keyword"
                                    formControlName="employee_name" (selected)="selectAccount($event,'employee')"
                                    (inputChanged)='employeeSearch($event)' (inputFocused)='onFocused($event)'
                                    [itemTemplate]="itemTemplate"
                                    [disabled]="invoiceMode == 'view' && loggedInuser && loggedInuser.accountcode != 'ADMIN' && miscellaneousInvoiceService.invoiceForm.value.employee != ''"
                                    [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>
                                <ng-template #itemTemplate let-item>
                                    <a
                                        innerHTML='<span class="colr-1 selected-ng">{{item.name}}({{item.account_code}})</span><br />'>
                                    </a>
                                </ng-template>
                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                                <span class="text-danger"
                                    *ngIf="miscellaneousInvoiceService.invoiceForm.value.employee == ''">Mandatory</span>
                            </td>
                            <td>
                                <input tabIndex="9" tabindex="9" style="min-width: max-content; padding: 0;"
                                    min="{{minDate}}" 
									max="{{maxDate}}"
                                    class="input-field" type="date" formControlName="invoice_date"
                                    (blur)="setServiceDate($event)" placeholder="MM/DD/YYYY" autocomplete="off" 
                                    [ngClass]="{'error-box': !miscellaneousInvoiceService.invoiceForm.controls.invoice_date.valid}"/>
                            </td>
                            <td>
                                <input style="width : 150px; padding: 0;" class="input-field" type="text"
                                    formControlName="invoice_number" disabled />
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

            <div class="mt-3 d-flex">
                <div class="w-50">
                    <span class="section-head  text-bold text-black">Narration</span>
                    <input
                    [ngClass]="{'error-field': !miscellaneousInvoiceService.invoiceForm.controls.narration_1.valid && miscellaneousInvoiceService.invoiceForm.controls.narration_1.hasError('pattern')}"
                    formControlName="narration_1" tabIndex="14" attr.tabIndex="14" type="text" maxlength="35"
                        class="border-bottom-0 d-block p-2 w-100 bbrr bblr">
                    <input 
                    [ngClass]="{'error-field': !miscellaneousInvoiceService.invoiceForm.controls.narration_2.valid && miscellaneousInvoiceService.invoiceForm.controls.narration_2.hasError('pattern')}"
                    formControlName="narration_2" tabIndex="15" attr.tabIndex="15" type="text" maxlength="35"
                        class="border-bottom-0 border-top-0 d-block mt-0 p-2 w-100 br0">
                    <input 
                    [ngClass]="{'error-field': !miscellaneousInvoiceService.invoiceForm.controls.narration_3.valid && miscellaneousInvoiceService.invoiceForm.controls.narration_3.hasError('pattern')}"
                    formControlName="narration_3" tabIndex="16" attr.tabIndex="16" type="text" maxlength="35"
                        class="border-bottom-0 border-top-0 d-block mt-0 p-2 w-100 br0">
                    <input 
                    [ngClass]="{'error-field': !miscellaneousInvoiceService.invoiceForm.controls.narration_4.valid && miscellaneousInvoiceService.invoiceForm.controls.narration_4.hasError('pattern')}"
                    formControlName="narration_4" tabIndex="17" attr.tabIndex="17" type="text" maxlength="35"
                        class="border-bottom-0 border-top-0 d-block mt-0 p-2 w-100 br0">
                    <input 
                    [ngClass]="{'error-field': !miscellaneousInvoiceService.invoiceForm.controls.narration_5.valid && miscellaneousInvoiceService.invoiceForm.controls.narration_5.hasError('pattern')}"
                    formControlName="narration_5" tabIndex="18" attr.tabIndex="18" type="text" maxlength="35"
                        class="border-top-0 d-block p-2 w-100 btrr btlr">
                    <span class="text-dark-orange"
                    *ngIf=" (
                                !miscellaneousInvoiceService.invoiceForm.controls.narration_1.valid && miscellaneousInvoiceService.invoiceForm.controls.narration_1.hasError('pattern')
                            ) ||
                            (
                                !miscellaneousInvoiceService.invoiceForm.controls.narration_2.valid && miscellaneousInvoiceService.invoiceForm.controls.narration_2.hasError('pattern')
                            ) ||
                            (
                                !miscellaneousInvoiceService.invoiceForm.controls.narration_3.valid && miscellaneousInvoiceService.invoiceForm.controls.narration_3.hasError('pattern')
                            ) ||
                            (
                                !miscellaneousInvoiceService.invoiceForm.controls.narration_4.valid && miscellaneousInvoiceService.invoiceForm.controls.narration_4.hasError('pattern')
                            ) ||
                            (
                                !miscellaneousInvoiceService.invoiceForm.controls.narration_5.valid && miscellaneousInvoiceService.invoiceForm.controls.narration_5.hasError('pattern')
                            )
                        ">
                    Invalid special chars</span>
                    <!-- <textarea 
                    tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +1}}"  
                    attr.tabIndex="{{miscellaneousInvoiceService.lastIndexOfTraveller +1}}"
                    formControlName="narration" cols="3"
                        class="w-100 write-narration"></textarea> -->
                </div>
                <form class="w-50 ml-4" [formGroup]="miscellaneousInvoiceService.travellerForm"
                    *ngIf="miscellaneousInvoiceService.travellerList != undefined">

                    <div class="traveller-outer-container">
                        <span class="section-head text-bold text-black">
                            Fare Information
                        </span>
                        <div class="traveller-section ">
                            <div class="traveller-col miscellaneousInvoiceServiceContainer"
                                formArrayName="travellerList">
                                <ng-container *ngFor="let travellers of DynamicFormControls.controls; let i = index">
                                    <ng-container [formGroupName]="i">
                                        <ng-container>
                                            <table class="table table-hovered table-bordered fare-breakup-table mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td class="text-right">
                                                            Non Account
                                                        </td>
                                                        <td>
                                                            <input tabIndex="10" attr.tabIndex="10"
                                                                formControlName="client_non_account"
                                                                (input)="calculateNetAmount(i)"
                                                                class="traveller-input-field  text-right" type="text" />
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">
                                                            Basic
                                                        </td>
                                                        <td>
                                                            <input tabIndex="11" attr.tabIndex="11"
                                                                formControlName="client_basic"
                                                                (input)="replicateClientAmount(i,'client_basic','supplier_basic')"
                                                                class="traveller-input-field  text-right" type="text" />
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">
                                                            TDS
                                                        </td>
                                                        <td>
                                                            <div 
                                                                class="d-flex justify-content-between">
                                                                <div style="width:24%" class="align-items-center mr-2">
                                                                    <label class="mb-0" title="">TDS On:</label>
                                                                    <select 
                                                                    tabIndex="12"  
                                                                    attr.tabIndex="12"
                                                                    class="inner-select-top w-100"
                                                                        (change)="calculateNetAmount(i)" formControlName="client_tds_type">
                                                                        <option value="B" title="Basic">Basic</option>
                                                                    </select>
                                                                </div>
                                                                <div style="width:24%" class="align-items-center mr-2">
                                                                    <label class="mb-0">TDS Rate:</label>
                                                                    <input 
                                                                    tabIndex="13"  
                                                                    attr.tabIndex="13"
                                                                     class="traveller-input-field"
                                                                        type="number" formControlName="client_tds_conf"
                                                                        (input)="calculateNetAmount(i)" />
                                                                </div>
                                                                <div style="width:24%" class="align-items-center mr-2">
                                                                    <label class="mb-0">Amount</label>
                                                                    <input formControlName="client_tds" readonly
                                                                class="traveller-input-field  text-right" type="text" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="text-right">
                                                            GST
                                                        </td>

                                                        <td>

                                                            <div *ngIf="travellers.value.client_sc1_type == 'T'"
                                                                class="d-flex justify-content-between">
                                                                <div style="width:24%" class="align-items-center mr-2">
                                                                    <label class="mb-0" title="">GST On:</label>
                                                                    <select tabIndex="12" attr.tabIndex="12"
                                                                        class="inner-select-top w-100"
                                                                        (change)="calculateNetAmount(i)"
                                                                        formControlName="client_gst_type">
                                                                        <option value="B">B</option>
                                                                        <option value="TOTAL">TOTAL</option>
                                                                    </select>
                                                                </div>
                                                                <div style="width:24%" class="align-items-center mr-2">
                                                                    <label class="mb-0">GST Rate:</label>
                                                                    <input tabIndex="13" attr.tabIndex="13"
                                                                        class="traveller-input-field" type="number"
                                                                        formControlName="client_gst_conf"
                                                                        (input)="calculateNetAmount(i)" />
                                                                </div>
                                                                <div style="width:24%" class="align-items-center mr-2">
                                                                    <label class="mb-0">CGST</label>
                                                                    <input disabled formControlName="client_cgst"
                                                                        class="traveller-input-field" type="number" />
                                                                </div>
                                                                <div style="width:24%" class="align-items-center mr-2">
                                                                    <label class="mb-0 ml-1 mr-1">SGST</label>
                                                                    <input disabled formControlName="client_sgst"
                                                                        class="traveller-input-field" type="number" />
                                                                </div>
                                                                <div style="width:24%" class="align-items-center">
                                                                    <label class="mb-0">IGST</label>
                                                                    <input disabled formControlName="client_igst"
                                                                        class="traveller-input-field" type="number" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="text-right">
                                                            Net Amount
                                                        </td>
                                                        <td>
                                                            <input formControlName="client_net_amount" readonly
                                                                class="traveller-input-field  text-right" type="text" />
                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </ng-container>

                                    </ng-container>
                                </ng-container>


                            </div>
                        </div>
                    </div>
                </form>
                
            </div>

            <!-- <app-ms-fare-summary></app-ms-fare-summary> -->
            

        </div>
        <div class="btn-field bottom-btns-container full-width-btn-container  d-flex align-item-center justify-content-end  ml-0 mr-0"> 
           
            <ng-container *ngIf="invoiceMode == 'view'">

                <button tabIndex="22" attr.tabIndex="22" type="button" class="btn-style bg-cyan mr-3"
                    (click)="openUpdateConfirmModal()" id="update_mis_invoice_btn"
                    *hasAccess="{'key' :  hasAccessUpdateCode}" >
                    Modify
                </button>
                <button tabIndex="23" attr.tabIndex="23" type="button" class="btn-style bg-red mr-3"
                    (click)="openDeleteConfirmModal()" id="delete_gst_invoice_btn"
                    *hasAccess="{'key' :  hasAccessDeleteCode}" >
                    Delete
                </button>
                <span style="position: relative; display: inline-block;" class="mr-3">
                <button type="button" class="btn-style bg-denim" (click)="clickToPrintOptions()">
                    Print
                </button>
                <span class="form-invoice-btn"  *ngIf="showPrintOptions">
                    <button type="button"
                    (click)="openVoucher(invoice_no)" class="btn-style bg-denim mr-2">Invoice</button>
        
                    <button type="button"
                    (click)="checkWhetherEInvGeneratedOrNot(invoice_no)" class="btn-style bg-denim ">E-Invoice</button>
                </span>
            </span>
                <button type="button" class="btn-style bg-brown mr-3" (click)="viewJournal(invoice_no)" id="journal_gst_invoice_btn">
                    Journal
                </button>
                <button type="button" class="btn-style bg-indigo" (click)="openActivity(invoice_no)">
                    Activity
                </button>
              
                <!-- <button type="button"  (click)="viewJournal(invoice_no)" class="btn-style mr-3">Journal</button> -->
            </ng-container>
            <ng-container *ngIf="invoiceMode != 'view'">
                <button type="button" (click)="closeMaxModule()" class="btn-style bg-green  mr-3">
                    Reset
                </button>
                <button tabIndex="21" attr.tabIndex="21" type="button" class="btn-style bg-purple"
                    (click)="openSaveConfirmModal()" id="save_gst_invoice">
                    Save
                </button>
            </ng-container>
           
        </div>
    </div>
</form>

<button type="button" hidden #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button>

<div class="modal fade" id="deleteConfirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" (click)="resetCurrentOpendModal()" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" #closeDeleteModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to delete this entry ?
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
                <button type="button" class="btn-style mr-2" (click)="closeDeleteConfirmModal()" id="no_delete_gst_invoice_confirm_modal_btn">No</button>
                <button type="button" class="btn-style" (click)="deleteInvoice(invoice_no)" id="yes_delete_gst_invoice_confirm_modal_btn">Yes</button>
            </div>
        </div>
    </div>
</div>
<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateConfirmationModal"></button>

<div class="modal fade" id="updateConfirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close" (click)="resetCurrentOpendModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>" update ‎this entry " </b> ?
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
                <button type="button" class="btn-style mr-2" (click)="closeUpdateConfirmModal()" id="no_update_gst_invoice_confirm_modal_btn">No</button>
                <button type="button" class="btn-style bg-purple"
                    (click)="updateProduct()" id="yes_update_gst_invoice_confirm_modal_btn">Yes</button>
            </div>
        </div>
    </div>
</div>

<button type="button" hidden #confirmSaveModal data-toggle="modal" data-target="#saveConfirmationModal"></button>

<div class="modal fade" id="saveConfirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" #closeSaveModal data-dismiss="modal" aria-label="Close" (click)="resetCurrentOpendModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>" save ‎this entry " </b> ?
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
                <button type="button" class="btn-style mr-2" (click)="closeSaveConfirmModal()" id="no_save_gst_invoice_confirm_modal_btn">No</button>
                <button type="button" class="btn-style bg-purple"
                    (click)="saveProductInvoice()" id="yes_save_gst_invoice_confirm_modal_btn">Yes</button>
            </div>
        </div>
    </div>
</div>

<button hidden #openMasterCreationModal data-target="#masterCreationModal"data-toggle="modal"></button>
<div
  class="modal"
  id="masterCreationModal"
  role="dialog"
  aria-labelledby="masterModalLabel"
  data-backdrop="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex justify-content-center">
          <h4 class="modal-title">Create {{currentMaster}}</h4>
        </div>
        <button
          type=" button"
          class="close"
          #closeMasterCreationModal
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
	    <app-master-creation [masterType]="currentMaster" [masterCreationModal]="masterCreationModal" [currentMasterName]="currentMasterName" 
		(callSetFormData)="setFormValues($event)"
		></app-master-creation>
     </div>
    </div>
</div>

<button type="button" hidden #generateEInvoiceModal data-toggle="modal" data-target="#openGenerateEInvoiceModal"></button>

<div class="modal fade" id="openGenerateEInvoiceModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Confirmation</h4>
                <button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                E-invoice not generated,do you want to generate? 
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-style mr-2" (click)="closeGenerateEInvoiceModal()">No</button>
                <button type="button" class="btn-style bg-red" (click)="generateEInvoice(invoice_no)">Yes</button>
            </div>
        </div>
    </div>
</div>