import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CookiesService } from 'src/app/shared/cookies.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
	resetPasswordForm;
	showMessage: boolean = false;
	alertType = '';
	message: string = ``;
	isLoginPage = true;
  token: any;
  userName = ""
	constructor(
		public commonService: CommonServiceService,
		private fb: FormBuilder,
		private spinner: NgxSpinnerService,
    private router: Router,
    private route : ActivatedRoute,
	private cookieService : CookiesService
	) {
    this.route.queryParams.subscribe(params => {
      debugger
      this.token = params['token'];
      this.userName = params['username'];
      console.log(this.token)
		});
   }

	ngOnInit() {
		this.resetPasswordForm = this.fb.group({
			newPassword: ["", Validators.required],
			confirmPassword: ["", Validators.required]
		}, { validator: this.checkPasswords });
		localStorage.removeItem("token")
  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
		let pass = group.controls.newPassword.value;
		let confirmPass = group.controls.confirmPassword.value;
		return pass === confirmPass ? null : { notSame: true }
	}
	get newPassword() {
		return this.resetPasswordForm.controls.newPassword;
	}
	get confirmPassword() {
		return this.resetPasswordForm.controls.confirmPassword;
	}

	resetPassword(value) {
		let requestObj = {
      "token": this.token,
      "new_password": value.newPassword
      }
		this.commonService.postWithOutInterceptor(`${environment.baseAccountUrl}/okount/updatepassword`, requestObj).subscribe(res => {
			console.log(res)
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = 'Password updated. Login'
		}, err => {
			console.log(err)
			let errorMessage = "Failed to update password."
			try {
				let error = JSON.parse(err['_body'])
				if (error.message != undefined) {
					errorMessage = error.message
				}
			} catch (error) {

			}
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = errorMessage
		})
	}

	hideError() {
		this.showMessage = false
	}

	login(){
		this.cookieService.deleteCookie('SYSTACC_ACCESS_TOKEN')
		this.cookieService.deleteCookie('SYSTACC_FINANCIAL_YEAR')
		this.cookieService.deleteCookie('SYSTACC_USER_LOGIN_DATA')
		window.location.href = environment.accountManagement+"/signin"
	}

}
