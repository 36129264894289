import { Injectable } from '@angular/core';
import * as moment from 'moment'
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { throwError, of, empty } from 'rxjs';
import { BsDatepickerConfig } from 'ngx-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ReceiptService {
	receiptForm : any
	receiptEntryForm : any
	// bsConfig: Partial<BsDatepickerConfig>;
	// colorTheme = 'theme-orange';
  showAddreceiptScreen: boolean = false
  
  receiptEntries : any = []
	isEdit: boolean;
	accountList: any;
	otherAccountList: any;
	ledgerList: any;
	constructor(
		private fb: FormBuilder,
		private http: Http,
		private httpClient: HttpClient
	) {

		// this.bsConfig = Object.assign({ dateInputFormat: 'DD-MM-YYYY' }, { containerClass: this.colorTheme }, { showWeekNumbers: false })
		this.receiptForm = this.fb.group({
			"receipt_id": ['', Validators.required],
			"receipt_code": [''],
			"amount": ['', Validators.required],
			"accountCodeTo": ['', Validators.required],
			"accountCodeToType": [''],
			"receiptDate": ['', Validators.required],
		})

		this.receiptEntryForm = this.fb.group({
			"account_code_from": [""],
			"account_code_from_name": [""],
			"account_code_from_type": ["client"],
			"account_code_to_name": [""],
			"account_code_to": [""],
			"account_code_to_type": [""],
			"cheque_no": [""],
			"cheque_date": [null],
			"cheque_bank_name": [""], //No input field available
			"amount": [0],
			"narration_1": [""],
			"narration_2": [""],
			"narration_3": [""],
			"narration_4": [""],
			"narration_5": [""],
			"remark_1": [""],
			"remark_2": [""],
			"remark_3": [""],
			"remark_4": [""],
			"remark_5": [""],
			"sub_ledger_code_1_type": ["client"],
			"sub_ledger_code_2_type": [""],
			"sub_ledger_code_3_type": [""],
			"sub_ledger_code_4_type": [""],
			"sub_ledger_code_5_type": [""],
			"sub_ledger_code_1": [""],
			"sub_ledger_code_2": [""],
			"sub_ledger_code_3": [""],
			"sub_ledger_code_4": [""],
			"sub_ledger_code_5": [""],
			"sub_ledger_code_1_name": [""],
			"sub_ledger_code_2_name": [""],
			"sub_ledger_code_3_name": [""],
			"sub_ledger_code_4_name": [""],
			"sub_ledger_code_5_name": [""],
			"other_transaction_type": [""],
			"other_account":[""],
			"other_account_name":[""],
			"other_amount" : [""]
		  })
	 }

	


	postMethod(url,data){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.post(`${environment.okountUrl}/${url}`,data, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}
	putMethod(url,data){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.put(`${environment.okountUrl}/${url}`,data, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}
	deleteMethod(url){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.delete(`${environment.okountUrl}/${url}`, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}
	getMethod(url){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.get(`${environment.okountUrl}/${url}`, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}

	searchAccount(url) {
		let token = localStorage.getItem('token');
		const headers = new HttpHeaders({
			'Authorization': token
		});
		return this.httpClient.get(`${environment.okountUrl}/${url}`)
			.pipe(map(res => {
				// console.log(res);
				return res;
				// console.log('Res data', res);
			}),
				catchError((err) => {
					return throwError(err);
				}))
	}


}
