import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver'
import { LoaderService } from 'src/app/shared/loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';

@Component({
  selector: 'app-print-jv',
  templateUrl: './print-jv.component.html',
  styleUrls: ['./print-jv.component.scss']
})
export class PrintJvComponent implements OnInit {

  jvData: any;
  jvId: any;
  valid: boolean;
  shortcuts: ShortcutInput[] = [];
  @Input() reference_code;
  @Input() preFetchedData;
  @Output() callSaveFetchedData: EventEmitter<any> = new EventEmitter();

  constructor(
    public commonService: CommonServiceService,
	private spinner: NgxSpinnerService,
    private loaderService: LoaderService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,

  ) { 
    this.route.paramMap.subscribe(params => {
			this.jvId = params['params'].id
			if (this.jvId != undefined) {
				document.title = `JV -  ${this.jvId}`
			}
		});
  }

  ngOnInit(): void {
	if(!this.preFetchedData){
		if(!this.jvId){
			this.jvId = this.reference_code
		}
		this.getJvData(this.jvId)
	}else{
		this.jvData = this.preFetchedData
		this.valid = true
	}
    
  }

  getJvData(searchId: any) {
		try {
			this.commonService.getRequest(`${environment.okountUrl}/jv/download?jv_code=${searchId}&preview=true`).subscribe(async (res) => {
        this.valid = true;
				this.jvData = this.transform(res)
			},
				err => {
					this.loaderService.hide()
				})
		} catch (error) {
			console.log('Error occured in gettting jv due to : ', error)
		}
	}
	transform(html) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}

  downloadPdf(searchId: any) {
		this.commonService.downloadPDF(`${environment.okountUrl}/jv/download?jv_code=${searchId}&preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `JV-${searchId}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in download pdf due to : ', error)
	}
}
