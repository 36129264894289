import { Injectable } from '@angular/core';
import { CommonServiceService } from './common-service.service';
import { XlsDownloadService } from './xls-download.service';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class DownloadService {

	constructor(
		public commonService: CommonServiceService,
		private xlsxDownloadService: XlsDownloadService) { }


	exportAsXLSX(fileName, xlsxData ):void {
		this.xlsxDownloadService.exportAsExcelFile(xlsxData, fileName);
	}

	exportAsXLSXGST(fileName, xlsData ):void {
		this.xlsxDownloadService.exportAsExcelFileGST(xlsData, fileName);
	}

	callPdfGeneratorApi(fileName, url,printOptions){
		window.scrollTo(0,0)
		if(url && localStorage.getItem('okount_token')){
			let splitHash = url.split('?')
			if(splitHash && splitHash.length > 1){
				url += `&access_token=${localStorage.getItem('okount_token')}`
			}else{
				url += `?access_token=${localStorage.getItem('okount_token')}`
			}
		}
		let downloadUrl = `${environment.baseAccountUrl}${environment.name == 'default' || environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/${url}`;
		

		let requestBody = {
			urltemp : downloadUrl,
			waitForSelector : '.voucher-container',
			printOptions : printOptions
		}
		this.commonService.generatePDF(requestBody).subscribe(res=>{
			let fileBlob = res.blob();
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
		},
		err => {
			console.log(err)
		});
	}
}
