<div class="ledger-container" #tableHeader tabIndex="0" [ngClass]="{ 'ledger-print-section': isPrint == 'true' }">
	<div class="logo-wrapper" *ngIf="isPrint"><div id="waterMark" [ngStyle]="{background: waterMark}"></div></div>
	<!-- <div class="alert alert-success msgSuccess" >
       messageSuccess
    </div>
    <div class="alert alert-danger msgError" >
       messageError
    </div> -->
	<!-- <div class="heading-box mt-3 ml-2">
        <div class="heading">Normal Ledger</div>
        <span class="hr-line"></span>
      </div> -->
	
	  <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
		<div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
		  <div class="d-flex flex-y-center">
			<img
			  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
				alertType=='success-box' ? 'green-sucess.svg' : 
				alertType=='warning-box' ? 'red-warning.svg' : 
				null }}"
			  width="20px"
			  height="20px"
			  alt="alert-logo"
			  class="mr-20"
			/>
			<div class="ml-10">
			  <h4 class="m-0 text-size-18 text-bold"
			  [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
				{{alertType === "error-box"
				? "Error"
				: alertType === "warning-box"
				? "Warning"
				: alertType === "success-box"
				? "Success"
				: null}}
			  </h4>
			  <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
			   {{message}}
			  </small>
			</div>
		  </div>
		  <img
			src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
			alt="close-btn"
			(click)="hideError()"
			className=" close-btn"
		  />
		</div>
	  </div>

	<div *ngIf="leaderData != undefined">
		<div class="voucher-container" >
			<!-- <div class="row">
				<div class="address col-4 float-left">
					<span class="cname">{{leaderData?.company_details?.name}}</span><br>
					<span class="address_line">{{leaderData?.company_details?.address_1}}
						{{leaderData?.company_details?.address_2}} {{leaderData?.company_details?.address_3}}</span>

				</div>

				<div class="col-4">
					<h3 class="receipt-head mt-2">
						Ledger Statement<br>
					</h3>
				</div>

				<div class="address col-4 float-right field-info">
					<span class="cname"> Date:
						<span>{{currentDate | date : 'dd/MM/yyyy'}}</span>
					</span>
				</div>
			</div> -->
			<!-- <div class="tab-nav d-flex align-item-center justify-content-between">
				<ul class="list-inline list-unstyled p-0 m-0">
					<li class="active page-title text-uppercase cursor"> SubGrouping Ledger</li>
				</ul>
				<ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
					<li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition">
						Dashboard</li>
					<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
					<li class="text-grey text-capitalize cursor text-primary transition"> SubGrouping Ledger</li>
				</ul>
			</div> -->
			<div style="background: #fff!Important;" class="row leader-header tab-nav pl-0 pr-0">
				<div class="col-sm-4 pr-0  ">
					<div *ngIf="isPrint">
						<span class="text-bold text-black text-uppercase" style="font-size: 15px;">{{leaderData?.company_details?.name}}</span><br>
						<p class="address_line mb-0 text-grey text-uppercase" style="font-size: 13px;"><span style="font-size: 13px;"  class="d-block">{{leaderData?.company_details?.address_1}}</span>
						  <span style="font-size: 13px;">{{leaderData?.company_details?.address_2}}</span>  <span class="ml-2" style="font-size: 13px;">{{leaderData?.company_details?.city_name}}</span> <span style="font-size: 13px;" class="ml-2">{{leaderData?.company_details?.address_3}}</span></p>
					   </div>
					<ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0" >
						<li  class="print-d-none" [routerLink]="['/']"
							class="text-grey text-capitalize cursor  hover-text-primary transition" [ngClass]="{'d-none': isPrint == 'true'}"> Analysis</li>
						<li  class="print-d-none"><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt="" [ngClass]="{'d-none': isPrint == 'true'}"></li>
						<li style="min-width: fit-content;" class="print-d-none text-grey text-capitalize cursor text-primary transition" [ngClass]="{'d-none': isPrint == 'false'}" [ngClass]="{'d-none': isPrint == 'true'}"> Subgrouping Ledger:- </li>
						<li class="text-primary "><ng-container *ngFor="let data of leaderData['all_clients_records']" >
						  <span [ngClass]="{ 'font-size-15': isPrint == 'true' }" class="text-bold line-clamp line-clamp-1" >
							  {{data['display_name']}}
						  </span>
					  </ng-container></li>
					</ul>
				</div>
				<div class="col-sm-4 text-center">
					<h4 style="font-size: 17px;" *ngIf="isPrint" class="text-uppercase text-bold text-black mt-2">
						SubGrouping Ledger Statement<br>
					  </h4>
					<span class="rec-date d-block text-center">
						<div class="noWrap justify-content-around">
							<ng-container *ngIf="leaderData.duration != undefined">
								<div class="cname">  <span  [ngClass]="{ 'font-size-15': isPrint == 'true' }"   
									>From</span>
									<h5 style="margin-right: 5px;" class="mb-0  d-inline"><span class="date text-bold text-orange" [ngClass]="{ 'font-size-15': isPrint == 'true' }"
											*ngIf="leaderData.duration.from_date != ''">
											{{leaderData?.duration?.from_date | date : 'd MMMM, y'}} 
										</span>
										<span [ngClass]="{ 'font-size-15': isPrint == 'true' }" class="date text-bold" *ngIf="leaderData.duration.from_date != ''">
											To
										</span>
										<span [ngClass]="{ 'font-size-15': isPrint == 'true' }" class="date text-bold" *ngIf="leaderData.duration.from_date == ''">
											Till Date
										</span>
										<span [ngClass]="{ 'font-size-15': isPrint == 'true' }" class="date text-bold text-orange">
											{{leaderData?.duration?.to_date | date : 'd MMMM, y'}}
										</span>
									</h5>
									<button
									(click)="openAdvanceSearchModal()"
									[ngClass]="{'d-none': isPrint == 'true'}" title="Custom Search" class="btn   save-as text-bold bg-whiteSmoke "
										type="button" >
										<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
									</button>
								</div>
							</ng-container>								
						</div>
					</span>
				</div>

				<div class="col-sm-4 d-flex align-item-center justify-content-end">
					<div class="text-right mt-2" *ngIf="isPrint">
						<span class="d-block text-black" [ngClass]="{ 'font-size-12': isPrint == 'true' }">{{currentDate | date : 'dd/MM/yyyy'}}</span>
						<span class="d-block text-grey"  [ngClass]="{ 'font-size-12': isPrint == 'true' }"> {{currentTime}}</span>
					  </div>

							<div
			class="page-search-box input-group d-flex align-item-center" 
      [ngClass]="{ 'd-none': isPrint == 'true' }"
			style="height: fit-content; text-align: left"
		  >
					<input
					  type="text"
					  class="form-control"
					  oninput="this.value = this.value.toUpperCase()"
					  placeholder="search"
					  style="text-align: left"
					/>
				  </div>
				  <div class="dropdown-btn position-relative" 
				  [ngClass]="{ 'd-none': isPrint == 'true' }">
					<button title="More..." class="btn-style mt-0 more-btn">
					  <span class="dot"></span><span class="dot"></span
					  ><span class="dot"></span>
					</button>
					<ul class="list-unstyled pl-0 dropdown-box">
					  <li  (click)="openModal()">
						<img
						  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-emal.svg"
						  alt=""
						/>
						<span class="ml-2">Email</span>
	  
						<button
						hidden
						#openEmailModal
						data-target="#emailModalInSubgroup"
						data-toggle="modal"
					  ></button>
					  </li>
				

					  <li (click)="openSubgroupLedgerModal()">
						<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg" alt="" />
						<span class="ml-2">Save As Pdf</span>
					  </li>



					  <li (click)="exportAsXLSX(leaderData?.duration?.from_date  ,leaderData?.duration?.to_date)" >
						<span class="ml-2">Excel</span>
					  </li>
					</ul>
				  </div>
					<!-- <div class="save-button d-flex pull-right mt-2 align-items-center" [ngClass]="{'d-none': isPrint == 'true'}">
						<button class="btn mr-1  save-as text-bold bg-whiteSmoke text-green dropdown-toggle" type="button"
							(click)="openModal()">
							<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/blue-email.svg" alt="">
							<span class="ml-1 text-blue">Email To</span>
						</button>
						<button hidden #openEmailModal data-target="#emailModal" data-toggle="modal"></button>
						<div class="dropdown">
							<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-download.svg" alt="">
								<span class="ml-1 text-green">Save As</span>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
								<a class="dropdown-item"
									(click)="exportAsXLSX(leaderData?.duration?.from_date  ,leaderData?.duration?.to_date)">EXCEL</a>
								<a class="dropdown-item" href="#">CSV</a>
								<a class="dropdown-item"
									(click)="callPdfGeneratorApi(leaderData?.duration?.from_date  ,leaderData?.duration?.to_date)">PDF</a>
							</div>
						</div>
					</div> -->
				</div>
			</div>
			<!-- row -->



			<div class="table-section subledger-table" [ngClass]="{'table-print-section': isPrint == 'true'}">
				<table class="table table striped">
					<thead>
						<tr *ngIf="isPrint" style="border-bottom: none!important;">
							<th colspan="7" class="page-top-space">
							</th>
						  </tr>
						<tr>
							<th class="text-left date" style="max-width: 105px">Date </th>

							<th style="max-width: 175px " class="text-left doc-no">Doc No
							</th>
							<th class="text-left particular">Account Description</th>
							<th class="text-right voucher"><span class="text-center d-block text-bold"> Voucher</span></th>
							<th class="text-right debit" style="min-width: 180px; max-width: 190px">
								<span class="text-right d-block text-bold">  Debit</span>
								<!-- <i *ngIf="ascSortAmt" (click)="amtSort(i)" class="fas fa-sort-alpha-up"></i>
								<i *ngIf="!ascSortAmt" (click)="amtSort(i);" class="fas fa-sort-alpha-down-alt"></i> -->

							</th>
							<th class="text-right credit" style="min-width: 180px; max-width: 190px">
								<span class="text-right d-block text-bold">  Credit</span>
							  </th>
							<!-- REMOVED BALANCE AS IT IS NOT REQUIRED IN GROUPING -->
							  <!-- <th class="text-right balance" style="max-width: 155px">
								<span class="text-right d-block text-bold" > Balance</span>
							</th> -->

						</tr>
					</thead>
					<tbody class="scroll-body main-panel" [ngClass]="{'full-height-table': isPrint == 'true'}"   [ngStyle]="{height: innerHeight}"
						(window:keydown)="onKeyDown($event)">
						<tr class="opening-bal" *ngIf="isPrint">
							<td class="text-left text-grey date" style="max-width: 105px;">{{ leaderData.duration.from_date | date: "dd/MM/yyyy"}}</td>
							<td class="text-left text-grey doc-no-particular" style="max-width: 175px;"><p class="text-bold text-black undl mb-0">OPENING BALANCE</p></td>
							<td class="text-left text-grey" ></td>
							<td class="text-right text-grey voucher" ></td>
							<td class="text-right text-red debit"  style="min-width: 180px; max-width: 190px;">
								<span class="bal-amount debit-amt text-bold"
								*ngIf="totalAmountObj && totalAmountObj?.openingBalanceType == 'debit' else debitSide">

								 {{totalAmountObj?.openingBalance && totalAmountObj?.openingBalance < 0 ?
									(totalAmountObj?.openingBalance*-1  | number :'1.2-2'): totalAmountObj?.openingBalance | number
									:'1.2-2'}} </span>
							</td>
							<!-- REMOVED BALANCE AS IT IS NOT REQUIRED IN GROUPING -->
							<!-- <td class="text-right text-green credit"  style="min-width: 180px; max-width: 190px;">
								<span class="bal-amount text-green credit-amt text-bold"
								*ngIf="totalAmountObj && totalAmountObj?.openingBalanceType == 'credit' else creditSide">
								 {{totalAmountObj?.openingBalance && totalAmountObj?.openingBalance < 0 ?
									(totalAmountObj?.openingBalance*-1  | number :'1.2-2') : totalAmountObj?.openingBalance | number
									:'1.2-2'}} </span>
							</td> -->
							<td class="text-right text-grey balance" style="min-width: 135px;max-width: 150px;" ></td>
						  </tr>
						<ng-container *ngFor="let data of leaderData['all_clients_records']">
							<!-- <span class="client-name">{{data['display_name']}}<br> 
							</span> -->
							<!-- <tr class="alert-warning">
								<td colspan="6">
									<div class="row w-100">
										<span class="client-name" style="width: 50%;">
											{{data['display_name']}}
										</span>
									</div>
								</td>
							</tr> -->

							<ng-container *ngFor="let client_record of data['client_records'] let i = index">
								<ng-container
									*ngIf="client_record['records'].length > 0 && client_record['aggregated_name'] != 'Opening Balance'">

									<span  class="client-name subgrouping-name text-decoration text-uppercase">
										<span 
										style="text-decoration: none;">{{client_record['aggregated_name']}}
									</span>
									</span>
									<tr class="table-row-break" *ngFor="let record of client_record['records'] let j=index"
										[attr.tabIndex]="i+j" id="rowIndex_{{i}}_{{j}}"
										(keyup.enter)="openViewMode(record.reference,record.reference_type, record.inv_reference_type)"
										[ngClass]="{
											'd-none': record.reference_type == 'opening_balance' && !isPrint,
											'borderOrange' : record?.debit > 0 ,
											'borderGreen' : record?.credit > 0}">
										<td                   style="max-width: 105px"
										class="text-left text-grey date"
									(click)="moveToIndex(i,j)">{{record?.timestamph | date
											:
											'dd/MM/yyyy'}}</td>
										<td style="max-width: 175px;" (click)="moveToIndex(i,j)"
										class="text-left transition text-bold cursor doc-no refer">
										<a
												[ngClass]="{'text-hyperlink': record.transaction_ref_no != null && record.transaction_ref_no != undefined}"
												(click)="openViewMode(record.transaction_ref_no,record.reference_type, record.inv_reference_type)">{{record?.transaction_ref_no}}</a>
												<a class="d-block" *ngIf="record?.x_field_1 && record.x_field_1!='null'">{{record?.x_field_1}}</a>
										</td>
										<td (click)="moveToIndex(i,j)" class="text-left text-grey particular">
											<ng-container *ngFor="let narration of record.narration.split(',')">
												<ng-container *ngIf="narration.trim() != ''">
													<span class="text-grey d-block">{{ narration }} </span>
												</ng-container>
											</ng-container>

											<!-- <span class="d-block">HARkesh Saini +4</span>
											<span class="d-block">Del-BOM-AND-BOM-DEL | TKT No. - 12345678901234</span>
											<span class="d-block">PNR - HSTPWSA | Flt No. - 6E-337 | TRV DT. - 02-01-2022</span>
											<span class="d-block">narration - testing.... </span> -->
											</td>
											<td  class="text-right text-grey voucher"><span class="text-center d-block">{{record.reference_type}}</span></td>

										<td (click)="moveToIndex(i,j)" class="text-right text-red debit"
										style="min-width: 180px; max-width: 190px">{{record?.debit}}
										</td>
										<td (click)="moveToIndex(i,j)"  class="text-right text-green credit"
										style="min-width: 180px; max-width: 190px">{{record?.credit}}
										</td>
										<!-- REMOVED BALANCE AS IT IS NOT REQUIRED IN GROUPING -->
										<!-- <td (click)="moveToIndex(i,j)" 
										class="text-right text-blue balance"
										style="max-width: 150px">{{record?.balance}}
										</td> -->
									</tr>
									<tr class="grand-total">
										<!-- <td class="grand text-left"> To receive : INR {{data?.outstanding | number : '1.2-2'}}</td> -->
										<td  class="text-left"></td>
										<td ></td>
										<td class="text-bold text-left">Sub Total:</td>
										<td class="text-center"></td>
										<td class="text-right text-red debit" style="min-width: 180px;    max-width: 190px;">
											{{client_record?.total_debit | number : '1.2-2'}}
										</td>
										<td class="text-right text-green credit" style="min-width: 180px;    max-width: 190px;">
											{{client_record?.total_credit | number : '1.2-2'}}
										</td>
										<!-- REMOVED BALANCE AS IT IS NOT REQUIRED IN GROUPING -->
										<!-- <td style="max-width: 150px;" class="text-right text-blue balance">{{
											client_record['records'][client_record['records'].length-1]?.balance |
											number : '1.2-2'}}</td> -->
									</tr>
								</ng-container>

							</ng-container>

						</ng-container>
						<tr class="" *ngIf="isPrint">
							<td class="text-left text-grey date" style="max-width: 105px;"></td>
							<td class="text-left text-grey doc-no-particular" style="max-width: 175px;"><p class="text-bold text-black mt-2 mb-0">SUBTOTAL </p></td>
							<td class="text-left text-grey" ></td>
							<td class="text-left text-grey " ></td>
							<td class="text-right ledger-subtotal text-red debit" style="min-width: 180px; max-width: 190px;">
								<span class="bal-amount debit-amt text-bold">
									<ng-container *ngIf="totalAmountObj?.openingBalanceType == 'debit';else onlyDebitAmount">
									  {{totalAmountObj?.debit | number: "1.2-2" }}
									</ng-container>
									<ng-template #onlyDebitAmount>
									  {{ totalAmountObj?.debit | number: "1.2-2" }}
									</ng-template>
								  </span>
							</td>
							<td class="text-right ledger-subtotal text-green credit"  style="min-width: 180px; max-width: 190px;">
								<span class="bal-amount credit-amt text-bold">
									<ng-container *ngIf="totalAmountObj?.openingBalanceType == 'credit';else onlyCreditAmount">
									  {{totalAmountObj?.credit | number: "1.2-2" }}
									</ng-container>
									<ng-template #onlyCreditAmount>
									  {{ totalAmountObj?.credit | number: "1.2-2" }}
									</ng-template>
								  </span>
							</td>
							<!-- REMOVED BALANCE AS IT IS NOT REQUIRED IN GROUPING -->
							<!-- <td class="text-right text-grey balance" style="min-width: 135px;max-width: 150px;" ></td> -->
						  </tr>
						<tr class="closing-bal" *ngIf="isPrint">
							<td class="text-left text-grey date" style="max-width: 175px;">{{ leaderData.duration.from_date | date: "dd/MM/yyyy"}}</td>
							<td class="text-left text-grey doc-no-particular" style="max-width: 175px;"><p class="text-bold text-black undl mb-0">CLOSING BALANCE</p></td>
							<td class="text-left text-grey" ></td>
							<td class="text-left text-grey" ></td>
							<td class="text-right text-red debit" style="min-width: 180px; max-width: 190px;">
								<span class="bal-amount debit-amt text-bold"
								*ngIf="totalAmountObj && totalAmountObj.type == 'DR.' else debitSide">
								{{totalAmountObj.diff && totalAmountObj.diff < 0 ? totalAmountObj.diff*-1 :
									totalAmountObj.diff | number :'1.2-2'}} </span>
							</td>
							<td class="text-right text-green credit"  style="min-width: 180px; max-width: 190px;">
								<span class="bal-amount text-green credit-amt text-bold"
										*ngIf="totalAmountObj && totalAmountObj.type == 'CR.' else creditSide">
										 {{totalAmountObj.diff && totalAmountObj.diff < 0 ? totalAmountObj.diff*-1 :
											totalAmountObj.diff | number :'1.2-2'}} </span>
							</td>
							<!-- REMOVED BALANCE AS IT IS NOT REQUIRED IN GROUPING -->
							<!-- <td class="text-right text-grey balance" style="min-width: 135px;max-width: 150px;" ></td> -->
						  </tr>
						  <tr *ngIf="isPrint">
							<td colspan="2" class="text-left text-grey doc-no-particular" style="width: auto!important;
							max-width: unset!important;" >
							  <p class="text-bold text-black mb-0">Amount Receivable: <span class="ml-2 text-bold">31,365.00</span></p>
							</td>
							<td class="text-left text-grey" ></td>
							<td class="text-left text-grey " ></td>
							<td class="text-right text-red debit" style="min-width: 180px; max-width: 190px;">
							 
							</td>
							<td class="text-right text-green credit"  style="min-width: 180px; max-width: 190px;">
						  
							</td>
							<!-- REMOVED BALANCE AS IT IS NOT REQUIRED IN GROUPING -->
							<!-- <td class="text-right text-grey balance" style="min-width: 135px;max-width: 150px;" ></td> -->
						  </tr>
						  <tr  *ngIf="isPrint" style="    border-bottom: none!important;">
							<td colspan="6" class="text-right text-grey" >
							  <h5 class="mb-0 text-bold" style="font-size: 16px;">For {{leaderData?.company_details?.name}}</h5>
							</td>
						  </tr>
						<tr *ngIf="leaderData['all_clients_records'] == undefined">
							<div class="w-100 text-center" style="padding: 20px;">
								<span><img class="not-found-img" src="{{commonService.aws_asset_url}}/_assets/images/problem.png"></span> <br>
								<span class="not-found-text">No matching items found</span>
							</div>
						</tr>
					</tbody>
				</table>
				<!-- <div class="field-pagination" style="position: absolute;bottom: 0;">
					<pagination-controls (pageChange)="setHeight(p, $event,undefined)" *ngIf="leaderData != undefined" id="clientData"></pagination-controls>
				 </div> -->

				<div id="print-section" class="balance-box ledger-table-box" *ngIf="!isPrint">
					<div></div>
					<div class="balance-container m-0">
						<div class="bal-item text-bold "><span>Opening Balance</span>
							<span class="bal-amount debit-amt text-bold"
								*ngIf="totalAmountObj && totalAmountObj?.openingBalanceType == 'debit' else debitSide">

								 {{totalAmountObj?.openingBalance && totalAmountObj?.openingBalance < 0 ?
									(totalAmountObj?.openingBalance*-1  | number :'1.2-2'): totalAmountObj?.openingBalance | number
									:'1.2-2'}} </span>
									<span class="bal-amount text-green credit-amt text-bold"
										*ngIf="totalAmountObj && totalAmountObj?.openingBalanceType == 'credit' else creditSide">
										 {{totalAmountObj?.openingBalance && totalAmountObj?.openingBalance < 0 ?
											(totalAmountObj?.openingBalance*-1  | number :'1.2-2') : totalAmountObj?.openingBalance | number
											:'1.2-2'}} </span>
						</div>
						<div class="bal-item text-bold"><span>Current Balance</span>
							<span class="bal-amount debit-amt text-bold">
								{{totalAmountObj?.debit | number :'1.2-2'}}
							</span>
							<span class="bal-amount text-green credit-amt text-bold">
								 {{totalAmountObj?.credit | number : '1.2-2'}}
							</span>
						</div>
						<div class="bal-item  text-bold "><span>Closing Balance</span>
							<span class="bal-amount debit-amt text-bold"
								*ngIf="totalAmountObj && totalAmountObj.type == 'DR.' else debitSide">
								{{totalAmountObj.diff && totalAmountObj.diff < 0 ? totalAmountObj.diff*-1 :
									totalAmountObj.diff | number :'1.2-2'}} </span>
									<span class="bal-amount text-green credit-amt text-bold"
										*ngIf="totalAmountObj && totalAmountObj.type == 'CR.' else creditSide">
										 {{totalAmountObj.diff && totalAmountObj.diff < 0 ? totalAmountObj.diff*-1 :
											totalAmountObj.diff | number :'1.2-2'}} </span>
						</div>
					</div>
				</div>
				<ng-template #debitSide>
					<span class="bal-amount debit-amt text-bold">
						{{0 | number :'1.2-2'}}
					</span>
				</ng-template>
				<ng-template #creditSide>
					<span class="bal-amount text-green credit-amt text-bold">
						{{0 | number :'1.2-2'}}
					</span>
				</ng-template>
			</div>
			

		</div>
	</div>
	<!-- Email Modal for sending mail -->
	<div class="modal" id="emailModalInSubgroup" role="dialog" aria-labelledby="emailModalLabel" data-backdrop="false">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header ">
					<div class="d-flex justify-content-center">
						<h4 class="modal-title ">Send Email</h4>
					</div>
					<button type=" button" class="close" #closeSendEmailModal data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body emailSenders">

					<!-- <div class="d-flex justify-content-end align-item-center">
							<span *ngIf="!ccEmail" (click)="showCCEmail()" class="text-size-14 mb-0 cursor  mr-2">Cc</span>
							<span *ngIf="!bccEmail" (click)="showBCCEmail()" class="text-size-14 mb-0 cursor">Bcc</span>
						</div> -->
					<form [formGroup]="sendEmailForm" style="margin-top: -10px;">
						<div class="form-group text-input-field">
							<textarea class="input-field ng-pristine ng-valid ng-touched text-size-12" id="toEmail"
								rows="3" formControlName="toEmails" required></textarea>
							<label for="To" class=" mb-0">To</label>

						</div>
						<div class="d-flex justify-content-end">
							<!-- <label for="To" class="text-size-14 mb-0">To</label> -->
							<div *ngIf="sendEmailForm.controls.toEmails.invalid">
								<div *ngIf="sendEmailForm.controls.toEmails.errors?.required" style="color:red">
									To Email is required.
								</div>
							</div>
						</div>


						<div class="form-group text-input-field mt-0">
							<textarea class="input-field ng-pristine ng-valid ng-touched text-size-12" id="ccEmail"
								rows="3" formControlName="ccEmails"></textarea>
							<label for="Cc" class=" mb-0">Cc</label>
						</div>

						<div class="form-group text-input-field">
							<textarea class=" input-field ng-pristine ng-valid ng-touched text-size-12" id="bccEmail"
								rows="3" formControlName="bccEmails"></textarea>
							<label for="Bcc" class=" mb-0">Bcc</label>
						</div>

						<div class="text-input-field">
							<input formControlName="subject"  class="input-field text-size-12" type="text" 
							autocomplete="off" />
							<label>Subject</label>
						</div>
						
						<div class="form-group text-input-field">
							<textarea
							  class="input-field ng-pristine ng-valid ng-touched text-size-12"
							  rows="5"
							  formControlName="body"
							></textarea>
							<label for="body" class="mb-0">Mail Body</label>
						  </div>
						  
						<div class="d-flex justify-content-center align-item-center">
							<button type="button" class="btn-style mr-1" data-dismiss="modal">Cancel</button>
							<button type="button" class="btn-style bg-green" (click)="sendEmails()">Send</button>
						</div>

					</form>
				</div>
			</div>
		</div>
	</div>

<button type="button" hidden #openSubgroupModal data-toggle="modal" data-target="#pdfModal"></button>
              
<div class="modal fade" id="pdfModal" role="dialog" aria-hidden="true" data-backdrop="false">
	<div class="modal-dialog modal-md" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4>Confirmation</h4>
				<button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body d-flex">
				<div><img src="https://systacc-assets.s3.ap-south-1.amazonaws.com/_assets/images/direct-download.png" width="40px" height="40px"/></div>
				<p style="margin-left:10px;" class="text-uppercase text-black">This file contains extensive data.,<br>Would you prefer to schedule a download for it?</p>
			  </div>
			<div class="modal-footer">
				<button type="button" class="btn-style mr-2" (click)="onNoClickSubgroupModal()">No</button>
				<button type="button" class="btn-style bg-blue"
					(click)="onClickSubgroupModal()">Yes</button>
			</div>
		</div>
	</div>
</div>




</div>