import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Http , Headers} from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonServiceService } from 'src/app/shared/common-service.service';

@Injectable({
  providedIn: 'root'
})
export class ContraService {

	accountList= [];
	ledgerList = [];
	updateContraForm: any;

  constructor(private http: Http,private httpClient: HttpClient) { }

  getMethod(url){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.get(`${environment.okountUrl}/${url}`, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}
}
