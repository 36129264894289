import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GeneralComponent } from './general.component';
import { CreateGeneralComponent } from './create-general/create-general.component';
import { ViewGeneralComponent } from './view-general/view-general.component';
import { UpdateGeneralComponent } from './update-general/update-general.component';


const routes: Routes = [


  {
		path: '', component: GeneralComponent,
		children: [

			{ path: 'create-general', component: CreateGeneralComponent },
			{ path: 'view-general', component: ViewGeneralComponent },
			{ path: 'update-general/:id', component: UpdateGeneralComponent },

      		{
				path: '',
				redirectTo: 'create-general',
				pathMatch: 'full'
			}
		]
	},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeneralRoutingModule { }
