import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { CommonServiceService } from '../../common-service.service';
import { environment } from 'src/environments/environment';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';

@Component({
  selector: 'app-psr-report-search',
  templateUrl: './psr-report-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class PsrReportSearchComponent implements OnInit {

  gstreportFilterForm: FormGroup;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	minDate = "2023-04-01"
  maxDate = moment().format('YYYY-MM-DD')
  airlineArr: any = []
  keyword = 'name';
  selectionArr : any = []

  constructor(
    public commonService: CommonServiceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private store: Store<InitialState>) {
			this.store.select('sharedReducers').subscribe((items)=>{
        if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				} else {
					let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
					if(!current_financial_year) {
						current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
					}
					this.minDate = current_financial_year.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				}	  
			})
  }
  
  ngOnInit() {

    this.gstreportFilterForm = this.formBuilder.group({
      start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate,this.checkDate('end_date')],
      selectedAirline: [''],
      selectedAirlineName: [''],
      viewMode: ['summary'],
      // type_of_pay: ''
    })
    this.setFormData()
    this.getAllAirlines()
    
    // this.gstreportFilterForm.controls.type_of_pay.setValue("")
    this.gstreportFilterForm.controls.selectedAirline.setValue("")
    // this.gstreportFilterForm.controls.type_of_pay.disable()
    this.gstreportFilterForm.controls.selectedAirline.disable()
  }

  checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}
  
  setFormData() {
    let queryParams = localStorage.getItem('activeGSTQueryParams')
    if (queryParams != null) {
      let parsedParams = JSON.parse(queryParams)
      
      if (parsedParams.start_date != "") {
        this.gstreportFilterForm.controls.start_date.setValue(moment(parsedParams.start_date).format('YYYY-MM-DD') )
      }
      if (parsedParams.end_date != "") {
        this.gstreportFilterForm.controls.end_date.setValue(moment(parsedParams.end_date).format('YYYY-MM-DD'))
      }
      if (parsedParams.selectedAirline != "") {
        this.gstreportFilterForm.controls.selectedAirline.setValue(parsedParams.airline ? parsedParams.airline : "")
      }
      if (parsedParams.viewMode != "") {
        this.gstreportFilterForm.controls.viewMode.setValue(parsedParams.viewMode ? parsedParams.viewMode : "" )
      }
    }
  }

  selectAccount(value) {
    if(value) {
      this.gstreportFilterForm.controls.selectedAirline.setValue(value.account_code ? value.account_code : "")
      this.gstreportFilterForm.controls.selectedAirlineName.setValue(value.account_name ? value.account_name : "")
    } else {
      this.gstreportFilterForm.controls.selectedAirline.setValue("")
    }
  }


  


	filterAirlineAccounts(value) {
    let tempArr = Object.assign([], this.airlineArr)
    let lowerCaseFilter = value.toLowerCase()
    let upperCaseFilter = value.toUpperCase()
    if(value) {
      let accounts = tempArr.filter(account => (
        account.account_name.includes(upperCaseFilter) ||
         account.account_name.includes(lowerCaseFilter) || 
         account.account_code.includes(upperCaseFilter) || 
         account.account_code.includes(lowerCaseFilter)
      ))
			this.selectionArr = accounts
		}else {
      this.selectionArr = tempArr;
    }
	}

  search(values) {
    GlobalVariable.openModalStatus = false;
    if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
      values.start_date = ""
    }
    else {
      values.start_date = moment(values.start_date).format('YYYY-MM-DD')
    }
    if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date" || values.end_date == "Invalid date") {
      values.end_date = ""
    }
    else {
      values.end_date = moment(values.end_date).format('YYYY-MM-DD')
    }
    if(values.selectedAirline == 'ALL') {
      let differenceInDays = moment(values.end_date).diff(moment(values.start_date), 'days');
      if(differenceInDays > 30) {
        return;
      }
    } 

    if(values.selectedAirline == null || values.selectedAirline == "") {
       values.selectedAirline = ""
    }
    if(values.viewMode == "summary") {
      this.router.navigateByUrl(`/report/psr-report?start_date=${values.start_date}&end_date=${values.end_date}`)
    }

    if(values.selectedAirline !== '' && values.viewMode == "detail") {
      this.router.navigateByUrl(`/report/psr-detailed-report?viewMode=${values.viewMode}&start_date=${values.start_date}&end_date=${values.end_date}&airline_code=${values.selectedAirline}`)
    }
    // this.reset()
  }

  getFilteredData(queryParams) {
    this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/gst`, queryParams).subscribe(res => {
      console.log(res);
    }, err => {
      console.error(err);
    })
  }

  reset() {
    GlobalVariable.openModalStatus = false;
    this.gstreportFilterForm = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
      selectedAirline: [''],
      viewMode: ['summary'],
      // type_of_pay: ''
    })
    localStorage.removeItem('activeGSTQueryParams')
  }

  clickSearchButton(){
		this.searchButton.nativeElement.click()
	}

  getAllAirlines() {
    this.commonService.getRequest(`${environment.okountUrl}/airline`,).subscribe(res => {
      this.airlineArr = res
      this.selectionArr = res

      if(this.selectionArr.length>0) {
        this.selectionArr.push({
          "name": "ALL", 
          "account_name": "ALL", 
          "account_code": "ALL", 
          "primary_email": ""
        })
      }
    }, err => {
      console.error(err);
    })
  }

  handleChange(event) {
    if(event.target.value == "summary") {
      // this.gstreportFilterForm.controls.type_of_pay.setValue("")
      this.gstreportFilterForm.controls.selectedAirline.setValue("")
      // this.gstreportFilterForm.controls.type_of_pay.disable()
      this.gstreportFilterForm.controls.selectedAirline.disable()
    }
    if(event.target.value == "detail") {
      // this.gstreportFilterForm.controls.type_of_pay.enable()
      this.gstreportFilterForm.controls.selectedAirline.enable()
    }
  }
}

