import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef, HostListener, ViewChild, ElementRef, Input } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, AbstractControl, NgForm } from '@angular/forms';
import * as moment from 'moment'
import { InvoiceServicesService } from './flight/services/invoice-services.service';

import { ProductServiceDetailsService } from './flight/services/product-service-details.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { SectorService } from './flight/services/sector.service';
import { environment } from 'src/environments/environment';
import { FormCanDeactivate } from 'src/app/shared/form-can-deactivate/form-can-deactivate';
import { MiscellaneousInvoiceService } from './miscellaneous-invoice/services/miscellaneous-invoice.service';
import { TotalFareModel } from './flight/models/total-fare-model';
import { InvoiceRequestBuilderService } from './flight/services/invoice-request-builder.service';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { RemoveInternalTab } from 'src/app/shared/storev1/actions';
import { TabService } from 'src/app/shared/services/tab.service';
import { CookiesService } from 'src/app/shared/cookies.service';
import * as FileSaver from 'file-saver'; 

@Component({
	selector: 'app-invoice',
	templateUrl: './invoice.component.html',
	styleUrls: ['./invoice.component.scss'],
	providers: [SectorService]
})
export class InvoiceComponent  implements OnInit {
	@Input() data;
	submitted = false;
	moduleView: boolean = false;
	fixedModule: boolean = false

	FligtTicket: boolean = false
	isVisible: any;

	isRetrive: boolean;
	retrievedData: any;
	errorMsg: boolean;
	isRetriveError: boolean;
	selectedTab: any = 'flight';
	keyword ;
	grouplist = [];
	groupId;
	initialValue = ''
	clientData:any
	checkEInvoice: boolean = false;
	isTesoyBoult: boolean  = false;
	nestedForm: FormGroup;
	summedAmount: number = 0;
	gstAmount: number = 0;
	affiliateArr: any;
	uuid: any;
	selectedLedger;
	ssrType;
	isGstNumberExist: boolean = false;

	isSuccess: boolean = false;
	showMessage: boolean = false;
	alertType = ''
	message: string = ``;
	invoice_number = ``;
	invoiceMode = 'save';
	invoice_no;

	showModuleMessage: boolean = false;
	moduleAlertType = ''
	moduleMessage: string = ``;

	duplicateTicket = false

	calculateGst;
	invoiceData: any;
	selectedProduct: any;
	selectedProductIndex: any;

	isAadesh: boolean = false;
	showPrintOptions: boolean = false;
	invalidCredit: boolean = false;
	currencyCode : string = "INR";
	convertPnr: string = ``;
	nativeLanguageCode = '';
	attachmentFile: File | null = null;
	//KEYBOARD VARS
	@ViewChild('accountAC', { static: true }) accountAC;

	@ViewChild('openConfirmDeleteModal', { static: true }) openConfirmDeleteModal: ElementRef
	@ViewChild('closeDeleteModal', { static: true }) closeDeleteModal: ElementRef

	@ViewChild('confirmUpdateModal', { static: true }) confirmUpdateModal: ElementRef
	@ViewChild('closeUpdateModal', { static: true }) closeUpdateModal: ElementRef
	
	@ViewChild('affiliateUpdateConfirmModal', { static: true }) affiliateUpdateConfirmModal: ElementRef
	@ViewChild('closeaffiliateUpdateConfirmModal', { static: true }) closeaffiliateUpdateConfirmModal: ElementRef

	@ViewChild("openInprogressPNR",{static:true}) openInprogressPNR;
	@ViewChild("closeInprogressPNR",{static:true}) closeInprogressPNR;

	@ViewChild("openAddMiscellaneousInvoice",{static:true}) openAddMiscellaneousInvoice;
	@ViewChild("coseAddMiscellaneousInvoice",{static:true}) closeAddMiscellaneousInvoice;
	@ViewChild('closeSendEmailModal') closebutton;
	@ViewChild('openEmailModal') openEmailModal;
	@ViewChild('openPaidSsrModal', {static: true}) openPaidSsrModal: ElementRef 
	@ViewChild("openSuccessModal", { static: true }) openSuccessModal;
	@ViewChild('generateEInvoiceModal', {static: true}) generateEInvoiceModal: ElementRef 
	@ViewChild("openMasterCreationModal", { static: true }) masterCreationModal : ElementRef;

	sendEmailForm:FormGroup
	currentOpenedModal = ''
	shortcuts: ShortcutInput[] = [];
	showWarningMessage: boolean = false;
	balanceObj: any;
	minDate; 
	maxDate;
	queryParams: any;
	referenceInvoiceList = [];
	currentSelectedAffiliate: any;
	limitType: string;
	selectedSupplier: any;
	navigationSubscription
	reInitialise = false;
	insuredAmount = 0;
	referenceMsInvoice: any;
	userInfo: any;
	showErrorMessage:boolean=false // use this to show/hide error message when no transaction found on next/prev action
	activeTabIndex = 0; // current active tab index

	paid_ssrs_types: any = [];
	newInvoice = true;
	copyELementTitle = 'Click to Copy'
	isTenantIndian: boolean = false;
	tenantCheck:boolean = false;
	AllMasterClient: string = "false";
	dueDateConf: string= "false";
	salesCurrencyPrint: string = "false"
	financialYearDiff = false;
	billOfSupplyActive = false;
	
	constructor(
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef,
		private productservicedetail: ProductServiceDetailsService,
		private invoiceService: InvoiceServicesService,
		private route: ActivatedRoute,
		private spinner: NgxSpinnerService,
		public commonService: CommonServiceService,
		public sectorService: SectorService,
		public miscellaneousInvoiceService: MiscellaneousInvoiceService,
		private router: Router,
		public requestBuilder: InvoiceRequestBuilderService,
		private store: Store<InitialState>,
		private tabService : TabService,
		private cookiesService: CookiesService

	) {
		this.route.paramMap.subscribe(params => {
			this.invoice_no = params['params'].invoice
			this.invoiceMode = params['params'].mode
		});
		
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
		
		this.sendEmailForm = this.formBuilder.group({
			toEmails: ["",Validators.required],
			bccEmails: [''],
			ccEmails:[''],
			subject:[''],
			body:['']
		})
		this.nestedForm = this.formBuilder.group({
			uuid: [''],
			booking_ref_no: [''],
			paymentdetails: this.formBuilder.array([this.paymentResGroup()]),
			invdate: [moment().format('YYYY-MM-DD'), Validators.required],
			duedate: [''],
			billaddress: [''],
			affiliate_name: ['', Validators.required],
			affiliate_code: [''],
			summedAmount: [null],
			gstAmount: [null],
			narration: [''],
			narration_1: [''],
			narration_2: [''],
			narration_3: [''],
			narration_4: [''],
			narration_5: [''],
			remark: [''],
			invoiceType: ['normal'],
			referenceInvoice: [''],
			flightType: [''],
			purchase_currency: [''],
			currency_rate: [''],
			sales_currency: [''],
			sales_currency_rate: [''],
			sales_currency_amount: [''],
			bill_of_supply: [false]
		})

		this.nestedForm.get('affiliate_code').valueChanges.subscribe(values => {
			this.ref.detectChanges()
		})

		this.nestedForm.get('paymentdetails').valueChanges.subscribe(values => {
			this.summedAmount = 0;
			this.gstAmount = 0;
			const ctrl = <FormArray>this.nestedForm.controls['paymentdetails'];
			ctrl.controls.forEach(x => {
				let amount = parseInt(x.get('amount').value)
				let gstAmount = parseInt(x.get('tax').value)
				
				this.summedAmount += amount;
				
				// if (this.balanceObj != undefined && this.balanceObj.total < this.summedAmount) {
				// 	this.showMessage = true
				// 	this.alertType = 'warning-box';
				// 	this.message = 'Low Balance. Please Update Balance to create invoice.'
				// }
				this.gstAmount += gstAmount;

				this.ref.detectChanges()
			});
		})

		this.sectorService.narrationData && this.sectorService.narrationData.subscribe((items: any)=>{
			if(items){
				if(
					(items.length>0 && this.invoiceMode!='view') || 
					(	items.length>0 && 
						items[0]!="" && 
						items[1]!="" && 
						items[2]!="" &&  
						items[3]!="" && 
						this.invoiceMode=='view'
					)
				){
					this.setNarration(items)
				}
			}
		})

		this.sectorService.calculationObservable && this.sectorService.calculationObservable.subscribe(items => {
			if(items && items.length>0) {
				if(!this.isTenantIndian) {
					let sales_currency = this.nestedForm.controls.sales_currency.value;
					let sales_currency_rate = this.nestedForm.controls.sales_currency_rate.value;
					if(sales_currency && sales_currency_rate) {
						let client_net_amount = Number(this.sectorService.totalFareModel[0]['net_received'])
						let grandTotal = Number(client_net_amount);
						this.nestedForm.controls.sales_currency_amount.setValue((grandTotal * sales_currency_rate).toFixed(2));
					} else {
						this.nestedForm.controls.sales_currency_amount.setValue('')
					}
				}
			}
		})

		this.store.select('sharedReducers').subscribe((items)=> {
			this.activeTabIndex = items.activeTabIndex
		})


	}

	initialiseInvites() {
		if (this.reInitialise) {
			// this.ngOnInit()
		}
	}

	ngOnDestroy() {
		if(this.navigationSubscription){
			this.navigationSubscription.unsubscribe();
		}
	}

	async ngOnInit() {
		if(this.data){
			this.invoice_no =this.data.invoice
			this.invoiceMode =this.data.mode
			this.checkWhetherEInvGenerated(this.invoice_no)
		}
		this.billOfSupplyActive = false;
		this.isTenantIndian = this.commonService.checkTenantIndian()
		this.referenceInvoiceList = []
		this.sectorService.totalFareModel = [];
		this.sectorService.fareList = [];
		this.sectorService.fareList[0] = {
		invoice_no: "New Invoice",
		type: "normal",
		};
		this.sectorService.totalFareModel.push(new TotalFareModel(null))
		this.sectorService.sectorForm.reset();
		this.sectorService.travellerForm.reset();
		this.sectorService.invoiceForm.reset();
		this.commonService.moduleViewStatus.subscribe(status => {
			if (status == true) {
				this.closeModuleFixed()
			} else {
				this.moduleFixed()
			}
		})
		this.spinner.show();
		let queryParams = this.route.queryParams['value']
		if(queryParams){
			this.queryParams = queryParams
		}
		let empConfiguration = this.commonService.employeeConfiguration;
		if(!empConfiguration || empConfiguration.length == 0){
			let loggedInUser = JSON.parse(localStorage.okountUserInfo);
			empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
		}

		let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
		let inv_config = globalSettingConfig.filter(el=>el.group=='FLIGHT_INV')
		if(inv_config && inv_config.length>0){
			console.log("inv_config[0].json_doc",inv_config[0].json_doc["value"])
			let flight_config = inv_config[0].json_doc["value"].filter(el=>el.key=='ALL MASTERS IN CLIENT')
			if(flight_config && flight_config.length){
				this.AllMasterClient = flight_config[0].value
			}
			let due_date_config = inv_config[0].json_doc["value"].filter(el=>el.key=='DUE DATE')
			if(due_date_config && due_date_config.length){
				this.dueDateConf = due_date_config[0].value
			}
		}
		let transaction_config = globalSettingConfig.filter(el=>el.group=='TRANSACTION')
		if(transaction_config && transaction_config.length>0){
			console.log("transaction_config[0].json_doc",transaction_config[0].json_doc["value"])
			let currency_config = transaction_config[0].json_doc["value"].filter(el=>el.key=='multi_currency_sale')
			if(currency_config && currency_config.length){
				this.salesCurrencyPrint = currency_config[0].value
			}
			let ticket_config = transaction_config[0].json_doc["value"].filter(el=>el.key=='flight_voucher')
			if(ticket_config && ticket_config.length){
				this.FligtTicket = ticket_config[0].value
			}
		}

		this.invoiceDateValidation();
		
		let product_type = 'flight';
		if(this.queryParams && this.queryParams.product_type && this.queryParams.product_type != 'consolidate'){
			product_type = this.queryParams.product_type.toLowerCase()
		}
		if (this.invoiceMode == 'view' && this.invoice_no != undefined) {
			this.onTabChange(product_type)
			this.getInvoiceData(this.invoice_no, product_type)
			this.getAllReferenceData(this.invoice_no)
		} else {
			this.getAccountingCompany()
			this.spinner.hide()
		}
		let product_list = []
		sessionStorage.removeItem('product_list')
		sessionStorage.setItem('product_list', JSON.stringify(product_list))

		if (this.invoiceMode != 'view') {
			var url = window.location.href.slice(window.location.href.indexOf('?') + 1);
			this.getuuid(url)
		}
		this.reInitialise = true
		this.getAccountingCompany()
		if(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA')) {
			let userData = JSON.parse(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA'))
			if(userData && userData.tenant_id && userData.tenant_id == environment.aadeshSystaccId && this.invoiceMode != 'view') {
				this.nestedForm.controls.narration_5.setValidators([Validators.required]);
				this.nestedForm.controls.narration_5.updateValueAndValidity();
			}
			if(userData.tenant_id && userData.tenant_id == environment.checkTenant) {
				this.tenantCheck = true;
			}
			
		}

		
	}

	forceReload() {
		this.ngOnInit();
	}



	setNarration(narrationArr){
		this.nestedForm.controls.narration_1.setValue(narrationArr[0])
		this.nestedForm.controls.narration_2.setValue(narrationArr[1])
		this.nestedForm.controls.narration_3.setValue(narrationArr[2])
		this.nestedForm.controls.narration_4.setValue(narrationArr[3])

		// this.nestedForm.controls.narration_1.setValue(narrationArr[3])
	}

	getAllReferenceData(referenceCode){
			this.commonService.getRequestNoloader(`${environment.okountUrl}/invoice/getAllReferenceData?reference_code=${referenceCode}`).subscribe(async (res)=>{
				let responseData = res['data']
				for (const key in responseData) {
					if (responseData.hasOwnProperty(key)) {
						const data = responseData[key];
						if (data['transaction_type'] == 'invoice') {
							let fareItem = this.referenceInvoiceList.filter(option =>{
								return option.invoice == data.invoice_no
							})
							if (fareItem.length ==0) {
							let obj = {
								invoice_no : data.invoice_no,
								type : data.transaction_type
							}
							this.referenceInvoiceList.push(obj)
							console.log('Invoice list in getAllReference invoice : ',this.referenceInvoiceList)
							
							}
						}else{
							let fareItem = this.referenceInvoiceList.filter(option =>{
								return option.invoice == data.credit_note_code
							})
							if (fareItem.length ==0) {
							let obj = {
								invoice_no : data.credit_note_code,
								type : data.transaction_type
							}
							this.referenceInvoiceList.push(obj)
							console.log('Invoice list in getAllReference credit_note : ',this.referenceInvoiceList)
							
							}
						}
					}
				}
				console.log('referenceInvoiceList : ',this.referenceInvoiceList)
			},err=>{

			})
		
	}

	focusAccountAc() {
		if (this.accountAC != undefined && this.accountAC.nativeElement && this.accountAC.nativeElement.focus) {
			this.accountAC.nativeElement.focus()
		}
	}

	checkEmployeeValidation() {
		try {
			this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
		    this.maxDate = moment().format('YYYY-MM-DD')
			
			let invoiceConfig = this.commonService.getConfigurationByKey('flight_invoice')
			if(invoiceConfig){
				let dateConfig = invoiceConfig['value'].filter(data => data.key == `minimum_${this.invoiceMode == 'view' ? 'update' : 'creation'}_date`)
				let daysConfig = invoiceConfig['value'].filter(data => data.key == `minimum_${this.invoiceMode == 'view' ? 'update' : 'creation'}_days`)
				let dateWithMinDate = moment(dateConfig ? dateConfig[0].value : '')
				let dateWithMinDays = dateConfig && daysConfig[0].value ? moment().subtract(Number(daysConfig[0].value), 'days') : dateWithMinDate
				if(dateWithMinDays.isBefore(dateWithMinDate)){
					this.minDate = dateWithMinDate.format('YYYY-MM-DD')
				}else{
					this.minDate = dateWithMinDays.format('YYYY-MM-DD')
				}
				this.nestedForm.controls.invdate.setValidators([Validators.required,this.validateDate()])
				this.nestedForm.controls.invdate.updateValueAndValidity()
			}
		} catch (error) {
			
		}
	}
	ngAfterViewInit() {
		setTimeout(() => {
			this.commonService.selectedSearchInput.subscribe(index => {
				if (index == 1) {
					this.focusAccountAc()
				}
			})
		}, 1000);

		this.shortcuts.push(			
			{
				key: "alt + s",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Save Invoice Entry",
				description: "Save Invoice Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("save_invoice_btn")){

				document.getElementById("save_invoice_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + e",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Update Invoice Entry",
				description: "Update Invoice Entry",
				
				command: (e)=>{
					this.openUpdateConfirmModal()
				},
				preventDefault: true
			},
			{
				key: "alt + d",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Invoice Entry",
				description: "Delete Invoice Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("delete_invoice_btn")){

				document.getElementById("delete_invoice_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + l",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Activity Invoice Log",
				description: "Activity Invoice Log",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("activity_invoice_log_btn")){

				document.getElementById("activity_invoice_log_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + j",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Open Journal",
				description: "Open Journal",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("journal_invoice_btn")){

				document.getElementById("journal_invoice_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + y",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On Yes Button",
				description: "Clicked On Yes Button",
				
				command: (e)=>{
				if(this.currentOpenedModal=='update'){
					this.updateProduct(this.nestedForm.value)
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}else if(this.currentOpenedModal=='delete'){
					this.deleteInvoice(this.invoice_no)
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
				
				// this.currentOpenedModal = ''
				},
					preventDefault: true
			},
			{
				key: "alt + n",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On NO Button",
				description: "Clicked On NO Button",
				
				command: (e)=>{
				if(this.currentOpenedModal=='update'){
					this.closeUpdateConfirmModal()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}else if(this.currentOpenedModal=='delete'){
					this.closeDeleteConfirmModal()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}

				// this.currentOpenedModal = ''
				},
					preventDefault: true
			},
			{
				key: "alt + c",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "clicked on create credit note btn",
				description: "clicked on create credit note btn",
				
				command: (e)=>{
				if(document.getElementById('create_credit_note_btn')){
					document.getElementById('create_credit_note_btn').click()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
					},
					preventDefault: true
			},
			{
				key: "f10",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Send mail",
				description: "Send mail",
				command: (e)=>{
				console.log(e)	
				if(document.getElementById('invoice_send_mail_btn') && this.invoiceMode=='view'){
					document.getElementById('invoice_send_mail_btn').click()
					setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
				}
			},
					preventDefault: true
			},
	  )
	}

	moveTotraveller() {
		this.commonService.selectedInput.next(5)
		this.moduleFixed()
	}

	openTraveller() {
		// this.editProduct(0)
		this.moveTotraveller()
	}

	getInvoiceData(searchId: any, type: any = 'flight') {
		try {
			let url = `invoice?invoiceNumber=${searchId}&type=${type}`
			this.invoiceService.getMethod(url).subscribe(async (res) => {
				if(res && res['data'] && res['data'].length>0){
					let invoiceData = res['data'][0];
					
					if(invoiceData && invoiceData.accounting_company) {
						if(invoiceData['accounting_company'].currency_code) {
						  this.currencyCode = invoiceData['accounting_company'].currency_code;
						}
						if(invoiceData['accounting_company'].native_language_code && invoiceData['accounting_company'].currency_code == 'AED') {
						  this.nativeLanguageCode = invoiceData['accounting_company'].native_language_code;
						}
					}
					
					this.invoiceData = res['data'][0];
					console.log('this.invoiceData',this.invoiceData);
					
					if(invoiceData.client){
						let url = `client/autocomplete?name_like=${invoiceData.client.account_code.toUpperCase()}`
						if(this.AllMasterClient=="true"){
							url = `client/autocomplete?name_like=${invoiceData.client.account_code.toUpperCase()}&status=active&client_type=all`
						}
						this.invoiceService.searchAccount(url).subscribe(res => {
							this.affiliateArr = res;
							this.initialValue = this.affiliateArr[0].name;
							console.log('Inititl value : ', this.initialValue)
							this.selectEvent(this.affiliateArr[0])
							this.sectorService.getInvoiceRootvalue(invoiceData)
							this.bindInvoiceData(invoiceData)
							if(res && res['accounting_company']) {
								if(res['accounting_company'].currency_code) {
									this.currencyCode = res['accounting_company'].currency_code;
								}
								if(res['accounting_company'].native_language_code && res['accounting_company'].currency_code == 'AED') {
									this.nativeLanguageCode = res['accounting_company'].native_language_code;
								}
							}
						});
					}else{
						this.spinner.hide()
						this.bindInvoiceData(invoiceData)

				}
				}else{
					this.spinner.hide()
				}
			},
				err => {
					this.spinner.hide()
					console.log(err)
				})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
		}
	}


	getMiscellineousInvoiceData(searchId: any, type: any = 'miscellaneous') {
		try {
			let url = `invoice/miscellaneous/${searchId}`
			this.invoiceService.getMethod(url).subscribe(async (res) => {
				if(res && res['data'] && res['data'].length>0){
				let invoiceData = res['data'][0];
				this.invoiceData = res['data'][0];
				this.invoiceService.searchAccount(`client/autocomplete?name_like=${invoiceData.affiliate_name.toUpperCase()}`).subscribe(res => {
					this.affiliateArr = res;
					this.initialValue = this.affiliateArr[0].name;
					console.log('Inititl value : ', this.initialValue)
					this.selectEvent(this.affiliateArr[0])
					this.bindInvoiceData(invoiceData)
				});
			}else{
				this.spinner.hide()
			}

			},
				err => {
					this.spinner.hide()
					console.log(err)
				})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
		}
	}


	perItemCalculation(i) {
		let rate = this.paymentArray.controls[i]['controls'].rate.value
		let qty = this.paymentArray.controls[i]['controls'].quantity.value
		let amount = rate * qty
		// let amount = this.paymentArray.controls[i]['controls'].amount.value
		this.paymentArray.controls[i]['controls'].amount.setValue(amount)
	}
	perItemAmountCalculation(i) {
		let qty = this.paymentArray.controls[i]['controls'].quantity.value
		let amount = this.paymentArray.controls[i]['controls'].amount.value
		let rate = amount / qty
		this.paymentArray.controls[i]['controls'].rate.setValue(rate)
	}


	async makeProduct(data) {
		this.closeModuleFixed()
		//TO ADD MULTIPLE PRODUCT REPLACE 0 with lastIndex in this.addPaymentData(0,data)
		if (this.invoiceMode != 'view') {
			if (this.paymentArray.controls.length > 0) {
				if (this.paymentArray.controls[0]['controls'].name.value != "") {
					// await this.addpayment() Uncomment this add multiple product
					let lastIndex = this.paymentArray.controls.length - 1
					this.addPaymentData(0, data)
				} else {
					let lastIndex = this.paymentArray.controls.length - 1
					this.addPaymentData(0, data)
				}
			}
		} else {
			if (this.paymentArray.controls[0]['controls'].name.value != "") {
				let lastIndex = this.selectedProductIndex
				this.addPaymentData(0, data)
			}
		}

		if(data && data['narrationArr']){
			for (const narr of data['narrationArr']) {
				if(narr){
					this.nestedForm.controls[`narration_${data['narrationArr'].indexOf(narr)+1}`].setValue(narr)
				}
			}
			
		}
	}
	async addPaymentData(index, data) {
		let product = data['product']
		let hsn = data['hsn']
		let quantity = data['quantity']
		let rate = data['rate']
		let narration = data['narration']
		narration = narration.split("\n").join(", ")
		let amount = data['amount']
		let tax = data['tax']
		if(product && product.toLowerCase() == 'miscellaneous'){
			rate = data['client_net_amount']
			amount = data['client_net_amount']
			tax = data['client_taxes']
			this.nestedForm.controls.narration.setValue(narration)
		}
		this.paymentArray.controls[index]['controls'].name.setValue(product)
		this.paymentArray.controls[index]['controls'].hsc_sac.setValue(hsn)
		this.paymentArray.controls[index]['controls'].quantity.setValue(quantity)
		this.paymentArray.controls[index]['controls'].rate.setValue(rate)
		this.paymentArray.controls[index]['controls'].narration.setValue(narration)
		this.paymentArray.controls[index]['controls'].amount.setValue(amount)
		this.paymentArray.controls[index]['controls'].tax.setValue(tax)
		if (sessionStorage.getItem('product_list') != undefined && sessionStorage.getItem('product_list') != null) {
			let productList = JSON.parse(sessionStorage.product_list)
			
			let limitType = 'cash'
			if(productList[0] != undefined && productList[0].json_doc != undefined && productList[0].json_doc.sub_ledger_code_1 != undefined
				){
				if (productList[0].json_doc.sub_ledger_code_1 == 'CC001B') {
					limitType = 'credit'
				}
			}
			this.duplicateTicket = false
			this.showMessage = false
			if (productList[0] != undefined && productList[0].json_doc != undefined &&  productList[0].json_doc.travellers) {
				let ticketNumberList = []
				for (const traveller of productList[0].json_doc.travellers) {
					let ticketNumber = traveller.ticket_no && traveller.ticket_no.length > 0 ? ticketNumberList.push(traveller.ticket_no.substring(traveller.ticket_no.length-10)) : ''
					
					// this.checkTicketNumber(traveller.ticket_no)
				}
				this.checkTicketNumber(ticketNumberList)
			}
			this.balanceObj = await this.getSelectedClientBalance(limitType)
		}
	}
	checkTicketNumber(ticketNumber){
		let reqObj = {
			ticket_no : ticketNumber,
			invoice_no : this.invoice_no,
			reference_type : 'invoice'
		}
		this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/invoice/check-ticket-number`,reqObj).subscribe((res : any)=>{
			if (res.length > 0) {
				this.showMessage = true
				this.duplicateTicket = true
				this.alertType = 'warning-box'
				this.message = `Ticket Number ${res[0].ticket_no} already exists in invoice ${res[0].invoice_no} .`
			}
		},err=>{
			console.log(err)
		})
	}

	async updateProduct(values) {
		try {
			this.spinner.show()
			this.closeUpdateConfirmModal()

			let productList = await this.getProduct()

			if(productList == undefined) {
				return
			}

			if(this.isGstNumberExist){
				const res = await this.invoiceService.getMethod(`client/autocomplete?name_like=${this.selectedLedger['account_code'].toUpperCase()}&status=active&client_type=all`).toPromise()
			//const res = await this.invoiceService.getMethod(`client?client_code=${this.selectedLedger['account_code'].toUpperCase()}&status=active`).toPromise()
          console.log("res",res)
          if(!res[0]["state_id"] || res[0]["state_id"] ==0 || !res[0]["state_name"]) {
            window.scroll(0,0)
            this.showMessage = true;
            this.isSuccess = false
            this.invoice_number = ''
            this.alertType = 'error-box';
            this.message = `Update Client With State Code`
            this.spinner.hide()
            return false
		  }
          }

			let requestObj = {
				"affiliate_code": this.selectedLedger['account_code'],
				"name": this.selectedLedger.name,
				"billaddress": values.billaddress,
				"ledger_id": this.selectedLedger['account_code'],
				"invoice_uuid": values.uuid,
				"invoice_date": `${moment(values.invdate).format('YYYY-MM-DD')}T${moment().format('HH:mm:ss')}`,
				"due_date": values.duedate ?moment(values.duedate).format('YYYY-MM-DDTHH:mm'):'',
				"flightType": values.flightType,
				"created_by": 1,  //FIX ME
				"updated_by": 1, //FIX ME
				"year_id": 1, //FIX ME
				"tenant_id": 1, //FIX ME
				"narration": values.narration,
				"narration_1": values.narration_1,
				"narration_2": values.narration_2,
				"narration_3": values.narration_3,
				"narration_4": values.narration_4,
				"narration_5": values.narration_5,
				"remark": values.remark,
				"total": this.summedAmount,
				"balance_due": 0.0,
				"gst_total": this.gstAmount,
				"products": productList,
				"product_type" : productList[0].product,
				"status": this.invoiceData.status ? this.invoiceData.status : 'Active',
				"notify" : this.queryParams && this.queryParams.notify == "true" ? true : false,
				"channel_name" : this.queryParams && this.queryParams.channel ? this.queryParams.channel : null,
				"purchase_currency" : values.purchase_currency?values.purchase_currency:null,
				"currency_rate" : values.currency_rate?values.currency_rate:null,
				"sales_currency": values.sales_currency ? values.sales_currency : null,
				"sales_currency_rate": values.sales_currency_rate ? values.sales_currency_rate : null,
				"sales_currency_amount": values.sales_currency_amount ? values.sales_currency_amount : null,
				"bill_of_supply": values.bill_of_supply
			}

			if (this.invoiceMode == 'view') {
				let apiEndpoint = `invoice/${values.uuid}`
				if(productList[0].product.toLowerCase() == 'miscellaneous'){
					apiEndpoint = `invoice/miscellaneous/${values.uuid}`
					if(requestObj['products'] && requestObj['products'][0] && requestObj['products'][0].json_doc){
						requestObj['products'][0] = requestObj['products'][0].json_doc
					}
				}

				if(this.nestedForm.controls.bill_of_supply.value === true) {
					let originalRequest = this.requestBuilder.removeGSTAndServiceCharges(requestObj)
					let bosRequest = this.requestBuilder.extractGSTAndServiceCharges(requestObj)

					requestObj = bosRequest;
					requestObj['billOfSupplyRequest'] = originalRequest;
					requestObj['billOfSupplyRequest']['invoice_uuid'] = null;
					requestObj['billOfSupplyRequest']['bill_of_supply'] = true;
				}

				this.invoiceService.putMethod(apiEndpoint, requestObj).subscribe(async (data) => {
					window.scroll(0, 0)
					this.showMessage = true;
					this.isSuccess = true
					this.alertType = 'success-box';
					this.message = `Invoice Updated`

					if(requestObj['billOfSupplyRequest'] && requestObj['billOfSupplyRequest']['bill_of_supply'] == true) {
						this.forceReload()
						return;
					}

					if(requestObj.status == 'draft'||requestObj.status == 'Draft'){
						this.showMessage = true;
						this.isSuccess = true
						this.alertType = 'success-box';
						this.invoice_number = data['invoice_no']
						this.message = `Invoice Saved with invoice no. : ${this.invoice_number}`
						sessionStorage.removeItem('product_list')
						
						// this.router.navigateByUrl(`transactions/invoice/view/${data['invoice_no']}`)
						let billingAddress = values.billaddress
						this.nestedForm.reset()
						this.nestedForm.controls.billaddress.setValue(billingAddress ? billingAddress : '')
						this.summedAmount = 0;
						this.gstAmount = 0;
						this.sectorService.totalFareModel[0] = new TotalFareModel(null);
						this.sectorService.resetFormData();
						this.sectorService.invoiceForm.controls.crsId.setValue("WEBSITE");
						this.sectorService.invoiceForm.controls.supplier.setValue("OT");
						this.paymentArray.controls = []
						this.uuid = await this.getInvoice()
						this.nestedForm.controls.uuid.setValue(this.uuid)
						this.newInvoice = false
						//this.openSuccessModal.nativeElement.click()
						await this.addpayment()
						window.history.replaceState({}, document.title,`${window.location.pathname}${window.location.hash.split("?")[0]}?invoice_number=${this.uuid}`);
						this.newInvoice = false;
        this.openSuccessModal.nativeElement.click()
						// setTimeout(()=>{
						// 	window.top.close()
						// },5000)
					}
					this.spinner.hide()
				}, err => {
					window.scroll(0, 0)
					this.showMessage = true;
					this.isSuccess = false
					this.invoice_number = ''
					this.alertType = 'error-box';
					this.message = err['error']['message']
					this.spinner.hide()
				})
			}
		} catch (error) {
			console.log(error)
			this.spinner.hide()
		}

	}

	paymentResGroup() {
		return this.formBuilder.group({
			name: ["", Validators.required],
			hsc_sac: [""],
			remark: [""],
			narration: [""],
			quantity: [0, [Validators.required, Validators.pattern('^[0-9]*$')]],
			rate: [0, Validators.required],
			amount: [0, Validators.required],
			tax: [0, [Validators.required, Validators.pattern('^[0-9]*$')]]
		})
	}

	addpayment() {
		this.paymentArray.push(this.paymentResGroup());
	}

	removepayment(index) {
		this.paymentArray.removeAt(index);
		let product = JSON.parse(sessionStorage.product_list)
		if (product.length > 0) {
			product.splice(index, 1)
			sessionStorage.setItem('product_list', JSON.stringify(product))
		}

	}

	clearalllines() {
		while (this.paymentArray.length !== 0) {
			this.paymentArray.removeAt(0)
		}
		this.paymentArray.push(this.paymentResGroup());
	}


	get paymentArray() {
		return <FormArray>this.nestedForm.get('paymentdetails');
	}

	async submithandler(values) {
		if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.nestedForm.controls.invdate.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to create the invoice.'
				return false;
			}
		}

		!this.financialYearDiff && this.checkEmployeeValidation()
		this.sectorService.submitted = true;
		const invalid = [];

		const controls = this.nestedForm.controls;
		for (const name in controls) {
			if (controls[name].invalid && name != "paymentdetails") {
				invalid.push(name);
			}
		}
		
		if(invalid.length > 0 || this.sectorService.travellerForm.invalid) {
			window.scroll(0,0)
			this.showMessage = true;
			this.isSuccess = false
			this.invoice_number = ''
			this.alertType = 'error-box';
			// this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
			this.message = 'Please fill the mandatory fields'
			this.spinner.hide()
			return false
		}

		if(this.duplicateTicket) {
			window.scroll(0,0)
			this.showMessage = true;
			this.isSuccess = false
			this.invoice_number = ''
			this.alertType = 'error-box';
			this.message = `Invalid Ticket Number.`
			this.spinner.hide()
			return false
		}
		if(this.isGstNumberExist){
		const res = await this.invoiceService.getMethod(`client/autocomplete?name_like=${this.selectedLedger['account_code'].toUpperCase()}&status=active&client_type=all`).toPromise()
			//client?client_code=${this.selectedLedger['account_code'].toUpperCase()}
          console.log("res",res)
          if(!res[0]["state_id"] || res[0]["state_id"] ==0 || !res[0]["state_name"]) {
            window.scroll(0,0)
            this.showMessage = true;
            this.isSuccess = false
            this.invoice_number = ''
            this.alertType = 'error-box';
            this.message = `Update Client With State Code`
            this.spinner.hide()
            return false
		  }
          }

		this.spinner.show()
		let productList = await this.getProduct()
		let requestObj = {
			"affiliate_code": this.selectedLedger['account_code'],
			"name": this.selectedLedger.name,
			"billaddress": values.billaddress,
			"ledger_id": this.selectedLedger['account_code'],
			"invoice_uuid": this.uuid,
			"invoiceType": values.invoiceType,
			"flightType": values.flightType,
			"referenceInvoice": values.referenceInvoice,
			"invoice_date": `${moment(values.invdate).format('YYYY-MM-DD')}T${moment().format('HH:mm:ss')}`,
			"due_date": values.duedate ?moment(values.duedate).format('YYYY-MM-DDTHH:mm'):'',
			"created_by": 1,
			"updated_by": 1,
			"year_id": 1,
			"tenant_id": 1,
			"narration": values.narration,
			"narration_1": values.narration_1,
			"narration_2": values.narration_2,
			"narration_3": values.narration_3,
			"narration_4": values.narration_4,
			"narration_5": values.narration_5,
			"remark": values.remark,
			"total": this.summedAmount,
			"balance_due": 0.0,
			"gst_total": this.gstAmount,
			"products": productList,
			"product_type": this.selectedTab,
			"purchase_currency":values.purchase_currency?values.purchase_currency:null,
			"currency_rate" : values.currency_rate?values.currency_rate:null,
			"sales_currency": values.sales_currency ? values.sales_currency : null,
			"sales_currency_rate": values.sales_currency_rate ? values.sales_currency_rate : null,
			"sales_currency_amount": values.sales_currency_amount ? values.sales_currency_amount : null,
			"bill_of_supply": values.bill_of_supply,
		}

		let retrievedData = JSON.parse(localStorage.getItem('retrievedData'))
		
		let additionalInvoiceArr = []
		if(this.paid_ssrs_types && this.paid_ssrs_types.length > 0) {
			for(let ssrType of this.paid_ssrs_types) {
				let additionalInvoiceObj = this.requestBuilder.bindPaidSsrData(retrievedData,ssrType, this.selectedLedger, values, requestObj['products'])
				additionalInvoiceArr.push(additionalInvoiceObj)
			}
		}

		if(additionalInvoiceArr && additionalInvoiceArr.length > 0) {
			requestObj['additional_invoice'] = additionalInvoiceArr
		}

		try {
			if (this.invoiceMode != 'view') {
				let apiEndpoint = 'invoice'
				if(productList[0].product.toLowerCase() == 'miscellaneous'){
					apiEndpoint = 'invoice/miscellaneous'
					if(requestObj['products'] && requestObj['products'][0] && requestObj['products'][0].json_doc){
						requestObj['products'][0] = requestObj['products'][0].json_doc
					}
				}
				if(this.nestedForm.controls.bill_of_supply.value === true) {
					let originalRequest = this.requestBuilder.removeGSTAndServiceCharges(requestObj)
					let bosRequest = this.requestBuilder.extractGSTAndServiceCharges(requestObj)

					requestObj = originalRequest;
					requestObj['billOfSupplyRequest'] = bosRequest;
				}
				

				this.invoiceService.postMethod(apiEndpoint, requestObj).subscribe(async (data) => {
					window.scroll(0, 0)
					this.showMessage = false;
					this.isSuccess = true
					this.alertType = 'success-box';
					this.message = `Invoice Saved with invoice no. : `
					this.invoice_number = data['invoice_no']
					sessionStorage.removeItem('product_list')
					let billingAddress = values.billaddress
					this.nestedForm.reset()
					this.nestedForm.controls.billaddress.setValue(billingAddress ? billingAddress : '')
					this.summedAmount = 0;
					this.gstAmount = 0;
					this.sectorService.totalFareModel[0] = new TotalFareModel(null);
					this.sectorService.resetFormData();
					this.sectorService.invoiceForm.controls.crsId.setValue("WEBSITE");
					this.sectorService.invoiceForm.controls.supplier.setValue("OT");
					this.paymentArray.controls = []
					this.uuid = await this.getInvoice()
					this.nestedForm.controls.uuid.setValue(this.uuid)
					this.newInvoice = false
					this.openSuccessModal.nativeElement.click()
					await this.addpayment()
					window.history.replaceState({}, document.title, `${window.location.pathname}${window.location.hash.split("?")[0]}?invoice_number=${this.uuid}`);
					this.spinner.hide()

				}, err => {
					window.scroll(0, 0)
					this.showMessage = true;
					this.isSuccess = false
					this.invoice_number = ''
					this.alertType = 'error-box';
					this.message = err['error']['message']
					this.spinner.hide()
				})
			}
		} catch (error) {
			console.log(error)
			this.spinner.hide()
		}

	}

	get DynamicControls() {
		const secLst = <FormArray>this.nestedForm.get('paymentdetails');
		return secLst;
	}

	reset(){
		this.nestedForm.reset()
		this.sectorService.resetFormData()
		this.insuredAmount = 0
		this.summedAmount = 0
		this.ngOnInit()


	}

	onChangeSearch(event) {	
		let value = event.target.value;	
		try {			
			if (value.length > 2) {
				this.sectorService.currentMasterName = value;
				this.sectorService.currentFormControlName = 'client'
				let url = `client/autocomplete?name_like=${value.toUpperCase()}&status=active`
				if(this.AllMasterClient=="true"){
					url = `client/autocomplete?name_like=${value.toUpperCase()}&status=active&client_type=all`
				}
				this.invoiceService.searchAccount(url).subscribe(res => {
					this.affiliateArr = res;
					if(!this.affiliateArr || this.affiliateArr.length == 0) {
						this.sectorService.showCreateButton = true;
						this.sectorService.currentMaster = 'client';
						
					   } else {
						this.sectorService.showCreateButton = false;
						this.sectorService.currentMaster = '';
					}
				});
			}
		} catch (error) {

		}
	}

	async selectAffiliateEvent(nzEvent){
		let nzValue = nzEvent && nzEvent.nzValue;
		if(nzValue) {
			let data = nzValue;
			this.currentSelectedAffiliate = data
			
			if(this.currentSelectedAffiliate.account_code && this.currentSelectedAffiliate.account_code != this.nestedForm.controls.affiliate_code.value && this.invoiceMode == 'view'){
				this.openAffiliateUpdateConfirmModal()
			}else{
				this.selectEvent(data)
			}
		}else {
			this.nestedForm.controls.affiliate_name.setValue(this.sectorService.currentMasterName)
			this.masterCreationModal.nativeElement.click()
            return false;
		}
	}

	async selectEvent(data) {
		// let ledgerDetails = await this.getLedgerDetails(data.ledger_id)
		if (data.hasOwnProperty('id')) {
			this.selectedLedger = data;
			let billingAddress = data.address_1 + " " + data.address_2 + " " + data.address_3;
			this.nestedForm.controls.billaddress.setValue(billingAddress ? billingAddress : '')
			this.nestedForm.controls.affiliate_name.setValue(data.name ? data.name : '')
			this.nestedForm.controls.affiliate_code.setValue(data.account_code ? data.account_code : '')
			this.sectorService.affiliate_code = data.account_code ? data.account_code : ''
			this.balanceObj = {}
			// let cashBalance = await this.getSelectedClientBalance(data.account_code,'cash')
			// this.balanceObj['cash']=cashBalance	
			this.sectorService.clientConfiguration = await this.commonService.getClientConfiguration(data.account_code)
			let currentClientConfiguration = this.sectorService.clientConfiguration;

			if(this.invoiceMode != 'view') {
				let billOfSupplyObj = currentClientConfiguration.find(data => data.key == 'enable_bill_of_supply')
				if(billOfSupplyObj){
					let value = billOfSupplyObj.value;
					if(value == 'TRUE') {
						this.billOfSupplyActive = true;
					}
				} else {
					this.billOfSupplyActive = false;
				}
				this.sectorService.calculateServiceChargeFlightWise(this.nestedForm.value);
			} else { 
				let billOfSupplyObj = currentClientConfiguration.find(data => data.key == 'enable_bill_of_supply')
				if(billOfSupplyObj){
					let value = billOfSupplyObj.value;
					if(value == 'TRUE' && this.invoiceData && this.invoiceData.bill_of_supply == false && !this.invoiceData.bos_reference) {
						this.billOfSupplyActive = true;
					}
				} else {
					this.billOfSupplyActive = false;
				}
			}
			this.balanceObj = await this.getSelectedClientBalance(this.limitType ? this.limitType : 'cash')
			if (this.nestedForm.controls.narration_4.value.substring(0,21)=="TICKET CREATED ONLINE"){
				this.nestedForm.controls.narration_4.setValue(`TICKET CREATED ONLINE : ${data.name}`)
			}

			// this.balanceObj['total']= creditBalance
			// this.balanceObj['total'] = Number(this.balanceObj['cash']) + Number(this.balanceObj['credit'])
			// if (this.balanceObj['total'] == 0) {
			// 	this.showMessage = true
			// 	this.alertType = 'warning-box';
			// 	this.message = 'Low Balance. Please Update Balance to create invoice.'
			// } else {
			// 	this.showMessage = false
			// }
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		console.log("Width: " + event.target.innerWidth);
	}

	getSelectedClientBalance(balance_type) {
		
		let userData = JSON.parse(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA'))
		if(userData && userData.tenant_id && userData.tenant_id == environment.aadeshSystaccId) {
			this.isAadesh = true;
		}
		let reqObj = { 
			"booking_date": new Date(), 
			"booking_amount": 0, 
			"type": balance_type, 
			"isLCC": false, 
			"source": "accounts", 
			"isDiAccount": false, 
			"affiliate_code": this.sectorService.affiliate_code
		}
		this.limitType = balance_type
		return new Promise((resolve, reject) => {
			
			this.commonService.postWithOutInterceptor(`${environment.IBEurl}/account/limit/checkLimit`, reqObj,false).subscribe(res => {
				let data = JSON.parse(res['_body'])
				let resObj = {
					total: data['amount']
				}
				if(data && data.hasOwnProperty('invalidCredit') && this.isAadesh && this.invoiceMode != 'view') {
					if(data['invalidCredit']) {
						this.invalidCredit = true;
						this.showMessage = true;
						this.alertType = 'error-box';
						this.message = `Insufficient balance for the client.`
					}else {
						this.invalidCredit = false;
					}
				}

				resolve(resObj)
			}, err => {
				let resObj = {
					total: 0
				}
				resolve(resObj)
			})
		})
	}

	maxModule() {
		this.moduleView = !this.moduleView
	}

	moduleFixed() {
		this.fixedModule = true;
		this.moduleView = true
	}

	closeModuleFixed() {
		this.fixedModule = false;
		this.moduleView = false
	}
	onTabChange(tabName) {
		this.selectedTab = tabName
		this.commonService.selectedInput.next(5)
	}

	async getuuid(url) {
		if (!url.includes('invoice_number') && this.invoiceMode != 'view') {
			this.uuid = await this.getInvoice()
			// this.nestedForm.controls.uuid.setValue(this.uuid)
			window.history.replaceState({}, document.title, `${window.location.pathname}${window.location.hash}?invoice_number=${this.uuid}`);
		} else {
			if (url.split('=')[1] != undefined) {
				let invNo = url.split('=')[1]
				this.nestedForm.controls.uuid.setValue(invNo)
			}
		}
	}

	getInvoice() {
		return new Promise((resolve, reject) => {
			this.invoiceService.getMethod(`invoice/getuuid`).subscribe(
				res => {
					let invoiceNo;
					try {
						invoiceNo = res["uuid"]
						resolve(invoiceNo);
					} catch (error) {
						invoiceNo = res
						resolve(invoiceNo);
					}
				},
				err => {
					reject("");
				}
			);
		});
	}
	getLedgerDetails(id) {
		return new Promise((resolve, reject) => {
			this.invoiceService.getMethod(`ledger/alldata/${id}`).subscribe(
				res => {
					resolve(res);
				},
				err => {
					reject(null);
				}
			);
		});
	}

	openInvoice(invoice_number,product_type) {
		let url = `#/transactions/invoice-view;searchId=${invoice_number};type=flight`
		if(product_type.toLowerCase()=='miscellaneous'){
			url = `#/transactions/ms-invoice-view;searchId=${invoice_number};type=miscellaneous`;
		}
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${url}`
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

	openNextOrPrevInvoice(invoice_number,isNextBtn){
		let arr = invoice_number.split('-')
		arr[arr.length-1] = isNextBtn?Number(arr[arr.length-1])+1:Number(arr[arr.length-1])-1
		invoice_number = arr.join('-')
		let type = 'flight'
		let url = `/#/transactions/invoice/view/${invoice_number};type=${type}`
		
		// window.history.replaceState({}, document.title,url);
		this.spinner.show()
		this.invoiceService.getMethod(`invoice?invoiceNumber=${invoice_number}&type=${type}`).subscribe(async (res) => {
			if(res && res['data'] && res['data'].length>0){
					// this.invoice_no=invoice_number
					let oldTitle =''
					let invoiceParts = this.invoice_no.split('-')
					oldTitle += invoiceParts[0]
					oldTitle +='...'
					oldTitle += invoiceParts[invoiceParts.length-1]

					let title =''
					let newInvoiceParts = invoice_number.split('-')
					title += newInvoiceParts[0]
					title +='...'
					title += newInvoiceParts[newInvoiceParts.length-1]
					this.tabService.replaceTabData( oldTitle,title, { invoice: invoice_number,mode : 'view' });
					this.showErrorMessage=false
					this.ngOnInit()
			}else{
				this.spinner.hide()
				this.showErrorMessage=true
			}
		},
		err => {
			console.log(err)
			this.spinner.hide()
			this.showErrorMessage=true
		})
	
	}

	getValue(formValue) {
		console.log(formValue)
	}

	hideError() {
		this.showMessage = false
	}


	async bindInvoiceData(invoiceData) {
		
		let narrationArr = []

		if(invoiceData.hasOwnProperty('narration_1') && invoiceData.narration_1.split(',').length>=3){
			narrationArr = invoiceData.narration_1.split(',')
		}
		let booking_ref_no = invoiceData && invoiceData.products && invoiceData.products.length > 0 && invoiceData.products[0].json_doc 
		                     && invoiceData.products[0].json_doc.booking_ref_no ? invoiceData.products[0].json_doc.booking_ref_no : ""
		let invoiceDate = invoiceData.invoice_date ? moment(invoiceData.invoice_date).format('YYYY-MM-DD') : ''
		this.sectorService.invoiceDate = invoiceDate
		this.nestedForm.controls.invdate.setValue(invoiceDate)
		this.nestedForm.controls.duedate.setValue(invoiceData.due_date ? moment(invoiceData.due_date).format('YYYY-MM-DD') : '')
		this.nestedForm.controls.uuid.setValue(invoiceData.invoice_no ? invoiceData.invoice_no : invoiceData.invoice_id)
		this.nestedForm.controls.booking_ref_no.setValue(booking_ref_no)
		this.nestedForm.controls.summedAmount.setValue(invoiceData.total ? invoiceData.total : 0)
		this.nestedForm.controls.gstAmount.setValue(invoiceData.gst_total ? invoiceData.gst_total : 0)
		this.nestedForm.controls.flightType.setValue(invoiceData.flight_type ? invoiceData.flight_type : '');
		this.nestedForm.controls.narration.setValue(invoiceData.narration_1 ? invoiceData.narration_1 : '');
		this.nestedForm.controls.narration_1.setValue(narrationArr.length>0 ?narrationArr[0]:invoiceData.narration_1?invoiceData.narration_1:"");
		this.nestedForm.controls.narration_2.setValue(narrationArr.length>0 ?narrationArr[1]:invoiceData.narration_2?invoiceData.narration_2:"");
		this.nestedForm.controls.narration_3.setValue(narrationArr.length>0 ?narrationArr[2]:invoiceData.narration_3?invoiceData.narration_3:"");
		this.nestedForm.controls.narration_4.setValue(narrationArr.length>=4 ?narrationArr[3]:invoiceData.narration_4?invoiceData.narration_4:"");
		this.nestedForm.controls.narration_5.setValue(narrationArr.length>=5 ?narrationArr[4]:invoiceData.narration_5?invoiceData.narration_5:"");
		this.nestedForm.controls.remark.setValue(invoiceData.remark ? invoiceData.remark : '');
		this.nestedForm.controls.invoiceType.setValue(invoiceData.invoice_type ? invoiceData.invoice_type : 'normal');
		this.nestedForm.controls.purchase_currency.setValue(invoiceData.purchase_currency ? invoiceData.purchase_currency : '');
		this.nestedForm.controls.currency_rate.setValue(invoiceData.currency_rate ? invoiceData.currency_rate : '');

		this.nestedForm.controls.sales_currency.setValue(invoiceData.sales_currency ? invoiceData.sales_currency : '');
		this.nestedForm.controls.sales_currency_rate.setValue(invoiceData.sales_currency_rate ? invoiceData.sales_currency_rate : '');
		this.nestedForm.controls.sales_currency_amount.setValue(invoiceData.sales_currency_amount ? invoiceData.sales_currency_amount : '');

		if (invoiceData.invoice_type != undefined && invoiceData.invoice_type == 'reissue') {
			this.nestedForm.controls.referenceInvoice.setValidators(Validators.required)
		} else {
			this.nestedForm.controls.referenceInvoice.setValidators(null)
		}
		if(invoiceData.invoice_no){
			this.invoice_no = invoiceData.invoice_no
		}
		this.nestedForm.controls.referenceInvoice.updateValueAndValidity()
		this.nestedForm.controls.referenceInvoice.setValue(invoiceData.reference_invoice ? invoiceData.reference_invoice : '');
		this.summedAmount = invoiceData.total ? invoiceData.total : 0;
		this.gstAmount = invoiceData.gst_total ? invoiceData.gst_total : 0;
		for (let i = 0; i < invoiceData.products.length; i++) {
			if (i > 0) {
				await this.addpayment()
			}
			let data = invoiceData.products[i]
			let product = invoiceData.product_type;
			if(data.json_doc.has_insurance){
				this.insuredAmount += Math.round(Number(data.json_doc.insurance_premium))
				this.referenceMsInvoice = data.json_doc.reference_ms_invoice
			}
			let hsn = data["hsn"];
			let quantity = data["quantity"];
			let rate = data["rate"];
			let narration = data["narration_1"];
			let amount = data["amount"];
			let tax = data["tax"];
			if (product.toLowerCase() == "miscellaneous") {
				rate = data["client_net_amount"];
				amount = data["client_net_amount"];
				tax = data["client_taxes"];
			}
			this.paymentArray.controls[i]['controls'].name.setValue(invoiceData.products[i]['product'] ? invoiceData.products[i]['product'] : invoiceData.product_type)
			this.paymentArray.controls[i]['controls'].quantity.setValue(quantity)
			this.paymentArray.controls[i]['controls'].hsc_sac.setValue(hsn)
			this.paymentArray.controls[i]['controls'].rate.setValue(rate)
			this.paymentArray.controls[i]['controls'].amount.setValue(amount)
			this.paymentArray.controls[i]['controls'].tax.setValue(tax)
			this.paymentArray.controls[i]['controls'].remark.setValue(invoiceData.products[i]['remark'] ? invoiceData.products[i]['remark'] : '')
			this.paymentArray.controls[i]['controls'].narration.setValue(narration)
			this.createProductList(i)
			
		}
		this.editProduct(0)
		this.spinner.hide()

	}

	async createProductList(i) {
		const product = this.invoiceData.products[i];
		let productType = 'flight'
		let productObj = {
			"json_doc": product['json_doc'] ? product['json_doc'] : product,
			"quantity": product.quantity,
			"hsn": product.hsn_sac,
			"rate": product.rate,
			"amount": product.amount,
			"tax": product.tax,
			"total": product.total,
			"product": productType,
			"narration": product.narration
		}
		
		let product_list = JSON.parse(sessionStorage.getItem('product_list'))
		if (product_list == null) {
			product_list = []
		}
		product_list.push(productObj)
		sessionStorage.setItem('product_list', JSON.stringify(product_list))
		if (sessionStorage.getItem('product_list') != undefined && sessionStorage.getItem('product_list') != null) {
			let productList = JSON.parse(sessionStorage.product_list)
			
			let limitType = 'cash'
			if(productList[0] != undefined && productList[0].json_doc != undefined && productList[0].json_doc.sub_ledger_code_1 != undefined
				){
					if (productList[0].json_doc.sub_ledger_code_1 == 'CC001B') {
						limitType = 'credit'
					}
				}
				this.duplicateTicket = false
				this.showMessage = false
				
				if(this.invoiceData && this.invoiceData.status && this.invoiceData.status == 'Draft' && productList[0] && productList[0].json_doc && productList[0].json_doc.import_messages && productList[0].json_doc.import_messages.warnings && productList[0].json_doc.import_messages.warnings.length>0) {
					let warnings = productList[0].json_doc.import_messages.warnings;
					this.showMessage = true
					this.alertType = 'warning-box';
					this.message = warnings.join(', ')
				}

				if (productList[0] != undefined && productList[0].json_doc != undefined &&  productList[0].json_doc.travellers) {
					let ticketNumberList = []
					for (const traveller of productList[0].json_doc.travellers) {
						let ticketNumber = traveller.ticket_no && traveller.ticket_no.length > 0 ? ticketNumberList.push(traveller.ticket_no.substring(traveller.ticket_no.length-10)) : ''
						// ticketNumberList.push(traveller.ticket_no)
					}
					this.checkTicketNumber(ticketNumberList)
				}
			this.sectorService.affiliate_code = this.invoiceData.affiliate_code
			if(this.invoiceData.affiliate_code){
				this.balanceObj = await this.getSelectedClientBalance(limitType)
			}
		}

	}

	editProduct(i) {
		try {
			this.selectedProductIndex = i;
			this.moveTotraveller();
			if(this.invoiceData && this.invoiceData.product_type && this.invoiceData.product_type.toLowerCase() =='miscellaneous'){
				this.openMiscellineousInvoiceProduct(i)
			}else{
				this.openInvoiceProduct(i)
			}
    	} catch (error) {
			console.error('Error while opening View form',error)
		}
	}

	openInvoiceProduct(i){
		
		// this.selectedProduct = this.invoiceData.products[i]
		let product = [];
		if (sessionStorage.getItem("product_list") != null) {
		product = JSON.parse(sessionStorage.product_list);
		}
		if (this.invoiceMode != "view") {
		  this.sectorService.fareList[0] = "New Invoice";
		}
		this.sectorService.cancelledTicket = [];
		if (this.invoiceData != undefined) {
		  this.sectorService.fareList[0] = {
			invoice_no: this.invoiceData.invoice_no ? this.invoiceData.invoice_no : 'New Invoice',
			type: this.invoiceData.invoice_type,
		  };
		}
		
		if (product[i] != undefined && product[i].json_doc != undefined) {
			if(this.sectorService.setProductSource.observers && this.sectorService.setProductSource.observers.length > 0){
				this.sectorService.setProductSource.next(product[i]);
			}else{
				let interVal = setInterval(()=> {
					if(this.sectorService.setProductSource.observers && this.sectorService.setProductSource.observers.length > 0){
						clearInterval(interVal)
						this.sectorService.setProductSource.next(product[i]);
					}
					},1000)
			}
		}
	}

	openMiscellineousInvoiceProduct(i){
		let product = [];
		if (sessionStorage.getItem("product_list") != null) {
		product = JSON.parse(sessionStorage.product_list);
		}
		// this.miscellaneousInvoiceService.initialiseFormData(product[i] && product[i].json_doc ? product[i].json_doc :product[i])
	}

	onFocused(e) {

	}

	viewJournal(invoice_no) {
		var url=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/view-invoice-journal/${invoice_no}`;

		window.open(url,'_blank')
		// this.router.navigate(['/transactions/view-invoice-journal', invoice_no])
	}
	createCreditNote(invoice_no) {
		var url=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/credit-note/create/${invoice_no}`;

		window.open(url,'_blank')
		// this.router.navigate(['/transactions/view-invoice-journal', invoice_no])
	}

	openActivity(invoice_no) {
		let pathurl=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/invoice/${invoice_no}`

		if(window.location.protocol != 'file:'){
			window.open(pathurl,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : pathurl
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}
	closeWindow() {
		this.store.dispatch(new RemoveInternalTab({
			tabIndex : this.activeTabIndex
		}))
		window.close();
	}

	deleteInvoice(invoice_no) {
		this.closeDeleteConfirmModal()
		this.commonService.deleteRequest(`${environment.okountUrl}/invoice?invoice_no=${invoice_no}`).subscribe(res => {
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = `Invoice Deleted`
		}, err => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = `Failed to delete invoice.`
		})
	}

	resetOpenedModal(){
		this.currentOpenedModal=''
	}
	async openDeleteConfirmModal() {
		if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.invoiceData.invoice_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.nestedForm.controls.invdate.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to delete the invoice.'
				return false;
			}
		}
		// this.closeOpenedModals('delete')
		this.currentOpenedModal = 'delete'
		this.openConfirmDeleteModal.nativeElement.click()
	}
	closeDeleteConfirmModal() {
		// this.currentOpenedModal = ''
		this.closeDeleteModal.nativeElement.click()
	}

	async openUpdateConfirmModal() {
		if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.invoiceData.invoice_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.nestedForm.controls.invdate.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to update the invoice.'
				return false;
			}
		}
		
		!this.financialYearDiff && this.checkEmployeeValidation();
		this.sectorService.submitted = true;

		const invalid = [];
			const controls = this.nestedForm.controls;
			for (const name in controls) {
				if (controls[name].invalid && name != "paymentdetails") {
					invalid.push(name);
			}
		}
		
		if(invalid.length > 0 || this.sectorService.travellerForm.invalid) {
			window.scroll(0,0)
			this.showMessage = true;
			this.isSuccess = false
			this.invoice_number = ''
			this.alertType = 'error-box';
			// this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
			this.message = 'Please fill the mandatory fields'
			this.spinner.hide()
			return false
		}

		if(this.duplicateTicket) {
			window.scroll(0,0)
			this.showMessage = true;
			this.isSuccess = false
			this.invoice_number = ''
			this.alertType = 'error-box';
			this.message = `Invalid Ticket Number.`
			this.spinner.hide()
			return false
		}
		// this.closeOpenedModals('update')
		this.currentOpenedModal = 'update'
		this.confirmUpdateModal.nativeElement.click()
	}
	closeUpdateConfirmModal() {
		// this.currentOpenedModal = ''
		this.confirmUpdateModal.nativeElement.click()
	}
	closeOpenedModals(modalName){
		if(modalName!='delete') this.closeDeleteConfirmModal()
		if(modalName!='update') this.closeUpdateConfirmModal()
		// return true
	}
	
	openAffiliateUpdateConfirmModal() {
		this.affiliateUpdateConfirmModal.nativeElement.click()
	}

	showMessageFn(data) {
		// debugger
		// this.showModuleMessage = data.status
		// this.moduleAlertType = data.alertType
		// this.moduleMessage = data.message ? data.message : ""
		this.showMessage = data.status
		this.alertType = data.alertType
		this.message = data.message ? data.message : "Something went wrong. Please contact dev team"
	}
	hideModuleMessage() {
		this.showModuleMessage = false
	}

	onTypeOfInvoiceChange(value) {
		if (value == 'reissue') {
			this.nestedForm.controls.referenceInvoice.setValidators(Validators.required)
		} else {
			this.nestedForm.controls.referenceInvoice.setValidators(null)
		}
		this.nestedForm.controls.referenceInvoice.updateValueAndValidity()
		this.sectorService.invoiceType = value
	}

	validateDate(){
		return (control: AbstractControl) => {
			let valid = true
			if(this){
				if (moment(control.value).isBefore(this.minDate) || moment(control.value).isAfter(this.maxDate)) {
					if(this.invoiceMode == 'view' && this.sectorService.invoiceDate && moment(control.value).isSame(moment(this.sectorService.invoiceDate))){
						valid = true
					}else{
						valid = false
					}
				}
			}
			return !valid ? {invdate: {value: control.value}} : null;
		};
	}

	viewPrintScreen(invoice){
		if (invoice != 'Credit Note' && invoice != 'New Invoice') {
			// if (i==0) {
			// 	window.open(`/#/transactions/invoice-view;searchId=${invoice};type=flight`, "_blank");
			// }else{
			// 	window.open(`/#/transactions/print-credit-note/${invoice};type=flight`, "_blank");
			// }
			if (invoice.includes('CRN')) {
				var printScreenPath=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/print-credit-note/${invoice};type=flight`;
		     	if(window.location.protocol != 'file:'){
				    window.open(printScreenPath,'_blank')
			    }else{
				   var customeEventHandler = new CustomEvent("handle-window-open", {
					"detail": {
						hashPath : printScreenPath
					}
				  });
				window.dispatchEvent(customeEventHandler)
			}


			}else{
				var printScreenPath=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/invoice-view;searchId=${invoice};type=flight`;
				if(window.location.protocol != 'file:'){
				    window.open(printScreenPath,'_blank')
			    }else{
				   var customeEventHandler = new CustomEvent("handle-window-open", {
					"detail": {
						hashPath : printScreenPath
					}
				  });
				window.dispatchEvent(customeEventHandler)
			}

			}

		}
	}

	handleInprogressAlert(data){
		if(data.showModal){
			this.closeModuleFixed()
			this.message = data.message
			this.openInprogressPNRModal()
		}
	}

	openInprogressPNRModal() {
		this.openInprogressPNR.nativeElement.click()
	}
	closeInprogressPNRModal() {
		this.closeInprogressPNR.nativeElement.click()
	}

	setSelectedSupplier(data){
		this.selectedSupplier = data
	}


	async getProduct() {
		let product_json_doc = this.requestBuilder.createInvoiceProduct(
		  this.sectorService.travellerForm.value,
		  this.sectorService.sectorForm.value,
		  this.sectorService.invoiceForm.value,
		  this.sectorService
		);
		let crsId = await this.requestBuilder.getCRSID(
		  this.sectorService.invoiceForm.value,
		  this.selectedSupplier[0].acode
		);
		product_json_doc["crs_id"] = crsId;
	
		product_json_doc[
		  "crs_id_value"
		] = this.sectorService.invoiceForm.value.crsId;
		product_json_doc[
		  "airline"
		] = this.sectorService.invoiceForm.controls.airline.value;
		product_json_doc[
		  "supplier"
		] = this.sectorService.invoiceForm.controls.supplier.value;
		product_json_doc[
		  "invoiceType"
		] = this.sectorService.invoiceForm.controls.invoiceType.value;
		product_json_doc[
		  "fareBasis"
		] = this.sectorService.invoiceForm.controls.fareBasis.value;
		product_json_doc[
		  "dealCode"
		] = this.sectorService.invoiceForm.controls.dealCode.value;
		product_json_doc[
			"classType"
		] = this.sectorService.invoiceForm.controls.classType.value;
		let total_fare = {};
		for (const obj in this.sectorService.totalFareModel[0]) {
		  if (obj != "0") {
			total_fare[obj] = `${this.sectorService.totalFareModel[0][obj]}`;
		  }
		}
		product_json_doc["total_fare"] = total_fare;

		let requestObj = {
		  travellers: this.sectorService.travellerForm.value,
		  sectors: this.sectorService.sectorForm.value,
		  invoiceForm: this.sectorService.invoiceForm.value,
		};
		let narration = "";
		let narrArr = []
		let hsn = "";
		
		for(let traveller of requestObj.travellers.travellerList) {
			if(requestObj.invoiceForm.invoiceType == "C" && (traveller.txtbookingSupplierCode == undefined || traveller.txtbookingSupplierCode == "" || traveller.txtbookingSupplierCode == null)) {
				this.showMessage = true
				this.alertType = "error-box"
				this.message = "Payable not Selected"
				this.spinner.hide()
				return ;
			}
		}

		for(let traveller of requestObj.travellers.travellerList) {
			if(traveller.type_of_pay == "OUR_CC" && (traveller.ledger_card_no == undefined || traveller.ledger_card_no == "" || traveller.ledger_card_no == null)) {
				this.showMessage = true
				this.alertType = "error-box"
				this.message = "Please select the card"
				this.spinner.hide()
				return ;
			}
		}
		
		try {
		  let saveTravellerRes = await this.saveTravellers(requestObj);
		  if (saveTravellerRes != "" && saveTravellerRes != undefined) {
			narration = saveTravellerRes["narration_1"]
			  ? saveTravellerRes["narration_1"]
			  : "";
			narrArr = saveTravellerRes["narration_2"]
			hsn = saveTravellerRes["hsn"] ? saveTravellerRes["hsn"] : "";
		  }
		} catch (error) {}
		let productObj = await this.requestBuilder.createProductObj(
		  product_json_doc,
		  this.sectorService.totalFareModel[0],
		  narration,
		  hsn,
		  this.sectorService
		);
		productObj['narrationArr'] = narrArr;
		let product_list = []
		sessionStorage.removeItem('product_list')
		this.makeProduct(productObj)
		product_list[0] = productObj;
		sessionStorage.setItem("product_list", JSON.stringify(product_list));
		return product_list
	  }

	  async saveTravellers(value) {
		let travellersObj = await this.requestBuilder.createTravellers(
		  value.travellers
		);
		let sectorObj = await this.requestBuilder.createSectorsObj(
		  value.sectors,
		  value.invoiceForm,
		  this.sectorService
		);
		var url = window.location.href.slice(window.location.href.indexOf("?") + 1);
		let invNo = url.split("=")[1];
		if (invNo == undefined) {
		  invNo = this.invoice_no;
		}
		if (invNo != undefined) {
		  let requestObj = {
			json_doc: travellersObj,
			sectors: sectorObj,
			uuid: invNo,
		  };

		  return new Promise((resolve, reject) => {
			this.invoiceService
			  .postMethod("invoice/traveller/save", requestObj)
			  .subscribe(
				(res) => {
				  let response = res;
				  resolve(response);
				  console.log("Travellers save response - SUCCESS : ", res);
				},
				(err) => {
				  resolve("");
				  console.log("Travellers save response - ERROR : ", err);
				}
			  );
		  });
		 
		}
	  }


	openAddMiscellaneousInvoiceModal() {
		this.openAddMiscellaneousInvoice.nativeElement.click()
	}
	closeAddMiscellaneousInvoiceModal() {
		this.closeAddMiscellaneousInvoice.nativeElement.click()
	}

	selectCreateMsInvoice(type){
		let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/ms/ms-invoice?flight_invoice=${this.invoice_no}`
		window.open(url,'_blank')
		this.closeAddMiscellaneousInvoiceModal()
	}
	openMsInvoice(invoice){
		let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/ms/ms-invoice/view/${invoice}`
		window.open(url,'_blank');
	}

	openSendEmailModal(){
		//define email.
		let primary_email = ''
		let secondary_email = ''
		let subject = `INVOICE NO. ${this.invoice_no}`
		let body = `Dear Travel Partner,\n\nPlease find Attached Invoice ${this.invoice_no}`
		body+=`\n\nPlease do not hesitate to contact us if you have any query or concern about attached document.\n\nIt's been a pleasure to serve you.\n\nBest Regards\n\n${this.invoiceData?.accounting_company?.name}\nAccounts Team`
		if(this.invoiceData.accounting_company && this.invoiceData.accounting_company.telephone){
			body += `\nContact Us on ${this.invoiceData.accounting_company.telephone}`
		}
		if(this.invoiceData && this.invoiceData.client){
		primary_email = this.invoiceData.client.hasOwnProperty('primary_email')?this.invoiceData.client.primary_email:''
		secondary_email = this.invoiceData.client.hasOwnProperty('secondary_email')?this.invoiceData.client.secondary_email:''
		}
		this.sendEmailForm.controls.toEmails.setValue(primary_email)
		this.sendEmailForm.controls.bccEmails.setValue("")
		this.sendEmailForm.controls.ccEmails.setValue(secondary_email?.split(',').join('\n'))
		this.sendEmailForm.controls.subject.setValue(subject)
		this.sendEmailForm.controls.body.setValue(body)
		this.openEmailModal.nativeElement.click();
	}


	callTosendEmail(){
		
		let toEmails = this.sendEmailForm.value.toEmails
		let bccEmails = this.sendEmailForm.value.bccEmails?.split(",")
		let ccEmails = this.sendEmailForm.value.ccEmails?.split(",")
		let subject = this.sendEmailForm.value.subject
        let body = this.sendEmailForm.value.body
		let clientName = this.invoiceData && this.invoiceData.client && this.invoiceData.client.name?this.invoiceData.client.name:''
		if(toEmails){
			toEmails = toEmails.split(",")
			window.scrollTo(0, 0)
			let emails = {
				to_addresses:toEmails,
				cc_addresses:ccEmails,
				bcc_addresses:bccEmails
			}
			let pageUrl = `transactions/invoice-view;searchId=${this.invoice_no};type=flight`
			const formData = new FormData();
			formData.append('email',JSON.stringify(emails))
			formData.append('name',clientName)
			formData.append('page_url',pageUrl)
			formData.append('subject',subject)
			formData.append('email_body',body)
			formData.append('document_num',this.invoice_no)
			if(this.attachmentFile){
				formData.append('attachmentFile',this.attachmentFile)
			}
			let data = formData
			
			this.commonService.fileUpload(`${environment.okountUrl}/send_mail?transaction_type=flight_invoice`,data).subscribe((res : any)=> {
				this.showMessage = true;
				this.alertType = 'success-box'
				this.message = res.msg
				this.closebutton.nativeElement.click();
			},err => {
				this.showMessage = true
				this.alertType = "error-box"
				this.message = err.msg
				this.closebutton.nativeElement.click();
				
			})
			}
	  }

	  openSsrModal(event) {
		if(event && event != undefined) {
			this.paid_ssrs_types = event
		}
		this.openPaidSsrModal.nativeElement.click();
	  }

	  async setSsrType(type) {
		let url = `#/transactions/invoice-view?ssrType=${type}`
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${url}`
		if(window.location.protocol != 'file:'){
		  window.open(path,'_blank')
		}else{
		  var customeEventHandler = new CustomEvent("handle-window-open", {
			"detail": {
			  hashPath : path
			}
			});
		  window.dispatchEvent(customeEventHandler)
		}
	}

	onClickSsrBtn() {
		this.openPaidSsrModal.nativeElement.click();

	}

	closeSuccesModal(){
		this.showMessage = false;
		this.isSuccess = false
		this.alertType = 'success-box';
		this.message = ``
		this.newInvoice = true
		this.openSuccessModal.nativeElement.click()
	}

	copytext(val: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.copyELementTitle = 'Copied'
	}

	viewInvoice(invoice_no) {
		this.router.navigateByUrl(`transactions/invoice/view/${invoice_no}`)
	}

	checkWhetherEInvGeneratedOrNot(invoice) {
		this.commonService.getRequest(`${environment.okountUrl}/e_invoicing/check-invoice?invoiceNumber=${invoice}`).subscribe((res : any)=>{
			if(res && res['message'] && res['message'] == 'not_generated') {
				this.generateEInvoiceModal.nativeElement.click()
				return false;
			} 
			this.getPdfOfEInvoice(invoice)
		},err=>{
			console.log(err)
		})
	}

	checkWhetherEInvGenerated(invoice) {
		this.commonService.getRequest(`${environment.okountUrl}/e_invoicing/check-invoice?invoiceNumber=${invoice}`).subscribe((res : any)=>{
			if(res && res['message'] && res['message'] == 'not_generated') {
				this.checkEInvoice= false;
			} else{
				this.checkEInvoice= true
			}
			if(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA')) {
				let userData = JSON.parse(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA'))
				
			if(userData.tenant_id && environment.BHUPESH_TENANT.split(',').includes(typeof userData.tenant_id=='number'?userData.tenant_id.toString():userData.tenant_id)){
				this.isTesoyBoult = true
			}
		}
		},err=>{
			console.log(err)
			return false
		})
	}

	getPdfOfEInvoice(invoice) {
		this.spinner.show()
		this.commonService.downloadPDF(`${environment.okountUrl}/e_invoicing/print?invoiceNumber=${invoice}&preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `E-invoice-${invoice}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			this.spinner.hide()
			FileSaver.saveAs(blob, fileName);
	   }, err => {
		    this.spinner.hide()
	   })
	}

	closeGenerateEInvoiceModal(){
		this.generateEInvoiceModal.nativeElement.click()
	}

	generateEInvoice(invoice) {
		this.spinner.show()
		this.commonService.postRequest(`${environment.okountUrl}/e_invoicing?invoiceNumber=${invoice}`, null).subscribe((res : any)=> {
			this.showMessage = true;
			this.alertType = 'success-box'
			this.getPdfOfEInvoice(invoice);
		},err => {
			this.showMessage = true
			this.spinner.hide()
			this.alertType = "error-box"
			this.message = err.error && err.error.message ? err.error.message : ''
		})

	}

	clickToPrintOptions() {
		this.showPrintOptions = !this.showPrintOptions;
	}

	setFormValues(data) {
		this.showMessage = true;
		this.alertType = 'success-box';
		this.message = `${this.sectorService.currentMaster} created successfully with account code. : ${data.account_code}`
		if(this.sectorService.currentMaster == 'airline'){
			if(data.account_name) {
			  this.sectorService.invoiceForm.controls.airline_name.setValue(data.account_name)
			}
			if(data.account_code) {
			  this.sectorService.invoiceForm.controls.airline.setValue(data.account_code)
			}
		}else {
			  this.selectEvent(data)
		}
		this.sectorService.showCreateButton = false;
	}

	getAccountingCompany() {
		try {
			this.invoiceService.getMethod('util/accounting-company').subscribe(async (res) => {
				if(res) {
					if(res['currency_code']) {
						this.currencyCode = res['currency_code'];
					}
					if(res['native_language_code'] && res['currency_code'] == 'AED') {
						this.nativeLanguageCode = res['native_language_code'];
					}
					if(this.invoiceMode != 'view') {
						this.nestedForm.controls.currency_rate.setValue(1);
				        this.nestedForm.controls.purchase_currency.setValue(this.currencyCode ? this.currencyCode : '');
					}
					if(res['gst_number']){
						this.isGstNumberExist = true
					}
				}
			},
			err => {
				this.spinner.hide()
			})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
		}
	}
	onpurchaseCurrencyChange(value){
		this.nestedForm.controls.currency_rate.setValue("");
		// this.nestedForm.controls.narration_1.setValue(value) 
	
	}

    convertInvoicePrintForClient(invoice_no) {
		
		let sales_currency = this.nestedForm.value.sales_currency;
		let sales_currency_rate = this.nestedForm.value.sales_currency_rate;
		let url = `#/transactions/invoice-view;searchId=${invoice_no};type=flight;currency_rate=${sales_currency_rate};sales_currency=${sales_currency}`
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${url}`
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

	changeSalesCurrentAmount(event) {
		let sales_currency = this.nestedForm.controls.sales_currency.value;
		let sales_currency_rate = this.nestedForm.controls.sales_currency_rate.value;
		if(sales_currency && sales_currency_rate) {
			let client_net_amount = Number(this.sectorService.totalFareModel[0]['net_received'])
			let grandTotal = Number(client_net_amount);
			this.nestedForm.controls.sales_currency_amount.setValue((grandTotal * sales_currency_rate).toFixed(2));
		} else {
			this.nestedForm.controls.sales_currency_amount.setValue('')
		}
	}

	showModal(): void {
		this.isVisible = true;
	  }

	  handleOk(): void {
		console.log('Button ok clicked!');
		this.isVisible = false;
	  }
	
	  handleCancel(): void {
		console.log('Button cancel clicked!');
		this.isVisible = false;
	  }

	  inputPnr(value){
		this.convertPnr=value
	  }

	  pnrConvert() {
		let url = environment.okountUrl + `/invoice/pnr-convert`
		this.commonService.postRequest(url,{"pnr":this.convertPnr}).subscribe(
			async(res: any) => {
				await this.sectorService.bindRetrievedData(res)
				localStorage.setItem("retrievedData", JSON.stringify(res))
			})
			this.isVisible = false;
			
	  }
	  handleAttachment(event: any) {
		this.attachmentFile = event.target.files[0];
	  }

	invoiceDateValidation() {
		let financeYearResponse = this.commonService.checkFinancialYearValidation();
		this.financialYearDiff = financeYearResponse.isFinancialYearDiff;
		this.minDate = financeYearResponse.minDate;
		this.maxDate = financeYearResponse.maxDate;
		
		this.nestedForm.controls.invdate.setValue(this.maxDate)
		this.nestedForm.controls.invdate.setValidators([Validators.required, this.validateDate()]);
		this.nestedForm.controls.invdate.updateValueAndValidity()
	}  

	openBill(invoice){
		this.spinner.show()
		this.commonService.downloadPDF(`${environment.okountUrl}/invoice/download?invoiceNumber=${invoice}&currency_bill=true&preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `CURRENCY-BILL-${invoice}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			this.spinner.hide()
			FileSaver.saveAs(blob, fileName);
	   }, err => {
		    this.spinner.hide()
	   })
	}

	openBosReference() {
		if(this.invoiceData && this.invoiceData.bos_reference) {
			var url=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/invoice/view/${this.invoiceData.bos_reference}`;
			window.open(url,'_blank')
		}	
	}

	viewTicket(invoice) {
		this.spinner.show()
		this.commonService.downloadPDF(`${environment.okountUrl}/invoice/ticket?invoiceNumber=${invoice}&preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `Ticket-${invoice}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			this.spinner.hide()
			FileSaver.saveAs(blob, fileName);
	   }, err => {
		    this.spinner.hide()
	   })
	}

}
	