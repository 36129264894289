import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PaymentService } from '../services/payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { NumberToWordsPipe } from 'src/app/shared/pipe/print-invoice.pipe';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver'; 
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder } from '@angular/forms';

@Component({
	selector: 'app-print-payment',
	templateUrl: './print-payment.component.html',
	styleUrls: ['./print-payment.component.scss']
})
export class PrintPaymentComponent implements OnInit {

	paymentId: any;
	paymentData: any;
	valid: boolean;
	shortcuts: ShortcutInput[] = [];
	@Input() reference_code;
	@Input() preFetchedData;
	@Output() callSaveFetchedData: EventEmitter<any> = new EventEmitter();
	waterMark;
	updateBillingDetailsForm: any;
	displayBillingDetails : any
	@ViewChild('openBillingDetailsModal', { static: true }) openBillingDetailsModal: ElementRef
	showPartyName: boolean = true;

	constructor(
		private paymentService: PaymentService,
		private spinner: NgxSpinnerService,
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		public sanitizer: DomSanitizer,
		private fb: FormBuilder
	) {
		this.route.paramMap.subscribe(params => {
			this.paymentId = params['params'].id
			if (this.paymentId != undefined) {
				document.title = `Payment -  ${this.paymentId}`
			}
		});
	}

	ngOnInit() {
		if(!this.preFetchedData){
			if(!this.paymentId){
				this.paymentId = this.reference_code
			}
			this.getPaymentDetails()
		}else{
			this.paymentData = this.preFetchedData
			this.valid = true
		}
		let queryParams = this.route.queryParams['value'];
		if(queryParams && queryParams.hide_party && queryParams.hide_party == 'true') {
			this.showPartyName = false;
		}

		this.updateBillingDetailsForm = this.fb.group({
			"invoice_no": [this.paymentId],
			"display_billing_details": [''],
			'inv_in_native_lang': [false]
		})
	}


	print() {
		window.print()
	}

	getPaymentDetails() {
		this.spinner.show()
		this.paymentService.getMethod(`paymententryv3/download?payment_code=${this.paymentId}&preview=true`).subscribe(async (res) => {
			if (res) {
				this.paymentData = this.transform(res);
				this.valid = true
			}
			this.spinner.hide()
		}, err => {
			this.paymentData = []
			this.spinner.hide()
		})
	}

	transform(html) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}
	
	downloadPdf(searchId: any) {
		this.commonService.downloadPDF(`${environment.okountUrl}/paymententryv3/download?payment_code=${searchId}&preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `Payment-${this.paymentId}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}

	getNumberToWord(value) {
		 let str = new NumberToWordsPipe().transform(Number(value).toFixed())
		 return str
	}

	updateDisplayBilling(){
		let reqObj = {
			invoice_no : this.updateBillingDetailsForm.value.invoice_no,
			display_billing_details : this.updateBillingDetailsForm.value.display_billing_details
		}
		
		this.displayBillingDetails = 'updating...'
		this.commonService.putRequest(`${environment.okountUrl}/paymententryv3/billing_details/update`,reqObj).subscribe(async(res: any) =>{
			this.openBillingDetailsModal.nativeElement.click()
			this.getPaymentDetails()
		},err =>{
			console.log(err)
		})
	}

	openBillingDetails() {
		this.openBillingDetailsModal.nativeElement.click();
	}

}
