<div class="ledger-container">
    <div class="voucher-container">
      <div class="row tab-nav pl-0 pr-0">
      <div class="col-sm-4">
        <ul
          class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0"
        >
          <li
            [routerLink]="['/']"
            class="text-grey text-capitalize cursor hover-text-primary transition"
            [ngClass]="{ 'd-none': isPrint == 'true' }"
          >
            Analysis
          </li>
          <li>
            <img
              src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg"
              alt=""
              [ngClass]="{ 'd-none': isPrint == 'true' }"
            />
          </li>
          <li
            class="text-grey text-capitalize cursor text-primary transition"
            [ngClass]="{ 'd-none': isPrint == 'false' }"
            [ngClass]="{ 'd-none': isPrint == 'true' }"
          >
          PSR Report -
          </li>
        </ul>
      </div>
      <div class="col-sm-4 text-center">
        <span class="rec-date d-block text-center">
          <div class="noWrap justify-content-around">
              <ng-container *ngIf="duration != undefined">
                  <div class="cname">From
                      <h5 style="margin-right: 5px;" class="mb-0  d-inline"><span class="date text-bold text-orange"
                              *ngIf="duration.from_date != ''">
                              {{duration?.from_date | date : 'd MMMM, y'}} 
                          </span>
                          <span class="date text-bold" *ngIf="duration.from_date != ''">
                              To
                          </span>
                          <span class="date text-bold" *ngIf="duration.from_date == ''">
                              Till Date
                          </span>
                          <span class="date text-bold text-orange">
                              {{duration?.to_date | date : 'd MMMM, y'}}
                          </span>
                      </h5>
                      <button
                      [ngClass]="{ 'd-none': isPrint == 'true' }"
                      title="Custom Search"
                      class="btn save-as text-bold bg-whiteSmoke"
                      type="button"
                      (click)="openAdvanceSearchModal()"
                      >
                      <img
                        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg"
                        alt=""
                        width="15px"
                      />
                    </button>
                  </div>
              </ng-container>	
          </div>
        </span>
      </div>
      <div class="col-sm-4 d-flex align-item-center justify-content-end">
        <div
        class="page-search-box input-group d-flex align-item-center" 
      [ngClass]="{ 'd-none': isPrint == 'true' }"
        style="height: fit-content; text-align: left"
      >
        <input
          type="text"
          class="form-control"
          oninput="this.value = this.value.toUpperCase()"
          placeholder="search"
          style="text-align: left"
        />
      </div>
      <div class="dropdown-btn position-relative" [ngClass]="{ 'd-none': isPrint == 'true' }">
          <button title="Filter..." class="btn-style ml-1 2 p-20 text-white text-bold">
                  Filter
          </button>
          <ul class="list-unstyled pl-0 dropdown-box" style="margin-top: -7px;">
          
          <li (click)="handleFilter('all')" >
              <input type="checkbox" [checked]="showData['invoice'] && showData['credit_note'] && showData['adm'] && showData['acm']"> <span class="ml-2">All</span>
          </li>
          <li (click)="handleFilter('invoice')" >
              <input type="checkbox" [checked]="showData['invoice']"> <span class="ml-2">Invoice</span>
          </li>
          <li (click)="handleFilter('credit_note')" >
              <input type="checkbox" [checked]="showData['credit_note']"> <span class="ml-2">Credit Note</span>
          </li>
          <li (click)="handleFilter('adm')" >
              <input type="checkbox" [checked]="showData['adm']"> <span class="ml-2">ADM</span>
          </li>
          <li (click)="handleFilter('acm')" >
              <input type="checkbox" [checked]="showData['acm']"> <span class="ml-2">ACM</span>
          </li>
          </ul>
      </div>
        
        <div class="dropdown-btn position-relative" 
        [ngClass]="{ 'd-none': isPrint == 'true' }">
          <button title="More..." class="btn-style mt-0 more-btn">
            <span class="dot"></span><span class="dot"></span
            ><span class="dot"></span>
          </button>
          <ul class="list-unstyled pl-0 dropdown-box">
            
            <li (click)="downloadPdf(duration.from_date, duration.to_date)" >
              <img
                src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg"
                alt=""
              />
              <span class="ml-2">Save As Pdf</span>
            </li>
            <li (click)="exportAsXLSX()">
              <span class="ml-2">EXCEL</span>
            </li>
          </ul>
        </div>
      </div>
      </div>

      <div class="voucher-container psrdetailedtbl misdetailedtbl mr-1">
        <div class="table" style="margin-top: -2px; height: auto!important;" *ngIf="listOfColumnsParent && listOfColumns">
          <nz-table #basicTable [nzData]="allInvoiceArr"
            [nzLoading]="loader"
            [nzFrontPagination]="false" 
            class="table text-black striped border-0">
              <thead>

                <tr style="border-bottom: 0;" class="parent-header">
                  <th
                    *ngFor="let column of listOfColumnsParent"
                      [ngClass]="column && column.class ? column.class: ''"
                      colspan="{{column && column.colspan ? column.colspan : 1}}"
                      attr.colspan="{{column && column.colspan ? column.colspan : 1}}"
                  >
                    {{ column?.name }}
                  </th>
                </tr>

                <tr class="sub-header" >
                  <th
                    *ngFor="let column of listOfColumns"
                        [ngClass]="column && column.class ? column.class: ''"
                        [ngStyle]="{width: column && column.width ? column.width : 'auto'}"
                    >
                    {{ column?.name }}
                  </th>
                </tr>
              </thead>

              <tbody class="scroll-body main-panel psr-data-tbl mis-data-tbl" style="position: absolute;">
                
                            <!-- Invoice -->
                            <ng-container *ngIf="basicTable['data']['invoice'] && showData['invoice']">
                            <tr>
                              <td colspan="15" class="text-bold undl text-black text-underline">Invoice</td>
                            </tr>
                            <tr *ngFor="let data of basicTable['data']['invoice'] ; let i = index;"
                            (keyup.enter)="openViewMode(data.remarks,'invoice')"
                            (click)="openViewMode(data.remarks,'invoice')"
                            >
                            <ng-container 
                                *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                            </ng-container>
                            </tr>
                            <tr *ngIf="basicTable['data']['invoice_total']">
                                <ng-container 
                                        *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['invoice_total'][0]}">
                                </ng-container>
                            </tr>
                            <tr>
                                <td colspan="15">&nbsp;</td>
                            </tr>
                            </ng-container>

                            <!-- Credit Note -->
                            <ng-container *ngIf="basicTable['data']['credit_note'] && showData['credit_note']"
                            >
                                <tr>
                                  <td colspan="15" class="text-bold undl text-black text-underline">Credit Note</td>
                                </tr>
                                <tr *ngFor="let data of basicTable['data']['credit_note'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.remarks,'credit_note')"
                                (click)="openViewMode(data.remarks,'credit_note')"
                                >
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['credit_note_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['credit_note_total'][0]}">
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td colspan="15">&nbsp;</td>
                                </tr>
                            </ng-container>
                            <!-- ADM -->
                            <ng-container *ngIf="basicTable['data']['adm'] && showData['adm']">
                                <tr><td colspan="15" class="text-bold undl text-black text-underline">ADM</td></tr>
                                <tr *ngFor="let data of basicTable['data']['adm'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.remarks,'adm')"
                                (click)="openViewMode(data.remarks,'adm')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['adm_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['adm_total'][0]}">
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td colspan="15">&nbsp;</td>
                                </tr>
                            </ng-container>
                            <!--ACM -->
                            <ng-container *ngIf="basicTable['data']['acm'] && showData['acm']">
                                <tr><td colspan="15" class="text-bold undl text-black text-underline">ACM</td></tr>
                                <tr *ngFor="let data of basicTable['data']['acm'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.remarks,'acm')"
                                (click)="openViewMode(data.remarks,'acm')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['acm_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['acm_total'][0]}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="15">&nbsp;</td>
                                </tr>
                            </ng-container>
              </tbody>

              <tfoot style="background: #fafafa!important;">
                <tr >
                  <ng-template #dataTemplate let-data="data" let-index="index">
                    <td >{{data?.ticket_no}}</td>
                    <td >{{data?.issue_date | date : 'dd/MM/yyyy'}}</td>
                    <td class="text-right">
                        <ng-container *ngIf="data?.cash > 0 ;else zeroVal">
                            {{data?.cash  | number :'1.2-2'}}
                        </ng-container>
                    <td class="text-right">
                        <ng-container *ngIf="data?.credit > 0 ;else zeroVal">
                            {{data?.credit  | number :'1.2-2'}}
                        </ng-container></td>
                    <td class="text-right">
                        <ng-container *ngIf="data?.base > 0 ;else zeroVal">
                            {{data?.base  | number :'1.2-2'}}
                        </ng-container></td>
                    <td class="text-right">
                        <ng-container *ngIf="data?.yq > 0 ;else zeroVal">
                            {{data?.yq  | number :'1.2-2'}}
                        </ng-container>
                    </td>
                    <td class="text-right">
                        <ng-container *ngIf="data?.tax > 0 ;else zeroVal">
                            {{data?.tax  | number :'1.2-2'}}
                        </ng-container>
                    </td>
                    <td class="text-right">
                        <ng-container *ngIf="data?.raf > 0 ;else zeroVal">
                            {{data?.raf  | number :'1.2-2'}}
                        </ng-container>
                    </td>
                    <td class="text-right">
                        <ng-container *ngIf="data?.comm_iata_rate > 0 ;else zeroVal">
                            {{data?.comm_iata_rate  | number :'1.2-2'}}
                        </ng-container>
                    </td>
                    <td class="text-right">
                        <ng-container *ngIf="data?.comm_iata_amount > 0 ;else zeroVal">
                            {{data?.comm_iata_amount  | number :'1.2-2'}}
                        </ng-container></td>
                    <td class="text-right">
                        <ng-container *ngIf="data?.comm_cb_rate > 0 ;else zeroVal">
                            {{data?.comm_iata_rate  | number :'1.2-2'}}
                        </ng-container>
                    </td>
                    <td class="text-right">
                        <ng-container *ngIf="data?.comm_cb_amount > 0 ;else zeroVal">
                            {{data?.comm_iata_amount  | number :'1.2-2'}}
                        </ng-container>
                    </td>
                    <td class="text-right">{{data && data.net_paid && data.net_paid != 'NaN' ? data.net_paid : 0  | number :'1.2-2'}}</td> 
                    <td class="text-right">{{data && data.net_paid && data.net_paid != 'NaN' ? data.net_paid : 0  | number :'1.2-2'}}</td>
                    <td >{{data?.remarks}}</td>
                    </ng-template>

                    <ng-template #totalTemplate let-data="data" let-index="index">
                        <td class="text-black text-bold">Nos. {{data.count}}</td>
                        <td ></td>
                        <td class="text-right sub-total-col amt-total  border-right" >{{data?.cash  | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total border-right">{{data?.credit  | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total border-right">{{data?.base  | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total border-right">{{data?.yq  | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total border-right">{{data?.tax  | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total border-right">{{data?.raf  | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total border-right">{{data?.comm_iata_rate  | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total border-right">{{data?.comm_iata_amount  | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total border-right">{{data?.comm_cb_rate  | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total border-right">{{data?.comm_cb_amount | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total border-right">{{data && data.net_paid && data.net_paid != 'NaN' ? data.net_paid : 0  | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total border-right">{{data && data.net_paid && data.net_paid != 'NaN' ? data.net_paid : 0  | number :'1.2-2'}}</td>
                        <td ></td>
                      </ng-template>

                      <ng-template #zeroVal> <span></span> </ng-template>
                      
                </tr>
              </tfoot>

            </nz-table>
        </div>
      </div>

    </div>
</div>