import { Component, OnInit, HostListener, ViewChild, ElementRef, Input } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { XlsDownloadService } from 'src/app/shared/xls-download.service';
import { DownloadService } from 'src/app/shared/download.service';
import * as moment from 'moment-timezone';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import {  Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { TabService } from 'src/app/shared/services/tab.service';
import { Tab } from 'src/app/shared/model/tab.model';
import { InvoiceComponent } from 'src/app/transactions/invoice/invoice.component';
import { MsInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/ms-invoice/ms-invoice.component';
import { GstInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/gst-invoice/gst-invoice.component';
import { V2CreatePaymentComponent } from 'src/app/transactions/payment/v2-create-payment/v2-create-payment.component';
import { V2CreateReceiptComponent } from 'src/app/transactions/receipt/v2-create-receipt/v2-create-receipt.component';
import { CreateAdmAcmComponent } from 'src/app/transactions/adm-acm/create-adm-acm/create-adm-acm.component';
import { AddEntryJvComponent } from 'src/app/transactions/jv/add-entry-jv/add-entry-jv.component';
import { InvoiceRefundComponent } from 'src/app/transactions/invoice-refund/invoice-refund.component';
import { OpenAdvanceSearchModal } from 'src/app/transactions/store/actions';
import * as FileSaver from 'file-saver';
import { TourComponent } from 'src/app/transactions/tour/tour.component';
import { MiscInvoiceComponent } from 'src/app/transactions/misc-invoice/misc-invoice.component';
import { ConsolidateInvoiceComponent } from 'src/app/transactions/consolidate-invoice/consolidate-invoice.component';


@Component({
	selector: 'app-client-unpaid-report',
	templateUrl: './client-unpaid-report.component.html',
	styleUrls: ['./client-unpaid-report.component.scss'],
	providers: [CommonServiceService]
})
export class ClientUnpaidReportComponent implements OnInit {
	@Input() data;
	p = 0;
	q = 0;
	leaderData: any;
	navigationSubscription;
	reInitialise: boolean;
	currentDate = moment().format('YYYY-MM-DD');
	currentTime = moment().format('HH:mm:ss');
	innerHeight: string;
	ascSorttdsAmt: boolean = true;
	ascSortRef: boolean = true;
	totalAmountObj: any;
	showMessage:boolean=false;
	alertType:String="";
	message:String=""
	clientData:any
	isAdvancedSearchOpen: boolean = false;
	sendEmailForm:FormGroup
	onSearch;
	onSearchFilter = new Subject<string>();
	parentIndex=0;
	childIndex=0;
	@ViewChild('tableHeader', {static:true}) tableHeader : ElementRef
	@ViewChild('closeSendEmailModal') closebutton;
	@ViewChild('openEmailModal') openEmailModal;
	account_type: any;
	isPrint: any;
	duration: any;
	allLedgerArr: any;
	ledgerArr: any[];
	shortcuts: ShortcutInput[] = [];
	referenceTypeName = {
		"invoice" : "INVOICE",
		"credit_note" : "Credit Note",
		"jv" : "Journal",
	}
	viewMode= 'normal'
	dataloaded: any;
	partialAmount = 0;
	remainingAmount = 0;
	queryParams: any;
	isTenantIndian: boolean = false;

	constructor(
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private xlsxDownloadService: XlsDownloadService,
		private downloadService: DownloadService,
		private fb:FormBuilder,
		private store: Store<InitialState>,
		private tabService: TabService

	) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});

		this.sendEmailForm = this.fb.group({
			toEmails: ["",Validators.required],
			bccEmails: [''],
			ccEmails:['']
		})

	}
	
	
	ngOnInit() {
		let queryParams = this.route.queryParams['value']
		this.queryParams = queryParams;
		this.isTenantIndian = this.commonService.checkTenantIndian()

		let queryData = Object.assign({}, queryParams)
		
		if (queryData.client != undefined) {
			localStorage.setItem('activeLedgerQueryParams', JSON.stringify(queryData))
			this.account_type = queryData.account_type
			this.isPrint = queryData.print
			this.getFilteredData(queryData)
		} 

		this.onSearchFilter.pipe(
			debounceTime(300),
			distinctUntilChanged())
			.subscribe(value => {
			  this.filterData(value)
		});
		this.reInitialise = true
		let client = JSON.parse(localStorage.getItem("activeLedgerQueryParams")).client
		this.getClient(client)
	}

	ngAfterViewInit() {
		
		this.shortcuts.push(			
			{
				key: "f10",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Open Email Modal",
				description: "Open Email Modal",
				
				command: (e)=>{
					this.openModal()
					setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
					},
					preventDefault: true
			}
			
	  )
	  console.log(`TIMESTAMP ngAfterViewInit : `, new Date());
	}
	openAdvanceSearchModal(){
		this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'unpaid-report'}));	
	}
	
	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}
	filterData(value) {
		// let tempVariable =  Object.assign([], this.leaderData)
		let tempArr = Object.assign([], this.allLedgerArr)
		if(value.length > 3){
			let lowerCaseFilter = value.toLowerCase()
			let upperCaseFilter = value.toUpperCase()
			this.ledgerArr = tempArr.filter(
				invoiceData => {
					if (	
							
							(
								invoiceData.narration.join('') != undefined && 
								invoiceData.narration.join('') != null && 
								(	
									invoiceData.narration.join('').toString().includes(value) || 
									invoiceData.narration.join('').toString().includes(upperCaseFilter) ||
									invoiceData.narration.join('').toString().includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.date != undefined && 
								invoiceData.date != null && 
								(	
									invoiceData.date.includes(value) || 
									invoiceData.date.includes(upperCaseFilter) ||
									invoiceData.date.includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.amount_debit != undefined && 
								invoiceData.amount_debit != null && 
								(	
									invoiceData.amount_debit.toString().includes(value) || 
									invoiceData.amount_debit.toString().includes(upperCaseFilter) ||
									invoiceData.amount_debit.toString().includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.amount_credit != undefined && 
								invoiceData.amount_credit != null && 
								(	
									invoiceData.amount_credit.toString().includes(value) || 
									invoiceData.amount_credit.toString().includes(upperCaseFilter) ||
									invoiceData.amount_credit.toString().includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.balance != undefined && 
								invoiceData.balance != null && 
								(	
									invoiceData.balance.toString().includes(value) || 
									invoiceData.balance.toString().includes(upperCaseFilter) ||
									invoiceData.balance.toString().includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.reference != undefined && 
								invoiceData.reference != null && 
								(	
									invoiceData.reference.includes(value) || 
									invoiceData.reference.includes(upperCaseFilter) ||
									invoiceData.reference.includes(lowerCaseFilter)
								)
							) 
						//Convert Number to string.
	
						) {
						return invoiceData
					}
				}
			)
		}else{
			this.ledgerArr = []
			for (let i = 0; i <= this.allLedgerArr.length; i++) {
				const element = this.allLedgerArr[i];
				if (element != undefined) {
					this.ledgerArr.push(element)
				}
			}
		}
		

	}
	

	ngOnDestroy() {
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.leaderData != undefined) {
			this.setHeight(this.p, this.q, event.target.innerHeight)
		}
	}

	setHeight(pIndex, innerPIndex, height) {
		if (height == undefined) {
			height = window.innerHeight
		}
		pIndex = Number(pIndex)
		innerPIndex = Number(innerPIndex)
		this.p = pIndex
		this.q = innerPIndex

		let parent = 0
		if (pIndex > 0) {
			parent = pIndex - 1
		}
		let child = 0
		if (innerPIndex > 0) {
			child = innerPIndex - 1
		}
		if (this.leaderData['all_clients_records'].length > 0) {
			if (this.leaderData['all_clients_records'][parent].client_records.length > 0) {
				if (this.leaderData['all_clients_records'][parent].client_records[child].records) {
					// this.innerHeight = 'auto';
					this.innerHeight = height - 230 + 'px'
				}else if (this.leaderData['all_clients_records'][parent].client_records<=2){
						 this.innerHeight = 'auto';

				}
				else {
					 this.innerHeight = height - 230 + 'px';
				}
			}
			else {
				this.innerHeight = height - 230 + 'px';
			}
		}
		else {
			this.innerHeight = height - 230 + 'px';
		}
	}

	getFilteredData(queryParams) {
		this.totalAmountObj ={};
		this.spinner.show()
		this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/unpaid-report/client`, queryParams).subscribe(res => {
			this.spinner.hide();
			this.duration = res['duration']
			this.leaderData = res
			
			this.allLedgerArr = res['all_clients_records'] && res['all_clients_records'].length> 0 && 
			res['all_clients_records'][0].client_records && res['all_clients_records'][0].client_records.length > 0 &&
			res['all_clients_records'][0].client_records

			if(this.allLedgerArr.length && this.allLedgerArr[0].records){
				this.viewMode  = 'detailed'
				let newArr = []
				for(let item of this.allLedgerArr){
					newArr.push(...item.records)
				}
				this.allLedgerArr = newArr
			}

			for(let item of this.allLedgerArr) {
				if(moment(item.date, 'YYYY-MM-DD').isAfter(moment(item.adjusted_timestamph, 'YYYY-MM-DD'))){
					item["isAdjustBefore"]=true
				}
				 if(item['transaction_remaining_amount']) {
					
					if(item.reference_type == 'receipt' && moment(item.adjusted_timestamph, 'YYYY-MM-DD').isAfter(moment(item.date, 'YYYY-MM-DD'))) {
						item['amount_credit'] = item['transaction_remaining_amount']
						item['transaction_remaining_amount'] = null
					} else if(item.reference_type == 'payment' && moment(item.adjusted_timestamph, 'YYYY-MM-DD').isAfter(moment(item.date, 'YYYY-MM-DD'))) {
						item['amount_debit'] = item['transaction_remaining_amount']
						item['transaction_remaining_amount'] = null
					}
				}
			}

			let partially = this.allLedgerArr.filter(item => item['transaction_remaining_amount'] > 0)
			
			for(let obj of partially) {
				this.partialAmount += Number(obj['transaction_remaining_amount'])
			}
			
			let balance = res['all_clients_records'] && res['all_clients_records'].length> 0 && 
			      res['all_clients_records'][0] && Math.abs(res['all_clients_records'][0]['outstanding'])
			
			this.remainingAmount = Number(balance) - Number(this.partialAmount)
			this.ledgerArr = []
			
			 for (let i = 0; i <= this.allLedgerArr.length; i++) {
				const element = this.allLedgerArr[i];
				if (element != undefined) {
					this.commonService.selectedInput.next(1)
					this.ledgerArr.push(element)
				}
			}
			 
		
			this.focusOnTableHeader()
			if (this.leaderData != undefined) {
				this.totalAmountObj = this.getAmount()
				if(queryParams.print == 'true'){
					this.innerHeight = "auto"
				}else{
					this.setHeight(0, 0, window.innerHeight)
				}
			}

			console.log('leaderData', this.leaderData);
			
		}, err => {
			console.error(err);
		})
	}

	tdsamtSort() {
		this.ascSorttdsAmt = !this.ascSorttdsAmt;
		var testData = this.leaderData;
		this.leaderData.data = testData.data.sort((a, b) => {
			if (this.ascSorttdsAmt) {
				if (b['balance'] < a['balance']) {
					return -1;
				}
				if (b['balance'] > a['balance']) {
					return 1;
				}
				return 0;
			} else {
				if (a['balance'] < b['balance']) {
					return -1;
				}
				if (a['balance'] > b['balance']) {
					return 1;
				}
				return 0;
			}
		});
	}

	refSort() {
		this.ascSortRef = !this.ascSortRef;
		let pIndex = Number(this.p)
		let parent = 0
		if (pIndex > 0) {
			parent = pIndex - 1
		}
		var testData = Object.assign([], this.leaderData['all_clients_records'][parent].client_records)
		for (let index = 0; index < testData.length; index++) {
			const element = testData[index];
			let sortedData = element.records.sort((a, b) => {
				if (this.ascSortRef) {
					if (b['reference'] < a['reference']) {
						return -1;
					}
					if (b['reference'] > a['reference']) {
						return 1;
					}
					return 0;
				} else {
					if (a['reference'] < b['reference']) {
						return -1;
					}
					if (a['reference'] > b['reference']) {
						return 1;
					}
					return 0;
				}
			});
			this.leaderData['all_clients_records'][parent].client_records[index].records = sortedData
		}
	}




	exportAsXLSX(fromDate, toDate): void {
		this.spinner.show()
		let xlsxData = [];
		this.leaderData['all_clients_records'].forEach(data => {
			data['client_records'].forEach(client_record => {
				if(client_record['records']){
					xlsxData.push({
						'DATE' : client_record.aggregated_name
					})
				client_record['records'].forEach(records => {
					let obj = {};
					let i = 0
					let newLineNarration = ''
					newLineNarration = records.narration.join("\n")
					obj["DATE"] = `${moment(records.date).format('L')}`;
					obj["REFERENCE"] = records.reference;
					obj["NARRATION"] = newLineNarration
					obj['VOUCHER TYPE'] = records.reference_type
					obj["DEBIT"] = records.amount_debit ? Number(Number(records.amount_debit > 0 ? records.amount_debit : records.amount_debit*-1).toFixed(2)) : 0;
					obj["CREDIT"] = records.amount_credit ? Number(Number(records.amount_credit > 0 ? records.amount_credit : records.amount_credit*-1).toFixed(2)) : 0;
					obj['BALANCE'] = records.balance ? Number(Number(records.balance).toFixed(2)) : 0;
					xlsxData.push(obj);
				});
			}else{
				let obj = {};
					let i = 0
					let newLineNarration = ''
					newLineNarration = client_record.narration.join("\n")
					obj["DATE"] = `${moment(client_record.date).format('L')}`;
					obj["REFERENCE"] = client_record.reference;
					obj["NARRATION"] = newLineNarration
					obj['VOUCHER TYPE'] = client_record.reference_type
					obj["DEBIT"] = client_record.amount_debit ? Number(Number(client_record.amount_debit > 0 ? client_record.amount_debit : client_record.amount_debit*-1).toFixed(2)) : 0;
					obj["CREDIT"] = client_record.amount_credit ? Number(Number(client_record.amount_credit > 0 ? client_record.amount_credit : client_record.amount_credit*-1).toFixed(2)) : 0;
					obj['BALANCE'] = client_record.balance ? Number(Number(client_record.balance).toFixed(2)) : 0;
					xlsxData.push(obj);
			}
			});
		});
		xlsxData.push({
			'NARRATION' : 'SUB TOTAL',
			'DEBIT' : this.totalAmountObj.debit ?  this.totalAmountObj.debit : 0,
			
			'CREDIT':  this.totalAmountObj.credit ? this.totalAmountObj.credit : 0

		})
		xlsxData.push({
			'NARRATION' : 'CLOSING BALANCE',
			'DEBIT' : this.totalAmountObj.type == 'DR.' ? this.totalAmountObj.diff : 0,
			'VOUCHER TYPE' : 'closing balance',
			'CREDIT': this.totalAmountObj.type == 'CR.' ?  this.totalAmountObj.diff : 0

		})

		this.downloadService.exportAsXLSX(`UNPAID REPORT ${fromDate} - ${toDate}`, xlsxData);
		this.spinner.hide()
	}

	callPdfGeneratorApi(fromDate, toDate) {
		window.scrollTo(0, 0)
		this.spinner.show()
		let url = 'print-pdf/report/client-unpaid-report'
		let queryString = location.hash.split('?')[1]
		if (queryString != undefined) {
			url = `${url}?print=true&${queryString}`
		}
		let printOptions = {
			"landscape": false,
			"format" : "A4",
			"preferCSSPageSize": false
		}
		this.downloadService.callPdfGeneratorApi(`UNPAID REPORT ${fromDate} - ${toDate}.pdf`, url,printOptions)
		this.spinner.hide()

	}

	downloadPdf(start_date, end_date) {
		this.commonService.downloadPDF(`${environment.okountUrl}/reports/unpaid-report/client/download`, this.queryParams).subscribe((fileBlob:any) =>{
			let fileName = `UNPAID STATEMENT FROM ${start_date} TO ${end_date}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}


	sendEmails(){
  	
		let toEmails = this.sendEmailForm.value.toEmails
		let bccEmails = this.sendEmailForm.value.bccEmails.split(",")
		let ccEmails = this.sendEmailForm.value.ccEmails.split(",")

		if(toEmails){
		  toEmails = toEmails.split(",")
		  let exclude = ""
		  if(sessionStorage.getItem("excludeArr")){
			  exclude = "&exclude="
			  let excludeArr = JSON.parse(sessionStorage.getItem("excludeArr"))
			  exclude += excludeArr.join(',')
		  }
		  console.log(exclude)
		  window.scrollTo(0, 0)
		  this.spinner.show()
		  let url = 'print-pdf/report/client-unpaid-report'
		  let queryString = location.hash.split('?')[1]
		  if (queryString != undefined) {
			  if(exclude){
				  queryString+=exclude
			  }
			  console.log(queryString)
			  url = `${url}?print=true&${queryString}`
		  }
		  let start_date = JSON.parse(localStorage.getItem("activeLedgerQueryParams")).start_date
		  let end_date  = JSON.parse(localStorage.getItem("activeLedgerQueryParams")).end_date
		  
	
		  let emails = {
			to_addresses:toEmails,
			cc_addresses:ccEmails,
			bcc_addresses:bccEmails
		  }
	  
		  let ledger_criteria = {
			  email:emails,
			  start_date:start_date,
			  end_date:end_date,
			  name:this.clientData.name,
			  email_for:"Unpaid statement",
			  printOptions : {
				"landscape": false,
				"format" : "A4",
				"preferCSSPageSize": false
			}
		  }

		  let data = {
			page_url: url,
			ledger_criteria:ledger_criteria,
			queryParams: this.queryParams,
			reportType : 'unpaid'
		}
	  
		  this.commonService.postRequest(`${environment.okountUrl}/ledger/email`,data).subscribe((res : any)=> {
			console.log(res)
			this.showMessage = true;
			this.alertType = 'success-box'
			this.message = res.msg
			this.closebutton.nativeElement.click();
		  },err => {
			this.showMessage = true
			this.alertType = "error-box"
			this.message = err.msg
			this.closebutton.nativeElement.click();
			
		  })
		}
	  }

	  openModal(){
		this.sendEmailForm.controls.toEmails.setValue(this.clientData.primary_email)
		this.sendEmailForm.controls.bccEmails.setValue("")
		this.sendEmailForm.controls.ccEmails.setValue(this.clientData.secondary_email.split(',').join('\n'))
		this.openEmailModal.nativeElement.click();
	}
	
	
	hideError(){
		this.showMessage = false
	}

	getClient(value) {
		if (value.length > 2) {
			this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
					console.log("res:",res)
					this.clientData = res[0]
			}, err => {
				console.log(err)
			})
		}
	}

	



	getAmount() {
		let amountObj = {
			debit: 0,
			credit: 0,
			diff: 0
		}
		let totalCredit = 0
		let totalDebit = 0
		let balance = 0
		let openingBalance = 0
		let openingBalanceType = 'debit'
		if (this.leaderData != undefined) {
			this.leaderData['all_clients_records'].forEach(data => {
				data['client_records'].forEach(client_record => {
					if(client_record['records']){
					client_record['records'].forEach(records => {
						if(records.reference_type == 'opening_balance'){
							openingBalance += records.amount_debit ? Number(records.amount_debit) : 0;
							if(openingBalance == 0)	{
								openingBalanceType = 'credit'
							}			
							openingBalance += records.amount_credit ? Number(records.amount_credit) : 0;				
						} else {
							if(records.amount_credit) {
								if(records.transaction_remaining_amount) {
									totalCredit += records.transaction_remaining_amount ? Number(records.transaction_remaining_amount) : 0;
								} else {
									totalCredit += records.amount_credit ? Number(records.amount_credit) : 0;
								}
							}else {
								if(records.transaction_remaining_amount) {
									totalDebit += records.transaction_remaining_amount ? Number(records.transaction_remaining_amount) : 0;
								} else {
									totalDebit += records.amount_debit ? Number(records.amount_debit) : 0;
								}
							}
							
						}

					});
					balance += client_record['records'][client_record['records'].length-1].balance ? Number(client_record['records'][client_record['records'].length-1].balance) : 0;
				}else{
					if(client_record.reference_type == 'opening_balance'){
						openingBalance += client_record.amount_debit ? Number(client_record.amount_debit) : 0;
						if(openingBalance == 0)	{
							openingBalanceType = 'credit'
						}			
						openingBalance += client_record.amount_credit ? Number(client_record.amount_credit) : 0;				
					} else {
						if(client_record.amount_credit) {
							if(client_record.transaction_remaining_amount && !client_record.isAdjustBefore) {
								totalCredit += client_record.transaction_remaining_amount ? Number(client_record.transaction_remaining_amount) : 0;
							} else {
								totalCredit += client_record.amount_credit ? Number(client_record.amount_credit) : 0;
							}
						}else {
							if(client_record.transaction_remaining_amount && !client_record.isAdjustBefore) {
								totalDebit += client_record.transaction_remaining_amount ? Number(client_record.transaction_remaining_amount) : 0;
							} else {
								totalDebit += client_record.amount_debit ? Number(client_record.amount_debit) : 0;
							}
						}
						
					}
					
					
				}
			});
			if(!this.leaderData['all_clients_records'][0]['client_records']['records']) balance += data['client_records'][data['client_records'].length-1].balance ? Number(data['client_records'][data['client_records'].length-1].balance) : 0;
			});
			amountObj['credit'] = totalCredit
			amountObj['debit'] = totalDebit
			amountObj['balance'] = balance
			amountObj['diff'] = Number(totalCredit) -(Number(totalDebit) - Number(openingBalance))
			amountObj['openingBalance'] = Number(openingBalance)
			amountObj['openingBalanceType'] = openingBalanceType
			amountObj['type'] = 'CR.'
			if (amountObj['diff'] < 0) {
				amountObj['type'] = 'DR.'
			}
			amountObj['diff'] = Math.abs(amountObj['diff']) 
		}
		return amountObj
	}

	openViewMode(reference, referenceType) {
		if (reference != undefined) {
			let url = '#/transactions/'
			let viewMode = 'view'
			let isInvoice = false
			let isMsInvoice = false
			let isGstInvoice = false
			let isPayment = false
			let isReceipt = false
			let isAdmAcm = false
			let isJv = false
			let isCreditNote = false
			let isTourInvoice = false
			let isMisc = false
			let isConsolidateInovoice = false
			
			if (referenceType == 'invoice') {
				url = '#/transactions/invoice'
				viewMode = 'view'
				if( !reference.includes('MIN') && !reference.includes('MZC') && !reference.includes('GSP') && 
					!reference.includes('GSS') &&  !reference.includes('TPS') && !reference.includes('TPR') &&
					!reference.includes('CI')
				) {
					isInvoice = true 
				} 
			}
			if (referenceType == 'invoice' && reference.includes('CI')) {
				url = `#/transactions/consolidate-invoice/inv/view/${reference}`
				viewMode = 'view'
				isConsolidateInovoice = true
			}
			if (referenceType == 'invoice' && (reference.includes('MIN') || reference.includes('MZC'))) {
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
				if(!reference.includes('GSP') && !reference.includes('GSS')) {
					isMsInvoice = true
				} 
			}
			if (referenceType == 'invoice' && (reference.includes('GSP') || reference.includes('GSS'))) {
				url = '#/transactions/ms/gst-invoice'
				viewMode = 'view'
				if(!reference.includes('MIN') && !reference.includes('MZC')) {
					isGstInvoice = true
				} 
			}
			if (referenceType == 'invoice' && (reference.includes('TPS') || reference.includes('TPR'))) {
				url = '#/transactions/tour/save/sales'
				viewMode = 'view'
				isTourInvoice = true
			}
			if (referenceType == 'credit_note'  &&  reference.includes('MZC')) {
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
				isMsInvoice =  true
			}
			if (referenceType == 'credit_note') {
				url = '#/transactions/credit-note'
				viewMode = 'view'
				isCreditNote =  true
			}
			if (referenceType == 'payment') {
				url = '#/transactions/payment'
				viewMode = 'view'
				isPayment = true
			}
			if (referenceType == 'receipt') {
				url = '#/transactions/receipt'
				viewMode = 'view'
				isReceipt = true
			}
			if (referenceType == 'jv') {
				url = '#/transactions/jv'
				viewMode = 'update'
				isJv = true
			}
			if (referenceType == 'adm' || referenceType == 'acm') {
				url = '#/transactions/adm-acm'
				viewMode = 'view'
				isAdmAcm = true
			}
			if (referenceType == 'misc-invoice') {
				url = '#/transactions/ms/misc-invoice'
				viewMode = 'view'
				isMisc = true
			}
			let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${url}/${viewMode}/${reference}`
			if(window.location.protocol != 'file:'){
				    let title =''
				    let referenceSplit = reference.split('-')
					title += referenceSplit[0]
					title +='...'
					title += referenceSplit[referenceSplit.length-1]
				    if(isInvoice) {
						this.tabService.addTab(
							new Tab(InvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' },reference)
							);
					}else if(isMisc) {
						this.tabService.addTab(
							new Tab(MiscInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
							);
					} else if(isConsolidateInovoice) {
						this.tabService.addTab(
							new Tab(ConsolidateInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
							);
					} else if(isMsInvoice) {
						this.tabService.addTab(
							new Tab(MsInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
							);
					} else if(isCreditNote) {
						this.tabService.addTab(
							new Tab(InvoiceRefundComponent, `${title}`, { creditNoteNumber: reference, mode : 'view' }, reference)
						);
					} else if(isGstInvoice) {
						this.tabService.addTab(
							new Tab(GstInvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
							);
					} else if(isPayment) {
						this.tabService.addTab(
							new Tab(V2CreatePaymentComponent, `${title}`, { paymentRef: reference ,mode : 'view' }, reference)
							);
					}
					else if(isReceipt) {
						this.tabService.addTab(
							new Tab(V2CreateReceiptComponent, `${title}`, { receiptRef: reference ,mode : 'view' }, reference)
							);
					} else if(isAdmAcm) {
						this.tabService.addTab(
							new Tab(CreateAdmAcmComponent, `${title}`, { adm_acm_number: reference, mode : 'view' }, reference)
						);
					} else if(isJv) {
						this.tabService.addTab(
							new Tab(AddEntryJvComponent, `${title}`, { jv_code: reference }, reference)
							);
					} else if(isTourInvoice) {
						this.tabService.addTab(
							new Tab(TourComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
							);
					} else {
						window.open(path,'_blank')		
				}
			}else{
				var customeEventHandler = new CustomEvent("handle-window-open", {
					"detail": {
						hashPath : path
					}
				  });
				window.dispatchEvent(customeEventHandler)

			}
		}
	}

	focusOnTableHeader() :void{
		if (this.tableHeader != undefined) {
			this.tableHeader.nativeElement.focus()
		}
	}

	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`).focus()
			this.childIndex = this.childIndex+1
		}else{
			if (this.parentIndex < this.leaderData['all_clients_records'][0].client_records.length -1) {
				this.parentIndex = this.parentIndex+1
				this.childIndex = -1
			}
			if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`).focus()
				this.childIndex = this.childIndex+1
			}else if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`).focus()
				this.childIndex = this.childIndex+1
			}
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`).focus()
			this.childIndex = this.childIndex-1
		}else{
			if (this.parentIndex > 0) {
				this.parentIndex = this.parentIndex-1
				if (this.leaderData['all_clients_records'][0].client_records[this.parentIndex] != undefined) {
					this.childIndex = this.leaderData['all_clients_records'][0].client_records[this.parentIndex].records.length
				}
			}else{
				this.childIndex = 0
			}
			if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`).focus()
				if (this.childIndex >0) {
					this.childIndex = this.childIndex-1
				}
			}else if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`).focus()
				if (this.childIndex >0) {
					this.childIndex = this.childIndex-1
				}
			}
		}
	}
	
	moveToIndex(parentIndex,childIndex){
		document.getElementById(`rowIndex_${parentIndex}_${childIndex}`).focus()
		this.parentIndex = parentIndex
		this.childIndex = childIndex
	}

	dataLoadedFn(i){
		if(!this.dataloaded){
			this.dataloaded = true
			try {
				setTimeout(() => {
					document.querySelector("#print-section") ? document.querySelector("#print-section").classList.add("voucher-container") : null
				}, 1000);
			} catch (error) {
				
			}
		}
	}

	
	downloadInv() {
		let invArr = this.allLedgerArr.filter((el) =>el.isAdjustBefore!=true)
		this.commonService.postRequest(`${environment.okountUrl}/reports/unpaid-report/client/invoice/download`, {"invArr":invArr,...this.queryParams}).subscribe((res:any) =>{
			this.showMessage = true;
			this.alertType = 'success-box'
			this.message = res.message
			
			setTimeout(() => {
				this.showMessage = false;
				let redirectUrl = `${environment.baseAccountUrl}/v2/#/report/schedule-reports/${res.channel_name}`
				window.open(redirectUrl, '_blank');
			}, 1000)
 
	   })
	}
}

