import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceServicesService } from '../flight/services/invoice-services.service';
import { LoaderService } from 'src/app/shared/loader.service';
import { NumberToWordsPipe } from 'src/app/shared/pipe/print-invoice.pipe';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import {  FormBuilder } from '@angular/forms';
import { async } from '@angular/core/testing';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver'; 

@Component({
	selector: 'app-view-invoice',
	templateUrl: './view-invoice.component.html',
	styleUrls: ['./view-invoice.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ViewInvoiceComponent implements OnInit {
	editReceiptentData: boolean = false;
	invoiceData: any;
	productData: any;
	accountList: any;
	displayBillingDetails : any
	updateBillingDetailsForm : any
	handlingCharges: any = 0;
	tdsRecv: any = 0;
	round_off: any = 0;
	showBox;
	netReceived: any;
	serviceCharge: any;
	totalInwords: any;
	totalInsurancePremium = 0;
	flightTotalReceived = 0;
	@Input() reference_code;
	@Input() preFetchedData;
	@Output() callSaveFetchedData: EventEmitter<any> = new EventEmitter()
	searchId: any;
	type: any;
	baseFare = 0
	yq = 0
	yr = 0
	k3 = 0
	oc = 0
	ot = 0
	baggage = 0
	additionalBaggage = 0
	ancillary = 0
	meal = 0
	seat = 0
	totalAmount = 0;
	subTotalFare = 0
	isLoggedIn: any;
	isValid: boolean;
	ssrType: any;
	waterMark;
	preview : boolean = true
	nativeLanguage: any;
	@ViewChild('openBillingDetailsModal', { static: true }) openBillingDetailsModal: ElementRef
	showPartyName: boolean = true;
	currency_rate;
	currency_code;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private loaderService: LoaderService,
		private invoiceService: InvoiceServicesService,
		public commonService: CommonServiceService,
		private fb: FormBuilder,
		public sanitizer: DomSanitizer
	) {
		this.sanitizer = sanitizer; 
	 }

	ngOnInit() {
		this.loaderService.show()
		this.searchId = this.route.snapshot.params['searchId'];
		this.currency_rate = this.route.snapshot.params['currency_rate'];
		this.currency_code = this.route.snapshot.params['sales_currency'];

		this.ssrType = this.route.snapshot.queryParams['ssrType'];
		this.updateBillingDetailsForm = this.fb.group({
			"invoice_no": [this.searchId],
			"display_billing_details": [''],
			'inv_in_native_lang': [false]
		})

		let queryParams = this.route.queryParams['value'];
		if(queryParams && queryParams.hide_party && queryParams.hide_party == 'true') {
			this.showPartyName = false;
		}

		this.isLoggedIn = localStorage.okountUserInfo ? true : false
		
		let retrievedData = JSON.parse(localStorage.getItem('retrievedData'))
		if(retrievedData && this.ssrType) {
			this.isValid = true
			this.bindRetrievalData(retrievedData)
			this.bindTotalFareTraveller(this.productData && this.productData.json_doc && this.productData.json_doc.travellers)
			
			let numberToBeConverted = Number(this.subTotalFare)

			this.totalInwords = ""
			if (numberToBeConverted < 0) {
				numberToBeConverted = numberToBeConverted * -1
				this.totalInwords = "Minus "
			}

			this.productData.json_doc['total_fare'] = {
				"client_service_charges": 0,
				"tds_recieved": 0,
				"commission_paid": 0,
				"round_off": 0,
				"gst_received": 0,
			}
			this.netReceived = Number(this.subTotalFare)
		    this.totalInwords += new NumberToWordsPipe().transform(Number(numberToBeConverted).toFixed(0))
			return
		} 
		
		if(!this.preFetchedData){
			if(this.reference_code){
				this.searchId = this.reference_code
				this.isLoggedIn = false
			}
			if (this.searchId != undefined) {
				document.title = `Invoice - ${this.searchId}`
			}
			this.type = this.route.snapshot.params['type'];
			this.getInvoiceData(this.searchId, this.type ? this.type : 'flight', this.currency_rate, this.currency_code);
		}
		else {
			this.isLoggedIn = false
			this.invoiceData = this.preFetchedData
			this.bindData()
		}
		this.getAccountingCompany()
	}
	getInvoiceData(searchId: any, type: any, currency_rate: any, currency_code:any) {
		try {
			let inv_in_native_lang = this.updateBillingDetailsForm.controls.inv_in_native_lang.value;
			let url = `${environment.okountUrl}/invoice/download?invoiceNumber=${searchId}&preview=${this.preview}`
			if(currency_rate && currency_code){
				url += `&currency_rate=${currency_rate}&currency_code=${currency_code}`
			}
			if(inv_in_native_lang) {
				 url += `&inv_in_native_lang=${inv_in_native_lang}`
			}
			this.commonService.getRequest(url).subscribe(async (res) => {
				this.invoiceData = this.transform(res)
				this.loaderService.hide()
				try {
					this.callSaveFetchedData.emit({
						data : this.invoiceData,
						invoice_no : searchId
					})
				} catch (error) {
					console.log(error)
				}
			},
				err => {
					this.loaderService.hide()
				})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
		}
	}

	downloadPdf(searchId: any) {

		let inv_in_native_lang = this.updateBillingDetailsForm.controls.inv_in_native_lang.value;
		let url = `${environment.okountUrl}/invoice/download?invoiceNumber=${searchId}&preview=false&inv_in_native_lang=${inv_in_native_lang}`
		if(this.currency_rate && this.currency_code){
			url += `&currency_rate=${this.currency_rate}&currency_code=${this.currency_code}`
		}
		this.commonService.downloadPDF(url, null).subscribe((fileBlob:any) =>{
			let fileName = `Invoice-${this.searchId}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}
	

	transform(html) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}
	getSubTotalFare(travellers : any) {
		for(const traveller of travellers){
			this.baggage+=Number(this.getSSRamount(traveller.ssr,'baggage'))
			this.additionalBaggage+=Number(this.getSSRamount(traveller.ssr,'additionalBaggage'))
			this.ancillary+=Number(this.getSSRamount(traveller.ssr,'ancillary'))
			this.meal+=Number(this.getSSRamount(traveller.ssr,'meal'))
			this.seat+=Number(this.getSSRamount(traveller.ssr,'seat'))
			this.subTotalFare+=this.getTotal(traveller)
		}
	}

	bindData(){
		
		// this.updateBillingDetailsForm.controls.invoice_no.setValue(this.invoiceData.invoice_no)
		if(this.invoiceData.display_billing_details !== null){
			this.displayBillingDetails = this.invoiceData.display_billing_details.split(',').join('\n')
			this.updateBillingDetailsForm.controls.display_billing_details.setValue(this.invoiceData.display_billing_details.split(',').join('\n'))
		}
		this.isValid = true
		this.productData = this.invoiceData.products[0]
		this.bindTotalFareTraveller(this.productData?.json_doc.travellers)
		this.handlingCharges = this.productData.net_commission ? this.productData.net_commission : 0
		this.tdsRecv = 	this.productData.tds ? this.productData.tds : 0
		this.serviceCharge = 	this.productData.service_charge ? this.productData.service_charge : 0
		this.flightTotalReceived = this.productData.json_doc.total_fare.net_received ? 	this.productData.json_doc.total_fare.net_received  : 0
		this.round_off = 	this.productData.round_off ? 	this.productData.round_off : 0
		if(this.productData.json_doc.has_insurance){
			this.totalInsurancePremium = this.productData.json_doc.insurance_premium
		}
		this.netReceived = Number(this.flightTotalReceived) + this.totalInsurancePremium
		let numberToBeConverted = Number(this.netReceived)
		this.totalInwords = ""
		if (numberToBeConverted < 0) {
			numberToBeConverted = numberToBeConverted * -1
			this.totalInwords = "Minus "
		}
		this.totalInwords += new NumberToWordsPipe().transform(Number(numberToBeConverted).toFixed(0))
		if(this.invoiceData?.accounting_company?.tenant_logo){
			this.waterMark = `url('${this.invoiceData?.accounting_company?.tenant_logo}')`
		}
		
		try {
			setTimeout(() => {
				document.querySelector("#print-section").classList.add("voucher-container")
			}, 1000);
		} catch (error) {
			
		}
	}	

	bindTotalFareTraveller(data){
		for (const traveller of data) {
			
			this.baseFare+=Number(this.getBase(traveller?.fare.base , traveller?.service_charge?.customer))
			this.yq+=Number(this.getYQ(traveller?.fare.YQ , traveller?.service_charge?.customer))
			this.yr+=Number(this.getYR(traveller?.fare.YR , traveller?.service_charge?.customer))
			this.k3+=Number(this.getK3(traveller?.fare.K3 , traveller?.service_charge?.customer))
			this.oc+=Number(this.getOC(traveller?.fare.OC , traveller?.service_charge?.customer))
			this.ot += this.getOT(traveller?.fare.OT , traveller?.service_charge?.customer)
			this.baggage+=this.getSSRamount(traveller.ssr,'baggage')
			this.additionalBaggage+=this.getSSRamount(traveller.ssr,'additionalBaggage')
			this.ancillary+=this.getSSRamount(traveller.ssr,'ancillary')
			this.meal+=this.getSSRamount(traveller.ssr,'meal')
			this.seat+=this.getSSRamount(traveller.ssr,'seat')
			this.subTotalFare+=this.getTotal(traveller)
			
	  }
		

	}
	
	getNumberToWord(value) {
		if (value != undefined) {
			return new NumberToWordsPipe().transform(Number(value).toFixed(2))
		}
	}
	getSSRamount(ssrs, type) {
		if (ssrs != undefined) {
			let mealAmount = 0
			let seatAmount = 0
			let baggageAmount = 0
			let additionalBaggageAmount = 0
			let ancillaryAmount = 0
			for (let i = 0; i < ssrs.length; i++) {
				const ssr = ssrs[i];
				mealAmount += Number(ssr.meal.amount)
				baggageAmount += Number(ssr.baggage.amount)
				additionalBaggageAmount += Number(ssr.additionalBaggage ? ssr.additionalBaggage.amount : 0)
				ancillaryAmount += Number(ssr.ancillary ? ssr.ancillary.amount : 0)
				seatAmount += Number(ssr.seat.amount)
			}
			if (type == 'seat') {
				return seatAmount
			} else if (type == 'meal') {
				return mealAmount
			} else if (type == 'baggage') {
				return baggageAmount
			}else if (type == 'additionalBaggage') {
				return additionalBaggageAmount
			}else if (type == 'ancillary') {
				return ancillaryAmount
			}
		}
	}

	
	getBase(BASE, serviceCharge) {
		let scAmount = 0
		if(serviceCharge.sc2_type =='BASE'){
			scAmount+=Number(serviceCharge.sc2_value)
		}
		if(serviceCharge.sc3_type =='BASE'){
			scAmount+=Number(serviceCharge.sc3_value)
		}
		return Number(BASE) + (scAmount  ? Number(scAmount) : 0);
	}
	getYQ(YQ, serviceCharge) {
		let scAmount = 0
		if(serviceCharge.sc2_type =='YQ'){
			scAmount+=Number(serviceCharge.sc2_value)
		}
		if(serviceCharge.sc3_type =='YQ'){
			scAmount+=Number(serviceCharge.sc3_value)
		}
		return Number(YQ) + (scAmount  ? Number(scAmount) : 0);
	}
	getYR(YR, serviceCharge) {
		let scAmount = 0
		if(serviceCharge.sc2_type =='YR'){
			scAmount+=Number(serviceCharge.sc2_value)
		}
		if(serviceCharge.sc3_type =='YR'){
			scAmount+=Number(serviceCharge.sc3_value)
		}
		return Number(YR) + (scAmount  ? Number(scAmount) : 0);
	}
	getK3(K3, serviceCharge) {
		let scAmount = 0
		if(serviceCharge.sc2_type =='K3'){
			scAmount+=Number(serviceCharge.sc2_value)
		}
		if(serviceCharge.sc3_type =='K3'){
			scAmount+=Number(serviceCharge.sc3_value)
		}
		return Number(K3) + (scAmount  ? Number(scAmount) : 0);
	}
	getOC(OC, serviceCharge) {
		let scAmount = 0
		if(serviceCharge.sc2_type =='OC'){
			scAmount+=Number(serviceCharge.sc2_value)
		}
		if(serviceCharge.sc3_type =='OC'){
			scAmount+=Number(serviceCharge.sc3_value)
		}
		return Number(OC) + (scAmount  ? Number(scAmount) : 0);
	}
	
	

	getOT(ot, serviceCharge) {
		let scAmount = 0
		if(serviceCharge.sc2_type == 'N' || serviceCharge.sc2_type == 'OT'){
			scAmount+=Number(serviceCharge.sc2_value)
		}
		if(serviceCharge.sc3_type =='N' || serviceCharge.sc3_type =='OT'){
			scAmount+=Number(serviceCharge.sc3_value)
		}
		return Number(ot) + (scAmount  ? Number(scAmount) : 0);
	}
	getTotal(traveller) {
		const base = this.getBase(traveller?.fare.base , traveller?.service_charge?.customer)
		const YQ = this.getYQ(traveller?.fare.YQ , traveller?.service_charge?.customer)
		const YR = this.getYR(traveller?.fare.YR , traveller?.service_charge?.customer)
		const K3 = this.getK3(traveller?.fare.K3 , traveller?.service_charge?.customer)
		const OC = this.getOC(traveller?.fare.OC , traveller?.service_charge?.customer)
		const OT = this.getOT(traveller?.fare.OT , traveller?.service_charge?.customer)
		const baggage = this.getSSRamount(traveller.ssr,'baggage')
		const additionalBaggage = this.getSSRamount(traveller.ssr,'additionalBaggage')
		const ancillary = this.getSSRamount(traveller.ssr,'ancillary')
		const meal = this.getSSRamount(traveller.ssr,'meal')
		const seat = this.getSSRamount(traveller.ssr,'seat')
		const fare = base + YQ + YR + K3 + OC + OT + baggage + meal + seat + additionalBaggage + ancillary
		return fare ;
	}
	updateDisplayBilling(){
		let reqObj = {
			invoice_no : this.updateBillingDetailsForm.value.invoice_no,
			display_billing_details : this.updateBillingDetailsForm.value.display_billing_details
		}
		
		this.displayBillingDetails = 'updating...'
		this.commonService.putRequest(`${environment.okountUrl}/invoice/display_billing_details`,reqObj).subscribe(async(res: any) =>{
			this.openBillingDetailsModal.nativeElement.click()
			this.getInvoiceData(this.searchId, this.type ? this.type : 'flight',  this.currency_rate, this.currency_code)
				
		},err =>{
			console.log(err)
		})
	}
	printInvoice() {
		window.print()
		
	}

	editReceipt(){
		this.editReceiptentData = true;
	}

	openBillingDetails() {
		this.openBillingDetailsModal.nativeElement.click();
	}


	bindRetrievalData(data) {
		let obj = {}
		let clientData = JSON.parse(localStorage.getItem("affiliateArr"))
		clientData = clientData[0]
		obj['invoice_no'] = ""
		obj['client'] = clientData
		obj['products'] = [{
			"json_doc" : {
				"travellers": this.bindTravellers(data, this.ssrType),
				"sectors": this.bindSectors(data)
			}
		}]

		this.invoiceData = obj
		this.productData = this.invoiceData  && this.invoiceData.products && this.invoiceData.products.length>0 &&  this.invoiceData.products[0]
	}


	bindSectors(data) {
		let sectorArr = []
		let sectors = data.sector
		for(let sector of sectors) {
			let departure = moment(sector.departureDateTime)
			let arrival = moment(sector.arrivalDateTime)
			let duration = moment.duration(arrival.diff(departure))
			let durationType = duration.asMinutes()

			let obj = this.bindAirportDetails(sector)
			sectorArr.push({
				"pnr": data.pnr,
				"duration": durationType,
				"departure_airport_details": obj.departure_airport_details,
				"arrival_airport_details": obj.arrival_airport_details,
				"legs": this.bindLegs(data, sector)
			})
		}
		
		return sectorArr
	}


	bindLegs(data, sector) {
		let legs = {}

		let departure = moment(sector.departureDateTime)
		let arrival = moment(sector.arrivalDateTime)
		let duration = moment.duration(arrival.diff(departure))
		let durationType = duration.asMinutes()

		let obj = this.bindAirportDetails(sector)
		legs["airline_name"] = data.airline && data.airline.account_name ? data.airline.account_name : "",
		legs["airline_code"] = sector && sector.airline ? sector.airline : "",
		legs["flight_no"] = sector && sector.flightNo ? sector.flightNo : "",
		legs["service_class"] = sector.bookingClass ? sector.bookingClass : "",
		legs["airline_pnr"] = sector.airlinePnr ? sector.airlinePnr : "",
		legs["duration"] = durationType,
		legs["departure_airport_details"] = obj.departure_airport_details,
		legs["arrival_airport_details"] = obj.arrival_airport_details

		return legs
	}


	bindAirportDetails(sector) {
		console.log("Sector", sector)
		let departure_airport_details = {
			"code": sector.name.split("/")[0],
			"city": sector.departureCity ? sector.departureCity : "" ,
			"name": sector.departureAirport ?  sector.departureAirport : "",
			"terminal": sector.departureTernimal ? sector.departureTernimal : "",
			"date_time":sector.departureDateTime ? sector.departureDateTime : ""
		} 
		let arrival_airport_details = {
			"code": sector.name.split("/")[1],
			"city": sector.arrivalCity ?  sector.arrivalCity : "",
			"name": sector.arrivalAirport ?  sector.arrivalAirport : "",
			"terminal": sector.arrivalTernimal ? sector.arrivalTernimal : "",
			"date_time": sector.arrivalDateTime ? sector.arrivalDateTime : ""
		}

		let obj = {
			"departure_airport_details": departure_airport_details,
			"arrival_airport_details": arrival_airport_details
		}
		return obj
	}

	bindTravellers (data, ssrType) {
		let travellersArr = []
		let travellers = data.traveller
		for(let travellerIndex in travellers) {
			let charges = this.bindCommission()

			let ssrData = data && data['ssrResponse'] && data['ssrResponse']['ssrs'] ? data['ssrResponse']['ssrs'] : []
			
			let ticket_no = ''
			if(ssrData) {
				for(let ssrObj of ssrData) {
					if(ssrType == 'meals') {
						if(ssrObj && ssrObj.meals && ssrObj.meals.length > 0) {
							let traveller = ssrObj.meals.find(meal => meal.travellerNumber == travellerIndex)
							 ticket_no = traveller['ticketNumber'] ? traveller['ticketNumber'] : ''
						}				
					} else if(ssrType == 'seats') {
						if(ssrObj && ssrObj.seats && ssrObj.seats.length > 0) {
							let traveller = ssrObj.seats.find(seat => seat.travellerNumber == travellerIndex)
							 ticket_no = traveller['ticketNumber'] ? traveller['ticketNumber'] : ''
						}
					} else if(ssrType == 'baggages') {
						if(ssrObj && ssrObj.baggages && ssrObj.baggages.length > 0) {
							let traveller = ssrObj.baggages.find(baggage => baggage.travellerNumber == travellerIndex)
							ticket_no = traveller['ticketNumber'] ? traveller['ticketNumber'] : ''
						}
					}
				}
			}
	

			travellersArr.push({
				"title": travellers[travellerIndex].title ? travellers[travellerIndex].title : "", 
				"type" : travellers[travellerIndex].type ? travellers[travellerIndex].type : "",
				"name": travellers[travellerIndex].name ? travellers[travellerIndex].name : "",
				"ticket_no": ticket_no ? ticket_no : '',
				"isConjuction": false,
				"fare": {
					"total": 0,
					"base": 0,
					"YQ": 0,
					"YR": 0,
					"K3": 0,
					"OT": 0,
					"OC": 0,
					"markup": 0,
					"service_charge": 0,
					"gst_on_service_charge": 0
				},
				"commission" : charges.commission,
				"service_charge": charges.service_charge,
				"dis_recall": charges.dis_recall,
				"ssr": this.bindSSR(ssrData,travellerIndex, ssrType)
			})
		}

		return travellersArr
	}

	bindSSR(requestedSsrs, travellerIndex, ssrType) {
		var ssrs = [];
		for (const requestedSsr of requestedSsrs) {
			var ssr = {
				"seat": ssrType == 'seats' ? this.getSsrDetails(requestedSsr.seats, travellerIndex, ssrType) : {"code": "","amount": 0},
				"meal": ssrType == 'meals' ? this.getSsrDetails(requestedSsr.meals, travellerIndex, ssrType) :  {"code": "","amount": 0},
				"baggage": ssrType == 'baggages' ? this.getSsrDetails(requestedSsr.baggages, travellerIndex, ssrType) : {"code": "","amount": 0},
				"additionalBaggage": {"code": "","amount": 0},
				"ancillary": {"code": "","amount": 0}
			}
			ssrs.push(ssr);
		}
		return ssrs;
	}
	
	getSsrDetails(ssrsOfType, travellerIndex, ssrType) {
		var trvSsr = {
			"code": "",
			"amount": 0
		}

		let SsrArr = ssrsOfType && ssrsOfType.filter(ssr => ssr.travellerNumber == travellerIndex)

		if(SsrArr && SsrArr.length>0) {
			let ssrCodes = []
			let ssrAmount = 0
			for(let ssr of SsrArr) {
				if (ssr && ssr['travellerNumber'] == travellerIndex) {
					if(ssrType != 'seats') {
						ssrCodes.push(ssr.code)
					    ssrAmount += Number(ssr.price)
					}else {
						ssrCodes.push(ssr.number)
					    ssrAmount += Number(ssr.price)
					}
				}	
			}  
			trvSsr.code = ssrCodes.join(', ')
			trvSsr.amount = Number(ssrAmount)
		}
		return trvSsr;
	}
	
	bindCommission() {
	let travellerCommission = {
			"commission": {
				"customer": {
					"iata_type": "RB",
					"iata_value": "0",
					"iata_amount": 0,
					"plb_type": "RB",
					"plb_value": "0",
					"plb_amount": 0,
					"cashback_type": "VL",
					"cashback_value": "0",
					"cashback_amount": 0,
					"discount_type": "VL",
					"discount_value": "0",
					"discount_amount": 0
				},
				"supplier": {
					"iata_type": "RB",
					"iata_value": 0,
					"iata_amount": 0,
					"plb_type": "RB",
					"plb_value": 0,
					"plb_amount": 0,
					"cashback_type": "RB",
					"cashback_value": 0,
					"cashback_amount": 0,
					"discount_type": "VL",
					"discount_value": 0,
					"discount_amount": 0
				}
			},
			"service_charge": {
				"customer": {
					"sc1_type": "T",
					"sc1_value": 5,
					"sc1_amount": 5,
					"sc2_type": "N",
					"sc2_value": 0,
					"sc2_amount": 0,
					"sc3_type": "T",
					"sc3_value": "0",
					"sc3_amount": "0"
				},
				"supplier": {
					"sc1_type": "T",
					"sc1_value": "0",
					"sc1_amount": "0",
					"sc2_type": "N",
					"sc2_value": "0",
					"sc2_amount": "0",
					"sc3_type": "N",
					"sc3_value": "0",
					"sc3_amount": "0"
				}
			},
			"dis_recall": {
				"customer": {
					"dis_recall_type": "",
					"dis_recall_value": 18,
					"dis_recall_amount": "0"
				},
				"supplier": {
					"dis_recall_type": "",
					"dis_recall_value": 0,
					"dis_recall_amount": "0"
				}
			}
		}

		return travellerCommission
	}

	selectLanguage(event) {
		this.getInvoiceData(this.searchId,  this.type ? this.type : 'flight',  this.currency_rate, this.currency_code)
	}

	getAccountingCompany() {
		try {
			this.invoiceService.getMethod('util/accounting-company').subscribe(async (res: any) => {
				if(res && res.native_language_code) {
					this.nativeLanguage =  res.native_language_code;
				}
			},
			err => {
			})
		} catch (error) {
			console.log('Error occured in get company due to : ', error)
		}
	}
}


