import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PaymentService } from '../services/payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment'
import { CommonServiceService } from 'src/app/shared/common-service.service';
@Component({
  selector: 'app-create-payment',
  templateUrl: './create-payment.component.html',
  styleUrls: ['./create-payment.component.scss']
})
export class CreatePaymentComponent implements OnInit {
  submitResponse: any;
	generalMasterList: any;
	showMessage: boolean = false;
	alertType = '';
	message: string = ``;
	@ViewChild('saveButton',{static:true}) saveButton : ElementRef;
	constructor(
		public paymentService: PaymentService,
		private spinner: NgxSpinnerService,
		public commonService: CommonServiceService
		// private fb: FormBuilder
	) { }

	ngOnInit() {
		// this.addRecord(true,this.paymentService.paymentForm.value) /** spinner starts on init */
		this.spinner.show();

		setTimeout(() => {
			/** spinner ends after 5 seconds */
			this.spinner.hide();
		}, 1000);
		// this.getEntries()

    this.getGeneralMaster('general/bank')
    this.paymentService.paymentForm.reset()
    this.paymentService.paymentEntries = []
    this.paymentService.paymentForm.controls.accountCodeFrom.setValue('')
    this.paymentService.paymentForm.controls.accountCodeType.setValue('general')
    this.paymentService.paymentForm.controls.payment_id.setValue('')
    this.paymentService.paymentForm.controls.paymentDate.setValue(moment().format('YYYY-MM-DD'))

	}



	addRecord(status, formValue) {
	this.hideError()
    this.spinner.show();
    this.paymentService.isEdit = false
		let userInfo = JSON.parse(localStorage.okountUserInfo)
		let addPaymentObj = {
			"account_code_from": formValue.accountCodeFrom,
			"account_code_from_type": formValue.accountCodeType,
			"payment_timestamp": moment(formValue.paymentDate).format('YYYY-MM-DD'),
			"last_modified_by": userInfo.id,
			"last_modified_by_name": userInfo.name
		}
		if (formValue.payment_id == "") {
			this.paymentService.postMethod(`paymentv2`, addPaymentObj).subscribe((res: any) => {
				let response = res
				this.paymentService.showAddPaymentScreen = status
				this.paymentService.paymentForm.controls.payment_id.setValue(response.payment_id)
				this.paymentService.paymentEntryForm.controls.account_code_from.setValue(formValue.accountCodeFrom)
				this.paymentService.paymentEntryForm.controls.account_code_from_type.setValue(formValue.accountCodeType)
				this.spinner.hide();
			},
				err => {
					this.spinner.hide();
				})
		} else {
			this.paymentService.showAddPaymentScreen = status
			this.spinner.hide();
		}
	}


	getEntries() {
		let payment_id = '119'
		if (payment_id != '') {
			this.paymentService.getMethod(`paymententryv2/getallbypaymentid?payment_id=${payment_id}`).subscribe((res: any) => {
				this.paymentService.paymentEntries = res
				this.spinner.hide();
			},
				err => {
					this.spinner.hide();
				})
		} else {
			this.paymentService.paymentEntries = []
		}
	}

	exitEntry(e){
		this.paymentService.showAddPaymentScreen = false
		try {
			this.commonService.selectedInput.next(4)
			this.saveButton.nativeElement.focus()
		} catch (error) {
			this.commonService.selectedInput.next(4)
		}
		
	}

	submit() {
		this.hideError()
		this.spinner.show();
		debugger
		let reqBody = {
			"payment_id": this.paymentService.paymentForm.value.payment_id
		}
		if (reqBody.payment_id != undefined && reqBody.payment_id != "" && reqBody.payment_id != null ) {
			this.paymentService.postMethod(`paymentv2/submit`, reqBody).subscribe(async (res: any) => {
				this.submitResponse = res
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = `Payment created with `
				try {
					debugger
					for (const key in this.paymentService.paymentEntries) {
						if (this.paymentService.paymentEntries.hasOwnProperty(key)) {
							const element = this.paymentService.paymentEntries[key];
							element['invoice_no'] = this.submitResponse[0].payment_code
							element['created_date'] = this.submitResponse[0].payment_timestamp
						}
					}
					// await this.doTransactionEntry(this.paymentService.paymentEntries)
				} catch (error) {
	
				}
				// alert(JSON.stringify(this.submitResponse))
				console.log('Payment Submit response : ', this.submitResponse)
				this.paymentService.paymentForm.reset()
				this.paymentService.paymentEntries = []
				this.paymentService.paymentForm.controls.accountCodeFrom.setValue('')
				this.paymentService.paymentForm.controls.payment_id.setValue('')
				this.paymentService.paymentForm.controls.paymentDate.setValue('')
				this.spinner.hide();
			},
				err => {
					this.showMessage = true
					this.alertType = 'error-box';
					this.message = `Failed to create payment entry.`
					this.spinner.hide();
				})
		} else {
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = `Add Atleat one entry before saving.`
			this.spinner.hide();
		}
	}

	reset() {
		let payment_id = this.paymentService.paymentForm.value.payment_id
		if (payment_id != '') {
			this.paymentService.deleteMethod(`paymentv2?payment_id=${payment_id}`).subscribe((res: any) => {
				this.paymentService.paymentForm.reset()
				this.paymentService.paymentEntries = []
				this.paymentService.paymentForm.controls.accountCodeFrom.setValue('G00002')
				this.paymentService.paymentForm.controls.payment_id.setValue('')
				this.paymentService.paymentForm.controls.paymentDate.setValue('')
				this.spinner.hide();
			},
				err => {
					this.spinner.hide();
				})
		} else {
			this.paymentService.paymentForm.reset()
			this.paymentService.paymentEntries = []
			this.paymentService.paymentForm.controls.accountCodeFrom.setValue('G00002')
			this.paymentService.paymentForm.controls.payment_id.setValue('')
			this.paymentService.paymentForm.controls.paymentDate.setValue('')
		}
	}

	editEntry(payment) {
		this.paymentService.showAddPaymentScreen = true
		this.paymentService.isEdit = true
		localStorage.setItem('selected_payment_entry', payment.payment_entry_id)
		this.paymentService.paymentEntryForm.controls.account_code_from.setValue(payment.account_code_from)
		this.paymentService.paymentEntryForm.controls.account_code_to.setValue(payment.account_code_to)
		this.paymentService.paymentEntryForm.controls.account_code_to_type.setValue(payment.account_code_to_type)
		this.paymentService.paymentEntryForm.controls.cheque_no.setValue(payment.cheque_no)
		if (payment.cheque_date != null && payment.cheque_date != '') {
			this.paymentService.paymentEntryForm.controls.cheque_date.setValue(moment(payment.cheque_date).format('YYYY-MM-DD'))
		}
		this.paymentService.paymentEntryForm.controls.cheque_bank_name.setValue(payment.cheque_bank_name)
		this.paymentService.paymentEntryForm.controls.amount.setValue(Number(payment.amount))
		this.paymentService.paymentEntryForm.controls.narration_1.setValue(payment.narration_1)
		this.paymentService.paymentEntryForm.controls.narration_2.setValue(payment.narration_2)
		this.paymentService.paymentEntryForm.controls.narration_3.setValue(payment.narration_3)
		this.paymentService.paymentEntryForm.controls.narration_4.setValue(payment.narration_4)
		this.paymentService.paymentEntryForm.controls.narration_5.setValue(payment.narration_5)
		this.paymentService.paymentEntryForm.controls.remark_1.setValue(payment.remark_1)
		this.paymentService.paymentEntryForm.controls.remark_2.setValue(payment.remark_2)
		this.paymentService.paymentEntryForm.controls.remark_3.setValue(payment.remark_3)
		this.paymentService.paymentEntryForm.controls.remark_4.setValue(payment.remark_4)
		this.paymentService.paymentEntryForm.controls.remark_5.setValue(payment.remark_4)
		this.paymentService.paymentEntryForm.controls.sub_ledger_code_1_type.setValue(payment.sub_ledger_code_1_type)
		this.paymentService.paymentEntryForm.controls.sub_ledger_code_1.setValue(payment.sub_ledger_code_1)
		this.paymentService.paymentEntryForm.controls.sub_ledger_code_1_name.setValue(payment.sub_ledger_code_1_value ? payment.sub_ledger_code_1_value : '')
		this.paymentService.paymentEntryForm.controls.account_code_from_name.setValue(payment.account_code_from_value)
		this.paymentService.paymentEntryForm.controls.account_code_to_name.setValue(payment.account_code_to_value)
	}

	getGeneralMaster(url) {
		this.paymentService.getMethod(url).subscribe(res => {
			this.generalMasterList = res
		}, err => {

		})
	}

	// doTransactionEntry(entries) {
	// 	this.paymentService.postMethod(`v3/payment`, entries).subscribe(res => {
	// 	}, err => {

	// 	})
	// }
	hideError() {
		this.showMessage = false
	}

}
