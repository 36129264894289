import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MiscInvoiceRoutingModule } from './misc-invoice-routing.module';
import { MiscInvoiceComponent } from './misc-invoice.component';
import { MiscInvoiceFareSummaryComponent } from './misc-invoice-fare-summary/misc-invoice-fare-summary.component';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { MiscInvoiceService} from './services/misc-invoice.service';


@NgModule({
  declarations: [MiscInvoiceComponent, MiscInvoiceFareSummaryComponent],
  imports: [
    CommonModule,
    MiscInvoiceRoutingModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    FormsModule, 
    ReactiveFormsModule,
    KeyboardShortcutsModule.forRoot()
  ],
  exports: [MiscInvoiceComponent],
  providers : [CommonServiceService,MiscInvoiceService]
})
export class MiscInvoiceModule { }
