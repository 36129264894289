import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GstInvoiceComponent } from './gst-invoice/gst-invoice.component';
import { MiscellaneousInvoiceComponent } from './miscellaneous-invoice.component';
import { MsInvoiceComponent } from './ms-invoice/ms-invoice.component';


const routes: Routes = [
	{
		path: 'ms-invoice', component: MsInvoiceComponent
	},
	{
		path: 'ms-invoice/:mode/:invoiceNumber', component: MsInvoiceComponent
	},
	{
		path: 'ms-invoice/:mode/:transactionType/:invoiceType', component: MsInvoiceComponent
	},
	{
		path: 'ms-invoice/:mode/:transactionType/:invoiceType/:invoiceNumber', component: MsInvoiceComponent
	},
	{
		path: 'gst-invoice', component: GstInvoiceComponent
	},
	{
		path: 'gst-invoice/:mode/:invoiceNumber', component: GstInvoiceComponent
	},
	{
		path: 'gst-invoice/:mode/:transactionType/:invoiceType', component: GstInvoiceComponent
	},
	{
		path: 'gst-invoice/:mode/:transactionType/:invoiceType/:invoiceNumber', component: GstInvoiceComponent
	},

	
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MiscellaneousInvoiceRoutingModule { }
