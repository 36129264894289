import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SectorRefundService } from '../services/sector-refund.service';
import { CommonServiceService } from 'src/app/shared/common-service.service';


@Component({
  selector: 'app-total-fare-info-refund',
  templateUrl: './total-fare-info-refund.component.html',
  styleUrls: ['../flight-refund.component.scss']
})
export class TotalFareInfoRefundComponent implements OnInit {
	discount_add: string = "false"
	vatAlternetname: string = 'VAT'

	@Input() sectorService;
	isTenantIndian: boolean = false;

	constructor(
		public commonService: CommonServiceService

	) { }

	async ngOnInit() {
		let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
		
		let inv_config = globalSettingConfig.filter(el=>el.group=='TRANSACTION')
		if(inv_config.length){
			let discount_config = inv_config[0].json_doc.value.filter(el=>el.key=='DISCOUNT')
			if(discount_config.length){
				this.discount_add = discount_config[0].value
			}
		}
		this.isTenantIndian = this.commonService.checkTenantIndian()
		if(!this.isTenantIndian){
			let inv_config = globalSettingConfig.filter(el=>el.group=='VAT')
		if(inv_config.length){
			let vat_config = inv_config[0].json_doc.value.filter(el=>el.key=='vat_rate')
			let vat_detail,vat_name
			if(vat_config.length){
				 vat_detail = vat_config[0].value
			}
			if(vat_detail && vat_detail.length ){
				vat_name = vat_detail.filter(el=>el.key=='effective_name')
			}
			if(vat_name && vat_name.length){
				this.vatAlternetname = vat_name[0].value
			}
			console.log("vatAlternetname",this.vatAlternetname)

		}
		}
	}

	getValue(value){
		console.log(value)
	}

	viewPrintScreen(invoice,i){
		if (invoice != 'Credit Note') {
			if (i==0) {
				if (invoice.includes('CRN')) {
					let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/print-credit-note/${invoice};type=flight`;
			        if(window.location.protocol != 'file:'){
				        window.open(path,'_blank')
			        }else{
				        var customeEventHandler = new CustomEvent("handle-window-open", {
					    "detail": {
						hashPath : path}
				    });
				    window.dispatchEvent(customeEventHandler)
		     	    }
				}else{
					let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/invoice-view;searchId=${invoice};type=flight`;
					if(window.location.protocol != 'file:'){
				        window.open(path,'_blank')
			        }else{
				        var customeEventHandler = new CustomEvent("handle-window-open", {
					    "detail": {
						hashPath : path}
				    });
				    window.dispatchEvent(customeEventHandler)
		     	    }
				}
			}else{
				let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/print-credit-note/${invoice};type=flight`;
				if(window.location.protocol != 'file:'){
					window.open(path,'_blank')
				}else{
					var customeEventHandler = new CustomEvent("handle-window-open", {
					"detail": {
					hashPath : path}
				});
				window.dispatchEvent(customeEventHandler)
				 }
			}
		}
	}

}
