import { AirportDetailModel } from './airport-detail-model';
import { BaggageInfoModel } from './baggage-info-model';

export class LegModel {

    airline_code : string;
    airline_name : string;
    airline_xl_code : string;
    flight_no : string;
    service_class : string;
    airline_pnr : string;
    duration : string;
    departure_airport_details : AirportDetailModel;
    arrival_airport_details : AirportDetailModel;
    baggage_info : BaggageInfoModel;
    
}