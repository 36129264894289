<form [formGroup]="billWiseFilterForm">

	<div class="flexbox aff-info">
		<div class="flexbox aff-info-inner">

			<div class="flexbox mb-3">

				<div class="input-fiel">
					<input class="input-ui" formControlName="start_date" id="from_date" type="date" 
                        min="{{minDate}}" max="{{maxDate}}"
						[ngClass]="{'error-box': !billWiseFilterForm.controls.start_date.valid}"
						searchtabindex="1"   />
					<label for="from_date">Starting Date</label>
				</div>
				<div class="input-fiel">
					<input class="input-ui" formControlName="end_date" id="to_date" type="date" searchtabindex="2"
                        min="{{minDate}}" max="{{maxDate}}"
                        [ngClass]="{'error-box': !billWiseFilterForm.controls.end_date.valid}" />
					<label for="to_date">Ending Date</label>
				</div>
			</div>

            <div class="flexbox mb-3">
                <div class="input-fiel form-group ng-field" searchtabindex="3" (focus)="focusAccountAc()">
                    <label class="ngauto-label" for="affname">Client</label>
                    <div class="ng-autocomplete">
                        <ng-autocomplete [data]="clientArr" #accountAC (inputCleared)="clearData('client')"
                            [searchKeyword]="keyword" (inputChanged)='getClient($event,false)'
                            historyIdentifier="clientArr" [itemTemplate]="itemTemplate" formControlName="client_name"
                            (selected)="selectAccount($event,'client')" [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>
                        <ng-template #itemTemplate let-item>
                            <a innerHTML='<span class="colr-1">{{item.name}}
                                ({{item.account_code}})</span><br />
                            <span class="colr-2">{{item.primary_email}}</span><br />'>
                            </a>
                        </ng-template>
                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div>
                </div>

                <div class="input-fiel form-group ng-field" searchtabindex="4" (focus)="focusAccountAc()">
                    <label class="ngauto-label" for="affname">Supplier</label>
                    <div class="ng-autocomplete">
                        <ng-autocomplete #clientAC [data]="supplierArr" [searchKeyword]="keyword"
                        (inputChanged)='filterSuppliers($event)' historyIdentifier="supplierArr" [itemTemplate]="itemTemplate"
                        formControlName="supplier_name" (selected)="selectSupplierAccount($event)"
                        [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>
        
                        <ng-template #itemTemplate let-item>
                        <a innerHTML='<span class="colr-1">{{item.name}}
                                    ({{item.account_code}})</span><br />
                                <span class="colr-2">{{item.primary_email}}</span><br />'>
                        </a>
                        </ng-template>
                        <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div>
                </div>
            </div>

            <div class="flexBox d-flex mt-1 ml-2 mr-2">
                <div class="input-fiel">
                    <label class="radio-label" for="creditNoteCheckBox">Include Credit Note</label>
                    <input class="radio-ui" id="creditNoteCheckBox" type="checkbox" name="include_credit_note"
                        searchtabindex="5" formControlName="include_credit_note" />
                </div>
            </div>
            

			<span class="report-style-heading border-bottom mr-10  mt-10 mb-10 pb-10">Report Style</span>
			<div class="flexBox d-flex mt-1 ml-2 mr-2">
                <div class="input-fiel">
					<label class="radio-label" for="normalView">Normal</label>
					<input class="radio-ui" id="normalView" type="radio" name="viewMode" value="normal" 
                    [searchtabindex]="6" formControlName="viewMode" />
				</div>
				<div class="input-fiel">
					<label class="radio-label" for="summary">Summary</label>
					<input class="radio-ui" id="summaryView" type="radio" name="viewMode" value="summary"
						[searchtabindex]="7" formControlName="viewMode" />
				</div>
			</div>


			<div class="d-flex mt-5 justify-content-center">
				<div class="input-fiel">
					<button id="submitSearchForm" class="btn-style bg-green mr-3"
						[disabled]="billWiseFilterForm.invalid"
						(click)="search(billWiseFilterForm.value)" searchtabindex="9" #searchButton
						(keyup.enter)="clickSearchButton()"> Search</button>
					<button type="button" class="btn-style bg-green" (click)="reset()">RESET</button>
					<button type="button" hidden class="btn-style" id="resetSearchForm" #closeModal data-dismiss="modal"></button>
				</div>
			</div>

		</div>
	</div>

</form>