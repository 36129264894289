
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
    <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
      <div class="d-flex flex-y-center">
        <img
          src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
            alertType=='success-box' ? 'green-sucess.svg' : 
            alertType=='warning-box' ? 'red-warning.svg' : 
            null }}"
          width="20px"
          height="20px"
          alt="alert-logo"
          class="mr-20"
        />
        <div class="ml-10">
          <h4 class="m-0 text-size-18 text-bold"
          [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
            {{alertType === "error-box"
            ? "Error"
            : alertType === "warning-box"
            ? "Warning"
            : alertType === "success-box"
            ? "Success"
            : null}}
          </h4>
          <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
           {{message}}
          </small>
        </div>
      </div>
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
        alt="close-btn"
        (click)="hideError()"
        className=" close-btn"
      />
    </div>
  </div>
  
  <div class="ledger-container  ">
    <div class="row tab-nav pl-0 pr-0">
        <!-- <div class="col-sm-4 pl-0">
            <ul class="list-inline list-unstyled p-0 m-0">
                <li class="active page-title text-uppercase cursor"> View Client</li>
            </ul>
        </div> -->
        <div class="col-sm-12 ">
          <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
              <li [routerLink]="['/']"
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> Master</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li class="text-grey text-capitalize cursor text-primary transition"> {{selectedMaster}} - {{selectedMasterName}}  Configuration</li>
          </ul>
      </div>
    </div>
    <div class="custom-table mt-3 module-container">
        <!-- <div class="section-title mt-2">
            <div class="head-col d-flex">
                <i class="fa fa-cog mr-3" aria-hidden="true"></i>
                <span class="module-heading">{{selectedMaster}} - {{selectedMasterName}}  Configuration</span>
            </div>
            <div class="search-col">
                <input [(ngModel)]="searchText" class="custom-search" placeholder="Search" id="livefilter-input" type="search"
                    value="">
                <i class="fas fa-search"></i>
            </div>
        </div> -->
        <!-- <table class="table table-bordered w-100 mt-2">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Value</th>
                    <th>Last Modified By</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of groupList | globalFilter : searchText ; let i=index">
                    <td>{{item.policy_display_key}}</td>
                    <td>
                        <ng-container   *ngIf="item.type == 'text'">
                            <input class="inner-input" type="text" [disabled]='i != editIndex' id="index_{{i}}" [value]='item.default'>
                        </ng-container>
                        <ng-container *ngIf="item.type == 'date'">
                            <input class="inner-input"  type="date" />
                        </ng-container>
                        <ng-container *ngIf="item.type == 'multiple'">
                            <select class="inner-input" [disabled]='i != editIndex' id="index_{{i}}" multiple>
                                <ng-container *ngFor="let value of item.values">
                                    <option  [selected]='value == item.default' value='{{value}}'>{{value}}</option>
                                </ng-container>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="item.type == 'dropdown'">
                            <select class="inner-input" [disabled]='i != editIndex' id="index_{{i}}">
                                <ng-container *ngFor="let value of item.values">
                                    <option  [selected]='value == item.default' value='{{value}}'>{{value}}</option>
                                </ng-container>
                            </select>
                        </ng-container>
                    </td>
                    <td>{{item.last_modified_by_name}}</td>
                    <td class="edit-row" >
                        <i class="fa fa-edit mr-3" (click)="edit(i)"></i>
                        <i class="fa fa-check" *ngIf="i == editIndex" (click)="save(i,item)"></i>
                    </td>
                </tr>
            </tbody>
        </table> -->
        <div class="outer-box mt-5 bg-white p-3" style="border: 1px solid #ededed; height:calc(100vh - 160px);overflow-y: auto;">
            <table width="100%" class="table configtable" cellpadding="0" cellspacing="0" [formGroup]="configForm">
                <thead class="configthead">
                    <tr>
                        <th>Parameter Name</th>
                        <th>Type</th>
                        <th>Value</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody formArrayName="configKeyList" class="configtbody">
                    <ng-container *ngFor="let parentConfig of DynamicFormControls.controls; let i = index">
                        <ng-container [formGroupName]="i">
                            <tr class="tr-border" style="line-height: 2.5; " >
                                <td class="font-weight-bold " style="border-top: 0px !important; text-align: left;"> {{parentConfig.value.key_name}} </td>
                                <td>
                                    <select class="inner-input" formControlName="type">
                                        <option value="object">Object</option>
                                        <option value="text">Text</option>
                                        <option value="date">Date</option>
                                        <option value="option">Option</option>
                                    </select>
                                </td>
                                <td style="border-right: 0px !important;" style="border-top: 0px !important; text-align: left;">
                                    <ng-container   *ngIf="parentConfig.value.type == 'text'">
                                        <input class="inner-input" type="text" id="index_{{i}}" formControlName="value">
                                    </ng-container>
                                 
                                    <ng-container *ngIf="parentConfig.value.type == 'date'" formControlName="value">
                                        <input class="inner-input"  type="date" />
                                    </ng-container>
    
                                    <ng-container *ngIf="parentConfig.value.type == 'option'">

                                        <select class="inner-input" id="index_{{i}}" formControlName="value">
                                            <ng-container *ngFor="let value of  parentConfig.value.options; let i = index;">
                                                <option   value='{{value}}'>{{value}}</option>
                                            </ng-container>
                                        </select>
                                    </ng-container>
    
                                    <ng-container *ngIf="parentConfig.value.type == 'object'">
                                            <table class="table w-100 m-0 inside-t subConfigtable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="border-right: 0px !important;">Key </th>
                                                        <th scope="col" style="border-right: 0px !important;">Type</th>
                                                        <th scope="col" style="border-right: 0px !important;">Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody formArrayName="childList">
                                                    <ng-container *ngFor="let childConfig of parentConfig.controls.childList.controls; let j = index">
                                                        <ng-container [formGroupName]="j">
                                                            <tr >
                                                                <td scope="row" style="border-right: 0px !important;vertical-align: middle;">{{childConfig.value.key_name}}</td>
                                                                <td style="border-right: 0px !important;vertical-align: middle;">
                                                                    <select class="inner-input" formControlName="type">
                                                                        <option value="text">Text</option>
                                                                        <option value="date">Date</option>
                                                                    </select>
                                                                </td>
                                                                <td style="border-right: 0px !important;vertical-align: middle;">
                                                                   <ng-container *ngIf="childConfig.value.type == 'text'">
                                                                    <input class="inner-input" type="text" formControlName="value" >
                                                                   </ng-container>
                                                                   <ng-container *ngIf="childConfig.value.type == 'date'">
                                                                    <input class="inner-input" type="date" formControlName="value" >
                                                                   </ng-container>
                                                                </td>

                                                               
                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>
                                                    <!-- <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <button class="btn btn-info btn-sm" (click)="openChildKeyModal(parentConfig)" >Add Key</button>
                                                        </td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                    </ng-container>
    
                                </td>
                                <td  class="edit-row"  style="border-top: 0px !important;vertical-align: middle;">
                                    <!-- <i class="fas fa-pencil-alt mr-3" (click)="edit(i,item)"></i> -->
                                    <button type="button" *ngIf="parentConfig.value.isOld == true" class="btn-style btn-sm bg-green" (click)="saveParameter(parentConfig.value,i)">Save</button>
                                    <button type="button" *ngIf="parentConfig.value.isOld != true" class="btn-style btn-sm bg-green" (click)="saveCloneParameter(parentConfig.value)">Save</button>
                                    <button class="btn-style bg-blue btn-sm  ml-2" *ngIf="parentConfig.value.isOld == true" (click)="cloneParameter(i)">Clone</button>
                                    <button class="btn-style btn-sm bg-purple ml-2" *ngIf="userInfo && userInfo.accountcode == 'SUPERADMIN' && parentConfig.value.type == 'object'" (click)="openChildKeyModal(parentConfig.value,i)" >Add Key</button>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>
                            <button class="btn btn-info btn-sm add-key-btn" *ngIf="userInfo && userInfo.accountcode == 'SUPERADMIN'" (click)="openChildKeyModal(undefined,undefined)">Add Parameter</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
    <!-- Button trigger modal -->
    <button  hidden #childKeyModalBtn  data-toggle="modal" data-target="#modelId">
      Launch
    </button>
    
    <!-- Modal -->
    <div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <form [formGroup]="childKeyForm">
                <div class="modal-content" style="width: max-content;">
                    <div class="modal-header">
                            <h5 class="modal-title">{{childKeyForm.value.parent_key}}</h5>
                                <button type="button" class="close" (click)="closeChildKeyForm()" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <button type="button" hidden  data-dismiss="modal" #childKeyModalCloseBtn>  </button>
                        </div>
                <div class="modal-body">
                    <div class="flexbox justify-content-center  container-fluid">
                        <div class="input-fiel">
                          <input type="text"
                            class="input-ui" name="" formControlName="key" id="key_name" aria-describedby="helpId" placeholder="">
                            <label for="key_name">Key</label>

                        </div>

                        <div class="input-fiel mt-3">
                          <select class="input-ui" formControlName="type" name="" id="">
                            <ng-container *ngIf="childKeyForm.value.parent_key == ''">
                                <option value="object">Object</option>
                            </ng-container>
                            <option value="text">Text</option>
                            <option value="date">Date</option>
                          </select>
                          <label for="">Key Type</label>

                        </div>

                        <div class="input-fiel mt-3">
                          <ng-container  *ngIf="childKeyForm.value.type == 'text'">
                            <input type="text" formControlName="value"
                            class="input-ui" name="" id="key_name" aria-describedby="helpId" placeholder="">
                          </ng-container>
                          <ng-container  *ngIf="childKeyForm.value.type == 'date'">
                            <input type="date" formControlName="value"
                            class="input-ui" name="" id="key_name" aria-describedby="helpId" placeholder="">
                          </ng-container>
                          <label for="key_name">Value</label>

                        </div>
                    </div>
                  
                </div>
                <div class="d-flex mb-2 justify-content-center ">
                    <div class="input-fiel">
                        <button type="button" class="btn-style mr-3"
                        (click)="closeChildKeyForm()"> CLOSE</button>
                        <button *ngIf="childKeyForm.value.parent_key == ''" type="button" class="btn-style" (click)="addParameter(childKeyForm.value)">SAVE PARAMETER</button>
                        <button *ngIf="childKeyForm.value.parent_key != ''" type="button" class="btn-style" (click)="addChildParameter(childKeyForm.value)">SAVE KEY</button>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closeChildKeyForm()">Close</button>
                    <button type="button" class="btn btn-primary" (click)="saveChildKey()">Save</button>
                </div> -->
            </div>
            </form>
        </div>
    </div>