import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvoiceRoutingModule } from './invoice-routing.module';
import { InvoiceComponent } from './invoice.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SharedModule } from 'src/app/shared/shared.module';
import { FlightModule } from './flight/flight.module';
import { HotelModule } from './hotel/hotel.module';
import { InsuranceModule } from './insurance/insurance.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { MiscellaneousInvoiceModule } from './miscellaneous-invoice/miscellaneous-invoice.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [InvoiceComponent],
  imports: [
    CommonModule,
    InvoiceRoutingModule,
    MiscellaneousInvoiceModule,
    SharedModule,
    FlightModule,
    HotelModule,
    InsuranceModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot(),
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [InvoiceComponent]
})
export class InvoiceModule { }
