<div class="ledger-container pb-20">
	<!-- <div class="tab-nav d-flex align-item-center justify-content-between">
		<ul class="list-inline list-unstyled p-0 m-0">
			<li class="active page-title   text-uppercase">Add Payment Receipt</li>
		</ul>
		<ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
			<li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition">
				Dashboard
			</li>
			<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
			<li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition">
				Update Payment
			</li>
			<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
			<li class="text-grey text-capitalize cursor text-primary transition"> Add Payment Receipt</li>
		</ul>
	</div> -->
  <form [formGroup]="paymentService.paymentEntryForm" style="width: 600px;"> 
    <div  class="custom-form">
      <div class="text-field-group d-flex align-items-center">
        <div class="select-field text-input-field mr-3">
          <select class="input-field" #accountType formControlName="account_code_to_type" tabIndex="1">
            <option value="client">Client</option>
            <option value="airline">Airline</option>
            <option value="supplier">Supplier</option>
            <option value="employee">Employee</option>
            <option value="general">General</option>
          </select>
          <label>Account Type</label>
        </div>
        <div class="input-fiel form-group ng-field">
          <!-- <select class="input-field" formControlName="account_code_to">
						<option value="">Select</option>
						<ng-container  *ngFor="let item of paymentService.accountList">
							<option  *ngIf="item.account_code != ''" value="{{item.account_code}}">{{item.name}}</option>
						</ng-container>
					</select> -->
          <label class="ngauto-label">Account</label>

          <div class="ng-autocomplete" (focus)="focusAccount($event)" tabIndex="2">
            <ng-autocomplete [data]="paymentService.accountList" #accountAC
              (selected)="selectAccount($event,'account_code_to')" formControlName="account_code_to_name"
              (opened)="setAccountAutoCompleteStatus(true)" (closed)="setAccountAutoCompleteStatus(false)"
              [searchKeyword]="keyword" (inputChanged)='onChangeSearch($event)' [itemTemplate]="itemTemplate"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>


            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
								({{item.account_code}})</span><br />'>
              </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="text-field-group d-flex align-items-center">
        <div class="select-field text-input-field mr-3">
          <select class="input-field" formControlName="sub_ledger_code_1_type" tabIndex="3">
            <option value="client">Client</option>
            <option value="airline">Airline</option>
            <option value="supplier">Supplier</option>
            <option value="employee">Employee</option>
            <option value="general">General</option>
          </select>
          <label>Sub Ledger Type</label>
        </div>

        <div class="input-fiel form-group ng-field">
          <label class=""
            [ngClass]="{'ngauto-label': accountAutoComplete==false,'normal-label' : accountAutoComplete==true}">Sub
            Ledger</label>

          <div class="ng-autocomplete" tabIndex="4" (focus)="focusSubLedger($event)">
            <ng-autocomplete #subLedgerAC [data]="paymentService.ledgerList" formControlName="sub_ledger_code_1_name"
              [searchKeyword]="keyword" (selected)="selectAccount($event,'sub_ledger_code_1')"
              (inputChanged)='ledgerSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>


            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}({{item.account_code}})</span><br />'> </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
        <!-- 
        <div class="select-field text-input-field">
          <select class="input-field" formControlName="sub_ledger_code_1">
            <ng-container *ngFor="let item of paymentService.ledgerList">
              <option *ngIf="item.account_code != ''" value="{{item.account_code}}">{{item.name}}</option>
            </ng-container>
          </select>
          <label>Sub Ledger</label>
        </div> -->
      </div>




      <div class="text-field-group d-flex align-items-center">
        <div class="text-input-field mr-3">
          <input oninput="this.value = this.value.toUpperCase()" tabIndex="5" class="input-field" type="text"
            formControlName="cheque_no" maxlength="30" pattern="([0-9]+)"
            [ngClass]="{'error-field': paymentService.paymentEntryForm.controls.cheque_no.invalid && paymentService.paymentEntryForm.controls.cheque_no.dirty,
					'success-field' : paymentService.paymentEntryForm.controls.cheque_no.valid && paymentService.paymentEntryForm.controls.cheque_no.dirty}" />
          <label
            [ngClass]="{'error-label': paymentService.paymentEntryForm.controls.cheque_no.invalid && paymentService.paymentEntryForm.controls.cheque_no.dirty}">Cheque
            no.
            <ng-container
              *ngIf="paymentService.paymentEntryForm.controls.cheque_no.invalid && paymentService.paymentEntryForm.controls.cheque_no.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid Cheque Number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field mr-3">
          <input oninput="this.value = this.value.toUpperCase()" tabIndex="6" class="input-field" type="date"
            [ngClass]="{'error-field': paymentService.paymentEntryForm.controls.cheque_date.invalid && paymentService.paymentEntryForm.controls.cheque_date.dirty,
						'success-field' : paymentService.paymentEntryForm.controls.cheque_date.valid && paymentService.paymentEntryForm.controls.cheque_date.dirty}" />
          <label
            [ngClass]="{'error-label': paymentService.paymentEntryForm.controls.cheque_date.invalid && paymentService.paymentEntryForm.controls.cheque_date.dirty}">Cheque
            Date
            <ng-container
              *ngIf="paymentService.paymentEntryForm.controls.cheque_date.invalid && paymentService.paymentEntryForm.controls.cheque_date.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid Date Format" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field">
          <input oninput="this.value = this.value.toUpperCase()" tabIndex="7" class="input-field" type="text"
            #amountField formControlName="amount" pattern="^\d*(\.\d{0,8})?$"
            [ngClass]="{'error-field': (paymentService.paymentEntryForm.controls.amount.invalid && paymentService.paymentEntryForm.controls.amount.dirty) || paymentService.paymentEntryForm.value.amount < 1,'success-field' : paymentService.paymentEntryForm.controls.amount.valid && paymentService.paymentEntryForm.controls.amount.dirty}" />
          <label
            [ngClass]="{'error-label': (paymentService.paymentEntryForm.controls.amount.invalid && paymentService.paymentEntryForm.controls.amount.dirty) || paymentService.paymentEntryForm.value.amount < 1}">Amount
            <ng-container
              *ngIf="(paymentService.paymentEntryForm.controls.amount.invalid && paymentService.paymentEntryForm.controls.amount.dirty) || paymentService.paymentEntryForm.value.amount < 1">
              <i class="fa fa-info-circle ml-2" title="Amount should be a number and greater than 0"
                aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
      </div>


      <div class="text-field-group d-flex justify-content-between">
        <div class="left-col">
          <div class="text-input-field">
            <input oninput="this.value = this.value.toUpperCase()" tabIndex="8" class="input-field" maxlength="35"
              type="text" formControlName="narration_1" />
            <label>Narration 1</label>
          </div>

          <div class="text-input-field">
            <input oninput="this.value = this.value.toUpperCase()" tabIndex="9" class="input-field" maxlength="35"
              type="text" formControlName="narration_2" />
            <label>Narration 2</label>
          </div>

          <div class="text-input-field">
            <input oninput="this.value = this.value.toUpperCase()" tabIndex="10" class="input-field" maxlength="35"
              type="text" formControlName="narration_3" />
            <label>Narration 3</label>
          </div>

          <div class="text-input-field">
            <input oninput="this.value = this.value.toUpperCase()" tabIndex="11" class="input-field" maxlength="35"
              type="text" formControlName="narration_4" />
            <label>Narration 4</label>
          </div>

          <div class="text-input-field">
            <input oninput="this.value = this.value.toUpperCase()" tabIndex="12" class="input-field" maxlength="35"
              type="text" formControlName="narration_5" />
            <label>Narration 5</label>
          </div>
        </div>

        <div class="right-col">
          <div class="text-input-field">
            <input oninput="this.value = this.value.toUpperCase()" tabIndex="13" class="input-field" maxlength="35"
              type="text" formControlName="remark_1" />
            <label>Remarks 1</label>
          </div>

          <div class="text-input-field">
            <input oninput="this.value = this.value.toUpperCase()" tabIndex="14" class="input-field" maxlength="35"
              type="text" formControlName="remark_2" />
            <label>Remarks 2</label>
          </div>

          <div class="text-input-field">
            <input oninput="this.value = this.value.toUpperCase()" tabIndex="15" class="input-field" maxlength="35"
              type="text" formControlName="remark_3" />
            <label>Remarks 3</label>
          </div>

          <div class="text-input-field">
            <input oninput="this.value = this.value.toUpperCase()" tabIndex="16" class="input-field" maxlength="35"
              type="text" formControlName="remark_4" />
            <label>Remarks 4</label>
          </div>

          <div class="text-input-field">
            <input oninput="this.value = this.value.toUpperCase()" tabIndex="17" class="input-field" maxlength="35"
              type="text" formControlName="remark_5" />
            <label>Remarks 5</label>
          </div>
        </div>
      </div>

      <span class="info alert-warning">* Remarks will not be available for print.</span>

    </div>
		<div class="btn-field bottom-btns-container mt-30 mb-2 d-flex align-item-center  ml-0 mr-0">        
      <ng-container *ngIf="!paymentService.isEdit else updateButtons">
        <button type="button" class="btn-style bg-purple mr-3" tabIndex="18" (click)="openConfirmModal()">Save</button>
      </ng-container>
      <ng-template #updateButtons>
        <button type="button" class="btn-style bg-blue mr-3" tabIndex="18"
          (click)="openUpdateConfirmModal()">Update</button>
        <button type="button" class="btn-style bg-red mr-3" tabIndex="19" (click)="deleteAndExit()">Delete</button>
      </ng-template>
      <button type="button" class="btn-style bg-orange" tabIndex="20" (click)="exitEntryPage()">Exit</button>
    </div>
  </form>
</div>
<button type="button" hidden #confirmModal data-toggle="modal" data-target="#confirmationModal"></button>
<button type="button" hidden #updateConfirmModal data-toggle="modal" data-target="#updateConfirmationModal"></button>


<div id="confirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <p>Add New Entry ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style" tabIndex="22" attr.tabIndex="22"
          (click)="saveAndExit(paymentService.paymentEntryForm.value)" data-dismiss="modal">No</button>
          <button type="button" class="btn-style bg-green" tabIndex="21" attr.tabIndex="21"
            (click)="saveAndEditNext(paymentService.paymentEntryForm.value)" data-dismiss="modal">Yes</button>
      </div>
    </div>
  </div>
</div>


<div id="updateConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <p>Are you sure to update ‎this entry ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style" tabIndex="24" attr.tabIndex="24" (click)="closeConfirmModal()"
          data-dismiss="modal">No</button>
          <button type="button" class="btn-style bg-green" tabIndex="23" attr.tabIndex="23"
            (click)="updateAndExit(paymentService.paymentEntryForm.value)" data-dismiss="modal">Yes</button>
      </div>
    </div>
  </div>
</div>