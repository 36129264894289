<form [formGroup]="tdsreportFilterForm">
  <div class="flexbox aff-info">
    <div class="flexbox aff-info-inner">
      <div class="flexbox ">
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="1" formControlName="start_date" id="from_date" type="date"
                min="{{minDate}}" max="{{maxDate}}"
                [ngClass]="{'error-box': !tdsreportFilterForm.controls.start_date.valid}"
              />
          <label for="start_date">Starting Date</label>

        </div>
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="2" formControlName="end_date" id="to_date" type="date"
              min="{{minDate}}" max="{{maxDate}}"
              [ngClass]="{'error-box': !tdsreportFilterForm.controls.end_date.valid}"
              />
          <label for="to_date">Ending Date</label>
        </div>
      </div>


      <div class="flexbox mt-3">
        <div class="input-fiel form-group ng-field" style=" width: 300px; ">
          <select class="input-field" searchtabindex="3" formControlName="supplier">
            <ng-container *ngIf="supplierlist != undefined">
              <option *ngFor="let item of supplierlist" value="{{item.key}}">{{item.display_name}}</option>
            </ng-container>
          </select>
          <label>Supplier</label>
        </div>
        <div class="input-fiel" searchtabindex="4" (focus)="focusAirlineAc()">
          <label class="ngauto-label" for="affname">Airline</label>
          <div class="ng-autocomplete">
            <ng-autocomplete [data]="airlineArr" #airlineAC [searchKeyword]="keyword"
              (inputChanged)='getAirline($event,false)' [itemTemplate]="itemTemplate" historyIdentifier="airlineArr"
              formControlName="airline_name" (selected)="selectAirline($event,'airline')"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                                    ({{item.account_code}})</span>'>
              </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
      </div>

      <span class="sub-head ">Include</span>

      <div class="flexBox d-flex mt-1 ml-2 mr-2 justify-content-between">
        <div class="input-fiel">
          <label class="radio-label" for="uuid">All</label>
          <input class="radio-ui" id="uuid" type="checkbox" name="" searchtabindex="5" formControlName="all" />
        </div>

        <div class="input-fiel">
          <label class="radio-label" for="genid">Ticket</label>
          <input class="radio-ui" id="genid" type="checkbox" name="" searchtabindex="6" formControlName="ticket" />
        </div>

        <div class="input-fiel">
          <label class="radio-label" for="empid">Refund</label>
          <input class="radio-ui" id="empid" type="checkbox" name="" searchtabindex="7" formControlName="refund" />
        </div>

      </div>
      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
            searchtabindex="8" #searchButton (keyup.enter)="clickSearchButton()"
            [disabled]="tdsreportFilterForm.invalid"
            (click)="search(tdsreportFilterForm.value)">
            Search</button>
          <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
        </div>
      </div>
    </div>
  </div>

</form>