import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/shared/loader.service';
import { NumberToWordsPipe } from 'src/app/shared/pipe/print-invoice.pipe';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver'

@Component({
  selector: 'app-print-proforma',
  templateUrl: './print-proforma.component.html',
  styleUrls: ['./print-proforma.component.scss']
})
export class PrintProformaComponent implements OnInit {

  invoiceData: any;
	searchId: any;

	@Input() reference_code;
	@Input() preFetchedData;
	@Output() callSaveFetchedData: EventEmitter<any> = new EventEmitter()
	basFare = 0;
	ot = 0;
	subTotalFare = 0;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private loaderService: LoaderService,
		public commonService: CommonServiceService,
		public sanitizer: DomSanitizer
	) { }

	ngOnInit() {
		this.loaderService.show()
		let searchId = this.route.snapshot.params['searchId'];
		this.searchId = searchId;
		if(!this.preFetchedData){
			if(this.reference_code){
				searchId = this.reference_code
			}
			if (searchId != undefined) {
				document.title = `Invoice - ${searchId}`
			}
			let type = this.route.snapshot.params['type'];
			this.getInvoiceData(searchId, type)
		}else{
			this.invoiceData = this.preFetchedData
		}
	}

	getInvoiceData(searchId: any, type: any) {
		try {
			this.commonService.getRequest(`${environment.okountUrl}/invoice/proforma/download/${searchId}?preview=true`).subscribe(async (res) => {
				this.invoiceData = this.transform(res)				
				try {
					this.callSaveFetchedData.emit({
						data : this.invoiceData,
						invoice_no : searchId
					})
				} catch (error) {
					
				}
				
			},
				err => {
					this.loaderService.hide()
					console.log(err)
				})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
		}
	}
	transform(html) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}
	
	
	downloadPdf(searchId: any) {
		this.commonService.downloadPDF(`${environment.okountUrl}/invoice/proforma/download/${searchId}?preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `Invoice-${searchId}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}

}
