import { InvoiceRefundService } from './invoice-refund.service';
import { TotalFareRefundModel } from '../models/models/total-fare-refund-model';
import { Injectable, ɵConsole } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Subject, BehaviorSubject } from 'rxjs';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SectorRefundService {


	// Observable string sources
	public componentMethodCallSource = new Subject<any>();

	// Observable string streams
	componentMethodCalled$ = this.componentMethodCallSource.asObservable();

	// Observable string sources
	public saveProductSource = new BehaviorSubject(false);
	// Observable string streams
	saveProductSourceMethod = this.saveProductSource.asObservable();

	public loadFlightRefund = new BehaviorSubject({});
	// Observable string streams
	callToLoadFlightRefund = this.loadFlightRefund.asObservable();

	public setInvoiceSourceData = new BehaviorSubject([]);
	invoiceRootData = this.setInvoiceSourceData.asObservable();


	sectorlist: FormArray; //FOR SECTOR 1 COMBINATION
	sector2list: FormArray; //FOR SECTOR 2 COMBINATION
	sectorForm: any;

	travellerForm: any;
	invoiceTravellerForm = []
	travellerList: FormArray;
	invoiceTravellerList = [];
	sectorList: FormArray;  // FOR EACH TRAVELLER

	invoiceForm: any;
	departurearray: any = [];
	arrivalarray: any = [];

	ledgerList: any = [];
	fareList = []
	airlineArr: any = [];
	totalFareModel = []
	accountList: any;
	employeeList: any;
	supplierList: any
	cancelledTicket = []
	invoiceMode = 'save';
	tour_account_code = ''
	invoice_no;
	totalIndexInTraveller = 0;
	lastIndexOfTraveller = 72;
	initialAirline: string = '';
	affiliate_code: any;
	isManual : boolean = false;
	retrievalConfig: any;
	disRecallRate:number = 18
	enablePayable: boolean;
	generalAccArr : any = [];

	constructor(private fb: FormBuilder,
		private invoiceservice: InvoiceRefundService,
		private spinner: NgxSpinnerService,
		public commonService: CommonServiceService
	) {
		// this.fareList.push(['1000012'])
		// this.totalFareModel[0] =  new TotalFareRefundModel(null);
		this.sectorForm = this.fb.group({
			txtSector1: ['', Validators.required],
			txtSector2: [''],
			sectorlist: this.fb.array([]),
			sector2list: this.fb.array([])
		});

		this.travellerForm = this.fb.group({
			travellerList: this.fb.array([]),
		});
		// this.invoiceTravellerForm = this.fb.group({
		// 	travellerList: this.fb.array([]),
		// });

		this.invoiceForm = this.fb.group({
			crsId: ['', Validators.required],
			supplier: ['', Validators.required],
			invoiceType: ['', Validators.required],
			pnr: ['', Validators.required],
			booking_ref: [''],
			fareBasis: [''],
			dealCode: [''],
			airline: [''],
			airline_name: [''],
			payable: [''],
			travellerCount: [''],
			employee: [''],
			employee_name: [''],
			sub_ledger_code_1: [''],
			sub_ledger_code_2: [''],
			sub_ledger_code_3: [''],
			sub_ledger_code_4: [''],
			sub_ledger_code_5: [''],
			sub_ledger_code_1_name: [''],
			sub_ledger_code_2_name: [''],
			sub_ledger_code_3_name: [''],
			sub_ledger_code_4_name: [''],
			sub_ledger_code_5_name: [''],
			sub_ledger_code_1_type: ['client'],
			sub_ledger_code_2_type: [''],
			sub_ledger_code_3_type: [''],
			sub_ledger_code_4_type: [''],
			sub_ledger_code_5_type: [''],
		});
		let airlineRes = this.getAirline()
		let accountsRes = this.getAccounts('client')
		let employeeRes = this.getEmployee('employee?noRole=true')
		let supplierRes = this.getBookingSupplier('util/getsupplierairline')

	}
	getDiscRecallSetting(queryParams,key){
		return new Promise((resolve, reject) => {
			queryParams['date'] = queryParams['date'] ? queryParams['date'] : moment().format('YYYY-MM-DD')
			this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/global_settings/configs`,queryParams).subscribe(res=>{
				// bind disc recall client
				this.travellerForm.controls.travellerList.controls[key].controls.disClientRecallValue.setValue(res['client_disc_recall_rate'])
				this.travellerForm.controls.travellerList.controls[key].controls.disSupplierRecallValue.setValue(res['airline_disc_recall_rate'])
				resolve(res)
			},err=>{
				resolve(null)
			})
		})
	}
	getAccounts(url) {
		return new Promise((resolve, reject) => {
			this.invoiceservice.getMethod(url).subscribe(res => {
				this.accountList = res
				resolve(res)
			}, err => {
				resolve(null)
			})
		})
	}
	getEmployee(url) {
		return new Promise((resolve, reject) => {
			this.invoiceservice.getMethod(url).subscribe(res => {
				this.employeeList = res
				resolve(res)
			}, err => {
				resolve(null)
			})
		})
	}

	getAirline() {
		try {
			return new Promise((resolve, reject) => {
				this.invoiceservice.searchAccount(`airline`).subscribe(res => {
					this.airlineArr = res
					resolve(res)
				}, err => {
					resolve(null)
				});
			})
		} catch (error) {
			console.log('Error while getting Airlines : ', error)
		}
	}
	getBookingSupplier(url) {
		return new Promise((resolve, reject) => {
			this.invoiceservice.getMethod(url).subscribe(res => {
				this.supplierList = res
				resolve(res)
			}, err => {
				resolve(null)
			})
		})
	}

	getGeneralAccounts() {
		return new Promise((resolve, reject) => {
			this.invoiceservice.getMethod('general/bank-od-cc').subscribe(res => {
				this.generalAccArr = res
				resolve(res)
			}, err => {
				resolve(null)
			})
		})
	}

	bindTraveller(travellerLength) {

		this.travellerList = this.travellerForm.get("travellerList");
		this.travellerList = this.travellerForm.get("travellerList") as FormArray;
		this.travellerList.controls = [];
		for (let i = 0; i < travellerLength; i++) {
			this.travellerList.push(this.buildTraveller(null));
		}
	}

	addTraveller() {
		this.travellerList.push(this.buildTraveller(null));
	}

	async bindRetrievedData(data) {

		this.travellerList = this.travellerForm.get("travellerList");
		this.travellerList = this.travellerForm.get("travellerList") as FormArray;
		this.travellerList.controls = [];

		this.invoiceForm.controls.travellerCount.setValue(data.traveller.length)
		for (const key in data.traveller) {
			if (data.traveller.hasOwnProperty(key)) {
				this.travellerList.push(this.buildTraveller(data.traveller[key]));

				//SET VALUE

				this.travellerForm.controls.travellerList.controls[key].controls.selTitle.setValue(data.traveller[key].title)
				this.travellerForm.controls.travellerList.controls[key].controls.selType.setValue(data.traveller[key].type)
				this.travellerForm.controls.travellerList.controls[key].controls.txtPassengerName.setValue(data.traveller[key].name)
				this.travellerForm.controls.travellerList.controls[key].controls.txtEmployeeName.setValue(data.traveller[key].employee)
				this.travellerForm.controls.travellerList.controls[key].controls.txtEmployeeCode.setValue(data.traveller[key].e_code)
				this.travellerForm.controls.travellerList.controls[key].controls.txtEmployeeCostCenter.setValue(data.traveller[key].cost_center)
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierCode.setValue(data.traveller[key].payable_transaction_code)
				this.travellerForm.controls.travellerList.controls[key].controls.supplierDate.setValue(data.traveller[key].payable_date)
				this.travellerForm.controls.travellerList.controls[key].controls.txtbookingSupplier.setValue(data.supplier)
				this.travellerForm.controls.travellerList.controls[key].controls.txtbookingSupplierIsBranch.setValue(data.supplier_is_branch)
				this.travellerForm.controls.travellerList.controls[key].controls.associate_supplier.setValue(data.associate_supplier)
				this.travellerForm.controls.travellerList.controls[key].controls.associate_supplier_name.setValue(data.associate_supplier_name)


				//SET FARE VALUE
				this.travellerForm.controls.travellerList.controls[key].controls.txtBaseFare.setValue(data.traveller[key].baseFare)
				this.travellerForm.controls.travellerList.controls[key].controls.txtYQ.setValue(data.traveller[key].yq)
				this.travellerForm.controls.travellerList.controls[key].controls.txtYR.setValue(data.traveller[key].yr)
				this.travellerForm.controls.travellerList.controls[key].controls.txtK3.setValue(data.traveller[key].k3)
				this.travellerForm.controls.travellerList.controls[key].controls.txtOC.setValue(data.traveller[key].oc)
				this.travellerForm.controls.travellerList.controls[key].controls.txtOT.setValue(data.traveller[key].ot)

				this.travellerForm.controls.travellerList.controls[key].controls.localBaseFare.setValue(data.traveller[key].localBaseFare)
				this.travellerForm.controls.travellerList.controls[key].controls.localYQ.setValue(data.traveller[key].localYQ)
				this.travellerForm.controls.travellerList.controls[key].controls.localYR.setValue(data.traveller[key].localYR)
				this.travellerForm.controls.travellerList.controls[key].controls.localK3.setValue(data.traveller[key].localK3)
				this.travellerForm.controls.travellerList.controls[key].controls.localOC.setValue(data.traveller[key].localOC)
				this.travellerForm.controls.travellerList.controls[key].controls.localOT.setValue(data.traveller[key].localOT)

				//SET TICKET NUMBER 
				let ticketNumber = data.pnr + '-' + (Number(key) + 1);
				let travellerFormControl = this.travellerForm.controls
				let invoiceFormControl = this.invoiceForm.controls
				let sectorFormControl = this.sectorForm.controls
				let trvLen = data.traveller.length / 2;
				let ticketArr = [];
				let trvCount = 0;
				if (data.traveller[key].ticketNo == null) {
					travellerFormControl.travellerList.controls[key].controls.txtTicketNumber.setValue(ticketNumber)
				} else if ((invoiceFormControl.crsId.value != 'website' || invoiceFormControl.crsId.value != 'WEBSITE' ) && sectorFormControl.txtSector2.value != "" && sectorFormControl.txtSector2.value != null) {
					if (data.traveller[key].ticketNo.split('-').length == 3) {
						if (Number(key) < trvLen) {
							let x = data.traveller[key].ticketNo.split('-');
							let y = x[0] + '-' + x[1]
							let x2 = x[2];
							let AMticketNo = y;
							ticketArr.push(y.slice(0, -2) + x2);
							travellerFormControl.travellerList.controls[key].controls.txtTicketNumber.setValue(AMticketNo);
							travellerFormControl.travellerList.controls[key].controls.isConjuction.setValue(false);
						}
						else {
							let AMticketNo = ticketArr[trvCount];
							travellerFormControl.travellerList.controls[key].controls.isConjuction.setValue(true);
							travellerFormControl.travellerList.controls[key].controls.txtTicketNumber.setValue(AMticketNo);
							trvCount++;
						}
					}
					else {
						travellerFormControl.travellerList.controls[key].controls.txtTicketNumber.setValue(ticketNumber)
					}

				} else {
					travellerFormControl.travellerList.controls[key].controls.txtTicketNumber.setValue(data.traveller[key].ticketNo)
				}


				//CALCULATE TOTALs
				this.calculateTotal(key)
			}
		}

		let sectorArray = [];

		for (let i = 0; i < data.sector.length; i++) {
			sectorArray.push(data.sector[i].name.split('/')[0]);
			sectorArray.push(data.sector[i].name.split('/')[1]);
		}

		let uniqueSector = this.onlyUnique(sectorArray)
		let sectorComb1 = uniqueSector.join('/');

		await this.bindSector1(sectorComb1);
		// this.invoiceForm.controls.airline.setValue(data.marketingAirlneCode ? data.marketingAirlneCode : data.sector[0].airline)

		for (const key in data.sector) {
			if (data.sector.hasOwnProperty(key)) {
				const sector = data.sector[key];

				this.sectorForm.controls.sectorlist.controls[key].controls.txtAirlinePnrX.setValue(sector.airlinePnr ? sector.airlinePnr : data.pnr)
				this.sectorForm.controls.sectorlist.controls[key].controls.txtAirlinePrefix.setValue(sector.airline)
				this.sectorForm.controls.sectorlist.controls[key].controls.txtFlightNo.setValue(sector.flightNo)
				this.sectorForm.controls.sectorlist.controls[key].controls.txtClass.setValue(sector.bookingClass)
				this.sectorForm.controls.sectorlist.controls[key].controls.dateAirlineDepartInfo.setValue(moment(sector.departureDateTime).format('YYYY-MM-DD'))
				this.sectorForm.controls.sectorlist.controls[key].controls.dateAirlineArrivalInfo.setValue(moment(sector.arrivalDateTime).format('YYYY-MM-DD'))
				this.sectorForm.controls.sectorlist.controls[key].controls.txtBaggage.setValue(sector.baggage)

				//SET AIRPORT INFO 
				let departTerminal = sector.departureTernimal ? `, ${sector.departureTernimal}` : "";
				let arrivalTerminal = sector.arrivalTernimal ? `, ${sector.arrivalTernimal}` : "";
				let departInfo = sector.departureCity + ', ' + sector.departureAirport + departTerminal;
				let arrivalinfo = sector.arrivalCity + ', ' + sector.arrivalAirport + arrivalTerminal;

				this.sectorForm.controls.sectorlist.controls[key].controls.txtAirlineDepartInfo.setValue(departInfo.toUpperCase() ? departInfo.toUpperCase() : departInfo)
				this.sectorForm.controls.sectorlist.controls[key].controls.txtAirlineArrivalInfo.setValue(arrivalinfo.toUpperCase() ? arrivalinfo.toUpperCase() : arrivalinfo)

			}
		}
		return true
	}

	onlyUnique(self) {
		var newArray = [];
		console.log('All Sector : ', self)
		newArray.push(self[0]);
		for (var i = 0; i < self.length - 1; i++) {
			if (self[i] != self[i + 1]) {
				newArray.push(self[i + 1]);
			}
		}
		console.log('Unique Sector Arr : ', newArray)
		return newArray
	}


	calculateTotal(i) {
		if (this.travellerForm.value.travellerList[i]) {
			let travellerData = Object.assign({}, this.travellerForm.value.travellerList[i])
			let baseFare = Number(travellerData['txtBaseFare'])
			let YQ = Number(travellerData['txtYQ'])
			let YR = Number(travellerData['txtYR'])
			let K3 = Number(travellerData['txtK3'])
			let OC = Number(travellerData['txtOC'])
			let OT = Number(travellerData['txtOT'])
			let addons = this.getAddonsTotal(travellerData['sectorList'])
			let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
			let total = baseFare + YQ + YR + K3 + OC + OT + addons
			this.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(addons)
			this.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(localAddons)
			this.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total)

			let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total)
		}
	}
	calculateInvoiceTotal(invIndex, tIndex) {
		if (this.invoiceTravellerForm[invIndex].value.travellerList[tIndex]) {
			let travellerData = Object.assign({}, this.invoiceTravellerForm[invIndex].value.travellerList[tIndex])
			// let travellerData = Object.assign({}, this.travellerForm.value.travellerList[i])
			let baseFare = Number(travellerData['txtBaseFare'])
			let YQ = Number(travellerData['txtYQ'])
			let YR = Number(travellerData['txtYR'])
			let K3 = Number(travellerData['txtK3'])
			let OC = Number(travellerData['txtOC'])
			let OT = Number(travellerData['txtOT'])
			let addons = this.getAddonsTotal(travellerData['sectorList'])
			let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
			let total = baseFare + YQ + YR + K3 + OC + OT + addons
			this.invoiceTravellerForm[invIndex].controls.travellerList.controls[tIndex].controls.txtAddons.setValue(addons)
			this.invoiceTravellerForm[invIndex].controls.travellerList.controls[tIndex].controls.localAddons.setValue(localAddons)
			this.invoiceTravellerForm[invIndex].controls.travellerList.controls[tIndex].controls.txtAmount.setValue(total)

			let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.invoiceTravellerForm[invIndex].controls.travellerList.controls[tIndex].controls.localAmount.setValue(local_total)

		}
	}

	getLocalAddonsTotal(sectors) {
		let totalAmount = 0
		for (const key in sectors) {
			if (sectors.hasOwnProperty(key)) {
				const element = sectors[key];
				if (element['txtSeatCharges'] != "") {
					totalAmount += Number(element['txtSeatCharges'])
				}
				if (element['txtMealCharges'] != "") {
					totalAmount += Number(element['txtMealCharges'])
				}
				if (element['txtBagCharges'] != "") {
					totalAmount += Number(element['txtBagCharges'])
				}
				if (element['txtAdditionalBaggageCharges'] != "") {
					totalAmount += Number(element['txtAdditionalBaggageCharges'])
				}
				if (element['txtAncillaryCharges'] != "") {
					totalAmount += Number(element['txtAncillaryCharges'])
				}
			}
		}
		return totalAmount
	}

	getAddonsTotal(sectors) {
		let currency_rate ;
		this.invoiceRootData.subscribe((items)=> {  
			console.log('items', items);
			
			currency_rate = items['currency_rate'] ? items['currency_rate'] : 1
		})
		console.log("currency_rate",currency_rate)
		let totalAmount = 0
		for (const key in sectors) {
			if (sectors.hasOwnProperty(key)) {
				const element = sectors[key];
				if (element['txtSeatCharges'] != "") {
					totalAmount += Number(element['txtSeatCharges']) * Number(currency_rate)
				}
				if (element['txtMealCharges'] != "") {
					totalAmount += Number(element['txtMealCharges']) * Number(currency_rate)
				}
				if (element['txtBagCharges'] != "") {
					totalAmount += Number(element['txtBagCharges']) * Number(currency_rate)
				}
				if (element['txtAdditionalBaggageCharges'] != "") {
					totalAmount += Number(element['txtAdditionalBaggageCharges']) * Number(currency_rate)
				}
				if (element['txtAncillaryCharges'] != "") {
					totalAmount += Number(element['txtAncillaryCharges']) * Number(currency_rate)
				}
			}
		}
		return totalAmount
	}

	buildTraveller(travellerData) {
		return this.fb.group({
			selTitle: [""],
			selType: ["", Validators.required],
			totalIndexCount: [35],
			travellerStatus: ['not_cancelled'],
			txtPassengerName: ["", Validators.required],
			txtEmployeeName: [ ""],
			txtEmployeeCode: [""],
			txtEmployeeCostCenter: [ ""],
			txtSupplierCode:[''],
			supplierDate:[''],
			txtTicketNumber: ["", Validators.required],
			prev_ticket_no: [""],
			newTicketNo: ["", Validators.required],
			newPNRNo: ["", Validators.required],
			txtbookingSupplier: [""],
			associate_supplier:[''],
			associate_supplier_name: [""],
			txtbookingSupplierName: [""],
			txtbookingSupplierIsBranch: [false],
			type_of_pay: [''],
			ledger_card_no: [''],
			ledger_card_name : [''],
			txtBaseFare: ["", Validators.required],
			txtYQ: [""],
			txtYR: [""],
			txtK3: [""],
			txtOC: [""],
			txtOT: [""],
			txtAddons: [""],
			txtAmount: [""],
			localBaseFare: [""],
			localYQ: [""],
			localYR: [""],
			localK3: [""],
			localOC: [""],
			localOT: [""],
			localAddons: [""],
			localAmount: [""],
			isCancel: [true],
			txtSupplierSC1Type: ["N"],
			txtSupplierSC1Value: ["0"],
			txtSupplierSC1Amount: ["0"],
			txtSupplierSC2Type: ["T"],
			txtSupplierSC2Value: ["0"],
			txtSupplierSC2Amount: ["0"],
			txtSupplierSC3Type: ["t"],
			txtSupplierSC3Value: ["0"],
			txtSupplierSC3Amount: ["0"],
			selSupplierIataType: ["RB"],
			selSupplierIataAmount: ["0"],
			selSupplierIataValue: ["0"],
			selSupplierCbType: ["VL"],
			selSupplierCbAmount: ["0"],
			selSupplierCbValue: ["0"],
			selSupplierDsType: ["VL"],
			selSupplierDsAmount: ["0"],
			selSupplierDsValue: ["0"],
			selSupplierPlbType: ["RB"],
			selSupplierPlbAmount: ["0"],
			selSupplierPlbValue: ["0"],
			selClientIataType: ["RB"],
			selClientIataValue: ["0"],
			selClientIataAmount: ["0"],
			selClientPlbType: ["RB"],
			selClientPlbValue: ["0"],
			selClientPlbAmount: ["0"],
			selClientCbType: ["VL"],
			selClientCbValue: ["0"],
			selClientCbAmount: ["0"],
			selClientDsType: ["VL"],
			selClientDsValue: ["0"],
			selClientDsAmount: ["0"],
			txtClientSC1Type: ["T"],
			txtClientSC1Value: ["0"],
			txtClientSC1Amount: ["0"],
			txtClientSC2Type: ["T"],
			txtClientSC2Taxable: ["N"],
			txtClientSC2Value: ["0"],
			txtClientSC2Amount: ["0"],
			txtClientSC3Type: ["T"],
			txtClientSC3Taxable: ["N"],
			txtClientSC3Value: ["0"],
			txtClientSC3Amount: ["0"],
			txtClientRAFAmount: ["0"],
			txtClientXXLAmount: ["0"],
			txtSupplierRAFAmount: ["0"],
			txtSupplierXXLAmount: ["0"],
			disClientRecallType: [""],
			disClientRecallValue: [this.disRecallRate],
			disClientRecallAmount: ["0"],
			disSupplierRecallType: [""],
			disSupplierRecallValue: [this.disRecallRate],
			disSupplierRecallAmount: ["0"],
			isConjuction: [false],
			sectorList: this.fb.array([]),
		});
	}

	buildTravellerSector(sectorData) {
		return this.fb.group({
			isCancelRequest: [false],
			isCancelled: [false],
			sector: [sectorData],
			txtSeatCode: [""],
			txtSeatCharges: [""],
			txtMealCode: [""],
			txtMealCharges: [""],
			txtBagCode: [""],
			txtBagCharges: [""],
			txtAdditionalBaggageCode: [""],
			txtAdditionalBaggageCharges: [""],
			txtAncillaryCode: [""],
			txtAncillaryCharges: [""],
		});
	}

	buildsectorlist(customSector, pnr, departureAirport, arrivalAirport) {
		return this.fb.group({
			txtSectorComb: [customSector],
			txtAirlinePnrX: [pnr, Validators.required],
			txtAirlinePrefix: ["", Validators.required],
			txtFlightNo: ["", Validators.required],
			txtClass: [""],
			txtAirlineDepartInfo: [departureAirport['result'].toUpperCase() ? departureAirport['result'].toUpperCase() : departureAirport['result']],
			txtAirlineArrivalInfo: [arrivalAirport['result'].toUpperCase() ? arrivalAirport['result'].toUpperCase() : arrivalAirport['result']],
			dateAirlineDepartInfo: ["", Validators.required],
			dateAirlineArrivalInfo: ["", Validators.required],
			txtBaggage: [""],
			arrivalCity: [""],
			arrivalCityCode: [""],
			departureCity: [""],
			departureCityCode: [""],
		});
	}

	get DynamicFormControls() {
		const secLst = <FormArray>this.sectorForm.get("sectorlist");
		return secLst;
	}
	get DynamicFormControls2() {
		return <FormArray>this.sectorForm.get("sector2list");
	}

	async bindSector1(value) {
		let sectorValue = value;
		let currentSectorArray = sectorValue.split("/");
		let currentVal = currentSectorArray.join("");
		if (currentVal.match(/.{1,3}/g) != null) {
			let splitVal = currentVal.match(/.{1,3}/g).join("/");
			this.sectorForm.controls.txtSector1.setValue(splitVal);
			if (currentSectorArray.length > 0) {
				let lastvalueOfCurrentSector = currentSectorArray[currentSectorArray.length - 1];
				if (lastvalueOfCurrentSector.length == 3) {
					this.sectorlist = this.sectorForm.get("sectorlist") as FormArray;
					this.sectorlist.controls = [];
					let i = 0;
					for (const currentSector in currentSectorArray) {
						let customSector = currentSectorArray[i] + "/" + currentSectorArray[i + 1];
						if (currentSectorArray[i + 1] != undefined && customSector.length > 6) {
							let departureAirport = await this.getAirport(currentSectorArray[i])
							let arrivalAirport = await this.getAirport(currentSectorArray[i + 1])
							let sectorList = this.buildsectorlist(customSector, "", departureAirport, arrivalAirport)
							this.sectorlist.push(sectorList);
						}
						i++;
					}

					this.travellerList = this.travellerForm.get("travellerList") as FormArray;
					this.totalIndexInTraveller = 0
					for (let i = 0; i < this.travellerList.length; i++) {
						this.sectorList = this.travellerForm.get("travellerList").controls[i].get('sectorList');
						let totalIndexCount = 37
						this.sectorList.controls = [];
						for (let i = 0; i < currentSectorArray.length - 1; i++) {
							let customSector = currentSectorArray[i] + "/" + currentSectorArray[i + 1];
							if (customSector.length > 6) {
								const element = customSector;
								this.sectorList.push(this.buildTravellerSector(element))
								totalIndexCount = totalIndexCount + 6
							}
						}
						this.totalIndexInTraveller = this.totalIndexInTraveller + totalIndexCount
						this.travellerForm.get("travellerList").controls[i].controls.totalIndexCount.setValue(totalIndexCount)
						let travellerStatus = this.isCancelledFn(this.travellerForm.get("travellerList").controls[i])
						this.travellerForm.get("travellerList").controls[i].controls.travellerStatus.setValue(travellerStatus)
					}
				}
			}
		} else {
			this.sectorlist = this.sectorForm.get("sectorlist") as FormArray;
			this.sectorlist.controls = [];
		}
		this.lastIndexOfTraveller = this.totalIndexInTraveller + 9
		console.log('Last index of traveller : ', this.lastIndexOfTraveller)

	}

	isCancelledFn(traveller) {
		let returnClass = "not_cancelled"
		if (this.isManual == true) {
			return returnClass
		}
		let isCancelled = this.cancelledTicket.filter(option => {
			return option.ticket_no == traveller.value.txtTicketNumber;
		});
		if (isCancelled.length > 0) {
			if (traveller.value.sectorList.length > 0) {
				let sectorList = traveller.controls.sectorList.controls
				for (const key in sectorList) {
					if (sectorList.hasOwnProperty(key)) {
						const sector = sectorList[key];
						for (const cancelledKey in isCancelled) {
							if (isCancelled.hasOwnProperty(cancelledKey)) {
								const cancelledEl = isCancelled[cancelledKey];
								if (traveller.value.sectorList.length == cancelledEl.sectors.length) {
									returnClass = `full_cancelled`
									traveller.controls.isCancel.setValue(false)
								} else {
									returnClass = `partial_cancelled`
								}
								for (const sectorKey in cancelledEl.sectors) {
									if (cancelledEl.sectors.hasOwnProperty(sectorKey)) {
										const cancelledSector = cancelledEl.sectors[sectorKey];
										if (sector.value.sector == cancelledSector) {
											sector.controls.isCancelled.setValue(true)
										}
									}
								}
							}
						}
					}
				}
			}
		}
		return returnClass
	}



	async getSector1(event) {
		if (event.target.value.length >= 3) {
			let sectorValue = event.target.value;
			let currentSectorArray = sectorValue.split("/");
			let currentVal = currentSectorArray.join("");
			let splitVal = currentVal.match(/.{1,3}/g).join("/");
			this.sectorForm.controls.txtSector1.setValue(splitVal);
		}

	}


	getAirport(code) {
		return new Promise((resolve, reject) => {
			this.invoiceservice.getAirlineInfo(code).subscribe(
				(res: any) => {
					let resArr = res.filter(option => {
						if (option['airport_CODE'] == code) {
							resolve(option);
						}
						else {
							option = {
								"result": "N/A",
								"airport_CODE": "N/A"
							}
							resolve(option);
						}
					});
				},
				err => {
					reject("");
				}
			);
		});
	}

	resetFormData() {
		this.travellerForm.reset()
		this.invoiceForm.reset()
		this.sectorForm.reset()
		this.travellerList.controls = []
		this.sectorList.controls = []
		this.sectorlist.controls = [];
		// this.sector2list.controls = [];
	}

	async bindCreditNoteData(data, index) {  // Add loader till full binding.
		this.travellerList = this.travellerForm.get("travellerList");
		this.travellerList = this.travellerForm.get("travellerList") as FormArray;
		this.travellerList.controls = [];
		this.invoiceForm.controls.booking_ref.setValue(data.booking_ref_no)
		this.invoiceForm.controls.travellerCount.setValue(data.travellers.length)
		
		this.invoiceForm.controls.sub_ledger_code_1_type.setValue(data.sub_ledger_code_1_type)
		this.invoiceForm.controls.sub_ledger_code_1.setValue(data.sub_ledger_code_1)
		if(this.invoiceMode != 'create'){
			this.invoiceForm.controls.employee.setValue(data.employee)
			this.invoiceForm.controls.employee_name.setValue(data.employee_name)

		}

		this.invoiceForm.controls.airline.setValue(data.airline)
		this.invoiceForm.controls.fareBasis.setValue(data.fareBasis)

		let reqObj = {
			clientCode : this.affiliate_code,
			airlineCode : data.airline,
			date : data.airline,
		}
		
		try {
			if(this.generalAccArr == undefined || this.generalAccArr == null || this.generalAccArr.length == 0) {
				await this.getGeneralAccounts();	
			}
			if (this.airlineArr == undefined || this.airlineArr == null || this.airlineArr.length == 0) {
				let airlineRes = await this.getAirline()
			}
			if (this.accountList == undefined) {
				let accountsRes = await this.getAccounts('client')
			}
			if (this.employeeList == undefined) {
				let employeeRes = await this.getEmployee('employee')
			}

			let selectedAirline = this.airlineArr.filter(option => {
				return option.account_code == data.airline;
			});
			if (selectedAirline.length > 0) {
				this.invoiceForm.controls.airline_name.setValue(selectedAirline[0].name)
			}
			let selectedEmployee = this.employeeList.filter(option => {
				return option.account_code == data.employee;
			});
			// if (selectedEmployee.length > 0) {
			// 	this.invoiceForm.controls.employee_name.setValue(selectedEmployee[0].name)
			// }
			let selectedSubledger = this.accountList.filter(option => {
				return option.account_code == data.sub_ledger_code_1;
			});
			if (selectedSubledger.length > 0) {
				this.invoiceForm.controls.sub_ledger_code_1_name.setValue(selectedSubledger[0].name)
			}
		} catch (error) {

		}
		let crsId = data.crs_id_value ? data.crs_id_value.toUpperCase() : 'WEBSITE'
		this.invoiceForm.controls.crsId.setValue(crsId.toUpperCase())
		let supplier = data.supplier 
		let invoiceType = data.invoiceType
		
		let selectedSupplier = this.retrievalConfig.filter(option => {
			return option.acode == data.airline;
		});
		if(selectedSupplier.length > 0){
			supplier = selectedSupplier[0].display_name
			if(!invoiceType){
				invoiceType = selectedSupplier[0].type
			}
		}
		
		this.invoiceForm.controls.supplier.setValue(supplier)
		this.invoiceForm.controls.invoiceType.setValue(invoiceType)
		for (const key in data.travellers) {
			if (data.travellers.hasOwnProperty(key)) {
				this.travellerList.push(this.buildTraveller(data.travellers[key]));

				//SET VALUE

				this.travellerForm.controls.travellerList.controls[key].controls.selTitle.setValue(data.travellers[key].title)
				this.travellerForm.controls.travellerList.controls[key].controls.selType.setValue(data.travellers[key].type)
				this.travellerForm.controls.travellerList.controls[key].controls.txtPassengerName.setValue(data.travellers[key].name)
				this.travellerForm.controls.travellerList.controls[key].controls.txtEmployeeName.setValue(data.travellers[key].employee)
				this.travellerForm.controls.travellerList.controls[key].controls.txtEmployeeCode.setValue(data.travellers[key].e_code)
				this.travellerForm.controls.travellerList.controls[key].controls.txtEmployeeCostCenter.setValue(data.travellers[key].cost_center)
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierCode.setValue(data.travellers[key].payable_transaction_code)
				this.travellerForm.controls.travellerList.controls[key].controls.supplierDate.setValue(data.travellers[key].payable_date)
				this.travellerForm.controls.travellerList.controls[key].controls.txtbookingSupplier.setValue(data.travellers[key].booking_supplier)
				this.travellerForm.controls.travellerList.controls[key].controls.txtbookingSupplierName.setValue(data.travellers[key].booking_supplier_name?data.travellers[key].booking_supplier_name:"")
				this.travellerForm.controls.travellerList.controls[key].controls.txtbookingSupplierIsBranch.setValue(data.travellers[key].booking_supplier_name?data.travellers[key].booking_supplier_is_branch:false)
				this.travellerForm.controls.travellerList.controls[key].controls.associate_supplier.setValue(data.travellers[key].associate_supplier?data.travellers[key].associate_supplier:'')
				this.travellerForm.controls.travellerList.controls[key].controls.associate_supplier_name.setValue(data.travellers[key].associate_supplier_name?data.travellers[key].associate_supplier_name:'')

				try {
					let selectedSupplier = this.supplierList.filter(option => {
						return option.account_code == data.travellers[key].booking_supplier;
					});
					if (selectedSupplier.length > 0) {
						this.travellerForm.controls.travellerList.controls[key].controls.txtbookingSupplierName.setValue(selectedSupplier[0].name)
						this.travellerForm.controls.travellerList.controls[key].controls.txtbookingSupplierIsBranch.setValue(selectedSupplier[0].is_branch)
					}
				} catch (error) {

				}

				let generalNameWithAccount;
				try {
					let selectedGeneral = this.generalAccArr.filter(option => {
						return option.account_code == data.travellers[key].ledger_card_no;
					});

					if(selectedGeneral && selectedGeneral.length > 0) {
						generalNameWithAccount = selectedGeneral[0].name + '('+ selectedGeneral[0].account_code + ')';
					}
				} catch (error) {

				}

				this.travellerForm.controls.travellerList.controls[key].controls.ledger_card_no.setValue(data.travellers[key].ledger_card_no)
				this.travellerForm.controls.travellerList.controls[key].controls.ledger_card_name.setValue(generalNameWithAccount)

				this.travellerForm.controls.travellerList.controls[key].controls.type_of_pay.setValue(data.travellers[key].pay_type ? data.travellers[key].pay_type : '')

				// //SET FARE VALUE
				this.travellerForm.controls.travellerList.controls[key].controls.txtBaseFare.setValue(data.travellers[key]['fare'].base)
				this.travellerForm.controls.travellerList.controls[key].controls.txtYQ.setValue(data.travellers[key]['fare'].YQ)
				this.travellerForm.controls.travellerList.controls[key].controls.txtYR.setValue(data.travellers[key]['fare'].YR)
				this.travellerForm.controls.travellerList.controls[key].controls.txtK3.setValue(data.travellers[key]['fare'].K3)
				this.travellerForm.controls.travellerList.controls[key].controls.txtOC.setValue(data.travellers[key]['fare'].OC)
				this.travellerForm.controls.travellerList.controls[key].controls.txtOT.setValue(data.travellers[key]['fare'].OT)
				this.travellerForm.controls.travellerList.controls[key].controls.txtTicketNumber.setValue(data.travellers[key]['ticket_no'])
				this.travellerForm.controls.travellerList.controls[key].controls.prev_ticket_no.setValue(data.travellers[key]['prev_ticket_no'] ? data.travellers[key]['prev_ticket_no'] : data.travellers[key]['ticket_no'])

				if(data.travellers[key].hasOwnProperty('local_fare')) {
				this.travellerForm.controls.travellerList.controls[key].controls.localBaseFare.setValue(data.travellers[key]['local_fare'].base)
				this.travellerForm.controls.travellerList.controls[key].controls.localYQ.setValue(data.travellers[key]['local_fare'].YQ)
				this.travellerForm.controls.travellerList.controls[key].controls.localYR.setValue(data.travellers[key]['local_fare'].YR)
				this.travellerForm.controls.travellerList.controls[key].controls.localK3.setValue(data.travellers[key]['local_fare'].K3)
				this.travellerForm.controls.travellerList.controls[key].controls.localOC.setValue(data.travellers[key]['local_fare'].OC)
				this.travellerForm.controls.travellerList.controls[key].controls.localOT.setValue(data.travellers[key]['local_fare'].OT)
				}

				if(data.cancellation_response && data.cancellation_response.splitedPnr){
					this.travellerForm.controls.travellerList.controls[key].controls.newTicketNo.setValue(`${data.cancellation_response.splitedPnr}-${Number(key)+1}`)
					this.travellerForm.controls.travellerList.controls[key].controls.newPNRNo.setValue(data.cancellation_response.splitedPnr)
				}

				// Bind Client Commission 
				this.travellerForm.controls.travellerList.controls[key].controls.selClientIataType.setValue(data.travellers[key].commission.customer['iata_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.selClientIataValue.setValue(data.travellers[key].commission.customer['iata_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.selClientIataAmount.setValue(data.travellers[key].commission.customer['iata_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.selClientPlbType.setValue(data.travellers[key].commission.customer['plb_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.selClientPlbValue.setValue(data.travellers[key].commission.customer['plb_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.selClientPlbAmount.setValue(data.travellers[key].commission.customer['plb_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.selClientCbType.setValue(data.travellers[key].commission.customer['cashback_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.selClientCbValue.setValue(data.travellers[key].commission.customer['cashback_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.selClientCbAmount.setValue(data.travellers[key].commission.customer['cashback_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.selClientDsType.setValue(data.travellers[key].commission.customer['discount_type']?data.travellers[key].commission.customer['discount_type']:'VL')
				this.travellerForm.controls.travellerList.controls[key].controls.selClientDsValue.setValue(data.travellers[key].commission.customer['discount_value']?data.travellers[key].commission.customer['discount_value']:'0')
				this.travellerForm.controls.travellerList.controls[key].controls.selClientDsAmount.setValue(data.travellers[key].commission.customer['discount_amount']?data.travellers[key].commission.customer['discount_amount']:'0')

				// Bind Client Service Charge 
				let sc1_value = data.travellers[key].service_charge.customer['sc1_value']
				let sc1_amount = data.travellers[key].service_charge.customer['sc1_amount']
				let sc2_value = data.travellers[key].service_charge.customer['sc2_value']
				let sc2_amount = data.travellers[key].service_charge.customer['sc2_amount']
				let sc3_value = data.travellers[key].service_charge.customer['sc3_value']
				let sc3_amount = data.travellers[key].service_charge.customer['sc3_amount']
				let sc2_type,sc2_taxable,sc3_type,sc3_taxable
				if(['N','B','T','Y'].includes(data.travellers[key].service_charge.customer['sc2_type'])){
					sc2_type = data.travellers[key].service_charge.customer['sc2_type']=="B"?"BASE":data.travellers[key].service_charge.customer['sc2_type']=="Y"?"YQ":'OT'
					sc2_taxable = data.travellers[key].service_charge.customer['sc2_type']=="T"?"T":"N"
					
				}
				if(['N','B','T','Y'].includes(data.travellers[key].service_charge.customer['sc3_type'])){
					
					sc3_type = data.travellers[key].service_charge.customer['sc3_type']=="B"?"BASE":data.travellers[key].service_charge.customer['sc3_type']=="Y"?"YQ":'OT'
					sc3_taxable = data.travellers[key].service_charge.customer['sc3_type']=="T"?"T":"N"
				}
				if(this.invoiceMode == 'create'){
					 sc1_value = 0
					 sc1_amount = 0
					//  sc2_value = 0
					//  sc2_amount = 0
					//  sc3_value = 0
					//  sc3_amount = 0
				}
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC1Type.setValue(data.travellers[key].service_charge.customer['sc1_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC1Value.setValue(sc1_value)
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC1Amount.setValue(sc1_amount)

				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC2Type.setValue(sc2_type?sc2_type:data.travellers[key].service_charge.customer['sc2_type']?data.travellers[key].service_charge.customer['sc2_type']:'OT')
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC2Taxable.setValue(sc2_taxable?sc2_taxable:data.travellers[key].service_charge.customer['sc2_taxable']?data.travellers[key].service_charge.customer['sc2_taxable']:'N')
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC2Value.setValue(sc2_value)
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC2Amount.setValue(sc2_amount)

				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC3Type.setValue(sc3_type?sc3_type:data.travellers[key].service_charge.customer['sc3_type']?data.travellers[key].service_charge.customer['sc3_type']:'OT')
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC3Taxable.setValue(sc3_taxable?sc3_taxable:data.travellers[key].service_charge.customer['sc3_taxable']?data.travellers[key].service_charge.customer['sc3_taxable']:'N')
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC3Value.setValue(sc3_value)
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC3Amount.setValue(sc3_amount)
				//Bind Supplier RAF and XXL Charges 

				let rafAmountClient = data.travellers[key].service_charge.customer['raf'] ? data.travellers[key].service_charge.customer['raf'] : 0
				let xxlAmountClient = data.travellers[key].service_charge.customer['xxl'] ? data.travellers[key].service_charge.customer['xxl'] : 0
				let xxlAmtClient = data.cancellation_response && data.cancellation_response.client && data.cancellation_response.client.XXL?data.cancellation_response.client.XXL/data.travellers.length:xxlAmountClient

				let rafAmtClient = data.cancellation_response && data.cancellation_response.client && data.cancellation_response.client.RAF?data.cancellation_response.client.RAF/data.travellers.length:rafAmountClient
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientRAFAmount.setValue(Number(rafAmtClient).toFixed(2))
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientXXLAmount.setValue(Number(xxlAmtClient).toFixed(2))




				// Bind Supplier Commission 
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierIataType.setValue(data.travellers[key].commission.supplier['iata_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierIataValue.setValue(data.travellers[key].commission.supplier['iata_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierIataAmount.setValue(data.travellers[key].commission.supplier['iata_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierCbType.setValue(data.travellers[key].commission.supplier['cashback_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierCbValue.setValue(data.travellers[key].commission.supplier['cashback_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierCbAmount.setValue(data.travellers[key].commission.supplier['cashback_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierPlbType.setValue(data.travellers[key].commission.supplier['plb_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierPlbValue.setValue(data.travellers[key].commission.supplier['plb_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierPlbAmount.setValue(data.travellers[key].commission.supplier['plb_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierDsType.setValue(data.travellers[key].commission.supplier['discount_type']?data.travellers[key].commission.supplier['discount_type']:'VL')
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierDsValue.setValue(data.travellers[key].commission.supplier['discount_value']?data.travellers[key].commission.supplier['discount_value']:'0')
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierDsAmount.setValue(data.travellers[key].commission.supplier['discount_amount']?data.travellers[key].commission.supplier['discount_amount']:'0')

				// Bind Supplier Service Charge 

				let supplier_sc1_value = data.travellers[key].service_charge.supplier['sc1_value']
				let supplier_sc1_amount = data.travellers[key].service_charge.supplier['sc1_amount']
				let supplier_sc2_value = data.travellers[key].service_charge.supplier['sc2_value']
				let supplier_sc2_amount = data.travellers[key].service_charge.supplier['sc2_amount']
				let supplier_sc3_value = data.travellers[key].service_charge.supplier['sc3_value']
				let supplier_sc3_amount = data.travellers[key].service_charge.supplier['sc3_amount']
				if(this.invoiceMode == 'create'){
					supplier_sc1_value = 0
					supplier_sc1_amount = 0
					// supplier_sc2_value = 0
					// supplier_sc2_amount = 0
					// supplier_sc3_value = 0
					// supplier_sc3_amount = 0
				}
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC1Type.setValue(data.travellers[key].service_charge.supplier['sc1_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC1Value.setValue(supplier_sc1_value)
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC1Amount.setValue(supplier_sc1_amount)

				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC2Type.setValue(data.travellers[key].service_charge.supplier['sc2_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC2Value.setValue(supplier_sc2_value)
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC2Amount.setValue(supplier_sc2_amount)

				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC3Type.setValue(data.travellers[key].service_charge.supplier['sc3_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC3Value.setValue(supplier_sc3_value)
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC3Amount.setValue(supplier_sc3_amount)

				//Bind Supplier RAF and XXL Charges 
				let rafAmountSupplier = data.travellers[key].service_charge.supplier['raf'] ? data.travellers[key].service_charge.supplier['raf'] : 0
				let rafAmtSupplier = data.cancellation_response && data.cancellation_response.supplier && data.cancellation_response.supplier.RAF?data.cancellation_response.supplier.RAF/data.travellers.length:rafAmountSupplier
				
				let xxlAmountSupplier = data.travellers[key].service_charge.supplier['xxl'] ? data.travellers[key].service_charge.supplier['xxl'] : 0
				let xxlAmtSupplier = data.cancellation_response && data.cancellation_response.supplier && data.cancellation_response.supplier.XXL?data.cancellation_response.supplier.XXL/data.travellers.length:xxlAmountSupplier
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierRAFAmount.setValue(Number(rafAmtSupplier).toFixed(2))
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierXXLAmount.setValue(Number(xxlAmtSupplier).toFixed(2))
				if(data.travellers[key].dis_recall){
					// bind disc recall client
					this.travellerForm.controls.travellerList.controls[key].controls.disClientRecallType.setValue(data.travellers[key].dis_recall.customer['dis_recall_type'])
					this.travellerForm.controls.travellerList.controls[key].controls.disClientRecallValue.setValue(data.travellers[key].dis_recall.customer['dis_recall_value'])
					this.travellerForm.controls.travellerList.controls[key].controls.disClientRecallAmount.setValue(data.travellers[key].dis_recall.customer['dis_recall_amount'])

					// bind disc recall for supplier
					this.travellerForm.controls.travellerList.controls[key].controls.disSupplierRecallType.setValue(data.travellers[key].dis_recall.supplier['dis_recall_type'])
					this.travellerForm.controls.travellerList.controls[key].controls.disSupplierRecallValue.setValue(data.travellers[key].dis_recall.supplier['dis_recall_value'])
					this.travellerForm.controls.travellerList.controls[key].controls.disSupplierRecallAmount.setValue(data.travellers[key].dis_recall.supplier['dis_recall_amount'])

				}else{
					reqObj['date'] = data.travellers[key].date
					if(data.travellers[key].booking_supplier){
						reqObj['airlineCode'] = data.travellers[key].booking_supplier
					}
					let resp = await this.getDiscRecallSetting(reqObj,key)
				}

				//CALCULATE TOTALs
				this.calculateTotal(key)

			}
		}



		let sectorArray = [];
		for (let i = 0; i < data.sectors.length; i++) {
			sectorArray.push(data.sectors[i].departure_airport_details.code);
			sectorArray.push(data.sectors[i].arrival_airport_details.code);
		}

		let uniqueSector = this.onlyUnique(sectorArray)
		let sectorComb1 = uniqueSector.join('/');

		await this.bindSector1(sectorComb1);
		if (data.sectorComb1 != undefined) {
			this.sectorForm.controls.txtSector1.setValue(data.sectorComb1);
		}
		if (data.sectorComb2 != null && data.sectorComb2 != '') {
			this.sectorForm.controls.txtSector2.setValue(data.sectorComb2);
		}

		//Bind SSR Charges
		for (const key in data.travellers) {
			if (data.travellers.hasOwnProperty(key)) {
				const travellerData = data.travellers[key];
				const travellerControl = this.travellerList.controls[key];
				let sectorList = travellerControl.get('sectorList');
				for (const sectorKey in sectorList['controls']) {
					if (sectorList['controls'].hasOwnProperty(sectorKey)) {
						const sectorSsrData = travellerData.ssr[sectorKey];
						if (sectorSsrData) {
							const sectorControl = sectorList['controls'][sectorKey];

						sectorControl.controls.txtSeatCharges.setValue(sectorSsrData.seat.amount)
						sectorControl.controls.txtSeatCode.setValue(sectorSsrData.seat.code)

						sectorControl.controls.txtMealCharges.setValue(sectorSsrData.meal.amount)
						sectorControl.controls.txtMealCode.setValue(sectorSsrData.meal.code)

						sectorControl.controls.txtBagCharges.setValue(sectorSsrData.baggage.amount)
						sectorControl.controls.txtBagCode.setValue(sectorSsrData.baggage.code)
						sectorControl.controls.txtAdditionalBaggageCharges.setValue(sectorSsrData.additionalBaggage ? sectorSsrData.additionalBaggage.amount : 0)
						sectorControl.controls.txtAdditionalBaggageCode.setValue(sectorSsrData.additionalBaggage ? sectorSsrData.additionalBaggage.code : 0)

						sectorControl.controls.txtAncillaryCharges.setValue(sectorSsrData.ancillary ? sectorSsrData.ancillary.amount : 0)
						sectorControl.controls.txtAncillaryCode.setValue(sectorSsrData.ancillary ? sectorSsrData.ancillary.code : 0)
						}
					}
				}
				this.calculateTotal(key)
			}
		}
		for (const key in data.sectors) {

			if (data.sectors.hasOwnProperty(key)) {
				const sector = data.sectors[key];
				let sectorName = `${sector.departure_airport_details.code}/${sector.arrival_airport_details.code}`
				let index = key
				if (this.sectorForm.controls.sectorlist.controls[key].value.txtSectorComb != sectorName) {
					this.sectorForm.controls.sectorlist.removeAt(key)
					// index = (Number(key)+1).toString()
					this.removeSectorFromTravellers(key)
				}
				this.invoiceForm.controls['pnr'].setValue(sector.pnr)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtAirlinePnrX.setValue(sector.legs.airline_pnr ? sector.legs.airline_pnr : sector.pnr)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtAirlinePrefix.setValue(sector.legs.airline_code)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtFlightNo.setValue(sector.legs.flight_no)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtClass.setValue(sector.legs.service_class)
				this.sectorForm.controls.sectorlist.controls[index].controls.dateAirlineDepartInfo.setValue(moment(sector.legs.departure_airport_details.date_time).format('YYYY-MM-DD'))
				this.sectorForm.controls.sectorlist.controls[index].controls.dateAirlineArrivalInfo.setValue(moment(sector.legs.arrival_airport_details.date_time).format('YYYY-MM-DD'))
				this.sectorForm.controls.sectorlist.controls[index].controls.arrivalCity.setValue(sector.legs.arrival_airport_details.city)
				this.sectorForm.controls.sectorlist.controls[index].controls.arrivalCityCode.setValue(sector.legs.arrival_airport_details.code)
				this.sectorForm.controls.sectorlist.controls[index].controls.departureCity.setValue(sector.legs.departure_airport_details.city)
				this.sectorForm.controls.sectorlist.controls[index].controls.departureCityCode.setValue(sector.legs.departure_airport_details.code)
				// 		this.sectorForm.controls.sectorlist.controls[key].controls.txtBaggage.setValue(sector.baggage)

				// 		//SET AIRPORT INFO 
				// 		let departTerminal = sector.departureTernimal ? `, ${sector.departureTernimal}` : "";
				// 		let arrivalTerminal = sector.arrivalTernimal ? `, ${sector.arrivalTernimal}` : "";
				// 		let departInfo = sector.departureCity + ', ' + sector.departureAirport + departTerminal;
				// 		let arrivalinfo = sector.arrivalCity + ', ' + sector.arrivalAirport +  arrivalTerminal;

				// 		this.sectorForm.controls.sectorlist.controls[key].controls.txtAirlineDepartInfo.setValue(departInfo)
				// 		this.sectorForm.controls.sectorlist.controls[key].controls.txtAirlineArrivalInfo.setValue(arrivalinfo)

			}
		}

		for (const key in data.travellers) {
			if (data.travellers.hasOwnProperty(key)) {
				const traveller = data.travellers[key];
				for (const segKey in traveller.segments) {
					if (traveller.segments.hasOwnProperty(segKey)) {
						const segment = traveller.segments[segKey];
						for (const secKey in segment) {
							if (segment.hasOwnProperty(secKey)) {
								const element = segment[secKey];
								if (element.status == 'REQUESTED') {
									let bindSectorCancelStatusRes = this.bindSectorCancelStatus(element.sector, key)
								}
							}
						}
					}
				}
				if (data.sectors.length > 0) {
					let sectorList = this.travellerForm.get("travellerList").controls[key].get('sectorList');
					if (sectorList != undefined) {
						for (const key in sectorList.value) {
							if (sectorList.value.hasOwnProperty(key)) {
								const element = sectorList.value[key];
								sectorList.controls[key].controls.isCancelRequest.setValue(true)
							}
						}
					}
				}
			}
		}
		let obj = {
			type: 'credit_note',
			index: index,
			req_from : "bindCreditNoteData",
			"use_form" : "travellerForm"
		}
		this.componentMethodCallSource.next(obj);
		return ""
	}
	removeSectorFromTravellers(sectorIndex) {
		for (let i = 0; i < this.travellerList.length; i++) {
			this.travellerForm.get("travellerList").controls[i].get('sectorList').removeAt(sectorIndex)
		}
	}
	bindSectorCancelStatus(sector: any, travellerIndex) {
		sector = sector.split('-').join('/')
		let isRequested = false
		let sectorList = this.travellerForm.get("travellerList").controls[travellerIndex].get('sectorList');
		if (sectorList != undefined) {
			for (const key in sectorList.value) {
				if (sectorList.value.hasOwnProperty(key)) {
					const element = sectorList.value[key];
					if (element.sector == sector) {
						sectorList.controls[key].controls.isCancelRequest.setValue(true)
					}
				}
			}
		}
		return isRequested;
	}

	async bindInvoiceData(data, index, invoiceType) {
		console.log('this.invoiceRootData', this.invoiceRootData);
		
		this.invoiceTravellerForm[index] = this.fb.group({
			travellerList: this.fb.array([]),
		});
		this.invoiceTravellerList[index] = this.invoiceTravellerForm[index].get("travellerList") as FormArray;
		this.invoiceTravellerList[index].controls = [];
		this.invoiceForm.controls.airline.setValue(data.airline)
		for (const key in data.travellers) {
			if (data.travellers.hasOwnProperty(key)) {
				this.invoiceTravellerList[index].push(this.buildTraveller(data.travellers[key]));

				//SET VALUE
				if (invoiceType == 'refund') {
					let cancelledObj = {
						ticket_no: data.travellers[key]['ticket_no'],
						sectors: data.travellers[key].cancel_sectors
					}
					this.cancelledTicket.push(cancelledObj)
				}
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selTitle.setValue(data.travellers[key].title)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selType.setValue(data.travellers[key].type)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtPassengerName.setValue(data.travellers[key].name)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtEmployeeName.setValue(data.travellers[key].employee)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtEmployeeCode.setValue(data.travellers[key].e_code)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtEmployeeCostCenter.setValue(data.travellers[key].cost_center)		
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierCode.setValue(data.travellers[key].payable_transaction_code)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.supplierDate.setValue(data.travellers[key].payable_date)			
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtbookingSupplier.setValue(data.travellers[key].booking_supplier)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.associate_supplier.setValue(data.travellers[key].associate_supplier)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.associate_supplier_name.setValue(data.travellers[key].associate_supplier_name)

				try {
					let selectedSupplier = this.supplierList.filter(option => {
						return option.account_code == data.travellers[key].booking_supplier;
					});
					if (selectedSupplier.length > 0) {
						this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtbookingSupplierName.setValue(selectedSupplier[0].name)
						this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtbookingSupplierIsBranch.setValue(selectedSupplier[0].is_branch)					}
				} catch (error) {

				}
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.ledger_card_no.setValue(data.travellers[key].ledger_card_no)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.ledger_card_name.setValue(data.travellers[key].ledger_card_name)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.type_of_pay.setValue(data.travellers[key].pay_type ? data.travellers[key].pay_type : '')

				// //SET FARE VALUE
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtBaseFare.setValue(data.travellers[key]['fare'].base)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtYQ.setValue(data.travellers[key]['fare'].YQ)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtYR.setValue(data.travellers[key]['fare'].YR)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtK3.setValue(data.travellers[key]['fare'].K3)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtOC.setValue(data.travellers[key]['fare'].OC)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtOT.setValue(data.travellers[key]['fare'].OT)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtAddons.setValue(data.travellers[key]['fare'].addons)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtTicketNumber.setValue(data.travellers[key]['ticket_no'])

				if(data.travellers[key].hasOwnProperty('local_fare')){
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.localBaseFare.setValue(data.travellers[key]['local_fare'].base)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.localYQ.setValue(data.travellers[key]['local_fare'].YQ)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.localYR.setValue(data.travellers[key]['local_fare'].YR)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.localK3.setValue(data.travellers[key]['local_fare'].K3)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.localOC.setValue(data.travellers[key]['local_fare'].OC)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.localOT.setValue(data.travellers[key]['local_fare'].OT)
				}

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.prev_ticket_no.setValue(data.travellers[key]['prev_ticket_no'] ? data.travellers[key]['prev_ticket_no'] : data.travellers[key]['ticket_no'])
				if(data.cancellation_response && data.cancellation_response.splitedPnr){
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.newTicketNo.setValue(`${data.cancellation_response.splitedPnr}-${Number(key)+1}`)
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.newPNRNo.setValue(data.cancellation_response.splitedPnr)
				}
				

				// Bind Client Commission 
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientIataType.setValue(data.travellers[key].commission.customer['iata_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientIataValue.setValue(data.travellers[key].commission.customer['iata_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientIataAmount.setValue(data.travellers[key].commission.customer['iata_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientPlbType.setValue(data.travellers[key].commission.customer['plb_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientPlbValue.setValue(data.travellers[key].commission.customer['plb_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientPlbAmount.setValue(data.travellers[key].commission.customer['plb_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientCbType.setValue(data.travellers[key].commission.customer['cashback_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientCbValue.setValue(data.travellers[key].commission.customer['cashback_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientCbAmount.setValue(data.travellers[key].commission.customer['cashback_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientDsType.setValue(data.travellers[key].commission.customer['discount_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientDsValue.setValue(data.travellers[key].commission.customer['discount_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientDsAmount.setValue(data.travellers[key].commission.customer['discount_amount'])

				// Bind Client Service Charge 

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC1Type.setValue(data.travellers[key].service_charge.customer['sc1_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC1Value.setValue(data.travellers[key].service_charge.customer['sc1_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC1Amount.setValue(data.travellers[key].service_charge.customer['sc1_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC2Type.setValue(data.travellers[key].service_charge.customer['sc2_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC2Taxable.setValue(data.travellers[key].service_charge.customer['sc2_taxable'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC2Value.setValue(data.travellers[key].service_charge.customer['sc2_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC2Amount.setValue(data.travellers[key].service_charge.customer['sc2_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC3Type.setValue(data.travellers[key].service_charge.customer['sc3_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC3Taxable.setValue(data.travellers[key].service_charge.customer['sc3_taxable'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC3Value.setValue(data.travellers[key].service_charge.customer['sc3_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC3Amount.setValue(data.travellers[key].service_charge.customer['sc3_amount'])

				//Bind Client RAF and XXL Charges 
				let rafAmountClient = data.travellers[key].service_charge.customer['raf'] ? data.travellers[key].service_charge.customer['raf'] : 0
				let xxlAmountClient = data.travellers[key].service_charge.customer['xxl'] ? data.travellers[key].service_charge.customer['xxl'] : 0
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientRAFAmount.setValue(rafAmountClient)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientXXLAmount.setValue(xxlAmountClient)





				// Bind Supplier Commission 
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierIataType.setValue(data.travellers[key].commission.supplier['iata_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierIataValue.setValue(data.travellers[key].commission.supplier['iata_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierIataAmount.setValue(data.travellers[key].commission.supplier['iata_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierCbType.setValue(data.travellers[key].commission.supplier['cashback_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierCbValue.setValue(data.travellers[key].commission.supplier['cashback_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierCbAmount.setValue(data.travellers[key].commission.supplier['cashback_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierPlbType.setValue(data.travellers[key].commission.supplier['plb_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierPlbValue.setValue(data.travellers[key].commission.supplier['plb_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierPlbAmount.setValue(data.travellers[key].commission.supplier['plb_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierDsType.setValue(data.travellers[key].commission.supplier['discount_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierDsValue.setValue(data.travellers[key].commission.supplier['discount_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierDsAmount.setValue(data.travellers[key].commission.supplier['discount_amount'])

				// Bind Supplier Service Charge 

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC1Type.setValue(data.travellers[key].service_charge.supplier['sc1_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC1Value.setValue(data.travellers[key].service_charge.supplier['sc1_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC1Amount.setValue(data.travellers[key].service_charge.supplier['sc1_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC2Type.setValue(data.travellers[key].service_charge.supplier['sc2_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC2Value.setValue(data.travellers[key].service_charge.supplier['sc2_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC2Amount.setValue(data.travellers[key].service_charge.supplier['sc2_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC3Type.setValue(data.travellers[key].service_charge.supplier['sc3_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC3Value.setValue(data.travellers[key].service_charge.supplier['sc3_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC3Amount.setValue(data.travellers[key].service_charge.supplier['sc3_amount'])

				//Bind Supplier RAF and XXL Charges
				let rafAmountSupplier = data.travellers[key].service_charge.supplier['raf'] ? data.travellers[key].service_charge.supplier['raf'] : 0
				let xxlAmountSupplier = data.travellers[key].service_charge.supplier['xxl'] ? data.travellers[key].service_charge.supplier['xxl'] : 0
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierRAFAmount.setValue(rafAmountSupplier)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierXXLAmount.setValue(xxlAmountSupplier)
				if(data.travellers[key].dis_recall){
					// bind disc recall client
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.disClientRecallType.setValue(data.travellers[key].dis_recall.customer['dis_recall_type'])
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.disClientRecallValue.setValue(data.travellers[key].dis_recall.customer['dis_recall_value'])
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.disClientRecallAmount.setValue(data.travellers[key].dis_recall.customer['dis_recall_amount'])

					// bind disc recall for supplier
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.disSupplierRecallType.setValue(data.travellers[key].dis_recall.supplier['dis_recall_type'])
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.disSupplierRecallValue.setValue(data.travellers[key].dis_recall.supplier['dis_recall_value'])
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.disSupplierRecallAmount.setValue(data.travellers[key].dis_recall.supplier['dis_recall_amount'])

				}
			}
		}
		let sectorArray = [];
		for (let i = 0; i < data.sectors.length; i++) {
			sectorArray.push(data.sectors[i].departure_airport_details.code);
			sectorArray.push(data.sectors[i].arrival_airport_details.code);
		}

		let uniqueSector = this.onlyUnique(sectorArray)
		let sectorComb1 = uniqueSector.join('/');

		await this.bindInvoiceSector1(sectorComb1, index);
		// console.log('Invoice traveller form : ',this.invoiceTravellerForm.value)


		for (const key in data.travellers) {
			if (data.travellers.hasOwnProperty(key)) {
				const travellerData = data.travellers[key];
				const travellerControl = this.invoiceTravellerForm[index].controls.travellerList.controls[key]
				// const travellerControl = this.travellerList.controls[key];
				let sectorList = travellerControl.get('sectorList');
				for (const sectorKey in sectorList['controls']) {
					if (sectorList['controls'].hasOwnProperty(sectorKey)) {
						const sectorSsrData = travellerData.ssr[sectorKey];
						if (sectorSsrData) {
							const sectorControl = sectorList['controls'][sectorKey];
						sectorControl.controls.txtSeatCharges.setValue(sectorSsrData.seat.amount)
						sectorControl.controls.txtSeatCode.setValue(sectorSsrData.seat.code)

						sectorControl.controls.txtMealCharges.setValue(sectorSsrData.meal.amount)
						sectorControl.controls.txtMealCode.setValue(sectorSsrData.meal.code)

						sectorControl.controls.txtBagCharges.setValue(sectorSsrData.baggage.amount)
						sectorControl.controls.txtBagCode.setValue(sectorSsrData.baggage.code)
						sectorControl.controls.txtAdditionalBaggageCharges.setValue(sectorSsrData.additionalBaggage ? sectorSsrData.additionalBaggage.amount : 0)
						sectorControl.controls.txtAdditionalBaggageCode.setValue(sectorSsrData.additionalBaggage ? sectorSsrData.additionalBaggage.code : 0)

						sectorControl.controls.txtAncillaryCharges.setValue(sectorSsrData.ancillary ? sectorSsrData.ancillary.amount : 0)
						sectorControl.controls.txtAncillaryCode.setValue(sectorSsrData.ancillary ? sectorSsrData.ancillary.code : 0)
						}
					}
				}
				this.calculateInvoiceTotal(index, key)
			}
		}

		let type = 'invoice'
		if (invoiceType == 'refund') {
			type = 'credit_note'
		}
		let obj = {
			type: type,
			index: index,
			req_from : "bindInvoiceData",
			"use_form" : "invoiceTravellerForm"
		}
		this.componentMethodCallSource.next(obj);
		return ""
		// this.componentMethodCallSource.next(Number(index)+1);

	}
	async bindInvoiceDataV2(data, index, invoiceType) {
		this.invoiceTravellerForm[index] = this.fb.group({
			travellerList: this.fb.array([]),
		});
		this.invoiceTravellerList[index] = this.invoiceTravellerForm[index].get("travellerList") as FormArray;
		this.invoiceTravellerList[index].controls = [];
		// let crsId = data.crs_id_value ? data.crs_id_value : 'website'
		// this.invoiceForm.controls.crsId.setValue(crsId)
		// this.invoiceForm.controls.supplier.setValue(data.supplier)
		// this.invoiceForm.controls.invoiceType.setValue(data.invoiceType)
		for (const key in data.travellers) {
			if (data.travellers.hasOwnProperty(key)) {
				this.invoiceTravellerList[index].push(this.buildTraveller(data.travellers[key]));

				//SET VALUE
				if (invoiceType == 'refund' || invoiceType == 'credit_note') {
					let cancelledObj = {
						ticket_no: data.travellers[key]['ticket_no'],
						sectors: data.travellers[key].cancel_sectors
					}
					this.cancelledTicket.push(cancelledObj)
				}
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selTitle.setValue(data.travellers[key].title)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selType.setValue(data.travellers[key].type)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtPassengerName.setValue(data.travellers[key].name)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtEmployeeName.setValue(data.travellers[key].employee)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtEmployeeCode.setValue(data.travellers[key].e_code)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtEmployeeCostCenter.setValue(data.travellers[key].cost_center)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierCode.setValue(data.travellers[key].payable_transaction_code)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.supplierDate.setValue(data.travellers[key].payable_date)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtbookingSupplier.setValue(data.travellers[key].booking_supplier)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.associate_supplier.setValue(data.travellers[key].associate_supplier)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.associate_supplier_name.setValue(data.travellers[key].associate_supplier_name)

				if (this.invoiceForm.value.invoiceType == "C") {
					this.enablePayable = true
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtbookingSupplier.setValidators(Validators.required)
				}else{
					this.enablePayable = false
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtbookingSupplier.setValidators(null)
				}
				if (data.travellers[key].type == 'OUR_CC') {
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.ledger_card_no.setValidators(Validators.required)
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.ledger_card_name.setValidators(Validators.required)
				}else{
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.ledger_card_no.setValidators(null)
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.ledger_card_name.setValidators(null)
				}
				if (this.invoiceForm.value.invoiceType == "B") {
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls['txtbookingSupplier'].setValue('BSP')
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls['txtbookingSupplierName'].setValue('799')
				}

				try {
					let selectedSupplier = this.supplierList.filter(option => {
						return option.account_code == data.travellers[key].booking_supplier;
					});
					if (selectedSupplier.length > 0) {
						this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtbookingSupplierName.setValue(selectedSupplier[0].name)
						this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtbookingSupplierIsBranch.setValue(selectedSupplier[0].is_branch)
					}
				} catch (error) {

				}
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.ledger_card_no.setValue(data.travellers[key].ledger_card_no)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.ledger_card_name.setValue(data.travellers[key].ledger_card_name)

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.type_of_pay.setValue(data.travellers[key].pay_type ? data.travellers[key].pay_type : '')

				// //SET FARE VALUE
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtBaseFare.setValue(data.travellers[key]['fare'].base)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtYQ.setValue(data.travellers[key]['fare'].YQ)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtYR.setValue(data.travellers[key]['fare'].YR)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtK3.setValue(data.travellers[key]['fare'].K3)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtOC.setValue(data.travellers[key]['fare'].OC)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtOT.setValue(data.travellers[key]['fare'].OT)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtAddons.setValue(data.travellers[key]['fare'].addons)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtTicketNumber.setValue(data.travellers[key]['ticket_no'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.prev_ticket_no.setValue(data.travellers[key]['prev_ticket_no'] ? data.travellers[key]['prev_ticket_no'] : data.travellers[key]['ticket_no'])

				if(data.travellers[key].hasOwnProperty('local_fare')){
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.localBaseFare.setValue(data.travellers[key]['local_fare'].base)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.localYQ.setValue(data.travellers[key]['local_fare'].YQ)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.localYR.setValue(data.travellers[key]['local_fare'].YR)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.localK3.setValue(data.travellers[key]['local_fare'].K3)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.localOC.setValue(data.travellers[key]['local_fare'].OC)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.localOT.setValue(data.travellers[key]['local_fare'].OT)
				}

				if(data.cancellation_response && data.cancellation_response.splitedPnr){
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.newTicketNo.setValue(`${data.cancellation_response.splitedPnr}-${Number(key)+1}`)
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.newPNRNo.setValue(data.cancellation_response.splitedPnr)
				}

				// Bind Client Commission 
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientIataType.setValue(data.travellers[key].commission.customer['iata_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientIataValue.setValue(data.travellers[key].commission.customer['iata_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientIataAmount.setValue(data.travellers[key].commission.customer['iata_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientPlbType.setValue(data.travellers[key].commission.customer['plb_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientPlbValue.setValue(data.travellers[key].commission.customer['plb_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientPlbAmount.setValue(data.travellers[key].commission.customer['plb_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientCbType.setValue(data.travellers[key].commission.customer['cashback_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientCbValue.setValue(data.travellers[key].commission.customer['cashback_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientCbAmount.setValue(data.travellers[key].commission.customer['cashback_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientDsType.setValue(data.travellers[key].commission.customer['discount_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientDsValue.setValue(data.travellers[key].commission.customer['discount_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selClientDsAmount.setValue(data.travellers[key].commission.customer['discount_amount'])

				// Bind Client Service Charge 

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC1Type.setValue(data.travellers[key].service_charge.customer['sc1_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC1Value.setValue(data.travellers[key].service_charge.customer['sc1_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC1Amount.setValue(data.travellers[key].service_charge.customer['sc1_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC2Type.setValue(data.travellers[key].service_charge.customer['sc2_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC2Taxable.setValue(data.travellers[key].service_charge.customer['sc2_taxable'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC2Value.setValue(data.travellers[key].service_charge.customer['sc2_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC2Amount.setValue(data.travellers[key].service_charge.customer['sc2_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC3Type.setValue(data.travellers[key].service_charge.customer['sc3_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC3Taxable.setValue(data.travellers[key].service_charge.customer['sc3_taxable'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC3Value.setValue(data.travellers[key].service_charge.customer['sc3_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientSC3Amount.setValue(data.travellers[key].service_charge.customer['sc3_amount'])

				//Bind Client RAF and XXL Charges 
				let rafAmountClient = data.travellers[key].service_charge.customer['raf'] ? data.travellers[key].service_charge.customer['raf'] : 0
				let xxlAmountClient = data.travellers[key].service_charge.customer['xxl'] ? data.travellers[key].service_charge.customer['xxl'] : 0
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientRAFAmount.setValue(rafAmountClient)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtClientXXLAmount.setValue(xxlAmountClient)





				// Bind Supplier Commission 
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierIataType.setValue(data.travellers[key].commission.supplier['iata_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierIataValue.setValue(data.travellers[key].commission.supplier['iata_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierIataAmount.setValue(data.travellers[key].commission.supplier['iata_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierCbType.setValue(data.travellers[key].commission.supplier['cashback_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierCbValue.setValue(data.travellers[key].commission.supplier['cashback_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierCbAmount.setValue(data.travellers[key].commission.supplier['cashback_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierPlbType.setValue(data.travellers[key].commission.supplier['plb_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierPlbValue.setValue(data.travellers[key].commission.supplier['plb_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierPlbAmount.setValue(data.travellers[key].commission.supplier['plb_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierDsType.setValue(data.travellers[key].commission.supplier['discount_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierDsValue.setValue(data.travellers[key].commission.supplier['discount_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.selSupplierDsAmount.setValue(data.travellers[key].commission.supplier['discount_amount'])
				// Bind Supplier Service Charge 

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC1Type.setValue(data.travellers[key].service_charge.supplier['sc1_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC1Value.setValue(data.travellers[key].service_charge.supplier['sc1_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC1Amount.setValue(data.travellers[key].service_charge.supplier['sc1_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC2Type.setValue(data.travellers[key].service_charge.supplier['sc2_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC2Value.setValue(data.travellers[key].service_charge.supplier['sc2_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC2Amount.setValue(data.travellers[key].service_charge.supplier['sc2_amount'])

				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC3Type.setValue(data.travellers[key].service_charge.supplier['sc3_type'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC3Value.setValue(data.travellers[key].service_charge.supplier['sc3_value'])
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierSC3Amount.setValue(data.travellers[key].service_charge.supplier['sc3_amount'])

				//Bind Supplier RAF and XXL Charges
				let rafAmountSupplier = data.travellers[key].service_charge.supplier['raf'] ? data.travellers[key].service_charge.supplier['raf'] : 0
				let xxlAmountSupplier = data.travellers[key].service_charge.supplier['xxl'] ? data.travellers[key].service_charge.supplier['xxl'] : 0
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierRAFAmount.setValue(rafAmountSupplier)
				this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.txtSupplierXXLAmount.setValue(xxlAmountSupplier)
				if(data.travellers[key].dis_recall){
					// bind disc recall client
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.disClientRecallType.setValue(data.travellers[key].dis_recall.customer['dis_recall_type'])
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.disClientRecallValue.setValue(data.travellers[key].dis_recall.customer['dis_recall_value'])
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.disClientRecallAmount.setValue(data.travellers[key].dis_recall.customer['dis_recall_amount'])

					// bind disc recall for supplier
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.disSupplierRecallType.setValue(data.travellers[key].dis_recall.supplier['dis_recall_type'])
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.disSupplierRecallValue.setValue(data.travellers[key].dis_recall.supplier['dis_recall_value'])
					this.invoiceTravellerForm[index].controls.travellerList.controls[key].controls.disSupplierRecallAmount.setValue(data.travellers[key].dis_recall.supplier['dis_recall_amount'])

				}
			}
		}
		let sectorArray = [];
		for (let i = 0; i < data.sectors.length; i++) {
			sectorArray.push(data.sectors[i].departure_airport_details.code);
			sectorArray.push(data.sectors[i].arrival_airport_details.code);
		}

		let uniqueSector = this.onlyUnique(sectorArray)
		let sectorComb1 = uniqueSector.join('/');

		await this.bindInvoiceSector1(sectorComb1, index);
		// console.log('Invoice traveller form : ',this.invoiceTravellerForm.value)
		let type = 'invoice'
		if (invoiceType == 'refund' || invoiceType == 'credit_note') {
			type = 'credit_note'
		}
		let obj = {
			type: type,
			index: Number(index),
			req_from : "bindInvoiceDataV2",
			"use_form" : "invoiceTravellerForm"
		}
		console.log(`Traveller value :`,this.invoiceTravellerForm[index].controls.travellerList.value)
		this.componentMethodCallSource.next(obj);
		return ""

	}

	async bindInvoiceSector1(value, index) {
		let sectorValue = value;
		let currentSectorArray = sectorValue.split("/");
		let currentVal = currentSectorArray.join("");
		if (currentVal.match(/.{1,3}/g) != null) {
			if (currentSectorArray.length > 0) {
				let lastvalueOfCurrentSector = currentSectorArray[currentSectorArray.length - 1];
				if (lastvalueOfCurrentSector.length == 3) {
					let travellerList = this.invoiceTravellerForm[index].get("travellerList") as FormArray;
					this.totalIndexInTraveller = 0
					for (let i = 0; i < travellerList.length; i++) {
						let sectorList = this.invoiceTravellerForm[index].get("travellerList").controls[i].get('sectorList');
						sectorList.controls = [];
						for (let i = 0; i < currentSectorArray.length - 1; i++) {
							let customSector = currentSectorArray[i] + "/" + currentSectorArray[i + 1];
							if (customSector.length > 6) {
								const element = customSector;
								sectorList.push(this.buildTravellerSector(element))
							}
						}
					}
				}
			}
		}
	}

	async updateTravellersValidators(controlName, type,value) {
		let travellersControls = this.travellerForm.controls.travellerList.controls
		for (const key in travellersControls) {
			if (travellersControls.hasOwnProperty(key)) {
				var traveller = travellersControls[key];
				if (traveller.controls[controlName] != undefined) {
					if (type == 'required') {
						traveller.controls[controlName].setValidators(Validators.required)
						traveller.controls[controlName].updateValueAndValidity()
					} else {
						traveller.controls[controlName].setValidators(null)
						traveller.controls[controlName].updateValueAndValidity()
					}
					if(value == 'B'){
						traveller.controls['txtbookingSupplierName'].setValue('BSP')
						traveller.controls['txtbookingSupplier'].setValue('799')
					}else{
						traveller.controls['txtbookingSupplierName'].setValue("")
						traveller.controls['txtbookingSupplier'].setValue('')
					}
					this.travellerForm.updateValueAndValidity()
					let reqObj = {
						clientCode : this.affiliate_code,
						airlineCode : traveller.controls['txtbookingSupplier'].value ?  traveller.controls['txtbookingSupplier'].value  : this.invoiceForm.controls.airline.value 
					}
					let resp = await this.getDiscRecallSetting(reqObj,key)
				}

			}
		}

		let status = {
			status: this.travellerForm.status,
			type: type,
			controlName: controlName
		}
		let obj = {
			type: 'credit_note',
			index: this.fareList.length - 1,
			req_from : "bindCreditNoteData",
			"use_form" : "travellerForm"
		}
		this.componentMethodCallSource.next(obj);
		return status
	}

	getInvoiceRootvalue(data){
		this.setInvoiceSourceData.next(data);
	}

}
