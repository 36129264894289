<form [formGroup]="verticalPNLFilterForm">
    <div class="flexbox aff-info">
      <div class="flexbox aff-info-inner">
        <div class="flexbox ">
          <div class="input-fiel">
            <input class="input-ui" searchtabindex="1" formControlName="start_date" id="start_date" type="date"  
              />
            <label for="start_date">Starting Date</label>
          </div>
          <div class="input-fiel">
            <input class="input-ui" searchtabindex="2" formControlName="end_date" id="end_date" type="date" 
             />
            <label for="end_date">Ending Date</label>
          </div>
        </div>
       
        <div class="flexbox mt-3">
          <div class="select-field text-input-fiel">
            <select class="input-ui" searchtabindex="3" formControlName="selectedBranch" id="selectedBranch">
              <option value="">Select</option>
              <option value="">None</option>
              <option value="all">All</option>
              <option *ngFor="let item of branches" value="{{item.tenant_id}}">{{item?.branch_name ? item.branch_name : "HQ"}}
              </option>
            </select>
            <label for="selectedBranch">Branch</label>
          </div>
          <div class="input-fiel">
            <select class="input-ui" searchtabindex="4" formControlName="viewType" id="viewType">
              <option value="month_wise">Month Wise</option>
              <option value="consolidate">CONSOLIDATE</option>
            </select>
            <label for="viewType">View Type</label>
          </div>
        </div>

        <div class="flexbox mt-3">
        <div class="input-fiel">
          <select class="input-ui" searchtabindex="5" formControlName="LastYearView" id="LastYearView">
            <option value="date_range">Date range</option>
            <option value="closing">CONSOLIDATE YEAR</option>
          </select>
          <label for="viewType">Last Year View</label>
        </div>
      </div>

        <div class="d-flex mt-5 justify-content-center">
          <div class="input-fiel">
            <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
              searchtabindex="3" #searchButton (keyup.enter)="clickSearchButton()"
              [disabled]="verticalPNLFilterForm.invalid"
              (click)="search(verticalPNLFilterForm.value)"> Search</button>
            <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
          </div>
        </div>
      </div>
    </div>
  </form>