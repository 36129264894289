import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdmAcmRoutingModule } from './adm-acm-routing.module';
import { CreateAdmAcmComponent } from './create-adm-acm/create-adm-acm.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AdmAcmComponent } from './adm-acm.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonServiceService } from 'src/app/shared/common-service.service';


@NgModule({
  declarations: [AdmAcmComponent,CreateAdmAcmComponent],
  imports: [
    CommonModule,
    AdmAcmRoutingModule,
    SharedModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  exports : [CreateAdmAcmComponent]
})
export class AdmAcmModule { }
