import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout-popup',
  templateUrl: './logout-popup.component.html',
  styleUrls: ['./logout-popup.component.scss']
})
export class LogoutPopupComponent implements OnInit {


  constructor( public commonService: CommonServiceService , private router : Router) { }

  ngOnInit(): void {
  }

  logout() {
		this.commonService.setLogOutModalStatus(false)
		localStorage.removeItem("token")
    localStorage.removeItem("userInfo")
    localStorage.clear()
		this.commonService.showLogout(false)
		this.router.navigate(['/login']);
    window.location.href = environment.accountManagement+"/signin"
	}
  
}
