import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NavigationEnd, ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { DownloadService } from 'src/app/shared/download.service';
import { GlobalVariable } from 'src/app/shared/global-variable';

@Component({
  selector: 'app-docs-report',
  templateUrl: './docs-report.component.html',
  styleUrls: ['../gst-report.component.scss']
})
export class DocsReportComponent implements OnInit {
  p;
  @Input() gstArr: any;
  @Input() loader;
  navigationSubscription
  reInitialise: boolean;
  currentDate =  moment().format('YYYY-MM-DD')

  isAdvancedSearchOpen: boolean = false;
  currentIndex=0
  queryParams: any;
  
	@ViewChild('tableHeader', {static:true}) tableHeader : ElementRef
  constructor(
    private spinner: NgxSpinnerService,
		private downloadService : DownloadService,
    private route: ActivatedRoute,
        public commonService: CommonServiceService,
        private router: Router) {
          this.navigationSubscription = this.router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
              this.initialiseInvites();
            }
          });
         }

  ngOnInit() {

    this.queryParams = this.route.queryParams['value']
    // this.getFilteredData(queryParams)
        // if (queryParams.start_date != undefined) {
        // } else {
        //     this.getData()
        // }
        this.reInitialise = true
        // let gstData = {
        //   name : 'docs',
        //   data : queryParams
        // }
        // this.commonService.showGstPage(gstData)
  }

  initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}
	ngOnDestroy() {

		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

  //ALL DATA
  getData() {
      this.commonService.getRequest(`${environment.okountUrl}/reports/gst`).subscribe(res => {
        this.gstArr = res
        let gstData = {
          name: 'docs',
          data: {
          start_date : res['duration'].from_date,
          end_date : res['duration'].to_date
        },
          company_details : res['company_details']
        }
        this.commonService.showGstPage(gstData)
      }, err => {
          console.error(err)
      })
  }
  //FILTERED DATA
  getFilteredData(queryParams) {
      this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/gst/docs`, queryParams).subscribe(res => {
        this.gstArr = res
        let gstData = {
          name: 'docs',
          data: {
          start_date : res['duration'].from_date,
          end_date : res['duration'].to_date
        },
          company_details : res['company_details']
        }
        this.commonService.showGstPage(gstData)
      }, err => {
      })
  }

  // keyboard integration
	// Add #tableHeader on table header

	focusOnTableHeader(){
		if(this.tableHeader != undefined){
			this.tableHeader.nativeElement.focus()
		}
	}
	
	// @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
    console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.currentIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex+1}`).focus()
			this.currentIndex = this.currentIndex+1
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.currentIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex-1}`).focus()
			this.currentIndex = this.currentIndex-1
		}
	}
	
	moveToIndex(i){
		document.getElementById(`rowIndex_${i}`).focus()
		this.currentIndex = i
	}

  callPdfGeneratorApi(fromDate, toDate){
		window.scrollTo(0,0)
		this.spinner.show()
		this.spinner.show()
		let url = 'print-pdf/pdf-gst-docs'
		let queryString = location.hash.split('?')[1]
		if (queryString != undefined) {
			url = `${url}?${queryString}`
		}
		this.downloadService.callPdfGeneratorApi(`DOCS GST ${fromDate} - ${toDate}.pdf`,url,{})	
		this.spinner.hide()
	}
 
  // exportAsXLSX(){
  // }
    
 
}
