import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CommonServiceService } from "../common-service.service";
import { LoginComponent } from "../login/login.component";
import { Tab } from "../model/tab.model";

@Injectable()
export class TabService {
  constructor(
    public commonService: CommonServiceService
  ){
    
  }
  public tabs: Tab[] = [
    // new Tab(LoginComponent, "Home", {active : true})
  ];

  public tabSub = new BehaviorSubject<Tab[]>(this.tabs);

  public removeTab(index: number) {
    this.tabs.splice(index, 1);
    if(this.tabs[index]){
      this.tabs[index].active = true;
    }else if(this.tabs[index-1]){
      this.tabs[index-1].active = true;
    }
    // this.tabs[index]
    // if (this.tabs.length > 0) {
    //   this.tabs[this.tabs.length - 1].active = true;
    // }
    this.tabSub.next(this.tabs);
    if(!this.tabs || this.tabs.length == 0){
      this.commonService.toggleTabs(false)
    }
  }

  public setActive(index: number) {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].active === true) {
        this.tabs[i].active = false;
      }
    }
    if(this.tabs[index]){
      this.tabs[index].active = true;
      this.commonService.toggleTabs(true)
    }
    this.tabSub.next(this.tabs);
  }

  public async addTab(tab: Tab) {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].active === true) {
        this.tabs[i].active = false;
      }
    }
    tab.id = await this.commonService.generateRandomNumber(10)
    tab.active = true;
    this.tabs.push(tab);
    this.tabSub.next(this.tabs);
    this.commonService.toggleTabs(true)
  }

  public replaceTabData(title,newTitle,tabData) {
    const findElement = (element) => element.title==title;
    let index = this.tabs.findIndex(findElement);
    if(this.tabs[index]){
      this.tabs[index].title = newTitle
      this.tabs[index].tabData = tabData
    }
    this.tabSub.next(this.tabs);
    this.commonService.toggleTabs(true)
  }

  public removeAllTab() {
    this.tabs = []
    this.tabSub.next(this.tabs);
  }
}
