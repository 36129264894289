import { ActionTypes } from "./actions";

export const InitialState = {
    loginDetails: localStorage.getItem("okountUserInfo")?JSON.parse(localStorage.getItem("okountUserInfo")):{},
    financeYear : sessionStorage.getItem("current_financial_year")?JSON.parse(sessionStorage.getItem("current_financial_year")):{},
    internalTabs : [],
    activeTabIndex : 0,
    loaderTemplate : ''
}

export function sharedReducers(state = InitialState,action) {
    console.log('ACTION',action)
    switch(action.type){
        case ActionTypes.SetLoginDetailsLocals :
            return {
                ...state,
                loginDetails : action.payload
            }
        case ActionTypes.SetFinancialyear :
            sessionStorage.setItem("current_financial_year",JSON.stringify(action.payload))
            let updatedState = {
                ...state,
                financeYear : action.payload
            }
            return updatedState
        case ActionTypes.AddInternalTabs :
            return {
                ...state,
                internalTabs : [...state.internalTabs,action.payload],
                activeTabIndex : state.internalTabs.length
            }
        case ActionTypes.UpdateInternalTabs :
            var internalTabs = JSON.parse(JSON.stringify(state.internalTabs))
            internalTabs[action.payload.tabIndex]= action.payload.internalTabsData
            return {
                ...state,
                internalTabs : internalTabs,
                activeTabIndex : action.payload.tabIndex
            }
        case ActionTypes.RemoveInternalTab :
            var internalTabs = JSON.parse(JSON.stringify(state.internalTabs))
            internalTabs.splice(action.payload.tabIndex,1)
            var activeTabIndex = internalTabs[action.payload.tabIndex-1] ? action.payload.tabIndex-1 : 0
            return {
                ...state,
                internalTabs : internalTabs,
                activeTabIndex : activeTabIndex
            }
        case ActionTypes.SetActiveTab :
            return {
                ...state,
                activeTabIndex : action.payload['tabIndex']
            }
        case ActionTypes.SetLoaderTemplate :
            return {
                ...state,
                loaderTemplate : action.payload
            }
        default :
            return state
    }
    
}