import { Component, OnInit, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ContraService } from '../services/contra.service';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import * as moment from 'moment-timezone'
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';

@Component({
	selector: 'app-create-contra',
	templateUrl: './create-contra.component.html',
	styleUrls: ['./create-contra.component.scss']
})
export class CreateContraComponent implements OnInit {
	createContraForm: any;
	contraArray: FormArray
	accountList = []
	ledgerList = []
	keyword = "name"

	showMessage: boolean = false;
	alertType = '';
	message: string = ``;
	selectedDate = moment().format('YYYY-MM-DD')
	shortcuts: ShortcutInput[] = [];

	@ViewChildren('accountAc') accountAc: QueryList<ElementRef>;
	@ViewChildren('subLedgerAc') subLedgerAc: QueryList<ElementRef>;
	// @ViewChild('accountAc') accountAc;
	// @ViewChild('subLedgerAc') subLedgerAc;
	lastInputIndex = 7;
	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef
	@ViewChild("closeMasterCreationModal", { static: true }) closeMasterCreationModal : ElementRef;
	@ViewChild("openMasterCreationModal", { static: true }) masterCreationModal : ElementRef;

	@ViewChild("openSuccessModal", { static: true }) openSuccessModal;
	isconfirmationModalOpen = false 
	minDate;
  	maxDate;
	newContra: boolean = true;

	contra_code = ''
	copyELementTitle = 'Click to Copy'
	currentMaster;
	currentMasterInputName;
	currentIndex;
	showCreateOption: boolean = false;
	isTenantIndian: boolean = true;
	acCurrencyCode = '';
	financialYearDiff = false;
	constructor(
		private spinner: NgxSpinnerService,
		private fb: FormBuilder,
		private contraService : ContraService,
		public commonService: CommonServiceService) { }

	async ngOnInit() {
		/** spinner starts on init */
		this.spinner.show();
		setTimeout(() => {
			this.spinner.hide();
		}, 100);

		this.createContraForm = this.fb.group({
			amountDiff: [0],
			totalCredit: [0],
			totalDebit: [0],
			contra_date: [this.selectedDate,Validators.required],
			contraArray: this.fb.array([]),
			currency_code: [''],
			currency_rate: [''],
		});
		this.contraArray = this.createContraForm.get("contraArray") as FormArray;		
		this.addContraRow()
		this.commonService.selectedInput.next(0)

		this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
		try {
			let empConfiguration = this.commonService.employeeConfiguration;
				if(!empConfiguration || empConfiguration.length == 0){
					let loggedInUser = JSON.parse(localStorage.okountUserInfo);
					empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
				}
		} catch (error) {
			
		}

		let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}

		this.contraDateValidation();

		this.isTenantIndian = this.commonService.checkTenantIndian()
		this.getAccountingCompany()

	}


	validateDate(){
		return (control: AbstractControl) => {
			let valid = true
			if(this){
				if (moment(control.value).isBefore(this.minDate) || moment(control.value).isAfter(this.maxDate)) {
					valid = false
				}
			}
			return !valid ? {contra_date: {value: control.value}} : null;
		};
	}


	ngAfterViewInit(){

		this.shortcuts.push(			
			{
				key: "alt + s",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Save Contra Entry",
				description: "Save Contra Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("save_contra_btn")){
				document.getElementById("save_contra_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + plus",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Add New Contra Row",
				description: "Add New Contra Row",
				
				command: (e)=>{
					this.addContraRow()
					},
					preventDefault: true
			},
			{
				key: "alt + -",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Contra Entry",
				description: "Delete Contra Entry",
				
				command: (e)=>{
				console.log(e)	
			if(this.contraArray.length>0){
				this.removeContraRow(this.contraArray.length-1)
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + y",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On Yes Button",
				description: "Clicked On Yes Button",
				
				command: (e)=>{
				if(this.isconfirmationModalOpen){
					this.addContraRow()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
				this.isconfirmationModalOpen = false
					},
					preventDefault: true
			},
			{
				key: "alt + n",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On No Button",
				description: "Clicked On No Button",
				
				command: (e)=>{
				if(this.isconfirmationModalOpen){
					this.submit(this.createContraForm.value)
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
				this.isconfirmationModalOpen = false
					},
					preventDefault: true
			},
			
	  )
	}

	setIsModalOpen(){
		this.isconfirmationModalOpen = false
	}
	openConfirmModal(lastIndex,addIndex) :  void{
		this.isconfirmationModalOpen = true
		this.hideError()
		this.confirmModal.nativeElement.click()
		this.moveToTab(lastIndex,addIndex)
	}
	closeConfirmModal() :  void{
		this.isconfirmationModalOpen = false
		console.log('Modal Closed')
		if (this.closeModal) {
			this.closeModal.nativeElement.click()
		}
	}

	focusAccountAc(e: Event,index) {
		e.stopPropagation();
		// this.accountAc["_results"][(<HTMLInputElement>e.target).tabIndex%(+this.accountAc["_results"].length-1)+1].el.nativeElement.focus();
		if (this.accountAc["_results"][index]) {
			this.accountAc["_results"][index].focus()
		}

	  }
	  focusSubLedgerAC(e: Event,index) {
		e.stopPropagation();
		if (this.subLedgerAc["_results"][index]) {
			this.subLedgerAc["_results"][index].focus()
		}
	  }

	movetoIndex(index,event: KeyboardEvent){
		if (event.key === 'Enter') {
		this.lastInputIndex = ((index+1)*7)
		setTimeout(() => {
			 this.commonService.selectedInput.next(this.lastInputIndex+1)
		}, 100);
	}
	}
	moveToTab(lastInputIndex,addIndex){
		this.commonService.selectedInput.next(lastInputIndex+addIndex)
	}

	addContraRow() {
		this.closeConfirmModal()
		let narration = ""
		if(this.contraArray.controls.length > 0){
			narration = this.contraArray.controls[0]['controls'].narration_1.value
		}
		let data = {
			narration_1 : narration
		}
		this.contraArray.push(this.buildContra(data));
		
		let index = this.contraArray.length-1
		if (index > 0) {
			let switchToIndex = index*8
			this.moveToTab(switchToIndex,1)
		}
	}
	removeContraRow(index) {
		this.contraArray.removeAt(index);
	}

	get DynamicFormControls() {
		return <FormArray>this.createContraForm.get("contraArray");
	}

	buildContra(data) {

		let currentUser= JSON.parse(localStorage.getItem('okountUserInfo'))
		let date =moment().format('YYYY-MM-DD')
		if (this.selectedDate != undefined) {
			date = this.selectedDate
		}
		return this.fb.group({
			"account_type": ["general"],
			"account_code": [""],
			"account_code_name": [""],
			"contra_date": [date],
			"account_balance": [""],
			"sub_ledger_type": [""],
			"sub_ledger_code": [""],
			"debit": ["0"],
			"credit": ["0"],
			"narration_1": [data.narration_1 ? data.narration_1 : ''],
			"narration_2": [""],
			"remarks_1": [""],
			"remarks_2": [""],
			"last_updated_by_id": [currentUser.id], // FIX ME
			"last_updated_by_name": [currentUser.name], // FIX ME
			"last_updated_by_code": [currentUser.accountcode] // FIX ME
		});
	}

	searchAccount(event,i) {
		try {
			let searchType = 'general'
			this.currentMaster = searchType;
			let value = event.target.value;
			if (value.length > 2 && searchType != null && searchType != '') {
				this.currentMasterInputName = value;
				this.currentIndex = i;
				this.commonService.getRequest(`${environment.okountUrl}/${searchType}/autocomplete?name_like=${value.toUpperCase()}&status=active`).subscribe((res):any => {
					this.accountList[i] = res
					if(!res || res['length'] == 0) {
						this.showCreateOption = true;
					}else {
						this.showCreateOption = false;
					}
				});
			}
		} catch (error) {

		}
	}
	selectAccount(nzEvent,formControlName,index){
		let value;
		if(nzEvent && nzEvent.hasOwnProperty('nzValue')) {
			if(nzEvent.nzValue == "") {
				this.currentIndex = index;
				if(this.currentMaster) {
					this.createContraForm.controls.contraArray.controls[index].controls[`${formControlName}_name`].setValue(this.currentMasterInputName)
					this.masterCreationModal.nativeElement.click()
				}
				return false;
			} else {
				value = nzEvent.nzValue;
				this.createContraForm.controls.contraArray.controls[index].controls[formControlName].setValue(value.account_code)
				this.createContraForm.controls.contraArray.controls[index].controls[`${formControlName}_name`].setValue(value.name)
			}
		}
		
	}
	searchLedger(value,i) {
		try {
			let searchType = this.createContraForm.controls.contraArray.controls[i].value.sub_ledger_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.commonService.getRequest(`${environment.okountUrl}/${searchType}/autocomplete?name_like=${value.toUpperCase()}&status=active`).subscribe(res => {
					this.ledgerList[i] = res
					console.log(this.ledgerList)
				});
			}
		} catch (error) {

		}
	}
	selectLedger(data,formControlName,index){
		this.createContraForm.controls.contraArray.controls[index].controls[formControlName].setValue(data.account_code)
	}

	calculateDiff(index,formControlName){
		if (formControlName == 'debit') {
			this.createContraForm.controls.contraArray.controls[index].controls.credit.setValue('0')
		}
		if (formControlName == 'credit') {
			this.createContraForm.controls.contraArray.controls[index].controls.debit.setValue('0')
		}
		let totalDebit = this.getTotal('debit')
		let totalCredit = this.getTotal('credit')
		let difference = Number((totalDebit - totalCredit).toFixed(2))
		this.createContraForm.controls.amountDiff.setValue(difference)
		this.createContraForm.controls.totalDebit.setValue(totalDebit)
		this.createContraForm.controls.totalCredit.setValue(totalCredit)
	}
	getTotal(amountType) {
		let amount = 0
		if (this.createContraForm.controls.contraArray.controls.length > 0) {
			let controls = this.createContraForm.controls.contraArray.controls
			for (let index = 0; index < controls.length; index++) {
				const element = controls[index];
				amount += Number(element.controls[amountType].value)
			}
		}	
		return Number(amount.toFixed(2))
	}


	async submit(value){
		if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.createContraForm.controls.contra_date.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to create the contra.'
				return false;
			}
		}

		!this.financialYearDiff && await this.initDateValidation();

		const invalid = [];
		const controls = this.createContraForm.controls;
		for (const name in controls) {
			if (controls[name].invalid && name != "contraArray") {
				invalid.push(name);
			}
		}
		if(invalid.length > 0) {
			window.scroll(0,0)
			this.showMessage = true;
			this.alertType = 'error-box';
			this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
			this.spinner.hide()
			this.closeConfirmModal()
			return false
		}
		this.showMessage = false
		this.hideError()
		this.closeConfirmModal()
		if (value.amountDiff != 0) {
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = `Amount Difference should be zero.`
			this.moveToTab(this.lastInputIndex,1)
			return false
		}
		else if (value.contraArray.length == 1) {
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = `To save Contra you must do 1 credit and 1 debit entry.`
			return false
		}
		else if (value.contra_date == null) {
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = `Invalid Contra Date.`
			return false
		}else{
			let date = moment(value.contra_date).format('YYYY-MM-DD')
		let d = await this.dateSelection(date)
		this.spinner.show()
		
		if(controls.currency_rate.value && controls.currency_code.value) {
			for(let item of this.createContraForm.value.contraArray){
				item['currency_rate'] = controls.currency_rate.value;
				item['currency_code'] = controls.currency_code.value;
			}
		}

		this.commonService.postRequest(`${environment.okountUrl}/contra`,this.createContraForm.value.contraArray).subscribe(async (res)=>{
			this.contra_code = res[0].contra_code
			this.showMessage = false
			this.newContra = true
			// await this.doTransactionEntry(res)
			this.alertType = 'success-box';
			this.message = `Contra Saved with Journol Code `
			this.openSuccessModal.nativeElement.click()
			this.createContraForm.reset()
			this.contraArray = this.createContraForm.get("contraArray") as FormArray;		
			this.contraArray.controls = []
			this.addContraRow()
			this.spinner.hide();
		},err=>{
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Failed to create Contra'
			if( err['error']['message']){
				this.message = err['error']['message']
			  }
			this.spinner.hide();
		})
		}
	}

	// doTransactionEntry(entries) {
	// 	this.commonService.postRequest(`${environment.okountUrl}/v3/contra`, entries).subscribe(res => {
	// 		debugger
	// 		console.log(res)
	// 	}, err => {
	// 	})
	// }

	hideError(){
		this.showMessage = false
	}
	reset(){
		this.createContraForm.reset()
		this.contraArray.controls = []
		this.addContraRow()
	}

	dateSelection(event){
		if (event != null) {
			let contraFormGroupControl = this.createContraForm.controls.contraArray.controls
			this.selectedDate = moment(event).format('YYYY-MM-DD')
			for (let i = 0; i < contraFormGroupControl.length; i++) {
				const contraControl = contraFormGroupControl[i];
				contraControl.controls.contra_date.setValue(this.selectedDate)
			}
			this.moveToTab(0, 1)
			return this.selectedDate
		}else{
			return this.selectedDate
		}
	}

	closeSuccesModal(){
		this.showMessage = false;
		this.alertType = '';
		this.message = ``
		this.newContra = true
		this.openSuccessModal.nativeElement.click()
	}

	viewJournal(contraCode) {
		let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/contra/update/${contraCode}`;
		window.open(url, "_blank");
	}

	copytext(val: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.copyELementTitle = 'Copied'
	}

	closeMasterModal() {
		this.closeMasterCreationModal.nativeElement.click()
	}

	setFormValues(data) {
		this.showMessage = true;
		this.alertType = 'success-box';
		this.message = `${this.currentMaster} created successfully with account code. : ${data.account_code}`
		
		this.createContraForm.controls.contraArray.controls[this.currentIndex].controls['account_code'].setValue(data.account_code)

	  }
	
	async initDateValidation (){
	    this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
		try {
		let empConfiguration = this.commonService.employeeConfiguration;
			if(!empConfiguration || empConfiguration.length == 0){
				let loggedInUser = JSON.parse(localStorage.okountUserInfo);
				empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
			}
			let config = this.commonService.getConfigurationByKey('contra')
			if(config){
				let dateConfig = config['value'].filter(data => data.key == 'minimum_creation_date')
				let daysConfig = config['value'].filter(data => data.key == 'minimum_creation_days')
				let dateWithMinDate = moment(dateConfig ? dateConfig[0].value : '')
				let dateWithMinDays = dateConfig && daysConfig[0].value ? moment().subtract(Number(daysConfig[0].value), 'days') : dateWithMinDate
				if(dateWithMinDays.isBefore(dateWithMinDate)){
					this.minDate = dateWithMinDate.format('YYYY-MM-DD')
				}else{
					this.minDate = dateWithMinDays.format('YYYY-MM-DD')
				}
			}
		} catch (error) {
			
		}
		this.maxDate = moment().format('YYYY-MM-DD')
		this.createContraForm.controls.contra_date.setValidators([Validators.required,this.validateDate()])
		this.createContraForm.controls.contra_date.updateValueAndValidity()
	} 

	getAccountingCompany() {
		try {
			this.contraService.getMethod('util/accounting-company').subscribe(async (res) => {
				if(res) {
					if(res['currency_code']) {
						this.acCurrencyCode = res['currency_code'];
					}
				}
			},
			err => {
				this.spinner.hide()
			})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
		}
	}

	onContraCurrencyChange(){
		this.createContraForm.controls.currency_rate.setValue("");
	}

	contraDateValidation() {
		let financeYearResponse = this.commonService.checkFinancialYearValidation();
		this.financialYearDiff = financeYearResponse.isFinancialYearDiff;
		this.minDate = financeYearResponse.minDate;
		this.maxDate = financeYearResponse.maxDate;

		this.createContraForm.controls.contra_date.setValue(this.maxDate);
		this.createContraForm.controls.contra_date.setValidators([Validators.required, this.validateDate()]);
		this.createContraForm.controls.contra_date.updateValueAndValidity()
	}

	resetSelection(formControlName,index) {
		this.createContraForm.controls.contraArray.controls[index].controls[formControlName].setValue('')
		if(formControlName == 'account_code'){
			this.createContraForm.controls.contraArray.controls[index].controls[`${formControlName}_name`].setValue('')
		  this.accountList = []
		}
		if(formControlName == 'sub_ledger_code'){
		  this.ledgerList = []
		}
	  }
	 
}
