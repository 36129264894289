import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { PaymentService } from '../services/payment.service';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../store/actions';
import { Tab } from 'src/app/shared/model/tab.model';
import { TabService } from 'src/app/shared/services/tab.service';
import { V2CreatePaymentComponent } from '../v2-create-payment/v2-create-payment.component';
@Component({
	selector: 'app-view-payment',
	templateUrl: './view-payment.component.html',
	styleUrls: ['./view-payment.component.scss']
})
export class ViewPaymentComponent implements OnInit {
	paymentArr: any;
	paymentAllArr: any;
	navigationSubscription;
	reInitialise: any;
	p;
	innerHeight: string;

	onSearch;
	onSearchFilter = new Subject<string>();
	fromRoot = true;
	selector: string = '.main-panel';
	fromIndex: any = 0;
	toIndex: any = 30;

	currentIndex=0
	@ViewChild('tableHeader', {static:true}) tableHeader : ElementRef
	isAdvancedSearchOpen: boolean = false;
	duration: any;
	loader: boolean = false;
	queryParams: any;


	constructor(
		private paymentService: PaymentService,
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private router: Router,
		private store: Store<InitialState>,
		private tabService: TabService

	) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	 }

	ngOnInit() {
		let queryParams = JSON.parse(JSON.stringify(this.route.queryParams['value']))
		if (queryParams.from_account != undefined) {
			localStorage.setItem('activePaymentQueryParams',JSON.stringify(queryParams))
		} 
		this.fromIndex = 0;
		queryParams['limit'] = 30;
        queryParams['offset'] = this.fromIndex;
		this.onSearch = ''
        this.search(queryParams)
		this.queryParams = queryParams
		this.reInitialise=true
		this.onSearchFilter.pipe(
			debounceTime(300),
			distinctUntilChanged())
			.subscribe(value => {
			  this.filterData(value)
		});

	}
	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}
	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setHeight(event.target.innerHeight)
	}

	setHeight(height) {
		if (this.paymentArr != undefined) {
			if (height == undefined) {
				height = window.innerHeight
			}
			if (this.paymentArr.length <= 9) {
				this.innerHeight ='auto';
			}
			else {
				this.innerHeight = height - 147 + 'px';
			}
		}
	}

	openAdvanceSearchModal(){
		this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'payment'}));	
	}


	oldSearch(values) {
		this.loader=true;
		this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/payment`, values).subscribe(res => {
			this.paymentArr = []
			this.loader = false;
			this.duration = res['duration']
			this.paymentAllArr = res['data']
			this.focusOnTableHeader()
			this.currentIndex = 0
			for (let i = 0; i <= 30; i++) {
				const element = this.paymentAllArr[i];
				if (element != undefined) {
					this.commonService.selectedInput.next(1)
					this.toIndex = i
					this.paymentArr.push(element)
				}
			}
			
			this.setHeight(window.innerHeight)
		})
	}

	async search(values) {
		let reqObj = Object.assign({},values)
		
		this.loader = true;
		let resp = await this.getData(reqObj)
		if(resp){
			this.loader = false;
			this.duration = resp['duration']
			this.paymentAllArr = resp['data']
			this.paymentArr = resp['data']
			this.focusOnTableHeader()
			this.currentIndex = 0
			this.setHeight(window.innerHeight)
			this.commonService.selectedInput.next(1)
		}
		
	}

	getData(values){
		return new Promise(async (resolve, reject) => {
			this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/payment`, values).subscribe(res => {
				resolve(res)
			},err=> {
				resolve(null)
			})
		})
	}

	async onScrollDown(event) {
		this.fromIndex +=30
		// let toIndex = Number(this.toIndex) + 10
		let queryParams = JSON.parse(JSON.stringify(this.queryParams))
		queryParams['limit'] = 30;
		queryParams['offset'] = this.fromIndex;
		let resp = await this.getData(queryParams)
		if(resp){
			this.paymentArr = this.paymentArr.concat(resp['data'])
			this.setHeight(window.innerHeight)
		}
	  }

	openPayment(payment_code){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/payment/view/${payment_code}`;
		if(window.location.protocol != 'file:'){
			let title =''
				let splitParts = payment_code.split('-')
				title += splitParts[0]
				title +='...'
				title += splitParts[splitParts.length-1]
			if(payment_code) {
				this.tabService.addTab(
					new Tab(V2CreatePaymentComponent, `${title}`, { paymentRef: payment_code ,mode: 'view'}, payment_code)
					);
			} else {
				window.open(path,'_blank')
			}
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}



	  onScrollUp(event) {
	  }

	  oldFilterData(value) {
		let tempArr = Object.assign([], this.paymentAllArr)
		if (value.length > 2) {
			let lowerCaseFilter = value.toLowerCase()
			let upperCaseFilter = value.toUpperCase()
			this.paymentArr = tempArr.filter(
				paymentData => {debugger

					if (	
						(
							paymentData.account_code_from != undefined && 
							paymentData.account_code_from != null && 
							(	paymentData.account_code_from.includes(value) || 
								paymentData.account_code_from.includes(upperCaseFilter) ||
								paymentData.account_code_from.includes(lowerCaseFilter)
							)
						)  || 
						(
							paymentData.payment_code != undefined && 
							paymentData.payment_code != null && 
							(	paymentData.payment_code.includes(value) || 
								paymentData.payment_code.includes(upperCaseFilter) ||
								paymentData.payment_code.includes(lowerCaseFilter)
							)
						) || 
						(
							paymentData.account_code_to_name != undefined && 
							paymentData.account_code_to_name != null && 
							(	paymentData.account_code_to_name.includes(value) || 
								paymentData.account_code_to_name.includes(upperCaseFilter) ||
								paymentData.account_code_to_name.includes(lowerCaseFilter)
							)
						)  || 
						(
							paymentData.account_code_from_name != undefined && 
							paymentData.account_code_from_name != null && 
							(	paymentData.account_code_from_name.includes(value) || 
								paymentData.account_code_from_name.includes(upperCaseFilter) ||
								paymentData.account_code_from_name.includes(lowerCaseFilter)
							)
						) || 
						(
							paymentData.amount != undefined && 
							paymentData.amount != null && 
							(	paymentData.amount.toString().includes(value) || 
								paymentData.amount.toString().includes(upperCaseFilter) ||
								paymentData.amount.toString().includes(lowerCaseFilter)
							)
						)
					) 
					
					{
						return paymentData
					}
				}
			)
			this.setHeight(window.innerHeight)
		} else{
			this.paymentArr = []
			for (let i = 0; i <= 30; i++) {
				const element = this.paymentAllArr[i];
				if (element != undefined) {
					this.toIndex = i
					this.paymentArr.push(element)
				}
			}
			this.setHeight(window.innerHeight)
		}
	}

	async filterData(value) {
        if (value.length >= 0) {
            this.queryParams['freetext'] = value
            let queryParams = JSON.parse(JSON.stringify(this.queryParams))
            this.queryParams = queryParams
			this.fromIndex=0
            queryParams['limit'] = 30;
            queryParams['offset'] = this.fromIndex;
			this.loader = true
            let resp = await this.getData(queryParams)
			this.loader = false
            if(resp){
                this.paymentArr = resp['data']
				let firstRow = document.getElementById('rowIndex_1') as HTMLElement
				if(firstRow){
					firstRow.scrollIntoView()
				}

            }
           
        this.setHeight(window.innerHeight)
        } else {
            delete this.queryParams['freetext']
            this.search(this.queryParams)
            this.setHeight(window.innerHeight)
        }
    }


		// keyboard integration
	// Add #tableHeader on table header

	focusOnTableHeader() :void{
		this.tableHeader.nativeElement.focus()
	}

	// @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.currentIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex+1}`).focus()
			this.currentIndex = this.currentIndex+1
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.currentIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex-1}`).focus()
			this.currentIndex = this.currentIndex-1
		}
	}

	moveToIndex(i){
		document.getElementById(`rowIndex_${i+1}`).focus()
		this.currentIndex = i+1
	}
}
