import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReceiptRoutingModule } from './receipt-routing.module';
import { ReceiptComponent } from './receipt.component';
import { CreateReceiptComponent } from './create-receipt/create-receipt.component';
import { ViewReceiptComponent } from './view-receipt/view-receipt.component';
import { AddEntryReceiptComponent } from './add-entry-receipt/add-entry-receipt.component';
import { ReceiptService } from './services/receipt.service';
import { ReceiptV2Service } from './services/receiptv2.service';

import { SharedModule } from 'src/app/shared/shared.module';
import { UpdateReceiptComponent } from './update-receipt/update-receipt.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { V2CreateReceiptComponent } from './v2-create-receipt/v2-create-receipt.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [ReceiptComponent, CreateReceiptComponent, ViewReceiptComponent, AddEntryReceiptComponent, UpdateReceiptComponent, V2CreateReceiptComponent],
  imports: [
    CommonModule,
    ReceiptRoutingModule,
    SharedModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  providers : [ReceiptService,ReceiptV2Service]
})
export class ReceiptModule { }
