import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerModule } from "ngx-spinner";
import { KeyboardShortcutsModule }     from 'ng-keyboard-shortcuts';  
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonServiceService } from './shared/common-service.service';
import { LoaderService } from './shared/loader.service';
import { XlsDownloadService } from './shared/xls-download.service';
import { EmailScreenComponent } from './email-screen/email-screen.component';
import { LoginComponent } from './shared/login/login.component';
import { AuthGuardService } from './shared/auth-guard.service';
import { CanDeactivateGuard } from './shared/can-deactivate/can-deactivate.guard';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GlobalSearchModule } from './shared/global-search/global-search.module';
import { CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { DarkModeToggleComponent } from './dark-mode-toggle/dark-mode-toggle.component';
import { DARK_MODE_OPTIONS } from 'angular-dark-mode';
import { CookiesService } from './shared/cookies.service';

import { reducers, metaReducers } from './store/reducers';
import { StoreModule } from '@ngrx/store';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpInterceptorService } from './shared/services/http-interceptor.service';
import { FormsModule } from '@angular/forms';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import en from '@angular/common/locales/en';
import { TabService } from './shared/services/tab.service';
import { TabContentComponent } from './tab-content.component';
import { ContentContainerDirective } from './content-container.directive';
import { InvoiceModule } from './transactions/invoice/invoice.module';
import { AdmAcmModule } from './transactions/adm-acm/adm-acm.module';
import { ClientModule } from './master/client/client.module';
import { AirlineModule } from './master/airline/airline.module';
import { SupplierModule } from './master/supplier/supplier.module';
import { EmployeeComponent } from './master/employee/employee.component';
import { GeneralModule } from './master/general/general.module';
import { EmployeeModule } from './master/employee/employee.module';
import { MiscellaneousInvoiceModule } from './transactions/invoice/miscellaneous-invoice/miscellaneous-invoice.module';
import { JvModule } from './transactions/jv/jv.module';
import { PaymentModule } from './transactions/payment/payment.module';
import { ReceiptModule } from './transactions/receipt/receipt.module';
import { DayBookReportModule } from './display/day-book-report/day-book-report.module';
import { StatementAccountModule } from './display/statement-account/statement-account.module';
import { GstReportModule } from './display/statutory-report/gst-report/gst-report.module';
import { ReportModule } from './report/report.module';
import { LedgerModule } from './display/account-book/ledger/ledger.module';
import { AccountBookModule } from './display/account-book/account-book.module';
import { FlightRefundModule } from './transactions/invoice-refund/flight-refund/flight-refund.module';
import { InvoiceRefundModule } from './transactions/invoice-refund/invoice-refund.module';
import { BankModule } from './master/bank/bank.module';
import { TourModule } from './transactions/tour/tour.module';
import { EventModule } from './transactions/event/event.module';
import { MiscInvoiceModule } from './transactions/misc-invoice/misc-invoice.module';
import { Router } from '@angular/router';
import { TraceService } from '@sentry/angular';
import { environment } from 'src/environments/environment';
import { ConsolidateInvoiceSearchModule } from './display/consolidate-search-invoice/consolidate-invoice.module';
import { ConsolidateInvoiceModule } from './transactions/consolidate-invoice/consolidate-invoice.module'; 
registerLocaleData(en);

import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import arAGlocaleAr from '@angular/common/locales/ar-EG';
import aeEGLocaleAr from '@angular/common/locales/ar-AE'
registerLocaleData(arAGlocaleAr);
registerLocaleData(aeEGLocaleAr);

import { ProformaInvoiceModule } from './transactions/proforma-invoice/proforma-invoice.module';
import { PurchaseInvoiceModule } from './transactions/purchase-invoice/purchase-invoice.module';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn: environment.sentry_dsn,
      environment : environment.BRANCH_REF_SLUG ? environment.BRANCH_REF_SLUG : 'debug',
      integrations: [
        new Integrations.BrowserTracing(),
      ],
      tracesSampleRate: 1.0,
    });
  }

  handleError(error: any): void {
    Sentry.captureException(error.originalError || error);
    // throw error;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    EmailScreenComponent,
    LoginComponent,
    DarkModeToggleComponent,
    TabContentComponent,
    ContentContainerDirective
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    KeyboardShortcutsModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxSpinnerModule,
    NgxPaginationModule,
    GlobalSearchModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    InvoiceModule,
    AdmAcmModule,
    ClientModule,
    AirlineModule,
    SupplierModule,
    EmployeeModule,
    GeneralModule,
    BankModule,
    MiscellaneousInvoiceModule,
    ProformaInvoiceModule,
    PurchaseInvoiceModule,
    JvModule,
    PaymentModule,
    ReceiptModule,
    DayBookReportModule,
    StatementAccountModule,
    GstReportModule,
    ReportModule,
    LedgerModule,
    AccountBookModule,
    InvoiceRefundModule,
    TourModule,
    EventModule,
    MiscInvoiceModule,
    ConsolidateInvoiceSearchModule,
    ConsolidateInvoiceModule
  ],
  exports : [TabContentComponent,ContentContainerDirective],
  providers: [CommonServiceService, LoaderService, XlsDownloadService,AuthGuardService,CanDeactivateGuard,CookiesService,TabService,
  {
    provide : LocationStrategy,
    useClass : HashLocationStrategy
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
  },
  {
    provide: DARK_MODE_OPTIONS,
    useValue: {
        darkModeClass: 'light-mode',
        lightModeClass: 'light-mode'
    },
},
  { provide: NZ_I18N, useValue: en_US },
  { provide: ErrorHandler  },
],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
