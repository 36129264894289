import { Component, Input, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { SectorRefundService } from '../services/sector-refund.service';
import { LegRefundModel } from '../models/models/leg-refund-model';

@Component({
  selector: 'app-sector-info-refund',
  templateUrl: './sector-info-refund.component.html',
  styleUrls: ['../flight-refund.component.scss','../../invoice-refund.component.scss']
})
export class SectorInfoRefundComponent implements OnInit {

	bsConfig: Partial<BsDatepickerConfig>;
	colorTheme = 'theme-orange';
	leg : LegRefundModel = new LegRefundModel();
	@Input() sectorService;
	constructor(
		// public sectorService : SectorRefundService
		) { }

	ngOnInit() {
		this.bsConfig = Object.assign({ dateInputFormat: 'DD/MM/YYYY HH:mm' }, { containerClass: this.colorTheme }, { showWeekNumbers: false })	
	}
	getValue(data){
		console.log(data)
	}
}
