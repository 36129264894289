import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment'
import { ActivatedRoute, Router } from '@angular/router';
import { ReceiptService } from '../services/receipt.service';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { FormCanDeactivate } from 'src/app/shared/form-can-deactivate/form-can-deactivate';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
@Component({
	selector: 'app-update-receipt',
	templateUrl: './update-receipt.component.html',
	styleUrls: ['./update-receipt.component.scss']
})
export class UpdateReceiptComponent extends FormCanDeactivate implements OnInit {
	@ViewChild('form')
 	form: NgForm;
	submitResponse: any;
	generalMasterList: any;
	showMessage: boolean = false;
	alertType = '';
	message: string = ``;
	receiptId: any;
	receiptData: any;
	invoiceMode = 'save';

	@ViewChild('confirmDeleteModal',{static:true}) confirmDeleteModal : ElementRef
	@ViewChild('closeDeleteModal',{static:true}) closeDeleteModal : ElementRef

	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef
	@ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef
	@ViewChild('updateButton',{static:true}) updateButton : ElementRef
	
	@ViewChild('confirmApproveModal',{static:true}) confirmApproveModal : ElementRef
	@ViewChild('closeApproveModal',{static:true}) closeApproveModal : ElementRef
	@ViewChild('confirmRejectModal',{static:true}) confirmRejectModal : ElementRef
	@ViewChild('closeRejectModal',{static:true}) closeRejectModal : ElementRef
	constructor(
		public receiptService: ReceiptService,
		private spinner: NgxSpinnerService,
		private route: ActivatedRoute,
		private router : Router,
		public commonService: CommonServiceService
		// private fb: FormBuilder
	) {
		super()
		this.route.paramMap.subscribe(params => {
			this.receiptId = params['params'].id
		});
	}

	async ngOnInit() {
		// this.addRecord(true,this.receiptService.receiptForm.value) /** spinner starts on init */
		this.spinner.show();

		setTimeout(() => {
			/** spinner ends after 5 seconds */
			this.spinner.hide();
		}, 1000);
		// this.getEntries()
		await this.getGeneralMaster('general/bank')
		this.getReceiptDetails()


	}

	getReceiptDetails() {
		this.receiptService.getMethod(`receiptv2?receipt_id=${this.receiptId}`).subscribe(async (res) => {
			if (res[0] != undefined) {
				this.receiptData = res[0]
				
				console.log(res)
				await this.setReceiptData()
			}
		}, err => {

		})
	}
	setReceiptData() {
		if (this.receiptData && this.receiptData.length>0) {
			
			this.receiptService.receiptForm.controls.receipt_id.setValue(this.receiptData.receipt_id)
			this.receiptService.receiptForm.controls.receipt_code.setValue(this.receiptData.receipt_code)
			let account_code_to = this.receiptData.account_code_to
			if (account_code_to.length == 6) {
				account_code_to = account_code_to.substring(1)
			}
			this.receiptService.receiptForm.controls.accountCodeTo.setValue(account_code_to)
			if (this.receiptData.receipt_timestamp != null && this.receiptData.receipt_timestamp != '') {
				this.receiptService.receiptForm.controls.receiptDate.setValue( moment(this.receiptData.receipt_timestamp).format('YYYY-MM-DD')) 

			}
			this.receiptService.receiptForm.controls.amount.setValue(this.receiptData.amount)
		}
	}



	addRecord(status, formValue) {
		this.spinner.show();
		this.receiptService.isEdit = false
		let userInfo = JSON.parse(localStorage.okountUserInfo)
		let addReceiptObj = {
			"account_code_to": formValue.accountCodeTo,
			"receipt_timestamp": moment(formValue.receiptDate).format('YYYY-MM-DD'),
			"last_modified_by": userInfo.id,
			"last_modified_by_name": userInfo.name
		}
		if (formValue.receipt_id == "") {
			this.receiptService.postMethod(`receiptv2`, addReceiptObj).subscribe((res: any) => {
				let response = res
				this.receiptService.showAddreceiptScreen = status
				this.receiptService.receiptForm.controls.receipt_id.setValue(response.receipt_id)
				this.receiptService.receiptEntryForm.controls.account_code_to.setValue(formValue.accountCodeTo)
				this.spinner.hide();
			},
				err => {
					this.spinner.hide();
				})
		} else {
			this.receiptService.showAddreceiptScreen = status
			this.spinner.hide();
		}
	}


	getEntries() {
		let receipt_id = this.receiptService.receiptForm.value.receipt_id
		if (receipt_id != '') {
			this.receiptService.getMethod(`receiptentryv2/getallbyreceiptid?receipt_id=${receipt_id}`).subscribe((res: any) => {
				this.receiptService.receiptEntries = res
				this.spinner.hide();
			},
				err => {
					this.spinner.hide();
				})
		} else {
			this.receiptService.receiptEntries = []
		}
	}

	updateReceipt() {
		this.spinner.show();
		this.closeUpdateConfirmModal()
		let formValues = this.receiptService.receiptForm.value
		let currentUser = JSON.parse(localStorage.getItem('okountUserInfo'))
		
		let reqBody = {
			"receipt_id": formValues.receipt_id,
			"receipt_code": formValues.receipt_code,
			"account_code_to": formValues.accountCodeTo,
			"receipt_timestamp": moment(formValues.receiptDate).format('YYYY-MM-DD'),
			"last_modified_by": currentUser.id,
			"last_modified_by_name": currentUser.name,
			"amount": formValues.amount
		}
		this.receiptService.putMethod(`receiptv2?receipt_id=${formValues.receipt_id}`, reqBody).subscribe(async (res: any) => {
			this.submitResponse = res
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = `Receipt Entry Updated.`
			try {
				for (const key in this.receiptService.receiptEntries) {
					if (this.receiptService.receiptEntries.hasOwnProperty(key)) {
						const element = this.receiptService.receiptEntries[key];
						element['invoice_no'] = this.submitResponse[0].receipt_code
						element['old_transaction_no'] = this.submitResponse[0].receipt_code
						element['created_date'] = this.submitResponse[0].receipt_timestamp
					}
				}
				// await this.doTransactionEntry(this.receiptService.receiptEntries)
			} catch (error) {

			}
			// alert(JSON.stringify(this.submitResponse))
			console.log('Receipt Submit response : ', this.submitResponse)
			// this.receiptService.receiptForm.reset()
			// this.receiptService.receiptEntries = []
			// this.receiptService.receiptForm.controls.accountCodeTo.setValue('')
			// this.receiptService.receiptForm.controls.receipt_id.setValue('')
			// this.receiptService.receiptForm.controls.receiptDate.setValue('')
			this.spinner.hide();
		},
			err => {
				this.showMessage = true
				this.alertType = 'error-box';
				this.message = `Failed to update receipt entry.`
				this.spinner.hide();
			})
	}

	reset() {
		let receipt_id = this.receiptService.receiptForm.value.receipt_id
		this.closeDeleteConfirmModal()
		if (receipt_id != '') {
			this.receiptService.deleteMethod(`receiptv2?receipt_id=${receipt_id}`).subscribe((res: any) => {
				this.receiptService.receiptForm.reset()
				this.receiptService.receiptEntries = []
				this.receiptService.receiptForm.controls.accountCodeTo.setValue('')
				this.receiptService.receiptForm.controls.receipt_id.setValue('')
				this.receiptService.receiptForm.controls.receiptDate.setValue('')
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = `Receipt entry Deleted.`
				this.spinner.hide();
			},
				err => {
					this.showMessage = true
					this.alertType = 'error-box';
					this.message = `Failed to delete receipt entry.`
					this.spinner.hide();
				})
		} else {
			this.receiptService.receiptForm.reset()
			this.receiptService.receiptEntries = []
			this.receiptService.receiptForm.controls.accountCodeTo.setValue('')
			this.receiptService.receiptForm.controls.receipt_id.setValue('')
			this.receiptService.receiptForm.controls.receiptDate.setValue('')
		}
	}

	editEntry(receipt) {
		this.receiptService.showAddreceiptScreen = true
		this.receiptService.isEdit = true
		localStorage.setItem('selected_receipt_entry', receipt.receipt_entry_id)
		this.receiptService.receiptEntryForm.controls.account_code_from.setValue(receipt.account_code_from)
		this.receiptService.receiptEntryForm.controls.account_code_to.setValue(receipt.account_code_to)
		this.receiptService.receiptEntryForm.controls.cheque_no.setValue(receipt.cheque_no)
		if (receipt.cheque_date != null && receipt.cheque_date != '') {
			this.receiptService.receiptEntryForm.controls.cheque_date.setValue(moment(receipt.cheque_date).format('YYYY-MM-DD'))
		}
		this.receiptService.receiptEntryForm.controls.cheque_bank_name.setValue(receipt.cheque_bank_name)
		this.receiptService.receiptEntryForm.controls.amount.setValue(Number(receipt.amount))
		this.receiptService.receiptEntryForm.controls.narration_1.setValue(receipt.narration_1)
		this.receiptService.receiptEntryForm.controls.narration_2.setValue(receipt.narration_2)
		this.receiptService.receiptEntryForm.controls.narration_3.setValue(receipt.narration_3)
		this.receiptService.receiptEntryForm.controls.narration_4.setValue(receipt.narration_4)
		this.receiptService.receiptEntryForm.controls.narration_5.setValue(receipt.narration_5)
		this.receiptService.receiptEntryForm.controls.remark_1.setValue(receipt.remark_1)
		this.receiptService.receiptEntryForm.controls.remark_2.setValue(receipt.remark_2)
		this.receiptService.receiptEntryForm.controls.remark_3.setValue(receipt.remark_3)
		this.receiptService.receiptEntryForm.controls.remark_4.setValue(receipt.remark_4)
		this.receiptService.receiptEntryForm.controls.remark_5.setValue(receipt.remark_4)
		this.receiptService.receiptEntryForm.controls.sub_ledger_code_1_type.setValue(receipt.sub_ledger_code_1_type)
		this.receiptService.receiptEntryForm.controls.sub_ledger_code_1.setValue(receipt.sub_ledger_code_1)
		this.receiptService.receiptEntryForm.controls.sub_ledger_code_1_name.setValue(receipt.sub_ledger_code_1_value ? receipt.sub_ledger_code_1_value : '')

		this.receiptService.receiptEntryForm.controls.account_code_from_name.setValue(receipt.account_code_from_value)
		this.receiptService.receiptEntryForm.controls.account_code_to_name.setValue(receipt.account_code_to_value)
		debugger
		this.receiptService.receiptEntryForm.controls.other_transaction_type.setValue(receipt.other_transaction_type)
		this.receiptService.receiptEntryForm.controls.other_account_name.setValue(receipt.other_account_value ? receipt.other_account_value : "")
		this.receiptService.receiptEntryForm.controls.other_account.setValue(receipt.other_account)
		this.receiptService.receiptEntryForm.controls.other_amount.setValue(receipt.other_transaction_amount)
	}

	getGeneralMaster(url) {
		this.receiptService.getMethod(url).subscribe(res => {
			this.generalMasterList = res
		}, err => {

		})
	}

	// doTransactionEntry(entries) {
	// 	this.receiptService.postMethod(`v3/receipt`, entries).subscribe(res => {
	// 	}, err => {

	// 	})
	// }
	hideError() {
		this.showMessage = false
	}

	viewJournal(invoice_no){
		let currentUrl = `transactions/credit-note/${this.invoiceMode}/${invoice_no}`
		localStorage.setItem('previousInvoiceUrl',currentUrl)
		this.router.navigate(['/transactions/view-invoice-journal',invoice_no])
	}

	openVoucher() {
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/receipt/print/${this.receiptId}`;

		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}
	openActivity() {
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/receipt/${this.receiptData.receipt_code}`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}
	closeWindow() {
		window.close();
	}

	openDeleteConfirmModal(){
		this.confirmDeleteModal.nativeElement.click()
		this.commonService.selectedInput.next(8)
	}
	closeDeleteConfirmModal(){
		this.closeDeleteModal.nativeElement.click()
	}

	openUpdateConfirmModal(){
		this.confirmUpdateModal.nativeElement.click()
		this.commonService.selectedInput.next(10)
	}
	closeUpdateConfirmModal(){
		this.closeUpdateModal.nativeElement.click()
	}

	exitEntry(e){
		this.receiptService.showAddreceiptScreen = false
		try {
			this.commonService.selectedInput.next(3)
			this.updateButton.nativeElement.focus()
		} catch (error) {
			this.commonService.selectedInput.next(3)
		}
		
	}



	// Approve and Reject Receipt entry done from IBE.
	
	openApproveConfirmModal(){
		this.confirmApproveModal.nativeElement.click()
		this.commonService.selectedInput.next(12)
	}
	closeApproveConfirmModal(){
		this.closeApproveModal.nativeElement.click()
		this.commonService.selectedInput.next(1)
	}

	openRejectConfirmModal(){
		this.confirmRejectModal.nativeElement.click()
		this.commonService.selectedInput.next(14)
	}
	closeRejectConfirmModal(){
		this.closeRejectModal.nativeElement.click()
		this.commonService.selectedInput.next(1)
	}

	approveReceipt(){
		this.spinner.show();
		this.closeApproveConfirmModal()
		let formValues = this.receiptService.receiptForm.value
		let currentUser = JSON.parse(localStorage.getItem('okountUserInfo'))
		
		let reqBody = {
			"receipt_id": formValues.receipt_id,
			"account_code_to": formValues.accountCodeTo,
			"receipt_timestamp": moment(formValues.receiptDate).format('YYYY-MM-DD'),
			"last_modified_by": currentUser.id,
			"last_modified_by_name": currentUser.name,
			"amount": formValues.amount,
			"status" : "active"
		}
		this.receiptService.postMethod(`receiptv2/verify`, reqBody).subscribe(async (res: any) => {
			this.submitResponse = res
			this.showMessage = true
			this.alertType = 'success-box';
			debugger
			this.message = `Receipt Approved with receipt code : ${this.submitResponse.receipt_code}`
			// alert(JSON.stringify(this.submitResponse))
			console.log('Receipt Arrove response : ', this.submitResponse)
			this.getReceiptDetails()
			// this.receiptService.receiptForm.reset()
			// this.receiptService.receiptEntries = []
			// this.receiptService.receiptForm.controls.accountCodeTo.setValue('')
			// this.receiptService.receiptForm.controls.receipt_id.setValue('')
			// this.receiptService.receiptForm.controls.receiptDate.setValue('')
			this.spinner.hide();
		},
			err => {
				this.showMessage = true
				this.alertType = 'error-box';
				this.message = `Failed to approve receipt entry.`
				this.spinner.hide();
			})
	}


	rejectReceipt(){
		this.spinner.show();
		this.closeRejectConfirmModal()
		let formValues = this.receiptService.receiptForm.value
		
		let reqBody = {
			"receipt_id": formValues.receipt_id,
			"status" : "rejected"
		}
		this.receiptService.postMethod(`receiptv2/verify`, reqBody).subscribe(async (res: any) => {
			this.submitResponse = res
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = `Receipt rejected.`
			console.log('Receipt Reject response : ', this.submitResponse)
			// this.getReceiptDetails()
			this.getReceiptDetails()
			this.spinner.hide();
		},
			err => {
				this.showMessage = true
				this.alertType = 'error-box';
				this.message = `Failed to reject receipt entry.`
				this.spinner.hide();
			})
	}
}
