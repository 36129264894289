import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,ElementRef,
} from "@angular/core";
import { InvoiceServicesService } from "./../flight/services/invoice-services.service";
import { FormBuilder, FormArray, Validators } from "@angular/forms";
import { InvoiceRequestBuilderService } from "./../flight/services/invoice-request-builder.service";
import { TotalFareModel } from "./../flight/models/total-fare-model";
import { ActivatedRoute } from "@angular/router";
import { CommonServiceService } from "src/app/shared/common-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { CookiesService } from "src/app/shared/cookies.service";

@Component({
  selector: "app-flight",
  templateUrl: "./flight.component.html",
  styleUrls: ["./flight.component.scss"],
})
export class FlightComponent implements OnInit {
  keyword;
  employeeList: any;

  expandTraveller: boolean = false;
  maximizedSection: boolean = false;

  crdIdArr: any = [];
  retrievedData: any;
  supplierArr: any = [];
  paid_ssrs_types: any = [];

  selectedSupplier: any = [];
  @Output() callProductEvent: EventEmitter<any> = new EventEmitter();
  @Output() callShowMessage: EventEmitter<any> = new EventEmitter();
  @Output() callInprogressEvent: EventEmitter<any> = new EventEmitter();
  @Output() callSupplierChange: EventEmitter<any> = new EventEmitter();
  @Output() openSsrModalMethod: EventEmitter<any> = new EventEmitter();

  @Input() invoiceMode = "save";
  @Input() invoice_no;

  @Input() selectedProduct;
  @Input() selectedProductIndex;
  @Input() invoiceType;
  @Input() invoice_root_data;
  @Input() masterCreationModal;
  @Input() isTenantIndian;

  productSubscription: Subscription;

  //KEYBOARD FOCUS VARS
  @ViewChild("airlineAC", { static: true }) airlineAC;
  @ViewChild("subLedgerAC", { static: true }) subLedgerAC;
  @ViewChild("employeeAC", { static: true }) employeeAC; 
	@ViewChild('deleteManuallyModal',{static:true}) deleteManuallyModal : ElementRef
	@ViewChild('closeDeleteModal',{static:true}) closeDeleteModal : ElementRef
  @ViewChild('openPaidSsrModal', {static: true}) openPaidSsrModal: ElementRef 

  showMessage = false;
  alertType = "";
  message = "";
  invalidTraveller = false;
  isAadesh:boolean = false

  retrievalConfig: any;
  loggedInuser: any;
  showAlertActionButton: boolean;
  alertActionButtonName: string;
  alertActionButtonFuntionType: string;
  narrationArr  = []
  @Input() sectorService

  constructor(
    private invoiceService: InvoiceServicesService,
    public requestBuilder: InvoiceRequestBuilderService,
    private route: ActivatedRoute,
    public commonService: CommonServiceService,
    private spinner: NgxSpinnerService,
    private store: Store<InitialState>,
    private cookiesService: CookiesService
  ) {
    this.route.paramMap.subscribe((params) => {
      // this.invoice_no = params["params"].invoice;
      this.invoiceMode = params["params"].mode ? params["params"].mode : "save";
    });
    if (this.selectedProduct != undefined) {
      this.sectorService.bindInvoiceData(this.selectedProduct["json_doc"]);
    }

    this.store.select('TransactionReducers').subscribe((items)=>{
			if(items && items.hasOwnProperty('narrationsArr')){
				if(items.narrationsArr.length>0){
					this.narrationArr = items.narrationsArr
				}
			}
		})

   
  }

  async ngOnInit() {
    this.spinner.show();
    let userData = JSON.parse(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA'))
    if(userData && userData.tenant_id && userData.tenant_id == environment.aadeshSystaccId) {
      this.isAadesh = true;
     }
    this.retrievalConfig = [{
      crs_id: "WEBSITE",
      display_name: "OTHER",
      display_key: "other",
      url: "",
      invioceType: "X",
      },
      {
        crs_id: "1A",
        display_name: "OTHER",
        display_key: "other",
        url: "",
        invioceType: "B",
      },
      {
        crs_id: "1G",
        display_name: "OTHER",
        display_key: "other",
        url: "",
        invioceType: "B",
    }];
    this.sectorService.totalFareModel = [];
    this.sectorService.fareList = [];
    this.sectorService.fareList[0] = {
      invoice_no: "New Invoice",
      type: "normal",
    };
    this.sectorService.totalFareModel.push(new TotalFareModel(null))
    this.sectorService.sectorForm.reset();
    this.sectorService.travellerForm.reset();
    this.sectorService.invoiceForm.reset();
    let loggedInUser = JSON.parse(localStorage.okountUserInfo);
    this.loggedInuser = loggedInUser
    this.sectorService.invoiceForm.controls.employee_name.setValue(
      loggedInUser.name
    );
    this.sectorService.invoiceForm.controls.employee.setValue(
      loggedInUser.accountcode
    );
	let res = await this.getCrsId();
    if (this.invoiceMode == "view" && this.invoice_no != undefined) {
      // this.getInvoiceData(this.invoice_no, 'flight');
      // this.getSupplier('website')
    } else {
      this.getSupplier("WEBSITE");
    }

    this.productSubscription = this.sectorService.setProductSourceCalled$.subscribe(
      (data) => {
        this.setProductDetails(data, this.invoice_no, "flight");
      },
      (error) => {}
    );
  }

  ngOnDestroy() {
    if(this.productSubscription){
      this.productSubscription.unsubscribe();
    }
  }
  focusAirlineAc() {
    if (this.airlineAC != undefined && this.airlineAC.nativeElement && this.airlineAC.nativeElement.focus) {
      this.airlineAC.nativeElement.focus();
    }
  }

  focusSubledgerAc() {
    if (this.subLedgerAC != undefined && this.subLedgerAC.nativeElement && this.subLedgerAC.nativeElement.focus) {
      this.subLedgerAC.nativeElement.focus();
    }
  }

  focusEmployeeAc() {
    if (this.employeeAC != undefined && this.employeeAC.nativeElement && this.employeeAC.nativeElement.focus) {
      this.employeeAC.nativeElement.focus();
    }
  }
  async setProductDetails(productData, searchId, type) {
    this.spinner.show();
    let crsId = productData["json_doc"].crs_id_value
      ? productData["json_doc"].crs_id_value
      : "WEBSITE";
    let supplierRes = await this.getSupplier(crsId.toUpperCase());
    let bindRes = await this.sectorService.bindInvoiceData(
      productData["json_doc"]
    );
    this.spinner.hide();
    this.getAllReferenceData(searchId);
    // this.getCreditNote(searchId, type)
    // this.onInvoiceTypeChange(productData["json_doc"].invoiceType ? productData["json_doc"].invoiceType : "X",false);
  }

  getInvoiceData(searchId: any, type: any) {
    try {
      this.invoiceService
        .getMethod(`invoice?invoiceNumber=${searchId}&type=${type}`)
        .subscribe(
          async (res) => {
            let invoiceData = res["data"][0];
            // this.sectorService.fareList[0] = {
            // 	invoice_no : invoiceData.invoice_no,
            // 	type : invoiceData.invoice_type ? invoiceData.invoice_type : 'normal'
            // }
            // console.log('Invoice list in getInvoiceData : ',this.sectorService.fareList)
            this.sectorService.bindInvoiceData(
              invoiceData["products"][0]["json_doc"]
            );
            this.getAllReferenceData(searchId);
            // this.getCreditNote(searchId,type)
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
          }
        );
    } catch (error) {
      console.log("Error occured in getInvoice due to : ", error);
    }
  }

  getAllReferenceData(referenceCode) {
    return new Promise((resolve, reject) => {
      this.commonService
        .getRequestNoloader(
          `${environment.okountUrl}/invoice/getAllReferenceData?reference_code=${referenceCode}`
        )
        .subscribe(
          async (res) => {
            let responseData = res["data"];
            for (const key in responseData) {
              if (responseData.hasOwnProperty(key)) {
                const data = responseData[key];
                if (data["transaction_type"] == "invoice") {
                  let fareItem = this.sectorService.fareList.filter(
                    (option) => {
                      return option.invoice == data.invoice_no;
                    }
                  );
                  if (fareItem.length == 0) {
                    let obj = {
                      invoice_no: data.invoice_no,
                      type: data.invoice_type,
                    };
                    this.sectorService.fareList.push(obj);
                    console.log(
                      "Invoice list in getAllReference invoice : ",
                      this.sectorService.fareList
                    );
                  }
                } else {
                  let fareItem = this.sectorService.fareList.filter(
                    (option) => {
                      return option.invoice == data.credit_note_code;
                    }
                  );
                  if (fareItem.length == 0) {
                    let obj = {
                      invoice_no: data.credit_note_code,
                      type: data.invoice_type,
                    };
                    this.sectorService.fareList.push(obj);
                    console.log(
                      "Invoice list in getAllReference credit_note : ",
                      this.sectorService.fareList
                    );
                  }
                }
                await this.sectorService.bindCreditNoteDataV2(
                  data["products"][0]["json_doc"],
                  key,
                  data["transaction_type"]
                );
              }
            }
            resolve(res);
          },
          (err) => {}
        );
    });
  }

  getCreditNote(searchId: any, type: any) {
    //Depricated
    try {
      this.invoiceService
        .getMethod(`creditNote?credit_note_no=${searchId}&type=${type}`)
        .subscribe(
          async (res) => {
            let creditNoteData = res["data"];
            // this.invoiceUUID = creditNoteData.invoice_uuid
            for (const key in creditNoteData) {
              if (creditNoteData.hasOwnProperty(key)) {
                const element = creditNoteData[key];
                let fareItem = this.sectorService.fareList.filter((option) => {
                  return option == element.credit_note_code;
                });
                if (fareItem.length == 0) {
                  let obj = {
                    invoice_no: element.credit_note_code,
                    type: "normal",
                  };
                  this.sectorService.fareList.push(obj);
                }
                await this.sectorService.bindCreditNoteData(
                  element["products"][0]["json_doc"],
                  key
                );
              }
            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
          }
        );
    } catch (error) {
      console.log("Error occured in getInvoice due to : ", error);
    }
  }
  getCrsId() {
    return new Promise((resolve, reject) => {
      this.commonService
        .getRequest(
          `${environment.pnrRetrieveUrls.base}portal-configuration/configuration/get-flight-supplier-urls`
        )
        .subscribe(
          (res: any) => {
            this.setSupplierUrls(res)
            resolve("");
          },
          (err) => {
            this.setSupplierUrls(this.commonService.portalConfiguration)
            console.log("Error while getting CRS IDs : ", err);
            resolve("");
          }
        );
    });
  }
  setSupplierUrls (retrievalConfig){
    this.crdIdArr = [];
    let userData = JSON.parse(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA'))
    if(userData && userData.tenant_id && userData.tenant_id == environment.aadeshSystaccId) {
     this.retrievalConfig = this.retrievalConfig.concat(retrievalConfig);
    }    
    this.sectorService.retrievalConfig =  this.retrievalConfig
    for (const data of retrievalConfig) {
      console.log(data.crs_id);
      if (
        data.crs_id != null &&
        this.crdIdArr.indexOf(data.crs_id) == -1
      ) {
        this.crdIdArr.push(data.crs_id);
      }
      if (retrievalConfig.indexOf(data) == 0) {
        this.sectorService.invoiceForm.controls.crsId.setValue(
          "WEBSITE"
        );
      }
    }
  }
  getSupplier(crsId) {
    return new Promise((resolve, reject) => {
      //   try {
      //     this.invoiceService
      //       .getFromOkunt(`crs/supplier?type=${crsId}`)
      //       .subscribe(
      //         (data) => {
      //           this.supplierArr = data;
      //           resolve("");
      //           if (this.supplierArr.length > 0) {
      //             this.onSupplierChange(this.supplierArr[0].id);
      //             this.sectorService.invoiceForm.controls.supplier.setValue(
      //               this.supplierArr[0].id ? this.supplierArr[0].id : ""
      //             );
      //           }

      //           if (crsId == "WEBSITE") {
      //             this.ledgerSearch("CC001A");
      //           } else {
      //             this.ledgerSearch("CC001B");
      //           }
      //         },
      //         (err) => {
      //           resolve("");
      //           throw new Error(JSON.stringify(err));
      //         }
      //       );
      //   } catch (err) {
      //     resolve("");
      //     console.log("Error while getting Suppliers : ", err);
      //   }

      try {
        this.supplierArr = [];
        for (const crs of this.retrievalConfig) {
          if (
            crs &&
            crs.crs_id &&
            crs.crs_id == crsId
          ) {
            this.supplierArr.push(crs);
          }
        }
        if (this.supplierArr.length > 0) {
          this.onSupplierChange(this.supplierArr[0].display_name);
          
          this.sectorService.invoiceForm.controls.supplier.setValue(
            this.supplierArr[0].display_name
              ? this.supplierArr[0].display_name
              : ""
          );
        }

        // if (crsId == "WEBSITE") {
        //   this.ledgerSearch("CC001A");
        // } else {
        //   this.ledgerSearch("CC001B");
        // }
        resolve("");
      } catch (error) {
        resolve("");
        console.log("Error while getting Suppliers : ", error);
      }
    });
  }

  onSupplierChange(value) {
    try {
      this.selectedSupplier = this.supplierArr.filter((option) => {
        if (option.display_name == value) {
          this.sectorService.invoiceForm.controls.invoiceType.setValue(
            option.type ? option.type : "X"
          );
          this.onInvoiceTypeChange(option.type ? option.type : "X",true);
        }
        return option.display_name == value;
      });
      if(value == 'OTHER'){
        this.sectorService.invoiceForm.controls.lastName.setValidators([Validators.required])
      }else{
        this.sectorService.invoiceForm.controls.lastName.setValidators(null)
      }
      this.sectorService.invoiceForm.updateValueAndValidity()
      this.callSupplierChange.emit(this.selectedSupplier)
    } catch (error) {
      console.log("Error occured while selecting supplier : ", error);
    }
  }
  async onInvoiceTypeChange(value,update) {
    try {
      let status;
      if (
        value == "C" &&
        this.sectorService.travellerForm.value.travellerList.length > 0
      ) {
        status = await this.sectorService.updateTravellersValidators(
          "txtbookingSupplier",
          "required",
          update ? value : ''
        );
        this.sectorService.enablePayable = true
      } else {
        status = await this.sectorService.updateTravellersValidators(
          "txtbookingSupplier",
          "null",
          update ? value : ''
        );
        this.sectorService.enablePayable = false
      }
    } catch (error) {
      console.log("Error occured while selecting invoice Type : ", error);
    }
  }

  onTravellerBlur(event) {
    if(this.sectorService.travellerList && Number(event.target.value) <  this.sectorService.travellerList.controls.length){
      // window.alert(`Please delete travellers manually.`)
      this.deleteManuallyModal.nativeElement.click();
      this.sectorService.invoiceForm.controls.travellerCount.setValue(this.sectorService.travellerList.controls.length)
      return false
    }
    let bindResponse = this.sectorService.bindTraveller(event.target.value, this.invoice_root_data);
    this.onInvoiceTypeChange(
      this.sectorService.invoiceForm.controls.invoiceType.value,true
    );
  }

  retrieveBooking() {
    this.commonService.selectedInput.next(12); // subledeger index
    try {
      this.spinner.show();
      let messageObj = {
        status: false,
        alertType: "",
        message: "",
      };
      this.callShowMessage.emit(messageObj);
      let supplierUrl = this.selectedSupplier[0].url;
      let pnr = this.sectorService.invoiceForm.controls.pnr.value;
      let serviceClass = this.sectorService.invoiceForm.controls.classType.value;
      let affiliate_code=this.sectorService.affiliate_code
      let airline = this.sectorService.invoiceForm.controls.airline_name.value
      let airline_code = this.sectorService.invoiceForm.controls.airline.value
      const lthis = this;
      let lastName = this.sectorService.invoiceForm.controls.lastName.value
      let userData = JSON.parse(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA'))
      let url = ''
      if (userData && userData.tenant_id && userData.tenant_id == environment.aadeshSystaccId) {
        url = `${supplierUrl}${pnr}`;
        // if(url && airline_code && display_name == 'VERTEIL') {
        //    url = `${supplierUrl}${pnr}`
        //   let airline2LtrCode = airline_code.substring(0, 2);
        //   url += `?service_class=${airline2LtrCode}&pnr=${pnr}&affiliate_id=${}`;
        // }
        console.log("serviceClass", serviceClass)
        let display_name = this.selectedSupplier[0].display_name;
        if (['AMADEUS', 'AMADEUS STUD'].includes(display_name)) {
          // if (!serviceClass || serviceClass == null) {
          //   let messageObj = {
          //     status: true,
          //     alertType: "error-box",
          //     message: 'Class is required'
          //   };
          //   this.callShowMessage.emit(messageObj);
          //   throw new Error("Class is required")
          // }
          url = `${supplierUrl}?default=&serviceClass=${serviceClass}&pnr=${pnr}&affiliateId=${affiliate_code}`
        }
        if (url && airline_code && display_name == 'VERTEIL') {
          let airline2LtrCode = airline_code.substring(0, 2);
          url += `?airline=${airline2LtrCode}`;
        }
      } else {
        url = environment.okountUrl + `/retrieve?pnr=${pnr}&lastName=${lastName}&airline_code=${airline_code}`
      }
      this.sectorService.getInvoiceRootvalue(this.invoice_root_data);
      this.commonService.getRequest(url).subscribe(
        async (res: any) => {
          let data = res;
          if (data.pnr != null && data.sector != null) {
            lthis.retrievedData = data;
            
            await this.sectorService.bindRetrievedData(lthis.retrievedData);
            let resp = await this.calculateFareFromApi(0);
            this.saveProductInvoice()
            this.spinner.hide();
            
            let ssrs =  lthis.retrievedData.ssrResponse && lthis.retrievedData.ssrResponse['ssrs'] ? lthis.retrievedData.ssrResponse['ssrs'] : []
            
            let ssrTypes = []
            for(let ssr of ssrs) {
              let baggage = ssr.baggages.filter(baggage => baggage.price != 0) 
              if(baggage.length > 0 && !ssrTypes.includes("baggages")) {
                ssrTypes.push("baggages")
              }
              let meals = ssr.meals.filter(meal => meal.price != 0)
              if(meals.length > 0 && !ssrTypes.includes("meals")) {
                ssrTypes.push("meals")
              }
              let seats = ssr.seats.filter(seat => seat.price != 0)
              if(seats.length > 0 && !ssrTypes.includes("seats")) {
                ssrTypes.push("seats")
              }
            }

            lthis.retrievedData['airline'] = airline
            localStorage.setItem("retrievedData", JSON.stringify(lthis.retrievedData))
            
            if(ssrTypes && ssrTypes.length > 0) {
              this.openSsrModalMethod.emit(ssrTypes)            
            }

          } else {
            this.spinner.hide();
            throw new Error("Null PNR or missing sector information");
          }
        },
        (err) => {
          this.spinner.hide();
          let message = "Failed to Retrive PNR.";
          if (err.error) {
            if (err.error.message) {
              message = err.error.message;
            }
          }
          let messageObj = {
            status: true,
            alertType: "error-box",
            message: message,
          };
          this.callShowMessage.emit(messageObj);
          throw new Error(message);
        }
      );
    } catch (error) {
      this.spinner.hide();
      console.log("Error in pnr retrieval : ", error);
    }
  }

  async saveProductInvoice() {
    this.sectorService.invoiceForm.controls.booking_ref.setValue(this.invoice_root_data.booking_ref_no.value)
    let product_json_doc = this.requestBuilder.createInvoiceProduct(
      this.sectorService.travellerForm.value,
      this.sectorService.sectorForm.value,
      this.sectorService.invoiceForm.value,
      this.sectorService
    );
    let crsId = await this.requestBuilder.getCRSID(
      this.sectorService.invoiceForm.value,
      this.selectedSupplier[0].acode
    );
    product_json_doc["crs_id"] = crsId;

    product_json_doc[
      "crs_id_value"
    ] = this.sectorService.invoiceForm.value.crsId;
    product_json_doc[
      "airline"
    ] = this.sectorService.invoiceForm.controls.airline.value;
    product_json_doc[
      "supplier"
    ] = this.sectorService.invoiceForm.controls.supplier.value;
    product_json_doc[
      "invoiceType"
    ] = this.sectorService.invoiceForm.controls.invoiceType.value;
    product_json_doc[
      "fareBasis"
    ] = this.sectorService.invoiceForm.controls.fareBasis.value;
    product_json_doc[
      "dealCode"
    ] = this.sectorService.invoiceForm.controls.dealCode.value;
    product_json_doc[
      "classType"
    ] = this.sectorService.invoiceForm.controls.classType.value;
    let total_fare = {};
    for (const obj in this.sectorService.totalFareModel[0]) {
      if (obj != "0") {
        total_fare[obj] = `${this.sectorService.totalFareModel[0][obj]}`;
      }
    }
    product_json_doc["total_fare"] = total_fare;
    // product_json_doc['boooking_supplier'] = this.selectedSupplier[0].scode;

    let requestObj = {
      travellers: this.sectorService.travellerForm.value,
      sectors: this.sectorService.sectorForm.value,
      invoiceForm: this.sectorService.invoiceForm.value,
    };
    let narration = "";
    let narrArr = []
    let hsn = "";
    try {
      let saveTravellerRes = await this.saveTravellers(requestObj);
      if (saveTravellerRes != "" && saveTravellerRes != undefined) {
        hsn = saveTravellerRes["hsn"] ? saveTravellerRes["hsn"] : "";
      }
    } catch (error) {}

    narration = this.narrationArr.join(", ")
    narrArr = this.narrationArr
    let productObj = await this.requestBuilder.createProductObj(
      product_json_doc,
      this.sectorService.totalFareModel[0],
      narration,
      hsn,
      this.sectorService
    );
    productObj['narrationArr'] = narrArr;
    let product_list = JSON.parse(sessionStorage.getItem("product_list"));
    if (product_list == null) {
      product_list = [];
    }
    //to add multiple product uncomment below code
    // product_list.push(productObj)
    product_list[0] = productObj;
    sessionStorage.setItem("product_list", JSON.stringify(product_list));
    
    // await this.ngOnInit()
    this.callProductEvent.emit(productObj);
    this.commonService.selectedInput.next(
      this.sectorService.lastIndexOfTraveller + 3
    );
    this.showMessage = false;
  }

  async updateProduct() {
    // console.table(this.sectorService.travellerForm.value.travellerList)
    // console.table(this.sectorService.sectorForm.value)
    // console.table(this.sectorService.invoiceForm.value)
    this.sectorService.invoiceForm.controls.booking_ref.setValue(this.invoice_root_data.booking_ref_no.value)
    let product_json_doc = this.requestBuilder.createInvoiceProduct(
      this.sectorService.travellerForm.value,
      this.sectorService.sectorForm.value,
      this.sectorService.invoiceForm.value,
      this.sectorService
    );
    let crsId = await this.requestBuilder.getCRSID(
      this.sectorService.invoiceForm.value,
      this.selectedSupplier[0].acode
    );
    product_json_doc["crs_id"] = crsId;
    product_json_doc[
      "crs_id_value"
    ] = this.sectorService.invoiceForm.value.crsId;
    product_json_doc[
      "airline"
    ] = this.sectorService.invoiceForm.controls.airline.value;
    product_json_doc[
      "supplier"
    ] = this.sectorService.invoiceForm.controls.supplier.value;
    product_json_doc[
      "invoiceType"
    ] = this.sectorService.invoiceForm.controls.invoiceType.value;
    product_json_doc[
      "fareBasis"
    ] = this.sectorService.invoiceForm.controls.fareBasis.value;
    product_json_doc[
      "dealCode"
    ] = this.sectorService.invoiceForm.controls.dealCode.value;
    product_json_doc[
      "booking_ref_no"
    ] = this.sectorService.invoiceForm.controls.booking_ref.value;
    product_json_doc[
      "classType"
    ] = this.sectorService.invoiceForm.controls.classType.value;
    // product_json_doc['boooking_supplier'] = this.selectedSupplier[0].scode;
    let total_fare = {};
    for (const obj in this.sectorService.totalFareModel[0]) {
      if (obj != "0") {
        total_fare[obj] = `${this.sectorService.totalFareModel[0][obj]}`;
      }
    }
    product_json_doc["total_fare"] = total_fare;
    let requestObj = {
      travellers: this.sectorService.travellerForm.value,
      sectors: this.sectorService.sectorForm.value,
      invoiceForm: this.sectorService.invoiceForm.value,
    };
    let saveTravellerRes = await this.saveTravellers(requestObj);

    let narration = "";
    let narrArr = []
    let hsn = "";
    if (saveTravellerRes != "" && saveTravellerRes != undefined) {
      narration = saveTravellerRes["narration_1"]
        ? saveTravellerRes["narration_1"]
        : "";
      narrArr = saveTravellerRes["narration_2"]
      hsn = saveTravellerRes["hsn"] ? saveTravellerRes["hsn"] : "";
    }
    let productObj = await this.requestBuilder.createProductObj(
      product_json_doc,
      this.sectorService.totalFareModel[0],
      narration,
      hsn,
      this.sectorService
    );
    productObj['narrationArr'] = narrArr;
    let product_list = JSON.parse(sessionStorage.getItem("product_list"));
    if (product_list == null) {
      product_list = [];
    }
    // product_list.push(productObj)
    product_list[this.selectedProductIndex] = productObj;
    product_list[0] = productObj;
    sessionStorage.setItem("product_list", JSON.stringify(product_list));
    // await this.ngOnInit()

    this.callProductEvent.emit(productObj);
    this.showMessage = false;
    this.commonService.selectedInput.next(
      this.sectorService.lastIndexOfTraveller + 3
    );
  }

  calculateFareFromApi(index) {
    let fareObj = this.requestBuilder.createFareObj(
      this.sectorService.travellerForm.value,
      this.sectorService.invoiceForm.value,
      this.sectorService.affiliate_code,
      this.invoice_root_data
    );
    this.sectorService.fareList[0] = {
      invoice_no: "New Invoice",
      type: "normal",
    };
    console.log(
      "Invoice list in calculateFareFromApi : ",
      this.sectorService.fareList
    );
    this.invoiceService.postMethod(`invoice/fare`, fareObj).subscribe(
      (res) => {
        this.sectorService.totalFareModel[index] = new TotalFareModel(res);
      },
      (err) => {}
    );
  }

  async saveTravellers(value) {
    let travellersObj = await this.requestBuilder.createTravellers(
      value.travellers
    );
    let sectorObj = await this.requestBuilder.createSectorsObj(
      value.sectors,
      value.invoiceForm,
      this.sectorService
    );
    var url = window.location.href.slice(window.location.href.indexOf("?") + 1);
    let invNo = url.split("=")[1];
    if (invNo == undefined) {
      invNo = this.invoice_no;
    }
    if (invNo != undefined) {
      let requestObj = {
        json_doc: travellersObj,
        sectors: sectorObj,
        uuid: invNo,
      };
      return new Promise((resolve, reject) => {
        this.invoiceService
          .postMethod("invoice/traveller/save", requestObj)
          .subscribe(
            (res) => {
              let response = res;
              resolve(response);
              console.log("Travellers save response - SUCCESS : ", res);
            },
            (err) => {
              resolve("");
              console.log("Travellers save response - ERROR : ", err);
            }
          );
      });
     
    }
  }

  getLedgers(url) {
    this.invoiceService.getMethod(url).subscribe(
      (res) => {
        this.sectorService.ledgerList = res;
      },
      (err) => {}
    );
  }

  getAccounts(value) {
    this.sectorService.getAccounts(value);
  }

  onFocused(e) {}

  closeMaxModule() {
    this.commonService.minimizeModule(true);
  }

  employeeSearch(event) {
    let value = event.target.value;
    try {
      // let searchType = this.sectorService.invoiceForm.controls.sub_ledger_code_1_type.value;
      if (value.length > 2) {
        this.invoiceService
          .searchAccount(
            `employee/autocomplete?name_like=${value.toUpperCase()}`
          )
          .subscribe(
            (res) => {
              this.employeeList = res;
            },
            (error) => {}
          );
      }
    } catch (error) {}
  }

  airlineSearch(event) {
    let value = event.target.value;
    try {
      // let searchType = this.sectorService.invoiceForm.controls.sub_ledger_code_1_type.value;
      if (value.length > 2) {
        this.sectorService.currentMasterName = value;
				this.sectorService.currentFormControlName = 'airline'
        this.invoiceService
          .searchAccount(
            `airline/autocomplete?name_like=${value.toUpperCase()}`
          )
          .subscribe(
            (res) => {
              this.sectorService.airlineArr = res;

              if(!this.sectorService.airlineArr || this.sectorService.airlineArr.length == 0) {
                this.sectorService.showCreateButton = true;
                this.sectorService.currentMaster = 'airline';
              } else {
                this.sectorService.showCreateButton = false;
                this.sectorService.currentMaster = '';
              }
            },
            (error) => {}
          );
      }
    } catch (error) {}
  }

  ledgerSearch(value) {
    try {
      let searchType = this.sectorService.invoiceForm.controls
        .sub_ledger_code_1_type.value;
      if (searchType == null) {
        this.sectorService.invoiceForm.controls.sub_ledger_code_1_type.setValue(
          ""
        );
        searchType = this.sectorService.invoiceForm.controls
          .sub_ledger_code_1_type.value;
      }
      if (value.length > 2 && searchType != null && searchType != "") {
        this.invoiceService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}`
          )
          .subscribe(
            (res) => {
              this.sectorService["ledgerList"] = res;
              // if (value == "CC001A" || value == "CC001B") {
              //   this.selectAccount(res[0], "sub_ledger_code_1");
              //   this.sectorService.invoiceForm.controls.sub_ledger_code_1_name.setValue(
              //     res[0].name
              //   );
              // }
            },
            (error) => {}
          );
      }
    } catch (error) {}
  }

  selectAccount(nzEvent, formControlName) {
    let nzValue = nzEvent.nzValue;
    let value = nzValue;
    if(value) {
      try {
        if(formControlName == 'employee_name') {
          this.sectorService.invoiceForm.controls['employee_name'].setValue(value.name)
          this.sectorService.invoiceForm.controls['employee'].setValue(value.account_code)
          return;
        }
        this.sectorService.invoiceForm.controls[formControlName].setValue(
          value.account_code
        );
        if(formControlName =='airline'){

          this.commonService.selectedInput.next(10)
        }
      } catch (error) {}
    }else {
      if(formControlName == 'airline') {
        this.sectorService.invoiceForm.controls.airline_name.setValue(this.sectorService.currentMasterName)
        this.masterCreationModal.nativeElement.click()
        return false;
      }
    }
  }

  keytab(event) {
    let element = event.srcElement.nextElementSibling; // get the sibling element

    if (element == null)
      // check if its null
      return;
    else element.focus(); // focus if not null
  }
  moveToIndex(index) {
    if (this.invoiceMode == "view") {
      this.commonService.selectedInput.next(index);
    }
  }

  showMessageFn(data) {
    this.showMessage = data.showMessage;
    this.alertType = data.alertType;
    this.message = data.message;
    this.invalidTraveller = data.isInvalid;
  }

  hideError() {
    this.showMessage = false;
  }
  handlePnrInput(override){
    let reqObj = {
      pnr : this.sectorService.invoiceForm.controls.pnr.value,
      created_by : this.loggedInuser.name,
      override : override
    }

    if(this.invoiceMode =='save' && this.sectorService.invoiceForm.controls.pnr.value && this.sectorService.invoiceForm.controls.pnr.value.length > 4){
      this.invoiceService.postMethod(`invoice/inProgressInvoice`,reqObj).subscribe((res : any)=> {
        if(res && res.inProgress == true && res.data[0] && res.data[0].created_by != this.loggedInuser.name){
          this.callInprogressEvent.emit({
            showModal : true,
            message : `${res.data[0].created_by} is already working on PNR : ${res.data[0].pnr}`
          })
        }
      })
    }
    if(this.invoiceMode =='view'){
      this.moveToIndex(10)
    }
  }

  handleAlertAction(actionFuntionType){
    if(actionFuntionType == 'override'){
      this.handlePnrInput(true)
    }
  }
  
}


