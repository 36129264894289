import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { Router } from '@angular/router';
import { CookiesService } from 'src/app/shared/cookies.service';

@Component({
    selector: 'app-create-bank',
    templateUrl: './create-bank.component.html',
    styleUrls: ['./create-bank.component.scss']
  })
export class CreateBankComponent implements OnInit {

  baseUrl = environment.okountUrl;
  submitted = false;
  bankForm : FormGroup;
  groupList: any;
  showMessage: boolean = false;
  alertType  = '';
  message: string  = ``;
  createdGeneralId = ""
  createdGeneralCode = ""
  stateArr: any = [];
  cityArr: any = [];
  newGeneral: boolean = true;
  isTenantIndian: boolean = false;
  remainingAmount = 0
    amountType = 'credit'
	vatAlternetname: string = 'VAT'

  isHQBranch: boolean = false;
  @ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef
	@ViewChild("openSuccessModal", { static: true }) openSuccessModal: ElementRef;
	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef;
    @ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef;
	branches = []
  constructor(private fb: FormBuilder, private spinner: NgxSpinnerService,
    public commonService: CommonServiceService, private router: Router, private cookieService : CookiesService) { 
    }

  async ngOnInit() {
    this.spinner.show(); 
    setTimeout(() => {		   
      this.spinner.hide();
    }, 1000);

    this.bankForm = this.fb.group({
      parent_id : ['' , Validators.required],
      account_name : ['' , Validators.required] ,
	  ifsc_code: [''],
	  account_number: [''],
	  swift_code:[''],
	  balance : [''],
      balance_type: ['debit'],
      state_id : [''],
	  state_name : [''],
	  city_id : [''],
	  city_name : [''],
      gst_number: [''],
	  vat_number: [''],
	  hierarchy: [''],
	  sub_type: ['bank'],
	  sync_in_branches: [''],
	  branch:{},
    })
    this.getStates('101')
    this.getGroups()
	
	this.isTenantIndian = this.commonService.checkTenantIndian()
	if(!this.isTenantIndian){
		let globalSettingConfig = this.commonService.globalSettingConfig;
	if(!globalSettingConfig || globalSettingConfig.length == 0) {
		globalSettingConfig = await this.commonService.getGlobalSettings();
	}
		let inv_config = globalSettingConfig.filter(el=>el.group=='VAT')
	if(inv_config.length){
		let vat_config = inv_config[0].json_doc.value.filter(el=>el.key=='vat_rate')
		let vat_detail,vat_name
		if(vat_config.length){
			 vat_detail = vat_config[0].value
		}
		if(vat_detail && vat_detail.length ){
			vat_name = vat_detail.filter(el=>el.key=='effective_name')
		}
		if(vat_name && vat_name.length){
			this.vatAlternetname = vat_name[0].value
		}
	}
	}

	this.isHQBranch = this.commonService.checkHQBranch()
	if(this.isHQBranch==true){
		this.getAllBranches()
		
		// this.branchForm = this.fb.group({
		// 	formObj
		// })
	}
  }

  getStates(country_id) {
		this.commonService.getOkount(`location/states?country_id=${country_id}`).subscribe(res => {
			this.stateArr = res
		})
	}

  selectState(state_id) {
		let selectedState = this.stateArr.filter(option => {
			return option.location_id == Number(state_id);
		})
		if (selectedState.length > 0) {
			this.bankForm.controls.state_name.setValue(selectedState[0].name)
		}
		this.getCities(state_id)
	}

  openConfirmModal() :  void{
		this.confirmModal.nativeElement.click()
	}
	closeConfirmModal() :  void{
		this.closeModal.nativeElement.click()
	}

  reset() {
		this.bankForm.reset();
		this.bankForm.controls.sub_type.setValue('bank')
	}

  submit(){
		this.submitted = true;
        this.closeConfirmModal()
		if(this.bankForm.valid){
            this.commonService.postRequest(`${this.baseUrl}/general`, this.bankForm.value).subscribe(res=>{
				this.updateOpeningBalanceAdjustAcc()
				this.showMessage =false
				this.newGeneral = false
				this.alertType = 'success-box';
				this.reset()
				this.createdGeneralId = res[0].id ? res[0].id : ""
				this.createdGeneralCode = res[0].account_code ? res[0].account_code : ""
				this.message = 'Bank saved successfully with account code. :'
				this.openSuccessModal.nativeElement.click()
				}, 
				error => {
				this.showMessage =true
				this.alertType = 'error-box';
				this.createdGeneralId = ""
				this.message = 'Failed to create bank. Please try Again.'
			})
		}
		else {
			this.showMessage =true
			this.alertType = 'warning-box';
				this.message = 'Please fill mandatory fields.'
			return false;
		}
	}
 
  getGroups(){
		this.commonService.getOkount('v3account/getallsuperparents').subscribe(res=>{
			this.groupList = res
			
			if(this.groupList && this.groupList.length > 0) {
				let item = this.groupList.find(item => item.account_code == 'BA0000003')
				this.bankForm.controls.parent_id.setValue(item.id)
			}
			
		},err=>{

		})
	}

  hideError(){
		this.showMessage = false
	}

	setHierarchy(parentId,onload){
		let hierarchy = this.bankForm.value.hierarchy
		if(!onload || !hierarchy){
			let parentAccount = this.groupList && this.groupList.filter(data=> {
				return data.id == parentId
			})
			if(parentAccount && parentAccount.length > 0){
				hierarchy = Number(parentAccount[0].hierarchy)+1
			}
		}
		this.bankForm.controls.hierarchy.setValue(hierarchy)
	}

	closeSuccesModal(){
		this.reset()
		this.newGeneral = true
		this.showMessage = false;
		this.alertType = 'success-box';
		this.message = ``
		this.openSuccessModal.nativeElement.click()
	}

	viewGeneral(bank_id) {
		this.closeSuccesModal()
        this.router.navigate([`/master/bank/update-bank/${bank_id}`])
	}

	getCities(state_id) {
		let selectedState = this.stateArr.filter(option => {
			return option.location_id == Number(state_id);
		})
		if (selectedState.length > 0) {
			this.bankForm.controls.state_name.setValue(selectedState[0].name)
		}
		this.commonService.getOkount(`location/city?state_id=${state_id}`).subscribe(res => {
			this.cityArr = res
		})
	}

	getCityName(city_id) {
		let selectedCity = this.cityArr.filter(option => {
			return option.location_id == Number(city_id);
		})
		if (selectedCity.length > 0) {
			this.bankForm.controls.city_name.setValue(selectedCity[0].name)
		}
	}

	getAllBranches() {
        this.commonService.getOkount(`branch`).subscribe(res=> {
          if(res && res['data']) {
            this.branches = res['data'];
			let formObj = this.branches.reduce((acc,curr)=>{
				if(curr.branch_type=='BRANCH'){
					acc[curr.branch_name]=false
				}
				return acc
			},{})
			console.log("formObj",formObj)
			this.bankForm.controls.branch.setValue(formObj)
          }
        },err=> {
    
        })
      }

	  handleValueChange(e,branchName){
		console.log("branchName",this.bankForm.controls.branch)
		this.bankForm.controls.branch.value[branchName]=e.currentTarget.checked
	  }
	  async checkBalance(){
        let opening_amount = await  this.getOpeningBalanceData()
        if(this.bankForm.controls.balance_type.value=='debit') opening_amount['debit']= Number(opening_amount['debit']) + Number(this.bankForm.controls.balance.value)
        if(this.bankForm.controls.balance_type.value=='credit') opening_amount['credit']= Number(opening_amount['credit']) + Number(this.bankForm.controls.balance.value)
            console.log("opening_amount",opening_amount)
        if(opening_amount && (opening_amount['debit']!=opening_amount['credit'])) {
            this.remainingAmount = Math.abs(Number((opening_amount['debit'] - opening_amount['credit']).toFixed(2)))
            this.amountType = opening_amount['debit']>opening_amount['credit'] ? 'credit' : 'debit'
			let res = await this.adjustAccOpeningBalance()
            if(res['balance']!=this.remainingAmount || this.amountType!=res['balance_type']){
                this.openUpdateConfirmModal()
			}else{
				this.updateOpeningBalancecombine()
			}
        }else{
            this.updateOpeningBalancecombine()
        }
    }

	adjustAccOpeningBalance(){
        return new Promise(async (resolve, reject) => {
        this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/globalsearch/opening_balance`, {client: 'OB0000165'}).subscribe(res => {
            resolve(res)
        },err=>{
            console.log("err",err)
            resolve(null)
        })
    })
    }
	getOpeningBalanceData(){
        return new Promise(async (resolve, reject) => {
            this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/openingBalanceTotal`, {}).subscribe(res => {
                resolve(res)
            },err=> {
                resolve(null)
            })
        })
    }
	openUpdateConfirmModal(){
		this.confirmUpdateModal.nativeElement.click()
	}
	 updateOpeningBalancecombine(){
        this.closeUpdateConfirmModal()
         this.submit()
    }
	closeUpdateConfirmModal(){
		this.closeUpdateModal.nativeElement.click()
	}
	updateOpeningBalanceAdjustAcc() {
        let req_body = {
            balance: this.remainingAmount,
            balance_type: this.amountType,
            account_code: 'OB0000165'
        }
        this.commonService.putRequest(`${environment.okountUrl}/client/updateOpeningBalanceAdjust`, req_body).subscribe(res => {
           
            console.log(res)
        }, err => {
        })

    }

}
