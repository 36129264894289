import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

import * as moment from 'moment';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { InvoiceServicesService } from '../invoice/flight/services/invoice-services.service';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TabService } from 'src/app/shared/services/tab.service';
import { CookiesService } from 'src/app/shared/cookies.service';
import * as FileSaver from 'file-saver';
import { ProductService } from './services/product.service';
import { DownloadService } from 'src/app/shared/download.service';
import { Tab } from 'src/app/shared/model/tab.model';
import { InvoiceComponent } from 'src/app/transactions/invoice/invoice.component';
import { MsInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/ms-invoice/ms-invoice.component';
import { MiscInvoiceComponent } from 'src/app/transactions/misc-invoice/misc-invoice.component';
import { InvoiceRefundComponent } from 'src/app/transactions/invoice-refund/invoice-refund.component';


@Component({
  selector: 'app-consolidate-invoice',
  templateUrl: './consolidate-invoice.component.html',
  styleUrls: ['./consolidate-invoice.component.scss'],
  providers: [ProductService]
})

export class ConsolidateInvoiceComponent implements OnInit {
  @Input() data;

  @ViewChild("subLedgerAC", { static: true }) subLedgerAC;
  @ViewChild("supplierAc", { static: true }) supplierAc;
  @ViewChild("supplier2Ac", { static: true }) supplier2Ac;
  @ViewChild("clientAC", { static: true }) clientAC;
  @ViewChild("generalLedgerAc", { static: true }) generalLedgerAc;
  @ViewChild("employeeAC", { static: true }) employeeAC;
  @ViewChild("ledgerCardAC", { static: true }) ledgerCardAC;
  @ViewChild("narrationArea", { static: true }) narrationArea;
  @ViewChild("openMasterCreationModal", { static: true }) masterCreationModal: ElementRef;
  @ViewChild("closeMasterCreationModal", { static: true }) closeMasterCreationModal: ElementRef;

  // bookingSupplierArr: any;
  bookingClientArr: any = [];
  bookingSupplierArr: any = [];
  bookingGenerlaAccountArr: any = [];

  isSuccess: boolean = false;
  showMessage: boolean = false;
  alertType = ''
  message: string = ``;

  invoiceMode = 'save'
  loggedInuser: any;
  keyword = "name";
  generalAccountArr: any;
  initialValue = '';
  employeeList: any;
  invoice_number: any;
  uuid: unknown;
  invoice_no: any = '';
  invoiceData: any;
  start_date: any;
  end_date: any;
  payablesArr: any = [];
  selectedClient: any;
  selectedMainClient: any;
  selectedSupplier: any;
  showTravellerIndex;
  maximizedSection: boolean;
  @ViewChildren('openModal') openModal: QueryList<ElementRef>;
  @ViewChild("openSuccessModal", { static: true }) openSuccessModal;
  staticInputCount = 10
  totalInputInTravellers = 43
  newInvoice = true;
  invoice_config: any;
  companyAddress: any;
  attachmentFile: File | null = null;

  @ViewChild('confirmDeleteModal', { static: true }) confirmDeleteModal: ElementRef;
  @ViewChild('closeDeleteModal', { static: true }) closeDeleteModal: ElementRef;
  @ViewChild('confirmUpdateModal', { static: true }) confirmUpdateModal: ElementRef
  @ViewChild('closeUpdateModal', { static: true }) closeUpdateModal: ElementRef
  @ViewChild('closeSendEmailModal') closebutton;
  @ViewChild('openEmailModal') openEmailModal;
  @ViewChild('generateEInvoiceModal', { static: true }) generateEInvoiceModal: ElementRef
  queryParams: any;
  invoiceType = 'tour';
  transactionType: any;
  reInitialise: any;
  navigationSubscription: any;
  shortcuts: ShortcutInput[] = [];
  currentOpenedModal = ''
  sendEmailForm: FormGroup
  minDate;
  maxDate;
  copyELementTitle = 'Click to Copy'
  hasError: boolean = false;
  currentMaster: any;
  currentMasterName: any;
  showCreateButton;
  currentFormControlName;
  isTenantIndian: boolean = false;
  currencyCode: string = "INR";
  nativeLanguageCode = '';
  showRefundableCheckbox: boolean = false;
  showPrintOptions: boolean = false
  isTesoyBoult: boolean = false;
  checkEInvoice: boolean = false;
  excelData: any;
  companyDetails: any;
  constructor(
    private formBuilder: FormBuilder,
    private invoiceService: InvoiceServicesService,
    //   public productService : productService,
    public commonService: CommonServiceService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    public tabService: TabService,
    private cookieService: CookiesService,
    public productService: ProductService,
    private downloadService : DownloadService,
  ) {
    this.route.paramMap.subscribe(params => {
      this.invoice_no = params['params'].invoiceNumber
      this.transactionType = params['params'].viewMode
      this.productService.consolidationType = params['params'].consolidationType || 'inv'
      if (this.transactionType && this.transactionType=='save') {
        this.invoiceMode = 'save'
      } else {
        this.invoiceMode = 'view'
      }

      this.sendEmailForm = this.formBuilder.group({
        toEmails: ["", Validators.required],
        bccEmails: [''],
        ccEmails: [''],
        subject: [''],
        body: ['']
      })
    });
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  initialiseInvites() {
    if (this.reInitialise) {
      this.ngOnInit()
    }
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    if (this.data) {
      this.invoice_no = this.data.invoice,
      this.invoiceMode = this.data.mode
    }
    this.productService.initialiseFormData({})

    this.isTenantIndian = this.commonService.checkTenantIndian()
    if (this.isTenantIndian) {
      this.staticInputCount = 10
    } else {
      this.staticInputCount = 12
    }

    this.productService.FlightForm.reset()
    // this.productService.BusForm.reset()
    // this.productService.HotelForm.reset()
    // this.productService.VisaForm.reset()
    // this.productService.invoiceForm.reset()
    // this.productService.RailForm.reset()
    // this.productService.CarForm.reset()

    if ((this.invoiceMode == 'save' || this.invoiceMode == 'view') && this.invoice_no != undefined && this.invoice_no.length > 0) {
      if (this.invoiceMode == 'save') {
        this.getImport(this.invoice_no)
      } else if (this.invoiceMode == 'view') {
        this.getConsolidate(this.invoice_no)
      }
    }

    //   this.getGeneralAccounts()
    //   this.getCompanyData()
    let data = {
      invoice_type: this.invoiceType,
      transaction_method: this.transactionType
    }
    //   this.productService.initialiseFormData(data,{})
    //   this.productService.initialiseTraveller()
    if (this.invoiceMode == 'save') {
      let loggedInUser = JSON.parse(localStorage.okountUserInfo);
      this.loggedInuser = loggedInUser
      // this.productService.invoiceForm.controls.employee_name.setValue(
      //   loggedInUser.name
      // );
      // this.productService.invoiceForm.controls.employee_code.setValue(
      //   loggedInUser.accountcode
      // );
      // this.getAccountingCompany();
    } 

    let empConfiguration = this.commonService.employeeConfiguration;
    if (!empConfiguration || empConfiguration.length == 0) {
      let loggedInUser = JSON.parse(localStorage.okountUserInfo);
      empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
    }

    //   this.productService.invoiceForm.controls.invoice_date.setValidators([Validators.required])
    //       this.productService.invoiceForm.controls.invoice_date.updateValueAndValidity()

    let globalSettingConfig = this.commonService.globalSettingConfig;
    if (!globalSettingConfig || globalSettingConfig.length == 0) {
      globalSettingConfig = await this.commonService.getGlobalSettings();
    }


    if (this.invoiceMode != 'view') {
      // this.invoice_config = this.productService.invoice_config[this.invoiceType] ? this.productService.invoice_config[this.invoiceType] : null
      if (this.invoice_config) {
        //   this.generalSearch(this.invoice_config.general_ledger,true,true, true)
      }
      var url = window.location.href.slice(window.location.href.indexOf('?') + 1);
      //   this.getuuid(url)
      let queryParams = this.route.queryParams['value']
      if (queryParams) {
        this.queryParams = queryParams
      }

    }

    this.showRefundableCheckbox = this.commonService.checkCreditNoteNonRefundableOrNot()

    this.reInitialise = true
    if (this.invoiceMode == 'save') {
      this.setNarration()
    } 

    setTimeout(() => {
      this.commonService.selectedInput.next(4)
    }, 100);
    

  }

  getImport(searchId: any) {
    try {
      let url = `invoice/consolidate_import?import_key=${searchId}`
      this.spinner.show()
      this.invoiceService.getMethod(`${url}`).subscribe(async (res) => {
        let invoiceData = res;
        this.start_date= res['start_date']
        this.end_date= res['end_date']
        // if(invoiceData && invoiceData.accounting_company) {
        //   if(invoiceData['accounting_company'].currency_code) {
        //     this.currencyCode = invoiceData['accounting_company'].currency_code;
        //   }
        //   if(invoiceData['accounting_company'].native_language_code && invoiceData['accounting_company'].currency_code == 'AED') {
        //     this.nativeLanguageCode = invoiceData['accounting_company'].native_language_code;
        //   }
        // }				
        if (invoiceData && invoiceData['client_main_code']) {
          //this.sectorService.affiliate_code = invoiceData.client.account_code ? invoiceData.client.account_code.toUpperCase() : ''
          let url = `client/autocomplete?name_like=${invoiceData['client_main_code'].toUpperCase()}`

          // this.invoiceService.searchAccount(url).subscribe(res => {
          //   this.affiliateArr = res;
          //   this.initialValue = this.affiliateArr[0].name;
          //   console.log('Inititl value : ',this.initialValue)
          //   this.selectEvent(this.affiliateArr[0])
          // });

          await this.productService.bindInvoiceData(invoiceData)
          if (res && res['accounting_company']) {
            if (res['accounting_company'].currency_code) {
              this.currencyCode = res['accounting_company'].currency_code;
            }
            if (res['accounting_company'].native_language_code && res['accounting_company'].currency_code == 'AED') {
              this.nativeLanguageCode = res['accounting_company'].native_language_code;
            }
          }
        } else {
          this.showMessage = true;
          this.isSuccess = false
          this.invoice_number = ''
          this.alertType = 'error-box';
          this.message = `Invoice no ${this.invoice_no} Does not exist `

        }
       
        this.calculateSC()
        this.spinner.hide()
      },
        err => {
          this.spinner.hide()
          console.log(err)
        })
    } catch (error) {
      this.spinner.hide()
      console.log('Error occured in getInvoice due to : ', error)
    }
  }

  getConsolidate(searchId: any) {
    try{
    let url = `invoice/consolidate/${searchId}`
      this.spinner.show()
			this.invoiceService.getMethod(url).subscribe(async (res) => {
				let invoiceData = res['data'][0];
          if(invoiceData && invoiceData.accounting_company) {
              if(invoiceData['accounting_company'].currency_code) {
                this.currencyCode = invoiceData['accounting_company'].currency_code;
              }
              if(invoiceData['accounting_company'].native_language_code && invoiceData['accounting_company'].currency_code == 'AED') {
                this.nativeLanguageCode = invoiceData['accounting_company'].native_language_code;
              }
          }
       
          this.invoiceData = res['data'][0];
          this.start_date= this.invoiceData['start_date']
          this.end_date= this.invoiceData['end_date']
          // this.invoiceType = this.invoiceData.products[0].invoice_type
          let resp = await this.productService.bindInvoiceData(invoiceData)
          this.transactionType = this.invoiceData.transaction_method
          this.productService.consolidationType = invoiceData.consolidation_type
          this.initDateValidation()
          if(this.isTenantIndian) {
            this.staticInputCount = 10
          }else {
            this.staticInputCount = 12
          }
          this.clientSearch(this.invoiceData.account_code,false, false)
          this.generalSearch(this.invoiceData.general_ledger_code,true,false, false)
          this.ledgerSearch(this.invoiceData.subledger_code, false)
          this.spinner.hide()
          this.calculateSC()
			},
				err => {
					this.spinner.hide()
				})
		} catch (error) {
      this.spinner.hide()
		}
  }

  get DynamicFormControls() {
    const FlightList = <FormArray>this.productService.FlightForm.get("FlightList");
    return FlightList;
  }

  async saveProductInvoice() {
    if (this.commonService.gstFiledDate) {
      if (moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.productService.invoiceForm.controls.invoice_date.value).format('YYYY-MM-DD'))) {
        this.showMessage = true
        this.spinner.hide()
        this.alertType = "error-box"
        this.message = 'Not Allowed to create the invoice.'
        return false;
      }
    }
    await this.initDateValidation()
    if (this.invoiceMode == "view") {
      this.updateProduct();
    } else {
      const invalid = [];
      const controls = this.productService.invoiceForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }


      if (invalid.length > 0) {
        window.scroll(0, 0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length - 1}` : ''}`
        this.spinner.hide()
        return false
      }


      this.spinner.show()
      await this.calculateSC()
      let product_json_doc = this.productService.createMsProduct(
        this.productService.invoiceForm.value, this.productService.FlightForm.value
        // , this.productService.HotelForm.value,
        // this.productService.RailForm.value, this.productService.CarForm.value, this.productService.BusForm.value,
        // this.productService.VisaForm.value, this.productService.InsuranceForm.value, this.productService.TourForm.value
      );
      this.productService.postMethod(`invoice/consolidates`, product_json_doc).subscribe(async (data) => {
        // this.productService.postMethod(``,product_json_doc).subscribe(async (data) => {
        window.scroll(0, 0)
        this.showMessage = false;
        this.isSuccess = true
        this.alertType = 'success-box';
        this.message = `Invoice Saved with invoice no. : `
        this.invoice_number = data['invoice_number']
        this.productService.initialiseFormData({})
        let loggedInUser = JSON.parse(localStorage.okountUserInfo);
        this.loggedInuser = loggedInUser
        this.productService.invoiceForm.controls.employee_name.setValue(
          loggedInUser.name
        );
        this.productService.invoiceForm.controls.employee.setValue(
          loggedInUser.accountcode
        );
        this.productService.FlightList.controls = [];
        this.productService.addFlight({})
        // this.productService.HotelList.controls = [];
        // this.productService.addHotel({})
        // this.productService.RailList.controls = [];
        // this.productService.addRail({})
        let obj = {
          invoice_no: 'New Invoice',
        };
        // this.productService.fareList = []
        // this.productService.fareList.push(obj);
        // this.productService.calculateFromApi(0)
         this.uuid = await this.getInvoice()
        this.productService.invoiceForm.controls.invoice_number.setValue(this.uuid)
        this.spinner.hide()
        window.history.replaceState({}, document.title, `/${window.location.hash}/${this.uuid}`);
        this.newInvoice = false;
        this.openSuccessModal.nativeElement.click()
        setTimeout(() => {
          this.commonService.selectedInput.next(1)
        }, 100);
      }, err => {
        window.scroll(0, 0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = err['error']['message']
        this.spinner.hide()
      })

    }
  }

  async initDateValidation() {
    this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
    try {
      let empConfiguration = this.commonService.employeeConfiguration;
      if (!empConfiguration || empConfiguration.length == 0) {
        let loggedInUser = JSON.parse(localStorage.okountUserInfo);
        empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
      }
      let config = this.commonService.getConfigurationByKey(this.invoiceType)
      if (config) {
        let dateConfig = config['value'].filter(data => data.key == `minimum_${this.invoiceMode == 'view' ? 'update' : 'creation'}_date`)
        let daysConfig = config['value'].filter(data => data.key == `minimum_${this.invoiceMode == 'view' ? 'update' : 'creation'}_days`)
        let dateWithMinDate = moment(dateConfig ? dateConfig[0].value : '')
        let dateWithMinDays = dateConfig && daysConfig[0].value ? moment().subtract(Number(daysConfig[0].value), 'days') : dateWithMinDate
        if (dateWithMinDays.isBefore(dateWithMinDate)) {
          this.minDate = dateWithMinDate.format('YYYY-MM-DD')
        } else {
          this.minDate = dateWithMinDays.format('YYYY-MM-DD')
        }
      } else {
        let current_financial_year = JSON.parse(sessionStorage.getItem('current_financial_year'))
        if (!current_financial_year) {
          current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
        }
        this.minDate = current_financial_year.start_date
      }
    } catch (error) {

    }
    this.maxDate = moment().format('YYYY-MM-DD')
    this.productService.invoiceForm.controls.invoice_date.setValidators([Validators.required, this.validateDate()])

    // this.productService.invoiceForm.controls.general_ledger_code.setValidators([Validators.required])
    // this.productService.invoiceForm.controls.general_ledger_code_name.setValidators([Validators.required])
    // this.productService.invoiceForm.controls.invoice_date.updateValueAndValidity()
  }

  validateDate() {
    return (control: AbstractControl) => {
      let valid = true
      if (this) {
        if (moment(control.value).isBefore(this.minDate) || moment(control.value).isAfter(this.maxDate)) {
          if (this.invoiceMode == 'view' && this.invoiceData && this.invoiceData.invoice_date && moment(control.value).isSame(moment(this.invoiceData.invoice_date))) {
            valid = true
          } else {
            valid = false
          }
        }
      }
      return !valid ? { invoice_date: { value: control.value } } : null;
    };
  }


  updateProduct() {
  }

  resetSelection(formControlName) {
    this.productService.invoiceForm.controls[formControlName].setValue('')
    this.productService.invoiceForm.controls[`${formControlName}_name`].setValue('')
    if (formControlName == 'client_code') {
      this.bookingClientArr = []
    }
    if (formControlName == 'supplier_code') {
      this.bookingSupplierArr = []
    }
  }

  focusClientAc() {
    if (this.clientAC && this.clientAC.focus) {
      this.clientAC.focus()
    }
  }

  clientSearch(event, withStatus, getMaster) {
    try {
      let value = event && event.target && event.target.value ? event.target.value : event;

      let searchType = this.productService.invoiceForm.controls.client_account_type.value;
      if (searchType == null) {
        this.productService.invoiceForm.controls.client_account_type.setValue(
          "client"
        );
        searchType = this.productService.invoiceForm.controls.client_account_type.value;
      }
      if (value && value.length > 2 && searchType != null && searchType != "") {
        this.currentMasterName = value;
        this.currentFormControlName = 'client_code'
        this.currentMaster = searchType;

        let status = ''
        if (withStatus) {
          status = '&status=active'
        }
        this.invoiceService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`
          )
          .subscribe(
            (res) => {
              this.bookingClientArr = res;
              if (!this.bookingClientArr || this.bookingClientArr.length == 0) {
                this.showCreateButton = true;
              } else {
                this.showCreateButton = false;
              }
              if (this.bookingClientArr[0] && this.invoiceMode == 'view') {
                this.selectAccount(this.bookingClientArr[0], 'client_code', null, getMaster)
              }

            },
            (error) => { }
          );
      }
    } catch (error) {
      console.error(error)
    }
  }

  selectAccount(event, formControlName, index, getMaster) {
    try {

      let value = event && event.nzValue ? event.nzValue : event;

      if (value && value.account_code) {
       
        this.productService.invoiceForm.controls[formControlName].setValue(
          value && value.account_code ? value.account_code : ''
        );
        this.productService.invoiceForm.controls[`${formControlName}_name`].setValue(
          value && value.name ? value.name : ''
        );
       
        if (formControlName == 'client_main_code') {
          this.selectedClient = value
          if (value && value.account_code && getMaster) {
            this.getMasterConfiguration(value.account_code ? value.account_code : '', null, 'client')
            // this.productService.calculateFromApi(0)
          }
        }
        if (formControlName == 'supplier_code') {
          this.selectedSupplier = value
        }
      } else {
        if (value && value.nzLabel && value.nzLabel.toLowerCase() == 'create new' && this.currentMasterName && this.invoiceMode != 'view') {
          this.openModalMasterCreation(formControlName)
        }
      }
    } catch (error) { }


  }

  getMasterConfiguration(payableAccountCode, travellerIndex, accountFor) {
    this.commonService.getRequest(`${environment.okountUrl}/master_settings?account_code=${payableAccountCode}`).subscribe((res: any) => {
      if (res && res.length > 0) {
        let data = res[0];
        if (accountFor == 'payable') {
          let tds = data && data.json_doc && data.json_doc.value && data.json_doc.value.find(item => item.key == 'tds')
          let gst = data && data.json_doc && data.json_doc.value && data.json_doc.value.find(item => item.key == 'gst')
          

        } else if (accountFor == 'client' && this.isTenantIndian) {
          
        }
      }
    }, err => {
    })
  }




  openModalMasterCreation(formControlName) {
    if (formControlName == 'client_code') {
      let selectedType = this.productService.invoiceForm.controls.client_account_type.value;
      this.productService.invoiceForm.controls.client_code_name.setValue(this.currentMasterName)
      this.currentMaster = selectedType;
      this.currentFormControlName = formControlName
    } else if (formControlName == 'general_ledger_code') {
      this.currentMaster = 'general'
      this.currentFormControlName = formControlName
      this.productService.invoiceForm.controls.general_ledger_code_name.setValue(this.currentMasterName)
    } else if (formControlName == 'subledger_code') {
      let selectedType = this.productService.invoiceForm.controls.subledger_type.value;
      this.currentMaster = selectedType;
      this.currentFormControlName = formControlName;
      this.productService.invoiceForm.controls.subledger_code_name.setValue(this.currentMasterName)
    }

    if (this.currentMaster) {
      this.masterCreationModal.nativeElement.click()
    }
  }

  focusGeneralLedgerAc() {
    if (this.generalLedgerAc && this.generalLedgerAc.focus) {
      this.generalLedgerAc.focus()

    }
  }

  generalSearch(event,isSelect,withStatus, getMaster) {
    try {
      let value = event && event.target && event.target.value ? event.target.value : event;

      if (value && value.length > 2) {
        this.currentMasterName = value;
        this.currentFormControlName = 'general_ledger_code_name'
        this.currentMaster = 'general';
       
        let status = '';
        if(withStatus){
          status = '&status=active'
        }
        this.invoiceService
          .searchAccount(
            `general/autocomplete?name_like=${value.toUpperCase()}${status}`
          )
          .subscribe(
            (res) => {
              this.bookingGenerlaAccountArr = res;
              if(!this.bookingGenerlaAccountArr || this.bookingGenerlaAccountArr.length == 0) {
                  this.showCreateButton = true;
                 } else {
                   this.showCreateButton = false;
                }
              if(this.bookingGenerlaAccountArr[0] && isSelect){
                this.selectAccount(this.bookingGenerlaAccountArr[0],'general_ledger_code', null, getMaster)
              }
            },
            (error) => {}
          );
      }
    } catch (error) {
      console.error(error)
    }
  }

  ledgerSearch(event, getMaster) {
    try {
      let value = event && event.target && event.target.value ? event.target.value : event;

      let searchType = this.productService.invoiceForm.controls
        .subledger_type.value;
      
      if (searchType == null) {
        this.productService.invoiceForm.controls.subledger_type.setValue(
          "client"
        );
        searchType = this.productService.invoiceForm.controls
          .subledger_type.value;
      }
      if (value.length > 2 && searchType != null && searchType != "") {
        this.currentMasterName = value;
        this.currentFormControlName = 'subledger_code'
        this.currentMaster = searchType;

        let status = '';
        if(this.invoiceMode != 'view'){
          status = '&status=active'
        }
        this.invoiceService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`
          )
          .subscribe(
            (res) => {
              this.productService["ledgerList"] = res;

              if(!this.productService["ledgerList"] || this.productService["ledgerList"].length == 0) {
                this.showCreateButton = true;
               } else {
                 this.showCreateButton = false;
                 this.currentMaster = ''
              }

              if (value == "CC001A" || value == "CC001B") {
                this.selectAccount(res[0], "subledger_code", null, getMaster);
                this.productService.invoiceForm.controls.subledger_code_name.setValue(
                  res[0].name
                );
              }
              if(this.invoiceMode == 'view') {
                this.selectAccount(res[0], "subledger_code", null, getMaster);
              }
            },

            
            (error) => {}
          );
      }
    } catch (error) {}
  }

  hideError() {
    this.showMessage = false
  }

  employeeSearch(value) {
    try {
      if (value.length > 2) {
        this.invoiceService
          .searchAccount(
            `employee/autocomplete?name_like=${value.toUpperCase()}`
          )
          .subscribe(
            (res) => {
              this.employeeList = res;
            },
            (error) => {}
          );
      }
    } catch (error) {}
  }

  focusEmployeeAc() {
    if (this.employeeAC != undefined) {
      this.employeeAC.focus();
    }
  }

  onFocused(e){}

 
 
  getInvoice() {
		return new Promise((resolve, reject) => {
			this.invoiceService.getMethod(`invoice/getuuid`).subscribe(
				res => {
					let invoiceNo;
					try {
						invoiceNo = res["uuid"]
						resolve(invoiceNo);
					} catch (error) {
						invoiceNo = res
						resolve(invoiceNo);
					}
				},
				err => {
					reject("");
				}
			);
		});
	}

  viewInvoice(invoice_no){
    this.closeSuccesModal()
    this.router.navigate([`/transactions/consolidate/${this.productService.consolidationType}/view/${invoice_no}`])
  }
  openVoucher(invoice_number) {
		let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/consolidate/${this.productService.consolidationType}/print;searchId=${invoice_number}`;
		window.open(url, "_blank");
	}
  createNew(){
    this.router.navigate([`/display/consolidate-invoice`])
  }
  closeSuccesModal(){
    this.showMessage = false;
    this.isSuccess = false
    this.alertType = 'success-box';
    this.message = ``
    this.newInvoice = true
    this.openSuccessModal.nativeElement.click()
  }


  viewJournal(invoice_no) {
    let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/view-invoice-journal/${invoice_no}`;
		window.open(url, "_blank");
	}

  clickToPrintOptions() {
		this.showPrintOptions = !this.showPrintOptions;
	}

  getExcelData() {
      let reqQuery = {
        start_date: moment(this.invoiceData.start_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
        end_date: moment(this.invoiceData.end_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
        client_code: this.invoiceData.client_code,
        is_consolidated: true,
        service_type: 'all',
        consolidate_ref_no : this.invoiceData.invoice_number
      }
      this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/mis-report`, reqQuery).subscribe(res => {
        this.excelData = res['data'];
        if(res && res['accounting_company']) {
          this.companyDetails = res['accounting_company'];
        }
        let client_details;
        if(res && res['client_details']) {
          client_details = res['client_details'];
        }
        let totalOutstanding = 0;
        if(res && res['outstanding']) {
          totalOutstanding = res['outstanding'];
        }
  
        this.calculateSubTotalForAllReferenceTypes(this.excelData);
        this.spinner.hide()

        this.exportAsXLSX(reqQuery, totalOutstanding, client_details);
      }, err => {
        this.spinner.hide()
        console.error(err)
      })
  }

  exportAsXLSX(reqQuery, totalOutstanding, clientDetails): void {
    this.spinner.show()
    let xlsxData = [];
    let allItems = ['flight_invoice', 'credit_note', 'hotel_invoice','hotel_credit_note', 'visa_invoice','visa_credit_note',
          'insurance_invoice','insurance_credit_note', 'rail_invoice', 'rail_credit_note', 'bus_invoice','bus_credit_note', 
          'car_invoice','car_credit_note', 'misc_invoice', 'misc_credit_note'
    ];
    
    for(let item of allItems) {
      if(this.excelData[item] && this.excelData[item].length>0) {
        
        this.excelData[item] && this.excelData[item].length>0 && this.excelData[item].forEach(data => {
          let obj = this.makeObjectForExcel(data, item, 'normal');
          xlsxData.push(obj);
        });
    
        let subTotal = this.excelData[`${item}_total`];
        if(subTotal) {
          let obj = this.makeObjectForExcel(subTotal, item, 'subTotal');
          xlsxData.push(obj);		
        }
          
        xlsxData.push({});
      }
      
    }
  
    let outstandingObject = {}
    outstandingObject['NET AMOUNT'] = 'Outstanding'
    outstandingObject[''] = totalOutstanding;
    xlsxData.push(outstandingObject);

    let firstLineObject = {}
    firstLineObject['Invoice Date'] = moment(reqQuery.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    firstLineObject['Invoice number'] = moment(reqQuery.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    firstLineObject['Ticket Number'] = this.companyDetails.name;
    xlsxData.push(firstLineObject);

    let secondLineObject = {}
    secondLineObject['Invoice Date'] = clientDetails.account_code;
    xlsxData.push(secondLineObject);
    
    let thirdLineObject = {}
    thirdLineObject['Invoice Date'] = clientDetails.name;
    xlsxData.push(thirdLineObject);

    let fouthLineObject = {}
    fouthLineObject['Invoice Date'] = `${clientDetails.address_1}, ${clientDetails.city_name}, ${clientDetails.state_name}`;
    xlsxData.push(fouthLineObject);

    this.downloadService.exportAsXLSX(`${clientDetails.name} invoices.`, xlsxData);
    this.spinner.hide()

  }
  
  makeObjectForExcel(data, item, objType) {
    let obj = {};
        obj["Invoice Date"] = data.issue_date ? moment(data.issue_date).format('DD/MM/YYYY') : '';
        obj["Invoice number"] = data.reference ? data.reference : ''
        obj['Against Invoice'] =  data.against_inv ? data.against_inv : ''
        obj["Pax Name"] = data.pax_name ? data.pax_name : ''
        obj["Airline Code"] = data.airline_code ? data.airline_code : ''
        obj["Ticket Number"] = data.ticket_no ? data.ticket_no : ''
        obj["Sector"] = data.sector ? data.sector : (objType == 'subTotal') ? item.toUpperCase() : '' 
        obj["Travel Date"] = data.travel_date ?  moment(data.travel_date).format('DD/MM/YYYY') : ''
        obj["E CODE"] =  data.account_code ? data.account_code : ''
        obj["DEPARTMENT"] =  data.department ? data.department : ''
        obj["SUB DEPARTMENT"] =  data.sub_department ? data.sub_department : ''
        obj["CHANNELS"] =  data.channels ? data.channels : ''
        obj["CLIENT CODE"] =  data.client_code ? data.client_code : ''
        obj["LOB"] =  data.lob ? data.lob : ''
        obj["Basic Fare"] = data.base ? Number(data.base) : ''
        obj["YQ Tax"] = data.yq ? Number(data.yq) : ''
        obj["YR Tax"] = data.yr ? Number(data.yr) : ''
        obj["OT TAX"] = data.ot ? Number(data.ot): ''
        obj["K3 TAX"] = data.k3 ? Number(data.k3) : ''
        obj["Service Fee"] = data.client_service_charge ? Number(data.client_service_charge): ''	
        obj["SGST"] = data.client_sgst ? Number(data.client_sgst) : ''			
        obj["CGST"] =  data.client_cgst ? Number(data.client_cgst) : ''	
        obj["IGST"] =  data.client_igst ? Number(data.client_igst) : ''		
        obj["XXL"] =  data.client_xxl ? Number(data.client_xxl) : ''	
        obj["COMMISSION"] = data.commission ? Number(data.commission) : ''			
        obj["TDS AMT"] =  data.c_tds_amount ? Number(data.c_tds_amount) : ''			
        obj["NET AMOUNT"] =  data.client_net_amount ? Number(data.client_net_amount)  : 0
  
        if(objType == "subTotal") {
          obj[''] =  data.client_net_amount ? Number(data.client_net_amount)  : 0
        }
  
  
    return obj;
  }

  calculateSubTotalForAllReferenceTypes(data) {
    for(let key in data) {
      if(data[key]  && data[key].length>0) {
          data[`${key}_total`] = this.calculateTotalReferenceTypeWise(data[key]);
      }
    }

  }
     

  calculateTotalReferenceTypeWise (invoiceArr) {
      const fieldsToCalculate = [
        "base",
        "ssr",
        "yq",
        "yr",
        "k3",
        "ot",
        "oc",
        "client_xxl",
        "client_raf",
        "c_tds_amount",
        "client_net_amount",
        "client_gst_raf",
        "client_igst",
        "client_cgst",
        "client_sgst",
        "client_service_charge",
        ];
    
      const subtotals = {};
    
       invoiceArr.forEach((item) => {
        fieldsToCalculate.forEach((field) => {
            const value = parseFloat(item[field]);
        if (!isNaN(value)) {
          if (!subtotals[field]) {
          subtotals[field] = 0;
          }
          subtotals[field] += value;
        }
        });
      });
      
      return subtotals;
  }
  openViewMode(invoice, invoiceType,transaction_method) {
    if(['hotel','insurance','rail','car','bus','visa'].includes(invoiceType)) invoiceType='ms_invoice'
    console.log("invoice", invoice, "invoiceType",invoiceType,transaction_method  )
		if (invoice != undefined) {
			let url = '#/transactions/'
			let viewMode = 'view'
			let isInvoice = false
			let isMisc = false
			let isMsInvoice = false
			let isCreditNote = false

			if (invoiceType == 'flight_invoice') {
				url = '#/transactions/invoice'
				viewMode = 'view'
				isInvoice = true 
				
			}
			if (invoiceType == 'misc_invoice') {
				url = '#/transactions/ms/misc-invoice'
				viewMode = 'view'
				isMisc = true
			}
	
			if ( invoiceType == 'ms_invoice') {
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
				isMsInvoice = true
			}
			if (transaction_method == 'purchase' && invoiceType == 'ms_invoice') {
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
				isMsInvoice = true
			} else if (invoiceType == 'flight_refund') {
				url = '#/transactions/credit-note'
				viewMode = 'view'
				isCreditNote =  true
			}
			
			let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${url}/${viewMode}/${invoice}`
			if(window.location.protocol != 'file:'){
				    let title =''
				    let referenceSplit = invoice.split('-')
					title += referenceSplit[0]
					title +='...'
					title += referenceSplit[referenceSplit.length-1]
				    if(isInvoice) {
						this.tabService.addTab(
							new Tab(InvoiceComponent, `${title}`, { invoice: invoice ,mode : 'view' },invoice)
							);
					}else if(isMisc) {
						this.tabService.addTab(
							new Tab(MiscInvoiceComponent, `${title}`, { invoice: invoice , mode : 'view' }, invoice)
							);
					} else if(isMsInvoice) {
						this.tabService.addTab(
							new Tab(MsInvoiceComponent, `${title}`, { invoice: invoice , mode : 'view' }, invoice)
							);
					} else if(isCreditNote) {
						this.tabService.addTab(
							new Tab(InvoiceRefundComponent, `${title}`, { creditNoteNumber: invoice, mode : 'view' }, invoice)
						);
					} else {
						window.open(path,'_blank')		
				}
			}else{
				var customeEventHandler = new CustomEvent("handle-window-open", {
					"detail": {
						hashPath : path
					}
				  });
				window.dispatchEvent(customeEventHandler)

			}
		}
	}

  setNarration(){
    let services = this.productService.FlightForm.controls.FlightList.controls
    let travellerList = services.reduce((acc,curr)=>{

      const existingObject  = acc.find(obj => obj.invoice_no.value === curr.controls.invoice_no.value)
      if (!existingObject) {
        acc.push(curr.controls);
    }
    return acc
    },[])
    let travellerListLength = travellerList.length
    var narrLine1 = `Total Transactions - ${travellerListLength}`
    var narrLine2 = `from ${moment(this.start_date,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')} T0 ${moment(this.end_date,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}`
    var narrLine3 = `ISSUED OFFLINE : ${this.productService.invoiceForm.controls.employee_name.value.toUpperCase()}`
    var narrLine4 = ''
    if(this.productService.consolidationType == 'event'){
      narrLine4 = `EVENT BY: ${this.productService.invoiceForm.value.client_code_name}`
    }

    this.productService.invoiceForm.controls.narration_1.setValue(narrLine1)
    this.productService.invoiceForm.controls.narration_2.setValue(narrLine2)
    this.productService.invoiceForm.controls.narration_3.setValue(narrLine3)
    this.productService.invoiceForm.controls.narration_4.setValue(narrLine4)
}
downloadInv() {
  let invArr = this.invoiceData.products.map(({ invoice_no, invoice_type }) => { 
    return{
      "reference":invoice_no,
      "reference_type": invoice_type=='flight_refund'?'credit_note':'invoice',
      "inv_reference_type":invoice_type=='flight_invoice'?'flight_invoice':(['rail','car','bus','hotel','insurance','visa'].includes(invoice_type)?'ms_invoice':invoice_type=='misc_invoice'?'misc-invoice':'')
    }
  })
  this.commonService.postRequest(`${environment.okountUrl}/reports/unpaid-report/client/invoice/download`, {"invArr":invArr,"start_date":this.start_date,'end_date':this.end_date,"client":this.invoiceData.client_main_code,"type":'consolidate'}).subscribe((res:any) =>{
    this.showMessage = true;
    this.alertType = 'success-box'
    this.message = res.message
    
    setTimeout(() => {
      this.showMessage = false;
      let redirectUrl = `${environment.baseAccountUrl}/v2/#/report/schedule-reports/${res.channel_name}`
      window.open(redirectUrl, '_blank');
    }, 1000)

   })
}
getGstRate() {
  let formValue = this.productService.invoiceForm.value
  let c_gst_rate = formValue.c_gst_conf ? formValue.c_gst_conf : 0
  let c_gst_rate_2 = formValue.c_gst_conf_2 ? formValue.c_gst_conf_2 : 0
  let c_gst_rate_3 = formValue.c_gst_conf_3 ? formValue.c_gst_conf_3 : 0
  let c_igst_applicable = this.selectedClient && this.selectedClient.country_id==101  ? this.checkIsIgstApplicable(this.selectedClient.gst_number,this.selectedClient.state_name) : false
  let c_vat_rate = formValue.c_vat_conf ? formValue.c_vat_conf : 0

  return {
    c_gst_rate,
    c_gst_rate_2,
    c_gst_rate_3,
    c_igst_applicable,
    c_vat_rate,
  }
}
checkIsIgstApplicable(otherGstNumber, otherState) {
  let accountingCompanyGstNumber = this.companyAddress && this.companyAddress.gst_number ? this.companyAddress.gst_number : ""
  let accountingCompanyState = this.companyAddress && this.companyAddress.state_name ? this.companyAddress.state_name : ''
  let accountingCompanyStateId = this.companyAddress && this.companyAddress.state_id ? this.companyAddress.state_id : ''
  if (otherGstNumber && otherGstNumber.length > 0 && otherGstNumber != 'null') {
    //if other's company code is of same as of accounting company then non igst applicable 
    if (accountingCompanyGstNumber.substring(0, 2) == otherGstNumber.substring(0, 2)) {
      return false;
    } else {
      return true;
    }
  } else if ((otherState && otherState.length && otherState.length > 0 && otherState != 'null')) {
    //if other's company state is of same sate then non igst applicable
    if (accountingCompanyState.toUpperCase() == otherState.toUpperCase()) {
      return false;
    } else {
      return true;
    }
    //if other code/state both is undefined then consider it non igst applicable 
}else if (typeof otherState=='number' && otherState!=0) {
  //if other's company state is of same sate then non igst applicable
  if (accountingCompanyStateId == otherState) {
      return false;
    } else {
      return true;
    }
    //if other code/state both is undefined then consider it non igst applicable 
  } else {
    return false;
  }
}


calculateSC(){
  const invoiceData = this.productService.invoiceForm.value
  console.log('DEBUG__CALC',invoiceData)
  let gstRate = this.getGstRate()
  let c_igst = 0
  let c_igst_2 = 0
  let c_sgst = 0
  let c_sgst_2 = 0
  let c_cgst = 0
  let c_cgst_2 = 0
  let c_net_gst = 0
  let c_net_gst_2 = 0
  let clientTotalAmount = 0
  let clientTotalAmount_2 = 0
  let clientTotalAmount_3 = 0
  let c_vat_amount = 0
  let c_igst_on_raf = 0
  let c_cgst_on_raf = 0
  let c_sgst_on_raf = 0
  let c_vat_on_raf = 0
  let tcs_amount = 0
  let client_net_total = 0

  let c_sc1_type = invoiceData.c_sc1_type
  let c_sc1_value = invoiceData.c_sc1_value
  let c_sc1_amount = 0
  let client_sub_total = this.productService?.totalFareModel[0]?.client_sub_total
  if(c_sc1_type == 'VL'){
    c_sc1_amount = c_sc1_value
  }else if(c_sc1_type == 'P'){
    c_sc1_amount = client_sub_total*(c_sc1_value/100)
  }
  if(invoiceData.c_gst_type== 'T' || (!this.isTenantIndian && invoiceData.c_vat_type== 'T')){
    clientTotalAmount = client_sub_total
  }
  
  if(invoiceData.c_gst_type_2== 'S'){
    clientTotalAmount_2 = Number(c_sc1_amount) 
  }
  
  if(this.isTenantIndian){
    if(gstRate.c_igst_applicable){
      c_igst = Number((clientTotalAmount * gstRate.c_gst_rate/100).toFixed(2))
      c_igst_2 = Number((clientTotalAmount_2 * gstRate.c_gst_rate_2/100).toFixed(2))
      
    }else{
      let rate = gstRate.c_gst_rate/2
      let rate_2 = gstRate.c_gst_rate_2/2
      c_cgst = Number((clientTotalAmount * rate/100).toFixed(2))
      c_sgst = Number((clientTotalAmount * rate/100).toFixed(2))
      c_cgst_2 = Number((clientTotalAmount_2 * rate_2/100).toFixed(2))
      c_sgst_2 = Number((clientTotalAmount_2 * rate_2/100).toFixed(2))
    }
  }else{
    c_vat_amount = Number((clientTotalAmount * gstRate.c_vat_rate/100).toFixed(2))
  }
  tcs_amount = client_sub_total*(invoiceData.tcs_rate/100)
 
  c_net_gst = c_cgst + c_sgst + c_igst;
  c_net_gst_2 = c_cgst_2 + c_sgst_2 + c_igst_2;
  client_net_total = Number(client_sub_total) + Number(c_sc1_amount) + Number(c_net_gst) + Number(c_net_gst_2) + Number(tcs_amount)

  this.productService.invoiceForm.controls.c_sc1_amount.setValue(Number(c_sc1_amount).toFixed(2))
  this.productService.invoiceForm.controls.c_cgst.setValue(Number(c_cgst).toFixed(2))
  this.productService.invoiceForm.controls.c_sgst.setValue(Number(c_sgst).toFixed(2))
  this.productService.invoiceForm.controls.c_igst.setValue(Number(c_igst).toFixed(2))
  this.productService.invoiceForm.controls.c_net_gst.setValue(Number(c_net_gst).toFixed(2))
  this.productService.invoiceForm.controls.c_cgst_2.setValue(Number(c_cgst_2).toFixed(2))
  this.productService.invoiceForm.controls.c_sgst_2.setValue(Number(c_sgst_2).toFixed(2))
  this.productService.invoiceForm.controls.c_igst_2.setValue(Number(c_igst_2).toFixed(2))
  this.productService.invoiceForm.controls.c_net_gst_2.setValue(Number(c_net_gst_2).toFixed(2))
  this.productService.invoiceForm.controls.c_vat_on_raf.setValue(Number(c_vat_on_raf).toFixed(2))
  this.productService.invoiceForm.controls.c_vat_amount.setValue(Number(c_vat_amount).toFixed(2))
  this.productService.invoiceForm.controls.tcs_amount.setValue(Number(tcs_amount).toFixed(2))
  this.productService.invoiceForm.controls.total.setValue(Number(client_net_total).toFixed(2))
    
  return true
}

}

