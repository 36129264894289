import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CommonServiceService } from '../../shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DownloadService } from 'src/app/shared/download.service';
import * as moment from 'moment-timezone';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../transactions/store/actions';
import { TabService } from 'src/app/shared/services/tab.service';
import { Tab } from 'src/app/shared/model/tab.model';
import { InvoiceComponent } from 'src/app/transactions/invoice/invoice.component';
import { MsInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/ms-invoice/ms-invoice.component';
import { GstInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/gst-invoice/gst-invoice.component';
import { V2CreatePaymentComponent } from 'src/app/transactions/payment/v2-create-payment/v2-create-payment.component';
import { V2CreateReceiptComponent } from 'src/app/transactions/receipt/v2-create-receipt/v2-create-receipt.component';
import { AddEntryJvComponent } from 'src/app/transactions/jv/add-entry-jv/add-entry-jv.component';
import { CreateAdmAcmComponent } from 'src/app/transactions/adm-acm/create-adm-acm/create-adm-acm.component';
import * as FileSaver from 'file-saver';
import { MiscInvoiceComponent } from 'src/app/transactions/misc-invoice/misc-invoice.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-day-book-report',
  templateUrl: './day-book-report.component.html',
  styleUrls: ['./day-book-report.component.scss']
})
export class DayBookReportComponent implements OnInit {
  p = 1;
  q = 0;
  daybookArr = [];
  navigationSubscription
  reInitialise = false;
  innerHeight: string;
	isPrint: any;
  fromRoot = true;
	selector: string = '.main-panel';

  currentDate =moment().format('YYYY-MM-DD')


  isAdvancedSearchOpen: boolean = false;
  parentIndex = 0;
  childIndex = 0;
  @ViewChild('tableHeader', { static: true }) tableHeader: ElementRef
  duration: any;
  queryParams: any;
  loader=false
  listOfColumns =[]
  accountColumn: any;
  fromIndex: number = 0;
  onSearch;
  onSearchFilter = new Subject<string>();
  searchInputValue: any;
  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public commonService: CommonServiceService,
    private router: Router,
    private store: Store<InitialState>,
    private downloadService: DownloadService,
    private tabService: TabService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  ngOnInit() {
    let queryParams = JSON.parse(JSON.stringify(this.route.queryParams['value']))
    
    if (queryParams.start_date != undefined) {
      localStorage.setItem('activeDayBookQueryParams', JSON.stringify(queryParams))
      this.isPrint = queryParams.print

    }
    queryParams['limit'] = 100;
    queryParams['offset'] = this.fromIndex;
    this.queryParams = queryParams;
    this.search(queryParams)
    this.reInitialise = true
    this.makeHeaders()
    this.onSearchFilter.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
          this.filterData(value)
      });
  }

  initialiseColumn(){
    
    // this.accountColumn = {
    //   name: 'Mismatch',
    //   filterMultiple: true,
    //   fixLeft : true,
    //   listOfFilter: [{
    //     text : 'Match',
    //     value : 'MT'
    //   },{
    //     text : 'Airline',
    //     value : 'AL'
    //   },{
    //     text : 'Amount',
    //     value : 'AM'
    //   }],
    //   filterFn: (list: any[], item: any) => list.some(
    //     (name) =>  {
    //       if(name == 'MT' && item?.airline_code_accounts == item?.airline && item?.diff == 0 ){
    //         return true
    //       }
    //       if(name == 'AM' &&  item?.diff != 0 ){
    //         return true
    //       }
    //       if(name == 'AL' &&  item?.airline_code_accounts != item?.airline ){
    //         return true
    //       }
    //       return false
    //   }),
    //   width : "95px"
    
    //   }
  }
  makeHeaders(){
		
    this.listOfColumns = [
      {
        name: 'Mismatch',
        filterMultiple: true,
        fixLeft : true,
        listOfFilter: [{
          text : 'CLIENT',
          value : 'CLIENT'
          },{
              text : 'AIRLINE',
              value : 'AIRLINE'
          },{
              text : 'SUPPLIER',
              value : 'SUPPLIER'
          },
          {
              text : 'GENERAL',
              value : 'GENERAL'
          },
          {
              text : 'BANK',
              value : 'BANK'
          }],
        filterFn: (list: any[], item: any) => list.some(
          (name) =>  {
            if(name == item?.master_name ){
              return true
            }
            return false
        }),
        width : "95px"
      
        },
      {
          name: 'DATE',
      
        },
      {
          name: 'ACCOUNT',
      
        },
      {
          name: 'NARRATION',
      },
      {
          name: 'REFERENCE',
        },
      {
      name: 'CHEQUE NO',
      },
      {
          name: 'DEBIT',
          width : "100px",
          fixRight : true
      },
      {
          name: 'CREDIT',
          width : "100px",
          fixRight : true
      },
    ]

    this.accountColumn = {
      name: 'Mismatch',
      filterMultiple: true,
      fixLeft : true,
      listOfFilter: [{
        text : 'CLIENT',
        value : 'CLIENT'
    },{
        text : 'AIRLINE',
        value : 'AIRLINE'
    },{
        text : 'SUPPLIER',
        value : 'SUPPLIER'
    },
    {
        text : 'GENERAL',
        value : 'GENERAL'
    },
    {
        text : 'BANK',
        value : 'BANK'
    }],
      filterFn: (list: any[], item: any) => list.some(
        (name) =>  {
          if(name ==  item?.master_name  ){
            return true
          }
          return false
      }),
      width : "95px"
    
      }
		
	}

  listOfAcTypeFilterFn(value,fn) {
    console.log(value)
  }
  initialiseInvites() {
    if (this.reInitialise) {
      this.ngOnInit()
    }
  }
  ngOnDestroy() {

    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.daybookArr != undefined) {
      this.setHeight(event.target.innerHeight)
    }
  }

  getData(values){
    return new Promise(async (resolve, reject) => {
        this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/reports/daybook`, values).subscribe(res => {
            resolve(res)
        },err=> {
            resolve(null)
        })
    })
}
  openAdvanceSearchModal(){
    this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'daybook'}));	
}
async filterData(value) {
  this.searchInputValue = value
  if (value.length >= 0) {
      this.queryParams['freetext'] = value
      let queryParams = JSON.parse(JSON.stringify(this.queryParams))
      this.queryParams = queryParams
      queryParams['limit'] = 100;
      queryParams['offset'] = 0;
      this.loader = true
      let resp = await this.getData(queryParams)
      this.loader = false
      if(resp){
          this.daybookArr = resp['data']
      }
     
  this.setHeight(window.innerHeight)
  } else {
      delete this.queryParams['freetext']
      this.search(this.queryParams)
      this.setHeight(window.innerHeight)
  }
}
  async search(values) {
    // this.spinner.show()
    this.loader = true
    let resp = await this.getData(values)
    this.loader = false
    if(resp){
        this.duration = resp['duration']
        this.daybookArr = resp['data']
        this.focusOnTableHeader()
       
        this.setHeight(window.innerHeight)
    }
}

  setHeight(height) {
    if (this.daybookArr != undefined) {
      if (height == undefined) {
          height = window.innerHeight
      }
      if (this.daybookArr.length <= 9) {
          this.innerHeight = 'auto';
      }
      else {
          this.innerHeight = height - 147 + 'px';
      }
  }
  }

  async exportAsXLSX(fromDate, toDate) {
    this.spinner.show()

    let xlsxData = [];
    let queryParams = JSON.parse(JSON.stringify(this.route.queryParams['value']))
    this.loader = true
    let downloadData = await this.getData(queryParams)
    this.loader = false;

    downloadData['data'].forEach(records => {
      let narration = records.naration && records.naration.split("\n")||""
      let pnrLine = narration && narration[2] && narration[2].split("|")[0]||""
      //console.log(pnrLine)
      let obj = {};
      obj["DATE"] = `${records.timestamph}`;
      obj["ACCOUNT NAME"] = records.name;
      obj["NARRATION"] = records.naration;
      obj["REFERENCE"] = records.transaction_ref_no;
      obj["CHEQUE NO"] = records.total_credit;
      obj['DEBIT'] = records.typhe == 'debit'?Number(Number(records.amount).toFixed(2)):0;
      obj['CREDIT'] = records.typhe == 'credit'?Number(Number(records.amount).toFixed(2)):0;
      obj["PNR"] = pnrLine && pnrLine.includes("PNR")?pnrLine.substring(6):""
      
      xlsxData.push(obj);
    });

    
    this.downloadService.exportAsXLSX(`DAYBOOK REPORT ${fromDate} - ${toDate}`, xlsxData);
    this.spinner.hide()
  }


	downloadPdf(start_date, end_date) {
    let queryParams = JSON.parse(JSON.stringify(this.queryParams))
    delete queryParams['limit']
    delete queryParams['offset']
		this.commonService.downloadPDF(`${environment.okountUrl}/reports/daybook/download`, queryParams).subscribe((fileBlob:any) =>{
			let fileName = `DAYBOOK REPORT ${start_date} - ${end_date}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}


  callPdfGeneratorApi(fromDate, toDate) {
    window.scrollTo(0, 0)
    this.spinner.show()
		let url = 'print-pdf/pdf-day-book'
		let queryString = location.hash.split('?')[1]
		if (queryString != undefined) {
			url = `${url}?${queryString}`
		}
    this.downloadService.callPdfGeneratorApi(`DAYBOOK REPORT ${fromDate} - ${toDate}.pdf`, url,{})
    this.spinner.hide()

  }

  openViewMode(reference, referenceType) {
    console.log("HEllo",reference, referenceType);
    
			let isInvoice = false
			let isMsInvoice = false
			let isGstInvoice = false
			let isPayment = false
			let isReceipt = false
      let isAdmAcm = false
			let isJv = false
			let isMisc = false

    if (reference != undefined) {
      let url = '#/transactions/invoice'
      let viewMode = 'view'
      if (referenceType == 'invoice') {
        url = '#/transactions/invoice'
        viewMode = 'view'
        if(!reference.includes('MIN') && !reference.includes('MZC') && !reference.includes('GSP') && !reference.includes('GSS')) {
					isInvoice = true 
				} 
      }
      if (referenceType == 'invoice' && (reference.includes('MIN') || reference.includes('MZC'))) {
        url = '#/transactions/ms/ms-invoice'
        viewMode = 'view'
        if(!reference.includes('GSP') && !reference.includes('GSS')) {
					isMsInvoice = true
				} 
      }
      if (referenceType == 'invoice' && (reference.includes('GSP') || reference.includes('GSS'))) {
				url = '#/transactions/ms/gst-invoice'
				viewMode = 'view'
				if(!reference.includes('MIN') && !reference.includes('MZC')) {
					isGstInvoice = true
				} 
			}
      if (referenceType == 'credit_note') {
        url = '#/transactions/credit-note'
        viewMode = 'view'
      }
      if (referenceType == 'payment') {
        url = '#/transactions/payment'
        viewMode = 'update'
        isPayment = true
      }
      if (referenceType == 'receipt') {
        url = '#/transactions/receipt'
        viewMode = 'update'
        isReceipt = true
      }
      if (referenceType == 'jv') {
				url = '#/transactions/jv'
				viewMode = 'update'
				isJv = true
			}
			if (referenceType == 'adm' || referenceType == 'acm') {
				url = '#/transactions/adm-acm'
				viewMode = 'view'
				isAdmAcm = true
			}
      if (referenceType == 'misc-invoice') {
				url = '#/transactions/ms/misc-invoice'
				viewMode = 'view'
				isMisc = true
			}
      
      let path = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${url}/${viewMode}/${reference}`
			if(window.location.protocol != 'file:'){

          let title =''
          let referenceSplit = reference.split('-')
          title += referenceSplit[0]
          title +='...'
          title += referenceSplit[referenceSplit.length-1]
            if(isInvoice) {
            this.tabService.addTab(
              new Tab(InvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
              );
          }else if(isMisc) {
            this.tabService.addTab(
              new Tab(MiscInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
              );
          }  else if(isMsInvoice) {
            this.tabService.addTab(
              new Tab(MsInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
              );
          } else if(isGstInvoice) {
            this.tabService.addTab(
              new Tab(GstInvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
              );
          } else if(isPayment) {
            this.tabService.addTab(
              new Tab(V2CreatePaymentComponent, `${title}`, { paymentRef: reference ,mode : 'view' }, reference)
              );
            }
            else if(isReceipt) {
              this.tabService.addTab(
              new Tab(V2CreateReceiptComponent, `${title}`, { receiptRef: reference ,mode : 'view' }, reference)
              );
          } else if(isAdmAcm) {
						this.tabService.addTab(
							new Tab(CreateAdmAcmComponent, `${title}`, { adm_acm_number: reference, mode : 'view' }, reference)
						);
					} else if(isJv) {
						this.tabService.addTab(
							new Tab(AddEntryJvComponent, `${title}`, { jv_code: reference }, reference)
							);
					} else {
            window.open(path,'_blank')		
          }
        }else{
          var customeEventHandler = new CustomEvent("handle-window-open", {
					"detail": {
						hashPath : path
					}
				  });
				window.dispatchEvent(customeEventHandler)
      }
    }
  }


  // keyboard integration
  // Add #tableHeader on table header

  focusOnTableHeader(): void {
    if (this.tableHeader != undefined) {
      this.tableHeader.nativeElement.focus()
    }
  }

  onKeyDown(e: any) {
    console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
      if (e.keyCode == 40) {
        e.preventDefault();
        this.moveToNext()
      } else if (e.keyCode === 38) {
        e.preventDefault();
        this.moveToPrev()
      } else if (e.keyCode === 37) {
        e.preventDefault();
        this.moveToLeft()
      } else if (e.keyCode === 39) {
        e.preventDefault();
        this.moveToRight()
      }
    }
  }
  moveToNext() {
    if (document.getElementById(`rowIndex_${this.parentIndex + 1}`) != null) {
      document.getElementById(`rowIndex_${this.parentIndex + 1}`).focus()
      this.parentIndex = this.parentIndex + 1
    }
  }
  moveToPrev() {

    if (document.getElementById(`rowIndex_${this.parentIndex - 1}`) != null) {
      document.getElementById(`rowIndex_${this.parentIndex - 1}`).focus()
      this.parentIndex = this.parentIndex - 1
    }
  }

  async moveToRight() {
    if (this.p == 0) {
      this.p = 1
    }
    await this.setHeight(undefined)
    this.moveToParentIndex(this.parentIndex)
  }
  async moveToLeft() {
    if (this.p >= 1) {
      await this.setHeight(undefined)
      this.moveToParentIndex(this.parentIndex)
    }
  }

  moveToIndex(index) {
    if (document.getElementById(`rowIndex_${index+1}`) != null) {
      document.getElementById(`rowIndex_${index+1}`).focus()
      this.parentIndex = index

    }
  }

  moveToParentIndex(parentIndex) {
    if (document.getElementById(`rowIndex_${parentIndex}`) != null) {
      document.getElementById(`rowIndex_${parentIndex}`).focus()
      this.parentIndex = parentIndex
      this.childIndex = 0
    }
  }


  // INFINITE SCROLL
async onScrollDown(event) {
  console.log('scroled')
  this.fromIndex +=100
  // let toIndex = Number(this.toIndex) + 10
  let queryParams = JSON.parse(JSON.stringify(this.queryParams))
  queryParams['limit'] = 100;
  queryParams['offset'] = this.fromIndex;
  let resp = await this.getData(queryParams)
  if(resp){
      this.daybookArr = this.daybookArr.concat(resp['data'])
      this.setHeight(window.innerHeight)
  }
  // for (let i = this.fromIndex; i <= toIndex; i++) {
  //     const element = this.viewAllClient[i];
  //     if (element != undefined) {
  //         this.toIndex = i
  //         this.viewClient.push(element)
  //     }
  // }
  // this.toIndex = toIndex
}
}
