<div class="footer-bar-two">
	<div class="item-name" *hasAccess="{'key' : 'F0058'}">
    <a [routerLink]="['/report/balance-sheet']" routerLinkActive="router-link-active" > Balance Sheet [Alt + B]</a>
	</div>
	<div class="item-name" *hasAccess="{'key' : 'F0057'}">
    <a [routerLink]="['/report/profit-loss']" routerLinkActive="router-link-active" > Profit & Loss[Alt + P]</a>
   </div>
   <div class="item-name" *hasAccess="{'key' : 'F0056'}">
    <a [routerLink]="['/report/trial-balance']" routerLinkActive="router-link-active" > Trial Balance [Alt + T]</a>
   </div>
  </div>
 
<router-outlet></router-outlet>