<form [formGroup]="invoiceFilterForm">

  <div class="flexbox aff-info">
    <div class="flexbox aff-info-inner">

      <div class="flexbox">

        <div class="input-fiel">
          <input class="input-ui" searchtabindex="1" value="Invoice Date" readonly />
        </div>

        <div class="input-fiel">
          <input class="input-ui" searchtabindex="2" formControlName="inv_start_date" id="date" type="date"
              min="{{minDate}}" max="{{maxDate}}"
              [ngClass]="{'error-box': !invoiceFilterForm.controls.inv_start_date.valid}"
              style="width: 140px;" />
          <label for="date">From Date</label>

        </div>

        <div class="input-fiel">
          <input class="input-ui" searchtabindex="3" formControlName="inv_end_date" id="date" type="date"
              min="{{minDate}}" max="{{maxDate}}"
              [ngClass]="{'error-box': !invoiceFilterForm.controls.inv_end_date.valid}"
              style="width: 140px;" />
          <label for="date">To Date</label>

        </div>


      </div>

      <div class="flexbox mt-3">

        <div class="input-fiel">
          <input class="input-ui" searchtabindex="4" value="Travelling Date" readonly />
        </div>

        <div class="input-fiel">
          <input class="input-ui" searchtabindex="5" formControlName="travel_start_date" id="date" type="date"
              style="width: 140px;" />
          <label for="date">From Date</label>

        </div>

        <div class="input-fiel">
          <input class="input-ui" searchtabindex="6" formControlName="travel_end_date" id="date" type="date"
              style="width: 140px;" />
          <label for="date">To Date</label>

        </div>
      </div>

      <div class="flexbox mt-3">
        <div class="select-field text-input-fiel">
          <select class="input-field" searchtabindex="7" formControlName="account_type">
            <option value="client">Client</option>
            <option value="airline">Airline</option>
            <option value="supplier">Supplier</option>
            <!-- <option value="employee">Employee</option>
                    <option value="general">General</option> -->
          </select>
          <label for="subLedger">Account Type</label>

        </div>
        <div class="input-fiel form-group ng-field" searchtabindex="8" (focus)="focusAccountAc()">
          <label class="ngauto-label" for="affname">Account</label>
          <div class="ng-autocomplete">
            <ng-autocomplete [data]="clientArr" #accountAC (inputCleared)="clearData('client')"
              [searchKeyword]="keyword" (inputChanged)='getClient($event,false)' historyIdentifier="clientArr"
              [itemTemplate]="itemTemplate" formControlName="account_name"
              (selected)="selectAccount($event,'account_code')" [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                            ({{item.account_code}})</span><br />
                        <span class="colr-2">{{item.primary_email}}</span><br />'>
              </a>
            </ng-template>
            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="flexBox d-flex mt-1 ml-2 mr-2">

        <div class="input-fiel">
          <label class="radio-label" for="invoiceCheckBox">Invoice</label>
          <input class="radio-ui" id="invoiceCheckBox" type="checkbox" name="include_invoice" value="subgroup"
            searchtabindex="9" formControlName="include_invoice" />
        </div>
        <div class="input-fiel">
          <label class="radio-label" for="creditNoteCheckBox">Credit Note</label>
          <input class="radio-ui" id="creditNoteCheckBox" type="checkbox" name="include_credit_note" value="subgroup"
            searchtabindex="10" formControlName="include_credit_note" />
        </div>
      </div>

      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
            searchtabindex="12" #searchButton (keyup.enter)="clickSearchButton()"
            [disabled]="invoiceFilterForm.invalid"
            (click)="search(invoiceFilterForm.value)"> View</button>
          <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
        </div>
      </div>

    </div>

  </div>
  <!-- </div> -->
</form>