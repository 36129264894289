import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

import * as moment from 'moment'
import { ReceiptService } from '../services/receipt.service';
import { CommonServiceService } from 'src/app/shared/common-service.service';
@Component({
	selector: 'app-create-receipt',
	templateUrl: './create-receipt.component.html',
	styleUrls: ['./create-receipt.component.scss']
})
export class CreateReceiptComponent implements OnInit {
	submitResponse: any;
	generalMasterList: any;
	showMessage: boolean = false;
	alertType = '';
	message: string = ``;
	@ViewChild('saveButton',{static:true}) saveButton : ElementRef;
	constructor(
		private spinner: NgxSpinnerService,
		public receiptService: ReceiptService,
		public commonService: CommonServiceService
	) { }

	ngOnInit() {
		/** spinner starts on init */
		this.spinner.show();

		setTimeout(() => {
			/** spinner ends after 5 seconds */
			this.spinner.hide();
		}, 1000);
		this.getGeneralMaster('general/bank')
		this.receiptService.receiptForm.reset()
		this.receiptService.receiptEntries = []
		this.receiptService.receiptForm.controls.accountCodeTo.setValue('')
		this.receiptService.receiptForm.controls.accountCodeToType.setValue('general')
		this.receiptService.receiptForm.controls.receipt_id.setValue('')
		this.receiptService.receiptForm.controls.receiptDate.setValue(moment().format('YYYY-MM-DD'))
	}

	addRecord(status, formValue) {
		this.hideError()
		this.spinner.show();
		let userInfo = JSON.parse(localStorage.okountUserInfo)
		this.receiptService.isEdit = false
		let addreceiptObj = {
			"account_code_to": formValue.accountCodeTo, //FIX ME
			"account_code_to_type": formValue.accountCodeToType, //FIX ME
			"receipt_timestamp": moment(formValue.receiptDate).format('YYYY-MM-DD'),
			"last_modified_by": userInfo.id,
			"last_modified_by_name": userInfo.name
		}
		if (formValue.receipt_id == "") {
			this.receiptService.postMethod(`receiptv2`, addreceiptObj).subscribe((res: any) => {
				let response = res
				this.receiptService.showAddreceiptScreen = status
				this.receiptService.receiptForm.controls.receipt_id.setValue(response.receipt_id)
				this.receiptService.receiptEntryForm.controls.account_code_to.setValue(formValue.accountCodeTo)
				this.receiptService.receiptEntryForm.controls.account_code_to_type.setValue(formValue.accountCodeToType)
				this.spinner.hide();
			},
				err => {
					this.spinner.hide();
				})
		} else {
			this.receiptService.showAddreceiptScreen = status
			this.spinner.hide();
		}
	}
	editEntry(receipt) {
		debugger
		this.receiptService.showAddreceiptScreen = true
		this.receiptService.isEdit = true
		localStorage.setItem('selected_receipt_entry', receipt.receipt_entry_id)
		this.receiptService.receiptEntryForm.controls.account_code_from.setValue(receipt.account_code_from)
		this.receiptService.receiptEntryForm.controls.account_code_to.setValue(receipt.account_code_to)
		this.receiptService.receiptEntryForm.controls.cheque_no.setValue(receipt.cheque_no)
		if (receipt.cheque_date != null && receipt.cheque_date != '') {
			this.receiptService.receiptEntryForm.controls.cheque_date.setValue(moment(receipt.cheque_date).format('YYYY-MM-DD'))
		}
		this.receiptService.receiptEntryForm.controls.cheque_bank_name.setValue(receipt.cheque_bank_name)
		this.receiptService.receiptEntryForm.controls.amount.setValue(Number(receipt.amount))
		this.receiptService.receiptEntryForm.controls.narration_1.setValue(receipt.narration_1)
		this.receiptService.receiptEntryForm.controls.narration_2.setValue(receipt.narration_2)
		this.receiptService.receiptEntryForm.controls.narration_3.setValue(receipt.narration_3)
		this.receiptService.receiptEntryForm.controls.narration_4.setValue(receipt.narration_4)
		this.receiptService.receiptEntryForm.controls.narration_5.setValue(receipt.narration_5)
		this.receiptService.receiptEntryForm.controls.remark_1.setValue(receipt.remark_1)
		this.receiptService.receiptEntryForm.controls.remark_2.setValue(receipt.remark_2)
		this.receiptService.receiptEntryForm.controls.remark_3.setValue(receipt.remark_3)
		this.receiptService.receiptEntryForm.controls.remark_4.setValue(receipt.remark_4)
		this.receiptService.receiptEntryForm.controls.remark_5.setValue(receipt.remark_4)
		this.receiptService.receiptEntryForm.controls.sub_ledger_code_1_type.setValue(receipt.sub_ledger_code_1_type)
		this.receiptService.receiptEntryForm.controls.sub_ledger_code_1.setValue(receipt.sub_ledger_code_1)
		this.receiptService.receiptEntryForm.controls.sub_ledger_code_1_name.setValue(receipt.sub_ledger_code_1_value ? receipt.sub_ledger_code_1_value : '')

		this.receiptService.receiptEntryForm.controls.account_code_from_name.setValue(receipt.account_code_from_value)
		this.receiptService.receiptEntryForm.controls.account_code_to_name.setValue(receipt.account_code_to_value)
		debugger
		this.receiptService.receiptEntryForm.controls.other_transaction_type.setValue(receipt.other_transaction_type)
		this.receiptService.receiptEntryForm.controls.other_account_name.setValue(receipt.other_account_value ? receipt.other_account_value : "")
		this.receiptService.receiptEntryForm.controls.other_account.setValue(receipt.other_account)
		this.receiptService.receiptEntryForm.controls.other_amount.setValue(receipt.other_transaction_amount)
	}

	submit() {
		this.hideError()
		this.spinner.show();
		let reqBody = {
			"receipt_id": this.receiptService.receiptForm.value.receipt_id
		}
		if (reqBody.receipt_id != undefined && reqBody.receipt_id != "" && reqBody.receipt_id != null ) {
			this.receiptService.postMethod(`receiptv2/submit`, reqBody).subscribe(async (res: any) => {
				this.submitResponse = res
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = `Receipt created with `
				// alert(JSON.stringify(this.submitResponse))
				try {
					debugger
					for (const key in this.receiptService.receiptEntries) {
						if (this.receiptService.receiptEntries.hasOwnProperty(key)) {
							const element = this.receiptService.receiptEntries[key];
							element['invoice_no'] = this.submitResponse[0].receipt_code
							element['account_code_to'] = this.receiptService.receiptForm.value.accountCodeTo
							element['created_date'] = this.submitResponse[0].receipt_timestamp
						}
					}
					// await this.doTransactionEntry(this.receiptService.receiptEntries)
				} catch (error) {
					console.log(error)
				}
				console.log('receipt Submit response : ', this.submitResponse)
				this.receiptService.receiptEntries = []
				this.receiptService.receiptForm.reset()
				this.receiptService.receiptForm.controls.accountCodeTo.setValue('G00002')
				this.receiptService.receiptForm.controls.receipt_id.setValue('')
				this.receiptService.receiptForm.controls.receiptDate.setValue('')
				this.spinner.hide();
			},
				err => {
					this.showMessage = true
					this.alertType = 'error-box';
					this.message = `Failed to create receipt entry.`
					this.spinner.hide();
				})
		} else {
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = `Add Atleat one entry before saving.`
			this.spinner.hide();
		}
	}
	getGeneralMaster(url) {
		this.receiptService.getMethod(url).subscribe(res => {
			this.generalMasterList = res
		}, err => {

		})
	}

	reset() {
		let receipt_id = this.receiptService.receiptForm.value.receipt_id
		if (receipt_id != '') {
			this.receiptService.deleteMethod(`receiptv2?receipt_id=${receipt_id}`).subscribe((res: any) => {
				this.receiptService.receiptForm.reset()
				this.receiptService.receiptEntries = []
				this.receiptService.receiptForm.controls.accountCodeTo.setValue('G00002')
				this.receiptService.receiptForm.controls.receipt_id.setValue('')
				this.receiptService.receiptForm.controls.receiptDate.setValue('')
				this.spinner.hide();
			},
				err => {
					this.spinner.hide();
				})
		} else {
			this.receiptService.receiptForm.reset()
			this.receiptService.receiptEntries = []
			this.receiptService.receiptForm.controls.accountCodeTo.setValue('G00002')
			this.receiptService.receiptForm.controls.receipt_id.setValue('')
			this.receiptService.receiptForm.controls.receiptDate.setValue('')
		}
	}

	// doTransactionEntry(entries) {
	// 	this.receiptService.postMethod(`v3/receipt`, entries).subscribe(res => {

	// 	}, err => {

	// 	})
	// }

	hideError() {
		this.showMessage = false
	}

	 exitEntry(e){
		this.receiptService.showAddreceiptScreen = false
		try {
			this.commonService.selectedInput.next(4)
			this.saveButton.nativeElement.focus()
		} catch (error) {
			this.commonService.selectedInput.next(4)
		}
		
	}
}
