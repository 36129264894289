<div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
  <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
    <div class="d-flex flex-y-center">
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
          alertType=='success-box' ? 'green-sucess.svg' : 
          alertType=='warning-box' ? 'red-warning.svg' : 
          null }}"
        width="20px"
        height="20px"
        alt="alert-logo"
        class="mr-20"
      />
      <div class="ml-10">
        <h4 class="m-0 text-size-18 text-bold"
        [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
          {{alertType === "error-box"
          ? "Error"
          : alertType === "warning-box"
          ? "Warning"
          : alertType === "success-box"
          ? "Success"
          : null}}
        </h4>
        <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
         {{message}}
        </small>
      </div>
    </div>
    <img
      src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
      alt="close-btn"
      (click)="hideError()"
      className=" close-btn"
    />
  </div>
</div>

<div class="ledger-container ">
  <!-- <div class="tab-nav d-flex align-item-center justify-content-between">
        <ul class="list-inline list-unstyled p-0 m-0">
            <li class="active page-title   text-uppercase"> Flown Report</li>
        </ul>
        <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
            <li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition"> Dashboard
            </li>
            <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
            <li class="text-grey text-capitalize cursor text-primary transition">View Flown Report</li>
        </ul>
    </div> -->
  <div class="row tab-nav pl-0 pr-0 justify-content-between">
    <!-- <div class="col-sm-4 pl-0">
                      <ul class="list-inline list-unstyled p-0 m-0">
                          <li class="active page-title text-uppercase cursor"> View Client</li>
                      </ul>
                  </div> -->
    <div class="col-sm-4">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
        <li [routerLink]="['/']" class="text-grey text-capitalize cursor hover-text-primary transition"
          [ngClass]="{ 'd-none': isPrint == 'true' }">
          Analysis
        </li>
        <li>
          <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""
            [ngClass]="{ 'd-none': isPrint == 'true' }" />
        </li>
        <li class="text-grey text-capitalize cursor text-primary transition"
          [ngClass]="{ 'd-none': isPrint == 'false' }" [ngClass]="{ 'd-none': isPrint == 'true' }">
          vertical PNL
        </li>
        <!-- <li class="text-primary line-clamp line-clamp-1">
              <ng-container
                *ngFor="let data of leaderData['all_clients_records']"
              >
                <span class="text-bold">
                  {{ data["display_name"] }}
                </span>
              </ng-container>
            </li> -->
      </ul>
    </div>
    <div class="col-sm-4 text-center">
      <span class="rec-date d-block text-center">
        <div class="noWrap justify-content-around">
          <ng-container>
            <div class="cname text-bold">
              <span class="text-bold">From </span>
              <h5 style="margin-right: 5px" class="mb-0 d-inline">
                <span class="date text-bold text-orange">{{duration && duration.from}}
                </span>
                <span class="date text-bold">
                  To
                </span>
                <span class="date text-bold text-orange">
                  {{duration && duration.to}}
                </span>
              </h5>

              <button [ngClass]="{ 'd-none': isPrint == 'true' }" title="Custom Search"
                class="btn save-as text-bold bg-whiteSmoke" type="button" (click)="openAdvanceSearchModal()">
                <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt=""
                  width="15px" />
              </button>
            </div>
          </ng-container>
        </div>
      </span>
    </div>
    <div class="col-sm-4 d-flex align-item-center justify-content-end">
      <div class="page-search-box input-group d-flex align-item-center" [ngClass]="{ 'd-none': isPrint == 'true' }"
        style="height: fit-content; text-align: left">
        <input type="text" class="form-control" [(ngModel)]="onSearch" oninput="this.value = this.value.toUpperCase()"
          (ngModelChange)="this.onSearchFilter.next($event)" placeholder="search" style=" text-align: left" />
      </div>

      <div class="dropdown-btn position-relative" [ngClass]="{ 'd-none': isPrint == 'true' }">
        <button title="More..." class="btn-style mt-0 more-btn">
          <span class="dot"></span><span class="dot"></span><span class="dot"></span>
        </button>
        <ul class="list-unstyled pl-0 dropdown-box">

          <li (click)="exportAsXLSX()">
            <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg" alt="" />
            <span class="ml-2">Excel</span>
          </li>

        </ul>
      </div>
    </div>
  </div>


  <div class="table-container ">
    <div class="table flown-tbl" style="margin-top: -5px;">
      <nz-table class="table  striped" style="table-layout: auto!important;" #pnlData tabIndex="0"
        [nzTableLayout]="'auto'" [nzData]="invoiceArr" [nzLoading]="loader" [nzFrontPagination]="false"
        [nzShowPagination]="false" [nzScroll]="{ x: '1000px', y: 'calc(100vh - 160px)' }">
        <thead>
          <tr>
            <th *ngFor="let column of listOfColumns" [nzSortOrder]="column.sortOrder" [nzSortFn]="column.sortFn"
              [nzSortDirections]="column.sortDirections" [nzFilterMultiple]="column.filterMultiple"
              [nzFilters]="column.listOfFilter" [nzFilterFn]="column.filterFn" [nzWidth]="column.width"
              [nzLeft]="column.fixLeft" [nzRight]="column.fixRight" [ngClass]="column.class"
              [ngStyle]="{'text-align': column.textAlign, 'width': column.width}"> {{ column.name }}</th>
          </tr>
        </thead>
        <tbody [ngStyle]="{height: innerHeight}" class="scroll-body main-panel">
          <th>INCOME</th>
          <ng-container *ngFor="let details of pnlData['data']['incomeData'] let i = index">
            <tr class="field-table-input" [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}">
              <td class="text-grey text-left" title="{{details?.name}}" style="white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis; text-align: left;vertical-align: middle;">
                {{details?.name}}
              </td>
              <ng-container *ngFor="let item of keys">
                <td style="text-align: right!important;" style="vertical-align: middle;text-align: right;"
                  [ngClass]="item.class">
                  {{details[item] ? (details[item] | number: "1.2-2") : "0.00" }}
                </td>
              </ng-container>
            </tr>
          </ng-container>
          <th>EXPENSE</th>
          <ng-container *ngFor="let details of pnlData['data']['expenseData'] let i = index">
            <tr class="field-table-input" [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}">
              <td class="text-grey text-left" title="{{details?.name}}" style="white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis; text-align: left;vertical-align: middle;">
                {{details?.name}}
              </td>
              <ng-container *ngFor="let item of keys">
                <td style="text-align: right!important;" style="vertical-align: middle;text-align: right;"
                  [ngClass]="item.class">
                  {{details[item] ? (details[item] | number: "1.2-2") : "0.00" }}
                </td>
              </ng-container>
            </tr>
          </ng-container>
          <th>SALES</th>
          <ng-container *ngFor="let details of pnlData['data']['salesData'] let i = index">
            <tr class="field-table-input" [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}">
              <td class="text-grey text-left" title="{{details?.name}}" style="white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis; text-align: left;vertical-align: middle;">
                {{details?.name}}
              </td>
              <ng-container *ngFor="let item of keys">
                <td style="text-align: right!important;" style="vertical-align: middle;text-align: right;"
                  [ngClass]="item.class">
                  {{details[item] ? (details[item] | number: "1.2-2") : "0.00" }}
                </td>
              </ng-container>
            </tr>
          </ng-container>
          <th>PURCHASE</th>
          <ng-container *ngFor="let details of pnlData['data']['purchaseData'] let i = index">
            <tr class="field-table-input" [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}">
              <td class="text-grey text-left" title="{{details?.name}}" style="white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis; text-align: left;vertical-align: middle;">
                {{details?.name}}
              </td>
              <ng-container *ngFor="let item of keys">
                <td style="text-align: right!important;" style="vertical-align: middle;text-align: right;"
                  [ngClass]="item.class">
                  {{details[item] ? (details[item] | number: "1.2-2") : "0.00" }}
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
        <tfoot>
          <tr class="text-bold">
            <td  class="text-grey text-left" style="white-space: nowrap;overflow: hidden;
            text-overflow: ellipsis; text-align: left;vertical-align: middle;"> PROFIT: </td>
            <ng-container *ngFor="let item of keys">
              <td style="text-align: right!important;" style="vertical-align: middle;text-align: right;" [ngClass]="item.class" >
                {{totalAmountObj[item]  ? (totalAmountObj[item] | number: "1.2-2") : "0.00" }}
              </td>
            </ng-container>
          </tr>
        </tfoot>
      </nz-table>
    </div>
  </div>
</div>