<form [formGroup]="invoiceFilterForm">

    <div class="flexbox aff-info">
      <div class="flexbox aff-info-inner">
  
        <div class="flexbox">
  
  
          <div class="input-fiel form-group ng-field" searchtabindex="1" (focus)="focusClientAc()">
            <label class="ngauto-label" for="affname"
            >Client<span class="text-danger">*</span>
            </label>

            <div class="ng-autocomplete">
                       <!-- <input
                            type="text" #clientAC  
                            name="client_name" 
                            [ngClass]="{'ng-error-field': invoiceFilterForm.controls.client_name.invalid}"
                            formControlName="client_name"
                            (inputChanged)="getClient($event,false)"
                            [nzAutocomplete]="clientAc"
                            historyIdentifier="clientArr"
                            [itemTemplate]="itemTemplate"
                            class="input-ui inv-date-input"

                         />

                         <nz-autocomplete #clientAc [data]="clientArr" [searchKeyword]="keyword">
                                              
                         </nz-autocomplete> -->



              <ng-autocomplete #clientAC [data]="clientArr" [searchKeyword]="keyword"
                (inputChanged)='getClient($event,false)' historyIdentifier="clientArr" [itemTemplate]="itemTemplate"
                (inputCleared)="clearData('client')"
                formControlName="client_name" (selected)="selectAccount($event,'client')"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
  
  
  
  
              <ng-template #itemTemplate let-item>
                <a innerHTML='<span class="colr-1">{{item.name}}
                                  ({{item.account_code}})</span><br />
                              <span class="colr-2">{{item.primary_email}}</span><br />'>
                </a>
              </ng-template>
  
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
  
          <div class="input-fiel">
            <input class="input-ui" searchtabindex="2" formControlName="start_date" id="date" type="date"
                min="{{minDate}}" max="{{maxDate}}"
                [ngClass]="{'error-box': !invoiceFilterForm.controls.start_date.valid}"
                style="width: 140px;" />
            <label for="date">From Date</label>
  
          </div>
  
          <div class="input-fiel">
            <input class="input-ui" searchtabindex="3" formControlName="end_date" id="date" type="date" 
                min="{{minDate}}" max="{{maxDate}}"
                [ngClass]="{'error-box': !invoiceFilterForm.controls.end_date.valid}"
               style="width: 140px;" />
            <label for="date">To Date</label>
  
          </div>
  
  
        </div>
  
        <div class="flexbox mt-3">
  
          <div class="input-fiel">
            <input class="input-ui" id="uidinv_no" oninput="this.value = this.value.toUpperCase()" type="text"
              placeholder="Invoice Number" searchtabindex="4" formControlName="invoice_no" /><label
              for="uidinv_no">Invoice Number</label>
          </div>
  
          <div class="input-fiel">
            <input class="input-ui" id="uuid_book_ref" oninput="this.value = this.value.toUpperCase()" type="text"
              searchtabindex="5" formControlName="booking_ref_no" placeholder="Booking Red No" /><label
              for="uuid_book_ref">Booking Ref No</label>
          </div>
        </div>
  
  
        <div class="flexBox d-flex mt-3">
          <div class="input-fiel">
            <input class="input-ui" id="uuid_pnr" type="text" oninput="this.value = this.value.toUpperCase()"
              searchtabindex="6" formControlName="pnr" placeholder="PNR" /><label for="uuid_pnr">PNR</label>
          </div>
  
  
          <div class="input-fiel">
            <input class="input-ui" id="uuid_tktno" type="text" oninput="this.value = this.value.toUpperCase()"
              searchtabindex="7" formControlName="ticket_no" placeholder="Ticket No" /><label for="uuid_tktno">Ticket
              No</label>
          </div>
        </div>
  
  
        <div class="flexbox mt-3">
  
          <div class="input-fiel">
            <input class="input-ui" id="uuid_paxname" type="text" oninput="this.value = this.value.toUpperCase()"
              searchtabindex="8" formControlName="pax_name" placeholder="Pax Name" /><label for="uuid_paxname">Pax
              Name</label>
          </div>
  
  
          <!-- <div class="input-fiel">
            <input class="input-ui" id="uuid_narr" type="text" oninput="this.value = this.value.toUpperCase()"
              searchtabindex="9" formControlName="narration" placeholder="Narration" /><label
              for="uuid_narr">Narration</label>
          </div> -->
          <div class="select-field text-input-fiel">
            <select class="input-field" searchtabindex="9" formControlName="status">
                <option value="active">Active</option>
                <option value="draft">Draft</option>
            </select>
            <label for="status">Status</label>
         </div>
  
        </div>
  
  
        <div class="flexbox mt-3">
  
          <div class="input-fiel ">
            <input class="input-ui " id="uuid_facc" type="number" searchtabindex="10" formControlName="from_amount"
              placeholder="From Amount" /><label for="uuid_facc">From Amount</label>
          </div>
  
  
          <div class="input-fiel ">
            <input class="input-ui " id="uuid_tacc" type="number" searchtabindex="11" formControlName="to_amount"
              placeholder="To Amount" /><label for="uuid_tacc">To Amount</label>
          </div>
  
        </div>
  
        <div class="flexbox mt-3">
          <div class="select-field text-input-fiel">
              <select class="input-field" searchtabindex="12" formControlName="product_type">
                  <option value="invoice">Invoice</option>
                  <option value="miscellaneous">Miscellaneous</option>
                  <option value="tour">Tour</option>
              </select>
              <label for="subLedger">Product</label>
      
          </div>
          <div class="select-field text-input-fiel" *ngIf="invoiceFilterForm.value.product_type != 'tour' ">
              <select class="input-field" searchtabindex="13" formControlName="invoice_type">
                  <option value="flight">Flight</option>
                  <option value="hotel">Hotel</option>
                  <option value="visa">Visa</option>
                  <option value="insurance">Insurance</option>
                  <option value="non_account">GST</option>
                  <option value="rail">Rail</option>      
                  <option value="car">Car</option>
                  <option value="bus">Bus</option>
                  <option value="misc-invoice">Other</option>
              </select>
              <label for="subLedger">Invoice Type</label>
          </div>
      
          <div class="select-field text-input-fiel" *ngIf="invoiceFilterForm.value.invoice_type != 'flight' || invoiceFilterForm.value.product_type == 'tour'">
              <select class="input-field" searchtabindex="14" formControlName="transaction_method">
                  <option value="">All</option>
                  <option value="sales">Sales</option>
                  <option value="purchase">Purchase</option>
              </select>
              <label for="subLedger">Sales/Purchase</label>
          </div>
      </div>
  
        <div class="d-flex mt-5 justify-content-center">
          <div class="input-fiel">
            <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
              searchtabindex="12" #searchButton (keyup.enter)="clickSearchButton()"
              [disabled]="invoiceFilterForm.invalid"
              (click)="search(invoiceFilterForm.value)"> Search</button>
            <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
          </div>
        </div>
  
      </div>
  
    </div>
    <!-- </div> -->
  </form>