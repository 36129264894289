import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormArray } from '@angular/forms';
import { SectorService } from './../services/sector.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LegModel } from './../models/leg-model';
import { CommonServiceService } from 'src/app/shared/common-service.service';


@Component({
	selector: 'app-sector-info',
	templateUrl: './sector-info.component.html',
	styleUrls: ['../flight.component.scss']
})
export class SectorInfoComponent implements OnInit {
	dateTimeMultiTab: string = "false";

	bsConfig: Partial<BsDatepickerConfig>;
	colorTheme = 'theme-orange';
	leg : LegModel = new LegModel();
	@Input() sectorService;
	@Input() isTenantIndian;
	constructor(
		public commonService : CommonServiceService
	) { }

	async ngOnInit() {
		this.bsConfig = Object.assign({ dateInputFormat: 'DD/MM/YYYY HH:mm' }, { containerClass: this.colorTheme }, { showWeekNumbers: false })	
		let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
			let inv_config = globalSettingConfig.filter(el=>el.group=='FLIGHT_INV')
			if(inv_config.length){
				let multi_tav_config = inv_config[0].json_doc.value.filter(el=>el.key=='MULTI TAB TRAVEL DATE TIME')
				if(multi_tav_config && multi_tav_config.length>0) this.dateTimeMultiTab = multi_tav_config[0].value
			}
		console.log("this.dateTimeMultiTab ",this.dateTimeMultiTab)
	}

	removeSector(i,sectorList) {
		 
		this.sectorService[sectorList].removeAt(i)
		this.sectorService.removeSectorFromTravellers(i)
	}
}
