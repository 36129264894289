import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonServiceService } from '../../common-service.service';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';
@Component({
	selector: 'app-adm-acm-search',
	templateUrl: './adm-acm-search.component.html',
	styleUrls: ['../global-search.component.scss']
})
export class AdmAcmSearchComponent implements OnInit {
	modalRef: BsModalRef;
	receiptFilterForm: FormGroup;
	receiptArr: any;
	clientArr: any;
	keyword = 'name';
	fromAccount: any;
	toAccount: any;
	subLedger: any;
	clientAccount: any

	@ViewChild('fraccountAC', { static: true }) fraccountAC;
	@ViewChild('toaccountAC', { static: true }) toaccountAC;
	@ViewChild('clientAc', { static: true }) clientAc;
	@ViewChild('searchButton', { static: true }) searchButton: ElementRef;

	minDate = "2023-04-01"
    maxDate = moment().format('YYYY-MM-DD')
	constructor(
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private router: Router,
		private store: Store<InitialState>) {
			this.store.select('sharedReducers').subscribe((items)=>{
				if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				} else {
					let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
					if(!current_financial_year) {
						current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
					}
					this.minDate = current_financial_year.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				}
				
		})
	}
	ngOnInit() {
		this.receiptFilterForm = this.formBuilder.group({
			account_1_code: [''],
			account_1_name: [''],
			account_2_code: [''],
			account_2_name: [''],
			client_code: [''],
			client_name: [''],
			start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate, this.checkDate('end_date')],
			transaction_no: [''],
			adm_acm_number: [''],
			reference_number: [''],
			ticket_no: [''],
			from_amount: [''],
			to_amount: [''],
			transaction_type: ['both']
		})
		this.setFormData()
	}


	setFormData() {
		// let queryParams = localStorage.getItem('activeCreditNoteQueryParams')
		let queryParams = localStorage.getItem('activeAdmAcmQueryParams')
		debugger
		if (queryParams != null) {
			let parsedParams = JSON.parse(queryParams)
			console.log('Active Query Params', parsedParams)
			this.getFromAccount(parsedParams.account_1_code ? parsedParams.account_1_code : "", true)
			this.getToAccount(parsedParams.account_2_code ? parsedParams.account_2_code : "", true)
			this.getClient(parsedParams.client_code ? parsedParams.client_code : "", true)


			this.receiptFilterForm.controls.start_date.setValue(moment(this.minDate).format('YYYY-MM-DD'))
			this.receiptFilterForm.controls.end_date.setValue(moment(this.maxDate).format('YYYY-MM-DD'))
			
			this.receiptFilterForm.controls.transaction_no.setValue(parsedParams.transaction_no ? parsedParams.transaction_no : "")
			this.receiptFilterForm.controls.adm_acm_number.setValue(parsedParams.adm_acm_number ? parsedParams.adm_acm_number : "")
			this.receiptFilterForm.controls.reference_number.setValue(parsedParams.reference_number ? parsedParams.reference_number : "")
			this.receiptFilterForm.controls.ticket_no.setValue(parsedParams.ticket_no ? parsedParams.ticket_no : "")
			this.receiptFilterForm.controls.from_amount.setValue(parsedParams.from_amount ? parsedParams.from_amount : "")
			this.receiptFilterForm.controls.to_amount.setValue(parsedParams.to_amount ? parsedParams.to_amount : "")
			this.receiptFilterForm.controls.transaction_type.setValue(parsedParams.transaction_type ? parsedParams.transaction_type : 'both')
		}
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'customModal' })
		);
	}

	getFromAccount(value, bindValue = false) {
		if(!value) {
			this.receiptFilterForm.controls['account_1_code'].setValue('')
			this.receiptFilterForm.controls['account_1_name'].setValue('')
		}
		if (value.length > 2) {
			this.commonService.getOkount(`v3account/ledgerautocomplete?name=${value.toUpperCase()}`).subscribe(res => {
				this.fromAccount = res
				if (bindValue && this.fromAccount.length > 0) {
					this.receiptFilterForm.controls['account_1_code'].setValue(this.fromAccount[0].account_code)
					this.receiptFilterForm.controls['account_1_name'].setValue(this.fromAccount[0].name)
				}

			}, err => {

			})
		}
	}

	getToAccount(value, bindValue) {
		if(!value) {
			this.receiptFilterForm.controls['account_2_code'].setValue('')
			this.receiptFilterForm.controls['account_2_name'].setValue('')
		}
		if (value.length > 2) {
			this.commonService.getOkount(`v3account/ledgerautocomplete?name=${value.toUpperCase()}`).subscribe(res => {
				this.toAccount = res
				if (bindValue && this.toAccount.length > 0) {
					this.receiptFilterForm.controls['account_2_code'].setValue(this.toAccount[0].account_code)
					this.receiptFilterForm.controls['account_2_name'].setValue(this.toAccount[0].name)
				}
			}, err => {

			})
		}
	}



	getClient(value, bindValue) {
		if(!value) {
			this.receiptFilterForm.controls['client_code'].setValue('')
			this.receiptFilterForm.controls['client_name'].setValue('')
		}
		if (value.length > 2) {
			this.commonService.getOkount(`v3account/ledgerautocomplete?name=${value.toUpperCase()}`).subscribe(res => {
				this.clientAccount = res
				if (bindValue && this.clientAccount.length > 0) {
					this.receiptFilterForm.controls['client_code'].setValue(this.clientAccount[0].account_code)
					this.receiptFilterForm.controls['client_name'].setValue(this.clientAccount[0].name)
				}
			}, err => {

			})
		}
	}


	reset() {
		GlobalVariable.openModalStatus = false;
		this.receiptFilterForm = this.formBuilder.group({
			account_1_code: [''],
			account_1_name: [''],
			account_2_code: [''],
			account_2_name: [''],
			client_code: [''],
			client_name: [''],
			start_date: [this.minDate],
			end_date: [this.maxDate],
			transaction_no: [''],
			adm_acm_number: [''],
			reference_number: [''],
			ticket_no: [''],
			from_amount: [''],
			to_amount: [''],
			transaction_type: ['both']
		})
		localStorage.removeItem('activeAdmAcmQueryParams')
	}

	search(values) {
		GlobalVariable.openModalStatus = false;
		if ((values.start_date == null || values.start_date == "" || values.start_date == "Invalid date" || values.start_date == "Invalid date")) {
			values.start_date = ""
		} else {
			values.start_date = moment(values.start_date).format('YYYY-MM-DD')
		}

		if ((values.end_date == null || values.end_date == "" || values.end_date == "Invalid date" || values.end_date == "Invalid date")) {
			values.end_date = ""
		} else {
			values.end_date = moment(values.end_date).format('YYYY-MM-DD')
		}


		let fromAccount = values.account_1_code
		if (values.account_1_code.account_code != undefined) {
			fromAccount = values.account_1_code.account_code
		}
		let toAccount = values.account_2_code
		if (values.account_2_code.account_code != undefined) {
			toAccount = values.account_2_code.account_code
		}
		let clientCode = values.client_code
		if (values.client_code.account_code != undefined) {
			clientCode = values.client_code.account_code
		}
		this.router.navigateByUrl(`/transactions/search-adm-acm?account_1_code=${fromAccount}&account_2_code=${toAccount}&start_date=${values.start_date}&end_date=${values.end_date}&adm_acm_number=${values.adm_acm_number?values.adm_acm_number:''}&from_amount=${values.from_amount?values.from_amount:''}&to_amount=${values.to_amount?values.to_amount:''}&client_code=${clientCode}&adm_acm_code=${values.transaction_no}&reference_number=${values.reference_number}&transaction_type=${values.transaction_type}&ticket_no=${values.ticket_no}`)
		// this.router.navigateByUrl(`/transactions/search-adm-acm`)

		// this.reset()
	}

	ngAfterViewInit() {
		this.commonService.selectedSearchInput.subscribe(index => {
			if (index == 1) {
				this.focusfrAccountAc()
			}

			if (index == 2) {
				this.focustoAccountAc()
			}

			if (index == 6) {
				this.focusClientAc()
			}
		})
	}

	focusfrAccountAc(): void {
		this.fraccountAC.focus();
	}

	focustoAccountAc(): void {
		this.toaccountAC.focus();
	}

	focusClientAc() {
		this.clientAc.focus();
	}

	clickSearchButton() {
		this.searchButton.nativeElement.click()
	}



	selectAccount(value, formControlName) {
		try {
			this.receiptFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}

	checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}

	clearData(formControl){
		this.receiptFilterForm.controls[formControl].setValue("")
	}
}
