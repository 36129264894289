import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServiceService } from '../common-service.service';

@Component({
  selector: 'app-notification-area',
  templateUrl: './notification-area.component.html',
  styleUrls: ['./notification-area.component.scss']
})
export class NotificationAreaComponent implements OnInit {
  @Input() notificationArr
  @Output() callShowNotification : EventEmitter<any> = new EventEmitter();

  constructor(
    private router : Router,
    public commonService : CommonServiceService
  ) { }

  ngOnInit(): void {
  }

  closeNotification(){
    this.callShowNotification.emit()
  }

  openRelatedPath(metaInfo){
    if(metaInfo && metaInfo.path){
      this.router.navigateByUrl(metaInfo.path)
    }
    this.closeNotification()
  }
  

}
