import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { CommonServiceService } from '../common-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-master-setting',
	templateUrl: './master-setting.component.html',
	styleUrls: ['./master-setting.component.scss']
})
export class MasterSettingComponent implements OnInit {
	searchText;
	selectedMaster: any;
	selectedMasterId: any;
	selectedMasterName: any;
	showMessage: boolean;
	alertType: string;
	message: string;
	configData: any;
	configForm: any;
	configKeyList: FormArray;
	childList: any;
	userInfo: any;

	childKeyForm: any;
	value: any
	@ViewChild('childKeyModalBtn', { static: true }) childKeyModalBtn: ElementRef;
	@ViewChild('childKeyModalCloseBtn', { static: true }) childKeyModalCloseBtn: ElementRef;
	constructor(
		public commonService: CommonServiceService,
		private route: ActivatedRoute,
		private router: Router,
		private spinner: NgxSpinnerService,
		private fb: FormBuilder
	) { }

	ngOnInit(): void {
		this.route.paramMap.subscribe(params => {
			this.selectedMaster = params['params'].master
			this.selectedMasterId = params['params'].accountCode
			this.selectedMasterName = params['params'].masterName.split('(')[0]
			this.configForm = this.fb.group({
				configKeyList: this.fb.array([]),
			});
			this.childKeyForm = this.fb.group({
				parent_key: [''],
				parent_key_index: [''],
				key: [''],
				type: ['text'],
				value: ['']
			});
			this.getGroupSettingData()

		});
	}

	getGroupSettingData() {
		this.spinner.show()
		try {
			this.commonService.getRequest(`${environment.okountUrl}/master_settings?account_code=${this.selectedMasterId}`).subscribe((res: any) => {

				if (res && res.length > 0) {
					this.configData = res[0]
					this.configKeyList = this.configForm.get("configKeyList") as FormArray;
					this.configKeyList.controls = []
					for (const key in this.configData.json_doc.value) {
						if (this.configData.json_doc.value.hasOwnProperty(key)) {
							const policyEl = this.configData.json_doc.value[key];
							this.configKeyList.push(this.buildConfig(policyEl, true))
							if (policyEl.type == 'object') {
								this.childList = this.configForm.get("configKeyList").controls[key].get('childList');
								this.childList.controls = []
								for (const policyElKey in policyEl.value) {
									if (policyEl.value.hasOwnProperty(policyElKey)) {
										const ploicyElChild = policyEl.value[policyElKey];
										this.childList.push(this.buildConfig(ploicyElChild, true))
										if (ploicyElChild.type == 'object') {
											this.childList = this.configForm.get("configKeyList").controls[key].get('childList').controls[policyElKey].get('childList');
											this.childList.controls = []
											for (const policyElChildKey in policyEl.value) {
												if (policyEl.value.hasOwnProperty(policyElChildKey)) {
													const ploicyElChildtwo = policyEl.value[policyElChildKey];
													this.childList.push(this.buildConfig(ploicyElChildtwo, true))
												}
											}
										}
									}
								}
							}
						}
					}
					console.log(this.configForm.value)
				}
				this.spinner.hide();
			}, err => {
				this.spinner.hide()
				console.log(err)
			})
		}
		catch (error) {
			debugger
		}

	}
	buildConfig(configData,isOld) {
		return this.fb.group({
			key_name: [configData.key],
			isOld: [isOld == true ? isOld : false],
			type: [configData.type ? configData.type : 'text'],
			value: [typeof configData.value == 'string' ? configData.value : JSON.stringify(configData.value)],
			options:[configData.type == 'option' ? configData.options :  []] ,
			childList: this.fb.array([]),
		});
	}
	get DynamicFormControls() {
		const configKeyList = <FormArray>this.configForm.get("configKeyList");
		return configKeyList;
	}
	openChildKeyModal(parentConfig,index) {
		debugger
		if (parentConfig) {
			this.childKeyForm.controls.parent_key.setValue(parentConfig.key_name)
			if (index != undefined) {
				this.childKeyForm.controls.parent_key_index.setValue(index)
			}
		} else {
			this.childKeyForm.controls.parent_key.setValue('')
		}
		this.childKeyModalBtn.nativeElement.click();
	}

	closeChildKeyModal(): void {
		this.childKeyModalCloseBtn.nativeElement.click();
	}

	resetChildKeyForm() {
		this.childKeyForm.controls.parent_key.setValue('')
		this.childKeyForm.controls.parent_key_index.setValue('')
		this.childKeyForm.controls.key.setValue('')
		this.childKeyForm.controls.type.setValue('text')
		this.childKeyForm.controls.value.setValue('')
	}

	closeChildKeyForm() {
		this.resetChildKeyForm()
		this.closeChildKeyModal()
	}


	saveParameter(values,index){
		let parameterValue = this.parseChildList(values.childList)
		if (values.type != 'object') {
			parameterValue = values.value
		}
		let reqObj = {
			"account_code":this.selectedMasterId,
			"type": values.type,
			"value": parameterValue,
			"key": values.key_name,
			"key_1":values.key_name,
			"index_1":index,
			"key_2": "",
			"index_2": "",
		}

		this.commonService.putRequest(`${environment.okountUrl}/master_settings`,reqObj).subscribe(res=>{
			console.log(res)
			this.getGroupSettingData()
			this.closeChildKeyForm()
		},err=>{
			console.log(err)
		})
	}

	parseChildList(childList: any) {
		let valuesList = []
		for (const key in childList) {
			if (childList.hasOwnProperty(key)) {
				const element = childList[key];
				let obj = {
					"key" : element.key_name,
					"value" : element.value,
					"type" : element.type
				}
				valuesList.push(obj)
			}
		}
		return valuesList
	}

	addParameter(values){
		debugger
		let reqObj = {
			"account_code":this.selectedMasterId,
			"type": values.type,
			"value": values.value,
			"key": values.key,
		}
		this.commonService.putRequest(`${environment.okountUrl}/master_settings`,reqObj).subscribe(res=>{
			console.log(res);
			this.getGroupSettingData()
			this.closeChildKeyForm()
			
		},err=>{
			console.log(err);
			
		})
	}
	addChildParameter(values){
		debugger
		let reqObj = {
			"account_code": this.selectedMasterId,
			"type": values.type,
			"value": values.value,
			"key": values.key,
			"key_1": values.parent_key,
			"index_1": values.parent_key_index,
			"key_2": "",
			"index_2": "",
			"is_new_key":true
		}
		this.commonService.putRequest(`${environment.okountUrl}/${this.selectedMaster}_settings`,reqObj).subscribe(res=>{
			console.log(res);
			this.getGroupSettingData()
			this.closeChildKeyForm()
		},err=>{
			console.log(err);
			
		})
	}

	cloneParameter(index){
		const policyEl = this.configData.json_doc.value[index];
		this.configKeyList.push(this.buildConfig(policyEl,false))
		var key = this.configKeyList.length - 1
		if (policyEl.type == 'object') {
			this.childList = this.configForm.get("configKeyList").controls[key].get('childList');
			this.childList.controls = []
			for (const policyElKey in policyEl.value) {
				if (policyEl.value.hasOwnProperty(policyElKey)) {
					const ploicyElChild = policyEl.value[policyElKey];
					this.childList.push(this.buildConfig(ploicyElChild,false))
					if (ploicyElChild.type == 'object') {
						this.childList = this.configForm.get("configKeyList").controls[key].get('childList').controls[policyElKey].get('childList');
						this.childList.controls = []
						for (const policyElChildKey in policyEl.value) {
							if (policyEl.value.hasOwnProperty(policyElChildKey)) {
								const ploicyElChildtwo = policyEl.value[policyElChildKey];
								this.childList.push(this.buildConfig(ploicyElChildtwo,false))
							}
						}
					}
				}
			}
		}
	}

	saveCloneParameter(values){
		let parameterValue = this.parseChildList(values.childList)
		let reqObj = {
			type : values.type,
			key : values.key_name,
			value : parameterValue
		}
		this.addParameter(reqObj)
	}

	hideError() {
		this.showMessage = false
	}



}
