import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonServiceService } from '../../common-service.service';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-e-invoice-search',
  templateUrl: './e-invoice-search.component.html',
	styleUrls: ['../global-search.component.scss']
})
export class EInvoiceSearchComponent implements OnInit {

  modalRef: BsModalRef;
	invoiceFilterForm: FormGroup;
	invoiceArr: any;
	clientArr: any;
	keyword = 'name';
	@ViewChild('clientAC',{static:true}) clientAC;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	minDate = "2023-04-01"
	maxDate = moment().format('YYYY-MM-DD')
	constructor(
		private modalService: BsModalService, 
		private formBuilder: FormBuilder, 
		public commonService: CommonServiceService, 
		private spinner: NgxSpinnerService,
        private store: Store<InitialState>,
		private router: Router) {
			this.store.select('sharedReducers').subscribe((items)=>{
				if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				} else {
					let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
					if(!current_financial_year) {
						current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
					}
					this.minDate = current_financial_year.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				}
			})
	}

	ngOnInit() {
		this.invoiceFilterForm = this.formBuilder.group({
			client: [''],
			product_type: ['invoice'],
			invoice_type: ['flight'],
			transaction_method : [''],
			client_name:['', Validators.required],
			date: [''],
			start_date: [this.minDate, [this.checkDate('start_date')]],
			end_date: [this.maxDate, [this.checkDate('start_date')]],
			invoice_no: [''],
			booking_ref_no: [''],
			pnr: [''],
			ticket_no: [''],
			pax_name: [''],
			from_amount: [''],
			to_amount: [''],
			status: ['active']
		})
		this.setFormData()
	}

	 
	  

	setFormData() {
		let queryParams = localStorage.getItem('activeInvoiceQueryParams')
		if (queryParams != null) {
			let parsedParams = JSON.parse(queryParams)
			console.log('Active Query Params', parsedParams)
			this.invoiceFilterForm.controls.client.setValue(parsedParams.client?parsedParams.client:"")
			this.getClient(parsedParams.client ? parsedParams.client : "",true)
			
			this.invoiceFilterForm.controls.start_date.setValue(moment(this.minDate).format('YYYY-MM-DD'))
			this.invoiceFilterForm.controls.end_date.setValue(moment(this.maxDate).format('YYYY-MM-DD'))
		
			this.invoiceFilterForm.controls.invoice_no.setValue(parsedParams.invoice_no?parsedParams.invoice_no:"")
			this.invoiceFilterForm.controls.booking_ref_no.setValue(parsedParams.booking_ref_no?parsedParams.booking_ref_no:"")
			this.invoiceFilterForm.controls.pnr.setValue(parsedParams.pnr ? parsedParams.pnr : "")
			this.invoiceFilterForm.controls.ticket_no.setValue(parsedParams.ticket_no?parsedParams.ticket_no:"")
			this.invoiceFilterForm.controls.pax_name.setValue(parsedParams.pax_name?parsedParams.pax_name:"")
			this.invoiceFilterForm.controls.status.setValue(parsedParams.status?parsedParams.status:"")
			this.invoiceFilterForm.controls.from_amount.setValue(parsedParams.from_amount?parsedParams.from_amount:"")
			this.invoiceFilterForm.controls.to_amount.setValue(parsedParams.to_amount?parsedParams.to_amount:"")
			this.invoiceFilterForm.controls.to_amount.setValue(parsedParams.to_amount?parsedParams.to_amount:"")
			this.invoiceFilterForm.controls.product_type.setValue(parsedParams.product_type?parsedParams.product_type:"invoice")
			this.invoiceFilterForm.controls.invoice_type.setValue(parsedParams.invoice_type?parsedParams.invoice_type:"flight")
			this.invoiceFilterForm.controls.transaction_method.setValue(parsedParams.transaction_method?parsedParams.transaction_method:"")
		}
	  }

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'customModal' })
		);
	}

	getClient(value,bindValue=false) {
		if (value.length > 2) {
			this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.clientArr = res
				if(bindValue && this.clientArr.length>0){
					this.invoiceFilterForm.controls['client'].setValue(this.clientArr[0].account_code)
					this.invoiceFilterForm.controls['client_name'].setValue(this.clientArr[0].name)
				}
			}, err => {
	
			})
		}
	}

	reset() {
		GlobalVariable.openModalStatus = false;
		this.invoiceFilterForm = this.formBuilder.group({
			client: [''],
			client_name:[''],
			start_date: [this.minDate],
			end_date:[this.maxDate],
			invoice_no: [''],
			booking_ref_no: [''],
			pnr: [''],
			ticket_no: [''],
			pax_name: [''],
			status: ['active'],
			from_amount: [''],
			to_amount: ['']
		})
	  }

	  search(values){
		console.log(values,'valuee');
		
		try {
			if (this.invoiceFilterForm.controls.group && this.invoiceFilterForm.controls.group.value.length == 0) this.invoiceFilterForm.controls.client_name.setValidators(Validators.required)
			if (this.invoiceFilterForm.invalid || (values.client_name == "" && this.invoiceFilterForm.controls.group && this.invoiceFilterForm.controls.group.value.length == 0)) {
				console.log('woo');
				return false
			} else {
				console.log('woo else');

				GlobalVariable.openModalStatus = false;
				if ((values.start_date == null || values.start_date == "" || values.start_date == "Invalid date" || values.start_date == "Invalid date")) {
					values.start_date = ""
				}
				else {
					values.start_date = moment(values.start_date).format('YYYY-MM-DD')
				}
				if ((values.end_date == null || values.end_date == "" || values.end_date == "Invalid date" || values.end_date == "Invalid date")) {
					values.end_date = ""
				}
				else {
					values.end_date = moment(values.end_date).format('YYYY-MM-DD')
				}
				let clientCode = values.client
				if (values.client.account_code != undefined) {
					clientCode = values.client.account_code
				}
				let clientName = values.client_name.name				

				window.location.href = `${environment.baseAccountUrl}/v2/#/e-invoice/list?client=${clientCode}&name=${values}&name=${clientName}&start_date=${values.start_date}&end_date=${values.end_date}&invoice_no=${values.invoice_no}&booking_ref_no=${values.booking_ref_no}&pnr=${values.pnr}&ticket_no=${values.ticket_no}&pax_name=${values.pax_name}&status=${values.status}&from_amount=${values.from_amount ? values.from_amount : ""}&to_amount=${values.to_amount ? values.to_amount : ""}&product_type=${values.product_type ? values.product_type : "invoice"}&invoice_type=${values.invoice_type ? values.invoice_type : "flight"}&transaction_method=${values.transaction_method ? values.transaction_method : ""}`

			}

		} catch (error) {
			console.log(error)
		}
	}

	ngAfterViewInit(){
		this.commonService.selectedSearchInput.subscribe(index=>{
			if (index == 1) {
				this.focusClientAc()
			}
		})
	}

	selectAccount(value,formControlName){
		try {
			this.invoiceFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}

	clickSearchButton(){
		this.searchButton.nativeElement.click()
	}

	focusClientAc(){
		this.clientAC.focus();
	}

	checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}

	clearData(formControl){
		this.invoiceFilterForm.controls[formControl].setValue("")
	}
}
