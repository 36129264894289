import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonServiceService } from '../../common-service.service';
import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import { GlobalVariable } from '../../global-variable';
import { InitialState } from 'src/app/store/reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-day-book-search',
  templateUrl: './day-book-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class DayBookSearchComponent implements OnInit {
  daybookFilterForm: FormGroup;
  // daybookArr: any;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	minDate = "2023-04-01"
  maxDate = moment().format('YYYY-MM-DD')
  constructor( public commonService: CommonServiceService,
     private formBuilder: FormBuilder , private router: Router,
    private store: Store<InitialState>) {
      this.store.select('sharedReducers').subscribe((items)=>{
				if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				} else {
					let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
					if(!current_financial_year) {
						current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
					}
					this.minDate = current_financial_year.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				}
				
		})
  }
  ngOnInit() {
    this.daybookFilterForm = this.formBuilder.group({
			start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate, this.checkDate('end_date')],
			client: [true],
			supplier: [true],
			airline: [true],
			general: [true],
			bank: [true]
		})
    this.setFormData()
  }

  checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}

  setFormData() {
    let queryParams = localStorage.getItem('activeDayBookQueryParams')
    if (queryParams != null) {
      let parsedParams = JSON.parse(queryParams)
      if (parsedParams.start_date != "") {
        this.daybookFilterForm.controls.start_date.setValue(moment(parsedParams.start_date).format('YYYY-MM-DD'))
      }
      if (parsedParams.end_date != "") {
        this.daybookFilterForm.controls.end_date.setValue(moment(parsedParams.end_date).format('YYYY-MM-DD'))
      }
	  this.daybookFilterForm.controls.client.setValue(parsedParams.client?parsedParams.client:true)
	  this.daybookFilterForm.controls.supplier.setValue(parsedParams.supplier?parsedParams.supplier:true)
	  this.daybookFilterForm.controls.airline.setValue(parsedParams.airline?parsedParams.airline:true)
	  this.daybookFilterForm.controls.general.setValue(parsedParams.general?parsedParams.general:true)
	  this.daybookFilterForm.controls.bank.setValue(parsedParams.bank?parsedParams.bank:true)

    }
  }


  //  getdaybook(value) {
	// 	if (value.length > 2) {
	// 		this.commonService.getOkount(`reports/daybook`).subscribe(res => {
	// 			debugger
	// 			this.daybookArr = res
	// 		}, err => {
	
	// 		})
	// 	}
	// }
   search(values){
    GlobalVariable.openModalStatus = false;
    if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
			values.start_date = ""
		}
		else{
			values.start_date = moment(values.start_date).format('YYYY-MM-DD')
    }
    if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date" || values.end_date == "Invalid date") {
			values.end_date = ""
		}
		else{
			values.end_date = moment(values.end_date).format('YYYY-MM-DD')
    }
     this.router.navigateByUrl(`/display/day-book-report?start_date=${values.start_date}&end_date=${values.end_date}&client=${values.client}&supplier=${values.supplier}&airline=${values.airline}&general=${values.general}&bank=${values.bank}`)
    //  this.reset()
    }

   getFilteredData(queryParams) {
    this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/daybook`, queryParams).subscribe(res => {
        console.log(res);
    }, err => {
        console.error(err);
    })
}

  reset(){
    GlobalVariable.openModalStatus = false;
    this.daybookFilterForm = this.formBuilder.group({
			start_date: [''],
			end_date: [''],
			client: [true],
			supplier: [true],
			airline: [true],
			general: [true],
			bank: [true]
    })
    localStorage.removeItem('activeDayBookQueryParams')
  }

  clickSearchButton(){
		this.searchButton.nativeElement.click()
	}
}
