import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonServiceService } from '../../common-service.service';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';
import { GlobalVariable } from '../../global-variable';
@Component({
	selector: 'app-supplier-search',
	templateUrl: './supplier-search.component.html',
	styleUrls: ['../global-search.component.scss']
})
export class SupplierSearchComponent implements OnInit {
	modalRef: BsModalRef;
	supplierFilterForm: FormGroup;
	supplierArr: any;
	keyword = 'name';
	countryArr: any = [];
	stateArr: any = [];
	cityArr: any = [];
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	@ViewChild('clientAC',{static:true}) clientAC;


	constructor(
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private router: Router) {
	}

	ngOnInit() {

		// this.spinner.show();
		// setTimeout(() => {
		// 	this.spinner.hide();
		// }, 1000);


		this.supplierFilterForm = this.formBuilder.group({
			account: [''],
			account_name: [''],
			account_code: [''],
		    city_id : [''],
			city_name : [''],
			state_id : [''],
			state_name : [''],
			country_id : ['101'],
			country : [''],
			phone: [''],
			email: [''],

		})

		this.getCountries()
		this.getStates('101')
		this.setFormData()
	}

	async setFormData() {
		let queryParams = localStorage.getItem('activeSupplierQueryParams')
		if (queryParams != null) {
			let parsedParams = JSON.parse(queryParams)
			// this.supplierFilterForm.controls.account.setValue(parsedParams.account ? parsedParams.account : "")
            this.getSupplier(parsedParams.account ? parsedParams.account : "",true)
			this.supplierFilterForm.controls.account_code.setValue(parsedParams.account_code ? parsedParams.account_code : "")
			this.supplierFilterForm.controls.country_id.setValue(parsedParams.country_id ? parsedParams.country_id : "")
			if (parsedParams.country_id != undefined && parsedParams.country_id != "" && parsedParams.country_id != null) {
			await	this.getStates(parsedParams.country_id)
			}
			this.supplierFilterForm.controls.state_id.setValue(parsedParams.state_id ? parsedParams.state_id : "")
			this.supplierFilterForm.controls.state_name.setValue(parsedParams.state ? parsedParams.state : "")
			if (parsedParams.state_id != undefined && parsedParams.state_id != "" && parsedParams.state_id != null) {
				await	this.getCities(parsedParams.state_id)
			}
			this.supplierFilterForm.controls.city_id.setValue(parsedParams.city_id ? parsedParams.city_id : "")
			this.supplierFilterForm.controls.city_name.setValue(parsedParams.city ? parsedParams.city : "")
			this.supplierFilterForm.controls.country.setValue(parsedParams.country ? parsedParams.country : "")
			this.supplierFilterForm.controls.phone.setValue(parsedParams.phone ? parsedParams.phone : "")
			this.supplierFilterForm.controls.email.setValue(parsedParams.email ? parsedParams.email : "")
		}
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'customModal' })
		);
	}

	getCountries() {
		this.commonService.getOkount(`location/country`).subscribe(res=>{
			this.countryArr = res
		})
	}

	getStates(country_id) {
		let selectedCountry = this.countryArr.filter(option => {
			return option.location_id == Number(country_id);
		})
		if (selectedCountry.length > 0) {
			this.supplierFilterForm.controls.country.setValue(selectedCountry[0].name)
		} else {
			this.supplierFilterForm.controls.country.setValue('India')
		}
		if (country_id != "") {
			this.commonService.getOkount(`location/states?country_id=${country_id}`).subscribe(res => {
				this.stateArr = res
			})
		}else{
			this.stateArr = []
			this.supplierFilterForm.controls.state_id.setValue("")
			this.supplierFilterForm.controls.state_name.setValue("")
			this.supplierFilterForm.controls.city_id.setValue("")
			this.supplierFilterForm.controls.city_name.setValue("")
		}
	}

	getCities(state_id) {
		let selectedState = this.stateArr.filter(option => {
			return option.location_id == Number(state_id);
		})
		if (selectedState.length > 0) {
			this.supplierFilterForm.controls.state_name.setValue(selectedState[0].name)
		}else{
			this.supplierFilterForm.controls.state_name.setValue("")

		}
		if (state_id != "") {
			this.commonService.getOkount(`location/city?state_id=${state_id}`).subscribe(res => {
				this.cityArr = res
			})
		}else{
			this.cityArr = []
			this.supplierFilterForm.controls.city_id.setValue("")
			this.supplierFilterForm.controls.city_name.setValue("")
		}
	}
	getCityName(city_id) {
		let selectedCity = this.cityArr.filter(option => {
			return option.location_id == Number(city_id);
		})
		if (selectedCity.length > 0) {
			this.supplierFilterForm.controls.city_name.setValue(selectedCity[0].name)
		}else{
			this.supplierFilterForm.controls.city_name.setValue("")
		}
	}

	getSupplier(value,bindValue=false) {
		if (value.length > 2) {
			this.commonService.getOkount(`supplier/autocomplete?name_like=${value}`).subscribe(res => {
				this.supplierArr = res
				if(bindValue && this.supplierArr.length>0){
					this.supplierFilterForm.controls['account'].setValue(this.supplierArr[0].account_code)
					this.supplierFilterForm.controls['account_name'].setValue(this.supplierArr[0].name)
				}
			}, err => {

			})
		}
	}

	reset() {
		GlobalVariable.openModalStatus = false;
		this.supplierFilterForm = this.formBuilder.group({
			account: [''],
			account_name: [''],
			account_code: [''],
		    city_id : [''],
			city_name : [''],
			state_id : [''],
			state_name : [''],
			country_id : ['101'],
			country : [''],
			phone: [''],
			email: [''],

		})

		this.getCountries()
		this.getStates('101')
		localStorage.removeItem('activeSupplierQueryParams')
	  }

	search(values) {
		GlobalVariable.openModalStatus = false;
		if (values.date == null || values.date == "" || values.date == "Invalid Date" || values.date == "Invalid date") {
			values.date = ""
		}
		else {
			values.date = moment(values.date).format('YYYY-MM-DD')
		}
		let account = values.account
		if (values.account.name != undefined) {
			account = values.account.name
		}
		this.router.navigateByUrl(`/master/supplier/view-supplier?account=${account}&account_code=${values.account_code}&country=${values.country}&city=${values.city_name}&state=${values.state_name}&country_id=${values.country_id}&city_id=${values.city_id}&state_id=${values.state_id}&phone=${values.phone}&email=${values.email}`)
		// this.reset()
	}

	ngAfterViewInit(){
		this.commonService.selectedSearchInput.subscribe(index=>{
			if (index == 1) {
				this.focusClientAc()
			}
		})
	}

	selectAccount(value,formControlName){
		try {
			this.supplierFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}

	focusClientAc(){
		this.clientAC.focus();
	}

	clickSearchButton(){
		this.searchButton.nativeElement.click()
	}
}
