import { Directive, ElementRef, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { CommonServiceService } from '../common-service.service';
import { environment } from 'src/environments/environment';
import { CommonDataService } from '../services/common-data.service';
import { GlobalData } from '../services/globla-data';

@Directive({
	selector: '[hasAccess]'
})
export class HasAccessDirective {

	isFind: boolean;
	subscription;
	constructor(
		public commonService: CommonServiceService,
		private commonDataService : CommonDataService,
		private element: ElementRef,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef) { }

	@Input()
	set hasAccess(val) {	
		this.setAccess(val);
	}

	async setAccess(val) {
		
		if (val.hasOwnProperty('key')) {
			this.isFind = false
			let accessData = localStorage.getItem('accessData') ? JSON.parse(localStorage.getItem('accessData')) : undefined
			// if (accessData != undefined) {
			// 	if (localStorage.getItem('okountUserInfo') != null && localStorage.getItem('okountUserInfo') != undefined) {
			// 		let userInfo = JSON.parse(localStorage.getItem('okountUserInfo'))
			// 		// console.log(GlobalData.getAccessData())
			// 		if (userInfo.roles[0] != undefined && accessData.role_id != userInfo.roles[0].roleid) {
			// 			accessData = await this.getFeatureData(userInfo.roles[0].roleid)
			// 		}
			// 	}
			// }
			if(accessData == undefined) {
				if (localStorage.getItem('okountUserInfo') != null && localStorage.getItem('okountUserInfo') != undefined) {
					let userInfo = JSON.parse(localStorage.getItem('okountUserInfo'))
					// console.log(GlobalData.getAccessData())
					accessData = await this.commonService.getFeatureData(userInfo.roles[0].roleid)
				}
			}
			let isAllowed =  await this.checkAccess(accessData? accessData.features : undefined,val.key)
			// console.log('step -1 ' + isAllowed)
			if (isAllowed == true) {
				this.viewContainer.createEmbeddedView(this.templateRef);
			}
			else {
				this.viewContainer.clear();
			}
		}
		else {
			this.viewContainer.clear();
		}
	}

	

	async checkAccess(json, key) {
		let isFind = false
		let isKeyFound = false;
		let isAllowed;
		try {
			for (const item of json) {
				if(item.hasOwnProperty('feature_code') && item['feature_code'] == key && !isKeyFound) {  
					isFind = item.access;	
					// console.log(key + ' - ' + item.access)	
					isKeyFound = true;				
					break;
				} 
				else if(item.sub_features.length > 0 && !isKeyFound) {
					let obj = await this.childAccess(item.sub_features, key);
					isKeyFound = obj['isKeyFound'];
					if(isKeyFound == true) {
						isFind = obj['isFind'];
					}else{
						if(!isKeyFound && obj.subData.length > 0){
							for (const subItems of obj.subData) {
								let subobj = await this.childAccess(subItems, key);
								if(subobj['isFind']){
									return	isFind = true;
								}
							}
							
						}else{
							isFind = false
						}
					}
				}
			}
		} catch (error) {
			
		}
		// console.log( 'return : ' + key + ' - ' + isFind)
		return isFind;
	}
	
	
	childAccess(json, key) {
		let obj = {
			subData : [],
			isFind : false,
			isKeyFound : false,
			key : key
	
		}
		for (const item of json) {
			if(item.hasOwnProperty('feature_code') && item['feature_code'] == key) {  
				// console.log(key + ' - ' + item.access)	
				obj['isFind'] = item.access;	
				obj['isKeyFound'] = true;			
				break;
			} 
			else if(item.sub_features.length > 0 ) {
				obj['subData'].push(item.sub_features)
			}
		}
		return obj;
	}




}
