<form [formGroup]="airlineFilterForm">

  <div class="flexbox aff-info">
    <div class="flexbox aff-info-inner">
      <div class="flexBox d-flex">
        <div class="input-fiel form-group ng-field" searchtabindex="1" (focus)="focusAirlineAc()">
          <label class="ngauto-label" for="account_name">Account</label>
          <div class="ng-autocomplete" id="account_name">
            <ng-autocomplete [data]="airlineArr" #airlineAC [searchKeyword]="keyword"
              (inputChanged)='getAirline($event,false)' historyIdentifier="airlineArr" [itemTemplate]="itemTemplate"
              formControlName="account_name" (selected)="selectAccount($event,'account')"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                                ({{item.account_code}})</span><br />'>
              </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>

        <div class="input-fiel">
          <input class="input-ui" id="account_c" oninput="this.value = this.value.toUpperCase()" type="text"
            searchtabindex="2" formControlName="account_code" placeholder="Account Code" /><label
            for="account_c">Account Code</label>
        </div>
      </div>


      <div class="flexbox mt-3">

        <div class="input-fiel">
          <input class="input-ui" id="p_email" oninput="this.value = this.value.toUpperCase()" type="text"
            searchtabindex="3" formControlName="email" placeholder="Email" /><label for="p_email">Email</label>
        </div>


        <div class="input-fiel">
          <input class="input-ui" id="m_no" type="text" searchtabindex="4" formControlName="phone"
            placeholder="Mobile" /><label for="m_no">Mobile</label>
        </div>

      </div>


      <div class="flexbox mt-3">

        <div class="input-fiel w-100">
          <select id="cuntry" class="input-field w-100" oninput="this.value = this.value.toUpperCase()"
            searchtabindex="5" formControlName="country_id" (change)="getStates($event.target.value)">
            <option value="">All</option>
            <option *ngFor="let country of countryArr" value="{{country.location_id}}">{{country.name}}</option>
          </select>
          <label for="cuntry">Country</label>
        </div>

        <div class="input-fiel w-100">
          <select id="state_n" class="input-field" oninput="this.value = this.value.toUpperCase()" searchtabindex="6"
            formControlName="state_id" (change)="getCities($event.target.value)">
            <option value="">All</option>
            <option *ngFor="let state of stateArr" value="{{state.location_id}}">{{state.name}}</option>
          </select>
          <label for="state_n">State</label>
        </div>


        <div class="input-fiel w-100">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" searchtabindex="7"
            formControlName="city_id" (change)="getCityName($event.target.value)">
            <option value="">All</option>
            <option *ngFor="let city of cityArr" value="{{city.location_id}}">{{city.name}}</option>
          </select>
          <label for="city_n">City</label>
        </div>

      </div>

      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
            searchtabindex="8" #searchButton (keyup.enter)="clickSearchButton()"
            (click)="search(airlineFilterForm.value)"> Search</button>
          <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
        </div>
      </div>

    </div>

  </div>
  <!-- </div> -->
</form>