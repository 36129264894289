<div class="ledger-container ">
    <div class="table-container  btb-tbl" (window:resize)="onResize($event)">
        <div class="table" [ngClass]="{'b2b-report-table': queryParams && queryParams.gst_type !== 'cdnr', 'cdnr-table': queryParams && queryParams.gst_type == 'cdnr'  }" >
            <nz-table class="table  fixed-tbody-height"
                #gstData [nzData]="gstArr && gstArr['data']"
                [nzLoading]="loader"
                [nzFrontPagination]="false"
                
            >
                <thead #tableHeader tabIndex="0">
                    <tr>
                        <th style="width: 56px;" class="text-left gst-no ellipse-text" >GSTIN/UIN</th>
                        <th style="width: 70px;" class="text-left receiver_name ellipse-text">Receiver Name</th>
                        <th style="width: 58px;" class="text-left invoice-no ellipse-text">
                            <ng-container *ngIf="queryParams && queryParams.gst_type == 'cdnr';else invnumber">CRN NO</ng-container>
                            <ng-template #invnumber>
                                INV No.
                            </ng-template>
                        </th>
                        <th style="width: 42px;" class="text-left invoice-date ellipse-text">
                            <ng-container *ngIf="queryParams && queryParams.gst_type == 'cdnr';else invdt">CRN DT</ng-container>
                            <ng-template #invdt>
                                INV DT
                            </ng-template>
                        </th>
                        <th style="width: 40px;" class="text-left reverse-charge ellipse-text" *ngIf="queryParams && queryParams.gst_type == 'cdnr'">Note Type</th>
                        <th style="width: 40px;" class="text-right invoice-value ellipse-text">
                            <ng-container *ngIf="queryParams && queryParams.gst_type == 'cdnr';else invval">NOTE VAL</ng-container>
                            <ng-template #invval>
                                INV VAL
                            </ng-template>
                        </th>
                        <th style="width: 48px;text-transform: capitalize!important;padding-left: 15px!important;" class="text-left supply-place ellipse-text">PoS </th>
                        <th style="width: 24px;" class="text-center reverse-charge ellipse-text">REV CH</th>
                        <th style="width: 38px;" class="text-center tax-rate ellipse-text">HSN</th>
                        <th style="width: 38px;" class="text-center tax-rate ellipse-text">TAX Rate(%)</th>
                        <th style="width: 28px;"  class="text-left invoice-type ellipse-text">INV Type</th>
                        <th style="width: 38px;"  class="text-left e-gst ellipse-text">E-Comm GSTIN</th>
                        <th style="width: 32px;"  class="text-center rate ellipse-text">Rate</th>
                        <th style="width: 40px;"  class="text-right taxable-value ellipse-text text-black ">TAX VAL</th>
                        <th style="width: 28px;"  class="text-right cess-amt ellipse-text">Cess AMT</th>
                    </tr>
                </thead>
                <ng-container *ngIf="gstData && gstData['data'] && gstData['data'].length>0 else noResult">
                    <tbody *ngIf="gstArr != undefined" class="scroll-body main-panel" 
                        (window:keydown)="onKeyDown($event)" (scrolled)="onScrollDown($event)" infiniteScroll
                        (window:keydown)="onKeyDown($event)" [fromRoot]="fromRoot" [infiniteScrollDistance]="1"
                        [infiniteScrollUpDistance]="1" [infiniteScrollThrottle]="50"
                        [infiniteScrollContainer]="infiniteSelector"
                        (scrolledUp)="onScrollUp($event)" 
                        (window:keydown)="onKeyDown($event)" >
                        
                        <tr *ngFor="let data of gstData['data'] let i = index" [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}"
                            (keyup.enter)="openViewMode(data.reference,data.reference_type)" 
                            class="field-table-input custom-table-row"
                            [ngClass]="{'d-none': isError && !(data.place_of_supply=='-' || data.hsn_sac=='')}">
                            <td style="width: 56px;" (click)="moveToIndex(i)" class="text-left gst-no text-grey ellipse-text">{{data?.gst_number}}</td>
                            <td style="width: 70px;"  (click)="moveToIndex(i)" class="text-left text-grey receiver_name ellipse-text">{{data?.receiver_name}}</td>
                            <td style="width: 58px;"  (click)="moveToIndex(i)" class="text-left text-grey invoice-no refer ellipse-text"><a
                                   class="font-500" style="text-decoration: none!important;" [ngClass]="{'text-hyperlink': data.reference != null && data.reference != undefined}"
                                    (click)="openViewMode(data.reference,data.reference_type)">{{data?.reference}}</a></td>
                            <td style="width: 42px;"  (click)="moveToIndex(i)" class="text-left text-grey invoice-date ellipse-text">{{data?.invoice_date | date :
                                'dd-MMM-yyyy'}}</td>
                            <td style="width: 40px;"  (click)="moveToIndex(i)" class="text-left text-grey reverse-charge ellipse-text" *ngIf="queryParams && queryParams.gst_type == 'cdnr'">{{data?.note_type}}</td>
                            <td style="width: 40px;"  (click)="moveToIndex(i)" class="text-right text-grey invoice-value ellipse-text">{{data?.invoice_value | number : '1.2-2'}}</td>
                            <td style="width: 48px; padding-left: 15px!important;"  (click)="moveToIndex(i)" [ngClass]="data.place_of_supply=='-'?'error_table_row':''" class="text-left text-grey supply-place ellipse-text">{{data?.place_of_supply}}</td>
                            <td style="width: 26px;"  (click)="moveToIndex(i)" class="text-center text-grey reverse-charge ellipse-text">{{data?.reverse_charge}}</td>
                            <td style="width: 38px;"  (click)="moveToIndex(i)" [ngClass]="data.hsn_sac==''?'error_table_row':''" class="text-center text-grey tax-rate ellipse-text">{{data?.hsn_sac}}</td>
                            <td style="width: 38px;"  (click)="moveToIndex(i)" class="text-center text-grey tax-rate ellipse-text">{{data?.c_gst_rate_sc}}</td>
                            <td style="width: 28px;"  (click)="moveToIndex(i)" class="text-left text-grey invoice-type ellipse-text">{{data?.invoice_type}}</td>
                            <td style="width: 38px;"  (click)="moveToIndex(i)" class="text-left text-grey e-gst ellipse-text">{{data?.e_commerce_gstin}}</td>
                            <td style="width: 32px;"  (click)="moveToIndex(i)" class="text-center rate ellipse-text">{{data?.rate}}</td>
                            <td style="width: 40px;"  (click)="moveToIndex(i)" class="text-right text-black taxable-value ellipse-text font-500">{{data?.sum | number : '1.2-2'}}</td>
                            <td style="width: 28px;"  (click)="moveToIndex(i)" class="text-right text-black font-500 cess-amt ellipse-text">{{data?.cess_amount | number : '1.2-2'}}</td>
                        </tr>
                    </tbody>
                    <tfoot *ngIf="gstArr && gstArr['summary']" class="table-tfoot-bg">
                        <tr>
                            <td style="width: 56px;" class=" gst-no ">
                                <span  class="font-500">Total rec.: </span> 
                            </td>
                            <td style="width: 70px;" class="receiver_name"><span class="text-right font-500" >{{gstArr['summary']?.receiver_count }}</span> </td>
                            <td style="width: 58px;"  class="invoice-no font-500"><span class="text-right font-500">{{gstArr['summary']?.total_record }}</span></td>
                            <td style="width: 42px;"  class="invoice-date"></td>
                            <td style="width: 40px;"  class="reverse-charge font-500" *ngIf="queryParams && queryParams.gst_type == 'cdnr'"></td>
                            <td style="width: 40px;"  class="text-right grnd-total  invoice-value font-500">{{gstArr['summary']?.invoice_value  | number : '1.2-2'}}</td>
                            <td style="width: 48px;padding-left: 15px!important;"  class="supply-place"></td>
                            <td style="width: 24px;"  class="reverse-charge"></td>
                            <td style="width: 38px;"  class="tax-rate"></td>
                            <td style="width: 28px;"  class="invoice-type"></td>
                            <td style="width: 38px;"  class="e-gst"></td>
                            <td style="width: 32px;"  class="rate"></td>
                            <td style="width: 40px;"  class="taxable-value  grnd-total text-right">{{gstArr['summary']?.taxable_amount  | number : '1.2-2'}}</td>
                            <td style="width: 28px;"  colspan="2" class="cess-amt grnd-total text-right">{{gstArr['summary']?.cess_amount  | number : '1.2-2'}}</td>
                        </tr>
                    </tfoot>
                </ng-container>
                <ng-template #noResult>
                    <div *ngIf="!loader" class="w-100 text-center" style="padding: 20px;">
                        <nz-empty nzNotFoundContent="No result Found"></nz-empty>
                    </div>
                </ng-template>
               
            </nz-table>
        </div>
    </div>

</div>