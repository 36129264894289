import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalSearchRoutingModule } from './global-search-routing.module';
import { GlobalSearchComponent } from './global-search.component';
import { InvoiceSearchComponent } from './invoice-search/invoice-search.component';
import { SharedModule } from '../shared.module';
import { ClientSearchComponent } from './client-search/client-search.component';
import { PaymentSearchComponent } from './payment-search/payment-search.component';
import { AirlineSearchComponent } from './airline-search/airline-search.component';
import { ReceiptSearchComponent } from './receipt-search/receipt-search.component';
import { SupplierSearchComponent } from './supplier-search/supplier-search.component';
import { GeneralSearchComponent } from './general-search/general-search.component';
import { EmployeeSearchComponent } from './employee-search/employee-search.component';
import { LedgerSearchComponent } from './ledger-search/ledger-search.component';
import { AccountSearchComponent } from './account-search/account-search.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CreditNoteSearchComponent } from './credit-note-search/credit-note-search.component';
import { JvSearchComponent } from './jv-search/jv-search.component';
import { OutstandingSearchComponent } from './outstanding-search/outstanding-search.component';
import { DayBookSearchComponent } from './day-book-search/day-book-search.component';
import { GstReportSearchComponent } from './gst-report-search/gst-report-search.component';
import { TdsReportSearchComponent } from './tds-report-search/tds-report-search.component';
import { TcsReportSearchComponent } from './tcs-report-search/tcs-report-search.component';
import { ReconciliationSearchComponent } from './reconciliation-search/reconciliation-search.component';
import { SearchTabDirective } from '../directive/search-tab.directive';
import { InvoiceCreditNoteSearchComponent } from './invoice-credit-note-search/invoice-credit-note-search.component';
import { RoleSearchComponent } from './role-search/role-search.component';
import { BankingSearchComponent } from './banking-search/banking-search.component';
import { FlownReportSearchComponent } from './flown-report-search/flown-report-search.component';
import { AdmAcmSearchComponent } from './adm-acm-search/adm-acm-search.component';
import { UnpaidReportSearchComponent } from './unpaid-report-search/unpaid-report-search.component';
import { PsrReportSearchComponent } from './psr-report-search/psr-report-search.component';
import { TrialBalanceSearchComponent } from './trial-balance-search/trial-balance-search.component';
import { MissingTransactionsSearchComponent } from './missing-transactions-search/missing-transactions-search.component';
import { EmailStatusSearchComponent } from './email-status-search/email-status-search.component';
import { BalanceSheetSearchComponent } from './balance-sheet-search/balance-sheet-search.component';
import { ProfitLossSearchComponent } from './profit-loss-search/profit-loss-search.component';
import { TicketStatementComponent } from './ticket-statement/ticket-statement.component';
import { BillWiseSearchComponent } from './bill-wise-search/bill-wise-search.component';
import { AgingReportSearchComponent } from './aging-report-search/aging-report-search.component';
import { EInvoiceSearchComponent } from './e-invoice-search/e-invoice-search.component';
import { EInvListSearchComponent } from './e-inv-list-search/e-inv-list-search.component';
import { B2cAdjustmentFilterComponent } from './b2c-adjustment-filter/b2c-adjustment-filter.component';
import { MisReportSearchComponent } from './mis-report-search/mis-report-search.component';
import { SalesReportSearchComponent } from './sales-report-search/sales-report-search.component';
import { ClientSalesReportSearchComponent } from './client-sales-report-search/client-sales-report-search.component';
import { HistoryLedgerSearchComponent } from './history-ledger-search/history-ledger-search.component';
import { FinancialReportSearchComponent } from './financial-report-search/financial-report-search.component';
import { NzAutocompleteModule } from 'ng-zorro-antd';
import { AirlinePlbReportComponent } from './airline-plb-report/airline-plb-report-search.component';
import { VerticalPNLSearchComponent } from './vertical-pnl/vertical-pnl-search.component';

@NgModule({
  declarations: [GlobalSearchComponent, InvoiceSearchComponent, ClientSearchComponent, PaymentSearchComponent, AirlineSearchComponent, ReceiptSearchComponent, SupplierSearchComponent, GeneralSearchComponent, EmployeeSearchComponent, LedgerSearchComponent, AccountSearchComponent, CreditNoteSearchComponent, JvSearchComponent, OutstandingSearchComponent, DayBookSearchComponent, GstReportSearchComponent,TcsReportSearchComponent, TdsReportSearchComponent, ReconciliationSearchComponent,SearchTabDirective, InvoiceCreditNoteSearchComponent, RoleSearchComponent, BankingSearchComponent, FlownReportSearchComponent, AdmAcmSearchComponent, UnpaidReportSearchComponent, PsrReportSearchComponent, TrialBalanceSearchComponent,MissingTransactionsSearchComponent,SalesReportSearchComponent, EmailStatusSearchComponent, ProfitLossSearchComponent, BalanceSheetSearchComponent, TicketStatementComponent, BillWiseSearchComponent,AgingReportSearchComponent, EInvoiceSearchComponent,EInvListSearchComponent, B2cAdjustmentFilterComponent, MisReportSearchComponent,ClientSalesReportSearchComponent, HistoryLedgerSearchComponent,FinancialReportSearchComponent, AirlinePlbReportComponent,VerticalPNLSearchComponent],
  imports: [
    CommonModule,
    // SharedModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    AutocompleteLibModule,
    BsDatepickerModule.forRoot(),
    NzAutocompleteModule,
  ],
  exports : [GlobalSearchComponent, InvoiceSearchComponent, ClientSearchComponent, PaymentSearchComponent, AirlineSearchComponent, ReceiptSearchComponent, SupplierSearchComponent, GeneralSearchComponent, EmployeeSearchComponent, LedgerSearchComponent, AccountSearchComponent, CreditNoteSearchComponent, JvSearchComponent, OutstandingSearchComponent , DayBookSearchComponent, GstReportSearchComponent,TcsReportSearchComponent, TdsReportSearchComponent ,ReconciliationSearchComponent,InvoiceCreditNoteSearchComponent , RoleSearchComponent, BankingSearchComponent,FlownReportSearchComponent,AdmAcmSearchComponent,UnpaidReportSearchComponent, PsrReportSearchComponent,SearchTabDirective,TrialBalanceSearchComponent,MissingTransactionsSearchComponent,SalesReportSearchComponent,EmailStatusSearchComponent,ProfitLossSearchComponent,BalanceSheetSearchComponent, TicketStatementComponent, BillWiseSearchComponent,AgingReportSearchComponent, EInvoiceSearchComponent,EInvListSearchComponent, B2cAdjustmentFilterComponent, MisReportSearchComponent,ClientSalesReportSearchComponent, HistoryLedgerSearchComponent,FinancialReportSearchComponent, AirlinePlbReportComponent,VerticalPNLSearchComponent]
})
export class GlobalSearchModule { }
