<form [formGroup]="ledgerFilterForm">
    <div class="flexbox aff-info">
      <div class="flexbox aff-info-inner">
        <div class="flexbox ">
        </div>

        <div class="flexbox mb-3">

            <div class="input-fiel">
                <input class="input-ui" formControlName="start_date" id="from_date" type="date"
                    [ngClass]="{'error-box': !ledgerFilterForm.controls.start_date.valid}"
                    searchtabindex="1"   />
                <label for="from_date">Starting Date</label>
            </div>
            <div class="input-fiel">
                <input class="input-ui" formControlName="end_date" id="to_date" type="date" searchtabindex="2" 
                [ngClass]="{'error-box': !ledgerFilterForm.controls.end_date.valid}"
                      />
                <label for="to_date">Ending Date</label>
            </div>

        </div>


        <div class="flexbox mb-3">

            <div class="input-fiel">
                <input class="input-ui" formControlName="credit_note_date" id="credit_note_date" type="date" 
                    searchtabindex="3" [ngClass]="{'error-box': !ledgerFilterForm.controls.credit_note_date.valid}"/>
                <label for="from_date">Credit Note</label>
            </div>
            <div class="input-fiel">
                <input class="input-ui" formControlName="pay_rec_date" id="pay_rec_date" type="date" 
                    searchtabindex="4" [ngClass]="{'error-box': !ledgerFilterForm.controls.pay_rec_date.valid}" />
                <label for="to_date">Pay || Rec</label>
            </div>

        </div>

        <div class="flexbox">
            <div class="select-field text-input-fiel">
                <select class="input-field" searchtabindex="5" formControlName="account_type" (change)="clearGroup($event,'group')">
                    <option value="none">None</option>
                    <option value="client">Client</option>
                    <option value="airline">Airline</option>
                    <option value="supplier">Supplier</option>
                    <option value="general">General</option>
                </select>
                <label for="subLedger">Account Type</label>

            </div>

            <div class="select-field text-input-fiel" *ngIf="ledgerFilterForm.value.account_type=='client'">
                <select class="input-field" searchtabindex="6" formControlName="group" (change)="updateViewMode($event)" >
                    
                    <ng-container *ngFor="let item of allGroupArr let i=index;">

                        <option value="" *ngIf="i==0">NONE</option>
                       <option [value]="item.id">{{item.client_group_name}}</option>
                    </ng-container>
                </select>
                <label for="group">Client Group</label>
            </div>
            
            <div class="input-fiel form-group ng-field" searchtabindex="7" (focus)="focusAccountAc()">
                <label class="ngauto-label" for="affname">Account</label>
                <div class="ng-autocomplete">
                    <ng-autocomplete [data]="clientArr" #accountAC (inputCleared)="clearData('client')"
                        [searchKeyword]="keyword" (inputChanged)='getClient($event,false)' 

                        historyIdentifier="clientArr" [itemTemplate]="itemTemplate" formControlName="client_name"
                        (selected)="selectAccount($event,'client')" [notFoundTemplate]="notFoundTemplate">

                    </ng-autocomplete>
                    <ng-template #itemTemplate let-item>
                        <a innerHTML='<span class="colr-1">{{item.name}}
                            ({{item.account_code}})</span><br />
                        <span class="colr-2">{{item.primary_email}}</span><br />'>
                        </a>
                    </ng-template>
                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
            </div>
        </div>

        <div class="alert-message-container mr-3  mb-3" *ngIf="alertMessage">
            <div class="alert-message">
              {{ alertMessage }}
            </div>
          </div>

        <div class="d-flex mt-5 justify-content-center">
          <div class="input-fiel">
            <button type="button" class="btn-style bg-purple mr-3" (click)="downloadExcel()">EXCEL</button>
            <button type="button" class="btn-style bg-green" (click)="downloadPdf()">PDF</button>
          </div>
        </div>
      </div>
    </div>
  </form>