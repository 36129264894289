import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Http , Headers} from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ProformaService {

	accountList= [];
	ledgerList = [];
	updateJvForm: any;

  constructor(private http: Http,private httpClient: HttpClient) { }

  getMethod(url){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.get(`${environment.okountUrl}/${url}`, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}

	searchAccount(url) {
		let token = localStorage.getItem('token');
		const headers = new HttpHeaders({
			'Authorization': token
		});
		return this.httpClient.get(`${environment.okountUrl}/${url}`)
			.pipe(map(res => {
				// console.log(res);
				return res;
				// console.log('Res data', res);
			}),
				catchError((err) => {
					return throwError(err);
				}))
	}

	createMsProduct(data,services) {
		let products = []
		let netAmount = 0
		
		let igst_amount = data.igst_amount;
		let cgst_amount = data.cgst_amount;
		let sgst_amount = data.sgst_amount;

		for (const service of services) {
		  let productObj =  {
			transaction_method: data.transaction_method ? data.transaction_method : "sales",
			reference_number: data.reference_number ? data.reference_number : '',
	  
			client_code: data.client_code ? data.client_code : "",
			client_code_name: data.client_code_name ? data.client_code_name.name ? data.client_code_name.name : data.client_code_name : "",
	  
			invoice_date: data.invoice_date ? moment(data.invoice_date).format('YYYY-MM-DDTHH:MM') : "",
			invoice_number: data.invoice_number ? data.invoice_number : "",
	  
			description: service.description ? service.description : '',
			rate: service.rate ? service.rate : 0,
			quantity: service.quantity ? service.quantity : 0,
			service_charge: service.service_charge ? service.service_charge : 0,
			gst_on: service.gst_on ? service.gst_on : 0,
			gst_rate: service.gst_rate ? service.gst_rate : 0,
			igst: service.igst ? service.igst : 0,
			sgst: service.sgst ? service.sgst : 0,
			cgst: service.cgst ? service.cgst : 0,
			amount: service.amount ? service.amount : 0,
			
			employee_code: data.employee_code ? data.employee_code : '',
			employee_code_name: data.employee_code_name ? data.employee_code_name : '',
		  };
		  netAmount += Number(service.amount)
		  products.push(productObj)
		}

		let invoiceObj = {
			"affiliate_code": data.client_code,
			"name": data.client_code_name ? data.client_code_name.name ? data.client_code_name.name : data.client_code_name : "",
			"invoice_uuid": data.invoice_number,
			"invoice_date": data.invoice_date,
			"created_by": data.employee_code_name,
			"updated_by": data.employee_code_name,
			"narration_1": data.narration_1,
			"narration_2": data.narration_2,
			"narration_3": data.narration_3,
			"narration_4": data.narration_4,
			"narration_5": data.narration_5,
			"remark": data.remark,
			"reference_number": data.reference_number,
			"total": Math.round(data.total),
			"total_payable": Math.round(data.total_payable),
			"gst_on": data.gst_on,
			"gst_rate": data.gst_rate,
			"igst_amount": igst_amount,
			"cgst_amount": cgst_amount,
			"sgst_amount": sgst_amount,
			"products": products,
			"product_type": 'proforma',
			"status": data.status? data.status:"Active",
			"transaction_method":  data.transaction_method ? data.transaction_method : "sales",
			"discount": data.discount,
			"service_charge": data.service_charge,
			"tcs_on": data.tcs_on,
			"tcs_rate": data.tcs_rate,
			"tcs_amount": data.tcs_amount,
			"grand_total": data.grand_total,
		  }
		  
		  return invoiceObj;

	}

}
