<div class="ledger-container" *ngIf="valid">
    <div class="voucher-container">

        <!-- <div class="tab-nav d-flex align-item-center justify-content-between">
            <ul class="list-inline list-unstyled p-0 m-0">
                <li class="active page-title text-uppercase cursor"> Un-Paid Report</li>
            </ul>
            <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
                <li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition">
                    Dashboard</li>
                <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
                <li class="text-grey text-capitalize cursor text-primary transition"> View Un-Paid Report</li>
            </ul>
        </div> -->
        <div class="row tab-nav pl-0 pr-0">
            <div class="col-sm-4">
                <ul
                  class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0"
                >
                  <li
                    [routerLink]="['/']"
                    class="text-grey text-capitalize cursor hover-text-primary transition"
                    [ngClass]="{ 'd-none': isPrint == 'true' }"
                  >
                    Analysis
                  </li>
                  <li>
                    <img
                      src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg"
                      alt=""
                      [ngClass]="{ 'd-none': isPrint == 'true' }"
                    />
                  </li>
                  <li
                    class="text-grey text-capitalize cursor text-primary transition"
                    [ngClass]="{ 'd-none': isPrint == 'false' }"
                    [ngClass]="{ 'd-none': isPrint == 'true' }"
                  >
                  Un-paid Report -
                  </li>
                  <!-- <li class="text-primary line-clamp line-clamp-1">
                    <ng-container
                      *ngFor="let data of leaderData['all_clients_records']"
                    >
                      <span class="text-bold">
                        {{ data["display_name"] }}
                      </span>
                    </ng-container>
                  </li> -->
                </ul>
              </div>
            <div class="col-sm-4 text-center">
                <span class="rec-date d-block text-center">
                    <div class="noWrap justify-content-around">
                        <ng-container *ngIf="duration != undefined">
                            <div class="cname">From
                                <h5 style="margin-right: 5px;" class="mb-0  d-inline"><span class="date text-bold text-orange"
                                        *ngIf="duration.from_date != ''">
                                        {{duration?.from_date | date : 'd MMMM, y'}} 
                                    </span>
                                    <span class="date text-bold" *ngIf="duration.from_date != ''">
                                        To
                                    </span>
                                    <span class="date text-bold" *ngIf="duration.from_date == ''">
                                        Till Date
                                    </span>
                                    <span class="date text-bold text-orange">
                                        {{duration?.to_date | date : 'd MMMM, y'}}
                                    </span>
                                </h5>
                                <button title="Custom Search" class="btn   save-as text-bold bg-whiteSmoke "
                type="button" >
                <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
            </button>
                            </div>
                        </ng-container>								
                    </div>
                </span>
            </div>
            <div class="col-sm-4 d-flex align-item-center justify-content-end">
                <div
                class="page-search-box input-group d-flex align-item-center" 
          [ngClass]="{ 'd-none': isPrint == 'true' }"
                style="height: fit-content; text-align: left"
              >
                <input
                  type="text"
                  class="form-control"
                  oninput="this.value = this.value.toUpperCase()"
                  placeholder="search"
                  style="text-align: left"
                />
              </div>
               
                <div class="dropdown-btn position-relative" 
                [ngClass]="{ 'd-none': isPrint == 'true' }">
                  <button title="More..." class="btn-style mt-0 more-btn">
                    <span class="dot"></span><span class="dot"></span
                    ><span class="dot"></span>
                  </button>
                  <ul class="list-unstyled pl-0 dropdown-box">
                    
                    <li (click)="callPdfGeneratorApi()" >
                      <img
                        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg"
                        alt=""
                      />
                      <span class="ml-2">Save As Pdf</span>
                    </li>
                  </ul>
                </div>
              </div>
        </div>
        <div class="page-sub-header row w-100 mb-1 justify-content-between mt-3">
            <div class="col-sm-3">
                <span class="title text-bold">{{companyDetail?.name}}</span><br>
                <div class="pl-15">
                    <span class="address_line">{{companyDetail?.address_1}}
                        {{companyDetail?.address_2}} {{companyDetail?.address_3}}
                    </span>
                </div>
            </div>
            <div class="col-sm-6">
                <!-- <span class="rec-date text-center">
                    <div class="noWrap">
                        <ng-container *ngIf="duration != undefined">
                            <span class="cname">For the period
                                <span class="date text-bold text-orange" *ngIf="duration.from_date != ''">
                                    {{duration?.from_date | date : 'd MMMM, y'}} To
                                </span>
                                <span class="date" *ngIf="duration.from_date == ''">
                                    Till Date
                                </span>
                                <span class="date text-bold text-orange">
                                    {{duration?.to_date | date : 'd MMMM, y'}}
                                </span>
                            </span>
                        </ng-container>
                    </div>
                </span> -->
            </div>
            <!-- <div class="col-sm-3 pr-0">
                <span class="save-button d-flex justify-content-end">
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-download.svg" alt="">
                            <span class="ml-1 text-green">Save As</span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" (click)="exportAsXLSX()">EXCEL</a>
                            <a class="dropdown-item" (click)="callPdfGeneratorApi()">PDF</a>
                        </div>
                    </div>
                </span>
            </div> -->

        </div>



    </div>
    <div class="table-section mt-1 mb-5">
        <table class="table table striped">
            <thead>
                <tr #tableHeader tabIndex="0">
                    <th class="text-left"></th>
                    <th class="text-right">Amount Dr.</th>
                    <th class="text-right">Amount Cr.</th>
                    <th class="text-right">Balance</th>
                </tr>
            </thead>
            <tbody class="scroll-body main-panel">
                <ng-container *ngFor="let invoice of invoiceArr ; let i = index;">
                    <tr class="field-table-input" [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}">
                        <td class="text-left" (click)="moveToIndex(i)"> {{invoice?.account_name}}</td>
                        <td class="text-right" (click)="moveToIndex(i)">{{invoice?.debit_amount}}</td>
                        <td class="text-right" (click)="moveToIndex(i)">{{invoice?.credit_amount}}</td>
                        <td class="text-right" (click)="moveToIndex(i)">{{invoice?.balance}}</td>
                    </tr>
                </ng-container>
                <tr class="field-table-input" *ngIf="totalAmountObj != undefined">
                    <td class="grand text-right">
                    </td>
                    <td class="grand text-right">
                        {{totalAmountObj?.debit_amount | currency:'INR':'symbol':'1.2-2'}}
                    </td>
                    <td class="grand text-right">
                        {{totalAmountObj?.credit_amount | currency:'INR':'symbol':'1.2-2'}}
                    </td>
                    <td class="grand text-right">
                        {{totalAmountObj?.balance | currency:'INR':'symbol':'1.2-2'}}
                    </td>
                </tr>
                <tr *ngIf="invoiceArr.length == 0 ">
                    <div class="w-100 text-center" style="padding: 20px;">
                        <span><img class="not-found-img" src="{{commonService.aws_asset_url}}/_assets/images/problem.png"></span> <br>
                        <span class="not-found-text">No Matching Items Found</span>
                    </div>
                </tr>
            </tbody>
        </table>
    </div>
</div>