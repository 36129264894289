		<div class="flexbox ml-4">
			<input type="text" hidden tabindex="1">
		  <p> Do you want to Logout?</p>
		</div>

		<div class="d-flex mt-2 justify-content-center ">
		  <div class="input-fiel">
			  <button type="button" class="btn-style" tabIndex="2" attr.tabIndex="2" data-dismiss="modal">No</button>
			  <button type="button" class="btn-style bg-red ml-3" tabIndex="1" attr.tabIndex="1" data-dismiss="modal" (click)="logout()"> Yes</button>
		  </div>
		</div>
