<form [formGroup]="sectorService.sectorForm">
	<div class="sector-section">
		<span class="section-head">Sector Information</span>

		<div class="segment-row mb-3">
			<div class="segment-col">
				<input oninput="this.value = this.value.toUpperCase()" class="main-input input-ui w-100" type="text" disabled placeholder="___/___/___/___/___" formControlName="txtSector1"
					autocomplete="off" (keyup)="sectorService.getSector1($event)" (change)="sectorService.bindSector1($event.target.value)" 
					maxlength="19" />
				<label>Sector 1</label>
			</div>

			<div class="segment-col ml-3">
				<input oninput="this.value = this.value.toUpperCase()" formControlName="txtSector2" class="main-input input-ui w-100" type="text" disabled placeholder="___/___/___/___/___" />
				<label>Sector 2</label>
			</div>
		</div>

	</div>

	<div class="sector-generated">
		<table class="table table-hovered table-bordered">
			<thead>
				<tr>
					<th>Sector</th>
					<th>Airline PNR</th>
					<th>AL/PF</th>
					<th>FLT NO.</th>
					<th>CL</th>
					<th>DEP. DATE TIME</th>
					<th>ARR. DATE TIME</th>
					<th>DEP. Airport</th>
					<th>Arr. Airport</th>
					<th>Baggage</th>
				</tr>
			</thead>

			<tbody>
				<ng-container formArrayName="sectorlist"
					*ngFor="let sectorlist of sectorService.DynamicFormControls.controls; let i = index ">
					<tr [formGroupName]="i">
						<td>
							{{sectorlist.value.txtSectorComb}}
						</td>
						<td>
							{{sectorlist.value.txtAirlinePnrX}}
						</td>
						<td>
							{{sectorlist.value.txtAirlinePrefix}}
						</td>
						<td>
							{{sectorlist.value.txtFlightNo}}
						</td>
						<td>
							{{sectorlist.value.txtClass}}
						</td>
						<td>
							{{sectorlist.value.dateAirlineDepartInfo=='Invalid date'?'':sectorlist.value.dateAirlineDepartInfo | date : 'dd/MM/yyyy HH:mm' }}
						</td>
						<td>
							{{sectorlist.value.dateAirlineArrivalInfo=='Invalid date'?'':sectorlist.value.dateAirlineArrivalInfo  | date : 'dd/MM/yyyy HH:mm' }}
						</td>
						<td>
							{{sectorlist.value.departureCity}}, {{sectorlist.value.departureCityCode}}
						</td>
						<td>
							{{sectorlist.value.arrivalCity}}, {{sectorlist.value.arrivalCityCode}}
						</td>
						
					
						<td>
							{{sectorlist.value.txtBaggage}}
						</td>
					</tr>
				</ng-container>
			</tbody>
		</table>
	</div>
</form>