<!-- <div class="message-wrapper" *ngIf="showMessage">
	<div class="update-info animated fadeInDown {{alertType}}">
		<div class="container">
			<div class="row">
				<div class="col-11">
					<h5>
						{{message}} <a *ngIf="submitResponse[0] != undefined" class="invoice_no ml-2"
							[routerLink]="['/transactions/receipt/update', submitResponse[0].receipt_id]">{{submitResponse[0].receipt_code}}</a>
						.
					</h5>
				</div>
				<div class="col-1">
					<button type="button" class="close" (click)="hideError()" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="ledger-container pb-20 ">
	<ng-container>
		<div class="row tab-nav pl-0 pr-0">
			<div class="col-sm-4 ">
			  <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
				  <li [routerLink]="['/']"
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> Banking</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li [routerLink]="['/']"
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> Receipt</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li class="text-grey text-capitalize cursor text-primary transition"> Create</li>
			  </ul>
		  </div>
			
		</div>
		<div class="custom-form-container text-left">
			<form [formGroup]="receiptService.receiptForm">
				<div style="width: 600px;" class="custom-form">

					<div class="text-field-group d-flex align-items-center">
						<div class="select-field text-input-field mr-3">
							<select class="input-field" formControlName="accountCodeTo" tabIndex="1">
								<option value="">Select</option>
								<ng-container *ngFor="let item of generalMasterList">
									<option *ngIf="item.account_code != ''" value="{{item.account_code}}">{{item.name}}
									</option>
								</ng-container>
							</select>
							<label>Cash / Bank</label>
						</div>


						<div class="text-input-field">
							<input oninput="this.value = this.value.toUpperCase()" tabIndex="2" class="input-field"
								type="date" formControlName="receiptDate" autocomplete="off" />
							<label>Generated Date</label>
						</div>

					</div>
					<div class="text-field-group d-flex align-items-center"
						*ngIf="receiptService.receiptEntries.length > 0">
						<div class="select-field text-input-field mr-3">
							<input oninput="this.value = this.value.toUpperCase()" class="input-field" type="text"
								readonly formControlName="receipt_id" />
							<label>Receipt Receipt Code</label>
						</div>

						<div class="text-input-field">
							<input oninput="this.value = this.value.toUpperCase()" class="input-field" type="text"
								readonly formControlName="amount" />
							<label>Amount</label>
						</div>
					</div>
					<span class="info alert-warning" *ngIf="receiptService.receiptEntries.length == 0">* To Save
						Receipt,
						you must add atleast 1 entry.</span>






				</div>
				<div class="container-fluid p-0 mt-5"
					*ngIf="receiptService.receiptEntries != undefined && receiptService.receiptEntries.length > 0">
					<div class="">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>Code</th>
									<th>Sub Ledger</th>
									<th>Account</th>
									<th>Cheque</th>
									<th>Amount</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let receipt of receiptService.receiptEntries">
									<td>{{receipt.account_code_from}}</td>
									<td>{{receipt.sub_ledger_code_1}}</td>
									<td>{{receipt?.account_code_from_value}}</td>
									<td>{{receipt.cheque_no}}</td>
									<td>{{receipt.amount | number : '1.2-2'}}</td>
									<td class="delete-row" (click)="editEntry(receipt)">
										<i class="fa fa-edit"></i>
									</td>
								</tr>
								<tr>
									<td colspan="3"></td>
									<td class="grand">Total Amount</td>
									<td class="grand">₹ {{receiptService.receiptForm.value.amount}}</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="action-box d-flex justify-content-center mt-3 mb-4" style="width: 600px;">
					<button type="button" class="btn-style bg-green mr-3" tabIndex="4" #saveButton
						(keyup.enter)="submit()" (click)="submit()">Save</button>
					<button type="button" class="btn-style bg-blue mr-3"
						[disabled]="receiptService.receiptForm.value.receiptDate == '' || receiptService.receiptForm.value.receiptDate == null || receiptService.receiptForm.controls.accountCodeTo.invalid"
						[ngClass]="{disbaledBtn: receiptService.receiptForm.value.receiptDate == '' || receiptService.receiptForm.value.receiptDate == null ||receiptService.receiptForm.controls.accountCodeTo.invalid}"
						(click)="addRecord(true,receiptService.receiptForm.value)" tabIndex="3">Add Entry</button>
					<button type="button" class="btn-style bg-red" (click)="reset()">Reset</button>
				</div>


			</form>
			<div style="margin-left: -15px;">
				<app-add-entry-receipt [isUpdate]="false" (callExitEntry)="exitEntry($event)"></app-add-entry-receipt>
				<app-v2-create-receipt></app-v2-create-receipt>
			</div>


		</div>

	</ng-container>


</div> -->


<app-v2-create-receipt></app-v2-create-receipt>