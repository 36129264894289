import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PurchaseInvoiceComponent } from './purchase-invoice.component';
import { AddEntryPurchaseInvoiceComponent } from './add-entry-purchase-invoice/add-entry-purchase-invoice.component';
import { PurchaseInvoiceRoutingModule } from './purchase-invoice-routing.module';


@NgModule({
  declarations: [PurchaseInvoiceComponent, AddEntryPurchaseInvoiceComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    PurchaseInvoiceRoutingModule,
    ReactiveFormsModule
  ],
  providers : [],
  exports: [AddEntryPurchaseInvoiceComponent]
})
export class PurchaseInvoiceModule { }
