<form [formGroup]="paymentFilterForm">

  <div class="flexbox aff-info">
    <div class="flexbox aff-info-inner">

      <div class="flexbox">
        <div class="input-fiel form-group ng-field" searchtabindex="1" (focus)="focusAirlineAc()">
          <label class="ngauto-label" for="affname">From Account</label>
          <div class="ng-autocomplete" id="affname">
            <ng-autocomplete [data]="fromAccount" #airlineAC [searchKeyword]="keyword"
              (inputChanged)='getFromAccount($event,false)' historyIdentifier="fromAccount"
              [itemTemplate]="itemTemplate" formControlName="from_account_name" (inputCleared)="clearData('from_account')"
              (selected)="selectAccount($event,'from_account')" [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                                ({{item.account_code}})</span><br />'>
              </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>

        <div class="input-fiel form-group ng-field" searchtabindex="2" (focus)="focustoAccountAc()">
          <label class="ngauto-label" for="affnametwo">To Account</label>
          <div class="ng-autocomplete" id="affnametwo">
            <ng-autocomplete [data]="toAccount" [searchKeyword]="keyword" #toaccountAC
              (inputChanged)='getToAccount($event,false)' historyIdentifier="toAccount" [itemTemplate]="itemTemplate"
              formControlName="to_account_name" (selected)="selectAccount($event,'to_account')" (inputCleared)="clearData('to_account')"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                                ({{item.account_code}})</span><br />'>
              </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="flexbox mt-3">
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="3" formControlName="start_date" id="date" type="date"
              min="{{minDate}}" max="{{maxDate}}"
              [ngClass]="{'error-box': !paymentFilterForm.controls.start_date.valid}"
              style="width: 140px;" />
          <label for="date">From Date</label>
        </div>

        <div class="input-fiel">
          <input class="input-ui" searchtabindex="4" formControlName="end_date" id="date" type="date" 
              min="{{minDate}}" max="{{maxDate}}"
              [ngClass]="{'error-box': !paymentFilterForm.controls.end_date.valid}"
             style="width: 140px;" />
          <label for="date">To Date</label>
        </div>

        <div class="input-fiel">
          <input class="input-ui" id="paymentNo" oninput="this.value = this.value.toUpperCase()" type="text"
            searchtabindex="5" formControlName="payment_no" placeholder="Payment Number" /><label
            for="paymentNo">Payment No.</label>
        </div>


      </div>


      <div class="flexBox d-flex mt-3">
        <div class="input-fiel">
          <input class="input-ui" id="uidnarration" oninput="this.value = this.value.toUpperCase()" type="text"
            searchtabindex="6" formControlName="narration" placeholder="Narration" /><label
            for="uidnarration">Narration</label>
        </div>


        <div class="input-fiel form-group ng-field" searchtabindex="7" (focus)="focusSubledgerAc()">
          <label class="ngauto-label" for="affname">Sub Ledger</label>
          <div class="ng-autocomplete">
            <ng-autocomplete [data]="subLedger" #subledgerAC [searchKeyword]="keyword"
              (inputChanged)='getSubLedgerAccount($event,false)' historyIdentifier="subLedger"
              [itemTemplate]="itemTemplate" formControlName="sub_ledger_name"
              (selected)="selectAccount($event,'sub_ledger')" [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                                ({{item.account_code}})</span><br />'>
              </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
      </div>


      <div class="flexbox mt-3">

        <div class="input-fiel">
          <input class="input-ui" id="uidFracc" type="number" searchtabindex="8" formControlName="from_amount"
            placeholder="From Account " /><label for="uidFracc">From Amount</label>
        </div>


        <div class="input-fiel">
          <input class="input-ui" id="uidToacc" type="number" searchtabindex="9" formControlName="to_amount"
            placeholder="To Account" /><label for="uidToacc">To Amount</label>
        </div>

      </div>

      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style mr-3 bg-purple" data-dismiss="modal"
            searchtabindex="10" #searchButton (keyup.enter)="clickSearchButton()"
            [disabled]="paymentFilterForm.invalid"
            (click)="search(paymentFilterForm.value)"> Search</button>
          <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
        </div>
      </div>

    </div>

  </div>
</form>