import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormArray, Validators, NgForm, AbstractControl, FormGroup } from '@angular/forms';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { FormCanDeactivate } from 'src/app/shared/form-can-deactivate/form-can-deactivate';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { ProformaService } from '../services/proforma.service';

@Component({
  selector: 'app-add-entry-proforma',
  templateUrl: './add-entry-proforma.component.html',
  styleUrls: ['./add-entry-proforma.component.scss']
})
export class AddEntryProformaComponent extends FormCanDeactivate  implements OnInit {

  @Input() data
	@ViewChild('form')
 	form: NgForm;
	invoiceData: any;
	pInvoiceArray: FormArray
	proformaInvoiceForm: any;
	selectedDate;
	proformaInvoiceId: any;
	keyword = "name"
	sendEmailForm: FormGroup;

	serviceForm: any;
	showMessage: boolean = false;
	
	alertType = '';
	navigationSubscription: any;

	message: string = ``;
	employeeList: any;
	accountList ;
	selectedClient: any;
	reInitialise: any;
	currencyCode : string = "INR";
	nativeLanguageCode = '';

	invoice_number = '';
	invoiceMode = 'save';
	loggedInuser: any;
	uuid: unknown;
	companyAddress: any;
	isconfirmationModalOpen: boolean = false;
	isSuccess: boolean = false;
	newInvoice: boolean = true
	copyELementTitle;
	attachmentFile: File | null = null;

	@ViewChildren('accountAc') accountAc: QueryList<ElementRef>;
	@ViewChildren('subLedgerAc') subLedgerAc: QueryList<ElementRef>;
	lastInputIndex = 4;
	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef

	@ViewChild('confirmDeleteModal',{static:true}) confirmDeleteModal : ElementRef
	@ViewChild('closeDeleteModal',{static:true}) closeDeleteModal : ElementRef
	@ViewChild('deleteConfirmBtn',{static:true}) deleteConfirmBtn : ElementRef

	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef
	@ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef

	@ViewChild('updateConfirmBtn',{static:true}) updateConfirmBtn : ElementRef
	@ViewChild('updateButton',{static:true}) updateButton : ElementRef
	@ViewChild('openEmailModal') openEmailModal;
	@ViewChild('closeSendEmailModal') closebutton;
	@ViewChild("openSuccessModal", { static: true }) openSuccessModal;


	shortcuts: ShortcutInput[] = [];
	currentOpenedModal = ''
	minDate;
  	maxDate;
	previousCount = 3;
	netPayable;
	
	constructor(
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private fb: FormBuilder,
		private route: ActivatedRoute,
		public proformaService: ProformaService,
		private router: Router,
	) {
    super()
	    let currentUser = JSON.parse(localStorage.getItem('okountUserInfo'))

		this.proformaInvoiceForm = this.fb.group({
			invoice_date: [moment().format('YYYY-MM-DD'),Validators.required],
			invoice_number: [],
			reference_number: [],
			client_code:['', Validators.required],
			client_code_name:['', Validators.required],	
			employee_code: [currentUser.accountcode],
			employee_code_name: [currentUser.name.toUpperCase()],
			service_charge: [''],
			gst_on: [''],
			gst_rate: ['18'],
			igst_amount: [''],
			cgst_amount: [''],
			sgst_amount: [''],
			discount: [''],
			narration_1: [''],
			narration_2: [''],
			narration_3: [''],
			narration_4: [''],
			narration_5: ['',Validators.pattern('^[^\,\"\(\)\']*$')],
			remark: [''],
			status: ["Active"],
			total: [''],
			total_payable: [''],
			tcs_on: ['total'],
			tcs_rate :  [''],
			tcs_amount: [''],
			grand_total:['']
			
		});
		
		this.sendEmailForm = this.fb.group({
			toEmails: ["",Validators.required],
			bccEmails: [''],
			ccEmails:[''],
			subject:[''],
			body:['']
		})
		this.serviceForm = this.fb.group({
			pInvoiceArray: this.fb.array([]),
		});
	
		this.route.paramMap.subscribe(params => {
			this.proformaInvoiceId = params['params'].invoice
			this.invoiceMode = params['params'].mode
		});
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	}

	async ngOnInit() {
		this.spinner.show();
		this.getCompanyData()

		if(this.data) {
			this.invoiceMode = this.data.mode;
			this.proformaInvoiceId = this.data.invoice;
		}

		if(this.invoiceMode == 'save'){
			this.pInvoiceArray = this.serviceForm.get("pInvoiceArray");
			this.pInvoiceArray = this.serviceForm.get("pInvoiceArray") as FormArray;
			this.pInvoiceArray.controls = []
			this.pInvoiceArray.push(this.buildEntry({}))

			let loggedInUser = JSON.parse(localStorage.okountUserInfo);
			this.loggedInuser = loggedInUser
			this.proformaInvoiceForm.controls.employee_code_name.setValue(
			  loggedInUser.name
			);
			this.proformaInvoiceForm.controls.employee_code.setValue(
			  loggedInUser.accountcode
			);
			this.spinner.hide()
			var url = window.location.href.slice(window.location.href.indexOf('?') + 1);
			this.getuuid(url)
		  }
		  else{
			if (this.invoiceMode == 'view' && this.proformaInvoiceId != undefined) {
				this.getProformaData(this.proformaInvoiceId)
			} 
		}

		this.commonService.selectedInput.next(0)

		this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
		try {
			let empConfiguration = this.commonService.employeeConfiguration;
				if(!empConfiguration || empConfiguration.length == 0){
					let loggedInUser = JSON.parse(localStorage.okountUserInfo);
					empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
				}
				let config = this.commonService.getConfigurationByKey('jv')
				if(config){
					let dateConfig = config['value'].filter(data => data.key == 'minimum_update_date')
					let daysConfig = config['value'].filter(data => data.key == 'minimum_update_days')
					let dateWithMinDate = moment(dateConfig ? dateConfig[0].value : '')
					let dateWithMinDays = dateConfig && daysConfig[0].value ? moment().subtract(Number(daysConfig[0].value), 'days') : dateWithMinDate
					if(dateWithMinDays.isBefore(dateWithMinDate)){
						this.minDate = dateWithMinDate.format('YYYY-MM-DD')
					}else{
						this.minDate = dateWithMinDays.format('YYYY-MM-DD')
					}
				}
		} catch (error) {
			
		}
		this.reInitialise = true
		this.maxDate = moment().format('YYYY-MM-DD')
		this.proformaInvoiceForm.controls.invoice_date.setValidators([Validators.required])
		this.proformaInvoiceForm.controls.invoice_date.updateValueAndValidity()
		this.getAccountingCompany()
	}

	employeeSearch(value) {
		try {
		  if (value.length > 2) {
			this.proformaService
			  .searchAccount(
				`employee/autocomplete?name_like=${value.toUpperCase()}`
			  )
			  .subscribe(
				(res) => {
				  this.employeeList = res;
				},
				(error) => {}
			  );
		  }
		} catch (error) {}
	  }

	ngOnDestroy() {
		if(this.navigationSubscription){
			this.navigationSubscription.unsubscribe();
		}
	}
  

	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}
	onChangeSearch(event) {	
		let value = event 
		if(event.target && event.target.value) {
			value = event.target.value;	
		}
		try {			
			if (value.length > 2) {
				this.proformaService.searchAccount(`client/autocomplete?name_like=${value.toUpperCase()}&status=active`).subscribe(res => {
					this.accountList = res;
				});
			}
		} catch (error) {

		}
	}
	
	async getuuid(url) {
		if (!url.includes('invoice_number') && this.invoiceMode != 'view') {
			this.uuid = await this.getInvoice()
			this.proformaInvoiceForm.controls.invoice_number.setValue(this.uuid)
			window.history.replaceState({}, document.title, `/${window.location.hash}?invoice_number=${this.uuid}`);
		} else {
			if (url.split('=')[1] != undefined) {
				let invNo = url.split('=')[1]
				this.proformaInvoiceForm.controls.invoice_number.setValue(invNo)
			}
		}
	}

	validateDate(){
		return (control: AbstractControl) => {
			let valid = true
			if(this){
				if (moment(control.value).isBefore(this.minDate) || moment(control.value).isAfter(this.maxDate)) {
					if(this.invoiceData && this.invoiceData.length > 0 && this.invoiceData[0].invoice_date && moment(control.value,'YYYY-MM-DD').isSame(moment(this.invoiceData[0].invoice_date,'YYYY-MM-DD'))){
						valid = true
					}else{
						valid = false
					}
				}
			}
			return !valid ? {invoice_date: {value: control.value}} : null;
		};
	}

	ngAfterViewInit(){

		this.shortcuts.push(			
			{
				key: "alt + e",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Update Jv Entry",
				description: "Update Jv Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("modify_jv_btn")){

				document.getElementById("modify_jv_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + d",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Jv Entry",
				description: "Delete Jv Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("delete_jv_btn")){

				document.getElementById("delete_jv_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + l",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Activity Log Of Jv Entry",
				description: "Activity Log Of Jv Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("jv_activity_log_btn")){

				document.getElementById("jv_activity_log_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + plus",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Add New Jv Row",
				description: "Add New Jv Row",
				
				command: (e)=>{
				console.log(e)	
					this.addProformaRow({})
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
					},
					preventDefault: true
			},
			{
				key: "alt + -",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Last Jv Row",
				description: "Delete Last Jv Row",
				
				command: (e)=>{
				console.log(e)	
				if(this.pInvoiceArray.length>0){
					this.removeJvRow(this.pInvoiceArray.length-1)
					setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
				}
						},
						preventDefault: true
			},
			{
				key: "alt + y",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On Yes Button",
				description: "Clicked On Yes Button",
				
				command: (e)=>{
				if(this.currentOpenedModal=='update'){
					this.updateProduct()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}else if(this.currentOpenedModal=='delete'){
					this.deleteInvoice(this.proformaInvoiceId)
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
				this.currentOpenedModal = ''
					},
					preventDefault: true
			},
			{
				key: "alt + n",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On No Button",
				description: "Clicked On No Button",
				
				command: (e)=>{
				if(this.currentOpenedModal=='update'){
					this.closeUpdateConfirmModal()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}else if(this.currentOpenedModal=='delete'){
					this.closeDeleteConfirmModal()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
				this.currentOpenedModal = ''
					},
					preventDefault: true
			},
			
	  )
	}

	setIsModalOpen(){
		this.isconfirmationModalOpen = false
	}
	openConfirmModal(lastIndex,addIndex) :  void{
		this.isconfirmationModalOpen = true
		this.hideError()
		this.confirmModal.nativeElement.click()
		this.moveToTab(lastIndex,addIndex)
	}
	closeConfirmModal() :  void{
		this.isconfirmationModalOpen = false
		if (this.closeModal) {
			this.closeModal.nativeElement.click()
		}
	}

	focusAccountAc(e: Event,index) {
		e.stopPropagation();
		// this.accountAc["_results"][(<HTMLInputElement>e.target).tabIndex%(+this.accountAc["_results"].length-1)+1].el.nativeElement.focus();
		if (this.accountAc["_results"][index]) {
			this.accountAc["_results"][index].focus()
		}

	  }
	  focusSubLedgerAC(e: Event,index) {
		e.stopPropagation();
		if (this.subLedgerAc["_results"][index]) {
			this.subLedgerAc["_results"][index].focus()
		}
	  }

	movetoIndex(index){
		this.lastInputIndex = ((index+1)*4)
		setTimeout(() => {
			this.commonService.selectedInput.next(this.lastInputIndex+1)
		}, 100);
	}
	moveToTab(lastInputIndex,addIndex){
		this.commonService.selectedInput.next(lastInputIndex+addIndex)
	}

	addProformaRow(data) {
		this.closeConfirmModal()
		if(!this.pInvoiceArray){
			this.pInvoiceArray = this.serviceForm.get("pInvoiceArray");
			this.pInvoiceArray = this.serviceForm.get("pInvoiceArray") as FormArray;
			this.pInvoiceArray.controls = []
		  }
		this.pInvoiceArray.push(this.buildEntry(data));
	}

	removeJvRow(index) {
		this.pInvoiceArray.removeAt(index);
		this.movetoIndex(this.pInvoiceArray.length - 1)
	}

	getAccounts(url, index) {
		return new Promise((resolve, reject) => {
			this.proformaService.getMethod(url).subscribe(res => {
				this.proformaService.accountList[index] = res
				resolve(res)
			}, err => {
				resolve("")
			})
		})
	}


	viewInvoice(invoice_no){
		let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/pi/proforma/view/${invoice_no}`
		window.location.href = url;
	}

	openVoucher(invoice_number) {
		let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/pi/proforma-print;searchId=${invoice_number}`;
		window.open(url, "_blank");
	}
	

	closeSuccesModal(){
		this.showMessage = false;
		this.isSuccess = false
		this.alertType = 'success-box';
		this.message = ``
		this.newInvoice = true
		this.openSuccessModal.nativeElement.click()
		window.location.reload()
	}
	getLedgers(url, index) {
		return new Promise((resolve, reject) => {
			this.proformaService.getMethod(url).subscribe(res => {
				this.proformaService.ledgerList[index] = res
				resolve(res)
			}, err => {
				resolve("")
			})
		})
	}

	get DynamicFormControls() {
		return <FormArray>this.serviceForm.get("pInvoiceArray");
	}

	dateSelection(event) {
		let jvFormGroupControl = this.serviceForm.controls.pInvoiceArray.controls
		this.selectedDate = moment(event).format('YYYY-MM-DD')
		for (let i = 0; i < jvFormGroupControl.length; i++) {
			const jvControl = jvFormGroupControl[i];
			jvControl.controls.invoice_date.setValue(this.selectedDate)
		}
	}

	searchAccount(value, i) {
		try {
			let searchType = 'client'
			if (value.length > 2 && searchType != null && searchType != '') {
				this.commonService.getRequest(`${environment.okountUrl}/${searchType}/autocomplete?name_like=${value.toUpperCase()}&status=active`).subscribe(res => {
					
					this.accountList = res
				});
			}
		} catch (error) {
			console.log('Error occured while account autoo complete search : ',error)
		}
	}

	selectAccount(nzEvent, formControlName) {
		let value = nzEvent;
		if(nzEvent && nzEvent.nzValue) {
			value = nzEvent.nzValue;
		}
		try {
		  this.proformaInvoiceForm.controls[formControlName].setValue(
			value && value.account_code ? value.account_code : ''
		  );
		  this.proformaInvoiceForm.controls[`${formControlName}_name`].setValue(
			value && value.name ? value.name : ''
		  );
		  if(formControlName == 'client_code' && this.invoiceMode != 'view'){
			this.selectedClient = value
		  }
		} catch (error) {}
	  }
	  

	getTotal() {
		let amount = 0
		if (this.serviceForm.controls.pInvoiceArray.controls.length > 0) {
			let controls = this.serviceForm.controls.pInvoiceArray.controls
			for (let index = 0; index < controls.length; index++) {
				const element = controls[index];
				amount += Number(element.controls.amount.value)
			}
		}
		this.proformaInvoiceForm.controls.total.setValue(amount);
		return amount
	}

	calculateNetAmount() {
		let totalAmount = this.getTotal();

		let client_igst = 0
		let client_sgst = 0
		let client_cgst = 0

		let gst_rate = this.proformaInvoiceForm.controls.gst_rate.value;
		let gst_on = this.proformaInvoiceForm.controls.gst_on.value;
		let service_charge = this.proformaInvoiceForm.controls.service_charge.value;
		let discount = this.proformaInvoiceForm.controls.discount.value;
		let client_igst_applicable = this.selectedClient  ? this.checkIsIgstApplicable(this.selectedClient.gst_number,this.selectedClient.state_id) : false


		let amountOntoBeGst = Number(service_charge);
		
		if(gst_on == 'T') {
			amountOntoBeGst = Number(totalAmount);
		}

		let gstAmount = Number((amountOntoBeGst * gst_rate/100).toFixed(2))
		if(client_igst_applicable) {
			client_igst = gstAmount;
		}else{
			let halfGst = gstAmount/2;
			client_cgst = halfGst;
			client_sgst = halfGst;
		}
		
		this.proformaInvoiceForm.controls.igst_amount.setValue(client_igst)
		this.proformaInvoiceForm.controls.cgst_amount.setValue(client_cgst)
		this.proformaInvoiceForm.controls.sgst_amount.setValue(client_sgst)

		let netPayableAmount = Number(totalAmount) + Number(service_charge) + Number(gstAmount) - Number(discount);
		this.netPayable = netPayableAmount;
		this.proformaInvoiceForm.controls.total_payable.setValue(netPayableAmount);
		let tcs_on=this.proformaInvoiceForm.controls.tcs_on.value
		let tcs_rate=this.proformaInvoiceForm.controls.tcs_rate.value
		let roundedAmount
		if(tcs_on && Number(tcs_rate)>0){
			if(tcs_on=='total'){
				let tcsAmount = (Number(tcs_rate) * Number(totalAmount)) / 100
			roundedAmount = Math.round(tcsAmount)
		}else{
			let tcsAmount = (Number(tcs_rate) * Number(netPayableAmount)) / 100
			roundedAmount = Math.round(tcsAmount)
		}
		this.proformaInvoiceForm.controls.tcs_amount.setValue(roundedAmount)
		}
		this.proformaInvoiceForm.controls.grand_total.setValue(Number(netPayableAmount?netPayableAmount:0)+Number(roundedAmount?roundedAmount:0));

		this.setNarration();
	}

	checkIsIgstApplicable (otherGstNumber,otherState) {
		let accountingCompanyGstNumber = this.companyAddress && this.companyAddress.gst_number ? this.companyAddress.gst_number : ""
		let accountingCompanyState = this.companyAddress && this.companyAddress.state_id ? this.companyAddress.state_id : ''
		if (otherGstNumber && otherGstNumber.length > 0 && otherGstNumber != 'null') {
		  if (accountingCompanyGstNumber.substring(0, 2) == otherGstNumber.substring(0, 2)) {
			  return false;
		  } else {
			  return true;
		  }
	  } else if (otherState && otherState.length > 0 && otherState != 'null') {
		  //if other's company state is of same sate then non igst applicable
		  if (accountingCompanyState == otherState) {
			  return false;
		  } else {
			  return true;
		  }
		  //if other code/state both is undefined then consider it non igst applicable 
	  } else {
		  return false;
	  }
	  }


	buildEntry(data) {
		return this.fb.group({
			"description": [data && data.description ? data.description : ''],
			"quantity": [data && data.quantity ? data.quantity : 1],
			"rate": [data && data.rate ? data.rate : ''],
			"service_charge": [data && data.service_charge ? data.service_charge : ''],
			"gst_on": [data && data.gst_on ? data.gst_on : ''],
			"gst_rate": [data && data.gst_rate ? data.gst_rate : ''],
			"igst": [data && data.igst ? data.igst : ''],
			"sgst": [data && data.sgst ? data.sgst : ''],
			"cgst": [data && data.cgst ? data.cgst : ''],
			"amount": [data && data.client_net_amount ? data.client_net_amount : '']
		});
	}
	
	getInvoice() {
		return new Promise((resolve, reject) => {
			this.proformaService.getMethod(`invoice/getuuid`).subscribe(
				res => {
					let invoiceNo;
					try {
						invoiceNo = res["uuid"]
						resolve(invoiceNo);
					} catch (error) {
						invoiceNo = res
						resolve(invoiceNo);
					}
					if(res && res['accounting_company']) {
						if(res['accounting_company'].currency_code) {
						  this.currencyCode = res['accounting_company'].currency_code;
						}
						if(res['accounting_company'].native_language_code && res['accounting_company'].currency_code == 'AED') {
						  this.nativeLanguageCode = res['accounting_company'].native_language_code;
						}
					}
				},
				err => {
					reject("");
				}
			);
		});
	}


	getCompanyData() {
		return new Promise((resolve, reject) => {
				this.commonService.getRequest(`${environment.okountUrl}/setting/address`).subscribe(
					res => {
						this.companyAddress = res
			  resolve(res)
					},
					err => {
						reject({});
					}
				);
			});
	  }
	
	async saveProductInvoice() {
		if (this.invoiceMode == "view") {
		  this.updateProduct();
		} else {
		  const invalid = [];
		  const controls = this.proformaInvoiceForm.controls;
		  for (const name in controls) {
			if (controls[name].invalid) {
			  invalid.push(name);
			}
		  }
		  if(invalid.length > 0) {
			window.scroll(0,0)
			this.showMessage = true;
			this.isSuccess = false
			this.invoice_number = ''
			this.alertType = 'error-box';
			this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
			this.spinner.hide()
			return false
		  }
		
		  let product_json_doc = this.proformaService.createMsProduct(
			this.proformaInvoiceForm.value,this.pInvoiceArray.value
		  );

		  this.closeConfirmModal()
		  this.commonService.postRequest(`${environment.okountUrl}/invoice/proforma`,product_json_doc).subscribe(async (data) => {
			window.scroll(0, 0)
			this.isSuccess = true
			this.alertType = 'success-box';
			this.invoice_number = data['invoice_no']
			this.message = `Invoice Saved with invoice no. : ${this.invoice_number}`
			this.proformaInvoiceForm.reset()
			let loggedInUser = JSON.parse(localStorage.okountUserInfo);
			this.loggedInuser = loggedInUser
			this.proformaInvoiceForm.controls.employee_code_name.setValue(
			  loggedInUser.name
			);
			this.proformaInvoiceForm.controls.employee_code.setValue(
				loggedInUser.accountcode
				);
			this.initialiseFormData({},{})
			this.pInvoiceArray.controls = [];

			this.addProformaRow({})
			let obj = {
			  invoice_no: 'New Invoice',
			};
			this.uuid = await this.getInvoice()
			this.proformaInvoiceForm.controls.invoice_number.setValue(this.uuid)
			window.history.replaceState({}, document.title, `/${window.location.hash}?invoice_number=${this.uuid}`);
			this.spinner.hide()
			this.newInvoice = false;
			this.openSuccessModal.nativeElement.click()
			setTimeout(() => {
			  this.commonService.selectedInput.next(1)
			}, 100);
		  },err=> {
			window.scroll(0, 0)
			this.showMessage = true;
			this.isSuccess = false
			this.invoice_number = ''
			this.alertType = 'error-box';
			this.message = err['error']['message']
			this.spinner.hide()
		  })
		  
		}
	  }

	updateProduct() {
		const invalid = [];

		const controls = this.proformaInvoiceForm.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		
		if(invalid.length > 0) {
			window.scroll(0,0)
			this.showMessage = true;
			this.isSuccess = false
			this.invoice_number = ''
			this.alertType = 'error-box';
			this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
			this.spinner.hide()
			this.closeUpdateConfirmModal()
			return false
	    }
			this.closeUpdateConfirmModal()
			this.spinner.show()
			let product_json_doc = this.proformaService.createMsProduct(
				this.proformaInvoiceForm.value,this.pInvoiceArray.value
			);
			this.closeConfirmModal()
			this.commonService.putRequest(`${environment.okountUrl}/invoice/proforma/update/${this.invoice_number}`,product_json_doc).subscribe(async (data) => {
			window.scroll(0, 0)
			this.showMessage = true;
			this.isSuccess = true
			this.alertType = 'success-box';
			this.message = `Invoice Updated`
			this.spinner.hide()
		},err=> {
			window.scroll(0, 0)
			this.showMessage = true;
			this.isSuccess = false
			this.alertType = 'error-box';
			this.message = err['error']['message']
			this.spinner.hide()
		})
	}

	reset() {
		this.proformaInvoiceForm.reset()
		this.pInvoiceArray.controls = []
		this.addProformaRow({})
	}

	openActivity(){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/jv/${this.proformaInvoiceId}`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

	closeWindow() {
		window.close();
	}

	deleteInvoice(invoice_number){
		this.closeDeleteConfirmModal()
		this.hideError();
		this.commonService.selectedInput.next(this.lastInputIndex+1)
		this.commonService.deleteRequest(`${environment.okountUrl}/invoice/proforma/${invoice_number}`).subscribe(res=>{
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = `Invoice deleted`
		},err=>{
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = `Failed to delete invoice.`
		})
	}

	hideError(){
		this.showMessage = false
	}
	resetCurrentModalType(){
		this.currentOpenedModal = ''
	}
	openDeleteConfirmModal(){
		this.closeOpenedModals('delete')
		this.currentOpenedModal = 'delete'
		this.confirmDeleteModal.nativeElement.click()
		this.commonService.selectedInput.next(this.lastInputIndex+5)
	}
	closeDeleteConfirmModal(){
		this.currentOpenedModal = ''
		this.closeDeleteModal.nativeElement.click()
	}
	
	focusDeleteConfirm(){
		this.deleteConfirmBtn.nativeElement.focus()
	}
	openUpdateConfirmModal(){
		this.closeOpenedModals('update')
		this.currentOpenedModal = 'update'
		this.confirmUpdateModal.nativeElement.click()
		this.commonService.selectedInput.next(this.lastInputIndex+7)
	}
	closeUpdateConfirmModal(){
		this.currentOpenedModal = ''
		this.closeUpdateModal.nativeElement.click()
	}

	closeOpenedModals(modalName){
		if(modalName!='delete') this.closeDeleteConfirmModal()
		if(modalName!='update') this.closeUpdateConfirmModal()
	}
	focusUpdateConfirm(){
		this.updateConfirmBtn.nativeElement.focus()
	}

	openJvActivity(proformaInvoiceId) {
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/jv/${proformaInvoiceId}`
		window.open(path,'_blank')
	}

	openSendEmailModal(){
		let primary_email = ''
		let secondary_email = ''
		console.log(this.invoiceData);
		
		let accounting_company = this.invoiceData && this.invoiceData.accounting_company ? this.invoiceData.accounting_company : null;
		let subject = `Invoice. ${this.proformaInvoiceId}`
		let body = `Dear Travel Partner,\n\nPlease find Attached Jv ${this.proformaInvoiceId}`
		body+=`\n\nPlease do not hesitate to contact us if you have any query or concern about attached document.\n\nIt's been a pleasure to serve you.\n\nBest Regards\n\n${accounting_company?.name}\nAccounts Team`
		if(accounting_company && accounting_company.telephone){
			body += `\nContact Us on ${accounting_company.telephone}`
		}
		if(this.invoiceData && this.invoiceData.client){
			primary_email = this.invoiceData.client.hasOwnProperty('primary_email')?this.invoiceData.client.primary_email:''
			secondary_email = this.invoiceData.client.hasOwnProperty('secondary_email')?this.invoiceData.client.secondary_email:''
		}
		this.sendEmailForm.controls.toEmails.setValue(primary_email)
		this.sendEmailForm.controls.bccEmails.setValue("")
		this.sendEmailForm.controls.ccEmails.setValue(secondary_email?.split(',').join('\n'))
		this.sendEmailForm.controls.subject.setValue(subject)
		this.sendEmailForm.controls.body.setValue(body)
		this.openEmailModal.nativeElement.click();
	}

	callTosendEmail(){
		let toEmails = this.sendEmailForm.value.toEmails
		let bccEmails = this.sendEmailForm.value.bccEmails?.split(",")
		let ccEmails = this.sendEmailForm.value.ccEmails?.split(",")
		let subject = this.sendEmailForm.value.subject
        let body = this.sendEmailForm.value.body
		if(toEmails){
			toEmails = toEmails.split(",")
			window.scrollTo(0, 0)
			let emails = {
				to_addresses:toEmails,
				cc_addresses:ccEmails,
				bcc_addresses:bccEmails
			}
			let pageUrl = `transactions/pi/proforma-print;searchId=${this.proformaInvoiceId}`
			const formData = new FormData();
			formData.append('email',JSON.stringify(emails))
			formData.append('page_url',pageUrl)
			formData.append('subject',subject)
			formData.append('email_body',body)
			formData.append('document_num',this.proformaInvoiceId)
			if(this.attachmentFile){
				formData.append('attachmentFile',this.attachmentFile)
			}
			let data = formData
			
			this.commonService.fileUpload(`${environment.okountUrl}/send_mail?transaction_type=proforma`,data).subscribe((res : any)=> {
				this.showMessage = true;
				this.alertType = 'success-box'
				this.message = res.msg
				this.closebutton.nativeElement.click();
			},err => {
				this.showMessage = true
				this.alertType = "error-box"
				this.message = err.msg
				this.closebutton.nativeElement.click();
			 })
			}
	  }


	calculateRowWise(event, index) {
		let value = event.target.value;
		value = Number(value);

		let controls = this.serviceForm.controls.pInvoiceArray.controls
		let selectedControl = controls[index];

		if(!selectedControl.controls.quantity.value || !selectedControl.controls.rate.value) {
			selectedControl.controls.amount.setValue(0)
		}
		let service_charge = Number(selectedControl.controls.service_charge.value||0)
		let gst_on = selectedControl.controls.gst_on.value
		let gst_rate = selectedControl.controls.gst_rate.value||0
		let totalAmount = Number(selectedControl.controls.rate.value||0)+ Number(service_charge)
		let igst = 0,cgst = 0, sgst =0


		let client_igst_applicable = this.selectedClient  ? this.checkIsIgstApplicable(this.selectedClient.gst_number,this.selectedClient.state_id) : false


		let amountOntoBeGst = Number(service_charge);
		
		if(gst_on == 'T') {
			amountOntoBeGst = Number(totalAmount);
		}else if(gst_on=='R'){
			amountOntoBeGst= Number(selectedControl.controls.rate.value||0)
		}

		let gstAmount = Number((amountOntoBeGst * gst_rate/100).toFixed(2))
		if(client_igst_applicable) {
			igst = gstAmount;
		}else{
			let halfGst = gstAmount/2;
			cgst = halfGst;
			sgst = halfGst;
		}
		
		selectedControl.controls.igst.setValue(igst)
		selectedControl.controls.cgst.setValue(cgst)
		selectedControl.controls.sgst.setValue(sgst)
		let amount =  Number(selectedControl.controls.quantity.value) * Number(Number(selectedControl.controls.rate.value||0)+Number(service_charge)+Number(igst)+Number(cgst)+Number(sgst))


		selectedControl.controls.amount.setValue(amount)
		this.calculateNetAmount()
	}

	copytext(val: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.copyELementTitle = 'Copied'
	}


	getProformaData(searchId: any) {
		try {
			let url = `invoice/proforma/${searchId}`
			this.spinner.show()
			this.commonService.getOkount(url).subscribe(async (res) => {
			let invoiceData = res['data'][0];
			this.invoiceData = res['data'][0];
			let resp = await this.bindInvoiceData(invoiceData)
			this.selectedClient = res['data'][0].client
			if(invoiceData) {
				this.invoice_number = invoiceData.invoice_no;
			}
			this.onChangeSearch(invoiceData.products[0].client_code)
		},
			err => {
				this.spinner.hide()
			})
		} catch (error) {
            this.spinner.hide()
			console.log('Error occured in getInvoice due to : ', error)
		}
	}

	async bindInvoiceData(invoiceData) {
		await this.initialiseFormData(invoiceData.products[0], invoiceData)

		this.pInvoiceArray = this.serviceForm.get("pInvoiceArray");
		this.pInvoiceArray = this.serviceForm.get("pInvoiceArray") as FormArray;
		this.pInvoiceArray.controls = []
		for (const iterator of invoiceData.products) {
		   this.addProformaRow(iterator)
        }

		this.calculateNetAmount()

		this.spinner.hide();
	}

	initialiseFormData(data,invoiceData){
		this.proformaInvoiceForm = this.fb.group({
		  invoice_date: [invoiceData.invoice_date ? moment(invoiceData.invoice_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),Validators.required],
		  invoice_number: [invoiceData.invoice_no ? invoiceData.invoice_no : ""],
		  reference_number: [invoiceData.reference_number ? invoiceData.reference_number : ''],
		  client_code:[data.client_code ? data.client_code : '', Validators.required],
		  client_code_name:[data.client_code_name ? data.client_code_name : '', Validators.required],	
		  employee_code: [data.employee_code ? data.employee_code : ''],
		  employee_code_name: [data.employee_code_name ? data.employee_code_name : ''],
		  service_charge: [invoiceData.service_charge ? invoiceData.service_charge : '' ],
		  gst_on: [invoiceData.gst_on ? invoiceData.gst_on : ''],
		  gst_rate: [invoiceData.gst_rate ? invoiceData.gst_rate : ''],
		  igst_amount: [invoiceData.igst_amount ? invoiceData.igst_amount : ''],
		  cgst_amount: [invoiceData.cgst_amount ? invoiceData.cgst_amount : ''],
		  sgst_amount: [invoiceData.sgst_amount ? invoiceData.sgst_amount : ''],
		  discount: [invoiceData.discount ? invoiceData.discount : ''],
		  narration_1: [invoiceData.narration_1 ? invoiceData.narration_1 : ''],
		  narration_2: [invoiceData.narration_2 ? invoiceData.narration_2 : ''],
		  narration_3: [invoiceData.narration_3 ? invoiceData.narration_3 : ''],
		  narration_4: [invoiceData.narration_4 ? invoiceData.narration_4 : ''],
		  narration_5: [invoiceData.narration_5 ? invoiceData.narration_5 : '' ,Validators.pattern('^[^\,\"\(\)\']*$')],
		  remark: [''],
		  status: [invoiceData.status? invoiceData.status:"Active"],
		  total: [invoiceData.total ? invoiceData.total : ''],
		  total_payable: [invoiceData.total_payable ? invoiceData.total_payable : ''],
		  tcs_on: [invoiceData.tcs_on ? invoiceData.tcs_on : 'total'],
		  tcs_rate: [invoiceData.tcs_rate ? invoiceData.tcs_rate : ''],
		  tcs_amount : [invoiceData.tcs_amount ? invoiceData.tcs_amount : ''],
		  grand_total: [invoiceData.grand_total ? invoiceData.grand_total : ''],


		});
		return true
	  }


	  setNarration(){
		let services = this.serviceForm.controls.pInvoiceArray.controls;
		let travellerListLength = services.length;
		let firstService = travellerListLength && services[0].controls;
		let secondService = services[1] && services[1].controls
		
		var narrLine1= firstService && firstService.description.value;
		var narrLine2 = secondService && secondService.description.value;
		
  
		var narrLine3 = ''
		if(services.length>2) {
			narrLine3 = `+ ${services.length-2} MORE ENTRIES`
		}
		
		var narrLine4 = `ISSUED BY : ${this.proformaInvoiceForm.controls.employee_code_name.value.toUpperCase()}`
		this.proformaInvoiceForm.controls.narration_1.setValue(narrLine1)
		this.proformaInvoiceForm.controls.narration_2.setValue(narrLine2)
		this.proformaInvoiceForm.controls.narration_3.setValue(narrLine3)
		this.proformaInvoiceForm.controls.narration_4.setValue(narrLine4)
	}
	  
	async initDateValidation (){
		try {
			this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
			this.maxDate = moment().format('YYYY-MM-DD')
			
			let config = this.commonService.getConfigurationByKey('proforma')
			if(config){
				let dateConfig = config['value'].filter(data => data.key == `minimum_${this.invoiceMode == 'view' ? 'update' : 'creation'}_date`)
				let daysConfig = config['value'].filter(data => data.key == `minimum_${this.invoiceMode == 'view' ? 'update' : 'creation'}_days`)
				let dateWithMinDate = moment(dateConfig ? dateConfig[0].value : '')
                let dateWithMinDays = dateConfig && daysConfig[0].value ? moment().subtract(Number(daysConfig[0].value), 'days') : dateWithMinDate
				if(dateWithMinDays.isBefore(dateWithMinDate)){
					this.minDate = dateWithMinDate.format('YYYY-MM-DD')
				}else{
					this.minDate = dateWithMinDays.format('YYYY-MM-DD')
				}
			}
		} catch (error) {
			
		}
         this.proformaInvoiceForm.controls.invoice_date.setValidators([Validators.required,this.validateDate()])
		this.proformaInvoiceForm.controls.invoice_date.updateValueAndValidity()
    }
	handleAttachment(event: any) {
		this.attachmentFile = event.target.files[0];
	  }

	  getAccountingCompany() {
		try {
		  this.proformaService.getMethod('util/accounting-company').subscribe(async (res) => {
			if(res) {
			  if(res['currency_code']) {
				this.currencyCode = res['currency_code'];
			  }
			  if(res['native_language_code'] && res['currency_code'] == 'AED') {
				this.nativeLanguageCode = res['native_language_code'];
			  }
			//   if(this.invoiceMode != 'view') {
			// 	this.miscellaneousInvoiceService.invoiceForm.controls.purchase_currency_rate.setValue(1);
			// 	this.miscellaneousInvoiceService.invoiceForm.controls.purchase_currency.setValue(this.currencyCode ? this.currencyCode : '');
			//   }
			  
			}
		  },
		  err => {
			this.spinner.hide()
		  })
		} catch (error) {
		  console.log('Error occured in getInvoice due to : ', error)
		}
	  }
}
