import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrintMiscInvoiceComponent } from './print-misc-invoice/print-misc-invoice.component';
import { MiscInvoiceComponent } from './misc-invoice.component';


const routes: Routes = [
	
	{
		path: 'print', component: PrintMiscInvoiceComponent
	},
	{
		path: 'save/:transaction_type', component: MiscInvoiceComponent
	},
	{
	 	path: 'view/:invoiceNumber',  component: MiscInvoiceComponent,
    },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MiscInvoiceRoutingModule { }
