<div class="ledger-container pb-20">
	<!-- <div class="tab-nav d-flex align-item-center justify-content-between">
		<ul class="list-inline list-unstyled p-0 m-0">
			<li class="active page-title   text-uppercase">Add Entry Receipt</li>
		</ul>
		<ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
			<li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition">
				Dashboard
			</li>
			<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
			<li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition">
				Update Receipt
			</li>
			<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
			<li class="text-grey text-capitalize cursor text-primary transition"> Add Entry Receipt</li>
		</ul>
	</div> -->
	<!-- <div class="row tab-nav pl-0 pr-0">
		<div class="col-sm-4 ">
		  <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
			  <li [routerLink]="['/']"
				  class="text-grey text-capitalize cursor  hover-text-primary transition"> Master</li>
			  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
			  <li [routerLink]="['/']"
				  class="text-grey text-capitalize cursor  hover-text-primary transition"> Client</li>
			  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
			  <li class="text-grey text-capitalize cursor text-primary transition"> View Client</li>
		  </ul>
	  </div>
	</div> -->
	<form [formGroup]="receiptService.receiptEntryForm" style="width: 600px; margin: 20px 15px;">
		<div class="custom-form">

			<!-- <div class="heading-box mt-4">
				<span class="hr-line"></span>
				<div class="heading">CREATE RECEIPT ENTRY</div>
			</div> -->

			<div class="text-field-group d-flex align-items-center">

				<div class="select-field text-input-field mr-3">
					<select class="input-field" #accountType formControlName="account_code_from_type" (change)="resetSelection('account_code_from')" tabIndex="1">
						<option value="client">Client</option>
						<option value="airline">Airline</option>
						<option value="supplier">Supplier</option>
						<option value="employee">Employee</option>
						<option value="general">General</option>
					</select>
					<label>Account Type</label>

				</div>

				<div class="input-fiel form-group ng-field" tabIndex="2" (focus)="focusAccount($event)">
					<!-- <select class="input-field" formControlName="account_code_to">
								  <option value="">Select</option>
								  <ng-container  *ngFor="let item of receiptService.accountList">
									  <option  *ngIf="item.account_code != ''" value="{{item.account_code}}">{{item.name}}</option>
								  </ng-container>
							  </select> -->
					<label class="ngauto-label">Account</label>

					<div class="ng-autocomplete">
						<ng-autocomplete #accountAC [data]="receiptService.accountList"
							formControlName="account_code_from_name" [searchKeyword]="keyword"
							(selected)="selectAccount($event,'account_code_from')"
							(opened)="setAccountAutoCompleteStatus(true)" (closed)="setAccountAutoCompleteStatus(false)"
							(inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
							[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
						</ng-autocomplete>


						<ng-template #itemTemplate let-item>
							<a innerHTML='<span class="colr-1">{{item.name}}
										  ({{item.account_code}})</span><br />'>
							</a>
						</ng-template>

						<ng-template #notFoundTemplate let-notFound>
							<div [innerHTML]="notFound"></div>
						</ng-template>
					</div>
				</div>
			</div>

			<div class="text-field-group d-flex align-items-center">
				<div class="select-field text-input-field mr-3">
					<select class="input-field" formControlName="sub_ledger_code_1_type" (change)="resetSelection('sub_ledger_code_1')" tabIndex="3">
						<option value="client">Client</option>
						<option value="airline">Airline</option>
						<option value="supplier">Supplier</option>
						<option value="employee">Employee</option>
						<option value="general">General</option>
					</select>
					<label>Sub Ledger Type</label>
				</div>

				<div class="input-fiel form-group ng-field" tabIndex="4" (focus)="focusSubLedger($event)">
					<label class=""
						[ngClass]="{'ngauto-label': accountAutoComplete==false,'normal-label' : accountAutoComplete==true}">Sub
						Ledger</label>

					<div class="ng-autocomplete">
						<ng-autocomplete #subLedgerAC [data]="receiptService.ledgerList"
							formControlName="sub_ledger_code_1_name" [searchKeyword]="keyword"
							(selected)="selectAccount($event,'sub_ledger_code_1')" (inputChanged)='ledgerSearch($event)'
							(inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
							[notFoundTemplate]="notFoundTemplate">
						</ng-autocomplete>


						<ng-template #itemTemplate let-item>
							<a innerHTML='<span class="colr-1">{{item.name}}({{item.account_code}})</span><br />'> </a>
						</ng-template>

						<ng-template #notFoundTemplate let-notFound>
							<div [innerHTML]="notFound"></div>
						</ng-template>
					</div>
				</div>

				<!-- <div class="select-field text-input-field">
					<select class="input-field" formControlName="sub_ledger_code_1">
						<ng-container  *ngFor="let item of receiptService.ledgerList">
							<option  *ngIf="item.account_code != ''" value="{{item.account_code}}">{{item.name}}</option>
						</ng-container>
					</select>
					<label>Sub Ledger </label>             
				  </div> -->
			</div>
			<div class="text-field-group d-flex align-items-center">
				<div class="text-input-field mr-3">
					<input oninput="this.value = this.value.toUpperCase()" tabIndex="5" class="input-field" type="text"
						formControlName="cheque_no" maxlength="30" pattern="([0-9]+)"
						[ngClass]="{'error-field': receiptService.receiptEntryForm.controls.cheque_no.invalid && receiptService.receiptEntryForm.controls.cheque_no.dirty,
					'success-field' :receiptService.receiptEntryForm.controls.cheque_no.valid && receiptService.receiptEntryForm.controls.cheque_no.dirty}" />
					<label
						[ngClass]="{'error-label': receiptService.receiptEntryForm.controls.cheque_no.invalid && receiptService.receiptEntryForm.controls.cheque_no.dirty}">Cheque
						no.
						<ng-container
							*ngIf="receiptService.receiptEntryForm.controls.cheque_no.invalid && receiptService.receiptEntryForm.controls.cheque_no.dirty">
							<i class="fa fa-info-circle ml-2" title="Invalid Cheque Number" aria-hidden="true"></i>
						</ng-container>
					</label>
				</div>

				<div class="text-input-field mr-3">
					<input oninput="this.value = this.value.toUpperCase()" tabIndex="6" class="input-field" type="date"
						formControlName="cheque_date" autocomplete="off"
						[ngClass]="{'error-field': receiptService.receiptEntryForm.controls.cheque_date.invalid && receiptService.receiptEntryForm.controls.cheque_date.dirty,
						'success-field' :receiptService.receiptEntryForm.controls.cheque_date.valid && receiptService.receiptEntryForm.controls.cheque_date.dirty}" />
					<label
						[ngClass]="{'error-label': receiptService.receiptEntryForm.controls.cheque_date.invalid && receiptService.receiptEntryForm.controls.cheque_date.dirty}">Cheque
						Date
						<ng-container
							*ngIf="receiptService.receiptEntryForm.controls.cheque_date.invalid && receiptService.receiptEntryForm.controls.cheque_date.dirty">
							<i class="fa fa-info-circle ml-2" title="Invalid Date Format" aria-hidden="true"></i>
						</ng-container>
					</label>
				</div>

				<div class="text-input-field">
					<input oninput="this.value = this.value.toUpperCase()" tabIndex="7" class="input-field" type="text"
						#amountField formControlName="amount" pattern="^\d*(\.\d{0,8})?$"
						[ngClass]="{'error-field': (receiptService.receiptEntryForm.controls.amount.invalid && receiptService.receiptEntryForm.controls.amount.dirty) || receiptService.receiptEntryForm.value.amount < 1,
					'success-field' :receiptService.receiptEntryForm.controls.amount.valid && receiptService.receiptEntryForm.controls.amount.dirty}" />
					<label
						[ngClass]="{'error-label': (receiptService.receiptEntryForm.controls.amount.invalid && receiptService.receiptEntryForm.controls.amount.dirty) || receiptService.receiptEntryForm.value.amount < 1}">Amount
						<ng-container
							*ngIf="(receiptService.receiptEntryForm.controls.amount.invalid && receiptService.receiptEntryForm.controls.amount.dirty) || receiptService.receiptEntryForm.value.amount < 1">
							<i class="fa fa-info-circle ml-2" title="Amount should be a number and greater than 0"
								aria-hidden="true"></i>
						</ng-container>
					</label>
				</div>
			</div>


			<div class="text-field-group d-flex justify-content-between">
				<div class="left-col">
					<div class="text-input-field">
						<input oninput="this.value = this.value.toUpperCase()" tabIndex="8" class="input-field"
							type="text" formControlName="narration_1" />
						<label>Narration 1</label>
					</div>

					<div class="text-input-field">
						<input oninput="this.value = this.value.toUpperCase()" tabIndex="9" class="input-field"
							type="text" formControlName="narration_2" />
						<label>Narration 2</label>
					</div>

					<div class="text-input-field">
						<input oninput="this.value = this.value.toUpperCase()" tabIndex="10" class="input-field"
							type="text" formControlName="narration_3" />
						<label>Narration 3</label>
					</div>

					<div class="text-input-field">
						<input oninput="this.value = this.value.toUpperCase()" tabIndex="11" class="input-field"
							type="text" formControlName="narration_4" />
						<label>Narration 4</label>
					</div>

					<div class="text-input-field">
						<input oninput="this.value = this.value.toUpperCase()" tabIndex="12" class="input-field"
							type="text" formControlName="narration_5" />
						<label>Narration 5</label>
					</div>
				</div>

				<div class="right-col">
					<div class="text-input-field">
						<input oninput="this.value = this.value.toUpperCase()" tabIndex="13" class="input-field"
							type="text" formControlName="remark_1" />
						<label>Remarks 1</label>
					</div>

					<div class="text-input-field">
						<input oninput="this.value = this.value.toUpperCase()" tabIndex="14" class="input-field"
							type="text" formControlName="remark_2" />
						<label>Remarks 2</label>
					</div>

					<div class="text-input-field">
						<input oninput="this.value = this.value.toUpperCase()" tabIndex="15" class="input-field"
							type="text" formControlName="remark_3" />
						<label>Remarks 3</label>
					</div>

					<div class="text-input-field">
						<input oninput="this.value = this.value.toUpperCase()" tabIndex="16" class="input-field"
							type="text" formControlName="remark_4" />
						<label>Remarks 4</label>
					</div>

					<div class="text-input-field">
						<input oninput="this.value = this.value.toUpperCase()" tabIndex="17" class="input-field"
							type="text" formControlName="remark_5" />
						<label>Remarks 5</label>
					</div>
				</div>
			</div>

			<div class="text-field-group d-flex align-items-center">
				<div class="input-fiel form-group ng-field mr-3" tabIndex="18" (focus)="focusOtherAccount($event)">
					<!-- <input oninput="this.value = this.value.toUpperCase()" tabIndex="18" class="input-field" type="text" formControlName="other_account" /> -->
					<label class="ngauto-label">Other Account
					</label>
					<div class="ng-autocomplete">
						<ng-autocomplete #otherAccountAC [data]="receiptService.otherAccountList"
							formControlName="other_account_name" [searchKeyword]="keyword"
							(selected)="selectAccount($event,'other_account')" (inputChanged)='getOtherAccount($event)'
							(inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
							[notFoundTemplate]="notFoundTemplate">
						</ng-autocomplete>

						<ng-template #itemTemplate let-item>
							<a innerHTML='<span class="colr-1">{{item.name}}
											({{item.account_code}})</span><br />'>
							</a>
						</ng-template>

						<ng-template #notFoundTemplate let-notFound>
							<div [innerHTML]="notFound"></div>
						</ng-template>
					</div>
				</div>

				<div class="text-input-field mr-3 ml-3" style="width: 73%;">
					<select class="input-field" tabIndex="19" formControlName="other_transaction_type">
						<option value="debit">DR</option>
						<option value="credit">CR</option>
					</select>
					<label>Transaction Type </label>
				</div>

				<div class="text-input-field">
					<input oninput="this.value = this.value.toUpperCase()" tabIndex="20" class="input-field" type="text"
						formControlName="other_amount" />
					<label>Other Amount

					</label>
				</div>

			</div>

			<span class="info alert-warning mt-10">* Remarks will not be available for print.</span>

		</div>
		<div class="action-box d-flex justify-content-center mt-4 mb-4">
			<ng-container *ngIf="!receiptService.isEdit else updateButtons">
				<button type="button" class="btn-style bg-green mr-3" tabIndex="21"
					(click)="openConfirmModal()">Save</button>
			</ng-container>
			<ng-template #updateButtons>
				<button type="button" class="btn-style bg-blue mr-3" tabIndex="21"
					(click)="openUpdateConfirmModal()">Update</button>
				<button type="button" class="btn-style bg-red mr-3" tabIndex="22"
					(click)="deleteAndExit(receiptService.receiptEntryForm.value)">Delete</button>
			</ng-template>
			<button type="button" tabIndex="23" class="btn-style bg-orange" (click)="exitEntryPage()">Cancell</button>
		</div>
	</form>
</div>

<!-- <div class="modal fade" id="confirmationModal"  role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
	<div class="modal-dialog modal-md" role="document">
	  <div class="modal-content">
		<div class="modal-header">
			<button type="button" class="close" #closeModal data-dismiss="modal" aria-label="Close">
			  <span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
		  Add New Entry ?
		</div>
		<div class="modal-footer">
		  <button type="button" class="btn-style mr-2" (click)="saveAndExit(receiptService.receiptEntryForm.value)" (keyup.arrowright)="focusTab('26')" (keyup.enter)="saveAndExit(receiptService.receiptEntryForm.value)" tabIndex="25">No</button>
		  <button type="button" class="btn-style" (click)="saveAndEditNext(receiptService.receiptEntryForm.value)" (keyup.arrowleft)="focusTab('25')" tabIndex="26" (keyup.enter)="saveAndEditNext(receiptService.receiptEntryForm.value)" >Yes</button>
		</div>
	  </div>
	</div>
  </div> -->

<button type="button" hidden #confirmModal data-toggle="modal" data-target="#confirmationModal"></button>
<button type="button" hidden #updateConfirmModal data-toggle="modal" data-target="#updateConfirmationModal"></button>


<div id="confirmationModal" class="modal fade" role="dialog" data-backdrop="false">
	<div class="modal-dialog">
		<!-- Modal content-->
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Confirmation</h4>
				<button type="button" #closeModal class="close" data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<p>Add New Entry ?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn-style bg-purple" tabIndex="24" attr.tabIndex="24"
					(click)="saveAndEditNext(receiptService.receiptEntryForm.value)" data-dismiss="modal">Yes</button>
				<button type="button" class="btn-style" tabIndex="25" attr.tabIndex="25"
					(click)="saveAndExit(receiptService.receiptEntryForm.value)" data-dismiss="modal">No</button>
			</div>
		</div>
	</div>
</div>


<div id="updateConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
	<div class="modal-dialog">
		<!-- Modal content-->
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Confirmation</h4>
				<button type="button" #closeModal class="close" data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<p>Are you sure to update ‎this entry ?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn-style bg-purple" tabIndex="26" attr.tabIndex="26"
					(click)="updateAndExit(receiptService.receiptEntryForm.value)" data-dismiss="modal">Yes</button>
				<button type="button" class="btn-style" tabIndex="27" attr.tabIndex="27" (click)="closeConfirmModal()"
					data-dismiss="modal">No</button>
			</div>
		</div>
	</div>
</div>