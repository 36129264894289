import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConsolidateInvoiceSearchComponent } from './consolidate-invoice.component';

const routes: Routes = [
  {
    path: ':consolidationType' , component: ConsolidateInvoiceSearchComponent,
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsolidateInvoiceSearchRoutingModule {

 }
