import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvoiceRefundRoutingModule } from './invoice-refund-routing.module';
import { InvoiceRefundComponent } from './invoice-refund.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FlightRefundModule } from './flight-refund/flight-refund.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [InvoiceRefundComponent],
  imports: [
    CommonModule,
    InvoiceRefundRoutingModule,
    SharedModule,
    FlightRefundModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot(),
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [InvoiceRefundComponent]
})
export class InvoiceRefundModule { }
