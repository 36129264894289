
<div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
    <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
      <div class="d-flex flex-y-center">
        <img
          src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
            alertType=='success-box' ? 'green-sucess.svg' : 
            alertType=='warning-box' ? 'red-warning.svg' : 
            null }}"
          width="20px"
          height="20px"
          alt="alert-logo"
          class="mr-20"
        />
        <div class="ml-10">
          <h4 class="m-0 text-size-18 text-bold"
          [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
            {{alertType === "error-box"
            ? "Error"
            : alertType === "warning-box"
            ? "Warning"
            : alertType === "success-box"
            ? "Success"
            : null}}
          </h4>
          <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
           {{message}}
          </small>
        </div>
      </div>
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
        alt="close-btn"
        (click)="hideError()"
        className=" close-btn"
      />
    </div>
  </div>
  
  <div class="modal-body">
    <form [formGroup]="masterForm" style="margin-top: -10px">
        <div class="form-group text-input-field">
            <select class="input-field" formControlName="parent_id" [ngClass]="{'error-field': (!masterForm.controls.parent_id.valid && submitted),
                'success-field' : masterForm.controls.parent_id.valid && masterForm.controls.parent_id.dirty}">
                <option selected disabled>SELECT</option>
                <ng-container *ngIf="groupList != undefined">
                <option *ngFor="let item of groupList" value="{{item.id}}">{{item.name}}</option>
                </ng-container>
            </select>
            <label
                [ngClass]="{'error-label': masterForm.controls.parent_id.invalid && masterForm.controls.parent_id.dirty || (!masterForm.controls.parent_id.valid && submitted)}">Group
                <ng-container
                *ngIf="masterForm.controls.parent_id.invalid && masterForm.controls.parent_id.dirty || (!masterForm.controls.parent_id.valid && submitted)">
                <i class="fa fa-info-circle ml-2" title="Group Required" aria-hidden="true"></i>
                </ng-container>
            </label>
        </div>

        <div class="form-group text-input-field">
            <div class="text-input-field mr-2" (focus)="focusAccountAc()" >
                <input class="input-field" oninput="this.value = this.value.toUpperCase()" id="master_name" name="name" #accountAc
                  formControlName="name" type="text" [ngClass]="{'error-field': masterForm.controls.name.invalid && masterForm.controls.name.dirty  || (!masterForm.controls.name.valid && submitted),
                    'success-field' : masterForm.controls.name.valid && masterForm.controls.name.dirty}" />
                <label
                  [ngClass]="{'error-label': masterForm.controls.name.invalid && masterForm.controls.name.dirty || (!masterForm.controls.name.valid && submitted)}">Name
                  <span class="text-danger">*</span>
                  <ng-container
                    *ngIf="masterForm.controls.name.invalid && masterForm.controls.name.dirty || (!masterForm.controls.name.valid && submitted)">
                    <i class="fa fa-info-circle ml-2" title="Account Required" aria-hidden="true"></i>
                  </ng-container>
                </label>
              </div>
        </div>

        <div class="text-input-group d-flex justify-content-between" *ngIf="masterType == 'airline'">
          <div style="width: 200px;" class="text-input-field mr-2">
            <input class="input-field"  oninput="this.value = this.value.toUpperCase()"
              formControlName="2_ltr_code" type="text" />
            <label>2 LTR-CODE</label>
          </div>
          <div style="width: 200px;" class="text-input-field mr-2">
            <input  class="input-field" oninput="this.value = this.value.toUpperCase()"
              formControlName="3_ltr_code" type="text" />
            <label>3 LTR-CODE</label>
          </div>
          <div style="width: 250px;" class="text-input-field">
            <input class="input-field"  oninput="this.value = this.value.toUpperCase()"
            value ="{{masterForm.value['2_ltr_code'] + masterForm.value['3_ltr_code']}}" type="text"  disabled />
            <label>Airline Code</label>
          </div>
          </div>
  
       
      
        <div *ngIf="['client', 'supplier', 'hotel'].includes(masterType)">
          <div class="form-group text-input-field">
              <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="address_1" 
                formControlName="address_1" type="text" [ngClass]="{'error-field': masterForm.controls.address_1.invalid && masterForm.controls.address_1.dirty || (!masterForm.controls.address_1.valid && submitted),
                  'success-field' : masterForm.controls.address_1.valid && masterForm.controls.address_1.dirty }" />
              <label
                [ngClass]="{'error-label': masterForm.controls.address_1.invalid && masterForm.controls.address_1.dirty || (!masterForm.controls.address_1.valid && submitted)}">Address
      
                <ng-container
                  *ngIf="masterForm.controls.address_1.invalid && masterForm.controls.address_1.dirty || (!masterForm.controls.address_1.valid && submitted)">
                  <i class="fa fa-info-circle ml-2" title="Required Address" aria-hidden="true"></i>
                </ng-container>
              </label>
      
            </div>

            <div class="text-input-group d-flex justify-content-between">
              <div class="text-input-field mr-2">
                <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="gst_number" 
                  formControlName="gst_number" type="text" maxlength="15"
                  pattern="^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$"
                  [ngClass]="{'error-field': masterForm.controls.gst_number.invalid && masterForm.controls.gst_number.dirty || (!masterForm.controls.gst_number.valid && submitted),
                  'success-field' : masterForm.controls.gst_number.valid && masterForm.controls.gst_number.dirty}" />
                <label
                  [ngClass]="{'error-label': masterForm.controls.gst_number.invalid && masterForm.controls.gst_number.dirty} || (!masterForm.controls.gst_number.valid && submitted)">
                  GST NUMBER
                  <ng-container
                    *ngIf="masterForm.controls.gst_number.invalid && masterForm.controls.gst_number.dirty || (!masterForm.controls.gst_number.valid && submitted)">
                    <i class="fa fa-info-circle ml-2" title="Invalid GST Number ( ex: 12ABCDE1234F1Z3)"
                      aria-hidden="true"></i>
                  </ng-container>
                </label>
              </div>
              <div class="text-input-field">
                <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="gst_expiry_date" 
                  formControlName="gst_expiry_date" type="date"
                  [ngClass]="{'error-field': masterForm.controls.gst_expiry_date.invalid && masterForm.controls.gst_expiry_date.dirty  || (!masterForm.controls.gst_expiry_date.valid && submitted),
                    'success-field' : masterForm.controls.gst_expiry_date.valid && masterForm.controls.gst_expiry_date.dirty}" />
                <label
                  [ngClass]="{'error-label': masterForm.controls.gst_expiry_date.invalid && masterForm.controls.gst_expiry_date.dirty}  || (!masterForm.controls.gst_expiry_date.valid && submitted)">Expiry
                  Date
                  <ng-container
                    *ngIf="masterForm.controls.gst_expiry_date.invalid && masterForm.controls.gst_expiry_date.dirty  || (!masterForm.controls.gst_expiry_date.valid && submitted)">
                    <i class="fa fa-info-circle ml-2" title="required expiry date" aria-hidden="true"></i>
                  </ng-container>
                </label>
              </div>
            </div>

            <div class="text-input-group d-flex justify-content-between">
              <div class="text-input-field mr-2">
                  <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="mobile_no" minlength="10"
                    maxlength="10"  formControlName="mobile_no" type="text" pattern="([0-9]+)" [ngClass]="{'error-field': masterForm.controls.mobile_no.invalid && masterForm.controls.mobile_no.dirty  || (!masterForm.controls.mobile_no.valid && submitted),
                  'success-field' : masterForm.controls.mobile_no.valid && masterForm.controls.mobile_no.dirty}" />
                  <label
                    [ngClass]="{'error-label': masterForm.controls.mobile_no.invalid && masterForm.controls.mobile_no.dirty  || (!masterForm.controls.mobile_no.valid && submitted)}">Mobile
                    No.
                    <ng-container
                      *ngIf="masterForm.controls.mobile_no.invalid && masterForm.controls.mobile_no.dirty  || (!masterForm.controls.mobile_no.valid && submitted)">
                      <i class="fa fa-info-circle ml-2" title="Invalid Mobile Number" aria-hidden="true"></i>
                    </ng-container>
                  </label>
              </div>
              <div class="text-input-field">
                <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="primary_email" 
                  formControlName="primary_email" type="text" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$"
                  [ngClass]="{'error-field': masterForm.controls.primary_email.invalid && masterForm.controls.primary_email.dirty  || (!masterForm.controls.primary_email.valid && submitted),
                'success-field' : masterForm.controls.primary_email.valid && masterForm.controls.primary_email.dirty}" />
                <label
                  [ngClass]="{'error-label': masterForm.controls.primary_email.invalid && masterForm.controls.primary_email.dirty  || (!masterForm.controls.primary_email.valid && submitted)}">Email
                  #1<ng-container
                    *ngIf="masterForm.controls.primary_email.invalid && masterForm.controls.primary_email.dirty  || (!masterForm.controls.primary_email.valid && submitted)">
                    <i class="fa fa-info-circle ml-2" title="Invalid Email" aria-hidden="true"></i>
                  </ng-container>
                </label>
      
              </div>
            </div>
          </div>
          <div class="text-input-group d-flex justify-content-between" *ngIf="masterType == 'client'">
            <div class="text-input-field mr-2">
              <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="5"
                formControlName="state_id" (change)="getCities($event.target.value)">
                <option *ngFor="let state of stateArr" value="{{state.location_id}}">{{state.name}}</option>
              </select>
              <label>State</label>
            </div>
            </div>
        
        <div class="mt-3 d-flex justify-content-center align-item-center">
        <button type="button" class="btn-style bg-red mr-2" data-dismiss="modal">
            Cancel
        </button>
        <button
            type="button"
            class="btn-style bg-green"
            (click)="openConfirmationModal()"
        >
            Save
        </button>
        </div>

    </form>
</div>
   

<button type="button" hidden #confirmCreateModal data-toggle="modal" data-target="#createConfirmModal"></button>

<div class="modal fade" id="createConfirmModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Confirmation</h4>
                <button type="button" class="close" #closeCreateModal  aria-label="Close" (click)="openConfirmationModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>Create {{masterType}}</b> ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-style bg-red mr-2"  (click)="openConfirmationModal()">No</button>
                <button type="button" class="btn-style bg-blue" (click)="saveMaster()">Yes</button>
            </div>
        </div>
    </div>
</div>
