import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { CookiesService } from 'src/app/shared/cookies.service';

@Component({
	selector: 'app-create-client',
	templateUrl: './create-client.component.html',
	styleUrls: ['./create-client.component.scss']
})
export class CreateClientComponent implements OnInit {

	submitted = false;
	baseUrl = environment.okountUrl;
	clientForm: FormGroup;
	groupList: any;
	showMessage: boolean = false;
	alertType = '';
	message: string = ``;
	countryArr: any = [];
	stateArr: any = [];
	cityArr: any = [];
	salesPersonArr: any;
	keyword = 'name';
	createdClientId = "";
	createdClientCode = "";
	newClient : boolean = true
	isTenantIndian: boolean = false;
	isTesoyBoult: boolean  = false;
	parentClients: any;
	isHQBranch: boolean = false;
	isHQCompany: boolean = false;
	 remainingAmount = 0
    amountType = 'credit'
	vatAlternetname: string = 'VAT'

	@ViewChild('salesPersonAC',{static : true}) salesPersonAC;
	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef
	@ViewChild("openSuccessModal", { static: true }) openSuccessModal: ElementRef;
	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef;
    @ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef;
	branches = []
	companies = []
	constructor(public commonService: CommonServiceService, private fb: FormBuilder, private spinner: NgxSpinnerService, private router: Router, private cookieService : CookiesService) {
	}


	async ngOnInit() {
		

		
		this.isTenantIndian = this.commonService.checkTenantIndian()
		this.spinner.show();
		setTimeout(() => {
			this.spinner.hide();
		}, 1000);
		this.clientForm = this.fb.group({
			parent_id: ['28', Validators.required],
			account_code : [''],
			address_1: [''],
			address_2: [''],
			address_3: [''],
			city_id: [''],
			city_name: [''],
			state_id: [''],
			state_name: [''],
			country_id: ['101'],
			country: [''],
			gst_number: [''],
			gst_expiry_date: [''],
			vat_number: [''],
			telephone: [''],
			mobile_no: [''],
			primary_email: [''],
			secondary_email: [''],
			pan_number: [''],
			balance: [''],
			remarks: [''],
			name: ['', [Validators.required]],
			account_legal_name: [''],
			contact_person: [''],
			days: [''],
			balance_type: ['debit'],
			sales_person: [''],
			sales_person_id: [''],
			sales_person_name: [''],
			sub_ledger: ['CC001A'],
			client_type: ['master'],
			parent_account_code : [''],
			sync_in_branches: [''],
			currency_rate:[''],
			local_balance: [''],
			currency:[''],
			is_memo_account:[false],
			is_branch:[false],
			branch:{},
			company:{}
		})
		
		
		this.getGroups()
		this.getCountries()
		this.getStates('101')
		
		
		
		this.isTesoyBoult  = this.commonService.checkTesoyBoult()
		this.isHQCompany = this.commonService.checkHQCompany()
		if(this.isHQCompany==true){
			this.getAllCompanys()
		}
		this.isHQBranch = this.commonService.checkHQBranch()
		if(this.isHQBranch==true){
			this.getAllBranches()
		}
		if(!this.isTenantIndian){
			let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
			let inv_config = globalSettingConfig.filter(el=>el.group=='VAT')
		if(inv_config.length){
			let vat_config = inv_config[0].json_doc.value.filter(el=>el.key=='vat_rate')
			let vat_detail,vat_name
			if(vat_config.length){
				 vat_detail = vat_config[0].value
			}
			if(vat_detail && vat_detail.length ){
				vat_name = vat_detail.filter(el=>el.key=='effective_name')
			}
			if(vat_name && vat_name.length){
				this.vatAlternetname = vat_name[0].value
			}
		}
		}
		
	}
	
	openConfirmModal() :  void{
		this.confirmModal.nativeElement.click()
		this.commonService.selectedInput.next(21)
	}
	closeConfirmModal() :  void{
		this.closeModal.nativeElement.click()
	}
	focusSalesPerson(e): void {
		e.stopPropagation();
		this.salesPersonAC.focus();
	}
	getCountries() {
		this.commonService.getOkount(`location/country`).subscribe(res => {
			this.countryArr = res
		})
	}

	getStates(country_id) {
		let selectedCountry = this.countryArr.filter(option => {
			return option.location_id == Number(country_id);
		})
		if (selectedCountry.length > 0) {
			this.clientForm.controls.country.setValue(selectedCountry[0].name)
		} else {
			this.clientForm.controls.country.setValue('India')
		}
		console.log(selectedCountry)
		this.commonService.getOkount(`location/states?country_id=${country_id}`).subscribe(res => {
			this.stateArr = res
		})
	}
	getCities(state_id) {
		let selectedState = this.stateArr.filter(option => {
			return option.location_id == Number(state_id);
		})
		if (selectedState.length > 0) {
			this.clientForm.controls.state_name.setValue(selectedState[0].name)
		}
		this.commonService.getOkount(`location/city?state_id=${state_id}`).subscribe(res => {
			this.cityArr = res
		})
	}
	getCityName(city_id) {
		let selectedCity = this.cityArr.filter(option => {
			return option.location_id == Number(city_id);
		})
		if (selectedCity.length > 0) {
			this.clientForm.controls.city_name.setValue(selectedCity[0].name)
		}
	}

	reset() {
		this.clientForm.reset();
	}

	submit() {

		this.submitted = true;
		this.closeConfirmModal()
		if (this.clientForm.valid) {
			let formValues = this.clientForm.value
			if (formValues.sales_person_name && formValues.sales_person_name.id != undefined) {
				formValues['sales_person_id'] = formValues.sales_person_name.id
				formValues['sales_person'] = formValues.sales_person_name.account_code
				formValues['sales_person_name'] = formValues.sales_person_name.name
			}
			// console.log('form is working')
			console.log('form is working')
			// if
			this.commonService.postRequest(`${this.baseUrl}/client`, this.clientForm.value).subscribe(res => {
				this.updateOpeningBalanceAdjustAcc()
				this.showMessage = false
				this.createdClientId = res[0].id ? res[0].id : ""
				this.createdClientCode = res[0].account_code ? res[0].account_code : ""
				this.reset()
				this.newClient = false
				this.alertType = 'success-box';
				this.message = 'Client saved successfully with account code. :'
				this.openSuccessModal.nativeElement.click()
			}, error => {
				this.showMessage = true
				this.alertType = 'error-box';
				this.createdClientId = ""
				this.message = 'Failed to create Client. Please try Again.'
				if(error.error && error.error.message=='duplicate key value violates unique constraint \"client_name_idx\"') this.message = 'CLIENT ALREADY EXIST'
			})
		}
		else {
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = 'Please fill mandatory fields.'
			return false;
		}
	}



	getGroups() {
		this.commonService.getOkount('v3account/getallsuperparents').subscribe(res => {
			this.groupList = res

			if(this.groupList && this.groupList.length > 0) {
				let item = this.groupList.find(item => item.account_code == 'SA0000005')
				this.clientForm.controls.parent_id.setValue(item.id)
			}

		}, err => {

		})
	}
	hideError() {
		this.showMessage = false
	}
	getSalesPerson(value) {
		if (value.length > 2) {
			this.commonService.getOkount(`employee/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.salesPersonArr = res
			}, err => {

			})
		}
	}

	selectSalesPerson(value) {
		try {
			this.clientForm.controls['sales_person'].setValue(value.account_code)
			this.clientForm.controls['sales_person_id'].setValue(value.id)
			this.clientForm.controls['sales_person_name'].setValue(value.name)
		} catch (error) {

		}
	}


	closeSuccesModal(){
		this.reset()
		this.newClient = true
		this.showMessage = false;
		this.alertType = 'success-box';
		this.message = ``
		this.openSuccessModal.nativeElement.click()
	}

	viewClient(client_id) {
		this.closeSuccesModal()
        this.router.navigate([`/master/client/update-client/${client_id}`])
	}

	onChangeClient(event) {
		let value = event.target.value;
		if(value == 'child') {
			this.clientForm.controls.parent_account_code.setValidators(Validators.required)
			this.clientForm.updateValueAndValidity();
			this.commonService.getOkount(`client/parents`).subscribe(res => {
				if(res && res['data']) {
					this.parentClients = res['data']
				}
			}, err => {

			})
		}else {
			this.clientForm.controls.parent_account_code.setValue('')
			this.clientForm.controls.parent_account_code.setErrors(null)
			this.clientForm.controls.parent_account_code.setValidators(null)
			this.clientForm.updateValueAndValidity();
		}
	}


	changeCurrentAmount(event) {
		let currency = this.clientForm.controls.currency.value;
		let currency_rate = this.clientForm.controls.currency_rate.value;
		if(currency && currency_rate) {
			let local_balance = this.clientForm.controls.local_balance.value
			this.clientForm.controls.balance.setValue((Number(local_balance) / currency_rate).toFixed(2));
		} else {
			this.clientForm.controls.balance.setValue('')
		}
	}

	currencyChange(value){
		this.clientForm.controls.currency_rate.setValue('');
	}

	getAllBranches() {
        this.commonService.getOkount(`branch`).subscribe(res=> {
          if(res && res['data']) {
            this.branches = res['data'];
			let formObj = this.branches.reduce((acc,curr)=>{
				if(curr.branch_type=='BRANCH'){
					acc[curr.branch_name]=false
				}
				return acc
			},{})
			console.log("formObj",formObj)
			this.clientForm.controls.branch.setValue(formObj)
          }
        },err=> {
    
        })
      }

	  getAllCompanys() {
        this.commonService.getOkount(`company`).subscribe(res=> {
          if(res && res['data']) {
            this.companies = res['data'];
			let formObj = this.companies.reduce((acc,curr)=>{
				if(curr.associate_company_id!==null){
					acc[curr.tenant_company_name]=false
				}
				return acc
			},{})
			console.log("formObj",formObj)
			this.clientForm.controls.company.setValue(formObj)
          }
        },err=> {
    
        })
      }

	  handleValueChange(e,branchName){
		console.log("branchName",this.clientForm.controls.branch)
		this.clientForm.controls.branch.value[branchName]=e.currentTarget.checked
	  }

	  handleCompanyChange(e,companyName){
		console.log("companyName",this.clientForm.controls.company)
		this.clientForm.controls.company.value[companyName]=e.currentTarget.checked
	  }

	  async checkBalance(){
        let opening_amount = await  this.getOpeningBalanceData()
        if(this.clientForm.controls.balance_type.value=='debit') opening_amount['debit']= Number(opening_amount['debit']) + Number(this.clientForm.controls.balance.value)
        if(this.clientForm.controls.balance_type.value=='credit') opening_amount['credit']= Number(opening_amount['credit']) + Number(this.clientForm.controls.balance.value)
            console.log("opening_amount",opening_amount)
        if(opening_amount && (opening_amount['debit']!=opening_amount['credit'])) {
            this.remainingAmount = Math.abs(Number((opening_amount['debit'] - opening_amount['credit']).toFixed(2)))
            this.amountType = opening_amount['debit']>opening_amount['credit'] ? 'credit' : 'debit'
            let res = await this.adjustAccOpeningBalance()
            if(res['balance']!=this.remainingAmount || this.amountType!=res['balance_type']){
                this.openUpdateConfirmModal()
			}else{
				this.updateOpeningBalancecombine()
			}
        }else{
            this.updateOpeningBalancecombine()
        }
    }

	adjustAccOpeningBalance(){
        return new Promise(async (resolve, reject) => {
        this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/globalsearch/opening_balance`, {client: 'OB0000165'}).subscribe(res => {
            resolve(res)
        },err=>{
            console.log("err",err)
            resolve(null)
        })
    })
    }

	getOpeningBalanceData(){
        return new Promise(async (resolve, reject) => {
            this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/openingBalanceTotal`, {}).subscribe(res => {
                resolve(res)
            },err=> {
                resolve(null)
            })
        })
    }
	openUpdateConfirmModal(){
		this.confirmUpdateModal.nativeElement.click()
	}
	 updateOpeningBalancecombine(){
        this.closeUpdateConfirmModal()
         this.submit()
    }
	closeUpdateConfirmModal(){
		this.closeUpdateModal.nativeElement.click()
	}
	updateOpeningBalanceAdjustAcc() {
        let req_body = {
            balance: this.remainingAmount,
            balance_type: this.amountType,
            account_code: 'OB0000165'
        }
        this.commonService.putRequest(`${environment.okountUrl}/client/updateOpeningBalanceAdjust`, req_body).subscribe(res => {
           
            console.log(res)
        }, err => {
        })

    }
	 
	 
}
