<form [formGroup]="sectorService.invoiceForm" class="ledger-container">
	<div>
		<div class="invoice-container invoice-table">
			<!-- <div class="heading">INVOICE</div> -->
			<table class="table table-hovered table-bordered">
				<thead>
					<tr>
						<th>CRS ID</th>
						<th>Retrieve Id</th>
						<th>Inv. Type</th>
						<th>PNR</th>
						<th>Airline</th>
						<th>Sub Ledger Type</th>
						<th>Sub-ledger</th>
						<th>Fare Basis</th>
						<th>Deal Code</th>
						<th>Employee</th>
						<th  class="text-left">SALES PERSON</th>
						<th  class="text-left">COST CENTER</th>
						<th>Traveller</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<select tabIndex="{{isTenantIndian==false?5:3}}" attr.tabIndex="{{isTenantIndian==false?5:3}}" style="    width: 100%;" class="inner-select"
								(change)="getSupplier($event.target.value)" formControlName="crsId">
								<option *ngFor="let crs of crdIdArr" value="{{crs}}">{{crs}}</option>
							</select>
						</td>
						<td>
							<select tabIndex="{{isTenantIndian==false?6:4}}" attr.tabIndex="{{isTenantIndian==false?6:4}}" class="inner-select" formControlName="supplier"
								(change)="onSupplierChange($event.target.value)">
								<option *ngFor="let item of supplierArr" value="{{item.display_name}}">
									{{item.display_name}}
								</option>
							</select>
						</td>
						<td>
							<select tabIndex="{{isTenantIndian==false?7:5}}" attr.tabIndex="{{isTenantIndian==false?7:5}}" class="inner-select" (change)="onInvoiceTypeChange($event.target.value)" formControlName="invoiceType">
								<option value="B">BSP</option>
								<option value="C">C-R</option>
								<option value="X">X-O</option>
							</select>
						</td>
						<td>
							{{sectorService.invoiceForm.value.pnr}}
						</td>
						<td>
							<div class="autocomplete-container">
								<div class="input-container" tabIndex="{{isTenantIndian==false?8:6}}" attr.tabIndex="{{isTenantIndian==false?8:6}}">
									<input #airlineAC 
										type="text" id=airlineAutoComplete
										(focus)="focusAirlineAc()"
										oninput="this.value = this.value.toUpperCase()" 
										name="airline_name"
										formControlName="airline_name"
										(input)="airlineSearch($event,true)"
										[nzAutocomplete]="airlineAuto"
									/>

									<nz-autocomplete #airlineAuto [nzBackfill]="true" (selectionChange)="selectAccount($event,'airline')" >
										<nz-auto-option  class="colr-1" *ngFor="let item of airlineArr" [nzValue]="item" [nzLabel]="item.name">
											<a
												innerHTML='<span class="colr-1 selected-ng">{{item.name}} ({{item.account_code}})</span>'>
											</a>                            
										</nz-auto-option>                      
									</nz-autocomplete>
						     	</div>
							</div>
						</td>
						<td>
							<select class="inner-select" tabIndex="{{isTenantIndian==false?9:7}}" attr.tabIndex="{{isTenantIndian==false?9:7}}" formControlName="sub_ledger_code_1_type"
								(change)="getAccounts($event.target.value)">
								<option value="">None</option>
								<option value="client">Client</option>
								<option value="airline">Airline</option>
								<option value="supplier">Supplier</option>
								<option value="general">General</option>
							</select>

						</td>
						<td>
							<div class="autocomplete-container">
								<div class="input-container">
									<input #subLedgerAC tabIndex="{{isTenantIndian==false?10:8}}" attr.tabIndex="{{isTenantIndian==false?10:8}}"
										type="text"
										(focus)="focusSubledgerAc()"
										oninput="this.value = this.value.toUpperCase()" 
										name="sub_ledger_code_1_name"
										formControlName="sub_ledger_code_1_name"
										(input)="ledgerSearch($event,true)"
										[nzAutocomplete]="subLedgerAC"
									/>

									<nz-autocomplete #subLedgerAC [nzBackfill]="true" (selectionChange)="selectAccount($event,'sub_ledger_code_1')" >
										<nz-auto-option  class="colr-1" *ngFor="let item of sectorService.ledgerList" [nzValue]="item" [nzLabel]="item.name">
											<a
												innerHTML='<span class="colr-1 selected-ng">{{item.name}} ({{item.account_code}})</span>'>
											</a>                            
										</nz-auto-option>                      
									</nz-autocomplete>
								</div>
							</div>

						</td>
						<td>
							{{sectorService.invoiceForm.value.fareBasis}}
						</td>
						<td>
							{{sectorService.invoiceForm.value.dealCode}}
						</td>
						<td>
							{{sectorService.invoiceForm.value.employee_name}}
						</td>
						<td>AKASH sHARMA
						</td>
						<td>Finance and Accounting
						</td>
						<!-- <td><input  oninput="this.value = this.value.toUpperCase()" type="text" class="field-input" formControlName="payable" /></td> -->
						<td>
							{{sectorService.invoiceForm.value.travellerCount}}
						</td>
					</tr>
				</tbody>
			</table>
			<app-sector-info-refund [sectorService]="sectorService"></app-sector-info-refund>
			<app-traveller-info-refund (callSaveTraveller)="saveProductInvoice()" [invoiceNumber]="invoice_no"
				[invoice_root_data]="invoice_root_data" [sectorService]="sectorService"></app-traveller-info-refund>
			<app-total-fare-info-refund [sectorService]="sectorService"></app-total-fare-info-refund>
			<!-- <div class="traveller-action-col d-flex justify-content-center mt-4">
				<button class="action-btn mb-5" (click)="saveProductInvoice()"
					[ngClass]="{disbaledBtn: sectorService.travellerForm.invalid || sectorService.invoiceForm.invalid || sectorService.sectorForm.invalid}"
					[disabled]="sectorService.travellerForm.invalid || sectorService.invoiceForm.invalid || sectorService.sectorForm.invalid">
					Save
				</button>

				<button (click)="closeMaxModule()" class="action-btn mb-5 ml-3">
					Cancel
				</button>
			</div> -->

		</div>
	</div>
</form>