import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonServiceService } from '../../common-service.service';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';

@Component({
  selector: 'app-invoice-credit-note-search',
  templateUrl: './invoice-credit-note-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class InvoiceCreditNoteSearchComponent implements OnInit {
  modalRef: BsModalRef;
	invoiceFilterForm: FormGroup;
	invoiceArr: any;
	clientArr: any;
	keyword = 'name';
	disableField: boolean;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	@ViewChild('clientAC',{static:true}) clientAC;
	minDate = "2023-04-01"
    maxDate = moment().format('YYYY-MM-DD')
	constructor(
		private modalService: BsModalService, 
		private formBuilder: FormBuilder, 
		public commonService: CommonServiceService, 
		private spinner: NgxSpinnerService,
		private router: Router,
		private store: Store<InitialState>) {
			
			this.store.select('sharedReducers').subscribe((items)=>{
				if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
				    // this.maxDate = items.financeYear.end_date
				}
				
		})
	}

	ngOnInit() {

		// this.spinner.show();
		// setTimeout(() => {
		// 	this.spinner.hide();
		// }, 1000);


		this.invoiceFilterForm = this.formBuilder.group({
			client: [''],
			client_name:[''],
			date: [''],
			invoice_no: [''],
			search_year: ['2020'],
			booking_ref_no: [''],
			pnr: [''],
			ticket_no: [''],
			pax_name: [''],
			narration: [''],
			from_amount: [''],
			to_amount: ['']
		})
		this.setFormData()
	}

	 
	  

	setFormData() {
		let queryParams = localStorage.getItem('activeInvoiceQueryParams')
		
		if (queryParams != null) {
			let parsedParams = JSON.parse(queryParams)
			console.log('Active Query Params', parsedParams)
			// this.invoiceFilterForm.controls.client.setValue(parsedParams.client)
		    this.getClient(parsedParams.client ? parsedParams.client : "",true)

			if (parsedParams.date != "") {
				this.invoiceFilterForm.controls.date.setValue(moment(parsedParams.date).format('YYYY-MM-DD'))
			}
			this.invoiceFilterForm.controls.invoice_no.setValue(parsedParams.invoice_no?parsedParams.invoice_no:"")
			this.invoiceFilterForm.controls.booking_ref_no.setValue(parsedParams.booking_ref_no?parsedParams.booking_ref_no:"")
			this.invoiceFilterForm.controls.pnr.setValue(parsedParams.pnr?parsedParams.pnr:"")
			this.invoiceFilterForm.controls.ticket_no.setValue(parsedParams.ticket_no?parsedParams.ticket_no:"")
			this.invoiceFilterForm.controls.pax_name.setValue(parsedParams.pax_name?parsedParams.pax_name:"")
			this.invoiceFilterForm.controls.narration.setValue(parsedParams.narration?parsedParams.narration:"")
			this.invoiceFilterForm.controls.from_amount.setValue(parsedParams.from_amount?parsedParams.from_amount:"")
			this.invoiceFilterForm.controls.to_amount.setValue(parsedParams.to_amount?parsedParams.to_amount:"")
		}
	  }

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'customModal' })
		);
	}

	getClient(value,bindValue=false) {
		if (value.length > 2) {
			this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				debugger
				this.clientArr = res
				if(bindValue && this.clientArr.length>0){
					this.invoiceFilterForm.controls['client'].setValue(this.clientArr[0].account_code)
					this.invoiceFilterForm.controls['client_name'].setValue(this.clientArr[0].name)
				}
			}, err => {
	
			})
		}
	}

	reset() {
		GlobalVariable.openModalStatus = false;
		this.invoiceFilterForm = this.formBuilder.group({
			client: [''],
			clinet_name:[''],
			date: [''],
			search_year: [''],
			invoice_no: [''],
			booking_ref_no: [''],
			pnr: [''],
			ticket_no: [''],
			pax_name: [''],
			narration: [''],
			from_amount: [''],
			to_amount: ['']
		})
	  }

	search(values){
		debugger
		GlobalVariable.openModalStatus = false;
		if (values.date == null || values.date == "" || values.date == "Invalid Date" || values.date == "Invalid date") {
			values.date = ""
		}
		else{
			values.date = moment(values.date).format('YYYY-MM-DD')
		}
		let clientCode= values.client
		if (values.client.account_code != undefined) {
			clientCode= values.client.account_code
		} 
		let isPreviousYear = false
		let url = `search_year=${values.search_year}&client=${clientCode}&date=${values.date}&invoice_no=${values.invoice_no}&booking_ref_no=${values.booking_ref_no}&pnr=${values.pnr}&ticket_no=${values.ticket_no}&pax_name=${values.pax_name}&narration=${values.narration}&from_amount=${values.from_amount}&to_amount=${values.to_amount}`
		if (values.search_year == '2019') {
			isPreviousYear = true
		}
		this.searchInvoice(url,isPreviousYear)
		// this.router.navigateByUrl(`transactions/search-invoice?search_year=${values.search_year}&client=${clientCode}&date=${values.date}&invoice_no=${values.invoice_no}&booking_ref_no=${values.booking_ref_no}&pnr=${values.pnr}&ticket_no=${values.ticket_no}&pax_name=${values.pax_name}&narration=${values.narration}&from_amount=${values.from_amount}&to_amount=${values.to_amount}`)
		// this.reset()
	}

	searchInvoice(url, isPreviousYear) {
		this.spinner.show()
		let endPoint = `globalsearch/invoice`
		if (isPreviousYear) {
			endPoint = 'invoice/previousInvioce'
		}
		this.commonService.getRequest(`${environment.okountUrl}/${endPoint}?${url}`).subscribe(res => {
			this.invoiceArr = res['data']
			this.spinner.hide()
			if (this.invoiceArr.length == 1) {
				this.router.navigateByUrl(`/transactions/credit-note/create/${this.invoiceArr[0].invoice_no}?isPreviousYear=${isPreviousYear}&isManual=true`)
				// this.router.navigate(['/transactions/credit-note', 'create', this.invoiceArr[0].invoice_no])
			} else {
				this.router.navigateByUrl(`transactions/search-invoice?${url}`)
			}
		})
	}
	onYearChange(){
		if (this.invoiceFilterForm.value.search_year == '2019') {
			this.disableField = true
		}else{
			this.disableField = false
		}

	}
	ngAfterViewInit(){
		this.commonService.selectedSearchInput.subscribe(index=>{
			if (index == 1) {
				this.focusClientAc()
			}
		})
	}

	selectAccount(value,formControlName){
		try {
			this.invoiceFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}
	
	focusClientAc(){
		this.clientAC.focus();
	}

	clickSearchButton(){
		this.searchButton.nativeElement.click()
	}
	clearData(formControl){
		this.invoiceFilterForm.controls[formControl].setValue("")
	}
}
