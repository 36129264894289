import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
 import { PrintConsolidateInvoiceComponent } from './print-consolidate-invoice/print-consolidate-invoice.component'; 
import { ConsolidateInvoiceComponent } from './consolidate-invoice.component'; 


const routes: Routes = [
	
	{
		path: ':consolidationType/print', component: PrintConsolidateInvoiceComponent
	},
	{
		path: ':consolidationType/:viewMode/:invoiceNumber', component: ConsolidateInvoiceComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ConsolidateInvoiceRoutingModule { }
