<div class="ledger-container detailed-ledger rotated_class">
    <div class="voucher-container">
      <div class="row tab-nav pl-0 pr-0">
      <div class="col-sm-4">
        <ul
          class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0"
        >
          <li
            [routerLink]="['/']"
            class="text-grey text-capitalize cursor hover-text-primary transition"
            [ngClass]="{ 'd-none': isPrint == 'true' }"
          >
            Analysis
          </li>
          <li>
            <img
              src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg"
              alt=""
              [ngClass]="{ 'd-none': isPrint == 'true' }"
            />
          </li>
          <li
            class="text-grey text-capitalize cursor text-primary transition"
            [ngClass]="{ 'd-none': isPrint == 'false' }"
            [ngClass]="{ 'd-none': isPrint == 'true' }"
          >
            TDS REPORT (DETAILED)  
          </li>
        </ul>
      </div>
      <div class="col-sm-4 text-center">
        <span class="rec-date d-block text-center">
          <div class="noWrap justify-content-around">
            <ng-container *ngIf="duration != undefined">
                  <div class="cname">From
                      <h5 style="margin-right: 5px;" class="mb-0  d-inline"><span class="date text-bold text-orange"
                              *ngIf="duration.start_date != ''">
                              {{duration?.start_date | date : 'd MMMM, y'}} 
                          </span>
                          <span class="date text-bold" *ngIf="duration.start_date != ''">
                              To
                          </span>
                          <span class="date text-bold" *ngIf="duration.start_date == ''">
                              Till Date
                          </span>
                          <span class="date text-bold text-orange">
                              {{duration?.end_date | date : 'd MMMM, y'}}
                          </span>
                      </h5>
                      <button
                      [ngClass]="{ 'd-none': isPrint == 'true' }"
                      title="Custom Search"
                      class="btn save-as text-bold bg-whiteSmoke"
                      type="button"
                      (click)="openAdvanceSearchModal()"
                      >
                      <img
                        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg"
                        alt=""
                        width="19px"
                      />
                    </button>
                  </div>
                </ng-container>
          </div>
        </span>
      </div>
      <div class="col-sm-4 d-flex align-item-center justify-content-end">
        <div
        class="page-search-box input-group d-flex align-item-center" 
      [ngClass]="{ 'd-none': isPrint == 'true' }"
        style="height: fit-content; text-align: left"
      >
      <input
      type="text"
      class="form-control"
      oninput="this.value = this.value.toUpperCase()"
      placeholder="search"
      style="text-align: left"
    />
      </div>
    
        
        <div class="dropdown-btn position-relative" 
        [ngClass]="{ 'd-none': isPrint == 'true' }">
          <button title="More..." class="btn-style mt-0 more-btn">
            <span class="dot"></span><span class="dot"></span
            ><span class="dot"></span>
          </button>
          <ul class="list-unstyled pl-0 dropdown-box">
            <li (click)="openSavePdfModal()">
                <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg" alt="" />
                <span class="ml-2">Save As Pdf</span>
              </li>
            
            <li (click)="exportAsXLSX(duration?.start_date, duration?.end_date)" >
              <img
                src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg"
                alt=""
              />
              <span class="ml-2">Excel</span>
            </li>
          </ul>
        </div>
      </div>
      </div>

      <div  class="table-section detledger-table" [ngClass]="{'table-print-section': isPrint == 'true'}">
        <div class="table" style="margin-top: -2px; height: auto!important;">
            <table class="table table striped" >
                <thead>
                    <tr *ngIf="isPrint" style="border-bottom: none!important;">
                        <th colspan="15" class="page-top-space">
                        </th>
                      </tr>
                    <tr>
                        <th class="text-left date" style="min-width: 105px;" >Date </th>
                        <th class="text-left doc-no" style="min-width: 155px;" >DOC NO</th>
                        <th class="text-left">Invoice Type </th>
                        <th class="text-left" >Service Type </th>
                        <!-- <th class="text-left">Added By</th>
                        <th class="text-left">Edit By</th> -->
                        <th class="text-left" >TDS Rate </th>
                        <th class="text-right hc-amt">amount </th>
                        <th class="text-right tds-amt">TDS Amount(Credit) </th>
                        <th class="text-right tds-amt">TDS Amount (Debit) </th>

                    </tr>
                </thead>

              <tbody class="scroll-body main-panel" [ngClass]="{'full-height-table': isPrint == 'true'}"  [ngStyle]="{height: innerHeight}"
                        (window:keydown)="onKeyDown($event)" *ngIf="(leaderData && !onSearch) || (leaderData && onSearch && onSearch.length<4)" >
                            <ng-container *ngFor="let data of leaderData['all_clients_records']">
                                <ng-container *ngFor="let client_record of data['client_records']  let i = index">
                                    <span *ngIf="client_record['display_name']" class="client-name text-decoration">{{client_record['display_name']}}
                                    </span>
                                    <ng-container *ngIf="client_record?.records">
                                        <ng-container *ngIf="client_record['aggregated_name'] != 'Opening Balance'">
                                            <tr class="client-name subgrouping-name text-decoration text-uppercase">
                                              <td align="left" style="color:#000000; text-align:left; font-weight: bold;" colspan="15">{{client_record['aggregated_name']}}-{{client_record['pan_number']}}</td>
                                            </tr>
                                            <ng-container *ngFor="let record of client_record['records']  let j = index">
                                                <tr 
                                                    (keyup.enter)="openViewMode(record.invoice_number,record.reference_type, record.inv_reference_type)"
                                                    [ngClass]="{'d-none': record.reference_type == 'opening_balance', 'borderOrange' : client_record?.total_debit > 0 ,'borderGreen' : client_record?.total_credit > 0}">
                                                    
                                      
                                                    <td (click)="moveToGroupedIndex(i,j)"
                                                    class="text-left text-grey date " style="min-width: 105px;" >
                                                        {{record?.invoice_date | date : 'dd/MM/yyyy'}}</td>
                                                    <td (click)="moveToGroupedIndex(i,j)"
                                                    class="text-left transition text-bold cursor doc-no" style="min-width: 155px;"><a
                                                            [ngClass]="{'text-hyperlink': record.invoice_number != null && record.invoice_number != undefined}"
                                                            (click)="openViewMode(record.invoice_number,record.reference_type, record.inv_reference_type)">{{record?.invoice_number}}</a>
                                                            <!-- <a class="d-block" *ngIf="record?.x_field_1 && record.x_field_1!='null' "
                                                            [ngClass]="{
                                                              ' underline-none':
                                                              record.invoice_number != null &&
                                                              record.invoice_number != undefined
                                                            }"
                                                            >{{record?.x_field_1}}</a> -->
                                                    </td>
                                                    <td  class="text-left text-grey">{{record?.reference_type}}</td>
                                                    <td  class="text-left text-grey">{{record?.inv_reference_type}}</td>
                                                    <td  class="text-left text-grey">{{record?.c_tds_rate}}</td>

                                                   
                                                    <!-- <td  class="text-left text-grey">{{record?.added_by}}</td>
                                                    <td  class="text-left text-grey">{{record?.edit_by}}</td> -->
                                                   
                                                    
                                                    <td (click)="moveToGroupedIndex(i,j)" class="text-right text-grey hc-amt">
                                                      {{record?.amount | number :'1.2-2' }}</td>
                                                  <td (click)="moveToGroupedIndex(i,j)" class="text-right text-grey tds-amt">
                                                      {{record.tds_amount_typhe=='credit'?record.tds_amount:0 | number :'1.2-2'}}
                                                  </td>
                                                  <td (click)="moveToGroupedIndex(i,j)" class="text-right text-grey tds-amt">
                                                    {{record.tds_amount_typhe=='debit'?record.tds_amount:0 | number :'1.2-2'}}
                                                  </td>
                                                    
                                                   
                                                </tr>
                                            </ng-container>
                                            <ng-container > 
                                                <tr class=" grand-total">
                                                    <td class="text-left text-grey date " style="min-width: 105px;" ></td>
                                                    <td class="text-left transition text-bold cursor doc-no" style="min-width: 155px;"></td>
                                                    <td class="text-left text-grey"></td>
                                                    <td class="text-left text-grey"></td>
                                                    <td class="text-left text-black acc-decription word-break subtotal text-bold">Sub Total:</td>
                                                    <td class="text-right text-black text-bold hc-amt">{{client_record?.commission | number : '1.2-2'}}</td>
                                                    <td class="text-right text-black text-bold tds-amt">{{client_record?.tds | number : '1.2-2'}}</td>
                                                   
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            
                            <ng-container *ngIf="data['grandTotal']">
                              <ng-container > 
                                <tr>
                                    <td class="text-left text-grey date " style="min-width: 105px;" ></td>
                                    <td class="text-left transition text-bold cursor doc-no" style="min-width: 155px;"></td>
                                    <td class="text-left text-grey"></td>
                                    <td class="text-left text-grey"></td>
                                    <td class="text-left text-black acc-decription word-break subtotal text-bold">Grand Total:</td>   
                                    <td class="text-right text-black text-bold hc-amt">{{data['grandTotal']?.commission | number : '1.2-2'}}</td>
                                    <td class="text-right text-black text-bold tds-amt">{{data['grandTotal']?.tds | number : '1.2-2'}}</td>
                                    
                                </tr>
                            </ng-container>
                            </ng-container>
                          </ng-container>

              </tbody>

            </table>
        </div>
    </div>

    </div>
</div>