<form [formGroup]="agingReportFilterForm " class="agingReportFilterFormNew">
    <div class="aff-info">
      <div class="aff-info-inner">
        <div class="aging-filter-tbl-heading border-bottom text-bold pb-10 mb-10 ">Please input first interval's TO value</div>
          <div class="mt-20 ">
              <table class="aging-filter-tbl" width="100%" cellpadding="0" cellspacing="0">
                <thead style="display:table-header-group">
                  <tr>
                    <th  style="width: 142px;min-width: 142px;text-align: left!important;">Interval 1</th>
                    <th style="width: 142px;min-width: 142px;text-align: center;">Interval 2</th>
                    <th style="width: 142px;min-width: 142px;text-align: center;">Interval 3</th>
                    <th style="width: 142px;min-width: 142px;text-align: right;">Interval 4</th>
                  </tr>
                </thead>
                <tbody>
                  <tr valign="center">
                    <td style="width: 142px;min-width: 142px;text-align: left;">
                        <span style="display: inline;font-weight: 500;">From 0 to </span>
                        <input 
                        [ngClass]="{'error-box' : (agingReportFilterForm.controls.firstTo.invalid && agingReportFilterForm.controls.firstTo.touched) || 
                        !agingReportFilterForm.controls.firstTo.valid}"
                          class="input-ui" type="text" pattern="\d*" maxlength="3" (input)="onFirstInput($event.target.value)"
                          searchtabindex="1" formControlName="firstTo" 
                          style="width: 30px; height: 20px;padding: 5px;" />
                    </td>
                    <td  style="width: 142px;min-width: 142px;text-align: center;"> 
                      <span *ngIf="agingReportFilterForm.controls.firstTo.value && agingReportFilterForm.controls.firstTo.valid">From {{agingReportFilterForm.value.secondFrom+1}} to </span>
                    <input 
                        [ngClass]="{'error-box' : (agingReportFilterForm.controls.secondTo.invalid && agingReportFilterForm.controls.secondTo.touched) || 
                        !agingReportFilterForm.controls.secondTo.valid}"
                          class="input-ui" type="text" pattern="\d*" maxlength="3" (input)="onSecondInput($event.target.value)"
                          searchtabindex="1" formControlName="secondTo" 
                          style="width: 30px; height: 20px;padding: 5px;" />
                        </td>
                    <td  style="width: 142px;min-width: 142px;text-align: center;"> 
                      <span *ngIf="agingReportFilterForm.controls.firstTo.value && agingReportFilterForm.controls.firstTo.valid">From {{agingReportFilterForm.value.thirdFrom+1}} to </span>
                    <input 
                        [ngClass]="{'error-box' : (agingReportFilterForm.controls.thirdTo.invalid && agingReportFilterForm.controls.thirdTo.touched) || 
                        !agingReportFilterForm.controls.thirdTo.valid}"
                          class="input-ui" type="text" pattern="\d*" maxlength="3" (input)="onThirdInput($event.target.value)"
                          searchtabindex="1" formControlName="thirdTo" 
                          style="width: 30px; height: 20px;padding: 5px;" />
                        </td>
                    <td  style="width: 142px;min-width: 142px;text-align: right;"> 
                      <span *ngIf="agingReportFilterForm.controls.firstTo.value && agingReportFilterForm.controls.firstTo.valid">From {{agingReportFilterForm.value.fourthFrom+1}} to </span>
                    <input 
                        [ngClass]="{'error-box' : (agingReportFilterForm.controls.fourthTo.invalid && agingReportFilterForm.controls.fourthTo.touched) || 
                        !agingReportFilterForm.controls.fourthTo.valid}"
                          class="input-ui" type="text" pattern="\d*" maxlength="3" 
                          searchtabindex="1" formControlName="fourthTo" 
                          style="width: 30px; height: 20px;padding: 5px;" />
                        </td>
                  </tr>
                </tbody>
              </table>
          </div>

          <div class="flexBox d-flex mt-4 ml-2 mr-2">

            <div class="input-fiel">
              <label class="radio-label" for="invoiceCheckBox">Client</label>
                <input class="radio-ui" id="invoiceCheckBox" type="radio" name="account" value="client" formControlName="account" />
               
            </div>

            <div class="input-fiel">
              <label class="radio-label" for="invoiceCheckBox">Supplier</label>
                <input class="radio-ui" id="invoiceCheckBox" type="radio" name="account" value="supplier" formControlName="account" />
               
            </div>

            <div class="input-fiel">
              <label class="radio-label" for="invoiceCheckBox">General</label>
                <input class="radio-ui" id="invoiceCheckBox" type="radio" name="account" value="general" formControlName="account" />
             
            </div>

            <div class="input-fiel">
              <label class="radio-label" for="invoiceCheckBox">Airline</label>
                <input class="radio-ui" id="invoiceCheckBox" type="radio" name="account" value="airline" formControlName="account" />
             
            </div>

          </div>

        <div class="d-flex mt-5 justify-content-center">
          <div class="input-fiel">
            <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
              searchtabindex="2" #searchButton (keyup.enter)="clickSearchButton()"
              [disabled]="agingReportFilterForm.invalid"
              [ngClass]="{disbaledBtn: agingReportFilterForm.invalid}"
              (click)="search(agingReportFilterForm.value)">
              Search</button>
            <button type="button" class="btn-style " id="resetSearchForm" (click)="reset()">RESET</button>
          </div>
        </div>
      </div>

    </div>
  </form>