import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReceiptService } from '../services/receipt.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { NumberToWordsPipe } from 'src/app/shared/pipe/print-invoice.pipe';

import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver'; 
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-print-receipt',
  templateUrl: './print-receipt.component.html',
  styleUrls: ['./print-receipt.component.scss']
})
export class PrintReceiptComponent implements OnInit {
	receiptId: any;
	receiptData: any;
	valid: boolean;
	shortcuts: ShortcutInput[] = [];
	@Input() reference_code;
	@Input() preFetchedData;
	@Output() callSaveFetchedData: EventEmitter<any> = new EventEmitter();
	waterMark;
	updateBillingDetailsForm: any;
	displayBillingDetails : any
	@ViewChild('openBillingDetailsModal', { static: true }) openBillingDetailsModal: ElementRef
	showPartyName: boolean = true;

	constructor(
		private receiptService: ReceiptService,
		private spinner: NgxSpinnerService,
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		public sanitizer: DomSanitizer,
		private fb: FormBuilder,
	) {
		this.route.paramMap.subscribe(params => {
			this.receiptId = params['params'].id
			if (this.receiptId != undefined) {
				document.title = `Receipt -  ${this.receiptId}`
			}
		});
	}

	ngOnInit() {
		if(!this.preFetchedData){
			if(!this.receiptId){
				this.receiptId = this.reference_code
			}
			this.getreceiptDetails()
		}else{
			this.receiptData = this.preFetchedData
			this.valid = true
		}

		this.updateBillingDetailsForm = this.fb.group({
			"invoice_no": [this.receiptId],
			"display_billing_details": [''],
			'inv_in_native_lang': [false]
		})

		let queryParams = this.route.queryParams['value'];
		if(queryParams && queryParams.hide_party && queryParams.hide_party == 'true') {
			this.showPartyName = false;
		}
	}

	print() {
		window.print()
	}

	getreceiptDetails() {
		this.spinner.show()
		this.receiptService.getMethod(`receiptentryv3/download?receipt_code=${this.receiptId}&preview=true`).subscribe(async (res:any) => {
		    if(res){
				this.receiptData = this.transform(res);
				this.valid = true
			}
			this.spinner.hide()
		}, err => {
			this.receiptData = []
			this.spinner.hide()

		})
	}

	transform(html) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}
	
	downloadPdf(searchId: any) {
		this.commonService.downloadPDF(`${environment.okountUrl}/receiptentryv3/download?receipt_code=${searchId}&preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `Receipt-${this.receiptId}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}

	getNumberToWord(value) {
		 let str = new NumberToWordsPipe().transform(Number(value).toFixed())
		 return str
	}

	updateDisplayBilling(){
		let reqObj = {
			invoice_no : this.updateBillingDetailsForm.value.invoice_no,
			display_billing_details : this.updateBillingDetailsForm.value.display_billing_details
		}
		
		this.displayBillingDetails = 'updating...'
		this.commonService.putRequest(`${environment.okountUrl}/receiptentryv3/billing_details/update`,reqObj).subscribe(async(res: any) =>{
			this.openBillingDetailsModal.nativeElement.click()
			this.getreceiptDetails()
		},err =>{
			console.log(err)
		})
	}

	openBillingDetails() {
		this.openBillingDetailsModal.nativeElement.click();
	}
}
