<app-outstanding-summary-ledger *ngIf="isOutStandingSummary"></app-outstanding-summary-ledger>

<div class="ledger-container" *ngIf="leaderData != undefined && !isOutStandingSummary">
	<!-- <div class="alert alert-success msgSuccess" >
       messageSuccess
    </div>
    <div class="alert alert-danger msgError" >
       messageError
    </div> -->
	<!-- <div class="heading-box mt-3 ml-2">
        <div class="heading">Summary Ledger</div>
        <span class="hr-line"></span>
      </div> -->

	  
	<div >
		<div class="voucher-container">
			<!-- <div class="row">
				<div class="address col-4 float-left">
					<span class="cname">{{leaderData?.company_details?.name}}</span><br />
					<span class="address_line">{{leaderData?.company_details?.address_1}}
						{{leaderData?.company_details?.address_2}} {{leaderData?.company_details?.address_3}}</span>

				</div>

				<div class="col-4">
					<h3 class="receipt-head mt-2">
						Ledger Statement<br>
					</h3>
				</div>

				<div class="address col-4 float-right field-info">
					<span class="cname"> Date:
						<span>{{currentDate | date : 'dd/MM/yyyy'}}</span>
					</span>
				</div>
			</div> -->
			<div class="row tab-nav pl-0 pr-0">
				<div class="col-sm-4 ">
					<div *ngIf="isPrint">
						<span class="text-bold text-black">{{leaderData?.company_details?.name}}</span><br>
						<p class="address_line mb-0 text-grey text-uppercase"><span class="d-block">{{leaderData?.company_details?.address_1}}</span>
						  <span >{{leaderData?.company_details?.address_2}}</span> <span >{{leaderData?.company_details?.address_3}}</span></p>
					   </div>
					<ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0" >
						<li [routerLink]="['/']"
							class="text-grey text-capitalize cursor  hover-text-primary transition" [ngClass]="{'d-none': isPrint == 'true'}"> Analysis</li>
						<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt="" [ngClass]="{'d-none': isPrint == 'true'}"></li>
						<li class="text-grey text-capitalize cursor text-primary transition" [ngClass]="{'d-none': isPrint == 'false'}" [ngClass]="{'d-none': isPrint == 'true'}"> Summary Ledger:- </li>
						<li class="text-primary "><ng-container *ngFor="let data of leaderData['all_clients_records']" >
						  <span class=" text-bold line-clamp line-clamp-1"  >
							  {{data['display_name']}}
						  </span>
					  </ng-container></li>
					</ul>
				</div>
				<div class="col-sm-4 text-center">
					<h4 *ngIf="isPrint" class="text-uppercase text-bold text-black mt-2">
						Summary Ledger Statement<br>
					  </h4>
					<span class="rec-date d-block text-center">
						<div class="noWrap justify-content-around">
							<ng-container *ngIf="leaderData.duration != undefined">
								<div class="cname">From
									<h5 style="margin-right: 5px;" class="mb-0  d-inline"><span class="date text-bold text-orange"
											*ngIf="leaderData.duration.from_date != ''">
											{{leaderData?.duration?.from_date | date : 'd MMMM, y'}} 
										</span>
										<span class="date text-bold" *ngIf="leaderData.duration.from_date != ''">
											To
										</span>
										<span class="date text-bold" *ngIf="leaderData.duration.from_date == ''">
											Till Date
										</span>
										<span class="date text-bold text-orange">
											{{leaderData?.duration?.to_date | date : 'd MMMM, y'}}
										</span>
									</h5>
									<button [ngClass]="{'d-none': isPrint == 'true'}" title="Custom Search" class="btn   save-as text-bold bg-whiteSmoke "
										type="button" >
										<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
									</button>
								</div>
							</ng-container>								
						</div>
					</span>
				</div>
				<div class="col-md-4 d-flex align-item-center justify-content-end">
					<div class="text-right mt-2" *ngIf="isPrint">
					  <span class="d-block text-black">{{currentDate | date : 'dd/MM/yyyy'}}</span>
					  <span class="d-block text-grey">{{currentTime}}</span>
					</div>
					<div
					class="page-search-box input-group d-flex align-item-center" 
			  [ngClass]="{ 'd-none': isPrint == 'true' }"
					style="height: fit-content; text-align: left"
				  >
					<input
					  type="text"
					  class="form-control"
					  oninput="this.value = this.value.toUpperCase()"
					  placeholder="search"
					  style="text-align: left"
					/>
				  </div>
					<div   [ngClass]="{ 'd-none': isPrint == 'true' }" class="dropdown-btn position-relative">
						<button title="More..." class="btn-style mt-0 more-btn">
						  <span class="dot"></span><span class="dot"></span
						  ><span class="dot"></span>
						</button>
						<ul class="list-unstyled pl-0 dropdown-box">
						  <!-- <li  (click)="openModal()">
							<img
							  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-emal.svg"
							  alt=""
							/>
							<span class="ml-2">Email</span>
		  
							<button
							hidden
							#openEmailModal
							data-target="#emailModal"
							data-toggle="modal"
						  ></button>
						  </li> -->
						  <li   (click)="
						  callPdfGeneratorApi(
							leaderData?.duration?.from_date,
							leaderData?.duration?.to_date
						  )
						" >
							<img
							  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg"
							  alt=""
							/>
							<span class="ml-2">Save As Pdf</span>
						  </li>
						</ul>
					  </div>
					<!-- <div class="save-button d-flex pull-right mt-2 align-items-center" [ngClass]="{'d-none': isPrint == 'true'}">
						<button class="btn mr-1  save-as text-bold bg-whiteSmoke text-green dropdown-toggle"
							type="button" (click)="openModal()">
							<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/blue-email.svg" alt="">
							<span class="ml-1 text-blue">Email To</span>
						</button>
						<button hidden #openEmailModal data-target="#emailModal" data-toggle="modal"></button>
						<div class="dropdown">
							<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-download.svg" alt="">
								<span class="ml-1 text-green">Save As</span>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
								<a class="dropdown-item"
									(click)="exportAsXLSX(leaderData?.duration?.from_date  ,leaderData?.duration?.to_date)">EXCEL</a>
								<a class="dropdown-item" href="#">CSV</a>
								<a class="dropdown-item"
									(click)="callPdfGeneratorApi(leaderData?.duration?.from_date  ,leaderData?.duration?.to_date)">PDF</a>
							</div>
						</div>
					</div> -->
				</div>
			</div>
			<!-- row -->

			<div class="table-section">
				<table class="table table striped">
					<thead>
						<tr>
							<th class="text-left">Date </th>

							<th class="text-left">Reference
								<!-- <i *ngIf="ascSortRef" (click)="refSort(i)" class="fas fa-sort-alpha-up"></i>
								<i *ngIf="!ascSortRef" (click)="refSort(i);" class="fas fa-sort-alpha-down-alt"></i> -->
							</th>
							<th class="text-left">Narration</th>
							<th class="text-right">Debit
								<!-- <i *ngIf="ascSortAmt" (click)="amtSort(i)" class="fas fa-sort-alpha-up"></i>
								<i *ngIf="!ascSortAmt" (click)="amtSort(i);" class="fas fa-sort-alpha-down-alt"></i> -->

							</th>
							<th class="text-right">Credit</th>
							<th class="text-right">Balance
								<!-- <i  *ngIf="ascSorttdsAmt" (click)="tdsamtSort(i)" class="fas fa-sort-numeric-up"></i>
								<i  *ngIf="!ascSorttdsAmt" (click)="tdsamtSort(i)" class="fas fa-sort-numeric-down-alt"></i> -->
							</th>

						</tr>
					</thead>
					<tbody class="scroll-body" [ngStyle]="{height: innerHeight}">
						<ng-container *ngFor="let data of leaderData['all_clients_records']  let i = index;">
							<ng-container *ngFor="let client_record of data['client_records']">
								<span class="client-name">{{client_record['aggregated_display_name']}}
								</span>
								<tr *ngFor="let record of client_record['records']">
									<tbody class="w-100 pt-2 pb-2" style="display: block;">
										<tr>
									<td class="text-left">{{record?.date | date : 'dd/MM/yyyy'}}</td>
									<td class="text-center">{{record?.reference}}</td>
									<td class="text-left">
										<ng-container *ngFor="let narration of record.narration">
											<ng-container *ngIf="narration.trim() != ''">
												{{narration}} <br>
											</ng-container>
										</ng-container>
									<td class="text-right text-red">{{record?.amount_debit}}</td>
									<td class="text-right text-green">{{record?.amount_credit}}</td>
									<td class="text-right text-blue">{{record?.balance}}</td>
									</tr>
									</tbody>
								</tr>
							</ng-container>

						</ng-container>
						<tr *ngIf="leaderData['all_clients_records'].length == 0">
							<div class="w-100 text-center" style="padding: 20px;">
								<span><img class="not-found-img" src="{{commonService.aws_asset_url}}/_assets/images/problem.png"></span> <br>
								<span class="not-found-text">No matching items found</span>
							</div>
						</tr>
					</tbody>
				</table>


			</div>

		</div>
	</div>

</div>