import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventRoutingModule } from './event-routing.module';
import { EventComponent } from './event.component';
import { EventFareSummaryComponent } from './event-fare-summary/event-fare-summary.component';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { EventService } from './services/event.service';
import { PrintEventInvoiceComponent } from './print-event-invoice/print-event-invoice.component';


@NgModule({
  declarations: [EventComponent, EventFareSummaryComponent],
  imports: [
    CommonModule,
    EventRoutingModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    FormsModule, 
    ReactiveFormsModule,
    KeyboardShortcutsModule.forRoot()
  ],
  exports: [EventComponent],
  providers : [CommonServiceService,EventService]
})
export class EventModule { }
