import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlightRoutingModule } from './flight-routing.module';
import { FlightComponent } from './flight.component';
import { SectorInfoComponent } from './sector-info/sector-info.component';
import { TravellerInfoComponent } from './traveller-info/traveller-info.component';
import { TotalFareInfoComponent } from './total-fare-info/total-fare-info.component';
import { InvoiceServicesService } from './services/invoice-services.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProductServiceDetailsService } from './services/product-service-details.service';
import { InvoiceRequestBuilderService } from './services/invoice-request-builder.service';
import { GstDetailComponent } from './gst-detail/gst-detail.component';


@NgModule({
  declarations: [FlightComponent, SectorInfoComponent, TravellerInfoComponent, TotalFareInfoComponent, GstDetailComponent],
  imports: [
    CommonModule,
    FlightRoutingModule,
    SharedModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [FlightComponent],
  providers :  [InvoiceServicesService, ProductServiceDetailsService, InvoiceRequestBuilderService ] 
})
export class FlightModule { }
