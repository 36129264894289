import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JvRoutingModule } from './jv-routing.module';
import { JvComponent } from './jv.component';
import { CreateJvComponent } from './create-jv/create-jv.component';
import { ViewJvComponent } from './view-jv/view-jv.component';
import { AddEntryJvComponent } from './add-entry-jv/add-entry-jv.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { JvService } from './services/jv.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonServiceService } from 'src/app/shared/common-service.service';


@NgModule({
  declarations: [JvComponent, CreateJvComponent, ViewJvComponent, AddEntryJvComponent],
  imports: [
    CommonModule,
    JvRoutingModule,
    SharedModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  providers : [JvService],
  exports: [AddEntryJvComponent]
})
export class JvModule { }
