import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef, HostListener, ViewChild, ElementRef, Input } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, AbstractControl, NgForm } from '@angular/forms';
import * as moment from 'moment'
import { LoaderService } from './../../shared/loader.service';

import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { ProductDetailRefundService } from './flight-refund/services/product-detail-refund.service';
import { InvoiceRefundService } from './flight-refund/services/invoice-refund.service';
import { SectorService } from '../invoice/flight/services/sector.service';
import { environment } from 'src/environments/environment';
import { SectorRefundService } from './flight-refund/services/sector-refund.service';
import { FormCanDeactivate } from 'src/app/shared/form-can-deactivate/form-can-deactivate';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import * as FileSaver from 'file-saver'; 
import { InvoiceRequestBuilderRefundService } from './flight-refund/services/invoice-request-builder-refund.service';
import { CookiesService } from 'src/app/shared/cookies.service';


@Component({
  selector: 'app-invoice-refund',
  templateUrl: './invoice-refund.component.html',
  styleUrls: ['./invoice-refund.component.scss'],
  providers: [SectorRefundService]
})
export class InvoiceRefundComponent extends FormCanDeactivate implements OnInit {
	@Input() data;
	@ViewChild('form')
 	form: NgForm;
	moduleView: boolean = false;
	fixedModule: boolean = false
	isRetrive: boolean;
	retrievedData: any;
	errorMsg: boolean;
	isRetriveError: boolean;
	selectedTab: any = 'flight';
	calculateGst;
	keyword = 'name';
	grouplist = [];
	groupId;
	initialValue = ''
	menuVisible: boolean = false;

	nestedForm: FormGroup;
	summedAmount: number = 0;
	gstAmount: number = 0;
	affiliateArr: any;
	tourArr:any
	uuid: any;
	selectedLedger;
	selectedTourLedger;
	creditNoteData :any
	isAadesh: boolean = false;

	isSuccess: boolean = false;
	showMessage: boolean = false;
	alertType = ''
	message: string  = ``;
	invoice_number = ``;
	invoiceMode = 'save';
	invoice_no;
	invoice_code: any;
	isPreviousYear: any;
	showPrintOptions: boolean = false;
	currencyCode : string = "INR";
	convertPnr: string = ``;
	nativeLanguageCode = '';
	attachmentFile: File | null = null;
	billOfSupplyActive = false;

	@ViewChild('accountAC',{static : true}) accountAC;
	@ViewChild('tourAccountAC', { static: true }) tourAccountAC;

	@ViewChild('confirmDeleteModal',{static:true}) confirmDeleteModal : ElementRef
	@ViewChild('closeDeleteModal',{static:true}) closeDeleteModal : ElementRef

	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef
	@ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef
	@ViewChild('closeSendEmailModal') closebutton;
	@ViewChild('openEmailModal') openEmailModal;
	@ViewChild("openSuccessModal", { static: true }) openSuccessModal;
	@ViewChild('generateEInvoiceModal', {static: true}) generateEInvoiceModal: ElementRef 


	newInvoice = true;
	copyELementTitle = 'Click to Copy'
	sendEmailForm:FormGroup
	isManual: boolean;
	shortcuts: ShortcutInput[] = [];
	navigationSubscription;
	reInitialise: boolean;
	queryParams: any;
	currentOpenedModal = ''
	isTenantIndian: boolean = false;
	minDate; 
	maxDate;
	AllMasterClient: string = "false";

	showRefundableCheckbox = false;
	financialYearDiff = false;
	noTDSReturnClientCheckBox: any;
	constructor(
		private formBuilder: FormBuilder,
		private ref: ChangeDetectorRef,
		private productservicedetail: ProductDetailRefundService,
		private invoiceService : InvoiceRefundService,
		public loaderService: LoaderService,
		private route: ActivatedRoute,
		private spinner: NgxSpinnerService,
		public commonService: CommonServiceService,
		private router : Router,
		public 	sectorService : SectorRefundService,
		public requestBuilder: InvoiceRequestBuilderRefundService,
		private cookiesService: CookiesService

	) {
		super()
		this.route.paramMap.subscribe(params => {
			this.invoice_no = params['params'].invoice?params['params'].invoice:''
			this.invoiceMode = params['params'].mode

			this.sectorService.invoice_no = params['params'].invoice? params['params'].invoice:''
			this.sectorService.invoiceMode = params['params'].mode
		});
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});

		this.sendEmailForm = this.formBuilder.group({
			toEmails: ["",Validators.required],
			bccEmails: [''],
			ccEmails:[''],
			subject:[''],
			body:['']
		})

		this.nestedForm = this.formBuilder.group({
			uuid: ['', Validators.required],
			paymentdetails: this.formBuilder.array([this.paymentResGroup()]),
			invdate: ['', Validators.required],
			refund_date: ['', [Validators.required]],
			billaddress: [''],
			affiliate_name: ['',this.dynamicConditionalValidator()],
			tour_account_name:[''],
			tour_account_code: [''],
			summedAmount: [null],
			gstAmount: [null],
			narration: [''],
			narration_1: [''],
			narration_2: [''],
			narration_3: [''],
			narration_4: [''],
			narration_5: ['',Validators.pattern('^[^\,\"\(\)\']*$')],
			remark:[''],
			invoiceType: ['normal'],
			referenceInvoice: [''],
			flightType: [''],
			purchase_currency: [''],
			currency_rate: [''],
			sales_currency: [''],
			sales_currency_rate: [''],
			sales_currency_amount: [''],
			nonRefundable: [''],
			bill_of_supply: [false],
			no_tds_return_client: [false],
			rcm_applicable: [false],
			// reverse_tds: [false]
		})
		this.handleInvoiceTypeChange();
	}

	initialiseInvites() {
		if (this.reInitialise) {
			// this.ngOnInit()
		}
	}

	async ngOnInit() {
		if(this.data){
			this.invoice_no =this.data.creditNoteNumber
			this.invoiceMode =this.data.mode
		}

		let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
		let inv_config = globalSettingConfig.filter(el=>el.group=='FLIGHT_INV')
		if(inv_config && inv_config.length>0){
			let flight_config = inv_config[0].json_doc["value"].filter(el=>el.key=='ALL MASTERS IN CLIENT')
			if(flight_config && flight_config.length){
				this.AllMasterClient = flight_config[0].value
			}
		}
		let transaction_config = globalSettingConfig.filter(el=>el.group=='TRANSACTION')
		if(transaction_config && transaction_config.length>0){      
		let no_tds_return_client_config = transaction_config[0].json_doc["value"].filter(el=>el.key=='no_tds_return_client')
			if(no_tds_return_client_config && no_tds_return_client_config.length){
				this.noTDSReturnClientCheckBox = no_tds_return_client_config[0].value
			}
		}
		this.creditNoteDateValidation();
		this.isTenantIndian = this.commonService.checkTenantIndian()

		sessionStorage.clear()
		this.sectorService.totalFareModel = []
		this.sectorService.sectorForm.reset()
		this.sectorService.travellerForm.reset()
		this.sectorService.invoiceForm.reset()
		let queryParams = this.route.queryParams['value']
		this.isPreviousYear = this.route.queryParams['value']['isPreviousYear']
		this.isManual = this.route.queryParams['value']['isManual'] ? true : false
		if(queryParams){
			this.queryParams = queryParams
		}
		this.sectorService.isManual = this.isManual
		this.commonService.moduleViewStatus.subscribe(status =>{
			if (status == true) {
				this.closeModuleFixed()
			}else{
				this.moduleFixed()
			}
		})
		

		if((this.invoiceMode == 'create' || this.invoiceMode =='view') && this.invoice_no != undefined && this.invoice_no.length>0) {
			if (this.invoiceMode == 'create' ) {
				this.getInvoiceData(this.invoice_no, 'invoice',true)
			}else if (this.invoiceMode =='view') {
				this.getCreditNote(this.invoice_no, 'requested')
			}
		}
		let product_list = []
		sessionStorage.setItem('product_list', JSON.stringify(product_list))
		
		this.nestedForm = this.formBuilder.group({
			uuid: ['', Validators.required],
			paymentdetails: this.formBuilder.array([this.paymentResGroup()]),
			invdate: ['', Validators.required],
			booking_ref_no: [''],
			refund_date: [moment().format('YYYY-MM-DD'), [Validators.required]],
			billaddress: [''],
			affiliate_name: ['',this.dynamicConditionalValidator()],
			tour_account_name:[''],
			tour_account_code: [''],
			summedAmount: [null],
			gstAmount: [null],
			narration: [''],
			narration_1: [''],
			narration_2: [''],
			narration_3: [''],
			narration_4: [''],
			narration_5: ['',Validators.pattern('^[^\,\"\(\)\']*$')],
			remark:[''],
			invoiceType: ['normal'],
			referenceInvoice: [''],
			flightType: [''],
			purchase_currency: [''],
			currency_rate: [''],
			sales_currency: [''],
			sales_currency_rate: [''],
			sales_currency_amount: [''],
			nonRefundable: [''],
			bill_of_supply: [false],
			no_tds_return_client: [false],
			rcm_applicable: [false],
			// reverse_tds: [false]

		})
		this.handleInvoiceTypeChange();
		this.reInitialise = true
		let empConfiguration = this.commonService.employeeConfiguration;
			if(!empConfiguration || empConfiguration.length == 0){
				let loggedInUser = JSON.parse(localStorage.okountUserInfo);
				empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
			}
		
		

		this.getAccountingCompany()

		this.showRefundableCheckbox = this.commonService.checkCreditNoteNonRefundableOrNot()
		let userData = JSON.parse(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA'))
		if(userData && userData.tenant_id && (userData.tenant_id == environment.aadeshSystaccId  || userData.tenant_id == environment.aadeshAedSystaccId)) {
			this.isAadesh = true;
		}
	}

	handleInvoiceTypeChange() {
		this.nestedForm.get('invoiceType')?.valueChanges.subscribe((value) => {
		  const affiliateControl = this.nestedForm.get('affiliate_name');
		  if (value !== 'tour') {
			affiliateControl?.setValidators(Validators.required);
		  } else {
			affiliateControl?.clearValidators();
		  }
		  affiliateControl?.updateValueAndValidity();
		});
	  }
	dynamicConditionalValidator() {
		return (control) => {
		  if (this.nestedForm && this.nestedForm.get('invoiceType')?.value !== 'tour') {
			return Validators.required(control);
		  }
		  return null;
		};
	  }
	
	checkRefundDate(){
		return (control: AbstractControl) => {
			let valid = true
			if(this){
				if (moment(control.value).isBefore(this.minDate) || moment(control.value).isAfter(this.maxDate)) {
					if(this.invoiceMode == 'view' && this.creditNoteData && this.creditNoteData.credit_note_date && moment(control.value,'YYYY-MM-DD').isSame(moment(this.creditNoteData.credit_note_date,'YYYY-MM-DD'))){
						valid = true
					}else{
						valid = false
					}
				}
			}
			return !valid ? {refund_date: {value: control.value}} : null;
		};
	}

	forceReload() {
		this.ngOnInit()
	}

	initDateValidation() {
		try {
			this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
			this.maxDate = moment().format('YYYY-MM-DD')
			
			let crnConfig = this.commonService.getConfigurationByKey('flight_credit_note')
			if(crnConfig){
				let dateConfig = crnConfig['value'].filter(data => data.key == `minimum_${this.invoiceMode == 'view' ? 'update' : 'creation'}_date`)
				let daysConfig = crnConfig['value'].filter(data => data.key == `minimum_${this.invoiceMode == 'view' ? 'update' : 'creation'}_days`)
				let dateWithMinDate = moment(dateConfig ? dateConfig[0].value : '')
				let dateWithMinDays = dateConfig && daysConfig[0].value ? moment().subtract(Number(daysConfig[0].value), 'days') : dateWithMinDate
				if(dateWithMinDays.isBefore(dateWithMinDate)){
					this.minDate = dateWithMinDate.format('YYYY-MM-DD')
				}else{
					this.minDate = dateWithMinDays.format('YYYY-MM-DD')
				}

				this.nestedForm.controls.refund_date.setValidators([Validators.required,this.checkRefundDate()])
				this.nestedForm.controls.refund_date.updateValueAndValidity()
			}
		} catch (error) {
			
		}
	}

	retrieveInvoice(invoice_no){
		this.spinner.show()
		console.log(invoice_no)
		this.invoice_no = invoice_no
		this.sectorService.loadFlightRefund.next({
			'status':true,
			"invoice_no":invoice_no
		})
		this.getInvoiceData(invoice_no,'invoice')
		setTimeout(()=>{
			this.spinner.hide()
		},1000)
	}


	refundDateChange(){
		let obj = {
			type: 'credit_note',
			index: this.sectorService.fareList.length -1,
			req_from : "bindCreditNoteData",
			"use_form" : "travellerForm"
		}
		this.sectorService.componentMethodCallSource.next(obj)
	}

	focusAccountAc(){
		if (this.accountAC != undefined) {
			this.accountAC.focus()
		}
	}

	focusTourAccountAc() {
		if (this.tourAccountAC != undefined && this.tourAccountAC.nativeElement && this.tourAccountAC.nativeElement.focus) {
			this.tourAccountAC.nativeElement.focus()
		}
	}

	ngAfterViewInit(){
		// this.commonService.selectedSearchInput.subscribe(index=>{
		// 	if (index == 1) {
		// 		this.focusAccountAc()
		// 	}
		// })
		this.shortcuts.push(			
			{
				key: "alt + s",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Save Credit Note Entry",
				description: "Save Credit Note Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("save_credit_note_btn")){

				document.getElementById("save_credit_note_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + e",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Update Credit Note Entry",
				description: "Update Credit Note Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("modify_credit_note_btn")){

				document.getElementById("modify_credit_note_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + d",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Credit Note Entry",
				description: "Delete Credit Note Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("delete_credit_note_btn")){

				document.getElementById("delete_credit_note_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + j",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Open Journal",
				description: "Open Journal",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("journal_credit_note_btn")){

				document.getElementById("journal_credit_note_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + y",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On Yes Button",
				description: "Clicked On Yes Button",
				
				command: (e)=>{
				console.log(e)	
			if(this.currentOpenedModal=='update' && document.getElementById("yes_update_credit_note_confirm_modal_btn")){
				document.getElementById("yes_update_credit_note_confirm_modal_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}else if(this.currentOpenedModal=='delete' && document.getElementById("yes_delete_credit_note_confirm_modal_btn")){
				document.getElementById("yes_delete_credit_note_confirm_modal_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
			this.currentOpenedModal = ''
					},
					preventDefault: true
			},
			{
				key: "alt + n",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On No Button",
				description: "Clicked On No Button",
				
				command: (e)=>{
				console.log(e)	
				if(this.currentOpenedModal=='update' && document.getElementById("no_update_credit_note_confirm_modal_btn")){
					document.getElementById("no_update_credit_note_confirm_modal_btn").click()
					setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
				}else if(this.currentOpenedModal=='delete' && document.getElementById("no_delete_credit_note_confirm_modal_btn")){
					document.getElementById("no_delete_credit_note_confirm_modal_btn").click()
					setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
				}
					},
					preventDefault: true
			},
			{
				key: ["alt + 1","alt + 2","alt + 3","alt + 4","alt + 5","alt + 6","alt + 7","alt + 8","alt + 9"],
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Edit  Traveller",
				description: "Edit  Traveller",
				
				command: (e)=>{
				console.log(e)
				   if(document.getElementById(`edit_traveller_${Number(e.event.key)-1}_btn`)){
					document.getElementById(`edit_traveller_${Number(e.event.key)-1}_btn`).click()
				   }
				   setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
					},
					preventDefault: true
					
			},
			{
				key: "f10",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Send mail",
				description: "Send mail",
				command: (e)=>{
				console.log(e)	
			if(document.getElementById('credit_note_send_mail_btn') && this.invoiceMode=='view'){
				document.getElementById('credit_note_send_mail_btn').click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
	  )
	}

	getInvoiceData(searchId: any, type: any,callForMisInvoice=true) {
		try {
			let url = `invoice?invoiceNumber=${searchId}&type=${type}`
			if (this.isPreviousYear != undefined && this.isPreviousYear == 'true') {
				url = `invoice/previousInvioce?invoice_no=${searchId}&type=${type}`
				if (this.isManual == true) {
					url = `invoice/previousInvioce?invoice_no=${searchId}&type=${type}&isManual=true`
				}
				if(this.queryParams && this.queryParams['bookingRef']){
					url +=`&booking_ref_no=${this.queryParams['bookingRef']}`
				}
			}
			this.invoiceService.getMethod(`${url}`).subscribe(async (res) => {	
				let invoiceData = res['data'][0];				
				if(invoiceData && invoiceData.accounting_company) {
					if(invoiceData['accounting_company'].currency_code) {
						this.currencyCode = invoiceData['accounting_company'].currency_code;
					}
					if(invoiceData['accounting_company'].native_language_code && invoiceData['accounting_company'].currency_code == 'AED') {
						this.nativeLanguageCode = invoiceData['accounting_company'].native_language_code;
					}
				}				
				if (invoiceData) {
					this.sectorService.affiliate_code = invoiceData.client && invoiceData.client.account_code ? invoiceData.client.account_code.toUpperCase() : ''
					if(invoiceData.client && invoiceData.client.account_code){
					let url =`client/autocomplete?name_like=${invoiceData.client.account_code.toUpperCase()}`
				if(this.AllMasterClient=="true"){
					url = `client/autocomplete?name_like=${invoiceData.client.account_code.toUpperCase()}&status=active&client_type=all`
				}
					this.invoiceService.searchAccount(url).subscribe(res => {
						this.affiliateArr = res;
						this.initialValue = this.affiliateArr[0].name;
						console.log('Inititl value : ',this.initialValue)
						this.selectEvent(this.affiliateArr[0])
					});
				}
					this.bindInvoiceData(invoiceData)
					this.sectorService.getInvoiceRootvalue(invoiceData)
					if(res && res['accounting_company']) {
						if(res['accounting_company'].currency_code) {
							this.currencyCode = res['accounting_company'].currency_code;
						}
						if(res['accounting_company'].native_language_code && res['accounting_company'].currency_code == 'AED') {
							this.nativeLanguageCode = res['accounting_company'].native_language_code;
						}
					}
				}else if(callForMisInvoice){
					this.isPreviousYear = "true"
					this.isManual = true
					this.getInvoiceData(searchId.split('-').pop(),"flight",false)
				}else{
					this.showMessage = true;
					this.isSuccess = false
					this.invoice_number = ''
					this.alertType = 'error-box';
					this.message = `Invoice no ${this.invoice_no} Does not exist `
						
				}
				if (invoiceData.tour_account_code) {
					let url = `general/autocomplete?name_like=${invoiceData.tour_account_code.toUpperCase()}&status=active&sub_type=tour`
					this.invoiceService.searchAccount(url).subscribe(res => {
						this.tourArr = res;
						let initialValue = this.tourArr[0].name;
						console.log('Inititl value : ', initialValue)
						this.selectTourEvent(this.tourArr[0])
					})
				}

			
				this.spinner.hide()
			},
				err => {
					this.spinner.hide()
					console.log(err)
				})
		} catch (error) {
			this.spinner.hide()
			console.log('Error occured in getInvoice due to : ', error)
		}
	}
	getCreditNote(searchId: any, type: any) {
		try {
			this.invoiceService.getMethod(`creditNote?credit_note_no=${searchId}&type=${type}`).subscribe(async (res) => {
				let invoiceData = res['data'][0];
				this.creditNoteData = res['data'][0];
				if(invoiceData.client && invoiceData.client.account_code){
				let url = `client/autocomplete?name_like=${invoiceData.client.account_code.toUpperCase()}`
				if(this.AllMasterClient=="true"){
					url = `client/autocomplete?name_like=${invoiceData.client.account_code.toUpperCase()}&status=active&client_type=all`
				}
				this.invoiceService.searchAccount(url).subscribe(res => {
					this.affiliateArr = res;
					this.initialValue = this.affiliateArr[0].name;
					console.log('Inititl value : ',this.initialValue)
					this.selectEvent(this.affiliateArr[0])
					this.sectorService.getInvoiceRootvalue(invoiceData)
				});
			}
				this.bindInvoiceData(invoiceData)

				if (invoiceData.tour_account_code) {
					let url = `general/autocomplete?name_like=${invoiceData.tour_account_code.toUpperCase()}&status=active&sub_type=tour`
					this.invoiceService.searchAccount(url).subscribe(res => {
						this.tourArr = res;
						let initialValue = this.tourArr[0].name;
						console.log('Inititl value : ', initialValue)
						this.selectTourEvent(this.tourArr[0])
					})
				}

			
				this.spinner.hide()
			},
				err => {
					this.spinner.hide()
					console.log(err)
				})
		} catch (error) {
			this.spinner.hide()
			console.log('Error occured in getInvoice due to : ', error)
		}
	}

	perItemCalculation(i) {
		let rate = this.paymentArray.controls[i]['controls'].rate.value
		let qty = this.paymentArray.controls[i]['controls'].quantity.value
		let amount = rate * qty
		// let amount = this.paymentArray.controls[i]['controls'].amount.value
		this.paymentArray.controls[i]['controls'].amount.setValue(amount)
	}
	perItemAmountCalculation(i) {
		let qty = this.paymentArray.controls[i]['controls'].quantity.value
		let amount = this.paymentArray.controls[i]['controls'].amount.value
		let rate = amount / qty
		this.paymentArray.controls[i]['controls'].rate.setValue(rate)
	}


	async makeProduct(data) {
		this.closeModuleFixed()
		if (this.paymentArray.controls.length > 0) {
			if (this.paymentArray.controls[0]['controls'].name.value != "") {
				// await this.addpayment() Uncomment this add multiple product
				let lastIndex = this.paymentArray.controls.length - 1
				this.addPaymentData(0, data)
			} else {
				let lastIndex = this.paymentArray.controls.length - 1
				this.addPaymentData(0, data)
			}
		}
	}

	addPaymentData(index, data) {
		this.paymentArray.controls[index]['controls'].name.setValue(data['product'])
		this.paymentArray.controls[index]['controls'].hsc_sac.setValue(data['hsn'])
		this.paymentArray.controls[index]['controls'].quantity.setValue(data['quantity'])
		this.paymentArray.controls[index]['controls'].rate.setValue(data['rate'])
		this.paymentArray.controls[index]['controls'].narration.setValue(data.narration ? data.narration : '')
		this.paymentArray.controls[index]['controls'].amount.setValue(data['amount'])
		this.paymentArray.controls[index]['controls'].tax.setValue(data['tax'])
	}

	paymentResGroup() {
		return this.formBuilder.group({
			name: ["", Validators.required],
			hsc_sac: [""],
			remark: [""],
			narration: [""],
			quantity: [0, [Validators.required, Validators.pattern('^[0-9]*$')]],
			rate: [0, [Validators.required, Validators.pattern('^[0-9]*$')]],
			amount: [0, [Validators.required, Validators.pattern('^[0-9]*$')]],
			tax: [0, [Validators.required, Validators.pattern('^[0-9]*$')]]
		})
	}

	addpayment() {
		this.paymentArray.push(this.paymentResGroup());
	}

	removepayment(index) {
		this.paymentArray.removeAt(index);
	}

	clearalllines() {
		while (this.paymentArray.length !== 0) {
			this.paymentArray.removeAt(0)
		}
		this.paymentArray.push(this.paymentResGroup());
	}


	get paymentArray() {
		return <FormArray>this.nestedForm.get('paymentdetails');
	}

	saveCreditNote(values){
		this.spinner.show()
		this.sectorService.saveProductSource.next(true)
		setTimeout(() => {
			this.submithandler(values)
		}, 100,values);
	}

	submithandler(values) {
		!this.financialYearDiff && this.initDateValidation()

		this.loaderService.show()
		this.spinner.show()
		let productList = JSON.parse(sessionStorage.product_list)
		// return false;
		const invalid = [];
		const controls = this.nestedForm.controls;
		for (const name in controls) {
			if (controls[name].invalid && name != "paymentdetails") {
				invalid.push(name);
			}
		}
		if(invalid.length > 0) {
			window.scroll(0,0)
			this.showMessage = true;
			this.isSuccess = false
			this.invoice_number = ''
			this.alertType = 'error-box';
			this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
			this.spinner.hide()
			return false
		}
		let invoice_code = values.uuid
		let requestObj = {
			"client_code": this.selectedLedger && this.selectedLedger['account_code']?this.selectedLedger['account_code']:'',
			"client_name": this.selectedLedger && this.selectedLedger['name']?this.selectedLedger['name']:'',
			"tour_account_code": this.selectedTourLedger && this.selectedTourLedger['account_code']?this.selectedTourLedger['account_code'] :'',
			"tour_account_name": this.selectedTourLedger && this.selectedTourLedger['name']?this.selectedTourLedger['name'] :'',
			"billaddress": values.billaddress,
			"ledger_id": this.selectedLedger && this.selectedLedger['account_code']?this.selectedLedger['account_code']:this.selectedTourLedger['account_code'],
			"invoice_code": invoice_code,
			"invoice_date": moment(values.invdate).format('YYYY-MM-DDTHH:mm'),
			"credit_note_date": moment(values.refund_date).format('YYYY-MM-DDTHH:mm'),
			"flightType": values.flightType,
			"invoiceType": values.invoiceType,
			"created_by": 1,
			"updated_by": 1,
			"year_id": 1,
			"tenant_id": 1,
			"narration_1": values.narration_1,
			"narration_2": values.narration_2,
			"narration_3": values.narration_3,
			"narration_4": values.narration_4,
			"narration_5": values.narration_5,
			"remark": values.remark,
			"total":productList[0] ? productList[0].total : this.summedAmount,
			"balance_due": 0.0,
			"gst": this.gstAmount,
			"products": productList,
			"purchase_currency":values.purchase_currency?values.purchase_currency:null,
			"currency_rate" : values.currency_rate?values.currency_rate:null,
			"sales_currency": values.sales_currency ? values.sales_currency : null,
			"sales_currency_rate": values.sales_currency_rate ? values.sales_currency_rate : null,
			"sales_currency_amount": values.sales_currency_amount ? values.sales_currency_amount : null,
			"nonRefundable" : values.nonRefundable ? values.nonRefundable : false,
			"bill_of_supply": values.bill_of_supply,
			"no_tds_return_client": values.no_tds_return_client || false,
			"rcm_applicable": values.rcm_applicable ? values.rcm_applicable : false,
				// "reverse_tds": values.reverse_tds? values.reverse_tds : false
		  }
		  
		if(this.nestedForm.controls.bill_of_supply.value === true) {
			let originalRequest = this.requestBuilder.removeGSTAndServiceCharges(requestObj)
			let bosRequest = this.requestBuilder.extractGSTAndServiceCharges(requestObj)

			requestObj = originalRequest;
			requestObj['billOfSupplyRequest'] = bosRequest;
		}
				
		try {
			this.productservicedetail.newInvoice('creditNote', requestObj).subscribe(async (data) => {
				window.scroll(0,0)
				this.showMessage = false;
				this.isSuccess = true
				this.alertType = 'success-box';
				this.message = `Credit Note Created with credit note no. : `
				this.invoice_number =  data['credit_note_code']
				this.openSuccessModal.nativeElement.click()
				this.newInvoice = false
				this.nestedForm.reset()
				this.sectorService.resetFormData()
				// sessionStorage.removeItem('product_list')
				// let billingAddress = values.billaddress
				// this.nestedForm.controls.billaddress.setValue(billingAddress ? billingAddress : '')
				// this.summedAmount = 0;
				// this.gstAmount = 0;
				// this.paymentArray.controls = []
				// this.uuid = await this.getInvoice()
				// this.nestedForm.controls.uuid.setValue(this.uuid)
				// await this.addpayment()
				// window.history.replaceState({}, document.title, `/#/transactions/invoice?invoice_number=${this.uuid}`);
				this.spinner.hide()
			},err=>{
				window.scroll(0,0)
				this.showMessage = true;
				this.isSuccess = false
				this.invoice_number = ''
				this.alertType = 'error-box';
				this.message = err['error']['message']
				this.spinner.hide()
			})
		} catch (error) {
			console.log(error)
			this.spinner.hide()
		}

	}

	updateCreditNote(values) {
		!this.financialYearDiff && this.initDateValidation()

		const invalid = [];
		const controls = this.nestedForm.controls;
		
		let travellers = this.sectorService.travellerForm.value
		let invoiceForm = this.sectorService.invoiceForm.value

		for(let traveller of travellers.travellerList) {
			if(invoiceForm.invoiceType == "C" && (traveller.txtbookingSupplier == undefined || traveller.txtbookingSupplier == "" )) {
				this.closeUpdateModal.nativeElement.click()
				this.showMessage = true
				this.alertType = "error-box"
				this.message = "Payable not entered"
				this.spinner.hide()
				return ;
			}
		}

		for(let traveller of travellers.travellerList) {
			if(traveller.type_of_pay == "OUR_CC" && (traveller.ledger_card_name == undefined || traveller.ledger_card_name == "" )) {
				this.closeUpdateModal.nativeElement.click()
				this.showMessage = true
				this.alertType = "error-box"
				this.message = "Please select the card"
				this.spinner.hide()
				return ;
			}
		}

		for (const name in controls) {
			if (controls[name].invalid && name != "paymentdetails") {
				invalid.push(name);
			}
		}

		if(invalid.length > 0) {
			window.scroll(0,0)
			this.showMessage = true;
			this.isSuccess = false
			this.invoice_number = ''
			this.alertType = 'error-box';
			this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
			this.spinner.hide()
			this.closeUpdateConfirmModal()
			return false
		}
		this.spinner.show()
		this.sectorService.saveProductSource.next(true)
		setTimeout(()=>{

			let productList = JSON.parse(sessionStorage.product_list)
			
			let requestObj = {
				"client_code": this.selectedLedger && this.selectedLedger['account_code']?this.selectedLedger['account_code']:'',
				"client_name": this.selectedLedger && this.selectedLedger['name']?this.selectedLedger['name']:'',
				"tour_account_code": this.selectedTourLedger && this.selectedTourLedger['account_code']?this.selectedTourLedger['account_code'] :'',
				"tour_account_name": this.selectedTourLedger && this.selectedTourLedger['name']?this.selectedTourLedger['name'] :'',
				"billaddress": values.billaddress,
				"ledger_id": this.selectedLedger && this.selectedLedger['account_code']?this.selectedLedger['account_code']:this.selectedTourLedger['account_code'],
				"credit_note_code": values.uuid,
				"invoice_code": this.invoice_code,
				"invoice_date": moment(values.invdate).format('YYYY-MM-DDTHH:mm'),
				"credit_note_date": moment(values.refund_date).format('YYYY-MM-DDTHH:mm'),
				"invoiceType": values.invoiceType,
				"flightType": values.flightType,
				"created_by": 1,
				"updated_by": 1,
				"year_id": 1,
				"tenant_id": 1,
				"narration_1": values.narration_1,
				"narration_2": values.narration_2,
				"narration_3": values.narration_3,
				"narration_4": values.narration_4,
				"narration_5": values.narration_5,
				"remark": values.remark,
				"total":productList[0] ? productList[0].total : this.summedAmount,
				"balance_due": 0.0,
				"gst": this.gstAmount,
				"products": productList,
				"notify" : this.queryParams && this.queryParams.notify == "true" ? true : false,
				"channel_name" : this.queryParams && this.queryParams.channel ? this.queryParams.channel : null,
				"purchase_currency" : values.purchase_currency?values.purchase_currency:null,
				"currency_rate" : values.currency_rate?values.currency_rate:null,
				"sales_currency": values.sales_currency ? values.sales_currency : null,
				"sales_currency_rate": values.sales_currency_rate ? values.sales_currency_rate : null,
				"sales_currency_amount": values.sales_currency_amount ? values.sales_currency_amount : null,
				"nonRefundable" : values.nonRefundable ? values.nonRefundable : false,
				"no_tds_return_client": values.no_tds_return_client ? values.no_tds_return_client : false,
				"rcm_applicable": values.rcm_applicable ? values.rcm_applicable : false,
				// "reverse_tds": values.reverse_tds? values.reverse_tds : false
				
			  }
			  console.log(JSON.stringify(requestObj))

			  if(this.nestedForm.controls.bill_of_supply.value === true) {
				let originalRequest = this.requestBuilder.removeGSTAndServiceCharges(requestObj)
				let bosRequest = this.requestBuilder.extractGSTAndServiceCharges(requestObj)

				requestObj = bosRequest;
				requestObj['billOfSupplyRequest'] = originalRequest;
				requestObj['billOfSupplyRequest']['bill_of_supply'] = true;
				requestObj['billOfSupplyRequest']['credit_note_code'] = null;
			}
	
			try {
				this.productservicedetail.updateInvoice(`creditNote/${values.uuid}`, requestObj).subscribe(async (data) => {
					window.scroll(0,0)
					this.showMessage = true;
					this.isSuccess = true
					this.alertType = 'success-box';
					this.message = `Credit Note updated :  `
					this.invoice_number =  data['credit_note_code']

					// sessionStorage.removeItem('product_list')
					// let billingAddress = values.billaddress
					// this.nestedForm.reset()
					// this.nestedForm.controls.billaddress.setValue(billingAddress ? billingAddress : '')
					// this.summedAmount = 0;
					// this.gstAmount = 0;
					// this.paymentArray.controls = []
					// this.uuid = await this.getInvoice()
					// this.nestedForm.controls.uuid.setValue(this.uuid)
					// await this.addpayment()
					this.closeUpdateConfirmModal()
					// window.history.replaceState({}, document.title, `/#/transactions/invoice?invoice_number=${this.uuid}`);
					this.spinner.hide()

					if(requestObj['billOfSupplyRequest'] && requestObj['billOfSupplyRequest']['bill_of_supply'] == true) {
						this.forceReload()
						return;
					}

				},err=>{
					window.scroll(0,0)
					this.showMessage = true;
					this.isSuccess = false
					this.invoice_number = ''
					this.alertType = 'error-box';
					this.message = err['error']['message']
					this.closeUpdateConfirmModal()
					this.spinner.hide()
				})
			} catch (error) {
				console.log(error)
				this.spinner.hide()
			}
		},200,values)
	}

	get DynamicControls() {
		const secLst = <FormArray>this.nestedForm.get('paymentdetails');
		return secLst;
	}

	onChangeSearch(event) {
		let value = event.target.value;
		try {
			if (value.length > 2) {
				let url = `client/autocomplete?name_like=${value.toUpperCase()}&status=active`
				if(this.AllMasterClient=="true"){
					url = `client/autocomplete?name_like=${value.toUpperCase()}&status=active&client_type=all`
				}
				this.invoiceService.searchAccount(url).subscribe(res => {
					this.affiliateArr = res;
				});
			}
		} catch (error) {

		}
	}

	onChangeTourSearch(event) {	
		let value = event.target.value;	
		try {			
			if (value.length > 2) {
				let url =`general/autocomplete?name_like=${value.toUpperCase()}&status=active&sub_type=tour`
				
				this.invoiceService.searchAccount(url).subscribe(res => {
					this.tourArr = res;
				});
			}
		} catch (error) {

		}
	}

	async selectEvent(nzEvent) {
		let data = nzEvent;
		if(nzEvent && nzEvent.nzValue) {
			data = nzEvent.nzValue;
		}
		// let ledgerDetails = await this.getLedgerDetails(data.ledger_id)
		if(data.hasOwnProperty('id')) {
			this.selectedLedger = data;
			let billingAddress = data.address_1 + " " + data.address_2 + " " + data.address_3;
			this.nestedForm.controls.billaddress.setValue(billingAddress ? billingAddress : '')
			this.nestedForm.controls.affiliate_name.setValue(data.name ? data.name : '')
			this.sectorService.affiliate_code = data.account_code ? data.account_code : ''
			console.log(this.selectedLedger)
		}

		let currentClientConfiguration:any = await this.commonService.getClientConfiguration(data.account_code)

		if(this.invoiceMode != 'view') {
			let billOfSupplyObj = currentClientConfiguration.find(data => data.key == 'enable_bill_of_supply')
			if(billOfSupplyObj){
				let value = billOfSupplyObj.value;
				if(value == 'TRUE') {
					this.billOfSupplyActive = true;
				}
			} else {
				this.billOfSupplyActive = false;
			}
		} else { 
			let billOfSupplyObj = currentClientConfiguration.find(data => data.key == 'enable_bill_of_supply')
			if(billOfSupplyObj){
				let value = billOfSupplyObj.value;
				if(value == 'TRUE' && this.creditNoteData && this.creditNoteData.bill_of_supply == false && !this.creditNoteData.bos_reference) {
					this.billOfSupplyActive = true;
				}
			} else {
				this.billOfSupplyActive = false;
			}
		}
	}

	async selectTourEvent(nzEvent){
		let nzValue = nzEvent && nzEvent.nzValue;
		let data = nzValue;
		if(!nzValue) data= nzEvent
			// this.currentSelectedTour = data
			if (data.hasOwnProperty('general_id')) {
				this.selectedTourLedger = data;
				this.nestedForm.controls.tour_account_name.setValue(data.name ? data.name : '')
				this.nestedForm.controls.tour_account_code.setValue(data.account_code ? data.account_code : '')
				this.sectorService.tour_account_code = data.account_code ? data.account_code : ''
				
			}
			
		// }else {
		// 	// this.nestedForm.controls.affiliate_name.setValue(this.sectorService.currentMasterName)
		// 	// this.masterCreationModal.nativeElement.click()
        //     return false;
		// }
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		console.log("Width: " + event.target.innerWidth);
	}

	maxModule() {
		this.moduleView = !this.moduleView
	}

	moduleFixed() {
		this.fixedModule = true;
		this.moduleView = true
	}

	closeModuleFixed() {
		this.fixedModule = false;
		this.moduleView = false
	}
	onTabChange(tabName) {
		this.selectedTab = tabName
	}

	async getuuid(url) {
		if (!url.includes('invoice_number') && this.invoiceMode != 'view') {
			this.uuid = await this.getInvoice()
			this.nestedForm.controls.uuid.setValue(this.uuid)
			window.history.replaceState({}, document.title, `/#/transactions/invoice?invoice_number=${this.uuid}`);
		}else{
			console.log(url)
			if (url.split('=')[1] != undefined) {
				let invNo = url.split('=')[1]
				this.nestedForm.controls.uuid.setValue(invNo)
			}
		}
	}

	getInvoice(){
		return new Promise((resolve, reject) => {
			this.invoiceService.getMethod(`invoice/getuuid`).subscribe(
				res => {
					let invoiceNo ;
					try {
						invoiceNo = res["uuid"]
						resolve(invoiceNo);
					} catch (error) {
						invoiceNo = res
						resolve(invoiceNo);
					}
				},
				err => {
					reject("");
				}
			);
		});
	}
	getLedgerDetails(id){
		return new Promise((resolve, reject) => {
			this.invoiceService.getMethod(`ledger/alldata/${id}`).subscribe(
				res => {
					resolve(res);
				},
				err => {
					reject(null);
				}
			);
		});
	}

	openInvoice(invoice_number){

		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/print-credit-note/${invoice_number};type=flight`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

	getValue(formValue){
		console.log(formValue)
	}

	hideError(){
		this.showMessage = false
	}



	async bindInvoiceData(invoiceData) {
		console.log("invoiceMode",this.invoiceMode)
		
		let invCode  = invoiceData.invoice_no
		if (invCode == undefined) {
			invCode = invoiceData.invoice_code
		}

		let booking_ref_no = invoiceData && invoiceData.products && invoiceData.products.length > 0 && invoiceData.products[0].json_doc 
		                   && invoiceData.products[0].json_doc.booking_ref_no ? invoiceData.products[0].json_doc.booking_ref_no : ""

		this.nestedForm.controls.booking_ref_no.setValue(booking_ref_no)				   
		this.nestedForm.controls.invdate.setValue(invoiceData.invoice_date ? moment(invoiceData.invoice_date).format('YYYY-MM-DD')  : '')
		this.nestedForm.controls.summedAmount.setValue(invoiceData.total ? invoiceData.total : 0)
		this.nestedForm.controls.gstAmount.setValue(invoiceData.gst_total ? invoiceData.gst_total : 0)
		this.nestedForm.controls.uuid.setValue(invCode)
		this.invoice_code = invCode
		this.nestedForm.controls.flightType.setValue(invoiceData.flight_type ? invoiceData.flight_type : '');

		this.nestedForm.controls.invoiceType.setValue(invoiceData.invoice_type ? invoiceData.invoice_type : 'normal');
		this.nestedForm.controls.referenceInvoice.setValue(invoiceData.reference_invoice ? invoiceData.reference_invoice : '');
		this.nestedForm.controls.purchase_currency.setValue(invoiceData.purchase_currency ? invoiceData.purchase_currency : '');
		this.nestedForm.controls.currency_rate.setValue(invoiceData.currency_rate ? invoiceData.currency_rate : '');

		this.nestedForm.controls.sales_currency.setValue(invoiceData.sales_currency ? invoiceData.sales_currency : '');
		this.nestedForm.controls.sales_currency_rate.setValue(invoiceData.sales_currency_rate ? invoiceData.sales_currency_rate : '');
		this.nestedForm.controls.sales_currency_amount.setValue(invoiceData.sales_currency_amount ? invoiceData.sales_currency_amount : '');
		this.nestedForm.controls.rcm_applicable.setValue(invoiceData.rcm_applicable ? invoiceData.rcm_applicable : false);
		this.nestedForm.controls.no_tds_return_client.setValue(invoiceData.no_tds_return_client ? invoiceData.no_tds_return_client : false);
		// this.nestedForm.controls.reverse_tds.setValue(invoiceData.reverse_tds ? invoiceData.reverse_tds : false);

		this.nestedForm.controls.nonRefundable.setValue(invoiceData.non_refundable_to_client ? invoiceData.non_refundable_to_client : '');
		
		if (this.invoiceMode == 'view') {
			if (invoiceData.invoice_date == null) {
				this.nestedForm.controls.invdate.setValue(invoiceData.credit_note_date ? moment(invoiceData.credit_note_date).format('YYYY-MM-DD')  : '')
			}
			this.nestedForm.controls.narration.setValue(invoiceData.narration_1 ? invoiceData.narration_1 : '');
			this.nestedForm.controls.narration_1.setValue(invoiceData.narration_1 ? invoiceData.narration_1 : '');
			this.nestedForm.controls.narration_2.setValue(invoiceData.narration_2 ? invoiceData.narration_2 : '');
			this.nestedForm.controls.narration_3.setValue(invoiceData.narration_3 ? invoiceData.narration_3 : '');
			this.nestedForm.controls.narration_4.setValue(invoiceData.narration_4 ? invoiceData.narration_4 : '');
			this.nestedForm.controls.narration_5.setValue(invoiceData.narration_5 ? invoiceData.narration_5 : '');
			this.nestedForm.controls.remark.setValue(invoiceData.remark ? invoiceData.remark : '');
			this.nestedForm.controls.refund_date.setValue(moment(invoiceData.credit_note_date).format('YYYY-MM-DD'));
			this.nestedForm.controls.uuid.setValue(invoiceData.credit_note_code ? invoiceData.credit_note_code : '')
			
		}
		this.summedAmount = invoiceData.total ? invoiceData.total : 0;
		this.gstAmount = invoiceData.gst_total ? invoiceData.gst_total : 0;

		for(let i=0; i< invoiceData.products.length;i++) {
			if(i > 0) {
				await this.addpayment()
			}
			this.paymentArray.controls[i]['controls'].name.setValue(invoiceData.products[i]['product'])
			this.paymentArray.controls[i]['controls'].quantity.setValue(invoiceData.products[i]['quantity'])
			this.paymentArray.controls[i]['controls'].rate.setValue(invoiceData.products[i]['rate'])
			this.paymentArray.controls[i]['controls'].amount.setValue(invoiceData.products[i]['amount'])
			this.paymentArray.controls[i]['controls'].tax.setValue(invoiceData.products[i]['tax'])
			this.paymentArray.controls[i]['controls'].remark.setValue(invoiceData.products[i]['remark'] ? invoiceData.products[i]['remark'] : '')
			this.paymentArray.controls[i]['controls'].narration.setValue(invoiceData.products[i]['narration_1'] ? invoiceData.products[i]['narration_1'] : '')
			this.createProductList(invoiceData.products[i])
		}
		
	}

	createProductList(invoiceData) {
		let productObj = {
			"json_doc": invoiceData['json_doc'],
			"quantity": invoiceData.quantity,
			"rate": invoiceData.rate,
			"amount": invoiceData.amount,
			"tax": invoiceData.tax,
			"total": invoiceData.total,
			"product": invoiceData.product,
			"narration": invoiceData.narration
		}
		let product_list = JSON.parse(sessionStorage.getItem('product_list'))
		if (product_list == null) {
			product_list = []
		}
		product_list.push(productObj)
		sessionStorage.setItem('product_list', JSON.stringify(product_list))

	}

	openSendEmailModal(){
		//define email.
		let primary_email = ''
		let secondary_email = ''
		let subject = `CREDIT NOTE NO. ${this.invoice_no}`
		let body = `Dear Travel Partner,\n\nPlease find Attached Credit Note ${this.invoice_no}`
		body+=`\n\nPlease do not hesitate to contact us if you have any query or concern about attached document.\n\nIt's been a pleasure to serving you\n\nBest Regards\n\n${this.creditNoteData?.accounting_company.name}\nAccounts Team`
		if(this.creditNoteData.accounting_company && this.creditNoteData?.accounting_company.telephone){
			body += `\nContact Us on ${this.creditNoteData?.accounting_company.telephone}`
		}
		if(this.affiliateArr && this.affiliateArr.length>0){
			let clinetData = this.affiliateArr[0]
			primary_email = clinetData.hasOwnProperty('primary_email')?clinetData.primary_email:''
			secondary_email = clinetData.hasOwnProperty('secondary_email') && clinetData.secondary_email ?clinetData.secondary_email:''
		}
		this.sendEmailForm.controls.toEmails.setValue(primary_email)
		this.sendEmailForm.controls.bccEmails.setValue("")
		this.sendEmailForm.controls.ccEmails.setValue(secondary_email.split(',').join('\n'))
		this.sendEmailForm.controls.subject.setValue(subject)
		this.sendEmailForm.controls.body.setValue(body)
		this.openEmailModal.nativeElement.click();
	}

	callTosendEmail(){
		
		let toEmails = this.sendEmailForm.value.toEmails
		let bccEmails = this.sendEmailForm.value.bccEmails.split(",")
		let ccEmails = this.sendEmailForm.value.ccEmails.split(",")
		let clientName = this.affiliateArr  && this.affiliateArr.length>0 && this.affiliateArr[0].name?this.affiliateArr[0].name:''
		let subject = this.sendEmailForm.value.subject
		let body = this.sendEmailForm.value.body

		if(toEmails){
		  toEmails = toEmails.split(",")
		  window.scrollTo(0, 0)
		  let emails = {
			to_addresses:toEmails,
			cc_addresses:ccEmails,
			bcc_addresses:bccEmails
		  }
		  let pageUrl = `transactions/print-credit-note/${this.invoice_no};type=flight`
		  const formData = new FormData();
			formData.append('email',JSON.stringify(emails))
			formData.append('name',clientName)
			formData.append('page_url',pageUrl)
			formData.append('subject',subject)
			formData.append('email_body',body)
			formData.append('document_num',this.invoice_no)
			if(this.attachmentFile){
				formData.append('attachmentFile',this.attachmentFile)
			}
		
			let data = formData
		  this.commonService.fileUpload(`${environment.okountUrl}/send_mail?transaction_type=credit_note`,data).subscribe((res : any)=> {
			console.log(res)
			this.showMessage = true;
			this.alertType = 'success-box'
			this.message = res.msg
			this.closebutton.nativeElement.click();
		  },err => {
			this.showMessage = true
			this.alertType = "error-box"
			this.message = err.msg
			this.closebutton.nativeElement.click();
			
		  })
		}
		
	  }

	onFocused(e) {

	}

	viewJournal(invoice_no){
		// let currentUrl = `transactions/credit-note/${this.invoiceMode}/${invoice_no}`
		// localStorage.setItem('previousInvoiceUrl',currentUrl)
		// this.router.navigate(['/transactions/view-invoice-journal',invoice_no])
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/view-invoice-journal/${invoice_no}`;
		if(window.location.protocol != 'file:') {
			window.open(path,'_blank')
		}
	}

	closeWindow() {
		window.close();
	}
	deleteCreditNote(credit_note_code){
		this.closeDeleteConfirmModal()
		this.commonService.deleteRequest(`${environment.okountUrl}/creditNote?credit_note_code=${credit_note_code}`).subscribe(res=>{
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = `Credit Note Deleted`
		},err=>{
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = `Failed to delete credit note.`
		})
	}
	resetCurrentOpendModal(){
		this.currentOpenedModal=''
	}
	openDeleteConfirmModal(){
		if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.creditNoteData.credit_note_date).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to delete the credit note.'
				return false;
			}
		}
		this.closeOpenedModals('delete')
		this.currentOpenedModal = 'delete'
		this.confirmDeleteModal.nativeElement.click()
	}
	closeDeleteConfirmModal(){
		this.currentOpenedModal = ''
		this.closeDeleteModal.nativeElement.click()
	}

	openUpdateConfirmModal(){
		if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.creditNoteData.credit_note_date).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to update the credit note.'
				return false;
			}
		}
		this.closeOpenedModals('update')
		this.currentOpenedModal = 'update'
		this.confirmUpdateModal.nativeElement.click()
	}
	closeUpdateConfirmModal(){
		this.currentOpenedModal = ''
		this.closeUpdateModal.nativeElement.click()
	}

	closeOpenedModals(modalName){
		if(modalName!='delete') this.closeDeleteConfirmModal()
		if(modalName!='update') this.closeUpdateConfirmModal()
	}

	closeSuccesModal(){
		this.showMessage = false;
		this.isSuccess = false
		this.alertType = 'success-box';
		this.message = ``
		this.newInvoice = true
		this.openSuccessModal.nativeElement.click()
	}

	copytext(val: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.copyELementTitle = 'Copied'
	}

	viewCreditNote(creditNo) {
		let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/credit-note/view/${creditNo}`;
		window.open(url, "_blank");
	}

	clickToPrintOptions() {
		this.showPrintOptions = !this.showPrintOptions;
	}

	checkWhetherEInvGeneratedOrNot(invoice) {
		this.commonService.getRequest(`${environment.okountUrl}/e_invoicing/check-invoice?invoiceNumber=${invoice}`).subscribe((res : any)=>{
			if(res && res['message'] && res['message'] == 'not_generated') {
				this.generateEInvoiceModal.nativeElement.click()
				return false;
			} 

			this.getPdfOfEInvoice(invoice)
		},err=>{
			console.log(err)
		})
	}

	getPdfOfEInvoice(invoice) {
		this.spinner.show()
		this.commonService.downloadPDF(`${environment.okountUrl}/e_invoicing/print/creditnote?invoiceNumber=${invoice}&preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `E-invoice-${invoice}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			this.spinner.hide()
			FileSaver.saveAs(blob, fileName);
	   }, err => {
		    this.spinner.hide()
	   })
	}

	closeGenerateEInvoiceModal(){
		this.generateEInvoiceModal.nativeElement.click()
	}

	generateEInvoice(invoice) {
		this.spinner.show()
		this.commonService.postRequest(`${environment.okountUrl}/e_invoicing?invoiceNumber=${invoice}`, null).subscribe((res : any)=> {
			this.showMessage = true;
			this.alertType = 'success-box'
			this.getPdfOfEInvoice(invoice);
		},err => {
			this.showMessage = true
			this.spinner.hide()
			this.alertType = "error-box"
			this.message = err.error && err.error.message ? err.error.message : ''
		})

	}

	onpurchaseCurrencyChange(value){
		this.nestedForm.controls.currency_rate.setValue('');
		// this.nestedForm.controls.narration_1.setValue(value) 
	
	}
	getAccountingCompany() {
		try {
			this.invoiceService.getMethod('util/accounting-company').subscribe(async (res) => {
				if(res) {
					if(res['currency_code']) {
						this.currencyCode = res['currency_code'];
					}
					if(res['native_language_code'] && res['currency_code'] == 'AED') {
						this.nativeLanguageCode = res['native_language_code'];
					}					
				}
			},
			err => {
				this.spinner.hide()
			})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
		}
	}

	changeSalesCurrentAmount(event) {
		let sales_currency = this.nestedForm.controls.sales_currency.value;
		let sales_currency_rate = this.nestedForm.controls.sales_currency_rate.value;
		if(sales_currency && sales_currency_rate) {
			let client_net_amount = Number(this.sectorService.totalFareModel[this.sectorService.totalFareModel.length-1]['net_paid'])
			let grandTotal = Number(client_net_amount);
			this.nestedForm.controls.sales_currency_amount.setValue((grandTotal * sales_currency_rate).toFixed(2));
		} else {
			this.nestedForm.controls.sales_currency_amount.setValue('')
		}
	}
	handleAttachment(event: any) {
		this.attachmentFile = event.target.files[0];
	}

	creditNoteDateValidation() {
		let financeYearResponse = this.commonService.checkFinancialYearValidation();
		this.financialYearDiff = financeYearResponse.isFinancialYearDiff;
		this.minDate = financeYearResponse.minDate;
		this.maxDate = financeYearResponse.maxDate;

		this.nestedForm.controls.refund_date.setValue(this.maxDate);
		this.nestedForm.controls.refund_date.setValidators([Validators.required, this.checkRefundDate()]);
		this.nestedForm.controls.refund_date.updateValueAndValidity()
	}  

	toggleMenu() {
		this.menuVisible = !this.menuVisible;
	  }
}
