<div class="total-details mt-4">
	<table class="table table-hovered table-bordered">
		<tr>
			<th></th>
			<th>FARE BREAKUP</th>
			<th>CLIENT</th>
			<th>SUPPLIER</th>

		</tr>
		<tr>
			<td>
				<table class="w-100">
					<tr>
						<th class="text-left">Reference</th>
					</tr>
					<tr *ngFor="let invoice of tourService.fareList let i = index">
						<td class="text-left">
							<a class="hyperLink" *ngIf="invoice.invoice_no != undefined" (click)="viewPrintScreen(invoice.invoice_no,i)">{{invoice.invoice_no}} 
								
							</a>
							<a class="hyperLink" *ngIf="invoice.invoice_no == undefined" (click)="viewPrintScreen(invoice,i)">{{invoice}} </a>
						</td>
					</tr>
				</table>

			</td>
			<td class="inner-supplier-table">
				<table class="w-100">
					<tr>
						<th class="text-right">Base</th>
						<th class="text-right">OT</th>
					</tr>
					<tr *ngFor="let invoice_no of tourService.fareList let i = index">
						<td class="text-right">{{tourService?.totalFareModel[i]?.client_basic | number : '1.2-2'}}</td>
						<td class="text-right">{{tourService?.totalFareModel[i]?.client_taxes | number : '1.2-2'}}</td>
					</tr>
				</table>

			</td>
			<td class="inner-supplier-table">
				<table class="w-100">
					<tr>
						<th class="text-right">Comm.</th>
						<th *ngIf="discount_add=='true'" class="text-right">Discount</th>
						<th class="text-right">TDS</th>
						<th class="text-right">S Ch.</th>
						<th class="text-right" *ngIf="isTenantIndian">GST</th>
						<th class="text-right" *ngIf="!isTenantIndian">{{vatAlternetname}}</th>
						<th class="text-right">Net Amount</th>
					</tr>
					<tr *ngFor="let invoice of tourService.fareList let i = index">
						<td class="text-right">{{tourService?.totalFareModel[i]?.client_commission_amount | number : '1.2-2'}}</td>
						<td *ngIf="discount_add=='true'" class="text-right">{{tourService?.totalFareModel[i]?.client_discount_amount | number : '1.2-2'}}</td>
						<td class="text-right">{{tourService?.totalFareModel[i]?.client_tds_amount | number : '1.2-2'}}</td>
						<td class="text-right">{{tourService?.totalFareModel[i]?.client_sc1_amount | number : '1.2-2'}}</td> <!--  TODO : Calculate in fare calculation -->
						<td class="text-right" *ngIf="isTenantIndian">{{tourService?.totalFareModel[i]?.client_gst | number : '1.2-2'}}</td>
						<td class="text-right" *ngIf="!isTenantIndian">{{tourService?.totalFareModel[i]?.client_vat_amount | number : '1.2-2'}}</td>
						<td class="text-right" >{{tourService?.totalFareModel[i]?.client_net_amount | number : '1.0-0'}}</td>
					</tr>
				</table>

			</td>
			<td>
				<table class="w-100">
					<tr>
						<th class="text-right">Comm.</th>
						<th *ngIf="discount_add=='true'" class="text-right">Discount</th>
						<th class="text-right">TDS</th>
						<th class="text-right">SC.T</th>
						<th class="text-right" *ngIf="isTenantIndian">GST</th>
						<th class="text-right" *ngIf="!isTenantIndian">{{vatAlternetname}}</th>
						<th class="text-right">Net Amount</th>
					</tr> 
					<tr *ngFor="let invoice of tourService.fareList let i = index">
						<td class="text-right">{{tourService?.totalFareModel[i]?.supplier_commission_amount | number : '1.2-2'}}</td>
						<td *ngIf="discount_add=='true'"class="text-right">{{tourService?.totalFareModel[i]?.supplier_discount_amount | number : '1.2-2'}}</td>
						<td class="text-right">{{tourService?.totalFareModel[i]?.supplier_tds_amount | number : '1.2-2'}}</td>
						<td class="text-right">{{tourService?.totalFareModel[i]?.supplier_sc1_amount | number : '1.2-2'}}</td> <!--  TODO : Calculate in fare calculation -->
						<td class="text-right" *ngIf="isTenantIndian">{{tourService?.totalFareModel[i]?.supplier_gst | number : '1.2-2'}}</td>
						<td class="text-right" *ngIf="!isTenantIndian">{{tourService?.totalFareModel[i]?.supplier_vat_amount | number : '1.2-2'}}</td>	
						<td class="text-right" >{{tourService?.totalFareModel[i]?.supplier_net_amount | number : '1.0-0'}}</td>
					</tr>
				</table>

			</td>

		</tr>
	</table>
</div>