import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { HeaderComponent } from './header/header.component';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonServiceService } from './common-service.service';
import { LoaderService } from './loader.service';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NumberToWordsPipe } from './pipe/print-invoice.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { GlobalFilterPipe } from './pipe/global-filter.pipe'
import { GlobalSearchModule } from './global-search/global-search.module';
import { TabDirective } from './directive/tab.directive';
import { XlsDownloadService } from './xls-download.service';
import { SearchTabDirective } from './directive/search-tab.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DownloadService } from './download.service';
import { HasAccessDirective } from './directive/has-access.directive';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { ResetPasswordComponent } from '../user-management/reset-password/reset-password.component';
import { CommonDataService } from './services/common-data.service';
import { UpdatePasswordComponent } from '../user-management/update-password/update-password.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LogoutPopupComponent } from '../user-management/logout-popup/logout-popup.component';
import { MasterSettingComponent } from './master-setting/master-setting.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CentrifugeService } from './services/centrifuge.service';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { NotificationAreaComponent } from './notification-area/notification-area.component';
import { NgRedux, NgReduxModule } from '@angular-redux/store';
import { initialState, InitialState, rootReducers } from '../store/reducer';
import { ViewInvoiceComponent } from '../transactions/invoice/view-invoice/view-invoice.component';
import { PrintCreditNoteComponent } from '../transactions/invoice-refund/print-credit-note/print-credit-note.component';
import { MsInvoiceViewComponent } from '../transactions/invoice/ms-invoice-view/ms-invoice-view.component';
import { PrintPaymentComponent } from '../transactions/payment/print-payment/print-payment.component';
import { PrintReceiptComponent } from '../transactions/receipt/print-receipt/print-receipt.component';
import { NormalLedgerComponent } from '../display/account-book/ledger/normal-ledger/normal-ledger.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { DetailedLedgerComponent } from '../display/account-book/ledger/detailed-ledger/detailed-ledger.component';
import { NzTableModule, NZ_I18N, en_US, NZ_ICONS, NzAutocompleteModule, NzAutocompleteOptionComponent, NzEmptyModule, NzModalModule} from 'ng-zorro-antd';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
import en from '@angular/common/locales/en';
registerLocaleData(en);
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])
import { MatTabsModule } from '@angular/material/tabs';
import { PrintTourInvoiceComponent } from '../transactions/tour/print-tour-invoice/print-tour-invoice.component';
import { PrintMiscInvoiceComponent } from '../transactions/misc-invoice/print-misc-invoice/print-misc-invoice.component';
import { PrintAdmAcmComponent } from '../transactions/adm-acm/print-adm-acm/print-adm-acm.component';
import { PrintJvComponent } from '../transactions/jv/print-jv/print-jv.component';
import { MasterCreationComponent } from './master-creation/master-creation.component';

@NgModule({
  declarations: [HeaderComponent,NumberToWordsPipe, GlobalFilterPipe, TabDirective, HasAccessDirective , ResetPasswordComponent,UpdatePasswordComponent , LogoutPopupComponent, MasterSettingComponent, LeftSidebarComponent, NotificationAreaComponent,
  ViewInvoiceComponent,PrintCreditNoteComponent,MsInvoiceViewComponent,PrintPaymentComponent,PrintReceiptComponent,PrintAdmAcmComponent,PrintJvComponent,PrintTourInvoiceComponent,PrintMiscInvoiceComponent,NormalLedgerComponent,DetailedLedgerComponent,MasterCreationComponent
  ] ,
  imports: [
    CommonModule,
    SharedRoutingModule,
    HttpModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    AutocompleteLibModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    KeyboardShortcutsModule.forRoot(),
    SelectDropDownModule,
    GlobalSearchModule,
    InfiniteScrollModule,
    NgxChartsModule,
    NgReduxModule,
    NzTableModule,
    NzAutocompleteModule,
    MatTabsModule,
    NzModalModule,
    NzEmptyModule
  ],
  exports: [HeaderComponent,
    LeftSidebarComponent,
    NotificationAreaComponent,
    HttpModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    AutocompleteLibModule,
    NumberToWordsPipe,
    BsDatepickerModule,
    ModalModule,
    KeyboardShortcutsModule,
    SelectDropDownModule,
    GlobalFilterPipe,
    TabDirective,
    InfiniteScrollModule,
    HasAccessDirective,
    NgxChartsModule,
    MasterSettingComponent,
    ViewInvoiceComponent,
    PrintCreditNoteComponent,
    MsInvoiceViewComponent,
    PrintTourInvoiceComponent,
    PrintMiscInvoiceComponent,
    PrintPaymentComponent,
    PrintReceiptComponent,
    PrintAdmAcmComponent,
    PrintJvComponent,
    NormalLedgerComponent,
    DetailedLedgerComponent,
    MasterCreationComponent,
    NzTableModule,
    NzEmptyModule,
    NzAutocompleteModule,
    NzAutocompleteOptionComponent,
    MatTabsModule,
    CommonModule,
    SharedRoutingModule,
    GlobalSearchModule,
    NzModalModule,
    NgReduxModule
  ],
  providers : [CommonServiceService, LoaderService,XlsDownloadService, DownloadService,CentrifugeService,CommonDataService, {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
  },HttpInterceptorService,{ provide: NZ_I18N, useValue: en_US }, { provide: NZ_ICONS, useValue: icons }]
})
export class SharedModule { 
  constructor(ngRedux: NgRedux<InitialState>) {
		ngRedux.configureStore(rootReducers, initialState);
	  }
}
