<div class="ledger-container mb-20 ">
    <div class="row tab-nav pl-0 pr-0">
        <div class="col-sm-6 ">
          <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
              <li  
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> Reports</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li  
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> Ledger </li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li class="text-grey text-capitalize cursor text-primary transition"> Search </li>
          </ul>
      </div>
    </div>
    <div class="pl-15" >
        <app-ledger-search class="modal-scroll-wrapper"></app-ledger-search>
    </div>
</div>