import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TourfileComponent } from './tourfile.component';
import { CreateTourfileComponent} from './create-tourfile/create-tourfile.component';
import { UpdateTourfileComponent } from './update-tourfile/update-tourfile.component';
import { TourfileRoutingModule } from './tourfile-routing.module';
import { ViewTourfileComponent } from './view-tourfile/view-tourfile.component'

@NgModule({
  declarations: [TourfileComponent, CreateTourfileComponent,  UpdateTourfileComponent, ViewTourfileComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    TourfileRoutingModule
  ],
  exports: [UpdateTourfileComponent]
})
export class TourfileModule { }
