import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TourFareSummaryModel } from '../models/tour-fare-summary-model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class TourService {
  
  invoiceForm: any;
  ledgerList: any = [];
  travellerForm: any;
  travellerList: FormArray;
  totalFareModel = []
  fareList = []
  lastIndexOfTraveller = 60
  invoice_config = {
    "tour" : {
      "general_ledger" : "TI0000129",
      "sac_code" : ""
    }
  }
  retrieveData: any=[]
  invoiceTotalAmount:Number = 0
  constructor(private fb: FormBuilder, private httpClient: HttpClient) {
    this.initialiseFormData({},{})
    this.travellerForm = this.fb.group({
			travellerList: this.fb.array([]),
		});
    let obj = {
      invoice_no: 'New Invoice',
    };
    this.fareList.push(obj);
    this.addTraveller({})
    this.calculateFromApi(0)
  }

  initialiseTraveller(){
    this.travellerForm = this.fb.group({
			travellerList: this.fb.array([]),
		});
    this.travellerList = this.travellerForm.get("travellerList");
    this.travellerList = this.travellerForm.get("travellerList") as FormArray;
    this.travellerList.controls = []
    let obj = {
      invoice_no: 'New Invoice',
    };
    this.fareList = [obj]
    this.addTraveller({})
    this.calculateFromApi(0)
  }

  addTraveller(data) {
    if(!this.travellerList){
      this.travellerList = this.travellerForm.get("travellerList");
		  this.travellerList = this.travellerForm.get("travellerList") as FormArray;
      this.travellerList.controls = []
    }
		this.travellerList.push(this.buildTraveller(data));
    this.lastIndexOfTraveller = 60
    this.lastIndexOfTraveller = this.travellerList.controls.length * this.lastIndexOfTraveller
	}

  removeTraveller(i) {
		this.travellerList.removeAt(i)
    this.invoiceForm.controls.number_of_pax.setValue(this.travellerList.length)
		this.calculateFromApi(0)
	}
  travellerCountUpdate(travellerLength) {

		this.travellerList = this.travellerForm.get("travellerList");
		this.travellerList = this.travellerForm.get("travellerList") as FormArray;
    let tempControl = Object.assign({},this.travellerList.controls)
    this.travellerList.controls = []
		for (let i = 0; i < travellerLength; i++) {
      if(tempControl[i]){
        let data = tempControl[i].value
        this.addTraveller(data)
      }else{
        this.addTraveller({})
      }
		}
		return ""
	}

  buildTraveller(data) {
    let hsn = this.getHSN(this.invoiceForm.value.invoice_type)
		return this.fb.group({
      
      service_name : [data.service_name ? data.service_name : ""],
      start_date : [data.start_date ? moment(data.start_date).format('YYYY-MM-DD')  : ""],
      end_date : [data.end_date ? moment(data.end_date).format('YYYY-MM-DD')  : ""],
      
      reference_number: [data.reference_number ? data.reference_number : ""],
      lead_pax_name: [data.lead_pax_name ? data.lead_pax_name.account_name?data.lead_pax_name.account_name:data.lead_pax_name : ""],
      quantity: [data.quantity ? data.quantity : ""],
      
      supplier_non_account: [data.supplier_non_account ? data.supplier_non_account : 0],
      supplier_basic: [data.supplier_basic ? data.supplier_basic : 0],
      supplier_taxes: [data.supplier_taxes ? data.supplier_taxes : 0],
      supplier_commission_type: [data.supplier_commission_type ? data.supplier_commission_type : "RB"],
      supplier_commission_value: [data.supplier_commission_value ? data.supplier_commission_value : 0],
      supplier_commission_amount: [data.supplier_commission_amount ? data.supplier_commission_amount : 0],
      supplier_discount_type: [data.supplier_discount_type ? data.supplier_discount_type : "VL"],
      supplier_discount_amount: [data.supplier_discount_amount ? data.supplier_discount_amount : 0],
      supplier_tds_amount: [data.supplier_tds ? data.supplier_tds : 0],
      supplier_tds_type: [data.supplier_tds_type ? data.supplier_tds_type : "C"],
      supplier_tds_conf: [data.supplier_tds_conf ? data.supplier_tds_conf : 0],

      supplier_sc1_type: [data.supplier_sc1_type ? data.supplier_sc1_type : "T"],
      supplier_sc1_amount: [data.supplier_sc1_amount ? data.supplier_sc1_amount : 0],
      supplier_sc2_type: [data.supplier_sc2_type ? data.supplier_sc2_type : "B"],
      supplier_sc2_taxable: [ data.supplier_sc2_taxable? data.supplier_sc2_taxable : "N"],
      supplier_sc2_amount: [data.supplier_sc2_amount ? data.supplier_sc2_amount : 0],
      supplier_sc3_type: [data.supplier_sc3_type ? data.supplier_sc3_type : "OT"],
      supplier_sc3_taxable: [data.supplier_sc3_taxable ? data.supplier_sc3_taxable : "N"],
      supplier_sc3_amount: [data.supplier_sc3_amount ? data.supplier_sc3_amount : 0],
      
      supplier_gst_type: [data.supplier_gst_type ? data.supplier_gst_type : "S"],
      supplier_gst_conf: [data.supplier_gst_conf ? data.supplier_gst_conf : 18],
      supplier_cgst: [data.supplier_cgst ? data.supplier_cgst : 0],
      supplier_sgst: [data.supplier_sgst ? data.supplier_sgst : 0],
      supplier_igst: [data.supplier_igst ? data.supplier_igst : 0],

      supplier_cgst_on_raf: [data.supplier_cgst_on_raf ? data.supplier_cgst_on_raf : 0],
      supplier_sgst_on_raf: [data.supplier_sgst_on_raf ? data.supplier_sgst_on_raf : 0],
      supplier_igst_on_raf: [data.supplier_igst_on_raf ? data.supplier_igst_on_raf : 0],
      supplier_vat_on_raf: [data.supplier_vat_on_raf ? data.supplier_vat_on_raf : 0],

      supplier_gst_type_2: [data.supplier_gst_type_2 ? data.supplier_gst_type_2 : "B"],
      supplier_gst_conf_2: [data.supplier_gst_conf_2 ? data.supplier_gst_conf_2 : 0],
      supplier_cgst_2: [data.supplier_cgst_2 ? data.supplier_cgst_2 : 0],
      supplier_sgst_2: [data.supplier_sgst_2 ? data.supplier_sgst_2 : 0],
      supplier_igst_2: [data.supplier_igst_2 ? data.supplier_igst_2 : 0],

      supplier_gst_type_3: [data.supplier_gst_type_3 ? data.supplier_gst_type_3 : "TOTAL"],
      supplier_gst_conf_3: [data.supplier_gst_conf_3 ? data.supplier_gst_conf_3 : 0],
      supplier_cgst_3: [data.supplier_cgst_3 ? data.supplier_cgst_3 : 0],
      supplier_sgst_3: [data.supplier_sgst_3 ? data.supplier_sgst_3 : 0],
      supplier_igst_3: [data.supplier_igst_3 ? data.supplier_igst_3 : 0],

      supplier_xxl_charges: [data.supplier_xxl_charges ? data.supplier_xxl_charges : 0],
      supplier_raf_charges: [data.supplier_raf_charges ? data.supplier_raf_charges : 0],
      
      supplier_net_amount: [data.supplier_net_amount ? data.supplier_net_amount : 0],

      client_non_account: [data.client_non_account ? data.client_non_account : 0],
      client_basic: [data.client_basic ? data.client_basic : 0],
      client_taxes: [data.client_taxes ? data.client_taxes : 0],
      client_commission_type: [data.client_commission_type ? data.client_commission_type : "RB"],
      client_commission_value: [data.client_commission_value ? data.client_commission_value : 0],
      client_commission_amount: [data.client_commission_amount ? data.client_commission_amount : 0],
      client_discount_type: [data.client_discount_type ? data.client_discount_type : "VL"],
      client_discount_amount: [data.client_discount_amount ? data.client_discount_amount : 0],
      client_tds_amount: [data.client_tds_amount ? data.client_tds_amount : 0],
      client_tds_type: [data.client_tds_type ? data.client_tds_type : "C"],
      client_tds_conf: [data.client_tds_conf ? data.client_tds_conf : 0],

      client_sc1_type: [data.client_sc1_type ? data.client_sc1_type : "T"],
      client_sc1_amount: [data.client_sc1_amount ? data.client_sc1_amount : 0],
      client_sc2_type: [data.client_sc2_type ? data.client_sc2_type : "B"],
      client_sc2_taxable: [data.client_sc2_taxable ? data.client_sc2_taxable : "N"],
      client_sc2_amount: [data.client_sc2_amount ? data.client_sc2_amount : 0],
      client_sc3_type: [data.client_sc3_type ? data.client_sc3_type : "OT"],
      client_sc3_amount: [data.client_sc3_amount ? data.client_sc3_amount : 0],
      client_sc3_taxable: [data.client_sc3_taxable ? data.client_sc3_taxable : "N"],

      client_gst_type: [data.client_gst_type ? data.client_gst_type : "S"],
      client_gst_conf: [data.client_gst_conf ? data.client_gst_conf : 18],
      client_cgst: [data.client_cgst ? data.client_cgst : 0],
      client_sgst: [data.client_sgst ? data.client_sgst : 0],
      client_igst: [data.client_igst ? data.client_igst : 0],

      client_cgst_on_raf: [data.client_cgst_on_raf ? data.client_cgst_on_raf : 0],
      client_sgst_on_raf: [data.client_sgst_on_raf ? data.client_sgst_on_raf : 0],
      client_igst_on_raf: [data.client_igst_on_raf ? data.client_igst_on_raf : 0],
      client_vat_on_raf: [data.client_vat_on_raf ? data.client_vat_on_raf : 0],

      client_gst_type_2: [data.client_gst_type_2 ? data.client_gst_type_2 : "B"],
      client_gst_conf_2: [data.client_gst_conf_2 ? data.client_gst_conf_2 : 0],
      client_cgst_2: [data.client_cgst_2 ? data.client_cgst_2 : 0],
      client_sgst_2: [data.client_sgst_2 ? data.client_sgst_2 : 0],
      client_igst_2: [data.client_igst_2 ? data.client_igst_2 : 0],

      client_gst_type_3: [data.client_gst_type_3 ? data.client_gst_type_3 : "TOTAL"],
      client_gst_conf_3: [data.client_gst_conf_3 ? data.client_gst_conf_3 : 0],
      client_cgst_3: [data.client_cgst_3 ? data.client_cgst_3 : 0],
      client_sgst_3: [data.client_sgst_3 ? data.client_sgst_3 : 0],
      client_igst_3: [data.client_igst_3 ? data.client_igst_3 : 0],
      hsn: [data.hsn? data.hsn:hsn],
      hsn_2: [data.hsn_2? data.hsn_2:''],
      hsn_3: [data.hsn_3? data.hsn_3:''],

      client_xxl_charges: [data.client_xxl_charges ? data.client_xxl_charges : 0],
      client_raf_charges: [data.client_raf_charges ? data.client_raf_charges : 0],
      
      client_net_amount: [data.client_net_amount ? data.client_net_amount : 0],

      service_details_1 : [data.service_details_1 ? data.service_details_1 : ''],
      service_details_2 : [data.service_details_2 ? data.service_details_2 : ''],
      service_details_3 : [data.service_details_3 ? data.service_details_3 : ''],
      service_details_4 : [data.service_details_4 ? data.service_details_4 : ''],
      service_details_5 : [data.service_details_5 ? data.service_details_5 : ''],

      pay_type: [data.pay_type ? data.pay_type : ""],
      ledger_card_no: [data.credit_card_account_code ? data.credit_card_account_code : ""],
      ledger_card_name: [data.credit_card_account_name ? data.credit_card_account_name.name ? data.credit_card_account_name.name : data.credit_card_account_name:''],
      e_code: [data.e_code ? data.e_code : ""],
      cost_center: [data.cost_center ? data.cost_center : ""],
      sales_person_name: [data.sales_person_name && data.sales_person_name.t_user_name ? data.sales_person_name.t_user_name: data.sales_person_name ],
       sales_person_code: [data.sales_person_code ? data.sales_person_code : ""], 
       refered_by_name: [data.refered_by_name && data.refered_by_name.name ? data.refered_by_name.name: data.refered_by_name ],
      refered_by_code: [data.refered_by_code ? data.refered_by_code : ""],
       invoice_cost_center: [data.invoice_cost_center?data.invoice_cost_center:''],
           payable_transaction_code: [data.payable_transaction_code ? data.payable_transaction_code : ""],
      payable_date: [data.payable_date ? moment(data.payable_date, 'YYYY-MM-DD HH:mm:ssZ').format('YYYY-MM-DD'): ""],
      payable_account_type: [data.payable_account_type ? data.payable_account_type : ""],
      payable_code:  [data.payable_account_name && data.payable_account_name.account_code ? data.payable_account_name.account_code : data.payable_code || data.payable_account_code || "" ],
      payable_account_name: [data.payable_account_name && data.payable_account_name.name ? data.payable_account_name.name : data.payable_account_name || "" ],
      payable_account_is_branch: [data.payable_account_is_branch ? data.payable_account_is_branch: false],
      associate_payable_code:  [data.associate_payable_account_name && data.associate_payable_account_name.account_code ? data.associate_payable_account_name.account_code : data.associate_payable_code || data.associate_payable_account_code || ""],
      associate_payable_account_name: [data.associate_payable_account_name && data.associate_payable_account_name.name ? data.associate_payable_account_name.name : data.associate_payable_account_name || ""],
      supplier_vat_type: [data.supplier_vat_type ? data.supplier_vat_type : "S"],
      supplier_vat_conf: [data.supplier_vat_conf ? data.supplier_vat_conf : 5],
      supplier_vat_amount: [data.supplier_vat_amount ? data.supplier_vat_amount : 0],

      client_vat_type: [data.client_vat_type ? data.client_vat_type : "S"],
      client_vat_conf: [data.client_vat_conf ? data.client_vat_conf : 5],
      client_vat_amount: [data.client_vat_amount ? data.client_vat_amount : 0]
    });
  }


  initialiseFormData(data,invoiceData){
    this.invoiceForm = this.fb.group({
      invoice_type: [invoiceData.invoice_type ? invoiceData.invoice_type : "tour",Validators.required],
      transaction_method: [data.transaction_method ? data.transaction_method : invoiceData.transaction_method ? invoiceData.transaction_method : "sales",Validators.required],
      client_account_type: [invoiceData.account_type ? invoiceData.account_type : "client",Validators.required],
      client_code: [invoiceData.account_code ? invoiceData.account_code : "",Validators.required],
      client_code_name: [invoiceData.account_name ? invoiceData.account_name : "",Validators.required],
      tour_code: [invoiceData.tour_code ? invoiceData.tour_code : ""],
      tour_code_name: [invoiceData.tour_code_name ? invoiceData.tour_code_name : ""],
      general_ledger_code: [invoiceData.general_ledger_code ? invoiceData.general_ledger_code : ""],
      general_ledger_code_name: [invoiceData.general_ledger_code_name ? data.general_ledger_code_name : ""],
      supplier_account_type: [data.supplier_account_type ? data.supplier_account_type : "supplier"],
      supplier_code: [data.supplier_code ? data.supplier_code : ""],
      supplier_code_name: [data.supplier_code_name ? data.supplier_code_name : ""],
      subledger_type: [invoiceData.subledger_type ? invoiceData.subledger_type : "client"],
      subledger_code: [invoiceData.subledger_code ? invoiceData.subledger_code : ""],
      subledger_code_name: [invoiceData.subledger_code_name ? invoiceData.subledger_code_name : ""],
      invoice_date: [data.invoice_date ? moment(data.invoice_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')],
      sub_invoice_type: [invoiceData.sub_invoice_type ? invoiceData.sub_invoice_type : ""],
      hsn: [invoiceData.hsn? invoiceData.hsn : this.getHSN(invoiceData.invoice_type)],
        hsn_2: [invoiceData.hsn_2? invoiceData.hsn_2 : ''],
        c_sc1_type: [invoiceData.c_sc1_type? invoiceData.c_sc1_type : 'VL'],
        c_sc1_value: [invoiceData.c_sc1_value? invoiceData.c_sc1_value : 0],
        c_sc1_amount: [invoiceData.c_sc1_amount? invoiceData.c_sc1_amount : 0],
        c_gst_type: [invoiceData.c_gst_type? invoiceData.c_gst_type : 'T'],
        c_gst_conf: [invoiceData.c_gst_conf? invoiceData.c_gst_conf : 0],
        c_cgst: [invoiceData.c_cgst? invoiceData.c_cgst : 0],
        c_sgst: [invoiceData.c_sgst? invoiceData.c_sgst : 0],
        c_igst: [invoiceData.c_igst? invoiceData.c_igst : 0],
        c_net_gst: [invoiceData.c_net_gst? invoiceData.c_net_gst : 0],
        c_gst_type_2: [invoiceData.c_gst_type_2? invoiceData.c_gst_type_2 : 'S'],
        c_gst_conf_2: [invoiceData.c_gst_conf_2? invoiceData.c_gst_conf_2 : 0],
        c_cgst_2: [invoiceData.c_cgst_2? invoiceData.c_cgst_2 : 0],
        c_sgst_2: [invoiceData.c_sgst_2? invoiceData.c_sgst_2 : 0],
        c_igst_2: [invoiceData.c_igst_2? invoiceData.c_igst_2 : 0],
        c_net_gst_2: [invoiceData.c_net_gst_2? invoiceData.c_net_gst_2 : 0],
        client_sub_total: [invoiceData.client_sub_total? invoiceData.client_sub_total : 0],
        total: [invoiceData.total? invoiceData.total : 0],
      invoice_number: [data.invoice_number ? data.invoice_number : ""],

      service_date: [data.service_date ? moment(data.service_date).format('YYYY-MM-DD') : ""],
      supplier_transaction_number: [data.supplier_transaction_number ? data.supplier_transaction_number : ""],
      reference_number: [data.reference_number ? data.reference_number : ""],
      employee_code: [invoiceData.employee_code ? invoiceData.employee_code : ""],
      employee_name: [invoiceData.employee_name ? invoiceData.employee_name : ""],
      number_of_pax: [data.number_of_pax ? data.number_of_pax : 1],
      narration_1: [invoiceData.narration_1 ? invoiceData.narration_1 : "",Validators.pattern('^[^\,\"\(\)\']*$')],
      narration_2: [invoiceData.narration_2 ? invoiceData.narration_2 : "",Validators.pattern('^[^\,\"\(\)\']*$')],
      narration_3: [invoiceData.narration_3 ? invoiceData.narration_3 : "",Validators.pattern('^[^\,\"\(\)\']*$')],
      narration_4: [invoiceData.narration_4 ? invoiceData.narration_4 : "",Validators.pattern('^[^\,\"\(\)\']*$')],
      narration_5: [invoiceData.narration_5 ? invoiceData.narration_5 : "",Validators.pattern('^[^\,\"\(\)\']*$')],
      skip_v3_entry : [false],
      reference_invoice_number : [''],
      tcs_rate : [invoiceData.tcs_rate ? Number(invoiceData.tcs_rate) : 0],
      tcs_rate_above_7 : [invoiceData.tcs_rate_above_7 ? Number(invoiceData.tcs_rate_above_7) :invoiceData.tcs_rate ? Number(invoiceData.tcs_rate) : 0],
      tcs_amount : [invoiceData.tcs_amount ? Number(invoiceData.tcs_amount) : 0],
      remark : [invoiceData.remark ? invoiceData.remark : ''],
      purchase_currency: [invoiceData.purchase_currency ? invoiceData.purchase_currency : ''],
      purchase_currency_rate: [invoiceData.purchase_currency_rate ? Number(invoiceData.purchase_currency_rate) : 1],
      sales_currency: [invoiceData.sales_currency ? invoiceData.sales_currency  : ''],
      sales_currency_rate: [invoiceData.sales_currency_rate ? Number(invoiceData.sales_currency_rate) : null],
      sales_currency_amount: [invoiceData.sales_currency_amount ? Number(invoiceData.sales_currency_amount) : ''],
      nonRefundable : [invoiceData.non_refundable_to_client ? invoiceData.non_refundable_to_client : false],
      bill_of_supply : [invoiceData.bill_of_supply ? invoiceData.bill_of_supply : false],
      rcm_applicable: [invoiceData.rcm_applicable ? invoiceData.rcm_applicable : false],
      reverse_tds: [invoiceData.reverse_tds ? invoiceData.reverse_tds : false],
      tcs_on : [invoiceData.tcs_on ? invoiceData.tcs_on : 'total'],
    });
    return true
  }

  async bindInvoiceData(invoiceData: any) {
    let res = await this.initialiseFormData(invoiceData.products[0],invoiceData)
    this.invoiceForm.controls.invoice_date.setValue(invoiceData.invoice_date ? moment(invoiceData.invoice_date).format('YYYY-MM-DD') : "");
    this.invoiceForm.controls.invoice_number.setValue(invoiceData.invoice_number ? invoiceData.invoice_number : "");
    if(invoiceData.reference_invoice_number){
      this.invoiceForm.controls.reference_invoice_number.setValue(invoiceData.reference_invoice_number ? invoiceData.reference_invoice_number : "");
      this.invoiceForm.controls.skip_v3_entry.setValue(invoiceData.reference_invoice_number ? true : false);
    }
    this.travellerList = this.travellerForm.get("travellerList");
		  this.travellerList = this.travellerForm.get("travellerList") as FormArray;
      this.travellerList.controls = []
    for (const iterator of invoiceData.products) {
      this.addTraveller(iterator)
    }
    let obj = {
      invoice_no: invoiceData.invoice_number ? invoiceData.invoice_number : 'New Invoice',
    };
    this.fareList[0] = obj;
    this.calculateFromApi(0)
  }

  setRetrieveData(data){
    this.retrieveData = data
  }

  setTotalAmount(amount){
    this.invoiceTotalAmount = amount
  }
 
  calculateFromApi(i){
    
    let calculationObj = this.createMsProduct(this.invoiceForm.value,this.travellerForm.value)
    this.postMethod(`invoice/miscellaneous-fare`,calculationObj).subscribe(async (res)=> {
      this.totalFareModel[i] = new TourFareSummaryModel(res);

      if(this.totalFareModel[i] && this.totalFareModel[i].client_net_amount){

        let sales_currency = this.invoiceForm.controls.sales_currency.value;
        let sales_currency_rate = this.invoiceForm.controls.sales_currency_rate.value;
        if(sales_currency && sales_currency_rate) {
          let client_net_amount =  this.totalFareModel[i].client_net_amount;
          let grandTotal = Number(client_net_amount)
          this.invoiceForm.controls.sales_currency_amount.setValue((grandTotal * sales_currency_rate).toFixed(2));
        } else {
          this.invoiceForm.controls.sales_currency_amount.setValue('')
        }
        
        let tcs_rate_value = this.invoiceForm.controls.tcs_rate.value;
        let tcs_rate_above_7 = this.invoiceForm.controls.tcs_rate_above_7.value;
        let tcs_on_value = this.invoiceForm.controls.tcs_on.value
        if(Number(tcs_rate_value)>0 && tcs_on_value ) {
          let tcsAmountUpto7 = (Number(tcs_rate_value) * Number(tcs_on_value=='basic'?
            ( Number(Number(this.totalFareModel[i].client_basic)+Number(this.travellerForm.value.travellerList.reduce((acc,curr)=>acc+ Number(curr.client_sc2_type=="B"?curr.client_sc2_amount:0)+ Number(curr.client_sc3_type=="B"?curr.client_sc3_amount:0),0)))<=700000?Number(this.totalFareModel[i].client_basic)+Number(this.travellerForm.value.travellerList.reduce((acc,curr)=>acc+ Number(curr.client_sc2_type=="B"?curr.client_sc2_amount:0)+ Number(curr.client_sc3_type=="B"?curr.client_sc3_amount:0),0)):700000):
           (Number(this.totalFareModel[i].client_net_amount - this.totalFareModel[i].client_gst - this.totalFareModel[i].client_tds )<=700000?Number(this.totalFareModel[i].client_net_amount - this.totalFareModel[i].client_gst - this.totalFareModel[i].client_tds ) :700000)))/ 100
 
           let tcsAmountAbove7 = (Number(tcs_rate_above_7) * Number(tcs_on_value=='basic'?
             ( Number(Number(this.totalFareModel[i].client_basic)+Number(this.travellerForm.value.travellerList.reduce((acc,curr)=>acc+ Number(curr.client_sc2_type=="B"?curr.client_sc2_amount:0)+ Number(curr.client_sc3_type=="B"?curr.client_sc3_amount:0),0)))<=700000?0:Number(this.totalFareModel[i].client_basic)+Number(this.travellerForm.value.travellerList.reduce((acc,curr)=>acc+ Number(curr.client_sc2_type=="B"?curr.client_sc2_amount:0)+ Number(curr.client_sc3_type=="B"?curr.client_sc3_amount:0),0))-700000):
            (Number(this.totalFareModel[i].client_net_amount - this.totalFareModel[i].client_gst - this.totalFareModel[i].client_tds )<=700000?0:Number(this.totalFareModel[i].client_net_amount - this.totalFareModel[i].client_gst - this.totalFareModel[i].client_tds )- 700000 )))/ 100
           let roundedAmount = Math.round(tcsAmountUpto7+tcsAmountAbove7)
          this.invoiceForm.controls.tcs_amount.setValue(roundedAmount)
        }else {
          this.invoiceForm.controls.tcs_amount.setValue(0)
        }
      }
    },err=> {

    })
  }

  createMsProduct(data,travellers) {
    let products = []
    let gst = 0
    let netAmount = 0
    let hsn = this.getHSN(data.invoice_type)
    for (const traveller of travellers.travellerList) {

      const billOfSupplyValue = data.bill_of_supply ? data.bill_of_supply : false;
      
      const igstAmount = Number(billOfSupplyValue !== true && traveller.client_igst ? traveller.client_igst : 0)
      const cgstAmount = Number(billOfSupplyValue !== true && traveller.client_cgst ? traveller.client_cgst : 0)
      const sgstAmount = Number(billOfSupplyValue !== true && traveller.client_sgst ? traveller.client_sgst : 0)
      const serviceChargeAmount = Number(billOfSupplyValue !== true && traveller.client_sc1_amount ? traveller.client_sc1_amount : 0)

      const totalClientAmount =  billOfSupplyValue !== true ? 
              Number(traveller.client_net_amount) : 
              ( Number(traveller.client_net_amount) - Number(traveller.client_sc1_amount) - 
                Number(traveller.client_igst) - Number(traveller.client_cgst) - Number(traveller.client_sgst) )


      let productObj =  {
        invoice_type: data.invoice_type ? data.invoice_type : "tour",
        transaction_method: data.transaction_method ? data.transaction_method : "sales",
  
        client_account_type: data.client_account_type ? data.client_account_type : "client",
        client_code: data.client_code ? data.client_code : "",
        client_code_name: data.client_code_name ? data.client_code_name.name ? data.client_code_name.name : data.client_code_name : "",
        
        general_ledger_code: data.general_ledger_code ? data.general_ledger_code : "",
        general_ledger_code_name: data.general_ledger_code_name ? data.general_ledger_code_name.name ? data.general_ledger_code_name.name : data.general_ledger_code_name : "",
  
        supplier_account_type: data.supplier_account_type ? data.supplier_account_type : "supplier",
        supplier_code: data.supplier_code ? data.supplier_code : "",
        supplier_code_name: data.supplier_code_name ? data.supplier_code_name.name ? data.supplier_code_name.name : data.supplier_code_name : "",
  
        subledger_type: data.subledger_type ? data.subledger_type : "client",
        subledger_code: data.subledger_code ? data.subledger_code : "",
        subledger_code_name: data.subledger_code_name ? data.subledger_code_name.name ? data.subledger_code_name.name : data.subledger_code_name : "",
  
  
        invoice_date: data.invoice_date ? moment(data.invoice_date).format('YYYY-MM-DDTHH:MM') : "",
        invoice_number: data.invoice_number ? data.invoice_number : "",
        
        number_of_pax: data.number_of_pax ? data.number_of_pax : "",
        service_details_1: traveller.service_details_1 ? traveller.service_details_1 : "",
        service_details_2: traveller.service_details_2 ? traveller.service_details_2 : "",
        service_details_3: traveller.service_details_3 ? traveller.service_details_3 : "",
        service_details_4: traveller.service_details_4 ? traveller.service_details_4 : "",
        service_details_5: traveller.service_details_5 ? traveller.service_details_5 : "",
        
        service_name: traveller.service_name ? traveller.service_name : "",
        start_date: traveller.start_date ? traveller.start_date : "",
        end_date: traveller.end_date ? traveller.end_date : "",
        lead_pax_name: traveller.lead_pax_name ? traveller.lead_pax_name.account_name?traveller.lead_pax_name.account_name:traveller.lead_pax_name : "",
        quantity: traveller.quantity ? traveller.quantity : "",
        reference_number: traveller.reference_number ? traveller.reference_number : "",
  
        supplier_non_account: traveller.supplier_non_account ? traveller.supplier_non_account : "",
        supplier_basic: traveller.supplier_basic ? traveller.supplier_basic : "",
        supplier_taxes: traveller.supplier_taxes ? traveller.supplier_taxes : "",
        supplier_commission_type: traveller.supplier_commission_type ? traveller.supplier_commission_type : "RB",
        supplier_commission_value: traveller.supplier_commission_value ? traveller.supplier_commission_value : "",
        supplier_commission_amount: traveller.supplier_commission_amount ? traveller.supplier_commission_amount : "",
        supplier_discount_type: traveller.supplier_discount_type ? traveller.supplier_discount_type : "VL",
        supplier_discount_amount: traveller.supplier_discount_amount ? traveller.supplier_discount_amount : "",
        supplier_tds_amount: traveller.supplier_tds_amount ? traveller.supplier_tds_amount : "",
        supplier_tds_type: traveller.supplier_tds_type ? traveller.supplier_tds_type : "",
        supplier_tds_conf: traveller.supplier_tds_conf ? traveller.supplier_tds_conf : "",
  
        supplier_sc1_type: traveller.supplier_sc1_type ? traveller.supplier_sc1_type : "T",
        supplier_sc1_amount: traveller.supplier_sc1_amount ? traveller.supplier_sc1_amount : "",
        supplier_sc2_type: traveller.supplier_sc2_type ? traveller.supplier_sc2_type : "B",
        supplier_sc2_taxable: traveller.supplier_sc2_taxable ? traveller.supplier_sc2_taxable : "N",
        supplier_sc2_amount: traveller.supplier_sc2_amount ? traveller.supplier_sc2_amount : "",
        supplier_sc3_type: traveller.supplier_sc3_type ? traveller.supplier_sc3_type : "OT",
        supplier_sc3_taxable: traveller.supplier_sc3_taxable ? traveller.supplier_sc3_taxable : "N",
        supplier_sc3_amount: traveller.supplier_sc3_amount ? traveller.supplier_sc3_amount : "",
        
        supplier_gst_type: traveller.supplier_gst_type ? traveller.supplier_gst_type : "S",
        supplier_gst_conf: traveller.supplier_gst_conf ? traveller.supplier_gst_conf : "",
        supplier_cgst: traveller.supplier_cgst ? traveller.supplier_cgst : "",
        supplier_sgst: traveller.supplier_sgst ? traveller.supplier_sgst : "",
        supplier_igst: traveller.supplier_igst ? traveller.supplier_igst : "",
        
        supplier_gst_type_2: traveller.supplier_gst_type_2 ? traveller.supplier_gst_type_2 : "B",
        supplier_gst_conf_2: traveller.supplier_gst_conf_2 ? traveller.supplier_gst_conf_2 : "",
        supplier_cgst_2: traveller.supplier_cgst_2 ? traveller.supplier_cgst_2 : "",
        supplier_sgst_2: traveller.supplier_sgst_2 ? traveller.supplier_sgst_2 : "",
        supplier_igst_2: traveller.supplier_igst_2 ? traveller.supplier_igst_2 : "",

        supplier_gst_type_3: traveller.supplier_gst_type_3 ? traveller.supplier_gst_type_3 : "TOTAL",
        supplier_gst_conf_3: traveller.supplier_gst_conf_3 ? traveller.supplier_gst_conf_3 : "",
        supplier_cgst_3: traveller.supplier_cgst_3 ? traveller.supplier_cgst_3 : "",
        supplier_sgst_3: traveller.supplier_sgst_3 ? traveller.supplier_sgst_3 : "",
        supplier_igst_3: traveller.supplier_igst_3 ? traveller.supplier_igst_3 : "",

        supplier_raf_charges: traveller.supplier_raf_charges ? traveller.supplier_raf_charges : "0",
        supplier_igst_on_raf: traveller.supplier_igst_on_raf ? traveller.supplier_igst_on_raf : "",
        supplier_cgst_on_raf: traveller.supplier_cgst_on_raf ? traveller.supplier_cgst_on_raf : "",
        supplier_sgst_on_raf: traveller.supplier_sgst_on_raf ? traveller.supplier_sgst_on_raf : "",
        supplier_xxl_charges : traveller.supplier_xxl_charges ? traveller.supplier_xxl_charges : 0,  

        supplier_net_amount: traveller.supplier_net_amount ? traveller.supplier_net_amount : "",
  
        client_non_account: traveller.client_non_account ? traveller.client_non_account : "",
        client_basic: traveller.client_basic ? traveller.client_basic : "",
        client_taxes: traveller.client_taxes ? traveller.client_taxes : "",
        client_commission_type: traveller.client_commission_type ? traveller.client_commission_type : "RB",
        client_commission_value: traveller.client_commission_value ? traveller.client_commission_value : "",
        client_commission_amount: traveller.client_commission_amount ? traveller.client_commission_amount : "",
        client_discount_type: traveller.client_discount_type ? traveller.client_discount_type : "VL",
        client_discount_amount: traveller.client_discount_amount ? traveller.client_discount_amount : "",
        client_tds_amount: traveller.client_tds_amount ? traveller.client_tds_amount : "",
        client_tds_type: traveller.client_tds_type ? traveller.client_tds_type : "",
        client_tds_conf: traveller.client_tds_conf ? traveller.client_tds_conf : "",

        client_sc1_type: traveller.client_sc1_type ? traveller.client_sc1_type : "T",
        client_sc1_amount: serviceChargeAmount,
        client_sc2_type: traveller.client_sc2_type ? traveller.client_sc2_type : "B",
        client_sc2_taxable: traveller.client_sc2_taxable ? traveller.client_sc2_taxable : "N",
        client_sc2_amount: traveller.client_sc2_amount ? traveller.client_sc2_amount : "",
        client_sc3_type: traveller.client_sc3_type ? traveller.client_sc3_type : "OT",
        client_sc3_amount: traveller.client_sc3_amount ? traveller.client_sc3_amount : "",
        client_sc3_taxable: traveller.client_sc3_taxable ? traveller.client_sc3_taxable : "N",

        client_gst_type: traveller.client_gst_type ? traveller.client_gst_type : "S",
        client_gst_conf: traveller.client_gst_conf ? traveller.client_gst_conf : "0",
        client_cgst: cgstAmount ? cgstAmount : "",
        client_sgst: sgstAmount ? sgstAmount : "",
        client_igst: igstAmount ? igstAmount : "",
        
        client_gst_type_2: traveller.client_gst_type_2 ? traveller.client_gst_type_2 : "B",
        client_gst_conf_2: traveller.client_gst_conf_2 ? traveller.client_gst_conf_2 : "",
        client_cgst_2: traveller.client_cgst_2 ? traveller.client_cgst_2 : "",
        client_sgst_2: traveller.client_sgst_2 ? traveller.client_sgst_2 : "",
        client_igst_2: traveller.client_igst_2 ? traveller.client_igst_2 : "",

        client_gst_type_3: traveller.client_gst_type_3 ? traveller.client_gst_type_3 : "TOTAL",
        client_gst_conf_3: traveller.client_gst_conf_3 ? traveller.client_gst_conf_3 : "",
        client_cgst_3: traveller.client_cgst_3 ? traveller.client_cgst_3 : "",
        client_sgst_3: traveller.client_sgst_3 ? traveller.client_sgst_3 : "",
        client_igst_3: traveller.client_igst_3 ? traveller.client_igst_3 : "",

        client_raf_charges: traveller.client_raf_charges ? traveller.client_raf_charges : "0",
        client_igst_on_raf: traveller.client_igst_on_raf ? traveller.client_igst_on_raf : "0",
        client_cgst_on_raf: traveller.client_cgst_on_raf ? traveller.client_cgst_on_raf : "0",
        client_sgst_on_raf: traveller.client_sgst_on_raf ? traveller.client_sgst_on_raf : "0",
        client_xxl_charges : traveller.client_xxl_charges ? traveller.client_xxl_charges : "0",  

        client_net_amount: totalClientAmount ? totalClientAmount : "",
        
        hsn : traveller.hsn?traveller.hsn:hsn,
        hsn_2 : traveller.hsn_2?traveller.hsn_2:'',
        hsn_3 : traveller.hsn_3?traveller.hsn_3:'',
        pay_type: traveller.pay_type ? traveller.pay_type : "",
        ledger_card_no: traveller.ledger_card_no ? traveller.ledger_card_no : "",
        ledger_card_name: traveller.ledger_card_name && traveller.ledger_card_name.name ? traveller.ledger_card_name.name  : "",
        e_code: traveller.e_code ? traveller.e_code.account_code ? traveller.e_code.account_code : traveller.e_code : "",
        cost_center: traveller.cost_center ? traveller.cost_center.cost_center ? traveller.cost_center.cost_center : traveller.cost_center : "",
        sales_person_name: traveller.sales_person_name && traveller.sales_person_name.t_user_name ?traveller.sales_person_name.t_user_name: traveller.sales_person_name,
        sales_person_code: traveller.sales_person_code ? traveller.sales_person_code : "",
        refered_by_name: traveller.refered_by_name && traveller.refered_by_name.name ? traveller.refered_by_name.name:traveller.refered_by_name  ,
        refered_by_code: traveller.refered_by_code ? traveller.refered_by_code : "",
        invoice_cost_center: traveller.invoice_cost_center?traveller.invoice_cost_center:'',
        payable_transaction_code: traveller.payable_transaction_code ? traveller.payable_transaction_code : "",
        payable_date: traveller.payable_date? moment(traveller.payable_date).format('YYYY-MM-DD'): "",
        employee_code: data.employee_code,
        employee_name: data.employee_name,
        payable_account_type: traveller.payable_account_type ? traveller.payable_account_type : "",
        payable_code: traveller.payable_account_name && traveller.payable_account_name.account_code ? traveller.payable_account_name.account_code : traveller.payable_code || traveller.payable_account_code || "",
        payable_account_name: traveller.payable_account_name && traveller.payable_account_name.name ? traveller.payable_account_name.name : traveller.payable_account_name || "",
        payable_account_is_branch: traveller.payable_account_is_branch ? traveller.payable_account_is_branch: false,
        associate_payable_code: traveller.associate_payable_account_name && traveller.associate_payable_account_name.account_code ? traveller.associate_payable_account_name.account_code : traveller.associate_payable_code || traveller.associate_payable_account_code || "",
        associate_payable_account_name: traveller.associate_payable_account_name && traveller.associate_payable_account_name.name ? traveller.associate_payable_account_name.name : traveller.associate_payable_account_name || "",
        reference_invoice_number : data.reference_invoice_number ? data.reference_invoice_number : "",
        skip_v3_entry : data.skip_v3_entry,
       
        supplier_vat_type: traveller.supplier_vat_type ? traveller.supplier_vat_type : "S",
        supplier_vat_conf: traveller.supplier_vat_conf ? traveller.supplier_vat_conf : 0,
        supplier_vat_amount: traveller.supplier_vat_amount ? traveller.supplier_vat_amount : 0,
        
        client_vat_type: traveller.client_vat_type ? traveller.client_vat_type : "S",
        client_vat_conf: traveller.client_vat_conf ? traveller.client_vat_conf : 18,
        client_vat_amount: traveller.client_vat_amount ? traveller.client_vat_amount : 0
      };

      gst += Number(igstAmount) + Number(cgstAmount) + Number(sgstAmount)
      netAmount += Number(totalClientAmount)
      products.push(productObj)
    }
    
  let totalAmount = Number(data.tcs_amount) + Number(netAmount)

  let invoioceObj = {
    "affiliate_code": data.client_code,
    "name": data.client_code_name ? data.client_code_name.name ? data.client_code_name.name : data.client_code_name : "",
    "tour_code": data.tour_code,
    "tour_code_name": data.tour_code_name ? data.tour_code_name.name ? data.tour_code_name.name : data.tour_code_name : "",
    "billaddress": "",
    "invoice_uuid": data.invoice_number,
    "invoice_date": data.invoice_date,
    "sub_invoice_type": data.sub_invoice_type,
    'hsn': data.hsn,
        "hsn_2": data.hsn_2,
        "c_sc1_type": data.c_sc1_type,
        "c_sc1_value": data.c_sc1_value,
        "c_sc1_amount": data.c_sc1_amount ,
        "c_gst_type": data.c_gst_type ,
        "c_gst_conf": data.c_gst_conf ,
        "c_cgst": data.c_cgst ,
        "c_sgst": data.c_sgst ,
        "c_igst": data.c_igst,
        "c_net_gst": data.c_net_gst,
        "c_gst_type_2": data.c_gst_type_2,
        "c_gst_conf_2": data.c_gst_conf_2,
        "c_cgst_2": data.c_cgst_2,
        "c_sgst_2": data.c_sgst_2,
        "c_igst_2": data.c_igst_2,
        "c_net_gst_2": data.c_net_gst_2,
        "client_sub_total": data.client_sub_total,
    "created_by": data.employee_code,
    "updated_by": data.employee_code,
    "employee_code" : data.employee_name && data.employee_name.account_code ? data.employee_name.account_code : data.employee_code || "",
    "employee_name" : data.employee_name && data.employee_name.name ? data.employee_name.name : data.employee_name || "",
    "year_id": 1,
    "tenant_id": 1,
    "narration_1": data.narration_1,
    "narration_2": data.narration_2,
    "narration_3": data.narration_3,
    "narration_4": data.narration_4,
    "narration_5": data.narration_5,
    "remark": data.remark,
    "tcs_rate": data.tcs_rate ? data.tcs_rate : null,
    "tcs_rate_above_7": data.tcs_rate_above_7 ? data.tcs_rate_above_7 :data.tcs_rate?data.tcs_rate: null,
    "tcs_amount": data.tcs_amount ? data.tcs_amount : null,
    "total": data.sub_invoice_type=='tour'?(data.total?data.total:this.invoiceTotalAmount): totalAmount?Math.round(totalAmount) : null,
    "balance_due": 0,
    "gst_total": gst,
    "products": products,
    "reference_invoice_number": products[0].reference_invoice_number,
    "transaction_method": data.transaction_method ? data.transaction_method : 'sales',
    "sales_currency": data.sales_currency ? data.sales_currency : null,
    "sales_currency_rate" : data.sales_currency_rate ? data.sales_currency_rate : null,
    "sales_currency_amount" : data.sales_currency_amount ? data.sales_currency_amount : null,
    "purchase_currency": data.purchase_currency ? data.purchase_currency : null,
    "purchase_currency_rate" : data.purchase_currency_rate ? data.purchase_currency_rate : null,
    "nonRefundable" : data.nonRefundable ? data.nonRefundable : false,
    "bill_of_supply" : data.bill_of_supply ? data.bill_of_supply : false,
    "rcm_applicable": data.rcm_applicable ? data.rcm_applicable : false,
    "reverse_tds": data.reverse_tds ? data.reverse_tds : false,
    "tcs_on": data.tcs_on ? data.tcs_on : 'total',
    "retrieveObj" : this.retrieveData
  }

  return invoioceObj;

  }
  getHSN(invoice_type: any) {
    let SAC_CODES = {
        "non_account" : 996425
    }
    if(SAC_CODES[invoice_type]){
      return SAC_CODES[invoice_type]
    }else{
      return 999799
    }
  }

  postMethod(url, data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.httpClient
      .post(`${environment.okountUrl}/${url}`, data, { headers: headers })
      .pipe(map((res) => {
          return res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
  }
  putMethod(url, data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.httpClient
      .put(`${environment.okountUrl}/${url}`, data, { headers: headers })
      .pipe(map((res) => {
          return res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
  }
  deleteMethod(url) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.httpClient
      .delete(`${environment.okountUrl}/${url}`, { headers: headers })
      .pipe(map((res) => {
          return res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
  }
  getMethod(url) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.httpClient
      .get(`${environment.okountUrl}/${url}`, { headers: headers })
      .pipe(map((res) => {
          return res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
  }

  calculateCommission(
    base,
    tax,
    commType,
    commFor,
    commRate,
    tdsType,
    tdsRate,
    roundOffType = "NORMAL_ROUND_OFF",
    i
  ) {
    var commAmount = 0.0;
    base = Number(base);
    tax = Number(tax);
    tdsRate = Number(tdsRate)
    if (commType.toUpperCase() == "VL" || commType.toUpperCase() == "VLN") {
      commAmount = Number(commRate);
    } else if (commType.toUpperCase() == "RB") {
      commAmount = (base * commRate) / 100;
      base = base - commAmount;
    } else if (commType.toUpperCase() == "RT") {
      commAmount = ((base + tax) * commRate) / 100;
    }

    const gstOnCommission = (commAmount * 18) / 100;
    var totalTds = (commAmount * tdsRate) / 100;
    if(tdsType == 'B'){
      totalTds = (base * tdsRate) / 100;
    }

    if (commFor == "CLIENT") {
      this.travellerForm.controls.travellerList.controls[i].controls.client_commission_amount.setValue(commAmount.toFixed(2));
      this.travellerForm.controls.travellerList.controls[i].controls.client_tds_amount.setValue(totalTds.toFixed(2));
      
    } else if (commFor == "SUPPLIER") {
      this.travellerForm.controls.travellerList.controls[i].controls.supplier_commission_amount.setValue(commAmount.toFixed(2));
      this.travellerForm.controls.travellerList.controls[i].controls.supplier_tds_amount.setValue(totalTds.toFixed(2));
    }
    return null
  }

  roundOffAmount(amount, type = "NORMAL_ROUND_OFF") {
    if (type && type.toUpperCase() == "NORMAL_ROUND_OFF") {
      amount = Math.round(amount);
    }
    if (type && type.toUpperCase() == "LOWER_ROUND_OFF") {
      amount = Number(String(amount).split(".")[0]);
    }
    return amount;
  }  

  createMsProductForBillSupply(data,travellers) {
    let products = []
    let gst = 0
    let netAmount = 0
    let hsn = this.getHSN(data.invoice_type)

    for (const traveller of travellers.travellerList) {
      const igstAmount = Number(traveller.client_igst ? traveller.client_igst : 0)
      const cgstAmount = Number(traveller.client_cgst ? traveller.client_cgst : 0)
      const sgstAmount = Number(traveller.client_sgst ? traveller.client_sgst : 0)
      const serviceChargeAmount = Number(traveller.client_sc1_amount ? traveller.client_sc1_amount : 0)

      const gstAmount = igstAmount + cgstAmount + sgstAmount;
      const totalClientAmount = serviceChargeAmount + gstAmount;

      let productObj =  {
        invoice_type: data.invoice_type ? data.invoice_type : "tour",
        transaction_method: data.transaction_method ? data.transaction_method : "sales",
  
        client_account_type: data.client_account_type ? data.client_account_type : "client",
        client_code: data.client_code ? data.client_code : "",
        client_code_name: data.client_code_name ? data.client_code_name.name ? data.client_code_name.name : data.client_code_name : "",
        
        general_ledger_code: data.general_ledger_code ? data.general_ledger_code : "",
        general_ledger_code_name: data.general_ledger_code_name ? data.general_ledger_code_name.name ? data.general_ledger_code_name.name : data.general_ledger_code_name : "",
  
        supplier_account_type: data.supplier_account_type ? data.supplier_account_type : "supplier",
        supplier_code: data.supplier_code ? data.supplier_code : "",
        supplier_code_name: data.supplier_code_name ? data.supplier_code_name.name ? data.supplier_code_name.name : data.supplier_code_name : "",
  
        subledger_type: data.subledger_type ? data.subledger_type : "client",
        subledger_code: data.subledger_code ? data.subledger_code : "",
        subledger_code_name: data.subledger_code_name ? data.subledger_code_name.name ? data.subledger_code_name.name : data.subledger_code_name : "",
  
  
        invoice_date: data.invoice_date ? moment(data.invoice_date).format('YYYY-MM-DDTHH:MM') : "",
        invoice_number: data.invoice_number ? data.invoice_number : "",
        
        number_of_pax: data.number_of_pax ? data.number_of_pax : "",
        service_details_1: traveller.service_details_1 ? traveller.service_details_1 : "",
        service_details_2: traveller.service_details_2 ? traveller.service_details_2 : "",
        service_details_3: traveller.service_details_3 ? traveller.service_details_3 : "",
        service_details_4: traveller.service_details_4 ? traveller.service_details_4 : "",
        service_details_5: traveller.service_details_5 ? traveller.service_details_5 : "",
        
        service_name: traveller.service_name ? traveller.service_name : "",
        start_date: traveller.start_date ? traveller.start_date : "",
        end_date: traveller.end_date ? traveller.end_date : "",
        lead_pax_name: traveller.lead_pax_name ? traveller.lead_pax_name.account_name?traveller.lead_pax_name.account_name:traveller.lead_pax_name : "",
        quantity: traveller.quantity ? traveller.quantity : "",
        reference_number: traveller.reference_number ? traveller.reference_number : "",
  
        supplier_non_account:  "",
        supplier_basic: "",
        supplier_taxes: "",
        supplier_commission_type: "RB",
        supplier_commission_value: "",
        supplier_commission_amount: "",
        supplier_tds_amount: "",
        supplier_tds_type: "",
        supplier_tds_conf: "",
  
        supplier_sc1_type: "T",
        supplier_sc1_amount: "",
        supplier_sc2_type: "N",
        supplier_sc2_taxable: "N",
        supplier_sc2_amount: "",
        supplier_sc3_type: "N",
        supplier_sc3_taxable: "N",
        supplier_sc3_amount: "",
        
        supplier_gst_type: "S",
        supplier_gst_conf: "",
        supplier_cgst: "",
        supplier_sgst: "",
        supplier_igst: "",

        supplier_gst_type_2: "B",
        supplier_gst_conf_2: "",
        supplier_cgst_2: "",
        supplier_sgst_2: "",
        supplier_igst_2: "",

        supplier_gst_type_3: "TOTAL",
        supplier_gst_conf_3: "",
        supplier_cgst_3: "",
        supplier_sgst_3: "",
        supplier_igst_3: "",

        supplier_raf_charges: "0",
        supplier_igst_on_raf: "",
        supplier_cgst_on_raf: "",
        supplier_sgst_on_raf: "",
        supplier_xxl_charges : 0, 
        
        supplier_net_amount: "",
  
        client_non_account: "",
        client_basic: "",
        client_taxes: "",
        client_commission_type: "RB",
        client_commission_value: "",
        client_commission_amount: "",
        client_tds_amount: "",
        client_tds_type: "",
        client_tds_conf: "",

        client_sc1_type: traveller.client_sc1_type ? traveller.client_sc1_type : "T",
        client_sc1_amount: serviceChargeAmount ? serviceChargeAmount : "",
        client_sc2_type: "N",
        client_sc2_taxable: "N",
        client_sc2_amount: "",
        client_sc3_type: "N",
        client_sc3_amount: "",
        client_sc3_taxable: "N",

        client_gst_type: traveller.client_gst_type ? traveller.client_gst_type : "S",
        client_gst_conf: traveller.client_gst_conf ? traveller.client_gst_conf : "0",
        client_cgst: cgstAmount ? cgstAmount : "",
        client_sgst: sgstAmount ? sgstAmount : "",
        client_igst: igstAmount ? igstAmount : "",

        client_gst_type_2: "B",
        client_gst_conf_2: "",
        client_cgst_2: "",
        client_sgst_2: "",
        client_igst_2: "",

        client_gst_type_3: "TOTAL",
        client_gst_conf_3: "",
        client_cgst_3: "",
        client_sgst_3: "",
        client_igst_3: "",

        client_raf_charges: "0",
        client_igst_on_raf: "0",
        client_cgst_on_raf: "0",
        client_sgst_on_raf: "0",
        client_xxl_charges : "0",
        
        client_net_amount: totalClientAmount ? totalClientAmount : '',
        
        hsn : traveller.hsn?traveller.hsn:hsn,
        hsn_2 : traveller.hsn_2?traveller.hsn_2:'',
        hsn_3 : traveller.hsn_3?traveller.hsn_3:'',
        pay_type: traveller.pay_type ? traveller.pay_type : "",
        ledger_card_no: traveller.ledger_card_no ? traveller.ledger_card_no : "",
        ledger_card_name: traveller.ledger_card_name && traveller.ledger_card_name.name ? traveller.ledger_card_name.name  : "",
        e_code: traveller.e_code ? traveller.e_code.account_code ? traveller.e_code.account_code : traveller.e_code : "",
        cost_center: traveller.cost_center ? traveller.cost_center.cost_center ? traveller.cost_center.cost_center : traveller.cost_center : "",
        sales_person_name: traveller.sales_person_name && traveller.sales_person_name.t_user_name ?traveller.sales_person_name.t_user_name: traveller.sales_person_name,
        sales_person_code: traveller.sales_person_code ? traveller.sales_person_code : "",
        refered_by_name: traveller.refered_by_name && traveller.refered_by_name.name ? traveller.refered_by_name.name:traveller.refered_by_name  ,
        refered_by_code: traveller.refered_by_code ? traveller.refered_by_code : "",
        invoice_cost_center: traveller.invoice_cost_center? traveller.invoice_cost_center:'',
        employee_code: data.employee_code,
        employee_name: data.employee_name,
        payable_account_type: traveller.payable_account_type ? traveller.payable_account_type : "",
        payable_code: traveller.payable_account_name && traveller.payable_account_name.account_code ? traveller.payable_account_name.account_code : traveller.payable_code || traveller.payable_account_code || "",
        payable_account_name: traveller.payable_account_name && traveller.payable_account_name.name ? traveller.payable_account_name.name : traveller.payable_account_name || "",
        payable_account_is_branch: traveller.payable_account_is_branch ? traveller.payable_account_is_branch: false,
        associate_payable_code: traveller.associate_payable_account_name && traveller.associate_payable_account_name.account_code ? traveller.associate_payable_account_name.account_code : traveller.associate_payable_code || traveller.associate_payable_account_code || "",
        associate_payable_account_name: traveller.associate_payable_account_name && traveller.associate_payable_account_name.name ? traveller.associate_payable_account_name.name : traveller.associate_payable_account_name || "",
        reference_invoice_number : data.reference_invoice_number ? data.reference_invoice_number : "",
        skip_v3_entry : data.skip_v3_entry,
       
        supplier_vat_type: "S",
        supplier_vat_conf: 0,
        supplier_vat_amount:  0,
        
        client_vat_type: "S",
        client_vat_conf: 18,
        client_vat_amount: 0
      };

      gst += Number(igstAmount) + Number(cgstAmount) + Number(sgstAmount)
      netAmount += Number(totalClientAmount)
      products.push(productObj)
    }
    
  let totalAmount = Number(data.tcs_amount) + Number(netAmount);

  let invoioceObj = {
      "affiliate_code": data.client_code,
      "name": data.client_code_name ? data.client_code_name.name ? data.client_code_name.name : data.client_code_name : "",
      "tour_code": data.tour_code,
      "tour_code_name": data.tour_code_name ? data.tour_code_name.name ? data.tour_code_name.name : data.tour_code_name : "",
      "billaddress": "",
      "invoice_uuid": data.invoice_number,
      "invoice_date": data.invoice_date,
      "sub_invoice_type": data.sub_invoice_type,
      "created_by": data.employee_code,
      "updated_by": data.employee_code,
      "employee_code" : data.employee_name && data.employee_name.account_code ? data.employee_name.account_code : data.employee_code || "",
      "employee_name" : data.employee_name && data.employee_name.name ? data.employee_name.name : data.employee_name || "",
      "year_id": 1,
      "tenant_id": 1,
      "narration_1": data.narration_1,
      "narration_2": data.narration_2,
      "narration_3": data.narration_3,
      "narration_4": data.narration_4,
      "narration_5": data.narration_5,
      "remark": data.remark,
      "tcs_rate": data.tcs_rate ? data.tcs_rate : null,
      "tcs_rate_above_7": data.tcs_rate_above_7 ? data.tcs_rate_above_7 : data.tcs_rate ? data.tcs_rate: null,
      "tcs_amount": data.tcs_amount ? data.tcs_amount : null,
      "total": data.sub_invoice_type=='tour'?this.invoiceTotalAmount:totalAmount ? Math.round(totalAmount) : null,
      "balance_due": 0,
      "gst_total": gst,
      "products": products,
      "reference_invoice_number": products[0].reference_invoice_number,
      "transaction_method": data.transaction_method ? data.transaction_method : 'sales',
      "sales_currency": data.sales_currency ? data.sales_currency : null,
      "sales_currency_rate" : data.sales_currency_rate ? data.sales_currency_rate : null,
      "sales_currency_amount" : data.sales_currency_amount ? data.sales_currency_amount : null,
      "purchase_currency": data.purchase_currency ? data.purchase_currency : null,
      "purchase_currency_rate" : data.purchase_currency_rate ? data.purchase_currency_rate : null,
      "nonRefundable" : data.nonRefundable ? data.nonRefundable : false,
      "bill_of_supply" : false,
      "rcm_applicable": data.rcm_applicable ? data.rcm_applicable : false,
      "reverse_tds": data.reverse_tds ? data.reverse_tds : false,
      "tcs_on": data.tcs_on ? data.tcs_on : 'total',
      'hsn': data.hsn,
        "hsn_2": data.hsn_2,
        "c_sc1_type": data.c_sc1_type,
        "c_sc1_value": data.c_sc1_value,
        "c_sc1_amount": data.c_sc1_amount ,
        "c_gst_type": data.c_gst_type ,
        "c_gst_conf": data.c_gst_conf ,
        "c_cgst": data.c_cgst ,
        "c_sgst": data.c_sgst ,
        "c_igst": data.c_igst,
        "c_net_gst": data.c_net_gst,
        "c_gst_type_2": data.c_gst_type_2,
        "c_gst_conf_2": data.c_gst_conf_2,
        "c_cgst_2": data.c_cgst_2,
        "c_sgst_2": data.c_sgst_2,
        "c_igst_2": data.c_igst_2,
        "c_net_gst_2": data.c_net_gst_2,
        "client_sub_total": data.client_sub_total,
        "retrieveObj" : this.retrieveData
    }

    return invoioceObj;
  }

}


