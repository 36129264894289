import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonServiceService } from '../../common-service.service';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';
import { GlobalVariable } from '../../global-variable';
@Component({
  selector: 'app-general-search',
  templateUrl: './general-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class GeneralSearchComponent implements OnInit {
  modalRef: BsModalRef;
  generalFilterForm: FormGroup;
  generalArr: any;
  keyword = 'name';
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	@ViewChild('clientAC',{static:true}) clientAC;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    public commonService: CommonServiceService,
    private spinner: NgxSpinnerService,
    private router: Router) {
  }

  ngOnInit() {

    // this.spinner.show();
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1000);


    this.generalFilterForm = this.formBuilder.group({
      account: [''],
      account_name: [''],
      account_code: [''],
      type: [''],   

    })

    // this.commonService.getOkount(`globalsearch/general`).subscribe(res => {
    //   this.generalArr = res
    // }, err => {
    //   console.log('Error  : ', err)
    // })
    this.setFormData()
	}

	async setFormData() {
		let queryParams = localStorage.getItem('activeGeneralQueryParams')
		if (queryParams != null) {
			let parsedParams = JSON.parse(queryParams)
			// this.generalFilterForm.controls.account.setValue(parsedParams.account ? parsedParams.account : "")
      this.getGeneral(parsedParams.account ? parsedParams.account : "",true)
			this.generalFilterForm.controls.account_code.setValue(parsedParams.account_code ? parsedParams.account_code : "")
			this.generalFilterForm.controls.type.setValue(parsedParams.type ? parsedParams.type : "")
			
		}
	}

  getGeneral(value,bindValue) {
		if (value.length > 2) {
			this.commonService.getOkount(`general/autocomplete?name_like=${value}`).subscribe(res => {
				this.generalArr = res
        if(bindValue && this.generalArr.length>0){
          this.generalFilterForm.controls['account'].setValue(this.generalArr[0].account_code)
          this.generalFilterForm.controls['account_name'].setValue(this.generalArr[0].name)
        }

			}, err => {
	
			})
		}
  }

  reset() {
    GlobalVariable.openModalStatus = false;
    this.generalFilterForm = this.formBuilder.group({
      account: [''],
      account_name: [''],
      account_code: [''],
      type: ['']
    })
    localStorage.removeItem('activeGeneralQueryParams')
	  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'customModal' })
    );
  }


  search(values) {
    GlobalVariable.openModalStatus = false;
    // if (values.date == "" || values.date == "Invalid Date") {
    //   values.date = ""
    // }
    // else {
    //   values.date = moment(values.date).format('YYYY-MM-DD')
    // }
    // let group= values.group
		// if (values.group.name != undefined) {
		// 	group= values.group.name
    // } 
    let account= values.account
		if (values.account.account_code != undefined) {
			account= values.account.account_code
		} 
    this.router.navigateByUrl(`/master/general/view-general?account=${account}&account_code=${values.account_code}&type=${values.type}`)
    // this.reset()
  }

  ngAfterViewInit(){
		this.commonService.selectedSearchInput.subscribe(index=>{
			if (index == 1) {
				this.focusClientAc()
			}
		})
	}

	focusClientAc(){
		this.clientAC.focus();
	}


	selectAccount(value,formControlName){
		try {
			this.generalFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}


  clickSearchButton(){
		this.searchButton.nativeElement.click()
	}
}
