import { Injectable } from '@angular/core';
import { SectorService } from './sector.service';
import * as moment from 'moment'
import { InvoiceServicesService } from './invoice-services.service';
import { TotalFareModel } from '../models/total-fare-model';
import { CommonServiceService } from 'src/app/shared/common-service.service';

@Injectable({
	providedIn: 'root'
})
export class InvoiceRequestBuilderService {
	dateTimeMultiTab: string = "false";
	constructor(
		public invoiceService : InvoiceServicesService,
		public commonService : CommonServiceService
	) { }

	createInvoiceProduct(travellerFormValue,sectorFromValue,invoiceFromValue,sectorService) {
		let travellers = this.createTravellers(travellerFormValue)
		let sectors = this.createSectorsObj(sectorFromValue,invoiceFromValue,sectorService)
		let requestObj = {
			"booking_ref_no":  invoiceFromValue.booking_ref,
			"has_insurance" : invoiceFromValue.has_insurance ? invoiceFromValue.has_insurance : false,
			"insurance_premium" : invoiceFromValue.insurance_premium ? invoiceFromValue.insurance_premium : 0,
			"reference_ms_invoice" : invoiceFromValue.reference_ms_invoice ? invoiceFromValue.reference_ms_invoice : "",
            "crs_id": "",
            "boooking_supplier": "",
			"travellers" : travellers,
			"gstDetail":invoiceFromValue.gstDetail,
			"sectors": sectors,
			"pnr": invoiceFromValue.pnr,
			"employee": invoiceFromValue.employee,
			"employee_name": invoiceFromValue.employee_name,
			"last_updated_by_user_id": JSON.parse(localStorage.okountUserInfo).id,
			"last_updated_by_user_name": JSON.parse(localStorage.okountUserInfo).name,
			"last_updated_by_user_account_code": JSON.parse(localStorage.okountUserInfo).accountcode,
			"sectorComb1" : sectorFromValue.txtSector1,
			"sectorComb2" : sectorFromValue.txtSector2,
			"sub_ledger_code_1": invoiceFromValue.sub_ledger_code_1,
			"sub_ledger_code_2": invoiceFromValue.sub_ledger_code_2,
			"sub_ledger_code_3": invoiceFromValue.sub_ledger_code_3,
			"sub_ledger_code_4": invoiceFromValue.sub_ledger_code_4,
			"sub_ledger_code_5": invoiceFromValue.sub_ledger_code_5,
			"sub_ledger_code_1_type": invoiceFromValue.sub_ledger_code_1_type,
			"sub_ledger_code_2_type": invoiceFromValue.sub_ledger_code_2_type,
			"sub_ledger_code_3_type": invoiceFromValue.sub_ledger_code_3_type,
			"sub_ledger_code_4_type": invoiceFromValue.sub_ledger_code_4_type,
			"sub_ledger_code_5_type": invoiceFromValue.sub_ledger_code_5_type
		}
		return requestObj;
	}
	getCRSID(invoiceFormValue, selectedRetID) {
		let selCRS = invoiceFormValue.crsId
		let selectCRS = "";
		if (selCRS == '1A') {
			selectCRS = "AM"
		}
		else if (selCRS == '1B') {
			selectCRS = "SB"
		}
		else if (selCRS == '1G') {
			selectCRS = "1G" //fix me
		}
		else if ((selCRS == 'website' || selCRS == 'WEBSITE') && selectedRetID == "6E670") {
			selectCRS = "6E"
		}
		else if ((selCRS == 'website' || selCRS == 'WEBSITE') && (selectedRetID == "SG050" || selectedRetID == "SG051" || selectedRetID == "SG052")) {
			selectCRS = "SG"
		}
		else if ((selCRS == 'website' || selCRS == 'WEBSITE') && (selectedRetID == "GO008" || selectedRetID == "G8008")) {
			selectCRS = "GO"
		}
		else if ((selCRS == 'website' || selCRS == 'WEBSITE') && (selectedRetID == "G9514")) {
			selectCRS = "AA" //FIX ME
		}
		else if ((selCRS == 'website' || selCRS == 'WEBSITE') && (selectedRetID == "Other")) {
			selectCRS = "OT" //FIX ME
		}
		else{
			selectCRS = 'OT'
		}
		return selectCRS;
	} 

	createTravellers(travellerFormValue){
		let travellers = []
		for (let i = 0; i < travellerFormValue.travellerList.length; i++) {
			var traveller = travellerFormValue.travellerList[i];
			let parsedTraveller = 	this.createTravellerObj(traveller)
			travellers.push(parsedTraveller)
		}
		return travellers
	}

	createTravellerObj(travellerData) {
		let traveller = JSON.parse(JSON.stringify(travellerData))
		let booking_supplier_name = '';
		if(traveller.txtbookingSupplier && traveller.txtbookingSupplier.name) {
			booking_supplier_name = traveller.txtbookingSupplier.name + '('+traveller.txtbookingSupplier.account_code+')';
		}
		if(typeof traveller.txtbookingSupplier == 'string'){
			traveller.txtbookingSupplier = {
				account_code: traveller.txtbookingSupplierCode,
				name: traveller.txtbookingSupplier
			}
			booking_supplier_name = traveller.txtbookingSupplier
		}
		
		let travellerObj = {
			"title": traveller.selTitle,
			"type": traveller.selType,
			"name": traveller.txtPassengerName,
			"employee":traveller.txtEmployeeName ? (traveller.txtEmployeeName.account_name?traveller.txtEmployeeName.account_name:traveller.txtEmployeeName):'',
			"e_code":traveller.txtEmployeeCode?traveller.txtEmployeeCode:'',
			"cost_center":traveller.txtEmployeeCostCenter?traveller.txtEmployeeCostCenter:'',
			"ticket_no": traveller.txtTicketNumber,
			"payable_transaction_code":traveller.txtSupplierCode?traveller.txtSupplierCode:'',
			"payable_date":traveller.supplierDate?moment(traveller.supplierDate).format('YYYY-MM-DD'):'',
			"prev_ticket_no": traveller.prev_ticket_no ? traveller.prev_ticket_no : traveller.txtTicketNumber,
			"isConjuction": traveller.isConjuction,
			"booking_supplier": traveller.txtbookingSupplier && traveller.txtbookingSupplier.account_code ? traveller.txtbookingSupplier.account_code : '',
			"booking_supplier_name" : booking_supplier_name,
			"pay_type": traveller.type_of_pay,
			"ledger_card_no":  traveller.ledger_card_no,
			"ledger_card_name": traveller.ledger_card_name && traveller.ledger_card_name.name ?  traveller.ledger_card_name.name : '',
			"fare": {
				"total": traveller.txtAmount,
				"base": traveller.txtBaseFare,
				"YQ": traveller.txtYQ,
				"YR": traveller.txtYR,
				"K3": traveller.txtK3,
				"OT": traveller.txtOT,
				"OC": traveller.txtOC,
				"addons": traveller.txtAddons,
				"markup": 0,
				"service_charge": traveller.txtSupplierSC1Amount,
				"gst_on_service_charge": 0
			},
			"local_fare":{
				"total": traveller.localAmount?traveller.localAmount:0,
				"base": traveller.localBaseFare? traveller.localBaseFare:0,
				"YQ": traveller.localYQ?traveller.localYQ:0,
				"YR": traveller.localYR?traveller.localYR:0,
				"K3": traveller.localK3?traveller.localK3:0,
				"OT": traveller.localOT?traveller.localOT:0,
				"OC": traveller.localOC?traveller.localOC:0,
				"addons": traveller.localAddons?traveller.localAddons:0,
				"markup": 0,
				"service_charge": traveller.localSupplierSC1Amount?traveller.localSupplierSC1Amount:0,
				"gst_on_service_charge": 0
			},
			"ssr": this.getSSRs(traveller.sectorList),
			"commission": {
				"customer": this.getClientComission(traveller),
				"supplier": this.getSupplierComission(traveller)
			},
			"service_charge": {
				"customer": this.getClientServiceCharge(traveller),
				"supplier": this.getSupplierServiceCharge(traveller)
			},

			"dis_recall":{
				"customer": this.getClientDiscountRecall(traveller),
				"supplier": this.getSupplierDiscountRecall(traveller)
			}
		}
		return travellerObj

	}
	getSSRs(sectorList: any) {
		let ssrList = []
		for (let i = 0; i < sectorList.length; i++) {
			const sector = sectorList[i];
			let seat = {
				code : sector.txtSeatCode && sector.txtSeatCode != 'NA' ? sector.txtSeatCode : '' ,
				amount : sector.txtSeatCharges
			}
			let meal = {
				code : sector.txtMealCode && sector.txtMealCode != 'NA' ? sector.txtMealCode : '',
				amount : sector.txtMealCharges
			}
			let baggage = {
				code : sector.txtBagCode && sector.txtBagCode != 'NA' ? sector.txtBagCode : '',
				amount : sector.txtBagCharges
			}
			let ancillary = {
				code : sector.txtAncillaryCode && sector.txtAncillaryCode != 'NA' ? sector.txtAncillaryCode : '',
				amount : sector.txtAncillaryCharges
			}
			let additionalBaggage = {
				code : sector.txtAdditionalBaggageCode && sector.txtAdditionalBaggageCode != 'NA' ? sector.txtAdditionalBaggageCode : '' ,
				amount : sector.txtAdditionalBaggageCharges
			}
			let ssr = {seat,meal,baggage,ancillary,additionalBaggage}
			ssrList.push(ssr)
		}
		return ssrList
	}
	getClientComission(traveller) {
		let clientComissionObj = {
			"iata_type": traveller.selClientIataType,
			"iata_value": traveller.selClientIataValue,
			"iata_amount": traveller.selClientIataAmount,
			"plb_type": traveller.selClientPlbType,
			"plb_value": traveller.selClientPlbValue,
			"plb_amount": traveller.selClientPlbAmount,
			"cashback_type": traveller.selClientCbType,
			"cashback_value": traveller.selClientCbValue,
			"cashback_amount": traveller.selClientCbAmount,
			"discount_type":traveller.selClientDsType,
			"discount_value": traveller.selClientDsValue,
			"discount_amount": traveller.selClientDsAmount
		}
		return clientComissionObj
	}
	getSupplierComission(traveller) {
		let supplierComissionObj = {
			"iata_type": traveller.selSupplierIataType,
			"iata_value": traveller.selSupplierIataValue,
			"iata_amount": traveller.selSupplierIataAmount,
			"plb_type": traveller.selSupplierPlbType,
			"plb_value": traveller.selSupplierPlbValue,
			"plb_amount": traveller.selSupplierPlbAmount,
			"cashback_type": traveller.selSupplierCbType,
			"cashback_value": traveller.selSupplierCbValue,
			"cashback_amount": traveller.selSupplierCbAmount,
			"discount_type": traveller.selSupplierDsType,
			"discount_value": traveller.selSupplierDsValue,
			"discount_amount": traveller.selSupplierDsAmount
		}
		return supplierComissionObj
	}
	getClientServiceCharge(traveller) {
		let clientComissionObj = {
			"sc1_type": traveller.txtClientSC1Type,
			"sc1_value": traveller.txtClientSC1Value,
			"sc1_amount": traveller.txtClientSC1Amount,
			"sc2_type": traveller.txtClientSC2Type,
			"sc2_taxable": traveller.txtClientSC2Taxable,
			"sc2_value": traveller.txtClientSC2Value,
			"sc2_amount": traveller.txtClientSC2Amount,
			"sc3_type": traveller.txtClientSC3Type,
			"sc3_taxable": traveller.txtClientSC3Taxable,
			"sc3_value": traveller.txtClientSC3Value,
			"sc3_amount": traveller.txtClientSC3Amount
		}
		return clientComissionObj
	}
	getSupplierServiceCharge(traveller) {
		let supplierComissionObj = {
			"sc1_type": traveller.txtSupplierSC1Type,
			"sc1_value": traveller.txtSupplierSC1Value,
			"sc1_amount": traveller.txtSupplierSC1Amount,
			"sc2_type": traveller.txtSupplierSC2Type,
			"sc2_value": traveller.txtSupplierSC2Value,
			"sc2_amount": traveller.txtSupplierSC2Amount,
			"sc3_type": traveller.txtSupplierSC3Type,
			"sc3_value": traveller.txtSupplierSC3Value,
			"sc3_amount": traveller.txtSupplierSC3Amount
		}
		return supplierComissionObj
	}


	getClientDiscountRecall(traveller) {
		let clientDiscRecallObj = {
			"dis_recall_type": traveller.disClientRecallType,
			"dis_recall_value": traveller.disClientRecallValue,
			"dis_recall_amount": traveller.disClientRecallAmount,
		}
		return clientDiscRecallObj
	}
	getSupplierDiscountRecall(traveller) {
		let supplierDiscRecallObj = {
			"dis_recall_type": traveller.disSupplierRecallType,
			"dis_recall_value": traveller.disSupplierRecallValue,
			"dis_recall_amount": traveller.disSupplierRecallAmount,
		}
		return supplierDiscRecallObj
	}

	createSectorsObj(sectorForm,invoiceForm,sectorService) {
		try {
			let sectors = []
			for (let i = 0; i < sectorForm.sectorlist.length; i++) {
				const sector = sectorForm.sectorlist[i];
				let depart_arrival_info = this.getAirlineInfo(sector,1)
				var duration = ""+moment.duration(moment(sector.dateAirlineArrivalInfo).diff(sector.dateAirlineDepartInfo, 'minutes'));
				let sectorObj = {
					"pnr": invoiceForm.pnr,
					"duration": duration,
					"departure_airport_details": depart_arrival_info['departure_airport_details'],
					"arrival_airport_details": depart_arrival_info['arrival_airport_details'],
					"legs": this.createLegs(sector, depart_arrival_info,sectorService)
				}
				sectors.push(sectorObj)
			}
			for (let i = 0; i < sectorForm.sector2list.length; i++) {
				const sector = sectorForm.sector2list[i];
				let depart_arrival_info = this.getAirlineInfo(sector,2)
				var duration = ""+moment.duration(moment(sector.dateAirlineArrivalInfo2).diff(sector.dateAirlineDepartInfo2, 'minutes'));
				let sectorObj = {
					"pnr": invoiceForm.pnr,
					"duration": duration,
					"departure_airport_details": depart_arrival_info['departure_airport_details'],
					"arrival_airport_details": depart_arrival_info['arrival_airport_details'],
					"legs": this.createConjunctionLegs(sector, depart_arrival_info,sectorService)
				}
				sectors.push(sectorObj)
			}
			return sectors
		} catch (error) {
			console.log('Error occured while creating sectors obj : ',error)
		}
	}

	getAirlineInfo(sector,sectorNo){

		let globalSettingConfig = this.commonService.globalSettingConfig;
			let inv_config = globalSettingConfig.filter(el=>el.group=='FLIGHT_INV')
			if(inv_config.length){
				 let multiTabConfig= inv_config[0].json_doc.value.filter(el=>el.key=='MULTI TAB TRAVEL DATE TIME')
				if(multiTabConfig && multiTabConfig.length>0){
					this.dateTimeMultiTab = multiTabConfig[0].value
				}
			}

		let sectorCombination = "";
		if (sector.txtSectorComb != undefined) {
			sectorCombination = sector.txtSectorComb 
		}else{
			sectorCombination = sector.txtSectorComb2 
		}
		let legArr = sectorCombination.split("/");
		let departure_station_code = legArr[0] ? legArr[0].trim() : "";
		let arrival_station_code = legArr[1] ? legArr[1].trim() : "";
		let departureDetails = "";
		if (sector.txtAirlineDepartInfo != undefined) {
			departureDetails = sector.txtAirlineDepartInfo 
		}else{
			departureDetails = sector.txtAirlineDepartInfo2 
		}
		let departurInfoArr = departureDetails.split(",");
		let departure_station_city = departurInfoArr[0] ? departurInfoArr[0].trim() : "";
		let departure_station_name = departurInfoArr[1] ? departurInfoArr[1].trim() : "";
		if (departurInfoArr[1].includes("(")) {
			departure_station_name = departure_station_city
		}
		let departure_terminal = sector.txtDepartureTerminal ? sector.txtDepartureTerminal : "";
		
		let arrivalDetails = "";
		if (sector.txtAirlineArrivalInfo != undefined) {
			arrivalDetails = sector.txtAirlineArrivalInfo 
		}else{
			arrivalDetails = sector.txtAirlineArrivalInfo2 
		}
		let arrivalInfoArr = arrivalDetails.split(",");
		let arrival_station_city = arrivalInfoArr[0] ? arrivalInfoArr[0].trim() : "";
		let arrival_station_name = arrivalInfoArr[1] ? arrivalInfoArr[1].trim() : "";
		if (arrivalInfoArr[1].includes("(")) {
			arrival_station_name = arrival_station_city
		}
		let arrival_terminal = sector.txtArrivalTerminal ? sector.txtArrivalTerminal : "";
		if (sectorNo == 2) {
			departure_terminal = sector.txtDepartureTerminal2 ? sector.txtDepartureTerminal2 : "";
			arrival_terminal = sector.txtArrivalTerminal2 ? sector.txtArrivalTerminal2 : "";
		}
		let departureDateTime = 'Invalid date'
		console.log("sector.dateAirlineDepartInfo",sector.dateAirlineDepartInfo)
		console.log("sector.AirlineDepartDate",sector.AirlineDepartDate)
		console.log("this.dateTimeMultiTab",this.dateTimeMultiTab)
		 if(sector.AirlineDepartDate != undefined && sector.AirlineDepartDate !='' &&sector.AirlineDepartDate!='Invalid date' && this.dateTimeMultiTab=="true"){
				console.log('sector.AirlineDepartTime',sector.AirlineDepartTime)
				if(sector.AirlineDepartTime=='Invalid date'|| sector.AirlineDepartTime==''){
					departureDateTime=`${moment(sector.AirlineDepartDate).format('YYYY-MM-DD')}`
				}else{

					departureDateTime = `${moment(sector.AirlineDepartDate).format('YYYY-MM-DD')}T${moment(sector.AirlineDepartTime,"HH:mm").format('HH:mm')}`
				}
			}else if(sector.AirlineDepartDate2 != undefined && sector.AirlineDepartDate2 !='' &&sector.AirlineDepartDate2!='Invalid date' && this.dateTimeMultiTab=="true"){
				console.log('sector.AirlineDepartTime2',sector.AirlineDepartTime2)
				if(sector.AirlineDepartTime2=='Invalid date' || sector.AirlineDepartTime2==''){
					departureDateTime=`${moment(sector.AirlineDepartDate2).format('YYYY-MM-DD')}`
				}else{

					departureDateTime = `${moment(sector.AirlineDepartDate2).format('YYYY-MM-DD')}T${moment(sector.AirlineDepartTime2,"HH:mm").format('HH:mm')}`
				}
			}else if (sector.dateAirlineDepartInfo != undefined && sector.dateAirlineDepartInfo !='' && sector.dateAirlineDepartInfo !='Invalid date' && this.dateTimeMultiTab=="false" ) {
				departureDateTime = `${moment(sector.dateAirlineDepartInfo).format('YYYY-MM-DDTHH:mm')}`
	
			}else if (sector.dateAirlineDepartInfo2 != undefined && sector.dateAirlineDepartInfo2 !='' && sector.dateAirlineDepartInfo2 !='Invalid date' && this.dateTimeMultiTab=="false" ){

				departureDateTime = moment(sector.dateAirlineDepartInfo2).format('YYYY-MM-DDTHH:mm')
			}
		
		console.log("departureDateTime",departureDateTime)
		let arrivalDateTime = 'Invalid date'
		console.log("sector.dateAirlineArrivalInfo",sector.dateAirlineArrivalInfo)
		console.log("sector.AirlineArrivalDate",sector.AirlineArrivalDate)
		 if(sector.AirlineArrivalDate != undefined && sector.AirlineArrivalDate !='' && sector.AirlineArrivalDate!='Invalid date' && this.dateTimeMultiTab=="true"){
				console.log("sector.AirlineArrivalTime",sector.AirlineArrivalTime)
				if(sector.AirlineArrivalTime=='Invalid date'|| sector.AirlineArrivalTime==''){
					arrivalDateTime =  `${moment(sector.AirlineArrivalDate).format('YYYY-MM-DD')}`
				}else{

					arrivalDateTime =  `${moment(sector.AirlineArrivalDate).format('YYYY-MM-DD')}T${moment(sector.AirlineArrivalTime,"HH:mm").format('HH:mm')}`
				}
			}else if(sector.AirlineArrivalDate2 != undefined && sector.AirlineArrivalDate2 !='' && sector.AirlineArrivalDate2!='Invalid date' && this.dateTimeMultiTab=="true"){
				console.log("sector.AirlineArrivalTime2",sector.AirlineArrivalTime2)
				if(sector.AirlineArrivalTime2=='Invalid date'||sector.AirlineArrivalTime2==''){
					arrivalDateTime =  `${moment(sector.AirlineArrivalDate2).format('YYYY-MM-DD')}`
				}else{

					arrivalDateTime =  `${moment(sector.AirlineArrivalDate2).format('YYYY-MM-DD')}T${moment(sector.AirlineArrivalTime2,"HH:mm").format('HH:mm')}`
				}
			}else if (sector.dateAirlineArrivalInfo != undefined && sector.dateAirlineArrivalInfo !='' && sector.dateAirlineArrivalInfo !='Invalid date' && this.dateTimeMultiTab=="false") {
				arrivalDateTime =  `${moment(sector.dateAirlineArrivalInfo).format('YYYY-MM-DDTHH:mm')}`
	
			}else  if (sector.dateAirlineArrivalInfo2 != undefined && sector.dateAirlineArrivalInfo2 !='' && sector.dateAirlineArrivalInfo2 !='Invalid date' && this.dateTimeMultiTab=="false") {
				arrivalDateTime = moment(sector.dateAirlineArrivalInfo2).format('YYYY-MM-DDTHH:mm')
			}
		
		console.log("arrivalDateTime",arrivalDateTime)
		let departArrivalInfo = {
			"departure_airport_details": {
				"code": departure_station_code,
				"city": departure_station_city,
				"name": departure_station_name,
				"terminal": departure_terminal,
				"date_time": departureDateTime,
				"remark": ""
			},
			"arrival_airport_details": {
				"code": arrival_station_code,
				"city": arrival_station_city,
				"name": arrival_station_name,
				"terminal": arrival_terminal,
				"date_time": arrivalDateTime,
				"remark": ""
			}
		}
		return departArrivalInfo
	}
	createLegs(sector,depart_arrival_info,sectorService) {
		 
		let selectedAirlineName = sectorService.masterAirlineArr.filter(option => {
			return option['2_ltr_code'] == sector.txtAirlinePrefix 
		});

		if (selectedAirlineName.length == 0) {
			selectedAirlineName = sectorService.masterAirlineArr.filter(option => {
				return option['account_code'] == sectorService.invoiceForm.value.airline;
			});
		}
		let airline_name = ''
		let airline_xl_code = ''
		if (selectedAirlineName.length > 0) {
			airline_name = selectedAirlineName[0]['account_name'].split('-')[0]
			airline_xl_code = selectedAirlineName[0]['account_code']
		}
		var duration = ""+moment.duration(moment(sector.dateAirlineArrivalInfo).diff(sector.dateAirlineDepartInfo, 'minutes'));
		let legObj  = {
				"airline_code": sector.txtAirlinePrefix,
				"airline_name": airline_name,
				"airline_xl_code": airline_xl_code,
				"flight_no": sector.txtFlightNo,
				"service_class": sector.txtClass,
				"airline_pnr": sector.txtAirlinePnrX,
				"duration": duration,
				"departure_airport_details": depart_arrival_info['departure_airport_details'],
				"arrival_airport_details": depart_arrival_info['arrival_airport_details'],
				"baggage_info": {
					"cabin_baggage": "",
					"checkin_baggabe": sector.txtBaggage
				}
			}
		
		return legObj;
	}
	createConjunctionLegs(sector,depart_arrival_info,sectorService) {
		let selectedAirlineName = sectorService.airlineArr.filter(option => {
			return option['2_ltr_code'] == sector.txtAirlinePrefix2 && option['account_code'] == sectorService.invoiceForm.value.airline;
		});
		if (selectedAirlineName.length == 0) {
			selectedAirlineName = sectorService.airlineArr.filter(option => {
				return option['2_ltr_code'] == sector.txtAirlinePrefix2;
			});
		}
		let airline_name = ''
		let airline_xl_code = ''
		if (selectedAirlineName.length > 0) {
			airline_name = selectedAirlineName[0]['account_name'].split('-')[0]
			airline_xl_code = selectedAirlineName[0]['account_code']
		}
		var duration = ""+moment.duration(moment(sector.dateAirlineArrivalInfo2).diff(sector.dateAirlineDepartInfo2, 'minutes'));
		let legObj  = {
				"airline_code": sector.txtAirlinePrefix2,
				"airline_name": airline_name,
				"airline_xl_code": airline_xl_code,
				"flight_no": sector.txtFlightNo2,
				"service_class": sector.txtClass2,
				"airline_pnr": sector.txtAirlinePnrY,
				"duration": duration,
				"departure_airport_details": depart_arrival_info['departure_airport_details'],
				"arrival_airport_details": depart_arrival_info['arrival_airport_details'],
				"baggage_info": {
					"cabin_baggage": "",
					"checkin_baggabe": sector.txtBaggage2
				}
			}
		
		return legObj;
	}


	createFareObj(travellers,invoiceForm,account_code,invoice_root_data){
		let fareObjArr = []
		 
		for (const key in travellers.travellerList) {
			if (travellers.travellerList.hasOwnProperty(key)) {
				const traveller = travellers.travellerList[key];
				let fareObj = {
					"base" : traveller.txtBaseFare,
					"yq" : traveller.txtYQ,
					"yr" : traveller.txtYR,
					"k3" : traveller.txtK3,
					"oc" : traveller.txtOC,
					"ot" : traveller.txtOT,
					"ssr" : traveller.txtAddons,
					"localbase" : traveller.localBaseFare,
					"localyq" : traveller.localYQ,
					"localyr" : traveller.localYR,
					"localk3" : traveller.localK3,
					"localoc" : traveller.localOC,
					"localot" : traveller.localOT,
					"localssr" : traveller.localAddons,
					"airline_account_code": invoiceForm.airline,
					"client_account_code": account_code,
					"date": invoice_root_data ? invoice_root_data.invdate : null,
					"client_accounting" : {
						"iata_type": traveller.selClientIataType,
						"iata_value": traveller.selClientIataValue,
						"plb_type": traveller.selClientPlbType,
						"plb_value": traveller.selClientPlbValue,
						"cashback_type": traveller.selClientCbType,
						"cashback_value": traveller.selClientCbValue,
						"discount_type": traveller.selClientDsType?traveller.selClientDsType:'VL',
						"discount_value": traveller.selClientDsValue?traveller.selClientDsValue:"0",
						"service_charge_1_type" : traveller.txtClientSC1Type,
						"service_charge_1_value" : traveller.txtClientSC1Value,
						"service_charge_2_type" : traveller.txtClientSC2Type,
						"service_charge_2_taxable" : traveller.txtClientSC2Taxable,
						"service_charge_2_value" : traveller.txtClientSC2Value,
						"service_charge_3_type" : traveller.txtClientSC3Type,
						"service_charge_3_taxable" : traveller.txtClientSC3Taxable,
						"service_charge_3_value" : traveller.txtClientSC3Value,
						"tds" : 0,
						"disc_recall" : traveller.disClientRecallValue,
						"gst_on_service_charge" : 0 ,
						"xxl_charge" : traveller.txtClientXXLAmount,
						"raf_charge" : traveller.txtClientRAFAmount 
					},
					"supplier_accounting" : {
						"iata_type": traveller.selSupplierIataType,
						"iata_value": traveller.selSupplierIataValue,
						"plb_type": traveller.selSupplierPlbType,
						"plb_value": traveller.selSupplierPlbValue,
						"cashback_type": traveller.selSupplierCbType,
						"cashback_value": traveller.selSupplierCbValue,
						"discount_type": traveller.selSupplierDsType?traveller.selSupplierDsType:'VL',
						"discount_value": traveller.selSupplierDsValue?traveller.selSupplierDsValue:'0',
						"service_charge_1_type" : traveller.txtSupplierSC1Type,
						"service_charge_1_value" : traveller.txtSupplierSC1Value,
						"service_charge_2_type" : traveller.txtSupplierSC2Type,
						"service_charge_2_value" : traveller.txtSupplierSC2Value,
						"service_charge_3_type" : traveller.txtSupplierSC3Type,
						"service_charge_3_value" : traveller.txtSupplierSC3Value,
						"tds" : 0,
						"disc_recall" : traveller.disSupplierRecallValue,
						"gst_on_service_charge" : 0 ,
						"raf_charge" : traveller.txtSupplierRAFAmount,
						"xxl_charge" : traveller.txtSupplierXXLAmount 
					},
					"traveller_detail" : traveller
				}

				fareObjArr.push(fareObj)
			}
		}
		return fareObjArr
	}

	async createProductObj(product_json,totalFareModel,narration,hsn,sectorService){
		if(!sectorService.totalFareModel[0]){
			sectorService.totalFareModel[0] = new TotalFareModel(null)
		}

		let total = sectorService.totalFareModel[0].net_received;
		let remark = product_json.travellers[0].title + " " +product_json.travellers[0].name;
				if(product_json.travellers.length > 1) {
					remark += " + " + Number(product_json.travellers.length - 1)
				}
		let productObj = {
			"json_doc": product_json,
			"hsn": hsn,
			"quantity":1,
			"rate": total,
			"amount": total,
			"tax": 0,
			"total":  total,
			"product" : "Flight",
			"narration" : narration
		  }
		  return productObj
	}
	getTotalAmount(totalFareModel) {
		return totalFareModel.net_received
	}
	getAmountwithoutGst(totalFareModel) {
		return totalFareModel.base + totalFareModel.ssr + totalFareModel.yq + totalFareModel.yr  + totalFareModel.oc + totalFareModel.ot
	}


	bindPaidSsrData (retrievedData, ssrType, selectedLedger, values,products) {
		let totalFareObj = {
			"total": 0.0,
			"gst_total": 0.0
		}
		let amount = 0;

		let product = products && products.length > 0 ? products[0] : {}
		let ssrs = retrievedData['ssrResponse'] && retrievedData['ssrResponse'] && retrievedData['ssrResponse']['ssrs']
		let travellers = this.bindTravellers(retrievedData, totalFareObj, product['supplier'], ssrs, ssrType)
		
		let obj = {
			"affiliate_code": selectedLedger['account_code'],
			"name": selectedLedger.name,
			"billaddress": "DELHI  ",
			"ledger_id": selectedLedger['account_code'],
			"invoice_date": moment().format('YYYY-MM-DDTHH:mm'),
			"created_by": 1,
			"updated_by": 1,
			"year_id": 1,
			"tenant_id": 1,
			"narration": values.narration,
			"narration_1": values.narration_1,
			"narration_2": values.narration_2,
			"narration_3": values.narration_3,
			"narration_4": values.narration_4,
			"narration_5": values.narration_5,
			"remark": values.remark,
			"total": Number(totalFareObj.total).toFixed(2),
			"balance_due": 0,
			"gst_total": Number(totalFareObj.gst_total).toFixed(2),
			"invoice_no": "",
			"referenceInvoice": "",
            "invoiceType": "reissue",
			"product_type": "Flight",
			"products": [
				{
					"json_doc": {
						"booking_ref_no": "",
						"crs_id": product && product['json_doc'] && product['json_doc']['crs_id'] ? product['json_doc']['crs_id'] : '' ,
						"crs_id_value":  product && product['json_doc'] && product['json_doc']['crs_id_value'] ? product['json_doc']['crs_id_value'] : '' ,
						"boooking_supplier": "",
						"travellers": travellers,
						"sectors": product && product['json_doc'] && product['json_doc']['sectors'] ? product['json_doc']['sectors'] : "",
						"employee": "EO0000",
						"sub_ledger_code_1": product['sub_ledger_code_1'] ? product['sub_ledger_code_1'] : '',
						"sub_ledger_code_2": "",
						"sub_ledger_code_3": "",
						"sub_ledger_code_4": "",
						"sub_ledger_code_5": "",
						"sub_ledger_code_1_type": "client",//it is fixed for clients
						"sub_ledger_code_2_type": "",
						"sub_ledger_code_3_type": "",
						"sub_ledger_code_4_type": "",
						"sub_ledger_code_5_type": "",
						"airline": product && product['json_doc'] && product['json_doc']['airline'] ? product['json_doc']['airline'] : "",
						"supplier": product && product['json_doc'] && product['json_doc']['supplier'] ? product['json_doc']['supplier'] : "",
						"invoiceType": product && product['json_doc'] && product['json_doc']['invoiceType'] ? product['json_doc']['invoiceType'] : "",
						"fareBasis":  product && product['json_doc'] && product['json_doc']['fareBasis'] ? product['json_doc']['fareBasis'] : "",
						"dealCode": product && product['json_doc'] && product['json_doc']['dealCode'] ? product['json_doc']['dealCode'] : "",
						"classType": product && product['json_doc'] && product['json_doc']['classType'] ? product['json_doc']['classType'] : ""
					},
					"quantity": 1,
					"rate": Number(totalFareObj.total).toFixed(2),
					"amount": Number(amount).toFixed(2),
					"tax": Number(totalFareObj.gst_total).toFixed(2),
					"total": Number(totalFareObj.total).toFixed(2),
					"product": "Flight",
					"narration": values.narration,
					"hsn_sac": "998551"
				}
			]
		}
	
		return obj;
	}
	
	bindFares(ssrs) {
		let totalFare = this.calculateTotalSsrPrice(ssrs);
		var fare = {
			"total": Number(totalFare),
			"base": 0.0,
			"YQ": 0.0,
			"YR": 0.0,
			"K3": 0.0,
			"OT": 0.0,
			"OC": 0.0,
			"markup": 0,
			"service_charge": 0,
			"gst_on_service_charge": 0
		}
		return fare;
	}

	bindLocalFares(ssrs) {
		let totalFare = this.calculateTotalSsrPrice(ssrs);
		var fare = {
			"total": Number(totalFare),
			"base": 0.0,
			"YQ": 0.0,
			"YR": 0.0,
			"K3": 0.0,
			"OT": 0.0,
			"OC": 0.0,
			"markup": 0,
			"service_charge": 0,
			"gst_on_service_charge": 0
		}
		return fare;
	}
	
	
	bindSSR(requestedSsrs, travellerIndex, ssrType) {
		var ssrs = [];
		for (const requestedSsr of requestedSsrs) {
			var ssr = {
				"seat": ssrType == 'seats' ? this.getSsrDetails(requestedSsr.seats, travellerIndex, ssrType) : {"code": "","amount": 0},
				"meal": ssrType == 'meals' ? this.getSsrDetails(requestedSsr.meals, travellerIndex, ssrType) :  {"code": "","amount": 0},
				"baggage": ssrType == 'baggages' ? this.getSsrDetails(requestedSsr.baggages, travellerIndex, ssrType) : {"code": "","amount": 0},
				"additionalBaggage": {"code": "","amount": 0},
				"ancillary": {"code": "","amount": 0}
			}
			ssrs.push(ssr);
		}
		return ssrs;
	}
	
	getSsrDetails(ssrsOfType, travellerIndex, ssrType) {
		var trvSsr = {
			"code": "",
			"amount": 0
		}

		let SsrArr = ssrsOfType && ssrsOfType.filter(ssr => ssr.travellerNumber == travellerIndex)

		if(SsrArr && SsrArr.length>0) {
			let ssrCodes = []
			let ssrAmount = 0
			for(let ssr of SsrArr) {
				if (ssr && ssr['travellerNumber'] == travellerIndex) {
					if(ssrType != 'seats') {
						ssrCodes.push(ssr.code)
					    ssrAmount += Number(ssr.price)
					}else {
						ssrCodes.push(ssr.number)
					    ssrAmount += Number(ssr.price)
					}
				}	
			}  
			trvSsr.code = ssrCodes.join(', ')
			trvSsr.amount = Number(ssrAmount)
		}
		return trvSsr;
	}
	
	calculateTotalSsrPrice(ssrs) {
		
		let travellerSsrTotal = 0;
		if (ssrs) {
			for (let ssr of ssrs) {
				if (ssr) {
					if (ssr.meal && ssr.meal.amount) {
						travellerSsrTotal += Number(ssr.meal.amount);
					}
					if (ssr.seat && ssr.seat.amount) {
						travellerSsrTotal += Number(ssr.seat.amount)
					}
					if (ssr.baggage && ssr.baggage.amount) {
						travellerSsrTotal += Number(ssr.baggage.amount)
					}
					if (ssr.ancillary && ssr.ancillary.amount) {
						travellerSsrTotal += Number(ssr.ancillary.amount)
					}
				}
			}
		}
		return travellerSsrTotal;
	}

	bindTravellers(request, totalFareObj, SCODE, ssrResponse, ssrType) {
		let travellers = [];

		for (var trvIndex in request.traveller) {
			
			let trv = request.traveller[trvIndex]
			let ssrs = this.bindSSR(ssrResponse, trvIndex, ssrType);
			let fare = this.bindFares(ssrs);
			let local_fare = this.bindLocalFares(ssrs)

			let ticket_no = ''

			if(ssrResponse && ssrResponse.length > 0) {
				for(let ssrObj of ssrResponse) {
					if(ssrType == 'meals') {
						if(ssrObj && ssrObj.meals && ssrObj.meals.length > 0) {
							let traveller = ssrObj.meals.find(meal => meal.travellerNumber == trvIndex)
							 ticket_no = traveller['ticketNumber'] ? traveller['ticketNumber'] : ''
						}				
					} else if(ssrType == 'seats') {
						if(ssrObj && ssrObj.seats && ssrObj.seats.length > 0) {
							let traveller = ssrObj.seats.find(seat => seat.travellerNumber == trvIndex)
							 ticket_no = traveller['ticketNumber'] ? traveller['ticketNumber'] : ''
						}
					} else if(ssrType == 'baggages') {
						if(ssrObj && ssrObj.baggages && ssrObj.baggages.length > 0) {
							let traveller = ssrObj.baggages.find(baggage => baggage.travellerNumber == trvIndex)
							ticket_no = traveller['ticketNumber'] ? traveller['ticketNumber'] : ''
						}
					}
				}
			}
	
			var traveller = {
				"title": trv.title,
				"type": trv.type,
				"name": trv.name,
				"ticket_no": ticket_no,
				"isConjuction": false,
				"booking_supplier": SCODE,
				"fare": fare,
				"local_fare": local_fare,
				"ssr": ssrs,
				"commission": {
					"customer": {
						"iata_type": "RB",
						"iata_value": "0",
						"iata_amount": "0",
						"plb_type": "RB",
						"plb_value": "0",
						"plb_amount": "0",
						"cashback_type": "VL",
						"cashback_value": "0",
						"cashback_amount": "0",
						"discount_type": "VL",
						"discount_value": "0",
						"discount_amount": "0"
					},
					"supplier": {
						"iata_type": "RB",
						"iata_value": "0",
						"iata_amount": "0",
						"plb_type": "RB",
						"plb_value": "0",
						"plb_amount": "0",
						"cashback_type": "VL",
						"cashback_value": "0",
						"cashback_amount": "0",
						"discount_type": "VL",
						"discount_value": "0",
						"discount_amount": "0"
					}
				},
				"service_charge": {
					"customer": {
						"sc1_type": "T",
						"sc1_value": "0",
						"sc1_amount": "0",
						"sc2_type": "N",
						"sc2_taxable": "N",
						"sc2_value": "0",
						"sc2_amount": "0",
						"sc3_type": "N",
						"sc3_taxable":"N",
						"sc3_value": "0",
						"sc3_amount": "0"
					},
					"supplier": {
						"sc1_type": "T",
						"sc1_value": "0",
						"sc1_amount": "0",
						"sc2_type": "N",
						"sc2_value": "0",
						"sc2_amount": "0",
						"sc3_type": "N",
						"sc3_value": "0",
						"sc3_amount": "0"
					}
				},
				"dis_recall": {
					"customer": {
						"dis_recall_type": "",
						"dis_recall_value": 18,
						"dis_recall_amount": "0"
					},
					"supplier": {
						"dis_recall_type": "",
						"dis_recall_value": 18,
						"dis_recall_amount": "0"
					}
				}
			}
			totalFareObj.total += Number(fare.total);
			travellers.push(traveller);
		}
		return travellers;
	}

	removeGSTAndServiceCharges(data) {
		let newData = JSON.parse(JSON.stringify(data));

		let	gstTotal = 0
		let serviceChargeTotal = 0;
		newData.products.forEach(product => {
			product.json_doc.travellers.forEach(traveller => {
				gstTotal += (parseFloat(traveller.service_charge.customer.sc1_amount) * 18/100)
				serviceChargeTotal += parseFloat(traveller.service_charge.customer.sc1_amount);
			});
		});
	
		let amountToSubtract = gstTotal + serviceChargeTotal;
		newData.gst_total = 0;
		newData.total -= amountToSubtract;
		newData.products[0].json_doc.total_fare.net_received -= amountToSubtract;
		newData.products.forEach(product => {
			product.rate -= amountToSubtract;
			product.amount -= amountToSubtract;
			product.total -= amountToSubtract;
			product.json_doc.total_fare.gst_recieved -= gstTotal;
			product.json_doc.total_fare.client_service_charges -= serviceChargeTotal;
			product.json_doc.travellers.forEach(traveller => {
				traveller.fare.gst_on_service_charge = 0;
				traveller.service_charge.customer.sc1_value = "0";
				traveller.service_charge.customer.sc1_amount = "0";
	
			});
		});
	
		return newData;
	}
	
	extractGSTAndServiceCharges(data) {
		let newData = JSON.parse(JSON.stringify(data));
		let	gstTotal = 0
		let serviceChargeTotal = 0;
		newData.products.forEach(product => {
			product.json_doc.travellers.forEach(traveller => {
				gstTotal = (parseFloat(traveller.service_charge.customer.sc1_amount) * 18/100)
				serviceChargeTotal += parseFloat(traveller.service_charge.customer.sc1_amount);
			});
		});
	
		let amountToAdd = gstTotal + serviceChargeTotal;
	
		newData.gst_total = gstTotal;
		newData.total = amountToAdd;
		newData.products[0].json_doc.total_fare.net_received = amountToAdd;
		newData.products.forEach(product => {
			product.rate = amountToAdd;
			product.amount = amountToAdd;
			product.total = amountToAdd;
			
			product.json_doc.total_fare.gst_recieved = gstTotal;
			product.json_doc.total_fare.client_service_charges = serviceChargeTotal;
	
			product.json_doc.travellers.forEach(traveller => {
				traveller.fare = {
					"total": "0",
					"base": "0",
					"YQ": "0",
					"YR": "0",
					"K3": "0",
					"OT": "0",
					"OC": "0",
					"addons": 0,
					"markup": 0,
					"service_charge": "0",
					"gst_on_service_charge": 0
				}
	
				traveller.commission = {
					"customer": {
						"iata_type": "RB",
						"iata_value": "0",
						"iata_amount": "0.00",
						"plb_type": "RB",
						"plb_value": "0",
						"plb_amount": "0.00",
						"cashback_type": "VL",
						"cashback_value": "0",
						"cashback_amount": "0.00"
					},
					"supplier": {
						"iata_type": "RB",
						"iata_value": "0",
						"iata_amount": 0,
						"plb_type": "RB",
						"plb_value": "0",
						"plb_amount": "0.00",
						"cashback_type": "VL",
						"cashback_value": "0",
						"cashback_amount": "0.00"
					}
				}
	
				traveller.service_charge = {
					"customer": {
						"sc1_type": "T",
						"sc1_value": traveller.service_charge.customer.sc1_value,
						"sc1_amount": traveller.service_charge.customer.sc1_amount,
						"sc2_type": "OT",
						"sc2_taxable": "N",
						"sc2_value": "0",
						"sc2_amount": "0",
						"sc3_type": "OT",
						"sc3_taxable": "N",
						"sc3_value": "0",
						"sc3_amount": "0"
					},
					"supplier": {
						"sc1_type": "T",
						"sc1_value": "0",
						"sc1_amount": "0",
						"sc2_type": "N",
						"sc2_value": "",
						"sc2_amount": "",
						"sc3_type": "N",
						"sc3_value": "0",
						"sc3_amount": "0"
					}
				}
	
				traveller.ssr = [
					{
						"seat": {
							"code": "",
							"amount": ""
						},
						"meal": {
							"code": "",
							"amount": ""
						},
						"baggage": {
							"code": "",
							"amount": ""
						},
						"ancillary": {
							"code": "",
							"amount": ""
						},
						"additionalBaggage": {
							"code": "",
							"amount": ""
						}
					}
				]
		
				traveller.dis_recall = {
					"customer": {
						"dis_recall_type": "",
						"dis_recall_value": 18,
						"dis_recall_amount": "0"
					},
					"supplier": {
						"dis_recall_type": "",
						"dis_recall_value": 18,
						"dis_recall_amount": "0"
					}
				}
			});
	
			product.json_doc.total_fare = {
				"base": "0",
				"yq": "0",
				"yr": "0",
				"k3": "0",
				"oc": "0",
				"ot": "0",
				"ssr": "0",
				"tds_paid": "0",
				"tds_recieved": "0",
				"gst_paid": "0",
				"gst_recieved": gstTotal,
				"commission_paid": "0",
				"commission_received": "0",
				"net_paid": "0",
				"round_off": "0",
				"net_received": amountToAdd,
				"client_normal_service_charges": "0",
				"supplier_normal_service_charges": "0",
				"vat_paid": "0",
				"vat_received": "0",
				"supplier_service_charges": "0",
				"client_service_charges": serviceChargeTotal
			}
		});
	
		newData.invoice_uuid = null;
		newData.bill_of_supply = false;
		return newData;
	}
	

}
