<div class="ledger-container">
    <div class="voucher-container">
      <div class="row tab-nav pl-0 pr-0">
      <div class="col-sm-4">
        <ul
          class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0"
        >
          <li
            [routerLink]="['/']"
            class="text-grey text-capitalize cursor hover-text-primary transition"
            [ngClass]="{ 'd-none': isPrint == 'true' }"
          >
            Analysis
          </li>
          <li>
            <img
              src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg"
              alt=""
              [ngClass]="{ 'd-none': isPrint == 'true' }"
            />
          </li>
          <li
            class="text-grey text-capitalize cursor text-primary transition"
            [ngClass]="{ 'd-none': isPrint == 'false' }"
            [ngClass]="{ 'd-none': isPrint == 'true' }"
          >
          Mis Report 
          </li>
        </ul>
      </div>
      <div class="col-sm-4 text-center">
        <span class="rec-date d-block text-center">
          <div class="noWrap justify-content-around">
              <ng-container *ngIf="duration != undefined">
                  <div class="cname">From
                      <h5 style="margin-right: 5px;" class="mb-0  d-inline"><span class="date text-bold text-orange"
                              *ngIf="duration.start_date != ''">
                              {{duration?.start_date | date : 'd MMMM, y'}} 
                          </span>
                          <span class="date text-bold" *ngIf="duration.start_date != ''">
                              To
                          </span>
                          <span class="date text-bold" *ngIf="duration.start_date == ''">
                              Till Date
                          </span>
                          <span class="date text-bold text-orange">
                              {{duration?.end_date | date : 'd MMMM, y'}}
                          </span>
                      </h5>
                      <button
                      [ngClass]="{ 'd-none': isPrint == 'true' }"
                      title="Custom Search"
                      class="btn save-as text-bold bg-whiteSmoke"
                      type="button"
                      (click)="openAdvanceSearchModal()"
                      >
                      <img
                        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg"
                        alt=""
                        width="19px"
                      />
                    </button>
                  </div>
              </ng-container>	
          </div>
        </span>
      </div>
      <div class="col-sm-4 d-flex align-item-center justify-content-end">
        <div
        class="page-search-box input-group d-flex align-item-center" 
      [ngClass]="{ 'd-none': isPrint == 'true' }"
        style="height: fit-content; text-align: left"
      >
        <input
          type="text"
          class="form-control"
          oninput="this.value = this.value.toUpperCase()"
          placeholder="search"
          style="text-align: left"
        />
      </div>
    
        
        <div class="dropdown-btn position-relative" 
        [ngClass]="{ 'd-none': isPrint == 'true' }">
          <button title="More..." class="btn-style mt-0 more-btn">
            <span class="dot"></span><span class="dot"></span
            ><span class="dot"></span>
          </button>
          <ul class="list-unstyled pl-0 dropdown-box">
            <li (click)="openSavePdfModal()">
                <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg" alt="" />
                <span class="ml-2">Save As Pdf</span>
              </li>
            
            <li (click)="exportAsXLSX()" >
              <img
                src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg"
                alt=""
              />
              <span class="ml-2">Excel</span>
            </li>
          </ul>
        </div>
      </div>
      </div>

    <div class="voucher-container psrdetailedtbl misdetailedtbl mr-1">
        <div class="table" style="margin-top: -2px; height: auto!important;">
          <nz-table #basicTable [nzData]="allInvoiceArr"
          
            [nzLoading]="loader"
            [nzFrontPagination]="false" 

            class="table mis-tbl text-black striped border-0">
              <thead class="mis-head-tbl">
                <tr style="border-bottom: 0;" >
                    <th class="report-date border-left">Invoice Date</th>
                    <th class="report-no  border-left">Invoice number</th>
                    <th class="report-credit  border-left">Pax Name</th>
                    <th class="border-left report-fare-code">Airline Code</th>
                    <th class="border-left report-fare-value">Ticket Number</th>
                    <th class="border-left report-sector">Sector</th>
                    <th *ngIf="queryParams.service_type != 'hotel'" class="border-left  travel-date">Travel Date</th>
                    <th *ngIf="queryParams.service_type == 'hotel'" class="border-left  check-in-date">Check In Date</th>
                    <th *ngIf="queryParams.service_type == 'hotel'" class="border-left  check-out-date">Check Out Date</th>
                    <th class="border-left report-raf">Basic Fare</th>
                    <th class="border-left report-rate">OT TAX</th>
                    <ng-container *ngIf="queryParams.service_type == 'flight' ">
                        <th class="border-left  report-yq-tax">YQ Tax</th>
                        <th class="border-left  report-amt">YR Tax</th>
                        <th class="border-left  report-k3-tax">K3 TAX</th>
                    </ng-container>    
                    <th class="border-left border-left  report-payable">Service Fee</th>
                    <th class="border-left  report-sgst">SGST</th>
                    <th class="border-left  report-cgst">CGST</th>
                    <th class="border-left  report-igst">IGST</th>
                    <ng-container *ngIf="queryParams.service_type =='hotel' || queryParams.service_type =='car'">
                        <th class="border-left  report-sgst">SGST 2</th>
                        <th class="border-left  report-cgst">CGST 2</th>
                        <th class="border-left  report-igst">IGST 2</th>
                        <th class="border-left  report-sgst">SGST 3</th>
                        <th class="border-left  report-cgst">CGST 3</th>
                        <th class="border-left  report-igst">IGST 3</th>
                    </ng-container>
                    <th class="border-left  report-xxl">XXL</th>
                    <th class="border-left report-tds-amt">TDS AMT</th>
                    <th class="border-left report-net-amt">NET AMOUNT</th>
                </tr>

              </thead>

              <tbody class="scroll-body main-panel psr-data-tbl mis-data-tbl" style="position: absolute;">
                
                            <!-- Invoice -->
                            <ng-container *ngIf="basicTable['data']['flight_invoice']">
                                <tr>
                                 <td colspan="22" class="text-bold undl text-black text-underline">Air Invoice</td>
                                </tr>
                                <tr *ngFor="let data of basicTable['data']['flight_invoice'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'invoice')"
                                (click)="openViewMode(data.reference,'invoice')"
                                >
                                <ng-container 
                                    *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['flight_invoice_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['flight_invoice_total']}">
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td colspan="22">&nbsp;</td>
                                </tr>
                            </ng-container>

                            <!-- Credit Note -->
                            <ng-container *ngIf="basicTable['data']['credit_note']"
                            >
                                <tr>
                                  <td colspan="19" class="text-bold undl text-black text-underline">Air Credit Note</td>
                                </tr>
                                <tr *ngFor="let data of basicTable['data']['credit_note'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'credit_note')"
                                (click)="openViewMode(data.reference,'credit_note')"
                                >
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['credit_note_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['credit_note_total']}">
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>
                           
                            <!-- Hotel Invoice -->
                            <ng-container *ngIf="basicTable['data']['hotel_invoice']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Hotel Invoice</td></tr>
                                <tr *ngFor="let data of basicTable['data']['hotel_invoice'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'ms-invoice')"
                                (click)="openViewMode(data.reference,'ms-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['hotel_invoice_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['hotel_invoice_total']}">
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                             <!-- Hotel Credit Note -->
                             <ng-container *ngIf="basicTable['data']['hotel_credit_note']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Hotel Credit Note</td></tr>
                                <tr *ngFor="let data of basicTable['data']['hotel_credit_note'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'ms-invoice')"
                                (click)="openViewMode(data.reference,'ms-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['hotel_credit_note_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['hotel_credit_note_total']}">
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>
                            
                            <!--Visa Invoice-->
                            <ng-container *ngIf="basicTable['data']['visa_invoice']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Visa Invoice</td></tr>
                                <tr *ngFor="let data of basicTable['data']['visa_invoice'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'ms-invoice')"
                                (click)="openViewMode(data.reference,'ms-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['visa_invoice_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['visa_invoice_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                             <!--Visa Credit Note -->
                             <ng-container *ngIf="basicTable['data']['visa_credit_note']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Visa Credit Note</td></tr>
                                <tr *ngFor="let data of basicTable['data']['visa_credit_note'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'ms-invoice')"
                                (click)="openViewMode(data.reference,'ms-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['visa_credit_note_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['visa_credit_note_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                            <!-- Insurance Invoice -->
                            <ng-container *ngIf="basicTable['data']['insurance_invoice']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Insurance Invoice</td></tr>
                                <tr *ngFor="let data of basicTable['data']['insurance_invoice'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'ms-invoice')"
                                (click)="openViewMode(data.reference,'ms-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['insurance_invoice_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['insurance_invoice_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                            <!-- Insurance Credit Note -->
                            <ng-container *ngIf="basicTable['data']['insurance_credit_note']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Insurance Credit Note</td></tr>
                                <tr *ngFor="let data of basicTable['data']['insurance_credit_note'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'ms-invoice')"
                                (click)="openViewMode(data.reference,'ms-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['insurance_credit_note_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['insurance_credit_note_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                            <!-- Rail Invoice -->
                            <ng-container *ngIf="basicTable['data']['rail_invoice']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Rail Invoice</td></tr>
                                <tr *ngFor="let data of basicTable['data']['rail_invoice'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'ms-invoice')"
                                (click)="openViewMode(data.reference,'ms-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['rail_invoice_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['rail_invoice_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                            <!-- Rail Credit Note -->
                            <ng-container *ngIf="basicTable['data']['rail_credit_note']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Rail Credit Note</td></tr>
                                <tr *ngFor="let data of basicTable['data']['rail_credit_note'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'ms-invoice')"
                                (click)="openViewMode(data.reference,'ms-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['rail_credit_note_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['rail_credit_note_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                             <!-- Car Invoice -->
                             <ng-container *ngIf="basicTable['data']['car_invoice']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Car Invoice</td></tr>
                                <tr *ngFor="let data of basicTable['data']['car_invoice'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'ms-invoice')"
                                (click)="openViewMode(data.reference,'ms-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['car_invoice_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['car_invoice_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                             <!-- Car CreditNote -->
                             <ng-container *ngIf="basicTable['data']['car_credit_note']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Car Credit Note</td></tr>
                                <tr *ngFor="let data of basicTable['data']['car_credit_note'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'ms-invoice')"
                                (click)="openViewMode(data.reference,'ms-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['car_credit_note_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['car_credit_note_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                             <!-- Bus Invoice -->
                             <ng-container *ngIf="basicTable['data']['bus_invoice']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Bus Invoice</td></tr>
                                <tr *ngFor="let data of basicTable['data']['bus_invoice'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'ms-invoice')"
                                (click)="openViewMode(data.reference,'ms-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['bus_invoice_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['bus_invoice_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                             <!-- Bus Credit Note -->
                             <ng-container *ngIf="basicTable['data']['bus_credit_note']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Bus Credit Note</td></tr>
                                <tr *ngFor="let data of basicTable['data']['bus_credit_note'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'ms-invoice')"
                                (click)="openViewMode(data.reference,'ms-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['bus_credit_note_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['bus_credit_note_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                            <!-- Gst Invoice -->
                            <ng-container *ngIf="basicTable['data']['gst_invoice']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Gst Invoice</td></tr>
                                <tr *ngFor="let data of basicTable['data']['gst_invoice'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'gst-invoice')"
                                (click)="openViewMode(data.reference,'gst-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['gst_invoice_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['gst_invoice_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                            <!-- Gst CreditNote -->
                            <ng-container *ngIf="basicTable['data']['gst_credit_note']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Gst Credit Note</td></tr>
                                <tr *ngFor="let data of basicTable['data']['gst_credit_note'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'gst-invoice')"
                                (click)="openViewMode(data.reference,'gst-invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['gst_credit_note_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['gst_credit_note_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                             <!-- Tour Invoices -->
                             <ng-container *ngIf="basicTable['data']['tour_invoice']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Tour Invoice</td></tr>
                                <tr *ngFor="let data of basicTable['data']['tour_invoice'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'tour')"
                                (click)="openViewMode(data.reference,'tour')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['tour_invoice_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['tour_invoice_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                            <!-- Tour Credit Note -->
                            <ng-container *ngIf="basicTable['data']['tour_credit_note']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Tour Credit Note</td></tr>
                                <tr *ngFor="let data of basicTable['data']['tour_credit_note'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'tour')"
                                (click)="openViewMode(data.reference,'tour')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['tour_credit_note_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['tour_credit_note_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                             <!-- Event Invoices -->
                             <ng-container *ngIf="basicTable['data']['event_invoice']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Event Invoice</td></tr>
                                <tr *ngFor="let data of basicTable['data']['event_invoice'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'event')"
                                (click)="openViewMode(data.reference,'event')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['event_invoice_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['event_invoice_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                            <!-- Event Credit Note -->
                            <ng-container *ngIf="basicTable['data']['event_credit_note']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Event Credit Note</td></tr>
                                <tr *ngFor="let data of basicTable['data']['event_credit_note'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'event')"
                                (click)="openViewMode(data.reference,'event')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['event_credit_note_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['event_credit_note_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                             <!-- Other Invoices -->
                             <ng-container *ngIf="basicTable['data']['misc_invoice']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Other Invoice</td></tr>
                                <tr *ngFor="let data of basicTable['data']['misc_invoice'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'misc_invoice')"
                                (click)="openViewMode(data.reference,'misc_invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['misc_invoice_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['misc_invoice_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                            <!-- Other Credit Note -->
                            <ng-container *ngIf="basicTable['data']['misc_credit_note']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Other Credit Note</td></tr>
                                <tr *ngFor="let data of basicTable['data']['misc_credit_note'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'misc_credit_note')"
                                (click)="openViewMode(data.reference,'misc_credit_note')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['misc_credit_note_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['misc_credit_note_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngIf="basicTable['data']['consolidated_invoice']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Consolidated Invoice</td></tr>
                                <tr *ngFor="let data of basicTable['data']['consolidated_invoice'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'consolidated_invoice')"
                                (click)="openViewMode(data.reference,'consolidated_invoice')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['consolidated_invoice_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['consolidated_invoice_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                            <!-- Receipt -->
                            <ng-container *ngIf="basicTable['data']['receipt']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Receipt</td></tr>
                                <tr *ngFor="let data of basicTable['data']['receipt'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'receipt')"
                                (click)="openViewMode(data.reference,'receipt')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['receipt_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['receipt_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

                             <!-- Payment -->
                             <ng-container *ngIf="basicTable['data']['payment']">
                                <tr><td colspan="19" class="text-bold undl text-black text-underline">Payment</td></tr>
                                <tr *ngFor="let data of basicTable['data']['payment'] ; let i = index;"
                                (keyup.enter)="openViewMode(data.reference,'payment')"
                                (click)="openViewMode(data.reference,'payment')">
                                    <ng-container 
                                        *ngTemplateOutlet="dataTemplate;context: {'data':data,index:i}">
                                    </ng-container>
                                </tr>
                                <tr *ngIf="basicTable['data']['payment_total']">
                                    <ng-container 
                                            *ngTemplateOutlet="totalTemplate;context: {'data':basicTable['data']['payment_total']}">
                                    </ng-container>
                                </tr>
                                <tr >
                                    <td colspan="19">&nbsp;</td>
                                </tr>
                            </ng-container>

              </tbody>

              <tfoot style="background: #fafafa!important;">
                <tr >
                  <ng-template #dataTemplate let-data="data" let-index="index">
                    <td class="report-date ">{{data?.issue_date | date : 'dd/MM/yyyy'}}</td>
                    <td class="report-no">{{data?.reference}}</td>
                    <td class="report-credit">{{data?.pax_name}}</td>
                    <td class="report-fare-code">{{data?.airline_code}}</td>
                    <td class="left report-fare-value">{{data?.ticket_no}}</td>
                    <td class="report-sector">{{data?.sector}}</td>

                    <ng-container *ngIf="queryParams.service_type == 'hotel' else travelDateTemp"> 
                        <td class="travel-date">{{data?.service_started | date : 'dd/MM/yyyy'}}</td>
                        <td class="travel-date">{{data?.service_ended | date : 'dd/MM/yyyy'}}</td>
                    </ng-container>

                    <ng-template #travelDateTemp>
                        <td class="travel-date">{{data?.travel_date | date : 'dd/MM/yyyy'}}</td>
                    </ng-template>

                    <td class="text-right report-raf">
                        <ng-container *ngIf="data?.base > 0 ;else zeroVal">
                            {{data?.base  | number :'1.2-2'}}
                        </ng-container>
                    </td>
                    
                    <td class="text-right report-rate">
                        <ng-container *ngIf="data?.ot > 0 ;else zeroVal">
                            {{data?.ot  | number :'1.2-2'}}
                        </ng-container>
                    </td>

                    <ng-container *ngIf="queryParams.service_type == 'flight' ">
                        <td class="text-right report-yq-tax">
                            <ng-container *ngIf="data?.yq > 0 ;else zeroVal">
                                {{data?.yq  | number :'1.2-2'}}
                            </ng-container>
                        </td>
                        <td class="text-right report-amt">
                            <ng-container *ngIf="data?.yr > 0 ;else zeroVal">
                                {{data?.yr  | number :'1.2-2'}}
                            </ng-container>
                        </td>
                        <td class="text-right report-k3-tax">
                            <ng-container *ngIf="data?.k3 > 0 ;else zeroVal">
                                {{data?.k3  | number :'1.2-2'}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <td class="text-right report-payable">
                        <ng-container *ngIf="data?.client_service_charge > 0 ;else zeroVal">
                            {{data?.client_service_charge  | number :'1.2-2'}}
                        </ng-container>
                    </td>
                    <td class="text-right report-sgst">
                        <ng-container *ngIf="data?.client_sgst > 0 ;else zeroVal">
                            {{data?.client_sgst  | number :'1.2-2'}}
                        </ng-container>
                    </td>
                    <td class="text-right report-cgst" style="font-weight: normal!important; color: rgba(0, 0, 0, 0.65)!important;">
                        <ng-container *ngIf="data?.client_cgst > 0 ;else zeroVal">
                            {{data?.client_cgst  | number :'1.2-2'}}
                        </ng-container></td>
                    <td class="text-right report-igst">
                        <ng-container *ngIf="data?.client_igst > 0 ;else zeroVal">
                            {{data?.client_igst  | number :'1.2-2'}}
                        </ng-container>
                    </td>

                    <ng-container *ngIf="queryParams.service_type == 'hotel' || queryParams.service_type == 'car'">
                        <td class="text-right report-sgst">
                            <ng-container *ngIf="data?.client_sgst_2 > 0 ;else zeroVal">
                                {{data?.client_sgst_2  | number :'1.2-2'}}
                            </ng-container>
                        </td>
                        <td class="text-right report-cgst" style="font-weight: normal!important; color: rgba(0, 0, 0, 0.65)!important;">
                            <ng-container *ngIf="data?.client_cgst_2 > 0 ;else zeroVal">
                                {{data?.client_cgst_2  | number :'1.2-2'}}
                            </ng-container>
                        </td>
                        <td class="text-right report-igst">
                            <ng-container *ngIf="data?.client_igst_2 > 0 ;else zeroVal">
                                {{data?.client_igst_2  | number :'1.2-2'}}
                            </ng-container>
                        </td>

                        <td class="text-right report-sgst">
                            <ng-container *ngIf="data?.client_sgst_3 > 0 ;else zeroVal">
                                {{data?.client_sgst_3  | number :'1.2-2'}}
                            </ng-container>
                        </td>
                        <td class="text-right report-cgst" style="font-weight: normal!important; color: rgba(0, 0, 0, 0.65)!important;">
                            <ng-container *ngIf="data?.client_cgst_3 > 0 ;else zeroVal">
                                {{data?.client_cgst_3  | number :'1.2-2'}}
                            </ng-container></td>
                        <td class="text-right report-igst">
                            <ng-container *ngIf="data?.client_igst_3 > 0 ;else zeroVal">
                                {{data?.client_igst_3  | number :'1.2-2'}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <td class="text-right report-xxl">
                        <ng-container *ngIf="data?.client_xxl > 0 ;else zeroVal">
                            {{data?.client_xxl  | number :'1.2-2'}}
                        </ng-container>
                    </td>
                    <td class="text-right report-tds-amt">
                        <ng-container *ngIf="data?.c_tds_amount > 0 ;else zeroVal">
                            {{data?.c_tds_amount  | number :'1.2-2'}}
                        </ng-container>
                    </td>
                    <td class="text-right  report-net-amt">{{data && data.client_net_amount && data.client_net_amount != 'NaN' ? (data.client_net_amount | number :'1.2-2') : 0 }}</td> 
                    </ng-template>

                    <ng-template #totalTemplate let-data="data" let-index="index">
                        <td class="report-date text-black text-bold">SubTotal: </td>
                        <td class="report-no"></td>
                        <td class="report-credit"></td>
                        <td class="report-fare-code"></td>
                        <td class="report-fare-value"></td>
                        <td class="report-sector"></td>
                        <td class="travel-date"></td>

                        <td *ngIf="queryParams.service_type == 'hotel'"></td>

                        <td class="text-right sub-total-col amt-total report-raf border-right" >{{data?.base | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total report-rate border-right">{{data?.ot  | number :'1.2-2'}}</td>

                        <ng-container *ngIf="queryParams.service_type == 'flight'">
                            <td class="text-right sub-total-col amt-total report-yq-tax border-right">{{data?.yq  | number :'1.2-2'}}</td>
                            <td class="text-right sub-total-col amt-total report-amt border-right">{{data?.yr  | number :'1.2-2'}}</td>
                            <td class="text-right sub-total-col amt-total report-k3-tax border-right">{{data?.k3  | number :'1.2-2'}}</td>
                        </ng-container>
                        
                        <td class="text-right sub-total-col amt-total report-payable border-right">{{data?.client_service_charge  | number :'1.2-2'}}</td>

                        <td class="text-right sub-total-col amt-total report-sgst border-right">{{data?.client_sgst  | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total report-cgst border-right">{{data?.client_cgst  | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total report-igst border-right">{{data?.client_igst  | number :'1.2-2'}}</td>

                        <ng-container *ngIf="queryParams.service_type == 'hotel' || queryParams.service_type == 'car'">
                            <td class="text-right sub-total-col amt-total report-sgst border-right">{{data?.client_sgst_2  | number :'1.2-2'}}</td>
                            <td class="text-right sub-total-col amt-total report-cgst border-right">{{data?.client_cgst_2  | number :'1.2-2'}}</td>
                            <td class="text-right sub-total-col amt-total report-igst border-right">{{data?.client_igst_2  | number :'1.2-2'}}</td>

                            <td class="text-right sub-total-col amt-total report-sgst border-right">{{data?.client_sgst_3  | number :'1.2-2'}}</td>
                            <td class="text-right sub-total-col amt-total report-cgst border-right">{{data?.client_cgst_3  | number :'1.2-2'}}</td>
                            <td class="text-right sub-total-col amt-total report-igst border-right">{{data?.client_igst_3  | number :'1.2-2'}}</td>
                        </ng-container>

                        <td class="text-right sub-total-col amt-total report-xxl border-right">{{data?.client_xxl | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total report-tds-amt border-right">{{data?.c_tds_amount | number :'1.2-2'}}</td>
                        <td class="text-right sub-total-col amt-total report-net-amt border-right">{{data && data.client_net_amount && data.client_net_amount != 'NaN' ? (data.client_net_amount | number :'1.2-2') : 0 }}</td>

                      </ng-template>

                      <ng-template #zeroVal> <span>0.00</span> </ng-template>
                      
                </tr>
              </tfoot>

            </nz-table>
        </div>
    </div>

    </div>
</div>