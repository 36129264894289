import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { SupplierComponent } from './supplier.component';
import { UpdateSupplierComponent } from './update-supplier/update-supplier.component';
import { SupplierRoutingModule } from './supplier-routing.module';
import { ViewSupplierComponent } from './view-supplier/view-supplier.component';
import { SupplierComponent } from './supplier.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreateSupplierComponent } from './create-supplier/create-supplier.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CommonServiceService } from 'src/app/shared/common-service.service';
@NgModule({
  declarations: [SupplierComponent, UpdateSupplierComponent, ViewSupplierComponent, CreateSupplierComponent],
  imports: [
    CommonModule,
    SharedModule,
    SupplierRoutingModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [UpdateSupplierComponent]
})
export class SupplierModule { }


