<div class="ledger-container mb-20 ">
    <div class="row tab-nav pl-0 pr-0">
        <div class="col-sm-6 ">
          <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
              <li  
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> Consolidation</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li  
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> {{consolidationType ? consolidationType : 'Invoice'}} </li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li class="text-grey text-capitalize cursor text-primary transition"> Search </li>
          </ul>
      </div>
    </div>
    <div class="consolidate-form-wrapper" style="max-width: 700;padding: 30px;">
		<form [formGroup]="consolidateInvoiceForm" class="consolidateInvoiceForm">
			<div class="flexbox aff-info">
			  <div class="flexbox aff-info-inner">

				<div class="flexbox ">
				  <div class="input-fiel">
					<input class="input-ui" searchtabindex="1" formControlName="start_date" id="from_date" type="date"
						min="{{minDate}}" max="{{maxDate}}"
						[ngClass]="{'error-box': !consolidateInvoiceForm.controls.end_date.valid}"
						/>
					<label for="from_date">Starting Date</label>
		  
				  </div>
				  <div class="input-fiel mr-0">
					<input class="input-ui" searchtabindex="2" formControlName="end_date" id="to_date" type="date"
						min="{{minDate}}" max="{{maxDate}}"
						[ngClass]="{'error-box': !consolidateInvoiceForm.controls.end_date.valid}"
						/>
					<label for="to_date">Ending Date</label>
				  </div>
				</div>

				<div class="flexbox ">
				    <div class="mt-20 input-fiel">
					<div class="select-field  text-input-fiel">
						<label class="ngauto-label">Memo Client</label>
						<div class="ng-autocomplete">
							<ng-autocomplete [data]="clientArr" #clientAc (inputCleared)="selectAccount($event,'selectedMemoClient')"
							[searchKeyword]="keyword" (inputChanged)="getClient($event,'selectedMemoClient',false)"
							[itemTemplate]="itemTemplate" formControlName="selectedMemoClientName"
							(selected)="selectAccount($event,'selectedMemoClient')" [notFoundTemplate]="notFoundTemplate" >
							</ng-autocomplete>
							<ng-template #itemTemplate let-item>
							<a innerHTML='<span class="colr-1">{{item.name}}
							({{item.account_code}})</span><br />
							<span class="colr-2">{{item.primary_email}}</span><br />'>
							</a>
							</ng-template>
							<ng-template #notFoundTemplate let-notFound>
							<div [innerHTML]="notFound"></div>
							</ng-template>
						</div>
					</div>
					</div>
					<div class="mt-20  mr-0 input-fiel">
					<div class="select-field  text-input-fiel">
					<label class="ngauto-label">Main Client</label>
					<div class="ng-autocomplete">
					<ng-autocomplete [data]="clientArr" #clientAc (inputCleared)="selectAccount($event,'selectedMainClient')"
					[searchKeyword]="keyword" (inputChanged)="getClient($event,'selectedMainClient',false)"
					[itemTemplate]="itemTemplate" formControlName="selectedMainClientName"
					(selected)="selectAccount($event,'selectedMainClient')" [notFoundTemplate]="notFoundTemplate" >

					</ng-autocomplete>
					<ng-template #itemTemplate let-item>
					<a innerHTML='<span class="colr-1">{{item.name}}
					({{item.account_code}})</span><br />
					<span class="colr-2">{{item.primary_email}}</span><br />'>
					</a>
					</ng-template>
					<ng-template #notFoundTemplate let-notFound>
					<div [innerHTML]="notFound"></div>
					</ng-template>
					</div>
					</div>
					</div>
				</div>

				<div class="flexBox d-flex mt-1">
					<div class="text-input-field multiselect-dropdown-container">
						<label>Transactions</label>
						<ng-multiselect-dropdown  [placeholder]="'Select transactions'"
							[settings]="dropdownSettings" formControlName="selected_transactions"
							[data]="attachmentDropdownList"
							(onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
						</ng-multiselect-dropdown>

					</div>

					<!-- <div class="input-fiel">
					  <label class="radio-label" for="flightCheckBox">Flight</label>
					  <input class="radio-ui" id="flightCheckBox" type="checkbox" name="include_flight" value="subgroup"
						 formControlName="include_flight" />
					</div>
					<div class="input-fiel">
						<label class="radio-label" for="hotelCheckBox">Hotel</label>
						<input class="radio-ui" id="hotelCheckBox" type="checkbox" name="include_hotel" value="subgroup"
						   formControlName="include_hotel" />
					  </div>
					  <div class="input-fiel">
						<label class="radio-label" for="railCheckBox">Rail</label>
						<input class="radio-ui" id="railCheckBox" type="checkbox" name="include_rail" value="subgroup"
						   formControlName="include_rail" />
					  </div>
					  <div class="input-fiel">
						<label class="radio-label" for="insuranceCheckBox">Insurance</label>
						<input class="radio-ui" id="insuranceCheckBox" type="checkbox" name="include_insurance" value="subgroup"
						   formControlName="include_insurance" />
					  </div>
					  <div class="input-fiel">
						<label class="radio-label" for="CheckBox">Visa</label>
						<input class="radio-ui" id="visaCheckBox" type="checkbox" name="include_visa" value="subgroup"
						   formControlName="include_visa" />
					  </div>
					  <div class="input-fiel">
						<label class="radio-label" for="carCheckBox">Car</label>
						<input class="radio-ui" id="carCheckBox" type="checkbox" name="include_car" value="subgroup"
						   formControlName="include_car" />
					  </div>
					  <div class="input-fiel">
						<label class="radio-label" for="busCheckBox">Bus</label>
						<input class="radio-ui" id="busCheckBox" type="checkbox" name="include_bus" value="subgroup"
						   formControlName="include_bus" />
					  </div>
					  <div class="input-fiel">
						<label class="radio-label" for="tourCheckBox">Tour</label>
						<input class="radio-ui" id="tourCheckBox" type="checkbox" name="include_tour" value="subgroup"
						   formControlName="include_tour" />
					  </div> -->
				</div>
				
				<div class="d-flex mt-5 justify-content-center">
				  <div class="input-fiel">
					<button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
					  searchtabindex="3" #searchButton (keyup.enter)="clickSearchButton()"
					  [disabled]="consolidateInvoiceForm.invalid"
					  (click)="search(consolidateInvoiceForm.value)">
					  Search</button>
					<button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
				  </div>
				</div>

			  </div>
			</div>
		</form>
    </div>
</div>