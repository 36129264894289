import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContraComponent } from './contra.component';
import { CreateContraComponent } from './create-contra/create-contra.component';
import { ViewContraComponent } from './view-contra/view-contra.component';
import { AddEntryContraComponent } from './add-entry-contra/add-entry-contra.component';
// import { PrintContraComponent } from './print-contra/print-contra.component';


const routes: Routes = [

	{
		path: '', component: ContraComponent,
		children: [
			{
				path: '',
				redirectTo: 'create',
				pathMatch: 'full'
			},
			 { path: 'create', component: CreateContraComponent },
			 { path: 'view', component: ViewContraComponent },
			{ path: 'add-entry', component: AddEntryContraComponent },
			{ path: 'update/:id', component: AddEntryContraComponent },
			// { path: 'print/:id', component: PrintContraComponent },

		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ContraRoutingModule { }
