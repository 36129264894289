import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment'
import { CommonServiceService } from '../common-service.service';
import { Body } from '@angular/http/src/body';
@Injectable({
	providedIn: 'root'
})
export class HttpInterceptorService {

	constructor(
		public commonService: CommonServiceService
	) {	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let access_token = 	localStorage.getItem("okount_token")
		let current_financial_year = this.commonService.getFinancialSession(moment()).toString()
		if(sessionStorage.getItem("current_financial_year") && JSON.parse(sessionStorage.getItem("current_financial_year"))['start_date']){
			current_financial_year = `${this.commonService.getFinancialSession(JSON.parse(sessionStorage.getItem("current_financial_year"))['start_date'])}`
		}
		if(access_token && req.params && req.params['map'] && req.params['map'].get('start_date') && req.params['map'].get('ignore_financial_check') != 'true'){
			let filterDate = req.params['map'].get('start_date')[0]
			let filterFinancialYear = this.commonService.getFinancialSession(filterDate)
			if(current_financial_year != filterFinancialYear){
				throw new HttpErrorResponse({
					error: new Error('Start date should be within selected financial year'),
					headers: req.headers,
					status: 400,
					statusText: 'financial_year_validation'
				});
			}
		}
		if(access_token && req.params && req.params['map'] && req.params['map'].get('end_date')){
			let filterDate = req.params['map'].get('end_date')[0]
			let filterFinancialYear = this.commonService.getFinancialSession(filterDate)
			if(current_financial_year != filterFinancialYear){
				throw new HttpErrorResponse({
					error: new Error('End date should be within selected financial year'),
					headers: req.headers,
					status: 400,
					statusText: 'financial_year_validation'
				});
			}
		}
		let  headers = new HttpHeaders({});
		var authReq = req.clone({
			headers: headers.set('Content-Type', 'application/json')
				.append('Authorization', access_token ? access_token : '')
				.append('X-FINANCIAL-YEAR', current_financial_year ? current_financial_year : '')
		});
		if(req.url.includes('/reconciliation/api/')){
			headers = new HttpHeaders({});
			var authReq = req.clone({
				headers: headers.set('Content-Type', 'application/json')
					.append('Authorization', access_token ? access_token : '')
			});
		}
		// console.log("Sending request with new header now ...", authReq);
		return next.handle(authReq)
	}
}