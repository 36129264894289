import { Injectable } from '@angular/core';
import * as moment from 'moment'
import { FormBuilder, Validators ,FormArray} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { throwError, of, empty } from 'rxjs';
import { CommonServiceService } from 'src/app/shared/common-service.service';

@Injectable({
	providedIn: 'root'
})
export class PaymentV2Service {
	paymentForm : any
	paymentEntryForm : any
	showAddPaymentScreen: boolean = false

	paymentEntries : any = []
	isEdit: boolean = false;
	accountList: any;
	ledgerList: any;
    paymentArray:FormArray
	constructor(
		private fb: FormBuilder,
		private http: Http,
		private httpClient: HttpClient
	) {

		this.initialiseFormData({})
	 }


     initialiseFormData(data){
		this.paymentForm = this.fb.group({
			"payment_code": [''],
			"payment_date": ['', Validators.required],
			"account_code_from" : [''],
			"account_code_from_name" : [''],
			"narration_5" : [''],
            paymentArray: this.fb.array([])
		})
		this.paymentArray = this.paymentForm.get("paymentArray") as FormArray
	}
     addRow(data){
        this.paymentArray.push(this.buildPayment(data))
    } 

    buildPayment(data){

        return this.fb.group({
            "payment_id":[data.hasOwnProperty('id')?data.id:''],
			"account_code_from": [data.hasOwnProperty("account_code_from")?data.account_code_from:''],
			"account_code_from_name": [data.hasOwnProperty("account_code_from_name")?data.account_code_from_name:''],
			"account_code_from_type": [data.hasOwnProperty("account_code_from_type")?data.account_code_from_type:'general'],
			"account_code_to": [data.hasOwnProperty("account_code_to")?data.account_code_to:''],
			"account_code_to_name": [data.hasOwnProperty("account_code_to_name")?data.account_code_to_name:''],
			"account_code_to_type": [data.hasOwnProperty("account_code_to_type")?data.account_code_to_type:'supplier'],
			"reference_type": [data.hasOwnProperty("reference_type")?data.reference_type:''],
			"reference_no": [data.hasOwnProperty("reference_no")?data.reference_no:''],
			"reference_date": [data.hasOwnProperty("reference_date")?moment(data.reference_date).format("YYYY-MM-DD"):null],
			"cheque_bank_name": [data.hasOwnProperty("cheque_bank_name")?data.cheque_bank_name:''], 
			"amount": [data.hasOwnProperty("amount")?data.amount:0],
			"narration_1": [data.hasOwnProperty("narration_1")?data.narration_1:''],
			"narration_2": [data.hasOwnProperty("narration_2")?data.narration_2:''],
			"narration_3": [data.hasOwnProperty("narration_3")?data.narration_3:''],
			"narration_4": [data.hasOwnProperty("narration_4")?data.narration_4:''],
			"narration_5": [data.hasOwnProperty("narration_5")?data.narration_5:''],
			"remark_1": [data.hasOwnProperty("remark_1")?data.remark_1:''],
			"remark_2": [data.hasOwnProperty("remark_2")?data.remark_2:''],
			"remark_3": [data.hasOwnProperty("remark_3")?data.remark_3:''],
			"remark_4": [data.hasOwnProperty("remark_4")?data.remark_4:''],
			"remark_5": [data.hasOwnProperty("remark_5")?data.remark_5:''],
			"sub_ledger_code_1_type": [data.hasOwnProperty("sub_ledger_code_1_type")?data.sub_ledger_code_1_type:''],
			"sub_ledger_code_2_type": [data.hasOwnProperty("sub_ledger_code_2_type")?data.sub_ledger_code_2_type:''],
			"sub_ledger_code_3_type": [data.hasOwnProperty("sub_ledger_code_3_type")?data.sub_ledger_code_3_type:''],
			"sub_ledger_code_4_type": [data.hasOwnProperty("sub_ledger_code_4_type")?data.sub_ledger_code_4_type:''],
			"sub_ledger_code_5_type": [data.hasOwnProperty("sub_ledger_code_5_type")?data.sub_ledger_code_5_type:''],
			"sub_ledger_code_1": [data.hasOwnProperty("sub_ledger_code_1")?data.sub_ledger_code_1:''],
			"sub_ledger_code_2": [data.hasOwnProperty("sub_ledger_code_2")?data.sub_ledger_code_2:''],
			"sub_ledger_code_3": [data.hasOwnProperty("sub_ledger_code_3")?data.sub_ledger_code_3:''],
			"sub_ledger_code_4": [data.hasOwnProperty("sub_ledger_code_4")?data.sub_ledger_code_4:''],
			"sub_ledger_code_5": [data.hasOwnProperty("sub_ledger_code_5")?data.sub_ledger_code_5:''],
			"sub_ledger_code_1_name": [data.hasOwnProperty("sub_ledger_code_1_name")?data.sub_ledger_code_1_name:''],
			"sub_ledger_code_2_name": [data.hasOwnProperty("sub_ledger_code_2_name")?data.sub_ledger_code_2_name:''],
			"sub_ledger_code_3_name": [data.hasOwnProperty("sub_ledger_code_3_name")?data.sub_ledger_code_3_name:''],
			"sub_ledger_code_4_name": [data.hasOwnProperty("sub_ledger_code_4_name")?data.sub_ledger_code_4_name:''],
			"sub_ledger_code_5_name": [data.hasOwnProperty("sub_ledger_code_5_name")?data.sub_ledger_code_5_name:''],
		  })
    } 

	postMethod(url,data){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.post(`${environment.okountUrl}/${url}`,data, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}
	putMethod(url,data){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.put(`${environment.okountUrl}/${url}`,data, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}
	deleteMethod(url){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.delete(`${environment.okountUrl}/${url}`, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}
	getMethod(url){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.get(`${environment.okountUrl}/${url}`, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}

	searchAccount(url) {
		let token = localStorage.getItem('token');
		const headers = new HttpHeaders({
			'Authorization': token
		});
		return this.httpClient.get(`${environment.okountUrl}/${url}`)
			.pipe(map(res => {
				// console.log(res);
				return res;
				// console.log('Res data', res);
			}),
				catchError((err) => {
					return throwError(err);
				}))
	}

}
