import { Component, OnInit,ElementRef, ViewChild, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from "moment";
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ReceiptV2Service } from '../../receipt/services/receiptv2.service';
@Component({
	selector: 'app-create-adm-acm',
	templateUrl: './create-adm-acm.component.html',
	styleUrls: ['./create-adm-acm.component.scss'],
	providers: [ReceiptV2Service]
})
export class CreateAdmAcmComponent implements OnInit {
	@Input() data;
	formData: any;
	sendEmailForm: FormGroup;
	accountOneList: any;
	keyword = 'name';
	accountAutoComplete: boolean = false;

	isSuccess: boolean = false;
	showMessage: boolean = false;
	alertType = ''
	message: string = '';
	transaction_number = '';


	transactionMode = "create"
	transactionNumber: any;
	submitted: boolean = false;
	@ViewChild('account_one',{static : true}) account_one;
	@ViewChild('account_two',{static : true}) account_two;
	@ViewChild('confirmDeleteModal', { static: true }) confirmDeleteModal: ElementRef;
	@ViewChild('closeDeleteModal', { static: true }) closeDeleteModal: ElementRef;
	@ViewChild('confirmUpdateModal', { static: true }) confirmUpdateModal: ElementRef
	@ViewChild('closeUpdateModal', { static: true }) closeUpdateModal: ElementRef
	@ViewChild('confirmSaveModal', { static: true }) confirmSaveModal: ElementRef
	@ViewChild('closeSaveModal', { static: true }) closeSaveModal: ElementRef
	@ViewChild("openSuccessModal", { static: true }) openSuccessModal: ElementRef;
	@ViewChild('openEmailModal') openEmailModal;
	@ViewChild('closeSendEmailModal') closebutton;
	queryParams: any;
	shortcuts: ShortcutInput[] = [];
	currentOpenedModal = ''
	minDate;
  	maxDate;
	admAcdData;
	hasAccessUpdateCode: any;
	hasAccessDeleteCode: any;
	newAdmAcm = true;
	copyELementTitle = 'Click to Copy'

	constructor(
		private fb: FormBuilder,
		private receiptService: ReceiptV2Service,
		private route: ActivatedRoute,
		private router: Router,
		private spinner: NgxSpinnerService,
		public commonService: CommonServiceService
	) {

		this.formData = this.fb.group({
			"transaction_type": ['ADM', Validators.required],
			"transaction_date": [moment().format('YYYY-MM-DD'), Validators.required],

			"account_1_type": ['airline', Validators.required],
			"account_1_code": ['', Validators.required],
			"account_1_code_value": ['', Validators.required],
			"account_1_subledger_type": [''],
			"account_1_subledger_code": [''],
			"account_1_subledger_code_value": [''],

			"account_2_type": ['general', Validators.required],
			"account_2_code": ['', Validators.required],
			"account_2_code_value": ['', Validators.required],
			"account_2_subledger_type": [''],
			"account_2_subledger_code": [''],
			"account_2_subledger_code_value": [''],

			"client_type": [''],
			"client_code": [''],
			"client_code_value": [''],
			"client_subledger_type": [''],
			"client_subledger_code": [''],
			"client_subledger_code_value": [''],

			"adm_acm_number": ['',Validators.required],
			"reference_number": [''],
			"ticket_no": [''],
			"type": ['DOM', Validators.required],

			"narration_1": [''],
			"narration_2": [''],
			"narration_3": [''],
			"narration_4": [''],
			"narration_5": [''],

			"supplier_amount": ['', Validators.required],
			"client_amount": [''],

		})

		this.sendEmailForm = this.fb.group({
			toEmails: ["",Validators.required],
			bccEmails: [''],
			ccEmails:[''],
			subject:[''],
			body:['']
		})

		this.route.paramMap.subscribe(params => {
			this.transactionMode = params['params'].mode ? params['params'].mode : 'create'
			this.transactionNumber = params['params'].transactionNumber ? params['params'].transactionNumber : ''
		});

	}

	async ngOnInit() {		
		if(this.data) {
			this.transactionNumber = this.data.adm_acm_number,
			this.transactionMode = this.data.mode
		}
		if (this.transactionMode == 'view' && this.transactionNumber != "") {
			try {
				this.spinner.show()
				this.addControl('adm_acm_code', '', false);
				this.getData()
			} catch (error) {
				this.spinner.hide()
			}
		}
		
		let queryParams = this.route.queryParams['value']
		if(queryParams){
			this.queryParams = queryParams
		}
		this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
		try {
			let empConfiguration = this.commonService.employeeConfiguration;
				if(!empConfiguration || empConfiguration.length == 0){
					let loggedInUser = JSON.parse(localStorage.okountUserInfo);
					empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
				}
				let config = this.commonService.getConfigurationByKey('adm_acm')
				if(config){
					let dateConfig = config['value'].filter(data => data.key == `minimum_${this.transactionMode == 'view' ? 'update' : 'creation'}_date`)
					let daysConfig = config['value'].filter(data => data.key == `minimum_${this.transactionMode == 'view' ? 'update' : 'creation'}_days`)
					let dateWithMinDate = moment(dateConfig ? dateConfig[0].value : '')
					let dateWithMinDays = dateConfig && daysConfig[0].value ? moment().subtract(Number(daysConfig[0].value), 'days') : dateWithMinDate
					if(dateWithMinDays && dateWithMinDays.isBefore(dateWithMinDate)){
						this.minDate = dateWithMinDate.format('YYYY-MM-DD')
					}else{
						this.minDate = dateWithMinDays.format('YYYY-MM-DD')
					}
				}
		} catch (error) {
			
		}

		let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}

		this.maxDate = moment().format('YYYY-MM-DD')
		this.formData.controls.transaction_date.setValidators([Validators.required,this.validateDate()])
		this.formData.controls.transaction_date.updateValueAndValidity()

		if(this.transactionMode == 'view' && this.transactionNumber != ''){
			if(this.transactionNumber.includes('AC')) {
				this.hasAccessUpdateCode = 'F0002063'
				this.hasAccessDeleteCode = 'F0002064'
			}else {
				this.hasAccessUpdateCode = 'F0003063'
				this.hasAccessDeleteCode = 'F0003064'
			}
	   }
	}


	validateDate(){
		return (control: AbstractControl) => {
			let valid = true
			if(this){
				if (moment(control.value).isBefore(this.minDate) || moment(control.value).isAfter(this.maxDate)) {
					if(this.admAcdData &&  this.admAcdData.transaction_date && moment(control.value,'YYYY-MM-DD').isSame(moment(this.admAcdData.transaction_date,'YYYY-MM-DD'))){
						valid = true
					}else{
						valid = false
					}
				}
			}
			return !valid ? {jv_date: {value: control.value}} : null;
		};
	}

	ngAfterViewInit(){

		this.shortcuts.push(			
			{
				key: "alt + s",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Save Acm Adm Entry",
				description: "Save Acm Adm Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("save_acm_adm_btn")){

				document.getElementById("save_acm_adm_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + e",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Update Acm Adm Entry",
				description: "Update Acm Adm Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("modify_acm_adm_btn")){

				document.getElementById("modify_acm_adm_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + d",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Acm Adm Entry",
				description: "Delete Acm Adm Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("delete_acm_adm_btn")){

				document.getElementById("delete_acm_adm_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + j",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Open Journal ",
				description: "Open Journal",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("journal_acm_adm_btn")){
				document.getElementById("journal_acm_adm_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + y",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On Yes Button",
				description: "Clicked On Yes Button",
				
				command: (e)=>{
				console.log(e)	
				if(this.currentOpenedModal=='update' && document.getElementById("yes_update_adm_acm_confirm_modal_btn")){
				document.getElementById("yes_update_adm_acm_confirm_modal_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
				}else if(this.currentOpenedModal=='delete' && document.getElementById("yes_delete_adm_acm_confirm_modal_btn")){
				document.getElementById("yes_delete_adm_acm_confirm_modal_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
				}else if(this.currentOpenedModal=='save' && document.getElementById("yes_save_adm_acm_confirm_modal_btn")){
				document.getElementById("yes_save_adm_acm_confirm_modal_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
				}
				this.currentOpenedModal = ''
					},
					preventDefault: true
			},
			{
				key: "alt + n",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On No Button",
				description: "Clicked On No Button",
				command: (e)=>{
				if(this.currentOpenedModal=='update' && document.getElementById("no_update_adm_acm_confirm_modal_btn")){
					document.getElementById("no_update_adm_acm_confirm_modal_btn").click()
					setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
				}else if(this.currentOpenedModal=='delete' && document.getElementById("no_delete_adm_acm_confirm_modal_btn")){
					document.getElementById("no_delete_adm_acm_confirm_modal_btn").click()
					setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
				}else if(this.currentOpenedModal=='save' && document.getElementById("no_save_adm_acm_confirm_modal_btn")){
					document.getElementById("no_save_adm_acm_confirm_modal_btn").click()
					setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
				}
                this.currentOpenedModal = ''
					},
					preventDefault: true
          
			}
	  )
	}

	focusElement(element){
		if(element) element.focus()
	}

	focusAccountOne(): void {
		this.account_one.focus();
	}

	focusAccountTwo(): void {
		this.account_two.focus();
	}

	addControl(controlName, value, isRequired) {
		this.formData.addControl(controlName, new FormControl(value, isRequired ? Validators.required : null));
	}

	getData() {

		this.receiptService.getMethod(`adm-acm?admAcmCode=${this.transactionNumber}`).subscribe(async (res: any) => {
			console.log(res)
			if(res && res.length>0){	
				this.admAcdData = res[0]
		    	let formBindRes = await this.setFormData(res[0]);
			}else if(res.hasOwnProperty('data') && res.data.length>0){
				this.admAcdData = res.data[0]
				let formBindRes = await this.setFormData(res.data[0]);
			}
			this.spinner.hide()
		}, err => {
			this.spinner.hide()
		})

	}
	setFormData(data) {
		let dataObjKeys = Object.keys(data);
		for (const key in dataObjKeys) {
			if (Object.prototype.hasOwnProperty.call(dataObjKeys, key)) {
				const controlName = dataObjKeys[key];
				if (this.formData.controls[controlName]) {
					let value = data[controlName]
					if (controlName == 'transaction_date') {
						value = moment(data[controlName]).format('YYYY-MM-DD')

					}
					this.formData.controls[controlName].setValue(value)
				}
			}
		}
	}

	onChangeSearch(value, searchControl,withStatus) {
		try {
			let searchType = this.formData.value[searchControl]
			if (value.length > 2 && searchType != null && searchType != '') {
				let status = ''
				if(withStatus){
					status = '&status=active'
				}
				this.receiptService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`).subscribe(res => {
					this.accountOneList = res;
				});
			}
		} catch (error) {

		}
	}
	clearValue(formControlName) {
		this.formData.controls[formControlName].setValue("")
		if (formControlName == 'account_1_code') {
			this.clearValue('account_1_code_value')
			this.clearValue('account_1_subledger_code')
			this.clearValue('account_1_subledger_code_value')
		}
		if (formControlName == 'account_2_code') {
			this.clearValue('account_2_code_value')
			this.clearValue('account_2_subledger_code')
			this.clearValue('account_2_subledger_code_value')
		}
		if (formControlName == 'client_code') {
			this.clearValue('client_code_value')
			this.clearValue('client_subledger_code')
			this.clearValue('client_subledger_code_value')
		}
	}

	selectAccount(value, formControlName) {
		try {
			this.formData.controls[formControlName].setValue(value.account_code)
			this.accountOneList = []
		} catch (error) {

		}
	}


	submit(value) {

		if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.formData.controls.transaction_date.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to create the transaction.'
				return false;
			}
		}
		
		this.closeSaveConfirmModal()
		this.spinner.show()
		let userInfo = JSON.parse(localStorage.okountUserInfo)
		value["last_updated_by"] = userInfo.accountcode
		value["created_by"] = userInfo.accountcode
		let invalid = []
		const controls = this.formData.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		this.submitted = true;
		if (invalid.length > 0) {
			window.scroll(0, 0)
			this.showMessage = true;
			this.transaction_number = ''
			this.alertType = 'error-box';
			this.message = ' Please fill the mandatory input fields.'
			this.spinner.hide()
			let fcName = invalid[0]
			if (fcName == 'account_1_code') {
				fcName = 'account_1_code_value'
				this.focusAccountOne()
			}else if(fcName == 'account_2_code'){
				this.focusAccountTwo()
			}else{
				let invalidElement = document.querySelectorAll(`[formControlName="${fcName}"]`)[0] as HTMLElement;
				invalidElement.focus()

			}
			// document.querySelectorAll(`[formControlName="${invalid[0]}"]`)[0].
			return false;
		}
		if(this.queryParams){
			value["notify"] = this.queryParams && this.queryParams.notify == "true" ? true : false
			value["channel_name"] = this.queryParams && this.queryParams.channel ? this.queryParams.channel : null
		}

		this.receiptService.postMethod(`adm-acm`, value).subscribe(
			(res: any) => {
				let response = res
				this.isSuccess = true
				this.formData.reset()
				this.resetFormForNewTransaction()
				this.showMessage = false
				this.alertType = 'success-box'
				this.newAdmAcm = false
				this.openSuccessModal.nativeElement.click()
				this.message = `${response.data.transaction_type} created with ${response.data.transaction_type} no. `;
				this.transaction_number = response.data.adm_acm_code;
				this.spinner.hide()
			},
			err => {
				window.scroll(0, 0)
				this.showMessage = true;
				this.isSuccess = false
				this.transaction_number = ''
				this.alertType = 'error-box';
				this.message = err['error']['message']
				this.spinner.hide()
			}
		);
	}


	updateTransaction(value) {
		this.closeUpdateConfirmModal()
		console.log(value)
		// return false;
		this.spinner.show()
		let userInfo = JSON.parse(localStorage.okountUserInfo)
		value["last_updated_by"] = userInfo.accountcode
		this.submitted = true;
		let invalid = []
		const controls = this.formData.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		if (invalid.length > 0) {
			window.scroll(0, 0)
			this.showMessage = true;
			this.transaction_number = ''
			this.alertType = 'error-box';
			this.message = ' Please fill the mandtory input fields, ACM/ADM cannot be empty.'
			this.spinner.hide()
			let fcName = invalid[0]
			if (fcName == 'account_1_code') {
				fcName = 'account_1_code_value'
				this.focusAccountOne()
			}else if(fcName == 'account_2_code'){
				this.focusAccountTwo()
			}else{
				let invalidElement = document.querySelectorAll(`[formControlName="${fcName}"]`)[0] as HTMLElement;
				invalidElement.focus()

			}
			// document.querySelectorAll(`[formControlName="${invalid[0]}"]`)[0].
			return false;
		}

		this.receiptService.putMethod(`adm-acm/${value.adm_acm_code}`, value).subscribe(
			(res: any) => {
				let response = res
				console.log('Response : ', response)
				this.isSuccess = true
				this.showMessage = true
				this.alertType = 'success-box'
				this.message = `${response.data.transaction_type} update successfully`;
				this.transaction_number = response.data.adm_acm_code;
				this.resetValues()
				this.spinner.hide()
			},
			err => {
				window.scroll(0, 0)
				this.showMessage = true;
				this.isSuccess = false
				this.transaction_number = ''
				this.alertType = 'error-box';
				this.message = err['error']['message']
				this.spinner.hide()
			}
		);
	}

	deleteTransaction() {
		this.closeDeleteConfirmModal()
		this.receiptService.deleteMethod(`adm-acm/${this.transactionNumber}`).subscribe(
			(res: any) => {
				let response = res
				console.log('Response : ', response)
				this.isSuccess = true
				this.showMessage = true
				this.alertType = 'success-box'
				this.message = `${this.formData.value.transaction_type} deleted successfully`;
				this.spinner.hide()
				setTimeout(() => {
					this.router.navigate([`/transactions/search-adm-acm`])
				}, 2000);
			},
			err => {
				window.scroll(0, 0)
				this.showMessage = true;
				this.isSuccess = false
				this.transaction_number = ''
				this.alertType = 'error-box';
				this.message = err['error']['message']
				this.spinner.hide()
			}
		);
	}

	viewTransaction() {
		this.router.navigate([`/transactions/adm-acm/view/${this.transaction_number}`])
	}

	viewJournal() {
		let currentUrl = `transactions/adm-acm/${this.transactionMode}/${this.transactionNumber}`
		localStorage.setItem('previousInvoiceUrl', currentUrl)
		// this.router.navigate(['/transactions/view-invoice-journal', this.transactionNumber])
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/view-invoice-journal/${this.transactionNumber}`
		window.open(path,'_blank')		

	}

	openActivity() {
		let pathurl=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/adm-acm/${this.transactionNumber}`

		if(window.location.protocol != 'file:'){
			window.open(pathurl,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : pathurl
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}


	hideError() {
		this.showMessage = false
	}

	resetValues() {
		let invalid = []
		const controls = this.formData.controls;
		for (const name in controls) {
			if (controls[name].invalid && name != "passengerList") {
				invalid.push(name);
			}
		}
	}
	closeWindow() {
		window.close();
	}

	resetCurrentOpendModal(){
		this.currentOpenedModal = ''
	  }

	openDeleteConfirmModal() {
		if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.admAcdData.transaction_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.formData.controls.transaction_date.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to delete the transaction.'
				return false;
			}
		}

		this.closeOpenedModals('delete')
	    this.currentOpenedModal = 'delete'
		this.confirmDeleteModal.nativeElement.click()
	}
	closeDeleteConfirmModal() {
	this.currentOpenedModal = ''
		this.closeDeleteModal.nativeElement.click()
	}

	openUpdateConfirmModal(){
		if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.admAcdData.transaction_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.formData.controls.transaction_date.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to update the transaction.'
				return false;
			}
		}

		this.closeOpenedModals('update')
		this.currentOpenedModal = 'update'
		this.confirmUpdateModal.nativeElement.click()
	}
	closeUpdateConfirmModal(){
		this.currentOpenedModal = ''
		this.closeUpdateModal.nativeElement.click()
	}

	openSaveConfirmModal(){
		this.closeOpenedModals('save')
		this.currentOpenedModal = 'save'
		this.confirmSaveModal.nativeElement.click()
	}
	closeSaveConfirmModal(){
		this.currentOpenedModal = ''
		this.closeSaveModal.nativeElement.click()
	}

	// close all modal except current
	closeOpenedModals(modalName){
		if(modalName!='delete') this.closeDeleteConfirmModal()
		if(modalName!='save') this.closeSaveConfirmModal()
		if(modalName!='update') this.closeUpdateConfirmModal()
	}

	closeSuccesModal(){
		this.showMessage = false;
		this.isSuccess = false
		this.alertType = 'success-box';
		this.message = ``
		this.newAdmAcm = true
		this.openSuccessModal.nativeElement.click()
		this.openSuccessModal.nativeElement.click()
	}

	copytext(val: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.copyELementTitle = 'Copied'
	}


	resetFormForNewTransaction(){
		this.setFormData({
			'transaction_type' : 'ADM',
			'account_1_type': 'airline',
			'account_2_type': 'general',
			'type': 'DOM'
		})
	}

	openPrintPage() {
		let pathurl=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/adm-acm/print/${this.transactionNumber}`

		if(window.location.protocol != 'file:'){
			window.open(pathurl,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : pathurl
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}


	openSendEmailModal(){
		let primary_email = ''
		let secondary_email = ''
		let accounting_company = this.admAcdData && this.admAcdData.accounting_company ? this.admAcdData.accounting_company : null
		let subject = `${this.formData.value.transaction_type}. ${this.transactionNumber}`
		let body = `Dear Travel Partner,\n\nPlease find Attached ${this.formData.value.transaction_type} ${this.transactionNumber}`
		body+=`\n\nPlease do not hesitate to contact us if you have any query or concern about attached document.\n\nIt's been a pleasure to serve you.\n\nBest Regards\n\n${this.admAcdData?.accounting_company?.name}\nAccounts Team`
		if(accounting_company && accounting_company.telephone){
			body += `\nContact Us on ${accounting_company.telephone}`
		}
		if(this.admAcdData && this.admAcdData.client){
			primary_email = this.admAcdData.client.hasOwnProperty('primary_email')?this.admAcdData.client.primary_email:''
			secondary_email = this.admAcdData.client.hasOwnProperty('secondary_email')?this.admAcdData.client.secondary_email:''
		}
		this.sendEmailForm.controls.toEmails.setValue(primary_email)
		this.sendEmailForm.controls.bccEmails.setValue("")
		this.sendEmailForm.controls.ccEmails.setValue(secondary_email?.split(',').join('\n'))
		this.sendEmailForm.controls.subject.setValue(subject)
		this.sendEmailForm.controls.body.setValue(body)
		this.openEmailModal.nativeElement.click();
	}

	callTosendEmail(){
		let toEmails = this.sendEmailForm.value.toEmails
		let bccEmails = this.sendEmailForm.value.bccEmails?.split(",")
		let ccEmails = this.sendEmailForm.value.ccEmails?.split(",")
		let subject = this.sendEmailForm.value.subject
        let body = this.sendEmailForm.value.body
		if(toEmails){
			toEmails = toEmails.split(",")
			window.scrollTo(0, 0)
			let emails = {
				to_addresses:toEmails,
				cc_addresses:ccEmails,
				bcc_addresses:bccEmails
			}
			let pageUrl = `transactions/adm-acm/print/${this.transactionNumber}`
			let data = {
					email:emails,
					page_url:pageUrl,
					subject:subject,
					email_body:body,
					document_num:this.transactionNumber
				}
			this.commonService.postRequest(`${environment.okountUrl}/send_mail?transaction_type=adm-acm`,data).subscribe((res : any)=> {
				this.showMessage = true;
				this.alertType = 'success-box'
				this.message = res.msg
				this.closebutton.nativeElement.click();
			},err => {
				this.showMessage = true
				this.alertType = "error-box"
				this.message = err.msg
				this.closebutton.nativeElement.click();
			 })
			}
	  }


}
