import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrintTourInvoiceComponent } from './print-tour-invoice/print-tour-invoice.component';
import { TourComponent } from './tour.component';


const routes: Routes = [
	
	{
		path: 'print', component: PrintTourInvoiceComponent
	},
	{
		path: 'save/:transaction_type', component: TourComponent
	},
	{
	 	path: 'view/:invoiceNumber',  component: TourComponent,
    },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TourRoutingModule { }
