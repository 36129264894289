import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EmployeeComponent } from './employee.component';
import { UpdateEmployeeComponent } from './update-employee/update-employee.component';
import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { ViewEmployeeComponent } from './view-employee/view-employee.component';


const routes: Routes = [


  {
		path: '', component: EmployeeComponent,
		children: [

			{ path: 'create-employee', component: CreateEmployeeComponent },
			  { path: 'update-employee/:id', component: UpdateEmployeeComponent },
			  { path: 'view-employee', component: ViewEmployeeComponent },
      
      		{
				path: '',
				redirectTo: 'create-employee',
				pathMatch: 'full'
			}
		]
	},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeeRoutingModule { }
