import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contra',
  templateUrl: './contra.component.html',
  styleUrls: ['./contra.component.scss']
})
export class ContraComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
