
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
  <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
    <div class="d-flex flex-y-center">
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
          alertType=='success-box' ? 'green-sucess.svg' : 
          alertType=='warning-box' ? 'red-warning.svg' : 
          null }}"
        width="20px"
        height="20px"
        alt="alert-logo"
        class="mr-20"
      />
      <div class="ml-10">
        <h4 class="m-0 text-size-18 text-bold"
        [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
          {{alertType === "error-box"
          ? "Error"
          : alertType === "warning-box"
          ? "Warning"
          : alertType === "success-box"
          ? "Success"
          : null}}
        </h4>
        <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
         {{message}}
        </small>
      </div>
    </div>
    <img
      src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
      alt="close-btn"
      (click)="hideError()"
      className=" close-btn"
    />
  </div>
</div>

<button type="button" hidden  #openSuccessModal data-toggle="modal" data-target="#successModal"></button>
<!-- Modal -->
<div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
    <div class="modal-dialog transaction-creation-modal">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <span class="messageFont">
                    <span>
                        {{message}} 
                    </span>
                    <a 
                        *ngIf="createdGeneralCode != ''"
                         href="javascript:void(0)"
                        (click)="viewGeneral(createdGeneralId)">
                            {{createdGeneralCode}}
                    </a>
                </span>
            <button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
            </div>

            <div class="modal-body">
                <p>Do you want to create new General or view newly created General ?</p>
            </div>
          
            <div class="modal-footer justify-content-center" >
                <button tabIndex="1" tabindex="1" type="button" class="btn-style bg-blue" (click)="viewGeneral(createdGeneralId)">
                    View
                </button>
                <button tabIndex="3" tabindex="3" type="button" class="btn-style bg-green" (click)="closeSuccesModal()">
                    Create New
                </button>
            </div>
        </div>
    </div>
</div>

<div class="ledger-container  ">
  <div class="row tab-nav d-flex align-item-center justify-content-between">
    <div class="col-md-4">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
        <li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition"> Dashboard
        </li>
        <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
        <li class="text-grey text-capitalize cursor text-primary transition">Create General</li>
      </ul>
    </div>
    <!-- <ul class="list-inline list-unstyled p-0 m-0">
      <li class="active page-title   text-uppercase"> General</li>
    </ul> -->
    
  </div>
  <div class="custom-form form-container mt-4 pl-15 pr-15" style="    width: 600px;
  margin: 20px 0 ;">


    <form [formGroup]="generalForm" [ngClass]="{'d-none': !newGeneral}">
      <div class="text-field-group d-flex">
        <div class="select-field text-input-field mr-2 col-10 p-0">
          <select class="input-field h-100" oninput="this.value = this.value.toUpperCase()" tabIndex="1" (change)="setHierarchy($event.target.value,false)"
            formControlName="parent_id" [ngClass]="{'error-field': (!generalForm.controls.parent_id.valid && submitted),
            'success-field' : generalForm.controls.parent_id.valid && generalForm.controls.parent_id.dirty}">
            <option selected disabled>SELECT</option>
            <!-- <ng-container *ngIf="groupList.length > 0"> -->
            <option *ngFor="let item of groupList" value="{{item.id}}">{{item.name}}</option>
            <!-- </ng-container> -->
          </select>
          <label
            [ngClass]="{'error-label': generalForm.controls.parent_id.invalid && generalForm.controls.parent_id.dirty || (!generalForm.controls.parent_id.valid && submitted)}">Group
            <span class="text-danger">*</span>
            <ng-container
              *ngIf="generalForm.controls.parent_id.invalid && generalForm.controls.parent_id.dirty || (!generalForm.controls.parent_id.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Group Required" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="2"
            formControlName="hierarchy" type="text" />
          <label>
            Hierarchy
          </label>
        </div>
      </div>
      <div class="text-field-group d-flex justify-content-between">
        <div class="text-input-field"  [ngClass]="{'mr-2': isHQBranch}">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="2"
            formControlName="account_name" type="text" [ngClass]="{'error-field': generalForm.controls.account_name.invalid && generalForm.controls.account_name.dirty || (!generalForm.controls.account_name.valid && submitted) ,
              'success-field' : generalForm.controls.account_name.valid && generalForm.controls.account_name.dirty}" />
          <label
            [ngClass]="{'error-label': generalForm.controls.account_name.invalid && generalForm.controls.account_name.dirty || (!generalForm.controls.account_name.valid && submitted)}">Account
            <span class="text-danger">*</span>

            <ng-container
              *ngIf="generalForm.controls.account_name.invalid && generalForm.controls.account_name.dirty || (!generalForm.controls.account_name.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid telephone Number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <!-- <div style="width: 150px;" class="select-field text-input-field" *ngIf="isHQBranch">
          <select class="input-field" formControlName="sync_in_branches">
            <option value="">Select</option>
            <option value="true">YES</option>
            <option value="false">NO</option>
          </select>
          <label>All Branches</label>
        </div> -->
       

      </div>

      <div class="text-input-group d-flex justify-content-between">

        <div class="text-input-field mr-2">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="5"
            formControlName="state_id" (change)="selectState($event.target.value)">
            <option *ngFor="let state of stateArr" value="{{state.location_id}}">{{state.name}}</option>
          </select>
          <label>State</label>
        </div>

        <div class="text-input-field" *ngIf="isTenantIndian">
          <input class="input-field" onblur="this.value = this.value.toUpperCase()" name="gst_number" tabIndex="7"
            formControlName="gst_number" type="text" maxlength="15"
            pattern="^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$"
            [ngClass]="{'error-field': generalForm.controls.gst_number.invalid && generalForm.controls.gst_number.dirty || (!generalForm.controls.gst_number.valid && submitted),
          'success-field' : generalForm.controls.gst_number.valid && generalForm.controls.gst_number.dirty}" />
          <label
            [ngClass]="{'error-label': generalForm.controls.gst_number.invalid && generalForm.controls.gst_number.dirty} || (!generalForm.controls.gst_number.valid && submitted)">
            GST NUMBER
            <ng-container
              *ngIf="generalForm.controls.gst_number.invalid && generalForm.controls.gst_number.dirty || (!generalForm.controls.gst_number.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid GST Number ( ex: 12ABCDE1234F1Z3)"
                aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field" *ngIf="!isTenantIndian">
          <input class="input-field" onblur="this.value = this.value.toUpperCase()" name="vat_number" tabIndex="7"
            formControlName="vat_number" type="text" maxlength="20"
            [ngClass]="{'error-field': generalForm.controls.vat_number.invalid && generalForm.controls.vat_number.dirty || (!generalForm.controls.vat_number.valid && submitted),
          'success-field' : generalForm.controls.vat_number.valid && generalForm.controls.vat_number.dirty}" />
          <label
            [ngClass]="{'error-label': generalForm.controls.vat_number.invalid && generalForm.controls.vat_number.dirty} || (!generalForm.controls.vat_number.valid && submitted)">
            {{vatAlternetname}} NUMBER
          </label>
        </div>

      </div>

      <ng-container *ngIf="!isTenantIndian">
        <div class="text-input-group d-flex justify-content-between">
        
          <div class="select-field text-input-field mr-3">
            <select id="selectscroll" class="input-field" tabIndex="1" formControlName="currency" (change)="currencyChange($event.target.value)" >
              <option value="INR">INR</option>
              <option value="CAD">CAD</option>
                <option value="USD">USD</option>
                <option value="AED">AED</option>
                <option value="EURO">EURO</option>
            </select>
            <label>CURRENCY</label>
          </div>
          <div class="text-input-field mr-3">
            <input class="input-field" style="    width: 185px;" oninput="this.value = this.value.toUpperCase()"
              name="currency" tabIndex="13" formControlName="currency_rate" type="text" maxlength="10"
              [ngClass]="{'error-field': generalForm.controls.currency_rate.invalid && generalForm.controls.currency_rate.dirty,
          'success-field' : generalForm.controls.currency_rate.valid && generalForm.controls.currency_rate.dirty}" />
            <label
              [ngClass]="{'error-label': generalForm.controls.currency_rate.invalid && generalForm.controls.currency_rate.dirty}">CURRENCY RATE<ng-container *ngIf="generalForm.controls.currency_rate.invalid && generalForm.controls.currency_rate.dirty">
                <i class="fa fa-info-circle ml-2" title="Invalid pan number" aria-hidden="true"></i>
              </ng-container>
            </label>
          </div>
          <div class="text-input-group d-flex justify-content-between">
            <div class="text-input-field" style="    width: 185px;">
              <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="local_balance" tabIndex="13"
            formControlName="local_balance"  type="text" [ngClass]="{'error-field': generalForm.controls.local_balance.invalid && generalForm.controls.local_balance.dirty,
          'success-field' : generalForm.controls.local_balance.valid && generalForm.controls.local_balance.dirty}" (input)="changeCurrentAmount($event)" />
          <label
            [ngClass]="{'error-label': generalForm.controls.local_balance.invalid && generalForm.controls.local_balance.dirty}">AMOUNT
            <ng-container *ngIf="generalForm.controls.local_balance.invalid && generalForm.controls.local_balance.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid amount" aria-hidden="true"></i>
            </ng-container>
          </label>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="text-field-group d-flex">
        <div class="w-50 text-input-field mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="hsn_code" tabIndex="3"
            formControlName="hsn_code" type="text" [ngClass]="{'error-field': generalForm.controls.hsn_code.invalid && generalForm.controls.hsn_code.dirty,
              'success-field' : generalForm.controls.hsn_code.valid && generalForm.controls.hsn_code.dirty}" />
          <label
            [ngClass]="{'error-label': generalForm.controls.hsn_code.invalid && generalForm.controls.hsn_code.dirty}">HSN
            Code
            <ng-container *ngIf="generalForm.controls.hsn_code.invalid && generalForm.controls.hsn_code.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid HSN Code" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="w-50 d-flex">
          <div class="text-input-field mr-2">
            <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="balance" tabIndex="3" [readonly]="!isTenantIndian"
              formControlName="balance" pattern="^\d*(\.\d{0,8})?$" type="text" [ngClass]="{'error-field': generalForm.controls.balance.invalid && generalForm.controls.balance.dirty,
                'success-field' : generalForm.controls.balance.valid && generalForm.controls.balance.dirty}" />
            <label
              [ngClass]="{'error-label': generalForm.controls.balance.invalid && generalForm.controls.balance.dirty}">Opening
              Balance
              <ng-container *ngIf="generalForm.controls.balance.invalid && generalForm.controls.balance.dirty">
                <i class="fa fa-info-circle ml-2" title="Invalid opening balance" aria-hidden="true"></i>
              </ng-container>
            </label>
          </div>
  
          <div class="w-25 select-field text-input-field">
            <select style="width: 60px;" class="input-field" tabIndex="4" formControlName="balance_type">
              <option value="debit">DR</option>
              <option value="credit">CR</option>
            </select>
          </div>
        </div>
        

      </div>
      <!-- <span class="info alert-warning"
        *ngIf="showMessage && alertType == 'success-box' && createdGeneralId != ''">General created. <a
          [routerLink]="['/master/policy/default-general-policy', createdGeneralId]">Click here to update
          policy.</a></span> -->

     
    </form>

    <div class="select-field text-input-field" *ngIf="isHQBranch">
      <label for="selectedBranch" style="font-size: 13px; font-weight: 500!important;">Branch</label>
      <ng-container *ngFor="let item of branches">
            <div *ngIf="item?.branch_type=='BRANCH'" class="input-fiel" style="display: flex;align-items: center;">
              <input class="radio-ui mr-2" type="checkbox"  (change)="handleValueChange($event,item?.branch_name)"  />
              <label class="radio-label" style="transform: none;font-size: 12px;margin-bottom: 0;" >{{item?.branch_name}}</label>
            </div>
      </ng-container>
    </div>


    
  </div>
  <div class="action-box btn-field bottom-btns-container mt-4 d-flex justify-content-end">
    <div>
      <button type="button" class="btn-style bg-green mr-3" (click)="reset()">Reset</button>
      <button type="button" class="btn-style bg-purple" tabIndex="5" #confirmModal
        (keyup.enter)="openConfirmModal()" data-toggle="modal" data-target="#confirmationModal">Save</button>
    </div>
   
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="confirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4>Confirmation</h4>
        <button type="button" class="close" #closeModal data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Create {{generalForm?.value?.account_name}} ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" data-dismiss="modal">Close</button>
        <button type="button" class="btn-style bg-purple" (click)="checkBalance()" (keyup.enter)="submit()"
          tabIndex="6">Yes</button>
      </div>
    </div>
  </div>
</div>
<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateconfirmationModal"></button>
<div id="updateconfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeUpdateModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        There is a mismatch of amount {{remainingAmount}} in your opening balance. It will be adjusted in the Opening Balance Adjustment account. Would you like to proceed?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" 
        data-dismiss="modal">Close</button>
        <button type="button" class="btn-style bg-green" (click)="updateOpeningBalancecombine()" >CREATE</button>
      </div>
    </div>
  </div>
</div>