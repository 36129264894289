export enum ActionTypes {
    SetLoginDetailsLocals = '[Set Login Details locals]',
    SetFinancialyear = '[Set financial year]',
    AddInternalTabs = '[Add internal tab list]',
    UpdateInternalTabs = '[Update internal tab list]',
    RemoveInternalTab = '[Remove internal tab list]',
    SetActiveTab = '[Set Active Tab]',
    SetLoaderTemplate = '[Set Loader Template]',

}
import {Action} from '@ngrx/store'

export class SetLoginDetails implements Action {
    readonly type = ActionTypes.SetLoginDetailsLocals;
    constructor(public payload: any){}
}
export class SetFinancialyear implements Action {
    readonly type = ActionTypes.SetFinancialyear;
    constructor(public payload: any){}
}

export class AddInternalTabs implements Action {
    readonly type = ActionTypes.AddInternalTabs;
    constructor(public payload: any){}
}
export class UpdateInternalTabs implements Action {
    readonly type = ActionTypes.UpdateInternalTabs;
    constructor(public payload: any){}
}
export class RemoveInternalTab implements Action {
    readonly type = ActionTypes.RemoveInternalTab;
    constructor(public payload: any){}
}
export class SetActiveTab implements Action {
    readonly type = ActionTypes.SetActiveTab;
    constructor(public payload: any){}
}
export class SetLoaderTemplate implements Action {
    readonly type = ActionTypes.SetLoaderTemplate;
    constructor(public payload: any){}
}


