

 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
	<div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
	  <div class="d-flex flex-y-center">
		<img
		  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
			alertType=='success-box' ? 'green-sucess.svg' : 
			alertType=='warning-box' ? 'red-warning.svg' : 
			null }}"
		  width="20px"
		  height="20px"
		  alt="alert-logo"
		  class="mr-20"
		/>
		<div class="ml-10">
		  <h4 class="m-0 text-size-18 text-bold"
		  [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
			{{alertType === "error-box"
			? "Error"
			: alertType === "warning-box"
			? "Warning"
			: alertType === "success-box"
			? "Success"
			: null}}
		  </h4>
		  <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
		   {{message}}
		  </small>
		</div>
	  </div>
	  <img
		src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
		alt="close-btn"
		(click)="hideError()"
		className=" close-btn"
	  />
	</div>
  </div>
  
<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<button type="button" hidden #openSuccessModal data-toggle="modal" data-target="#successModal"></button>
			<!-- Modal -->
    <div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
        <div class="modal-dialog transaction-creation-modal">
            <!-- Modal content-->
            <div class="modal-content"  *ngIf="submitResponse && submitResponse[0] != undefined">
                <div class="modal-header">
                    <span class="messageFont">
                        <span>
                            {{message}} 
                        </span>
                            <a *ngIf="submitResponse[0].payment_code != ''" 
                                href="javascript:void(0)"	
                                (click)="openVoucher(submitResponse[0].payment_code)">
                                {{submitResponse[0].payment_code}}
                            </a>
                            <i *ngIf="submitResponse[0].payment_code != ''" [title]="copyELementTitle" (click)="copytext(submitResponse[0].payment_code)" class="fa fa-copy cursor-pointer"></i>
                        </span>
                        <button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
                    </div>
                <div class="modal-body">
                    <p>Do you want to create new Payment or  view newly created Payment ?</p>
                </div>
                <div class="modal-footer justify-content-center">
                    <button 
                        tabIndex="1" 
                        tabindex="1"
                        type="button" 
                        class="btn btn-primary bg-blue" 
                        (click)="viewPayment(submitResponse[0].payment_code)">
                        View
                    </button>
                    <button 
                        tabIndex="2" 
                        tabindex="2"
                        type="button" 
                        class="btn btn-primary btn-grey" 
                        (click)="openVoucher(submitResponse[0].payment_code)">
                        Print
                    </button>
                    <button 
                        tabIndex="3" 
                        tabindex="3"
                        type="button" 
                        class="btn btn-primary bg-green"  
                        (click)="closeSuccesModal()">
                        Create New
                    </button>
                </div>
            </div>
        </div>
    </div>

  <div class="ledger-container" [ngClass]="{'d-none': !newPayment}" >
	<ng-container>
		<div class="row tab-nav pl-0 pr-0">
			<div class="col-sm-6 ">
			  <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
				  <li  
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> Banking</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li  
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> Payment</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li class="text-grey text-capitalize cursor text-primary transition"> Create</li>
			  </ul>
		  </div>
		  <div class="col-sm-6 text-right">
		  </div>
		</div>
	
		<form #form="ngForm" [formGroup]="paymentService.paymentForm" >
			<div class="pl-15 p-15 pt-3 custom-form form-container w-100 mb-4">
                <div class="row">
                    <div class="col-sm-2 text-field-group d-flex align-items-center  pb-3" >
						<div class="text-input-field">
							<input formControlName="payment_date"  tabIndex="0" class="input-field" type="date" 
								min="{{minDate}}" 
								max="{{maxDate}}"
								placeholder="MM/DD/YYYY" autocomplete="off" tabIndex="1"
								[ngClass]="{'error-box': !paymentService.paymentForm.controls.payment_date.valid}" />
							<label>Payment Date</label>
						</div>
						
					</div>
                    <div class="col-sm-2 text-field-group d-flex align-items-center  pb-3" >
						<div class="text-input-field multiselect-dropdown-container">

							<!-- <select class="input-field" formControlName="account_code_from" attr.tabIndex="2" tabIndex="2">
								<option value="">Select</option>
								<ng-container *ngFor="let item of generalMasterList">
									<option *ngIf="item.account_code != ''" value="{{item.account_code}}">{{item.name}}
									</option>
								</ng-container>
							</select>
							<label>Cash / Bank</label> -->
							<label>Cash / Bank</label> 
							<div class="ng-autocomplete custom-ngc" (focus)="focusBankAc($event)">
								<input attr.tabIndex="2" tabIndex="2"
									type="text" #accountAC oninput="this.value = this.value.toUpperCase()" 
									class="autocomplete-container" name="account_code_from_name" 
									formControlName="account_code_from_name"
									(input)="getGeneralMaster($event,true)"
									[nzAutocomplete]="accountCodeFromAc"
								/>
		
								<nz-autocomplete #accountCodeFromAc [nzBackfill]="true"  (selectionChange)="selectBank($event,'account_code_from')">
									<nz-auto-option  class="colr-1" *ngFor="let item of generalMasterList" [nzValue]="item" [nzLabel]="item.name">
										<a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
									</nz-auto-option>     
									<nz-auto-option *ngIf="showCreateButton && currentMaster == 'general'" class="colr-1" nzValue="" style="text-align: center"
                                        nzLabel="Create New">
										<span>Create New</span>                          
									</nz-auto-option>                      
								</nz-autocomplete>

							</div>	
						</div>
						
					</div>
                   
                    <div class="col-sm-2 text-field-group d-flex align-items-center  pb-3" *ngIf="searchId">
						<div class="text-input-field">
							<input class="input-field" readonly type="text" formControlName="payment_code" autocomplete="off" />
							<label>Payment Code</label>
						</div>
						
					</div>
                </div>
				<div class="">
					
					<!-- <div class="table-head">Entries</div> -->
                    <table class="table table-bordered">
						<thead>
							<tr>
                                <th style="width: 25px;">S.no</th>
								<th>Account Type</th>
								<th>Code</th>
								<th>Sub Ledger Type</th>
								<th>Sub Ledger</th>
								<th>Cheque</th>
								<th>Cheque Date</th>
								<th class="text-right">Amount</th>
								<th class="text-center">Actions</th>
							</tr>
						</thead>
						<tbody class="fixed-tbody-height " formArrayName="paymentArray">
                            

							<tr *ngFor="let paymentEntry of DynamicFormControls.controls; let i = index; "  [formGroupName]="i">
								<td style="width: 25px;">{{i+1}}</td>
								<td>
									<select class="input-field" #accountType formControlName="account_code_to_type" (change)="resetSelection('account_code_to',i)"  attr.tabIndex="{{i*7+1+previousTabCount}}" tabIndex="{{i*7+1+previousTabCount}}">
										<option value="client">Client</option>
										<option value="airline">Airline</option>
										<option value="supplier">Supplier</option>
										<option value="general">General</option>
									</select>
								</td>

								<td>
									<div class="ng-autocomplete custom-ngc"  (focus)="focusAccount($event,i)">
										<input attr.tabIndex="{{i*7+2+previousTabCount}}" tabIndex="{{i*7+2+previousTabCount}}"
											type="text" #accountAC oninput="this.value = this.value.toUpperCase()" 
											class="autocomplete-container" name="account_code_to_name" 
											formControlName="account_code_to_name"
											(input)="onChangeSearch($event ,i,true)"
											[nzAutocomplete]="accountCodeToName"
										/>
				
										<nz-autocomplete #accountCodeToName [nzBackfill]="true"  (selectionChange)="selectAccount($event,'account_code_to',i)">
											<nz-auto-option  class="colr-1" *ngFor="let item of accountList[i]" [nzValue]="item" [nzLabel]="item.name">
												<a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
											</nz-auto-option>        
											<nz-auto-option *ngIf="showCreateButton && currentMaster == paymentEntry.value.account_code_to_type" class="colr-1" nzValue="" style="text-align: center"
												nzLabel="Create New">
												<span>Create New</span>                          
											</nz-auto-option>                           
										</nz-autocomplete>

									</div>	
								</td>

								<td>
									<select class="input-field" formControlName="sub_ledger_code_1_type" (change)="resetSelection('sub_ledger_code_1',i)" attr.tabIndex="{{i*7+3+previousTabCount}}" tabIndex="{{i*7+3+previousTabCount}}">
										<option value="">None</option>
										<option value="client">Client</option>
										<option value="airline">Airline</option>
										<option value="supplier">Supplier</option>
										<option value="employee">Employee</option>
										<option value="general">General</option>
									  </select>
								</td>
								<td>
									<div class="ng-autocomplete custom-ngc"  (focus)="focusSubLedger($event,i)">
										<!-- <ng-autocomplete #subLedgerAC [data]="ledgerList[i]" formControlName="sub_ledger_code_1_name"
										[searchKeyword]="keyword" (selected)="selectAccount($event,'sub_ledger_code_1',i)"
										(inputChanged)='ledgerSearch($event,i,true)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
										[notFoundTemplate]="notFoundTemplate" >
									  </ng-autocomplete>
									<ng-template #itemTemplate let-item>
										<a
											innerHTML='<span class="colr-1">{{item.name}}({{item.account_code}})</span><br />'>
										</a>
									</ng-template>

									<ng-template #notFoundTemplate let-notFound>
										<div [innerHTML]="notFound"></div>
									</ng-template> -->

									<input attr.tabIndex="{{i*7+4+previousTabCount}}" tabIndex="{{i*7+4+previousTabCount}}" 
											type="text" #accountAC oninput="this.value = this.value.toUpperCase()" 
											class="autocomplete-container" name="sub_ledger_code_1_name" 
											formControlName="sub_ledger_code_1_name"
											(input)="ledgerSearch($event,i,true)"
											[nzAutocomplete]="sub_ledger_code_1_name_ac"
										/>
				
									<nz-autocomplete #sub_ledger_code_1_name_ac [nzBackfill]="true"  (selectionChange)="selectAccount($event,'sub_ledger_code_1',i)">
										<nz-auto-option  class="colr-1" *ngFor="let item of ledgerList[i]" [nzValue]="item" [nzLabel]="item.name">
											<a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
										</nz-auto-option>                      
									</nz-autocomplete>
								</div>
								</td>

								<td>
									<input oninput="this.value = this.value.toUpperCase()" attr.tabIndex="{{i*7+5+previousTabCount}}" tabIndex="{{i*7+5+previousTabCount}}" class="input-field" type="text"
									formControlName="cheque_no" maxlength="30" pattern="([0-9]+)" />
								</td>
								<td>
									<input oninput="this.value = this.value.toUpperCase()" attr.tabIndex="{{i*7+6+previousTabCount}}" tabIndex="{{i*7+6+previousTabCount}}" class="input-field" type="date"
									formControlName="cheque_date" autocomplete="off"
									/>
								
								</td>

								<td>
									<input oninput="this.value = this.value.toUpperCase()" attr.tabIndex="{{i*7+7+previousTabCount}}" tabIndex="{{i*7+7+previousTabCount}}" class="input-field" type="text"
										formControlName="amount" (input)="calculateDiff()"  pattern="^\d*(\.\d{0,8})?$" class="text-right"
									/>
								</td>
								<td class="edit-row text-center">
									<!-- <button title="Delete..." class="btn   save-as text-bold bg-whiteSmoke "
										type="button" (click)="removeJvRow(i)">
										<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/red-delete.svg" alt="" width="13px">
									</button> -->
                                    <button  title="Add Row" class="custom-table-btn bg-white border-0 p-0 mr-2 text-bold hoverableShadow transition cursor" type="button" (click)="addRow()" 
									 id="add_row_payment_btn_{{i}}">
									<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-row.svg" alt="" width="20px">
									
								  </button>
									<button  title="Delete" class="custom-table-btn bg-white border-0 p-0 mr-2 text-bold hoverableShadow transition cursor" type="button" (click)="removePaymentRow(i)"
									id="remove_row_payment_btn_{{i}}">
									<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-delete.svg" alt="" width="20px">
									
								  </button>
								
								  <button  title="View Adjusted Invoices" class="custom-table-btn bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button" (click)="viewAdjustedInvoices()"
									id="adjusted_invoices_payment_btn_{{i}}"
									>
									<img src="{{commonService.aws_asset_url}}/_assets/icons/dark-mode/green-cirlcle-edit.svg" alt="" width="20px">
								</button>

							</td>
							</tr>
						</tbody>
						<tbody>
							<tr>
							<td colspan="6"></td>
							<td>
								<div class="d-flex justify-content-center text-bold">
									SUBTOTAL:
								</div>
							</td>
							<td> <span class="text-bold">{{totalAmount}} </span></td>
							<td></td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
							<td colspan="9" class="text-right text-black pt-3 pb-3">
								<br />
								<div class="text-input-field w-50">
									<input class="input-field" attr.tabIndex="{{paymentService.paymentArray.length*7+1+previousTabCount}}" tabIndex="{{paymentService.paymentArray.length*7+1+previousTabCount}}" type="text" formControlName="narration_5" autocomplete="off" />
									<label>Narration</label>
								</div>
								
							</td>

							</tr>
							
							
						</tfoot>
					</table>
				</div>
			</div>
			<div class="btn-field bottom-btns-container  full-width-btn-container d-flex align-item-center justify-content-end  ml-0 mr-0">  
				<ng-container *ngIf="searchId">
					
					<button type="button" class="btn-style bg-cyan mr-3" 
					*hasAccess="{'key' : 'F0004023'}"
					attr.tabIndex="{{paymentService.paymentArray.length*7+2+previousTabCount}}" tabIndex="{{paymentService.paymentArray.length*7+2+previousTabCount}}"  (click)="openConfirModal('update')" id="update_payment_btn">Modify</button>
					<button type="button" class="btn-style bg-red mr-3" 
					*hasAccess="{'key' : 'F0004024'}"
					attr.tabIndex="{{paymentService.paymentArray.length*7+3+previousTabCount}}" tabIndex="{{paymentService.paymentArray.length*7+3+previousTabCount}}"  (click)="openConfirModal('delete')" id="delete_payment_btn">Delete</button>
				
					<button type="button" class="btn-style bg-denim mr-3" (click)="openVoucher(searchId)">Print</button> 
					<button type="button" class="btn-style bg-brown mr-3" (click)="viewJournal(searchId)" id="journal_payment_btn">
						Journal
					</button>
					<button type="button" class="btn-style bg-indigo mr-3" id="payment_activity_log" (click)="openActivity()">Activity</button> 
					<button type="button" class="btn-style bg-rusty-red text-white mr-3"
					attr.tabIndex="{{paymentService.paymentArray.length*7+4+previousTabCount}}" 
					tabIndex="{{paymentService.paymentArray.length*7+4+previousTabCount}}"
					(click)="openSendEmailModal()" id="receipt_send_mail_btn" id="payment_send_mail_btn">Email</button>
					<button type="button" class="btn-style bg-blue" *ngIf="showAdjustbtn" id="adjust-balance-button" (click)="openAdjustmentModal()">Adjustment</button>
				</ng-container>
				<ng-container *ngIf="!searchId">
					<button type="button" class="btn-style bg-green ml-3 mr-3" (click)="resetPayment()">Reset</button>
					<button type="button" class="btn-style bg-purple " (click)="openConfirModal('save')"  attr.tabIndex="{{paymentService.paymentArray.length*7+2+previousTabCount}}" tabIndex="{{paymentService.paymentArray.length*7+2+previousTabCount}}" id="save_payment_btn">Save</button>
			    </ng-container>
			</div>
		</form>

	</ng-container>

</div>

<button type="button" hidden #confirmModal data-toggle="modal" data-target="#confirmationModal"></button>

<div class="modal fade" id="confirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" #closeConfirmModal data-dismiss="modal" aria-label="Close" id="confirm_close_Modal_{{confirmationModalType}}_btn" (click)="resetCurrentModalType()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>" {{confirmationModalType}} ‎this entry " </b> ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-style mr-2" (click)="closeConfirModal()">No</button>
                <button type="button" class="btn-style bg-purple"
				    id="confirm_Opened_Modal_{{confirmationModalType}}_btn"
                    (click)="confirmationModalType=='save'?submit(paymentService.paymentForm.value):confirmationModalType=='update'?updateAndExit(paymentService.paymentForm.value):confirmationModalType=='delete'?deletePayment(paymentService.paymentForm.value):''">Yes</button>
            </div>
        </div>
    </div>
</div>

 <!-- Email Modal for sending mail -->
 <button
 hidden
 #openEmailModal
 data-target="#emailModalInPayment"
 data-toggle="modal"
></button>
 <div
   class="modal"
   id="emailModalInPayment"
   role="dialog"
   aria-labelledby="emailModalLabel"
   data-backdrop="false"
 >
   <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header">
         <div class="d-flex justify-content-center">
           <h4 class="modal-title">Send Email</h4>
         </div>
         <button
           type=" button"
           class="close"
           #closeSendEmailModal
           data-dismiss="modal"
           aria-label="Close"
         >
           <span aria-hidden="true">&times;</span>
         </button>
       </div>
       <div class="modal-body emailSenders">
         <!-- <div class="d-flex justify-content-end align-item-center">
                           <span *ngIf="!ccEmail" (click)="showCCEmail()" class="text-size-14 mb-0 cursor  mr-2">Cc</span>
                           <span *ngIf="!bccEmail" (click)="showBCCEmail()" class="text-size-14 mb-0 cursor">Bcc</span>
                       </div> -->
         <form [formGroup]="sendEmailForm" style="margin-top: -10px">
           <div class="form-group text-input-field">
             <textarea
               class="input-field ng-pristine ng-valid ng-touched text-size-12"
               id="toEmail"
               rows="1"
               formControlName="toEmails"
               required
             ></textarea>
             <label for="To" class="mb-0">To</label>
           </div>
           <p class="text-right mt-0">
			<!-- <label for="To" class="text-size-14 mb-0">To</label> -->
			<small *ngIf="sendEmailForm.controls.toEmails.invalid">
			  <span
				*ngIf="sendEmailForm.controls.toEmails.errors?.required"
				style="color: red"
			  >
				*To Email is required.
			</span>
		   </small>
		   </p>

           <div class="form-group text-input-field mt-0">
             <textarea
               class="input-field ng-pristine ng-valid ng-touched text-size-12"
               id="ccEmail"
			   style="max-height: 45px;"
               formControlName="ccEmails"
             ></textarea>
             <label for="Cc" class="mb-0">Cc</label>
           </div>

           <div class="form-group text-input-field">
             <textarea
               class="input-field ng-pristine ng-valid ng-touched text-size-12"
               id="bccEmail"
			   style="max-height: 45px;"
               formControlName="bccEmails"
             ></textarea>
             <label for="Bcc" class="mb-0">Bcc</label>
           </div>

		   <div class="text-input-field">
			<input formControlName="subject"  class="input-field text-size-12" type="text" 
			autocomplete="off" />
			<label>Subject</label>
		</div>

		<div class="form-group text-input-field">
			<textarea
			  class="input-field ng-pristine ng-valid ng-touched text-size-12"
			  rows="5"
			  formControlName="body"
			></textarea>
			<label for="body" class="mb-0">Mail Body</label>
			<input type="file" (change)="handleAttachment($event)" />

		  </div>
           <div class="mt-3 d-flex justify-content-center align-item-center">
             <button type="button" class="btn-style mr-3" data-dismiss="modal">
               Cancel
             </button>
             <button
               type="button"
               class="btn-style bg-green"
               (click)="callTosendEmail()"
             >
               Send
             </button>
           </div>
         </form>
       </div>
     </div>
   </div>
 </div>


<button hidden #openMasterCreationModal data-target="#masterCreationModal"data-toggle="modal"></button>
<div
  class="modal"
  id="masterCreationModal"
  role="dialog"
  aria-labelledby="masterModalLabel"
  data-backdrop="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex justify-content-center">
          <h4 class="modal-title">Create {{currentMaster}}</h4>
        </div>
        <button
          type=" button"
          class="close"
          #closeMasterCreationModal
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
	    <app-master-creation [masterType]="currentMaster" [masterCreationModal]="masterCreationModal" [currentMasterName]="currentMasterName" 
		(callSetFormData)="setFormValues($event)"></app-master-creation>
     </div>
    </div>
</div>