import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LedgerRoutingModule } from './ledger-routing.module';
import { LedgerComponent } from './ledger.component';
import { NormalLedgerComponent } from './normal-ledger/normal-ledger.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SummaryLedgerComponent } from './summary-ledger/summary-ledger.component';
import { SubgroupLedgerComponent } from './subgroup-ledger/subgroup-ledger.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { OutstandingSummaryLedgerComponent } from './outstanding-summary-ledger/outstanding-summary-ledger.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { LedgerFilterSearchComponent } from './ledger-filter-search/ledger-filter-search.component';


@NgModule({
  declarations: [LedgerComponent, SummaryLedgerComponent, SubgroupLedgerComponent, OutstandingSummaryLedgerComponent, LedgerFilterSearchComponent],
  imports: [
    CommonModule,
    LedgerRoutingModule,
    NgxPaginationModule,
    SharedModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  exports : []
})
export class LedgerModule { }
