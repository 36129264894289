import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { CommonServiceService } from '../../common-service.service';
import { environment } from 'src/environments/environment';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';

@Component({
  selector: 'app-e-inv-list-search',
  templateUrl: './e-inv-list-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class EInvListSearchComponent implements OnInit {

  misReportFilterForm: FormGroup;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	minDate = "2023-04-01"
  maxDate = moment().format('YYYY-MM-DD')
  clientArr: any = []
  keyword = 'name';

  constructor(
    public commonService: CommonServiceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private store: Store<InitialState>) {
			this.store.select('sharedReducers').subscribe((items)=>{
        if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				} else {
					let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
					if(!current_financial_year) {
						current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
					}
					this.minDate = current_financial_year.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				}	  
			})
  }
  
  ngOnInit() {

    this.misReportFilterForm = this.formBuilder.group({
      start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate,this.checkDate('end_date')],
      // selectedClient: [''],
      // selectedClientName: [''],
    })
    this.setFormData()
    
    // this.misReportFilterForm.controls.selectedClient.setValue("")
  }

  checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}
  
  setFormData() {
    let queryParams = localStorage.getItem('activeEInvListSearchQueryParams')
    if (queryParams != null) {
      let parsedParams = JSON.parse(queryParams)
      
      if (parsedParams.start_date != "") {
        this.misReportFilterForm.controls.start_date.setValue(moment(parsedParams.start_date).format('YYYY-MM-DD') )
      }
      if (parsedParams.end_date != "") {
        this.misReportFilterForm.controls.end_date.setValue(moment(parsedParams.end_date).format('YYYY-MM-DD'))
      }
      // if (parsedParams.selectedClient != "") {
      //   this.misReportFilterForm.controls.selectedClient.setValue(parsedParams.airline ? parsedParams.airline : "")
      // }
    }
  }

  selectAccount(value) {
    // if(value) {
    //   this.misReportFilterForm.controls.selectedClient.setValue(value.account_code ? value.account_code : "")
    //   this.misReportFilterForm.controls.selectedClientName.setValue(value.name ? value.name : "")
    // } else {
    //   this.misReportFilterForm.controls.selectedClient.setValue("")
    // }
  }


  search(values) {
    GlobalVariable.openModalStatus = false;
    if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
      values.start_date = ""
    }
    else {
      values.start_date = moment(values.start_date).format('YYYY-MM-DD')
    }
    if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date" || values.end_date == "Invalid date") {
      values.end_date = ""
    }
    else {
      values.end_date = moment(values.end_date).format('YYYY-MM-DD')
    }
    // if(values.selectedClient == null || values.selectedClient == "") {
    //    values.selectedClient = ""
    // }

    // if(values.selectedClient !== '') {
      
        this.router.navigateByUrl(`/report/e-inv-list?start_date=${values.start_date}&end_date=${values.end_date}`);
      
    // }
    // this.reset()
  }

  getFilteredData(queryParams) {
    this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/gst`, queryParams).subscribe(res => {
      console.log(res);
    }, err => {
      console.error(err);
    })
  }

  reset() {
    GlobalVariable.openModalStatus = false;
    this.misReportFilterForm = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
      // selectedClient: ['']
    })
    localStorage.removeItem('activeEInvListSearchQueryParams')
  }

  clickSearchButton(){
		this.searchButton.nativeElement.click()
	}

  getClient(value,bindValue = false) {
		if(!value) {
			// this.misReportFilterForm.controls['selectedClient'].setValue('')
			// this.misReportFilterForm.controls['selectedClientName'].setValue('')
		}
		if (value.length > 2) {
			this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.clientArr = res
        console.log(this.clientArr);
        
				if(bindValue && this.clientArr.length>0){
					// this.misReportFilterForm.controls['selectedClient'].setValue(this.clientArr[0].account_code)
					// this.misReportFilterForm.controls['selectedClientName'].setValue(this.clientArr[0].name)
				}
			}, err => {

			})
		}
	}

}

