import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { CommonServiceService } from '../../common-service.service';
import { environment } from 'src/environments/environment';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';
@Component({
  selector: 'app-reconciliation-search',
  templateUrl: './reconciliation-search.component.html',
  styleUrls: ['../global-search.component.scss']

})
export class ReconciliationSearchComponent implements OnInit {
  tdsreportFilterForm: FormGroup;
  keyword = "name"
  supplierArr: any ;
  airlineArr: any;
  supplierlist: Object;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	@ViewChild('airlineAC',{static:true}) airlineAC;
	minDate = "2023-04-01"
  maxDate = moment().format('YYYY-MM-DD')
  constructor(
    public commonService: CommonServiceService,
    private router: Router, private formBuilder: FormBuilder,private store: Store<InitialState>
  ) { 

    this.store.select('sharedReducers').subscribe((items)=>{
      if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
        this.minDate = items.financeYear.start_date
        this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
                    moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      } else {
        let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
        if(!current_financial_year) {
          current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
        }
        this.minDate = current_financial_year.start_date
        this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
                    moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      }
      
  })
  }

  ngOnInit() {
    this.tdsreportFilterForm = this.formBuilder.group({
      start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate,this.checkDate('end_date')],
      supplier: ['iata'],
      airline: [''],
      airline_name: [''],
      all: [true],
      ticket: [false],
      refund: [false]

    })
    this.getSupplier()
    this.setFormData()
    
  }


	checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}

  setFormData() {
		let queryParams = localStorage.getItem('activeReconcilationQueryParams')
		if (queryParams != null) {
			debugger
			let parsedParams = JSON.parse(queryParams)
			console.log('Active Query Params', parsedParams)
      this.getAirline(parsedParams.airline ? parsedParams.airline : "",true)
      if(parsedParams.start_date!=""){
         this.tdsreportFilterForm.controls.start_date.setValue(parsedParams.start_date)
      }
      if(parsedParams.end_date!=""){
       this.tdsreportFilterForm.controls.end_date.setValue(parsedParams.end_date)
      }
      this.tdsreportFilterForm.controls.supplier.setValue(parsedParams.supplier?parsedParams.supplier:"iata")
      this.tdsreportFilterForm.controls.ticket.setValue(parsedParams.ticket?parsedParams.ticket:false)
      this.tdsreportFilterForm.controls.all.setValue(parsedParams.all?parsedParams.all:false)
      this.tdsreportFilterForm.controls.refund.setValue(parsedParams.refund?parsedParams.refund:false)
		}
	  }

  ngAfterViewInit(){
		this.commonService.selectedSearchInput.subscribe(index=>{
			if(index == 4){
				this.focusAirlineAc()
			}
		})
	}

  search(values){
    GlobalVariable.openModalStatus = false;
    debugger
    if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
      values.start_date = ""
    }
    else {
      values.start_date = moment(values.start_date).format('YYYY-MM-DD')
    }
    if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date" || values.end_date == "Invalid date") {
      values.end_date = ""
    }
    else {
      values.end_date = moment(values.end_date).format('YYYY-MM-DD')
    }
     this.router.navigateByUrl(`/utilities/reconciliation?start_date=${values.start_date}&end_date=${values.end_date}&supplier=${values.supplier}&airline=${values.airline}&all=${values.all}&ticket=${values.ticket}&refund=${values.refund}`)
    //  this.reset()
   }


   getSupplier(){

    return this.commonService.getRequest(`${environment.okountUrl}/setting/reconciliation`).subscribe(res => {
		  this.supplierlist = res
		}, err => {
		  console.error(err)
		})

    // if (value.length > 2) {
		// 	this.commonService.getRequest(`${environment.okountUrl}/setting/reconciliation`).subscribe(res => {
		// 		this.supplierArr = res
		// 	}, err => {

		// 	})
		// }
   }


	getAirline(value,bindValue=false) {
		if (value.length > 2) {
			this.commonService.getOkount(`airline/autocomplete?name_like=${value}`).subscribe(res => {
				this.airlineArr = res
        if(bindValue && this.airlineArr.length>0){
          this.tdsreportFilterForm.controls['airline'].setValue(this.airlineArr[0].account_code)
          this.tdsreportFilterForm.controls['airline_name'].setValue(this.airlineArr[0].name)
        }
			}, err => {

			})
		}
	}

   reset(){
    GlobalVariable.openModalStatus = false;
     this.tdsreportFilterForm.reset()
   }

   clickSearchButton(){
		this.searchButton.nativeElement.click()
  }
  
  focusAirlineAc(){
		this.airlineAC.focus();
	}

  selectAirline(value,formControlName){
		try {
			this.tdsreportFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}

}
