
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
  <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
    <div class="d-flex flex-y-center">
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
          alertType=='success-box' ? 'green-sucess.svg' : 
          alertType=='warning-box' ? 'red-warning.svg' : 
          null }}"
        width="20px"
        height="20px"
        alt="alert-logo"
        class="mr-20"
      />
      <div class="ml-10">
        <h4 class="m-0 text-size-18 text-bold"
        [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
          {{alertType === "error-box"
          ? "Error"
          : alertType === "warning-box"
          ? "Warning"
          : alertType === "success-box"
          ? "Success"
          : null}}
        </h4>
        <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
         {{message}}
        </small>
      </div>
    </div>
    <img
      src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
      alt="close-btn"
      (click)="hideError()"
      className=" close-btn"
    />
  </div>
</div>

<button type="button" hidden  #openSuccessModal data-toggle="modal" data-target="#successModal"></button>
<!-- Modal -->
<div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
    <div class="modal-dialog transaction-creation-modal">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <span class="messageFont">
                    <span>
                        {{message}} 
                    </span>
                    <a 
                        *ngIf="createdAirlineCode != ''"
                         href="javascript:void(0)"
                        (click)="viewAirline(createdAirlineId)">
                            {{createdAirlineCode}}
                    </a>
                </span>
            <button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
            </div>

            <div class="modal-body">
                <p>Do you want to create new Airline or view newly created Airline ?</p>
            </div>
          
            <div class="modal-footer justify-content-center" >
                <button tabIndex="1" tabindex="1" type="button" class="btn-style bg-blue" (click)="viewAirline(createdAirlineId)">
                    View
                </button>
                <button tabIndex="3" tabindex="3" type="button" class="btn-style bg-green" (click)="closeSuccesModal()">
                    Create New
                </button>
            </div>
        </div>
    </div>
</div>


<div class="ledger-container">
  <div class="row tab-nav pl-0 pr-0">
    <!-- <div class="col-sm-4 pl-0">
        <ul class="list-inline list-unstyled p-0 m-0">
            <li class="active page-title text-uppercase cursor"> View Client</li>
        </ul>
    </div> -->
    <div class="col-sm-4 ">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
          <li [routerLink]="['/']"
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Master</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li [routerLink]="['/']"
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Airline</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li class="text-grey text-capitalize cursor text-primary transition"> Create Airline</li>
      </ul>
  </div>
</div>

    <form [formGroup]="airlineForm"  [ngClass]="{'d-none': !newAirline}">
      <div class="custom-form form-container " style="    width: 600px;
      margin: 20px 15px;">


      <div class="text-input-group d-flex justify-content-between">

        <div class="select-field text-input-field" [ngClass]="{'mr-2': isHQBranch}">
          <select class="input-field" tabIndex="1" oninput="this.value = this.value.toUpperCase()"
            formControlName="parent_id" [ngClass]="{'error-field': (!airlineForm.controls.parent_id.valid && submitted),
            'success-field' : airlineForm.controls.parent_id.valid && airlineForm.controls.parent_id.dirty}">
            <option selected disabled>SELECT</option>
            <!-- <ng-container *ngIf="groupList.length > 0"> -->
            <option *ngFor="let item of groupList" value="{{item.id}}">{{item.name}}</option>
            <!-- </ng-container> -->
          </select>
          <label
            [ngClass]="{'error-label': airlineForm.controls.parent_id.invalid && airlineForm.controls.parent_id.dirty || (!airlineForm.controls.parent_id.valid && submitted)}">Group
            <ng-container
              *ngIf="airlineForm.controls.parent_id.invalid && airlineForm.controls.parent_id.dirty || (!airlineForm.controls.parent_id.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Group Required" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div style="width: 150px;" class="select-field text-input-field" *ngIf="isHQBranch">
          <select class="input-field" formControlName="sync_in_branches">
            <option value="">Select</option>
            <option value="true">YES</option>
            <option value="false">NO</option>
          </select>
          <label>All Branches</label>
        </div>

      </div>


      <div class="text-field-group d-flex justify-content-between">
        <div class="text-input-field mr-2" colspan="4">
          <input class="input-field" tabIndex="2" oninput="this.value = this.value.toUpperCase()"
            formControlName="account_name" type="text" [ngClass]="{'error-field': airlineForm.controls.account_name.invalid && airlineForm.controls.account_name.dirty || (!airlineForm.controls.account_name.valid && submitted) ,
              'success-field' : airlineForm.controls.account_name.valid && airlineForm.controls.account_name.dirty}" />
          <label
            [ngClass]="{'error-label': airlineForm.controls.account_name.invalid && airlineForm.controls.account_name.dirty || (!airlineForm.controls.account_name.valid && submitted)}">Airline Name
            <span class="text-danger">*</span>

            <ng-container
              *ngIf="airlineForm.controls.account_name.invalid && airlineForm.controls.account_name.dirty || (!airlineForm.controls.account_name.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid telephone Number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
       
        <div style="width: 200px;" class="text-input-field mr-2">
          <input class="input-field" tabIndex="3" oninput="this.value = this.value.toUpperCase()"
            formControlName="2_ltr_code" type="text" />
          <label>2 LTR-CODE</label>
        </div>
        <div style="width: 200px;" class="text-input-field mr-2">
          <input tabIndex="4" class="input-field" oninput="this.value = this.value.toUpperCase()"
            formControlName="3_ltr_code" type="text" />
          <label>3 LTR-CODE</label>
        </div>
        <div style="width: 250px;" class="text-input-field">
          <input class="input-field" tabIndex="3" oninput="this.value = this.value.toUpperCase()"
          value ="{{airlineForm.value['2_ltr_code'] + airlineForm.value['3_ltr_code']}}" type="text"  disabled />
          <label>Airline Code</label>
        </div>

      </div>

      <div class="text-input-field">
        <input tabIndex="5" class="input-field" oninput="this.value = this.value.toUpperCase()" name="address_1"
          formControlName="address_1" type="text" [ngClass]="{'error-field': airlineForm.controls.address_1.invalid && airlineForm.controls.address_1.dirty || (!airlineForm.controls.address_1.valid && submitted) ,
                    'success-field' : airlineForm.controls.address_1.valid && airlineForm.controls.address_1.dirty}" />
        <label
          [ngClass]="{'error-label': airlineForm.controls.address_1.invalid && airlineForm.controls.address_1.dirty || (!airlineForm.controls.address_1.valid && submitted)}">Address

          <ng-container
            *ngIf="airlineForm.controls.address_1.invalid && airlineForm.controls.address_1.dirty || (!airlineForm.controls.address_1.valid && submitted)">
            <i class="fa fa-info-circle ml-2" title="Invalid telephone Number" aria-hidden="true"></i>
          </ng-container>
        </label>
      </div>

      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
          <select tabIndex="6" class="input-field" oninput="this.value = this.value.toUpperCase()"
            formControlName="country_id" (change)="getStates($event.target.value)">
            <option *ngFor="let country of countryArr" value="{{country.location_id}}">{{country.name}}</option>
          </select>
          <label>Country</label>
        </div>

        <div class="text-input-field mr-2">
          <select tabIndex="7" class="input-field" oninput="this.value = this.value.toUpperCase()"
            formControlName="state_id" (change)="getCities($event.target.value)">
            <option *ngFor="let state of stateArr" value="{{state.location_id}}">{{state.name}}</option>
          </select>
          <label>State</label>
        </div>

        <div class="text-input-field">
          <select tabIndex="8" class="input-field" oninput="this.value = this.value.toUpperCase()"
            formControlName="city_id" (change)="getCityName($event.target.value)">
            <option *ngFor="let city of cityArr" value="{{city.location_id}}">{{city.name}}</option>
          </select>
          <label>City</label>
        </div>
      </div>


      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field" *ngIf="isTenantIndian">
          <input class="input-field" onblur="this.value = this.value.toUpperCase()" name="gst_number" tabIndex="7"
            formControlName="gst_number" type="text" maxlength="15"
            pattern="^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$"
            [ngClass]="{'error-field': airlineForm.controls.gst_number.invalid && airlineForm.controls.gst_number.dirty || (!airlineForm.controls.gst_number.valid && submitted),
            'success-field' : airlineForm.controls.gst_number.valid && airlineForm.controls.gst_number.dirty}" />
          <label
            [ngClass]="{'error-label': airlineForm.controls.gst_number.invalid && airlineForm.controls.gst_number.dirty} || (!airlineForm.controls.gst_number.valid && submitted)">
            GST NUMBER
            <ng-container
              *ngIf="airlineForm.controls.gst_number.invalid && airlineForm.controls.gst_number.dirty || (!airlineForm.controls.gst_number.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid GST Number ( ex: 12ABCDE1234F1Z3)"
                aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field" *ngIf="!isTenantIndian">
          <input class="input-field" onblur="this.value = this.value.toUpperCase()" name="vat_number" tabIndex="7"
            formControlName="vat_number" type="text" maxlength="20"
            [ngClass]="{'error-field': airlineForm.controls.vat_number.invalid && airlineForm.controls.vat_number.dirty || (!airlineForm.controls.vat_number.valid && submitted),
            'success-field' : airlineForm.controls.vat_number.valid && airlineForm.controls.vat_number.dirty}" />
          <label
            [ngClass]="{'error-label': airlineForm.controls.vat_number.invalid && airlineForm.controls.vat_number.dirty} || (!airlineForm.controls.vat_number.valid && submitted)">
            {{vatAlternetname}} NUMBER
          </label>
        </div>

      </div>



      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
          <input tabIndex="9" class="input-field" oninput="this.value = this.value.toUpperCase()"
            formControlName="telephone" type="text" />
          <label>Telephone</label>
        </div>

        <div class="text-input-field">
          <input tabIndex="10" class="input-field" oninput="this.value = this.value.toUpperCase()" name="mobile_no"
            minlength="10" maxlength="10" formControlName="mobile_no" type="text" pattern="([0-9]+)" [ngClass]="{'error-field': airlineForm.controls.mobile_no.invalid && airlineForm.controls.mobile_no.dirty || (!airlineForm.controls.mobile_no.valid && submitted),
                'success-field' : airlineForm.controls.mobile_no.valid && airlineForm.controls.mobile_no.dirty}" />
          <label
            [ngClass]="{'error-label': airlineForm.controls.mobile_no.invalid && airlineForm.controls.mobile_no.dirty  || (!airlineForm.controls.mobile_no.valid && submitted)}">Mobile
            No.
            <ng-container
              *ngIf="airlineForm.controls.mobile_no.invalid && airlineForm.controls.mobile_no.dirty || (!airlineForm.controls.mobile_no.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid Mobile Number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
      </div>

      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
          <input tabIndex="11" class="input-field" oninput="this.value = this.value.toUpperCase()" name="primary_email"
            formControlName="primary_email" type="text" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$"
            [ngClass]="{'error-field': airlineForm.controls.primary_email.invalid && airlineForm.controls.primary_email.dirty || (!airlineForm.controls.primary_email.valid && submitted),
          'success-field' : airlineForm.controls.primary_email.valid && airlineForm.controls.primary_email.dirty}" />
          <label
            [ngClass]="{'error-label': airlineForm.controls.primary_email.invalid && airlineForm.controls.primary_email.dirty || (!airlineForm.controls.primary_email.valid && submitted)}">Email
            #1 <ng-container
              *ngIf="airlineForm.controls.primary_email.invalid && airlineForm.controls.primary_email.dirty || (!airlineForm.controls.primary_email.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid Email" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field">
          <input tabIndex="12" class="input-field" oninput="this.value = this.value.toUpperCase()"
            name="secondary_email" formControlName="secondary_email" type="text"
            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$"
            [ngClass]="{'error-field': airlineForm.controls.secondary_email.invalid && airlineForm.controls.secondary_email.dirty,
                'success-field' : airlineForm.controls.secondary_email.valid && airlineForm.controls.secondary_email.dirty}" />
          <label
            [ngClass]="{'error-label': airlineForm.controls.secondary_email.invalid && airlineForm.controls.secondary_email.dirty}">Email
            #2 <ng-container
              *ngIf="airlineForm.controls.secondary_email.invalid && airlineForm.controls.secondary_email.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid Email" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
      </div>



      <div class="text-input-group d-flex justify-content-between">

        <div class="w-50 text-input-field mr-2">
          <input tabIndex="13" class="input-field" oninput="this.value = this.value.toUpperCase()" name="pan_number"
            formControlName="pan_number" type="text" maxlength="10" pattern="([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$"
            [ngClass]="{'error-field': airlineForm.controls.pan_number.invalid && airlineForm.controls.pan_number.dirty,
                  'success-field' : airlineForm.controls.pan_number.valid && airlineForm.controls.pan_number.dirty}" />
          <label
            [ngClass]="{'error-label': airlineForm.controls.pan_number.invalid && airlineForm.controls.pan_number.dirty}">PAN
            NO. <ng-container *ngIf="airlineForm.controls.pan_number.invalid && airlineForm.controls.pan_number.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid pan number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="w-50 d-flex">
          <div class="text-input-field mr-2">
            <input tabIndex="14" class="input-field" oninput="this.value = this.value.toUpperCase()" name="balance"
              formControlName="balance" pattern="^\d*(\.\d{0,8})?$" type="text" [ngClass]="{'error-field': airlineForm.controls.balance.invalid && airlineForm.controls.balance.dirty || (!airlineForm.controls.balance.valid && submitted),
                        'success-field' : airlineForm.controls.balance.valid && airlineForm.controls.balance.dirty}" />
            <label
              [ngClass]="{'error-label': airlineForm.controls.balance.invalid && airlineForm.controls.balance.dirty} || (!airlineForm.controls.balance.valid && submitted)">Opening
              Balance
              <ng-container
                *ngIf="airlineForm.controls.balance.invalid && airlineForm.controls.balance.dirty  || (!airlineForm.controls.balance.valid && submitted)">
                <i class="fa fa-info-circle ml-2" title="Invalid opening balance" aria-hidden="true"></i>
              </ng-container>
            </label>
          </div>
          <div style="width: 60px;" class="select-field text-input-field">
            <select tabIndex="15" class="input-field" formControlName="balance_type">
              <option value="debit" selected>DR</option>
              <option value="credit">CR</option>
            </select>
          </div>
        </div>
      </div>
      <div class="text-input-field">
        <textarea tabIndex="16" class="input-field" formControlName="remarks"
          oninput="this.value = this.value.toUpperCase()" rows="2"></textarea>
        <!-- <input tabIndex="9" class="input-field" oninput="this.value = this.value.toUpperCase()" formControlName="remarks"
            type="text" /> -->
        <label>Remarks</label>
      </div>
      <!-- <span class="info alert-warning"
        *ngIf="showMessage && alertType == 'success-box' && createdAirlineId != ''">Airline Created. <a
          [routerLink]="['/master/policy/default-airline-policy', createdAirlineId]">Click here to update
          policy.</a></span> -->
</div>
<div class="btn-field bottom-btns-container mt-30 d-flex align-item-center justify-content-end  ml-0 mr-0"> 
  <div>
    <button type="button" class="btn-style bg-green mr-3" (click)="reset()">Reset</button>
    <button type="button" class="btn-style bg-purple" tabIndex="17" #confirmModal (click)="openConfirmModal()"
            data-toggle="modal" data-target="#confirmationModal">Save</button>
  </div>
 
        
      </div>

    </form>
</div>

<div class="modal fade" id="confirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4>Confirmation</h4>
        <button type="button" class="close" #closeModal data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Create {{airlineForm?.value?.account_name}} ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" data-dismiss="modal">Close</button>
        <button type="button" class="btn-style bg-green" (click)="checkBalance()" tabIndex="18">Yes</button>
      </div>
    </div>
  </div>
</div>

<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateconfirmationModal"></button>
<div id="updateconfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeUpdateModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        There is a mismatch of amount {{remainingAmount}} in your opening balance. It will be adjusted in the Opening Balance Adjustment account. Would you like to proceed?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" 
        data-dismiss="modal">Close</button>
        <button type="button" class="btn-style bg-green" (click)="updateOpeningBalancecombine()" >UPDATE</button>
      </div>
    </div>
  </div>
</div>