<form [formGroup]="generalFilterForm">

  <div class="flexbox aff-info">
    <div class="flexbox aff-info-inner">

      <div class="flexbox">

        <div class="input-fiel" searchtabindex="1" (focus)="focusClientAc()">
          <label class="ngauto-label" for="affname">Account </label>
          <div class="ng-autocomplete">
            <ng-autocomplete #clientAC [data]="generalArr" [searchKeyword]="keyword"
              (inputChanged)='getGeneral($event,false)' historyIdentifier="generalArr" [itemTemplate]="itemTemplate"
              formControlName="account_name" (selected)="selectAccount($event,'account')"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                                  ({{item.account_code}})</span><br />
                              <span class="colr-2">{{item.primary_email}}</span><br />'>
              </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>

        <div class="input-fiel">
          <input class="input-ui" id="acc_c" oninput="this.value = this.value.toUpperCase()" type="text"
            placeholder="Account Code" searchtabindex="2" formControlName="account_code" /><label for="acc_c">Account
            Code</label>
        </div>


      </div>

      <div class="flexbox mt-3">


        <div class="input-fiel">
          <input class="input-ui" id="typ" oninput="this.value = this.value.toUpperCase()" type="text"
            searchtabindex="3" formControlName="type" placeholder="Type" /><label for="typ">Type</label>
        </div>
      </div>

      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
            searchtabindex="4" #searchButton (keyup.enter)="clickSearchButton()"
            (click)="search(generalFilterForm.value)"> Search</button>
          <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
        </div>
      </div>

    </div>

  </div>
</form>