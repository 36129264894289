import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountBookRoutingModule } from './account-book-routing.module';
import { AccountBookComponent } from './account-book.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AccountBookComponent],
  imports: [
    CommonModule,
    AccountBookRoutingModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AccountBookModule { }
