import { Component, HostListener, OnInit, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { DownloadService } from 'src/app/shared/download.service';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../transactions/store/actions';
import { InvoiceComponent } from 'src/app/transactions/invoice/invoice.component';
import { Tab } from 'src/app/shared/model/tab.model';
import { TabService } from 'src/app/shared/services/tab.service';
import { MsInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/ms-invoice/ms-invoice.component';
import { GstInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/gst-invoice/gst-invoice.component';
import { V2CreatePaymentComponent } from 'src/app/transactions/payment/v2-create-payment/v2-create-payment.component';
import { V2CreateReceiptComponent } from 'src/app/transactions/receipt/v2-create-receipt/v2-create-receipt.component';
import { CreateAdmAcmComponent } from 'src/app/transactions/adm-acm/create-adm-acm/create-adm-acm.component';
import { AddEntryJvComponent } from 'src/app/transactions/jv/add-entry-jv/add-entry-jv.component';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';

@Component({
  selector: 'app-psr-detailed-report',
  templateUrl: './psr-detailed-report.component.html',
  styleUrls: ['./psr-detailed-report.component.scss']
})
export class PsrDetailedReportComponent implements OnInit {
  @Input() data;
  isPrint
  companyDetail: any;
  duration: any;
  valid: boolean;
  allInvoiceArr: any;
  invoiceArr: any;
  innerHeight: string;
  currentIndex: any;
  reInitialise: boolean;
  navigationSubscription: any;
  advancedSearchSubscription: any;
  showPrintData: boolean;
  listOfColumns =[];
  showData: { invoice: boolean; credit_note: boolean; adm: boolean; acm: boolean; };
  listOfColumnsParent = []
  queryParams: any;
  loader: boolean = false;
  listOfData= []
  constructor(
    public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private route: ActivatedRoute,
		private router: Router,
		private downloadService: DownloadService,
		private store: Store<InitialState>,
		private tabService: TabService
  ) { 
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
  }

  ngOnInit() {
	const data = [];
    for (let i = 0; i < 100; i++) {
      data.push({
        name: 'John Brown',
        age: i + 1,
        street: 'Lake Park',
        building: 'C',
        number: 2035,
        companyAddress: 'Lake Street 42',
        companyName: 'SoftLake Co',
        gender: 'M'
      });
    }
    this.listOfData = data;

	    let queryParams = this.route.queryParams['value']
		this.queryParams = queryParams
		let queryData = Object.assign({}, queryParams)

		if(this.data) {
			queryData['airline_code'] = this.data.airlineCode
			queryData['start_date'] = this.data.start_date
			queryData['end_date'] = this.data.end_date
		}

		setTimeout(() => {
			this.spinner.hide();
		}, 1000);

		this.search(queryData)
		localStorage.setItem('activePsrDetailQueryParams', JSON.stringify(queryData))

		this.reInitialise = true
    this.listOfColumns = [
			{name: '', class:' ticket-no border-left '},
			{name: '', class:'ticket-date  border-left'},
			{name: 'Cash',class:'text-center border-top border-left ticket-cash'},
			{name: 'Credit',class:'text-center border-left border-top ticket-credit'},
			{name: 'Fare Value'	,class:'text-right border-top border-left ticket-fare-value'},
			{name: 'YQ Tax',class:'text-center  border-left  border-top ticket-yq-tax'},
			{name: 'Tax',class:'text-center  border-left border-top ticket-tax'},
			{name: 'RAF',class:'text-right  border-left border-top ticket-raf'},
			{name: 'Rate',  class:'text-center  border-left  border-top ticket-rate'},
			{name: 'AMOUNT',class:'text-center  border-left border-top ticket-amt'},
			{name: 'Rate',class:'text-center  border-left  border-top ticket-rate'},
			{name: 'Amount',class:'text-center  border-left border-top ticket-amt-two'},
			{name: 'Net Payable',class:'border-left border-left border-top ticket-payable'},
			{name: 'BSP Amount',class:'border-left   border-left border-top ticket-bsp-amt'},
			{name: 'Remarks',class:' ticket-remark border-top border-left'},
		]
    this.listOfColumnsParent = [
			{name: 'Ticket Number', class:'border-left border-bottom ticket-no'},
			{name: 'Issue date', class:'border-left border-bottom ticket-date'},
			{name: 'Transaction Values', class:'text-center border-left ticket-trans-val',colspan:2},
			{name: 'Commisionble',class:'text-right border-left ticket-commisionble'},
			{name: 'Transaction Values', class:'text-center border-left ticket-trans-value',colspan:2},
			{name: '',class:'ticket-raf text-right border-left '},
			{name: 'STD Commission',class:'text-center border-left ticket-std',colspan:2},
			{name: 'O/R Incentives',class:'text-center border-left ticket-incentives',colspan:2},
			{name: '',class:'text-right border-left ',colspan:2},
			{name: '',class:'text-right border-left',colspan:1}
		]
    this.showData = {
      invoice : true,
      credit_note : true,
      adm : true,
      acm : true
    }

	}
  initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}
  ngAfterViewInit() {
		this.advancedSearchSubscription = this.commonService.advancedSearch.subscribe(status => {
			console.log('Is modal opened : ', status)
			// this.isAdvancedSearchOpen = status
		})
	}
	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
		if (this.advancedSearchSubscription) {
			this.advancedSearchSubscription.unsubscribe()
		}
	}
  search(queryParams) {
	    this.loader = true;
		let url = `${environment.okountUrl}/reports/reconciliation/psr-detail`

		this.commonService.getRequestWithQueryParamsNoLoader(`${url}`, queryParams).subscribe(res => {
			this.loader = false;
			this.companyDetail = res['company_details']
			this.allInvoiceArr = res['data']
			this.invoiceArr = res['data']
			this.duration = res['duration']
			this.valid = true
			// this.totalAmountObj = this.getAmount(res['data'])
			this.setHeight(window.innerHeight)
		})
	}

  @HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setHeight(event.target.innerHeight)
	}

  setHeight(height) {
		
		if (this.invoiceArr != undefined) {
			if (height == undefined) {
				height = window.innerHeight
			}
			if (this.invoiceArr.length <= 20) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = height - 210 + 'px';
			}
		}
	}


  exportAsXLSX(): void {
		this.spinner.show()
		let xlsxData = [];
		
	    let allItems = ['invoice', 'credit_note', 'adm', 'acm'];
		for(let item of allItems) {
			this.allInvoiceArr[item] && this.allInvoiceArr[item].length>0 && this.allInvoiceArr[item].forEach(data => {
				let obj = {};
				obj["TICKET NUMBER"] = data.ticket_no ? data.ticket_no : ''
				obj["ISSUE DATE"] = data.issue_date ?  moment(data.issue_date).format('DD/MM/YYYY') : ''
				obj["TRANSACTION VALUES - CASH"] = data.cash ? Number(data.cash) : ''
				obj["TRANSACTION VALUES - CREDIT"] = data.credit ? Number(data.credit) : ''
				obj["COMMISIONBLE FARE VALUES"] = data.base ? Number(data.base) : ''
				obj["TRANSACTION VALUES - YQ"] = data.yq ? Number(data.yq) : ''
				obj["TRANSACTION VALUES - TAX"] = data.tax ? Number(data.tax) : ''
				obj["RAF"] = data.raf ? Number(data.raf) : ''
				obj["STD COMMISSION	- RATE"] = data.comm_iata_rate ? Number(data.comm_iata_rate) : ''
				obj["STD COMMISSION	- AMOUNT"] = data.comm_iata_amount ? Number(data.comm_iata_amount) : ''
				obj["O/R INCENTIVES	- RATE"] = data.comm_cb_rate ? Number(data.comm_cb_rate): ''
				obj["O/R INCENTIVES	- AMOUNT"] = data.comm_cb_amount ? Number(data.comm_cb_amount) : ''
				obj["NET PAYABLE"] = data.net_paid ? Number(data.net_paid): ''	
				obj["BSP AMOUNT"] = data.net_paid ? Number(data.net_paid) : ''			
				obj["REMARKS"] = data.remarks ? data.remarks : ''			
				xlsxData.push(obj);
			});

			xlsxData.push({});
			this.allInvoiceArr[`${item}_total`] && this.allInvoiceArr[`${item}_total`].length>0 && this.allInvoiceArr[`${item}_total`].forEach(data => {
				let obj = {};
				obj["TICKET NUMBER"] = `Nos. ${data.count}`
				obj["ISSUE DATE"] = ''
				obj["TRANSACTION VALUES - CASH"] = data.cash ? Number(data.cash) : ''
				obj["TRANSACTION VALUES - CREDIT"] = data.credit ? Number(data.credit) : ''
				obj["COMMISIONBLE FARE VALUES"] = data.base ? Number(data.base) : ''
				obj["TRANSACTION VALUES - YQ"] = data.yq ? Number(data.yq) : ''
				obj["TRANSACTION VALUES - TAX"] = data.tax ? Number(data.tax) : ''
				obj["RAF"] = data.raf ? Number(data.raf) : ''
				obj["STD COMMISSION	- RATE"] = data.comm_iata_rate ? Number(data.comm_iata_rate) : ''
				obj["STD COMMISSION	- AMOUNT"] = data.comm_iata_amount ? Number(data.comm_iata_amount) : ''
				obj["O/R INCENTIVES	- RATE"] = data?.comm_cb_rate 
				obj["O/R INCENTIVES	- AMOUNT"] = data?.comm_cb_amount
				obj["NET PAYABLE"] = data.net_paid ? Number(data.net_paid): ''	
				obj["BSP AMOUNT"] = data.net_paid ? Number(data.net_paid): ''	
				obj["REMARKS"] = ''			
				xlsxData.push(obj);
			});
			xlsxData.push({});
		}
		
		this.downloadService.exportAsXLSX(`PSR Detailed Report :`, xlsxData);
		this.spinner.hide()
	}

	callPdfGeneratorApi() {
		this.showPrintData = true
		setTimeout(() => {
			window.print()
			this.showPrintData = false
		}, 100);
	}

	downloadPdf(start_date, end_date) {
		this.commonService.downloadPDF(`${environment.okountUrl}/reports/reconciliation/psr-detail/download`, this.queryParams).subscribe((fileBlob:any) =>{
			let fileName = `PSR DETAILED REPORT FROM ${start_date} TO ${end_date}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}

  handleFilter(type){
    if(type == 'all'){
      this.showData = {
        invoice : true,
        credit_note : true,
        adm : true,
        acm : true
      }
    }else{
      this.showData[type] = ! this.showData[type]
    }
  }

  openAdvanceSearchModal(){
	this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'psr-report'}));	
	}

	openViewMode(reference, referenceType) {
		    let isInvoice = false
			let isMsInvoice = false
			let isGstInvoice = false
			let isPayment = false
			let isReceipt = false
		    let isAdmAcm = false
			let isJv = false

		if (reference != undefined) {
			let url = '#/transactions/'
			let viewMode = 'view'
			if (referenceType == 'invoice') {
				url = '#/transactions/invoice'
				viewMode = 'view'
				if(!reference.includes('MIN') && !reference.includes('MZC') && !reference.includes('GSP') && !reference.includes('GSS')) {
					isInvoice = true 
				} 
			}
			if (referenceType == 'invoice' && (reference.includes('MIN') || reference.includes('MZC'))) {
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
				if(!reference.includes('GSP') && !reference.includes('GSS')) {
					isMsInvoice = true
				} 
			}
			if (referenceType == 'invoice' && (reference.includes('GSP') || reference.includes('GSS'))) {
				url = '#/transactions/ms/gst-invoice'
				viewMode = 'view'
				if(!reference.includes('MIN') && !reference.includes('MZC')) {
					isGstInvoice = true
				} 
			}
			if (referenceType == 'credit_note') {
				url = '#/transactions/credit-note'
				viewMode = 'view'
			}
			if (referenceType == 'payment') {
				url = '#/transactions/payment'
				viewMode = 'view'
				isPayment = true
			}
			if (referenceType == 'receipt') {
				url = '#/transactions/receipt'
				viewMode = 'view'
				isReceipt = true
			}
			if (referenceType == 'jv') {
				url = '#/transactions/jv'
				viewMode = 'update'
				isJv = true
			}
			if (referenceType == 'adm' || referenceType == 'acm') {
				url = '#/transactions/adm-acm'
				viewMode = 'view'
				isAdmAcm = true
			}

			let path = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${url}/${viewMode}/${reference}`

			if(window.location.protocol != 'file:'){
				let title =''
				let referenceSplit = reference.split('-')
				title += referenceSplit[0]
				title +='...'
				title += referenceSplit[referenceSplit.length-1]
				if(isInvoice) {
					this.tabService.addTab(
						new Tab(InvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
						);
				} else if(isMsInvoice) {
					this.tabService.addTab(
						new Tab(MsInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
						);
				} else if(isGstInvoice) {
					this.tabService.addTab(
						new Tab(GstInvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
						);
				} else if(isPayment) {
					this.tabService.addTab(
						new Tab(V2CreatePaymentComponent, `${title}`, { paymentRef: reference ,mode : 'view' }, reference)
						);
				}
				else if(isReceipt) {
					this.tabService.addTab(
					new Tab(V2CreateReceiptComponent, `${title}`, { receiptRef: reference ,mode : 'view' }, reference)
					);
				} else if(isAdmAcm) {
					this.tabService.addTab(
						new Tab(CreateAdmAcmComponent, `${title}`, { adm_acm_number: reference, mode : 'view' }, reference)
					);
				} else if(isJv) {
					this.tabService.addTab(
						new Tab(AddEntryJvComponent, `${title}`, { jv_code: reference }, reference)
						);
				} else {
				    window.open(path,'_blank')		
				}			
			}else{
				var customeEventHandler = new CustomEvent("handle-window-open", {
					"detail": {
						hashPath : path
					}
				  });
				window.dispatchEvent(customeEventHandler)
			}
		}
	}
}

