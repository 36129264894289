<div class="ledger-container" *ngIf="valid">
    <div class="voucher-container">
        <div class="row tab-nav pl-0 pr-0">
            <div class="col-sm-4">
              <ul
                class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0"
              >
                <li
                  [routerLink]="['/']"
                  class="text-grey text-capitalize cursor hover-text-primary transition"
                  [ngClass]="{ 'd-none': isPrint == 'true' }"
                >
                  Analysis
                </li>
                <li>
                  <img
                    src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg"
                    alt=""
                    [ngClass]="{ 'd-none': isPrint == 'true' }"
                  />
                </li>
                <li
                  class="text-grey text-capitalize cursor text-primary transition"
                  [ngClass]="{ 'd-none': isPrint == 'false' }"
                  [ngClass]="{ 'd-none': isPrint == 'true' }"
                >
                PSR Report -
                </li>
              </ul>
            </div>
            <div class="col-sm-4 text-center">
              <span class="rec-date d-block text-center">
                <div class="noWrap justify-content-around">
                    <ng-container *ngIf="duration != undefined">
                        <div class="cname">From
                            <h5 style="margin-right: 5px;" class="mb-0  d-inline"><span class="date text-bold text-orange"
                                    *ngIf="duration.from_date != ''">
                                    {{duration?.from_date | date : 'd MMMM, y'}} 
                                </span>
                                <span class="date text-bold" *ngIf="duration.from_date != ''">
                                    To
                                </span>
                                <span class="date text-bold" *ngIf="duration.from_date == ''">
                                    Till Date
                                </span>
                                <span class="date text-bold text-orange">
                                    {{duration?.to_date | date : 'd MMMM, y'}}
                                </span>
                            </h5>
                            <button
                            [ngClass]="{ 'd-none': isPrint == 'true' }"
                            title="Custom Search"
                            class="btn save-as text-bold bg-whiteSmoke"
                            type="button"
                            (click)="openAdvanceSearchModal()"
                          >
                            <img
                              src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg"
                              alt=""
                              width="15px"
                            />
                          </button>
                        </div>
                    </ng-container>	
                </div>
              </span>
            </div>
            <div class="col-sm-4 d-flex align-item-center justify-content-end">
              <div
              class="page-search-box input-group d-flex align-item-center" 
        [ngClass]="{ 'd-none': isPrint == 'true' }"
              style="height: fit-content; text-align: left"
            >
              <input
                type="text"
                class="form-control"
                [(ngModel)]="onSearch"
                oninput="this.value = this.value.toUpperCase()"
                (ngModelChange)="this.onSearchFilter.next($event)"
                placeholder="search"
                style="text-align: left"
              />
            </div>
             
              <div class="dropdown-btn position-relative" 
              [ngClass]="{ 'd-none': isPrint == 'true' }">
                <button title="More..." class="btn-style mt-0 more-btn">
                  <span class="dot"></span><span class="dot"></span
                  ><span class="dot"></span>
                </button>
                <ul class="list-unstyled pl-0 dropdown-box">
                  
                  <li (click)="downloadPdf(duration.from_date ,duration.to_date)" >
                    <img
                      src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg"
                      alt=""
                    />
                    <span class="ml-2">Save As Pdf</span>
                  </li>
                  <li (click)="exportAsXLSX()">
                  <span class="ml-2">EXCEL</span>
                </li>
                </ul>
              </div>
            </div>
          </div>

        <div class="table-section mt-1 position-relative psrDetail">
                <nz-table #psrData [nzData]="invoiceArr"
                  [nzLoading]="loader"
                  [nzFrontPagination]="false">
                    <thead>
                      <tr #tableHeader tabIndex="0">
                          <th  class="text-left ellipse-text" >Airline Detail</th>
                          <th style="max-width: 130px;" class="text-right ellipse-text">Sales</th>
                          <th style="max-width: 130px;" class="text-right ellipse-text">Refunds</th>
                          <th style="max-width: 130px;" class="text-right ellipse-text">ADM</th>
                          <th style="max-width: 130px;" class="text-right ellipse-text">ACM</th>
                          <th style="max-width: 130px;" class="text-right ellipse-text">Net Payable</th>
                      </tr>
                  </thead>

                  <ng-container *ngIf="psrData && psrData['data'] && psrData['data'].length>0 else noResult">
                        <tbody class="scroll-body main-panel border-0" style="    max-height: calc(100vh - 152px); overflow-y: auto;"
                        (window:keydown)="onKeyDown($event)">
                        <ng-container *ngFor="let invoice of psrData['data'] ; let i = index;">
                            <tr class="field-table-input" [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}"
                            (keyup.enter)="openDetailedPsr(invoice.sub_ledger)">
                                <td class="text-left ellipse-text" 
                                    (click)="moveToIndex(i)"> <span class="ellipse-text">{{invoice?.account_name}}</span> </td>
                                <td style="max-width: 130px;" class="text-right"  (click)="moveToIndex(i)"><span class="ellipse-text">{{invoice?.sales}}</span>
                                </td>
                                <td style="max-width: 130px;"  class="text-right " (click)="moveToIndex(i)">
                                  <span class="ellipse-text">{{invoice?.refunds}}</span></td>
                                <td style="max-width: 130px;" class="text-right "  (click)="moveToIndex(i)"><span class="ellipse-text">{{invoice?.adm}}</span>
                                </td>
                                <td style="max-width: 130px;" class="text-right "  (click)="moveToIndex(i)"><span class="ellipse-text">{{invoice?.acm}}</span>
                                </td>
                                <td style="max-width: 130px;" class="text-right   font-500" (click)="moveToIndex(i)">
                                  <span class="ellipse-text text-black">{{invoice?.net_payable}}</span></td>
                            </tr>
                        </ng-container>
                    </tbody>
                    <tfoot class=" border-0 total-amt-bg ">
                        <tr class="border-0" *ngIf="totalAmountObj != undefined">
                            <td  class="sub-total-col amt-total text-right" >
                            </td>
                            <td style="max-width: 130px;" class="sub-total-col amt-total text-right">
                                {{totalAmountObj?.sales  | number :'1.2-2'}}
                            </td>
                            <td style="max-width: 130px;" class="sub-total-col amt-total text-right">
                                {{totalAmountObj?.refunds  | number :'1.2-2'}}
                            </td>
                            <td style="max-width: 130px;" class="sub-total-col amt-total text-right">
                                {{totalAmountObj?.adm  | number :'1.2-2'}}
                            </td>
                            <td style="max-width: 130px;" class="sub-total-col amt-total text-right">
                                {{totalAmountObj?.acm  | number :'1.2-2'}}
                            </td>
                            <td style="max-width: 130px;" class="sub-total-col amt-total text-right">
                                {{totalAmountObj?.net_payable  | number :'1.2-2'}}
                            </td>
                          </tr>
                        </tfoot>
                  </ng-container>

                  <ng-template #noResult>
                    <div *ngIf="!loader" class="w-100 text-center" style="padding: 20px;">
                        <nz-empty nzNotFoundContent="No result Found"></nz-empty>
                    </div>
                </ng-template>

                </nz-table>
        </div>

    </div>
</div>