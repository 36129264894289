<form [formGroup]="airlinePlbForm">
    <div class="flexbox aff-info">
      <div class="flexbox aff-info-inner">
        <div class="flexbox ">
          <div class="input-fiel">
            <input class="input-ui" searchtabindex="1" formControlName="start_date" id="from_date" type="date"
                min="{{minDate}}" max="{{maxDate}}"
                [ngClass]="{'error-box': !airlinePlbForm.controls.end_date.valid}"
                />
            <label for="from_date">Starting Date</label>
  
          </div>
          <div class="input-fiel">
            <input class="input-ui" searchtabindex="2" formControlName="end_date" id="to_date" type="date"
                min="{{minDate}}" max="{{maxDate}}"
                [ngClass]="{'error-box': !airlinePlbForm.controls.end_date.valid}"
                />
            <label for="to_date">Ending Date</label>
          </div>
        </div>
  
        <div class="flexBox d-flex mt-20 ml-2 mr-2">
         
          <div class="select-field text-input-fiel">
            <select class="input-field" searchtabindex="5" formControlName="account_type">
              <option value="none">None</option>
              <option value="client">Client</option>
              <option value="airline">Airline</option>
              <option value="supplier">Supplier</option>
            </select>
            <label for="account_type">Account Type</label>
          </div>
        </div>
  
        <div class="d-flex mt-5 justify-content-center">
          <div class="input-fiel">
            <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
              searchtabindex="3" #searchButton (keyup.enter)="clickSearchButton()"
              [disabled]="airlinePlbForm.invalid"
              (click)="search(airlinePlbForm.value)">
              Search</button>
            <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
          </div>
        </div>
      </div>
    </div>
  </form>