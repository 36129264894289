import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateAdmAcmComponent } from './create-adm-acm/create-adm-acm.component';
import { PrintAdmAcmComponent } from './print-adm-acm/print-adm-acm.component';


// const routes: Routes = [];

// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule]
// })
// export class AdmAcmRoutingModule { }


const routes: Routes = [
	{
		path: '', component: CreateAdmAcmComponent
	},
	{
		path: 'print/:transactionNumber', component: PrintAdmAcmComponent
	},
	{
		path: ':mode/:transactionNumber', component: CreateAdmAcmComponent
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AdmAcmRoutingModule { }