import { ActionTypes } from './actions';
import * as moment from 'moment'

export const initialState = {
  advanceSearchModalStatus:false,
  narrationsArr:[]
};

export function TransactionReducers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SetOpenAdvanceSearchModal:
      return {
        ...state,
        advanceSearchModalStatus : action.payload
      }

    case ActionTypes.SetNarrtionsArr:
      console.log('SetNarrtionsArr',action.payload)
      return {
        ...state,
        narrationsArr : createNarrtionsArr(action.payload)
      }      
    default:
      return state;
  }
}


function createNarrtionsArr(payload){
    let {sectors,travellers,employee,pnr} = payload
  
    //variables for data handling
    var narrLine1="";
    var narrLine2="";
    var narrLine3="";
    var narrLine4 = `TICKET CREATED OFFLINE : ${employee}`
    if(sectors[0].txtAirlinePnrX){

    }
    if (travellers && travellers.length>0) {
        let travCount = travellers.length;
        if (travCount > 1) {
            narrLine1 = travellers[0].txtPassengerName + ' + ' + (travCount - 1);
        } else {
            narrLine1 = travellers[0].txtPassengerName;
        }
    }

    if (sectors && sectors.length>0) {
      let ind=0
      while(ind<sectors.length){
        let sector = sectors[ind]
        if(sector.txtSectorComb || sector.txtSectorComb2){
          let sectorArr = sector.txtSectorComb?sector.txtSectorComb.split("/"):sector.txtSectorComb2.split("/")
          if(narrLine2==""){
             narrLine2+=sectorArr[0]+"-"+sectorArr[1]
          }else{
            narrLine2+="-"+sectorArr[1]
          }
        }
        ind+=1
      }

      if(narrLine2 && travellers.length>0)narrLine2+=" | TKT NO - "+travellers[0].txtTicketNumber
    }

   
    let narr3Parts = [] 
    narr3Parts.push(`PNR - ${pnr}`)
    if(sectors && sectors.length > 0){
     
      if(sectors[0].txtAirlinePrefix && sectors[0].txtAirlinePrefix.length > 0){
        let narr3Part2Sub = `FLT NO - ${sectors[0].txtAirlinePrefix}`
        if(sectors[0].txtFlightNo && sectors[0].txtFlightNo.length>0){
          narr3Part2Sub +="-"+sectors[0].txtFlightNo
        }
        narr3Parts.push(narr3Part2Sub)
      }
      if (sectors[0].dateAirlineDepartInfo && sectors[0].dateAirlineDepartInfo.length>0) {
        narr3Parts.push(`TRV DT - ${moment(sectors[0].dateAirlineDepartInfo).format("DD-MM-YYYY")}`)
      }
    }
    narrLine3 = narr3Parts.join(' | ')
    return [narrLine1,narrLine2,narrLine3,narrLine4]
}



