import { Component, OnInit, HostListener, ViewChild, ElementRef, Input } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment-timezone';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import {  Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { TabService } from 'src/app/shared/services/tab.service';
import { Tab } from 'src/app/shared/model/tab.model';
import { InvoiceComponent } from 'src/app/transactions/invoice/invoice.component';
import { MiscInvoiceComponent } from 'src/app/transactions/misc-invoice/misc-invoice.component';
import { MsInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/ms-invoice/ms-invoice.component';
import { GstInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/gst-invoice/gst-invoice.component';
import { V2CreateReceiptComponent } from 'src/app/transactions/receipt/v2-create-receipt/v2-create-receipt.component';
import { TourComponent } from 'src/app/transactions/tour/tour.component';
import { InvoiceRefundComponent } from 'src/app/transactions/invoice-refund/invoice-refund.component';
import { OpenAdvanceSearchModal } from 'src/app/transactions/store/actions';

@Component({
	selector: 'app-adjust-b2cs-data',
  templateUrl: './adjust-b2cs-data.component.html',
  styleUrls: ['./adjust-b2cs-data.component.scss']
})
export class AdjustB2csDataComponent implements OnInit {
  allGstB2CSArr : any;
  gstB2CSArr: any;
  currentDate = moment().format('YYYY-MM-DD')
	listOfColumns =[]
	duration: any;
  
	loader = false;
  navigationSubscription;
  advancedSearchSubscription;
  onSearch;
  onSearchFilter = new Subject<string>();
	innerHeight: string;
  currentIndex=0
  indeterminate = false;
  checked= false;
  selectedItems = [];
  showMessage:boolean=false;
	alertType:String="";
  isPrint: any;
	message:String=""
	p = 0;
	q = 0;
	leaderData: any;
	reInitialise: boolean;
	currentTime = moment().format('HH:mm:ss');
	ascSorttdsAmt: boolean = true;
	ascSortRef: boolean = true;
	totalAmountObj: any;

	isAdvancedSearchOpen: boolean = false;
	parentIndex=0;
	childIndex=0;
	@ViewChild('tableHeader', {static:true}) tableHeader : ElementRef
	alreadyAdjusted: boolean;
	
	allLedgerArr: any;
	ledgerArr: any[];
	shortcuts: ShortcutInput[] = [];
	referenceTypeName = {
		"invoice" : "INVOICE",
		"credit_note" : "Credit Note",
		"jv" : "Journal",
	}
	company_details: any;
	totalReceiptBalance: any;
	totalBalance: any
	transactionAmount = 0;
	deletedAmount = 0;

	transactionData: any;
	adjustmentForm: FormGroup;
	leftAmount : any;
	referenceType: any

	minDate : any
	maxDate : any
	constructor(
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private fb:FormBuilder,
		private store: Store<InitialState>,
    private tabService: TabService

	) {
		this.store.select('sharedReducers').subscribe((items)=>{

			if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
				this.minDate = items.financeYear.start_date
				this.maxDate = items.financeYear.end_date
			}
				
		})
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});

	}
	
	
	async ngOnInit() {
		let queryParams = this.route.queryParams['value']
	
		let queryData = Object.assign({}, queryParams)
		this.spinner.show()
		

		if(queryData) {
      this.getB2csData(queryParams)
		}
		
		this.onSearchFilter.pipe(
			debounceTime(300),
			distinctUntilChanged())
			.subscribe(value => {
			  this.filterB2CSData(value)
		});
		this.reInitialise = true
	}


	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}

  filterB2CSData(value: any) {
    let tempArr = JSON.parse(JSON.stringify(this.allGstB2CSArr))
    let lowerCaseFilter = value.toLowerCase()
    let upperCaseFilter = value.toUpperCase()
    this.gstB2CSArr = tempArr.filter(
      data => {
        if (	
            (
              data.invoice_date != undefined && 
              data.invoice_date != null && 
              (	
                data.invoice_date.toString().includes(value) || 
                data.invoice_date.toString().includes(upperCaseFilter) ||
                data.invoice_date.toString().includes(lowerCaseFilter)
              )
            ) ||
            (
              data.reference != undefined && 
              data.reference != null && 
              (	
                data.reference.toString().includes(value) || 
                data.reference.toString().includes(upperCaseFilter) ||
                data.reference.toString().includes(lowerCaseFilter)
              )
            )  ||
            (
              data.affiliate_name != undefined && 
              data.affiliate_name != null && 
              (	
                data.affiliate_name.toString().includes(value) || 
                data.affiliate_name.toString().includes(upperCaseFilter) ||
                data.affiliate_name.toString().includes(lowerCaseFilter)
              )
            )
            ||
            (
              data.invoice_amount != undefined && 
              data.invoice_amount != null && 
              (	
                data.invoice_amount.toString().includes(value) || 
                data.invoice_amount.toString().includes(upperCaseFilter) ||
                data.invoice_amount.toString().includes(lowerCaseFilter)
              )
            )
          ) {
          return data
        }
      }
    )
  }
	

	ngOnDestroy() {
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
    if (this.advancedSearchSubscription) {
			this.advancedSearchSubscription.unsubscribe()
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.leaderData != undefined) {
			this.setHeight(this.p, this.q, event.target.innerHeight)
		}
	}

	setHeight(pIndex, innerPIndex, height) {
		if (height == undefined) {
			height = window.innerHeight
		}
		pIndex = Number(pIndex)
		innerPIndex = Number(innerPIndex)
		this.p = pIndex
		this.q = innerPIndex

		let parent = 0
		if (pIndex > 0) {
			parent = pIndex - 1
		}
		let child = 0
		if (innerPIndex > 0) {
			child = innerPIndex - 1
		}
		if (this.allGstB2CSArr.length > 0) {
			if (this.allGstB2CSArr[parent].length > 0) {
				if (this.allGstB2CSArr[parent][child].length <= 5) {
					this.innerHeight = 'auto';
				}
				else {
					this.innerHeight = height - 230 + 'px';
				}
			}
			else {
				this.innerHeight = height - 230 + 'px';
			}
		}
		else {
			this.innerHeight = height - 230 + 'px';
		}
	}


	
	hideError(){
		this.showMessage = false
	}

	focusOnTableHeader() :void{
		if (this.tableHeader != undefined) {
			this.tableHeader.nativeElement?.focus()
		}
	}

	onKeyDown(e: any) {
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`).focus()
			this.childIndex = this.childIndex+1
		}else{
			if (this.parentIndex < this.allGstB2CSArr.length -1) {
				this.parentIndex = this.parentIndex+1
				this.childIndex = -1
			}
			if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`).focus()
				this.childIndex = this.childIndex+1
			}else if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`).focus()
				this.childIndex = this.childIndex+1
			}
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`).focus()
			this.childIndex = this.childIndex-1
		}else{
			if (this.parentIndex > 0) {
				this.parentIndex = this.parentIndex-1
				if (this.allGstB2CSArr[this.parentIndex] != undefined) {
					this.childIndex = this.allGstB2CSArr[this.parentIndex].records.length
				}
			}else{
				this.childIndex = 0
			}
			if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`).focus()
				if (this.childIndex >0) {
					this.childIndex = this.childIndex-1
				}
			}else if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`).focus()
				if (this.childIndex >0) {
					this.childIndex = this.childIndex-1
				}
			}
		}
	}
	
	moveToIndex(parentIndex,childIndex){
		document.getElementById(`rowIndex_${parentIndex}_${childIndex}`).focus()
		this.parentIndex = parentIndex
		this.childIndex = childIndex
	}

  getB2csData(query) {
    return new Promise(async (resolve, reject) => {
      this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/gst/b2cs-adustment-data`, query).subscribe(res => {
        if(res && res['data']) {
          this.allGstB2CSArr = res['data'];
          this.gstB2CSArr = res['data']
        }
        if(res && res['duration']) {
           this.duration = res['duration']
        }
		
        resolve(res);
		this.getTotalAmount();
        this.focusOnTableHeader()
        if (this.gstB2CSArr != undefined) {
          this.setHeight(0, 0, window.innerHeight)
        }
      }, err => {
        this.showMessage = true;
        this.alertType = 'error-box'
        this.message = err.message ? err.message : 'Something went wrong!';
        this.loader = false;
        resolve(null)
      })
    })
  }

  openViewMode(reference, referenceType) {
    let isInvoice = false
    let isCreditNote = true
    let isMsInvoice = false
    let isGstInvoice = false
    let isMisc = false
    let isTourInvoice = false

    let viewMode = 'view'
  if (reference != undefined) {
    if (referenceType == 'invoice') {
        isInvoice = true 
    }
    if (referenceType == 'misc-invoice') {
      viewMode = 'view'
      if(!reference.includes('GSP') && !reference.includes('GSS')) {
        isMsInvoice = true
      } 
    }
    if (referenceType == 'invoice' && (reference.includes('GSP') || reference.includes('GSS'))) {
      viewMode = 'view'
      if(!reference.includes('MIN') && !reference.includes('MZC')) {
        isGstInvoice = true
      } 
    }
    if (referenceType == 'credit_note') {
      viewMode = 'view'
      isCreditNote = true
    }
    if (referenceType == 'misc-invoice' ) {
      viewMode = 'view'
      isMisc = true
    }
    if (referenceType == 'tour' ) {
      viewMode = 'view'
      isTourInvoice = true
    }
  }

    if(window.location.protocol != 'file:'){
    let title =''
    let referenceSplit = reference.split('-')
    title += referenceSplit[0]
    title +='...'
    title += referenceSplit[referenceSplit.length-1]
    if(isInvoice) {
    this.tabService.addTab(
      new Tab(InvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
      );
    } else if(isCreditNote) {
      this.tabService.addTab(
        new Tab(InvoiceRefundComponent, `${title}`, { creditNoteNumber: reference, mode : 'view' }, reference)
      );
    }  else if(isMisc) {
      this.tabService.addTab(
        new Tab(MiscInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
        );
    }else if(isMsInvoice) {
    this.tabService.addTab(
      new Tab(MsInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
      );
    } else if(isGstInvoice) {
    this.tabService.addTab(
      new Tab(GstInvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
      );
    }else if(isTourInvoice) {
      this.tabService.addTab(
        new Tab(TourComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
        );
      } 
    }
  }

  saveSelectedData = () => {
    this.loader = true;
    let reqBody = {
      gst_data : this.selectedItems
    }
    return new Promise(async (resolve, reject) => {
      this.commonService.postRequest(`${environment.okountUrl}/reports/gst/b2cs-adustment-data`, reqBody).subscribe(res => {
        this.showMessage = true;
        this.alertType = 'success-box'
        this.message = 'Invoices adjusted successfully';
        resolve(res)
      }, err => {
        this.showMessage = true;
        this.alertType = 'error-box'
        this.message = err.message ? err.message : 'Something went wrong!';
        this.loader = false;
        resolve(null)
      })
    })
  }

  getTotalAmount() {
	let total_amount = 0;
	let total_taxable = 0;

	for(let item of this.allGstB2CSArr) {
		total_amount += Number(item.invoice_amount)
	}

	let paidInvoices = this.allGstB2CSArr.filter(item => item.taxable == 'YES')
	for(let item of paidInvoices) {
		total_taxable += Number(item.invoice_amount)
	}

	if(this.selectedItems && this.selectedItems.length>0) {
		for(let obj of this.selectedItems) {
			total_taxable += Number(obj.invoice_amount)
		}
	}

	let remaining_balance = total_amount - total_taxable

	this.totalAmountObj = {
		total_amount, remaining_balance, total_taxable
	}
  }

  onItemChecked(data, event) {
    if(event.target.checked) {
      this.selectedItems.push(data);
    } else {
      let itemIndex = this.selectedItems.findIndex(item => ((item.reference == data.reference) && (Number(item.invoice_amount) == Number(data.invoice_amount))));
      this.selectedItems.splice(itemIndex, 1);
    }
	this.getTotalAmount();
  }

  openAdvanceSearchModal(){
		this.store.dispatch(new OpenAdvanceSearchModal({status: true, title: 'b2c-adjustment'}));	
	}


}