import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JvComponent } from './jv.component';
import { CreateJvComponent } from './create-jv/create-jv.component';
import { ViewJvComponent } from './view-jv/view-jv.component';
import { AddEntryJvComponent } from './add-entry-jv/add-entry-jv.component';
import { PrintJvComponent } from './print-jv/print-jv.component';


const routes: Routes = [

	{
		path: '', component: JvComponent,
		children: [
			{
				path: '',
				redirectTo: 'create',
				pathMatch: 'full'
			},
			{ path: 'create', component: CreateJvComponent },
			{ path: 'view', component: ViewJvComponent },
			{ path: 'add-entry', component: AddEntryJvComponent },
			{ path: 'update/:id', component: AddEntryJvComponent },
			{ path: 'print/:id', component: PrintJvComponent },

		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class JvRoutingModule { }
