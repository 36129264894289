import { Component, OnInit, ViewChild, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment-timezone';
import { DownloadService } from 'src/app/shared/download.service';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { InvoiceComponent } from 'src/app/transactions/invoice/invoice.component';
import { MsInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/ms-invoice/ms-invoice.component';
import { GstInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/gst-invoice/gst-invoice.component';
import { V2CreatePaymentComponent } from 'src/app/transactions/payment/v2-create-payment/v2-create-payment.component';
import { V2CreateReceiptComponent } from 'src/app/transactions/receipt/v2-create-receipt/v2-create-receipt.component';
import { CreateAdmAcmComponent } from 'src/app/transactions/adm-acm/create-adm-acm/create-adm-acm.component';
import { AddEntryJvComponent } from 'src/app/transactions/jv/add-entry-jv/add-entry-jv.component';
import { Tab } from 'src/app/shared/model/tab.model';
import { TabService } from 'src/app/shared/services/tab.service';
import { MiscInvoiceComponent } from 'src/app/transactions/misc-invoice/misc-invoice.component';
import { TourComponent } from 'src/app/transactions/tour/tour.component';
import { EventComponent } from 'src/app/transactions/event/event.component';
import { OpenAdvanceSearchModal } from 'src/app/transactions/store/actions';
import { InitialState } from 'src/app/store/reducer';
import { Store } from '@ngrx/store';
import { InvoiceRefundComponent } from 'src/app/transactions/invoice-refund/invoice-refund.component';
import * as FileSaver from 'file-saver'; 


@Component({
  selector: 'app-mis-report',
  templateUrl: './mis-report.component.html',
  styleUrls: ['./mis-report.component.scss']
})
export class MisReportComponent implements OnInit {
  isPrint;
  p;
  navigationSubscription
  reInitialise: boolean;
  currentDate =  moment().format('YYYY-MM-DD')
  count = 0
  receiver_count: any = "200" ;
  isAdvancedSearchOpen: boolean = false;
  currentIndex=0
  queryParams: any;
  allInvoiceArr :any;
  invoiceArr: any;
  loader;

  duration: any;
  fromRoot = true;
  infiniteSelector: string = '.main-panel';

  @ViewChild('tableHeader', {static:true}) tableHeader : ElementRef;
  @Output() callOnScrollDownB2bGst : EventEmitter<any> = new EventEmitter();

  innerHeight: string;
  listOfColumns =[];
  companyDetails;
  clientDetails: any;
  totalOutstanding : any;

  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public commonService: CommonServiceService,
		private downloadService : DownloadService,
    private router: Router,
    private tabService: TabService,
    private store: Store<InitialState>
  ) {
    // this.spinner.show()
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  ngOnInit() {
    this.queryParams = this.route.queryParams['value']
    this.getFilteredData(this.queryParams);
    this.reInitialise = true

    this.listOfColumns = [
			{name: 'Invoice Date', class:'report-date border-left ',},
			{name: 'Invoice number', class:' report-no  border-left'},
			// {name: 'Invoice Type',class:'text-center border-top border-left report-cash'},
			{name: 'Pax Name',class:'report-credit  border-left'},
			{name: 'Airline Code',class:'border-left report-fare-code'},
            {name: 'Ticket Number',class:'border-left report-fare-value'},
			{name: 'Sector',class:'  border-left   report-yq-tax'},
			{name: 'Travel Date',class:'  border-left  report-tax'},
			{name: 'Basic Fare',class:'border-left  report-raf'},
			{name: 'YQ Tax',  class:'  border-left   yq-report-rate'},
			{name: 'YR Tax',class:'  border-left  report-amt'},
			{name: 'OT TAX',class:'  border-left   report-rate'},
			{name: 'K3 TAX',class:'  border-left  report-k3-tax'},
			{name: 'Service Fee',class:'border-left border-left  report-payable'},
            {name: 'SGST',class:'  border-left  report-sgst'},
			{name: 'CGST',class:'  border-left  report-cgst'},
			{name: 'IGST',class:'  border-left  report-igst'},
			{name: 'XXL',class:'  border-left  report-xxl'},
			{name: 'TDS AMT',class:'border-left report-tds-amt'},
			{name: 'NET AMOUNT',class:'border-left report-net-amt'},
		]
  }


	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setHeight(event.target.innerHeight)
	}

  setHeight(height) {
		if (this.allInvoiceArr != undefined) {
			if (height == undefined) {
				height = window.innerHeight
			}
			if (this.allInvoiceArr['flight_invoice'] && this.allInvoiceArr['flight_invoice'].length <= 9) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = height - 140 + 'px';
			}
		}
  }
  
  initialiseInvites() {
    if (this.reInitialise) {
      this.ngOnInit()
    }
  }
  ngOnDestroy() {

    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  //ALL DATA
  getData() {
    this.commonService.getRequest(`${environment.okountUrl}/reports/mis-report`).subscribe(res => {
      this.allInvoiceArr = res['data'];
	  this.invoiceArr = res['data'];
	  if(res && res['accounting_company']) {
		this.companyDetails = res['accounting_company'];
	  }
	  if(res && res['client_details']) {
		this.clientDetails = res['client_details'];
	  }
	  if(res && res['duration']) {
		this.duration = res['duration'];
	  }
	  if(res && res['outstanding']) {
		this.totalOutstanding = res['outstanding'];
	  }

	  this.calculateSubTotalForAllReferenceTypes(this.allInvoiceArr);
      this.spinner.hide()

    }, err => {
			this.spinner.hide()
      console.error(err)
    })
  }
  //FILTERED DATA
  getFilteredData(queryParams) {
    this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/mis-report`, queryParams).subscribe(res => {
      this.allInvoiceArr = res['data'];
	  this.invoiceArr = res['data'];
	  if(res && res['accounting_company']) {
		this.companyDetails = res['accounting_company'];
	  }
	  if(res && res['client_details']) {
		this.clientDetails = res['client_details'];
	  }
	  if(res && res['duration']) {
		this.duration = res['duration'];
	  }
	  if(res && res['outstanding']) {
		this.totalOutstanding = res['outstanding'];
	  }

	  this.calculateSubTotalForAllReferenceTypes(this.allInvoiceArr);
      this.spinner.hide()

    }, err => {
			this.spinner.hide()
      console.error(err);
    })
  }

  openViewMode(reference, referenceType) {
			let isInvoice = false
			let isMsInvoice = false
			let isGstInvoice = false
			let isPayment = false
			let isReceipt = false
		    let isAdmAcm = false
			let isJv = false
			let isMisc = false
			let isTourInvoice = false
			let isEventInvoice = false
			let isCreditNote = false;
		if (reference != undefined) {
			let url = '#/transactions/'
			let viewMode = 'view'
			if (referenceType == 'invoice') {
				url = '#/transactions/invoice'
				viewMode = 'view'
				if(!reference.includes('MIN') && !reference.includes('MZC') && !reference.includes('GSP') && !reference.includes('GSS')) {
					isInvoice = true 
				} 
			}
			if (referenceType == 'ms-invoice') {
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
				isMsInvoice = true
			}
			if (referenceType == 'gst-invoice' && (reference.includes('GSP') || reference.includes('GSS'))) {
				url = '#/transactions/ms/gst-invoice'
				viewMode = 'view'
				if(!reference.includes('MIN') && !reference.includes('MZC')) {
					isGstInvoice = true
				} 
			}
			if (referenceType == 'credit_note') {
				url = '#/transactions/credit-note'
				viewMode = 'view'
				isCreditNote = true;
			}
			if (referenceType == 'payment') {
				url = '#/transactions/payment'
				viewMode = 'view'
				isPayment = true
			}
			if (referenceType == 'receipt') {
				url = '#/transactions/receipt'
				viewMode = 'view'
				isReceipt = true
			}
			if (referenceType == 'jv') {
				url = '#/transactions/jv'
				viewMode = 'update'
				isJv = true
			}
			if (referenceType == 'adm' || referenceType == 'acm') {
				url = '#/transactions/adm-acm'
				viewMode = 'view'
				isAdmAcm = true
			}
			if (referenceType == 'misc_invoice' ) {
				url = '#/transactions/ms/misc'
				viewMode = 'view'
				isMisc = true
			}
			if (referenceType == 'tour' ) {
				url = '#/transactions/ms/misc'
				viewMode = 'view'
				isTourInvoice = true
			}
			if (referenceType == 'event' ) {
				url = '#/transactions/event'
				viewMode = 'view'
				isEventInvoice = true
			}

			let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${url}/${viewMode}/${reference}`
			if(window.location.protocol != 'file:'){
			let title =''
			let referenceSplit = reference.split('-')
			title += referenceSplit[0]
			title +='...'
			title += referenceSplit[referenceSplit.length-1]
			if(isInvoice) {
			this.tabService.addTab(
				new Tab(InvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
				);
			} else if(isCreditNote) {
				this.tabService.addTab(
					new Tab(InvoiceRefundComponent, `${title}`, { creditNoteNumber: reference, mode : 'view' }, reference)
				);
			} else if(isMisc) {
				this.tabService.addTab(
					new Tab(MiscInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
					);
			}else if(isMsInvoice) {
			this.tabService.addTab(
				new Tab(MsInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
				);
			} else if(isGstInvoice) {
			this.tabService.addTab(
				new Tab(GstInvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
				);
			} else if(isPayment) {
			this.tabService.addTab(
				new Tab(V2CreatePaymentComponent, `${title}`, { paymentRef: reference ,mode : 'view' }, reference)
				);
			}
			else if(isReceipt) {
				this.tabService.addTab(
				new Tab(V2CreateReceiptComponent, `${title}`, { receiptRef: reference ,mode : 'view' }, reference)
				);
			} else if(isAdmAcm) 
			{
			this.tabService.addTab(
				new Tab(CreateAdmAcmComponent, `${title}`, { adm_acm_number: reference, mode : 'view' }, reference)
			);

			}else if(isJv) {
			this.tabService.addTab(
				new Tab(AddEntryJvComponent, `${title}`, { jv_code: reference }, reference)
				);
			}else if(isTourInvoice) {
				this.tabService.addTab(
					new Tab(TourComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
					);
				}
				else if(isEventInvoice) {
					this.tabService.addTab(
						new Tab(EventComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
						);
					}else {
			window.open(path,'_blank')		
			}
      }else{
        var customeEventHandler = new CustomEvent("handle-window-open", {
					"detail": {
						hashPath : path
					}
				  });
				window.dispatchEvent(customeEventHandler)
      }
		}
  }
  
  // keyboard integration
	// Add #tableHeader on table header

	focusOnTableHeader(){
		if(this.tableHeader != undefined){
			this.tableHeader.nativeElement.focus()
		}
	}
	
	// @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.currentIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex+1}`).focus()
			this.currentIndex = this.currentIndex+1
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.currentIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex-1}`).focus()
			this.currentIndex = this.currentIndex-1
		}
	}
	
	moveToIndex(i){
		document.getElementById(`rowIndex_${i+1}`).focus()
		this.currentIndex = i+1
	}

  callPdfGeneratorApi(fromDate, toDate){
		window.scrollTo(0,0)
		this.spinner.show()
		this.spinner.show()
		let url = 'print-pdf/pdf-gst-b2b'
		let queryString = location.hash.split('?')[1]
		if (queryString != undefined) {
			url = `${url}?${queryString}`
		}
		this.downloadService.callPdfGeneratorApi(`B2B GST ${fromDate} - ${toDate}.pdf`,url,{})	
		this.spinner.hide()
	}

	async onScrollDown(event) {
		this.callOnScrollDownB2bGst.emit()
	}

	onScrollUp(event) {}

  openAdvanceSearchModal(){
    this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'mis-report'}));	
  }

  calculateSubTotalForAllReferenceTypes(data) {
	for(let key in data) {
		if(key != 'opening_balance') {
			if(data[key]  && data[key].length>0) {
			    data[`${key}_total`] = this.calculateTotalReferenceTypeWise(data[key]);
			}
		}
	}

	if(!data['opening_balance']) {
		this.allInvoiceArr['opening_balance'] = [{reference_type: 'opening_balance' ,client_net_amount: 0}]
	}
  }


  calculateTotalReferenceTypeWise (invoiceArr) {
	const fieldsToCalculate = [
		"base",
		"ssr",
		"yq",
		"yr",
		"k3",
		"ot",
		"oc",
		"client_xxl",
		"client_raf",
		"c_tds_amount",
		"client_net_amount",
		"client_gst_raf",
		"client_igst",
		"client_cgst",
		"client_sgst",
		"client_service_charge",
	  ];

	const subtotals = {};

	 invoiceArr.forEach((item) => {
		fieldsToCalculate.forEach((field) => {
        const value = parseFloat(item[field]);
		if (!isNaN(value)) {
			if (!subtotals[field]) {
			subtotals[field] = 0;
			}
			subtotals[field] += value;
		}
		});
	});
  
	return subtotals;
	
  }

  exportAsXLSX(): void {
	this.spinner.show()
	let xlsxData = [];
	let allItems = ['opening_balance', 'flight_invoice', 'credit_note', 'hotel_invoice','hotel_credit_note', 'visa_invoice','visa_credit_note',
	    'insurance_invoice','insurance_credit_note', 'rail_invoice', 'rail_credit_note', 'bus_invoice','bus_credit_note', 'car_invoice','car_credit_note' ,
		'tour_invoice','tour_credit_note', 'misc_invoice', 'misc_credit_note', "receipt", "payment","event_invoice" , "event_credit_note", "consolidated_invoice",
		'jv','purchase_invoice','purchase_refund'
	];
	
	for(let item of allItems) {
		if(this.allInvoiceArr[item] && this.allInvoiceArr[item].length>0) {
			
			this.allInvoiceArr[item] && this.allInvoiceArr[item].length>0 && this.allInvoiceArr[item].forEach(data => {
				let obj = this.makeObjectForExcel(data, item, 'normal');
				xlsxData.push(obj);
			});
	
			let subTotal = this.allInvoiceArr[`${item}_total`];
			if(subTotal) {
				let obj = this.makeObjectForExcel(subTotal, item, 'subTotal');
				xlsxData.push(obj);		
			}
				
			xlsxData.push({});
		}
		
	}

        let outstandingObject = {}
		outstandingObject['NET AMOUNT'] = 'Outstanding'
		outstandingObject[''] = this.totalOutstanding;
		xlsxData.push(outstandingObject);

		let firstLineObject = {}
		firstLineObject['Invoice Date'] = moment(this.duration.start_date).format('DD/MM/YYYY');
		firstLineObject['Invoice number'] = moment(this.duration.end_date).format('DD/MM/YYYY');
		firstLineObject['Ticket Number'] = this.companyDetails.name
		xlsxData.push(firstLineObject);

		let secondLineObject = {}
		secondLineObject['Invoice Date'] = this.clientDetails.account_code;
		xlsxData.push(secondLineObject);
		
		let thirdLineObject = {}
		thirdLineObject['Invoice Date'] = this.clientDetails.name;
		xlsxData.push(thirdLineObject);

		let fouthLineObject = {}
		fouthLineObject['Invoice Date'] = `${this.clientDetails.address_1}, ${this.clientDetails.city_name}, ${this.clientDetails.state_name}`;
		xlsxData.push(fouthLineObject);

		this.downloadService.exportAsXLSX(`${this.clientDetails.name} MIS REPORT`, xlsxData);
		this.spinner.hide()
    }

	makeObjectForExcel(data, item, objType) {
		let obj = {};
		    obj["Invoice Date"] = data.issue_date ? moment(data.issue_date).format('DD/MM/YYYY') : '';
			obj["Invoice number"] = data.reference ? data.reference : ''
			obj["Reference No."] = data.booking_ref_no ? data.booking_ref_no : ''
			obj['Against Invoice'] =  data.against_inv ? data.against_inv : ''
			obj["Pax Name"] = data.pax_name ? data.pax_name : ''
			obj["Airline Code"] = data.airline_code ? data.airline_code : ''
			obj["Ticket Number"] = data.ticket_no ? data.ticket_no : ''
			obj["Sector"] = data.sector ? data.sector : (objType == 'subTotal' || item == 'opening_balance') ? item.toUpperCase() : '' 
			obj["Travel Date"] = data.travel_date ?  moment(data.travel_date).format('DD/MM/YYYY') : ''
			obj["Travel Date 2"] = data.travelling_date_2 ?  moment(data.travelling_date_2).format('DD/MM/YYYY') : '' 
			obj['Invoice Type'] = data.invoice_type? data.invoice_type : ''
			obj['Hotel Name'] = data.hotel_name? data.hotel_name : ''
			obj['Room Name'] = data.room_name? data.room_name : ''
			obj["Basic Fare"] = data.base ? Number(data.base) : ''
			obj["SSR"] = data.ssr ? Number(data.ssr) : '',
			obj["YQ Tax"] = data.yq ? Number(data.yq) : ''
			obj["YR Tax"] = data.yr ? Number(data.yr) : ''
			obj["OT TAX"] = data.ot ? Number(data.ot): ''
			obj["K3 TAX"] = data.k3 ? Number(data.k3) : ''
			obj['OC TAX'] = data.oc ? Number(data.oc) : ''
			obj["Service Fee"] = data.client_service_charge ? Number(data.client_service_charge): ''	
			obj["SGST"] = data.client_sgst ? Number(data.client_sgst) : ''			
			obj["CGST"] =  data.client_cgst ? Number(data.client_cgst) : ''	
			obj["IGST"] =  data.client_igst ? Number(data.client_igst) : ''		
			obj["XXL"] =  data.client_xxl ? Number(data.client_xxl) : ''	
			obj['RAF'] = data.client_raf ? Number(data.client_raf) : ''
			obj["COMMISSION"] = data.commission ? Number(data.commission) : ''			
			obj["TDS AMT"] =  data.c_tds_amount ? Number(data.c_tds_amount) : ''	
			obj['TCS AMT']= data.tcs_amount ? Number(data.tcs_amount) : ''	
			obj["NET AMOUNT"] =  data.client_net_amount ? Number(data.client_net_amount)  : 0

			if(objType == "subTotal") {
				obj[''] =  data.client_net_amount ? Number(data.client_net_amount)  : 0
			}

			if(objType == 'normal' && item == 'opening_balance') {
				obj["NET AMOUNT"] =  data.client_net_amount ? Number(data.client_net_amount) : 0.0;
				obj[''] = data.client_net_amount ? Number(data.client_net_amount) : 0.0;
			}

		return obj;
	}

	calculateTotalOutstanding(data) {
		let totalAmount = 0;
		for(let key in data) {
			if(data[key] && data[key].length>0){
				for(let item of data[key]){
					if(item.transaction_method == 'sales') {
						totalAmount += Number(item.client_net_amount)
					}else if(item.transaction_method == 'purchase') {
						totalAmount -= Number(item.client_net_amount)
					}
				}
			}
		}

		return totalAmount;
		
	}

	openSavePdfModal() {
		//this.queryParams = this.route.queryParams['value']
		const fromDate = this.duration?.from_date;
		const toDate = this.duration?.to_date; 
		const display_name= this.clientDetails?.name
		this.downloadPdf(fromDate, toDate, display_name)
		return;

	}

	downloadPdf(start_date, end_date,display_name) {
		let queryData = this.queryParams;
		this.commonService.downloadPDF(`${environment.okountUrl}/reports/mis-report/download`, queryData).subscribe((fileBlob:any) =>{
			let fileName = `${display_name} NORMAL LEDGER FROM ${start_date} TO ${end_date}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}
}
