<form [formGroup]="receiptFilterForm">

	<div class="flexbox aff-info">
		<div class="flexbox aff-info-inner">

			<div class="flexbox">
				<div class="input-fiel form-group ng-field" searchtabindex="1" (focus)="focusfrAccountAc()">
					<label class="ngauto-label" for="affname">Account 1</label>
					<div class="ng-autocomplete width193">
						<ng-autocomplete [data]="fromAccount" #fraccountAC [searchKeyword]="keyword"
							(inputChanged)='getFromAccount($event,false)' historyIdentifier="fromAccount"
							[itemTemplate]="itemTemplate" formControlName="account_1_name"
							(selected)="selectAccount($event,'account_1_code')" [notFoundTemplate]="notFoundTemplate">
						</ng-autocomplete>

						<ng-template #itemTemplate let-item>
							<a innerHTML='<span class="colr-1">{{item.name}}
                                ({{item.account_code}})</span><br />'>
							</a>
						</ng-template>

						<ng-template #notFoundTemplate let-notFound>
							<div [innerHTML]="notFound"></div>
						</ng-template>
					</div>
				</div>
				<div class="input-fiel form-group ng-field" searchtabindex="2" (focus)="focustoAccountAc()">
					<label class="ngauto-label" for="affname">Account 2</label>
					<div class="ng-autocomplete width193">
						<ng-autocomplete [data]="toAccount" #toaccountAC [searchKeyword]="keyword"
							(inputChanged)='getToAccount($event,false)' historyIdentifier="toAccount"
							[itemTemplate]="itemTemplate" formControlName="account_2_name"
							(selected)="selectAccount($event,'account_2_code')" [notFoundTemplate]="notFoundTemplate">
						</ng-autocomplete>

						<ng-template #itemTemplate let-item>
							<a innerHTML='<span class="colr-1">{{item.name}}
                                ({{item.account_code}})</span><br />'>
							</a>
						</ng-template>

						<ng-template #notFoundTemplate let-notFound>
							<div [innerHTML]="notFound"></div>
						</ng-template>
					</div>
				</div>
				<div class="input-fiel form-group ng-field" searchtabindex="2" (focus)="focustoAccountAc()">
					<label class="ngauto-label" for="affname">Client</label>
					<div class="ng-autocomplete width193">
						<ng-autocomplete [data]="clientAccount" #clientAc [searchKeyword]="keyword"
							(inputChanged)='getClient($event,false)' [itemTemplate]="itemTemplate"
							(inputCleared)="clearData('client')"
							formControlName="client_name" (selected)="selectAccount($event,'client_code')"
							[notFoundTemplate]="notFoundTemplate">
						</ng-autocomplete>

						<ng-template #itemTemplate let-item>
							<a innerHTML='<span class="colr-1">{{item.name}}
                                ({{item.account_code}})</span><br />'>
							</a>
						</ng-template>

						<ng-template #notFoundTemplate let-notFound>
							<div [innerHTML]="notFound"></div>
						</ng-template>
					</div>
				</div>
			</div>

			<div class="flexbox mt-3">
				<div class="input-fiel">
					<input class="input-ui" searchtabindex="3" formControlName="start_date" id="date" type="date"
						min="{{minDate}}" max="{{maxDate}}"
						[ngClass]="{'error-box': !receiptFilterForm.controls.start_date.valid}"
						style="width: 140px;" />
					<label for="date">From Date</label>

				</div>

				<div class="input-fiel">
					<input class="input-ui" searchtabindex="4" formControlName="end_date" id="date" type="date"
						min="{{minDate}}" max="{{maxDate}}"
						[ngClass]="{'error-box': !receiptFilterForm.controls.end_date.valid}"
						  style="width: 140px;" />
					<label for="date">To Date</label>

				</div>
				<div class="input-fiel">
					<input class="input-ui" id="receiptNo" oninput="this.value = this.value.toUpperCase()" type="text"
						searchtabindex="5" formControlName="transaction_no" placeholder="Doc Number"
						style="width: 140px;" /><label for="receiptNo" >Doc. No.</label>
				</div>

				<div class="input-fiel">
					<input class="input-ui" id="receiptNo" oninput="this.value = this.value.toUpperCase()" type="text"
						searchtabindex="5" formControlName="adm_acm_number" placeholder="ADM/ACM Number"
						style="width: 140px;" /><label for="receiptNo">ADM/ACM. No.</label>

				</div>

			</div>





			<div class="flexbox mt-3">

				<div class="input-fiel">
					<input class="input-ui" id="uidFracc" type="number" searchtabindex="8" formControlName="from_amount"
						placeholder="From Account" /><label for="uidFracc">From Amount</label>
				</div>


				<div class="input-fiel">
					<input class="input-ui" id="uidToacc" type="number" searchtabindex="9" formControlName="to_amount"
						placeholder="To Amount" /><label for="uidToacc">To Amount</label>
				</div>

			</div>
			<div class="flexBox d-flex mt-3">
				<div class="input-fiel">
					<input class="input-ui" id="uidFracc" type="number" searchtabindex="8"
						formControlName="reference_number" placeholder="From Account" style="width: 140px;" /><label
						for="uidFracc">Ref. No.</label>

				</div>
				<div class="input-fiel">
					<input class="input-ui" id="receiptNo" oninput="this.value = this.value.toUpperCase()" type="text"
						searchtabindex="5" formControlName="ticket_no" placeholder="Ticket Number"
						style="width: 140px;" /><label for="receiptNo">Ticket. No.</label>
				</div>
				<div class="input-fiel">
					<select class="input-ui" name="" id="" searchtabindex="9" formControlName="transaction_type">
						<option value="both">Both</option>
						<option value="ACM">ACM</option>
						<option value="ADM">ADM</option>
					</select>
					<label for="uidFracc">Type</label>

				</div>

			</div>

			<div class="d-flex mt-5 justify-content-center">
				<div class="input-fiel">
					<button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" type="button"
						data-dismiss="modal" searchtabindex="10" #searchButton (keyup.enter)="clickSearchButton()"
						(click)="search(receiptFilterForm.value)" [disabled]="receiptFilterForm.invalid">
						Search</button>
					<button type="button" id="resetSearchForm" class="btn-style bg-green" (click)="reset()">RESET</button>
				</div>
			</div>

		</div>

	</div>
	<!-- </div> -->
</form>