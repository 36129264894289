
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
	<div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
	  <div class="d-flex flex-y-center">
		<img
		  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
			alertType=='success-box' ? 'green-sucess.svg' : 
			alertType=='warning-box' ? 'red-warning.svg' : 
			null }}"
		  width="20px"
		  height="20px"
		  alt="alert-logo"
		  class="mr-20"
		/>
		<div class="ml-10">
		  <h4 class="m-0 text-size-18 text-bold"
		  [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
			{{alertType === "error-box"
			? "Error"
			: alertType === "warning-box"
			? "Warning"
			: alertType === "success-box"
			? "Success"
			: null}}
		  </h4>
		  <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
		   {{message}}
		  </small>
		</div>
	  </div>
	  <img
		src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
		alt="close-btn"
		(click)="hideError()"
		className=" close-btn"
	  />
	</div>
  </div>

<div class="ledger-container">
	<ng-container *ngIf="!paymentService.showAddPaymentScreen else addPaymentScreen">


		<div class="row tab-nav pl-0 pr-0">
			<!-- <div class="col-sm-4 pl-0">
				<ul class="list-inline list-unstyled p-0 m-0">
					<li class="active page-title text-uppercase cursor"> View Client</li>
				</ul>
			</div> -->
			<div class="col-sm-12">
			  <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
				  <li  
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> Banking</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li  
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> Payment</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li  
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> View Payment</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li class="text-grey text-capitalize cursor text-primary transition"> Edit Payment</li>
			  </ul>
		  </div>
		</div>

		<form [formGroup]="paymentService.paymentForm" #form="ngForm">
			<div style="width: 800px; margin: 0 15px;" class="custom-form">

				<div class="text-field-group d-flex align-items-center">
					<div class="select-field text-input-field mr-3">
						<select class="input-field" formControlName="accountCodeFrom" tabIndex="1">
							<option value="">Select</option>
							<ng-container *ngFor="let item of generalMasterList">
								<option *ngIf="item.account_code != ''" value="{{item.account_code}}">{{item.name}}
								</option>
							</ng-container>
						</select>
						<label>Cash / Bank</label>
					</div>
					<div class="text-input-field">
						<input oninput="this.value = this.value.toUpperCase()" tabIndex="2" class="input-field"
							type="date" formControlName="paymentDate" />
						<label>Generated Date</label>
					</div>

				</div>

				<div class="text-field-group d-flex align-items-center"
					*ngIf="paymentService.paymentEntries.length > 0">
					<div class="select-field text-input-field mr-3">
						<input oninput="this.value = this.value.toUpperCase()" class="input-field" type="text" readonly
							formControlName="payment_code" />
						<label>Payment payment Code</label>
					</div>
					<div class="text-input-field">
						<input oninput="this.value = this.value.toUpperCase()" class="input-field"
							formControlName="amount" pattern="^\d*(\.\d{0,8})?$" type="text" readonly />
						<label>Amount</label>
					</div>
				</div>

				<span class="info alert-warning" *ngIf="paymentService.paymentEntries.length == 0">* To Save Payment,
					you must add atleast 1 entry.</span>

			</div>
			<div style="width: 800px; margin: 0 15px;" class=" p-0 mt-5"
				*ngIf="paymentService.paymentEntries != undefined && paymentService.paymentEntries.length > 0">
				<div>
					<table class="table table-bordered">
						<thead>
							<tr>
								<th>Code</th>
								<th>Sub Ledger</th>
								<th>Account</th>
								<th>Cheque</th>
								<th>Amount</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let payment of paymentService.paymentEntries">
								<td class="text-black text-bold">{{payment.account_code_to}}</td>
								<td class="text-black text-bold">{{payment.sub_ledger_code_1}}</td>
								<td class="text-black text-bold">{{payment?.account_code_to_value}}</td>
								<td class="text-black text-bold">{{payment.cheque_no}}</td>
								<td class="text-black text-bold">{{payment.amount | number : '1.2-2'}}</td>
								<td class="edit-row">
									<button style="width: 25px; margin: 0 auto;" title="Edit" class="btn   save-as text-bold bg-whiteSmoke "
										type="button" (click)="editEntry(payment)">
										<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
									</button>
								</td>
							</tr>
							<tr>
								<!-- <td colspan="3"></td> -->
								<td class="grand text-primary">Total Amount</td>
								<td class="grand text-primary"> {{paymentService.paymentForm.value.amount}}</td>
								<!-- <td></td> -->
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="btn-field bottom-btns-container mt-30 mb-2 d-flex align-item-center  ml-0 mr-0">  
				  <!-- <button type="button" class="btn-style bg-green mr-3" tabIndex="20"
          >save</button> -->
				<button type="button" class="btn-style bg-blue mr-3"
					[disabled]="paymentService.paymentForm.invalid || paymentService.paymentEntries.length == 0"
					[ngClass]="{disbaledBtn: paymentService.paymentForm.invalid || paymentService.paymentEntries.length == 0}"
					(click)="openUpdateConfirmModal()" #updateButton tabIndex="3">Modify</button>
				<button type="button" class="btn-style bg-orange mr-3" (click)="openVoucher()" tabIndex="4">View Voucher</button>
				<button type="button" class="btn-style bg-green mr-3"
					[disabled]="paymentService.paymentForm.controls.paymentDate.invalid"
					[ngClass]="{disbaledBtn: paymentService.paymentForm.controls.paymentDate.invalid}"
					(click)="addRecord(true,paymentService.paymentForm.value)" tabIndex="5">Add Entry</button>
				<button type="button" (click)="openActivity()" class="btn-style bg-indigo mr-3" tabIndex="6">Activity
				</button>
				<button type="button" class="btn-style bg-red" (click)="openDeleteConfirmModal()" tabIndex="7">Delete</button>
			</div>
		</form>


	</ng-container>
	<ng-template #addPaymentScreen>
		<app-add-payment-record [isUpdate]="true" (callExitEntry)="exitEntry($event)"></app-add-payment-record>
	</ng-template>
</div>

<button type="button" hidden #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button>

<div id="deleteConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
	<div class="modal-dialog">
		<!-- Modal content-->
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Confirmation</h4>
				<button type="button" #closeDeleteModal class="close" data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<p>Are you sure to delete ‎this entry ?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn-style" tabIndex="9" attr.tabIndex="9"
					(click)="closeDeleteConfirmModal()" data-dismiss="modal">No</button>
					<button type="button" class="btn-style bg-red" tabIndex="8" attr.tabIndex="8" (click)="reset()"
						data-dismiss="modal">Yes</button>
			</div>
		</div>
	</div>
</div>

<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateConfirmationModal"></button>

<div id="updateConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
	<div class="modal-dialog">
		<!-- Modal content-->
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Confirmation</h4>
				<button type="button" #closeUpdateModal class="close" data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<p>Are you sure to update ‎this entry ?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn-style" tabIndex="11" attr.tabIndex="11"
					(click)="closeUpdateConfirmModal()" data-dismiss="modal">No</button>
					<button type="button" class="btn-style bg-green" tabIndex="10" attr.tabIndex="10" (click)="updatePayment()"
						data-dismiss="modal">Yes</button>
			</div>
		</div>
	</div>
</div>