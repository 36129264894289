<div class="ledger-container ledger-form">
    <div class="row tab-nav pl-0 pr-0">
        <div class="col-sm-4 ">
          <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
              <li [routerLink]="['/']"
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> Banking</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li [routerLink]="['/']"
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> Receipt</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li class="text-grey text-capitalize cursor text-primary transition"> View Receipt</li>
          </ul>
      </div>
        <div class="col-sm-4 text-center">
            <span class="rec-date d-block text-center">
                <div class="noWrap justify-content-around">
                    <ng-container >
                        <div class="cname text-bold"><span *ngIf="duration && duration.from" class="text-bold">From</span>
                            <h5 style="margin-right: 5px;" class="mb-0  d-inline">
                                <span class="date text-bold text-orange" *ngIf="duration && duration.from"
                                    >
                                    {{duration && duration.from}}
                                </span>
                                <span class="date text-bold" *ngIf="duration && duration.from">
                                    To 
                                </span>
                                <span class="date text-bold" >
                                    Till Date
                                </span>
                                <span class="date text-bold text-orange">
                                    {{duration && duration.to}}
                                </span>
                            </h5>
                            <button title="Custom Search" class="btn   save-as text-bold bg-whiteSmoke " 
                                type="button" (click)="openAdvanceSearchModal()" >
                                <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
                            </button>
                        </div>
                    </ng-container>								
                </div>
            </span>
        </div>
        <div class="col-sm-4  d-flex align-item-center justify-content-end">
          <div class="page-search-box input-group  d-flex align-item-center"
          style="height: fit-content; text-align: left;">
          <input type="text" class="form-control" [(ngModel)]="onSearch" oninput="this.value = this.value.toUpperCase()"
            (ngModelChange)="this.onSearchFilter.next($event)" placeholder="search" style=" text-align: left" />
         
        </div>
        </div>
    </div>
   
    <div class="table-container">
        <div class="table" style="    margin-top: -5px;">

            <nz-table #receiptData [nzData]="receiptArr"
                [nzLoading]="loader"
                [nzFrontPagination]="false"
            >

                <thead>
                    <tr #tableHeader tabIndex="0">
                        <th style="max-width: 145px;" class="text-left">Date</th>
                        <th style="max-width: 170px;" class="text-left">Doc. No.</th>
                        <th style="max-width: 170px;" class="text-left ellipse-text">Bank</th>
                        <th class="text-left">Client</th>
                        <th style="max-width: 100px;" class="text-right">Amount</th>
                        <th class="text-right" style="max-width: 100px;">Action</th>
                    </tr>
                </thead>

                    <ng-container *ngIf="receiptData && receiptData['data'] && receiptData['data'].length>0 else noResult">
                        <tbody [ngStyle]="{height: innerHeight}" class="scroll-body main-panel" infiniteScroll
                            (window:keydown)="onKeyDown($event)" [fromRoot]="fromRoot" [infiniteScrollDistance]="1"
                            [infiniteScrollUpDistance]="1" [infiniteScrollThrottle]="200"
                            [infiniteScrollContainer]="selector" (scrolled)="onScrollDown($event)"
                            (scrolledUp)="onScrollUp($event)">
                            <tr class="field-table-input" *ngFor="let item of receiptData['data'] ; let i = index"
                                [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}" (keyup.enter)="openReceipt(item.receipt_code)">
                                <td style="max-width: 145px;" class="text-left text-grey " (click)="moveToIndex(i)">
                                    {{item?.receipt_date | date :
                                    'dd/MM/yyyy HH:mm'}}</td>
                                <td style="max-width: 170px;text-decoration: none;" class="text-left text-grey refer font-500 doc-number-color"><span
                                        (click)="openReceipt(item.receipt_code)">{{item?.receipt_code}}</span></td>
                                <td class="text-left text-grey ellipse-text" style="max-width: 170px;" (click)="moveToIndex(i)">
                                   <span class="ellipse-text" style="max-width: 170px;">{{item?.account_code_to_name}}</span> 
                                </td>
                                <td class="text-left text-grey">
                                    {{item?.account_code_from_name}}
                                </td>
                                <td style="max-width: 100px;" class="text-right text-black font-500" (click)="moveToIndex(i)">
                                    {{item?.amount}}
                                </td>
                                <td class="text-right action-buttons text-grey edit-row " style="max-width: 100px;">
                                    <button title="Edit" class=" bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button"
                                    (click)="openReceipt(item.receipt_code)">
                                    <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-edit-btn.svg" alt="" width="20px">
                                </button>
                                </td>
                           </tr>
                           
                        </tbody>
                    </ng-container>
                    
                    <ng-template #noResult>
                        <div *ngIf="!loader" class="w-100 text-center" style="padding: 20px;">
                            <nz-empty nzNotFoundContent="No result Found"></nz-empty>
                        </div>
                    </ng-template>

                </nz-table>
        </div>
    </div>
</div>