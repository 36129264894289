<form [formGroup]="misReportFilterForm">
    <div class="flexbox aff-info">
      <div class="flexbox aff-info-inner">
        <div class="flexbox ">
          <div class="input-fiel">
            <input class="input-ui" searchtabindex="1" formControlName="start_date" id="from_date" type="date"
                min="{{minDate}}" max="{{maxDate}}"
                [ngClass]="{'error-box': !misReportFilterForm.controls.end_date.valid}"
                />
            <label for="from_date">Starting Date</label>
  
          </div>
          <div class="input-fiel">
            <input class="input-ui" searchtabindex="2" formControlName="end_date" id="to_date" type="date"
                min="{{minDate}}" max="{{maxDate}}"
                [ngClass]="{'error-box': !misReportFilterForm.controls.end_date.valid}"
                />
            <label for="to_date">Ending Date</label>
          </div>
        </div>
  
        <!-- <div class="flexbox mt-20">
          <div class="select-field  text-input-fiel">
            <label for="client" class="ngauto-label">Client</label>
            <div class="ng-autocomplete">
                <ng-autocomplete [data]="clientArr" #clientAc (inputCleared)="selectAccount($event)"
                  [searchKeyword]="keyword" (inputChanged)='getClient($event)'
                  [itemTemplate]="itemTemplate" formControlName="selectedClientName"
                  (selected)="selectAccount($event)" [notFoundTemplate]="notFoundTemplate" >
    
                </ng-autocomplete>
                <ng-template #itemTemplate let-item>
                  <a innerHTML='<span class="colr-1">{{item.name}}
                                    ({{item.account_code}})</span><br />
                                <span class="colr-2">{{item.primary_email}}</span><br />'>
                  </a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
            </div>

        </div> -->
        
  
        <div class="d-flex mt-5 justify-content-center">
          <div class="input-fiel">
            <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
              searchtabindex="3" #searchButton (keyup.enter)="clickSearchButton()"
              [disabled]="misReportFilterForm.invalid"
              (click)="search(misReportFilterForm.value)">
              Search</button>
            <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
          </div>
        </div>
      </div>
    </div>
  </form>