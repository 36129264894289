<form [formGroup]="gstreportFilterForm">
  <div class="flexbox aff-info">
    <div class="flexbox aff-info-inner">

      <div class="flexbox">
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="1" tabindex="1" formControlName="start_date" id="from_date" type="date"
          min="{{minDate}}" max="{{maxDate}}"
          [ngClass]="{'error-box': !gstreportFilterForm.controls.start_date.valid}"
              />
          <label for="from_date">Starting Date</label>

        </div>
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="2" tabindex="2" formControlName="end_date" id="to_date" type="date"
            min="{{minDate}}" max="{{maxDate}}"
            [ngClass]="{'error-box': !gstreportFilterForm.controls.end_date.valid}"
              />
          <label for="to_date">Ending Date</label>
        </div>
      </div>

      <div class="flexBox d-flex mt-3 ">

        <div class="select-field text-input-fiel">
					<select class="input-field" searchtabindex="3"  tabindex="3" formControlName="account_type">
						<option value="none">None</option>
						<option value="client">Client</option>
						<option value="airline">Airline</option>
						<option value="supplier">Supplier</option>
						<option value="general">General</option>
					</select>
					<label for="subLedger">Account Type</label>
				</div>

        <div *ngIf="gstreportFilterForm.controls.account_type.value === 'none'; else accountName"  class="select-field text-input-fiel" searchtabindex="4" tabindex="4" >
          <select class="input-field" formControlName="subgroup_type" (change) = removeClient()>
              <option value="client">Client</option>
						<option value="supplier">Supplier</option>
          </select>
          <label for="account">Account</label>
      </div>
        
      <ng-template #accountName >
        <div class="input-fiel form-group ng-field" searchtabindex="4" tabindex="4" (focus)="focusAccountAc()">
          <label class="ngauto-label" for="affname">Account</label>
          <div class="ng-autocomplete" >
            <ng-autocomplete [data]="clientArr"  #accountAC 
              [searchKeyword]="keyword" (inputChanged)='getClient($event,false)'
              historyIdentifier="clientArr" [itemTemplate]="itemTemplate" formControlName="client_name"
              (selected)="selectAccount($event,'client')" [notFoundTemplate]="notFoundTemplate">
  
            </ng-autocomplete>
            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                                ({{item.account_code}})</span><br />
                            <span class="colr-2">{{item.primary_email}}</span><br />'>
              </a>
            </ng-template>
            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
      </ng-template>

      </div>

      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
             #searchButton (keyup.enter)="clickSearchButton()" searchtabindex="4"
             [ngClass]="{disbaledBtn: gstreportFilterForm.invalid}"
             [disabled]="gstreportFilterForm.invalid"
            (click)="search(gstreportFilterForm.value)">
            Search</button>
          <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
        </div>
      </div>

    </div>
  </div>
</form>