import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { NumberToWordsPipe } from 'src/app/shared/pipe/print-invoice.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver'; 
import { FormBuilder } from '@angular/forms';

@Component({
	selector: 'app-print-credit-note',
	templateUrl: './print-credit-note.component.html',
	styleUrls: ['./print-credit-note.component.scss']
})
export class PrintCreditNoteComponent implements OnInit {
booking_ref_no:any;
	creditNote: any = [];
	credit_note_code: any;
	creditnoteList: any;
	handlingCharges: any = 0;
	tdsRecv: any = 0;
	accountList: any;
	totalFareObj : any
	baseFare = 0
	yq = 0
	yr = 0
	k3 = 0
	oc = 0
	ot = 0
	baggage = 0
	meal = 0
	seat = 0
	additionalBaggage = 0
	ancillary = 0
	totalAmount = 0;
	subTotalFare = 0
	@Input() reference_code;
	@Input() preFetchedData;
	@Output() callSaveFetchedData: EventEmitter<any> = new EventEmitter()
	isValid: boolean;
	waterMark;
	preview: boolean = true;
	displayBillingDetails : any
	updateBillingDetailsForm : any
	showPartyName: boolean = true;
	@ViewChild('openBillingDetailsModal', { static: true }) openBillingDetailsModal: ElementRef
	constructor(
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private router : Router,
		private route: ActivatedRoute,
		public sanitizer: DomSanitizer,
		private fb: FormBuilder,
		) {
	    this.sanitizer = sanitizer;
		this.route.paramMap.subscribe(params => {
			this.credit_note_code = params['params'].id
		});
	}

	ngOnInit() {
		if(!this.preFetchedData){
			if(!this.credit_note_code){
				this.credit_note_code = this.reference_code
			}
			this.getData()
		}else{
			this.creditNote.push(this.preFetchedData)
			this.bindData()
		}

		let queryParams = this.route.queryParams['value'];
		if(queryParams && queryParams.hide_party && queryParams.hide_party == 'true') {
			this.showPartyName = false;
		}
		// this.getData()
		if (this.credit_note_code != undefined) {
			document.title = `Credit Note - ${this.credit_note_code}`
		}
		this.updateBillingDetailsForm = this.fb.group({
			"invoice_no": [this.credit_note_code],
			"display_billing_details": ['']
		})
	}
	getTotalBaseFare(ar){
		console.log(ar)
	}
	

	getData() {
		this.spinner.show();
		this.commonService.getOkount(`creditNote/download?credit_note_no=${this.credit_note_code}&preview=${this.preview}`).subscribe(async (data) => {
			this.creditNote = this.transform(data);
			try {
				this.callSaveFetchedData.emit({
					data : this.creditNote,
					invoice_no : this.credit_note_code,
				})
			} catch (error) {
				
			}
		}, error => {
			this.spinner.hide();
		})

		try {
			this.commonService.getOkount(`v3/view-trial-balance/${this.credit_note_code}`).subscribe(res => {
				this.accountList = res
				if (this.accountList != undefined) {
					this.handlingCharges = this.getAmountValue('HANDLING CHARGES', 'credit')
					this.tdsRecv = this.getAmountValue('TDS PAYABLE', 'debit')
					console.log('HANDLING CHARGES : ', this.handlingCharges)
					console.log('TDS PAYBLE : ', this.tdsRecv)
				}
			}, err => {
				console.log('Error  : ', err)
			})
		} catch (error) {

		}
	}
	
	transform(html) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}

	downloadPdf(searchId: any) {
		this.commonService.downloadPDF(`${environment.okountUrl}/creditNote/download?credit_note_no=${searchId}&preview=false`,null).subscribe((fileBlob:any) =>{
			let fileName = `Credit_Note-${this.credit_note_code}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}
	

	bindTotalFareTraveller(data){
		for (const traveller of data) {

			this.baseFare+=Number(this.getBase(traveller?.fare.base , traveller?.service_charge?.customer))
			this.yq+=Number(this.getYQ(traveller?.fare.YQ , traveller?.service_charge?.customer))
			this.yr+=Number(this.getYR(traveller?.fare.YR , traveller?.service_charge?.customer))
			this.k3+=Number(this.getK3(traveller?.fare.K3 , traveller?.service_charge?.customer))
			this.oc+=Number(this.getOC(traveller?.fare.OC , traveller?.service_charge?.customer))
			this.ot += this.getOT(traveller?.fare.OT , traveller?.service_charge?.customer)
			this.baggage+=this.getSSRamount(traveller.ssr,'baggage')
			this.meal+=this.getSSRamount(traveller.ssr,'meal')
			this.seat+=this.getSSRamount(traveller.ssr,'seat')
			this.additionalBaggage+=Number(this.getSSRamount(traveller.ssr,'additionalBaggage'))
			this.ancillary+=Number(this.getSSRamount(traveller.ssr,'ancillary'))
			this.subTotalFare+=this.getTotal(traveller)
	  }
		

	}

	async bindData(){
		this.bindTotalFareTraveller(this.creditNote[0]?.products[0]?.json_doc.travellers)
		this.isValid = true
		if (this.creditNote[0].products[0] != undefined) {
			if (this.creditNote[0].products[0].json_doc.travellers) {
				let travellers = this.creditNote[0].products[0].json_doc.travellers
				this.totalFareObj = this.creditNote[0].products[0].json_doc?.total_fare

				this.totalAmount = 0
				for (let i = 0; i < travellers.length; i++) {
					const element = travellers[i];
					let commissionObj = element['commission']['customer']
					let baseFare = element['fare']['base'];
					let YQ = element['fare']['YQ'];
					let YR = element['fare']['YR'];
					let fareObj = await this.calculateComission(commissionObj,baseFare,YQ,YR)
					element['totalCommission'] = fareObj['commission']
					element['totalTDS'] = fareObj['tds']
					element['gstOnCommission'] = fareObj['gstOnCommission']
					element['totalAmount'] = await this.getTotalAmount(element)
					this.totalAmount += element['totalAmount']
					// console.log(`Fare obj for traveller : ${element['name']} `,fareObj)
				}
			}

		}
		
		try {
			setTimeout(() => {
				document.querySelector("#print-section").classList.add("voucher-container")
			}, 1000);
		} catch (error) {
			
		}
	}
	
	getBase(BASE, serviceCharge) {
		let scAmount = 0
		if(serviceCharge.sc1_type =='BASE' || serviceCharge.sc1_type =='B'){
			scAmount+=Number(serviceCharge.sc1_value)
		}
		if(serviceCharge.sc2_type =='BASE' || serviceCharge.sc2_type =='B'){
			scAmount+=Number(serviceCharge.sc2_value)
		}
		if(serviceCharge.sc3_type =='BASE' || serviceCharge.sc3_type =='B'){
			scAmount+=Number(serviceCharge.sc3_value)
		}
		return Number(BASE) + (scAmount  ? Number(scAmount) : 0);
	}
	getYQ(YQ, serviceCharge) {
		let scAmount = 0
		if(serviceCharge.sc1_type =='YQ' || serviceCharge.sc1_type =='Y'){
			scAmount+=Number(serviceCharge.sc1_value)
		}
		if(serviceCharge.sc2_type =='YQ' || serviceCharge.sc2_type =='Y'){
			scAmount+=Number(serviceCharge.sc2_value)
		}
		if(serviceCharge.sc3_type =='YQ' || serviceCharge.sc3_type =='Y'){
			scAmount+=Number(serviceCharge.sc3_value)
		}
		return Number(YQ) + (scAmount  ? Number(scAmount) : 0);
	}
	getYR(YR, serviceCharge) {
		let scAmount = 0
		if(serviceCharge.sc2_type =='YR'){
			scAmount+=Number(serviceCharge.sc1_value)
		}
		if(serviceCharge.sc2_type =='YR'){
			scAmount+=Number(serviceCharge.sc2_value)
		}
		if(serviceCharge.sc3_type =='YR'){
			scAmount+=Number(serviceCharge.sc3_value)
		}
		return Number(YR) + (scAmount  ? Number(scAmount) : 0);
	}
	getK3(K3, serviceCharge) {
		let scAmount = 0
		if(serviceCharge.sc1_type =='K3'){
			scAmount+=Number(serviceCharge.sc1_value)
		}
		if(serviceCharge.sc2_type =='K3'){
			scAmount+=Number(serviceCharge.sc2_value)
		}
		if(serviceCharge.sc3_type =='K3'){
			scAmount+=Number(serviceCharge.sc3_value)
		}
		return Number(K3) + (scAmount  ? Number(scAmount) : 0);
	}
	getOC(OC, serviceCharge) {
		let scAmount = 0
		if(serviceCharge.sc1_type =='OC'){
			scAmount+=Number(serviceCharge.sc1_value)
		}
		if(serviceCharge.sc2_type =='OC'){
			scAmount+=Number(serviceCharge.sc2_value)
		}
		if(serviceCharge.sc3_type =='OC'){
			scAmount+=Number(serviceCharge.sc3_value)
		}
		return Number(OC) + (scAmount  ? Number(scAmount) : 0);
	}
	
	

	getOT(ot, serviceCharge) {
		let scAmount = 0
		if(serviceCharge.sc1_type == 'N' || serviceCharge.sc1_type == 'OT' || serviceCharge.sc1_type == 'T'){
			scAmount+=Number(serviceCharge.sc1_value)
		}
		if(serviceCharge.sc2_type == 'N' || serviceCharge.sc2_type == 'OT' || serviceCharge.sc2_type == 'T'){
			scAmount+=Number(serviceCharge.sc2_value)
		}
		if(serviceCharge.sc3_type =='N' || serviceCharge.sc3_type =='OT' || serviceCharge.sc3_type == 'T'){
			scAmount+=Number(serviceCharge.sc3_value)
		}
		return Number(ot) + (scAmount  ? Number(scAmount) : 0);
	}
	getTotal(traveller) {
		const base = this.getBase(traveller?.fare.base , traveller?.service_charge?.customer)
		const YQ = this.getYQ(traveller?.fare.YQ , traveller?.service_charge?.customer)
		const YR = this.getYR(traveller?.fare.YR , traveller?.service_charge?.customer)
		const K3 = this.getK3(traveller?.fare.K3 , traveller?.service_charge?.customer)
		const OC = this.getOC(traveller?.fare.OC , traveller?.service_charge?.customer)
		const OT = this.getOT(traveller?.fare.OT , traveller?.service_charge?.customer)
		const baggage = this.getSSRamount(traveller.ssr,'baggage')
		const meal = this.getSSRamount(traveller.ssr,'meal')
		const seat = this.getSSRamount(traveller.ssr,'seat')
		const additionalBaggage = this.getSSRamount(traveller.ssr,'additionalBaggage')
		const ancillary = this.getSSRamount(traveller.ssr,'ancillary')
		const fare = base + YQ + YR + K3 + OC + OT + baggage + meal + seat + additionalBaggage + ancillary
		return fare ;
	}

	getAmountValue(amountName, amountType) {
		for (const key in this.accountList) {
			if (this.accountList.hasOwnProperty(key)) {
				const element = this.accountList[key];
				if (element['name'] == amountName) {
					return Number(element[amountType])
				}
			}
		}
	}

	getSSRamount(ssrs, type) {
		if (ssrs != undefined) {
			let mealAmount = 0
			let seatAmount = 0
			let baggageAmount = 0
			let additionalBaggageAmount = 0
			let ancillaryAmount = 0
			for (let i = 0; i < ssrs.length; i++) {
				const ssr = ssrs[i];
				mealAmount += Number(ssr.meal.amount)
				baggageAmount += Number(ssr.baggage.amount)
				seatAmount += Number(ssr.seat.amount)
				additionalBaggageAmount += Number(ssr.additionalBaggage ? ssr.additionalBaggage.amount : 0)
				ancillaryAmount += Number(ssr.ancillary ? ssr.ancillary.amount : 0)
			}
			if (type == 'seat') {
				return seatAmount
			} else if (type == 'meal') {
				return mealAmount
			} else if (type == 'baggage') {
				return baggageAmount
			}else if (type == 'additionalBaggage') {
				return additionalBaggageAmount
			}else if (type == 'ancillary') {
				return ancillaryAmount
			}
		}
	}

	printInvoice() {
		window.print()
	}
	getNumberToWord(value) {
		return new NumberToWordsPipe().transform(value)
	}



	// Calculate Amount 

	getTotalAmount(traveller) {
		var base = Number(traveller.fare.base);
		var yq = Number(traveller.fare.YQ);
		var yr = Number(traveller.fare.YR);
		var k3 = Number(traveller.fare.K3);
		var oc = Number(traveller.fare.OC);
		var ot = Number(traveller.fare.OT);
		var ssr = 0;
		var tds = Number(traveller.totalTDS);
		var commission = Number(traveller.totalCommission);
		var sc1 = Number(traveller.service_charge.customer.sc1_amount);
		var sc2 = Number(traveller.service_charge.customer.sc2_amount);
		var xxl = Number(traveller.service_charge.customer.xxl);
		var raf = Number(traveller.service_charge.customer.raf);
		var gstOnRaf = Number(traveller.service_charge.customer.gst_on_raf);
		let flightTotal = base + yq + yr + k3 + oc + ot + ssr;
		let total = flightTotal - commission + tds - xxl - raf - gstOnRaf
		return total
	}
	

	calculateComission(req, base, yq, yr) {
		var iataType = "";
		var pblType = "";
		var cashType = "";
		var iataRate = 0.0;
		var plbRate = 0.0;
		var cashRate = parseFloat("0.0");
		var YQ = yq;
		var YR = yr;
		var total = 0
		//for better excetion handling
		if (req) {
			if (req["iata_type"]) {
				iataType = req["iata_type"];
			} else {
				throw new Error("Failed getting IATA_TYPE");
			}
			if (req["plb_type"]) {
				pblType = req["plb_type"];
			} else {
				throw new Error("Failed getting PLB_TYPE ");
			}
			if (req["cashback_type"]) {
				cashType = req["cashback_type"];
			} else {
				throw new Error("Failed getting CASH_TYPE ");
			}
			if (req["iata_value"] != null && req["iata_value"] != undefined) {
				iataRate = parseFloat(req["iata_value"]);
			} else {
				throw new Error("Failed getting CASH_TYPE ");
			}
			if (req["plb_value"] != null && req["plb_value"] != undefined)  {
				plbRate = parseFloat(req["plb_value"]);
			} else {
				throw new Error("Failed getting CASH_TYPE ");
			}
			if (req["plb_value"] != null && req["plb_value"] != undefined) {
				cashRate = parseFloat(req["cashback_value"]);
			}
		} else {
			throw new Error("Failed to get commission data ");
		}
		var iataCommission = 0.0;
		if (iataType.toUpperCase() == "VL") {
			iataCommission = iataRate;
			total = (total - iataCommission);
		} else if (iataType.toUpperCase() == "RB") {
			iataCommission = (base * iataRate / 100);
			base = (base - iataCommission);
		} else if (iataType.toUpperCase() == "RY") {
			var baseCommission = (base * iataRate / 100);
			var yQCommission = (YQ * iataRate / 100);
			base = (base - baseCommission);
			YQ = (YQ - yQCommission);
			iataCommission = baseCommission + yQCommission;
		} else if (iataType.toUpperCase() == "RR") {
			var baseCommission = (base * iataRate / 100);
			var yRCommission = (YR * iataRate / 100);
			base = (base - baseCommission);
			YR = (YR - yRCommission);
			iataCommission = baseCommission + yRCommission;
		} else if (iataType.toUpperCase() == "RQ") {
			iataCommission = (YQ * iataRate / 100);
			YQ = (YQ - iataCommission);
		} else if (iataType.toUpperCase() == "RT") {
			iataCommission = (total * iataRate / 100);
			total = (total - iataCommission);
		}
		var plbCommission = 0.0;
		if (pblType.toUpperCase() == "VL") {
			plbCommission = plbRate;
			total = (total - plbCommission);
		} else if (pblType.toUpperCase() == "RB") {
			plbCommission = (base * plbRate / 100);
			base = (base - plbCommission);
		} else if (pblType.toUpperCase() == "RR") {
			var baseCommission = (base * plbRate / 100);
			var yRCommission = (YR * plbRate / 100);
			base = (base - baseCommission);
			YR = (YR - yRCommission);
			plbCommission = baseCommission + yRCommission;
		} else if (pblType.toUpperCase() == "RY") {
			var baseCommission = (base * plbRate / 100);
			var yQCommission = (YQ * plbRate / 100);
			base = (base - baseCommission);
			YQ = (YQ - yQCommission);
			plbCommission = baseCommission + yQCommission;
		} else if (pblType.toUpperCase() == "RQ") {
			plbCommission = (YQ * plbRate / 100);
			YQ = (YQ - plbCommission);
		} else if (pblType.toUpperCase() == "RT") {
			plbCommission = (total * plbRate / 100);
			total = (total - plbCommission);
		}
		var cashbackCommission = 0.0;
		// Cash back commission only appplicable for Special round trip in GDS
		if (cashType.toUpperCase() == "VL") {
			cashbackCommission = (cashRate); //TODO : Add segment size
			total = (total - cashbackCommission);
		} else if (cashType.toUpperCase() == "RB") {
			cashbackCommission = (base * cashRate / 100);
			base = (base - cashbackCommission);
		} else if (cashType.toUpperCase() == "RR") {
			var baseCommission = (base * cashRate / 100);
			var yRCommission = (YR * cashRate / 100);
			base = (base - baseCommission);
			YR = (YR - yRCommission);
			cashbackCommission = baseCommission + yRCommission;
		} else if (cashType.toUpperCase() == "RY") {
			var baseCommission = (base * cashRate / 100);
			var yQCommission = (YQ * cashRate / 100);
			base = (base - baseCommission);
			YQ = (YQ - yQCommission);
			cashbackCommission = baseCommission + yQCommission;
		} else if (cashType.toUpperCase() == "RQ") {
			cashbackCommission = (YQ * cashRate / 100);
			YQ = (YQ - cashbackCommission);
		} else if (cashType.toUpperCase() == "RT") {
			cashbackCommission = (total * cashRate / 100);
			total = (total - cashbackCommission);
		}
		var totalCommission = plbCommission + iataCommission + cashbackCommission;
		const gstOnCommission = totalCommission * 0.18;
		totalCommission = totalCommission - gstOnCommission
		var totalTds = totalCommission * 0.05
		const obj = {
			"commission": totalCommission,
			"tds": totalTds,
			"gstOnCommission": gstOnCommission
		}
		return obj;
	}

	round_to_precision(x) {
		return Math.round(x * 100) / 100;
	}


	viewJournal(invoice_no){
		let currentUrl = `print-credit-note/${invoice_no}`
		localStorage.setItem('previousInvoiceUrl',currentUrl)
		this.router.navigate(['/transactions/view-invoice-journal',invoice_no])
	}

	getBookingRefNo(nar){
		return nar.split(',').filter(e => e.includes('AT'))[1].split(':')[1]
	}
	getBaseFareSubTotal(){
	}

	updateDisplayBilling(){
		let reqObj = {
			invoice_no : this.updateBillingDetailsForm.value.invoice_no,
			display_billing_details : this.updateBillingDetailsForm.value.display_billing_details
		}
		
		this.displayBillingDetails = 'updating...'
		this.commonService.putRequest(`${environment.okountUrl}/creditNote/display_billing_details`,reqObj).subscribe(async(res: any) =>{
			this.openBillingDetailsModal.nativeElement.click()
			this.getData()
				
		},err =>{
			console.log(err)
		})
	}

	openBillingDetails() {
		this.openBillingDetailsModal.nativeElement.click();
	}

}
