import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientRoutingModule } from './client-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { ClientComponent } from './client.component';
import { UpdateClientComponent } from './update-client/update-client.component';
import { CreateClientComponent } from './create-client/create-client.component';
import { ViewClientComponent } from './view-client/view-client.component';
import { UpdatePolicyComponent } from './update-policy/update-policy.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';


@NgModule({
  declarations: [ ClientComponent , UpdateClientComponent, CreateClientComponent, ViewClientComponent, UpdatePolicyComponent],
  imports: [
    CommonModule,
    SharedModule,
    ClientRoutingModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [UpdateClientComponent]
})
export class ClientModule { }
