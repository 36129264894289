<div class="ledger-container p-0">
    <div class="row tab-nav pl-0 pr-0">
        <!-- <div class="col-sm-4 pl-0">
            <ul class="list-inline list-unstyled p-0 m-0">
                <li class="active page-title text-uppercase cursor"> View Client</li>
            </ul>
        </div> -->
        <div class="col-sm-4 ">
          <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
              <li [routerLink]="['/']"
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> Invoice</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li [routerLink]="['/']"
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> JV</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li class="text-grey text-capitalize cursor text-primary transition"> View JV</li>
          </ul>
      </div>
        <div class="col-sm-4 text-center">
            <span class="rec-date d-block text-center" >
                <div class="noWrap justify-content-around">
                  <ng-container *ngIf="duration">
                        <div class="cname text-bold"><span *ngIf="duration.from" class="text-bold">From</span>
                            <h5 style="margin-right: 5px;" class="mb-0  d-inline">
                                <span class="date text-bold text-orange" *ngIf="duration.from"
                                    >
                                    {{duration.from}}
                                </span>
                                <span class="date text-bold" *ngIf="duration.from">
                                    To
                                </span>
                                <span class="date text-bold" >
                                    Till Date
                                </span>
                                <span class="date text-bold text-orange">
                                    {{duration.to}}
                                </span>
                            </h5>
                            <button title="Custom Search" class="btn   save-as text-bold bg-whiteSmoke "
                                type="button"  (click)="openAdvanceSearchModal()">
                                <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
                                
                            </button>
                        </div>
                    </ng-container>								
                </div>
            </span>
        </div>
        <div class="col-sm-4  d-flex align-item-center justify-content-end">
          <div class="page-search-box input-group  d-flex align-item-center"
          style="height: fit-content; text-align: left;">
          <input type="text" class="form-control" [(ngModel)]="onSearch" oninput="this.value = this.value.toUpperCase()"
            (ngModelChange)="this.onSearchFilter.next($event)" placeholder="search" style=" text-align: left" />
         
        </div>
        </div>
    </div>

    <div class="table-container">
        <div class="table" style="    margin-top: -5px;">

            <nz-table #jvData [nzData]="jvArr"
                [nzLoading]="loader"
                [nzFrontPagination]="false"
            >

                <thead>
                    <tr #tableHeader tabIndex="0">
                        <th class="text-left">Doc. No.</th>
                        <th class="text-left">JV Date</th>
                        <th class="text-left">Created Date</th>
                        <th class="text-left">Last Updated By</th>
                        <th class="text-right">Debit Amount</th>
                        <th class="text-right">Credit Amount</th>
                    </tr>
                </thead>
                <ng-container *ngIf="jvData && jvData.data && jvData.data.length>0 else noResult">
                <tbody [ngStyle]="{height: innerHeight}" class="scroll-body main-panel not-found-alignment" infiniteScroll
                        (window:keydown)="onKeyDown($event)" [fromRoot]="fromRoot" [infiniteScrollDistance]="1"
                        [infiniteScrollUpDistance]="1" [infiniteScrollThrottle]="200"
                        [infiniteScrollContainer]="selector" (scrolled)="onScrollDown($event)"
                        (scrolledUp)="onScrollUp($event)">
                    <ng-container *ngFor="let item of jvData['data'] ; let i = index">
                        <tr class="field-table-input" [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}"
                            (keyup.enter)="openJV(item.journal_code)" >
                            <td (click)="moveToIndex(i)" class="text-left text-grey refer font-500 doc-number-color"><span
                                    (click)="openJV(item.journal_code)">{{item?.journal_code}}</span></td>
                            <td (click)="moveToIndex(i)" class="text-left text-grey">{{item?.jv_date | date :
                                'dd/MM/yyyy HH:mm'}}</td>
                            <td (click)="moveToIndex(i)" class="text-left text-grey">{{item?.last_updated_on | date :
                                'dd/MM/yyyy HH:mm'}}</td>
                            <td (click)="moveToIndex(i)" class="text-left text-grey">{{item?.last_updated_by_name}}</td>
                            <td (click)="moveToIndex(i)" class="text-right text-red font-500">{{item?.debit_amount}}</td>
                            <td (click)="moveToIndex(i)" class="text-right text-green font-500 amt-green">{{item?.credit_amount}}</td>
                        </tr>
                    </ng-container>
                </tbody>
                </ng-container>
                <ng-template #noResult>
                    <div *ngIf="!loader" class="w-100 text-center" style="padding: 20px;">
                        <nz-empty nzNotFoundContent="No result Found"></nz-empty>
                    </div>
                  </ng-template>
            </nz-table>
        </div>
    </div>
</div>