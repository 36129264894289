import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ClientComponent } from './client.component';
import { UpdateClientComponent } from './update-client/update-client.component';
import { CreateClientComponent } from './create-client/create-client.component';
import { ViewClientComponent } from './view-client/view-client.component';
import { UpdatePolicyComponent } from './update-policy/update-policy.component';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';


const routes: Routes = [

	{
		path: '', component: ClientComponent, 
		children: [

			{ path: 'create-client', component: CreateClientComponent, canActivate:[AuthGuardService] },
			{ path: 'update-client/:id', component: UpdateClientComponent, canActivate:[AuthGuardService] },
			{ path: 'view-client', component: ViewClientComponent, canActivate:[AuthGuardService], },
			{ path: 'update-policy', component: UpdatePolicyComponent, canActivate:[AuthGuardService], },

			// {
			// 	path: '',
			// 	redirectTo: 'create-client',
			// 	pathMatch: 'full'
			// }
		]
	},

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ClientRoutingModule { }
