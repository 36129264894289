import { combineReducers, createStore } from "redux";

import {TransactionReducers} from 'src/app/transactions/store/reducers'
import { sharedReducers } from "../shared/storev1/reducers";
// import { sharedReducers } from "./sharedModule/reducers";


const reducers = {
    TransactionReducers,
    sharedReducers

}
export interface InitialState {
    TransactionReducers:any,
    sharedReducers:any

}
export const initialState = {
    TransactionReducers:{},
    sharedReducers:{}
};


export  const rootReducers = combineReducers({
  ...reducers,
})
