import {
  Component,
  Input,
  ComponentFactoryResolver,
  ViewChild,
  OnInit
} from "@angular/core";
import { ContentContainerDirective } from "./content-container.directive";
import { Tab } from "./shared/model/tab.model";
import { TabService } from "./shared/services/tab.service";
import { SkeletonComponent } from "./skeleton.component";

@Component({
  selector: "app-tab-content",
  template: "<ng-template content-container></ng-template>"
})
export class TabContentComponent implements OnInit {
  @Input() tab;
  viewContainerRef;
  componentFactory;
  componentRef;
  oldTabData
  @ViewChild(ContentContainerDirective, { static: true }) contentContainer: ContentContainerDirective;
  constructor(
      private componentFactoryResolver: ComponentFactoryResolver,
      private tabService : TabService
    ) {
    this.tabService.tabSub.subscribe(tabs => {
      let selectedTab = tabs.findIndex(tab => tab.active)
      if(this.tab && tabs[selectedTab] &&  this.tab.id == tabs[selectedTab].id){
        if(tabs[selectedTab].tabData && this.oldTabData &&  tabs[selectedTab].tabData.invoice != this.oldTabData.invoice){
          (this.componentRef.instance as SkeletonComponent).data = this.tab.tabData;
        }
      }
		});
  }

  async ngOnInit() {
    const tab: Tab = this.tab;
    if(this.tab && this.tab.tabData){
      this.oldTabData = JSON.parse(JSON.stringify(this.tab.tabData))
    }
    this.componentFactory = await  this.componentFactoryResolver.resolveComponentFactory(
      tab.component
    );
    this.viewContainerRef = this.contentContainer.viewContainerRef;
    this.componentRef = await this.viewContainerRef.createComponent(this.componentFactory);
    (this.componentRef.instance as SkeletonComponent).data = tab.tabData;
  }
}
