import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DarkModeService } from 'angular-dark-mode';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from '../common-service.service';
import { GlobalVariable } from '../global-variable';
import { GlobalData } from '../services/globla-data';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';
// import { SetLoginDetails } from 'src/app/store/sharedModule/actions';
import { SetFinancialyear } from '../storev1/actions';


@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit {
  maximize : boolean = false;
  minimize : boolean = true;
  isnavExpended
  showSubMenues : false;
  dashbardImgSrc : string = '';
  MasterImgSrc : string = '';
  TransactionImgSrc : string = '';
  ReportImgSrc : string = '';
  UtilityImgSrc : string = '';
  DisplayImgSrc : string = '';
  userImgSrc : string = '';
  settingImgSrc : string = '';
  notificationImgSrc : string = '';
  logoutImgSrc : string = '';
  userInfo: any;
  shortHandName: string;
  financialYear: any;
  notifcationColor : "#9C27B0"
  numOfNotification:any= [1,2,3,4,5];
  @ViewChild('logoutBtn',{static : true}) logoutBtn: ElementRef;
	@ViewChild('openDropDown',{static : true}) openDropDown: ElementRef;
	@ViewChild('closeModal',{static : true}) closeModal: ElementRef;

  isModalOpened: boolean;

  menuItems;
  activeParent: any;
  activeFirstLevel: any;
  activeSecondLevel: any;
  activeThirdLevel: any;

	@ViewChild('leftSideMenu',{static : true}) leftSideMenu;
  activeMenu: any = 'F0000';

  darkMode;
  financialYears: any;
    constructor(
      public commonService: CommonServiceService,
      private router: Router,
      private darkModeService: DarkModeService,
      private store: Store<InitialState>
    ) { 
      this.dashbardImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/menues/inactive-dashboard.svg';
      this.MasterImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/menues/inactive-master.svg';
      this.TransactionImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/menues/inactive-transaction.svg';
      this.ReportImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/menues/inactive-reports.svg';
      this.UtilityImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/menues/inactive-utility.svg';
      this.DisplayImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/menues/inactive-display.svg';
      this.userImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/menues/inactive-user.svg'
      this.settingImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/menues/inactive-settings.svg'
      this.notificationImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/menues/inactive-notification.svg'
      this.logoutImgSrc = this.commonService.aws_asset_url+'/_assets/icons/light-mode/menues/inactive-logout.svg'
    }

  
    async ngOnInit() {
      let accessData = localStorage.getItem('accessData') ? JSON.parse(localStorage.getItem('accessData')) : null
      this.menuItems = accessData ? accessData : GlobalData.getAccessData();
      if (localStorage.getItem('okountUserInfo') != null && localStorage.getItem('okountUserInfo') != undefined) {
        this.userInfo = JSON.parse(localStorage.getItem('okountUserInfo'))
        this.getInitialName(this.userInfo.name)
        if(!this.menuItems){
          this.menuItems = await this.commonService.getFeatureData(this.userInfo.roles[0].roleid)
        }
      }
      this.darkModeService.darkMode$.subscribe(status => {
        this.darkMode = status
        this.logoutImgSrc = `${this.commonService.aws_asset_url}/_assets/icons/${this.darkMode? 'dark' : 'light'}-mode/menues/inactive-logout.svg`
      })
    }

    getFeatureData(roleId: any) {
      return new Promise(async (resolve, reject) => {
        this.commonService.getRequestNoloader(`${environment.okountUrl}/featureaccess/role?id=${roleId}`).subscribe(res=>{
          resolve(res);
        }, error =>{
          resolve(null);
        })
      })
    }
    getFinancialYears(totalFinancialYear){
      let financialYearList = Array.from({length: totalFinancialYear}, (v, i) => i)
      this.financialYears = []
      for (const fy of financialYearList) {
        let data = moment().subtract(fy,'y').format('YYYY-MM-DD')
        let fySession = this.commonService.getFinancialSession(data)
        let fySessionTill = this.commonService.getFinancialSession(moment(data).add(1,'y'))
        fySessionTill = ""+fySessionTill
        fySessionTill = fySessionTill.substring(fySessionTill.length-2)
        this.financialYears.push({
          key : `${fySession}-${fySessionTill}`,
          value : fySession
        })
      }
      console.log(`LAST ${totalFinancialYear} FINANCIAL_YEARS`,this.financialYears)
    }

    getInitialName(fullName) {
      if (fullName != undefined) {
        let nameArr = fullName.split(" ")
        let shorthandName = ""
        if (nameArr.length == 1) {
          shorthandName = nameArr[0].substring(0, 2)
        } else if (nameArr.length > 1)
          for (const key in nameArr) {
            if (nameArr.hasOwnProperty(key)) {
              if (Number(key) <= 1) {
                const element = nameArr[key];
                shorthandName += element.substring(0, 1)
              }
            }
          }
        this.shortHandName = shorthandName
        console.log('Shorthand name ', this.shortHandName)
      }
    }

    setLogoutMOdalStatus(status){
      this.commonService.setLogOutModalStatus(status)
    }
  
    maximizeLeftSideBarMenues(){
      this.maximize = true;
      this.minimize = false
    }
    minimizeLeftSideBarMenues(){
      this.maximize = false;
      this.minimize = true;
    }  



    handleParentMenu(parent){
      if(parent.feature_code == this.activeParent){
        this.activeParent = null
        this.activeFirstLevel = null
        this.activeSecondLevel = null
        this.activeThirdLevel = null
      }else{
        this.activeParent = parent.feature_code
      }
      if(parent.is_menu && parent.url != null){
        this.activeParent = null
        this.activeFirstLevel = null
        this.activeSecondLevel = null
        this.activeThirdLevel = null
        this.activeMenu = parent.feature_code ? parent.feature_code : undefined
        if(parent.feature_code =='F0000'){
          window.location.href = `${environment.accountManagement}`
        }else{
          this.router.navigateByUrl(parent.url)
        }
      }
    }

    // hanldeActivePage(feature_code){
    //   this.activeParent = null
    //   this.activeFirstLevel = null
    //   this.activeSecondLevel = null
    //   this.activeThirdLevel = null
    //   this.activeMenu = feature_code ? feature_code : undefined
    // }

    hanldeActivePage(feature_code,menuData){
      this.activeParent = null
      this.activeFirstLevel = null
      this.activeSecondLevel = null
      this.activeThirdLevel = null
      this.activeMenu = feature_code ? feature_code : undefined
      if(menuData.is_menu && menuData.url != null){
        this.activeMenu = menuData.feature_code ? menuData.feature_code : undefined
        let queryString = menuData.query_params ? this.paramsToString(menuData.query_params) : "";
        if(menuData.query_params && menuData.query_params.app && menuData.query_params.app == 'systacc') {
          let url = `${environment.accountManagement}${menuData.url}`;
          window.location.href = url
          return;
        }
        if(menuData.query_params && menuData.query_params.app_version){
          let url = `${environment.baseAccountUrl}/${menuData.query_params.app_version}/#${menuData.url}${queryString ? `?${queryString}` : ''}`;
          window.location.href = url
        } else{
          this.router.navigate([menuData.url],{queryParams : menuData.query_params})
        }
      }
    }

    paramsToString(obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p) && p != 'app_version') {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    }

    handleFirstLevelMenu(featureCode){
      if(featureCode == this.activeFirstLevel){
        if(featureCode==null){
          this.activeParent = null
        }
        this.activeFirstLevel = null
        this.activeSecondLevel = null
        this.activeThirdLevel = null
      }else{
        this.activeFirstLevel = featureCode
      }
    }
    handleSecondLevelMenu(featureCode){
      if(featureCode == this.activeSecondLevel){
        this.activeParent = null
        this.activeFirstLevel = null
        this.activeSecondLevel = null
        this.activeThirdLevel = null
      }else{
        this.activeSecondLevel = featureCode
      }
    }

    handleThirdLevelMenu(featureCode){
      if(featureCode == this.activeThirdLevel){
        this.activeParent = null
        this.activeFirstLevel = null
        this.activeSecondLevel = null
        this.activeThirdLevel = null
      }else{
        this.activeThirdLevel = featureCode
      }
    }

    @HostListener('document:mousedown', ['$event'])
		onGlobalClick(event): void {
			if (this.leftSideMenu && this.leftSideMenu.nativeElement && !this.leftSideMenu.nativeElement.contains(event.target)) {
        this.activeParent = null
        this.activeFirstLevel = null
        this.activeSecondLevel = null
        this.activeThirdLevel = null
			}
		}
   
        
}
  