import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { CommonServiceService } from '../common-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalData } from '../services/globla-data';
import { DarkModeService } from 'angular-dark-mode';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';
// import { SetLoginDetails } from 'src/app/store/sharedModule/actions';
import { NgRedux } from "@angular-redux/store";
import { SetFinancialyear, SetLoginDetails } from '../storev1/actions';




@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	darkModeStatus;
	loginForm;
	resetPasswordForm;
	showMessage: boolean = false;
	alertType = '';
	message: string = ``;
	isLoginPage = true;
	constructor(
		public commonService: CommonServiceService,
		private fb: FormBuilder,
		private darkModeService: DarkModeService,
		private spinner: NgxSpinnerService,
		private route: ActivatedRoute,
		private router: Router,
		private store: Store<InitialState>,
		private ngRedux: NgRedux<InitialState>,
	) { }

	ngOnInit() {
		window.location.href = environment.accountManagement+"/signin"
		this.loginForm = this.fb.group({
			"username": ['', Validators.required],
			"password": ['', Validators.required]
		})
		this.resetPasswordForm = this.fb.group({
			"username": ['', Validators.required]
		})
		let queryParams = this.route.queryParams['value']
		if (queryParams.access_token != undefined) {
			this.loginwithAccessToken(queryParams)
		}
		if(localStorage.getItem('okount_token') == null){
			localStorage.clear()
		}else{
			let url = ""
			if (queryParams.type == 'credit_note') {
				url = `/transactions/credit-note/create/${queryParams.invoice_no}?isPreviousYear=${queryParams.isPreviousYear}`
				
			}
			if (queryParams.type == 'external_invoice') {
				url = `/transactions/invoice`
			}
			if (queryParams.type == 'invoice') {
				url = `/transactions/invoice/view/${queryParams.invoice_no}`
				if(queryParams.employee){
					url+=`?employee=${queryParams.employee}`
				}
			}
			this.router.navigateByUrl(url)
		}
		// this.darkModeService.darkMode$.subscribe(status => {
		// 	this.darkModeStatus = !status
		//   })
	}
	loginwithAccessToken(queryParams: any) {
		let reqBody = {
			access_token : queryParams.access_token
		}
		this.commonService.postWithOutInterceptor(`${environment.okountUrl}/login`, reqBody).subscribe((res: any) => {
			let loginResponse = JSON.parse(res['_body'])
			localStorage.setItem("okount_token", loginResponse['access_token'])
			localStorage.setItem("okountUserInfo", JSON.stringify(loginResponse))
			localStorage.setItem("current_financial_year",JSON.stringify(loginResponse['finance_year']))
			sessionStorage.setItem("current_financial_year",JSON.stringify(loginResponse['finance_year']))
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = 'Logged in.'
			setTimeout(() => {
				this.commonService.showLogout(true)
				if (queryParams.type == 'credit_note') {
					let url = `/transactions/credit-note/create/${queryParams.invoice_no}?isPreviousYear=${queryParams.isPreviousYear}`
					if(queryParams.booking_reference){
						url += `&bookingRef=${queryParams.booking_reference}`
					}
					this.router.navigateByUrl(url)
				}
				if (queryParams.type == 'external_invoice') {
					let url = `/transactions/invoice`
					this.router.navigateByUrl(url)
				}
				if (queryParams.type == 'invoice') {
					let url = `/transactions/invoice/view/${queryParams.invoice_no}`
					if(queryParams.employee){
						url+=`?employee=${queryParams.employee}`
					}
					this.router.navigateByUrl(url)
				}
			}, 100);
		}, err => {
			let errorMessage = "Invalid Access Token."
			try {
				let error = JSON.parse(err['_body'])
				if (error.message != undefined) {
					errorMessage = error.message
				}
			} catch (error) {

			}
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = errorMessage
		})
	}




	login() {
		if (this.loginForm.invalid) {
			return false
		} else {
			this.commonService.postWithOutInterceptor(`${environment.okountUrl}/login`, this.loginForm.value).subscribe((res: any) => {
				console.log(res);

				let loginResponse = JSON.parse(res['_body'])
				debugger
				this.store.dispatch(new SetLoginDetails({loginDetails: loginResponse}));
				// this.ngRedux.dispatch(SetLoginDetails({loginDetails: loginResponse}))

				localStorage.setItem("okount_token", loginResponse['access_token'])
				localStorage.setItem("okountUserInfo", JSON.stringify(loginResponse))
				localStorage.setItem("current_financial_year",JSON.stringify(loginResponse['finance_year']))
				sessionStorage.setItem("current_financial_year",JSON.stringify(loginResponse['finance_year']))
				this.store.dispatch(new SetFinancialyear(loginResponse['finance_year']));
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = 'Logged in.'
				this.commonService.getRequestNoloader(`${environment.okountUrl}/featureaccess/role?id=${loginResponse.roles[0].roleid}`).subscribe(res=>{
					localStorage.setItem('accessData',JSON.stringify(res))
					GlobalData.setAccessData(res);
					window.location.reload()
					setTimeout(() => {
						this.commonService.showLogout(true)
						this.router.navigate(['/'])
					}, 100);
				}, error =>{
					console.log(error);
				})
				
			}, err => {
				let errorMessage = "Incorrect username or password."
				try {
					let error = JSON.parse(err['_body'])
					if (error.message != undefined) {
						errorMessage = error.message
					}
				} catch (error) {

				}
				this.showMessage = true
				this.alertType = 'error-box';
				this.message = errorMessage
			})
		}
	}

	resetPassword(value) {
		let requestObj = {
			"redirect_url": `${environment.baseAccountUrl}/#/update-password`,
			"username": value.username
		}
		this.commonService.postWithOutInterceptor(`${environment.okountUrl}/forgetpassword`, requestObj).subscribe(res => {
			console.log(res)
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = 'Reset link has been sent to your registered email.'
		}, err => {
			console.log(err)
			let errorMessage = "User name doesn't exist."
			try {
				let error = JSON.parse(err['_body'])
				if (error.message != undefined) {
					errorMessage = error.message
				}
			} catch (error) {

			}
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = errorMessage
		})
	}

	hideError() {
		this.showMessage = false
	}

}
