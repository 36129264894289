<div [innerHTML]="invoiceData" class="pdf-print-view" >
</div>
<div style="margin-top:20px; text-align: center;" class="d-flex justify-content-center actionbtn">
	<button class="btn-style bg-green" (click)="downloadPdf()">Print</button>
</div>

<div style="margin-top:20px; display: flex; justify-content: center; align-items: center;" >
	<div class="switch" >
		<input id="view-toggle" class="check-toggle check-toggle-round-flat"  type="checkbox" (change)="selectMode($event.target.checked)">
		<label for="view-toggle"></label>
		<span class="on" value="norman">Normal View</span>
		<span class="off" value="consolidate">Consolidate View</span>
	</div>
</div>
