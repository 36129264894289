import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReceiptService } from '../services/receipt.service';
import { CommonServiceService } from 'src/app/shared/common-service.service';

@Component({
	selector: 'app-add-entry-receipt',
	templateUrl: './add-entry-receipt.component.html',
	styleUrls: ['./add-entry-receipt.component.scss']
})
export class AddEntryReceiptComponent implements OnInit {

	keyword = 'name';
	@ViewChild('amountField',{static : true}) amountField: ElementRef;
	@Input() isUpdate;
	@ViewChild('accountAC',{static : true}) accountAC;
	@ViewChild('subLedgerAC',{static : true}) subLedgerAC;
	@ViewChild('otherAccountAC',{static : true}) otherAccountAC;
	@ViewChild('accountType',{static:true}) accountType : ElementRef
	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('updateConfirmModal',{static:true}) updateConfirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef

	@Output() callExitEntry: EventEmitter<any> = new EventEmitter();
	accountAutoComplete: boolean = false;
	constructor(
		private spinner: NgxSpinnerService,
		public receiptService: ReceiptService,
		public commonService: CommonServiceService
	) { }

	ngOnInit() {
		this.commonService.selectedInput.next(0)
		if (this.isUpdate) {
			if (this.receiptService.receiptEntryForm.value.account_code_from_type != '') {
				this.getAccounts(this.receiptService.receiptEntryForm.value.account_code_from_type)
			}else{
				// this.getAccounts('client')
			}
	
			if (this.receiptService.receiptEntryForm.value.sub_ledger_code_1_type != '') {
				this.getLedgers(this.receiptService.receiptEntryForm.value.sub_ledger_code_1_type)
			}else{
				// this.getLedgers('client')
			}
		}
		if (this.accountType != undefined) {
			this.accountType.nativeElement.focus()
		}
		
	}

	openConfirmModal() :  void{
		this.confirmModal.nativeElement.click()
		this.commonService.selectedInput.next(24)
	}
	openUpdateConfirmModal() :  void{
		this.updateConfirmModal.nativeElement.click()
		this.commonService.selectedInput.next(26)
	}
	closeConfirmModal() :  void{
		this.closeModal.nativeElement.click()
	}
	focusAccount(e): void {
		e.stopPropagation();
		this.accountAC.focus();
	}
	focusSubLedger(e): void {
		e.stopPropagation();
		this.subLedgerAC.focus();
	}
	focusOtherAccount(e): void {
		e.stopPropagation();
		this.otherAccountAC.focus();
	}


	async saveAndEditNext(formValue) {
		this.spinner.show()
		this.closeConfirmModal()
		let response = await this.addEntry(formValue)
		if (response != false) {
			this.resetFormData()
		}
	}
	async saveAndExit(formValue) {
		this.spinner.show()
		this.closeConfirmModal()
		let response = await this.addEntry(formValue)
		if (response != false) {
			this.exitEntryPage()
		}
	}

	async updateAndExit(formValue){
		this.spinner.show()
		this.closeConfirmModal()
		let response = await this.updateEntry(formValue)
		if (response != false) {
			this.receiptService.isEdit = false
			this.exitEntryPage()
		}
	}
	async deleteAndExit(value){
		this.spinner.show()
		this.closeConfirmModal()
		let response = await this.deleteEntry()
		if (response != false) {
			this.receiptService.isEdit = false
			this.exitEntryPage()
		}
	}



	addEntry(formValue) {
		if (this.receiptService.receiptEntryForm.invalid || formValue.amount < 1) {
			this.spinner.hide()
			return false
		}
		formValue['receipt_id'] = this.receiptService.receiptForm.value.receipt_id
		let loggedInUser = JSON.parse(localStorage.getItem('okountUserInfo'))
		formValue['last_modified_by'] = loggedInUser.id
		formValue['last_modified_by_name'] = loggedInUser.name
		return new Promise((resolve, reject) => {
			this.receiptService.postMethod(`receiptentryv2`, formValue).subscribe(
				res => {
					let response = res
					console.log('Response : ', response)
					this.getEntries()
					resolve(response)
				},
				err => {
					this.getEntries()
					reject("");
				}
			);
		});
	}

	updateEntry(formValue) {
		if (this.receiptService.receiptEntryForm.invalid || formValue.amount < 1) {
			this.spinner.hide()
			return false
		}
		let receipt_entry_id = localStorage.getItem('selected_receipt_entry')
		formValue['receipt_id'] = this.receiptService.receiptForm.value.receipt_id
		let loggedInUser = 	JSON.parse(localStorage.getItem('okountUserInfo'))
		formValue['last_modified_by'] = loggedInUser.id
		formValue['last_modified_by_name'] = loggedInUser.name
		return new Promise((resolve, reject) => {
			this.receiptService.putMethod(`receiptentryv2?receipt_entry_id=${receipt_entry_id}`,formValue).subscribe(
				res => {
					let response = res
					console.log('Response : ', response)
					this.getEntries()
					resolve(response)
				},
				err => {
					this.getEntries()
					reject("");
				}
			);
	});
	}

	deleteEntry() {
		let receipt_entry_id = localStorage.getItem('selected_receipt_entry')
		return new Promise((resolve, reject) => {
			this.receiptService.deleteMethod(`receiptentryv2?receipt_entry_id=${receipt_entry_id}`).subscribe(
				res => {
					let response = res
					console.log('Response : ', response)
					this.getEntries()
					resolve(response)
				},
				err => {
					this.getEntries()
					reject("");
				}
			);
	});
	}

	getTotalAmount() {
		let receipt_id = this.receiptService.receiptForm.value.receipt_id
		this.receiptService.getMethod(`receiptentryv2/getTotal?receipt_id=${receipt_id}`).subscribe(
			res => {
				let response = res
				this.receiptService.receiptForm.controls.amount.setValue(response['totalAmount'])
				console.log('Response : ', response)
			},
			err => {
			}
		);
		console.log()
	}
	async exitEntryPage() {
		this.spinner.show()
		await this.getTotalAmount()
		this.resetFormData()
		this.callExitEntry.emit(false)
		this.receiptService.showAddreceiptScreen = false
	}

	getEntries() {
		let receipt_id = this.receiptService.receiptForm.value.receipt_id
		if (receipt_id != '') {
			this.receiptService.getMethod(`receiptentryv2/getallbyreceiptid?receipt_id=${receipt_id}`).subscribe((res: any) => {
				debugger
				this.receiptService.receiptEntries = res
				this.spinner.hide();
			},
				err => {
					this.spinner.hide();
				})
		}else{
			this.receiptService.receiptEntries = []
		}
	}

	resetFormData() {

		//Reset Amount
		this.receiptService.receiptEntryForm.controls.amount.setValue(0)

		//Reset Check Data 
		this.receiptService.receiptEntryForm.controls.cheque_no.setValue("")
		this.receiptService.receiptEntryForm.controls.cheque_date.setValue(null)


		//Reset Narrations
		this.receiptService.receiptEntryForm.controls.narration_1.setValue("")
		this.receiptService.receiptEntryForm.controls.narration_2.setValue("")
		this.receiptService.receiptEntryForm.controls.narration_3.setValue("")
		this.receiptService.receiptEntryForm.controls.narration_4.setValue("")
		this.receiptService.receiptEntryForm.controls.narration_5.setValue("")

		//Reset Remarks
		this.receiptService.receiptEntryForm.controls.remark_1.setValue("")
		this.receiptService.receiptEntryForm.controls.remark_2.setValue("")
		this.receiptService.receiptEntryForm.controls.remark_3.setValue("")
		this.receiptService.receiptEntryForm.controls.remark_4.setValue("")
		this.receiptService.receiptEntryForm.controls.remark_5.setValue("")

		//Reset other fields
		this.receiptService.receiptEntryForm.controls.other_account.setValue("")
		this.receiptService.receiptEntryForm.controls.other_transaction_type.setValue("")
		this.receiptService.receiptEntryForm.controls.other_amount.setValue("")

		this.spinner.hide()
	}

	getAccounts(url){
		this.receiptService.getMethod(url).subscribe(res=>{
			this.receiptService.accountList = res
		},err=>{

		})
	}
	getLedgers(url){
		this.receiptService.getMethod(url).subscribe(res=>{
			this.receiptService.ledgerList = res
		},err=>{

		})
	}

	onValueChange(event){
		this.amountField.nativeElement.focus()
	}

	LedgerSearch(value) {
		debugger
		try {
			let searchType = this.receiptService.receiptEntryForm.value.sub_ledger_code_1
			if (value.length > 2 && searchType != null && searchType != '') {
				this.receiptService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				debugger	
				this.receiptService.ledgerList = res;
				});
			}
		} catch (error) {

		}
	}

	onFocused(e) {

	}

	ledgerSearch(value){
		try {
			let searchType = this.receiptService.receiptEntryForm.value.sub_ledger_code_1_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.receiptService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}&status=active`).subscribe(res => {
				this.receiptService.ledgerList = res;
				});
			}
		} catch (error) {

		}

	}


	selectAccount(value,formControlName){
		try {
			this.receiptService.receiptEntryForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}
	onChangeSearch(value) {
		try {
			let searchType = this.receiptService.receiptEntryForm.value.account_code_from_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.receiptService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}&status=active`).subscribe(res => {
				this.receiptService.accountList = res;
				});
			}
		} catch (error) {

		}
	}

	getOtherAccount(value) {
		try {
			if (value.length > 2) {
				this.receiptService.searchAccount(`general/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.receiptService.otherAccountList = res;
				});
			}
		} catch (error) {

		}
	}

	setAccountAutoCompleteStatus(status){
		this.accountAutoComplete = status
	}

	focusTab(tabIndex){
		this.commonService.selectedInput.next(tabIndex)
	}


}
