import { Component, OnInit, Output, EventEmitter, ViewChildren, QueryList, ElementRef, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { InvoiceRequestBuilderService } from '../services/invoice-request-builder.service';
import { InvoiceServicesService } from '../services/invoice-services.service';
import { TotalFareModel } from '../models/total-fare-model';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { NgxSpinnerService } from "ngx-spinner";
import { CookiesService } from 'src/app/shared/cookies.service';
import { InvoiceComponent } from '../../invoice.component';


@Component({
	selector: 'app-traveller-info',
	templateUrl: './traveller-info.component.html',
	styleUrls: ['../flight.component.scss']
})
export class TravellerInfoComponent implements OnInit {
	corporate_employee: string = "false"
	supplier_trans: string = "false"
	discount_add: string = "false"
	@ViewChildren('supplierAc') supplierAc: QueryList<ElementRef>;
	@ViewChildren('ledgerCardAc') ledgerCardAc: QueryList<ElementRef>;
	@ViewChild('employeeAc') employeeAc: QueryList<ElementRef>

	expandTraveller: boolean = false
	maximizedSection: boolean = false

	valid: boolean;
	showTravellerIndex: any;
	bookingSupplierArr: any = [];
	employeeArr: any =[]
	bookingSupplierArrBase: any = [];
	@Output() callSaveTraveller: EventEmitter<any> = new EventEmitter();
	@Output() callSaveProduct: EventEmitter<any> = new EventEmitter();
	@Output() callShowMessage: EventEmitter<any> = new EventEmitter();
	setAmountVal: boolean;
	@ViewChildren('openModal') openModal: QueryList<ElementRef>;
	@Input() invoiceType
	@Input() invoice_no
	@Input() invoice_root_data
	@Input() sectorService
	@Input() invoiceMode;
	selectedTravellerType: string;
	generalAccountArr: any;
	generalFilteredData: any
	currentSelectedAffiliate: any;
	keyword = 'name';
	initialValue = ''
	shortcuts: ShortcutInput[] = [];

	selectedGeneralAccount: any;
	currencyCode : string = "INR";
	nativeLanguageCode = '';
	isTenantIndian: boolean = false;


	constructor(
		private fb: FormBuilder,
		public requestBuilder: InvoiceRequestBuilderService,
		public invoiceService: InvoiceServicesService,
		public commonService: CommonServiceService,
		private ref: ChangeDetectorRef,
		private spinner: NgxSpinnerService,
		private cookieService : CookiesService


	) { }

	async ngOnInit() {
		let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
		
		let inv_config = globalSettingConfig.filter(el=>el.group=='TRANSACTION')
		if(inv_config.length){
			let corporate_employee = inv_config[0].json_doc.value.filter(el=>el.key=='corporate_employee')
			if(corporate_employee.length){
				this.corporate_employee = corporate_employee[0].value
			}
		}
		if(inv_config.length){
			let payable_trans = inv_config[0].json_doc.value.filter(el=>el.key=='PAYABLE TRANSACTION')
			if(payable_trans.length){
				this.supplier_trans = payable_trans[0].value
			}
		}
		if(inv_config.length){
			let discount_config = inv_config[0].json_doc.value.filter(el=>el.key=='DISCOUNT')
			if(discount_config.length){
				console.log("discount_config",discount_config)
				this.discount_add = discount_config[0].value
			}
		}
		
		this.isTenantIndian = this.commonService.checkTenantIndian()
		if(this.invoiceMode != 'view') {
			this.getBookingSupplier('', null)
			this.getGeneralAccounts()
		}

		this.sectorService.componentMethodCalled$.subscribe(index => {
			this.calculateFareFromApi(index);
		}, error => {
		})
		// this.sectorService.travellerForm.get('travellerList').valueChanges.subscribe(values => {
		// 	this.ref.detectChanges()
		// 	console.log(values)
		// })
		console.log("this.sectorService.travellerForm.controls.travellerList.controls",this.sectorService.travellerForm.controls.travellerList.controls)
		this.getAccountingCompany()
		console.log()

	}



	ngAfterViewInit() {
	
		this.shortcuts.push(			
			{
				key: "alt + plus",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Add New Traveller",
				description: "Add New Traveller",
				
				command: (e)=>{
				console.log(e)	
				this.sectorService.invoiceForm.controls.travellerCount.setValue((this.sectorService.invoiceForm.value.travellerCount+1))
				this.sectorService.addTraveller()
				setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
					},
					preventDefault: true
			},
			{
				key: "alt + -",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Last Traveller",
				description: "Delete Last Traveller",
				
				command: (e)=>{
				console.log(e)
				   if(this.sectorService.travellerList.length>0){
					let totalUpdatedTravellerCount = this.sectorService.invoiceForm.value.travellerCount-1
					this.sectorService.invoiceForm.controls.travellerCount.setValue(totalUpdatedTravellerCount)
					this.removeTraveller(totalUpdatedTravellerCount)
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				   }
				
					},
					preventDefault: true
					
			},
			{
				key: ["alt + 1","alt + 2","alt + 3","alt + 4","alt + 5","alt + 6","alt + 7","alt + 8","alt + 9"],
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Edit  Traveller",
				description: "Edit  Traveller",
				
				command: (e)=>{
				console.log(e)
				   if(document.getElementById(`edit_traveller_${Number(e.event.key)-1}_btn`)){
					document.getElementById(`edit_traveller_${Number(e.event.key)-1}_btn`).click()
				   }
				   setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
					},
					preventDefault: true
					
			},
		
			
	  )
	}
	showTravellerFull(i) {
		if (i != this.showTravellerIndex) {
			this.showTravellerIndex = i
		} else {
			this.showTravellerIndex = null
		}
	}

	async getBookingSupplier(event, currentIndex) {
		let val;
		if(event) {
			val = event.target.value;
		}
		if(event && currentIndex != null) {
			if(!val || val == null || val == undefined) {
				const traveller = this.sectorService.travellerForm.controls.travellerList.controls[currentIndex];
				traveller.controls['txtbookingSupplier'].setValue('')
				traveller.controls['txtbookingSupplierCode'].setValue('')
				let reqObj = {
					clientCode : this.sectorService.affiliate_code,
					airlineCode : this.sectorService.invoiceForm.value.airline,
					date : this.invoice_root_data.invdate ? this.invoice_root_data.invdate : null,
				}
				let resp = await this.sectorService.getDiscRecallSetting(reqObj,currentIndex)
				setTimeout(() => {
					this.calculateFareFromApi(0)
				}, 100);
			}
		};
		try {
			let url = 'util/getsupplierairline'
			if(val && val.length > 2){
				url = `util/getsupplierairline?name=${val}`
			}
			this.invoiceService.searchAccount(url).subscribe(data => {
				this.bookingSupplierArr = data
				this.bookingSupplierArrBase = data;
			}, err => {
				throw new Error(JSON.stringify(err));
			})
		} catch (error) {
			console.log('Error while getting Booking Suppliers : ', error)

		} 
	}
	
	async getCoporateEmployee(event, currentIndex) {
		let val;
		if(event) {
			val = event.target.value;
		}
		if(event && currentIndex != null) {
			if(!val || val == null || val == undefined) {
				const traveller = this.sectorService.travellerForm.controls.travellerList.controls[currentIndex];
				traveller.controls['txtPassengerName'].setValue('')
				traveller.controls['txtEmployeeName'].setValue('')
				traveller.controls['txtEmployeeCode'].setValue('')
				traveller.controls['txtEmployeeCostCenter'].setValue('')
				
				// setTimeout(() => {
				// 	this.calculateFareFromApi(0)
				// }, 100);
			}
		};
		let client_code = this.sectorService.affiliate_code
		try {
			let url = `util/getCorporateEmployee?&client=${client_code}`
			if(val && val.length > 2){
				url = `util/getCorporateEmployee?name=${val}&client=${client_code}`
			}
	
			this.invoiceService.searchAccount(url).subscribe(data => {
				this.employeeArr = data["data"]
				// this.bookingSupplierArrBase = data;
				if(!data ||(data["data"] && data["data"].length==0)){
					this.sectorService.travellerForm.controls.travellerList.controls[currentIndex].controls['txtPassengerName'].setValue(val)
				}
			}, err => {
				throw new Error(JSON.stringify(err));
			})
		} catch (error) {
			console.log('Error while getting Booking Suppliers : ', error)

		} 
	}


	getGeneralAccounts() {
		try {
			this.invoiceService.getMethod(`general/bank-od-cc`).subscribe(data => {
				this.generalAccountArr = data
				this.generalFilteredData = data;
			}, err => {
				this.generalAccountArr = []
				this.generalFilteredData = []
				throw new Error(JSON.stringify(err));
			})
		} catch (error) {
			console.log('Error while getting Booking Suppliers : ', error)

		}
	}

	maximizeSection(index) {
		this.maximizedSection = true
		this.showTravellerIndex = index
		let traveller = this.sectorService.travellerForm.value.travellerList[index]
		let tabindex = this.showTravellerIndex*(traveller.totalIndexCount)+1+(this.sectorService.lastSectorTwoIndex)
		setTimeout(()=> {
			this.commonService.selectedInput.next(tabindex)
		},100)
	}
	minimizeSection() {
		this.maximizedSection = false
		this.showTravellerIndex = null
		this.commonService.selectedInput.next(this.sectorService.lastIndexOfTraveller+1)
	}



	addTraveller() {
		this.sectorService.addTraveller()
	}
	removeTraveller(i) {
		this.sectorService.travellerList.removeAt(i)
		this.sectorService.invoiceForm.controls.travellerCount.setValue(this.sectorService.travellerList.controls.length)
		this.calculateFareFromApi(0)
	}

	get DynamicFormControls() {
		const travellerList = <FormArray>this.sectorService.travellerForm.get("travellerList");
		return travellerList;
	}


	moveToNextTraveller(i) {
		this.showTravellerIndex = i + 1
		let traveller = this.sectorService.travellerForm.value.travellerList[i]
		let index = this.showTravellerIndex*(traveller.totalIndexCount)+1+(this.sectorService.lastSectorTwoIndex)
		setTimeout(()=> {
			this.commonService.selectedInput.next(index)
		},100)
		this.calculateFareFromApi(0)
	}
	moveToPreviousTraveller(i) {
		this.showTravellerIndex = i - 1
		let traveller = this.sectorService.travellerForm.value.travellerList[i]
		let index = this.showTravellerIndex*(traveller.totalIndexCount)+1+(this.sectorService.lastSectorTwoIndex)
		this.commonService.selectedInput.next(index)
		this.calculateFareFromApi(0)
	}

	setAmount(event) {
		// this.setAmountVal = !this.setAmountVal;
		this.replicateFormData(0)
		this.calculateFareFromApi(0)
	}
	setFare(event,formControlName){
		if (event.target.checked == true) {
			let tempFormValue = Object.assign({}, this.sectorService.travellerForm.value.travellerList[0])
			let travellerListLength = this.sectorService.travellerForm.controls.travellerList.controls.length
			for (let i = 0; i < travellerListLength; i++) {
				const traveller = this.sectorService.travellerForm.controls.travellerList.controls[i];
				if (traveller.value.isConjuction == false) {
					traveller.controls[formControlName].setValue(tempFormValue[formControlName])
				}
			}

			this.calculateFareFromApi(0)
		}
	}

	replicateFormData(index) {
		let tempFormValue = Object.assign({}, this.sectorService.travellerForm.value.travellerList[index])
		let travellerListLength = this.sectorService.travellerForm.controls.travellerList.controls.length
		for (let i = 0; i < travellerListLength; i++) {
			const travellerList = this.sectorService.travellerForm.controls.travellerList.controls[i];
			if (tempFormValue.travellerStatus == travellerList.value.travellerStatus && tempFormValue.selType == travellerList.value.selType) {
				if (!travellerList.value.isConjuction) {
					this.setTravellerData(travellerList, tempFormValue)
				}
			}
		}
		this.saveTravellers();
		if (this.showTravellerIndex < travellerListLength - 1) {
			this.moveToNextTraveller(index)
		}
	}
	setTravellerData(travellerList, tempFormValue) {
		let keys = Object.keys(tempFormValue)
		for (let i = 0; i < keys.length; i++) {
			const element = keys[i];
			if (element != 'selType' && element != 'selTitle' && element != 'txtPassengerName' && element != 'txtTicketNumber' && element != 'sectorList' && element != 'prev_ticket_no') {
				if(element == 'txtbookingSupplier' && typeof tempFormValue[element] == 'object'){
					travellerList.controls[element].setValue(tempFormValue[element].name)
					travellerList.controls['txtbookingSupplierCode'].setValue(tempFormValue[element].account_code)
				}else{
					travellerList.controls[element].setValue(tempFormValue[element])

				}
			}
		}
	}

	calculateServiceCharge(i, chargeAmountContorl, chargeValueControl) {
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls[chargeAmountContorl].setValue(this.sectorService.travellerForm.controls.travellerList.controls[i].controls[chargeValueControl].value)

		// if(this.sectorService.travellerForm.controls.travellerList.controls[i].controls[chargeTypeContorl].value == 'N') {
		// 	let serviceChargeValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls[chargeValueContro].value);
		// 	let previousOt = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtOT.value)
		// 	let finalOt = previousOt + serviceChargeValue;
			// this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtOT.setValue(finalOt);
		// }
		// this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtSupplierSC1Value.value
		// this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtOT
	}

	calculateDiscountRecallAmount(i, disRecallAmountContorl, disRecallValueControl,plbControl){

		let plbAmount = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls[plbControl].value)
		let disClientRecallAmount = ((plbAmount*Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls[disRecallValueControl].value))/100).toFixed(2)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls[disRecallAmountContorl].setValue(disClientRecallAmount)
	}
	calculateTotal(i) {
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let baseFare = Number(travellerData['txtBaseFare'])
		let YQ = Number(travellerData['txtYQ'])
		let YR = Number(travellerData['txtYR'])
		let K3 = Number(travellerData['txtK3'])
		let OC = Number(travellerData['txtOC'])
		let OT = Number(travellerData['txtOT'])
		let txtAddons = this.getAddonsTotal(travellerData['sectorList'])
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let addons = this.getLocalAddonsTotal(travellerData['sectorList'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total.toFixed(2))
		let total = baseFare + YQ + YR + K3 + OC + OT + txtAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(txtAddons)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(addons)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total.toFixed(2))
		

		this.calculateCommission(i)
		this.calculateFareFromApi(0)
	}
	

	getAddonsTotal(sectors) {
		let totalAmount = 0
		for (const key in sectors) {
			if (sectors.hasOwnProperty(key)) {
				const element = sectors[key];
				if (element['txtSeatCharges'] != "") {
					totalAmount += Number(element['txtSeatCharges']) *  Number(this.invoice_root_data.currency_rate||1)
				}
				if (element['txtMealCharges'] != "") {
					totalAmount += Number(element['txtMealCharges']) *  Number(this.invoice_root_data.currency_rate||1)
				}
				if (element['txtBagCharges'] != "") {
					totalAmount += Number(element['txtBagCharges']) *  Number(this.invoice_root_data.currency_rate||1)
				}
				if (element['txtAdditionalBaggageCharges'] != "") {
					totalAmount += Number(element['txtAdditionalBaggageCharges']) *  Number(this.invoice_root_data.currency_rate||1)
				}
				if (element['txtAncillaryCharges'] != "") {
					totalAmount += Number(element['txtAncillaryCharges']) *  Number(this.invoice_root_data.currency_rate||1)
				}
			}
		}
		return totalAmount
	}


	async calculateCommission(i) {
		let sectorLength = this.sectorService.travellerForm.controls.travellerList.value[i].sectorList.length
		
		let isConjuction = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.isConjuction.value

		let clientIataType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientIataType.value
		let clientPlbType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientPlbType.value
		let clientCbType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientCbType.value
		let clientDsType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientDsType.value

		let supplierIataType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierIataType.value
		let supplierPlbType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierPlbType.value
		let supplierCbType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierCbType.value
		let supplierDsType = this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierDsType.value
		await this.getAmountForComissionCalculatation(isConjuction, 'IATA', 'CLIENT', clientIataType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'PLB', 'CLIENT', clientPlbType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'CASHBACK', 'CLIENT', clientCbType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'DISCOUNT', 'CLIENT', clientDsType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'IATA', 'SUPPLIER', supplierIataType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'PLB', 'SUPPLIER', supplierPlbType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'CASHBACK', 'SUPPLIER', supplierCbType, i,sectorLength)
		await this.getAmountForComissionCalculatation(isConjuction, 'DISCOUNT', 'SUPPLIER', supplierDsType, i,sectorLength)


		// this.calculateDiscountRecallAmount(i, 'disClientRecallAmount', 'disClientRecallValue','selClientPlbAmount')
		// this.calculateDiscountRecallAmount(i, 'disSupplierRecallAmount', 'disSupplierRecallValue','selSupplierPlbAmount')
	}


	getAmountForComissionCalculatation(isConjuction, type, csType, cType, i,sectorLength) {
		if(type == 'CASHBACK' && csType == 'CLIENT' && cType == 'VLN') {
			this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientCbValue.setValidators([Validators.pattern(/^-?\d{0,8}(\.\d{1,2})?$/)])
		} else if(type == 'CASHBACK' && csType == 'CLIENT' && cType != 'VLN') {
			this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientCbValue.setValidators([Validators.pattern(/^\d{0,8}(\.\d{1,2})?$/)])
		}
		if(type == 'CASHBACK' && csType == 'SUPPLIER' && cType == 'VLN') {
			this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierCbValue.setValidators([Validators.pattern(/^-?\d{0,8}(\.\d{1,2})?$/)])
		} else if(type == 'CASHBACK' && csType == 'SUPPLIER' && cType != 'VLN') {
			this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierCbValue.setValidators([Validators.pattern(/^\d{0,8}(\.\d{1,2})?$/)])
		}
		let loopTotal = 0;
		let totalIata = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierIataAmount.value)
		let totalPlb = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierPlbAmount.value)
		let totalCB = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierCbAmount.value)
		let totalDS = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierDsAmount.value)

		let totalIataCus = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientIataAmount.value)
		let totalPlbCus = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientPlbAmount.value)
		let totalCBCus = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientCbAmount.value)
		let totalDSCus = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientDsAmount.value)

		let supplierIataValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierIataValue.value)
		let supplierPlbValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierPlbValue.value)
		let supplierCBValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierCbValue.value)
		let supplierDSValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierDsValue.value)

		let clientIataValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientIataValue.value)
		let clientPlbValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientPlbValue.value)
		let clientCBValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientCbValue.value)
		let clientDSValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientDsValue.value)
		if (!isConjuction) {
			if (cType == "RB") {
				loopTotal += Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.value);
			}
			else if (cType == "RY") {
				loopTotal += Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.value) +
					Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYQ.value);
			}
			else if (cType == "RR") {
				loopTotal += Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.value) +
					Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYR.value);
			}
			else if (cType == "RQ") {
				loopTotal += Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYQ.value);
			}
			else if (cType == "RT") {
				loopTotal += Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.value) +
					Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYQ.value) +
					Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYR.value) +
					Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtK3.value) +
					Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtClientSC2Amount.value)
			}
			else if (cType == "VL" || cType == "VLN") {
				loopTotal = 1
			}
		}
		if (csType == "CLIENT") {
			if (type == "IATA" ) {
				if(loopTotal != 0){
					totalIataCus = (Number(loopTotal) / 100) * Number(clientIataValue);
					if (cType == "VL") {
						totalIataCus = Number(clientIataValue)
					}
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientIataAmount.setValue(totalIataCus.toFixed(2))
				}else{
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientIataAmount.setValue(0)
				}
			}
			else if (type == "PLB") {
				if(loopTotal != 0){
					totalPlbCus = (Number(loopTotal) - Number(totalIataCus)) * Number(clientPlbValue) / 100;
					if (cType == "VL") {
						totalPlbCus = Number(clientPlbValue)
					}
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientPlbAmount.setValue(totalPlbCus.toFixed(2))
				}else{
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientPlbAmount.setValue(0)
				}
			}
			else if (type == "CASHBACK") {
				if(loopTotal != 0){
					totalCBCus = ((Number(loopTotal) - (Number(totalIataCus) + Number(totalPlbCus))) / 100) * Number(clientCBValue) * sectorLength;
					if (cType == "VL") {
						totalCBCus = Number(clientCBValue) * sectorLength
					}
					if (cType == "VLN") {
						totalCBCus = Number(clientCBValue)
					}
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientCbAmount.setValue(totalCBCus.toFixed(2))
				}else{
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientCbAmount.setValue(0)
				}
				
			}
			else if (type == "DISCOUNT" && loopTotal != 0) {
				if(loopTotal != 0){
						totalDSCus = Number(clientDSValue) * sectorLength
					if (cType == "VLN") {
						totalDSCus = Number(clientDSValue)
					}
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientDsAmount.setValue(totalDSCus.toFixed(2))
				}else{
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selClientDsAmount.setValue(0)
				}
				
			}
		}
		else if (csType == "SUPPLIER") {
			if (type == "IATA") {
				loopTotal = 0
			if (cType == "RB") {
				let base = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.value);
				base = (Number(base) / 100) * Number(supplierIataValue);
				loopTotal = Math.round(base)
			}
			else if (cType == "RY") {
				let base =  Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.value) 
				base = (Number(base) / 100) * Number(supplierIataValue);
				base = Math.round(base)
				let yq = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYQ.value)
				yq = (Number(yq) / 100) * Number(supplierIataValue);
				yq = Math.round(yq)
				loopTotal = base+yq
			}
			else if (cType == "RR") {
				let base =  Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.value) 
				base = (Number(base) / 100) * Number(supplierIataValue);
				base = Math.round(base)
				let yr = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYR.value)
				yr = (Number(yr) / 100) * Number(supplierIataValue);
				yr = Math.round(yr)
				loopTotal = base+yr
			}
			else if (cType == "RQ") {
				let yq = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYQ.value)
				yq = (Number(yq) / 100) * Number(supplierIataValue);
				loopTotal = Math.round(yq)
			}
			else if (cType == "RT") {
				let base =  Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.value) 
				base = (Number(base) / 100) * Number(supplierIataValue);
				base = Math.round(base)
				let yr = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYR.value)
				yr = (Number(yr) / 100) * Number(supplierIataValue);
				yr = Math.round(yr)
				let yq = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYQ.value)
				yq = (Number(yq) / 100) * Number(supplierIataValue);
				yq = Math.round(yq)

				let k3 = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtK3.value)
				k3 = (Number(k3) / 100) * Number(supplierIataValue);

				let sc2 = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtClientSC2Amount.value)
				sc2 = (Number(sc2) / 100) * Number(supplierIataValue);
				loopTotal = base+yq+yr+k3+sc2
				loopTotal = Math.round(loopTotal)
				
			}
			else if (cType == "VL" || cType == "VLN") {
				loopTotal = 1
			}
			if(loopTotal != 0){
				totalIata = loopTotal
				// FIXME : NNED TO REMOVE INFANT TOTAL 
				if (cType == "VL") {
					totalIata = Number(supplierIataValue)
				}
				totalIata = Math.round(totalIata)
				this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierIataAmount.setValue(totalIata.toFixed(2))
			}else{
				this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierIataAmount.setValue(0)
			}

			}
			else if (type == "PLB") {
				if(loopTotal != 0){
					totalPlb = (Number(loopTotal) - Number(totalIata)) * Number(supplierPlbValue) / 100;
					if (cType == "VL") {
						totalPlb = Number(supplierPlbValue)
					}
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierPlbAmount.setValue(totalPlb.toFixed(2))
					
				}else{
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierPlbAmount.setValue(0)

				}

			}
			else if (type == "CASHBACK") {
				if(loopTotal != 0){
					totalCB = ((Number(loopTotal) - (Number(totalIata) + Number(totalPlb))) / 100) * Number(supplierCBValue)*sectorLength;
					if (cType == "VL") {
						totalCB = Number(supplierCBValue)*sectorLength
					}
					if (cType == "VLN") {
						totalCB = Number(supplierCBValue)
					}
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierCbAmount.setValue(totalCB.toFixed(2))
				}else{
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierCbAmount.setValue(0)
					
				}

			}
			else if (type == "DISCOUNT" ) {
				if(loopTotal != 0){
					totalDS = Number(supplierDSValue)*sectorLength
					if (cType == "VLN") {
						totalDS = Number(supplierDSValue)
					}
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierDsAmount.setValue(totalDS.toFixed(2))
				}else{
					this.sectorService.travellerForm.controls.travellerList.controls[i].controls.selSupplierDsAmount.setValue(0)
				}

			}

		}
	}


	calculateFareFromApiv1(i) {
		let url = `creditNote/fare`
		if(i.hasOwnProperty('index')) {
			i = i['index']
		}

		if (i == 0) {
			url = `invoice/fare`
		}
		let fareObj = this.requestBuilder.createFareObj(this.sectorService.travellerForm.value,this.sectorService.invoiceForm.value,this.sectorService.affiliate_code,this.invoice_root_data)
		if (i>0) {
			console.log('Invoice traveller while calculating fare : ', this.sectorService.invoiceTravellerForm[Number(i)-1].value)
			fareObj = this.requestBuilder.createFareObj(this.sectorService.invoiceTravellerForm[Number(i)-1].value,this.sectorService.invoiceForm.value,this.sectorService.affiliate_code,this.invoice_root_data)
		}
		if (this.sectorService.fareList.length == 0) {
			this.sectorService.fareList.push('')
		}
		// let fareObj = this.requestBuilder.createFareObj(this.sectorService.travellerForm.value)
		this.invoiceService.postMethod(url, fareObj).subscribe(res => {
			this.sectorService.totalFareModel[i] = new TotalFareModel(res);
		},
			err => {

			})
	}
	focusSupplierAc(index) {
		if (this.supplierAc != undefined && this.supplierAc["_results"] && this.supplierAc["_results"][index].nativeElement && this.supplierAc["_results"][index].nativeElement.focus) {
			this.supplierAc["_results"][index].nativeElement.focus()
		}
	  }
	  focusGeneralAc(index) {
		if (this.ledgerCardAc != undefined && this.ledgerCardAc["_results"] && this.ledgerCardAc["_results"][index].nativeElement && this.ledgerCardAc["_results"][index].nativeElement.focus) {
			this.ledgerCardAc["_results"][index].nativeElement.focus()
		}
	  }
	  focusEmployeeAc(index) {
		if (this.employeeAc != undefined && this.employeeAc["_results"] && this.employeeAc["_results"][index].nativeElement && this.employeeAc["_results"][index].nativeElement.focus) {
			this.employeeAc["_results"][index].nativeElement.focus()
		}
	  }

	async calculateFareFromApi(data) {
		let i = data;
		let type  = '';
		let url = `creditNote/fare`
		if(data.hasOwnProperty('index')) {
			i = data['index'];
			type = data['type'];
			if (type == 'invoice' || (this.sectorService.fareList[i].invoice_no && (this.sectorService.fareList[i].invoice_no.toString().includes('INV') || this.sectorService.fareList[i].invoice_no.toString().includes('IW')))) {
				url  = `invoice/fare`
			}
			else {
				url  = `creditNote/fare`
			}
		}		
		else if (i == 0) {
			url = `invoice/fare`
			if (this.sectorService.fareList[i].toString() == 'Credit Note'  ||  (this.sectorService.fareList[i].invoice_no &&  (this.sectorService.fareList[i].invoice_no.toString().includes('CRN') || this.sectorService.fareList[i].invoice_no.toString().includes('IZ')))) {
				url  = `creditNote/fare`
			}
		}
		let fareObj = this.requestBuilder.createFareObj(this.sectorService.travellerForm.value,this.sectorService.invoiceForm.value,this.sectorService.affiliate_code,this.invoice_root_data)
		if (i>0) {
			console.log('Invoice traveller while calculating fare : ', this.sectorService.invoiceTravellerForm[Number(i)-1].value)
			fareObj = this.requestBuilder.createFareObj(this.sectorService.invoiceTravellerForm[Number(i)-1].value,this.sectorService.invoiceForm.value,this.sectorService.affiliate_code,this.invoice_root_data)
		}
		if (this.sectorService.fareList.length == 0) {
			this.sectorService.fareList.push('')
		}
		// let fareObj = this.requestBuilder.createFareObj(this.sectorService.travellerForm.value)
		let res = await this.runRequest(url ,fareObj,i)
		
	}

	runRequest(url,obj,i){
		return new Promise((resolve, reject) => {
			this.invoiceService.postMethod(url, obj).subscribe(res => {
				this.sectorService.totalFareModel[i] = new TotalFareModel(res);

				this.sectorService.calculationSubject.next(this.sectorService.totalFareModel);
				resolve(res)
			},
				err => {
					resolve(err)
				})
			})
	}

	async saveTravellers() {
		//  

		// for(let i=0; i<this.sectorService.travellerForm.controls.travellerList.length;i++) {
		// 	if(this.sectorService.travellerForm.controls.travellerList.controls[i].controls['txtClientSC1Type'].value == 'N') {
		// 		let serviceChargeValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls['txtClientSC1Value'].value);
		// 		let previousOt = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtOT.value)
		// 		let finalOt = previousOt + serviceChargeValue;
		// 		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtOT.setValue(finalOt);
		// 	}
		// 	if(this.sectorService.travellerForm.controls.travellerList.controls[i].controls['txtClientSC2Type'].value == 'N') {
		// 		let serviceChargeValue = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls['txtClientSC2Value'].value);
		// 		let previousOt = Number(this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtOT.value)
		// 		let finalOt = previousOt + serviceChargeValue;
		// 		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtOT.setValue(finalOt);
		// 	}
		// }
		let requestObj = {
			travellers : this.sectorService.travellerForm.value,
			sectors : this.sectorService.sectorForm.value,
			invoiceForm : this.sectorService.invoiceForm.value
		}
		this.callSaveTraveller.emit(requestObj)
		// let travellersObj = await  this.requestBuilder.createTravellers(this.sectorService.travellerForm.value)
		// var url = window.location.href.slice(window.location.href.indexOf('?') + 1);
		// if (url.split('=')[1] != undefined) {
		// 	let invNo = url.split('=')[1]
		// 	let requestObj = {
		// 		"json_doc" : travellersObj,
		// 		"uuid" : invNo
		// 	}
		// 	this.invoiceService.postMethod('invoice/traveller/save',requestObj).subscribe(res=>{
		// 		console.log('Travellers save response - SUCCESS : ',res)
		// 	},err=>{
		// 		console.log('Travellers save response - ERROR : ',err)
		// 	})
		// }
	}



	returnToInvoice(){
		this.minimizeSection()
		this.calculateFareFromApi(0)
		this.saveTravellers()
		this.callSaveProduct.emit()
		
	}

	setTabIndex(event){
		let travellers = this.sectorService.travellerForm.value.travellerList
		console.log("travellers",travellers)
		if (travellers.length == 1) {
			this.commonService.selectedInput.next(Number(event.target.tabIndex)+2)
		}
	}

	openReplicateModal(e: Event,index) {
		e.stopPropagation();
		if (this.openModal["_results"][0]) {
			this.openModal["_results"][0].nativeElement.click()
			if (this.sectorService.travellerForm.value.travellerList[index]) {
				if (this.sectorService.travellerForm.value.travellerList[index].selType == 'ADT') {
					this.selectedTravellerType = 'Adult'
				}else if (this.sectorService.travellerForm.value.travellerList[index].selType == 'CHD') {
					this.selectedTravellerType = 'Child'
				}else if (this.sectorService.travellerForm.value.travellerList[index].selType == 'INF') {
					this.selectedTravellerType = 'Infants'
				}
			}
			this.commonService.selectedInput.next(this.sectorService.lastIndexOfTraveller+3)
		}
	}

	setBookingSupplier(event,index){
		let invoiceType = this.sectorService.invoiceForm.controls.invoiceType.value
		if (invoiceType == 'C') {
			this.sectorService.travellerForm.controls.travellerList.controls[index].controls.txtbookingSupplier.setValidators(Validators.required)
		}
		// else{
		// 	this.sectorService.travellerForm.controls.travellerList.controls[index].controls.txtbookingSupplier.setValidators(null)
		// 	this.sectorService.travellerForm.controls.travellerList.controls[index].controls.txtbookingSupplier.setValue(null)
		// }
		this.calculateFareFromApi(0)
		// this.sectorService.travellerForm.controls.travellerList.controls[index].controls.ledger_card_no.setValue('')
		let traveller = this.sectorService.travellerForm.controls.travellerList.controls[index]
		if(event.target.value == 'OUR_CC'){
			this.getGeneralAccounts()
			traveller.controls['ledger_card_no'].setValidators(Validators.required)
			traveller.controls['ledger_card_name'].setValidators(Validators.required)
		} else {
			traveller.controls.ledger_card_name.setValue('')
			traveller.controls.ledger_card_no.setValue('')
			traveller.controls['ledger_card_no'].setValidators(null);
			traveller.controls['ledger_card_name'].setValidators(null);
			traveller.controls['ledger_card_no'].setErrors(null);
			traveller.controls['ledger_card_name'].setErrors(null);
		}
		this.sectorService.travellerForm.updateValueAndValidity();
	}

	checkTicketNumber(ticketNumber){

		this.commonService.getRequestNoloader(`${environment.okountUrl}/invoice/check-ticket-number?ticket_no=${ticketNumber}&invoice_no=${this.invoice_no}&reference_type=invoice`).subscribe((res : any)=>{
			if (res.length > 0) {
				let messageObj = {
					isInvalid : true,
					showMessage : true,
					alertType : 'warning-box',
					message : `Ticket Number ${ticketNumber} already exists in invoice ${res[0].invoice_no} .`
				}
				this.callShowMessage.emit(messageObj)
			}else{
				let messageObj = {
					isInvalid : false,
					showMessage : false,
					alertType : 'warning-box',
					message : ``
				}
				this.callShowMessage.emit(messageObj)
			}
			
			
		},err=>{
			console.log(err)
		})
	}


	
	onChangeSearch(value) {
	
		
	}

	async selectBookingSupplier(nzEvent, currentIndex ){
		let event = nzEvent && nzEvent.nzValue;
		if(event) {
			let travellersControl = this.sectorService.travellerForm.controls.travellerList.controls
			for (const traveller of travellersControl) {
				let index = this.sectorService.travellerForm.controls.travellerList.controls.indexOf(traveller)
				if(index >= currentIndex){
				traveller.controls['txtbookingSupplier'].setValue(event.name)
				traveller.controls['txtbookingSupplierCode'].setValue(event.account_code)
				let reqObj = {
					clientCode : this.sectorService.affiliate_code,
					airlineCode : event.account_code,
					date : this.invoice_root_data.invdate ? this.invoice_root_data.invdate : null,
				}
				let resp = await this.sectorService.getDiscRecallSetting(reqObj,index)
				this.calculateFareFromApi(0)
				}
			}
		}else{
			const traveller = this.sectorService.travellerForm.controls.travellerList.controls[currentIndex];
			traveller.controls['txtbookingSupplier'].setValue('')
			traveller.controls['txtbookingSupplierName'].setValue('')
			let reqObj = {
				clientCode : this.sectorService.affiliate_code,
				airlineCode : this.sectorService.invoiceForm.value.airline,
				date : this.invoice_root_data.invdate ? this.invoice_root_data.invdate : null,
			}
			let resp = await this.sectorService.getDiscRecallSetting(reqObj,currentIndex)
			setTimeout(() => {
				this.calculateFareFromApi(0)
			}, 100);
		}
	}

	async selectEvent(data) {
	}

	onFocused(event) {
		this.bookingSupplierArr = []
	}

	selectGeneralAccount(event, currentIndex){
		if(event && event.nzValue) {
			let value = event.nzValue;
			if(value.name) {
				this.sectorService.travellerForm.controls.travellerList.controls[currentIndex].controls['ledger_card_name'].setValue(value.name)
			}
			if(value.account_code) {
				this.sectorService.travellerForm.controls.travellerList.controls[currentIndex].controls['ledger_card_no'].setValue(value.account_code)
			}
		}
	}

	async filterGeneralAccounts(event, currentIndex) {
		if(!this.generalAccountArr || this.generalAccountArr == undefined || this.generalAccountArr == null) {
			await this.getGeneralAccounts()
		}
		let value = event.target.value;
		if(value) {
			let accounts = this.generalAccountArr.filter(account => (account.name.includes(value) || account.account_code.includes(value)))
			this.generalFilteredData = accounts;
		} else {
			this.generalFilteredData = this.generalAccountArr;
			let traveller = this.sectorService.travellerForm.controls.travellerList.controls[currentIndex]
			if(traveller) {
				traveller.controls['ledger_card_name'].setValue('')
				traveller.controls['ledger_card_no'].setValue('')
				traveller.controls['ledger_card_no'].setValidators(null)
				traveller.controls['ledger_card_name'].setValidators(null)
				this.sectorService.travellerForm.updateValueAndValidity()

			}
		}
	}

	onGeneralFocused() {
		this.generalAccountArr = []
	}
	getAccountingCompany() {
		try {
			this.invoiceService.getMethod('util/accounting-company').subscribe(async (res) => {
				if(res) {
					if(res['currency_code']) {
						this.currencyCode = res['currency_code'];
					}
					if(res['native_language_code'] && res['currency_code'] == 'AED') {
						this.nativeLanguageCode = res['native_language_code'];
					}
				}
			},
			err => {
				this.spinner.hide()
			})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
		}
	}

	calculateYQ(i){
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let txtYQ = Number(travellerData['localYQ']) / Number(this.invoice_root_data.currency_rate)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYQ.setValue(txtYQ.toFixed(2))

		
		let baseFare = Number(travellerData['txtBaseFare'])
		let YR = Number(travellerData['txtYR'])
		let K3 = Number(travellerData['txtK3'])
		let OC = Number(travellerData['txtOC'])
		let OT = Number(travellerData['txtOT'])
		let addons = this.getAddonsTotal(travellerData['sectorList'])
		let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(localAddons)
		let total = baseFare + txtYQ + YR + K3 + OC + OT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(addons)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total.toFixed(2))
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total.toFixed(2))
		this.calculateCommission(i)
		this.calculateFareFromApi(0)

	}
	calculateBase(i){
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let txtBaseFare = Number(travellerData['localBaseFare']) / Number(this.invoice_root_data.currency_rate)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtBaseFare.setValue(txtBaseFare.toFixed(2))


		let YQ = Number(travellerData['txtYQ'])
		let YR = Number(travellerData['txtYR'])
		let K3 = Number(travellerData['txtK3'])
		let OC = Number(travellerData['txtOC'])
		let OT = Number(travellerData['txtOT'])
		let addons = this.getAddonsTotal(travellerData['sectorList'])
		let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(localAddons)
		let total = txtBaseFare + YQ + YR + K3 + OC + OT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(addons)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total.toFixed(2))
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total.toFixed(2))
		this.calculateCommission(i)
		this.calculateFareFromApi(0)

	}
	calculateYR(i){
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let txtYR = Number(travellerData['localYR']) / Number(this.invoice_root_data.currency_rate)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtYR.setValue(txtYR.toFixed(2))

		let baseFare = Number(travellerData['txtBaseFare'])
		let YQ = Number(travellerData['txtYQ'])
		let K3 = Number(travellerData['txtK3'])
		let OC = Number(travellerData['txtOC'])
		let OT = Number(travellerData['txtOT'])
		let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(localAddons)
		let addons = this.getAddonsTotal(travellerData['sectorList'])
		let total = baseFare + YQ + txtYR + K3 + OC + OT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(addons)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total.toFixed(2))
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total.toFixed(2))
		this.calculateCommission(i)
		this.calculateFareFromApi(0)

	}
	calculateK3(i){
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let txtK3 = Number(travellerData['localK3']) / Number(this.invoice_root_data.currency_rate)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtK3.setValue(txtK3.toFixed(2))

		let baseFare = Number(travellerData['txtBaseFare'])
		let YQ = Number(travellerData['txtYQ'])
		let YR = Number(travellerData['txtYR'])
		let OC = Number(travellerData['txtOC'])
		let OT = Number(travellerData['txtOT'])
		let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(localAddons)
		let addons = this.getAddonsTotal(travellerData['sectorList'])
		let total = baseFare + YQ + YR + txtK3 + OC + OT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(addons)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total.toFixed(2))
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total.toFixed(2))
		this.calculateCommission(i)
		this.calculateFareFromApi(0)

	}
	calculateOC(i){
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let txtOC = Number(travellerData['localOC']) / Number(this.invoice_root_data.currency_rate)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtOC.setValue(txtOC.toFixed(2))

		let baseFare = Number(travellerData['txtBaseFare'])
		let YQ = Number(travellerData['txtYQ'])
		let YR = Number(travellerData['txtYR'])
		let K3 = Number(travellerData['txtK3'])
		let OT = Number(travellerData['txtOT'])
		let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(localAddons)
		let addons = this.getAddonsTotal(travellerData['sectorList'])
		let total = baseFare + YQ + YR + K3 + txtOC + OT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(addons)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total.toFixed(2))
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total.toFixed(2))
		this.calculateCommission(i)
		this.calculateFareFromApi(0)

	}
	calculateOT(i){
		let travellerData = Object.assign({}, this.sectorService.travellerForm.value.travellerList[i])
		let txtOT = Number(travellerData['localOT']) / Number(this.invoice_root_data.currency_rate)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtOT.setValue(txtOT.toFixed(2))

		let baseFare = Number(travellerData['txtBaseFare'])
		let YQ = Number(travellerData['txtYQ'])
		let YR = Number(travellerData['txtYR'])
		let K3 = Number(travellerData['txtK3'])
		let OC = Number(travellerData['txtOC'])
		let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(localAddons)
		let addons = this.getAddonsTotal(travellerData['sectorList'])
		let total = baseFare + YQ + YR + K3 + OC + txtOT + addons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(addons)
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(total.toFixed(2))
		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.sectorService.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(local_total.toFixed(2))
		this.calculateCommission(i)
		this.calculateFareFromApi(0)

	}

	getLocalAddonsTotal(sectors) {
		let totalAmount = 0
		for (const key in sectors) {
			if (sectors.hasOwnProperty(key)) {
				const element = sectors[key];
				if (element['txtSeatCharges'] != "") {
					totalAmount += Number(element['txtSeatCharges']) 
				}
				if (element['txtMealCharges'] != "") {
					totalAmount += Number(element['txtMealCharges']) 
				}
				if (element['txtBagCharges'] != "") {
					totalAmount += Number(element['txtBagCharges']) 
				}
				if (element['txtAdditionalBaggageCharges'] != "") {
					totalAmount += Number(element['txtAdditionalBaggageCharges']) 
				}
				if (element['txtAncillaryCharges'] != "") {
					totalAmount += Number(element['txtAncillaryCharges']) 
				}
			}
		}
		return totalAmount
	}

	async selectEmployee(nzEvent, currentIndex) {
		let nzValue = nzEvent && nzEvent.nzValue;
		if (nzValue) {
			let data = nzValue;
			this.currentSelectedAffiliate = data
			let travellersControl = this.sectorService.travellerForm.controls.travellerList.controls
			for (const traveller of travellersControl) {
				let index = this.sectorService.travellerForm.controls.travellerList.controls.indexOf(traveller)
				console.log("index ", index,"currentIndex ",	currentIndex )
				if (index == currentIndex) {
					console.log("this.currentSelectedAffiliate",this.currentSelectedAffiliate)
					traveller.controls['txtPassengerName'].setValue(this.currentSelectedAffiliate.account_name?this.currentSelectedAffiliate.account_name:'')
					traveller.controls['txtEmployeeName'].setValue(this.currentSelectedAffiliate.account_name?this.currentSelectedAffiliate.account_name:'')
					traveller.controls['txtEmployeeCode'].setValue(this.currentSelectedAffiliate.account_code?this.currentSelectedAffiliate.account_code:'')
					traveller.controls['txtEmployeeCostCenter'].setValue(this.currentSelectedAffiliate.cost_center?this.currentSelectedAffiliate.cost_center:'')

					// let reqObj = {
					// 	clientCode: this.sectorService.affiliate_code,
					// 	airlineCode: event.account_code,
					// 	date: this.invoice_root_data.invdate ? this.invoice_root_data.invdate : null,
					// }
					// let resp = await this.sectorService.getDiscRecallSetting(reqObj, index)
					 this.calculateFareFromApi(0)
				}
			}
		} else {
			const traveller = this.sectorService.travellerForm.controls.travellerList.controls[currentIndex];
			traveller.controls['txtPassengerName'].setValue('')
			traveller.controls['txtEmployeeName'].setValue('')
			traveller.controls['txtEmployeeCode'].setValue('')
			traveller.controls['txtEmployeeCostCenter'].setValue('')
			// let reqObj = {
			// 	clientCode: this.sectorService.affiliate_code,
			// 	airlineCode: this.sectorService.invoiceForm.value.airline,
			// 	date: this.invoice_root_data.invdate ? this.invoice_root_data.invdate : null,
			// }
			// let resp = await this.sectorService.getDiscRecallSetting(reqObj, currentIndex)
			setTimeout(() => {
				this.calculateFareFromApi(0)
			}, 100);
			
		}
	}
}