import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AirlineComponent } from './airline.component';
import { CreateAirlineComponent } from './create-airline/create-airline.component';
import { ViewAirlineComponent } from './view-airline/view-airline.component';
import { UpdateAirlineComponent } from './update-airline/update-airline.component';

const routes: Routes = [
  {
		path: '', component: AirlineComponent,
		children: [
			{path: 'create-airline', component: CreateAirlineComponent},
			{path: 'view-airline', component: ViewAirlineComponent},
			{path: 'update-airline/:id', component: UpdateAirlineComponent},
      
      		{
				path: '',
				redirectTo: 'create-airline',
				pathMatch: 'full'
			}
		]
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AirlineRoutingModule { }
