import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as moment from  'moment-timezone';
import { GlobalData } from './services/globla-data';
import { CommonServiceService } from './common-service.service';
import { environment } from 'src/environments/environment';
import { CookiesService } from './cookies.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
	isFind: boolean;

	constructor(private router: Router,
		public commonService: CommonServiceService,
		private cookieService : CookiesService
		) { }

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		try {
			if(this.cookieService.getCookie('SYSTACC_ACCESS_TOKEN')){
				localStorage.setItem('okount_token',this.cookieService.getCookie('SYSTACC_ACCESS_TOKEN'))
			}
			if(this.cookieService.getCookie('SYSTACC_FINANCIAL_YEAR')){
				localStorage.setItem('current_financial_year',this.cookieService.getCookie('SYSTACC_FINANCIAL_YEAR'))
			}
			if (localStorage.getItem('okount_token') != undefined && localStorage.getItem('okount_token') != null) {
				var current_time = new Date().getTime() / 1000;
				let jwt = this.commonService.parseJwt(localStorage.getItem('okount_token'))
				let StateUrl = state.url;
				if (current_time > jwt.exp) {
					localStorage.clear()
					this.commonService.showLogout(false)
					// this.router.navigate(['/login']);
					this.cookieService.deleteCookie('SYSTACC_ACCESS_TOKEN')
					this.cookieService.deleteCookie('SYSTACC_FINANCIAL_YEAR')
					this.cookieService.deleteCookie('SYSTACC_USER_LOGIN_DATA')
					window.location.href = environment.accountManagement+"/signin"
					return true;

				}else{
					if(state.url.includes('?')) {
						StateUrl = state.url.split('?')[0]
					}
					try {
						if(Object.keys(route.params).length != 0 && route.params.constructor === Object) {
							StateUrl = StateUrl.replace('/'+Object.values(route.params)[0],'');
						}
					} catch (error) {
						
					}
					let isAllow = await this.setAccess(StateUrl)
					if(!isAllow) {
						alert('you can not access ' + StateUrl)
					}
					return isAllow;
				}
				// if (current_time > jwt.exp) {
				// 	return true;
					// console.log('Auth Success')
				// }
				// else {
					// console.log('Move to login page')
				// 	localStorage.removeItem("token")
				// 	this.router.navigate(['/login']);
				// 	return true;
				// }
			}
			else {
				// console.log('Move to login page due to no token found')
				localStorage.removeItem("okount_token")
				window.location.href = environment.accountManagement+"/signin"
				// this.router.navigate(['/login']);
				return false;
			}
		}
		catch (Exception) {
			// console.log('Move to login page due to : ',Exception)
			localStorage.removeItem("okount_token")
			// this.router.navigate(['/login']);
			this.cookieService.deleteCookie('SYSTACC_ACCESS_TOKEN')
			this.cookieService.deleteCookie('SYSTACC_FINANCIAL_YEAR')
			this.cookieService.deleteCookie('SYSTACC_USER_LOGIN_DATA')
			window.location.href = environment.accountManagement+"/signin"
			return false;
		}

	}

	async setAccess(val) {
		this.isFind = false
		let accessData = localStorage.getItem('accessData') ? JSON.parse(localStorage.getItem('accessData')) : null
      	accessData = accessData ? accessData : GlobalData.getAccessData();
		if(accessData == undefined) {
			if (localStorage.getItem('okountUserInfo') != null && localStorage.getItem('okountUserInfo') != undefined) {
				let userInfo = JSON.parse(localStorage.getItem('okountUserInfo'))
				accessData = await this.commonService.getFeatureData(userInfo.roles[0].roleid)
			}
		}
		return  await this.checkAccess(accessData? accessData.features : undefined,val)
	}

	

	checkAccess(json, key) {
		let isFind = false
		let isKeyFound = false;
		let isAllowed;
		for (const index in json) {
			if (json.hasOwnProperty(index)) {
				const item = json[index];
				if(item.hasOwnProperty('url') && item['url'] == key && !isKeyFound) {  
					isFind = item.access;	
					// console.log(key + ' - ' + item.access)	
					isKeyFound = true;				
					break;
				} 
				else if(item.sub_features.length > 0 && !isKeyFound) {
					let obj = this.childAccess(item.sub_features, key);
					isKeyFound = obj['isKeyFound'];
					if(isKeyFound == true) {
						isFind = obj['isFind'];
					}	
					else if(obj.subData.length > 0) {
						for(let k= 0; k<obj.subData.length;k++) {
							if(isKeyFound == false) {	
								let subFeature = [];				
								let newObj = this.childAccess(obj.subData[k], key);
								isKeyFound = newObj['isKeyFound'];
								if(isKeyFound == true) {
									isFind = newObj['isFind'];
								}
							}
						}
					}
				}
			}
		}
		// console.log( 'return : ' + key + ' - ' + isFind)
		if(isKeyFound == false) {
			return true;
		}
		else{
			return isFind;
		}
	}
	
	
	childAccess(json, key) {
		let obj = {
			subData : [],
			isFind : false,
			isKeyFound : false,
			key : key
	
		}
		for (const index in json) {
			if (json.hasOwnProperty(index)) {
				const item = json[index];
				if(item.hasOwnProperty('url') && item['url'] == key) {  
					// console.log(key + ' - ' + item.access)	
					obj['isFind'] = item.access;	
					obj['isKeyFound'] = true;			
					break;
				} 
				else if(item.sub_features.length > 0 ) {
					obj['subData'].push(item.sub_features);
				}
			}
		}
		return obj;
	}

}
