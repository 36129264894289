
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
  <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
    <div class="d-flex flex-y-center">
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
          alertType=='success-box' ? 'green-sucess.svg' : 
          alertType=='warning-box' ? 'red-warning.svg' : 
          null }}"
        width="20px"
        height="20px"
        alt="alert-logo"
        class="mr-20"
      />
      <div class="ml-10">
        <h4 class="m-0 text-size-18 text-bold"
        [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
          {{alertType === "error-box"
          ? "Error"
          : alertType === "warning-box"
          ? "Warning"
          : alertType === "success-box"
          ? "Success"
          : null}}
        </h4>
        <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
         {{message}}
        </small>
      </div>
    </div>
    <img
      src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
      alt="close-btn"
      (click)="hideError()"
      className=" close-btn"
    />
  </div>
</div>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<button type="button" hidden #openSuccessModal data-toggle="modal" data-target="#successModal"></button>
			<!-- Modal -->
    <div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
        <div class="modal-dialog transaction-creation-modal">
            <!-- Modal content-->
            <div class="modal-content"  *ngIf="transaction_number">
                <div class="modal-header">
                    <span class="messageFont">
                        <span>
                            {{message}} 
                        </span>
                            <a *ngIf="transaction_number != ''" 
                                href="javascript:void(0)"	
                                (click)="viewTransaction()">
                                {{transaction_number}}
                            </a>
                            <a *ngIf="transaction_number != ''" [title]="copyELementTitle" (click)="copytext(transaction_number)">
                              <i  class="fa fa-copy cursor-pointer"></i>
                            </a>
                        </span>
                        <button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
                    </div>
                <div class="modal-body">
                    <p>Do you want to create new {{formData.value.transaction_type}} or  view newly created {{formData.value.transaction_type}} ?</p>
                </div>
                <div class="modal-footer justify-content-center">
                    <button 
                        tabIndex="1" 
                        tabindex="1"
                        type="button" 
                        class="btn btn-primary bg-blue" 
                        (click)="viewTransaction()">
                        View
                    </button>
                    <button 
                        tabIndex="3" 
                        tabindex="3"
                        type="button" 
                        class="btn btn-primary bg-green"  
                        (click)="closeSuccesModal()">
                        Create New
                    </button>
                </div>
            </div>
        </div>
    </div>

<div class="ledger-container " [ngClass]="{'d-none': !newAdmAcm}">
  <div class="row tab-nav pl-0 pr-0">
    <!-- <div class="col-sm-4 pl-0">
        <ul class="list-inline list-unstyled p-0 m-0">
            <li class="active page-title text-uppercase cursor"> View Client</li>
        </ul>
    </div> -->
    <div class="col-sm-12 ">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
          <!-- <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Invoice</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li> -->
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> ACM ADM </li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> View ACM ADM</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li class="text-grey text-capitalize cursor text-primary transition"> Edit ACM ADM</li>
      </ul>
  </div>
</div>
  <form [formGroup]="formData">
    <div class=" mt-4 pl-15 pr-15 custom-form form-container w-100 mb-4" >
    <div class="page-sub-header d-flex w-100 mb-1 align-item-center justify-content-between mt-1">
      <div class="form-inline" *ngIf="transactionMode == 'create'">
        <label class="radio-inline">
          <input type="radio" class="mr-1" formControlName="transaction_type" value="ADM" name="transaction_type">ADM
        </label>
        <label class="radio-inline ml-3">
          <input type="radio" class="mr-1" formControlName="transaction_type" value="ACM" name="transaction_type">ACM
        </label>
      </div>
      <div class="form-inline" *ngIf="transactionMode != 'create'">
        <label class="radio-inline" *ngIf="formData.value.transaction_type == 'ACM'">
          <input type="radio" class="mr-1" formControlName="transaction_type" value="ACM" name="transaction_type">
          ACM
        </label>
        <label class="radio-inline" *ngIf="formData.value.transaction_type == 'ADM'">
          <input type="radio" class="mr-1" formControlName="transaction_type" value="ADM" name="transaction_type">
          ADM
        </label>
      </div>
   <div class="d-flex justify-space-end align-item-center">
    <div class="mr-3" >
      <div class="form-group text-input-field " *ngIf="transactionMode == 'view' && transactionNumber !=''">
        <label style="width: max-content;line-height: 10px;" for="">Code</label>
        <input type="text" name="" style="width: 150px;" formControlName="adm_acm_code"
          class="form-control form-control-sm input-field ng-pristine ng-valid ng-touched" readonly>
      </div>
    </div>
    <div class=" pr-0 ">
      <div class="page-search-box input-group  d-flex align-item-center "
        style="height: fit-content; text-align: left;">

        <div class="form-group text-input-field"
          [ngClass]="{'w-50': transactionMode == 'view' && transactionNumber !=''}">
          <label style="width: max-content;line-height: 10px;" for="">Doc Date:
            <span class="text-danger">*</span>
            <ng-container>
              <i class="fa fa-info-circle ml-2" title="Invalid Date" aria-hidden="true"></i>
            </ng-container>
          </label>
          <input type="date" name="" style="width: 100px;" tabIndex="1" formControlName="transaction_date"
            class="form-control form-control-sm input-field ng-pristine ng-valid ng-touched"
            min="{{minDate}}" 
            max="{{maxDate}}"
            [ngClass]="{'validateError': (formData.controls.transaction_date.invalid && formData.controls.transaction_date.dirty) || (formData.controls.transaction_date.invalid && submitted)}">
        </div>
      </div>
    </div>
   </div>
    </div>
    <div class="container-fluid p-0">
      <div class="row mt-2 border py-4  ">
        <div class="col-md-6">
          <div class="entry-card">
            <div class="inner-entry-card">
              <div class="form-inline row py-1">
                <div class="col-sm-5 form-group pr-0">
                  <label for="" >Account # 1<span class="text-danger">*</span></label>
                  <select class="form-control-sm ml-2 form-control acc_type" tabIndex="2" name=""
                    formControlName="account_1_type">
                    <option value="airline">Airline</option>
                    <option value="supplier">Supplier</option>
                  </select>
                </div>
                <div class="col-sm-7 form-group ">
                  <div class="input-fiel form-group ng-field" tabIndex="3" (focus)="focusElement(account_one)">

                    <div
                    >
                    <ng-autocomplete #account_one [data]="accountOneList" formControlName="account_1_code_value" class="autocomplete-container"
                        [ngClass]="{'validate-ng-error': (formData.controls.account_1_code_value.invalid && formData.controls.account_1_code_value.touched) || (!formData.controls.account_1_code_value.valid && submitted)}"
                        [searchKeyword]="keyword" (selected)="selectAccount($event,'account_1_code')"
                        (inputChanged)='onChangeSearch($event,"account_1_type",true)'
                        (inputCleared)='clearValue("account_1_code")' [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                        >
                      </ng-autocomplete>


                      <ng-template #itemTemplate let-item>
                        <a innerHTML='<span class="colr-1">{{item.name}}
                                                    ({{item.account_code}})</span><br />'>
                        </a>
                      </ng-template>

                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-inline row py-1">
                <div class="col-sm-5 form-group pr-0">
                  <label for="">Sub Ledger</label>
                  <select class="form-control-sm ml-2 form-control acc_type" tabIndex="4" name=""
                    formControlName="account_1_subledger_type">
                    <option value="">Select</option>
                    <option value="airline">Airlines</option>
                  </select>
                </div>
                <!-- <div class="col-sm-7 form-group p-0">                            
                          <select class="form-control-sm ml-2 form-control acc_value w-100" name=""  >
                              <option value="" selected >VISTARA - (Haryana) - UK228 </option>
                          </select>
                      </div> -->
                <div class="col-sm-7 form-group ">
                  <div class="input-fiel form-group ng-field" tabIndex="5"
                    (focus)="focusElement(account_one_sub_ledger)">

                    <div>
                      <ng-autocomplete #account_one_sub_ledger [data]="accountOneList"
                        formControlName="account_1_subledger_code_value" [searchKeyword]="keyword"
                        (selected)="selectAccount($event,'account_1_subledger_code')"
                        (inputChanged)='onChangeSearch($event,"account_1_subledger_type",true)'
                        (inputCleared)='clearValue("account_1_subledger_code")' [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate">
                      </ng-autocomplete>


                      <ng-template #itemTemplate let-item>
                        <a innerHTML='<span class="colr-1">{{item.name}}
                                                    ({{item.account_code}})</span><br />'>
                        </a>
                      </ng-template>

                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="inner-entry-card">

              <div class="form-inline row py-1">
                <div class="col-sm-5 form-group pr-0">
                  <label for="">Account # 2<span class="text-danger">*</span></label>
                  <select class="form-control-sm ml-2 form-control acc_type" tabIndex="6" name=""
                    formControlName="account_2_type">
                    <option value="general">General</option>
                    <option value="airline">Airline</option>
                  </select>
                </div>
                <div class="col-sm-7 form-group ">
                  <div class="input-fiel form-group ng-field" tabIndex="7" (focus)="focusElement(account_two)">

                    <div>
                    <ng-autocomplete #account_two [data]="accountOneList" formControlName="account_2_code_value" class="autocomplete-container"
                        [ngClass]="{'validate-ng-error': (formData.controls.account_2_code_value.invalid && formData.controls.account_2_code_value.touched) || (!formData.controls.account_2_code_value.valid && submitted)}"
                        [searchKeyword]="keyword" (selected)="selectAccount($event,'account_2_code')"
                        (inputChanged)='onChangeSearch($event,"account_2_type",true)'
                        (inputCleared)='clearValue("account_2_code")' [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                        >
                      </ng-autocomplete>


                      <ng-template #itemTemplate let-item>
                        <a innerHTML='<span class="colr-1">{{item.name}}
                                                    ({{item.account_code}})</span><br />'>
                        </a>
                      </ng-template>

                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-inline row py-1">
                <div class="col-sm-5 form-group pr-0">
                  <label for="">Sub Ledger</label>
                  <select class="form-control-sm ml-2 form-control acc_type" tabIndex="8" name=""
                    formControlName="account_2_subledger_type">
                    <option value="">Select</option>
                    <option value="client">Client</option>
                    <option value="airline">Airline</option>
                    <option value="supplier">Supplier</option>
                    <option value="employee">Employee</option>
                    <option value="general">General</option>
                  </select>
                </div>
                <div class="col-sm-7 form-group ">
                  <div class="input-fiel form-group ng-field" tabIndex="9"
                    (focus)="focusElement(account_two_sub_ledger)">

                    <div>
                      <ng-autocomplete #account_two_sub_ledger [data]="accountOneList"
                        formControlName="account_2_subledger_code_value" [searchKeyword]="keyword"
                        (selected)="selectAccount($event,'account_2_subledger_code')"
                        (inputChanged)='onChangeSearch($event,"account_2_subledger_type",true)'
                        (inputCleared)='clearValue("account_2_subledger_code")' [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate">
                      </ng-autocomplete>


                      <ng-template #itemTemplate let-item>
                        <a innerHTML='<span class="colr-1">{{item.name}}
                                                    ({{item.account_code}})</span><br />'>
                        </a>
                      </ng-template>

                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="inner-entry-card">
              <div class="form-inline row py-1">
                <div class="col-sm-5 form-group pr-0">
                  <label for="">Client</label>
                  <select class="form-control-sm ml-2 form-control acc_type" tabIndex="10" name=""
                    formControlName="client_type">
                    <option value="">Select</option>
                    <option value="client">Client</option>
                  </select>
                </div>
                <div class="col-sm-7 form-group ">
                  <div class="input-fiel form-group ng-field" tabIndex="11" (focus)="focusElement(client)">

                    <div>
                      <ng-autocomplete #client [data]="accountOneList" formControlName="client_code_value"
                        [searchKeyword]="keyword" (selected)="selectAccount($event,'client_code')"
                        (inputChanged)='onChangeSearch($event,"client_type",true)' (inputCleared)='clearValue("client_code")'
                        [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                      </ng-autocomplete>


                      <ng-template #itemTemplate let-item>
                        <a innerHTML='<span class="colr-1">{{item.name}}
                                                    ({{item.account_code}})</span><br />'>
                        </a>
                      </ng-template>

                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-inline row py-1">
                <div class="col-sm-5 form-group pr-0">
                  <label for="">Sub Ledger</label>
                  <select class="form-control-sm ml-2 form-control acc_type" tabIndex="12" name=""
                    formControlName="client_subledger_type">
                    <option value="">Select</option>
                    <option value="client">Client</option>
                    <option value="airline">Airline</option>
                    <option value="supplier">Supplier</option>
                    <option value="employee">Employee</option>
                  </select>
                </div>
                <div class="col-sm-7 form-group ">
                  <div class="input-fiel form-group ng-field" tabIndex="13" (focus)="focusElement(client_sub_ledger)">

                    <div>
                      <ng-autocomplete #client_sub_ledger [data]="accountOneList"
                        formControlName="client_subledger_code_value" [searchKeyword]="keyword"
                        (selected)="selectAccount($event,'client_subledger_code')"
                        (inputChanged)='onChangeSearch($event,"client_subledger_type",true)'
                        (inputCleared)='clearValue("client_subledger_code")' [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate">
                      </ng-autocomplete>
                      <ng-template #itemTemplate let-item>
                        <a innerHTML='<span class="colr-1">{{item.name}}
                                                    ({{item.account_code}})</span><br />'>
                        </a>
                      </ng-template>

                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="entry-card pt-1">
            <div class="inner-entry-card">
              <div>
                <div class="form-group text-input-field">
                  <label  for="" class="label-size">{{formData.value.transaction_type}} No:<span class="text-danger">*</span></label>
                  <input type="text" name="" formControlName="adm_acm_number" tabIndex="14"
                    class="form-control form-control-sm " value=""
                    [ngClass]="{'validateError': (formData.controls.adm_acm_number.invalid && formData.controls.adm_acm_number.touched) || (!formData.controls.adm_acm_number.valid && submitted)}">
                </div>
                <div class="form-group text-input-field">
                  <label for="" class="label-size">Ref No:</label>
                  <input type="text" name="" formControlName="reference_number" tabIndex="15"
                    class="form-control form-control-sm ">
                </div>
                <div class="form-group text-input-field">
                  <label for="" class="label-size">INT - DOM:<span class="text-danger">*</span></label>
                  <!-- <input type="text" name="" formControlName="type"  class="form-control form-control-sm " > -->
                  <select class="form-control-sm form-control" name="" tabIndex="16" formControlName="type">
                    <option value="INT">INT.</option>
                    <option value="DOM">DOM.</option>
                  </select>
                </div>


                <div class="form-group text-input-field">
                  <label for="" class="label-size">Ticket No:</label>
                  <input type="text" name="" maxlength="10" formControlName="ticket_no" tabIndex="17"
                    class="form-control form-control-sm" value="7645887444">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="entry-card pt-1">
            <div class="inner-entry-card">
              <div class="form-group text-input-field">
                <label for="" class="label-size">Narration:</label>
                <input type="text" name="" formControlName="narration_1" tabIndex="18"
                  class="form-control form-control-sm margin-b-5" value="7645887444">
                <input type="text" name="" formControlName="narration_2" tabIndex="19"
                  class="form-control form-control-sm margin-b-5">
                <input type="text" name="" formControlName="narration_3" tabIndex="20"
                  class="form-control form-control-sm margin-b-5">
                <input type="text" name="" formControlName="narration_4" tabIndex="21"
                  class="form-control form-control-sm margin-b-5 ">
                <input type="text" name="" formControlName="narration_5" tabIndex="22"
                  class="form-control form-control-sm margin-b-5">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2 supplier-client-amt text-center">
        <div class="form-group text-input-field mr-3 w-50">
          <label style="line-height: 10px;" for="">Supplier Amount:<span class="text-danger">*</span></label>
          <input type="text" name="" formControlName="supplier_amount" tabIndex="23" class="form-control"
          [ngClass]="{'validateError': (formData.controls.supplier_amount.invalid && formData.controls.supplier_amount.touched) || (!formData.controls.supplier_amount.valid && submitted)}">
        </div>
        <div class="form-group text-input-field w-50" *ngIf="formData.value.client_type != ''">
          <label style="line-height: 10px;" for="">Client Amount:</label>
          <input type="text" name="" formControlName="client_amount" class="form-control">
        </div>
      </div>
    </div>
  </div>
  <div class="btn-field bottom-btns-container full-width-btn-container d-flex align-item-center justify-content-end ml-0 mr-0">
    <div>
      <!-- <button type="button" (click)="closeMaxModule()" class="btn-style bg-green mr-3">
        Reset
      </button>  -->
      <button class=" btn-style bg-purple" (click)="openSaveConfirmModal()" tabIndex="24"
      *ngIf="transactionMode == 'create'" id="save_acm_adm_btn">Save</button>
      <ng-container *ngIf="transactionMode == 'view'">
        <button class="mr-3  btn-style bg-cyan" (click)="openUpdateConfirmModal()" tabIndex="25"
           id="modify_acm_adm_btn">Modify</button>
        
        <button class="mr-3 btn-style bg-red" (click)="openDeleteConfirmModal()" tabIndex="26"
            *hasAccess="{'key': hasAccessDeleteCode}"
            id="delete_acm_adm_btn">Delete</button>
            <button class="mr-3 btn-style bg-brown" (click)="viewJournal()" tabIndex="27"
              id="journal_acm_adm_btn">Journal
            </button>
            <button class="mr-3  btn-style bg-green" (click)="openPrintPage()" tabIndex="28"
            id="print_acm_adm_btn">
            Print
          </button>
              <button class="btn-style bg-indigo mr-3" (click)="openActivity()" tabIndex="29"
              >Activity
            </button>
            <button type="button" (click)="openSendEmailModal()" tabIndex="30" class="btn-style bg-blue-ivy" id="jv_email_btn">
              Email
            </button>
      </ng-container>
    </div>
 
  </div>
  </form>
</div>


<button type="button" hidden #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button>

<div class="modal fade" id="deleteConfirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" (click)="resetCurrentOpendModal()" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" #closeDeleteModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to delete this entry ?
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
                <button type="button" class="btn-style mr-2" (click)="closeDeleteConfirmModal()" id="no_delete_adm_acm_confirm_modal_btn">No</button>
                <button type="button" class="btn-style" (click)="deleteTransaction()" id="yes_delete_adm_acm_confirm_modal_btn">Yes</button>
            </div>
        </div>
    </div>
</div>
<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateConfirmationModal"></button>

<div class="modal fade" id="updateConfirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close" (click)="resetCurrentOpendModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>" update ‎this entry " </b> ?
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
                <button type="button" class="btn-style mr-2" (click)="closeUpdateConfirmModal()" id="no_update_adm_acm_confirm_modal_btn">No</button>
                <button type="button" class="btn-style bg-purple"
                    (click)="updateTransaction(formData.value)" id="yes_update_adm_acm_confirm_modal_btn">Yes</button>
            </div>
        </div>
    </div>
</div>

<button type="button" hidden #confirmSaveModal data-toggle="modal" data-target="#saveConfirmationModal"></button>

<div class="modal fade" id="saveConfirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" #closeSaveModal data-dismiss="modal" aria-label="Close" (click)="resetCurrentOpendModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>" save ‎this entry " </b> ?
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
                <button type="button" class="btn-style mr-2" (click)="closeSaveConfirmModal()" id="no_save_adm_acm_confirm_modal_btn">No</button>
                <button type="button" class="btn-style bg-purple"
                    (click)="submit(formData.value)" id="yes_save_adm_acm_confirm_modal_btn">Yes</button>
            </div>
        </div>
    </div>
</div>

<button
	hidden
	#openEmailModal
	data-target="#emailModalOfAdmAcm"
	data-toggle="modal"
  ></button>
	<div
	  class="modal"
	  id="emailModalOfAdmAcm"
	  role="dialog"
	  aria-labelledby="emailModalLabel"
	  data-backdrop="false"
	>
	  <div class="modal-dialog" role="document">
		<div class="modal-content">
		  <div class="modal-header">
			<div class="d-flex justify-content-center">
			  <h4 class="modal-title">Send Email</h4>
			</div>
			<button
			  type=" button"
			  class="close"
			  #closeSendEmailModal
			  data-dismiss="modal"
			  aria-label="Close"
			>
			  <span aria-hidden="true">&times;</span>
			</button>
		  </div>
		  <div class="modal-body emailSenders">
			{{this.formData.value.transaction_type.toUpperCase()}}
			<form [formGroup]="sendEmailForm" style="margin-top: -10px">
			  <div class="form-group text-input-field">
				<textarea
				  class="input-field ng-pristine ng-valid ng-touched text-size-12"
				  id="toEmail"
				  rows="1"
				  formControlName="toEmails"
				  required
				></textarea>
				<label for="To" class="mb-0">To</label>
			  </div>
			  <div class="d-flex justify-content-end">
				<div *ngIf="sendEmailForm.controls.toEmails.invalid">
				  <div
					*ngIf="sendEmailForm.controls.toEmails.errors?.required"
					style="color: red"
				  >
					To Email is required.
				  </div>
				</div>
			  </div>
  
			  <div class="form-group text-input-field ">
				<textarea
				  class="input-field ng-pristine ng-valid ng-touched text-size-12"
				  id="ccEmail"
				  style="max-height: 45px;"
				  formControlName="ccEmails"
				></textarea>
				<label for="Cc" class="mb-0">Cc</label>
			  </div>
  
			  <div class="form-group text-input-field">
				<textarea
				  class="input-field ng-pristine ng-valid ng-touched text-size-12"
				  id="bccEmail"
				  style="max-height: 45px;"
				  formControlName="bccEmails"
				></textarea>
				<label for="Bcc" class="mb-0">Bcc</label>
			  </div>
			  <div class="text-input-field">
				  <input formControlName="subject"  class="input-field text-size-12" type="text" 
				  autocomplete="off" />
				  <label>Subject</label>
			  </div>
			  <div class="form-group text-input-field">
			   <textarea
				 class="input-field ng-pristine ng-valid ng-touched text-size-12"
				 rows="5"
				 formControlName="body"
			   ></textarea>
			   <label for="body" class="mb-0">Mail Body</label>
			 </div>
			  <div class="mt-3 d-flex justify-content-center align-item-center">
				<button type="button" class="btn-style mr-2" data-dismiss="modal">
				  Cancel
				</button>
				<button
				  type="button"
				  class="btn-style bg-green"
				  (click)="callTosendEmail()"
				>
				  Send
				</button>
			  </div>
			</form>
		  </div>
		</div>
	  </div>
	</div>