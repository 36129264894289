<div class="ledger-container pb-20 ">
    <div class="row tab-nav pl-0 pr-0  align-item-center">
        <div class="col-sm-4 ">
          <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
              <li 
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> Master</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li 
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> Supplier</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li class="text-grey text-capitalize cursor text-primary transition"> View Supplier</li>
          </ul>
      </div>
        <div class="col-sm-4 text-center">
            <span class="rec-date  d-block text-center">
                <div class="noWrap justify-content-around">
                    <ng-container >
                        <div class="cname text-bold"><span class="text-bold">Displaying all records</span>
                           
                            <button title="Custom Search" class="btn   save-as text-bold bg-whiteSmoke " 
                                type="button" (click)="openAdvanceSearchModal()" >
                                <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
                            </button>
                        </div>
                    </ng-container>								
                </div>
            </span>
        </div>
        <div class="col-sm-4  d-flex align-item-center justify-content-end">
          <div class="page-search-box input-group  d-flex align-item-center"
          style="height: fit-content; text-align: left;">
          <input type="text" class="form-control" [(ngModel)]="onSearch" oninput="this.value = this.value.toUpperCase()"
            (ngModelChange)="this.onSearchFilter.next($event)" placeholder="search" style=" text-align: left" />
        </div>
        <div class="dropdown-btn position-relative" >
            <button title="More..." class="btn-style mt-0 more-btn">
              <span class="dot"></span><span class="dot"></span
              ><span class="dot"></span>
            </button>
            <ul class="list-unstyled pl-0 dropdown-box">
              <li (click)="
              exportSupplierAsXLSX(
              )">
                <i   aria-hidden="true" class="fa fa-file-excel-o fa-excel"></i>
                <span class="ml-2">Excel File</span>
            <button
                hidden
                #openEmailModal
                data-target="#emailModal"
                data-toggle="modal"
            ></button>
              </li>
        
            </ul>
          </div>

        </div>


    </div>

    <div class="table-container" (window:resize)="onResize($event)">
        <div class="table" style="    margin-top: -5px;">
            <nz-table #supplierData [nzData]="viewSupplier"
                [nzLoading]="loader"
                [nzFrontPagination]="false">
                
                <thead>
                    <tr #tableHeader tabIndex="0">
                        <th style="max-width: 300px;" class="text-left">Account Name</th>
                        <th style="max-width: 120px;" class="text-left">Account Code</th>
                        <th style="max-width: 100px;" class="text-left">Mobile No</th>
                        <th style="max-width: 270px;" class="text-left">Email</th>
                        <th class="text-left">Address</th>
                        <th style="max-width: 100px;" class="text-right">Action</th>
                    </tr>
                </thead>

                <ng-container *ngIf="supplierData && supplierData.data && supplierData.data.length>0 else noResult">
                    <tbody [ngStyle]="{height: innerHeight}" class="main-panel" infiniteScroll
                        (window:keydown)="onKeyDown($event)" [fromRoot]="fromRoot" [infiniteScrollDistance]="1"
                        [infiniteScrollUpDistance]="1" [infiniteScrollThrottle]="200"
                        [infiniteScrollContainer]="selector" (scrolled)="onScrollDown($event)"
                        (scrolledUp)="onScrollUp($event)">
                        <ng-container *ngFor="let item of supplierData['data']  let i = index">
                            <tr class="field-table-input custom-table-row" [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}"
                                (keyup.enter)="open_supplier(item.id, item.account_code, item.name)">
                                <td style="    max-width: 300px;" class="text-left text-grey refer font-500" (click)="moveToIndex(i)">
                                    {{item?.name}}</td>
                                <td style="    max-width: 120px; text-decoration: none;"
                                    class="text-left text-grey " (click)="moveToIndex(i)">{{item?.account_code}}
                                </td>
                                <td style="    max-width: 100px;" class="text-left text-grey" (click)="moveToIndex(i)">
                                    {{item?.mobile_no}}</td>
                                <td style="    max-width: 270px;" class="text-left text-grey word-break"
                                    (click)="moveToIndex(i)">
                                    {{item?.primary_email}}</td>
                                <td class="text-left text-grey " (click)="moveToIndex(i)">{{item?.address_1}}</td>
                                <td style="    max-width: 50px;" class="text-right edit-row">
                                    <button title="Edit" class=" custom-table-btn bg-white border-0 p-0 text-bold  hoverableShadow transition cursor" type="button"
                                    (click)="open_supplier(item.id, item.account_code, item.name)">
                                    <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-edit-btn.svg" alt="" width="20px">
                                </button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </ng-container>
               
                <ng-template #noResult>
                    <div *ngIf="!loader" class="w-100 text-center" style="padding: 20px;">
                        <nz-empty nzNotFoundContent="No result Found"></nz-empty>
                    </div>
                </ng-template>
                
            </nz-table>
        </div>
    </div>

</div>