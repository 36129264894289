<div class="ledger-container  outstanding-tbl" #tableHeader tabIndex="0">
	<div >
		<div class="voucher-container">
			<div class="logo-wrapper" *ngIf="isPrint"><div id="waterMark" [ngStyle]="{background: waterMark}"></div></div>
			<div class="row tab-nav pl-0 pr-0">
				<div class="col-sm-4 ">
					<ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0" >
						<li [routerLink]="['/']"
							class="text-grey text-capitalize cursor  hover-text-primary transition" [ngClass]="{'d-none': isPrint == 'true'}"> Analysis</li>
						<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt="" [ngClass]="{'d-none': isPrint == 'true'}"></li>
						<li class="text-grey text-capitalize cursor text-primary transition" [ngClass]="{'d-none': isPrint == 'false'}" [ngClass]="{'d-none': isPrint == 'true'}"> Outstanding Report </li>
					</ul>
			  </div>
				<div class="col-sm-4 text-center">
					<span class="rec-date d-block text-center">
						<div class="noWrap justify-content-around">
							<ng-container >
								<div class="cname text-bold"><span *ngIf="duration && duration.from_date" class="text-bold">From</span>
									<h5 style="margin-right: 5px;" class="mb-0  d-inline">
										<span class="date text-bold text-orange" *ngIf="duration && duration.from_date"
											>
											{{duration && duration.from_date}}
										</span>
										<span class="date text-bold" *ngIf="duration && duration.from_date">
											To
										</span>
										<span class="date text-bold" >
											Till Date
										</span>
										<span class="date text-bold text-orange">
											{{duration && duration.to_date}}
										</span>
									</h5>
									<button  [ngClass]="{'d-none': isPrint == 'true'}" title="Custom Search" class="btn   save-as text-bold bg-whiteSmoke " 
										type="button" (click)="openAdvanceSearchModal()" >
										<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
									</button>
								</div>
							</ng-container>								
						</div>
					</span>
				</div>
				<div [ngClass]="{'d-none': isPrint == 'true'}" class="col-sm-4   d-flex align-item-center justify-content-end">
					<div
					class="page-search-box input-group d-flex align-item-center"
					style="height: fit-content; text-align: left"
				  >
					<input
					  type="text"
					  class="form-control"
					  [(ngModel)]="onSearch"
					  oninput="this.value = this.value.toUpperCase()"
					  (ngModelChange)="this.onSearchFilter.next($event)"
					  placeholder="search"
					  style="text-align: left"
					/>
				  </div>
					<div class="dropdown-btn position-relative">
						<button title="More..." class="btn-style mt-0 more-btn">
						  <span class="dot"></span><span class="dot"></span
						  ><span class="dot"></span>
						</button>
						<ul class="list-unstyled pl-0 dropdown-box">
						  <li   (click)="downloadPdf(outStandingData?.duration?.from_date  ,outStandingData?.duration?.to_date)
						" >
							<img
							  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg"
							  alt=""
							/>

							<span class="ml-2">Save As Pdf</span>
						  </li>
						  <li   (click)="exportAsXLSX(outStandingData?.duration?.from_date  ,outStandingData?.duration?.to_date)" >	
							<span class="ml-2">Excel</span>
						  </li>
						</ul>
					  </div>
				</div>
			</div>

			<!-- row -->
			<div class="table-container">
			<div class="table" style="margin-top: -5px;">
				<nz-table class="table  striped"  #clientOutstandingTable [nzData]="outStandingData && outStandingData['data']"style="table-layout: auto!important;"
				 [nzLoading]="loader"
				 [nzFrontPagination]="false" 
				 [nzShowPagination]="false"
			    >
					<thead>
					  <tr>
						<th class="text-left " style="max-width: 130px;font-weight: 700;">Account Code</th>
						<th class="text-left:" style="font-weight: 700;">Account</th>
						<th class="text-right" style="max-width: 250px;font-weight: 700;">Opening Balance</th>
						<th class="text-right" style="max-width: 120px;font-weight: 700;">Debit</th>
						<th class="text-right" style="max-width: 120px;font-weight: 700;">Credit</th>
						<th class="text-right" style="max-width: 120px;padding-right: 17px!important;font-weight: 700;">Balance</th>
					  </tr>
					</thead>
					<ng-container *ngIf="clientOutstandingTable && clientOutstandingTable.data && clientOutstandingTable.data.length> 0 else noResult">
					    <tbody>
							<tr *ngFor="let data of clientOutstandingTable.data" (keyup.enter)="openLedger(data['account_code'])">
								<td class="text-left text-grey" style="max-width: 130px;">{{data['account_code']}}</td>
								<td class="text-left  text-grey refer" (click)="openLedger(data['account_code'])">{{data['display_name']}}</td>
								<td class=" text-right text-blue"style=" max-width: 250px;white-space: nowrap;">{{data?.opening_balance | number : '1.2-2'}}</td>
								<td class=" text-right text-red"style=" max-width: 120px;white-space: nowrap;">{{data?.total_debit | number : '1.2-2'}}</td>
								<td class=" text-right text-green"style="max-width: 120px;white-space: nowrap;">{{data?.total_credit | number : '1.2-2'}}</td>
								<td class=" text-right text-blue" style="max-width: 120px; white-space: nowrap;font-weight: 700;padding-right: 17px!important;">
										{{data?.outstanding | number : '1.2-2'}}
								</td>
							</tr>
					   </tbody>
				    </ng-container>
					
					<ng-template #noResult>
						<div *ngIf="!loader" class="w-100 text-center" style="padding: 20px;">
							<nz-empty nzNotFoundContent="No result Found"></nz-empty>
						</div>
					</ng-template>
				</nz-table>
			</div>
</div>
		</div>
	</div>

</div>