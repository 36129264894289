import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GlobalVariable } from '../../global-variable';

@Component({
  selector: 'app-role-search',
  templateUrl: './role-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class RoleSearchComponent implements OnInit {
  roleFilterForm: FormGroup;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;

  constructor(private router: Router, private formBuilder: FormBuilder ) { }

  ngOnInit() {
    this.roleFilterForm = this.formBuilder.group({
      role_id: [''],
      name: ['']
    })
  }

  search(values) {
    GlobalVariable.openModalStatus = false;
    this.router.navigateByUrl(`/user-management/role/view-role?name=${values.name}`)
  }
  reset() {
    GlobalVariable.openModalStatus = false;
    this.roleFilterForm = this.formBuilder.group({
      role_id: [''],
      name: ['']
    })
  }
  clickSearchButton(){
		this.searchButton.nativeElement.click()
	}

}
