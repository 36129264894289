<form [formGroup]="sectorService.sectorForm">
	<div class="sector-section">
		<!-- <span class="section-head text-black">Sector Information</span> -->

		<div class="segment-row mb-3">
			<div class="segment-col">
				<input tabIndex="{{isTenantIndian ? 17 : 19}}" oninput="this.value = this.value.toUpperCase()" class="main-input" type="text"
				[ngClass]="{'validateError': (sectorService.sectorForm.controls.txtSector1.invalid && sectorService.sectorForm.controls.txtSector1.touched) || 
					(!sectorService.sectorForm.controls.txtSector1.valid && sectorService.submitted)}"
					placeholder="___/___/___/___/___" formControlName="txtSector1" autocomplete="off"
					(keyup)="sectorService.getSector1($event)" (change)="sectorService.bindSector1($event.target.value,false)"
					maxlength="31" />
				<label>Sector 1<span class="text-danger">*</span></label>
			</div>

			<div class="segment-col ml-3">
				<input tabIndex="{{isTenantIndian ? 18 : 20}}"  oninput="this.value = this.value.toUpperCase()" class="main-input" type="text"
					placeholder="___/___/___/___/___" formControlName="txtSector2" autocomplete="off"
					(keyup)="sectorService.getSector2($event)" (change)="sectorService.bindSector2($event.target.value)"
					maxlength="19" />
				<label>Sector 2</label>
			</div>
		</div>

	</div>

	<div class="sector-generated">
		<table class="table table-hovered table-bordered">
			<thead>
				<tr>
					<th>Sector</th>
					<th>Airline PNR<span class="text-danger">*</span></th>
					<th>AL/PF<span class="text-danger">*</span></th>
					<th>FLT NO.<span class="text-danger">*</span></th>
					<th>CL</th>
					<th *ngIf="dateTimeMultiTab=='false'" >DEP. DATE TIME<span class="text-danger">*</span></th>
					<th *ngIf="dateTimeMultiTab=='true'" >DEP. DATE TIME<span class="text-danger">*</span></th>

					<th *ngIf="dateTimeMultiTab=='false'">ARR. DATE TIME<span class="text-danger">*</span></th>
					<th *ngIf="dateTimeMultiTab=='true'">ARR. DATE TIME<span class="text-danger">*</span></th>

					<th>DEP. Airport</th>
					<th>DEP. T</th>
					<th>Arr. Airport</th>
					<th>Arr. T</th>
					<th>Baggage</th>
					<th>Action</th>
				</tr>
			</thead>

			<tbody>
				<ng-container formArrayName="sectorlist"
					*ngFor="let sectorlist of sectorService.DynamicFormControls.controls; let i = index ">
					<tr [formGroupName]="i">
						<td><input oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" disabled formControlName="txtSectorComb" maxlength="7" />
						</td>
						<td>
							<input tabIndex="{{i*11+1+(isTenantIndian ? 18 : 20)}}" attr.tabIndex="{{i*11+1+(isTenantIndian ? 18 : 20)}}" maxlength="12"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" formControlName="txtAirlinePnrX" (change)="sectorService.setNarration()"
								[ngClass]="{'validateError': (sectorlist.controls.txtAirlinePnrX.invalid && sectorlist.controls.txtAirlinePnrX.touched) || 
					            (!sectorlist.controls.txtAirlinePnrX.valid && sectorService.submitted)}" />
						</td>
						<td>
							<input tabIndex="{{i*11+2+(isTenantIndian ? 18 : 20)}}" attr.tabIndex="{{i*11+2+(isTenantIndian ? 18 : 20)}}" maxlength="5"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" style="width: 35px;" formControlName="txtAirlinePrefix"  (change)="sectorService.setNarration()"
								[ngClass]="{'validateError': (sectorlist.controls.txtAirlinePrefix.invalid && sectorlist.controls.txtAirlinePrefix.touched) || 
					            (!sectorlist.controls.txtAirlinePrefix.valid && sectorService.submitted)}"/>
						</td>
						<td>
							<input tabIndex="{{i*11+3+(isTenantIndian ? 18 : 20)}}" attr.tabIndex="{{i*11+3+(isTenantIndian ? 18 : 20)}}"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" formControlName="txtFlightNo" (change)="sectorService.setNarration()" maxlength="6"
								[ngClass]="{'validateError': (sectorlist.controls.txtFlightNo.invalid && sectorlist.controls.txtFlightNo.touched) || 
					            (!sectorlist.controls.txtFlightNo.valid && sectorService.submitted)}"/>
						</td>
						<td>
							<input tabIndex="{{i*11+4+(isTenantIndian ? 18 : 20)}}" attr.tabIndex="{{i*11+4+(isTenantIndian ? 18 : 20)}}" maxlength="4"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" style="width: 35px;" formControlName="txtClass" (change)="sectorService.setNarration()"/>
						</td>
							<td *ngIf="dateTimeMultiTab=='false'">
								<input tabIndex="{{i*11+5+(isTenantIndian ? 18 : 20)}}" attr.tabIndex="{{i*11+5+(isTenantIndian ? 18 : 20)}}" 
									class="unstyled w-75 traveller-input-field" type="datetime-local"
									formControlName="dateAirlineDepartInfo" (change)="sectorService.setNarration()"
									[ngClass]="{'validateError': (sectorlist.controls.dateAirlineDepartInfo.invalid && sectorlist.controls.dateAirlineDepartInfo.touched) || 
									(!sectorlist.controls.dateAirlineDepartInfo.valid && sectorService.submitted)}"/>
							</td>
							<td *ngIf="dateTimeMultiTab=='true'" >
								<span style="display: flex;flex-flow: row;">
									<input tabIndex="{{i*11+5+(isTenantIndian ? 18 : 20)}}" attr.tabIndex="{{i*11+5+(isTenantIndian ? 18 : 20)}}" 
										class=" traveller-input-field" type="date"
										formControlName="AirlineDepartDate" (change)="sectorService.setNarration()"
										[ngClass]="{'validateError': (sectorlist.controls.AirlineDepartDate.invalid && sectorlist.controls.AirlineDepartDate.touched) || 
										(!sectorlist.controls.AirlineDepartDate.valid && sectorService.submitted)}"/>
									<input class=" traveller-input-field" type="time" formControlName="AirlineDepartTime"/>
								</span>
							</td>
							<td *ngIf="dateTimeMultiTab=='false'">
								<input tabIndex="{{i*11+6+(isTenantIndian ? 18 : 20)}}" attr.tabIndex="{{i*11+6+(isTenantIndian ? 18 : 20)}}"
									class="traveller-input-field" type="datetime-local"
									formControlName="dateAirlineArrivalInfo" (change)="sectorService.setNarration()"
									[ngClass]="{'validateError': (sectorlist.controls.dateAirlineArrivalInfo.invalid && sectorlist.controls.dateAirlineArrivalInfo.touched) || 
									(!sectorlist.controls.dateAirlineArrivalInfo.valid && sectorService.submitted)}"/>
							</td>
							<td *ngIf="dateTimeMultiTab=='true'" >
								<span style="display: flex;flex-flow: row;">

									<input tabIndex="{{i*11+6+(isTenantIndian ? 18 : 20)}}" attr.tabIndex="{{i*11+6+(isTenantIndian ? 18 : 20)}}"
										class="traveller-input-field" type="date"
										formControlName="AirlineArrivalDate" (change)="sectorService.setNarration()"
										[ngClass]="{'validateError': (sectorlist.controls.AirlineArrivalDate.invalid && sectorlist.controls.AirlineArrivalDate.touched) || 
										(!sectorlist.controls.AirlineArrivalDate.valid && sectorService.submitted)}"/>
									<input class=" traveller-input-field" type="time" formControlName="AirlineArrivalTime"/>  
								</span>
							</td>
						<td style="min-width: 150px;">
							<input tabIndex="{{i*11+7+(isTenantIndian ? 18 : 20)}}" attr.tabIndex="{{i*11+7+(isTenantIndian ? 18 : 20)}}"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" formControlName="txtAirlineDepartInfo" (change)="sectorService.setNarration()"/>
						</td>
						<td>
							<input tabIndex="{{i*11+8+(isTenantIndian ? 18 : 20)}}" attr.tabIndex="{{i*11+8+(isTenantIndian ? 18 : 20)}}" style="width: 35px;" oninput="this.value = this.value.toUpperCase()"
								class="traveller-input-field" type="text" formControlName="txtDepartureTerminal" />
						</td>
						<td style="min-width: 150px;">
							<input tabIndex="{{i*11+9+(isTenantIndian ? 18 : 20)}}" attr.tabIndex="{{i*11+9+(isTenantIndian ? 18 : 20)}}"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" formControlName="txtAirlineArrivalInfo" />
						</td>
						<td>
							<input tabIndex="{{i*11+10+(isTenantIndian ? 18 : 20)}}" attr.tabIndex="{{i*11+10+(isTenantIndian ? 18 : 20)}}" style="width: 35px;" oninput="this.value = this.value.toUpperCase()"
								class="traveller-input-field" type="text" formControlName="txtArrivalTerminal" />
						</td>

						<td>

							<input tabIndex="{{i*11+11+(isTenantIndian ? 18 : 20)}}" attr.tabIndex="{{i*11+11+(isTenantIndian ? 18 : 20)}}"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" formControlName="txtBaggage" />
						</td>
						<td class="action-col">
							<a (click)="removeSector(i,'sectorlist')"  title="Delete" class=" custom-table-btn bg-white border-0 p-0 text-bold hoverableShadow transition cursor" href="javascript:void(0)"><img   src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-delete.svg" alt="" width="20px">
							</a>
						</td>
					</tr>
				</ng-container>

				<!-- Sector 2 Form Array -->
				<ng-container formArrayName="sector2list"
					*ngFor="let sectorlist of sectorService.DynamicFormControls2.controls; let j = index ">
					<tr [formGroupName]="j">
						<td><input oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" disabled formControlName="txtSectorComb2" />
						</td>
						<td>
							<input tabIndex="{{j*11+12+(sectorService.lastSectorOneIndex)}}"
								attr.tabIndex="{{j*11+12+(sectorService.lastSectorOneIndex)}}"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" formControlName="txtAirlinePnrY"
								[ngClass]="{'validateError': (sectorlist.controls.txtAirlinePnrY.invalid && sectorlist.controls.txtAirlinePnrY.touched) || 
					            (!sectorlist.controls.txtAirlinePnrY.valid && sectorService.submitted)}" />
						</td>
						<td>
							<input tabIndex="{{j*11+13+(sectorService.lastSectorOneIndex)}}"
								attr.tabIndex="{{j*11+13+(sectorService.lastSectorOneIndex)}}" style="width: 35px;"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" formControlName="txtAirlinePrefix2" 
								[ngClass]="{'validateError': (sectorlist.controls.txtAirlinePrefix2.invalid && sectorlist.controls.txtAirlinePrefix2.touched) || 
					            (!sectorlist.controls.txtAirlinePrefix2.valid && sectorService.submitted)}"/>
						</td>
						<td>
							<input tabIndex="{{j*11+14+(sectorService.lastSectorOneIndex)}}"
								attr.tabIndex="{{j*11+14+(sectorService.lastSectorOneIndex)}}"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" formControlName="txtFlightNo2"
								[ngClass]="{'validateError': (sectorlist.controls.txtFlightNo2.invalid && sectorlist.controls.txtFlightNo2.touched) || 
					            (!sectorlist.controls.txtFlightNo2.valid && sectorService.submitted)}" />
						</td>
						<td>
							<input tabIndex="{{j*11+15+(sectorService.lastSectorOneIndex)}}"
								attr.tabIndex="{{j*11+15+(sectorService.lastSectorOneIndex)}}" style="width: 35px;"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" formControlName="txtClass2" />
						</td>
						<td *ngIf="dateTimeMultiTab=='false'">
							<input tabIndex="{{j*11+16+(sectorService.lastSectorOneIndex)}}"
								attr.tabIndex="{{j*11+16+(sectorService.lastSectorOneIndex)}}"
								class="unstyled w-75 traveller-input-field" type="datetime-local"
								formControlName="dateAirlineDepartInfo2" 
								[ngClass]="{'validateError': (sectorlist.controls.dateAirlineDepartInfo2.invalid && sectorlist.controls.dateAirlineDepartInfo2.touched) || 
					            (!sectorlist.controls.dateAirlineDepartInfo2.valid && sectorService.submitted)}"/>
						</td>
						<td *ngIf="dateTimeMultiTab=='true'" >
								<span style="display: flex;flex-flow: row;">
									<input tabIndex="{{j*11+16+(sectorService.lastSectorOneIndex)}}" attr.tabIndex="{{j*11+16+(sectorService.lastSectorOneIndex)}}"
										class=" traveller-input-field" type="date"
										formControlName="AirlineDepartDate2"
										[ngClass]="{'validateError': (sectorlist.controls.AirlineDepartDate2.invalid && sectorlist.controls.AirlineDepartDate2.touched) || 
										(!sectorlist.controls.AirlineDepartDate2.valid && sectorService.submitted)}"/>
									<input class=" traveller-input-field" type="time" formControlName="AirlineDepartTime2"/>
								</span>
							</td>
						<td *ngIf="dateTimeMultiTab=='false'">
							<input tabIndex="{{j*11+17+(sectorService.lastSectorOneIndex)}}"
								attr.tabIndex="{{j*11+17+(sectorService.lastSectorOneIndex)}}"
								oninput="this.value = this.value.toUpperCase()"
								class="unstyled w-75 traveller-input-field" type="datetime-local"
								formControlName="dateAirlineArrivalInfo2"
								[ngClass]="{'validateError': (sectorlist.controls.dateAirlineArrivalInfo2.invalid && sectorlist.controls.dateAirlineArrivalInfo2.touched) || 
					            (!sectorlist.controls.dateAirlineArrivalInfo2.valid && sectorService.submitted)}" />
						</td>
						<td *ngIf="dateTimeMultiTab=='true'" >
							<span style="display: flex;flex-flow: row;">

								<input tabIndex="{{j*11+17+(sectorService.lastSectorOneIndex)}}" attr.tabIndex="{{j*11+17+(sectorService.lastSectorOneIndex)}}"
									class="traveller-input-field" type="date"
									formControlName="AirlineArrivalDate2" (change)="sectorService.setNarration()"
									[ngClass]="{'validateError': (sectorlist.controls.AirlineArrivalDate2.invalid && sectorlist.controls.AirlineArrivalDate2.touched) || 
									(!sectorlist.controls.AirlineArrivalDate2.valid && sectorService.submitted)}"/>
								<input class=" traveller-input-field" type="time" formControlName="AirlineArrivalTime2"/>  
							</span>
						</td>
						<td>
							<input tabIndex="{{j*11+18+(sectorService.lastSectorOneIndex)}}"	
								attr.tabIndex="{{j*11+18+(sectorService.lastSectorOneIndex)}}"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" formControlName="txtAirlineDepartInfo2" />
						</td>
						<td>
							<input tabIndex="{{j*11+19+(sectorService.lastSectorOneIndex)}}"
								attr.tabIndex="{{j*11+19+(sectorService.lastSectorOneIndex)}}"
								oninput="this.value = this.value.toUpperCase()" style="width: 35px;"
								class="traveller-input-field" type="text" formControlName="txtDepartureTerminal2" />
						</td>
						<td>
							<input tabIndex="{{j*11+20+(sectorService.lastSectorOneIndex)}}"
								attr.tabIndex="{{j*11+20+(sectorService.lastSectorOneIndex)}}"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" formControlName="txtAirlineArrivalInfo2" />
						</td>
						<td>
							<input tabIndex="{{j*11+21+(sectorService.lastSectorOneIndex)}}"
							attr.tabIndex="{{j*11+21+(sectorService.lastSectorOneIndex)}}" oninput="this.value = this.value.toUpperCase()" style="width: 35px;"
								class="traveller-input-field" type="text" formControlName="txtArrivalTerminal2" />
						</td>

						<td>
							<input tabIndex="{{j*11+22+(sectorService.lastSectorOneIndex)}}"
								attr.tabIndex="{{j*11+22+(sectorService.lastSectorOneIndex)}}"
								oninput="this.value = this.value.toUpperCase()" class="traveller-input-field"
								type="text" formControlName="txtBaggage2" />
						</td>
						<td class="action-col">
							<a (click)="removeSector(j,'sector2list')" title="Delete" class=" custom-table-btn bg-white border-0 p-0 text-bold hoverableShadow transition cursor" href="javascript:void(0)"><img (click)="removeSector(j,'sector2list')" src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-delete.svg" alt="" width="20px">
							</a>
							<!-- <a (click)="removeSector(j,'sector2list')" href="javascript:void(0)">
								<button title="Delete" class="btn   save-as text-bold bg-whiteSmoke " type="button"
									(click)="removeSector(j,'sector2list')" href="javascript:void(0)">
									<i  
									class="fa text-red fa-trash" aria-hidden="true"></i>
								</button>
							</a> -->
							<!-- <i title="Delete"
									class="fa fa-trash" aria-hidden="true"></i> -->
						</td>
					</tr>
				</ng-container>
			</tbody>
		</table>
	</div>
</form>