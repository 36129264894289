import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { CommonServiceService } from '../common-service.service';

type IKNOWISNUMBER = any;
type IKNOWISSTRING = any;

@Directive({
  selector: '[searchtabindex]'
})
export class SearchTabDirective {


	private _searchIndex: number;
	get searchIndex(): IKNOWISNUMBER {
		return this._searchIndex;
	}
	@Input('searchtabindex')
	set searchIndex(i: IKNOWISSTRING) {
		this._searchIndex = parseInt(i);
	}

	constructor(
		private el: ElementRef,
		public commonService: CommonServiceService
	) { }

	ngOnInit() {
		this.commonService.selectedSearchInput
			.subscribe((i) => {
				// console.log('Selected search index : ',i)
				// console.log(`Selected search index : ${i} and current element index : ${this.searchIndex}`)
				if (i === this.searchIndex) {
					this.el.nativeElement.focus();
				}
			});
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(e: any) {
		if(e.altKey && e.keyCode == 13){
			e.preventDefault();
			var element = document.getElementById("submitSearchForm")
			if(element && !element['disabled']){
				element.click()
				element.setAttribute('disabled','true')
				setTimeout(() => {
					element.removeAttribute('disabled')
				}, 100,element);
			}
		}else if (e.shiftKey && e.keyCode == 13) {
			e.preventDefault();
			this.commonService.selectedSearchInput.next(this.searchIndex - 1)
		} else if (e.keyCode === 13) {
			e.preventDefault();
			if (this.el.nativeElement.nodeName == 'BUTTON') {
				this.el.nativeElement.click()
			} else {
				this.commonService.selectedSearchInput.next(this.searchIndex + 1)
			}
		}
	}

}

