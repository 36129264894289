import { Injectable, ɵConsole, Input } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { InvoiceServicesService } from '../services/invoice-services.service';
import { TotalFareModel } from '../models/total-fare-model';
import { BehaviorSubject, Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { ActivatedRoute } from '@angular/router';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { SetNarrationsArr } from '../../../../transactions/store/actions';
@Injectable({
	providedIn: 'root'
})
export class SectorService {
	


	// Observable string sources
	private componentMethodCallSource = new Subject<any>();

	// Observable string streams
	componentMethodCalled$ = this.componentMethodCallSource.asObservable();

	public setProductSource = new Subject<any>();
	setProductSourceCalled$ = this.setProductSource.asObservable();

	public setInvoiceSourceData = new BehaviorSubject([]);
	invoiceRootData = this.setInvoiceSourceData.asObservable();

	private narrationSubject = new BehaviorSubject([]);
	narrationData = this.narrationSubject.asObservable()

	private calculationSubject = new BehaviorSubject([]);
	calculationObservable = this.calculationSubject.asObservable()

	sectorlist: FormArray; //FOR SECTOR 1 COMBINATION
	sector2list: FormArray; //FOR SECTOR 2 COMBINATION
	sectorForm: any;

	travellerForm: any;
	travellerList: FormArray;
	sectorList: FormArray;  // FOR EACH TRAVELLER
	sector2List: FormArray;  // FOR EACH TRAVELLER
	invoiceForm: any;
	departurearray: any = [];
	arrivalarray: any = [];

	ledgerList: any = [];

	airlineArr: any = [];
	masterAirlineArr: any = [];
	totalFareModel = []
	accountList: any;
	employeeList: any;
	fareList = []
	generalAccArr : any = [];
	clientConfiguration: any

	invoiceTravellerForm = []
	creditTravellerList = []
	supplierList: any
	cancelledTicket = []
	initialAirline: string = '';
	affiliate_code = ""
	invoiceDate = ""

	nonTaxableServiceCharge = [];
	lastSectorOneIndex = 31;
	lastSectorTwoIndex = 31;
	lastIndexOfTraveller = 72;
	totalIndexInTraveller = 0
	invoiceType: any;
    retrievalConfig: any;
	enablePayable: boolean;
	disRecallRateClient:number = 18
	disRecallRateSupplier:number = 18

	submitted = false

	showCreateButton: boolean = false;
	currentMaster: any;
	currentMasterName ='';
	currentFormControlName;

	clientServiceCharge;
	@Input() invoice_root_data

	constructor(private fb: FormBuilder,
		private invoiceservice: InvoiceServicesService,
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private store: Store<InitialState>
	) {

		this.sectorForm = this.fb.group({
			txtSector1: ['', Validators.required],
			txtSector2: [''],
			sectorlist: this.fb.array([]),
			sector2list: this.fb.array([])
		});

	

		this.travellerForm = this.fb.group({
			travellerList: this.fb.array([]),
		});

		this.invoiceForm = this.fb.group({
			crsId: ['', Validators.required],
			supplier: ['', Validators.required],
			has_insurance: [''],
			insurance_premium: [''],
			reference_ms_invoice: [''],
			invoiceType: ['', Validators.required],
			pnr: ['', Validators.required],
			lastName: [''],
			booking_ref: [''],
			fareBasis: [''],
			dealCode: [''],
			classType: [''],
			airline: ['', Validators.required],
			airline_name: [''],
			payable: [''],
			travellerCount: [''],
			employee: ['',Validators.required],
			employee_name: ['',Validators.required],
			sub_ledger_code_1: [''],
			sub_ledger_code_2: [''],
			sub_ledger_code_3: [''],
			sub_ledger_code_4: [''],
			sub_ledger_code_5: [''],
			sub_ledger_code_1_type: [''],
			sub_ledger_code_2_type: [''],
			sub_ledger_code_3_type: [''],
			sub_ledger_code_4_type: [''],
			sub_ledger_code_5_type: [''],
			sub_ledger_code_1_name: [''],
			sub_ledger_code_2_name: [''],
			sub_ledger_code_3_name: [''],
			sub_ledger_code_4_name: [''],
			sub_ledger_code_5_name: [''],
			gstDetail:this.fb.group({
						"companyName":['',Validators.minLength(3)],
						"email":["",Validators.email],
						"gstNumber":[
							"",
							
							Validators.pattern(
							  "^([0][1-9]|[1-2][0-9]|[3][0-7])([A-Z]{5})([0-9]{4})([A-Z]{1}[1-9A-Z]{1})([Z]{1})([0-9A-Z]{1})+$"
							),
						  ],
						"phone": [
							"",
							
							[
							  Validators.pattern("^[0-9]*$"),
							  Validators.minLength(10),
							  Validators.maxLength(10),
							],
						  ],
				})
		});

		 

		let airlineRes = this.getAirline(true)
		let accountsRes = this.getAccounts('client')
		let employeeRes = this.getEmployee('employee?noRole=true')
		let supplierRes = this.getBookingSupplier('util/getsupplierairline')
		let loggedInUser = JSON.parse(localStorage.okountUserInfo);
		// this.getConfiguration(loggedInUser.accountcode)
		
	}


	getNarration(data : any) {
		this.narrationSubject.next(data)
	}


    setNarration(){
		let sectorsArr = []
		sectorsArr= Object.assign([],this.sectorlist && this.sectorlist.value && this.sectorlist.value.length>0?this.sectorlist.value:[])
		if(((this.sector2list && this.sector2list.value && this.sector2list.value.length>0)?this.sector2list.value:[]).length>0){	
			for(let ind=0;ind<this.sector2list.value.length;ind++){

				let obj = this.sector2list.value[ind]
				let flag = false
				for(let sector of sectorsArr){
					if(sector.hasOwnProperty('txtSectorComb2') && sector.txtSectorComb2==obj.txtSectorComb2){
						flag=true;
						break;
					}
				}
				if(!flag)sectorsArr.push(obj)
			}
		}
		let employeeName = typeof this.invoiceForm.controls.employee_name.value == 'object' ? 
							this.invoiceForm.controls.employee_name.value.account_name : 
							this.invoiceForm.controls.employee_name.value

		let narrationData =	{ 
			"sectors":sectorsArr ,
			"travellers":(this.travellerForm && this.travellerForm.value && this.travellerForm.value.travellerList)?this.travellerForm.value.travellerList:[],
			"employee" : employeeName,
			"pnr" : this.invoiceForm.controls.pnr.value
     	}

		let {sectors,travellers,employee,pnr} = narrationData
		//variables for data handling
		var narrLine1="";
		var narrLine2="";
		var narrLine3="";
		var narrLine4 = `TICKET CREATED OFFLINE : ${employee}`
		if(sectors[0].txtAirlinePnrX){
	
		}
		if (travellers && travellers.length>0) {
			let travCount = travellers.length;
			if (travCount > 1) {
				narrLine1 = travellers[0].txtPassengerName.account_name?travellers[0].txtPassengerName.account_name:travellers[0].txtPassengerName + ' + ' + (travCount - 1);
			} else {
				narrLine1 = travellers[0].txtPassengerName.account_name?travellers[0].txtPassengerName.account_name:travellers[0].txtPassengerName;
			}
		}
	
		if (sectors && sectors.length>0) {
		  let ind=0
		  while(ind<sectors.length){
			let sector = sectors[ind]
			if(sector.txtSectorComb || sector.txtSectorComb2){
			  let sectorArr = sector.txtSectorComb?sector.txtSectorComb.split("/"):sector.txtSectorComb2.split("/")
			  if(narrLine2==""){
				 narrLine2+=sectorArr[0]+"-"+sectorArr[1]
			  }else{
				narrLine2+="-"+sectorArr[1]
			  }
			}
			ind+=1
		  }
	
		  if(narrLine2 && travellers.length>0)narrLine2+=" | TKT NO - "+travellers[0].txtTicketNumber
		}
	   
		let narr3Parts = [] 
		narr3Parts.push(`PNR - ${pnr}`)

		
		if(sectors && sectors.length > 0){
		 
		  if(sectors[0].txtAirlinePrefix && sectors[0].txtAirlinePrefix.length > 0){
			let narr3Part2Sub = `FLT NO - ${sectors[0].txtAirlinePrefix}`
			if(sectors[0].txtFlightNo && sectors[0].txtFlightNo.length>0){
			  narr3Part2Sub +="-"+sectors[0].txtFlightNo
			}
			narr3Parts.push(narr3Part2Sub)
		  }
		  if (sectors[0].dateAirlineDepartInfo && sectors[0].dateAirlineDepartInfo.toUpperCase() !=  'INVALID DATE'  && sectors[0].dateAirlineDepartInfo.length>0) {
			narr3Parts.push(`TRV DT - ${moment(sectors[0].dateAirlineDepartInfo).format("DD-MM-YYYY")}`)
		  } else if (sectors[0].AirlineDepartDate && sectors[0].AirlineDepartDate.length>0) {
			narr3Parts.push(`TRV DT - ${moment(sectors[0].AirlineDepartDate).format("DD-MM-YYYY")}`)
		  }
		}
		narrLine3 = narr3Parts.join(' | ')
	    let narrationArr = [narrLine1,narrLine2,narrLine3,narrLine4]
		this.getNarration(narrationArr);
	}




	getDiscRecallSetting(queryParams,key){
		return new Promise((resolve, reject) => {
			queryParams['date'] = queryParams['date'] ? queryParams['date'] : moment().format('YYYY-MM-DD')
			this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/global_settings/configs`,queryParams).subscribe(res=>{
				// bind disc recall client
				this.travellerForm.controls.travellerList.controls[key].controls.disClientRecallValue.setValue(res['client_disc_recall_rate'])
				this.travellerForm.controls.travellerList.controls[key].controls.disSupplierRecallValue.setValue(res['airline_disc_recall_rate'])
				resolve(res)
			},err=>{
				resolve(null)
			})
		})
	}

	getAccounts(url) {
		return new Promise((resolve, reject) => {
			if(url){
				this.invoiceservice.getMethod(url).subscribe(res => {
					this.accountList = res
					resolve(res)
				}, err => {
					this.accountList = []
					resolve(null)
				})
			}else{
				this.accountList = []
				resolve(null)
			}
	})
	}
	getEmployee(url) {
		return new Promise((resolve, reject) => {
		this.invoiceservice.getMethod(url).subscribe(res => {
			this.employeeList = res
			resolve(res)
		}, err => {
			resolve(null)
		})
	})
	}

	getAirline(setMasterAirline = false) {
		try {
			return new Promise((resolve, reject) => {
			this.invoiceservice.searchAccount(`airline`).subscribe(res => {
				this.airlineArr = res
				if(setMasterAirline) {
					this.masterAirlineArr = res
				}
				resolve(res)
			},err=>{
				resolve(null)
			});
		})
		} catch (error) {
			console.log('Error while getting Airlines : ', error)
		}
	}
	getBookingSupplier(url) {
		return new Promise((resolve, reject) => {
		this.invoiceservice.getMethod(url).subscribe(res => {
			this.supplierList = res
			resolve(res)
		}, err => {
			resolve(null)
		})
	})
	}


	getGeneralAccounts() {
		return new Promise((resolve, reject) => {
			this.invoiceservice.getMethod('general/bank-od-cc').subscribe(res => {
				this.generalAccArr = res
				resolve(res)
			}, err => {
				resolve(null)
			})
		})
	}


	bindTraveller(travellerLength, nestedFormValue) {

		this.travellerList = this.travellerForm.get("travellerList");
		this.travellerList = this.travellerForm.get("travellerList") as FormArray;
		let tempControl = Object.assign({},this.travellerList.controls)
		this.travellerList.controls = [];
		
		for (let i = 0; i < travellerLength; i++) {
			if(tempControl[i]){
				let data = tempControl[i].value;
				let oldSectorList = data.sectorList;
				
				this.travellerList.push(this.buildTraveller(data))
				this.sectorList = this.travellerForm.get("travellerList").controls[i].get('sectorList');
				let totalIndexCount = 43
				this.sectorList.controls = [];
				for(let item of oldSectorList){
					this.sectorList.push(this.buildTravellerSector(item.sector))
					totalIndexCount = totalIndexCount + 10
				}
				this.totalIndexInTraveller = this.totalIndexInTraveller + totalIndexCount;
				this.travellerForm.get("travellerList").controls[i].controls.totalIndexCount.setValue(totalIndexCount)
				let travellerStatus = this.isCancelledFn(this.travellerForm.get("travellerList").controls[i])
				this.travellerForm.get("travellerList").controls[i].controls.travellerStatus.setValue(travellerStatus)
				const travellerControl = this.travellerList.controls[i];
				let sectorList  = travellerControl.get('sectorList');
				
				for(const sectorKey in sectorList['controls']){
				    if (sectorList['controls'].hasOwnProperty(sectorKey)) {
						const sectorSsrData = oldSectorList[sectorKey];
							if (sectorSsrData) {
								const sectorControl = sectorList['controls'][sectorKey];
								sectorControl.controls.txtSeatCharges.setValue(sectorSsrData.txtSeatCharges ? sectorSsrData.txtSeatCharges : '')
								sectorControl.controls.txtSeatCode.setValue(sectorSsrData.txtSeatCode ? sectorSsrData.txtSeatCode : '')

								sectorControl.controls.txtMealCharges.setValue(sectorSsrData.txtMealCharges ? sectorSsrData.txtMealCharges : '')
								sectorControl.controls.txtMealCode.setValue(sectorSsrData.txtMealCode ? sectorSsrData.txtMealCode : '')

								sectorControl.controls.txtBagCharges.setValue(sectorSsrData.txtBagCharges ? sectorSsrData.txtBagCharges : '')
								sectorControl.controls.txtBagCode.setValue(sectorSsrData.txtBagCode ? sectorSsrData.txtBagCode : '')

								sectorControl.controls.txtAdditionalBaggageCharges.setValue(sectorSsrData.txtAdditionalBaggageCharges ? sectorSsrData.txtAdditionalBaggageCharges : '')
								sectorControl.controls.txtAdditionalBaggageCode.setValue(sectorSsrData.txtAdditionalBaggageCode ? sectorSsrData.txtAdditionalBaggageCode : '')

								sectorControl.controls.txtAncillaryCharges.setValue(sectorSsrData.txtAncillaryCharges ? sectorSsrData.txtAncillaryCharges : '')
								sectorControl.controls.txtAncillaryCode.setValue(sectorSsrData.txtAncillaryCode ? sectorSsrData.txtAncillaryCode : '')
							}
						}
					}
				    this.calculateTotal(i);
			  } else {
				
					this.travellerList.push(this.buildTraveller(null));
					this.sectorList = this.travellerForm.get("travellerList").controls[i].get('sectorList');
					
					let firstTravellerSectorList ;
					if(this.travellerList && this.travellerList.value && this.travellerList.value.length>0 && this.travellerList.value[0].sectorList) {
						firstTravellerSectorList = this.travellerList.value[0].sectorList;
					}
					let totalIndexCount = 43
					for(let item of firstTravellerSectorList){
						this.sectorList.push(this.buildTravellerSector(item.sector))
						totalIndexCount = totalIndexCount + 10
					}

					this.totalIndexInTraveller = this.totalIndexInTraveller + totalIndexCount;
					this.travellerForm.get("travellerList").controls[i].controls.totalIndexCount.setValue(totalIndexCount)
					let travellerStatus = this.isCancelledFn(this.travellerForm.get("travellerList").controls[i])
					this.travellerForm.get("travellerList").controls[i].controls.travellerStatus.setValue(travellerStatus)
					this.calculateTotal(i);
			  }


			if (this.invoiceType == 'reissue') {
				this.travellerForm.controls.travellerList.controls[i].controls.prev_ticket_no.setValidators([Validators.required])
			}else{
				this.travellerForm.controls.travellerList.controls[i].controls.prev_ticket_no.setValidators(null)
			}
			this.travellerForm.controls.travellerList.controls[i].controls.prev_ticket_no.updateValueAndValidity()
			
		}

		this.calculateServiceChargeFlightWise(nestedFormValue);
		
		if(this.sectorlist && this.sectorlist.controls) {
			this.lastSectorOneIndex = ((this.sectorlist.controls.length-1) * 11) + 11 + 21
			this.sector2list = this.sectorForm.get("sector2list") as FormArray;
		}
		if(this.sector2list && this.sector2list.controls) {
			this.lastSectorTwoIndex = ((this.sector2list.controls.length-1) * 11) + 11 + this.lastSectorOneIndex
		}
		
		this.lastIndexOfTraveller = this.totalIndexInTraveller + this.lastSectorTwoIndex

		return ""
	}

	addTraveller() {
		this.travellerList.push(this.buildTraveller(null));
	}




	async bindRetrievedData(data) {
		this.travellerList = this.travellerForm.get("travellerList");
		this.travellerList = this.travellerForm.get("travellerList") as FormArray;
		this.travellerList.controls = [];
		
		this.invoiceForm.controls.travellerCount.setValue(data.traveller.length)
		let trvLen = data.traveller.length / 2;
		let ticketArr = [];
		let trvCount = 0;
		this.sectorForm.controls.txtSector1.setValue(data.sectorComb1);
		if (data.sectorComb2 != null && data.sectorComb2 != '') {
			this.sectorForm.controls.txtSector2.setValue(data.sectorComb2);
		}
		for (const key in data.traveller) {
			if (data.traveller.hasOwnProperty(key)) {
				this.travellerList.push(this.buildTraveller(data.traveller[key]));

				//SET VALUE

				this.travellerForm.controls.travellerList.controls[key].controls.selTitle.setValue(data.traveller[key].title)
				this.travellerForm.controls.travellerList.controls[key].controls.selType.setValue(data.traveller[key].type)
				this.travellerForm.controls.travellerList.controls[key].controls.txtPassengerName.setValue(data.traveller[key].name)
				this.travellerForm.controls.travellerList.controls[key].controls.txtEmployeeName.setValue(data.traveller[key].employee?data.traveller[key].employee:'')
				this.travellerForm.controls.travellerList.controls[key].controls.txtEmployeeCode.setValue(data.traveller[key].e_code?data.traveller[key].e_code:'')
				this.travellerForm.controls.travellerList.controls[key].controls.txtEmployeeCostCenter.setValue(data.traveller[key].cost_center?data.traveller[key].cost_center:'')
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierCode.setValue(data.traveller[key].payable_transaction_code?data.traveller[key].payable_transaction_code:'')
				this.travellerForm.controls.travellerList.controls[key].controls.supplierDate.setValue(data.traveller[key].payable_date?data.traveller[key].payable_date:'')
				if (this.invoiceForm.value.invoiceType == "C") {
					this.enablePayable = true
					this.travellerForm.controls.travellerList.controls[key].controls.txtbookingSupplier.setValidators(Validators.required)
				}else{
					this.enablePayable = false
					this.travellerForm.controls.travellerList.controls[key].controls.txtbookingSupplier.setValidators(null)
				}
				if (data.traveller[key].type == 'OUR_CC') {
					this.travellerForm.controls.travellerList.controls[key].controls.ledger_card_no.setValidators(Validators.required)
					this.travellerForm.controls.travellerList.controls[key].controls.ledger_card_name.setValidators(Validators.required)
				}else{
					this.travellerForm.controls.travellerList.controls[key].controls.ledger_card_no.setValidators(null)
					this.travellerForm.controls.travellerList.controls[key].controls.ledger_card_name.setValidators(null)
				}
				if (this.invoiceForm.value.invoiceType == "B") {
					this.travellerForm.controls.travellerList.controls[key].controls['txtbookingSupplier'].setValue('BSP')
					this.travellerForm.controls.travellerList.controls[key].controls['txtbookingSupplierCode'].setValue('799')
				}
				this.travellerForm.updateValueAndValidity()
				
				
				let isTenantIndian = this.commonService.checkTenantIndian();
				
				if(isTenantIndian) { 
                    //SET FARE VALUE
					this.travellerForm.controls.travellerList.controls[key].controls.txtBaseFare.setValue(data.traveller[key].baseFare)
					this.travellerForm.controls.travellerList.controls[key].controls.txtYQ.setValue(data.traveller[key].yq)
					this.travellerForm.controls.travellerList.controls[key].controls.txtYR.setValue(data.traveller[key].yr)
					this.travellerForm.controls.travellerList.controls[key].controls.txtK3.setValue(data.traveller[key].k3)
					this.travellerForm.controls.travellerList.controls[key].controls.txtOC.setValue(data.traveller[key].oc)
					this.travellerForm.controls.travellerList.controls[key].controls.txtOT.setValue(data.traveller[key].ot)

					this.travellerForm.controls.travellerList.controls[key].controls.selSupplierIataType.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.supplier.iataType?data.traveller[key].commission.supplier.iataType:'RB')
					this.travellerForm.controls.travellerList.controls[key].controls.selSupplierIataValue.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.supplier.iataValue?data.traveller[key].commission.supplier.iataValue:0)
					this.travellerForm.controls.travellerList.controls[key].controls.selSupplierIataAmount.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.supplier.iataAmount?data.traveller[key].commission.supplier.iataAmount:0)
					this.travellerForm.controls.travellerList.controls[key].controls.selSupplierPlbType.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.supplier.plbType?data.traveller[key].commission.supplier.plbType:"RB")
					this.travellerForm.controls.travellerList.controls[key].controls.selSupplierPlbValue.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.supplier.plbValue?data.traveller[key].commission.supplier.plbValue:0)
					this.travellerForm.controls.travellerList.controls[key].controls.selSupplierPlbAmount.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.supplier.plbAmount?data.traveller[key].commission.supplier.plbAmount:0)
					this.travellerForm.controls.travellerList.controls[key].controls.selSupplierCbType.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.supplier.cashbackType?data.traveller[key].commission.supplier.cashbackType:"VL")
					this.travellerForm.controls.travellerList.controls[key].controls.selSupplierCbValue.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.supplier.cashbackValue?data.traveller[key].commission.supplier.cashbackValue:0)
					this.travellerForm.controls.travellerList.controls[key].controls.selSupplierCbAmount.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.supplier.cashbackAmount?data.traveller[key].commission.supplier.cashbackAmount:0)

					this.travellerForm.controls.travellerList.controls[key].controls.selClientIataType.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.customer.iataType ? data.traveller[key].commission.customer.iataType:"RB")
					this.travellerForm.controls.travellerList.controls[key].controls.selClientIataValue.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.customer.iataValue ? data.traveller[key].commission.customer.iataValue:0)
					this.travellerForm.controls.travellerList.controls[key].controls.selClientIataAmount.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.customer.iataAmount?data.traveller[key].commission.customer.iataAmount:0)
					this.travellerForm.controls.travellerList.controls[key].controls.selClientPlbType.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.customer.plbType?data.traveller[key].commission.customer.plbType:"RB")
					this.travellerForm.controls.travellerList.controls[key].controls.selClientPlbValue.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.customer.plbValue?data.traveller[key].commission.customer.plbValue:0)
					this.travellerForm.controls.travellerList.controls[key].controls.selClientPlbAmount.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.customer.plbAmount?data.traveller[key].commission.customer.plbAmount:0)
					this.travellerForm.controls.travellerList.controls[key].controls.selClientCbType.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.customer.cashbackType?data.traveller[key].commission.customer.cashbackType:'VL')
					this.travellerForm.controls.travellerList.controls[key].controls.selClientCbValue.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.customer.cashbackValue?data.traveller[key].commission.customer.cashbackValue:0)
					this.travellerForm.controls.travellerList.controls[key].controls.selClientCbAmount.setValue(data.traveller[key] && data.traveller[key].commission && data.traveller[key].commission.customer && data.traveller[key].commission.customer.cashbackAmount?data.traveller[key].commission.customer.cashbackAmount:0)

				} else {
					let currency_rate = 1 ;
					this.invoiceRootData.subscribe((items)=> {  
						currency_rate = items['currency_rate'] ? items['currency_rate'] : 1
					})
				
					this.travellerForm.controls.travellerList.controls[key].controls.localBaseFare.setValue(data.traveller[key].baseFare)
					this.travellerForm.controls.travellerList.controls[key].controls.localYQ.setValue(data.traveller[key].yq)
					this.travellerForm.controls.travellerList.controls[key].controls.localYR.setValue(data.traveller[key].yr)
					this.travellerForm.controls.travellerList.controls[key].controls.localK3.setValue(data.traveller[key].k3)
					this.travellerForm.controls.travellerList.controls[key].controls.localOC.setValue(data.traveller[key].oc)
					this.travellerForm.controls.travellerList.controls[key].controls.localOT.setValue(data.traveller[key].ot)

					this.travellerForm.controls.travellerList.controls[key].controls.txtBaseFare.setValue((data.traveller[key].baseFare / currency_rate).toFixed(2))
					this.travellerForm.controls.travellerList.controls[key].controls.txtYQ.setValue((data.traveller[key].yq / currency_rate).toFixed(2))
					this.travellerForm.controls.travellerList.controls[key].controls.txtYR.setValue((data.traveller[key].yr / currency_rate).toFixed(2))
					this.travellerForm.controls.travellerList.controls[key].controls.txtK3.setValue((data.traveller[key].yr / currency_rate).toFixed(2))
					this.travellerForm.controls.travellerList.controls[key].controls.txtOC.setValue((data.traveller[key].oc / currency_rate).toFixed(2))
					this.travellerForm.controls.travellerList.controls[key].controls.txtOT.setValue((data.traveller[key].ot / currency_rate).toFixed(2))
				}
				

				//SET TICKET NUMBER 
				let ticketNumber = data.pnr + '-' + (Number(key) + 1);
				let travellerFormControl = this.travellerForm.controls
				let invoiceFormControl = this.invoiceForm.controls
				let sectorFormControl = this.sectorForm.controls
				if (data.traveller[key].ticketNo == null) {
					travellerFormControl.travellerList.controls[key].controls.txtTicketNumber.setValue(ticketNumber)
					travellerFormControl.travellerList.controls[key].controls.prev_ticket_no.setValue(ticketNumber)

				} else if ((invoiceFormControl.crsId.value != 'website' || invoiceFormControl.crsId.value != 'WEBSITE') && sectorFormControl.txtSector2.value != "" && sectorFormControl.txtSector2.value != null) {
					if (data.traveller[key].ticketNo.split('-').length == 3) {
						if (Number(key) < trvLen) {
							let x = data.traveller[key].ticketNo.split('-');
							let y = x[0] + '-' + x[1]
							let x2 = x[2];
							let AMticketNo = y;
							ticketArr.push(y.slice(0, -2) + x2);
							travellerFormControl.travellerList.controls[key].controls.txtTicketNumber.setValue(AMticketNo);
							travellerFormControl.travellerList.controls[key].controls.prev_ticket_no.setValue(AMticketNo);
							travellerFormControl.travellerList.controls[key].controls.isConjuction.setValue(false);
						}
						else {
							let AMticketNo = ticketArr[trvCount];
							travellerFormControl.travellerList.controls[key].controls.isConjuction.setValue(true);
							travellerFormControl.travellerList.controls[key].controls.txtTicketNumber.setValue(AMticketNo);
							travellerFormControl.travellerList.controls[key].controls.prev_ticket_no.setValue(AMticketNo);
							trvCount++;
						}
					}
					else {
						travellerFormControl.travellerList.controls[key].controls.txtTicketNumber.setValue(data.traveller[key].ticketNo)
						travellerFormControl.travellerList.controls[key].controls.prev_ticket_no.setValue(data.traveller[key].ticketNo)
					}

				} else {
					travellerFormControl.travellerList.controls[key].controls.txtTicketNumber.setValue(data.traveller[key].ticketNo)
					travellerFormControl.travellerList.controls[key].controls.prev_ticket_no.setValue(data.traveller[key].ticketNo)
				}

				if (this.invoiceType == 'reissue') {
					travellerFormControl.travellerList.controls[key].controls.prev_ticket_no.setValidators([Validators.required])
				}else{
					travellerFormControl.travellerList.controls[key].controls.prev_ticket_no.setValidators(null)
				}
				travellerFormControl.travellerList.controls[key].controls.prev_ticket_no.updateValueAndValidity()
				let supplierCode = this.travellerForm.controls.travellerList.controls[key].controls['txtbookingSupplierCode'].value
				let reqObj = {
					clientCode : this.affiliate_code,
					airlineCode : supplierCode ?  supplierCode  : this.invoiceForm.controls.airline.value 
				}
				//CALCULATE TOTALs
				this.getDiscRecallSetting(reqObj,key)
				this.calculateTotal(key)
			}
		}

		let sectorArray = [];
		for (let i = 0; i < data.sector.length; i++) {
			sectorArray.push(data.sector[i].name.split('/')[0]);
			sectorArray.push(data.sector[i].name.split('/')[1]);
		}

		let uniqueSector = this.onlyUnique(sectorArray)
		let sectorComb1 = uniqueSector.join('/');

		await this.bindSector1(sectorComb1,true, null);
		this.sectorForm.controls.txtSector1.setValue(data.sectorComb1);
		if (data.sectorComb2 != null && data.sectorComb2 != '') {
			this.sectorForm.controls.txtSector2.setValue(data.sectorComb2);
		}
		// this.invoiceForm.controls.airline.setValue(data.marketingAirlneCode ? data.marketingAirlneCode : data.sector[0].airline)
		let ssrs = data.ssrResponse && data.ssrResponse['ssrs']

		for (const key in data.traveller) {
			if (data.traveller.hasOwnProperty(key)) {
				const travellerData = data.traveller[key];
				const travellerControl = this.travellerList.controls[key];
				let sectorList  = travellerControl.get('sectorList');

				if (sectorList['controls'] && ssrs) {
					for(let sectorKey in sectorList['controls']) {
					   let sectorControls = sectorList['controls']
					   let sectorSsr = ssrs[sectorKey]
					   
					   let baggageCodes = []
					   let baggages = sectorSsr['baggages'].filter(bag => bag.price == 0) 
					   if(baggages.length> 0){
						for(let baggage of baggages) {
							baggageCodes.push(baggage.code)
						   }
					   }
					   
					   let ancillariesCodes = []
					   let ancillaries =  sectorSsr['ancillaries'].filter(anc => anc.price == 0) 
					   if(ancillaries.length> 0) {
						for(let ancillary of ancillaries) {
							ancillariesCodes.push(ancillary.code)
						}
					   }

					   let mealsCodes = []
					   let meals =  sectorSsr['meals'].filter(meal => meal.price == 0) 
					   if(meals.length> 0) {
						for(let meal of meals) {
							mealsCodes.push(meal.code)
						}
					   }
					  
					   let seatCodes = []
					   let seats = sectorSsr['seats'].filter(seat => seat.price == 0) 
					   if(seats.length> 0) {
						for(let seat of seats) {
							seatCodes.push(seat.Number)
						}
					   }

						sectorControls[sectorKey].controls.txtSeatCharges.setValue(0)
						sectorControls[sectorKey].controls.txtSeatCode.setValue(seatCodes && seatCodes.length> 0 ? seatCodes.toString(): "NA")

						sectorControls[sectorKey].controls.txtMealCharges.setValue(travellerData.mealCharges)
						sectorControls[sectorKey].controls.txtMealCode.setValue(mealsCodes && mealsCodes.length> 0 ? mealsCodes.toString() : "NA")

						sectorControls[sectorKey].controls.txtBagCharges.setValue(travellerData.baggageCharges)
						sectorControls[sectorKey].controls.txtBagCode.setValue(baggageCodes && baggageCodes.length> 0 ? baggageCodes.toString() : "NA")

						sectorControls[sectorKey].controls.txtAdditionalBaggageCharges.setValue(travellerData.additionalBaggageCharges ? travellerData.additionalBaggageCharges : 0)
						sectorControls[sectorKey].controls.txtAdditionalBaggageCode.setValue("NA")

						sectorControls[sectorKey].controls.txtAncillaryCharges.setValue(travellerData.ancillaryCharges ? travellerData.ancillaryCharges : 0)
						sectorControls[sectorKey].controls.txtAncillaryCode.setValue(ancillariesCodes && ancillariesCodes.length>0 ? ancillariesCodes.toString() : "NA")
					}  
				}

				this.calculateTotal(key)
			}
		}

		for (const key in data.sector) {
			if (data.sector.hasOwnProperty(key)) {
				const sector = data.sector[key];
				 
				let index = key
				if (this.sectorForm.controls.sectorlist.controls[key].value.txtSectorComb != sector.name) {
					this.sectorForm.controls.sectorlist.removeAt(key)
					// index = (Number(key)+1).toString()
					this.removeSectorFromTravellers(key)
				}
				this.sectorForm.controls.sectorlist.controls[index].controls.txtAirlinePnrX.setValue(sector.airlinePnr ? sector.airlinePnr : data.pnr)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtAirlinePrefix.setValue(sector.airline)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtFlightNo.setValue(sector.flightNo)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtClass.setValue(sector.bookingClass)
				this.sectorForm.controls.sectorlist.controls[index].controls.dateAirlineDepartInfo.setValue(moment(sector.departureDateTime).format('YYYY-MM-DDTHH:mm'))
				this.sectorForm.controls.sectorlist.controls[index].controls.dateAirlineArrivalInfo.setValue(moment(sector.arrivalDateTime).format('YYYY-MM-DDTHH:mm'))
				this.sectorForm.controls.sectorlist.controls[index].controls.AirlineDepartDate.setValue(moment(sector.departureDateTime).format('YYYY-MM-DD'))
				this.sectorForm.controls.sectorlist.controls[index].controls.AirlineArrivalDate.setValue(moment(sector.arrivalDateTime).format('YYYY-MM-DD'))
				this.sectorForm.controls.sectorlist.controls[index].controls.AirlineDepartTime.setValue(moment(sector.departureDateTime).format('HH:mm'))
				this.sectorForm.controls.sectorlist.controls[index].controls.AirlineArrivalTime.setValue(moment(sector.arrivalDateTime).format('HH:mm'))
				this.sectorForm.controls.sectorlist.controls[index].controls.txtBaggage.setValue(sector.baggage)

				//SET AIRPORT INFO 
				let departTerminal = sector.departureTernimal ? `${sector.departureTernimal}` : "";
				let arrivalTerminal = sector.arrivalTernimal ? `${sector.arrivalTernimal}` : "";
				let departInfo = sector.departureCity  + (sector.departureCity ? ', ' : '') + sector.departureAirport;
				let arrivalinfo = sector.arrivalCity + (sector.arrivalCity ? ', ' : '') + sector.arrivalAirport;

				this.sectorForm.controls.sectorlist.controls[index].controls.txtAirlineDepartInfo.setValue(departInfo.toUpperCase() ? departInfo.toUpperCase() : departInfo)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtAirlineArrivalInfo.setValue(arrivalinfo.toUpperCase() ? arrivalinfo.toUpperCase() : arrivalinfo)

				this.sectorForm.controls.sectorlist.controls[index].controls.txtDepartureTerminal.setValue(departTerminal)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtArrivalTerminal.setValue(arrivalTerminal)

			}
		}
		this.setNarration()

	}

	onlyUnique(self) {
		var newArray = [];
		console.log('All Sector : ', self)
		newArray.push(self[0]);
		for (var i = 0; i < self.length - 1; i++) {
			if (self[i] != self[i + 1]) {
				newArray.push(self[i + 1]);
			}
		}
		console.log('Unique Sector Arr : ', newArray)
		return newArray
	}


	calculateTotal(i) {
		if (this.travellerForm.value.travellerList[i]) {
			let travellerData = Object.assign({}, this.travellerForm.value.travellerList[i])
		let baseFare = Number(travellerData['txtBaseFare'])
		let YQ = Number(travellerData['txtYQ'])
		let YR = Number(travellerData['txtYR'])
		let K3 = Number(travellerData['txtK3'])
		let OC = Number(travellerData['txtOC'])
		let OT = Number(travellerData['txtOT'])
		let addons = this.getAddonsTotal(travellerData['sectorList'])
		let localAddons = this.getLocalAddonsTotal(travellerData['sectorList'])
		let total = baseFare + YQ + YR + K3 + OC + OT + addons
		this.travellerForm.controls.travellerList.controls[i].controls.txtAddons.setValue(addons)
		this.travellerForm.controls.travellerList.controls[i].controls.localAddons.setValue(localAddons)

		this.travellerForm.controls.travellerList.controls[i].controls.txtAmount.setValue(Number(total).toFixed(2))

		let localBaseFare = Number(travellerData['localBaseFare'])
		let localYQ = Number(travellerData['localYQ'])
		let localYR = Number(travellerData['localYR'])
		let localK3 = Number(travellerData['localK3'])
		let localOC = Number(travellerData['localOC'])
		let localOT = Number(travellerData['localOT'])
		let local_total = localBaseFare + localYQ + localYR + localK3 + localOC + localOT + localAddons
		this.travellerForm.controls.travellerList.controls[i].controls.localAmount.setValue(Number(local_total).toFixed(2))
		}
	}
	getAddonsTotal(sectors) {
		let currency_rate ;
		this.invoiceRootData.subscribe((items)=> {  
			currency_rate = items['currency_rate'] ? items['currency_rate'] : 1
		})
		let totalAmount = 0
		for (const key in sectors) {
			if (sectors.hasOwnProperty(key)) {
				const element = sectors[key];
				if (element['txtSeatCharges'] != "") {
					totalAmount += Number(element['txtSeatCharges']) * Number(currency_rate)
				}
				if (element['txtMealCharges'] != "") {
					totalAmount += Number(element['txtMealCharges']) * Number(currency_rate)
				}
				if (element['txtBagCharges'] != "") {
					totalAmount += Number(element['txtBagCharges']) * Number(currency_rate)
				}
				if (element['txtAdditionalBaggageCharges'] != "") {
					totalAmount += Number(element['txtAdditionalBaggageCharges']) * Number(currency_rate)
				}
				if (element['txtAncillaryCharges'] != "") {
					totalAmount += Number(element['txtAncillaryCharges']) * Number(currency_rate)
				}
			}
		}
		return totalAmount
	}

	buildTraveller(travellerData) {
		let totalClientServiceCharge = 0;
		
		if(this.clientServiceCharge) {
			let sectorListLength = this.DynamicFormControls.controls && this.DynamicFormControls.controls.length;
			if(sectorListLength) {
				totalClientServiceCharge = this.clientServiceCharge * sectorListLength;
			}
		}
		if(travellerData && Number(travellerData.txtClientSC1Value)) {
			totalClientServiceCharge += Number(travellerData.txtClientSC1Value)
		}

		

		return this.fb.group({
			selTitle: [travellerData && travellerData.selTitle ? travellerData.selTitle : "" ,Validators.required],
			totalIndexCount: [43],
			travellerStatus : ['not_cancelled'],
			selType: [travellerData && travellerData.selType ? travellerData.selType : "ADT", Validators.required],
			txtPassengerName: [travellerData && travellerData.txtPassengerName ? travellerData.txtPassengerName : "", [Validators.required   ]],
			txtEmployeeName: [travellerData && travellerData.txtEmployeeName ? travellerData.txtEmployeeName : ""],
			txtEmployeeCode: [travellerData && travellerData.txtEmployeeCode ? travellerData.txtEmployeeCode : ""],
			txtEmployeeCostCenter: [travellerData && travellerData.txtEmployeeCostCenter ? travellerData.txtEmployeeCostCenter : ""],
			txtSupplierCode: [travellerData && travellerData.txtSupplierCode ? travellerData.txtSupplierCode : ""],
			supplierDate: [travellerData && travellerData.supplierDate ? travellerData.supplierDate : ""],
			txtTicketNumber: [travellerData && travellerData.txtTicketNumber ? travellerData.txtTicketNumber : "", [Validators.required, Validators.pattern('^[ A-Za-z0-9_@./#&+-]*$')]],
			prev_ticket_no : [travellerData && travellerData.prev_ticket_no ? travellerData.prev_ticket_no : ""],
			txtbookingSupplier: [travellerData && travellerData.txtbookingSupplier ? travellerData.txtbookingSupplier : ""],
			txtbookingSupplierCode: [travellerData && travellerData.txtbookingSupplierCode ? travellerData.txtbookingSupplierCode : ""],
			type_of_pay: [travellerData && travellerData.type_of_pay ? travellerData.type_of_pay : ""],
			ledger_card_no: [travellerData && travellerData.ledger_card_no ? travellerData.ledger_card_no : ""],
			ledger_card_name: [travellerData && travellerData.ledger_card_name ? travellerData.ledger_card_name : ""],
			txtBaseFare: [travellerData && travellerData.txtBaseFare ? travellerData.txtBaseFare : "0", Validators.required],
			txtYQ: [travellerData && travellerData.txtYQ ? travellerData.txtYQ : "0"],
			txtYR: [travellerData && travellerData.txtYR ? travellerData.txtYR : "0"],
			txtK3: [travellerData && travellerData.txtK3 ? travellerData.txtK3 : "0"],
			txtOC: [travellerData && travellerData.txtOC ? travellerData.txtOC : "0"],
			txtOT: [travellerData && travellerData.txtOT ? travellerData.txtOT : "0"],
			txtAddons: [travellerData && travellerData.txtAddons ? travellerData.txtAddons : "0"],
			txtAmount: [travellerData && travellerData.txtAmount ? travellerData.txtAmount : "0"],
			txtSupplierSC1Type: [travellerData && travellerData.txtSupplierSC1Type ? travellerData.txtSupplierSC1Type : "T"],
			txtSupplierSC1Value: [travellerData && travellerData.txtSupplierSC1Value ? travellerData.txtSupplierSC1Value : "0"],
			txtSupplierSC1Amount: [travellerData && travellerData.txtSupplierSC1Amount ? travellerData.txtSupplierSC1Amount : "0"],
			txtSupplierSC2Type: [travellerData && travellerData.txtSupplierSC2Type ? travellerData.txtSupplierSC2Type : "N"],
			txtSupplierSC2Value: [travellerData && travellerData.txtSupplierSC2Value ? travellerData.txtSupplierSC2Value : "", [Validators.pattern(/^-?\d{1,8}(\.\d{1,2})?$/)]],
			txtSupplierSC2Amount: [travellerData && travellerData.txtSupplierSC2Amount ? travellerData.txtSupplierSC2Amount : ""],
			txtSupplierSC3Type: [travellerData && travellerData.txtSupplierSC3Type ? travellerData.txtSupplierSC3Type : "N"],
			txtSupplierSC3Value: [travellerData && travellerData.txtSupplierSC3Value ? travellerData.txtSupplierSC3Value : "0", [Validators.pattern(/^-?\d{1,8}(\.\d{1,2})?$/)]],
			txtSupplierSC3Amount: [travellerData && travellerData.txtSupplierSC3Amount ? travellerData.txtSupplierSC3Amount : "0"],
			selSupplierIataType: [travellerData && travellerData.selSupplierIataType ? travellerData.selSupplierIataType : "RB"],
			selSupplierIataAmount: [travellerData && travellerData.selSupplierIataAmount ? travellerData.selSupplierIataAmount : "0"],
			selSupplierIataValue: [travellerData && travellerData.selSupplierIataValue ? travellerData.selSupplierIataValue : "0"],
			selSupplierCbType: [travellerData && travellerData.selSupplierCbType ? travellerData.selSupplierCbType : "VL"],
			selSupplierCbAmount: [travellerData && travellerData.selSupplierCbAmount ? travellerData.selSupplierCbAmount : "0"],
			selSupplierCbValue: [travellerData && travellerData.selSupplierCbValue ? travellerData.selSupplierCbValue : "0"],
			selSupplierPlbType: [travellerData && travellerData.selSupplierPlbType ? travellerData.selSupplierPlbType : "RB"],
			selSupplierPlbAmount: [travellerData && travellerData.selSupplierPlbAmount ? travellerData.selSupplierPlbAmount : "0"],
			selSupplierPlbValue: [travellerData && travellerData.selSupplierPlbValue ? travellerData.selSupplierPlbValue : "0"],
			selSupplierDsType: [travellerData && travellerData.selSupplierDsType ? travellerData.selSupplierDsType : "VL"],
			selSupplierDsAmount: [travellerData && travellerData.selSupplierDsAmount ? travellerData.selSupplierDsAmount : "0"],
			selSupplierDsValue: [travellerData && travellerData.selSupplierDsValue ? travellerData.selSupplierDsValue : "0"],
			selClientIataType: [travellerData && travellerData.selClientIataType ? travellerData.selClientIataType : "RB"],
			selClientIataValue: [travellerData && travellerData.selClientIataValue ? travellerData.selClientIataValue : "0"],
			selClientIataAmount: [travellerData && travellerData.selClientIataAmount ? travellerData.selClientIataAmount : "0"],
			selClientPlbType: [travellerData && travellerData.selClientPlbType ? travellerData.selClientPlbType : "RB"],
			selClientPlbValue: [travellerData && travellerData.selClientPlbValue ? travellerData.selClientPlbValue : "0"],
			selClientPlbAmount: [travellerData && travellerData.selClientPlbAmount ? travellerData.selClientPlbAmount : "0"],
			selClientCbType: [travellerData && travellerData.selClientCbType ? travellerData.selClientCbType : "VL"],
			selClientCbValue: [travellerData && travellerData.selClientCbValue ? travellerData.selClientCbValue : "0"],
			selClientCbAmount: [travellerData && travellerData.selClientCbAmount ? travellerData.selClientCbAmount : "0"],
			selClientDsType: [travellerData && travellerData.selClientDsType ? travellerData.selClientDsType : "VL"],
			selClientDsValue: [travellerData && travellerData.selClientDsValue ? travellerData.selClientDsValue : "0"],
			selClientDsAmount: [travellerData && travellerData.selClientDsAmount ? travellerData.selClientDsAmount : "0"],
			txtClientSC1Type: [travellerData && travellerData.txtClientSC1Type ? travellerData.txtClientSC1Type : "T"],
			txtClientSC1Value: [totalClientServiceCharge ? totalClientServiceCharge : "0"],
			txtClientSC1Amount: [totalClientServiceCharge ? totalClientServiceCharge : "0"],
			txtClientSC2Type: [travellerData && travellerData.txtClientSC2Type ? travellerData.txtClientSC2Type : "OT"],
			txtClientSC2Taxable: [travellerData && travellerData.txtClientSC2Taxable ? travellerData.txtClientSC2Taxable : "N"],
			txtClientSC2Value: [travellerData && travellerData.txtClientSC2Value ? travellerData.txtClientSC2Value : "0", [Validators.pattern(/^-?\d{1,8}(\.\d{1,2})?$/)]],
			txtClientSC2Amount: [travellerData && travellerData.txtClientSC2Amount ? travellerData.txtClientSC2Amount : "0"],
			txtClientSC3Type: [travellerData && travellerData.txtClientSC3Type ? travellerData.txtClientSC3Type : "OT"],
			txtClientSC3Taxable: [travellerData && travellerData.txtClientSC3Taxable ? travellerData.txtClientSC3Taxable : "N"],
			txtClientSC3Value: [travellerData && travellerData.txtClientSC3Value ? travellerData.txtClientSC3Value : "0", [Validators.pattern(/^-?\d{1,8}(\.\d{1,2})?$/)]],
			txtClientSC3Amount: [travellerData && travellerData.txtClientSC3Amount ? travellerData.txtClientSC3Amount : "0"],
			isConjuction: [false],
			txtClientRAFAmount: [travellerData && travellerData.txtClientRAFAmount ? travellerData.txtClientRAFAmount : "0"],
			txtClientXXLAmount: [travellerData && travellerData.txtClientXXLAmount ? travellerData.txtClientXXLAmount : "0"],
			txtSupplierRAFAmount: [travellerData && travellerData.txtSupplierRAFAmount ? travellerData.txtSupplierRAFAmount : "0"],
			txtSupplierXXLAmount: [travellerData && travellerData.txtSupplierXXLAmount ? travellerData.txtSupplierXXLAmount : "0"],
			disClientRecallType: [""],
			disClientRecallValue: [this.disRecallRateClient],
			disClientRecallAmount: ["0"],
			disSupplierRecallType: [""],
			disSupplierRecallValue: [this.disRecallRateSupplier],
			disSupplierRecallAmount: ["0"],
			sectorList: this.fb.array([]),
			sector2List: this.fb.array([]),
			localBaseFare: [travellerData && travellerData.localBaseFare ? travellerData.localBaseFare : "0"],
			localYQ: [travellerData && travellerData.localYQ ? travellerData.localYQ : "0"],
			localYR: [travellerData && travellerData.localYR ? travellerData.localYR : "0"],
			localK3: [travellerData && travellerData.localK3 ? travellerData.localK3 : "0"],
			localOC: [travellerData && travellerData.localOC ? travellerData.localOC : "0"],
			localOT: [travellerData && travellerData.localOT ? travellerData.localOT : "0"],
			localAddons: [travellerData && travellerData.localAddons ? travellerData.localAddons : "0"],
			localAmount: [travellerData && travellerData.localAmount ? travellerData.localAmount : "0"],
		});
	}

	buildTravellerSector(sectorData) {
		return this.fb.group({
			sector: [sectorData],
			txtSeatCode: ["", Validators.pattern('^[a-zA-Z0-9_]*$')],
			txtSeatCharges: [""],
			txtMealCode: ["", Validators.pattern('^[a-zA-Z0-9_]*$')],
			txtMealCharges: [""],
			txtBagCode: ["", Validators.pattern('^[a-zA-Z0-9_]*$')],
			txtBagCharges: [""],
			txtAdditionalBaggageCode: ["", Validators.pattern('^[a-zA-Z0-9_]*$')],
			txtAdditionalBaggageCharges: [""],
			txtAncillaryCode: ["", Validators.pattern('^[a-zA-Z0-9_]*$')],
			txtAncillaryCharges: [""],
			isCancelled : [false]

		});
	}

	buildsectorlist(customSector, pnr, departureAirport, arrivalAirport,prefix="") {
		return this.fb.group({
			txtSectorComb: [customSector],
			txtAirlinePnrX: [pnr, Validators.required],
			txtAirlinePrefix: [prefix, Validators.required],
			txtFlightNo: ["", Validators.required],
			txtClass: [""],
			txtAirlineDepartInfo: [departureAirport && departureAirport['result'].toUpperCase() ? departureAirport['result'].toUpperCase() : ''],
			txtAirlineArrivalInfo: [arrivalAirport && arrivalAirport['result'].toUpperCase() ? arrivalAirport['result'].toUpperCase() : ''],
			dateAirlineDepartInfo: [""],
			txtDepartureTerminal: [""],
			dateAirlineArrivalInfo: [""],
			txtArrivalTerminal: [""],
			txtBaggage: [""],
			AirlineDepartDate:[''],
			AirlineDepartTime:[''],
			AirlineArrivalDate:[''],
			AirlineArrivalTime:['']
		});
	}
	buildsector2list(customSector, pnr, departureAirport, arrivalAirport,prefix="") {
		return this.fb.group({
			txtSectorComb2: [customSector],
			txtAirlinePnrY: [pnr, Validators.required],
			txtAirlinePrefix2: [prefix, Validators.required],
			txtFlightNo2: ["", Validators.required],
			txtClass2: [""],
			txtAirlineDepartInfo2: [departureAirport['result'].toUpperCase() ? departureAirport['result'].toUpperCase() : departureAirport['result']],
			txtAirlineArrivalInfo2: [arrivalAirport['result'].toUpperCase() ? arrivalAirport['result'].toUpperCase() : arrivalAirport['result']],
			dateAirlineDepartInfo2: ["", Validators.required],
			txtDepartureTerminal2: [""],
			dateAirlineArrivalInfo2: ["", Validators.required],
			txtArrivalTerminal2: [""],
			txtBaggage2: [""],
			AirlineDepartDate2:[''],
			AirlineDepartTime2:[''],
			AirlineArrivalDate2:[''],
			AirlineArrivalTime2:['']
		});
	}

	get DynamicFormControls() {
		const secLst = <FormArray>this.sectorForm.get("sectorlist");
		return secLst;
	}
	get DynamicFormControls2() {
		return <FormArray>this.sectorForm.get("sector2list");
	}

	async bindSector1(value,skipSectorList=false, nestedFormValue) {
		let sectorValue = value;
		let currentSectorArray = sectorValue.split("/");
		let currentVal = currentSectorArray.join("");
		if (currentVal.match(/.{1,3}/g) != null) {
			let splitVal = currentVal.match(/.{1,3}/g).join("/");
			this.sectorForm.controls.txtSector1.setValue(splitVal);
			if (currentSectorArray.length > 0) {
				let lastvalueOfCurrentSector = currentSectorArray[currentSectorArray.length - 1];
				if (lastvalueOfCurrentSector.length == 3) {
					this.sectorlist = this.sectorForm.get("sectorlist") as FormArray;
					this.sectorlist.controls = [];
					let i = 0;
					for (const currentSector in currentSectorArray) {
						let customSector = currentSectorArray[i] + "/" + currentSectorArray[i + 1];
						if (currentSectorArray[i + 1] != undefined && customSector.length > 6) {
							let departureAirport;
							let arrivalAirport;
							if(!skipSectorList){
								departureAirport = await this.getAirport(currentSectorArray[i],true)
								arrivalAirport = await this.getAirport(currentSectorArray[i + 1],true)
							}
							let airlinePrefix = ""
							if(this.invoiceForm.value.airline_name && this.invoiceForm.value.airline_name['2_ltr_code']){
								airlinePrefix = this.invoiceForm.value.airline_name['2_ltr_code']
							}else{
								airlinePrefix = this.invoiceForm.value.airline ? this.invoiceForm.value.airline.substr(0,2) : ""
							}
							let airlinePnr = this.invoiceForm.value.pnr
							let sectorList = this.buildsectorlist(customSector, airlinePnr, departureAirport, arrivalAirport,airlinePrefix)
							this.sectorlist.push(sectorList);
						}
						i++;
					}

					this.travellerList = this.travellerForm.get("travellerList") as FormArray;
					this.totalIndexInTraveller = 0
					for (let i = 0; i < this.travellerList.length; i++) {
						this.sectorList = this.travellerForm.get("travellerList").controls[i].get('sectorList');
						let totalIndexCount = 43
						this.sectorList.controls = [];
						for (let i = 0; i < currentSectorArray.length - 1; i++) {
							let customSector = currentSectorArray[i] + "/" + currentSectorArray[i + 1];
							if (customSector.length > 6) {
								const element = customSector;
								this.sectorList.push(this.buildTravellerSector(element))
								totalIndexCount = totalIndexCount + 10
							}
						}
						this.totalIndexInTraveller = this.totalIndexInTraveller + totalIndexCount
						this.travellerForm.get("travellerList").controls[i].controls.totalIndexCount.setValue(totalIndexCount)
						let travellerStatus = this.isCancelledFn(this.travellerForm.get("travellerList").controls[i])
						this.travellerForm.get("travellerList").controls[i].controls.travellerStatus.setValue(travellerStatus)
					}
				}
			}
		} else {
			this.sectorlist = this.sectorForm.get("sectorlist") as FormArray;
			this.sectorlist.controls = [];
		}
		if(nestedFormValue) {
			this.calculateServiceChargeFlightWise(nestedFormValue);
		}
		let isTenantIndian = this.commonService.checkTenantIndian();
		let lastIndexOfSector;
		if(isTenantIndian) {
			lastIndexOfSector = 18;
		} else {
			lastIndexOfSector = 20;
		}
		this.lastSectorOneIndex = ((this.sectorlist.controls.length-1) * 11) + 11 + lastIndexOfSector
		this.sector2list = this.sectorForm.get("sector2list") as FormArray;
		this.lastSectorTwoIndex = ((this.sector2list.controls.length-1) * 11) + 11 + this.lastSectorOneIndex
		this.lastIndexOfTraveller = this.totalIndexInTraveller + this.lastSectorTwoIndex

		// this.setNarration()
	
	}


	async getSector1(event) {
		if (event.target.value.length >= 3) {
			let sectorValue = event.target.value;
			let currentSectorArray = sectorValue.split("/");
			let currentVal = currentSectorArray.join("");
			let splitVal = currentVal.match(/.{1,3}/g).join("/");
			this.sectorForm.controls.txtSector1.setValue(splitVal);
		}

	}

	async bindSector2(value) {
		let sectorValue = value;
		let currentSectorArray = sectorValue.split("/");
		let currentVal = currentSectorArray.join("");
		if (currentVal.match(/.{1,3}/g) != null) {
			let splitVal = currentVal.match(/.{1,3}/g).join("/");
			let sector1 = this.sectorForm.controls.txtSector1.value
			let sector1Arr = sector1.split("/");
			let temp = sector1Arr[sector1Arr.length - 1] + "/" + splitVal;
			this.sectorForm.controls.txtSector2.setValue(splitVal);
			currentSectorArray = temp.split("/")
			if (currentSectorArray.length > 0) {
				let lastvalueOfCurrentSector = currentSectorArray[currentSectorArray.length - 1];
				if (lastvalueOfCurrentSector.length == 3) {
					this.sector2list = this.sectorForm.get("sector2list") as FormArray;
					this.sector2list.controls = [];
					let i = 0;
					for (const currentSector in currentSectorArray) {
						let customSector = currentSectorArray[i] + "/" + currentSectorArray[i + 1];
						if (currentSectorArray[i + 1] != undefined && customSector.length > 6) {
							let departureAirport = await this.getAirport(currentSectorArray[i],true)
							let arrivalAirport = await this.getAirport(currentSectorArray[i + 1],true)
							let airlinePrefix = ""
							if(this.invoiceForm.value.airline_name && this.invoiceForm.value.airline_name['2_ltr_code']){
								airlinePrefix = this.invoiceForm.value.airline_name['2_ltr_code']
							}else{
								airlinePrefix = this.invoiceForm.value.airline ? this.invoiceForm.value.airline.substr(0,2) : ""
							}
							let airlinePnr = this.invoiceForm.value.pnr ? this.invoiceForm.value.pnr : ""
							let sector2list = this.buildsector2list(customSector, airlinePnr, departureAirport, arrivalAirport,airlinePrefix)
							this.sector2list.push(sector2list);
						}
						i++;
					}

					this.travellerList = this.travellerForm.get("travellerList") as FormArray;
					for (let i = 0; i < this.travellerList.length; i++) {
						this.sector2List = this.travellerForm.get("travellerList").controls[i].get('sector2List');
						this.sector2List.controls = [];
						for (let i = 0; i < currentSectorArray.length - 1; i++) {
							let customSector = currentSectorArray[i] + "/" + currentSectorArray[i + 1];
							if (customSector.length > 6) {
								const element = customSector;
								this.sector2List.push(this.buildTravellerSector(element))
							}
						}
					}
				}
			}
		} else {
			this.sector2list = this.sectorForm.get("sector2list") as FormArray;
			this.sector2list.controls = [];
		}
		this.lastSectorTwoIndex = this.sector2list.controls.length * 11 + 11 + this.lastSectorOneIndex
		console.log('Last index on sector two', this.lastSectorTwoIndex)
        // this.setNarration()
	}


	async getSector2(event) {
		if (event.target.value.length >= 3) {
			let sectorValue = event.target.value;
			let currentSectorArray = sectorValue.split("/");
			let currentVal = currentSectorArray.join("");
			let splitVal = currentVal.match(/.{1,3}/g).join("/");
			this.sectorForm.controls.txtSector2.setValue(splitVal);
		}

	}


	getAirport(code,isCode) {
		return new Promise((resolve, reject) => {
			this.invoiceservice.getAirlineInfo(code).subscribe(
				(res: any) => {
					let resArr = res.filter(option => {
						if(isCode){
							if (option['airport_CODE'] == code) {
								resolve(option);
							}
							else {
								option = {
									"result": "N/A",
									"airport_CODE": "N/A"
								}
								resolve(option);
							}
						}else{
							resolve(option)
						}
					});
				},
				err => {
					reject("");
				}
			);
		});
	}

	resetFormData() {
		this.travellerForm.reset()
		this.invoiceForm.reset()
		this.sectorForm.reset()
		if(this.travellerList && this.travellerList.controls)this.travellerList.controls = []
		if(this.sectorList && this.sectorList.controls)this.sectorList.controls = []
		if(this.sectorlist && this.sectorlist.controls)this.sectorlist.controls = []
		if(this.sector2list && this.sector2list.controls)this.sector2list.controls = []

		// this.sector2list.controls = [];

	}

	async onInvoiceTypeChange(value) {
		try {
		  let status;
		  if (
			value == "C" &&
			this.travellerForm.value.travellerList.length > 0
		  ) {
			status = await this.updateTravellersValidators(
			  "txtbookingSupplier",
			  "required",
			  value
			);
		  } else {
			status = await this.updateTravellersValidators(
			  "txtbookingSupplier",
			  "null",
			  value
			);
		  }
		  console.log(`value : ${value} : `,status);
		} catch (error) {
		  console.log("Error occured while selecting invoice Type : ", error);
		}
	  }
	async bindInvoiceData(data) {
		console.log("data",data)
		this.travellerList = this.travellerForm.get("travellerList");
		this.travellerList = this.travellerForm.get("travellerList") as FormArray;
		this.travellerList.controls = [];
		this.invoiceForm.controls.travellerCount.setValue(data.travellers.length)
		this.invoiceForm.controls.fareBasis.setValue(data.fareBasis)
		this.invoiceForm.controls.dealCode.setValue(data.dealCode)
		this.invoiceForm.controls.classType.setValue(data.classType)
		this.invoiceForm.controls.airline.setValue(data.airline)
		if(data.booking_ref_no) {
			this.invoiceForm.controls.booking_ref.setValue(data.booking_ref_no)
		}
		let reqObj = {
			clientCode : this.affiliate_code,
			airlineCode : data.airline,
			date : data.airline,
		}
		

		try {
			if(this.generalAccArr == undefined || this.generalAccArr == null || this.generalAccArr.length == 0) {
				await this.getGeneralAccounts();
			}
			if (this.airlineArr == undefined || this.airlineArr == null || this.airlineArr.length == 0) {
				let airlineRes = await this.getAirline()
			}
			if (this.accountList == undefined) {
				let accountsRes = await this.getAccounts('client')
			}
			if (this.employeeList == undefined) {
				let employeeRes = await this.getEmployee('employee')
			}
	
			let selectedAirline = this.airlineArr.filter(option => {
				return option.account_code == data.airline;
			});
			if (selectedAirline.length > 0) {
				this.invoiceForm.controls.airline_name.setValue(selectedAirline[0].name)
				this.airlineArr = selectedAirline
			}
			if(this.route.snapshot &&
				this.route.snapshot.queryParams &&
				this.route.snapshot.queryParams['employee']){
					data.employee = this.route.snapshot.queryParams['employee']
			}
			let selectedEmployee  = this.employeeList.filter(option => {
					return option.account_code == data.employee;
			});
			if (selectedEmployee.length > 0) {
					this.invoiceForm.controls.employee_name.setValue(selectedEmployee[0].name)
			}
			this.invoiceForm.controls.employee.setValue(data.employee ? data.employee : '')
			if(data.employee == 'EO0000'){
				this.invoiceForm.controls.employee.setValue('ADMIN')
				this.invoiceForm.controls.employee_name.setValue('ADMIN')
			}
			let selectedSubledger = this.accountList.filter(option => {
				return option.account_code == data.sub_ledger_code_1;
			});
		
			
			if (selectedSubledger.length > 0) {
				this.invoiceForm.controls.sub_ledger_code_1_name.setValue(selectedSubledger[0].name)
			}
	
		} catch (error) {
			
		}
		let crsId = data.crs_id_value ? data.crs_id_value.toUpperCase() : 'WEBSITE'
		this.invoiceForm.controls.crsId.setValue(crsId.toUpperCase())
		let supplier = data.supplier 
		let invoiceType = data.invoiceType
		let selectedSupplier = this.retrievalConfig.filter(option => {
			return option.acode == data.airline;
		});
		if(selectedSupplier.length > 0){
			supplier = selectedSupplier[0].display_name
			if(!invoiceType){
				invoiceType = selectedSupplier[0].type
			}
		}
		
		this.invoiceForm.controls.supplier.setValue(supplier)
		this.invoiceForm.controls.invoiceType.setValue(invoiceType)
		this.onInvoiceTypeChange(invoiceType)
		for (const key in data.travellers) {
			if (data.travellers.hasOwnProperty(key)) {
				this.travellerList.push(this.buildTraveller(data.travellers[key]));
				let supplierNameWithAccount;
				try {
					let selectedSupplier = this.supplierList.filter(option => {
						return option.account_code == data.travellers[key].booking_supplier;
					});
					if(selectedSupplier && selectedSupplier.length > 0) {
						supplierNameWithAccount = selectedSupplier[0].name + '('+ selectedSupplier[0].account_code + ')';
					}
					else{
						let selectedSupplier =  this.airlineArr.filter(option => {
							return option.account_code == data.travellers[key].booking_supplier;
						});
						if(selectedSupplier && selectedSupplier.length > 0) {
							supplierNameWithAccount = selectedSupplier[0].account_name ;
						}
					}
				} catch (error) {

				}
				if(!supplierNameWithAccount) {
					supplierNameWithAccount  = data.travellers[key].booking_supplier
				}

				let generalNameWithAccount;
				try {
					let selectedGeneral = this.generalAccArr.filter(option => {
						return option.account_code == data.travellers[key].ledger_card_no;
					});

					if(selectedGeneral && selectedGeneral.length > 0) {
						generalNameWithAccount = selectedGeneral[0].name + '('+ selectedGeneral[0].account_code + ')';
					}
				} catch (error) {

				}

				//SET VALUE
				// let travellerStatus = this.isCancelledFn(this.travellerForm.controls.travellerList.controls[key])
				this.travellerForm.controls.travellerList.controls[key].controls.selTitle.setValue(data.travellers[key].title.toUpperCase())
				this.travellerForm.controls.travellerList.controls[key].controls.selType.setValue(data.travellers[key].type)
				this.travellerForm.controls.travellerList.controls[key].controls.txtPassengerName.setValue(data.travellers[key].name)
				this.travellerForm.controls.travellerList.controls[key].controls.txtEmployeeName.setValue(data.travellers[key].employee ? data.travellers[key].employee : data.travellers[key].name)
				this.travellerForm.controls.travellerList.controls[key].controls.txtEmployeeCode.setValue(data.travellers[key].e_code?data.travellers[key].e_code:'')
				this.travellerForm.controls.travellerList.controls[key].controls.txtEmployeeCostCenter.setValue(data.travellers[key].cost_center?data.travellers[key].cost_center:'')
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierCode.setValue(data.travellers[key].payable_transaction_code?data.travellers[key].payable_transaction_code:'')
				this.travellerForm.controls.travellerList.controls[key].controls.supplierDate.setValue(data.travellers[key].payable_date?data.travellers[key].payable_date:'')
				this.travellerForm.controls.travellerList.controls[key].controls.txtbookingSupplier.setValue(supplierNameWithAccount)
				this.travellerForm.controls.travellerList.controls[key].controls.txtbookingSupplierCode.setValue(data.travellers[key].booking_supplier)
				
				this.travellerForm.controls.travellerList.controls[key].controls.ledger_card_no.setValue(data.travellers[key].ledger_card_no)
				this.travellerForm.controls.travellerList.controls[key].controls.ledger_card_name.setValue(generalNameWithAccount)

				
				this.travellerForm.controls.travellerList.controls[key].controls.type_of_pay.setValue(data.travellers[key].pay_type ? data.travellers[key].pay_type : '')
				
				if (data.invoiceType == 'C') {
					this.travellerForm.controls.travellerList.controls[key].controls.txtbookingSupplier.setValidators(Validators.required)
				}else{
					this.travellerForm.controls.travellerList.controls[key].controls.txtbookingSupplier.setValidators(null)
				}

				if (data.travellers[key].pay_type == 'OUR_CC') {
					this.travellerForm.controls.travellerList.controls[key].controls.ledger_card_no.setValidators(Validators.required)
					this.travellerForm.controls.travellerList.controls[key].controls.ledger_card_name.setValidators(Validators.required)
				}else{
					this.travellerForm.controls.travellerList.controls[key].controls.ledger_card_no.setValidators(null)
					this.travellerForm.controls.travellerList.controls[key].controls.ledger_card_name.setValidators(null)
				}
				// //SET FARE VALUE
				this.travellerForm.controls.travellerList.controls[key].controls.txtBaseFare.setValue(data.travellers[key]['fare'].base)
				this.travellerForm.controls.travellerList.controls[key].controls.txtYQ.setValue(data.travellers[key]['fare'].YQ)
				this.travellerForm.controls.travellerList.controls[key].controls.txtYR.setValue(data.travellers[key]['fare'].YR)
				this.travellerForm.controls.travellerList.controls[key].controls.txtK3.setValue(data.travellers[key]['fare'].K3)
				this.travellerForm.controls.travellerList.controls[key].controls.txtOC.setValue(data.travellers[key]['fare'].OC)
				this.travellerForm.controls.travellerList.controls[key].controls.txtOT.setValue(data.travellers[key]['fare'].OT)

				this.travellerForm.controls.travellerList.controls[key].controls.txtTicketNumber.setValue(data.travellers[key]['ticket_no'])
				this.travellerForm.controls.travellerList.controls[key].controls.prev_ticket_no.setValue(data.travellers[key]['prev_ticket_no'] ? data.travellers[key]['prev_ticket_no'] : data.travellers[key]['ticket_no'])
				
				if(data.travellers[key].hasOwnProperty('local_fare')) {
					this.travellerForm.controls.travellerList.controls[key].controls.localBaseFare.setValue(data.travellers[key]['local_fare'].base)
					this.travellerForm.controls.travellerList.controls[key].controls.localYQ.setValue(data.travellers[key]['local_fare'].YQ)
					this.travellerForm.controls.travellerList.controls[key].controls.localYR.setValue(data.travellers[key]['local_fare'].YR)
					this.travellerForm.controls.travellerList.controls[key].controls.localK3.setValue(data.travellers[key]['local_fare'].K3)
					this.travellerForm.controls.travellerList.controls[key].controls.localOC.setValue(data.travellers[key]['local_fare'].OC)
					this.travellerForm.controls.travellerList.controls[key].controls.localOT.setValue(data.travellers[key]['local_fare'].OT)
		     	}

				if (this.invoiceType == 'reissue') {
					this.travellerForm.controls.travellerList.controls[key].controls.prev_ticket_no.setValidators([Validators.required])
				}else{
					this.travellerForm.controls.travellerList.controls[key].controls.prev_ticket_no.setValidators(null)
				}
				this.travellerForm.controls.travellerList.controls[key].controls.prev_ticket_no.updateValueAndValidity()
				this.travellerForm.controls.travellerList.controls[key].controls.isConjuction.setValue(data.travellers[key]['isConjuction'])


				// Bind Client Commission 
				this.travellerForm.controls.travellerList.controls[key].controls.selClientIataType.setValue(data.travellers[key].commission.customer['iata_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.selClientIataValue.setValue(data.travellers[key].commission.customer['iata_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.selClientIataAmount.setValue(data.travellers[key].commission.customer['iata_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.selClientPlbType.setValue(data.travellers[key].commission.customer['plb_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.selClientPlbValue.setValue(data.travellers[key].commission.customer['plb_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.selClientPlbAmount.setValue(data.travellers[key].commission.customer['plb_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.selClientCbType.setValue(data.travellers[key].commission.customer['cashback_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.selClientCbValue.setValue(data.travellers[key].commission.customer['cashback_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.selClientCbAmount.setValue(data.travellers[key].commission.customer['cashback_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.selClientDsType.setValue(data.travellers[key].commission.customer['discount_type']?data.travellers[key].commission.customer['discount_type']:'VL')
				this.travellerForm.controls.travellerList.controls[key].controls.selClientDsValue.setValue(data.travellers[key].commission.customer['discount_value']?data.travellers[key].commission.customer['discount_value']:'0')
				this.travellerForm.controls.travellerList.controls[key].controls.selClientDsAmount.setValue(data.travellers[key].commission.customer['discount_amount']?data.travellers[key].commission.customer['discount_amount']:'0')

				// Bind Client Service Charge 

				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC1Type.setValue(data.travellers[key].service_charge.customer['sc1_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC1Value.setValue(data.travellers[key].service_charge.customer['sc1_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC1Amount.setValue(data.travellers[key].service_charge.customer['sc1_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC2Type.setValue(data.travellers[key].service_charge.customer['sc2_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC2Taxable.setValue(data.travellers[key].service_charge.customer['sc2_taxable'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC2Value.setValue(data.travellers[key].service_charge.customer['sc2_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC2Amount.setValue(data.travellers[key].service_charge.customer['sc2_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC3Type.setValue(data.travellers[key].service_charge.customer['sc3_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC3Taxable.setValue(data.travellers[key].service_charge.customer['sc3_taxable'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC3Value.setValue(data.travellers[key].service_charge.customer['sc3_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtClientSC3Amount.setValue(data.travellers[key].service_charge.customer['sc3_amount'])




				// Bind Supplier Commission 
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierIataType.setValue(data.travellers[key].commission.supplier['iata_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierIataValue.setValue(data.travellers[key].commission.supplier['iata_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierIataAmount.setValue(data.travellers[key].commission.supplier['iata_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierCbType.setValue(data.travellers[key].commission.supplier['cashback_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierCbValue.setValue(data.travellers[key].commission.supplier['cashback_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierCbAmount.setValue(data.travellers[key].commission.supplier['cashback_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierPlbType.setValue(data.travellers[key].commission.supplier['plb_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierPlbValue.setValue(data.travellers[key].commission.supplier['plb_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierPlbAmount.setValue(data.travellers[key].commission.supplier['plb_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierDsType.setValue(data.travellers[key].commission.supplier['discount_type']?data.travellers[key].commission.supplier['discount_type']:'VL')
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierDsValue.setValue(data.travellers[key].commission.supplier['discount_value']?data.travellers[key].commission.supplier['discount_value']:'0')
				this.travellerForm.controls.travellerList.controls[key].controls.selSupplierDsAmount.setValue(data.travellers[key].commission.supplier['discount_amount']?data.travellers[key].commission.supplier['discount_amount']:'0')
				// Bind Supplier Service Charge 

				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC1Type.setValue(data.travellers[key].service_charge.supplier['sc1_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC1Value.setValue(data.travellers[key].service_charge.supplier['sc1_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC1Amount.setValue(data.travellers[key].service_charge.supplier['sc1_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC2Type.setValue(data.travellers[key].service_charge.supplier['sc2_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC2Value.setValue(data.travellers[key].service_charge.supplier['sc2_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC2Amount.setValue(data.travellers[key].service_charge.supplier['sc2_amount'])

				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC3Type.setValue(data.travellers[key].service_charge.supplier['sc3_type'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC3Value.setValue(data.travellers[key].service_charge.supplier['sc3_value'])
				this.travellerForm.controls.travellerList.controls[key].controls.txtSupplierSC3Amount.setValue(data.travellers[key].service_charge.supplier['sc3_amount'])


				if(data.travellers[key].dis_recall){
					// bind disc recall client
					this.travellerForm.controls.travellerList.controls[key].controls.disClientRecallType.setValue(data.travellers[key].dis_recall.customer['dis_recall_type'])
					this.travellerForm.controls.travellerList.controls[key].controls.disClientRecallValue.setValue(data.travellers[key].dis_recall.customer['dis_recall_value'])
					this.travellerForm.controls.travellerList.controls[key].controls.disClientRecallAmount.setValue(data.travellers[key].dis_recall.customer['dis_recall_amount'])

					// bind disc recall for supplier
					this.travellerForm.controls.travellerList.controls[key].controls.disSupplierRecallType.setValue(data.travellers[key].dis_recall.supplier['dis_recall_type'])
					this.travellerForm.controls.travellerList.controls[key].controls.disSupplierRecallValue.setValue(data.travellers[key].dis_recall.supplier['dis_recall_value'])
					this.travellerForm.controls.travellerList.controls[key].controls.disSupplierRecallAmount.setValue(data.travellers[key].dis_recall.supplier['dis_recall_amount'])

				}else{
					reqObj['date'] = data.travellers[key].date
					if(data.travellers[key].booking_supplier){
						reqObj['airlineCode'] = data.travellers[key].booking_supplier
					}
					let resp = await this.getDiscRecallSetting(reqObj,key)
				}
			


				// let travellerStatus = this.isCancelledFn(this.travellerForm.controls.travellerList.controls[key])
				// console.log(travellerStatus)
				//CALCULATE TOTALs
				this.calculateTotal(key)

			}
		}
		this.travellerForm.updateValueAndValidity()

		let sectorArray = [];
		for (let i = 0; i < data.sectors.length; i++) {
			if(data.sectors[i].departure_airport_details.code==''){
				let airportCity = data.sectors[i].departure_airport_details.city
				let airportData = await this.getAirport(airportCity,false)
				data.sectors[i].departure_airport_details.code = airportData['airport_CODE']
			}
			if(data.sectors[i].arrival_airport_details.code==''){
				let airportCity = data.sectors[i].arrival_airport_details.city
				let airportData = await this.getAirport(airportCity,false)
				data.sectors[i].arrival_airport_details.code = airportData['airport_CODE']
			}
			sectorArray.push(data.sectors[i].departure_airport_details.code);
			sectorArray.push(data.sectors[i].arrival_airport_details.code);
		}
		let uniqueSector = this.onlyUnique(sectorArray)
		let sectorComb1 = uniqueSector.join('/');

		this.bindSector1(sectorComb1,true, null);
		if (data.sectorComb1 != undefined && data.sectorComb1.length > 0) {
			this.sectorForm.controls.txtSector1.setValue(data.sectorComb1);
		}
		if (data.sectorComb2 != null && data.sectorComb2 != '') {
			this.sectorForm.controls.txtSector2.setValue(data.sectorComb2);
		}

		for (const key in data.travellers) {
			if (data.travellers.hasOwnProperty(key)) {
				const travellerData = data.travellers[key];
				const travellerControl = this.travellerList.controls[key];
				let sectorList  = travellerControl.get('sectorList');
				for (const sectorKey in sectorList['controls']) {
					if (sectorList['controls'].hasOwnProperty(sectorKey)) {
						const sectorSsrData = travellerData.ssr[sectorKey];
						if (sectorSsrData) {
							const sectorControl = sectorList['controls'][sectorKey];
							sectorControl.controls.txtSeatCharges.setValue(sectorSsrData.seat.amount)
							sectorControl.controls.txtSeatCode.setValue(sectorSsrData.seat.code)

							sectorControl.controls.txtMealCharges.setValue(sectorSsrData.meal.amount)
							sectorControl.controls.txtMealCode.setValue(sectorSsrData.meal.code)

							sectorControl.controls.txtBagCharges.setValue(sectorSsrData.baggage.amount)
							sectorControl.controls.txtBagCode.setValue(sectorSsrData.baggage.code)

							sectorControl.controls.txtAdditionalBaggageCharges.setValue(sectorSsrData.additionalBaggage ? sectorSsrData.additionalBaggage.amount : 0)
							sectorControl.controls.txtAdditionalBaggageCode.setValue(sectorSsrData.additionalBaggage ? sectorSsrData.additionalBaggage.code : 0)

							sectorControl.controls.txtAncillaryCharges.setValue(sectorSsrData.ancillary ? sectorSsrData.ancillary.amount : 0)
							sectorControl.controls.txtAncillaryCode.setValue(sectorSsrData.ancillary ? sectorSsrData.ancillary.code : 0)
						}
					}
				}
				this.calculateTotal(key)
			}
		}
		
		for (const key in data.sectors) {

			if (data.sectors.hasOwnProperty(key)) {
				const sector = data.sectors[key];
				let sectorName = `${sector.departure_airport_details.code}/${sector.arrival_airport_details.code}`
				let index = key
				if (this.sectorForm.controls.sectorlist.controls[key].value.txtSectorComb != sectorName) {
					this.sectorForm.controls.sectorlist.removeAt(key)
					// index = (Number(key)+1).toString()
					this.removeSectorFromTravellers(key)
				}
				this.invoiceForm.controls['pnr'].setValue(sector.pnr)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtAirlinePnrX.setValue(sector.legs.airline_pnr ? sector.legs.airline_pnr : sector.pnr)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtAirlinePrefix.setValue(sector.legs.airline_code)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtFlightNo.setValue(sector.legs.flight_no)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtClass.setValue(sector.legs.service_class)
				this.sectorForm.controls.sectorlist.controls[index].controls.dateAirlineDepartInfo.setValue(moment(sector.legs.departure_airport_details.date_time).format('YYYY-MM-DDTHH:mm'))
				this.sectorForm.controls.sectorlist.controls[index].controls.dateAirlineArrivalInfo.setValue(moment(sector.legs.arrival_airport_details.date_time).format('YYYY-MM-DDTHH:mm'))
				this.sectorForm.controls.sectorlist.controls[index].controls.AirlineDepartDate.setValue(moment(sector.legs.departure_airport_details.date_time).format('YYYY-MM-DD'))
				this.sectorForm.controls.sectorlist.controls[index].controls.AirlineArrivalDate.setValue(moment(sector.legs.arrival_airport_details.date_time).format('YYYY-MM-DD'))
				this.sectorForm.controls.sectorlist.controls[index].controls.AirlineDepartTime.setValue(moment(sector.legs.departure_airport_details.date_time).format('HH:mm'))
				this.sectorForm.controls.sectorlist.controls[index].controls.AirlineArrivalTime.setValue(moment(sector.legs.arrival_airport_details.date_time).format('HH:mm'))
				let checkinBaggage = sector?.legs?.baggage_info?.checkin_baggabe;
				if (checkinBaggage && checkinBaggage.unit) {
					checkinBaggage = checkinBaggage.unit
				}
				this.sectorForm.controls.sectorlist.controls[index].controls.txtBaggage.setValue(checkinBaggage)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtDepartureTerminal.setValue(sector.departure_airport_details.terminal)
				this.sectorForm.controls.sectorlist.controls[index].controls.txtArrivalTerminal.setValue(sector.arrival_airport_details.terminal)

				//SET AIRPORT INFO 
				let departInfo = sector.departure_airport_details['city'] + ', ' + sector.departure_airport_details['name']
				let arrivalinfo = sector.arrival_airport_details['city'] + ', ' + sector.arrival_airport_details['name']

				this.sectorForm.controls.sectorlist.controls[key].controls.txtAirlineDepartInfo.setValue(departInfo)
				this.sectorForm.controls.sectorlist.controls[key].controls.txtAirlineArrivalInfo.setValue(arrivalinfo)

			}
		}

		// binding gst detail
		this.invoiceForm.controls.gstDetail.controls.companyName.setValue(data && data.gstDetail && data.gstDetail.companyName?data.gstDetail.companyName:"")
		this.invoiceForm.controls.gstDetail.controls.email.setValue(data && data.gstDetail && data.gstDetail.email?data.gstDetail.email:"")
		this.invoiceForm.controls.gstDetail.controls.gstNumber.setValue(data && data.gstDetail && data.gstDetail.gstNumber?data.gstDetail.gstNumber:"")
		this.invoiceForm.controls.gstDetail.controls.phone.setValue(data && data.gstDetail && data.gstDetail.phone?data.gstDetail.phone:"")


		let obj = {
			type : 'invoice',
			index : 0
 		}
		this.componentMethodCallSource.next(obj);
		return ""

	}

	async updateTravellersValidators(controlName,type,value){
		let travellersControls = this.travellerForm.controls.travellerList.controls
		for (const key in travellersControls) {
			if (travellersControls.hasOwnProperty(key)) {
				var traveller = travellersControls[key];
				if (traveller.controls[controlName] != undefined) {
					if (type == 'required') {
						traveller.controls[controlName].setValidators(Validators.required)
						traveller.controls[controlName].updateValueAndValidity()
					}else{
						traveller.controls[controlName].setValidators(null)
						traveller.controls[controlName].updateValueAndValidity()
					}
					if(value == 'B'){
						traveller.controls['txtbookingSupplier'].setValue('BSP')
						traveller.controls['txtbookingSupplierCode'].setValue('799')
					}else{
						traveller.controls['txtbookingSupplier'].setValue("")
						traveller.controls['txtbookingSupplierCode'].setValue('')
					}
					this.travellerForm.updateValueAndValidity()
					let reqObj = {
						clientCode : this.affiliate_code,
						airlineCode : traveller.controls['txtbookingSupplierCode'].value ?  traveller.controls['txtbookingSupplierCode'].value  : this.invoiceForm.controls.airline.value 
					}
					let resp = await this.getDiscRecallSetting(reqObj,key)
				}
				
			}
		}
		let obj = {
			type : 'invoice',
			index : 0
 		}
		this.componentMethodCallSource.next(obj);
		let status = {
			status : this.travellerForm.status,
			type : type,
			controlName : controlName
		}
		return status
	}

	isCancelledFn(traveller){
		let returnClass = "not_cancelled"
		let isCancelled = this.cancelledTicket.filter(option => {
			return option.ticket_no == traveller.value.txtTicketNumber;
		});
		if (isCancelled.length > 0) {
			if (traveller.value.sectorList.length > 0) {
				let sectorList = traveller.controls.sectorList.controls
				for (const key in sectorList) {
					if (sectorList.hasOwnProperty(key)) {
						const sector = sectorList[key];
						for (const cancelledKey in isCancelled) {
							if (isCancelled.hasOwnProperty(cancelledKey)) {
								const cancelledEl = isCancelled[cancelledKey];
								if (traveller.value.sectorList.length == cancelledEl.sectors.length) {
									returnClass = `full_cancelled`
								}else{
									returnClass = `partial_cancelled`
								}
								for (const sectorKey in cancelledEl.sectors) {
									if (cancelledEl.sectors.hasOwnProperty(sectorKey)) {
										const cancelledSector = cancelledEl.sectors[sectorKey];
										if (sector.value.sector == cancelledSector) {
											sector.controls.isCancelled.setValue(true)
										}
									}
								}
							}
						}
					}
				}
			}
		}
		return returnClass
	}


	async bindCreditNoteData(data, i) {  //Depricated
		this.invoiceTravellerForm[i] = this.fb.group({
			travellerList: this.fb.array([]),
		});
		this.creditTravellerList[i] = this.invoiceTravellerForm[i].get("travellerList") as FormArray;
		this.creditTravellerList[i].controls = [];
		for (const key in data.travellers) {
			if (data.travellers.hasOwnProperty(key)) {
				this.creditTravellerList[i].push(this.buildTraveller(data.travellers[key]));

				//SET VALUE
				let cancelledObj = {
					ticket_no : data.travellers[key]['ticket_no'],
					sectors : data.travellers[key].cancel_sectors
				}
				this.cancelledTicket.push(cancelledObj)
				
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selTitle.setValue(data.travellers[key].title)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selType.setValue(data.travellers[key].type)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtPassengerName.setValue(data.travellers[key].name)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtEmployeeName.setValue(data.travellers[key].employee?data.travellers[key].employee:'')
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtEmployeeCode.setValue(data.travellers[key].e_code?data.travellers[key].e_code:'')
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtEmployeeCostCenter.setValue(data.travellers[key].cost_center?data.travellers[key].cost_center:'')
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierCode.setValue(data.travellers[key].payable_transaction_code?data.travellers[key].payable_transaction_code:'')
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.supplierDate.setValue(data.travellers[key].payable_date?data.travellers[key].payable_date:'')
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtbookingSupplier.setValue(data.travellers[key].booking_supplier)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.ledger_card_no.setValue(data.travellers[key].ledger_card_no)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.ledger_card_name.setValue(data.travellers[key].ledger_card_name)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.type_of_pay.setValue(data.travellers[key].pay_type ? data.travellers[key].pay_type : '')
				try {
					let selectedSupplier = this.supplierList.filter(option => {
						return option.account_code == data.travellers[key].booking_supplier;
					});
					if (selectedSupplier.length > 0) {
						this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtbookingSupplierName.setValue(selectedSupplier[0].name)
					}
				} catch (error) {

				}
				

				// //SET FARE VALUE
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtBaseFare.setValue(data.travellers[key]['fare'].base)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtYQ.setValue(data.travellers[key]['fare'].YQ)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtYR.setValue(data.travellers[key]['fare'].YR)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtK3.setValue(data.travellers[key]['fare'].K3)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtOC.setValue(data.travellers[key]['fare'].OC)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtOT.setValue(data.travellers[key]['fare'].OT)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtTicketNumber.setValue(data.travellers[key]['ticket_no'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.isConjuction.setValue(data.travellers[key]['isConjuction'])


				// Bind Client Commission 
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientIataType.setValue(data.travellers[key].commission.customer['iata_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientIataValue.setValue(data.travellers[key].commission.customer['iata_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientIataAmount.setValue(data.travellers[key].commission.customer['iata_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientPlbType.setValue(data.travellers[key].commission.customer['plb_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientPlbValue.setValue(data.travellers[key].commission.customer['plb_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientPlbAmount.setValue(data.travellers[key].commission.customer['plb_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientCbType.setValue(data.travellers[key].commission.customer['cashback_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientCbValue.setValue(data.travellers[key].commission.customer['cashback_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientCbAmount.setValue(data.travellers[key].commission.customer['cashback_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientDsType.setValue(data.travellers[key].commission.customer['discount_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientDsValue.setValue(data.travellers[key].commission.customer['discount_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientDsAmount.setValue(data.travellers[key].commission.customer['discount_amount'])

				// Bind Client Service Charge 

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC1Type.setValue(data.travellers[key].service_charge.customer['sc1_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC1Value.setValue(data.travellers[key].service_charge.customer['sc1_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC1Amount.setValue(data.travellers[key].service_charge.customer['sc1_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC2Type.setValue(data.travellers[key].service_charge.customer['sc2_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC2Taxable.setValue(data.travellers[key].service_charge.customer['sc2_taxable'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC2Value.setValue(data.travellers[key].service_charge.customer['sc2_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC2Amount.setValue(data.travellers[key].service_charge.customer['sc2_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC3Type.setValue(data.travellers[key].service_charge.customer['sc3_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC3Taxable.setValue(data.travellers[key].service_charge.customer['sc3_taxable'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC3Value.setValue(data.travellers[key].service_charge.customer['sc3_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC3Amount.setValue(data.travellers[key].service_charge.customer['sc3_amount'])

				//Bind Client RAF and XXL Charges 
				let rafAmountClient = data.travellers[key].service_charge.customer['raf'] ? data.travellers[key].service_charge.customer['raf'] : 0
				let xxlAmountClient = data.travellers[key].service_charge.customer['xxl'] ? data.travellers[key].service_charge.customer['xxl'] : 0
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientRAFAmount.setValue(rafAmountClient)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientXXLAmount.setValue(xxlAmountClient)





				// Bind Supplier Commission 
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierIataType.setValue(data.travellers[key].commission.supplier['iata_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierIataValue.setValue(data.travellers[key].commission.supplier['iata_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierIataAmount.setValue(data.travellers[key].commission.supplier['iata_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierCbType.setValue(data.travellers[key].commission.supplier['cashback_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierCbValue.setValue(data.travellers[key].commission.supplier['cashback_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierCbAmount.setValue(data.travellers[key].commission.supplier['cashback_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierPlbType.setValue(data.travellers[key].commission.supplier['plb_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierPlbValue.setValue(data.travellers[key].commission.supplier['plb_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierPlbAmount.setValue(data.travellers[key].commission.supplier['plb_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierDsType.setValue(data.travellers[key].commission.supplier['discount_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierDsValue.setValue(data.travellers[key].commission.supplier['discount_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierDsAmount.setValue(data.travellers[key].commission.supplier['discount_amount'])

				// Bind Supplier Service Charge 

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC1Type.setValue(data.travellers[key].service_charge.supplier['sc1_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC1Value.setValue(data.travellers[key].service_charge.supplier['sc1_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC1Amount.setValue(data.travellers[key].service_charge.supplier['sc1_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC2Type.setValue(data.travellers[key].service_charge.supplier['sc2_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC2Value.setValue(data.travellers[key].service_charge.supplier['sc2_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC2Amount.setValue(data.travellers[key].service_charge.supplier['sc2_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC3Type.setValue(data.travellers[key].service_charge.supplier['sc3_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC3Value.setValue(data.travellers[key].service_charge.supplier['sc3_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC3Amount.setValue(data.travellers[key].service_charge.supplier['sc3_amount'])

				//Bind Supplier RAF and XXL Charges
				let rafAmountSupplier = data.travellers[key].service_charge.supplier['raf'] ? data.travellers[key].service_charge.supplier['raf'] : 0
				let xxlAmountSupplier = data.travellers[key].service_charge.supplier['xxl'] ? data.travellers[key].service_charge.supplier['xxl'] : 0
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierRAFAmount.setValue(rafAmountSupplier)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierXXLAmount.setValue(xxlAmountSupplier)



				if(data.travellers[key].dis_recall){
					// bind disc recall client
					this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.disClientRecallType.setValue(data.travellers[key].dis_recall.customer['dis_recall_type'])
					this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.disClientRecallValue.setValue(data.travellers[key].dis_recall.customer['dis_recall_value'])
					this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.disClientRecallAmount.setValue(data.travellers[key].dis_recall.customer['dis_recall_amount'])

					// bind disc recall for supplier
					this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.disSupplierRecallType.setValue(data.travellers[key].dis_recall.supplier['dis_recall_type'])
					this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.disSupplierRecallValue.setValue(data.travellers[key].dis_recall.supplier['dis_recall_value'])
					this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.disSupplierRecallAmount.setValue(data.travellers[key].dis_recall.supplier['dis_recall_amount'])

				}
				
			}
		}
		this.bindTravellerStatus()
		// console.log('Invoice traveller form : ', this.invoiceTravellerForm[i].value)
		this.componentMethodCallSource.next(Number(i) + 1);
	}
	async bindCreditNoteDataV2(data, i,transaction_type) {
		this.invoiceTravellerForm[i] = this.fb.group({
			travellerList: this.fb.array([]),
		});
		this.creditTravellerList[i] = this.invoiceTravellerForm[i].get("travellerList") as FormArray;
		this.creditTravellerList[i].controls = [];
		for (const key in data.travellers) {
			if (data.travellers.hasOwnProperty(key)) {
				this.creditTravellerList[i].push(this.buildTraveller(data.travellers[key]));

				//SET VALUE
				if (transaction_type == 'credit_note') {
					let cancelledObj = {
						ticket_no : data.travellers[key]['ticket_no'],
						sectors : data.travellers[key].cancel_sectors
					}
					this.cancelledTicket.push(cancelledObj)
				}
				
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selTitle.setValue(data.travellers[key].title)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selType.setValue(data.travellers[key].type)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtPassengerName.setValue(data.travellers[key].name)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtEmployeeName.setValue(data.travellers[key].employee?data.travellers[key].employee:'')
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtEmployeeCode.setValue(data.travellers[key].e_code?data.travellers[key].e_code:'')
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtEmployeeCostCenter.setValue(data.travellers[key].cost_center?data.travellers[key].cost_center:'')
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierCode.setValue(data.travellers[key].payable_transaction_code?data.travellers[key].payable_transaction_code:'')
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.supplierDate.setValue(data.travellers[key].payable_date?data.travellers[key].payable_date:'')
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtbookingSupplier.setValue(data.travellers[key].booking_supplier)
				try {
					let selectedSupplier = this.supplierList.filter(option => {
						return option.account_code == data.travellers[key].booking_supplier;
					});
					if (selectedSupplier.length > 0) {
						this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtbookingSupplierName.setValue(selectedSupplier[0].name)
					}
				} catch (error) {

				}
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.ledger_card_no.setValue(data.travellers[key].ledger_card_no)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.ledger_card_name.setValue(data.travellers[key].ledger_card_name)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.type_of_pay.setValue(data.travellers[key].pay_type ? data.travellers[key].pay_type : '')

				// //SET FARE VALUE
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtBaseFare.setValue(data.travellers[key]['fare'].base)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtYQ.setValue(data.travellers[key]['fare'].YQ)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtYR.setValue(data.travellers[key]['fare'].YR)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtK3.setValue(data.travellers[key]['fare'].K3)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtOC.setValue(data.travellers[key]['fare'].OC)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtOT.setValue(data.travellers[key]['fare'].OT)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtAddons.setValue(data.travellers[key]['fare'].addons)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtTicketNumber.setValue(data.travellers[key]['ticket_no'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.isConjuction.setValue(data.travellers[key]['isConjuction'])


				// Bind Client Commission 
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientIataType.setValue(data.travellers[key].commission.customer['iata_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientIataValue.setValue(data.travellers[key].commission.customer['iata_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientIataAmount.setValue(data.travellers[key].commission.customer['iata_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientPlbType.setValue(data.travellers[key].commission.customer['plb_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientPlbValue.setValue(data.travellers[key].commission.customer['plb_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientPlbAmount.setValue(data.travellers[key].commission.customer['plb_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientCbType.setValue(data.travellers[key].commission.customer['cashback_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientCbValue.setValue(data.travellers[key].commission.customer['cashback_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientCbAmount.setValue(data.travellers[key].commission.customer['cashback_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientDsType.setValue(data.travellers[key].commission.customer['discount_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientDsValue.setValue(data.travellers[key].commission.customer['discount_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selClientDsAmount.setValue(data.travellers[key].commission.customer['discount_amount'])

				// Bind Client Service Charge 

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC1Type.setValue(data.travellers[key].service_charge.customer['sc1_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC1Value.setValue(data.travellers[key].service_charge.customer['sc1_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC1Amount.setValue(data.travellers[key].service_charge.customer['sc1_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC2Type.setValue(data.travellers[key].service_charge.customer['sc2_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC2Taxable.setValue(data.travellers[key].service_charge.customer['sc2_taxable'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC2Value.setValue(data.travellers[key].service_charge.customer['sc2_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC2Amount.setValue(data.travellers[key].service_charge.customer['sc2_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC3Type.setValue(data.travellers[key].service_charge.customer['sc3_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC3Taxable.setValue(data.travellers[key].service_charge.customer['sc3_taxable'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC3Value.setValue(data.travellers[key].service_charge.customer['sc3_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientSC3Amount.setValue(data.travellers[key].service_charge.customer['sc3_amount'])

				//Bind Client RAF and XXL Charges 
				let rafAmountClient = data.travellers[key].service_charge.customer['raf'] ? data.travellers[key].service_charge.customer['raf'] : 0
				let xxlAmountClient = data.travellers[key].service_charge.customer['xxl'] ? data.travellers[key].service_charge.customer['xxl'] : 0
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientRAFAmount.setValue(rafAmountClient)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtClientXXLAmount.setValue(xxlAmountClient)





				// Bind Supplier Commission 
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierIataType.setValue(data.travellers[key].commission.supplier['iata_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierIataValue.setValue(data.travellers[key].commission.supplier['iata_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierIataAmount.setValue(data.travellers[key].commission.supplier['iata_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierCbType.setValue(data.travellers[key].commission.supplier['cashback_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierCbValue.setValue(data.travellers[key].commission.supplier['cashback_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierCbAmount.setValue(data.travellers[key].commission.supplier['cashback_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierPlbType.setValue(data.travellers[key].commission.supplier['plb_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierPlbValue.setValue(data.travellers[key].commission.supplier['plb_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierPlbAmount.setValue(data.travellers[key].commission.supplier['plb_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierDsType.setValue(data.travellers[key].commission.supplier['discount_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierDsValue.setValue(data.travellers[key].commission.supplier['discount_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.selSupplierDsAmount.setValue(data.travellers[key].commission.supplier['discount_amount'])
				// Bind Supplier Service Charge 

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC1Type.setValue(data.travellers[key].service_charge.supplier['sc1_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC1Value.setValue(data.travellers[key].service_charge.supplier['sc1_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC1Amount.setValue(data.travellers[key].service_charge.supplier['sc1_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC2Type.setValue(data.travellers[key].service_charge.supplier['sc2_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC2Value.setValue(data.travellers[key].service_charge.supplier['sc2_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC2Amount.setValue(data.travellers[key].service_charge.supplier['sc2_amount'])

				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC3Type.setValue(data.travellers[key].service_charge.supplier['sc3_type'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC3Value.setValue(data.travellers[key].service_charge.supplier['sc3_value'])
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierSC3Amount.setValue(data.travellers[key].service_charge.supplier['sc3_amount'])

				//Bind Supplier RAF and XXL Charges
				let rafAmountSupplier = data.travellers[key].service_charge.supplier['raf'] ? data.travellers[key].service_charge.supplier['raf'] : 0
				let xxlAmountSupplier = data.travellers[key].service_charge.supplier['xxl'] ? data.travellers[key].service_charge.supplier['xxl'] : 0
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierRAFAmount.setValue(rafAmountSupplier)
				this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.txtSupplierXXLAmount.setValue(xxlAmountSupplier)

				if(data.travellers[key].dis_recall){
					// bind disc recall client
					this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.disClientRecallType.setValue(data.travellers[key].dis_recall.customer['dis_recall_type'])
					this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.disClientRecallValue.setValue(data.travellers[key].dis_recall.customer['dis_recall_value'])
					this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.disClientRecallAmount.setValue(data.travellers[key].dis_recall.customer['dis_recall_amount'])

					// bind disc recall for supplier
					this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.disSupplierRecallType.setValue(data.travellers[key].dis_recall.supplier['dis_recall_type'])
					this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.disSupplierRecallValue.setValue(data.travellers[key].dis_recall.supplier['dis_recall_value'])
					this.invoiceTravellerForm[i].controls.travellerList.controls[key].controls.disSupplierRecallAmount.setValue(data.travellers[key].dis_recall.supplier['dis_recall_amount'])

				}
				
			}
		}
		let sectorArray = [];
		for (let i = 0; i < data.sectors.length; i++) {
			sectorArray.push(data.sectors[i].departure_airport_details.code);
			sectorArray.push(data.sectors[i].arrival_airport_details.code);
		}

		let uniqueSector = this.onlyUnique(sectorArray)
		let sectorComb1 = uniqueSector.join('/');

		await this.bindInvoiceSector1(sectorComb1,i);
		this.bindTravellerStatus()
		// console.log('Invoice traveller form : ', this.invoiceTravellerForm[i].value)
		let obj = {
			type : transaction_type,
			index : Number(i) + 1
 		}
		this.componentMethodCallSource.next(obj);
		// this.componentMethodCallSource.next(Number(i) + 1);
	}

	async bindInvoiceSector1(value,index) {
		let sectorValue = value;
		let currentSectorArray = sectorValue.split("/");
		let currentVal = currentSectorArray.join("");
		if (currentVal.match(/.{1,3}/g) != null) {
			if (currentSectorArray.length > 0) {
				let lastvalueOfCurrentSector = currentSectorArray[currentSectorArray.length - 1];
				if (lastvalueOfCurrentSector.length == 3) {
					let travellerList = this.invoiceTravellerForm[index].get("travellerList") as FormArray;
					this.totalIndexInTraveller = 0
					for (let i = 0; i < travellerList.length; i++) {
						let sectorList = this.invoiceTravellerForm[index].get("travellerList").controls[i].get('sectorList');
						sectorList.controls = [];
						for (let i = 0; i < currentSectorArray.length - 1; i++) {
							let customSector = currentSectorArray[i] + "/" + currentSectorArray[i + 1];
							if (customSector.length > 6) {
								const element = customSector;
								sectorList.push(this.buildTravellerSector(element))
							}
						}
					}
				}
			}
		}
	}

	bindTravellerStatus(){
		for (let i = 0; i < this.travellerList.length; i++) {
			let travellerStatus = this.isCancelledFn(this.travellerForm.get("travellerList").controls[i])
			this.travellerForm.get("travellerList").controls[i].controls.travellerStatus.setValue(travellerStatus)
		}
	}

	removeSectorFromTravellers(sectorIndex) {
		for (let i = 0; i < this.travellerList.length; i++) {
			this.travellerForm.get("travellerList").controls[i].get('sectorList').removeAt(sectorIndex)
		}
	}
	
	calculateServiceChargeFlightWise(nestedFormValue) {
		let flightType = nestedFormValue.flightType;
		let currentClassType = this.invoiceForm.controls.classType.value;

		let serviceChargeAmount = 0;

		if(flightType && currentClassType) {
			if(flightType == 'domestic') {
				if(currentClassType == 'economy') {
					serviceChargeAmount = this.getClientConfigurationAmount("domestic_economy_ppps")
				} else if(currentClassType == 'business') {
					serviceChargeAmount = this.getClientConfigurationAmount("domestic_business_ppps")
				}
			} else if(flightType == 'international') {
				if(currentClassType == 'economy') {
					serviceChargeAmount = this.getClientConfigurationAmount("international_economy_ppps")
				} else if(currentClassType == 'business') {
					serviceChargeAmount = this.getClientConfigurationAmount("international_business_ppps")
				} else if(currentClassType == 'first') {
					serviceChargeAmount = this.getClientConfigurationAmount("international_first")
				}
			}
			let numberSC = Number(serviceChargeAmount ? serviceChargeAmount : '');
			this.clientServiceCharge = numberSC;
	
			let travellers = this.travellerForm.get("travellerList");
			let sectorListLength = 1;
			if(this.DynamicFormControls.controls && this.DynamicFormControls.controls.length>1) {
				sectorListLength = this.DynamicFormControls.controls.length;
			}
			
			if(travellers && travellers.controls.length>0) {
				for(let item of travellers.controls) {
					if(sectorListLength) {
						let previousServiceCharge = item.controls.txtClientSC1Value.value;
						if(!previousServiceCharge || Number(previousServiceCharge) == 0) {
							let totalSectorAmount = sectorListLength * Number(this.clientServiceCharge) 
							item.controls.txtClientSC1Value.setValue(totalSectorAmount)
							item.controls.txtClientSC1Amount.setValue(totalSectorAmount);
						}
					}
				}
			}
			return numberSC;
		}
		


		
	}

	getClientConfigurationAmount(key) {
		let value = 0;
		if(key && this.clientConfiguration && this.clientConfiguration.length>0){
			let selectedItem = this.clientConfiguration.find(item => item.key == key)
			if(selectedItem && selectedItem.value) {
				value = Number(selectedItem.value);
			}
		}
		return value;
	}

	getLocalAddonsTotal(sectors) {
		let totalAmount = 0
		for (const key in sectors) {
			if (sectors.hasOwnProperty(key)) {
				const element = sectors[key];
				if (element['txtSeatCharges'] != "") {
					totalAmount += Number(element['txtSeatCharges']) 
				}
				if (element['txtMealCharges'] != "") {
					totalAmount += Number(element['txtMealCharges'])
				}
				if (element['txtBagCharges'] != "") {
					totalAmount += Number(element['txtBagCharges'])
				}
				if (element['txtAdditionalBaggageCharges'] != "") {
					totalAmount += Number(element['txtAdditionalBaggageCharges'])
				}
				if (element['txtAncillaryCharges'] != "") {
					totalAmount += Number(element['txtAncillaryCharges'])
				}
			}
		}
		return totalAmount
	}

	getInvoiceRootvalue(data){
		this.setInvoiceSourceData.next(data);
	}

}

 