import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TourfileComponent } from './tourfile.component';
import { CreateTourfileComponent } from './create-tourfile/create-tourfile.component';
import { UpdateTourfileComponent } from './update-tourfile/update-tourfile.component';
import { ViewTourfileComponent } from './view-tourfile/view-tourfile.component';

const routes: Routes = [
  {
		path: '', component: TourfileComponent,
		children: [
			{ path: 'create-tour', component: CreateTourfileComponent },
			{ path: 'view-tour', component: ViewTourfileComponent },
			{ path: 'update-tour/:id', component: UpdateTourfileComponent },
      		{
				path: '',
				redirectTo: 'create-tour',
				pathMatch: 'full'
			}
		]
	},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TourfileRoutingModule { }
