<div class="total-details mt-4">
	<table class="table table-hovered table-bordered">
		<tr>
			<th></th>
			<th>FARE BREAKUP</th>
			<th>SUPPLIER</th>
			<th>CLIENT</th>

		</tr>
		<tr >
            <td>
				<table class="w-100">
					<tr>
						<th>Reference</th>
					</tr>
					<tr *ngFor="let invoice of sectorService.fareList let i = index">
						<td class="text-right">
							<a class="hyperLink" *ngIf="invoice.invoice_no != undefined" (click)="viewPrintScreen(invoice.invoice_no,i)">{{invoice.invoice_no}} 
								<ng-container *ngIf="invoice.type == 'reissue'">(R)</ng-container>
								<ng-container *ngIf="invoice.type == 'split'">(S)</ng-container>
							</a>
							<a class="hyperLink" *ngIf="invoice.invoice_no == undefined" (click)="viewPrintScreen(invoice,i)">{{invoice}} <ng-container *ngIf="invoice.type == 'reissue'">(R)</ng-container></a>
						</td>
					</tr>
				</table>

			</td>
			<td class="inner-supplier-table">
				<table class="w-100">
					<tr>
						<th>Base</th>
						<th>YQ</th>
						<th>YR</th>
						<th>K3</th>
						<th>OC</th>
						<th>OT</th>
						<th>SSR</th>
					</tr>
					<tr *ngFor="let invoice_no of sectorService.fareList let i = index">
						<td class="text-right">{{sectorService?.totalFareModel[i]?.base | number : '1.2-2'}}</td>
						<td class="text-right">{{sectorService?.totalFareModel[i]?.yq | number : '1.2-2'}}</td>
						<td class="text-right">{{sectorService?.totalFareModel[i]?.yr | number : '1.2-2'}}</td>
						<td class="text-right">{{sectorService?.totalFareModel[i]?.k3 | number : '1.2-2'}}</td>
						<td class="text-right">{{sectorService?.totalFareModel[i]?.oc | number : '1.2-2'}}</td>
						<td class="text-right">{{sectorService?.totalFareModel[i]?.ot | number : '1.2-2'}}</td>
						<td class="text-right">{{sectorService?.totalFareModel[i]?.ssr | number : '1.2-2'}}</td>
					</tr>
				</table>

			</td>
			<td class="inner-supplier-table">
				<table class="w-100">
					<tr>
						<th>TDS</th>
						<th *ngIf="isTenantIndian">GST</th>
						<th *ngIf="!isTenantIndian">{{vatAlternetname}}</th>
						<th>Comm.</th>
						<th *ngIf="discount_add=='true'">Discount</th>
						<th>SC.N</th>
                        <th>SC.T</th>
						<th>XXL</th>
						<th>RAF</th>
						<th  *ngIf="isTenantIndian">GST (RAF)</th>
						<th  *ngIf="!isTenantIndian">{{vatAlternetname}} (RAF)</th>
						<th>NET AMT</th>
					</tr>
					<tr *ngFor="let invoice of sectorService.fareList let i = index">
						<td class="text-right">{{sectorService?.totalFareModel[i]?.tds_paid | number : '1.2-2'}}</td>
						<td class="text-right" *ngIf="isTenantIndian">{{sectorService?.totalFareModel[i]?.gst_paid | number : '1.2-2'}}</td>
						<td class="text-right" *ngIf="!isTenantIndian">{{sectorService?.totalFareModel[i]?.vat_paid | number : '1.2-2'}}</td>
						<td class="text-right">{{sectorService?.totalFareModel[i]?.commission_received | number : '1.2-2'}}</td>
						<td *ngIf="discount_add=='true'" class="text-right">{{sectorService?.totalFareModel[i]?.discount_received | number : '1.2-2'}}</td>
						<td class="text-right">{{sectorService?.totalFareModel[i]?.supplier_normal_service_charges | number : '1.2-2'}}</td> 
						<td class="text-right">{{sectorService?.totalFareModel[i]?.supplier_service_charges | number : '1.2-2'}}</td> 
						<td class="text-right">{{sectorService?.totalFareModel[i]?.supplier_xxl | number : '1.2-2'}}</td>
						<td class="text-right">{{sectorService?.totalFareModel[i]?.supplier_raf | number : '1.2-2'}}</td>
						<td class="text-right" *ngIf="isTenantIndian" >{{sectorService?.totalFareModel[i]?.supplier_raf_gst | number : '1.2-2'}}</td>
						<td class="text-right" *ngIf="!isTenantIndian" >{{sectorService?.totalFareModel[i]?.supplier_raf_vat | number : '1.2-2'}}</td>
						<!-- if invoice -->
						<td *ngIf="invoice.invoice_no && ( invoice.invoice_no.toString().includes('INV') || 
							invoice.invoice_no.toString().includes('IW') || invoice.invoice_no.toString().includes('BPI')  )" class="text-right">{{sectorService?.totalFareModel[i]?.net_paid | number : '1.2-2'}}</td>
						<!-- if credit Note -->
						<td *ngIf="(invoice.invoice_no && (invoice.invoice_no.toString().includes('CRN') || invoice.invoice_no.toString().includes('IZ') || invoice.invoice_no.toString() == 'Credit Note')) || invoice.toString() == 'Credit Note' " class="text-right">{{sectorService?.totalFareModel[i]?.net_received | number : '1.2-2'}}</td>
					</tr>
				</table>

			</td>
			<td>
				<table class="w-100">
					<tr>
						<th>TDS</th>
						<th *ngIf="isTenantIndian">GST</th>
						<th *ngIf="!isTenantIndian">{{vatAlternetname}}</th>
						<th>Comm.</th>
						<th *ngIf="discount_add=='true'">Discount</th>
                        <th>SC.N</th>
                        <th>SC.T</th>
                        <th>XXL</th>
						<th>RAF</th>
						<th  *ngIf="isTenantIndian">GST (RAF)</th>
						<th  *ngIf="!isTenantIndian">{{vatAlternetname}} (RAF)</th>
						<th>R/O</th>
						<th>NET AMT</th>
					</tr>
					<tr *ngFor="let invoice of sectorService.fareList let i = index">
						<td class="text-right">{{sectorService?.totalFareModel[i]?.tds_recieved | number : '1.2-2'}}</td>
						<td class="text-right" *ngIf="isTenantIndian">{{sectorService?.totalFareModel[i]?.gst_recieved | number : '1.2-2'}}</td>
						<td class="text-right" *ngIf="!isTenantIndian">{{sectorService?.totalFareModel[i]?.vat_received | number : '1.2-2'}}</td>
						<td class="text-right">{{sectorService?.totalFareModel[i]?.commission_paid | number : '1.2-2'}}</td>
						<td *ngIf="discount_add=='true'" class="text-right">{{sectorService?.totalFareModel[i]?.discount_paid | number : '1.2-2'}}</td>
						<td class="text-right">{{sectorService?.totalFareModel[i]?.client_normal_service_charges | number : '1.2-2'}}</td> 
						<td class="text-right">{{sectorService?.totalFareModel[i]?.client_service_charges | number : '1.2-2'}}</td> 
                        <td class="text-right">{{sectorService?.totalFareModel[i]?.client_xxl | number : '1.2-2'}}</td>
						<td class="text-right">{{sectorService?.totalFareModel[i]?.client_raf | number : '1.2-2'}}</td>
						<td class="text-right" *ngIf="isTenantIndian">{{sectorService?.totalFareModel[i]?.client_raf_gst | number : '1.2-2'}}</td>
						<td class="text-right" *ngIf="!isTenantIndian">{{sectorService?.totalFareModel[i]?.client_raf_vat | number : '1.2-2'}}</td>
						<!-- <td class="text-right">{{sectorService?.totalFareModel[i]?.net_paid | number : '1.2-2'}}</td> -->
						<td class="text-right">{{sectorService?.totalFareModel[i]?.round_off | number : '1.2-2'}}</td>

						<!-- if invoice -->
						<td *ngIf="invoice.invoice_no && ( invoice.invoice_no.toString().includes('INV') || 
							invoice.invoice_no.toString().includes('IW') || invoice.invoice_no.toString().includes('BPI') )" class="text-right">{{sectorService?.totalFareModel[i]?.net_received | number : '1.2-2'}}</td>
						<!-- if credit Note -->
						<td *ngIf="(invoice.invoice_no && (invoice.invoice_no.toString().includes('CRN') || invoice.invoice_no.toString().includes('IZ') || invoice.invoice_no.toString() == 'Credit Note')) || invoice.toString() == 'Credit Note' " class="text-right">{{sectorService?.totalFareModel[i]?.net_paid | number : '1.2-2'}}</td>
					</tr>
				</table>

			</td>

		</tr>
	</table>
</div>