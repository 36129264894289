import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TourRoutingModule } from './tour-routing.module';
import { TourComponent } from './tour.component';
import { TourFareSummaryComponent } from './tour-fare-summary/tour-fare-summary.component';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { TourService } from './services/tour.service';
import { PrintTourInvoiceComponent } from './print-tour-invoice/print-tour-invoice.component';


@NgModule({
  declarations: [TourComponent, TourFareSummaryComponent],
  imports: [
    CommonModule,
    TourRoutingModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    FormsModule, 
    ReactiveFormsModule,
    KeyboardShortcutsModule.forRoot()
  ],
  exports: [TourComponent],
  providers : [CommonServiceService,TourService]
})
export class TourModule { }
