import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonServiceService } from '../../shared/common-service.service';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import * as moment from 'moment-timezone';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/app/transactions/payment/services/payment.service';
import { GlobalVariable } from '../../shared/global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';
@Component({
	selector: 'app-consolidate-invoice',
	templateUrl: './consolidate-invoice.component.html',
	styleUrls: ['./consolidate-invoice.component.scss']
})
export class ConsolidateInvoiceSearchComponent implements OnInit {

	consolidateInvoiceForm: FormGroup;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	minDate = "2023-04-01"
  maxDate = moment().format('YYYY-MM-DD')
  clientArr: any = []
  keyword = 'name';
  attachmentDropdownList = []
  dropdownSettings = {};
  consolidationType = ''

  constructor(
    public commonService: CommonServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private store: Store<InitialState>
    ) {
      this.route.paramMap.subscribe(params => {
        this.consolidationType = params['params'].consolidationType
      });
			this.store.select('sharedReducers').subscribe((items)=>{
        if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				} else {
					let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
					if(!current_financial_year) {
						current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
					}
					this.minDate = current_financial_year.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				}	  
			})
      
  }
  
  ngOnInit() {

    this.consolidateInvoiceForm = this.formBuilder.group({
      start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate,this.checkDate('end_date')],
      selectedMemoClient: [''],
      selectedMemoClientName: [''],
      selectedMainClient: [''],
      selectedMainClientName: [''],
      selected_transactions: ['',Validators.required]
      // include_flight: [true],
      // include_hotel: [true],
      // include_insurance: [true],
      // include_visa: [true],
      // include_rail: [true],
      // include_car: [true],
      // include_bus: [true],
      // include_tour: [true]
    })
   
    
    this.attachmentDropdownList = [
      { id: 'flight', name: 'flight' },
      { id: 'rail', name: 'rail' },
      { id: 'car', name: 'car' },
      { id: 'bus', name: 'bus' },
      {id:  'hotel', name: 'hotel'},
      {id: 'insurance', name: 'insurance'},
      {id: 'visa', name: 'visa'},
      {id: 'other', name: 'other'}
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.setFormData()
    this.consolidateInvoiceForm.controls.selectedClient.setValue("")
    this.consolidateInvoiceForm.controls.selected_transactions.setValue("")

  }

  checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}
  
  setFormData() {
    let queryParams = localStorage.getItem('consolidateInvoiceQueryParams')
    if (queryParams != null) {
      let parsedParams = JSON.parse(queryParams)
      
      if (parsedParams.start_date != "") {
        this.consolidateInvoiceForm.controls.start_date.setValue(moment(parsedParams.start_date).format('YYYY-MM-DD') )
      }
      if (parsedParams.end_date != "") {
        this.consolidateInvoiceForm.controls.end_date.setValue(moment(parsedParams.end_date).format('YYYY-MM-DD'))
      }
      if (parsedParams.selectedClient != "") {
        this.consolidateInvoiceForm.controls.selectedClient.setValue(parsedParams.selectedClient ? parsedParams.selectedClient : "")
      }
      this.consolidateInvoiceForm.controls.selected_transactions.setValue(parsedParams.selected_transactions?parsedParams.selected_transactions:'')

      // this.consolidateInvoiceForm.controls.include_flight.setValue(parsedParams.include_flight?parsedParams.include_flight:true)
      // this.consolidateInvoiceForm.controls.include_hotel.setValue(parsedParams.include_hotel?parsedParams.include_hotel:true)
      // this.consolidateInvoiceForm.controls.include_rail.setValue(parsedParams.include_rail?parsedParams.include_rail:true)
      // this.consolidateInvoiceForm.controls.include_insurance.setValue(parsedParams.include_insurance?parsedParams.include_insurance:true)
      // this.consolidateInvoiceForm.controls.include_car.setValue(parsedParams.include_car?parsedParams.include_car:true)
      // this.consolidateInvoiceForm.controls.include_bus.setValue(parsedParams.include_bus?parsedParams.include_bus:true)
      // this.consolidateInvoiceForm.controls.include_tour.setValue(parsedParams.include_tour?parsedParams.include_tour:true)
    }
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  selectAccount(value,formControlName) {
    if(value) {
      this.consolidateInvoiceForm.controls[`${formControlName}`].setValue(value.account_code ? value.account_code : "")
      this.consolidateInvoiceForm.controls[`${formControlName}Name`].setValue(value.name ? value.name : "")
    } else {
      this.consolidateInvoiceForm.controls[`${formControlName}`].setValue("")
      this.consolidateInvoiceForm.controls[`${formControlName}Name`].setValue("")
    }
  }


  search(values) {
    GlobalVariable.openModalStatus = false;
    if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
      values.start_date = ""
    }
    else {
      values.start_date = moment(values.start_date).format('YYYY-MM-DD')
    }
    if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date" || values.end_date == "Invalid date") {
      values.end_date = ""
    }
    else {
      values.end_date = moment(values.end_date).format('YYYY-MM-DD')
    }
    if(values.selectedMemoClient == null || values.selectedMemoClient == "") {
       values.selectedMemoClient = ""
    }
    if(values.selected_transactions==null || values.selected_transactions=='' ){
      values.selected_transactions = ''
    }else{
      console.log("values.selected_transactions",values.selected_transactions)
      values.selected_transactions = values.selected_transactions.map(data=>data.id.toLocaleLowerCase())
    }

    if(values.selectedMemoClient !== '') {
      window.location.href = `${environment.baseAccountUrl}/v2/#/all-invoice/list?client_code=${values.selectedMemoClient}&client_main_code=${values.selectedMainClient}&start_date=${values.start_date}&end_date=${values.end_date}&selected_transactions=${values.selected_transactions}&consolidation_type=${this.consolidationType}`

      // this.router.navigateByUrl(`/globalSearch/allInvoiices?start_date=${values.start_date}&end_date=${values.end_date}&client_code=${values.selectedClient}`)
      // &invoice_no=${values.invoice_no}&booking_ref_no=${values.booking_ref_no}&pnr=${values.pnr}&ticket_no=${values.ticket_no}&pax_name=${values.pax_name}&status=${values.status}&from_amount=${values.from_amount ? values.from_amount : ""}&to_amount=${values.to_amount ? values.to_amount : ""}&product_type=${values.product_type ? values.product_type : "invoice"}&invoice_type=${values.invoice_type ? values.invoice_type : "flight"}&transaction_method=${values.transaction_method ? values.transaction_method : ""}
    }
    // this.reset()
  }

  getFilteredData(queryParams) {
    this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/gst`, queryParams).subscribe(res => {
      console.log(res);
    }, err => {
      console.error(err);
    })
  }

  reset() {
    GlobalVariable.openModalStatus = false;
    this.consolidateInvoiceForm = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
      selectedMemoClient: [''],
      selectedMemoClientName: [''],
      selectedMainClient: [''],
      selectedMainClientName: [''],
      selected_transactions:['']
    })
    localStorage.removeItem('consolidateInvoiceQueryParams')
  }

  clickSearchButton(){
		this.searchButton.nativeElement.click()
	}

  getClient(value,formControlName,bindValue = false) {
		if(!value) {
			this.consolidateInvoiceForm.controls[formControlName].setValue('')
			this.consolidateInvoiceForm.controls[`${formControlName}Name`].setValue('')
		}
		if (value.length > 2) {
			this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.clientArr = res
        console.log(this.clientArr);
        
				if(bindValue && this.clientArr.length>0){
					this.consolidateInvoiceForm.controls[formControlName].setValue(this.clientArr[0].account_code)
					this.consolidateInvoiceForm.controls[`${formControlName}Name`].setValue(this.clientArr[0].name)
				}
			}, err => {

			})
		}
	}

}
