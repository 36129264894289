import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';

import * as moment from 'moment-timezone';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { DownloadService } from 'src/app/shared/download.service';

@Component({
  selector: 'app-unpaid-report',
  templateUrl: './unpaid-report.component.html',
  styleUrls: ['./unpaid-report.component.scss']
})
export class UnpaidReportComponent implements OnInit {
	isPrint:any;
  fromRoot = true;
	infiniteSelector: string = '.main-panel';
	fromIndex: any = 0;
	dataLimit: any = 100;
	toIndex: any = 30;
	allInvoiceArr: any;
	showPrintData: boolean = false;
	invoiceFilterForm: FormGroup;
	innerHeight: string;
	invoiceArr: any;
	clientArr: any;
	keyword = 'name';
	navigationSubscription
	reInitialise = false;
	p;
	valid: boolean;
	isAdvancedSearchOpen: boolean = false;
	currentIndex = 0
	@ViewChild('tableHeader', { static: true }) tableHeader: ElementRef
	advancedSearchSubscription: Subscription;
	queryData: any;
	companyDetail: any;
	currentDate = new Date()
	duration: any;

	totalAmountObj: any;
	constructor(
		private formBuilder: FormBuilder,
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private route: ActivatedRoute,
		private router: Router,
		private downloadService: DownloadService
	) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	}

	ngOnInit() {
		this.spinner.show();
		setTimeout(() => {
			this.spinner.hide();
		}, 1000);
		let queryParams = this.route.queryParams['value']
		this.queryData = Object.assign({}, queryParams)
		this.search()
		localStorage.setItem('activeInvoiceQueryParams', JSON.stringify(queryParams))

		this.reInitialise = true

	}

	search() {
		let url = `${environment.okountUrl}/reports/reconciliation/un-paid`

		this.commonService.getRequestWithQueryParams(`${url}`, this.queryData).subscribe(res => {
      debugger
      this.companyDetail = res['company_details']
			this.allInvoiceArr = res['data']
			this.invoiceArr = res['data']
			this.duration = res['duration']
			this.valid = true
			this.totalAmountObj = this.getAmount(res['data'])
		})
	}

	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}

	ngAfterViewInit() {
		this.advancedSearchSubscription = this.commonService.advancedSearch.subscribe(status => {
			console.log('Is modal opened : ', status)
			// this.isAdvancedSearchOpen = status
		})
	}
	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
		if (this.advancedSearchSubscription) {
			this.advancedSearchSubscription.unsubscribe()
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setHeight(event.target.innerHeight)
	}

	getData() {
		this.commonService.getOkount(`v3/view-all-invoice`).subscribe(res => {
			this.invoiceArr = res
			this.focusOnTableHeader()
			this.currentIndex = 0
			this.valid = true
		}, err => {
			console.log('Error  : ', err)
		})
	}
	getClient(value) {
		if (value.length > 2) {
			this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				debugger
				this.clientArr = res
				this.setHeight(window.innerHeight)
			}, err => {

			})
		}
	}
	async selectEvent(data) {
		// let ledgerDetails = await this.getLedgerDetails(data.ledger_id)
		if (data.hasOwnProperty('id')) {
			debugger
			let billingAddress = data.address_1 + " " + data.address_2 + " " + data.address_3;
			this.invoiceFilterForm.controls.client.setValue(billingAddress ? billingAddress : '')
		}
	}
	// openInvoice(invoice_number){

	// 	// invoice/:mode/:invoice
	// 	window.open(`/transactions/invoice-view;searchId=${invoice_number};type=flight`, "_blank");
	// 	window.open(`/transactions/invoice-view;searchId=${invoice_number};type=flight`, "_blank");
	// }

	setHeight(height) {
		if (this.invoiceArr != undefined) {
			if (height == undefined) {
				height = window.innerHeight
			}
			if (this.invoiceArr.length <= 10) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = height - 300 + 'px';
			}
		}
	}

	focusOnTableHeader() {
		if (this.tableHeader != undefined) {
			this.tableHeader.nativeElement.focus()
		}
	}

	// @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext() {
		if (document.getElementById(`rowIndex_${this.currentIndex + 1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex + 1}`).focus()
			this.currentIndex = this.currentIndex + 1
		}
	}
	moveToPrev() {
		if (document.getElementById(`rowIndex_${this.currentIndex - 1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex - 1}`).focus()
			this.currentIndex = this.currentIndex - 1
		}
	}

	moveToIndex(i) {
		document.getElementById(`rowIndex_${i + 1}`).focus()
		this.currentIndex = i + 1
	}

	exportAsXLSX(): void {
		this.spinner.show()
		let xlsxData = [];
		this.invoiceArr.forEach(data => {
			let obj = {};
			obj["Airline Detail"] = data.account_name
			obj["Amount Dr."] = Number(data.debit_amount)
			obj["Amount Cr."] = Number(data.credit_amount)
			obj["Balance"] = Number(data.balance)
			xlsxData.push(obj);
		});
		let obj1 = {};
			obj1["Airline Detail"] = ""
			obj1["Amount Dr."] = ""
			obj1["Amount Cr."] = ""
			obj1["Balance"] = ""
			xlsxData.push(obj1);
		let obj = {}
			obj["Airline Detail"] = ""
			obj["Amount Dr."] = Number(this.totalAmountObj['debit_amount'])
			obj["Amount Cr."] = Number(this.totalAmountObj['credit_amount'])
			obj["Balance"] = Number(this.totalAmountObj['balance'])
			xlsxData.push(obj);
		this.downloadService.exportAsXLSX(`Unpaid Report :`, xlsxData);
		this.spinner.hide()
	}

	callPdfGeneratorApi() {
		this.showPrintData = true
		setTimeout(() => {
			window.print()
			this.showPrintData = false
		}, 100);
	}


	getAmount(data) {
		let amountObj = {
			credit_amount: 0,
			debit_amount: 0,
			balance: 0,
		}
		let totalCredit = 0
		let totalDebit = 0
		let totalBalance = 0
		if (data != undefined) {
			data.forEach(element => {
				totalCredit += Number(element['credit_amount'])
				totalDebit += Number(element['debit_amount'])
				totalBalance += Number(element['balance'])
			});
			amountObj['credit_amount'] = totalCredit
			amountObj['debit_amount'] = totalDebit
			amountObj['balance'] = totalBalance
			return amountObj
		}
	}


}
