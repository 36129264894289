import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
  } from '@ngrx/store';
import { sharedReducers } from 'src/app/shared/storev1/reducers';

  import {TransactionReducers} from 'src/app/transactions/store/reducers'
  import { environment } from '../../../environments/environment';
  
  
  export interface State {
    TransactionReducers:any,
    sharedReducers:any
  }

  
  export const reducers: ActionReducerMap<State> = {
    TransactionReducers,
    sharedReducers
  };
  
  
  export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
  