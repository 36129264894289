import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GstReportRoutingModule } from './gst-report-routing.module';
import { B2csReportComponent } from './b2cs-report/b2cs-report.component';
import { HsnReportComponent } from './hsn-report/hsn-report.component';
import { DocsReportComponent } from './docs-report/docs-report.component';
import { B2bReportComponent } from './b2b-report/b2b-report.component';
import { GstReportComponent } from './gst-report.component';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdnrReportComponent } from './cdnr-report/cdnr-report.component';


@NgModule({
  declarations: [GstReportComponent, B2bReportComponent ,B2csReportComponent, HsnReportComponent, DocsReportComponent, CdnrReportComponent],
  imports: [
    CommonModule,
    GstReportRoutingModule,
    SharedModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  providers : [CommonServiceService]
})
export class GstReportModule { }
