import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CommonDataService {

	accessData: any ;

	constructor() { 
	}

	public getAccessData() {
		return this.accessData;
	}

	public setAccessData(data) {
		this.accessData = data;
	}
}
