import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from '../../common-service.service';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';

@Component({
  selector: 'app-aging-report-search',
  templateUrl: './aging-report-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class AgingReportSearchComponent implements OnInit {

  agingReportFilterForm: FormGroup;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
  minDate = "2022-04-01"
  maxDate = moment().format('YYYY-MM-DD')

  constructor(
    public commonService: CommonServiceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private store: Store<InitialState>) {
      this.store.select('sharedReducers').subscribe((items)=>{
				if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				} else {
					let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
					if(!current_financial_year) {
						current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
					}
					this.minDate = current_financial_year.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				}
				
		})
  }
  
  ngOnInit() {
    this.agingReportFilterForm = this.formBuilder.group({
      start_date: [this.minDate],
      end_date: [this.maxDate],
      viewMode: [''],
      firstFrom: [0],
      secondFrom: [''],
      thirdFrom: [''],
      fourthFrom: [''],
      firstTo: [''],
      secondTo: [''],
      thirdTo: [''],
      fourthTo: [0],
      account: ['client'],
    })
  }

  search(values) {
    GlobalVariable.openModalStatus = false;
    if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
      values.start_date = ""
    }
    else {
      values.start_date = moment(values.start_date).format('YYYY-MM-DD')
    }
    if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date" || values.end_date == "Invalid date") {
      values.end_date = ""
    }
    else {
      values.end_date = moment(values.end_date).format('YYYY-MM-DD')
    }

    localStorage.setItem('agingReportQueryParams', JSON.stringify(values))
    this.router.navigateByUrl(`/report/aging-report/view?start_date=${values.start_date}&end_date=${values.end_date}&viewMode=${values.viewMode}&limit=${values.firstTo}&limit2=${Number(values.secondTo)-Number(values.secondFrom)}&limit3=${Number(values.thirdTo)-Number(values.thirdFrom)}&limit4=${Number(values.fourthTo)-Number(values.fourthFrom)}&account=${values.account}`)
  }

  reset() {
    GlobalVariable.openModalStatus = false;
    this.agingReportFilterForm = this.formBuilder.group({
      viewMode: [''],
      account:["client"]
    })
  }

  onFirstInput(values) {
    this.agingReportFilterForm.controls['secondFrom'].setValue(Number(values))
    this.agingReportFilterForm.controls['secondTo'].setValue(Number(2*(values)))
    this.agingReportFilterForm.controls['thirdFrom'].setValue(Number(2*(values)))
    this.agingReportFilterForm.controls['thirdTo'].setValue(Number(3*(values)))
    this.agingReportFilterForm.controls['fourthFrom'].setValue(Number(3*(values)))
    this.agingReportFilterForm.controls['fourthTo'].setValue(Number(4*(values)))
  }


  clickSearchButton(){
		this.searchButton.nativeElement.click()
	}

  onSecondInput(values) {
    this.agingReportFilterForm.controls['thirdFrom'].setValue(Number(values))
  }

  onThirdInput(values) {
    this.agingReportFilterForm.controls['fourthFrom'].setValue(Number(values))
  }
}

