import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AirlineRoutingModule } from './airline-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { AirlineComponent } from './airline.component';
import { CreateAirlineComponent } from './create-airline/create-airline.component';
import { ViewAirlineComponent } from './view-airline/view-airline.component';
import { UpdateAirlineComponent } from './update-airline/update-airline.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';


@NgModule({
  declarations: [AirlineComponent, CreateAirlineComponent, ViewAirlineComponent, UpdateAirlineComponent ],
  imports: [
    CommonModule,
    SharedModule,
    AirlineRoutingModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule, 
    ReactiveFormsModule
  ],
  exports: [UpdateAirlineComponent]
})
export class AirlineModule { }
