import { Component, OnInit, HostListener, ElementRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { DownloadService } from 'src/app/shared/download.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment-timezone';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../../../transactions/store/actions';
import { Tab } from 'src/app/shared/model/tab.model';
import { TabService } from 'src/app/shared/services/tab.service';
import { InvoiceComponent } from 'src/app/transactions/invoice/invoice.component';
import { MsInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/ms-invoice/ms-invoice.component';
import { GstInvoiceComponent } from 'src/app/transactions/invoice/miscellaneous-invoice/gst-invoice/gst-invoice.component';
import { V2CreatePaymentComponent } from 'src/app/transactions/payment/v2-create-payment/v2-create-payment.component';
import { V2CreateReceiptComponent } from 'src/app/transactions/receipt/v2-create-receipt/v2-create-receipt.component';
import { CreateAdmAcmComponent } from 'src/app/transactions/adm-acm/create-adm-acm/create-adm-acm.component';
import { AddEntryJvComponent } from 'src/app/transactions/jv/add-entry-jv/add-entry-jv.component';
import { InvoiceRefundComponent } from 'src/app/transactions/invoice-refund/invoice-refund.component';
import * as FileSaver from 'file-saver'; 
import { TourComponent } from 'src/app/transactions/tour/tour.component';
import { EventComponent } from 'src/app/transactions/event/event.component';
import { CookiesService } from 'src/app/shared/cookies.service';
import { MiscInvoiceComponent } from 'src/app/transactions/misc-invoice/misc-invoice.component';
import { AddEntryPurchaseInvoiceComponent } from 'src/app/transactions/purchase-invoice/add-entry-purchase-invoice/add-entry-purchase-invoice.component';
import { ConsolidateInvoiceComponent } from 'src/app/transactions/consolidate-invoice/consolidate-invoice.component';
@Component({
	selector: 'app-detailed-ledger',
	templateUrl: './detailed-ledger.component.html',
	styleUrls: ['./detailed-ledger.component.scss']
})
export class DetailedLedgerComponent implements OnInit {

	p = 0;
	q = 0;
	leaderData: any;
	navigationSubscription;
	reInitialise: boolean;
	currentDate = moment().format('YYYY-MM-DD');
	currentTime = moment().format('HH:mm:ss');
	innerHeight: string;
	ascSorttdsAmt: boolean = true;
	ascSortRef: boolean = true;
	totalAmountObj: any;
	showMessage:boolean=false;
	alertType:String="";
	message:String=""
	clientData:any
	isAdvancedSearchOpen: boolean = false;
	sendEmailForm:FormGroup;
	currentIndex = 0
	isPrint: any;
	supplier_trans: string='false'
	discount_add: string='false'
	referenceTypeName = {
		"invoice" : "INVOICE",
		"credit_note" : "Credit Note",
		"jv" : "Journal",
		"consolidate_invoice" : "Consolidate",
		"event_invoice" : "Event",
	}

	@ViewChild('tableHeader', { static: true }) tableHeader: ElementRef
	@ViewChild('closeSendEmailModal') closebutton;
	@ViewChild('openEmailModal') openEmailModal;
	@ViewChild('openPdfDetailModal', { static: true }) openPdfDetailModal: ElementRef

	parentIndex = 0;
	childIndex = 0;
	shortcuts: ShortcutInput[] = [];

	narrArr: any;
	queryParams: any;
	@Input() filters
	dataloaded: boolean;
	isSubgrouping= false;
	waterMark;
	accounting_company: any;
	
	constructor(
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private router: Router,
		private downloadService: DownloadService,
		private fb:FormBuilder,
		private store: Store<InitialState>,
		private tabService: TabService,
		private cookiesService : CookiesService

	) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});

		this.sendEmailForm = this.fb.group({
			toEmails: ["",Validators.required],
			bccEmails: [''],
			ccEmails:[''],
			subject:[''],
			body:['']
		})
	}

	async ngOnInit() {
		let queryParams = this.route.queryParams['value']
		this.queryParams = queryParams
		if(this.filters){
			queryParams = this.filters
		}
		if (queryParams.client != undefined) {
			localStorage.setItem('activeLedgerQueryParams', JSON.stringify(queryParams))
			this.getFilteredData(queryParams)
			this.isPrint = queryParams.print
			this.isSubgrouping = queryParams.grouping && queryParams.grouping == 'true'
		} else {
			this.getData()
		}
		let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
		let transaction_config = globalSettingConfig.filter(el=>el.group=='TRANSACTION')
		if(transaction_config && transaction_config.length>0){      
      let payable_config = transaction_config[0].json_doc["value"].filter(el=>el.key=='PAYABLE TRANSACTION')
			if(payable_config && payable_config.length){
				this.supplier_trans = payable_config[0].value
			}
		let discount_config = transaction_config[0].json_doc["value"].filter(el=>el.key=='DISCOUNT')
		if(discount_config && discount_config.length){
			this.discount_add = discount_config[0].value
		}
		}
		this.reInitialise = true

		let client = JSON.parse(localStorage.getItem("activeLedgerQueryParams")).client
		this.getClient(client)
	}


	ngAfterViewInit() {
		
		this.shortcuts.push(			
			{
				key: "f10",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Open Email Modal",
				description: "Open Email Modal",
				
				command: (e)=>{
					this.openModal()
					setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
					},
					preventDefault: true
			}
			
	  )
	}
	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}

	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.leaderData != undefined) {
			this.setHeight(this.p, this.q, event.target.innerHeight)
		}
	}

	openSavePdfDetailedModal() {
		const fromDate = this.leaderData?.duration?.from_date; 
		const toDate = this.leaderData?.duration?.to_date; 
		let recordsLength = 0;

		let all_clients_records = this.leaderData.all_clients_records && this.leaderData.all_clients_records.length>0 ? this.leaderData.all_clients_records[0] : {};
		let clientRecords = all_clients_records.client_records;
		let display_name= all_clients_records.display_name

		if(this.isSubgrouping) {
			if(clientRecords && clientRecords.length>0) {
				for(let clientRecord of clientRecords) {
					if(clientRecord.records && clientRecord.records.length>0) {
						recordsLength += clientRecord.records.length;
					}
				}
			}
		} else  {
			if(clientRecords && clientRecords.length>0) {
				recordsLength += clientRecords.length;
			}
		}

		if(recordsLength <= 1000) {
			this.downloadPdf(fromDate,toDate, null,display_name)
			return;
		} else {
			this.openPdfDetailModal.nativeElement.click()
		}
		
	}


	onYesClickPdfDetailModal() {
		let queryData = JSON.parse(localStorage.getItem('activeLedgerQueryParams'))
		queryData.schedule_download = true;
		const queryParams = queryData;

		let url = `${environment.okountUrl}/reports/ledger/detailed/download`
		if(this.isSubgrouping) {
			url = `${environment.okountUrl}/reports/ledger/detailed/grouping/download`
		}
		 
		this.commonService.getRequestWithPdfData(url, queryParams).subscribe((res: any) => {
			this.showMessage = true;
			this.alertType = 'success-box'
			this.message = 'Download Scheduled Successfully.'
			this.onNoClickPdfDetailModal()
			setTimeout(() => {
				this.showMessage = false;
				let redirectUrl = `${environment.baseAccountUrl}/v2/#/report/schedule-reports`
				window.open(redirectUrl, '_blank');
			}, 1000)
		}, err => {
			console.log(err)
		})		
	  }
	  
	  
	onNoClickPdfDetailModal() {
		this.openPdfDetailModal.nativeElement.click()
	}


	//ALL DATA
	getData() {
		this.commonService.getRequest(`${environment.okountUrl}/reports/ledger/detailed`).subscribe(res => {
			this.leaderData = res
			this.accounting_company =  res['company_details'];

			if(res && res['company_details'] && res['company_details'].tenant_logo) {
				this.waterMark = `url('${res['company_details'].tenant_logo}')`
			}

			if (this.leaderData != undefined) {
				this.totalAmountObj = this.getAmount()
				this.setHeight(0, 0, window.innerHeight)
			}

		}, err => {
			console.error(err)
		})
	}

	downloadPdf(start_date, end_date, xlsx,display_name) {
		let queryData = JSON.parse(localStorage.getItem('activeLedgerQueryParams'))
		let url = `${environment.okountUrl}/reports/ledger/detailed/download`
		if(this.isSubgrouping) {
			url = `${environment.okountUrl}/reports/ledger/detailed/grouping/download`
		}

		if(xlsx){
			queryData['download_type'] = 'xlsx'
		}

		this.commonService.downloadPDF(url, queryData).subscribe((fileBlob:any) =>{
			let fileName = `${display_name} DETAILED LEDGER FROM ${start_date} TO ${end_date}.${queryData['download_type'] == 'xlsx' ? 'xlsx' : 'pdf'}` 			
			let blob = new Blob([fileBlob], { 
				type: queryData['download_type'] == 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}
	

	setHeight(pIndex, innerPIndex, height) {
		if (height == undefined) {
			height = window.innerHeight
		}
		pIndex = Number(pIndex)
		innerPIndex = Number(innerPIndex)
		this.p = pIndex
		this.q = innerPIndex

		let parent = 0
		if (pIndex > 0) {
			parent = pIndex - 1
		}
		let child = 0
		if (innerPIndex > 0) {
			child = innerPIndex - 1
		}
		if (this.leaderData['all_clients_records'].length > 0) {
			if(this.isSubgrouping){
				this.innerHeight = height - 230 + 'px';
			}else if (this.leaderData['all_clients_records'][parent].client_records.length <= 2) {
				// if (this.leaderData['all_clients_records'][parent].client_records[child].records.length <= 3) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = height - 230 + 'px';
			}
		}
		else {
			this.innerHeight = height - 230 + 'px';
		}

		// else {
		// 	this.innerHeight = height - 250 + 'px';
		// }
	}

	//FILTERED DATA
	getNarration(data : any){
		this.narrArr = []
		this.narrArr =	data.narration.split(',')

	}
	getFilteredData(queryParams) {
		this.totalAmountObj ={};
		this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/ledger/detailed`, queryParams).subscribe(res => {
			this.leaderData = res
			this.accounting_company =  res['company_details'];
			if(res && res['company_details'] && res['company_details'].tenant_logo) {
				this.waterMark = `url('${res['company_details'].tenant_logo}')`
			}
			if (this.leaderData != undefined) {
				this.totalAmountObj = this.getAmount()
				this.setHeight(0, 0, window.innerHeight)
			}
		}, err => {
			console.error(err);
		})
	}

	exportAsXLSX(fromDate, toDate): void {
		
		this.spinner.show()
		let xlsxData = [];
		
		if(this.queryParams.subgroup_type || this.isSubgrouping){
			this.downloadPdf(fromDate, toDate, true,this.leaderData.all_clients_records[0].display_name)	
			return;
		}

		this.leaderData.all_clients_records[0]['client_records'].forEach(element => {
			let obj = {};
			let newLineNarration = ''
			let i = 0
			let narrationData : any
			let detailLedgerData : any
			if(element.records && element.records[0]){
				detailLedgerData = element.records[0]
			}
			if(!element.records){
				detailLedgerData = element
			}

			if(element.records && element.records[0]?.narration){
				narrationData = element.records[0]?.narration
			}
			if(!element.records && element.narration){
				narrationData = element.narration
			}
			if(narrationData && typeof narrationData == 'object'){
				if(element.reference_type=='payment' || element.reference_type=='receipt'){
					narrationData.splice(2,1)
				}else{
					narrationData.splice(3,1)
				}
				newLineNarration = narrationData.join("\n")
			}
			
			
			obj["DATE"] = `${detailLedgerData?.date ? moment(detailLedgerData?.date).format('DD/MM/YYYY') : ''}`;
			obj["DOC NO."] = detailLedgerData?.reference;
			obj["ACCOUNT DESCRIPTION"] =newLineNarration;
			obj['BASIC'] = detailLedgerData?.base ? Number(Number(detailLedgerData?.base).toFixed(2)) : 0;
			obj["TAXES"] = detailLedgerData?.tax ? Number(Number(detailLedgerData?.tax).toFixed(2)) : 0;
			obj["H/C"] = detailLedgerData?.commission ? Number(Number(detailLedgerData?.commission).toFixed(2)) : 0;
			if(this.discount_add=='true'){
				obj["DISCOUNT"] = detailLedgerData?.discount ? Number(Number(detailLedgerData?.discount).toFixed(2)) : 0;
			}
			obj["GST"] = detailLedgerData?.gst ? Number(Number(detailLedgerData?.gst).toFixed(2)) : 0;
			obj["SERV. CHRGS"] = detailLedgerData?.service_charge ? Number(Number(detailLedgerData?.service_charge).toFixed(2)) : 0;
			obj["TDS"] = detailLedgerData?.tds ? Number(Number(detailLedgerData?.tds).toFixed(2)) : 0;
			obj["DEBIT"] = detailLedgerData?.amount_debit ? Number(Number(detailLedgerData?.amount_debit > 0 ? detailLedgerData?.amount_debit : detailLedgerData?.amount_debit*-1).toFixed(2)) : 0;
			obj["CREDIT"] = detailLedgerData?.amount_credit ? Number(Number(detailLedgerData?.amount_credit > 0 ? detailLedgerData?.amount_credit : detailLedgerData?.amount_credit*-1).toFixed(2)) : 0;
			obj["BALANCE"] = detailLedgerData.balance ? Number(Number(detailLedgerData.balance).toFixed(2)) : 0;
			if(this.supplier_trans=='true'){
				obj["PAYABLE TRANSACTION CODE"] = detailLedgerData && detailLedgerData.payable_transaction_code ? detailLedgerData.payable_transaction_code : "";
				obj["PAYABLE DATE"] = detailLedgerData && detailLedgerData.payable_date? detailLedgerData.payable_date : '';
			}
			xlsxData.push(obj);

		});
		xlsxData.push({
			'ACCOUNT DESCRIPTION' : 'CLOSING BALANCE',
			'DEBIT' : this.totalAmountObj.type == 'DR.' ? this.totalAmountObj.diff : 0,
			'CREDIT': this.totalAmountObj.type == 'CR.' ?  this.totalAmountObj.diff : 0
		})

		xlsxData.push({},{},{"DATE":`FROM ${fromDate} TO ${toDate} CLIENT: ${this.leaderData.all_clients_records[0].display_name}`})

		this.downloadService.exportAsXLSX(`${this.leaderData.all_clients_records[0].display_name} DETAILED LEDGER ${fromDate} - ${toDate}`, xlsxData);
		this.spinner.hide()
	}

	callPdfGeneratorApi(fromDate, toDate) {
		window.scrollTo(0, 0)
		this.spinner.show()
		let url = 'print/account-book/ledger/detailed-ledger'
		let queryString = location.hash.split('?')[1]
		if (queryString != undefined) {
			url = `${url}?print=true&${queryString}`
		}
		let printOptions = {
			"landscape": true,
			"format" : "A4",
			"preferCSSPageSize": false
		  }
		this.downloadService.callPdfGeneratorApi(`DETAILED LEDGER ${fromDate} - ${toDate}.pdf`, url,printOptions)
		this.spinner.hide()
	}


		
	sendEmails(){
  	
		let toEmails = this.sendEmailForm.value.toEmails
		let bccEmails = this.sendEmailForm.value.bccEmails.split(",")
		let ccEmails = this.sendEmailForm.value.ccEmails.split(",")
        let body = this.sendEmailForm.value.body
		let subject = this.sendEmailForm.value.subject


		if(toEmails){
		  toEmails = toEmails.split(",")
		  let exclude = ""
		  if(sessionStorage.getItem("excludeArr")){
			  exclude = "&exclude="
			  let excludeArr = JSON.parse(sessionStorage.getItem("excludeArr"))
			  exclude += excludeArr.join(',')
		  }
		  window.scrollTo(0, 0)
		  this.spinner.show()
		  let url = 'print/account-book/ledger/detailed-ledger'
		  let queryString = location.hash.split('?')[1]
		  if (queryString != undefined) {
			  if(exclude){
				  queryString+=exclude
			  }
			  url = `${url}?print=true&${queryString}`
		  }
		  let start_date = JSON.parse(localStorage.getItem("activeLedgerQueryParams")).start_date
		  let end_date  = JSON.parse(localStorage.getItem("activeLedgerQueryParams")).end_date
		  
	
		  let emails = {
			to_addresses:toEmails,
			cc_addresses:ccEmails,
			bcc_addresses:bccEmails
		  }
	  
		  let ledger_criteria = {
			  email:emails,
			  start_date:start_date,
			  end_date:end_date,
			  name:this.clientData.name,
			  email_for:"ledger statement",
			  printOptions : {
				"landscape": true,
				"format" : "A4",
				"preferCSSPageSize": false
			  }
			  
		  }
		
		  let data = {
			  page_url: url,
			  ledger_criteria:ledger_criteria,
			  queryParams: this.queryParams,
			  email_body:body,
			  subject: subject
		  }
	  
	  
		  this.commonService.postRequest(`${environment.okountUrl}/ledger/email`,data).subscribe((res : any)=> {
			this.showMessage = true;
			this.alertType = 'success-box'
			this.message = res.msg
			this.closebutton.nativeElement.click();
		  },err => {
			this.showMessage = true
			this.alertType = "error-box"
			this.message = err.msg
			this.closebutton.nativeElement.click();
			
		  })
		}
	  }

	  openModal(){
		//define email.
		
		this.sendEmailForm.controls.toEmails.setValue(this.clientData.primary_email)
		this.sendEmailForm.controls.bccEmails.setValue("")
			
		let userData = JSON.parse(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA'))
		let companyName;
		if(userData && userData['t_tenant_info'] && userData['t_tenant_info']['tenant_company_name']) {
			companyName = userData['t_tenant_info']['tenant_company_name'];
		}
		
		let subject = `Ledger statement For The Period ${this.leaderData.duration.from_date ? moment(this.leaderData.duration.from_date).format('MMMM DD, YYYY') : ''} To ${this.leaderData.duration.to_date ? moment(this.leaderData.duration.to_date).format('MMMM DD, YYYY') : ''}`;
		
		let body = `Dear Travel Partner,\n\nPlease find Attached herewith Ledger statement For The Period ${this.leaderData.duration.from_date ? moment(this.leaderData.duration.from_date).format('MMMM DD, YYYY') : ''} To ${this.leaderData.duration.to_date ? moment(this.leaderData.duration.to_date).format('MMMM DD, YYYY') : ''}`
		body += `\n\nPlease do not hesitate to contact us if you have any query or concern about attached document.\n\nIt's been a pleasure to serve you.\n\nBest Regards\n\n${this.accounting_company?.name?.toUpperCase()}\nAccounts Team`
		if(this.accounting_company && this.accounting_company.telephone){
			body += `\nContact Us on ${this.accounting_company.telephone}`
		}

		this.sendEmailForm.controls.subject.setValue(subject);
		this.sendEmailForm.controls.body.setValue(body)


		this.sendEmailForm.controls.ccEmails.setValue(this.clientData.secondary_email.split(',').join('\n'))
		this.openEmailModal.nativeElement.click();
	}
	
	hideError(){
		this.showMessage = false
	}

	// showCCEmail(){
	// 	this.ccEmail=true;
	// }
	// showBCCEmail(){
	// 	this.bccEmail= true;
	// }

	getClient(value) {
		if (value.length > 2) {
			this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
					this.clientData = res[0]
			}, err => {
				console.log(err)
			})
		}
	}

	

	getAmount() {
		let amountObj = {
			debit: 0,
			credit: 0,
			diff: 0
		}
		let totalCredit = 0
		let totalDebit = 0
		let balance = 0
		let openingBalance = 0
		let openingBalanceType = 'debit'
		if (this.leaderData != undefined) {
			this.leaderData['all_clients_records'].forEach(data => {
				data['client_records'].forEach(record => {
					if (record.hasOwnProperty('records')) {
						record.records.forEach(data => {
							if(data.reference_type == 'opening_balance'){
								openingBalance += data.amount_debit ? Number(data.amount_debit) : 0;
								if(openingBalance == 0)	{
									openingBalanceType = 'credit'
								}			
								openingBalance += data.amount_credit ? Number(data.amount_credit) : 0;				
							}else{
								totalCredit += data.amount_credit ? Number(data.amount_credit) : 0;
								totalDebit += data.amount_debit ? Number(data.amount_debit) : 0;
							}
						});
					} else {
						if(record.reference_type == 'opening_balance'){
							openingBalance += record.amount_debit ? Number(record.amount_debit) : 0;
							if(openingBalance == 0)	{
								openingBalanceType = 'credit'
							}			
							openingBalance += record.amount_credit ? Number(record.amount_credit) : 0;				
						}else{
							totalCredit += record.amount_credit ? Number(record.amount_credit) : 0;
							totalDebit += record.amount_debit ? Number(record.amount_debit) : 0;
						}
						
					}
					// balance += records.balance ? Number(records.balance) : 0;
				});
				// balance = data['records'][data['records'].length-1].balance ? Number(data['records'][data['records'].length-1].balance) : 0;

			});
			amountObj['credit'] = totalCredit
			amountObj['debit'] = totalDebit
			amountObj['balance'] = balance
			amountObj['openingBalance'] = Number(openingBalance)
			// amountObj['balance'] = Number(balance) - Number(openingBalance)
			amountObj['openingBalanceType'] = openingBalanceType
			amountObj['diff'] = Number(totalCredit) - (Number(totalDebit) - Number(openingBalance))
			amountObj['type'] = 'CR.'
			if (amountObj['diff'] < 0) {
				amountObj['type'] = 'DR.'
			}
			amountObj['diff'] = Math.abs(amountObj['diff'])
		}
		return amountObj
	}

	openViewMode(reference, referenceType, inv_reference_type) {
		let isInvoice = false
		let isMsInvoice = false
		let isMisc = false
		let isGstInvoice = false
		let isPayment = false
		let isReceipt = false
		let isAdmAcm = false
		let isJv = false
		let isCreditNote = false
		let isTourInvoice = false
		let isPurchaseInvoice = false
		let isEventInvoice = false
		let isConsolidateInovoice = false

		if (reference != undefined) {
			let url = '#/transactions/'
			let viewMode = 'view'
			if (referenceType == 'invoice' && inv_reference_type == 'flight_invoice') {
				url = '#/transactions/invoice'
				viewMode = 'view'
				isInvoice = true
			}
			if (referenceType == 'misc-invoice') {
				url = '#/transactions/ms/misc-invoice'
				viewMode = 'view'
				isMisc = true
			}
			if (referenceType == 'consolidate_invoice') {
				url = `#/transactions/consolidate-invoice/inv/view/${reference}`
				viewMode = 'view'
				isConsolidateInovoice = true
			}
			if (referenceType == 'invoice' && inv_reference_type == 'ms_invoice') {
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
				isMsInvoice = true
			}
			if (referenceType == 'invoice' && inv_reference_type == 'event_invoice') {
				url = '#/transactions/event/save/sales'
				viewMode = 'view'
				isEventInvoice = true
			}
			if (referenceType == 'invoice' && inv_reference_type == 'gst_invoice') {
				url = '#/transactions/ms/gst-invoice'
				viewMode = 'view'
				isGstInvoice = true
			}
			if (referenceType == 'invoice' && inv_reference_type == 'tour_invoice') {
				url = '#/transactions/tour/save/sales'
				viewMode = 'view'
				isTourInvoice = true
			}

			if (referenceType == 'invoice' && inv_reference_type == 'purchase_invoice') {
				viewMode = 'view'
				isPurchaseInvoice = true
			}

			if (referenceType == 'credit_note' && inv_reference_type == 'ms_invoice') {
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
				isMsInvoice = true
			} else if (referenceType == 'credit_note' && inv_reference_type == 'tour_invoice') {
				url = '#/transactions/tour/save/sales'
				viewMode = 'view'
				isTourInvoice = true
			} else if (referenceType == 'credit_note') {
				url = '#/transactions/credit-note'
				viewMode = 'view'
				isCreditNote =  true
			}
			if (referenceType == 'payment') {
				url = '#/transactions/payment'
				viewMode = 'view'
				isPayment = true
			}
			if (referenceType == 'receipt') {
				url = '#/transactions/receipt'
				viewMode = 'view'
				isReceipt = true
			}
			if (referenceType == 'jv') {
				url = '#/transactions/jv'
				viewMode = 'update'
				isJv = true
			}
			if (referenceType == 'adm' || referenceType == 'acm') {
				url = '#/transactions/adm-acm'
				viewMode = 'view'
				isAdmAcm = true
			}


			let path = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${url}/${viewMode}/${reference}`

			if(window.location.protocol != 'file:'){
				let title =''
				let referenceSplit = reference.split('-')
				title += referenceSplit[0]
				title +='...'
				title += referenceSplit[referenceSplit.length-1]
				if(isInvoice) {
					this.tabService.addTab(
						new Tab(InvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' }, reference)
						);
				}else if(isMisc) {
					this.tabService.addTab(
						new Tab(MiscInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
						);
				} else if(isConsolidateInovoice) {
					this.tabService.addTab(
						new Tab(ConsolidateInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
						);
				} else if(isMsInvoice) {
					this.tabService.addTab(
						new Tab(MsInvoiceComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
						);
						
				} else if(isPurchaseInvoice) {
					this.tabService.addTab(
						new Tab(AddEntryPurchaseInvoiceComponent , `${title}`, { invoice: reference ,mode : 'view' }, reference)
						);
				} else if(isCreditNote) {
					this.tabService.addTab(
						new Tab(InvoiceRefundComponent, `${title}`, { creditNoteNumber: reference, mode : 'view' }, reference)
						);
				} else if(isGstInvoice) {
					this.tabService.addTab(
						new Tab(GstInvoiceComponent, `${title}`, { invoice: reference ,mode : 'view' } , reference)
						);
				} else if(isPayment) {
					this.tabService.addTab(
						new Tab(V2CreatePaymentComponent, `${title}`, { paymentRef: reference ,mode : 'view' }, reference)
						);
				}
				else if(isReceipt) {
					this.tabService.addTab(
						new Tab(V2CreateReceiptComponent, `${title}`, { receiptRef: reference ,mode : 'view' }, reference)
						);
				} else if(isAdmAcm) {
					this.tabService.addTab(
						new Tab(CreateAdmAcmComponent, `${title}`, { adm_acm_number: reference, mode : 'view' }, reference)
					);
				} else if(isJv) {
					this.tabService.addTab(
						new Tab(AddEntryJvComponent, `${title}`, { jv_code: reference }, reference)
						);
				}else if(isTourInvoice) {
					this.tabService.addTab(
						new Tab(TourComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
						);
				}else if(isEventInvoice) {
					this.tabService.addTab(
						new Tab(EventComponent, `${title}`, { invoice: reference , mode : 'view' }, reference)
						);
				} else {
					window.open(path,'_blank')		
			}
			}else{
				var customeEventHandler = new CustomEvent("handle-window-open", {
					"detail": {
						hashPath : path
					}
				  });
				window.dispatchEvent(customeEventHandler)
			}
		}
	}


	// keyboard integration
	// Add #tableHeader on table header

	focusOnTableHeader() {
		if (this.tableHeader != undefined) {
			this.tableHeader.nativeElement.focus()
		}
	}

	// @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			let records = this.leaderData["all_clients_records"][0].client_records[0].records
			if (records) {

				if (e.keyCode == 40) {
					e.preventDefault();
					this.groupedMoveToNext()
				} else if (e.keyCode === 38) {
					e.preventDefault();
					this.groupedMoveToPrev()
				}
			} else {
				if (e.keyCode == 40) {
					e.preventDefault();
					this.moveToNext()
				} else if (e.keyCode === 38) {
					e.preventDefault();
					this.moveToPrev()
				}
			}
		}
	}
	moveToNext() {
		if (document.getElementById(`rowIndex_${this.currentIndex + 1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex + 1}`).focus()
			this.currentIndex = this.currentIndex + 1
		}
	}
	moveToPrev() {
		if (document.getElementById(`rowIndex_${this.currentIndex - 1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex - 1}`).focus()
			this.currentIndex = this.currentIndex - 1
		}
	}

	moveToIndex(i) {
		document.getElementById(`rowIndex_${i + 1}`).focus()
		this.currentIndex = i + 1
	}

	//Grouped Keyboard Integration
	groupedMoveToNext() {
		if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex + 1}`) != null) {
			document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex + 1}`).focus()
			this.childIndex = this.childIndex + 1
		} else {
			if (this.parentIndex < this.leaderData['all_clients_records'][0].client_records.length - 1) {
				this.parentIndex = this.parentIndex + 1
				this.childIndex = -1
			}
			if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex + 1}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex + 1}`).focus()
				this.childIndex = this.childIndex + 1
			} else if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`).focus()
				this.childIndex = this.childIndex + 1
			}
		}
	}
	groupedMoveToPrev() {
		if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex - 1}`) != null) {
			document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex - 1}`).focus()
			this.childIndex = this.childIndex - 1
		} else {
			if (this.parentIndex > 0) {
				this.parentIndex = this.parentIndex - 1
				if (this.leaderData['all_clients_records'][0].client_records[this.parentIndex] != undefined) {
					this.childIndex = this.leaderData['all_clients_records'][0].client_records[this.parentIndex].records.length
				}
			} else {
				this.childIndex = 0
			}
			if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex - 1}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex - 1}`).focus()
				if (this.childIndex > 0) {
					this.childIndex = this.childIndex - 1
				}
			} else if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`).focus()
				if (this.childIndex > 0) {
					this.childIndex = this.childIndex - 1
				}
			}
		}
	}
	moveToGroupedIndex(parentIndex, childIndex) {
		document.getElementById(`rowIndex_${parentIndex}_${childIndex}`).focus()
		this.parentIndex = parentIndex
		this.childIndex = childIndex
	}

	openAdvanceSearchModal(){
		this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'ledger'}));	
	}
	dataLoadedFn(i){
		if(!this.dataloaded){
			this.dataloaded = true
			try {
				setTimeout(() => {
					document.querySelector("#print-section").classList.add("voucher-container")
				}, 1000);
			} catch (error) {
				
			}
		}
	}
}

