<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<div class="ledger-container detailed-ledger rotated_class" style="size: landscape;" #tableHeader tabIndex="0" [ngClass]="{ 'ledger-print-section': isPrint == 'true' }">
	<!-- <div class="alert alert-success msgSuccess" >
       messageSuccess
    </div>
    <div class="alert alert-danger msgError" >
       messageError
    </div> -->
	<!-- <div class="heading-box mt-3 ml-2">
        <div class="heading">Normal Ledger</div>
        <span class="hr-line"></span>
      </div> -->

	  <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
		<div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
		  <div class="d-flex flex-y-center">
			<img
			  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
				alertType=='success-box' ? 'green-sucess.svg' : 
				alertType=='warning-box' ? 'red-warning.svg' : 
				null }}"
			  width="20px"
			  height="20px"
			  alt="alert-logo"
			  class="mr-20"
			/>
			<div class="ml-10">
			  <h4 class="m-0 text-size-18 text-bold"
			  [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
				{{alertType === "error-box"
				? "Error"
				: alertType === "warning-box"
				? "Warning"
				: alertType === "success-box"
				? "Success"
				: null}}
			  </h4>
			  <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
			   {{message}}
			  </small>
			</div>
		  </div>
		  <img
			src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
			alt="close-btn"
			(click)="hideError()"
			className=" close-btn"
		  />
		</div>
	  </div>

	<div *ngIf="leaderData != undefined">
		<div class="logo-wrapper" *ngIf="isPrint"><div id="waterMark" [ngStyle]="{background: waterMark}"></div></div>
		<div class="print-wrapper" >
			<!-- <div class="row">
				<div class="address col-4 float-left">
					<span class="cname">{{leaderData?.company_details?.name}}</span><br>
					<span class="address_line">{{leaderData?.company_details?.address_1}}
						{{leaderData?.company_details?.address_2}} {{leaderData?.company_details?.address_3}}</span>

				</div>

				<div class="col-4">
					<h3 class="receipt-head mt-2">
						Ledger Statement<br>
					</h3>
				</div>

				<div class="address col-4 float-right field-info">
					<span class="cname"> Date:
						<span>{{currentDate | date : 'dd/MM/yyyy'}}</span>
					</span>
				</div>
			</div> -->
			<!-- <div class="tab-nav d-flex align-item-center justify-content-between">
				<ul class="list-inline list-unstyled p-0 m-0">
					<li class="active page-title text-uppercase cursor"> Detailed Ledger</li>
				</ul>
				<ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
					<li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition">
						Dashboard</li>
					<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
					<li class="text-grey text-capitalize cursor text-primary transition"> Detailed Ledger</li>
				</ul>
			</div> -->

			<div  style="background: #fff!Important;" class="row leader-header tab-nav pl-0 pr-0">
				<div class="col-sm-4 pr-0 ">
					<div *ngIf="isPrint">
						<span class="text-bold text-black text-uppercase" style="font-size: 15px;">{{leaderData?.company_details?.name}}</span><br>
						<p class="address_line mb-0 text-grey text-uppercase" style="font-size: 13px;"><span style="font-size: 13px;"  class="d-block">{{leaderData?.company_details?.address_1}}</span>
						  <span style="font-size: 13px;">{{leaderData?.company_details?.address_2}}</span>  <span style="font-size: 13px;" class="ml-2">{{leaderData?.company_details?.city_name}}</span> <span style="font-size: 13px;" class="ml-2">{{leaderData?.company_details?.address_3}}</span></p>
					   </div>
					<ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0" >
						<li [routerLink]="['/']"
							class="text-grey text-capitalize cursor  hover-text-primary print-d-none transition" [ngClass]="{'d-none': isPrint == 'true'}"> Analysis</li>
						<li class="print-d-none"><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt="" [ngClass]="{'d-none': isPrint == 'true'}"></li>
						<li  style="min-width: fit-content;" class="print-d-none  text-capitalize cursor text-primary transition" [ngClass]="{'d-none': isPrint == 'false'}" [ngClass]="{'d-none': isPrint == 'true'}"> Detailed Ledger:- </li>
						<li class="text-primary"><ng-container *ngFor="let data of leaderData['all_clients_records']" >
						  <span [ngClass]="{ 'font-size: 15px;': isPrint == 'true' }" class=" text-bold line-clamp line-clamp-1"  >
							  {{data['display_name']}}
						  </span>
					  </ng-container></li>
					</ul>
				</div>
				<div class="col-sm-4 text-center">
					<h4 style="font-size: 17px;" *ngIf="isPrint" class="text-uppercase text-bold text-black mt-2">
						Detailed Ledger Statement<br>
					  </h4>
					<span class="rec-date mt-10 d-block ">
						<div class="noWrap justify-content-around">
							<ng-container *ngIf="leaderData.duration != undefined">
								<div class="cname"  [ngClass]="{ 'font-size-15': isPrint == 'true' }" > 
									<span  [ngClass]="{ 'font-size-15': isPrint == 'true' }"  
									>From</span>
									<h5 style="margin-right: 5px;" class="mb-0  d-inline"><span [ngClass]="{ 'font-size-15': isPrint == 'true' }"  class="date text-bold text-orange"
											*ngIf="leaderData.duration.from_date != ''">
											{{leaderData?.duration?.from_date | date : 'd MMMM, y'}} 
										</span>
										<span [ngClass]="{ 'font-size-15': isPrint == 'true' }"  class="date text-bold" *ngIf="leaderData.duration.from_date != ''">
											To
										</span>
										<span [ngClass]="{ 'font-size-15': isPrint == 'true' }"  class="date text-bold" *ngIf="leaderData.duration.from_date == ''">
											Till Date
										</span>
										<span [ngClass]="{ 'font-size-15': isPrint == 'true' }"  class="date text-bold text-orange">
											{{leaderData?.duration?.to_date | date : 'd MMMM, y'}}
										</span>
									</h5>
									<button [ngClass]="{'d-none': isPrint == 'true'}" title="Custom Search" class="btn print-d-none  save-as text-bold bg-whiteSmoke "
										type="button"
										(click)="openAdvanceSearchModal()" >
										<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
									</button>
								</div>
							</ng-container>								
						</div>
					</span>
				</div>
				<div class="col-sm-4 d-flex align-item-center justify-content-end">
					<div class="text-right mt-2" *ngIf="isPrint">
					  <span [ngClass]="{ 'font-size-12': isPrint == 'true' }" class="d-block text-black">{{currentDate | date : 'dd/MM/yyyy'}}</span>
					  <span [ngClass]="{ 'font-size-12': isPrint == 'true' }" class="d-block text-grey">{{currentTime}}</span>
					</div>
					
					<div
			class="page-search-box input-group d-flex align-item-center" 
      [ngClass]="{ 'd-none': isPrint == 'true' }"
			style="height: fit-content; text-align: left"
		  >
					<input
					  type="text"
					  class="form-control"
					  oninput="this.value = this.value.toUpperCase()"
					  placeholder="search"
					  style="text-align: left"
					/>
				  </div>
				  <div class="dropdown-btn position-relative" 
				  [ngClass]="{ 'd-none': isPrint == 'true' }">
						<button title="More..." class="btn-style mt-0 more-btn">
						  <span class="dot"></span><span class="dot"></span
						  ><span class="dot"></span>
						</button>
						<ul class="list-unstyled pl-0 dropdown-box">
						  <li  (click)="openModal()">
							<img
							  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-emal.svg"
							  alt=""
							/>
							<span class="ml-2">Email</span>
		  
							<button
							hidden
							#openEmailModal
							data-target="#emailModalInDetailed"
							data-toggle="modal"
						  ></button>
						  </li>
						  
						  <li (click)="openSavePdfDetailedModal()">
							<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg" alt="" />
							<span class="ml-2">Save As Pdf</span>
						  </li>
						  <li (click)="exportAsXLSX(leaderData?.duration?.from_date  ,leaderData?.duration?.to_date)" >
							
							<span class="ml-2">Excel</span>
						  </li>
						</ul>
					  </div>
					<!-- <div class="save-button d-flex pull-right mt-2 align-items-center" [ngClass]="{'d-none': isPrint == 'true'}">
						<button class="btn mr-1  save-as text-bold bg-whiteSmoke text-green dropdown-toggle"
							type="button" (click)="openModal()">
							<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/blue-email.svg" alt="">
							<span class="ml-1 text-blue">Email To</span>
						</button>
						<button hidden #openEmailModal data-target="#emailModal" data-toggle="modal"></button>
						<div class="dropdown">
							<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-download.svg" alt="">
								<span class="ml-1 text-green">Save As</span>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
								<a class="dropdown-item"
									(click)="exportAsXLSX(leaderData?.duration?.from_date  ,leaderData?.duration?.to_date)">EXCEL</a>
								<a class="dropdown-item" href="#">CSV</a>
								<a class="dropdown-item"
									(click)="callPdfGeneratorApi(leaderData?.duration?.from_date  ,leaderData?.duration?.to_date)">PDF</a>
							</div>
						</div>
					</div> -->
				</div>
			</div>

			<div  class="table-section detledger-table" [ngClass]="{'table-print-section': isPrint == 'true'}">
				<table class="table table striped" >
					<thead>
						<tr *ngIf="isPrint" style="border-bottom: none!important;">
							<th colspan="11" class="page-top-space">
							</th>
						  </tr>
						<tr>
							<th class="text-left date" style="min-width: 105px;" >Date </th>
							<th class="text-left doc-no" style="min-width: 175px;" >Doc No.</th>
							<th class="text-left acc-decription">Account Description </th>

							<th class="text-left ">Voucher</th>
							<th *ngIf="supplier_trans=='true'" class="text-left ">Transaction Code</th>
							<th *ngIf="supplier_trans=='true'" class="text-left ">Supplier Date</th>

							<th class="text-right basic-amt">Basic </th>
							<th class="text-right taxes-amt">Taxes </th>
							<th class="text-right hc-amt">H/C </th>

							<th *ngIf="discount_add=='true'" class="text-right hc-amt">DISCOUNT </th>
							<th class="text-right tds-amt">TDS </th>
							<th class="text-right ser-amt">S. Ch</th>
							<th class="text-right gst-amt">GST </th>

							<th class="text-right debit">Debit</th>
							<th class="text-right credit">Credit</th>
							<th class="text-right balance " *ngIf="!isSubgrouping">Balance</th>

						</tr>
					</thead>
					<tbody class="scroll-body main-panel" [ngClass]="{'full-height-table': isPrint == 'true'}"  [ngStyle]="{height: innerHeight}"
						(window:keydown)="onKeyDown($event)">
						<tr class="opening-bal" *ngIf="isPrint">
							<td class="text-left text-grey date"  >{{ leaderData.duration.from_date| date: "dd/MM/yyyy"}}</td>
							<td class="text-left acc-decription"  ><p class="text-bold text-black undl mb-0">OPENING BALANCE</p></td>
							<td class="text-left text-grey doc-no"></td>
							
							<td class="text-left text-grey "  ></td>
							<td *ngIf="supplier_trans=='true'" class="text-left text-grey"></td>
							<td *ngIf="supplier_trans=='true'" class="text-left text-grey"></td>
							<td class="text-right basic-amt "  ></td>
							<td class="text-right taxes-amt "  ></td>
							<td class="text-right hc-amt "  ></td>
							<td class="text-right tds-amt "  ></td>
							<td class="text-text-right ser-amt"  ></td>
							<td class="text-right gst-amt"  ></td>
							<td class="text-right text-red debit" >
								<span class="bal-amount debit-amt text-bold"
								*ngIf="
								totalAmountObj && totalAmountObj.openingBalanceType == 'debit'
							  ">
							 {{totalAmountObj.openingBalance && totalAmountObj.openingBalance < 0 ?
								(totalAmountObj.openingBalance*-1 | number :'1.2-2' ) : totalAmountObj.openingBalance | number :'1.2-2'}}
								</span>
							</td>
							<td class="text-right text-green credit" >
								<span class="bal-amount text-green credit-amt text-bold"
								*ngIf="totalAmountObj && totalAmountObj.openingBalanceType == 'credit' ">
								{{totalAmountObj.openingBalance && totalAmountObj.openingBalance < 0 ?
									(totalAmountObj.openingBalance*-1 | number :'1.2-2') : totalAmountObj.openingBalance | number
									:'1.2-2'}} </span>
							</td>
							<td class="text-right text-green balance" *ngIf="!isSubgrouping" ></td>
						  </tr>
						<ng-container *ngFor="let data of leaderData['all_clients_records']">
							<ng-container *ngFor="let client_record of data['client_records']  let i = index">
								<span *ngIf="client_record['display_name']" class="client-name text-decoration">{{client_record['display_name']}}
								</span>
								<ng-container *ngIf="client_record?.records; else nonGrouped">
									<ng-container *ngIf="client_record['aggregated_name'] != 'Opening Balance'">
										<span class="client-name subgrouping-name text-decoration text-uppercase"><span>{{client_record['aggregated_name']}}</span>
									</span>
										<ng-container *ngFor="let record of client_record['records']  let j = index">
											<tr [attr.tabIndex]="i+j" id="rowIndex_{{i}}_{{j}}"
												(keyup.enter)="openViewMode(record.reference,record.reference_type, record.inv_reference_type)"
												[ngClass]="{'d-none': record.reference_type == 'opening_balance', 'borderOrange' : client_record?.total_debit > 0 ,'borderGreen' : client_record?.total_credit > 0}">
												
								  
												<td   (click)="moveToGroupedIndex(i,j)"
												class="text-left text-grey date " style="min-width: 105px;" >
													{{record?.date | date : 'dd/MM/yyyy'}}</td>
												<td (click)="moveToGroupedIndex(i,j)"
												class="text-left transition text-bold cursor doc-no" style="min-width: 175px;"><a
														[ngClass]="{'text-hyperlink': record.reference != null && record.reference != undefined}"
														(click)="openViewMode(record.reference,record.reference_type, record.inv_reference_type)">{{record?.reference}}</a>
														<a class="d-block" *ngIf="record?.x_field_1 && record.x_field_1!='null'"
														[ngClass]="{
														  ' underline-none':
															record.reference != null &&
															record.reference != undefined
														}"
														>{{record?.x_field_1}}</a>
												</td>
												<td 
													(click)="moveToGroupedIndex(i,j)"
													class="text-left text-grey acc-decription word-break">
													<ng-container *ngFor="let narration of record.narration">
														<ng-container *ngIf="narration.trim() != '' &&  narration.trim() != '|'">
														  <span class="text-grey d-block">{{ narration }} </span>
														</ng-container>
													  </ng-container>
												</td>
												<td  class="text-left text-grey" style="width: 180px;">
													<span  *ngIf="record?.inv_reference_type == 'event_invoice';else nonEventTransactions">
														{{ referenceTypeName[record?.inv_reference_type] ? referenceTypeName[record?.inv_reference_type] :  record?.inv_reference_type }}
													</span>
													<ng-template #nonEventTransactions >
														<span >
														{{ referenceTypeName[record?.reference_type] ? referenceTypeName[record?.reference_type] :  record?.reference_type }}
														</span>
													</ng-template>
												</td>
												<td  *ngIf="supplier_trans=='true'" class="text-left text-grey">{{record?.payable_transaction_code}}</td>
												<td  *ngIf="supplier_trans=='true'" class="text-left text-grey">{{record?.payable_date| date : 'dd/MM/yyyy'}}</td>
												<td (click)="moveToGroupedIndex(i,j)" class="text-right text-grey  basic-amt ">
													{{record?.base | number :'1.2-2' }}
												</td>
												<td (click)="moveToGroupedIndex(i,j)" class="text-right text-grey taxes-amt">
													{{record?.tax | number :'1.2-2' }}
												</td>
												<td (click)="moveToGroupedIndex(i,j)" class="text-right text-grey hc-amt">
													{{record?.commission | number :'1.2-2' }}</td>
													<td *ngIf="discount_add=='true'" (click)="moveToGroupedIndex(i,j)" class="text-right text-grey hc-amt">
														{{record?.discount | number :'1.2-2' }}</td>
												<td (click)="moveToGroupedIndex(i,j)" class="text-right text-grey tds-amt">
													{{record?.tds | number :'1.2-2'}}
												</td>
												<td (click)="moveToGroupedIndex(i,j)" class="text-right text-grey ser-amt">
													{{record?.service_charge | number :'1.2-2'}}</td>
												<td (click)="moveToGroupedIndex(i,j)" class="text-right text-grey gst-amt">
													{{record?.gst | number :'1.2-2' }}
												</td>
												<td  (click)="moveToGroupedIndex(i,j)"
													class="text-right text-grey debit">
													{{record?.amount_debit | number :'1.2-2' }}
												</td>
												<td  (click)="moveToGroupedIndex(i,j)"
												class="text-right text-grey credit">
													{{record?.amount_credit | number :'1.2-2' }}
												</td>
												
											</tr>
										</ng-container>
										<ng-container > 
											<tr class=" grand-total">
												<td class="text-left text-grey date " style="min-width: 105px;" ></td>
												<td class="text-left transition text-bold cursor doc-no" style="min-width: 175px;"></td>
												<td class="text-left text-grey acc-decription word-break text-bold">Sub Total:</td>
												<td class="text-left text-grey"></td>
												<td *ngIf="supplier_trans=='true'" class="text-left text-grey"></td>
												<td *ngIf="supplier_trans=='true'" class="text-left text-grey"></td>
												<td class="text-right text-black text-bold basic-amt ">{{client_record?.base | number : '1.2-2'}}</td>
												<td class="text-right text-black text-bold taxes-amt">{{client_record?.tax | number : '1.2-2'}}</td>
												<td class="text-right text-black text-bold hc-amt">{{client_record?.commission | number : '1.2-2'}}</td>
												<td *ngIf="discount_add=='true'" class="text-right text-black text-bold hc-amt">{{client_record?.discount | number : '1.2-2'}}</td>
												<td class="text-right text-black text-bold tds-amt">{{client_record?.tds | number : '1.2-2'}}</td>
												<td class="text-right text-black text-bold ser-amt">{{client_record?.service_charge | number : '1.2-2'}}</td>
												<td class="text-right text-black text-bold gst-amt">{{client_record?.gst | number : '1.2-2'}}</td>
												<td class="text-right text-black text-bold debit ">{{client_record?.total_debit | number : '1.2-2'}}</td>
												<td class="text-right text-black text-bold credit">{{client_record?.total_credit | number : '1.2-2'}}</td>
												
											</tr>
										</ng-container>
										<!-- <tr class="border-bottom"
											*ngIf="totalAmountObj != undefined && leaderData.all_clients_records[0].client_records.length > 0">

											<td  class=" text-grey" colspan="9" >
												<p class="m-0 text-right text-bold">DIFF: <span class="text-bold" [ngClass]="{'text-danger': totalAmountObj.type == 'DR.','text-success': totalAmountObj.type == 'CR.'}">
													{{totalAmountObj?.diff }}&nbsp;{{totalAmountObj?.type}}
												</span></p>
											</td>
											<td class="text-grey text-right text-grey debit grand">{{client_record?.total_debit | number : '1.2-2' }}
											</td>
											<td class="text-right text-grey credit  grand">{{client_record?.total_credit | number : '1.2-2'}}
											</td>
										</tr> -->
									</ng-container>
								</ng-container>
								<ng-template #nonGrouped>
									<tr [attr.tabIndex]="i" id="rowIndex_{{i}}"
										(keyup.enter)="openViewMode(client_record.reference,client_record.reference_type, client_record.inv_reference_type)"
										class="{{client_record.reference_type}}"
										[ngClass]="{'d-none': client_record.reference_type == 'opening_balance', 'borderOrange' : client_record?.amount_debit > 0 ,'borderGreen' : client_record?.amount_credit > 0}">
										<td  (click)="moveToIndex(i)" class="text-left text-grey date " style="min-width: 105px;">
											{{client_record?.date
											| date : 'dd/MM/yyyy'}}
										<ng-container *ngIf="i == data['client_records'].length - 1">
											{{dataLoadedFn(i)}}
										</ng-container>
										</td>
										<td   (click)="moveToIndex(i)"
										class="text-left transition text-bold cursor doc-no" style="min-width: 175px;"><a
												[ngClass]="{'text-hyperlink': client_record.reference != null && client_record.reference != undefined}"
												(click)="openViewMode(client_record.reference,client_record.reference_type, client_record.inv_reference_type)">{{client_record?.reference}}</a>
												<a class="d-block" *ngIf="client_record?.x_field_1 && client_record.x_field_1 !='null' ">{{client_record?.x_field_1}}</a>
												
										</td>
										<td (click)="moveToIndex(i)"
											class="text-left text-grey acc-decription word-break">
											<ng-container *ngFor="let narration of client_record.narration">
												<ng-container *ngIf="narration.trim() != '' &&  narration.trim() != '|'">
												  <span class="text-grey d-block">{{ narration }} </span>
												</ng-container>
											  </ng-container>
										</td>
										<td  class="text-center text-grey ">
											<!-- INV -->
											<span  *ngIf="client_record?.inv_reference_type == 'event_invoice';else nonEventTransactions">
												{{ referenceTypeName[client_record?.inv_reference_type] ? referenceTypeName[client_record?.inv_reference_type] :  client_record?.inv_reference_type }}
											</span>
											<ng-template #nonEventTransactions >
												<span >
												{{ referenceTypeName[client_record?.reference_type] ? referenceTypeName[client_record?.reference_type] :  client_record?.reference_type }}
												</span>
											</ng-template>
										</td>
										<td  *ngIf="supplier_trans=='true'" class="text-center text-grey ">{{client_record?.payable_transaction_code}}</td>
										<td  *ngIf="supplier_trans=='true'" class="text-center text-grey ">{{client_record?.payable_date| date : 'dd/MM/yyyy'}}</td>
										<td (click)="moveToIndex(i)" class="text-right text-grey  basic-amt ">{{client_record?.base | number :'1.2-2'
											}}</td>
										<td (click)="moveToIndex(i)" class="text-right text-grey taxes-amt">{{client_record?.tax | number :'1.2-2'
											}}
										</td>
										<td (click)="moveToIndex(i)" class="text-right text-grey  hc-amt">
											{{client_record?.commission | number :'1.2-2' }}
										</td>
										<td *ngIf="discount_add=='true'" (click)="moveToIndex(i)" class="text-right text-grey  hc-amt">
											{{client_record?.discount | number :'1.2-2' }}
										</td>
										<td (click)="moveToIndex(i)" class="text-right text-grey  tds-amt">{{client_record?.tds | number :'1.2-2'
											}}
										</td>
										<td (click)="moveToIndex(i)" class="text-right text-grey ser-amt">
											{{client_record?.service_charge | number :'1.2-2'
											}}</td>

										<td (click)="moveToIndex(i)" class="text-right text-grey  gst-amt">{{client_record?.gst | number :'1.2-2'
											}}
										</td>
										<td  (click)="moveToIndex(i)"
											class="text-right text-red debit">{{client_record?.amount_debit | number :'1.2-2'
											}}</td>
										<td  (click)="moveToIndex(i)"
											class="text-right text-green credit">
											{{client_record?.amount_credit | number :'1.2-2' }}</td>

											<td class="text-right text-green balance" *ngIf="!isSubgrouping" >
												<span class="bal-amount text-green credit-amt "
												>
												{{ client_record?.balance | number :'1.2-2'}}</span>
											</td>
									</tr>

								</ng-template>


							</ng-container>
							<!-- <tr class="border-bottom"
								*ngIf="totalAmountObj != undefined && leaderData.all_clients_records[0].client_records.length > 0">

								<td class=""></td>
								<td class=""></td>
								<td class=""></td>
								<td class=""></td>
								<td class=""></td>
								<td class=""></td>
								<td class=""></td>
								<td class=""></td>
								<td class="text-right grand">
									<div class="d-flex justify-content-center">
											Diff: &nbsp;
											<span [ngClass]="{'text-danger': totalAmountObj.type == 'DR.','text-success': totalAmountObj.type == 'CR.'}">
												{{totalAmountObj?.diff }}&nbsp;{{totalAmountObj?.type}}
											</span>
										</div>
								</td>
								<td class="text-right text-red grand">{{totalAmountObj?.amount_debit | number : '1.2-2' }}</td>
								<td class="text-right text-green grand">{{totalAmountObj?.amount_credit | number : '1.2-2'}}
								</td>
							</tr> -->

						</ng-container>
						<tr class="opening-bal" *ngIf="isPrint">
							<td class="text-left text-grey date"  ></td>
							<td class="text-left text-grey doc-no"    ><p class="text-bold text-black mt-2 mb-0">SUBTOTAL </p></td>
							<td class="text-left acc-decription"  ></td>
							<td class="text-right basic-amt "  ></td>
							<td class="text-right taxes-amt "  ></td>
							<td class="text-right hc-amt "  ></td>
							<td class="text-right gst-amt"  ></td>
							<td class="text-text-right ser-amt"  ></td>
							<td class="text-left text-grey "  ></td>
							<td class="text-right tds-amt "  ></td>
							<td class="text-right text-red ledger-subtotal debit" >
								<span class="bal-amount debit-amt text-bold">
									{{ totalAmountObj?.debit | number: "1.2-2" }}
								  </span>
							</td>
							<td class="text-right text-green credit ledger-subtotal" >
								<span class="bal-amount credit-amt text-bold">
									{{ totalAmountObj?.credit | number: "1.2-2" }}
								  </span>
							</td>

							<td class="text-right text-green balance" *ngIf="!isSubgrouping">
							
							</td>
						  </tr>
						<tr class="closing-bal" *ngIf="isPrint">
							<td class="text-left text-grey date" >{{ leaderData.duration.to_date| date: "dd/MM/yyyy"}}</td>
							<td class="text-left text-grey acc-decription " colspan="2" ><p class="text-bold text-black undl mb-0">CLOSING BALANCE</p></td>
							<td class="text-left text-grey doc-no " ></td>
							<td class="text-left text-grey basic-amt" ></td>
							<td class="text-left text-grey taxes-amt" ></td>
							<td class="text-left text-grey  hc-amt" ></td>
							<td class="text-left text-grey  tds-amt" ></td>
							<td class="text-left text-grey ser-amt" ></td>
							<td class="text-left text-grey " ></td>
							<td class="text-left text-grey gst-amt" ></td>
							<td class="text-right text-red debit"  >
								<span class="bal-amount debit-amt text-bold"
							*ngIf="totalAmountObj && totalAmountObj.type == 'DR.' else zeroAmount">
							 {{totalAmountObj.diff && totalAmountObj.diff < 0 ? totalAmountObj.diff*-1 :
								totalAmountObj.diff | number :'1.2-2'}} </span>
							</td>
							<td class="text-right text-green credit"  >
								<span class="bal-amount text-green credit-amt text-bold"
									*ngIf="totalAmountObj && totalAmountObj.type == 'CR.' else zeroAmount">
									 {{totalAmountObj.diff && totalAmountObj.diff < 0 ? totalAmountObj.diff*-1 :
										totalAmountObj.diff | number :'1.2-2'}} </span>
							</td>
							<td class="text-right text-green balance" *ngIf="!isSubgrouping" >
							
							</td>
						  </tr>
						  <tr class="" *ngIf="isPrint">
							<td colspan="3" class="text-left text-grey " style="width: auto!important;
							max-width: unset!important; min-width: 300px!important;" >
							  <p class="text-bold text-black mb-0" *ngIf="
							  totalAmountObj && totalAmountObj.type == 'DR.';
							">Amount Receivable: <span class="ml-2 text-bold" *ngIf="totalAmountObj.diff > 0" >{{totalAmountObj.diff | number :'1.2-2'}}</span></p>
							<p class="text-bold text-black mb-0" *ngIf="
              				  totalAmountObj && totalAmountObj.type == 'CR.';
              				">Amount Payable: <span class="ml-2 text-bold" *ngIf="totalAmountObj.diff > 0" >{{totalAmountObj.diff | number :'1.2-2' }}</span></p>
							</td>
							<td class="text-left text-grey" ></td>
							<td class="text-left text-grey" ></td>
							<td class="text-left text-grey" ></td>
							<td class="text-left text-grey" ></td>
							<td class="text-left text-grey" ></td>
							<td class="text-right ledger-subtotal text-red "  >
							 
							</td>
							<td class="text-right ledger-subtotal text-green "  >
							  
							</td>
							<td class="text-right text-green balance" *ngIf="!isSubgrouping" >
							
							</td>
						  </tr>
						  <tr  *ngIf="isPrint" style="    border-bottom: none!important;">
							<td colspan="13" class="text-right text-grey" >
							  <h5 class="mb-0 text-bold" style="font-size: 16px;">For {{leaderData?.company_details?.name}}</h5>
							</td>
						  </tr>
						<tr *ngIf="leaderData.all_clients_records[0].client_records.length  == 0">
							<div class="w-100 text-center" style="padding: 20px;">
								<span><img class="not-found-img" src="{{commonService.aws_asset_url}}/_assets/images/problem.png"></span> <br>
								<span class="not-found-text">No matching items found</span>
							</div>
						</tr>
					</tbody>
				</table>
			</div>
			<div id="print-section" class="balance-box ledger-table-box" *ngIf="!isPrint && !queryParams.subgroup_type">
				<div></div>
				<div class="balance-container" [ngClass]="{'marigin-right': !isSubgrouping}" >
					<div class="bal-item text-bold "><span>Opening Balance</span>
						<span class="bal-amount debit-amt text-bold"
							*ngIf="totalAmountObj && totalAmountObj.openingBalanceType == 'debit' else zeroAmount">

							 {{totalAmountObj.openingBalance && totalAmountObj.openingBalance < 0 ?
								(totalAmountObj.openingBalance*-1 | number :'1.2-2' ) : totalAmountObj.openingBalance | number :'1.2-2'}}
								</span>
								<span class="bal-amount text-green credit-amt text-bold"
									*ngIf="totalAmountObj && totalAmountObj.openingBalanceType == 'credit' else zeroAmount">
									 {{totalAmountObj.openingBalance && totalAmountObj.openingBalance < 0 ?
										((totalAmountObj.openingBalance*-1 | number :'1.2-2') | number :'1.2-2') : totalAmountObj.openingBalance | number
										:'1.2-2'}} </span>
					</div>
					<div class="bal-item text-bold"><span>Current Balance</span>
						<span class="bal-amount debit-amt text-bold">
							 {{totalAmountObj?.debit | number : '1.2-2' }}
						</span>
						<span class="bal-amount text-green credit-amt text-bold">
							 {{totalAmountObj?.credit | number : '1.2-2'}}
						</span>
					</div>
					<div class="bal-item  text-bold "><span>Closing Balance</span>
						<span class="bal-amount debit-amt text-bold"
							*ngIf="totalAmountObj && totalAmountObj.type == 'DR.' else zeroAmount">
							 {{totalAmountObj.diff && totalAmountObj.diff < 0 ? (totalAmountObj.diff*-1 | number :'1.2-2') :
								totalAmountObj.diff | number :'1.2-2'}} </span>
								<span class="bal-amount text-green credit-amt text-bold"
									*ngIf="totalAmountObj && totalAmountObj.type == 'CR.' else zeroAmount">
									 {{totalAmountObj.diff && totalAmountObj.diff < 0 ? (totalAmountObj.diff*-1 | number :'1.2-2') :
										totalAmountObj.diff | number :'1.2-2'}} </span>
					</div>

					
				</div>
			</div>
			<ng-template #zeroAmount>
				<span class="bal-amount text-green text-bold credit-amt ">
					 {{0 | number :'1.2-2'}}
				</span>
			</ng-template>
		</div>
	</div>
	<!-- Email Modal for sending mail -->
	<div class="modal" id="emailModalInDetailed" role="dialog" aria-labelledby="emailModalLabel" data-backdrop="false">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header ">
					<div class="d-flex justify-content-center">
						<h4 class="modal-title ">Send Email</h4>
					</div>
					<button type=" button" class="close" #closeSendEmailModal data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body emailSenders">

					<!-- <div class="d-flex justify-content-end align-item-center">
							<span *ngIf="!ccEmail" (click)="showCCEmail()" class="text-size-14 mb-0 cursor  mr-2">Cc</span>
							<span *ngIf="!bccEmail" (click)="showBCCEmail()" class="text-size-14 mb-0 cursor">Bcc</span>
						</div> -->
					<form [formGroup]="sendEmailForm" style="margin-top: -10px;">
						<div class="form-group text-input-field">
							<textarea class="input-field ng-pristine ng-valid ng-touched text-size-12" id="toEmail"
								rows="3" formControlName="toEmails" required></textarea>
							<label for="To" class=" mb-0">To</label>

						</div>
						<div class="d-flex justify-content-end">
							<!-- <label for="To" class="text-size-14 mb-0">To</label> -->
							<div *ngIf="sendEmailForm.controls.toEmails.invalid">
								<div *ngIf="sendEmailForm.controls.toEmails.errors?.required" style="color:red">
									To Email is required.
								</div>
							</div>
						</div>


						<div class="form-group text-input-field mt-0">
							<textarea class="input-field ng-pristine ng-valid ng-touched text-size-12" id="ccEmail"
								rows="3" formControlName="ccEmails"></textarea>
							<label for="Cc" class=" mb-0">Cc</label>
						</div>

						<div class="form-group text-input-field">
							<textarea class=" input-field ng-pristine ng-valid ng-touched text-size-12" id="bccEmail"
								rows="3" formControlName="bccEmails"></textarea>
							<label for="Bcc" class="mb-0">Bcc</label>
						</div>

						<div class="text-input-field">
							<input formControlName="subject"  class="input-field text-size-12" type="text" 
							autocomplete="off" />
							<label>Subject</label>
						</div>
						
						<div class="form-group text-input-field">
							<textarea
							  class="input-field ng-pristine ng-valid ng-touched text-size-12"
							  rows="5"
							  formControlName="body"
							></textarea>
							<label for="body" class="mb-0">Mail Body</label>
						  </div>
						  

						<div class="d-flex justify-content-center align-item-center">
							<button type="button" class="btn-style  mr-1" data-dismiss="modal">Cancel</button>
							<button type="button" class="btn-style bg-green" (click)="sendEmails()">Send</button>
						</div>

					</form>
				</div>
			</div>
		</div>
	</div>

	<button type="button" hidden #openPdfDetailModal data-toggle="modal" data-target="#pdfModal"></button>
              
<div class="modal fade " id="pdfModal" role="dialog" aria-hidden="true"
  data-backdrop="false">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
	  
        <h4 style="margin-bottom: 0px;">Confirmation</h4>
        <button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex">
		<div><img src="https://systacc-assets.s3.ap-south-1.amazonaws.com/_assets/images/direct-download.png" width="30px" height="30px"/></div>
        <p style="margin-left:15px;" class="text-uppercase text-black">This file contains extensive data.,<br>Would you prefer to schedule a download for it?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-2" (click)="onNoClickPdfDetailModal()">No</button>
        <button type="button" class="btn-style bg-blue" (click)="onYesClickPdfDetailModal()">Yes</button>
      </div>
    </div>
  </div>
</div>



</div>

