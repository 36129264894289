import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DayBookReportComponent } from './day-book-report.component'

const routes: Routes = [
  {
    path: '' , component: DayBookReportComponent,
    // children:[
    //   {path: 'day-book-report', component: DayBookReportComponent},
    //   {
    //     path: '',
    //     redirectTo: 'day-book-report',
    //     pathMatch: 'full'
    //   }
    // ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DayBookReportRoutingModule {

 }
