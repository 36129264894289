<ngx-spinner>
    <ng-container *ngIf="loaderTemplate">
        <span [innerHTML]="loaderTemplate"></span>
    </ng-container>
</ngx-spinner>
<div class="app-layout">
    <div class="header print-d-none" [ngClass]="{'d-none full-width-sidebar': !showHeader}">
        <!-- <div class="switch-notification" >
            <div class=" d-flex justify-content-between align-items-center">               
                <p class="m-0">
                    <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/sad-icon.svg" width="20px" height="20px" alt="" srcset="" class="mr-2">
                    Facing troubles with SYSTACC beta, <span (click)="switchVersion(false)" class="text-underline cursor-pointer">Switch to old version</span>
                </p>
                <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/active-close.svg" width="15px" height="15px" alt="" class="cursor-pointer" srcset="">

                 </div>
        </div> -->
        <app-header [hasAccessToken]="hasAccessToken" (callSwitchVersion)="switchVersion($event)" (callShowNotification)="showNotificationAreaFn()" [notificationCount]="notificationCount"></app-header>
    </div>
    <div class="d-flex ml-0 mr-0 menues-page-content">
        <div class="print-d-none  menues-bar p-0" [ngClass]="{'d-none': !hasAccessToken}" *ngIf="!showTabs"> 
            <app-left-sidebar >
            </app-left-sidebar>
        </div>
        <div class="page-content w-100per  p-0 " [ngClass]="{'height-100vh': showHeader == false,'no-left-sidebar' : showTabs}">
            <app-notification-area *ngIf="showNotificationArea" (callShowNotification)="showNotificationAreaFn()"
                [notificationArr]="notificationArr"></app-notification-area>
            <ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
            <ng-keyboard-shortcuts-help [key]="'f1'" [closeKey]="'escape'"></ng-keyboard-shortcuts-help>
            <!-- notification toast -->



<div class="toasts-container" *ngIf="newNotification && newNotification.length > 0">

    <div *ngFor="let item of newNotification" [ngClass]="{show: newNotification && newNotification.length > 0}" id="toast">
        <div class="d-flex">
            <div id="img">
                <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/white-bell-icon.svg" width="20px" height="20px" alt="" srcset="">
            </div>
            <div id="desc">
                <h4 class="cursor hover-text-primary">{{item.message}}</h4>
                <div class="d-flex justify-content-between align-item-center">
                    <p><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/grey-time.svg" width="10px" height="10px"
                            style="object-fit: contain;" alt="" srcset=""> <small> {{item.timestamp | date : 'dd/MM/yyyy HH:mm'}}</small></p>
                    <small (click)="closeToast(item)" class="text-red cursor close-btn">Close</small>
                </div>

            </div>
        </div>
    </div>
</div>


           

            <!-- <ng-container *ngFor="let item of tabs; let i = index;">

                <ng-container *ngIf="selectedTab == 0;else tabComponent">
                    <router-outlet></router-outlet>
                </ng-container>
                <ng-template #tabComponent>

                </ng-template>
            </ng-container> -->
            <div [ngClass]="{'d-none': showTabs}">
                <router-outlet></router-outlet>
            </div>
            <div [@.disabled]="true" [ngClass]="{'d-none': !showTabs}">
                <mat-tab-group 
                    class="header-less-tabs"
                    [selectedIndex]="selectedTab" >
                <mat-tab 
                    *ngFor="let tab of tabs; let tabIndex = index">
                    <div>
                        <!-- <app-invoice [data]="tab.tabData"></app-invoice> -->
                        <app-tab-content [tab]="tab"></app-tab-content>
                    </div>
                    
                </mat-tab>
            </mat-tab-group>
            </div>
        </div>
    </div>

</div>

          
<button type="button" hidden data-toggle="modal" #globalDateModal data-target="#globalDateFilterModal"></button>
<div id="globalDateFilterModal" class="modal fade" role="dialog" data-backdrop="true">
    <div class="modal-dialog" *ngIf="dateFilterForm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Please enter the dates.</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="dateFilterForm">
                    <div class="flexbox aff-info">
                        <div class="flexbox aff-info-inner">
                            <div class="flexbox mb-3 justify-content-between">
                                <div class="input-fiel mr-3">
                                    <input class="input-ui" #startDateInput formControlName="start_date" id="from_date" type="date"
                                        min="{{minDate}}" max="{{maxDate}}"
                                        searchtabindex="1"   />
                                    <label for="from_date">Starting Date</label>
                                </div>
                                <div class="input-fiel">
                                    <input class="input-ui" formControlName="end_date" id="to_date" type="date" searchtabindex="2" 
                                    min="{{minDate}}" max="{{maxDate}}"
                                        />
                                    <label for="to_date">Ending Date</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-style bg-purple" searchtabindex="3" [disabled]="dateFilterForm.invalid"
                    data-dismiss="modal" id="submitSearchForm" (click)="onSearchDates()"> Search</button>
            </div>
        </div>
    </div>
</div>
