import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, Validators, FormBuilder, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment'
import { FormCanDeactivate } from 'src/app/shared/form-can-deactivate/form-can-deactivate';
import { CookiesService } from 'src/app/shared/cookies.service';


@Component({
	selector: 'app-update-supplier',
	templateUrl: './update-supplier.component.html',
	styleUrls: ['./update-supplier.component.scss']
})
export class UpdateSupplierComponent extends FormCanDeactivate implements OnInit {
	@Input() data
	@ViewChild('form')
 	form: NgForm;
	supplierId: any;

	submitted = false;
	baseUrl = environment.okountUrl;
	supplierForm: FormGroup;
	supplierContactForm: FormGroup;
	groupList: any;

	alertType = '';
	message: string = ``;
	countryArr: any = [];
	stateArr: any = [];
	cityArr: any = [];
	remainingAmount = 0
    amountType = 'credit'
	allContactsForm: FormGroup;
	selectedSupplier : any;
	supplierContacts: any = [];

	showMessage: boolean = false;
	supplierData: any;
	isTenantIndian: boolean = false;
	branches: any = []
	isHQBranch: boolean = false;
	vatAlternetname: string = 'VAT'

	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef
	@ViewChild('confirmDeleteModal',{static:true}) confirmDeleteModal : ElementRef
	@ViewChild('closeDeleteModal',{static:true}) closeDeleteModal : ElementRef
	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef
	@ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef
	@ViewChild('openContactDetail',{static:true}) openContactDetail : ElementRef
	@ViewChild('closeContactDetail',{static:true}) closeContactDetail : ElementRef
	@ViewChild('addEditContact', {static: true}) addEditContact : ElementRef
	@ViewChild('closeEditContact',{static:true}) closeEditContact : ElementRef
	@ViewChild('confirmOBModal',{static:true}) confirmOBModal : ElementRef;
    @ViewChild('closeOBModal',{static:true}) closeOBModal : ElementRef;


	constructor(
		public commonService: CommonServiceService,
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private spinner: NgxSpinnerService,
		private cookieService : CookiesService
	) {
		super()
		this.route.paramMap.subscribe(params => {
			this.supplierId = params['params'].id
		});

		this.supplierForm = this.fb.group({
			parent_id: ['', Validators.required],
			address_1: [''],
			city_id: [''],
			city_name: [''],
			state_id: [''],
			state_name: [''],
			country_id: [''],
			country: [''],
			gst_number: [''],
			gst_expiry_date: [''],
			vat_number: [''],
			telephone: [''],
			mobile_no: [''],
			primary_email: [''],
			secondary_email: [''],
			pan_number: [''],
			remarks: [''],
			balance: [''],
			name: ['', Validators.required],
			status: [''],
			balance_type: [''],
			is_card_account : [false],
			currency_rate:[''],
			local_balance: [''],
			currency:[''],
			branch:{}

		})

		this.supplierContactForm = this.fb.group({
			account_code: ['',  Validators.required],
			type: ['', Validators.required],
			mobile: ['', Validators.required],
			email: ['', ],
			description: [''],
			selectedSupplier: [''],
			contact_person: [''],
			address: ['']
		})

	}

	async ngOnInit() {
		
		this.isTenantIndian = this.commonService.checkTenantIndian()
		if(this.data){
			this.supplierId = this.data.supplier_id
		}
		if (this.supplierId != undefined) {
			this.getSupplier()
		}

		this.isHQBranch = this.commonService.checkHQBranch()
		if(this.isHQBranch==true){
			this.getAllBranches()
			
			// this.branchForm = this.fb.group({
			// 	formObj
			// })
		}

		if(!this.isTenantIndian){
			let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
			let inv_config = globalSettingConfig.filter(el=>el.group=='VAT')
		if(inv_config.length){
			let vat_config = inv_config[0].json_doc.value.filter(el=>el.key=='vat_rate')
			let vat_detail,vat_name
			if(vat_config.length){
				 vat_detail = vat_config[0].value
			}
			if(vat_detail && vat_detail.length ){
				vat_name = vat_detail.filter(el=>el.key=='effective_name')
			}
			if(vat_name && vat_name.length){
				this.vatAlternetname = vat_name[0].value
			}
		}
		}
	}

	openConfirmModal() :  void{
		this.hideError()
		this.confirmModal.nativeElement.click()
	}
	closeConfirmModal() :  void{
		this.closeModal.nativeElement.click()
	}

	getSupplier() {
		this.commonService.getOkount(`supplier?supplier_id=${this.supplierId}`).subscribe(async (res) => {
			if (res[0] != undefined) {
				this.supplierData = res[0]
			}
			if(this.supplierData) {
				this.getSupplierContacts()
			}
			console.log(this.supplierData)
			await this.getGroups()
			await this.getCountries()
			await this.getStates(this.supplierData.country_id)
			await this.getCities(this.supplierData.state_id)
			await this.getCityName(this.supplierData.city_id)
			await this.setSupplierData()
		})
	}
	setSupplierData() {
		if (this.supplierForm != undefined && this.supplierData != undefined) {
			this.supplierForm.controls.parent_id.setValue(this.supplierData.parent_id)
			this.supplierForm.controls.address_1.setValue(this.supplierData.address_1)
			this.supplierForm.controls.city_id.setValue(this.supplierData.city_id)
			this.supplierForm.controls.city_name.setValue(this.supplierData.city_name)
			this.supplierForm.controls.state_id.setValue(this.supplierData.state_id)
			this.supplierForm.controls.state_name.setValue(this.supplierData.state_name)
			this.supplierForm.controls.country_id.setValue(this.supplierData.country_id)
			this.supplierForm.controls.country.setValue(this.supplierData.country)
			this.supplierForm.controls.gst_number.setValue(this.supplierData.gst_number)
			if (this.supplierData.gst_expiry_date != null && this.supplierData.gst_expiry_date != '') {
				this.supplierForm.controls.gst_expiry_date.setValue(moment(this.supplierData.gst_expiry_date).format('YYYY-MM-DD'))
			}
			this.supplierForm.controls.vat_number.setValue(this.supplierData.vat_number)
			this.supplierForm.controls.telephone.setValue(this.supplierData.telephone)
			this.supplierForm.controls.mobile_no.setValue(this.supplierData.mobile_no)
			this.supplierForm.controls.primary_email.setValue(this.supplierData.primary_email)
			this.supplierForm.controls.secondary_email.setValue(this.supplierData.secondary_email)
			this.supplierForm.controls.pan_number.setValue(this.supplierData.pan_number)
			this.supplierForm.controls.balance.setValue(this.supplierData.balance)
			this.supplierForm.controls.remarks.setValue(this.supplierData.remarks)
			this.supplierForm.controls.name.setValue(this.supplierData.name)
			this.supplierForm.controls.status.setValue(this.supplierData.status)
			this.supplierForm.controls.balance_type.setValue(this.supplierData.balance_type)
			this.supplierForm.controls.currency.setValue(this.supplierData.currency)
			this.supplierForm.controls.currency_rate.setValue(this.supplierData.currency_rate)
			this.supplierForm.controls.local_balance.setValue(this.supplierData.local_balance)
			this.supplierForm.controls.is_card_account.setValue(String(this.supplierData.is_card_account))

		}
	}

	getCountries() {
		debugger
		this.commonService.getOkount(`location/country`).subscribe(res => {
			this.countryArr = res
		})
	}

	getStates(country_id) {
		let selectedCountry = this.countryArr.filter(option => {
			return option.location_id == Number(country_id);
		})
		if (selectedCountry.length > 0) {
			this.supplierForm.controls.country.setValue(selectedCountry[0].name)
		} else {
			this.supplierForm.controls.country.setValue('India')
		}
		console.log(selectedCountry)
		this.commonService.getOkount(`location/states?country_id=${country_id}`).subscribe(res => {
			this.stateArr = res
		})
	}
	getCities(state_id) {
		let selectedState = this.stateArr.filter(option => {
			return option.location_id == Number(state_id);
		})
		if (selectedState.length > 0) {
			this.supplierForm.controls.state_name.setValue(selectedState[0].name)
		}
		this.commonService.getOkount(`location/city?state_id=${state_id}`).subscribe(res => {
			this.cityArr = res
		})
	}
	getCityName(city_id) {
		let selectedCity = this.cityArr.filter(option => {
			return option.location_id == Number(city_id);
		})
		if (selectedCity.length > 0) {
			this.supplierForm.controls.city_name.setValue(selectedCity[0].name)
		}
	}

	openUpdateConfirmModal(){
		this.confirmUpdateModal.nativeElement.click()
		this.commonService.selectedInput.next(21)
	}

	closeUpdateConfirmModal(){
		this.closeUpdateModal.nativeElement.click()
	}

	updateSupplier() {

		this.submitted = false;
		this.closeUpdateConfirmModal()
		switch (this.supplierForm.value.is_card_account) {
			case "true":
				this.supplierForm.value.is_card_account = true
				break;
			case "false":
				this.supplierForm.value.is_card_account = false
				break;
			default:
				break;
		}
		if (this.supplierForm.valid) {
			this.submitted = true;
			this.commonService.putRequest(`${this.baseUrl}/supplier?supplier_id=${this.supplierId}`, this.supplierForm.value).subscribe(res => {
				this.updateOpeningBalanceAdjustAcc()
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = 'Supplier updated successfully.'
			}, error => {
				this.showMessage = true
				this.alertType = 'error-box';
				this.message = 'Failed to update Supplier. Please try Again.'
			})
		}
		else {
			this.submitted = true;
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = 'Please fill mandatory fields.'
			return false;
		}
	}

	getGroups() {
		this.commonService.getOkount('v3account/getallsuperparents').subscribe(res => {
			this.groupList = res
			console.log('Group list : ', this.groupList)
		}, err => {

		})
	}
	hideError() {
		this.showMessage = false
	}

	closeWindow() {
		window.close();
	}


	openDeleteConfirmModal(){
		this.confirmDeleteModal.nativeElement.click()
		this.commonService.selectedInput.next(23)
	}

	closeDeleteConfirmModal(){
		this.closeDeleteModal.nativeElement.click()
	}


	deleteSupplier(){
		// this.submitted = true;
		let supplieRid = this.supplierData.account_code
		this.closeDeleteConfirmModal()
		if(supplieRid != ''){
			this.commonService.deleteRequest(`${this.baseUrl}/supplier?account_code=${supplieRid}`).subscribe((res: any) => {
				this.supplierForm.reset()
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = `Supplier Deleted.`
				this.spinner.hide();
			},
				err => {
					this.showMessage = true
					this.alertType = 'error-box';
					this.message = `Failed to delete supplier.`
					this.spinner.hide();
				})
		} else {
			this.supplierForm.reset()
		}
	}

	openConfiguration(supplier, supplier_code) {
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/master/setting/supplier/${supplier && supplier.split('(')[0]}/${supplier_code}`
		window.open(path,'_blank')
	}
	openActivity(){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/supplier/${this.supplierData.account_code}`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}


	openAddEditContactModal() {
		this.addEditContact.nativeElement.click()
	}

	saveContact() {
		let account_code = this.supplierData.account_code
		console.log("account_code",account_code)
		this.supplierContactForm.controls.account_code.setValue(account_code)
		this.commonService.postRequest(`${this.baseUrl}/supplier/contacts`, this.supplierContactForm.value).subscribe(res => {
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = 'Address added successfully.'
			this.getSupplierContacts()
			this.closeContactDetail.nativeElement.click()
		}, error => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Failed to add address for the supplier. Please try Again.'
		})
	}

	updateContact() {
		this.commonService.putRequest(`${this.baseUrl}/supplier/contacts?contact_id=${this.selectedSupplier.id}`, this.supplierContactForm.value).subscribe(res => {
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = 'Address updated successfully.'
			this.getSupplierContacts()
			this.closeContactDetail.nativeElement.click()
		}, error => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Failed to update address for the supplier. Please try Again.'
		})	
	}


	getSupplierContacts() {
		let account_code = this.supplierData.account_code
		this.commonService.getOkount(`supplier/contacts?account_code=${account_code}`).subscribe((res) => {
			if (res != undefined) {
				this.supplierContacts = res['data']
				console.log("supplierContacts", this.supplierContacts)
			}
		})
	}

	submitAddEditContact() {
		    let account_code = this.supplierData.account_code
			let value = this.supplierContactForm.controls.selectedSupplier.value
			this.closeEditContact.nativeElement.click()

			if(value == '' || value == null || value == undefined ) {
				this.supplierContactForm.controls.account_code.setValue(account_code)
				this.supplierContactForm.controls.type.setValue("")
				this.supplierContactForm.controls.mobile.setValue("")
				this.supplierContactForm.controls.email.setValue("")
				this.supplierContactForm.controls.description.setValue("")
				this.supplierContactForm.controls.address.setValue("")
				this.supplierContactForm.controls.contact_person.setValue("")
				this.openContactDetail.nativeElement.click()
			} else {
				let supplier = this.supplierContacts.find(acc => acc.id == value)
				this.selectedSupplier = supplier
				this.supplierContactForm.controls.account_code.setValue(supplier.account_code)
				this.supplierContactForm.controls.type.setValue(supplier.type)
				this.supplierContactForm.controls.mobile.setValue(supplier.mobile)
				this.supplierContactForm.controls.email.setValue(supplier.email)
				this.supplierContactForm.controls.description.setValue(supplier.description)
				this.supplierContactForm.controls.address.setValue(supplier.address)
				this.supplierContactForm.controls.contact_person.setValue(supplier.contact_person)
				this.openContactDetail.nativeElement.click()
			}
	}

	changeCurrentAmount(event) {
		let currency = this.supplierForm.controls.currency.value;
		let currency_rate = this.supplierForm.controls.currency_rate.value;
		if(currency && currency_rate) {
			let local_balance = this.supplierForm.controls.local_balance.value
			this.supplierForm.controls.balance.setValue((Number(local_balance) / currency_rate).toFixed(2));
		} else {
			this.supplierForm.controls.balance.setValue('')
		}
	}

	currencyChange(value){
		this.supplierForm.controls.currency_rate.setValue('');
	
	}

	getAllBranches() {
        this.commonService.getOkount(`branch`).subscribe(res=> {
          if(res && res['data']) {
            this.branches = res['data'];
			let formObj = this.branches.reduce((acc,curr)=>{
				if(curr.branch_type=='BRANCH'){
					acc[curr.branch_name]=false
				}
				return acc
			},{})
			console.log("formObj",formObj)
			this.supplierForm.controls.branch.setValue(formObj)
          }
        },err=> {
    
        })
      }

	  handleValueChange(e,branchName){
		console.log("branchName",this.supplierForm.controls.branch)
		this.supplierForm.controls.branch.value[branchName]=e.currentTarget.checked
	  }
	  async checkBalance(){
        let opening_amount = await  this.getOpeningBalanceData()
        if(this.supplierForm.controls.balance_type.value=='debit') opening_amount['debit']= Number(opening_amount['debit']) + Number(this.supplierForm.controls.balance.value)
        if(this.supplierForm.controls.balance_type.value=='credit') opening_amount['credit']= Number(opening_amount['credit']) + Number(this.supplierForm.controls.balance.value)
            console.log("opening_amount",opening_amount)
        if(opening_amount && (opening_amount['debit']!=opening_amount['credit'])) {
            this.remainingAmount = Math.abs(Number((opening_amount['debit'] - opening_amount['credit']).toFixed(2)))
            this.amountType = opening_amount['debit']>opening_amount['credit'] ? 'credit' : 'debit'
			let res = await this.adjustAccOpeningBalance()
			if(res['balance']!=this.remainingAmount || this.amountType!=res['balance_type']){
                this.openOBConfirmModal()
			}else{
				this.updateOpeningBalancecombine()
			}
        }else{
            this.updateOpeningBalancecombine()
        }
    }
	adjustAccOpeningBalance(){
        return new Promise(async (resolve, reject) => {
        this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/globalsearch/opening_balance`, {client: 'OB0000165'}).subscribe(res => {
            resolve(res)
        },err=>{
            console.log("err",err)
            resolve(null)
        })
    })
    }  
	
	getOpeningBalanceData(){
        return new Promise(async (resolve, reject) => {
            this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/openingBalanceTotal`, {"account_code":this.supplierData.account_code}).subscribe(res => {
                resolve(res)
            },err=> {
                resolve(null)
            })
        })
    }
	openOBConfirmModal(){
		this.confirmOBModal.nativeElement.click()
	}
	 updateOpeningBalancecombine(){
        this.closeOBConfirmModal()
        this.updateSupplier()
    }
	closeOBConfirmModal(){
		this.closeOBModal.nativeElement.click()
	}
	updateOpeningBalanceAdjustAcc() {
        let req_body = {
            balance: this.remainingAmount,
            balance_type: this.amountType,
            account_code: 'OB0000165'
        }
        this.commonService.putRequest(`${environment.okountUrl}/client/updateOpeningBalanceAdjust`, req_body).subscribe(res => {
           
            console.log(res)
        }, err => {
        })

    }

}
