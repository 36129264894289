
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
	<div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
	  <div class="d-flex flex-y-center">
		<img
		  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
			alertType=='success-box' ? 'green-sucess.svg' : 
			alertType=='warning-box' ? 'red-warning.svg' : 
			null }}"
		  width="20px"
		  height="20px"
		  alt="alert-logo"
		  class="mr-20"
		/>
		<div class="ml-10">
		  <h4 class="m-0 text-size-18 text-bold"
		  [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
			{{alertType === "error-box"
			? "Error"
			: alertType === "warning-box"
			? "Warning"
			: alertType === "success-box"
			? "Success"
			: null}}
		  </h4>
		  <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
		   {{message}}
		  </small>
		</div>
	  </div>
	  <img
		src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
		alt="close-btn"
		(click)="hideError()"
		className=" close-btn"
	  />
	</div>
  </div>
  
<div class="login-form-container ">
	<div class="login text-center">
		<div class="login-heading">
			<div class="logo">
				<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/full-logo.svg" alt="" width="130px" class="cursor logo " />
			</div>
			<div class="welcome text-primary text-bold">Welcome</div>
			<div class="signin-text">Please sign in to your account.</div>
			<!-- <div class="signup">No account? <a class="link" href="/register">Sign up now</a></div> -->
		</div>
		<div class="login-form" *ngIf="isLoginPage">
			<form name="form" [formGroup]="loginForm">
				<div class="text-input-field mb-3">
					<input class="input-ui" tabIndex="1" type="text" placeholder="Enter Email address" name="username"
						formControlName="username">
					<label>Email</label>
				</div>
				<div class="text-input-field">
					<input class="input-ui" tabIndex="2" type="password" placeholder="Enter password" name="password"
						formControlName="password">
					<label>Password</label>

				</div>
				<!-- <div class="check mt-2">
				<input type="checkbox" value="on">
					<span class="chk-txt ml-2">Keep me logged in</span>
			</div> -->
				<div class="login-btns">
					<button class="btn text-bold bg-primary text-white" type="button" tabIndex="3"
						(keyup.enter)="login()" (click)="login()">Login</button>
					<p class="text-green text-bold cursor" (click)="isLoginPage = false">Forgot Password</p>
				</div>
			</form>
		</div>

		<div class="login-form" *ngIf="!isLoginPage">
			<form name="form" [formGroup]="resetPasswordForm">
				<div class="text-input-field">
					<input class="input-ui" placeholder="Enter User Name" name="username" formControlName="username">
					<label>User Name</label>

				</div>
				<!-- <div class="check mt-2">
				<input type="checkbox" value="on">
					<span class="chk-txt ml-2">Keep me logged in</span>
			</div> -->
				<div class="login-btns">
					<button class="btn text-bold bg-primary text-white" type="button"
						 (click)="resetPassword(resetPasswordForm.value)">Reset</button>
					<p class="text-green text-bold cursor" (click)="isLoginPage = true" >Login</p>
					<!-- <button class="btn-style  mr-3" type="button"
						(click)="resetPassword(resetPasswordForm.value)">Reset</button> -->
				</div>
			</form>
		</div>
	</div>
</div>