import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment-timezone'
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../../transactions/store/actions';
import { DownloadService } from 'src/app/shared/download.service';
import { TabService } from 'src/app/shared/services/tab.service';
import { UpdateClientComponent } from '../update-client/update-client.component'
import { Tab } from 'src/app/shared/model/tab.model';
@Component({
    selector: 'app-view-client',
    templateUrl: './view-client.component.html',
    styleUrls: ['./view-client.component.scss']
})
export class ViewClientComponent implements OnInit {
    viewAllClient: any;
    viewClient: any;
    innerHeight: string;
    navigationSubscription
    reInitialise = false;
    isUpdated: boolean = false;
    notUpdated: boolean = false;
    notDeleted: boolean = false;
    isDeleted: boolean = false;
    fromRoot = true;
    selector: string = '.main-panel';
    fromIndex: any = 0;
    toIndex: any = 30;
    onSearch;
    onSearchFilter = new Subject<string>();
    isDisabled = false;
    isAdvancedSearchOpen: boolean = false;
    currentIndex=0
    @ViewChild('tableHeader', {static:true}) tableHeader : ElementRef
    @ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
    @ViewChild('confirmUpdateOBModal',{static:true}) confirmUpdateOBModal : ElementRef
    @ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef

    remainingAmount = 0
    amountType = 'credit'
    balanceForm: FormGroup;
    viewBalance: any;
    selectedAccount: any;
    searchInputValue: any = "";
    duration: any;
    allGroupArr:any = []
    showEditOptions: boolean;
    loader: boolean;
    queryParams: any;
    isTenantIndian: boolean = false;
    constructor(
        public commonService: CommonServiceService,
        private route: ActivatedRoute,
        private router: Router,
        private spinner: NgxSpinnerService,
        private fb: FormBuilder,
        private store: Store<InitialState>,
        private downloadService: DownloadService,
		private tabService : TabService

        ) {
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                this.initialiseInvites();
            }
        });
    }
    ngOnInit() {
        // this.getClient()
        this.isTenantIndian = this.commonService.checkTenantIndian()

        this.balanceForm = this.fb.group({
            cash: this.fb.array([
            this.fb.group ({
                balance: ['', Validators.required],
                balance_type: ['debit'],
                sub_ledger: ['CC001B'],
                account_code: [''],
                currency:[''],
                currency_rate:[''],
                local_balance:['']
            })
        ])
        })

        let queryParams = JSON.parse(JSON.stringify(this.route.queryParams['value']))
        
        if (queryParams.account_code != undefined) {
            localStorage.setItem('activeClientQueryParams', JSON.stringify(queryParams))
        }
        queryParams['limit'] = 100;
        queryParams['offset'] = this.fromIndex;
        this.search(queryParams)
        this.queryParams = queryParams
        this.reInitialise = true

        this.onSearchFilter.pipe(
            debounceTime(300),
            distinctUntilChanged())
            .subscribe(value => {
                this.filterData(value)
            });

       
            // this.updatebalance()
    }
    openAdvanceSearchModal(){
        this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'client'}));	
    }
    
    async filterData(value) {
        this.searchInputValue = value
        let tempArr = Object.assign([], this.viewAllClient)
        if (value.length >= 0) {
            this.queryParams['freetext'] = value
            let queryParams = JSON.parse(JSON.stringify(this.queryParams))
            this.queryParams = queryParams
            this.fromIndex=0
            queryParams['limit'] = 100;
            queryParams['offset'] = this.fromIndex;
            this.loader = true
            let resp = await this.getData(queryParams)
            this.loader = false
            if(resp){
                this.viewClient = resp['data']
                let firstRow = document.getElementById('rowIndex_1') as HTMLElement
				if(firstRow){
					firstRow.scrollIntoView()
				}
            }
           
        this.setHeight(window.innerHeight)
        } else {
            delete this.queryParams['freetext']
            this.search(this.queryParams)
            this.setHeight(window.innerHeight)
        }
    }
    initialiseInvites() {
        if (this.reInitialise) {
            this.ngOnInit()
        }
    }
    ngOnDestroy() {
        // avoid memory leaks here by cleaning up after ourselves. If we
        // don't then we will continue to run our initialiseInvites()
        // method on every navigationEnd event.
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // if (this.viewAllClient.length <= 9) {
        //  this.innerHeight = 'auto';
        // }
        // else {
        //  this.setHeight(event.target.innerHeight)
        // }
        this.setHeight(event.target.innerHeight)
    }
    // @HostListener('load', ['$event'])
    // onPageLoad(event) {
    //  // console.log('loaded');
    //  // this.onResize(event.target['innerWidth']);
    // if (this.viewAllClient.length <= 9) {
    //      this.innerHeight = 'auto';
    //  }
    //  else {
    //      this.setHeight(event.target.innerHeight)
    //  }
    // }

async search(values) {
    // this.spinner.show()
    this.loader = true
    let resp = await this.getData(values)
    if(resp){
        this.loader = false
        this.viewClient = resp['data']
        this.duration = resp['duration']
        this.viewAllClient = resp['data']
        this.focusOnTableHeader()
        this.currentIndex = 0
       
        this.setHeight(window.innerHeight)
    }
}

getData(values){
    return new Promise(async (resolve, reject) => {
        this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/client`, values).subscribe(res => {
            resolve(res)
        },err=> {
            resolve(null)
        })
    })
}

setHeight(height) {
    if (this.viewClient != undefined) {
        if (height == undefined) {
            height = window.innerHeight
        }
        if (this.viewClient.length <= 9) {
            this.innerHeight = 'auto';
        }
        else {
            this.innerHeight = height - 147 + 'px';
        }
    }
}

open_client(client_id, account_code, client_name){
    let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/master/client/update-client/${client_id}`
    if(window.location.protocol != 'file:'){
        let client = client_id
        if(client) {
            this.tabService.addTab(
                new Tab(UpdateClientComponent, `C-${account_code}`, { client_id: client_id }, client_name)
                );
            }
       else {
        window.open(path,'_blank')
       } 
    }else{
        var customeEventHandler = new CustomEvent("handle-window-open", {
            "detail": {
                hashPath : path
            }
          });
        window.dispatchEvent(customeEventHandler)
        
    }
}
// INFINITE SCROLL
async onScrollDown(event) {
    console.log('scroled')
    this.fromIndex +=100
    // let toIndex = Number(this.toIndex) + 10
    let queryParams = JSON.parse(JSON.stringify(this.queryParams))
    queryParams['limit'] = 100;
    queryParams['offset'] = this.fromIndex;
    let resp = await this.getData(queryParams)
    if(resp){
        this.viewClient = this.viewClient.concat(resp['data'])
        this.setHeight(window.innerHeight)
    }
    // for (let i = this.fromIndex; i <= toIndex; i++) {
    //     const element = this.viewAllClient[i];
    //     if (element != undefined) {
    //         this.toIndex = i
    //         this.viewClient.push(element)
    //     }
    // }
    // this.toIndex = toIndex
}
onScrollUp(event) {
}
focusOnTableHeader(){
    if(this.tableHeader != undefined){
        this.tableHeader.nativeElement.focus()
    }
}
// @HostListener('window:keydown', ['$event'])
onKeyDown(e: any) {   
	if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
		if (e.keyCode == 40) {
			e.preventDefault();
			this.moveToNext()
		} else if (e.keyCode === 38) {
			e.preventDefault();
			this.moveToPrev()
		}
	}
}
moveToNext(){
    if (document.getElementById(`rowIndex_${this.currentIndex+1}`) != null) {
        document.getElementById(`rowIndex_${this.currentIndex+1}`).focus()
        this.currentIndex = this.currentIndex+1
    }
}
moveToPrev(){
    if (document.getElementById(`rowIndex_${this.currentIndex-1}`) != null) {
        document.getElementById(`rowIndex_${this.currentIndex-1}`).focus()
        this.currentIndex = this.currentIndex-1
    }
}
moveToIndex(i){
    document.getElementById(`rowIndex_${i+1}`).focus()
    this.currentIndex = i+1
}
async openConfirmModal(item){
    const lThis = this;
    this.selectedAccount = item.account_code
    // CC001A for cash balance
    // CC001B for credit balance
    let creditBalance = await this.getClientOpeningBalance(item.account_code,'CC001B')
    console.log("creditBalance",creditBalance)
    //let creditBalance = await this.balanceParser(getCreditOpeningBalance)
    // console.log("creditBalance",creditBalance)

    // setTimeout(() => {
        lThis.balanceForm.controls.cash['controls'][0].controls.account_code.setValue(item.account_code)
        lThis.isUpdated = false
        lThis.notUpdated = false
        lThis.notDeleted = false
        lThis.isDeleted = false
            lThis.balanceForm.controls.cash['controls'][0].controls.balance.setValue(creditBalance['balance'])
            lThis.balanceForm.controls.cash['controls'][0].controls.balance_type.setValue(creditBalance['balance_type'])
            lThis.balanceForm.controls.cash['controls'][0].controls.currency.setValue(creditBalance['currency'])
            lThis.balanceForm.controls.cash['controls'][0].controls.currency_rate.setValue(creditBalance['currency_rate'])
            lThis.balanceForm.controls.cash['controls'][0].controls.local_balance.setValue(creditBalance['local_balance'])
        

    // }, 200);
    this.confirmModal.nativeElement.click()
}
    getClientOpeningBalance(account_code: any,balance_type) {
        return new Promise(async (resolve, reject) => {
            let financialYearObj = JSON.parse(sessionStorage.getItem('current_financial_year'))
            let financialYear = this.commonService.getFinancialSession(moment(financialYearObj.start_date))
            this.showEditOptions = false
            if(financialYear == '2023' || financialYear == '2024'){
                this.showEditOptions = true
            }
            let reqObj = {
                viewMode: "normal",
                // start_date: `${financialYear}-04-01`,
                // end_date: moment().format('YYYY-MM-DD'),
                client: account_code,
                subledger_type: "client",
                sub_ledger_code: balance_type,
            }
            //this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/ledger/opening_balance`, reqObj).subscribe(res => {
            this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/globalsearch/opening_balance`, reqObj).subscribe(res => {

                resolve(res)
            },err=>{
                console.log("err",err)
                resolve(null)
            })
        })
    }

    balanceParser(data) {
        return new Promise(async (resolve, reject) => {
            let resObj = {
                balance : 0,
                balance_type : 'debit',
                currency:'AED',
                currency_rate:1,
                local_balance:null
            }
            if (data != null) {
                if (data.all_clients_records != undefined && data.all_clients_records.length > 0) {
                    if (data.all_clients_records[0].client_records != undefined && data.all_clients_records[0].client_records.length > 0) {
    
                        if (data.all_clients_records[0].client_records[0].records != undefined && data.all_clients_records[0].client_records[0].records.length > 0) {
                            let record = data.all_clients_records[0].client_records[0].records[0]
                            if (record.amount_debit == 0) {
                                resObj.balance_type = 'credit'
                                resObj.balance = record.amount_credit
                            }
                            if (record.amount_credit == 0) {
                                resObj.balance_type = 'debit'
                                resObj.balance = record.amount_debit
                            }
                            resObj.currency = record.currency
                            resObj.currency_rate = record.currency_rate
                            resObj.local_balance = record.local_balance
                        }
                    }
                }
            }
            resolve(resObj)
        })
    }


    updatebalance() {
        console.log(this.balanceForm.value)
        // if (this.balanceForm.valid) {
            
            this.commonService.putRequest(`${environment.okountUrl}/client/update-opening-balance`, this.balanceForm.value.cash).subscribe(res => {
                this.updateOpeningBalanceAdjustAcc()
                this.isUpdated = true
                this.viewBalance = res
                console.log(res)
            }, err => {
                this.notUpdated = false
            })
        
    }
    closeUpdateConfirmModal(){
		this.closeUpdateModal.nativeElement.click()
	}

    updateOpeningBalanceAdjustAcc() {
        let req_body = {
            balance: this.remainingAmount,
            balance_type: this.amountType,
            account_code: 'OB0000165'
        }
        this.commonService.putRequest(`${environment.okountUrl}/client/updateOpeningBalanceAdjust`, req_body).subscribe(res => {
            this.isUpdated = true
            this.viewBalance = res
            console.log(res)
        }, err => {
            this.notUpdated = false
        })

    }
    

    async checkBalance(){
        let opening_amount = await  this.getOpeningBalanceData()
        if(this.balanceForm.controls.cash['controls'][0].controls.balance_type.value=='debit') opening_amount['debit']= Number(opening_amount['debit']) + Number(this.balanceForm.controls.cash['controls'][0].controls.balance.value)
        if(this.balanceForm.controls.cash['controls'][0].controls.balance_type.value=='credit') opening_amount['credit']= Number(opening_amount['credit']) + Number(this.balanceForm.controls.cash['controls'][0].controls.balance.value)
            console.log("opening_amount",opening_amount)
        if(opening_amount && (opening_amount['debit']!=opening_amount['credit'])) {
            this.remainingAmount = Math.abs(Number((opening_amount['debit'] - opening_amount['credit']).toFixed(2)))
            this.amountType = opening_amount['debit']>opening_amount['credit'] ? 'credit' : 'debit'
            let res = await this.adjustAccOpeningBalance()
            if(res['balance']!=this.remainingAmount || this.amountType!=res['balance_type']){
                this.openUpdateConfirmModal()
            }else{
                this.updateOpeningBalancecombine()
            }
        }else{
            this.updateOpeningBalancecombine()
        }
    }

    adjustAccOpeningBalance(){
        return new Promise(async (resolve, reject) => {
        this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/globalsearch/opening_balance`, {client: 'OB0000165'}).subscribe(res => {
            resolve(res)
        },err=>{
            console.log("err",err)
            resolve(null)
        })
    })
    }


     updateOpeningBalancecombine(){
        this.closeUpdateConfirmModal()
         this.updatebalance()
    }


    openUpdateConfirmModal(){
		this.confirmUpdateOBModal.nativeElement.click()
	}

    deleteBalance() {
        let reqObj = {
            "account_code" : this.selectedAccount
        }
        this.commonService.deleteRequestWithBodyParam(`${environment.okountUrl}/client/delete-opening-balance`, reqObj).subscribe(res => {
            this.isDeleted = true
            this.viewBalance = res
            console.log(res)
        }, err => {
            this.notDeleted = false
        })
    }



    exportClientAsXLSX() {

        try{
            let groupHasMap = {}
            this.spinner.show()
            this.commonService.getOkount('v3account/getAllClientGroupName').subscribe(async res => {
                this.allGroupArr = res
                this.allGroupArr.forEach((group)=>{
                    groupHasMap[group.id] = group.client_group_name
                })

                this.loader = true
                let downloadData = await this.getData(null);
                this.loader = false;
                let xlsxData = [];
                downloadData['data'].forEach(client => {
                    xlsxData.push({
                        "ACCOUNT CODE":client.account_code,
                        "COMPANY NAME":client.name,
                        "ADDRESS":client.address_1,
                        "CITY":client.city_name,
                        "STATE":client.state_name,
                        "PAN":client.pan_number,
                        "GST NO":client.gst_number,
                        "EXPIRY DATE": client.gst_expiry_date ? moment(client.gst_expiry_date).format('DD/MM/YYYY') : '',
                        "SALES PERSON":client.sales_person_name,
                        "TELEPHONE":client.telephone,
                        "MOBILE":client.mobile_no,
                        "EMAIL 1":client.primary_email,
                        "EMAIL 2":client.secondary_email,
                        "GROUP":client.client_group_id!=null && groupHasMap.hasOwnProperty(client.client_group_id)?groupHasMap[client.client_group_id]:'',
                        "STATUS":client.status           
                     })
                });
                this.downloadService.exportAsXLSX(`clients`, xlsxData);
                this.spinner.hide()
            }, err => {
                throw new Error(err)
            })
    
          
        }catch(error){
            this.spinner.hide()
        }
       
	}

    changeCurrentAmount(event) {
		let currency = this.balanceForm.controls.cash['controls'][0].controls.currency.value;
		let currency_rate = this.balanceForm.controls.cash['controls'][0].controls.currency_rate.value;
        console.log('currency',currency,currency_rate)
		if(currency && currency_rate) {
			let local_balance = this.balanceForm.controls.cash['controls'][0].controls.local_balance.value
			this.balanceForm.controls.cash['controls'][0].controls.balance.setValue((Number(local_balance) / currency_rate).toFixed(2));
		} else {
			this.balanceForm.controls.cash['controls'][0].controls.balance.setValue('')
		}
	}

	currencyChange(value){
		this.balanceForm.controls.cash['controls'][0].controls.currency_rate.setValue('');
	
	}

    getOpeningBalanceData(){
        return new Promise(async (resolve, reject) => {
            this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/openingBalanceTotal`, {"account_code":this.selectedAccount}).subscribe(res => {
                resolve(res)
            },err=> {
                resolve(null)
            })
        })
    }
}

