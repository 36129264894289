import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, ɵConsole, Input } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
//import { InvoiceServicesService } from '../services/invoice-services.service';
import { ConsolidateFareSummaryComponent } from '../consolidate-fare-summary/consolidate-fare-summary.component'; 
import { BehaviorSubject, Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { ActivatedRoute } from '@angular/router';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { catchError,map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { consolidateFareSummaryModel } from '../modals/consolidate-fare-summary-model';
//import { SetNarrationsArr } from '../../../../transactions/store/actions';

@Injectable({
	providedIn: 'root'
})
export class ProductService{
    invoiceForm: any
    totalFareModel = []
    fareList = []
    FlightForm: any;
    HotelForm: any;
    RailForm: any;
    CarForm: any;
    BusForm: any;
    VisaForm: any;
    InsuranceForm: any;
    TourForm: any;
    FlightList: FormArray;
    HotelList: FormArray;
    RailList: FormArray;
    CarList: FormArray;
    BusList: FormArray;
    VisaList: FormArray;
    InsuranceList: FormArray;
    TourList: FormArray;
    consolidationType = 'inv'


    constructor(private fb: FormBuilder,private httpClient: HttpClient,
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private store: Store<InitialState>
	){
    let obj = {
      invoice_no: 'New Invoice',
    };
    this.fareList.push(obj);
        this.initialiseFormData({})
        this.FlightForm = this.fb.group({
			FlightList: this.fb.array([])
			
		})
    //     this.HotelForm = this.fb.group({
		// 	HotelList: this.fb.array([])
			
		// })
    //     this.RailForm = this.fb.group({
		// 	RailList: this.fb.array([])
			
		// })
    //     this.CarForm = this.fb.group({
		// 	CarList: this.fb.array([])
			
		// })
    //     this.BusForm = this.fb.group({
		// 	BusList: this.fb.array([])
			
		// })
    //     this.VisaForm = this.fb.group({
		// 	VisaList: this.fb.array([])
			
		// })
    //     this.InsuranceForm = this.fb.group({
		// 	InsuranceList: this.fb.array([])
			
		// })
    // this.TourForm = this.fb.group({
		// 	TourList: this.fb.array([])
			
		// })

        
}
 async bindInvoiceData(data){
    let res = await this.initialiseFormData(data)
    if(data.products){
      this.FlightList = this.FlightForm.get("FlightList");
        this.FlightList = this.FlightForm.get("FlightList") as FormArray;
      this.FlightList.controls = []
      for (const iterator of data.products) {
        this.addFlight(iterator)
      }
      // this.consolidationType = data.consolidation_type
    }
    // if(data.hotel){
    //   this.HotelList = this.HotelForm.get("HotelList");
    //     this.HotelList = this.HotelForm.get("HotelList") as FormArray;
    //     this.HotelList.controls = []
    //   for (const iterator of data.hotel) {
    //     this.addHotel(iterator)
    //   }
    // }
    // if(data.rail){
    //   this.RailList = this.RailForm.get("RailList");
    //     this.RailList = this.RailForm.get("RailList") as FormArray;
    //     this.RailList.controls = []
    //   for (const iterator of data.rail) {
    //     this.addRail(iterator)
    //   }
    // }
    let obj = {
      invoice_no: 'New Invoice',
    };
    this.fareList = [obj]
    let calculationObj = this.createMsProduct(this.invoiceForm.value,this.FlightForm.value)
    let calcApiRes = await this.calculateFare(calculationObj)

 }
 
 calculateFare(calculationObj){
  return new Promise((resolve, reject) => {
    this.postMethod(`invoice/consolidate-fare`,calculationObj).subscribe(async (res : any)=> {
      this.totalFareModel[0] = new consolidateFareSummaryModel(res)
      this.invoiceForm.controls.client_sub_total.setValue(res.client_net_amount)
      resolve(res)
    },err=> {
      resolve([])
    })
  })
 }
 initialiseFormData(data){
     this.invoiceForm = this.fb.group({
        invoice_date: [moment(data["invoice_date"]||'').format('YYYY-MM-DD'), Validators.required],
        client_account_type: [data["client_account_type"]||"client",Validators.required],
        client_code: [ data["client_code"]||"",Validators.required],
        client_code_name: [data["client_code_name"]|| "",Validators.required],
        client_main_code: [ data["client_main_code"]||"",Validators.required],
        client_main_code_name: [data["client_main_code_name"]|| "",Validators.required],
        billaddress: [data['billaddress']||''],
        invoice_uuid: [data['invoice_uuid']||''],
        general_ledger_code: [data.products?data.products[0]["general_ledger_code"]:""],
        general_ledger_code_name: [data.products?data.products[0]["general_ledger_code_name"]:""],
        invoice_number: [ data["invoice_number"]||""],
        type: [""],
        narration_1: [ data["narration_1"]||""],
        narration_2: [ data["narration_2"]||""],
        narration_3: [ data["narration_3"]||""],
        narration_4: [ data["narration_4"]||""],
        narration_5: [ data["narration_5"]||""],
        remark: [ data["remark"]||""],
        employee: [ data["employee"]||""],
        employee_name: [data["employee_name"]||""],
        start_date: [data['start_date']||''],
        end_date: [data['end_date']],
        hsn: [data['hsn'] || ''],
        hsn_2: [data['hsn_2'] || ''],
        c_sc1_type: [data['c_sc1_type'] || 'VL'],
        c_sc1_value: [data['c_sc1_value'] || 0],
        c_sc1_amount: [data['c_sc1_amount'] || 0],
        c_gst_type: [data['c_gst_type'] || 'T'],
        c_gst_conf: [data['c_gst_conf'] || 0],
        c_cgst: [data['c_cgst'] || 0],
        c_sgst: [data['c_sgst'] || 0],
        c_igst: [data['c_igst'] || 0],
        c_net_gst: [data['c_net_gst'] || 0],
        c_gst_type_2: [data['c_gst_type_2'] || 'S'],
        c_gst_conf_2: [data['c_gst_conf_2'] || 0],
        c_cgst_2: [data['c_cgst_2'] || 0],
        c_sgst_2: [data['c_sgst_2'] || 0],
        c_igst_2: [data['c_igst_2'] || 0],
        c_net_gst_2: [data['c_net_gst_2'] || 0],
        tcs_rate: [data['tcs_rate'] || ''],
        tcs_amount: [data['tcs_amount'] || 0],
        client_sub_total: [data['client_sub_total'] || 0],
        total: [data['total'] || 0],
        c_vat_on_raf: [data['c_vat_on_raf']],
        c_vat_amount: [data['c_vat_amount']],
        consolidation_type: [data['consolidation_type' || this.consolidationType]],
    })
  //   this.totalFareForm = this.fb.group({
  //     basic: [ data && data.total_fare?data.total_fare["basic"]:0],
  //     taxes:  [ data && data.total_fare?data.total_fare["taxes"]:0],
  //     c_commission:  [ data && data.total_fare?data.total_fare["c_commission"]:0],
  //     c_TDS:  [ data && data.total_fare?data.total_fare["c_TDS"]:0],
  //     c_service_charge_1:  [ data && data.total_fare?data.total_fare["c_service_charge_1"]:0],
  //     c_sc1_gst:  [ data && data.total_fare?data.total_fare["c_sc1_gst"]:0],
  //     c_non_tax_sc2: [ data && data.total_fare?data.total_fare["c_non_tax_sc2"]:0],
  //     c_taxable_sc2:  [ data && data.total_fare?data.total_fare["c_taxable_sc2"]:0],
  //     c_non_tax_sc3:  [ data && data.total_fare?data.total_fare["c_non_tax_sc3"]:0],
  //     c_taxable_sc3:  [ data && data.total_fare?data.total_fare["c_taxable_sc3"]:0]
  // })
    return true
 }


 addFlight(data) {
    if(!this.FlightList){
      this.FlightList = this.FlightForm.get("FlightList");
		  this.FlightList = this.FlightForm.get("FlightList") as FormArray;
      this.FlightList.controls = []
    }
		this.FlightList.push(this.buildFlight(data));
	}

    buildFlight(data) {
		return this.fb.group({

            invoice_type:[data.invoice_type? data.invoice_type: ''],
            invoice_no:[data.invoice_no ? data.invoice_no : ""],
            traveller_name:[data.traveller_name ? data.traveller_name : ""],
            c_basic:[data.c_basic ? data.c_basic : 0],
            c_taxes:[data.c_taxes ? data.c_taxes : 0],
            s_basic:[data.s_basic ? data.s_basic : 0],
            s_taxes:[data.s_taxes ? data.s_taxes : 0],
            c_commission:[data.c_commission ? data.c_commission : 0],
            c_tds:[data.c_tds ? data.c_tds : 0],
            c_sc1_type:[data.c_sc1_type ? data.c_sc1_type : ''],
            c_sc1_amount:[data.c_sc1_amount ? data.c_sc1_amount : 0],
            c_gst_type:[data.c_gst_type ? data.c_gst_type : ""],
            c_gst_conf:[data.c_gst_conf ? data.c_gst_conf : 0],
            c_cgst:[data.c_cgst ? data.c_cgst : 0],
            c_sgst: [data.c_sgst ? data.c_sgst : 0],
            c_igst: [data.c_igst ? data.c_igst : 0],
            c_sc2_amount:[data.c_sc2_amount ? data.c_sc2_amount : 0],
            c_sc2_taxable:[data.c_sc2_taxable ? data.c_sc2_taxable : ""],
            c_sc2_type:[data.c_sc2_type ? data.c_sc2_type : ""],
            c_gst_type_2:[data.c_gst_type_2 ? data.c_gst_type_2 : ""],
            c_gst_conf_2:[data.c_gst_conf_2 ? data.c_gst_conf_2 : 0],
            c_cgst_2: [data.c_cgst_2 ? data.c_cgst_2 : 0],
            c_sgst_2: [data.c_sgst_2 ? data.c_sgst_2 : 0],
            c_igst_2: [data.c_igst_2 ? data.c_igst_2 : 0],
            c_sc3_amount:[data.c_sc3_amount ? data.c_sc3_amount : 0],
            c_sc3_taxable:[data.c_sc3_taxable ? data.c_sc3_taxable : ""],
            c_sc3_type:[data.c_sc3_type ? data.c_sc3_type : ""],
            c_gst_type_3:[data.c_gst_type_3 ? data.c_gst_type_3 : ""],
            c_gst_conf_3:[data.c_gst_conf_3 ? data.c_gst_conf_3 : 0],
            c_cgst_3: [data.c_cgst_3 ? data.c_cgst_3 : 0],
            c_sgst_3: [data.c_sgst_3 ? data.c_sgst_3 : 0],
            c_igst_3: [data.c_igst_3 ? data.c_igst_3 : 0],
            s_commission:[data.s_commission ? data.s_commission : 0],
            s_tds:[data.s_tds ? data.s_tds : 0],
            s_sc1_amount: [data.s_sc1_amount ? data.s_sc1_amount : 0],
            s_gst_type:[data.s_gst_type ? data.s_gst_type : ""],
            s_gst_conf:[data.s_gst_conf ? data.s_gst_conf : 0],
            s_cgst: [data.s_cgst ? data.s_cgst : 0],
            s_sgst: [data.s_sgst ? data.s_sgst : 0],
            s_igst: [data.s_igst ? data.s_igst : 0],
            s_sc2_amount:[data.s_sc2_amount ? data.s_sc2_amount : 0],
            s_sc2_taxable:[data.s_sc2_taxable ? data.s_sc2_taxable : ""],
            s_sc2_type:[data.s_sc2_type ? data.s_sc2_type : ""],
            s_gst_type_2:[data.s_gst_type_2 ? data.s_gst_type_2 : ""],
            s_gst_conf_2:[data.s_gst_conf_2 ? data.s_gst_conf_2 : 0],
            s_cgst_2: [data.s_cgst_2 ? data.s_cgst_2 : 0],
            s_sgst_2:[data.s_sgst_2 ? data.s_sgst_2 : 0],
            s_igst_2:[data.s_igst_2 ? data.s_igst_2 : 0],
            s_sc3_amount:[data.s_sc3_amount ? data.s_sc3_amount : 0],
            s_sc3_taxable:[data.s_sc3_taxable ? data.s_sc3_taxable : ""],
            s_sc3_type:[data.s_sc3_type ? data.s_sc3_type : ""],
            s_gst_type_3:[data.s_gst_type_3 ? data.s_gst_type_3 : ""],
            s_gst_conf_3:[data.s_gst_conf_3 ? data.s_gst_conf_3 : 0],
            s_cgst_3:[data.s_cgst_3 ? data.s_cgst_3 : 0],
            s_sgst_3: [data.s_sgst_3 ? data.s_sgst_3 : 0],
            s_igst_3: [data.s_igst_3 ? data.s_igst_3 : 0],
            client_net_amount: [Number(Math.round(data.client_net_amount? data.client_net_amount : 0)).toFixed(2)],
            supplier_net_amount: [data.supplier_net_amount?data.supplier_net_amount: 0],
            payable_account_type:[data.payable_account_type ? data.payable_account_type : ''],
            payable_account_code:[data.payable_account_code ? data.payable_account_code : ''],
            payable_account_name:[data.payable_account_name ? data.payable_account_name : ''],
            transaction_method:[data.transaction_method?data.transaction_method:''],
            c_raf: [data.c_raf ? data.c_raf : "0"],
            c_igst_on_raf: [data.c_igst_on_raf ? data.c_igst_on_raf : "0"],
            c_cgst_on_raf: [data.c_cgst_on_raf ? data.c_cgst_on_raf : "0"],
            c_sgst_on_raf: [data.c_sgst_on_raf ? data.c_sgst_on_raf : "0"],
            c_xxl: [data.c_xxl ? data.c_xxl : "0"] ,
            s_raf: [data.s_raf ? data.s_raf : "0"],
            s_igst_on_raf: [data.s_igst_on_raf ? data.s_igst_on_raf : "0"],
            s_cgst_on_raf: [data.s_cgst_on_raf ? data.s_cgst_on_raf : "0"],
            s_sgst_on_raf: [data.s_sgst_on_raf ? data.s_sgst_on_raf : "0"],
            s_xxl: [data.s_xxl ? data.s_xxl : "0"],
            hsn:[data.hsn?data.hsn:''],
            hsn_2:[data.hsn_2?data.hsn_2:''],
            hsn_3:[data.hsn_3?data.hsn_3:''],
            c_ssr:[data.c_ssr?data.c_ssr:0],
            c_permit_charges:[data.c_permit_charges ?  data.c_permit_charges : 0],
            c_parking_charges:[data.c_parking_charges ?  data.c_parking_charges : 0],
            c_toll_charges:[data.c_toll_charges ?  data.c_toll_charges : 0],
            c_meal_charges:[data.c_meal_charges ?  data.c_meal_charges : 0],
            c_travel_insurance:[data.c_travel_insurance ?  data.c_travel_insurance : 0],
            s_permit_charges:[data.s_permit_charges ?  data.s_permit_charges : 0],
            s_parking_charges:[data.s_parking_charges ?  data.s_parking_charges : 0],
            s_toll_charges:[data.s_toll_charges ?  data.s_toll_charges : 0],
            s_meal_charges:[data.s_meal_charges ?  data.s_meal_charges : 0],
            s_travel_insurance:[data.s_travel_insurance ?  data.s_travel_insurance : 0],
            c_gst_on_comm_value: [data.c_gst_on_comm_value? data.c_gst_on_comm_value:0],
            s_gst_on_comm_value: [data.s_gst_on_comm_value?data.s_gst_on_comm_value:0],
            c_gst_on_comm_amount: [data.c_gst_on_comm_amount?data.c_gst_on_comm_amount:0],
            general_ledger_code: [data.general_ledger_code?data.general_ledger_code:0],
            general_ledger_code_name: [data.general_ledger_code_name?data.general_ledger_code_name:0]
     
    });
  }
  // addHotel(data) {
  //   if(!this.HotelList){
  //     this.HotelList = this.HotelForm.get("HotelList");
	// 	  this.HotelList = this.HotelForm.get("HotelList") as FormArray;
  //     this.HotelList.controls = []
  //   }
	// 	this.HotelList.push(this.buildHotel(data));
	// }

  //   buildHotel(data) {
	// 	return this.fb.group({

  //           invoice_no:[data.invoice_no ? data.invoice_no : ""],
  //           basic:[data.basic ? data.basic : 0],
  //           taxes:[data.taxes ? data.taxes : 0],
  //           c_commission:[data.c_commission ? data.c_commission : 0],
  //           c_TDS:[data.c_TDS ? data.c_TDS : 0],
  //           c_service_charge_1:[data.c_service_charge_1 ? data.c_service_charge_1 : 0],
  //           c_sc1_gst: [data.c_sc1_gst ? data.c_sc1_gst : 0],
  //           c_non_tax_sc2:[data.c_non_tax_sc2 ? data.c_non_tax_sc2 : 0],
  //           c_taxable_sc2:[data.c_taxable_sc2 ? data.c_taxable_sc2 : 0],
  //           c_non_tax_sc3:[data.c_non_tax_sc3 ? data.c_non_tax_sc3 : 0],
  //           c_taxable_sc3:[data.c_taxable_sc3 ? data.c_taxable_sc3 : 0],
  //           s_commission:[data.s_commission ? data.s_commission : 0],
  //           s_TDS:[data.s_TDS ? data.s_TDS : 0],
  //           s_service_charge_1:[data.s_service_charge_1 ? data.s_service_charge_1 : 0],
  //           s_sc1_gst: [data.s_sc1_gst ? data.s_sc1_gst : 0],
  //           s_non_tax_sc2:[data.s_non_tax_sc2 ? data.s_non_tax_sc2 : 0],
  //           s_taxable_sc2:[data.s_taxable_sc2 ? data.s_taxable_sc2 : 0],
  //           s_non_tax_sc3:[data.s_non_tax_sc3 ? data.s_non_tax_sc3 : 0],
  //           s_taxable_sc3:[data.s_taxable_sc3 ? data.s_taxable_sc3 : 0],
  //           client_net_amount: [data.client_net_amount? data.client_net_amount: 0],
  //           supplier_net_amount: [data.supplier_net_amount?data.supplier_net_amount: 0],
  //           payable_account_type:[data.payable_account_type ? data.payable_account_type : ''],
  //           payable_account_code:[data.payable_account_code ? data.payable_account_code : ''],
  //           payable_account_name:[data.payable_account_name ? data.payable_account_name : ''],
  //           invoice_type:[data.invoice_type? data.invoice_type: '']
     
  //   });
  // }

  // addRail(data) {
  //   if(!this.RailList){
  //     this.RailList = this.RailForm.get("RailList");
	// 	  this.RailList = this.RailForm.get("RailList") as FormArray;
  //     this.RailList.controls = []
  //   }
	// 	this.RailList.push(this.buildHotel(data));
	// }


  createMsProduct(data,Flights) {
    let products = []
    let gst = 0
    let netAmount = 0
    // let hsn = this.getHSN(data.invoice_type)
    // for (const traveller of Flights.FlightList) {
    //   let productObj =  {
       
    //   };

    //   gst += Number(traveller.client_igst) + Number(traveller.client_cgst) + Number(traveller.client_sgst)
    //   netAmount += Number(traveller.client_net_amount)
    //   products.push(productObj)
    // }
    
  // let totalAmount = Number(data.tcs_amount) + Number(netAmount)
  let FlightArr = Flights.FlightList
  let invoioceObj = {
    "client_code": data.client_code,
    "client_code_name": data.client_code_name ? data.client_code_name.name ? data.client_code_name.name : data.client_code_name : "",
    "client_main_code": data.client_main_code,
    "client_main_code_name": data.client_main_code_name ? data.client_main_code_name.name ? data.client_main_code_name.name : data.client_main_code_name : "",
    "client_account_type":data.client_account_type,
    "billaddress": "",
    "invoice_uuid": '',
    "invoice_number":data.invoice_number,
    "invoice_date": data.invoice_date,
    "created_by": data.employee,
    "updated_by": data.employee,
    "employee" : data.employee_name && data.employee_name.account_code ? data.employee_name.account_code : data.employee || "",
    "employee_name" : data.employee_name && data.employee_name.name ? data.employee_name.name : data.employee_name || "",
    "year_id": 1,
    "tenant_id": 1,
    "narration_1": data.narration_1,
    "narration_2": data.narration_2,
    "narration_3": data.narration_3,
    "narration_4": data.narration_4,
    "narration_5": data.narration_5,
    "remark": data.remark,
    "start_date": data.start_date,
    "end_date": data.end_date,
    "c_sc1_type": data.c_sc1_type,
    "c_sc1_value": data.c_sc1_value,
    "c_sc1_amount": data.c_sc1_amount,
    "c_gst_type": data.c_gst_type || 'T',
    "c_gst_conf": data.c_gst_conf || 0,
    "c_cgst": data.c_cgst,
    "c_sgst": data.c_sgst,
    "c_igst": data.c_igst,
    "c_net_gst": data.c_net_gst,
    "c_gst_type_2": data.c_gst_type_2 || 'S',
    "c_gst_conf_2": data.c_gst_conf_2 || 0,
    "c_cgst_2": data.c_cgst_2,
    "c_sgst_2": data.c_sgst_2,
    "c_igst_2": data.c_igst_2,
    "c_net_gst_2": data.c_net_gst_2,
    "tcs_rate": data.tcs_rate,
    "tcs_amount": data.tcs_amount,
    "client_sub_total": data.client_sub_total || data.total,
    "total": data.total,
    "consolidation_type": this.consolidationType,
    "hsn": data.hsn,
    "hsn_2": data.hsn_2,
    "products": FlightArr
  }
  
    
    // "tcs_rate": data.tcs_rate ? data.tcs_rate : null,
    // "tcs_amount": data.tcs_amount ? data.tcs_amount : null,
    // "total": totalAmount ? Math.round(totalAmount) : null,
    // "balance_due": 0,
    // "gst_total": gst,
    // "reference_invoice_number": products[0].reference_invoice_number,
    // "transaction_method": data.transaction_method ? data.transaction_method : 'sales',
    // "sales_currency": data.sales_currency ? data.sales_currency : null,
    // "sales_currency_rate" : data.sales_currency_rate ? data.sales_currency_rate : null,
    // "sales_currency_amount" : data.sales_currency_amount ? data.sales_currency_amount : null,
    // "purchase_currency": data.purchase_currency ? data.purchase_currency : null,
    // "purchase_currency_rate" : data.purchase_currency_rate ? data.purchase_currency_rate : null,
    // "nonRefundable" : data.nonRefundable ? data.nonRefundable : false,
    // "tcs_on": data.tcs_on ? data.tcs_on : 'total',
    
    // 'hotel': Hotels.HotelList,
    // 'car': Cars.CarList,
    // 'bus': Buses.BusList,
    // 'rail': Rails.RailList,
    // 'visa': Visas.VisaList,
    // 'insurance': Insurances.InsuranceList,
    // 'tour': tours.TourList,
    // 'total_fare':this.totalFareModel

  return invoioceObj;

  }

  postMethod(url, data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.httpClient
      .post(`${environment.okountUrl}/${url}`, data, { headers: headers })
      .pipe(map((res) => {
          return res;
        }),
        catchError((err) => {
          return throwError(err);
        }));
  }

   
}