<form [formGroup]="filterForm">
    <div class="flexbox aff-info">
      <div class="flexbox aff-info-inner">
        <div class="flexbox ">
          <div class="input-fiel">
            <input class="input-ui" searchtabindex="1" formControlName="start_date" id="from_date" type="date"
                min="{{minDate}}" max="{{maxDate}}"
                [ngClass]="{'error-box': !filterForm.controls.start_date.valid}"
                />
            <label for="from_date">Starting Date</label>
  
          </div>
          <div class="input-fiel">
            <input class="input-ui" searchtabindex="2" formControlName="end_date" id="to_date" type="date"
                min="{{minDate}}" max="{{maxDate}}"
                [ngClass]="{'error-box': !filterForm.controls.end_date.valid}"
                />
            <label for="to_date">Ending Date</label>
          </div>
        </div>
      
        <div class="flexbox mt-3">
          <div class="select-field text-input-fiel">
            <select class="input-field" searchtabindex="3" formControlName="selectedBranch" id="selectedBranch">
              <option value="">Select</option>
              <option value="">None</option>
              <option value="all">All</option>
              <option *ngFor="let item of branches" value="{{item.tenant_id}}">{{item?.branch_name}}
              </option>
            </select>
            <label for="selectedBranch">Branch</label>
          </div>
        </div>

        <div class="d-flex mt-5 justify-content-center">
          <div class="input-fiel">
            <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
              searchtabindex="3" #searchButton (keyup.enter)="clickSearchButton()"
              [disabled]="filterForm.invalid"
              (click)="search(filterForm.value)">
              Search</button>
            <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
          </div>
        </div>
      </div>
    </div>
  </form>