import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "lodash";

@Pipe({
  name: 'globalFilter'
})
export class GlobalFilterPipe implements PipeTransform {

  transform(value: Array<object>, search: string): Array<object> {
    var array = [];
    if (search == '' || search == undefined) {
      return value;
    }
    search = search.toLocaleLowerCase()
    _.find(value, function (o) {
      debugger
      // {{item.policy_display_key}}
      if (o['policy_display_key'] != undefined && o['last_modified_by_name'] != null && o['policy_group'] != null) {

        if (
          o['policy_display_key'].toLocaleLowerCase().indexOf(search) >= 0 ||
          o['last_modified_by_name'].toLocaleLowerCase().indexOf(search) >= 0 ||
          o['policy_group'].toLocaleLowerCase().indexOf(search) >= 0
        ) {
          array.push(o)
        }
      }
    })
    return array
  }
}

