<div class="ledger-container ">
    <!-- <div class="tab-nav d-flex align-item-center justify-content-between">
        <ul class="list-inline list-unstyled p-0 m-0">
            <li class="active page-title   text-uppercase"> Flown Report</li>
        </ul>
        <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
            <li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition"> Dashboard
            </li>
            <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
            <li class="text-grey text-capitalize cursor text-primary transition">View Flown Report</li>
        </ul>
    </div> -->
    <div class="row tab-nav pl-0 pr-0 justify-content-between">
        <!-- <div class="col-sm-4 pl-0">
                      <ul class="list-inline list-unstyled p-0 m-0">
                          <li class="active page-title text-uppercase cursor"> View Client</li>
                      </ul>
                  </div> -->
        <div class="col-sm-4">
          <ul
            class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0"
          >
            <li
              [routerLink]="['/']"
              class="text-grey text-capitalize cursor hover-text-primary transition"
              [ngClass]="{ 'd-none': isPrint == 'true' }"
            >
              Analysis
            </li>
            <li>
              <img
                src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg"
                alt=""
                [ngClass]="{ 'd-none': isPrint == 'true' }"
              />
            </li>
            <li
              class="text-grey text-capitalize cursor text-primary transition"
              [ngClass]="{ 'd-none': isPrint == 'false' }"
              [ngClass]="{ 'd-none': isPrint == 'true' }"
            >
            Flown Report
            </li>
            <!-- <li class="text-primary line-clamp line-clamp-1">
              <ng-container
                *ngFor="let data of leaderData['all_clients_records']"
              >
                <span class="text-bold">
                  {{ data["display_name"] }}
                </span>
              </ng-container>
            </li> -->
          </ul>
        </div>
        <div class="col-sm-4 text-center">
          <span class="rec-date d-block text-center">
            <div class="noWrap justify-content-around">
              <ng-container>
                <div class="cname text-bold">
                  <span class="text-bold"
                    >INV Date From </span
                  >
                  <h5 style="margin-right: 5px" class="mb-0 d-inline">
                    <span
                      class="date text-bold text-orange"
                    >{{inv_duration && inv_duration.from_date}}
                    </span>
                    <span class="date text-bold" >
                      To
                    </span>
                    <span class="date text-bold text-orange">
                      {{inv_duration && inv_duration.end_date}}
                    </span>
                  </h5>
                  <span class="text-bold"
                    >& Travel Date From </span
                  >
                  <h5 style="margin-right: 5px" class="mb-0 d-inline">
                    <span
                      class="date text-bold text-orange"
                    >{{travel_duration && travel_duration.from_date}}
                    </span>
                    <span class="date text-bold" >
                      To
                    </span>
                    <span class="date text-bold text-orange">
                      {{travel_duration && travel_duration.end_date}}
                    </span>
                  </h5>
                 
                  <button
                    [ngClass]="{ 'd-none': isPrint == 'true' }"
                    title="Custom Search"
                    class="btn save-as text-bold bg-whiteSmoke"
                    type="button" (click)="openAdvanceSearchModal()"
                  >
                    <img
                      src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg"
                      alt=""
                      width="15px"
                    />
                  </button>
                </div>
              </ng-container>
            </div>
          </span>
        </div>
        <div class="col-sm-4 d-flex align-item-center justify-content-end">
          <div
          class="page-search-box input-group d-flex align-item-center" 
          [ngClass]="{ 'd-none': isPrint == 'true' }"
          style="height: fit-content; text-align: left"
        >
        <input type="text" class="form-control" [(ngModel)]="onSearch" oninput="this.value = this.value.toUpperCase()"
        (ngModelChange)="this.onSearchFilter.next($event)" placeholder="search" style=" text-align: left" />
        </div>
        
          <div class="dropdown-btn position-relative" 
          [ngClass]="{ 'd-none': isPrint == 'true' }">
            <button title="More..." class="btn-style mt-0 more-btn">
              <span class="dot"></span><span class="dot"></span
              ><span class="dot"></span>
            </button>
            <ul class="list-unstyled pl-0 dropdown-box">
              
              <li   (click)="downloadPdf()" >
                <img
                  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg"
                  alt=""
                />
                <span class="ml-2">Save As Pdf</span>
              </li>
              
            </ul>
          </div>
        </div>
      </div>


    <div class="table-container ">
      <div class="table flown-tbl" style="margin-top: -5px;">
        <nz-table class="table  striped"  style="table-layout: auto!important;"
        #flownReportData [nzData]="invoiceArr"
        [nzLoading]="loader"
        [nzFrontPagination]="false"
        [nzScroll]="{ x: '1000px', y: 'calc(100vh - 160px)' }"
        >
            <thead>
                <tr #tableHeader tabIndex="0">
                    <th [nzLeft]="true" style="min-width: 90px;" class="text-left">Issue Date</th>
                    <th  style="width: 90px; min-width: 90px;" class="text-left">INV Date</th>
                    <th style="width: 154px;min-width: 154px;" class="text-left" >Invoice No.</th>
                    <th style="width: 116px;min-width: 116px;" class="text-left">TKT No</th>
                    <th style="width: 60px;min-width: 60px;" class="text-left">PNR</th>
                    <th style="width: 90px;min-width: 90px;" class="text-left">GDS</th>
                    <th style="width: 150px;min-width: 150px;" class="text-left">Supplier</th>
                    <th style="width: 60px;min-width: 60px;" class="text-left">CRN No.</th>
                    <th style="width: 180px;min-width: 180px;" class="text-left" >Pax Name</th>
                    <th style="width: 90px;min-width: 90px;" class="text-left">Travel Date</th>
                    <th style="width: 70px;min-width: 70px;" class="text-right">Fare Amt</th>
                    <th style="width: 50px;min-width: 50px;" class="text-right">Basic</th>
                    <th style="width: 50px;min-width: 50px;" class="text-right">YQ</th>
                    <th style="width: 50px;min-width: 50px;" class="text-right">YR</th>
                    <th style="width: 50px;min-width: 50px;" class="text-right">K3</th>
                    <th style="width: 50px;min-width: 50px;" class="text-right">OC</th>
                    <th [nzRight]="true" style="width: 59px;min-width: 59px;" class="text-right">OT</th>
                </tr>
            </thead>
            <ng-container *ngIf="flownReportData && flownReportData['data'] && flownReportData['data'].length>0 else noResult">
              <tbody [ngStyle]="{height: innerHeight}" class="main-panel" infiniteScroll
                    (window:keydown)="onKeyDown($event)" [fromRoot]="fromRoot" [infiniteScrollDistance]="1"
                    [infiniteScrollUpDistance]="1" [infiniteScrollThrottle]="200"
                    [infiniteScrollContainer]="selector" (scrolled)="onScrollDown($event)">
                  <ng-container *ngFor="let invoice of flownReportData.data ; let i = index;">
                      <tr class="field-table-input"  [attr.tabIndex]="i+1"
                          id="rowIndex_{{i+1}}" (keyup.enter)="openInvoice(invoice.reference)">
                          <td [nzLeft]="true"  style="width: 90px;min-width: 90px;" class="text-left text-grey" (click)="moveToIndex(i)">{{invoice?.created_on | date :
                              'dd/MM/yyyy'}}</td>
                          <td style="width: 90px;min-width: 90px;" class="text-left text-grey" (click)="moveToIndex(i)">{{invoice?.invoice_date | date :
                              'dd/MM/yyyy'}}</td>
                          <td style="width: 154px;min-width: 154px;" class="text-left text-grey" (click)="moveToIndex(i)" >
                              <span style="display: block; " class="ellipse-text text-black font-500" *ngIf="invoice.reference_type=='invoice'"
                                 >{{invoice?.reference}}</span>
                              <span style="display: block; " class="ellipse-text text-black font-500" *ngIf="invoice.reference_type=='credit_note'"
                                  >{{invoice?.reference_invoice}}</span>
                          </td>
                          <td  style="width: 116px;min-width: 116px;"  class="text-left text-grey ellipse-text" (click)="moveToIndex(i)"> {{invoice?.ticket_no}}</td>
                          <td style="width: 60px;min-width: 60px;"  class="text-left text-grey" (click)="moveToIndex(i)">{{invoice?.pnr}}</td>
                          <td style="width: 90px;min-width: 90px;" class="text-left text-grey" (click)="moveToIndex(i)">{{invoice?.gds_name}}</td>
                          <td style="width: 150px;min-width: 150px;" class="text-left text-grey ellipse-text" (click)="moveToIndex(i)">
                              <span  class="ellipse-text"  *ngIf="invoice.supplier_account_name != ''">{{invoice?.supplier_account_name}}</span>
                              <span  class="ellipse-text"  *ngIf="invoice.supplier_account_name == ''">{{invoice?.airline_account_name}}</span>
                          </td>
                          <td style="width: 60px;min-width: 60px;"  class="text-left text-grey ellipse-text" (click)="moveToIndex(i)">
                            <span class="ellipse-text" *ngIf="invoice.reference_type=='credit_note'">{{invoice?.reference}}</span></td>

                          <td style="width: 180px;min-width: 180px;" class="text-left text-grey ellipse-text" (click)="moveToIndex(i)">
                            <span style="width: 180px;min-width: 180px;display: block!important;" class="ellipse-text">{{invoice?.title}} {{invoice?.name}}</span>
                          </td>

                          <td  style="width: 90px;min-width: 90px;" class="text-left text-grey" (click)="moveToIndex(i)">{{invoice?.travelling_date | date :
                              'dd/MM/yyyy'}}</td>
                          <td style="width: 70px;min-width: 70px;"  class="text-right text-grey" (click)="moveToIndex(i)">
                              {{invoice?.net_received}}</td>
                          <td style="width: 50px;min-width: 50px;"  class="text-right text-grey" (click)="moveToIndex(i)">
                              {{invoice?.base}}</td>
                          <td style="  width: 50px;  min-width: 50px;" class="text-right text-grey" (click)="moveToIndex(i)">
                              {{invoice?.yq}}</td>
                          <td style="  width: 50px;  min-width: 50px;" class="text-right text-grey" (click)="moveToIndex(i)">
                              {{invoice?.yr}}</td>
                          <td style="  width: 50px;  min-width: 50px;" class="text-right text-grey" (click)="moveToIndex(i)">
                              {{invoice?.k3}}</td>
                          <td  style=" width: 50px;   min-width: 50px;" class="text-right text-grey" (click)="moveToIndex(i)">
                              {{invoice?.oc}}</td>
                          <td [nzRight]="true" style="  width: 55px;  min-width: 55px;" class="text-right text-grey" (click)="moveToIndex(i)">
                              {{invoice?.ot}}</td>
                      </tr>
                  </ng-container>
              </tbody>
            </ng-container>
          <ng-template #noResult>
            <div *ngIf="!loader" class="w-100 text-center" style="padding: 20px;">
                <nz-empty nzNotFoundContent="No result Found"></nz-empty>
            </div>
        </ng-template>
        </nz-table>
      </div>
    </div>
  </div>