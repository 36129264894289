import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, Validators, FormBuilder, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormCanDeactivate } from 'src/app/shared/form-can-deactivate/form-can-deactivate';
import { CookiesService } from 'src/app/shared/cookies.service';
@Component({
	selector: 'app-update-airline',
	templateUrl: './update-airline.component.html',
	styleUrls: ['./update-airline.component.scss']
})
export class UpdateAirlineComponent extends FormCanDeactivate implements OnInit {
	@Input() data
	@ViewChild('form')
	form: NgForm;
	airlineId: any;

	submitted = false;
	baseUrl = environment.okountUrl;
	airlineForm: FormGroup;
	airlineContactForm: FormGroup;
	groupList: any;

	alertType = '';
	message: string = ``;
	countryArr: any = [];
	stateArr: any = [];
	cityArr: any = [];
	
	allContactsForm: FormGroup;
	selectedAirline : any;
	airlineContacts: any = [];
	remainingAmount = 0
    amountType = 'credit'
	showMessage: boolean = false;
	airlineData: any;
	isTenantIndian: boolean = false;
	vatAlternetname: string = 'VAT'

	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef
	@ViewChild('confirmDeleteModal',{static:true}) confirmDeleteModal : ElementRef
	@ViewChild('closeDeleteModal',{static:true}) closeDeleteModal : ElementRef
	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef
	@ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef
	@ViewChild('openContactDetail',{static:true}) openContactDetail : ElementRef
	@ViewChild('closeContactDetail',{static:true}) closeContactDetail : ElementRef
	@ViewChild('addEditContact', {static: true}) addEditContact : ElementRef
	@ViewChild('closeEditContact',{static:true}) closeEditContact : ElementRef
	@ViewChild('confirmOBModal',{static:true}) confirmOBModal : ElementRef;
    @ViewChild('closeOBModal',{static:true}) closeOBModal : ElementRef;


	constructor(
		public commonService: CommonServiceService,
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private spinner: NgxSpinnerService,
		private cookieService : CookiesService
	) {
		super()
		this.route.paramMap.subscribe(params => {
			this.airlineId = params['params'].id
		});

		this.airlineForm = this.fb.group({
			parent_id: ['', Validators.required],
			account_name: ['', Validators.required],
			address_1: [''],
			city_id : [''],
			city_name : [''],
			state_id : [''],
			state_name : [''],
			country_id : [''],
			country : [''],
			gst_number : [''],
			vat_number: [''],
			telephone: [''],
			mobile_no: [''],
			primary_email: [''],
			secondary_email: [''],
			pan_number: [''],
			remarks: [''],
			balance: [''],
			status: [''],
			balance_type: [''],
			"2_ltr_code": ['', Validators.required],
			"3_ltr_code": ['', Validators.required]

		})

		this.airlineContactForm = this.fb.group({
			account_code: ['',  Validators.required],
			type: ['', Validators.required],
			mobile: ['', Validators.required],
			email: ['', Validators.required],
			description: [''],
			selectedAirline: [''],
			contact_person: [''],
			address: ['']
		})

	}

	async ngOnInit() {
		if(this.data) {
			this.airlineId = this.data.airline_id
		}
		if (this.airlineId != undefined) {
			this.getAirline()
		}

		
		this.isTenantIndian = this.commonService.checkTenantIndian()
		if(!this.isTenantIndian){
			let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
			let inv_config = globalSettingConfig.filter(el=>el.group=='VAT')
		if(inv_config.length){
			let vat_config = inv_config[0].json_doc.value.filter(el=>el.key=='vat_rate')
			let vat_detail,vat_name
			if(vat_config.length){
				 vat_detail = vat_config[0].value
			}
			if(vat_detail && vat_detail.length ){
				vat_name = vat_detail.filter(el=>el.key=='effective_name')
			}
			if(vat_name && vat_name.length){
				this.vatAlternetname = vat_name[0].value
			}
		}
		}

	}
	getAirline() {
		this.commonService.getOkount(`airline?airline_id=${this.airlineId}`).subscribe(async (res) => {
			if (res[0] != undefined) {
				this.airlineData = res[0]
			}
			if(this.airlineData) {
				this.getAirlineContacts();
			}

			await this.getGroups()
			await this.getCountries()
			await this.getStates(this.airlineData.country_id)
			await this.getCities(this.airlineData.state_id)
			await this.getCityName(this.airlineData.city_id)
			await this.setAirlineData()
		})
	}
	setAirlineData() {
		if (this.airlineForm != undefined && this.airlineData != undefined) {
			this.airlineForm.controls.parent_id.setValue(this.airlineData.parent_id)
			this.airlineForm.controls.address_1.setValue(this.airlineData.address_1)
			this.airlineForm.controls.city_id.setValue(this.airlineData.city_id)
			this.airlineForm.controls.city_name.setValue(this.airlineData.city_name)
			this.airlineForm.controls.state_id.setValue(this.airlineData.state_id)
			this.airlineForm.controls.state_name.setValue(this.airlineData.state_name)
			this.airlineForm.controls.country_id.setValue(this.airlineData.country_id)
			this.airlineForm.controls.country.setValue(this.airlineData.country)
			this.airlineForm.controls.gst_number.setValue(this.airlineData.gst_number)
			this.airlineForm.controls.vat_number.setValue(this.airlineData.vat_number)
			this.airlineForm.controls.telephone.setValue(this.airlineData.telephone)
			this.airlineForm.controls.mobile_no.setValue(this.airlineData.mobile_no)
			this.airlineForm.controls.primary_email.setValue(this.airlineData.primary_email)
			this.airlineForm.controls.secondary_email.setValue(this.airlineData.secondary_email)
			this.airlineForm.controls.pan_number.setValue(this.airlineData.pan_number)
			this.airlineForm.controls.balance.setValue(this.airlineData.balance)
			this.airlineForm.controls.remarks.setValue(this.airlineData.remarks)
			this.airlineForm.controls.account_name.setValue(this.airlineData.account_name)
			this.airlineForm.controls.status.setValue(this.airlineData.status)
			this.airlineForm.controls['2_ltr_code'].setValue(this.airlineData['2_ltr_code'])
			this.airlineForm.controls['3_ltr_code'].setValue(this.airlineData['3_ltr_code'])
			this.airlineForm.controls.balance_type.setValue(this.airlineData.balance_type)

		}
	}

	getCountries() {
		this.commonService.getOkount(`location/country`).subscribe(res => {
			this.countryArr = res
		})
	}

	getStates(country_id) {
		let selectedCountry = this.countryArr.filter(option => {
			return option.location_id == Number(country_id);
		})
		if (selectedCountry.length > 0) {
			this.airlineForm.controls.country.setValue(selectedCountry[0].name)
		} else {
			this.airlineForm.controls.country.setValue('India')
		}
		console.log(selectedCountry)
		this.commonService.getOkount(`location/states?country_id=${country_id}`).subscribe(res => {
			this.stateArr = res
		})
	}
	getCities(state_id) {
		let selectedState = this.stateArr.filter(option => {
			return option.location_id == Number(state_id);
		})
		if (selectedState.length > 0) {
			this.airlineForm.controls.state_name.setValue(selectedState[0].name)
		}
		this.commonService.getOkount(`location/city?state_id=${state_id}`).subscribe(res => {
			this.cityArr = res
		})
	}
	getCityName(city_id) {
		let selectedCity = this.cityArr.filter(option => {
			return option.location_id == Number(city_id);
		})
		if (selectedCity.length > 0) {
			this.airlineForm.controls.city_name.setValue(selectedCity[0].name)
		}
	}

	openUpdateConfirmModal(){
		this.confirmUpdateModal.nativeElement.click()
		this.commonService.selectedInput.next(22)
	}

	closeUpdateConfirmModal(){
		this.closeUpdateModal.nativeElement.click()
	}

	updateAirline() {

		this.submitted = true;
		this.closeUpdateConfirmModal()
		
		let formValid = true;
		if(this.airlineForm.controls['account_name'].value != 'BSP' ) {
			if(!this.airlineForm.valid) {
				formValid = false
			}
		}
		if (formValid) {
			this.commonService.putRequest(`${this.baseUrl}/airline?airline_id=${this.airlineId}`, this.airlineForm.value).subscribe(res => {
				this.updateOpeningBalanceAdjustAcc()
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = 'Airline updated successfully.'
			}, error => {
				this.showMessage = true
				this.alertType = 'error-box';
				this.message = 'Failed to update Airline. Please try Again.'
			})
		}
		else {
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = 'Please fill mandatory fields.'
			return false;
		}
	}

	getGroups() {
		this.commonService.getOkount('v3account/getallsuperparents').subscribe(res => {
			this.groupList = res
		}, err => {

		})
	}
	hideError() {
		this.showMessage = false
	}

	closeWindow() {
		window.close();
	}

	openDeleteConfirmModal(){
		this.confirmDeleteModal.nativeElement.click()
		this.commonService.selectedInput.next(24)
	}

	closeDeleteConfirmModal(){
		this.closeDeleteModal.nativeElement.click()
	}


	deleteAirline(){
		// this.submitted = true;
		let airlinEid = this.airlineData.account_code
		this.closeDeleteConfirmModal()
		if(airlinEid != ''){
			this.commonService.deleteRequest(`${this.baseUrl}/airline?account_code=${airlinEid}`).subscribe((res: any) => {
				this.airlineForm.reset()
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = `Airline Deleted.`
				this.spinner.hide();
			},
				err => {
					this.showMessage = true
					this.alertType = 'error-box';
					this.message = `Failed to delete airline.`
					this.spinner.hide();
				})
		} else {
			this.airlineForm.reset()
		}
	}
	openConfiguration(airline, airlineCode) {
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/master/setting/airline/${airline}/${airlineCode}`
		window.open(path,'_blank')
	}	

	openActivity(){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/airline/${this.airlineData.account_code}`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}


	openAddEditContactModal() {
		this.addEditContact.nativeElement.click()
	}

	saveContact() {
		let account_code = this.airlineData.account_code
		this.airlineContactForm.controls.account_code.setValue(account_code)
		this.commonService.postRequest(`${this.baseUrl}/airline/contacts`, this.airlineContactForm.value).subscribe(res => {
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = 'Address added successfully.'
			this.getAirlineContacts()
			this.closeContactDetail.nativeElement.click()
		}, error => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Failed to add address for the airline. Please try Again.'
		})
	}

	updateContact() {
		this.commonService.putRequest(`${this.baseUrl}/airline/contacts?contact_id=${this.selectedAirline.id}`, this.airlineContactForm.value).subscribe(res => {
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = 'Address updated successfully.'
			this.getAirlineContacts()
			this.closeContactDetail.nativeElement.click()
		}, error => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Failed to update address for the airline. Please try Again.'
		})	
	}


	getAirlineContacts() {
		let account_code = this.airlineData.account_code
		this.commonService.getOkount(`airline/contacts?account_code=${account_code}`).subscribe((res) => {
			if (res != undefined) {
				this.airlineContacts = res['data']
			}
		})
	}

	submitAddEditContact() {
		    let account_code = this.airlineData.account_code
			let value = this.airlineContactForm.controls.selectedAirline.value
			this.closeEditContact.nativeElement.click()
			if(value == '' || value == null || value == undefined ) {
				this.airlineContactForm.controls.account_code.setValue(account_code)
				this.airlineContactForm.controls.type.setValue("")
				this.airlineContactForm.controls.mobile.setValue("")
				this.airlineContactForm.controls.email.setValue("")
				this.airlineContactForm.controls.description.setValue("")
				this.airlineContactForm.controls.contact_person.setValue("")
				this.airlineContactForm.controls.address.setValue("")
				this.openContactDetail.nativeElement.click()
			} else {
				let airline = this.airlineContacts.find(acc => acc.id == value)
				this.selectedAirline = airline
				this.airlineContactForm.controls.account_code.setValue(account_code)
				this.airlineContactForm.controls.type.setValue(airline.type)
				this.airlineContactForm.controls.mobile.setValue(airline.mobile)
				this.airlineContactForm.controls.email.setValue(airline.email)
				this.airlineContactForm.controls.description.setValue(airline.description)
				this.airlineContactForm.controls.contact_person.setValue(airline.contact_person)
				this.airlineContactForm.controls.address.setValue(airline.address)
				this.openContactDetail.nativeElement.click()
		    }
	}
	async checkBalance(){
        let opening_amount = await  this.getOpeningBalanceData()
        if(this.airlineForm.controls.balance_type.value=='debit') opening_amount['debit']= Number(opening_amount['debit']) + Number(this.airlineForm.controls.balance.value)
        if(this.airlineForm.controls.balance_type.value=='credit') opening_amount['credit']= Number(opening_amount['credit']) + Number(this.airlineForm.controls.balance.value)
            console.log("opening_amount",opening_amount)
        if(opening_amount && (opening_amount['debit']!=opening_amount['credit'])) {
            this.remainingAmount = Math.abs(Number((opening_amount['debit'] - opening_amount['credit']).toFixed(2)))
            this.amountType = opening_amount['debit']>opening_amount['credit'] ? 'credit' : 'debit'
			let res = await this.adjustAccOpeningBalance()
            if(res['balance']!=this.remainingAmount || this.amountType!=res['balance_type']){
                this.openOBConfirmModal()
			}else{
				this.updateOpeningBalancecombine()
			}
        }else{
            this.updateOpeningBalancecombine()
        }
    }

	adjustAccOpeningBalance(){
        return new Promise(async (resolve, reject) => {
        this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/globalsearch/opening_balance`, {client: 'OB0000165'}).subscribe(res => {
            resolve(res)
        },err=>{
            console.log("err",err)
            resolve(null)
        })
    })
    }
	
	getOpeningBalanceData(){
        return new Promise(async (resolve, reject) => {
            this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/openingBalanceTotal`, {"account_code":this.airlineData.account_code}).subscribe(res => {
                resolve(res)
            },err=> {
                resolve(null)
            })
        })
    }
	openOBConfirmModal(){
		this.confirmOBModal.nativeElement.click()
	}
	updateOpeningBalancecombine(){
        this.closeOBConfirmModal()
         this.updateAirline()
        
    }
	closeOBConfirmModal(){
		this.closeOBModal.nativeElement.click()
	}
	updateOpeningBalanceAdjustAcc() {
        let req_body = {
            balance: this.remainingAmount,
            balance_type: this.amountType,
            account_code: 'OB0000165'
        }
        this.commonService.putRequest(`${environment.okountUrl}/client/updateOpeningBalanceAdjust`, req_body).subscribe(res => {
           
            console.log(res)
        }, err => {
        })

    }

}

	