import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PaymentService } from '../services/payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment'
import { ActivatedRoute } from '@angular/router';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { NgForm } from '@angular/forms';
import { FormCanDeactivate } from 'src/app/shared/form-can-deactivate/form-can-deactivate';
import { environment } from 'src/environments/environment';
@Component({
	selector: 'app-update-payment',
	templateUrl: './update-payment.component.html',
	styleUrls: ['./update-payment.component.scss']
})
export class UpdatePaymentComponent extends FormCanDeactivate implements OnInit {
	@ViewChild('form')
 	form: NgForm;
	submitResponse: any;
	generalMasterList: any;
	showMessage: boolean = false;
	alertType = '';
	message: string = ``;
	paymentId: any;
	paymentData: any;

	@ViewChild('confirmDeleteModal',{static:true}) confirmDeleteModal : ElementRef
	@ViewChild('closeDeleteModal',{static:true}) closeDeleteModal : ElementRef
	@ViewChild('deleteConfirmBtn',{static:true}) deleteConfirmBtn : ElementRef

	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef
	@ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef

	@ViewChild('updateConfirmBtn',{static:true}) updateConfirmBtn : ElementRef
	@ViewChild('updateButton',{static:true}) updateButton : ElementRef
	constructor(
		public paymentService: PaymentService,
		private spinner: NgxSpinnerService,
		private route: ActivatedRoute,
		public commonService: CommonServiceService
		// private fb: FormBuilder
	) {
		super()
		this.route.paramMap.subscribe(params => {
			this.paymentId = params['params'].id
			this.getPaymentDetails()
		});
	}

	ngOnInit() {	
		this.spinner.show();
		setTimeout(() => {
			this.spinner.hide();
		}, 1000);
		
	}

	// ngAfterViewInit(){
	// 	this.commonService.selectedInput.subscribe(index=>{
	// 		console.log('Selected Input tabindex : ',index)

	// 		if(index == 8){
	// 			this.focusDeleteConfirm()
	// 		}
		
	// 		if(index == 10){
	// 			debugger
	// 			this.focusUpdateConfirm()
	// 		}


	// 	})
	// }

	getPaymentDetails() {
		this.paymentService.getMethod(`paymentv2?payment_id=${this.paymentId}`).subscribe(async (res) => {
			if (res[0] != undefined) {
				this.paymentData = res[0]
				await this.getGeneralMaster('general/bank')
				console.log(res)
				await this.setPaymentData()
				this.getEntries()

			}
		}, err => {

		})
	}
	setPaymentData() {
		if (this.paymentData != undefined) {
			this.paymentService.paymentForm.controls.payment_id.setValue(this.paymentData.payment_id)
			this.paymentService.paymentForm.controls.payment_code.setValue(this.paymentData.payment_code)
			this.paymentService.paymentForm.controls.accountCodeFrom.setValue(this.paymentData.account_code_from)
			if (this.paymentData.payment_timestamp != null && this.paymentData.payment_timestamp != '') {
				this.paymentService.paymentForm.controls.paymentDate.setValue(moment(this.paymentData.payment_timestamp).format('YYYY-MM-DD'))
				
			}
			this.paymentService.paymentForm.controls.accountCodeType.setValue(this.paymentData.account_code_from_type ? this.paymentData.account_code_from_type : 'general')
			this.paymentService.paymentForm.controls.amount.setValue(this.paymentData.amount)
		}
	}



	addRecord(status, formValue) {
		this.spinner.show();
		this.paymentService.isEdit = false
		let userInfo = JSON.parse(localStorage.okountUserInfo)
		let addPaymentObj = {
			"account_code_from": formValue.accountCodeFrom,
			"payment_timestamp": moment(formValue.paymentDate).format('YYYY-MM-DD'),
			"last_modified_by": userInfo.id,
			"last_modified_by_name": userInfo.name
		}
		if (formValue.payment_id == "") {
			this.paymentService.postMethod(`paymentv2`, addPaymentObj).subscribe((res: any) => {
				let response = res
				this.paymentService.showAddPaymentScreen = status
				this.paymentService.paymentForm.controls.payment_id.setValue(response.payment_id)
				this.paymentService.paymentEntryForm.controls.account_code_from.setValue(formValue.accountCodeFrom);
				
				this.spinner.hide();
			},
				err => {
					this.spinner.hide();
				})
		} else {
			this.paymentService.showAddPaymentScreen = status
			this.spinner.hide();
		}
	}


	getEntries() {
		let payment_id = this.paymentService.paymentForm.value.payment_id
		if (payment_id != '') {
			this.paymentService.getMethod(`paymententryv2/getallbypaymentid?payment_id=${payment_id}`).subscribe((res: any) => {
				this.paymentService.paymentEntries = res

				this.spinner.hide();
			},
				err => {
					this.spinner.hide();
				})
		} else {
			this.paymentService.paymentEntries = []
		}
	}

	updatePayment() {
		this.spinner.show();
		this.closeUpdateConfirmModal()
		let formValues = this.paymentService.paymentForm.value
		let currentUser= JSON.parse(localStorage.getItem('okountUserInfo'))
		
		let reqBody = {
			"payment_id": formValues.payment_id,
			"payment_code": formValues.payment_code,
			"account_code_from": formValues.accountCodeFrom,
			"payment_timestamp": moment(formValues.paymentDate).format('YYYY-MM-DD'),
			"last_modified_by": currentUser.id,
			"last_modified_by_name": currentUser.name,
			"amount": formValues.amount
		}
		this.paymentService.putMethod(`paymentv2?payment_id=${formValues.payment_id}`, reqBody).subscribe(async (res: any) => {
			this.submitResponse = res
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = `Payment entry updated.`
			try {
				for (const key in this.paymentService.paymentEntries) {
					if (this.paymentService.paymentEntries.hasOwnProperty(key)) {
						const element = this.paymentService.paymentEntries[key];
						element['invoice_no'] = this.submitResponse[0].payment_code
						element['old_transaction_no'] = this.submitResponse[0].payment_code
						element['created_date'] = this.submitResponse[0].payment_timestamp
					}
				}
			// await this.doTransactionEntry(this.paymentService.paymentEntries)
			} catch (error) {

			}
			// alert(JSON.stringify(this.submitResponse))
			console.log('Payment Submit response : ', this.submitResponse)
			// this.paymentService.paymentForm.reset()
			// this.paymentService.paymentEntries = []
			// this.paymentService.paymentForm.controls.accountCodeFrom.setValue('')
			// this.paymentService.paymentForm.controls.payment_id.setValue('')
			// this.paymentService.paymentForm.controls.paymentDate.setValue('')
			this.spinner.hide();
		},
			err => {
				this.showMessage = true
				this.alertType = 'error-box';
				this.message = `Failed to update payment entry.`
				this.spinner.hide();
			})
	}

	reset() {
		let payment_id = this.paymentService.paymentForm.value.payment_id
		this.closeDeleteConfirmModal()
		if (payment_id != '') {
			this.paymentService.deleteMethod(`paymentv2?payment_id=${payment_id}`).subscribe((res: any) => {
				this.paymentService.paymentForm.reset()
				this.paymentService.paymentEntries = []
				this.paymentService.paymentForm.controls.accountCodeFrom.setValue('')
				this.paymentService.paymentForm.controls.payment_id.setValue('')
				this.paymentService.paymentForm.controls.paymentDate.setValue('')
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = `Payment entry Deleted.`
				this.spinner.hide();
			},
				err => {
					this.showMessage = true
					this.alertType = 'error-box';
					this.message = `Failed to delete payment entry.`
					this.spinner.hide();
				})
		} else {
			this.paymentService.paymentForm.reset()
			this.paymentService.paymentEntries = []
			this.paymentService.paymentForm.controls.accountCodeFrom.setValue('')
			this.paymentService.paymentForm.controls.payment_id.setValue('')
			this.paymentService.paymentForm.controls.paymentDate.setValue('')
		}
	}

	editEntry(payment) {
		this.paymentService.showAddPaymentScreen = true
		this.paymentService.isEdit = true
		localStorage.setItem('selected_payment_entry', payment.payment_entry_id)
		this.paymentService.paymentEntryForm.controls.account_code_from.setValue(payment.account_code_from)
		this.paymentService.paymentEntryForm.controls.account_code_to.setValue(payment.account_code_to)
		this.paymentService.paymentEntryForm.controls.account_code_to_type.setValue(payment.account_code_to_type)
		this.paymentService.paymentEntryForm.controls.cheque_no.setValue(payment.cheque_no)
		if (payment.cheque_date != null && payment.cheque_date != '') {
			this.paymentService.paymentEntryForm.controls.cheque_date.setValue(moment(payment.cheque_date).format('YYYY-MM-DD'))
		}
		this.paymentService.paymentEntryForm.controls.cheque_bank_name.setValue(payment.cheque_bank_name)
		this.paymentService.paymentEntryForm.controls.amount.setValue(Number(payment.amount))
		this.paymentService.paymentEntryForm.controls.narration_1.setValue(payment.narration_1)
		this.paymentService.paymentEntryForm.controls.narration_2.setValue(payment.narration_2)
		this.paymentService.paymentEntryForm.controls.narration_3.setValue(payment.narration_3)
		this.paymentService.paymentEntryForm.controls.narration_4.setValue(payment.narration_4)
		this.paymentService.paymentEntryForm.controls.narration_5.setValue(payment.narration_5)
		this.paymentService.paymentEntryForm.controls.remark_1.setValue(payment.remark_1)
		this.paymentService.paymentEntryForm.controls.remark_2.setValue(payment.remark_2)
		this.paymentService.paymentEntryForm.controls.remark_3.setValue(payment.remark_3)
		this.paymentService.paymentEntryForm.controls.remark_4.setValue(payment.remark_4)
		this.paymentService.paymentEntryForm.controls.remark_5.setValue(payment.remark_4)
		this.paymentService.paymentEntryForm.controls.sub_ledger_code_1_type.setValue(payment.sub_ledger_code_1_type)
		this.paymentService.paymentEntryForm.controls.sub_ledger_code_1.setValue(payment.sub_ledger_code_1)
		this.paymentService.paymentEntryForm.controls.sub_ledger_code_1_name.setValue(payment.sub_ledger_code_1_value ? payment.sub_ledger_code_1_value : '')
		this.paymentService.paymentEntryForm.controls.account_code_from_name.setValue(payment.account_code_from_value)
		this.paymentService.paymentEntryForm.controls.account_code_to_name.setValue(payment.account_code_to_value)
	}

	getGeneralMaster(url) {
		this.paymentService.getMethod(url).subscribe(res => {
			this.generalMasterList = res
		}, err => {

		})
	}

	// doTransactionEntry(entries) {
	// 	this.paymentService.postMethod(`v3/payment`, entries).subscribe(res => {
	// 	}, err => {

	// 	})
	// }
	hideError() {
		this.showMessage = false
	}

	openVoucher(){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/payment/print/${this.paymentId}`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}

	}

	openActivity(){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/payment/${this.paymentData.payment_code}`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

	closeWindow(){
		window.close();
	}

	openDeleteConfirmModal(){
		this.confirmDeleteModal.nativeElement.click()
		this.commonService.selectedInput.next(8)
	}
	closeDeleteConfirmModal(){
		this.closeDeleteModal.nativeElement.click()
	}
	
	focusDeleteConfirm(){
		this.deleteConfirmBtn.nativeElement.focus()
	}
	openUpdateConfirmModal(){
		this.confirmUpdateModal.nativeElement.click()
		this.commonService.selectedInput.next(10)
	}
	closeUpdateConfirmModal(){
		this.closeUpdateModal.nativeElement.click()
	}
	focusUpdateConfirm(){
		this.updateConfirmBtn.nativeElement.focus()
	}

	exitEntry(e){
		this.paymentService.showAddPaymentScreen = false
		try {
			this.commonService.selectedInput.next(3)
			this.updateButton.nativeElement.focus()
		} catch (error) {
			this.commonService.selectedInput.next(3)
		}
		
	}

}
