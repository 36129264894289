import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/shared/loader.service';
import { NumberToWordsPipe } from 'src/app/shared/pipe/print-invoice.pipe';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-print-consolidate-invoice',
  templateUrl: './print-consolidate-invoice.component.html',
  styleUrls: ['./print-consolidate-invoice.component.scss']
})


export class PrintConsolidateInvoiceComponent implements OnInit {


	invoiceData: any;
	productData: any;
	accountList: any;
	handlingCharges: any = 0;
	tdsRecv: any = 0;
	round_off: any = 0;
	showBox;
	netReceived: any;
	serviceCharge: any;
	totalInwords: any;

	isNonAccount = false
	totalNonAccountAmount: number;
	fareData: any;
	waterMark;

	@Input() reference_code;
	@Input() preFetchedData;
	@Output() callSaveFetchedData: EventEmitter<any> = new EventEmitter()
	basFare = 0;
	ot = 0;
	gstAmount = 0;
	subTotalFare = 0;
	searchId: any;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private loaderService: LoaderService,
		public commonService: CommonServiceService,
		public sanitizer: DomSanitizer
	) {
		this.sanitizer = sanitizer
	 }

	ngOnInit() {
		this.loaderService.show()
		let searchId = this.route.snapshot.params['searchId'];
		this.searchId = this.route.snapshot.params['searchId'];
		if(!this.preFetchedData){
			if(this.reference_code){
				searchId = this.reference_code
			}
			if (searchId != undefined) {
				document.title = `Invoice - ${searchId}`
			}
			let type = this.route.snapshot.params['type'];
			this.getInvoiceData(searchId, type)
		}else{
			this.invoiceData = this.preFetchedData
			this.bindData()
		}
	}
	async bindData(){
		this.getSubTotalFare(this.invoiceData)
		this.productData = this.invoiceData.products[0]
		this.totalNonAccountAmount = 0
		if(this.productData.invoice_type == 'non_account'){
			this.isNonAccount = true
		}
		let resp = await this.getTotalAmount(this.invoiceData)
		this.waterMark = `url('${this.invoiceData?.accounting_company?.tenant_logo}')`
	}

	getInvoiceData(searchId: any, type: any) {
		try {
			this.commonService.getRequest(`${environment.okountUrl}/invoice/consolidate/download/${searchId}?preview=true`).subscribe(async (res) => {
				this.invoiceData = this.transform(res)
				this.loaderService.hide()
			},
			err => {
				this.loaderService.hide()
			})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
		}
	}
	getSubTotalFare(invoiceData: any) {
		this.basFare 
		this.ot
		this.subTotalFare
		for(const data of  invoiceData.products){
			this.basFare+=Number(data.client_basic)
			this.ot+=this.getOT(data)
			this.gstAmount +=this.getGST(data)
			this.subTotalFare+=this.getTotal(data)
		}

	}

	getTotalAmount(invoiceData){
		this.commonService.postRequest(`${environment.okountUrl}/invoice/consolidate-fare`,invoiceData).subscribe(async (res : any)=> {
			console.log('calculationRes',res)
			this.fareData = res
			this.tdsRecv = 	res.client_tds ? res.client_tds : 0
			this.totalNonAccountAmount = Number(res.client_non_account) + Number(res.client_basic)
			this.serviceCharge = 	res.client_sc1_amount ? res.client_sc1_amount : 0
			this.netReceived = 	res.client_net_amount ? res.client_net_amount  : 0
			this.round_off = 	((this.netReceived - Math.round(this.netReceived))* -1).toFixed(2)
			let numberToBeConverted = Number(this.netReceived)
			this.totalInwords = ""
			if (numberToBeConverted < 0) {
				numberToBeConverted = numberToBeConverted * -1
				this.totalInwords = "Minus "
			}
			this.totalInwords += new NumberToWordsPipe().transform(Number(numberToBeConverted).toFixed(0))

			this.loaderService.hide()
			try {
				setTimeout(() => {
					document.querySelector("#print-section") && document.querySelector("#print-section").classList.add("voucher-container")
				}, 1000);
			} catch (error) {
				
			}
		  },err=> {
			this.loaderService.hide()
		  })
	}
	
	getNumberToWord(value) {
		if (value != undefined) {
			return new NumberToWordsPipe().transform(Number(value).toFixed(2))
		}
	}
	getSSRamount(ssrs, type) {
		if (ssrs != undefined) {
			let mealAmount = 0
			let seatAmount = 0
			let baggageAmount = 0
			for (let i = 0; i < ssrs.length; i++) {
				const ssr = ssrs[i];
				mealAmount += Number(ssr.meal.amount)
				baggageAmount += Number(ssr.baggage.amount)
				seatAmount += Number(ssr.seat.amount)
			}
			if (type == 'seat') {
				return seatAmount
			} else if (type == 'meal') {
				return mealAmount
			} else if (type == 'baggage') {
				return baggageAmount
			}
		}
	}

	getOT(productData) {
		return Number(productData.client_taxes) + this.getServiceCharge(productData,'N')
	}
	getGST(productData) {
		return Number(productData.client_igst) + Number(productData.client_cgst) + Number(productData.client_sgst)
	}
	getTotal(productData) {
		let fare = productData.client_basic ? productData.client_basic : 0;
		let taxes = productData.client_taxes ? productData.client_taxes : 0;
		return Number(fare) + Number(taxes) + this.getServiceCharge(productData,'N')
	}

	getSC(scType){
		let amount = 0
		for (const product of this.invoiceData.products) {
			amount += this.getServiceCharge(product,scType)
		}
		return amount
	}

	getServiceCharge(product,scType){
		let amount = 0
		amount += product.client_sc1_amount && product.client_sc1_type == scType ? Number(product.client_sc1_amount) : 0
			
		amount += product.client_sc2_amount && product.client_sc2_type == scType ? Number(product.client_sc2_amount) : 0
		
		amount += product.client_sc3_amount && product.client_sc3_type == scType ? Number(product.client_sc3_amount) : 0
		return amount;
	}

	printInvoice() {
		window.print()
	}

	transform(html) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}

	downloadPdf() {
		this.commonService.downloadPDF(`${environment.okountUrl}/invoice/consolidate/download/${this.searchId}?preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `Invoice-${this.searchId}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}

}
