<div class="modal-body">
	<form [formGroup]="pwdForm">
		<div class="flexbox justify-content-center ml-4">

			<div class="input-fiel">
				<input class="input-ui" id="old_password" type="password" formControlName="oldPassword" /><label
					for="old_password">Old Password</label>
			</div>


			<div class="input-fiel mt-3">
				<input class="input-ui" id="new_password" type="password" autocomplete="off" id="txtPassword"
					formControlName="newPassword" /><label for="new_password">New Password</label>
			</div>
			

			<div class="input-fiel mt-3">
				<input class="input-ui" id="confirm_password" type="password" autocomplete="off" id="txtConfirmPassword"
					formControlName="confirm_password" /><label for="confirm_password">Confirm Password</label>
			</div>


		</div>
		<div class="d-flex mt-2 justify-content-center " *ngIf="showMessage">
			<div class="{{alertType}}">
				{{message}}
			</div>
		</div>
		<div class="d-flex mt-2 justify-content-center " *ngIf="pwdForm.hasError('notSame') && confirm_password.dirty">
			<div class="error-message">
				Password Mismatch
			</div>
		</div>
		
		<div class="d-flex   justify-content-center mt-20 ">
			<div class="input-fiel">
				<button type="button" class="btn-style bg-green mr-3" [disabled]="!pwdForm.valid"
					(click)="changePassword(pwdForm.value)"> SAVE</button>
				<button type="button" class="btn-style bg-green">RESET</button>
			</div>
		</div>
	</form>


</div>