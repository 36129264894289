import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DayBookReportRoutingModule } from './day-book-report-routing.module';
import { DayBookReportComponent } from './day-book-report.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [DayBookReportComponent],
  imports: [
    CommonModule,
    DayBookReportRoutingModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [DayBookReportComponent]
})
export class DayBookReportModule { }
