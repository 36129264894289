
	<div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
	 <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
	   <div class="d-flex flex-y-center">
		 <img
		   src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
			 alertType=='success-box' ? 'green-sucess.svg' : 
			 alertType=='warning-box' ? 'red-warning.svg' : 
			 null }}"
		   width="20px"
		   height="20px"
		   alt="alert-logo"
		   class="mr-20"
		 />
		 <div class="ml-10">
		   <h4 class="m-0 text-size-18 text-bold"
		   [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
			 {{alertType === "error-box"
			 ? "Error"
			 : alertType === "warning-box"
			 ? "Warning"
			 : alertType === "success-box"
			 ? "Success"
			 : null}}
		   </h4>
		   <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
			{{message}}
		   </small>
		 </div>
	   </div>
	   <img
		 src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
		 alt="close-btn"
		 (click)="hideError()"
		 className=" close-btn"
	   />
	 </div>
   </div>
   
  <ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

  <div class="ledger-container">
	<ng-container>
		<div class="row tab-nav pl-0 pr-0">
			<!-- <div class="col-sm-4 pl-0">
				<ul class="list-inline list-unstyled p-0 m-0">
					<li class="active page-title text-uppercase cursor"> View Client</li>
				</ul>
			</div> -->
			<div class="col-sm-12">
			  <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
				  <li  
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> Invoice</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li  
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> JV</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li  
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> View JV</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li class="text-grey text-capitalize cursor text-primary transition"> Edit JV</li>
			  </ul>
		  </div>
		</div>
		<!-- <div class="d-flex justify-content-between">
			<div  class="heading-box mt-4 mb-3" style="width: 600px;">
				<div class="heading">UPDATE JV</div>
				<span class="hr-line"></span>
			</div>
				<div class="action-box mt-4">
					<button type="button" class="btn-style" (click)="closeWindow()">Close</button>
				</div>
		</div> -->
		<form [formGroup]="updateJvForm" #form="ngForm">
			<div class="pl-15 p-15 pt-3 custom-form form-container w-100 mb-4">
				<div class="column">
					<div class="row">
						<div class="col-sm-2  text-field-group d-flex align-items-center  pb-3">
							<div class="text-input-field">
								<input formControlName="jv_date" tabIndex="0" class="input-field" type="date" 
									min="{{minDate}}" 
									max="{{maxDate}}"
									placeholder="MM/DD/YYYY" autocomplete="off"
									[ngClass]="{'validateError': !updateJvForm.controls.jv_date.valid}"/>
								<label>JV Date<span class="text-danger">*</span></label>
							</div>
						</div>

						<div class="col-sm-2 text-field-group d-flex align-items-center  pb-3" *ngIf="jvId">
							<div class="text-input-field">
								<input class="input-field" readonly type="text"  autocomplete="off" [value]="jvId" readonly />
								<label>JV Code</label>
							</div>
							
						</div>
	
						<ng-container *ngIf="!isTenantIndian"> 
							<div class="col-sm-2 text-field-group d-flex align-items-center  pb-3" >
								<div class="text-input-field">
									<select tabIndex="1" tabindex="1"  class="input-field w-20"
									 formControlName="currency_code" (change)="onJvCurrencyChange()" >
										<option value="">Select</option>
										<option value="AED">AED</option>
										<option value="CAD">CAD</option>
										<option value="USD">USD</option>
										<option value="INR">INR</option>
										<option value="EURO">EURO</option>
										<option value="POUND">POUND</option>
									</select>
									<label for="currency_code">Currency</label>
								</div>
							</div>
		
							<div class="col-sm-2 text-field-group d-flex align-items-center  pb-3">
								<div class="text-input-field">
									<input tabIndex="2" tabindex="2" class="input-field w-20" type="text" formControlName="currency_rate"/>
									<label>Rate <span *ngIf="acCurrencyCode && updateJvForm.value.currency_code">({{acCurrencyCode}}-{{updateJvForm.value.currency_code}})</span></label>
								</div>
							</div>
						</ng-container>
					</div>
				
					<div class="">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>S.No.</th>
									<th>Acc. Type</th>
									<th>Account</th>
									<th>Acc. Bal</th>
									<th>Sub Ledger Type</th>
									<th>Sub Ledger</th>
									<!-- <th>Date</th> -->
									<th class="text-right">Debit</th>
									<th class="text-right">Credit</th>
									<th class="text-center">Narration</th>
									<th class="text-center">Remarks</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody formArrayName="jvArray">
								<!-- *ngFor="let jvEntry of DynamicFormControls.controls; let i = index; " -->
								<tr *ngFor="let jvEntry of DynamicFormControls.controls; let i = index;" [formGroupName]="i">
									<td>{{i+1}}</td>
									<td>
										<select class="inner-select" tabIndex="{{i*8+(isTenantIndian ? 1 : 3)}}" 
											tabIndex="{{i*8+(isTenantIndian ? 1 : 3)}}" (change)="resetSelection('account_code',i)"
											formControlName="account_type" style="width:  55px;">
											<option value="">Select</option>
											<option value="client">Client</option>
											<option value="airline">Airline</option>
											<option value="supplier">Supplier</option>
											<option value="employee">Employee</option>
											<option value="general">General</option>
										</select>
									</td>
									<td>
										<div class="ng-autocomplete custom-ngc" tabIndex="{{i*8+(isTenantIndian ? 2 : 4)}}" 
											attr.tabIndex="{{i*8+(isTenantIndian ? 2 : 4)}}"
										(focus)="focusAccountAc($event,i)">
											<ng-autocomplete  #accountAc
											[data]="accountList[i]" 
											formControlName="account_code_name"
											[searchKeyword]="keyword"
											(selected)="selectAccount($event,'account_code',i)"
											(inputChanged)='searchAccount($event,i)' 
											[itemTemplate]="itemTemplate"
											[notFoundTemplate]="notFoundTemplate">
										</ng-autocomplete>
										<ng-template #itemTemplate let-item>
											<a
												innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'>
											</a>
										</ng-template>

										<ng-template #notFoundTemplate let-notFound>
											<div [innerHTML]="notFound"></div>
										</ng-template>
										</div>
									</td>
									<td>
										<input readonly class="inner-input-ui text-right" type="text" />
									</td>
									<td>
										<select class="inner-select" formControlName="sub_ledger_type"  style="width: 100%;" (change)="resetSelection('sub_ledger_code',i)"
										 	tabIndex="{{i*8+(isTenantIndian ? 3 : 5)}}" attr.tabIndex="{{i*8+(isTenantIndian ? 3 : 5)}}">
											<option value="">Select</option>
											<option value="client">Client</option>
											<option value="airline">Airline</option>
											<option value="supplier">Supplier</option>
											<option value="employee">Employee</option>
											<option value="general">General</option>
										</select>
									</td>
									<td>
										<div class="ng-autocomplete custom-ngc"  tabIndex="{{i*8+(isTenantIndian ? 4 : 6)}}" 
										attr.tabIndex="{{i*8+(isTenantIndian ? 4 : 6)}}" (focus)="focusSubLedgerAC($event,i)">
											<ng-autocomplete  #subLedgerAc 
											[data]="ledgerList[i]" 
											formControlName="sub_ledger_code_name"
											[searchKeyword]="keyword"
											(selected)="selectLedger($event,'sub_ledger_code',i)"
											(inputChanged)='searchLedger($event,i)' 
											[itemTemplate]="itemTemplate"
											[notFoundTemplate]="notFoundTemplate">
										</ng-autocomplete>
										<ng-template #itemTemplate let-item>
											<a
												innerHTML='<span class="colr-1">{{item.name}}({{item.account_code}})</span><br />'>
											</a>
										</ng-template>

										<ng-template #notFoundTemplate let-notFound>
											<div [innerHTML]="notFound"></div>
										</ng-template>
										</div>
									</td>
									<td> 
										<input tabIndex="{{i*8+(isTenantIndian ? 5 : 7)}}" 
										attr.tabIndex="{{i*8+(isTenantIndian ? 5 : 7)}}" class="inner-input-ui" oninput="this.value = this.value.toUpperCase()" class="inner-input-ui text-right" type="text" (input)="calculateDiff(i,'debit')" (focus)="$event.target.select()"
										formControlName="debit" pattern="^\d*(\.\d{0,8})?$"
										[ngClass]="{'error-field': (jvEntry.controls.debit.invalid && jvEntry.controls.debit.dirty),'success-field' : jvEntry.controls.debit.valid && jvEntry.controls.debit.dirty}"
										[ngClass]="{'validateError': updateJvForm.value.totalDebit  != updateJvForm.value.totalCredit}" />
									</td>
									<td>
										<input tabIndex="{{i*8+(isTenantIndian ? 6 : 8)}}" 
										attr.tabIndex="{{i*8+(isTenantIndian ? 6 : 8)}}"class="inner-input-ui" oninput="this.value = this.value.toUpperCase()" class="inner-input-ui text-right" type="text" (input)="calculateDiff(i,'credit')" (focus)="$event.target.select()"
										formControlName="credit" pattern="^\d*(\.\d{0,8})?$"
										[ngClass]="{'error-field': (jvEntry.controls.credit.invalid && jvEntry.controls.credit.dirty),'success-field' : jvEntry.controls.credit.valid && jvEntry.controls.credit.dirty}" 
										[ngClass]="{'validateError': updateJvForm.value.totalDebit  != updateJvForm.value.totalCredit}" />
									</td>
									<td>
										<input tabIndex="{{i*8+(isTenantIndian ? 7 : 9)}}" 
											attr.tabIndex="{{i*8+(isTenantIndian ? 7 : 9)}}" class="inner-input-ui" oninput="this.value = this.value.toUpperCase()" class="inner-input-ui text-right" type="text"  formControlName="narration_1" />
									</td>
									<td>
										<input tabIndex="{{i*8+(isTenantIndian ? 8 : 10)}}" 
										attr.tabIndex="{{i*8+(isTenantIndian ? 8 : 10)}}" class="inner-input-ui" oninput="this.value = this.value.toUpperCase()" class="inner-input-ui text-right" type="text" (keyup.enter)="movetoIndex(i)"  formControlName="remarks_1" />
									</td>
									<td class="edit-row text-center">
										<!-- <button title="Delete..." class="btn   save-as text-bold bg-whiteSmoke "
											type="button" (click)="removeJvRow(i)">
											<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/red-delete.svg" alt="" width="13px">
										</button> -->
										<button  title="Delete" class="custom-table-btn bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button"
										(click)="removeJvRow(i)" id="remove_jv_row_btn_{{i}}">
										<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-delete.svg" alt="" width="20px">
									</button>
										<button  title="Adjust Invoices" class="custom-table-btn bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button" (click)="adjustInvoices(i)"
										id="adjust_invoices_jv_btn_{{i}}"
										>
										<img src="{{commonService.aws_asset_url}}/_assets/icons/dark-mode/green-cirlcle-edit.svg" alt="" width="20px">
									</button>
									<button  title="view Adjusted Invoices" class="custom-table-btn bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button" (click)="viewAdjustedInvoices(i)"
										id="adjusted_invoices_jv_btn_{{i}}"
										>
										<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-delete.svg" alt="" width="20px">
									</button>
									
									</td>

								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="3"></td>
									<td class="text-black font-weight-normal text-right" style="padding : 10px">Difference</td>
									<td class="text-black font-weight-normal text-right"  style="padding : 10px"  [ngClass]="{'text-danger': updateJvForm.value.amountDiff  != 0,'text-success' : updateJvForm.value.amountDiff  == 0}">
										<span>{{updateJvForm.value.amountDiff | number : '1.2-2'}}</span>
									</td>
									<td class="text-black font-weight-normal text-right" style="padding : 10px">Total</td>
									<td class="text-black font-weight-normal text-right"  style="padding : 10px">
										{{updateJvForm.value.totalDebit | number : '1.2-2'}}
									</td>
									<td class="text-black font-weight-normal text-right"  style="padding : 10px">
										{{updateJvForm.value.totalCredit | number : '1.2-2'}}
								</td>
									<td colspan="3"></td>
								</tr>
							</tfoot>
						</table>
					</div>

					<div class="fare-diff-col d-flex justify-content-between align-items-center">
						
						<div class="text-field-group">
							<div class="text-input-field mt-0">
								<button type="button" class="btn-style text-white mr-3" (click)="addJvRow()" id="add_jv_row_btn"><i class="fa fa-plus fa fa-plus-square text-white"></i> Row</button>
							</div>
						</div>

						<div class="text-field-group d-flex">
							<span class="info alert-warning" >* To Save JV, Difference Should be zero (0).</span>
						</div>
					</div>
				

				
				</div>
			</div>
			<div class="btn-field bottom-btns-container full-width-btn-container d-flex align-item-center justify-content-end  ml-0 mr-0">  
				
       <!-- <button type="button" class="btn-style bg-green mr-3" tabIndex="20"
          >save</button> -->
		  <button type="button" attr.tabIndex="{{lastInputIndex+4}}" tabIndex="{{lastInputIndex+4}}" class="btn-style bg-green mr-3" (click)="reset()">Reset</button>
				<button type="button" class="btn-style bg-cyan mr-3"
				[disabled]="updateJvForm.value.amountDiff  != 0 || updateJvForm.invalid"  [ngClass]="{disbaledBtn: updateJvForm.value.amountDiff  != 0  || updateJvForm.invalid}"
				attr.tabIndex="{{lastInputIndex+1}}" tabIndex="{{lastInputIndex+1}}" (click)="openUpdateConfirmModal()" id="modify_jv_btn" *hasAccess="{'key' : 'F0002073'}">Modify</button>
				
				<button type="button" class="btn-style bg-red mr-3" (click)="openDeleteConfirmModal()" attr.tabIndex="{{lastInputIndex+2}}" tabIndex="{{lastInputIndex+2}}" id="delete_jv_btn" *hasAccess="{'key' : 'F0002074'}">Delete</button>
				<button type="button" class="btn-style bg-brown mr-3" (click)="openJvPrint(jvId)" attr.tabIndex="{{lastInputIndex+2}}" tabIndex="{{lastInputIndex+2}}" id="open_jv_btn" >Print</button>

				<button type="button" attr.tabIndex="{{lastInputIndex+3}}" tabIndex="{{lastInputIndex+3}}" (click)="openActivity()" class="btn-style bg-indigo mr-3" id="jv_activity_log_btn">Activity
				</button>
				<button type="button" attr.tabIndex="{{lastInputIndex+3}}" tabIndex="{{lastInputIndex+3}}"(click)="openSendEmailModal()" class="btn-style bg-blue-ivy" id="jv_email_btn">
					Email
				</button>
				
			</div>
		</form>

	</ng-container>

</div>

<button type="button" hidden  #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button >

	<div id="deleteConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
	  <div class="modal-dialog">
		  <!-- Modal content-->
		  <div class="modal-content">
			  <div class="modal-header">
				  <h4 class="modal-title">Confirmation</h4>
				  <button type="button" #closeDeleteModal class="close" data-dismiss="modal" (click)="resetCurrentModalType()">&times;</button>
			  </div>
			  <div class="modal-body">
				  <p>Are you sure to delete ‎this entry ?</p>
			  </div>
			  <div class="modal-footer">
				  <button type="button" class="btn-style" attr.tabIndex="{{lastInputIndex+6}}" tabIndex="{{lastInputIndex+6}}"
					  (click)="closeDeleteConfirmModal()" data-dismiss="modal">No</button>
					  <button type="button" class="btn-style bg-red" attr.tabIndex="{{lastInputIndex+5}}" tabIndex="{{lastInputIndex+5}}"  (click)="deleteJV(jvId)" data-dismiss="modal">Yes</button>
			  </div>
		  </div>
	  </div>
  </div>
  
  <button type="button" hidden   #confirmUpdateModal data-toggle="modal" data-target="#updateConfirmationModal"></button >
  
  <div id="updateConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
	  <div class="modal-dialog">
		  <!-- Modal content-->
		  <div class="modal-content">
			  <div class="modal-header">
				  <h4 class="modal-title">Confirmation</h4>
				  <button type="button" #closeUpdateModal class="close" data-dismiss="modal" (click)="resetCurrentModalType()">&times;</button>
			  </div>
			  <div class="modal-body">
				  <p>Are you sure to update ‎this entry ?</p>
			  </div>
			  <div class="modal-footer">
				  <button type="button" class="btn-style" attr.tabIndex="{{lastInputIndex+8}}" tabIndex="{{lastInputIndex+8}}"
					  (click)="closeUpdateConfirmModal()" data-dismiss="modal">No</button>
					  <button type="button" class="btn-style bg-green" attr.tabIndex="{{lastInputIndex+7}}" tabIndex="{{lastInputIndex+7}}" (click)="submit(updateJvForm.value)"
						 data-dismiss="modal">Yes</button>
			  </div>
		  </div>
	  </div>
  </div>

 
	<button
	hidden
	#openEmailModal
	data-target="#emailModalOfInvoice"
	data-toggle="modal"
  ></button>
	<div
	  class="modal"
	  id="emailModalOfInvoice"
	  role="dialog"
	  aria-labelledby="emailModalLabel"
	  data-backdrop="false"
	>
	  <div class="modal-dialog" role="document">
		<div class="modal-content">
		  <div class="modal-header">
			<div class="d-flex justify-content-center">
			  <h4 class="modal-title">Send Email</h4>
			</div>
			<button
			  type=" button"
			  class="close"
			  #closeSendEmailModal
			  data-dismiss="modal"
			  aria-label="Close"
			>
			  <span aria-hidden="true">&times;</span>
			</button>
		  </div>
		  <div class="modal-body emailSenders">
			JV
			<form [formGroup]="sendEmailForm" style="margin-top: -10px">
			  <div class="form-group text-input-field">
				<textarea
				  class="input-field ng-pristine ng-valid ng-touched text-size-12"
				  id="toEmail"
				  rows="1"
				  formControlName="toEmails"
				  required
				></textarea>
				<label for="To" class="mb-0">To</label>
			  </div>
			  <div class="d-flex justify-content-end">
				<div *ngIf="sendEmailForm.controls.toEmails.invalid">
				  <div
					*ngIf="sendEmailForm.controls.toEmails.errors?.required"
					style="color: red"
				  >
					To Email is required.
				  </div>
				</div>
			  </div>
  
			  <div class="form-group text-input-field ">
				<textarea
				  class="input-field ng-pristine ng-valid ng-touched text-size-12"
				  id="ccEmail"
				  style="max-height: 45px;"
				  formControlName="ccEmails"
				></textarea>
				<label for="Cc" class="mb-0">Cc</label>
			  </div>
  
			  <div class="form-group text-input-field">
				<textarea
				  class="input-field ng-pristine ng-valid ng-touched text-size-12"
				  id="bccEmail"
				  style="max-height: 45px;"
				  formControlName="bccEmails"
				></textarea>
				<label for="Bcc" class="mb-0">Bcc</label>
			  </div>
			  <div class="text-input-field">
				  <input formControlName="subject"  class="input-field text-size-12" type="text" 
				  autocomplete="off" />
				  <label>Subject</label>
			  </div>
			  <div class="form-group text-input-field">
			   <textarea
				 class="input-field ng-pristine ng-valid ng-touched text-size-12"
				 rows="5"
				 formControlName="body"
			   ></textarea>
			   <label for="body" class="mb-0">Mail Body</label>
			   <input type="file" (change)="handleAttachment($event)" />

			 </div>
			  <div class="mt-3 d-flex justify-content-center align-item-center">
				<button type="button" class="btn-style mr-2" data-dismiss="modal">
				  Cancel
				</button>
				<button
				  type="button"
				  class="btn-style bg-green"
				  (click)="callTosendEmail()"
				>
				  Send
				</button>
			  </div>
			</form>
		  </div>
		</div>
	  </div>
	</div>