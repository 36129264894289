import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TabService } from 'src/app/shared/services/tab.service';
import { InitialState } from "src/app/store/reducer";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-adjusted-invoice',
  templateUrl: './view-adjusted-invoice.component.html',
  styleUrls: ['./view-adjusted-invoice.component.scss']
})
export class ViewAdjustedInvoiceComponent implements OnInit {

  @Input() data;
	p = 0;
	q = 0;
	leaderData: any;
	navigationSubscription;
	reInitialise: boolean;
	currentDate = moment().format('YYYY-MM-DD');
	currentTime = moment().format('HH:mm:ss');
	innerHeight: string;
	ascSorttdsAmt: boolean = true;
	ascSortRef: boolean = true;
	totalAmountObj: any;
  itemToUpdate: any;
  itemToDelete: any;

	showMessage:boolean=false;
	alertType:String="";
	message:String=""
	clientData:any
	isAdvancedSearchOpen: boolean = false;
	onSearch;
	onSearchFilter = new Subject<string>();
	parentIndex=0;
	childIndex=0;
	@ViewChild('tableHeader', {static:true}) tableHeader : ElementRef
  @ViewChild('confirmModal',{static:true}) openConfirmationModal : ElementRef
  @ViewChild('closeConfirmModal',{static:true}) closeConfirmModal : ElementRef
  @ViewChild('closeEditReferenceReceipt') closeEditReferenceReceipt : ElementRef;

	isPrint: any;
	duration: any;
	allLedgerArr: any;
	ledgerArr: any[];
	shortcuts: ShortcutInput[] = [];
	referenceTypeName = {
		"invoice" : "INVOICE",
		"credit_note" : "Credit Note",
		"jv" : "Journal",
	}
	company_details: any;
	totalReceiptBalance: any;
	totalBalance: any
	transactionAmount = 0;
	receiptData: any;
	adjustedInvoices : any;
	adjustmentForm: FormGroup;
	confirmationModalType = ''
	deletedAmount = 0
	queryParams: any;
	totalAdjustedAmount = 0
	transactionData: any;
	referenceType: any;
	constructor(
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private fb:FormBuilder,
		private store: Store<InitialState>,
		private tabService: TabService

	) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});

		this.adjustmentForm = this.fb.group({
			reference: ["",Validators.required]
		})
	}
	
	async ngOnInit() {
		let queryParams = this.route.queryParams['value']
		
		let queryData = Object.assign({}, queryParams)
		this.queryParams = queryData
		this.spinner.show()

		if(queryData.reference_type == 'payment' ) {
			this.referenceType = 'payment'
		}else if(queryData.reference_type == 'receipt'){
			this.referenceType = 'receipt'
		}

		if(queryData) {
			this.getData(queryData)
		}

		this.onSearchFilter.pipe(
			debounceTime(300),
			distinctUntilChanged())
			.subscribe(value => {
			  this.filterData(value)
		});
		this.reInitialise = true
	}


	getReceiptDetails(queryData) {
		this.commonService
		  .getOkount(`receiptentryv3?receipt_code=${queryData['adjustment_reference']}`)
		  .subscribe(
			async (res: any) => {
			  this.getReferenceAmountData(queryData)
			  if (res[0] != undefined) {
				this.receiptData = res;
				for(let item of this.receiptData) {
					if(queryData.account_code==item.account_code_from){
					this.transactionAmount += Number(item.amount)
					}
				}
			  }
			},
			(err) => {}
		  );
	  }

	  getPaymentDetails(queryData) {
		this.commonService
		  .getOkount(`paymententryv3?payment_code=${queryData['adjustment_reference']}`)
		  .subscribe(
			async (res: any) => {
				if (res[0] != undefined) {
					this.transactionData = res;
					for(let item of this.transactionData) {
						if(queryData.account_code==item.account_code_to){
						this.transactionAmount += Number(item.amount)
						}
					}
				}
			},
			(err) => {}
		  );
	}
	
	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}
	filterData(value) {
		let tempArr = Object.assign([], this.allLedgerArr)
		if(value.length > 3){
			let lowerCaseFilter = value.toLowerCase()
			let upperCaseFilter = value.toUpperCase()
			this.ledgerArr = tempArr.filter(
				invoiceData => {
					if (	
							
							(
								invoiceData.narration.join('') != undefined && 
								invoiceData.narration.join('') != null && 
								(	
									invoiceData.narration.join('').toString().includes(value) || 
									invoiceData.narration.join('').toString().includes(upperCaseFilter) ||
									invoiceData.narration.join('').toString().includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.date != undefined && 
								invoiceData.date != null && 
								(	
									invoiceData.date.includes(value) || 
									invoiceData.date.includes(upperCaseFilter) ||
									invoiceData.date.includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.amount_debit != undefined && 
								invoiceData.amount_debit != null && 
								(	
									invoiceData.amount_debit.toString().includes(value) || 
									invoiceData.amount_debit.toString().includes(upperCaseFilter) ||
									invoiceData.amount_debit.toString().includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.amount_credit != undefined && 
								invoiceData.amount_credit != null && 
								(	
									invoiceData.amount_credit.toString().includes(value) || 
									invoiceData.amount_credit.toString().includes(upperCaseFilter) ||
									invoiceData.amount_credit.toString().includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.balance != undefined && 
								invoiceData.balance != null && 
								(	
									invoiceData.balance.toString().includes(value) || 
									invoiceData.balance.toString().includes(upperCaseFilter) ||
									invoiceData.balance.toString().includes(lowerCaseFilter)
								)
							) ||
							(
								invoiceData.reference != undefined && 
								invoiceData.reference != null && 
								(	
									invoiceData.reference.includes(value) || 
									invoiceData.reference.includes(upperCaseFilter) ||
									invoiceData.reference.includes(lowerCaseFilter)
								)
							) 
	
						) {
						return invoiceData
					}
				}
			)
		}else{
			this.ledgerArr = []
			for (let i = 0; i <= this.allLedgerArr.length; i++) {
				const element = this.allLedgerArr[i];
				if (element != undefined) {
					this.ledgerArr.push(element)
				}
			}
		}
	}
	

	ngOnDestroy() {
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.leaderData != undefined) {
			this.setHeight(this.p, this.q, event.target.innerHeight)
		}
	}

	getData(data) {
		this.commonService
		  .getOkount(`payment_adjustment/get-adjusted-invoices?reference=${data['adjustment_reference']}&account_code=${data['account_code']}&start_date=2022-04-01`)
		  .subscribe(
			async (res: any) => {
				if(this.referenceType == 'receipt') {
					this.getReceiptDetails(data)
				}else {
					this.getPaymentDetails(data)
				}
				this.leaderData = res
				this.totalBalance = res['all_clients_records'][0]['outstanding']
				this.duration = res['duration']
				this.company_details = res['company_details']
		
				this.allLedgerArr= res['all_clients_records'][0].client_records[0].records
				this.ledgerArr = []

				for (let i = 0; i <= this.allLedgerArr.length; i++) {
						const element = this.allLedgerArr[i];
						if (element != undefined) {		
							this.commonService.selectedInput.next(1)
							this.ledgerArr.push(element)
						}
					}
				
				this.focusOnTableHeader()
				if (this.leaderData != undefined) {
					this.setHeight(0, 0, window.innerHeight)
				}
        
      }, err => {
        console.error(err)
      })
      this.spinner.hide()
	}

	setHeight(pIndex, innerPIndex, height) {
		if (height == undefined) {
			height = window.innerHeight
		}
		pIndex = Number(pIndex)
		innerPIndex = Number(innerPIndex)
		this.p = pIndex
		this.q = innerPIndex

		let parent = 0
		if (pIndex > 0) {
			parent = pIndex - 1
		}
		let child = 0
		if (innerPIndex > 0) {
			child = innerPIndex - 1
		}
		if (this.leaderData['all_clients_records'].length > 0) {
			if (this.leaderData['all_clients_records'][parent].client_records.length > 0) {
				if (this.leaderData['all_clients_records'][parent].client_records[child].records.length <= 5) {
					this.innerHeight = 'auto';
				}
				else {
					this.innerHeight = height - 230 + 'px';
				}
			}
			else {
				this.innerHeight = height - 230 + 'px';
			}
		}
		else {
			this.innerHeight = height - 230 + 'px';
		}
	}

	tdsamtSort() {
		this.ascSorttdsAmt = !this.ascSorttdsAmt;
		var testData = this.leaderData;
		this.leaderData.data = testData.data.sort((a, b) => {
			if (this.ascSorttdsAmt) {
				if (b['balance'] < a['balance']) {
					return -1;
				}
				if (b['balance'] > a['balance']) {
					return 1;
				}
				return 0;
			} else {
				if (a['balance'] < b['balance']) {
					return -1;
				}
				if (a['balance'] > b['balance']) {
					return 1;
				}
				return 0;
			}
		});
	}

	refSort() {
		this.ascSortRef = !this.ascSortRef;
		let pIndex = Number(this.p)
		let parent = 0
		if (pIndex > 0) {
			parent = pIndex - 1
		}
		var testData = Object.assign([], this.leaderData['all_clients_records'][parent].client_records)
		for (let index = 0; index < testData.length; index++) {
			const element = testData[index];
			let sortedData = element.records.sort((a, b) => {
				if (this.ascSortRef) {
					if (b['reference'] < a['reference']) {
						return -1;
					}
					if (b['reference'] > a['reference']) {
						return 1;
					}
					return 0;
				} else {
					if (a['reference'] < b['reference']) {
						return -1;
					}
					if (a['reference'] > b['reference']) {
						return 1;
					}
					return 0;
				}
			});
			this.leaderData['all_clients_records'][parent].client_records[index].records = sortedData
		}
	}
	

	getClient(value) {
		if (value.length > 2) {
			this.commonService.getOkount(`client/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
					console.log("res:",res)
					this.clientData = res[0]
			}, err => {
				console.log(err)
			})
		}
	}


	focusOnTableHeader() :void{
		if (this.tableHeader != undefined) {
			this.tableHeader.nativeElement.focus()
		}
	}

	onKeyDown(e: any) {
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}

	resetCurrentModalType(){
		this.confirmationModalType = ''
	}
	
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`).focus()
			this.childIndex = this.childIndex+1
		}else{
			if (this.parentIndex < this.leaderData['all_clients_records'][0].client_records.length -1) {
				this.parentIndex = this.parentIndex+1
				this.childIndex = -1
			}
			if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex+1}`).focus()
				this.childIndex = this.childIndex+1
			}else if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`).focus()
				this.childIndex = this.childIndex+1
			}
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`).focus()
			this.childIndex = this.childIndex-1
		}else{
			if (this.parentIndex > 0) {
				this.parentIndex = this.parentIndex-1
				if (this.leaderData['all_clients_records'][0].client_records[this.parentIndex] != undefined) {
					this.childIndex = this.leaderData['all_clients_records'][0].client_records[this.parentIndex].records.length
				}
			}else{
				this.childIndex = 0
			}
			if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex-1}`).focus()
				if (this.childIndex >0) {
					this.childIndex = this.childIndex-1
				}
			}else if (document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`) != null) {
				document.getElementById(`rowIndex_${this.parentIndex}_${this.childIndex}`).focus()
				if (this.childIndex >0) {
					this.childIndex = this.childIndex-1
				}
			}
		}
	}
	
	moveToIndex(parentIndex,childIndex){
		document.getElementById(`rowIndex_${parentIndex}_${childIndex}`).focus()
		this.parentIndex = parentIndex
		this.childIndex = childIndex
	}


  openConfirModal(confirmationModalType, item ){
    this.confirmationModalType = confirmationModalType
    if(this.confirmationModalType == 'delete') {
      this.itemToDelete = item
    }

    this.openConfirmationModal.nativeElement.click()
  }

  onCancel() {
    this.closeEditReferenceReceipt.nativeElement.click()
  }

  closeConfirmationModal () {
	this.closeConfirmModal.nativeElement.click()
  }

	hideError(){
		this.showMessage = false
	}

  deleteAdjustment() {
    let item = this.itemToDelete
	this.closeConfirmModal.nativeElement.click()
		this.commonService
		.deleteRequest(`${environment.okountUrl}/payment_adjustment/delete-adjustment?reference_id=${item['adjustment_id']}&account_code=${this.queryParams['account_code']}&reference=${this.queryParams['receipt_reference']}`)
		.subscribe(
			async (res: any) => {
				this.ngOnInit()
				this.showMessage = true;
				this.alertType = 'success-box'
				this.message = res['message']
			},
			(err) => {
				console.log("error", err)
			}
		  );
	}

	getReferenceAmountData(queryData) {
		this.commonService
		  .getOkount(`payment_adjustment/get-reference-data?account_code=${queryData['account_code']}&reference=${queryData['adjustment_reference']}`)
		  .subscribe(
			async (res: any) => {
				if (res && res['data'] && res['data']['adjustment_amount']) {
					let adjustment_amount = Number(res['data']['adjustment_amount'])
					this.deletedAmount = Number(this.transactionAmount) - Number(adjustment_amount)
				}
			  },
			  (err) => {}
		  );
	  }
}