import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmployeeRoutingModule } from './employee-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { EmployeeComponent } from './employee.component';
import { UpdateEmployeeComponent } from './update-employee/update-employee.component';
import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { ViewEmployeeComponent } from './view-employee/view-employee.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CommonServiceService } from 'src/app/shared/common-service.service';

@NgModule({
  declarations: [ EmployeeComponent , UpdateEmployeeComponent, CreateEmployeeComponent, ViewEmployeeComponent],
  imports: [
    CommonModule,
    SharedModule,
    EmployeeRoutingModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [UpdateEmployeeComponent]
})
export class EmployeeModule { }
