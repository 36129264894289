<form [formGroup]="ticketSearchForm">
  <div class="flexbox aff-info">
    <div class="flexbox aff-info-inner">
      <div class="flexbox ">
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="1" formControlName="start_date" id="from_date" type="date"
            min="{{minDate}}" max="{{maxDate}}"
            [ngClass]="{'error-box': !ticketSearchForm.controls.start_date.valid}"
              />
          <label for="from_date">Starting Date</label>
        </div>
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="2" formControlName="end_date" id="to_date" type="date"
              min="{{minDate}}" max="{{maxDate}}"
              [ngClass]="{'error-box': !ticketSearchForm.controls.end_date.valid}"
              />
          <label for="to_date">Ending Date</label>
        </div>
      </div>

      <div class="flexBox d-flex mt-20 ml-2 mr-2">
        <div class="input-fiel ">
          <label class="radio-label d-flex align-items-center" for="invoice">
          <input searchtabindex="3" formControlName="reference_type" name="reference_type" value="invoice" id="invoice" type="radio" />
         <span class="ml-2">Invoice</span></label> 
        </div>
        <div class="input-fiel ">
          <label class="radio-label d-flex align-items-center" for="credit_note">
          <input searchtabindex="4" formControlName="reference_type" name="reference_type" value="credit_note" id="credit_note" type="radio" />
         <span class="ml-2">Credit Note</span></label>
        </div>
        <div class="input-fiel ">
          <label class="radio-label d-flex align-items-center" for="both">
          <input searchtabindex="5" formControlName="reference_type" name="reference_type" value="both" id="both" type="radio" />
         <span class="ml-2">Both</span></label>
        </div>
      </div>

        <div class="flexBox d-flex mt-20 ml-2 mr-2">
          <div class="input-fiel mr-2" searchtabindex="6" (focus)="focusClientAc()" >
            <div class="ng-autocomplete">
              <label class="ngauto-label" for="supplier">Supplier </label>
              <ng-autocomplete #clientAC [data]="supplierArr" [searchKeyword]="keyword"
                (inputChanged)='filterSuppliers($event)' historyIdentifier="supplierArr" [itemTemplate]="itemTemplate"
                formControlName="selectedSupplierName" (selected)="selectAccount($event)"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a innerHTML='<span class="colr-1">{{item.name}}
                                    ({{item.account_code}})</span><br />
                                <span class="colr-2">{{item.primary_email}}</span><br />'>
                </a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>

            <div class="input-fiel">
              <label class="radio-label" for="IsCard">Card</label>
              <input class="radio-ui" searchtabindex="7"  id="IsCard" type="checkbox" name="is_card" formControlName="is_card" value="is_card" />
            </div>
      </div>

      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
            searchtabindex="8" #searchButton (keyup.enter)="clickSearchButton()"
            [ngClass]="{disbaledBtn: ticketSearchForm.invalid}"
            [disabled]="ticketSearchForm.invalid"
            (click)="search(ticketSearchForm.value)">
            Search</button>
          <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
        </div>
      </div>
    </div>
  </div>
</form>
