import { Component, Input, OnInit } from '@angular/core';
import { CookiesService } from 'src/app/shared/cookies.service';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from 'src/app/shared/common-service.service';

@Component({
  selector: 'app-misc-invoice-fare-summary',
  templateUrl: './misc-invoice-fare-summary.component.html',
  styleUrls: ['./misc-invoice-fare-summary.component.scss']
})
export class MiscInvoiceFareSummaryComponent implements OnInit {
	discount_add: string = "false"
	vatAlternetname: string = 'VAT'
  @Input() miscInvoiceService;
  isTenantIndian: boolean = false;

  constructor(private cookieService : CookiesService,
	public commonService: CommonServiceService
	) { }

  async ngOnInit() {
	let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
		
		let inv_config = globalSettingConfig.filter(el=>el.group=='TRANSACTION')
		if(inv_config.length){
			let discount_config = inv_config[0].json_doc.value.filter(el=>el.key=='DISCOUNT')
			if(discount_config.length){
				this.discount_add = discount_config[0].value
			}
		}
	this.isTenantIndian = this.commonService.checkTenantIndian()

	if(!this.isTenantIndian){
		let inv_config = globalSettingConfig.filter(el=>el.group=='VAT')
	if(inv_config.length){
		let vat_config = inv_config[0].json_doc.value.filter(el=>el.key=='vat_rate')
		let vat_detail,vat_name
		if(vat_config.length){
			 vat_detail = vat_config[0].value
		}
		if(vat_detail && vat_detail.length ){
			vat_name = vat_detail.filter(el=>el.key=='effective_name')
		}
		if(vat_name && vat_name.length){
			this.vatAlternetname = vat_name[0].value
		}
	}
	}
  }


  viewPrintScreen(invoice,i){
		if (invoice != 'Credit Note' && invoice != 'New Invoice') {
			// if (i==0) {
			// 	window.open(`/#/transactions/invoice-view;searchId=${invoice};type=flight`, "_blank");
			// }else{
			// 	window.open(`/#/transactions/print-credit-note/${invoice};type=flight`, "_blank");
			// }
			let path = ''
      path = `#/transactions/misc-invoice/print;searchId=${invoice};type=flight`
		
			path = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${path}`
			window.open(path,'_blank')

		}
	}

}
