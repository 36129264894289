<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
<div class="ledger-container"  [ngClass]="{ 'ledger-print-section': isPrint == 'true' }">
 
  <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
    <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
      <div class="d-flex flex-y-center">
        <img
          src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
            alertType=='success-box' ? 'green-sucess.svg' : 
            alertType=='warning-box' ? 'red-warning.svg' : 
            null }}"
          width="20px"
          height="20px"
          alt="alert-logo"
          class="mr-20"
        />
        <div class="ml-10">
          <h4 class="m-0 text-size-18 text-bold"
          [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
            {{alertType === "error-box"
            ? "Error"
            : alertType === "warning-box"
            ? "Warning"
            : alertType === "success-box"
            ? "Success"
            : null}}
          </h4>
          <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
           {{message}}
          </small>
        </div>
      </div>
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
        alt="close-btn"
        (click)="hideError()"
        className=" close-btn"
      />
    </div>
  </div>

  <div *ngIf="leaderData != undefined">
    <div class="" id="print-section">
      <div>
         <div style="background: #fff!Important;"  class="row leader-header tab-nav pl-0 pr-0">
          <div class="col-sm-4 pr-0">
            <div *ngIf="isPrint">
             <span class="text-bold text-black text-uppercase" style="font-size: 15px;">{{leaderData?.company_details?.name}}</span><br>
             <p class="address_line mb-0 text-grey text-uppercase" style="font-size: 13px;"><span style="font-size: 13px;"  class="d-block">{{leaderData?.company_details?.address_1}}</span>
               <span style="font-size: 13px;">{{leaderData?.company_details?.address_2}}</span>  <span class="ml-2" style="font-size: 13px;">{{leaderData?.company_details?.city_name}}</span> <span style="font-size: 13px;" class="ml-2">{{leaderData?.company_details?.address_3}}</span></p>
            </div>
            <ul
              class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0"
            >
              <li
                [routerLink]="['/']"
                class="text-grey text-capitalize cursor hover-text-primary transition"
                [ngClass]="{ 'd-none': isPrint == 'true' }"
              >
                Analysis
              </li>
              <li>
                <img
                  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg"
                  alt=""
                  [ngClass]="{ 'd-none': isPrint == 'true' }"
                />
              </li>
              <li
                class="text-grey text-capitalize cursor text-primary transition"
                [ngClass]="{ 'd-none': isPrint == 'false' }"
                [ngClass]="{ 'd-none': isPrint == 'true' }"
              >
                Unpaid Report:-
              </li>
              <li class="text-primary ">
                <ng-container
                  *ngFor="let data of leaderData['all_clients_records']"
                >
                  <span [ngClass]="{ 'font-size-15': isPrint == 'true' }" class="text-bold line-clamp line-clamp-1">
                    {{ data["display_name"] }}
                  </span>
                </ng-container>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 text-center">
            <h4 style="font-size: 17px;" *ngIf="isPrint" class="text-uppercase text-bold text-black mt-2">
              Unpaid Report<br>
            </h4>
            <span class="rec-date d-block text-center">
              <div class="noWrap justify-content-around">
                <ng-container>
                  <div class="cname text-bold">
                    <span  [ngClass]="{ 'font-size-15': isPrint == 'true' }"  *ngIf="duration.from_date" 
                      >From</span
                    >
                    <h5 style="margin-right: 5px;" class="mb-0 d-inline">
                      <span [ngClass]="{ 'font-size-15': isPrint == 'true' }" 
                        class="date text-bold text-orange"
                        *ngIf="duration.from_date"
                      >
                        {{ duration.from_date }}
                      </span>
                      <span [ngClass]="{ 'font-size-15': isPrint == 'true' }"  class="date text-bold" *ngIf="duration.from_date">
                        To
                      </span>
                      <span [ngClass]="{ 'font-size-15': isPrint == 'true' }"  class="date text-bold text-orange">
                        {{ duration.to_date }}
                      </span>
                    </h5>
                    <button
                      [ngClass]="{ 'd-none': isPrint == 'true' }"
                      title="Custom Search"
                      class="btn save-as text-bold bg-whiteSmoke"
                      type="button"
                      (click)="openAdvanceSearchModal()"
                    >
                      <img
                        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg"
                        alt=""
                        width="15px"
                      />
                    </button>
                  </div>
                </ng-container>
              </div>
            </span>
          </div>
          <div class="col-sm-4 d-flex align-item-center justify-content-end">
            <div class="text-right mt-2" *ngIf="isPrint">
              <span class="d-block text-black" [ngClass]="{ 'font-size-12': isPrint == 'true' }">{{currentDate | date : 'dd/MM/yyyy'}}</span>
              <span class="d-block text-grey"  [ngClass]="{ 'font-size-12': isPrint == 'true' }"> {{currentTime}}</span>
            </div>
			<div
			class="page-search-box input-group d-flex align-item-center" 
      [ngClass]="{ 'd-none': isPrint == 'true' }"
			style="height: fit-content; text-align: left"
		  >
			<input
			  type="text"
			  class="form-control"
			  [(ngModel)]="onSearch"
			  oninput="this.value = this.value.toUpperCase()"
			  (ngModelChange)="this.onSearchFilter.next($event)"
			  placeholder="search"
			  style="text-align: left"
			/>
		  </div>
            <div class="dropdown-btn position-relative" 
            [ngClass]="{ 'd-none': isPrint == 'true' }">
              <button title="More..." class="btn-style mt-0 more-btn">
                <span class="dot"></span><span class="dot"></span
                ><span class="dot"></span>
              </button>
              <ul class="list-unstyled pl-0 dropdown-box">
                <li  (click)="openModal()">
                  <img
                    src="../.{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-emal.svg"
                    alt=""
                  />
				  <span class="ml-2">Email</span>

				  <button
				  hidden
				  #openEmailModal
				  data-target="#emailModalInNormalLedger"
				  data-toggle="modal"
				></button>
                </li>
                <li   (click)="
                downloadPdf(
                leaderData?.duration?.from_date,
                leaderData?.duration?.to_date
              )
			  " >
                  <img
                    src="../.{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg"
                    alt=""
                  />
				  <span class="ml-2">Save As Pdf</span>
                </li>
                <li     (click)="
                exportAsXLSX(
                  leaderData?.duration?.from_date,
                  leaderData?.duration?.to_date
                )
              " >
                         
                  <span class="ml-2">EXCEL</span>
                        </li>

                        <li     (click)="
                        downloadInv()
                      " >
                                 
                          <span class="ml-2">Download Invoices</span>
                                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div  class="ledger-table"
        [ngClass]="{ 'table-print-section': isPrint == 'true' }"
      >
        <table class="table table striped" >
          <thead #tableHeader tabIndex="0">
            <tr *ngIf="isPrint" style="border-bottom: none!important;">
              <th colspan="7" class="page-top-space">
              </th>
            </tr>
            <tr class="transition">
              <th class="text-left date" style="max-width: 105px">Date</th>

              <th style="max-width: 175px " class="text-left doc-no text-black font-500">
                <span class="text-bold"
                  >Doc No
                  <i
                    *ngIf="ascSortRef"
                    (click)="refSort()"
                    class="fas fa-sort-alpha-up"
                  ></i>
                  <i
                    *ngIf="!ascSortRef"
                    (click)="refSort()"
                    class="fas fa-sort-alpha-down-alt"
                  ></i
                ></span>
              </th>
              <th class="text-left particular">Account Description</th>
              <th class="text-right voucher"><span class="text-center d-block text-bold"> Voucher</span></th>
              <th class="text-right debit" style="min-width: 180px; max-width: 190px">
                <span class="text-right d-block text-bold">  Debit</span>
              </th>
              <th class="text-right credit" style="min-width: 180px; max-width: 190px">
                <span class="text-right d-block text-bold">  Credit</span>
              </th>
              <th class="text-right balance" style="min-width: 180px; max-width: 190px">
                <span class="text-right d-block text-bold"> Remaining </span>
              </th>
              <th class="text-right balance" style="max-width: 155px">
               <span class="text-right d-block text-bold" > Balance</span>
              </th>
            </tr>
          </thead>
          <tbody
            class="scroll-body main-panel"
            [ngStyle]="{ height: innerHeight }"
            (window:keydown)="onKeyDown($event)"
          >
          <tr class="opening-bal" *ngIf="isPrint">
            <td class="text-left text-grey date" style="max-width: 105px;">{{ duration.from_date | date: "dd/MM/yyyy"}}</td>
            <td class="text-left text-grey doc-no-particular" style="max-width: 175px;"><p class="text-bold text-black undl mb-0">OPENING BALANCE</p></td>
            <td class="text-left text-grey" ></td>
            <td class="text-right text-grey voucher" ></td>
            <td class="text-right text-red debit"  style="min-width: 180px; max-width: 190px;">
              <span 
              class="bal-amount debit-amt text-bold"
              *ngIf="
                totalAmountObj && totalAmountObj.openingBalanceType == 'debit';
                else debitSide
              "
            >
            
              {{
                totalAmountObj.openingBalance &&
                totalAmountObj.openingBalance < 0
                  ? (totalAmountObj.openingBalance * -1 | number: "1.2-2")
                  : (totalAmountObj.openingBalance | number: "1.2-2")
              }}
            </span>
            </td>
            <td class="text-right text-green credit"  style="min-width: 180px; max-width: 190px;">
              <span
              class="bal-amount text-green credit-amt text-bold"
              *ngIf="
                totalAmountObj && totalAmountObj.openingBalanceType == 'credit';
                else creditSide
              "
            >
            
              {{
                totalAmountObj.openingBalance &&
                totalAmountObj.openingBalance < 0
                  ? (totalAmountObj.openingBalance * -1 | number: "1.2-2")
                  : (totalAmountObj.openingBalance | number: "1.2-2")
              }}
            </span>
            </td>
            <td class="text-right text-grey balance" style="min-width: 135px;max-width: 150px;" ></td>
          </tr>
            <ng-container
              *ngFor="let data of leaderData['all_clients_records']"
            >
              <ng-container
                *ngFor="
                  let client_record of data['client_records'];
                  let i = index
                "
              >
              <span *ngIf="client_record['display_name']" class="client-name text-decoration">{{client_record['display_name']}}
              </span>
              <ng-container *ngIf="client_record?.records; else nonGrouped">
                <ng-container *ngIf="client_record['aggregated_name'] != 'Opening Balance'">
                <span class="client-name" *ngIf="client_record['records'].length>1"
                  >{{ client_record["aggregated_name"] }}
                </span>
                <tr class="table-row-break"
                  *ngFor="let record of client_record['records']; let j = index"
                  [attr.tabIndex]="i + j"
                  id="rowIndex_{{ i }}_{{ j }}"
                  (keyup.enter)="
                    openViewMode(record.reference, record.reference_type)
                  "
                  [ngClass]="{
                    'd-none': record.reference_type == 'opening_balance',
                    borderOrange: record?.amount_debit > 0,
                    borderGreen: record?.amount_credit > 0,
                    'error_table_row': record.isAdjustBefore
                  }"
            [attr.title]="record.isAdjustBefore ? 'Adjusted Before The Selected Date Range' : ''"
                >

                  <td
                  (click)="moveToIndex(i, j)"
                  style="max-width: 105px"
                  class="text-left text-grey date"
                >
                  {{ record?.date | date: "dd/MM/yyyy" }}
                  <ng-container *ngIf="j == ledgerArr.length - 1">
                    {{dataLoadedFn(j)}}
                  </ng-container>
                </td>
                <td
                  style="max-width: 175px "
                  (click)="moveToIndex(i, j)"
                  class="text-left transition text-bold cursor doc-no refer"
                >
                  <a
                    [ngClass]="{
                      ' underline-none':
                        record.reference != null &&
                        record.reference != undefined
                    }"
                    (click)="
                      openViewMode(record.reference, record.reference_type)
                    "
                    >{{ record?.reference }}</a
                  >
                  <a class="d-block" *ngIf="record?.x_field_1">{{record?.x_field_1}}</a>
                </td>
                <td (click)="moveToIndex(i, j)" class="text-left text-grey particular">
                  <ng-container *ngFor="let narration of record.narration">
                    <ng-container *ngIf="narration.trim() != '' &&  narration.trim() != '|'">
                      <span class="text-grey d-block">{{ narration }} </span>
                    </ng-container>
                  </ng-container>
                </td>
                <td  class="text-right text-grey voucher">
                  <span class="text-center d-block">
                    {{ referenceTypeName[record?.reference_type] ? referenceTypeName[record?.reference_type] :  record?.reference_type }}
                  </span>
                </td>
                <td
                  (click)="moveToIndex(i, j)"
                  class="text-right text-red debit"
                  style="min-width: 180px; max-width: 190px"
                >
                  {{
                    record.amount_debit && record.amount_debit < 0
                      ? record.amount_debit * -1 
                      : record.amount_debit | number :'1.2-2'
                  }}
                </td>
                <td
                  (click)="moveToIndex(i, j)"
                  class="text-right text-green credit"
                  style="min-width: 180px; max-width: 190px"
                >
                  {{
                    record.amount_credit && record.amount_credit < 0
                      ? record.amount_credit * -1
                      : record.amount_credit | number :'1.2-2'
                  }}
                </td>

                <td
                (click)="moveToIndex(i, j)"
                class="text-right text-purple balance"
                style="min-width: 180px; max-width: 190px"
              >
                {{
                  record.transaction_remaining_amount | number :'1.2-2'
                }}
              </td>
                <td
                  (click)="moveToIndex(i, j)"
                  class="text-right text-blue balance"
                  style="max-width: 150px"
                >
                  {{ record?.balance | number :'1.2-2'}}
                </td>
                </tr>
              </ng-container>
            
            
          </ng-container>

          <ng-template #nonGrouped>
            
            <tr class="table-row-break"
            [attr.tabIndex]="i"
            id="rowIndex_{{ i }}"
            (keyup.enter)="
            openViewMode(client_record.reference, client_record.reference_type)
            "
            [ngClass]="{
              'd-none': client_record.reference_type == 'opening_balance',
              borderOrange: client_record?.amount_debit > 0,
              borderGreen: client_record?.amount_credit > 0,
              'error_table_row': client_record.isAdjustBefore
            }"
            [attr.title]="client_record.isAdjustBefore ? 'Adjusted Before The Selected Date Range' : ''"
            >

              <td
              (click)="moveToIndex(i, 0)"
              style="max-width: 105px"
              class="text-left text-grey date"
            >
              {{ client_record?.date | date: "dd/MM/yyyy" }}
              <ng-container *ngIf=" 0== ledgerArr.length - 1">
                {{dataLoadedFn(0)}}
              </ng-container>
            </td>
            <td
              style="max-width: 175px "
              (click)="moveToIndex(i, 0)"
              class="text-left transition text-bold cursor doc-no refer"
            >
              <a
                [ngClass]="{
                  ' underline-none':
                  client_record.reference != null &&
                  client_record.reference != undefined
                }"
                (click)="
                  openViewMode(client_record.reference, client_record.reference_type)
                "
                >{{ client_record?.reference }}</a
              >
              <a class="d-block" *ngIf="client_record?.x_field_1">{{client_record?.x_field_1}}</a>
            </td>
            <td (click)="moveToIndex(i, 0)" class="text-left text-grey particular">
              <ng-container *ngFor="let narration of client_record.narration">
                <ng-container *ngIf="narration.trim() != '' &&  narration.trim() != '|'">
                  <span class="text-grey d-block">{{ narration }} </span>
                </ng-container>
              </ng-container>
            </td>
            <td  class="text-right text-grey voucher">
              <span class="text-center d-block">
                {{ referenceTypeName[client_record?.reference_type] ? referenceTypeName[client_record?.reference_type] :  client_record?.reference_type }}
              </span>
            </td>
            <td
              (click)="moveToIndex(i, 0)"
              class="text-right text-red debit"
              style="min-width: 180px; max-width: 190px"
            >
              {{
                client_record.amount_debit && client_record.amount_debit < 0
                  ? client_record.amount_debit * -1 
                  : client_record.amount_debit | number :'1.2-2'
              }}
            </td>
            <td  
              (click)="moveToIndex(i, 0)"
              class="text-right text-green credit"
              style="min-width: 180px; max-width: 190px"
            >
              {{
                client_record.amount_credit && client_record.amount_credit < 0
                  ? client_record.amount_credit * -1
                  : client_record.amount_credit | number :'1.2-2'
              }}
            </td>

            <td
            (click)="moveToIndex(i, 0)"
            class="text-right text-purple balance"
            style="min-width: 180px; max-width: 190px"
          >
            {{
              client_record.transaction_remaining_amount | number :'1.2-2'
            }}
          </td>
            <td
              (click)="moveToIndex(i, 0)"
              class="text-right text-blue balance"
              style="max-width: 150px"
            >
              {{ client_record?.balance | number :'1.2-2'}}
            </td>
            </tr>
          </ng-template>
          
          </ng-container>
            </ng-container>
            <tr class="" *ngIf="isPrint">
              <td class="text-left text-grey date" style="max-width: 105px;"></td>
              <td class="text-left text-grey doc-no-particular" style="max-width: 175px;"><p class="text-bold text-black mt-2 mb-0">SUBTOTAL </p></td>
              <td class="text-left text-grey" ></td>
              <td class="text-left text-grey " ></td>
              <td class="text-right ledger-subtotal text-red debit" style="min-width: 180px; max-width: 190px;">
                <span class="bal-amount debit-amt text-bold">
                  <ng-container *ngIf="totalAmountObj?.openingBalanceType == 'debit';else debitSide">
                    {{totalAmountObj?.openingBalance | number: "1.2-2" }}
                  </ng-container>
                  <ng-template #debitSide>
                    {{ totalAmountObj?.openingBalance | number: "1.2-2" }}
                  </ng-template>
                </span>
              </td>
              <td class="text-right ledger-subtotal text-green credit"  style="min-width: 180px; max-width: 190px;">
                <span class="bal-amount credit-amt text-bold">
                  <ng-container *ngIf="totalAmountObj?.openingBalanceType == 'credit';else creditSide">
                    {{totalAmountObj?.openingBalance | number: "1.2-2" }}
                  </ng-container>
                  <ng-template #creditSide>
                    {{ totalAmountObj?.openingBalance | number: "1.2-2" }}
                  </ng-template>
                </span>
              </td>
              <td class="text-right text-grey balance" style="min-width: 135px;max-width: 150px;" ></td>
            </tr>
            <tr class="closing-bal" *ngIf="isPrint">
              <td class="text-left text-grey date" style="max-width: 105px;">{{ duration.to_date | date: "dd/MM/yyyy"}}</td>
              <td class="text-left text-grey doc-no-particular" style="max-width: 175px;"><p class="text-bold text-black undl mb-0">CLOSING BALANCE</p></td>
              <td class="text-left text-grey" ></td>
              <td class="text-left text-grey " ></td>
              <td class="text-right text-red debit" style="min-width: 180px; max-width: 190px;">
                <span
                class="bal-amount debit-amt text-bold"
                *ngIf="
                  totalAmountObj && totalAmountObj.type == 'DR.';
                  else debitSide
                "
              >
              
                {{
                  totalAmountObj.balance && totalAmountObj.balance < 0
                    ? (totalAmountObj.balance * -1 | number: '1.2-2')
                    : (totalAmountObj.balance | number: '1.2-2')
                }}
              </span>
              </td>
              <td class="text-right text-green credit"  style="min-width: 180px; max-width: 190px;">
                <span
                class="bal-amount text-green credit-amt text-bold"
                *ngIf="
                  totalAmountObj && totalAmountObj.type == 'CR.';
                  else creditSide
                "
              >
              
                {{
                  totalAmountObj.balance && totalAmountObj.balance < 0
                    ? (totalAmountObj?.balance * -1 | number: '1.2-2')
                    : (totalAmountObj?.balance | number: '1.2-2')
                }}
                <!-- ₹
                      {{totalAmountObj?.balance | number : '1.2-2' }} -->
              </span>
              </td>
              <td class="text-right text-grey balance" style="min-width: 135px;max-width: 150px;" ></td>
            </tr>
            <tr *ngIf="isPrint">
              <td class="text-left text-grey date" style="max-width: 105px;"></td>
              <td colspan="3" class="text-left text-grey doc-no-particular" style="width: auto!important;
              max-width: unset!important;" >
                <p class="text-bold text-black mb-0" *ngIf="
                totalAmountObj && totalAmountObj.type == 'DR.';
              ">Amount Receivable: <span class="ml-2 text-bold" *ngIf="totalAmountObj.diff > 0" >{{totalAmountObj.diff | number :'1.2-2'}}</span></p>
              <p class="text-bold text-black mb-0" *ngIf="
              totalAmountObj && totalAmountObj.type == 'CR.';
            ">Amount Payable: <span class="ml-2 text-bold" *ngIf="totalAmountObj.diff > 0" >{{totalAmountObj.diff | number :'1.2-2' }}</span></p>
              </td>
              <td class="text-left text-grey " ></td>
              <td class="text-right text-red debit" style="min-width: 180px; max-width: 190px;">
               
              </td>
              <td class="text-right text-green credit"  style="min-width: 180px; max-width: 190px;">
            
              </td>
              <td class="text-right text-grey balance" style="min-width: 135px;max-width: 150px;" ></td>
            </tr>
           
            <tr  *ngIf="isPrint" style="    border-bottom: none!important;">
              <td colspan="7" class="text-right text-grey" >
                <h5 class="mb-0 text-bold" style="font-size: 16px;">For {{leaderData?.company_details?.name}}</h5>
              </td>
            </tr>
            <tr *ngIf="leaderData && leaderData.length == 0">
              <div class="w-100 text-center" style="padding: 20px">
                <span
                  ><img
                    class="not-found-img"
                    src="{{commonService.aws_asset_url}}/_assets/images/problem.png"
                /></span>
                <br />
                <span class="not-found-text">No result found</span>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="balance-box" *ngIf="!isPrint">
        <div></div>
        <div class="balance-container">
          <div class="bal-item text-bold">
            <span>Opening Balance</span>
            <span
              class="bal-amount debit-amt text-bold"
              *ngIf="
                totalAmountObj && totalAmountObj.openingBalanceType == 'debit';
                else debitSide
              "
            >
            
              {{
                totalAmountObj.openingBalance &&
                totalAmountObj.openingBalance < 0
                  ? (totalAmountObj.openingBalance * -1 | number: "1.2-2")
                  : (totalAmountObj.openingBalance | number: "1.2-2")
              }}
            </span>
            <span
              class="bal-amount text-green credit-amt text-bold"
              *ngIf="
                totalAmountObj && totalAmountObj.openingBalanceType == 'credit';
                else creditSide
              "
            >
            
              {{
                totalAmountObj.openingBalance &&
                totalAmountObj.openingBalance < 0
                  ? (totalAmountObj.openingBalance * -1 | number: "1.2-2")
                  : (totalAmountObj.openingBalance | number: "1.2-2")
              }}
            </span>
          </div>
          <div class="bal-item text-bold">
            <span>Current Balance</span>
            <span class="bal-amount debit-amt text-bold">
              {{ totalAmountObj?.debit | number: "1.2-2" }}
            </span>
            <span class="bal-amount text-green credit-amt text-bold">
               {{ totalAmountObj?.credit | number: "1.2-2" }}
            </span>
          </div>
          <div class="bal-item text-bold">
            <span>Closing Balance</span>
            <span
              class="bal-amount debit-amt text-bold"
              *ngIf="
                totalAmountObj && totalAmountObj.type == 'DR.';
                else debitSide
              "
            >
            
              {{
                totalAmountObj.balance && totalAmountObj.balance < 0
                  ? (totalAmountObj.balance * -1 | number: "1.2-2") 
                  : (totalAmountObj.balance | number: "1.2-2")
              }}
              <!-- ₹
										{{totalAmountObj?.balance | number : '1.2-2' }} -->
            </span>
            <span
              class="bal-amount text-green credit-amt text-bold"
              *ngIf="
                totalAmountObj && totalAmountObj.type == 'CR.';
                else creditSide
              "
            >
            
              {{
                totalAmountObj.balance && totalAmountObj.balance < 0
                  ? (totalAmountObj?.balance * -1 | number: "1.2-2")
                  : (totalAmountObj?.balance | number: "1.2-2")
              }}
              <!-- ₹
										{{totalAmountObj?.balance | number : '1.2-2' }} -->
            </span>
          </div>

         
        </div>
      </div>
    </div>
  </div>
  <!-- Email Modal for sending mail -->
  <div
    class="modal"
    id="emailModalInNormalLedger"
    role="dialog"
    aria-labelledby="emailModalLabel"
    data-backdrop="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex justify-content-center">
            <h4 class="modal-title">Send Email</h4>
          </div>
          <button
            type=" button"
            class="close"
            #closeSendEmailModal
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body emailSenders">
          <form [formGroup]="sendEmailForm" style="margin-top: -10px">
            <div class="form-group text-input-field">
              <textarea
                class="input-field ng-pristine ng-valid ng-touched text-size-12"
                id="toEmail"
                rows="3"
                formControlName="toEmails"
                required
              ></textarea>
              <label for="To" class="mb-0">To</label>
            </div>
            <div class="d-flex justify-content-end">
              <div *ngIf="sendEmailForm.controls.toEmails.invalid">
                <div
                  *ngIf="sendEmailForm.controls.toEmails.errors?.required"
                  style="color: red"
                >
                  To Email is required.
                </div>
              </div>
            </div>

            <div class="form-group text-input-field mt-0">
              <textarea
                class="input-field ng-pristine ng-valid ng-touched text-size-12"
                id="ccEmail"
                rows="3"
                formControlName="ccEmails"
              ></textarea>
              <label for="Cc" class="mb-0">Cc</label>
            </div>

            <div class="form-group text-input-field">
              <textarea
                class="input-field ng-pristine ng-valid ng-touched text-size-12"
                id="bccEmail"
                rows="3"
                formControlName="bccEmails"
              ></textarea>
              <label for="Bcc" class="mb-0">Bcc</label>
            </div>
            <div class="d-flex justify-content-center align-item-center">
              <button type="button" class="btn-style mr-2" data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                class="btn-style bg-green"
                (click)="sendEmails()"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <ng-template #debitSide>
    <span class="bal-amount debit-amt text-bold">
       <span>{{ 0 | number: "1.2-2" }}</span>
    </span>
  </ng-template>
  <ng-template #creditSide>
    <span class="bal-amount text-green credit-amt text-bold">
       {{ 0 | number: "1.2-2" }}
    </span>
  </ng-template>
</div>
