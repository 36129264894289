
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
  <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
    <div class="d-flex flex-y-center">
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
          alertType=='success-box' ? 'green-sucess.svg' : 
          alertType=='warning-box' ? 'red-warning.svg' : 
          null }}"
        width="20px"
        height="20px"
        alt="alert-logo"
        class="mr-20"
      />
      <div class="ml-10">
        <h4 class="m-0 text-size-18 text-bold"
        [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
          {{alertType === "error-box"
          ? "Error"
          : alertType === "warning-box"
          ? "Warning"
          : alertType === "success-box"
          ? "Success"
          : null}}
        </h4>
        <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
         {{message}}
        </small>
      </div>
    </div>
    <img
      src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
      alt="close-btn"
      (click)="hideError()"
      className=" close-btn"
    />
  </div>
</div>

<div class="ledger-container  ">
  <div class="row tab-nav pl-0 pr-0">
    <!-- <div class="col-sm-4 pl-0">
        <ul class="list-inline list-unstyled p-0 m-0">
            <li class="active page-title text-uppercase cursor"> View Client</li>
        </ul>
    </div> -->
    <div class="col-sm-12 ">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Master</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Supplier</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> View Supplier</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li class="text-grey text-capitalize cursor text-primary transition"> Edit Supplier</li>
      </ul>
  </div>

</div>
 

    <form [formGroup]="supplierForm" #form="ngForm">
      <div style="display: flex; flex-flow: row;">
      <div class="custom-form form-container mt-4 pl-15 pr-15" style="    width: 600px;
      margin: 20px 0;">
      <div class="text-input-group d-flex justify-content-between">

        <div class="select-field text-input-field mr-2">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="1"
            formControlName="parent_id" [ngClass]="{'error-field': (!supplierForm.controls.parent_id.valid && submitted),
            'success-field' : supplierForm.controls.parent_id.valid && supplierForm.controls.parent_id.dirty}">
            <option selected disabled>SELECT</option>
            <!-- <ng-container *ngIf="groupList.length > 0"> -->
            <option *ngFor="let item of groupList" value="{{item.id}}">{{item.name}}</option>
            <!-- </ng-container> -->
          </select>
          <label
            [ngClass]="{'error-label': supplierForm.controls.parent_id.invalid && supplierForm.controls.parent_id.dirty || (!supplierForm.controls.parent_id.valid && submitted)}">Group
            <ng-container
              *ngIf="supplierForm.controls.parent_id.invalid && supplierForm.controls.parent_id.dirty || (!supplierForm.controls.parent_id.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Group Required" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div style="width: 150px;" class="select-field text-input-field">
          <select class="input-field" tabIndex="1" formControlName="is_card_account">
            <option value="true">YES</option>
            <option value="false">NO</option>
          </select>
          <label>CARD</label>
        </div>

      </div>

      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
        <input class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="2" formControlName="name"
          type="text" [ngClass]="{'error-field': supplierForm.controls.name.invalid && supplierForm.controls.name.dirty || (!supplierForm.controls.name.valid && submitted) ,
  'success-field' : supplierForm.controls.name.valid && supplierForm.controls.name.dirty}" />
        <label
          [ngClass]="{'error-label': supplierForm.controls.name.invalid && supplierForm.controls.name.dirty || (!supplierForm.controls.name.valid && submitted)}">Account
          <span class="text-danger">*</span>

          <ng-container
            *ngIf="supplierForm.controls.name.invalid && supplierForm.controls.name.dirty || (!supplierForm.controls.name.valid && submitted)">
            <i class="fa fa-info-circle ml-2" title="Invalid Account Number" aria-hidden="true"></i>
          </ng-container>
        </label>
      </div>

        <div style="width: 150px;" class="text-input-field mr-2" *ngIf="supplierData?.account_code">
          <input class="input-field" [value]="supplierData?.account_code"   type="text" readonly />
        
          <label>Account Code</label>
        </div>
      </div>

      <div class="text-input-field">
        <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="address_1" tabIndex="3"
          formControlName="address_1" type="text" [ngClass]="{'error-field': supplierForm.controls.address_1.invalid && supplierForm.controls.address_1.dirty,
          'success-field' : supplierForm.controls.address_1.valid && supplierForm.controls.address_1.dirty}" />
        <label
          [ngClass]="{'error-label': supplierForm.controls.address_1.invalid && supplierForm.controls.address_1.dirty}">Address

          <ng-container *ngIf="supplierForm.controls.address_1.invalid && supplierForm.controls.address_1.dirty">
            <i class="fa fa-info-circle ml-2" title="Address Required" aria-hidden="true"></i>
          </ng-container>
        </label>
      </div>

      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="4"
            formControlName="country_id" (change)="getStates($event.target.value)">
            <option *ngFor="let country of countryArr" value="{{country.location_id}}">{{country.name}}</option>
          </select>
          <label>Country</label>
        </div>

        <div class="text-input-field mr-2">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="5"
            formControlName="state_id" (change)="getCities($event.target.value)">
            <option *ngFor="let state of stateArr" value="{{state.location_id}}">{{state.name}}</option>
          </select>
          <label>State</label>
        </div>


        <div class="text-input-field">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="6"
            formControlName="city_id" (change)="getCityName($event.target.value)">
            <option *ngFor="let city of cityArr" value="{{city.location_id}}">{{city.name}}</option>
          </select>
          <label>City</label>
        </div>
      </div>


      <div class="text-input-group d-flex justify-content-between">

        <div class="text-input-field mr-2" *ngIf="isTenantIndian">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="gst_number" tabIndex="7"
            formControlName="gst_number" type="text" maxlength="15"
            pattern="^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$"
            [ngClass]="{'error-field': supplierForm.controls.gst_number.invalid && supplierForm.controls.gst_number.dirty,
              'success-field' : supplierForm.controls.gst_number.valid && supplierForm.controls.gst_number.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.gst_number.invalid && supplierForm.controls.gst_number.dirty}">
            GST NUMBER
            <ng-container *ngIf="supplierForm.controls.gst_number.invalid && supplierForm.controls.gst_number.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid GST Number ( ex: 12ABCDE1234F1Z3)"
                aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field" *ngIf="isTenantIndian">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="gst_expiry_date" tabIndex="8"
            formControlName="gst_expiry_date" type="date"
            [ngClass]="{'error-field': supplierForm.controls.gst_expiry_date.invalid && supplierForm.controls.gst_expiry_date.dirty,
                  'success-field' : supplierForm.controls.gst_expiry_date.valid && supplierForm.controls.gst_expiry_date.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.gst_expiry_date.invalid && supplierForm.controls.gst_expiry_date.dirty}">Expiry
            Date
            <ng-container
              *ngIf="supplierForm.controls.gst_expiry_date.invalid && supplierForm.controls.gst_expiry_date.dirty">
              <i class="fa fa-info-circle ml-2" title="required expiry date" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field mr-2" *ngIf="!isTenantIndian">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="vat_number" tabIndex="7"
            formControlName="vat_number" type="text" maxlength="20"
            [ngClass]="{'error-field': supplierForm.controls.vat_number.invalid && supplierForm.controls.vat_number.dirty,
              'success-field' : supplierForm.controls.vat_number.valid && supplierForm.controls.vat_number.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.vat_number.invalid && supplierForm.controls.vat_number.dirty}">
            {{vatAlternetname}} NUMBER
          </label>
        </div>

      </div>
      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="telephone" tabIndex="9"
            formControlName="telephone" type="text" [ngClass]="{'error-field': supplierForm.controls.telephone.invalid && supplierForm.controls.telephone.dirty,
          'success-field' : supplierForm.controls.telephone.valid && supplierForm.controls.telephone.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.telephone.invalid && supplierForm.controls.telephone.dirty}">telephone
            <ng-container *ngIf="supplierForm.controls.telephone.invalid && supplierForm.controls.telephone.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid telephone number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="mobile_no" minlength="10"
            maxlength="15" tabIndex="10" formControlName="mobile_no" type="text" pattern="([0-9]+)" [ngClass]="{'error-field': supplierForm.controls.mobile_no.invalid && supplierForm.controls.mobile_no.dirty || (!supplierForm.controls.mobile_no.valid && submitted),
              'success-field' : supplierForm.controls.mobile_no.valid && supplierForm.controls.mobile_no.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.mobile_no.invalid && supplierForm.controls.mobile_no.dirty  || (!supplierForm.controls.mobile_no.valid && submitted)}">Mobile
            No.
            <ng-container
              *ngIf="supplierForm.controls.mobile_no.invalid && supplierForm.controls.mobile_no.dirty || (!supplierForm.controls.mobile_no.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid Mobile Number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
      </div>
      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="primary_email" tabIndex="11"
            formControlName="primary_email" type="text" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$"
            [ngClass]="{'error-field': supplierForm.controls.primary_email.invalid && supplierForm.controls.primary_email.dirty || (!supplierForm.controls.primary_email.valid && submitted),
    'success-field' : supplierForm.controls.primary_email.valid && supplierForm.controls.primary_email.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.primary_email.invalid && supplierForm.controls.primary_email.dirty || (!supplierForm.controls.primary_email.valid && submitted)}">Email
            #1 <ng-container
              *ngIf="supplierForm.controls.primary_email.invalid && supplierForm.controls.primary_email.dirty || (!supplierForm.controls.primary_email.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid Email" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="secondary_email"
            tabIndex="12" formControlName="secondary_email" type="text"
             [ngClass]="{'error-field': supplierForm.controls.secondary_email.invalid && supplierForm.controls.secondary_email.dirty,
      'success-field' : supplierForm.controls.secondary_email.valid && supplierForm.controls.secondary_email.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.secondary_email.invalid && supplierForm.controls.secondary_email.dirty}">Email
            #2 <ng-container
              *ngIf="supplierForm.controls.secondary_email.invalid && supplierForm.controls.secondary_email.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid Email" aria-hidden="true"></i>
            </ng-container>
          </label>

        </div>
      </div>

      <ng-container *ngIf="!isTenantIndian">
        <div class="text-input-group d-flex justify-content-between">
          
          <div class="select-field text-input-field mr-3"  >
            <select id="selectscroll" class="input-field" tabIndex="1" formControlName="currency"  (change)="currencyChange($event.target.value)" >
              <option value="INR">INR</option>
              <option value="CAD">CAD</option>
                <option value="USD">USD</option>
                <option value="AED">AED</option>
                <option value="EURO">EURO</option>
            </select>
            <label>CURRENCY</label>
          </div>
          <div class="text-input-field mr-3">
            <input class="input-field" style="    width: 185px;" oninput="this.value = this.value.toUpperCase()" 
              name="currency" tabIndex="13" formControlName="currency_rate" type="text" maxlength="10"
              [ngClass]="{'error-field': supplierForm.controls.currency_rate.invalid && supplierForm.controls.currency_rate.dirty,
          'success-field' : supplierForm.controls.currency_rate.valid && supplierForm.controls.currency_rate.dirty}" />
            <label
              [ngClass]="{'error-label': supplierForm.controls.currency_rate.invalid && supplierForm.controls.currency_rate.dirty}">CURRENCY RATE<ng-container *ngIf="supplierForm.controls.currency_rate.invalid && supplierForm.controls.currency_rate.dirty">
                <i class="fa fa-info-circle ml-2" title="Invalid pan number" aria-hidden="true"></i>
              </ng-container>
            </label>
          </div>
          <div class="text-input-group d-flex justify-content-between">
            <div class="text-input-field" style="    width: 185px;">
              <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="local_balance" tabIndex="13" 
            formControlName="local_balance"  type="text" [ngClass]="{'error-field': supplierForm.controls.local_balance.invalid && supplierForm.controls.local_balance.dirty,
          'success-field' : supplierForm.controls.local_balance.valid && supplierForm.controls.local_balance.dirty}" (input)="changeCurrentAmount($event)" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.local_balance.invalid && supplierForm.controls.local_balance.dirty}"  >AMOUNT
            <ng-container *ngIf="supplierForm.controls.local_balance.invalid && supplierForm.controls.local_balance.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid amount" aria-hidden="true"></i>
            </ng-container>
          </label>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="text-input-group d-flex justify-content-between">

        <div class="w-50 text-input-field mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="pan_number" tabIndex="13"
            formControlName="pan_number" type="text" maxlength="10" pattern="([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$"
            [ngClass]="{'error-field': supplierForm.controls.pan_number.invalid && supplierForm.controls.pan_number.dirty,
          'success-field' : supplierForm.controls.pan_number.valid && supplierForm.controls.pan_number.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.pan_number.invalid && supplierForm.controls.pan_number.dirty}">PAN
            NO. <ng-container
              *ngIf="supplierForm.controls.pan_number.invalid && supplierForm.controls.pan_number.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid pan number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="w-50 d-flex">
          <div class="w-75 text-input-field mr-2">
            <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="balance" tabIndex="14"  [readonly]="!isTenantIndian"
              formControlName="balance" pattern="^\d*(\.\d{0,8})?$" type="text" [ngClass]="{'error-field': supplierForm.controls.balance.invalid && supplierForm.controls.balance.dirty,
                  'success-field' : supplierForm.controls.balance.valid && supplierForm.controls.balance.dirty}" />
            <label
              [ngClass]="{'error-label': supplierForm.controls.balance.invalid && supplierForm.controls.balance.dirty}">Opening
              Balance
              <ng-container *ngIf="supplierForm.controls.balance.invalid && supplierForm.controls.balance.dirty">
                <i class="fa fa-info-circle ml-2" title="Invalid opening balance" aria-hidden="true"></i>
              </ng-container>
            </label>
          </div>

          <div  class="w-25 select-field text-input-field">
            <select class="input-field" tabIndex="15" formControlName="balance_type">
              <option value="debit">DR</option>
              <option value="credit">CR</option>
            </select>
          </div>
      </div>

      </div>

      <div class="w-100 select-field text-input-field">
        <select class="w-100  input-field" tabIndex="16" formControlName="status">
          <option value="active">ACTIVE</option>
          <option value="inactive">INACTIVE</option>
        </select>
        <label>Status</label>
      </div>

      <div class="text-input-field">
        <textarea tabIndex="17" class="input-field" formControlName="remarks"
          oninput="this.value = this.value.toUpperCase()" rows="2"></textarea>
        <label>Remarks</label>
      </div>
      <div class="form-check">
        <input class="radio-ui mr-2" type="checkbox" formControlName="is_branch" id="isBranch">
        <label class="radio-label" style="transform: none;font-size: 12px;margin-bottom: 0;" for="isBranch">
          Is Company
        </label>
      </div>

      <!-- <div class="select-field text-input-field" *ngIf="isHQCompany">
        <label for="selectedCompany" style="font-size: 13px; font-weight: 500!important;">Company</label>
        <ng-container *ngFor="let item of companies">
            <div *ngIf="item?.associate_company_id!==null" class="input-fiel" style="display: flex;align-items: center;">
              <input class="radio-ui mr-2" type="checkbox"  (change)="handleCompanyChange($event,item?.tenant_company_name)"  />
              <label class="radio-label" style="transform: none;font-size: 12px;margin-bottom: 0;" >{{item?.tenant_company_name}}</label>
            </div>
        </ng-container>
      </div> -->
      <div class="select-field text-input-field" *ngIf="isHQBranch">
        <label for="selectedBranch" style="font-size: 13px; font-weight: 500!important;">Branch</label>
        <ng-container *ngFor="let item of branches">
          <div *ngIf="item?.branch_type=='BRANCH'" class="input-fiel" style="display: flex;align-items: center;">
            <input class="radio-ui mr-2" type="checkbox"  (change)="handleValueChange($event,item?.branch_name)"  />
            <label class="radio-label" style="transform: none;font-size: 12px;margin-bottom: 0;" >{{item?.branch_name}}</label>
          </div>
    </ng-container>
      </div>

    </div>
    <div class='contactCard contact-address ml-5' *ngIf="supplierContacts && supplierContacts.length > 0">
      <h3>Contacts: </h3>
         <div class="cardCn bg-purple" *ngFor="let item of supplierContacts">
            <p><span class="text-black"> {{item.contact_person}}</span><span *ngIf="item.type" class="designation">, {{item.type}}</span></p>
            <p>Email: <span class="text-primary">{{item.email}}</span> </p>
            <p>M: <span class="text-black">{{item.mobile}}</span> </p>
            <p *ngIf="item.address">Address: <span class="text-black address">{{item.address}}</span></p>
            <p *ngIf="item.description">Description: <span class="text-black">{{item.description}}</span> </p>
        </div>
    </div>
  </div>
      <div class="btn-field bottom-btns-container full-width-btn-container d-flex align-item-center justify-content-end ml-0 mr-0"> 
         <!-- <button type="button" class="btn-style bg-green mr-3" tabIndex="20"
          >save</button> -->
          <button type="button" class="btn-style bg-green mr-3" tabIndex="18" (click)="setSupplierData()">Reset</button>
          <button type="button" class="btn-style bg-cyan mr-3" tabIndex="19"
          *hasAccess="{'key' : 'F0001033'}"
          (click)="openUpdateConfirmModal()">Modify</button>
      
        <button type="button" class="btn-style bg-red mr-3" tabIndex="20"
          *hasAccess="{'key' : 'F0001034'}"
          (click)="openDeleteConfirmModal()">Delete</button>
        <!-- <button type="button" class="btn-style bg-primary text-white"
        <button type="button" class="btn-style bg-primary text-white mr-3"
          [routerLink]="['/master/setting/supplier', supplierData.name,supplierData.account_code]" tabIndex="21">Update
          Policy</button> -->
          <button type="button" class="btn-style bg-purple-iris mr-3"
          (click)="openConfiguration(supplierData.name,supplierData.account_code)" tabIndex="21">Configuration</button>
        <button type="button" class="btn-style bg-indigo mr-3 " id="receipt_activity_log" (click)="openActivity()">Activity</button> 
        <button type="button" class="btn-style bg-water-blue" tabIndex="23" id="modal_btn" (click)="openAddEditContactModal()">Add/Edit Contacts</button> 
      </div>

    </form>
</div>


<!-- <div class="modal fade" id="confirmationModal"  role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
          <button type="button" class="close" #closeModal data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
        Update {{supplierForm?.value?.name}} ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" tabIndex="22"  data-dismiss="modal">Close</button>
        <button type="button" class="btn-style" (click)="updateSupplier()" (keyup.enter)="updateSupplier()" tabIndex="23">Yes</button>
      </div>
    </div>
  </div>
</div> -->

<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateconfirmationModal"></button>
<div id="updateconfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeUpdateModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        Update {{supplierForm?.value?.name}} ?
      </div>
      <div class="modal-footer">       
        <button type="button" class="btn-style mr-3" tabIndex="22" attr.tabIndex="22"
          data-dismiss="modal">Close</button>
          <button type="button" class="btn-style bg-green" (click)="checkBalance()" data-dismiss="modal" tabIndex="21"
          attr.tabIndex="21">Yes</button>
      </div>
    </div>
  </div>
</div>


<button type="button" hidden #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button>

<div id="deleteConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeDeleteModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <p>Are you sure to delete ‎this entry ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style" tabIndex="24" attr.tabIndex="24" (click)="closeDeleteConfirmModal()"
        data-dismiss="modal">No</button>
        <button type="button" class="btn-style bg-red" tabIndex="23" attr.tabIndex="23" (click)="deleteSupplier()"
          data-dismiss="modal">Yes</button>
      </div>
    </div>
  </div>
</div>


<button type="button" hidden #openContactDetail data-toggle="modal" data-target="#contactDetailModal"></button>
  <div
    class="modal"
    id="contactDetailModal"
    aria-labelledby="contactDetailModal"
    data-backdrop="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="margin-top:100px;">
        <div class="modal-header">
          <div class="d-flex justify-content-center">
            <h4 class="modal-title">{{supplierForm.controls.name.value}}'s Additional Contacts</h4>
          </div>
          <button
            type=" button"
            class="close"
            #closeContactDetail
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="supplierContactForm" >
            <div>

              <div class="form-group text-input-field ">
                <input
                  class="input-field ng-pristine ng-valid ng-touched text-size-12"
                  id="contact_person"
                  style="max-height: 45px;"
                  formControlName="contact_person" />
                <label for="contact_person" class="mb-0">Contact Person</label>
              </div>
              
              <div class="form-group text-input-field">
                <select
                  class="input-field ng-pristine ng-valid ng-touched text-size-12"
                  id="type"
                  formControlName="type"
                  required
                >
                <option value="">select</option>
                <option value="owner">Owner</option>
                <option value="accountant">Accountant</option>
                <option value="sales">Sales</option>
                <option value="counter_staff">Counter staff</option>
                <option value="other">Other</option>
              </select>
                <label for="type" class="mb-0">Contact Type</label>
              </div>

              <div class="form-group text-input-field">
                <textarea
                  class="input-field ng-pristine ng-valid ng-touched text-size-12"
                  rows="3"
                  id="address"
                  formControlName="address"
                ></textarea>
                <label for="address" class="mb-0">Address</label>
              </div>
  
              <div class="form-group text-input-field ">
                <input
                  class="input-field ng-pristine ng-valid ng-touched text-size-12"
                  id="mobile"
                  style="max-height: 45px;"
                  formControlName="mobile" />
                <label for="mobile" class="mb-0">Mobile No.</label>
              </div>
  
              <div class="form-group text-input-field ">
                <input
                  class="input-field ng-pristine ng-valid ng-touched text-size-12"
                  id="email"
                  style="max-height: 45px;"
                  formControlName="email" />
                <label for="email" class="mb-0">Email</label>
              </div>
             
              <div class="form-group text-input-field">
               <textarea
                 class="input-field ng-pristine ng-valid ng-touched text-size-12"
                 rows="3"
                 id="description"
                 formControlName="description"
               ></textarea>
               <label for="description" class="mb-0">Description</label>
             </div>
            </div>
            
            <div class="mt-3 d-flex justify-content-center align-item-center">
              <button type="button" class="btn-style mr-2" data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                class="btn-style bg-green"
                [disabled]="!supplierContactForm.valid"
                (click)="supplierContactForm.controls.selectedSupplier.value == '' ? saveContact() : updateContact()">
                Save Address
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


  <button type="button" hidden #addEditContact data-toggle="modal" data-target="#addEditContactModal"></button>
  <div
    class="modal"
    id="addEditContactModal"
    aria-labelledby="addEditContactModal"
    data-backdrop="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="margin-top:100px;max-width: 300px;">
        <div class="modal-header">
          <div class="d-flex justify-content-center">
            <h4 class="modal-title">{{supplierForm.controls.name.value}}'s Add/Edit Contact</h4>
          </div>
          <button
            type=" button"
            class="close"
            #closeEditContact
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="supplierContactForm" >
            <div class="form-group text-input-field">
              <select class="input-field ng-pristine ng-valid ng-touched text-size-12" formControlName="selectedSupplier">
                <option value="" class="text-red">Add new contact</option>
                <option *ngFor="let item of supplierContacts" value="{{item.id}}">{{item.contact_person}}</option>
              </select>
            </div>

            <div class="mt-3 d-flex justify-content-center align-item-center">
              <button type="button" class="btn-style mr-2" data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                class="btn-style bg-green"
                (click)="submitAddEditContact()">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <button type="button" hidden #confirmOBModal data-toggle="modal" data-target="#updateOBModal"></button>
  <div id="updateOBModal" class="modal fade" role="dialog" data-backdrop="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Confirmation</h4>
          <button type="button" #closeOBModal class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          There is a mismatch of amount {{remainingAmount}} in your opening balance. It will be adjusted in the Opening Balance Adjustment account. Would you like to proceed?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn-style mr-3" 
          data-dismiss="modal" (click)="closeOBConfirmModal()">Close</button>
          <button type="button" class="btn-style bg-green" (click)="updateOpeningBalancecombine()" >UPDATE</button>
        </div>
      </div>
    </div>
  </div>