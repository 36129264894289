
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
    <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
      <div class="d-flex flex-y-center">
        <img
          src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
            alertType=='success-box' ? 'green-sucess.svg' : 
            alertType=='warning-box' ? 'red-warning.svg' : 
            null }}"
          width="20px"
          height="20px"
          alt="alert-logo"
          class="mr-20"
        />
        <div class="ml-10">
          <h4 class="m-0 text-size-18 text-bold"
          [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
            {{alertType === "error-box"
            ? "Error"
            : alertType === "warning-box"
            ? "Warning"
            : alertType === "success-box"
            ? "Success"
            : null}}
          </h4>
          <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
           {{message}}
          </small>
        </div>
      </div>
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
        alt="close-btn"
        (click)="hideError()"
        className=" close-btn"
      />
    </div>
  </div>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<button type="button" hidden #openSuccessModal data-toggle="modal" data-target="#successModal"></button>

			<!-- Modal -->
    <div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
        <div class="modal-dialog transaction-creation-modal">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <span class="messageFont">
                        <span>
                            {{message}} 
                        </span>
                            <a *ngIf="invoice_number != ''" 
                                href="javascript:void(0)"	
                                (click)="openInvoice(invoice_number, 'flight')">
                                {{invoice_number}}
                            </a>
                            <i *ngIf="invoice_number != ''" [title]="copyELementTitle" (click)="copytext(invoice_number)" class="fa fa-copy cursor-pointer"></i>
                        </span>
                        <button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
                    </div>
                <div class="modal-body">
                    <p>Do you want to create new invoice or  view newly created Invoice ?</p>
                </div>
                <div class="modal-footer justify-content-center">
                    <button 
                        tabIndex="1" 
                        tabindex="1"
                        type="button" 
                        class="btn btn-primary bg-blue" 
                        (click)="viewInvoice(invoice_number)">
                        View
                    </button>
                    <button 
                        tabIndex="2" 
                        tabindex="2"
                        type="button" 
                        class="btn btn-primary btn-grey" 
                        (click)="openInvoice(invoice_number, 'flight')">
                        Print
                    </button>
                    <button 
                        tabIndex="3" 
                        tabindex="3"
                        type="button" 
                        class="btn btn-primary bg-green"  
                        (click)="closeSuccesModal()">
                        Create New
                    </button>
                </div>
            </div>

        </div>
    </div>

<div class="ledger-container ">
    <div class="row tab-nav pl-0 pr-0">
        <!-- <div class="col-sm-4 pl-0">
            <ul class="list-inline list-unstyled p-0 m-0">
                <li class="active page-title text-uppercase cursor"> View Client</li>
            </ul>
        </div> -->
        <div class="col-sm-12 ">
          <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
              <!-- <li [routerLink]="['/']"
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> Master</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li [routerLink]="['/']"
                  class="text-grey text-capitalize cursor  hover-text-primary transition"> Client</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li> -->
              <li class="text-grey text-capitalize cursor text-primary transition"> Invoice</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-right.svg" alt=""></li>
              <li class="text-grey text-capitalize cursor hover-text-primary transition"> Flight</li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-right.svg" alt=""></li>
              <li class="text-grey text-capitalize cursor hover-text-primary transition"> Sales</li>
          </ul>
      </div>
  
   
    </div>
    <!-- 
    <div class="d-flex justify-content-between mb-3">
        <div class="heading-box mt-4 mb-3" style="width: 720px;">
            <div class="heading">Invoice</div>
            <span class="hr-line"></span>
        </div>
        <div class="action-box mt-4" *ngIf="invoiceMode == 'view'">
            <button type="button" class="btn-style" (click)="closeWindow()">Close</button>
        </div>
    </div> -->


    <form [formGroup]="nestedForm" [ngClass]="{'d-none': !newInvoice}">
        <div class="pl-15 pr-15 pt-4 mb-4 custom-form form-container  w-100">
        <div class="text-center mt-20"  *ngIf="showErrorMessage">
            <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/no-transaction-3.svg" width="400"/>
        </div>
        <div [ngClass]="{'d-none': showErrorMessage}">
        <div class="d-flex align-item-center justify-content-between">
            <div class="form-group d-flex align-items-center justify-content-between">
                <div class="mr-3 input-fiel form-group ng-field autocompletecontainer" tabIndex="1" (focus)="focusAccountAc()">
                    <label class="ngauto-label"  for="affname">Client<span class="text-danger">*</span></label>
                    <div class="ng-autocomplete">
                        <input
                            type="text" #accountAC oninput="this.value = this.value.toUpperCase()" 
                             id="affname" name="affiliate_name" 
                            [ngClass]="{'validate-ng-error': (nestedForm.controls.affiliate_name.invalid && nestedForm.controls.affiliate_name.touched) || 
                            (!nestedForm.controls.affiliate_name.valid && sectorService.submitted)}"
                            formControlName="affiliate_name"
                            (input)="onChangeSearch($event)"
                            [nzAutocomplete]="clientAc"
                            class="input-ui inv-date-input"
                        />

                        <nz-autocomplete #clientAc [nzBackfill]="true"  (selectionChange)="selectAffiliateEvent($event)">
                            <nz-auto-option  class="colr-1" *ngFor="let item of affiliateArr" [nzValue]="item" [nzLabel]="item.name">
                                <a innerHTML='<span  class="colr-1">{{item.name}}
                                        ({{item.account_code}})</span><br />
                                    <span class="colr-2">{{item.primary_email}}</span><br />'>
                                </a>                            
                            </nz-auto-option>     
                            <nz-auto-option *ngIf="sectorService.showCreateButton && sectorService.currentMaster == 'client' " class="colr-1" nzValue="" style="text-align: center"
                                nzLabel="Create New">
                                <span>Create New</span>                          
                            </nz-auto-option>                      
                        </nz-autocomplete>
                       
                    </div>
                </div>
                <!-- <div class="mr-3 ">
                <div class="input-fiel flex-column d-flex"
                    [ngClass]="{'has-error': (nestedForm.controls.billaddress.errors?.required && nestedForm.controls.billaddress.dirty) , 'has-success': !nestedForm.controls.billaddress.errors?.required }">


                    <input oninput="this.value = this.value.toUpperCase()" tabIndex="2" tabindex="2"
                        placeholder="Billing Address" id="billaddress" formControlName="billaddress" class="input-ui" 
                        [ngClass]="{'validateError': (nestedForm.controls.billaddress.invalid && nestedForm.controls.billaddress.touched) || 
                        (!nestedForm.controls.billaddress.valid && sectorService.submitted)}"/>
                    <label for="billaddress" >Billing 
                    Address<span class="text-danger">*</span></label>
                        placeholder="Billing Address" id="billaddress" formControlName="billaddress" class="input-ui" />
                    <label for="billaddress">Billing Address</label>



                    <span class="error-ui mt-0 ml-2"
                        *ngIf="nestedForm.controls.billaddress.dirty && nestedForm.controls.billaddress.errors?.required">
                        This field is required.</span>

                     (already commented)<li *ngIf="form.controls.content.dirty">Start writing from second line</li>
                   
                </div>
            </div> -->
            <div class="mr-3 ">
                <div class=" input-fiel flex-column d-flex">
                    <input tabIndex="2" tabindex="2"  class="input-ui inv-date-input"
                        formControlName="invdate" id="invdate" type="date" placeholder="Invoice Issue Date"
                        min="{{minDate}}"
                        max="{{maxDate}}"
                        autocomplete="off" />
                    <label for="invdate">Invoice Date</label>
                    <span class="error-ui mt-0 ml-2" *ngIf="!nestedForm.controls.invdate.valid">
                        Invalid Date .</span>

                </div>
            </div>
            <div class="mr-3 ">
                <div class="input-fiel">
                    <select id="inv-type" style="min-width: 110px;" tabIndex="3" tabindex="3" class="input-ui inv-date-input invoice-type-select" (change)="onTypeOfInvoiceChange($event.target.value)" formControlName="invoiceType">
                        <option value="normal">Normal</option>
                        <option value="reissue">Re-issue</option>
                        <option value="split">Split</option>
                    </select>
                    <label for="inv-type">Invoice Type</label>

                </div>
                </div>
                <div class="mr-3 " *ngIf="nestedForm.value.invoiceType == 'reissue'">
                <div class="input-fiel" >
                    <input class="input-ui inv-date-input" formControlName="referenceInvoice" id="reference"
                        autocomplete="off" />
                    <label for="reference">Reference Invoice</label>

                </div>
                </div>
                <div class="mr-3">
                <div class=" input-fiel mr-0" *ngIf="invoiceMode == 'view'">
                    <input oninput="this.value = this.value.toUpperCase()" class="input-ui inv-number-input" id="uuid"
                        formControlName="uuid" type="text" placeholder="Invoice Number" disabled /><label
                        for="uuid">Invoice Number</label>
                </div>
                </div>
                <div class="mr-3">
                    <div class=" input-fiel mr-0" (keydown.enter)="openTraveller()">
                        <input oninput="this.value = this.value.toUpperCase()" tabIndex="4" tabindex="4" class="input-ui inv-number-input" id="booking_ref_no"
                            formControlName="booking_ref_no" type="text" placeholder="Booking Ref Number" /><label
                            for="booking_ref_no">Booking Ref No</label>
                    </div>
                </div>
                <div class="mr-3">
                    <div class="input-fiel">
                        <select id="flightType" style="min-width: 110px;" tabIndex="5" tabindex="5" class="input-ui inv-date-input invoice-type-select" formControlName="flightType" (change)="invoiceMode!= 'view' && sectorService.calculateServiceChargeFlightWise(nestedForm.value)">
                            <option value="">Select</option>
                            <option value="domestic">Domestic</option>
                            <option value="international">International</option>
                        </select>
                        <label for="flightType">Flight Type</label>
                    </div>
                </div>



                <div class="mr-3" *ngIf="!isTenantIndian">
                    <div class="input-fiel">
                        <select id="flightType" style="min-width: 160px;" tabIndex="6" tabindex="6"  class="input-ui inv-date-input" formControlName="purchase_currency" (change)="onpurchaseCurrencyChange($event.target.value)" >
                            <option value="">Select</option>
                            <option value="AED">AED</option>
                            <option value="CAD">CAD</option>
                            <option value="USD">USD</option>
                            <option value="INR">INR</option>
                            <option value="EURO">EURO</option>
                        </select>
                        <label for="purchase_currency">Purchase Curr.</label>
                    </div>
                </div>
                <div class="" *ngIf="!isTenantIndian">
                    <div class="input-fiel">
                        <input oninput="this.value = this.value" class="input-ui inv-number-input" id="currency_rate"
                        formControlName="currency_rate" type="text" tabIndex="7" tabindex="7" 
                        [ngClass]="{'validateError': (nestedForm.controls.currency_rate.invalid && nestedForm.controls.currency_rate.touched) || 
											            (!nestedForm.controls.currency_rate.valid )}" />
                        <label for="currency_rate">Rate({{currencyCode}}-{{nestedForm.value.purchase_currency}})</label>
                    </div>
                </div>
            </div>
            <div class="form-group d-flex align-item-center justify-content-end">
                

              
 
            </div>
        </div>
        <!-- 
        <div class="inner-nav mt-2" [ngClass]="fixedModule ? 'inner-nav mt-2 fixed-content' : 'inner-nav mt-2'">
            <div class="message-wrapper" *ngIf="showModuleMessage">
                <div class="update-info animated fadeInDown {{moduleAlertType}}">
                    <div class="container">
                        <div class="row">
                            <div class="col-11">
                                <h5 class="messageFont">
                                    {{moduleMessage}}
                                </h5>
                            </div>
                            <div class="col-1">
                                <button type="button" class="close" (click)="hideModuleMessage()" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="titlebar">
                <span *ngIf="!moduleView" class="titlebar-heading text-uppercase">{{selectedTab}}</span>
                <div class="buttons">
                    <ng-container *ngIf="invoiceMode != 'view'">
                        <div *ngIf="!moduleView" (click)="editProduct(0)" (keyup.enter)="editProduct(0)" tabIndex="4"
                            tabindex="4" class="minmax-btn" data-toggle="tooltip" title="Maximize">
                            <span class="max-btn">
                                <i class="fa fa-window-maximize mr-2" aria-hidden="true"></i>
                                <span>Maximize</span>
                            </span>
                        </div>

                    </ng-container>
                </div>
            </div>

            <div class="module-container" [ngClass]="moduleView ? 'maximized-module' : 'minimized-module'">

                <ul class="nav nav-tabs">
                    <li><a [ngClass]="{'active': selectedTab=='flight'}" data-toggle="tab"
                            (click)="onTabChange('flight')" href="#flights">Flights</a></li>
                </ul>



                <div class="tab-content-container">
                    <div class="tab-content mt-3">
                        <div id="flights" class="tab-pane" [ngClass]="{'active': selectedTab=='flight'}">
                            <app-flight (callProductEvent)="makeProduct($event)"
                                (callInprogressEvent)="handleInprogressAlert($event)"
                                (callShowMessage)="showMessageFn($event)" [selectedProduct]="selectedProduct"
                                [selectedProductIndex]="selectedProductIndex"
                                [invoiceType]="nestedForm.value.invoiceType" [invoice_root_data]="nestedForm.value">
                            </app-flight>
                        </div>
                    </div>
                </div>

            </div>

        </div> -->


        <div class="module-container">
            <!-- 
            <ul class="nav nav-tabs">
                <li><a [ngClass]="{'active': selectedTab=='flight'}" data-toggle="tab" (click)="onTabChange('flight')"
                        href="#flights">Flights</a></li>
            </ul> -->



            <div class="tab-content-container p-0">
                <div class="tab-content p-0 mt-3">
                    <div id="flights" class="tab-pane" [ngClass]="{'active': selectedTab=='flight' || selectedTab=='invoice'}">
                        <app-flight
                        [sectorService]="sectorService"    
                        (callProductEvent)="makeProduct($event)"
                            (callSupplierChange)="setSelectedSupplier($event)"
                            (callInprogressEvent)="handleInprogressAlert($event)" [invoice_no]="invoice_no" [invoiceMode]="invoiceMode"
                            (callShowMessage)="showMessageFn($event)" [selectedProduct]="selectedProduct"
                            [selectedProductIndex]="selectedProductIndex" [invoiceType]="nestedForm.value.invoiceType"
                            [invoice_root_data]="nestedForm.value" (openSsrModalMethod)="openSsrModal($event)"  
                            [masterCreationModal]="masterCreationModal" [isTenantIndian]="isTenantIndian">
                        </app-flight>
                    </div>

                    <!-- <div id="miscellaneous" *ngIf="moduleView" class="tab-pane" [ngClass]="{'active': selectedTab=='miscellaneous'}">

                        <app-miscellaneous-invoice (callProductEvent)="makeProduct($event)" (callShowMessage)="showMessageFn($event)" [selectedProduct]="selectedProduct" [selectedProductIndex]="selectedProductIndex" [invoiceType]="nestedForm.value.invoiceType" [invoice_root_data]="nestedForm.value"></app-miscellaneous-invoice>
                    </div> -->

                </div>
            </div>

        </div>

        <div class="custom-table mt-3">
            <!-- <table class="table table-bordered outer-table-group" formArrayName="paymentdetails">
                <thead>
                    <tr>
                        <th style="max-width: 50px;">#</th>
                        <th>Product / Service</th>
                        <th>HSN/SAC</th>
                        <th>Narration</th>
                        <th>Qty</th>
                        <th>Amount</th>
                        <th style="max-width: 50px;" [ngClass]="{'table-scroll-fix': paymentArray.controls.length >5 }"
                            class=""></th>
                    </tr>
                </thead

                <tbody class="outer-table-group-tbody">
                    <tr *ngFor="let group of paymentArray.controls; let i = index; " [formGroupName]="i">
                        <td style="max-width: 50px"> {{i+1}} </td>
                        <td>
                            <span class="custom-tr-input">
                                <input oninput="this.value = this.value.toUpperCase()" type="text" name="name"
                                    formControlName="name" />
                                <span class="error-ui"
                                    *ngIf="group.controls.name.dirty && group.controls.name.errors?.required">
                                    This field is required
                                </span>
                            </span>
                        </td>
                        <td>
                            <span class="custom-tr-input">
                                <input oninput="this.value = this.value.toUpperCase()" type="text" name="hsc_sac"
                                    formControlName="hsc_sac" />
                                <span class="error-ui"
                                    *ngIf="group.controls.hsc_sac.dirty && group.controls.hsc_sac.errors?.required">
                                    This field is required
                                </span>
                            </span>
                        </td>

                        <td>
                            <span class="custom-tr-input">
                                <input oninput="this.value = this.value.toUpperCase()" type="text" name="narration"
                                    formControlName="narration" />
                                <span class="error-ui"
                                    *ngIf="group.controls.narration.dirty && group.controls.narration.errors?.required">
                                    This field is required
                                </span>
                            </span>
                        </td>

                        <td>
                            <span class="custom-tr-input">
                                <input class="text-right" oninput="this.value = this.value.toUpperCase()" type="text"
                                    name="quantity" formControlName="quantity" (input)="perItemCalculation(i)" />
                                <span class="error-ui"
                                    *ngIf="group.controls.quantity.touched && group.controls.quantity.errors?.required">
                                    This field is required
                                </span>
                                <span class="error-ui"
                                    *ngIf="!group.controls.quantity.valid && group.controls.quantity.hasError('pattern')">
                                    Only Numbers Allowed</span>

                            </span>
                        </td>

                        <td>
                            <span class="custom-tr-input ">
                                <input class="text-right" oninput="this.value = this.value.toUpperCase()" type="text"
                                    name="amount" formControlName="amount" (input)="perItemAmountCalculation(i)" />
                                <span class="error-ui"
                                    *ngIf="group.controls.amount.touched && group.controls.amount.errors?.required">
                                    This field is required
                                </span>
                                <span class="error-ui"
                                    *ngIf="!group.controls.amount.valid && group.controls.amount.hasError('pattern')">
                                    Only Numbers Allowed</span>
                            </span>
                        </td>


                        <td style="max-width: 50px;">
                            <span class="action-col">
                                <a (click)="editProduct(i)" href="javascript:void(0)"><i title="Edit"
                                        class="fas fa-user-edit"></i></a>
                                <a (click)="removepayment(i)" href="javascript:void(0)"><i title="Delete"
                                        class="fa fa-trash" aria-hidden="true"></i></a>
                            </span>
                        </td>

                    </tr>
                </tbody>

            </table> -->



            <div class="table-actions d-flex justify-content-between">
                <div class="action d-flex flex-column">
                    <span *ngIf="nestedForm.value.narration_1">
                       <span class="text-grey">Narration 1 :</span> {{nestedForm.value.narration_1}}
                    </span>
                    <span *ngIf="nestedForm.value.narration_2">
                        <span class="text-grey">Narration 2 :</span>  {{nestedForm.value.narration_2}}
                    </span>
                    <span *ngIf="nestedForm.value.narration_3">
                        <span class="text-grey">Narration 3 :</span>  {{nestedForm.value.narration_3}}
                    </span>
                    <span *ngIf="nestedForm.value.narration_4">
                        <span class="text-grey">Narration 4 :</span>  {{nestedForm.value.narration_4}}
                    </span>
                </div>

                <div class="subtotal">
                    <!-- <div class="total-box">
                        <span class="title-type">Subtotal</span>
                        <span class="result-type">&#8377; 0.00</span>
                    </div> -->

                    <div class="total-box" *ngIf="insuredAmount" >
                            <!-- <span class="title-type link" (click)="openMsInvoice(referenceMsInvoice)">Total Insurance Premium</span> -->
                            <span class="title-type" >Total Insurance Premium</span>
                            <span class="result-type">
                                {{insuredAmount | currency: currencyCode :'symbol':'1.0' : nativeLanguageCode }}
                            </span>
                    </div>
                    <div class="total-box">
                        <span class="title-type">Total Flight Amount</span>
                        <span class="result-type">
                            {{summedAmount | currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
                        </span>
                    </div>

                    <div class="total-box" *ngIf="balanceObj && balanceObj.total != undefined"
                    [ngClass]="{'text-danger': balanceObj.total == 0}">
                        <span class="title-type">Grand Total</span>
                        <span class="result-type">
                            {{insuredAmount+summedAmount | currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
                        </span>
                    </div>

                    <div class="total-box" *ngIf="balanceObj && balanceObj.total != undefined" [ngClass]="{'text-danger': balanceObj.total == 0}">
                        <span class="title-type">Current Balance</span>
                        <span class="result-type">{{balanceObj?.total | currency: currencyCode :'symbol' : '0.1-1' : nativeLanguageCode }}</span>
                    </div>

                    <div *ngIf="!isTenantIndian" class="currencyWrapper mt-5">
                        <div class="row">
                            <div class="col-4 input-fiel ">
                                <select id="flightType" class="input-ui inv-date-input" formControlName="sales_currency" >
                                    <option value="">Select</option>
                                    <option value="AED">AED</option>
                                    <option value="CAD">CAD</option>
                                    <option value="USD">USD</option>
                                    <option value="INR">INR</option>
                                    <option value="EURO">EURO</option>
                                </select>
                                <label for="sales_currency">Sales Curr.</label>
                            </div>
    
                            <div class="col-4  input-fiel p-0">
                                <input oninput="this.value = this.value"  class="input-ui inv-number-input text-right" id="sales_currency_rate"
                                    formControlName="sales_currency_rate" type="text" (input)="changeSalesCurrentAmount($event)"
                                />
                                <label for="sales_currency_rate">Sales Curr. Rate</label>
                            </div>
    
                            <div class="col-4 input-fiel ">
                                <input disabled  class="input-ui inv-number-input text-right" id="sales_currency_amount"
                                    formControlName="sales_currency_amount" type="text"
                                />
                                <label for="sales_currency_amount">Sales Amount</label>
                            </div>
                        </div>
                       
                    </div>

                    <div *ngIf="salesCurrencyPrint=='true'" class="currencyWrapper mt-5">
                        <div class="row" style="justify-content: flex-end">
                            <div class="col-4 input-fiel ">
                                <select id="flightType" class="input-ui inv-date-input" formControlName="sales_currency" >
                                    <option value="">Select</option>
                                    <option value="AED">AED</option>
                                    <option value="CAD">CAD</option>
                                    <option value="USD">USD</option>
                                    <option value="INR">INR</option>
                                    <option value="EURO">EURO</option>
                                </select>
                                <label for="sales_currency">Sales Curr.</label>
                            </div>
    
                            <div class="col-4  input-fiel p-0">
                                <input oninput="this.value = this.value"  class="input-ui inv-number-input text-right" id="sales_currency_rate"
                                    formControlName="sales_currency_rate" type="text" 
                                />
                                <label for="sales_currency_rate">Sales Curr. Rate</label>
                            </div>
                        </div>
                       
                    </div>

                   
                </div>
            </div>

            <div class="text-input-group d-flex justify-content-between mt-3">
                <!-- <div class="input-fiel mt-10 w-50">
                    <input tabIndex="{{sectorService.lastIndexOfTraveller+3}}"
                        attr.tabIndex="{{sectorService.lastIndexOfTraveller+3}}"
                        oninput="this.value = this.value.toUpperCase()" class="input-ui w-100" id="narration"
                        formControlName="narration_4" type="text" placeholder="Narration" /><label
                        for="narration">Narration 4</label>
                </div> -->
                <div class="input-fiel mt-10 w-50  mr-3">
                    <input tabIndex="{{sectorService.lastIndexOfTraveller+3}}"
                        attr.tabIndex="{{sectorService.lastIndexOfTraveller+3}}"
                        class="input-ui w-100" id="narration"
                        formControlName="narration_5" type="text" placeholder="Narration"/>
                        <label 
                        for="narration" 
                        [ngClass]="{'error-label-narration': (nestedForm.controls.narration_5.invalid && nestedForm.controls.narration_5.touched) || 
                            (!nestedForm.controls.narration_5.valid && sectorService.submitted)}">Narration 5
                        </label>
                        <span class="error-ui" *ngIf="(nestedForm.controls.narration_5.invalid && nestedForm.controls.narration_5.touched) || 
                        (!nestedForm.controls.narration_5.valid && sectorService.submitted)">Invalid Narration 5</span>
                </div>
                <div class="input-fiel mr-0 w-50 mt-10">
                    <input tabIndex="{{sectorService.lastIndexOfTraveller+4}}"
                        attr.tabIndex="{{sectorService.lastIndexOfTraveller+4}}"
                        oninput="this.value = this.value.toUpperCase()" class="input-ui w-100" id="remark"
                        formControlName="remark" type="text" placeholder="Remark" /><label for="remark">Remark</label>
                </div>
                <div class="input-fiel mr-0 w-10 mt-10" *ngIf="dueDateConf=='true'">
                    <input 
                        oninput="this.value = this.value.toUpperCase()" class="input-ui w-100" id="duedate"
                        formControlName="duedate" type="date" placeholder="duedate" /><label for="duedate">Due Date</label>
                </div>
            </div>
            <!-- <div class="d-flex justify-content-center" *ngIf="balanceObj">
                <div *ngIf="balanceObj.credit" class="ml-2">
                    <span class="font-weight-bold">Credit Balance : </span> {{balanceObj?.credit | number : '0.1-1'}}
                </div>
            </div> -->
           

        </div>
        </div>
    </div>
    <div class="btn-field bottom-btns-container full-width-btn-container  d-flex align-item-center justify-content-between ml-0 mr-0">
        <div class="w-25" *ngIf="invoiceMode=='view'" >
            <button type="button" title="Previous Invoice" class="mr-2 btn-style bg-btn-grey" (click)="openNextOrPrevInvoice(invoice_no,false)" > Prev</button>
            <button type="button"  title="Next Invoice" class="btn-style bg-btn-purple"  (click)="openNextOrPrevInvoice(invoice_no,true)">Next </button>
        </div>
       <div class="w-100 d-flex justify-content-end">
        <div>
            <ng-container *ngIf="invoiceMode != 'view'"> 

                <div class="w-100 d-flex align-item-center">
                    <div class="d-flex align-items-center flex-row  mr-20" *ngIf="billOfSupplyActive === true">
                        <label for="clientBos" class="mb-0 clientRefLbl">Bill Of Supply</label>
                        <input class="ml-10" formControlName="bill_of_supply" id="clientBos" type="checkbox" style="height: 14px;"/>
                    </div>

                    <div>
                        <button *ngIf="tenantCheck" nz-button  class="btn-style mr-3 bg-denim" (click)="showModal()"><span>CryptInvoice</span></button>
                        <button type="button" class="btn-style bg-green mr-3" tabIndex="{{sectorService.lastIndexOfTraveller+5}}"
                            attr.tabIndex="{{sectorService.lastIndexOfTraveller+6}}" (click)="reset()">Reset</button>
            
                        <button type="button" class="btn-style bg-purple" (click)="submithandler(nestedForm.value)"
                            [ngClass]="{disbaledBtn: (nestedForm.invalid  || duplicateTicket) }"
                            tabIndex="{{sectorService.lastIndexOfTraveller+7}}"
                            [disabled]="invalidCredit || duplicateTicket || sectorService.travellerForm.invalid"
                            attr.tabIndex="{{sectorService.lastIndexOfTraveller+7}}" id="save_invoice_btn"> 
                            Save
                        </button>
                        <button type="button" *ngIf="paid_ssrs_types && paid_ssrs_types.length > 0" class="btn-style bg-red mr-3" tabIndex="{{sectorService.lastIndexOfTraveller+8}}"
                            attr.tabIndex="{{sectorService.lastIndexOfTraveller+8}}" (click)="openSsrModal(undefined)">
                            View Ssrs
                        </button>
                    </div>

                </div>    
               
    
            </ng-container>

<nz-modal [(nzVisible)]="isVisible" nzTitle="Create Invoice from Cryptic PNR Response" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" [nzFooter]="null" [nzWidth]="'30%'">
    
    <ng-container>
        <form class="convert-form">
        <div class="inputArea">
            <textarea class="dataInputChildBox" name="input" oninput="this.value = this.value.toUpperCase()" cols="30" rows="10" (change)="inputPnr($event.target.value)" placeholder=" 
            Paste PNR cryptic response here
            1. NIHA/YOGI HIRAN MR(ADT)
            2. QR1054 N 24NOV 5*AUHDOH HK1  2440 2340  24NOV  E  QR/KDPQQE
            3. QR1363 N 25NOV 6*DOHMPM HK1  0445 0944  25NOV  E  QR/KDPQQE"></textarea>
            <button class="dataInputChildBox button" type="submit" (click)="pnrConvert()" ><h3>Invoicify</h3><img src="https://systacc-assets.s3.ap-south-1.amazonaws.com/_assets/images/paper-plane.svg" height="48" alt="paper plane"></button>
            </div>
    </form>
    </ng-container>
    
      </nz-modal>
      <ng-container *ngIf="(!invoiceData) || invoiceData.is_consolidate!=true" >
            <ng-container *ngIf="invoiceMode == 'view' && !showErrorMessage" >
                  <!-- <button type="button" class="btn-style bg-green mr-3" tabIndex="20"
              >save</button> -->

                <div class="w-100 d-flex align-item-center">
                    <div class="d-flex align-items-center flex-row  mr-20" *ngIf="billOfSupplyActive === true">
                        <label for="clientBos" class="mb-0 clientRefLbl">Bill Of Supply</label>
                        <input class="ml-10" formControlName="bill_of_supply" id="clientBos" type="checkbox" style="height: 14px;"/>
                    </div>

                    <div>
                        <button type="button" class="btn-style bg-cyan mr-3" *hasAccess="{'key' : 'F0002013'}"
                        tabIndex="{{sectorService.lastIndexOfTraveller+4}}" attr.tabIndex="{{sectorService.lastIndexOfTraveller+4}}" 
                         [ngClass]="{disbaledBtn: nestedForm.invalid}" [disabled]="invoiceData && invoiceData.is_consolidate == true"
                         (click)="openUpdateConfirmModal()" id="update_mis_invoice_btn">{{invoiceData && invoiceData.status == 'Active' ? 'Modify' : 'Save'}}</button>
        
                         <button type="button" class="btn-style bg-red mr-3" *hasAccess="{'key' : 'F0002014'}" [disabled]="invoiceData && invoiceData.is_consolidate == true"
                        tabIndex="{{sectorService.lastIndexOfTraveller+7}}" attr.tabIndex="{{sectorService.lastIndexOfTraveller+7}}" 
                          (click)="openDeleteConfirmModal()" id="delete_invoice_btn">Delete</button>
                        
                          <ng-container *ngIf="invoiceData && invoiceData.status == 'Active'">
                            <span style="position: relative; display: inline-block;" class="mr-3">
                                <button type="button" class="btn-style bg-cyan " (click)="clickToPrintOptions()">Print</button>

                                    <span class="form-invoice-btn"  *ngIf="showPrintOptions">
                                        <button type="button" *ngIf="checkEInvoice==false || isTesoyBoult==false"
                                        tabIndex="{{sectorService.lastIndexOfTraveller+5}}" attr.tabIndex="{{sectorService.lastIndexOfTraveller+5}}" 
                                        (click)="openInvoice(invoice_no,invoiceData && invoiceData.product_type ? invoiceData.product_type : 'flight')" class="btn-style bg-denim mr-2">Invoice</button>

                                        <button type="button" *ngIf="isTenantIndian"
                                        (click)="checkWhetherEInvGeneratedOrNot(invoice_no)" class="btn-style bg-denim ">E-Invoice</button>
                                    </span>
                                    </span>
                            <button type="button" *ngIf="invoiceData && (!invoiceData.product_type || (invoiceData.product_type && invoiceData.product_type.toLowerCase() == 'flight'))"
                            tabIndex="{{sectorService.lastIndexOfTraveller+6}}" attr.tabIndex="{{sectorService.lastIndexOfTraveller+6}}" 
                            (click)="createCreditNote(invoice_no)" class="btn-style bg-blue-ivy mr-3" id="create_credit_note_btn">Credit Note</button>

                            <button type="button" class="btn-style bg-pine mr-3" *ngIf="invoiceData && invoiceData.bos_reference" 
                                (click)="openBosReference()" id="open_bos_btn">
                                {{invoiceData.bill_of_supply ? 'TAX INVOICE' : 'BILL OF SUPPLY'}}
                            </button>
                            
                                
                                <button type="button" 
                                tabIndex="{{sectorService.lastIndexOfTraveller+7}}" attr.tabIndex="{{sectorService.lastIndexOfTraveller+7}}" 
                            (click)="viewJournal(invoice_no)" class="btn-style bg-brown mr-3" id="journal_invoice_btn">Journal
                            </button>

                            <button type="button" 
                               *ngIf="isAadesh==false"
                            (click)="viewTicket(invoice_no)" class="btn-style bg-blue mr-3" id="ticket_invoice_btn">Ticket
                            </button>
                    
                            <button type="button" *ngIf="salesCurrencyPrint=='true'"
                                tabIndex="{{sectorService.lastIndexOfTraveller+8}}" attr.tabIndex="{{sectorService.lastIndexOfTraveller+8}}"  (click)="openBill(invoice_no)" class="btn-style bg-denim mr-3" id="activity_invoice_log_btn">MultiCurrency Bill
                            </button>
                                
                            <button type="button"
                                tabIndex="{{sectorService.lastIndexOfTraveller+8}}" attr.tabIndex="{{sectorService.lastIndexOfTraveller+8}}"  (click)="openActivity(invoice_no)" class="btn-style bg-indigo mr-3" id="activity_invoice_log_btn">Activity
                            </button>
                            <button type="button" class="btn-style bg-rusty-red text-white"
                                tabIndex="{{sectorService.lastIndexOfTraveller+9}}" attr.tabIndex="{{sectorService.lastIndexOfTraveller+9}}" 
                                (click)="openSendEmailModal()" id="invoice_send_mail_btn">Email</button>
                       </ng-container>
                    </div>
                </div> 

                
    
                
            </ng-container>
        </ng-container>
        </div>
      
       </div>
    </div>

    </form>
</div>


<button type="button" hidden #openConfirmDeleteModal data-toggle="modal" data-target="#openDeleteConfirmationModal"></button>

<div class="modal fade" id="openDeleteConfirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" #closeDeleteModal data-dismiss="modal" aria-label="Close" (click)="resetOpenedModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>delete {{invoice_no}}</b> ?
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
                <button type="button" class="btn-style mr-2" (click)="closeDeleteConfirmModal()">No</button>
                <button type="button" class="btn-style bg-red" (click)="deleteInvoice(invoice_no)">Yes</button>
            </div>
        </div>
    </div>
</div>

<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateConfirmationModal"></button>

<div class="modal fade" id="updateConfirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Confirmation</h4>
                <button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close" (click)="resetOpenedModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>update {{invoice_no}}</b> ?
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
                <button type="button" class="btn-style mr-2" data-dismiss="modal" (click)="closeUpdateConfirmModal()">No</button>
                <button type="button" class="btn-style bg-blue" (click)="updateProduct(nestedForm.value)" >Yes</button>
            </div>
        </div>
    </div>
</div>

<!-- Update Affiliate Warning -->
<button type="button" hidden #affiliateUpdateConfirmModal data-toggle="modal"
    data-target="#affiliateUpdateConfirmModal"></button>

<div class="modal fade" id="affiliateUpdateConfirmModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true"
    data-backdrop="false">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5>Alert !</h5>
                <button type="button" class="close" #closeUpdateModal data-dismiss="modal"
                    (click)="selectEvent(selectedLedger)" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to change affiliate?
                If Yes , all these entries will be updated from account <strong>{{selectedLedger?.name}}</strong> to
                <strong>{{currentSelectedAffiliate?.name}}</strong>
                <ul>
                    <li *ngFor="let invoice of referenceInvoiceList">
                        <a class="hyperLink" *ngIf="invoice.invoice_no != undefined"
                            (click)="viewPrintScreen(invoice.invoice_no)">{{invoice.invoice_no}}
                            <ng-container *ngIf="invoice.type == 'reissue'">(R)</ng-container>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
                <button type="button" class="btn-style mr-2" data-dismiss="modal"
                    (click)="selectEvent(selectedLedger)">No</button>
                <button type="button" class="btn-style" data-dismiss="modal"
                    (click)="selectEvent(currentSelectedAffiliate)">Yes</button>
            </div>
        </div>
    </div>
</div>

        <button type="button" hidden  #openInprogressPNR data-toggle="modal" data-target="#inprogressModal"></button >
	
		<div class="modal fade" id="inprogressModal"  role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
			<div class="modal-dialog modal-md" role="document">
			  <div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" hidden #closeInprogressPNR data-dismiss="modal" aria-label="Close">
					  <span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
				   {{message}}
				</div>
				<div class="modal-footer">
				  <!-- <button type="button" class="btn-style mr-3"   data-dismiss="modal">No</button> -->
				  <button type="button" class="btn-style" data-dismiss="modal"  (click)="ngOnInit()" >Work on new PNR</button>
				</div>
			  </div>
			</div>
		  </div>

        <button type="button" hidden  #openAddMiscellaneousInvoice data-toggle="modal" data-target="#addMiscellaneousInvoice"></button >

        <div class="modal fade" id="addMiscellaneousInvoice"  role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5>Select type of miscellaneous invoice !</h5>
                    <button type="button" class="close" #closeAddMiscellaneousInvoice data-dismiss="modal" (click)="selectEvent(selectedLedger)" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="list-unstyled ms-invoice-list">
                        <li (click)="selectCreateMsInvoice('hotel')">Hotel</li>
                        <li (click)="selectCreateMsInvoice('insurance')">Insurance</li>
                        <li (click)="selectCreateMsInvoice('visa')">Visa</li>
                        <li (click)="selectCreateMsInvoice('other')">Other</li>
                    </ul>
                </div>
                </div>
            </div>
            </div>
<!-- </div> -->

  <!-- Email Modal for sending mail -->
  <button
  hidden
  #openEmailModal
  data-target="#emailModalOfInvoice"
  data-toggle="modal"
></button>
  <div
    class="modal"
    id="emailModalOfInvoice"
    role="dialog"
    aria-labelledby="emailModalLabel"
    data-backdrop="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex justify-content-center">
            <h4 class="modal-title">Send Email</h4>
          </div>
          <button
            type=" button"
            class="close"
            #closeSendEmailModal
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body emailSenders">
          <!-- <div class="d-flex justify-content-end align-item-center">
							<span *ngIf="!ccEmail" (click)="showCCEmail()" class="text-size-14 mb-0 cursor  mr-2">Cc</span>
							<span *ngIf="!bccEmail" (click)="showBCCEmail()" class="text-size-14 mb-0 cursor">Bcc</span>
						</div> -->Invoice
          <form [formGroup]="sendEmailForm" style="margin-top: -10px">
            <div class="form-group text-input-field">
              <textarea
                class="input-field ng-pristine ng-valid ng-touched text-size-12"
                id="toEmail"
                rows="1"
                formControlName="toEmails"
                required
              ></textarea>
              <label for="To" class="mb-0">To</label>
            </div>
            <div class="d-flex justify-content-end">
              <!-- <label for="To" class="text-size-14 mb-0">To</label> -->
              <div *ngIf="sendEmailForm.controls.toEmails.invalid">
                <div
                  *ngIf="sendEmailForm.controls.toEmails.errors?.required"
                  style="color: red"
                >
                  To Email is required.
                </div>
              </div>
            </div>

            <div class="form-group text-input-field ">
              <textarea
                class="input-field ng-pristine ng-valid ng-touched text-size-12"
                id="ccEmail"
                style="max-height: 45px;"
                formControlName="ccEmails"
              ></textarea>
              <label for="Cc" class="mb-0">Cc</label>
            </div>

            <div class="form-group text-input-field">
              <textarea
                class="input-field ng-pristine ng-valid ng-touched text-size-12"
                id="bccEmail"
                style="max-height: 45px;"
                formControlName="bccEmails"
              ></textarea>
              <label for="Bcc" class="mb-0">Bcc</label>
            </div>
            <div class="text-input-field">
                <input formControlName="subject"  class="input-field text-size-12" type="text" 
                autocomplete="off" />
                <label>Subject</label>
            </div>
            <div class="form-group text-input-field">
             <textarea
               class="input-field ng-pristine ng-valid ng-touched text-size-12"
               rows="5"
               formControlName="body"
             ></textarea>
             <label for="body" class="mb-0">Mail Body</label>
             <input type="file" (change)="handleAttachment($event)" />
           </div>
            <div class="mt-3 d-flex justify-content-center align-item-center">
              <button type="button" class="btn-style mr-2" data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                class="btn-style bg-green"
                (click)="callTosendEmail()"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  


<button
hidden
#openPaidSsrModal
data-target="#paidSSrModal"
data-toggle="modal"
></button>
<div
  class="modal text-center"
  id="paidSSrModal"
  role="dialog"
  aria-labelledby="paidSSrModalLabel"
  data-backdrop="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex justify-content-center">
          <h4 class="modal-title"></h4>
        </div>
        <button
          type=" button"
          class="close"
          #closePaidSsrModal
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="paid_ssrs_types && paid_ssrs_types.length>0">
          <div class="ml-3 align-item-center justify-content-center" style="font-size: 16px;color:#007a5a">
              <h2 class="mb-0">This invoice has paid ssrs: </h2>
              <ul class="mb-0 d-flex justify-content-center ssrlist">
                 <li class="m-4" (click)="setSsrType(ssr)"  *ngFor="let ssr of paid_ssrs_types"><button style="cursor: pointer;" class="btn-style bg-green">{{ssr}}</button></li>
              </ul> 
          </div>
          <p class="ssrMessage">Preview your paid SSR invoice by clicking on the ssrs, Please save the main invoice to edit.</p>
      </div>
    </div>
  </div>
</div>

<button type="button" hidden #generateEInvoiceModal data-toggle="modal" data-target="#openGenerateEInvoiceModal"></button>

<div class="modal fade" id="openGenerateEInvoiceModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Confirmation</h4>
                <button type="button" class="close" #closeUpdateModal data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                E-invoice not generated,do you want to generate? 
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-style mr-2" (click)="closeGenerateEInvoiceModal()">No</button>
                <button type="button" class="btn-style bg-red" (click)="generateEInvoice(invoice_no)">Yes</button>
            </div>
        </div>
    </div>
</div>

<button hidden #openMasterCreationModal data-target="#masterCreationModal"data-toggle="modal"></button>

<div
  class="modal"
  id="masterCreationModal"
  role="dialog"
  aria-labelledby="masterModalLabel"
  data-backdrop="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex justify-content-center">
          <h4 class="modal-title">Create {{sectorService.currentMaster}}</h4>
        </div>
        <button
          type=" button"
          class="close"
          #closeMasterCreationModal
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
	    <app-master-creation [masterType]="sectorService.currentMaster" [masterCreationModal]="masterCreationModal" [currentMasterName]="sectorService.currentMasterName" 
		(callSetFormData)="setFormValues($event)"
		></app-master-creation>
     </div>
    </div>
</div>
