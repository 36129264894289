<div class="ledger-container ">
	<!-- <div class="heading-box mt-3 ml-2">
        <div class="heading">Day Book Report</div>
        <span class="hr-line"></span>
      </div> -->

	<div >

		<!-- <ng-container *ngIf="report != undefined"> -->
		<!-- <ng-container *ngFor="let info of report" > -->
		<div class="voucher-container">
			<!-- <div class="tab-nav d-flex align-item-center justify-content-between">
				<ul class="list-inline list-unstyled p-0 m-0">
					<li class="active page-title   text-uppercase"> Day Book</li>
				</ul>
				<ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
					<li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition">
						Dashboard
					</li>
					<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
					<li class="text-grey text-capitalize cursor text-primary transition"> View Day Book</li>
				</ul>
			</div> -->
			<div class="row tab-nav pl-0 pr-0">
				<!-- <div class="col-sm-4 pl-0">
					<ul class="list-inline list-unstyled p-0 m-0">
						<li class="active page-title text-uppercase cursor"> View Client</li>
					</ul>
				</div> -->
				<div class="col-sm-4 ">
				  <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
					  <li [routerLink]="['/']"
						  class="text-grey text-capitalize cursor  hover-text-primary transition"> Analysis</li>
					  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
					  <li class="text-grey text-capitalize cursor text-primary transition"> Day Book</li>
				  </ul>
			  </div>
				<div class="col-sm-4 text-center">
					<span class="rec-date d-block text-center">
						<div class="noWrap justify-content-around">
							<ng-container  *ngIf="duration">
								<div class="cname text-bold"><span *ngIf="duration.from_date" class="text-bold">From</span>
									<h5 style="margin-right: 5px;" class="mb-0  d-inline">
										<span class="date text-bold text-orange" *ngIf="duration.from_date"
											>
											{{duration.from_date}}
										</span>
										<span class="date text-bold" *ngIf="duration.from_date">
											To
										</span>
										<span class="date text-bold" >
											Till Date
										</span>
										<span class="date text-bold text-orange">
											{{duration.to_date}}
										</span>
									</h5>
									<button title="Custom Search" class="btn   save-as text-bold bg-whiteSmoke " 
					type="button" (click)="openAdvanceSearchModal()" >
					<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
				</button>
								</div>
							</ng-container>						
						</div>
					</span>
				</div>
				<div [ngClass]="{'d-none': isPrint == 'true'}" class="col-sm-4   d-flex align-item-center justify-content-end">
					<div
					class="page-search-box input-group d-flex align-item-center"
					style="height: fit-content; text-align: left"
				  >
					<input
					  type="text"
					  class="form-control"
					  [(ngModel)]="onSearch" oninput="this.value = this.value.toUpperCase()"
					  (ngModelChange)="this.onSearchFilter.next($event)"
					  placeholder="search"
					  style="text-align: left"
					/>
				  </div>
					<div class="dropdown-btn position-relative">
						<button title="More..." class="btn-style mt-0 more-btn">
						  <span class="dot"></span><span class="dot"></span
						  ><span class="dot"></span>
						</button>
						<ul class="list-unstyled pl-0 dropdown-box">
						  <!-- <li  (click)="openModal()">
							<img
							  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-emal.svg"
							  alt=""
							/>
							<span class="ml-2">Email</span>
		  
							<button
							hidden
							#openEmailModal
							data-target="#emailModal"
							data-toggle="modal"
						  ></button>
						  </li> -->
						  <li  (click)="downloadPdf(duration?.from_date  ,duration?.to_date)" >
							<img
							  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg"
							  alt=""
							/>
							<span class="ml-2">Save As Pdf</span>
						  </li>
						  <li  (click)="exportAsXLSX(duration?.from_date  ,duration?.to_date)" >
							<span class="ml-2">Excel</span>
						  </li>
						</ul>
					  </div>
				</div>
				<!-- <div class="col-sm-4  d-flex align-item-center justify-content-end">
					
				<span class="save-button ml-1 mt-2">
					<div class="dropdown">
						<button class="btn btn-secondary text-green dropdown-toggle" type="button"
							id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
							aria-expanded="false">
							<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-download.svg" alt="">
							<span class="ml-1 text-green">Save As</span>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
							<a class="dropdown-item"
								(click)="exportAsXLSX(daybookArr?.duration?.from_date  ,daybookArr?.duration?.to_date)">EXCEL</a>
							<a class="dropdown-item" href="#">CSV</a>
							<a class="dropdown-item"
								(click)="callPdfGeneratorApi(daybookArr?.duration?.from_date  ,daybookArr?.duration?.to_date)">PDF</a>
						</div>
					</div>
				</span>
				</div> -->
			</div>

			<div class="table-container" (window:resize)="onResize($event)">
				<div class="table" style="    margin-top: -5px;">
				
					<!-- <nz-table #dayBookData [nzData]="daybookArr"
					[nzLoading]="loader"
					[nzFrontPagination]="false"
				>
	
					<thead>
						<tr #tableHeader tabIndex="0">
							<th class="text-left" style="max-width: 170px;" title="Account Type">AC TYPE </th> -->
					<nz-table #dayBookData
			
					tabIndex="0" 
					[nzData]="daybookArr"
					[nzLoading]="loader"
					[nzFrontPagination]="false"
						>
			
					<thead>
						<tr #tableHeader tabIndex="0">
							<th style=" max-width:80px;" class="text-left">AC Type </th>
							<th style="max-width:120px" class="text-left">Date </th>
							<th style="max-width:130px" class="text-left">Account</th>
							<th class="text-left">Narration </th>
							<th style="max-width:170px" class="text-left">Reference</th>
							<th style="max-width:80px" class="text-left">Cheque No</th>
							<th style="max-width:130px" class="text-right">Debit</th>
							<th style="max-width:130px" class="text-right">Credit</th>
						</tr>
					</thead>
						<ng-container *ngIf="dayBookData && dayBookData['data'] && dayBookData['data'].length>0 else noResult">
							<tbody [ngStyle]="{height: innerHeight}" class="scroll-body main-panel" infiniteScroll
								(window:keydown)="onKeyDown($event)" [fromRoot]="fromRoot" [infiniteScrollDistance]="1"
								[infiniteScrollUpDistance]="1" [infiniteScrollThrottle]="200"
								[infiniteScrollContainer]="selector" (scrolled)="onScrollDown($event)"
								>
								<tr *ngFor="let info of dayBookData['data'] let i = index" [attr.tabIndex]="i+1"
									id="rowIndex_{{i+1}}"
									(keyup.enter)="openViewMode(info.transaction_ref_no,info.reference_type)">
	
									<td  style="max-width:80px" (click)="moveToIndex(i)" class="text-left text-grey">{{info?.master_name}} </td>

									<td style="max-width:120px" (click)="moveToIndex(i)" class="text-left text-grey">{{info?.timestamph | date:
										'dd/MM/yyyy
										HH:mm'}} </td>

									<td style="max-width:130px" (click)="moveToIndex(i)" class="text-left text-grey "><span class="ellipse-text">{{info?.name}}</span></td>

									<td  (click)="moveToIndex(i)" class="text-left text-grey" style="white-space: pre-wrap!important;">{{info?.naration}}</td>

									<td style="max-width:170px" (click)="moveToIndex(i)" class="text-left text-black refer"> <a style="text-decoration: none!important;" class=" font-500"
											[ngClass]="{'text-hyperlink': info.transaction_ref_no != null && info.transaction_ref_no != undefined}"
											(click)="openViewMode(info.transaction_ref_no,info.reference_type)">{{info?.transaction_ref_no}}</a>
									</td>

									<td style="max-width:80px" (click)="moveToIndex(i)" class="text-left text-grey"></td>

									<td style="max-width:130px" (click)="moveToIndex(i)" class="text-right text-grey debit-color font-500">{{info.typhe == 'debit' ? info.amount : 0 | number :
										'1.2-2'}}
									</td>

									<td style="max-width:130px" (click)="moveToIndex(i)" class="text-right text-grey credit-color font-500">{{info.typhe == 'credit' ? info.amount : 0 | number :
										'1.2-2'}}
									</td>
								</tr>
							   
							</tbody>
						</ng-container>
						
						<ng-template #noResult>
							<div class="w-100 text-center" style="padding: 20px;" *ngIf="!loader">
								<nz-empty nzNotFoundContent="No result Found"></nz-empty>
							</div>
						  </ng-template>
	
					</nz-table>
				</div>
			</div>
		</div>
	</div>

</div>