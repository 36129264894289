import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { throwError, of, empty } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class InvoiceRefundService {

  ledgerList: any;
	invoiceForm: any;

	constructor(
		private http: Http,
		private httpClient: HttpClient
	) { }

	getFromOkunt(url) {
		let token = localStorage.getItem('token');
		const headers = new HttpHeaders({
			'Authorization': token ? token : ''
		});
		return this.httpClient.get(`${environment.okountUrl}/${url}`, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
				catchError((err) => {
					return throwError(err);
				}))
	}

	// getRedisData(url2) {
	// 	let token = localStorage.getItem('token');
	// 	const headers = new HttpHeaders({
	// 		'Content-Type': 'application/json',
	// 		'Authorization': token ? token : ''
	// 	});
	// 	return this.httpClient.get(`${environment.url2}/${url2}`)
	// 		.pipe(map(res => {
	// 			return res;
	// 		}),
	// 			catchError((err) => {
	// 				return throwError(err);
	// 			}))
	// }

	searchLedger(url) {
		let token = localStorage.getItem('token');
		const headers = new HttpHeaders({
			'Authorization': token
		});
		return this.httpClient.get(`${environment.okountUrl}/ledger/${url}`)
			.pipe(map(res => {
				// console.log(res);
				return res;
				// console.log('Res data', res);
			}),
				catchError((err) => {
					return throwError(err);
				}))
	}
	searchAccount(url) {
		let token = localStorage.getItem('token');
		const headers = new HttpHeaders({
			'Authorization': token
		});
		return this.httpClient.get(`${environment.okountUrl}/${url}`)
			.pipe(map(res => {
				// console.log(res);
				return res;
				// console.log('Res data', res);
			}),
				catchError((err) => {
					return throwError(err);
				}))
	}

	getAirlineInfo(val) {

		let token = localStorage.getItem('token');
		const headers = new HttpHeaders({
			'Authorization': token ? token : ''
		});
		return this.httpClient.get(`${environment.IBEurl}/air/suggest?in=${val}`, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
				catchError((err) => {
					return throwError(err);
				}))
	}

	retrievePNR(url) {
		let token = localStorage.getItem('token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.get(`${environment.pnrRetrieveUrls.base}${url}`, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
				catchError((err) => {
					return throwError(err);
				}))
	}

	postMethod(url, data) {
		let token = localStorage.getItem('token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.post(`${environment.okountUrl}/${url}`, data, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
				catchError((err) => {
					return throwError(err);
				}))
	}
	getMethod(url) {
		let token = localStorage.getItem('token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.get(`${environment.okountUrl}/${url}`, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
				catchError((err) => {
					return throwError(err);
				}))
	}


}
