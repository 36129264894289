import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountBookComponent } from './account-book.component';
import { LedgerComponent } from './ledger/ledger.component';


const routes: Routes = [
  {path: '', component: AccountBookComponent,
      children:[
      {path: 'ledger',loadChildren: './ledger/ledger.module#LedgerModule'},
      {
        path: '',
        redirectTo: 'ledger',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountBookRoutingModule { }
