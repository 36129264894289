import { Component, OnInit, HostListener } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DownloadService } from 'src/app/shared/download.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-summary-ledger',
  templateUrl: './summary-ledger.component.html',
  styleUrls: ['./summary-ledger.component.scss']
})
export class SummaryLedgerComponent implements OnInit {
	isPrint:any;
  p=0;
	q=0;
	leaderData: any;
	navigationSubscription;
	reInitialise: boolean;
	currentDate = moment().format('YYYY-MM-DD')
	currentTime = moment().format('HH:mm:ss');
	innerHeight: string;
	isOutStandingSummary = false
	constructor(
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private router: Router,
		private spinner : NgxSpinnerService,
		private downloadService : DownloadService
	) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	}

	ngOnInit() {

		let queryParams = this.route.queryParams['value']
		if(queryParams && queryParams.account_type && queryParams.account_type.length>0){
			this.isOutStandingSummary = true
		}else{
			if (queryParams.client != undefined) {
				localStorage.setItem('activeLedgerQueryParams',JSON.stringify(queryParams))
				this.isPrint = queryParams.print
				this.getFilteredData(queryParams)
			} else {
				this.getData()
			}
			this.isOutStandingSummary = false
		}
		
		this.reInitialise = true

	}
	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}

	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.leaderData != undefined) {
			this.setHeight(this.p,this.q,event.target.innerHeight)
		}
	}


	//ALL DATA
	getData() {
		this.commonService.getRequest(`${environment.okountUrl}/reports/ledger`).subscribe(res => {
			this.leaderData = res
			if (this.leaderData != undefined) {
				this.setHeight(0,0,window.innerHeight)
			}
			
		}, err => {
			console.error(err)
		})
	}

	setHeight(pIndex,innerPIndex,height){
		if (height == undefined) {
			height = window.innerHeight
		}
		pIndex = Number(pIndex)
		innerPIndex = Number(innerPIndex)
		this.p = pIndex
		this.q = innerPIndex

		let parent = 0
		if (pIndex > 0) {
			parent = pIndex - 1
		}
		let child = 0
		if (innerPIndex > 0) {
			child = innerPIndex - 1
		}
		if (this.leaderData['all_clients_records'].length > 0) {
			if (this.leaderData['all_clients_records'][parent].client_records.length > 0) {
				if (this.leaderData['all_clients_records'][parent].client_records[child].records.length <= 3) {
					this.innerHeight = 'auto';
				}
				else {
					this.innerHeight = height - 250 + 'px';
				}
			}
			else {
				this.innerHeight = 'auto';
			}
		}
		else {
			this.innerHeight = 'auto';
		}
	}

	//FILTERED DATA
	getFilteredData(queryParams) {
		this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/ledger`, queryParams).subscribe(res => {
			this.leaderData = res
			if (this.leaderData != undefined) {
				this.setHeight(0,0,window.innerHeight)
			}
		}, err => {
			console.error(err);
		})
	}
	exportAsXLSX(fromDate, toDate):void {
		this.spinner.show()
		let xlsxData = [];
		this.leaderData['all_clients_records'].forEach(data => {
			data['client_records'].forEach(client_record => {
				client_record['records'].forEach(records => {
					let obj = {};
					obj["DATE"] = `${moment(records.date).format('DD/MM/YYYY')}`;
					obj["REFERENCE"] = records.reference;
					obj["BALANCE TYPE"] = client_record.aggregated_display_name;
					obj["NARRATION"] = records.narration;
					obj["DEBIT"] = records.amount_debit ? Number(Number(records.amount_debit).toFixed(2)) : "";
					obj["CREDIT"] = records.amount_credit ? Number(Number(records.amount_credit).toFixed(2)) : "";
					obj['BALANCE'] = records.balance ? Number(Number(records.balance).toFixed(2)) : "";
					xlsxData.push(obj);
				
				});
			});
		});

		xlsxData.push({},{},{"DATE":`FROM ${fromDate} TO ${toDate} CLIENT: ${this.leaderData.all_clients_records[0].display_name}`})

		this.downloadService.exportAsXLSX(`SUMMARY LEDGER ${fromDate} - ${toDate}`, xlsxData);
		this.spinner.hide()
	}


	callPdfGeneratorApi(fromDate, toDate){
		window.scrollTo(0,0)
		this.spinner.show()
		let url = 'print-pdf/pdf-summary-ledger'
		let queryString = location.hash.split('?')[1]
		if (queryString != undefined) {
			url = `${url}?${queryString}`
		}
		this.downloadService.callPdfGeneratorApi(`SUMMARY LEDGER ${fromDate} - ${toDate}.pdf`,url,{})	
		this.spinner.hide()
		
	}

	openViewMode(reference, referenceType) {
		if (reference != undefined) {
			let url = '#/transactions/'
			let viewMode = 'view'
			if (referenceType == 'invoice') {
				url = '#/transactions/invoice'
				viewMode = 'view'
			}
			if (referenceType == 'invoice' && (reference.includes('MIN') || reference.includes('MZC'))) {
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
			}
			if (referenceType == 'invoice' && (reference.includes('GSP') || reference.includes('GSS'))) {
				url = '#/transactions/ms/gst-invoice'
				viewMode = 'view'
			}
			if (referenceType == 'credit_note' && ( reference.includes('MZC'))) {
				url = '#/transactions/ms/ms-invoice'
				viewMode = 'view'
			}
			if (referenceType == 'credit_note') {
				url = '#/transactions/credit-note'
				viewMode = 'view'
			}
			if (referenceType == 'payment') {
				url = '#/transactions/payment'
				viewMode = 'view'
			}
			if (referenceType == 'receipt') {
				url = '#/transactions/receipt'
				viewMode = 'view'
			}
			if (referenceType == 'jv') {
				url = '#/transactions/jv'
				viewMode = 'update'
			}
			if (referenceType == 'adm' || referenceType == 'acm') {
				url = '#/transactions/adm-acm'
				viewMode = 'view'
			}
			let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${url}/${viewMode}/${reference}`
			if(window.location.protocol != 'file:'){
			window.open(path, "_blank");
			}
			else{
				var customeEventHandler = new CustomEvent("handle-window-open", {
					"detail": {
						hashPath : path
					}
				  });
				window.dispatchEvent(customeEventHandler)
			}
		}
	}


}
