
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CookiesService } from 'src/app/shared/cookies.service';

@Component({
  selector: 'app-create-airline',
  templateUrl: './create-airline.component.html',
  styleUrls: ['./create-airline.component.scss']
})
export class CreateAirlineComponent implements OnInit {

	submitted = false;
	baseUrl = environment.okountUrl;
	airlineForm: FormGroup;
	groupList: any = [];
	showMessage: boolean = false;
	alertType = '';
	message: string = ``;
	countryArr: any = [];
	stateArr: any = [];
	cityArr: any = [];
	createdAirlineId = ""
	createdAirlineCode = ""
	newAirline : boolean = true
	isTenantIndian: boolean = false;
	isHQBranch : boolean = false;
	remainingAmount = 0
    amountType = 'credit'
	vatAlternetname: string = 'VAT'

	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef
	@ViewChild("openSuccessModal", { static: true }) openSuccessModal: ElementRef;
	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef;
    @ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef;
	constructor(public commonService: CommonServiceService, private fb: FormBuilder, private spinner: NgxSpinnerService, private router: Router,private cookieService : CookiesService) { }

	async ngOnInit() {
		
		this.getGroups()
		this.spinner.show();
		setTimeout(() => {
			this.spinner.hide();
		}, 1000);

		this.airlineForm = this.fb.group({

			parent_id: ['30', Validators.required],
			account_name: ['', [Validators.required]],
			address_1: [''],
			city_id : [''],
			city_name : [''],
			state_id : [''],
			state_name : [''],
			country_id : ['101'],
			country : [''],
			telephone: [''],
			mobile_no: ['', ],
			primary_email: [''],
			secondary_email: [''],
			pan_number: ['', ],
			gst_number: ['', ],
			vat_number: [''],
			remarks: [''],
			balance: [''],
			balance_type: ['debit'],
			"2_ltr_code": [''],
			"3_ltr_code": [''],
			sync_in_branches: ['']
		})

		this.getCountries()
		this.getStates('101')

		
		this.isTenantIndian = this.commonService.checkTenantIndian()
		if(!this.isTenantIndian){
			let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
			let inv_config = globalSettingConfig.filter(el=>el.group=='VAT')
		if(inv_config.length){
			let vat_config = inv_config[0].json_doc.value.filter(el=>el.key=='vat_rate')
			let vat_detail,vat_name
			if(vat_config.length){
				 vat_detail = vat_config[0].value
			}
			if(vat_detail && vat_detail.length ){
				vat_name = vat_detail.filter(el=>el.key=='effective_name')
			}
			if(vat_name && vat_name.length){
				this.vatAlternetname = vat_name[0].value
			}
		}
		}
		
		this.isHQBranch = this.commonService.checkHQBranch()
		

	}

	openConfirmModal() :  void{
		this.confirmModal.nativeElement.click()
	}
	closeConfirmModal() :  void{
		this.closeModal.nativeElement.click()
	}

	getCountries() {
		this.commonService.getOkount(`location/country`).subscribe(res=>{
			this.countryArr = res
		})
	}

	getStates(country_id){
		let selectedCountry = this.countryArr.filter(option => {
			return option.location_id == Number(country_id);
		})
		if (selectedCountry.length > 0) {
			this.airlineForm.controls.country.setValue(selectedCountry[0].name)
		}else{
			this.airlineForm.controls.country.setValue('India')
		}
		console.log(selectedCountry)
		this.commonService.getOkount(`location/states?country_id=${country_id}`).subscribe(res=>{
			this.stateArr = res
		})
	}

	getCities(state_id){
		let selectedState = this.stateArr.filter(option => {
			return option.location_id == Number(state_id);
		})
		if (selectedState.length > 0) {
			this.airlineForm.controls.state_name.setValue(selectedState[0].name)
		}
		this.commonService.getOkount(`location/city?state_id=${state_id}`).subscribe(res=>{
			this.cityArr = res
		})
	}
	getCityName(city_id){
		let selectedCity = this.cityArr.filter(option => {
			return option.location_id == Number(city_id);
		})
		if (selectedCity.length > 0) {
			this.airlineForm.controls.city_name.setValue(selectedCity[0].name)
		}
	}

	reset() {
		this.airlineForm.reset();
		this.airlineForm.controls.parent_id.setValue('30')
		this.airlineForm.controls.country_id.setValue('101')
		this.airlineForm.controls.balance_type.setValue('debit')
	  }
	  

	
	submit() {

		this.submitted = true;
		this.hideError()
		if (this.airlineForm.valid) {
			console.log('form is working')
			this.commonService.postRequest(`${this.baseUrl}/airline`, this.airlineForm.value).subscribe(res => {
				this.updateOpeningBalanceAdjustAcc()
				this.showMessage = false
				this.createdAirlineId = res[0].id ? res[0].id : ""
				this.createdAirlineCode = res[0].account_code ?  res[0].account_code : ''
				this.alertType = 'success-box';
				this.message = 'Airline created successfully with account code. :'
				this.newAirline = false
				this.openSuccessModal.nativeElement.click()
				this.reset()
				this.closeConfirmModal()
			}, error => {
				this.closeConfirmModal()
				this.showMessage = true
				this.alertType = 'error-box';
				this.createdAirlineId = ""
				this.message = 'Failed to create Airline. Please try Again.'
			})
		}
		else {
			this.closeConfirmModal()
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = 'Please fill mandatory fields.'
			return false;
		}
	}


	getGroups() {
		this.commonService.getOkount('v3account/getallsuperparents').subscribe(res => {
			this.groupList = res
			console.log('Group list : ', this.groupList)
		}, err => {

		})
	}
	hideError() {
		this.showMessage = false
	}

	closeSuccesModal(){
		this.reset()
		this.showMessage = false;
		this.alertType = 'success-box';
		this.message = ``
		this.newAirline = true
		this.openSuccessModal.nativeElement.click()
	}

	viewAirline(airline_id) {
		this.closeSuccesModal()
        this.router.navigate([`/master/airline/update-airline/${airline_id}`])
	}

	async checkBalance(){
        let opening_amount = await  this.getOpeningBalanceData()
        if(this.airlineForm.controls.balance_type.value=='debit') opening_amount['debit']= Number(opening_amount['debit']) + Number(this.airlineForm.controls.balance.value)
        if(this.airlineForm.controls.balance_type.value=='credit') opening_amount['credit']= Number(opening_amount['credit']) + Number(this.airlineForm.controls.balance.value)
            console.log("opening_amount",opening_amount)
        if(opening_amount && (opening_amount['debit']!=opening_amount['credit'])) {
            this.remainingAmount = Math.abs(Number((opening_amount['debit'] - opening_amount['credit']).toFixed(2)))
            this.amountType = opening_amount['debit']>opening_amount['credit'] ? 'credit' : 'debit'
			let res = await this.adjustAccOpeningBalance()
            if(res['balance']!=this.remainingAmount || this.amountType!=res['balance_type']){
                this.openUpdateConfirmModal()
			}else{
				this.updateOpeningBalancecombine()
			}
        }else{
            this.updateOpeningBalancecombine()
        }
    }

	adjustAccOpeningBalance(){
        return new Promise(async (resolve, reject) => {
        this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/globalsearch/opening_balance`, {client: 'OB0000165'}).subscribe(res => {
            resolve(res)
        },err=>{
            console.log("err",err)
            resolve(null)
        })
    })
    }
	getOpeningBalanceData(){
        return new Promise(async (resolve, reject) => {
            this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/openingBalanceTotal`, {}).subscribe(res => {
                resolve(res)
            },err=> {
                resolve(null)
            })
        })
    }
	openUpdateConfirmModal(){
		this.confirmUpdateModal.nativeElement.click()
	}
	updateOpeningBalancecombine(){
        this.closeUpdateConfirmModal()
        this.submit()
        
    }
	closeUpdateConfirmModal(){
		this.closeUpdateModal.nativeElement.click()
	}
	updateOpeningBalanceAdjustAcc() {
        let req_body = {
            balance: this.remainingAmount,
            balance_type: this.amountType,
            account_code: 'OB0000165'
        }
        this.commonService.putRequest(`${environment.okountUrl}/client/updateOpeningBalanceAdjust`, req_body).subscribe(res => {
           
            console.log(res)
        }, err => {
        })

    }

}

