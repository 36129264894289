<div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
	<div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
	  <div class="d-flex flex-y-center">
		<img
		  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
			alertType=='success-box' ? 'green-sucess.svg' : 
			alertType=='warning-box' ? 'red-warning.svg' : 
			null }}"
		  width="20px"
		  height="20px"
		  alt="alert-logo"
		  class="mr-20"
		/>
		<div class="ml-10">
		  <h4 class="m-0 text-size-18 text-bold"
		  [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
			{{alertType === "error-box"
			? "Error"
			: alertType === "warning-box"
			? "Warning"
			: alertType === "success-box"
			? "Success"
			: null}}
		  </h4>
		  <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
		   {{message}}
		  </small>
		</div>
	  </div>
	  <img
		src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
		alt="close-btn"
		(click)="hideError()"
		className=" close-btn"
	  />
	</div>
  </div>

<div class="ledger-container ">
  <div class="gst-heading">
      <!-- <div class="tab-nav d-flex align-item-center justify-content-between">
          <ul class="list-inline list-unstyled p-0 m-0">
              <li class="active page-title   text-uppercase"> GST Report</li>
          </ul>
          <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
              <li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition">
                  Dashboard
              </li>
              <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
              <li class="text-grey text-capitalize cursor text-primary transition"> View GST Report</li>
          </ul>
      </div> -->
      <div class="row tab-nav pl-0 pr-0">
          <!-- <div class="col-sm-4 pl-0">
                        <ul class="list-inline list-unstyled p-0 m-0">
                            <li class="active page-title text-uppercase cursor"> View Client</li>
                        </ul>
                    </div> -->
          <div class="col-sm-4">
            <ul
              class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0"
            >
              <li
                [routerLink]="['/']"
                class="text-grey text-capitalize cursor hover-text-primary transition"
                [ngClass]="{ 'd-none': isPrint == 'true' }"
              >
                Analysis
              </li>
              <li>
                <img
                  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg"
                  alt=""
                  [ngClass]="{ 'd-none': isPrint == 'true' }"
                />
              </li>
              <li
                class="text-grey text-capitalize cursor text-primary transition"
                [ngClass]="{ 'd-none': isPrint == 'false' }"
                [ngClass]="{ 'd-none': isPrint == 'true' }"
              >
                GST Report -
              </li>
              <!-- <li class="text-primary line-clamp line-clamp-1">
                <ng-container
                  *ngFor="let data of leaderData['all_clients_records']"
                >
                  <span class="text-bold">
                    {{ data["display_name"] }}
                  </span>
                </ng-container>
              </li> -->
            </ul>
          </div>
          <div class="col-sm-4 text-center">
            <span class="rec-date mt-10 d-block text-center">
              <div class="noWrap justify-content-around">
                <ng-container>
                  <div class="cname text-bold" *ngIf="duration">
                    <span *ngIf="duration.from_date" class="text-bold"
                      >From</span
                    >
                    <h5 style="margin-right: 5px" class="mb-0 d-inline">
                      <span
                        class="date text-bold text-orange"
                        *ngIf="duration.from_date"
                      >
                        {{ duration.from_date }}
                      </span>
                      <span class="date text-bold" *ngIf="duration.from_date">
                        To
                      </span>
                      <span class="date text-bold"> Till Date </span>
                      <span class="date text-bold text-orange">
                        {{ duration.to_date }}
                      </span>
                    </h5>
                    <button
                      [ngClass]="{ 'd-none': isPrint == 'true' }"
                      title="Custom Search"
                      class="btn save-as text-bold bg-whiteSmoke"
                      type="button"
                      (click)="openAdvanceSearchModal()"
                    >
                      <img
                        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg"
                        alt=""
                        width="15px"
                      />
                    </button>
                  </div>
                </ng-container>
              </div>
            </span>
          </div>
          <div class="col-sm-4 d-flex align-item-center justify-content-end">
            <div
            class="page-search-box input-group d-flex align-item-center" 
      [ngClass]="{ 'd-none': isPrint == 'true' || activePage =='docs' }"
            style="height: fit-content; text-align: left"
          >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="onSearch"
              oninput="this.value = this.value.toUpperCase()"
              (ngModelChange)="this.onSearchFilter.next($event)"
              placeholder="search..."
              style="text-align: left"
          />
            <!-- <div class="save-button">
                                  <button title="Search Receipts..."
                                      class="btn mr-1  save-as text-bold bg-whiteSmoke text-green dropdown-toggle" type="button"
                                      (click)="openModal()">
                                      <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/purple-magnifying-glass.svg" alt="">
                    
                                  </button>
                    
                              </div> -->
          </div>
            <!-- <div class="save-button d-flex pull-right mt-2 align-items-center">
             
              <button
                title="Send Emails"
                class="btn mr-1 save-as text-bold bg-whiteSmoke text-green dropdown-toggle"
                type="button"
                (click)="openModal()"
              >
                <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/blue-email.svg" alt="" />
                <span class="ml-1 text-blue">Email To</span>
              </button>
              <button
                hidden
                #openEmailModal
                data-target="#emailModal"
                data-toggle="modal"
              ></button>
              <div class="dropdown">
                <button
                  title="Download As"
                  class="btn save-as text-green dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-download.svg"
                    alt=""
                  />
                  <span class="ml-1 text-green">Save As</span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item"
                    (click)="
                      exportAsXLSX(
                        leaderData?.duration?.from_date,
                        leaderData?.duration?.to_date
                      )
                    "
                    >EXCEL</a
                  >
                  <a class="dropdown-item" href="#">CSV</a>
                  <a
                    class="dropdown-item"
                    (click)="
                      callPdfGeneratorApi(
                        leaderData?.duration?.from_date,
                        leaderData?.duration?.to_date
                      )
                    "
                    >PDF</a
                  >
                </div>
              </div>
            </div> -->
            <div class="dropdown-btn position-relative" 
            [ngClass]="{ 'd-none': isPrint == 'true' }">
              <button title="More..." class="btn-style mt-0 more-btn">
                <span class="dot"></span><span class="dot"></span
                ><span class="dot"></span>
              </button>
              <ul class="list-unstyled pl-0 dropdown-box">
                <li>
                  <img
                    src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/black-outline-pdf.svg"
                    alt=""
                  />
                  <span class="ml-2">Save As Pdf</span>
                </li>

                <li (click)="
                exportAsXLSXGST('1')
                    " >
                 
                  <span  class="ml-2">Excel v1</span>
                </li>
                <li (click)="
                exportAsXLSXGST('2')
                    " >
                 
                  <span  class="ml-2">Excel v2</span>
                </li>
                <li (click)="
                  downloadGDFile()
                    " >
                 
                  <span  class="ml-2">GSTR1 (Portal Compliant)</span>
                </li>

                <li (click)="
                  download3bFile()
                    " >
                  <span  class="ml-2">GST PURCHASE INVOICES</span>
              </li>

                <li (click)="
                  downloadB3GDFile()
                    " >
                
                  <span  class="ml-2">GSTR B3</span>
                </li>

                <li (click)="
                  downloadData()
                    " >
                 
                  <span  class="ml-2">GST DATA</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div *ngIf="isError==true" class="page-sub-header page-header-tabs row w-100 mb-1 justify-content-between mt-3 bg-white">
          <div class="col-sm-10">
          <h5   style="margin-right: 5px" class="mb-0 d-inline">
            <span
              class="date text-bold col-sm-4 text-center">
            Message: GSTR1 Download will be disabled ! Please fix transactions highlighted in red.
            </span>
            </h5>
            <button type="button" title="Reload"
                      class="btn save-as text-bold bg-whiteSmoke"
                      type="button"
                      (click)="reloadPage()"
                    >
                      <img
                        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/purple-refresh-icon.svg"
                        alt=""
                        width="15px"
                      />
            </button>
          </div>
        </div>
      <!-- <div class="page-sub-header page-header-tabs row w-100 mb-1 justify-content-between mt-3 bg-white">
          <div class="col-sm-4">
              <ul class="nav nav-tabs   pr-5 " style="margin-left:15px" role="tablist">
                  <li class="nav-item">
                      <a class="nav-link" [ngClass]="{active: activePage == 'b2b'}" data-toggle="tab"
                          (click)="openPage('b2b','b2b')" role="tab">B2B </a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" [ngClass]="{active: activePage == 'b2cs'}" data-toggle="tab"
                          (click)="openPage('b2cs','b2cs')" role="tab">B2CS </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" [ngClass]="{active: activePage == 'cdnr'}" data-toggle="tab"
                        (click)="openPage('b2b','cdnr')" role="tab">CDNR </a>
                </li>
                  <li class="nav-item">
                      <a class="nav-link" [ngClass]="{active: activePage == 'hsn'}" data-toggle="tab"
                          (click)="openPage('hsn','hsn')" role="tab">HSN </a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" [ngClass]="{active: activePage == 'docs'}" data-toggle="tab"
                          (click)="openPage('docs','docs')" role="tab">DOCS </a>
                  </li>
                 
              </ul>
          </div>
      </div> -->

  </div>
</div>
<ng-container *ngIf="activePage == 'all'">
<app-b2b-report [isError]="isError" [gstArr]="gstB2BArr" [loader]="loader" (callOnScrollDownB2bGst)="onScrollDownB2B()"></app-b2b-report>
</ng-container>
<ng-container *ngIf="activePage == 'cdnr'">
<app-cdnr-report [gstArr]="gstCdnrArr" [loader]="loader" (callOnScrollDowncdnrGst)="onScrollDownCDNR()"></app-cdnr-report>
</ng-container>
<ng-container *ngIf="activePage == 'b2cs'">
<app-b2cs-report [gstArr]="gstB2CSArr" [loader]="loader"></app-b2cs-report>
</ng-container>
<ng-container *ngIf="activePage == 'hsn'">
<app-hsn-report [gstArr]="gstHSNArr" [loader]="loader"></app-hsn-report>
</ng-container>
<ng-container *ngIf="activePage == 'docs'">
<app-docs-report [gstArr]="gstDOCSArr" [loader]="loader"></app-docs-report>
</ng-container>
<!-- <router-outlet></router-outlet> -->