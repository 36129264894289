<div *ngIf="showMessage" class="status-modal-box  transition delay scale-1"
	[ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
	<div
		class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
		<div class="d-flex flex-y-center">
			<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
			alertType=='success-box' ? 'green-sucess.svg' : 
			alertType=='warning-box' ? 'red-warning.svg' : 
			null }}" width="20px" height="20px" alt="alert-logo" class="mr-20" />
			<div class="ml-10">
				<h4 class="m-0 text-size-18 text-bold"
					[ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
					{{alertType === "error-box"
					? "Error"
					: alertType === "warning-box"
					? "Warning"
					: alertType === "success-box"
					? "Success"
					: null}}
				</h4>
				<small className="text-dark-grey-color text-size-12 mt-5 mb-0">
					{{message}}
				</small>
			</div>
		</div>
		<img src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg" alt="close-btn" (click)="hideError()"
			className=" close-btn" />
	</div>
</div>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>


<button type="button" hidden #openSuccessModal data-toggle="modal" data-target="#successModal"></button>

<!-- Modal -->
<div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
	<div class="modal-dialog transaction-creation-modal">
		<!-- Modal content-->
		<div class="modal-content">
			<div class="modal-header">
				<span class="messageFont">
					<span>
						{{message}}
					</span>
					<a *ngIf="invoice_number != ''" href="javascript:void(0)" (click)="openVoucher(invoice_number)">
						{{invoice_number}}
					</a>
					<a *ngIf="invoice_number != ''" [title]="copyELementTitle" (click)="copytext(invoice_number)">
						<i class="fa fa-copy cursor-pointer"></i>
					</a>
				</span>
				<button type="button" class="close" (click)="viewInvoice(invoice_number)">&times;</button>
			</div>
			<div class="modal-body">

				<p>Do you want to create new invoice or view newly created Invoice ?</p>
			</div>
			<div class="modal-footer justify-content-center">
				<button tabIndex="1" tabindex="1" type="button" class="btn btn-primary bg-blue"
					(click)="viewInvoice(invoice_number)">
					View
				</button>
				<button tabIndex="2" tabindex="2" type="button" class="btn btn-primary btn-grey"
					(click)="openVoucher(invoice_number)">
					Print
				</button>
				<button tabIndex="3" tabindex="3" type="button" class="btn btn-primary bg-green"
					(click)="createNew()">
					Create New
				</button>
			</div>
		</div>

	</div>
</div>



<form [formGroup]="productService.invoiceForm" [ngClass]="{'d-none': !newInvoice}" class="ledger-container ">
	<div class="row tab-nav pl-0 pr-0">
		<!-- <div class="col-sm-4 pl-0">
			<ul class="list-inline list-unstyled p-0 m-0">
				<li class="active page-title text-uppercase cursor"> View Client</li>
			</ul>
		</div> -->
		<div class="col-sm-12 ">
			<ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
				<li class="text-grey text-capitalize cursor text-primary transition">Consolidate</li>
				<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-right.svg" alt=""></li>
				<li class="text-grey text-capitalize cursor  hover-text-primary transition"> {{productService.consolidationType == 'inv' ? 'Invoice' : productService.consolidationType}}</li>
				<li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-right.svg" alt=""></li>
				<li class="text-grey text-capitalize cursor  hover-text-primary transition"> {{transactionType}}</li>
			</ul>
		</div>
	</div>




	<div class="pl-15 pr-15 custom-form form-container w-100 mb-4">
		<div class=" mb-0 mt-2 mb-fix tourInvoiceServiceContainer">
			<div class="invoice-container invoice-table">
				<div class="memo-tbl-container">
					<table width="100%" cellpadding="0" cellspacing="0" border="0" class=" table table-hovered table-bordered top-miscellaneous-bar w-100">
						<thead>
							<tr>
								<th style="text-align: left;">Memo Account</th>
								<th style="text-align: left;">Main Account</th>
								<!-- <th>General Ledger</th> -->
								<!-- <th style="width: 100px;">Sub Ledger Type</th>
							<th>Sub-ledger</th> -->
							<th style="width: 100px;">Invoice Date</th>
							<th style="width: 100px;">START Date</th>
							<th style="width: 100px;">END Date</th>
								<!-- <th style="width: 140px;" *ngIf="!isTenantIndian">Purchase currency</th>
							<th style="width: 40px;" *ngIf="!isTenantIndian">Rate({{currencyCode}}-{{productService.invoiceForm.value.purchase_currency}})</th> -->
								<th style="width: 150px;"
									*ngIf="productService.invoiceForm.value?.reference_invoice_number">Reference</th>
								<th style="width: 150px;">Invoice Number</th>
								<th style="width: 150px;">Employee</th>
							</tr>
						</thead>
						<tbody class="consolidate-list">
							<tr>
								<!-- client -->
								<td style="text-align: left; width: 200px;">
									<div class="autocomplete-container">
										<div class="input-container" (focus)="focusClientAc()">
											<input tabIndex="5" tabindex="5" type="text" #clientAC
												oninput="this.value = this.value.toUpperCase()"
												class="autocomplete-container" name="client_code_name"
												formControlName="client_code_name"
												(input)="clientSearch($event,true, true)"
												[nzAutocomplete]="accountCodeAuto"  readonly 
												disabled
												/>

											<nz-autocomplete #accountCodeAuto [nzBackfill]="true"
												(selectionChange)="selectAccount($event,'client_code', null, true)">
												<nz-auto-option class="colr-1" *ngFor="let item of bookingClientArr"
													[nzValue]="item" [nzLabel]="item.name">
													<a
														innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>
												</nz-auto-option>
												<nz-auto-option
													*ngIf="showCreateButton && currentFormControlName == 'client_code'"
													class="colr-1" nzValue="" style="text-align: center"
													nzLabel="Create New">
													<span>Create New</span>
												</nz-auto-option>
											</nz-autocomplete>
										</div>
									</div>

								</td>
								<td style="text-align: left; width: 200px;">
									<div class="autocomplete-container">
										<div class="input-container" (focus)="focusClientAc()">
											<input tabIndex="5" tabindex="5" type="text" #clientAC
												oninput="this.value = this.value.toUpperCase()"
												class="autocomplete-container" name="client_main_code_name"
												formControlName="client_main_code_name"
												(input)="clientSearch($event,true, true)"
												[nzAutocomplete]="accountCodeAuto"  readonly disabled/>

											<nz-autocomplete #accountCodeAuto [nzBackfill]="true"
												(selectionChange)="selectAccount($event,'client_main_code', null, true)">
												<nz-auto-option class="colr-1" *ngFor="let item of bookingClientArr"
													[nzValue]="item" [nzLabel]="item.name">
													<a
														innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>
												</nz-auto-option>
												<nz-auto-option
													*ngIf="showCreateButton && currentFormControlName == 'client_code'"
													class="colr-1" nzValue="" style="text-align: center"
													nzLabel="Create New">
													<span>Create New</span>
												</nz-auto-option>
											</nz-autocomplete>
										</div>
									</div>

								</td>

								<!-- <td (focus)="focusGeneralLedgerAc()" style="text-align: left; width: 200px;">


									<div class="autocomplete-container">
										<div class="input-container" (focus)="focusGeneralLedgerAc()">
											<input tabIndex="6" tabindex="6" type="text" #generalLedgerAc
												oninput="this.value = this.value.toUpperCase()"
												class="autocomplete-container" name="general_ledger_code_name"
												formControlName="general_ledger_code_name"
												(input)="generalSearch($event,false,true, true)"
												[nzAutocomplete]="generalLedgerAutocomplete" />

											<nz-autocomplete #generalLedgerAutocomplete [nzBackfill]="true"
												(selectionChange)="selectAccount($event,'general_ledger_code', null, true)">
												<nz-auto-option class="colr-1"
													*ngFor="let item of bookingGenerlaAccountArr" [nzValue]="item"
													[nzLabel]="item.name">
													<a
														innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>
												</nz-auto-option>
												<nz-auto-option
													*ngIf="showCreateButton && currentFormControlName == 'general_ledger_code_name'"
													class="colr-1" nzValue="" style="text-align: center"
													nzLabel="Create New">
													<span>Create New</span>
												</nz-auto-option>
											</nz-autocomplete>
										</div>
									</div>


								</td> -->
								<!-- <td>
								<select class="inner-select" tabIndex="7" tabindex="7" formControlName="subledger_type">
									<option value="">Select</option>
									<option value="client">Client</option>
									<option value="airline">Airline</option>
									<option value="supplier">Supplier</option>
									<option value="employee">Employee</option>
									<option value="general">General</option>
								</select>
							</td>
							<td style="text-align: left;
							width: 135px;"  >
								
	
								<div class="autocomplete-container" >
									<div class="input-container"  (focus)="focusSubledgerAc()">
										<input tabIndex="8" tabindex="8"
											type="text" #subLedgerAC oninput="this.value = this.value.toUpperCase()" 
											class="autocomplete-container" name="subledger_code_name" 
											formControlName="subledger_code_name"
											(input)="ledgerSearch($event, true)"
											[nzAutocomplete]="subLedgerAutocomplete"
										/>
				
										<nz-autocomplete #subLedgerAutocomplete [nzBackfill]="true"  (selectionChange)="selectAccount($event,'subledger_code', null, true)">
											<nz-auto-option  class="colr-1" *ngFor="let item of productService.ledgerList" [nzValue]="item" [nzLabel]="item.name">
												<a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
											</nz-auto-option>   
											<nz-auto-option *ngIf="showCreateButton && currentFormControlName == 'subledger_code'" class="colr-1" nzValue="" style="text-align: center" nzLabel="Create New">
												<span>Create New</span>                          
											</nz-auto-option>                          
										</nz-autocomplete>
									</div>	
								</div>

							</td> -->

								<!-- <td>
								<input tabIndex="9" tabindex="9" style="min-width: max-content; padding: 0;"
									min="{{minDate}}" 
									max="{{maxDate}}"
									class="input-field" type="date" formControlName="invoice_date"
									(blur)="setServiceDate($event)"
									placeholder="MM/DD/YYYY" autocomplete="off" 
									[ngClass]="{'error-box': productService.invoiceForm.controls.invoice_date.dirty && !productService.invoiceForm.controls.invoice_date.valid}"/>
							</td>
							<td *ngIf="!isTenantIndian">
								<select id="flightType" tabIndex="10" tabindex="10" class="input-ui inv-date-input" formControlName="purchase_currency">
									<option value="">Select</option>
									<option value="AED">AED</option>
									<option value="USD">USD</option>
									<option value="INR">INR</option>
									<option value="EURO">EURO</option>
								</select>
							</td>
							<td *ngIf="!isTenantIndian">
								<input tabIndex="11" tabindex="11" type="text" class="field-input text-center" name=""  formControlName="purchase_currency_rate"/>
							</td> -->
								<td>
									<input tabIndex="9" tabindex="9" style="min-width: max-content; padding: 0;"
										min="{{minDate}}" max="{{maxDate}}" class="input-field" type="date"
										formControlName="invoice_date" 
										placeholder="MM/DD/YYYY" autocomplete="off" [readonly]="invoiceMode=='view'"
										[ngClass]="{'error-box': productService.invoiceForm.controls.invoice_date.dirty && !productService.invoiceForm.controls.invoice_date.valid}"/>
								</td>
								<td>
									<span style="min-width: max-content; padding: 0;" >{{start_date| date :
										'dd/MM/yyyy'}}</span>
								</td>
								<td>
									<span style="min-width: max-content; padding: 0;" >{{end_date| date :
										'dd/MM/yyyy'}}</span>
								</td>
								<td>
									<ng-container *ngIf="invoiceMode == 'save'; else invoiceNumber">-</ng-container>
									<ng-template #invoiceNumber>
										<span
											style="min-width: max-content; padding: 0;">{{productService.invoiceForm.value.invoice_number}}</span>
									</ng-template>
								</td>

								<td tabIndex="{{isTenantIndian ? 10 : 12}}" tabindex="{{isTenantIndian ? 10 : 12}}"
									class="text-center" (focus)="focusEmployeeAc()">

									<ng-autocomplete [data]="employeeList" #employeeAC [searchKeyword]="keyword"
										formControlName="employee_name"
										(selected)="selectAccount($event,'employee', null, true)"
										(inputChanged)='employeeSearch($event)' (inputFocused)='onFocused($event)'
										[itemTemplate]="itemTemplate"
										[disabled]="invoiceMode == 'view' && loggedInuser && loggedInuser.accountcode != 'ADMIN' && productService.invoiceForm.value.employee != ''"
										[notFoundTemplate]="notFoundTemplate">
									</ng-autocomplete>


									<ng-template #itemTemplate let-item>
										<a
											innerHTML='<span class="colr-1 selected-ng">{{item.name}}({{item.account_code}})</span><br />'>
										</a>
									</ng-template>

									<ng-template #notFoundTemplate let-notFound>
										<div [innerHTML]="notFound"></div>
									</ng-template>

									<span class="text-danger"
										*ngIf="productService.invoiceForm.value.employee == ''">Please Enter
										Employee</span>
								</td>
							</tr>
						</tbody>
					</table>

				</div>

				<form [formGroup]="productService.FlightForm" *ngIf="productService.FlightList != undefined">
					<div>
						<table class="mt-3 tbl table table-hovered table-bordered top-miscellaneous-bar w-100">
							<thead>
								<tr>
									<th style="text-align: center;width: 40px;">SL No.</th>
									<th style="width: 140px;text-align: left;">INVOICE NUMBER</th>
									<th style="width: 140px;text-align: left;">INVOICE type</th>
									<th style="width: 270px;text-align: left;">TRAVELLER</th>
									<th style="width: 180px;text-align: right;">NET AMOUNT</th>
									<!-- <th style="width: 180px;text-align: right;">SUPPLIER NET AMOUNT</th>
									<th style=" width: 120px;">Payable Type</th>
									<th style=" width: 210px;">Payable</th> -->
								</tr>
							</thead>
						</table>
						<div  class="tbl-container">
						<table class="tbl table table-hovered table-bordered top-miscellaneous-bar w-100">
							
							<tbody formArrayName="FlightList">
								<ng-container *ngFor="let invoice of DynamicFormControls.controls; let i = index">
									<ng-container [formGroupName]="i">
										<tr class=" {{invoice.value.transaction_method}}_table_row">
											<td style="text-align: center;width: 40px;">
												{{i+1}}
											</td>
											<td style="width: 140px;text-align: left;">
												<a class="doc-number-color" style=" font-weight: bold;" [ngClass]="{
													' underline-none':
													invoice.reference != null &&
													invoice.reference != undefined 
												  }" (click)="openViewMode(invoice.value.invoice_no, invoice.value.invoice_type,invoice.value.transaction_method)">{{ invoice.value?.invoice_no }}</a>
											</td>
											<!-- <td style="width: 140px;text-align: left;">
												<input style="min-width: -webkit-fill-available;font-weight: 600 !important;"
													class="field-input w-100" formControlName="invoice_no" disabled/>
											</td> -->
											<td style="width: 140px;text-align: left;">
												{{ invoice.value?.invoice_type }}
												<!-- <input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="invoice_type"  disabled/> -->
											</td>

											<td style="width: 270px;text-align: left;">
												{{ invoice.value?.traveller_name }}
												<!-- <input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="traveller_name" disabled/> -->
											</td>

											<td style="width: 180px;text-align: right;">
												{{ invoice.value?.client_net_amount }}
												<!-- <input style="min-width: -webkit-fill-available;  font-weight: bold;"
													class="field-input w-100 text-right" formControlName="client_net_amount" disabled/> -->
											</td>

											<!-- <td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100 text-right" formControlName="supplier_net_amount" disabled/>
											</td>

											<td>
												<select class="select-input w-100"
													formControlName="payable_account_type" disabled>
													<option value="">Select</option>
													<option value="client">Client</option>
													<option value="airline">Airline</option>
													<option value="supplier">Supplier</option>
													<option value="employee">Employee</option>
													<option value="general">General</option>
												</select>
											</td>

											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="payable_account_name" disabled/>
											</td> -->

											<!-- <td style="text-align: left;"
												[ngClass]="{'error-box': invoice.controls.payable_account_name.dirty && !invoice.controls.payable_account_name.valid}">
												<ng-autocomplete [data]="payablesArr"
													formControlName="payable_account_name" [searchKeyword]="keyword" disabled
													[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
												</ng-autocomplete>

												<ng-template #itemTemplate let-item>
													<a innerHTML='<span class="colr-1">{{item?.name}}
														({{item?.account_code}})</span>'>
													</a>
												</ng-template>

												<ng-template #notFoundTemplate let-notFound>
													<div [innerHTML]="notFound"></div>
												</ng-template>

											</td> -->
										</tr>
									</ng-container>
								</ng-container>
							</tbody>
							
						</table>
					</div>
					</div>
				</form>
				<!-- <form [formGroup]="productService.HotelForm" *ngIf="productService.HotelList != undefined">
					<div>
						<table class="table table-hovered table-bordered top-miscellaneous-bar w-100">

							<thead>
								<tr>
									<th style="width: 70px;">SL No.</th>
									<th style="width: 270px;">INVOICE NUMBER</th>
									<th>CLIENT NET AMOUNT</th>
									<th>SUPPLIRT NET AMOUNT</th>
									<th style=" width: 75px;">Payable Type</th>
									<th style=" width: 130px;">Payable</th>
								</tr>
							</thead>

							<tbody formArrayName="HotelList">
								<ng-container *ngFor="let invoice of DynamicFormControls.controls; let i = index">
									<ng-container [formGroupName]="i">
										<tr class=" _table_row">
											<td style="width: 30px; " class="text-center">
												{{i+1}}
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="invoice_no" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="client_net_amount" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="supplier_net_amount" />
											</td>
											<td>
												<select class="select-input w-100"
													formControlName="payable_account_type">
													<option value="">Select</option>
													<option value="client">Client</option>
													<option value="airline">Airline</option>
													<option value="supplier">Supplier</option>
													<option value="employee">Employee</option>
													<option value="general">General</option>
												</select>
											</td>

											<td style="text-align: left;"
												[ngClass]="{'error-box': invoice.controls.payable_account_name.dirty && !invoice.controls.payable_account_name.valid}">
												<ng-autocomplete [data]="payablesArr"
													formControlName="payable_account_name" [searchKeyword]="keyword"
													(selected)="selectAccount($event,'payable_code', i, true)"
													(inputChanged)="payableSearch($event, i, true)"
													(inputFocused)='onFocused($event)'
													(inputCleared)="selectAccount($event,'payable_code', i, true)"
													[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
												</ng-autocomplete>

												<ng-template #itemTemplate let-item>
													<a innerHTML='<span class="colr-1">{{item?.name}}
														({{item?.account_code}})</span>'>
													</a>
												</ng-template>

												<ng-template #notFoundTemplate let-notFound>
													<div [innerHTML]="notFound"></div>
												</ng-template>

											</td>
										</tr>
									</ng-container>
								</ng-container>
							</tbody>
						</table>
					</div>
				</form>
				<form [formGroup]="productService.RailForm" *ngIf="productService.RailList != undefined">
					<div>
						<table class="table table-hovered table-bordered top-miscellaneous-bar w-100">

							<thead>
								<tr>
									<th style="width: 70px;">SL No.</th>
									<th style="width: 270px;">INVOICE NUMBER</th>
									<th>CLIENT NET AMOUNT</th>
									<th>SUPPLIRT NET AMOUNT</th>
									<th style=" width: 75px;">Payable Type</th>
									<th style=" width: 130px;">Payable</th>
								</tr>
							</thead>

							<tbody formArrayName="RailList">
								<ng-container *ngFor="let invoice of DynamicFormControls.controls; let i = index">
									<ng-container [formGroupName]="i">
										<tr class=" _table_row">
											<td style="width: 30px; " class="text-center">
												{{i+1}}
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="invoice_no" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="client_net_amount" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="supplier_net_amount" />
											</td>
											<td>
												<select class="select-input w-100"
													formControlName="payable_account_type">
													<option value="">Select</option>
													<option value="client">Client</option>
													<option value="airline">Airline</option>
													<option value="supplier">Supplier</option>
													<option value="employee">Employee</option>
													<option value="general">General</option>
												</select>
											</td>

											<td style="text-align: left;"
												[ngClass]="{'error-box': invoice.controls.payable_account_name.dirty && !invoice.controls.payable_account_name.valid}">
												<ng-autocomplete [data]="payablesArr"
													formControlName="payable_account_name" [searchKeyword]="keyword"
													(selected)="selectAccount($event,'payable_code', i, true)"
													(inputChanged)="payableSearch($event, i, true)"
													(inputFocused)='onFocused($event)'
													(inputCleared)="selectAccount($event,'payable_code', i, true)"
													[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
												</ng-autocomplete>

												<ng-template #itemTemplate let-item>
													<a innerHTML='<span class="colr-1">{{item?.name}}
														({{item?.account_code}})</span>'>
													</a>
												</ng-template>

												<ng-template #notFoundTemplate let-notFound>
													<div [innerHTML]="notFound"></div>
												</ng-template>

											</td>
										</tr>
									</ng-container>
								</ng-container>
							</tbody>
						</table>
					</div>

				</form>
				<form [formGroup]="productService.CarForm" *ngIf="productService.CarList != undefined">
					<div>
						<table class="table table-hovered table-bordered top-miscellaneous-bar w-100">

							<thead>
								<tr>
									<th style="width: 70px;">SL No.</th>
									<th style="width: 270px;">INVOICE NUMBER</th>
									<th>CLIENT NET AMOUNT</th>
									<th>SUPPLIRT NET AMOUNT</th>
									<th style=" width: 75px;">Payable Type</th>
									<th style=" width: 130px;">Payable</th>
								</tr>
							</thead>

							<tbody formArrayName="CarList">
								<ng-container *ngFor="let invoice of DynamicFormControls.controls; let i = index">
									<ng-container [formGroupName]="i">
										<tr class=" _table_row">
											<td style="width: 30px; " class="text-center">
												{{i+1}}
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="invoice_no" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="client_net_amount" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="supplier_net_amount" />
											</td>
											<td>
												<select class="select-input w-100"
													formControlName="payable_account_type">
													<option value="">Select</option>
													<option value="client">Client</option>
													<option value="airline">Airline</option>
													<option value="supplier">Supplier</option>
													<option value="employee">Employee</option>
													<option value="general">General</option>
												</select>
											</td>

											<td style="text-align: left;"
												[ngClass]="{'error-box': invoice.controls.payable_account_name.dirty && !invoice.controls.payable_account_name.valid}">
												<ng-autocomplete [data]="payablesArr"
													formControlName="payable_account_name" [searchKeyword]="keyword"
													(selected)="selectAccount($event,'payable_code', i, true)"
													(inputChanged)="payableSearch($event, i, true)"
													(inputFocused)='onFocused($event)'
													(inputCleared)="selectAccount($event,'payable_code', i, true)"
													[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
												</ng-autocomplete>

												<ng-template #itemTemplate let-item>
													<a innerHTML='<span class="colr-1">{{item?.name}}
														({{item?.account_code}})</span>'>
													</a>
												</ng-template>

												<ng-template #notFoundTemplate let-notFound>
													<div [innerHTML]="notFound"></div>
												</ng-template>

											</td>
										</tr>
									</ng-container>
								</ng-container>
							</tbody>
						</table>
					</div>

				</form>
				<form [formGroup]="productService.BusForm" *ngIf="productService.BusList != undefined">
					<div>
						<table class="table table-hovered table-bordered top-miscellaneous-bar w-100">

							<thead>
								<tr>
									<th style="width: 70px;">SL No.</th>
									<th style="width: 270px;">INVOICE NUMBER</th>
									<th>CLIENT NET AMOUNT</th>
									<th>SUPPLIRT NET AMOUNT</th>
									<th style=" width: 75px;">Payable Type</th>
									<th style=" width: 130px;">Payable</th>
								</tr>
							</thead>

							<tbody formArrayName="BusList">
								<ng-container *ngFor="let invoice of DynamicFormControls.controls; let i = index">
									<ng-container [formGroupName]="i">
										<tr class=" _table_row">
											<td style="width: 30px; " class="text-center">
												{{i+1}}
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="invoice_no" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="client_net_amount" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="supplier_net_amount" />
											</td>
											<td>
												<select class="select-input w-100"
													formControlName="payable_account_type">
													<option value="">Select</option>
													<option value="client">Client</option>
													<option value="airline">Airline</option>
													<option value="supplier">Supplier</option>
													<option value="employee">Employee</option>
													<option value="general">General</option>
												</select>
											</td>

											<td style="text-align: left;"
												[ngClass]="{'error-box': invoice.controls.payable_account_name.dirty && !invoice.controls.payable_account_name.valid}">
												<ng-autocomplete [data]="payablesArr"
													formControlName="payable_account_name" [searchKeyword]="keyword"
													(selected)="selectAccount($event,'payable_code', i, true)"
													(inputChanged)="payableSearch($event, i, true)"
													(inputFocused)='onFocused($event)'
													(inputCleared)="selectAccount($event,'payable_code', i, true)"
													[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
												</ng-autocomplete>

												<ng-template #itemTemplate let-item>
													<a innerHTML='<span class="colr-1">{{item?.name}}
														({{item?.account_code}})</span>'>
													</a>
												</ng-template>

												<ng-template #notFoundTemplate let-notFound>
													<div [innerHTML]="notFound"></div>
												</ng-template>

											</td>
										</tr>
									</ng-container>
								</ng-container>
							</tbody>
						</table>
					</div>

				</form>
				<form [formGroup]="productService.InsuranceForm" *ngIf="productService.InsuranceList != undefined">
					<div>
						<table class="table table-hovered table-bordered top-miscellaneous-bar w-100">

							<thead>
								<tr>
									<th style="width: 70px;">SL No.</th>
									<th style="width: 270px;">INVOICE NUMBER</th>
									<th>CLIENT NET AMOUNT</th>
									<th>SUPPLIRT NET AMOUNT</th>
									<th style=" width: 75px;">Payable Type</th>
									<th style=" width: 130px;">Payable</th>
								</tr>
							</thead>

							<tbody formArrayName="InsuranceList">
								<ng-container *ngFor="let invoice of DynamicFormControls.controls; let i = index">
									<ng-container [formGroupName]="i">
										<tr class=" _table_row">
											<td style="width: 30px; " class="text-center">
												{{i+1}}
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="invoice_no" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="client_net_amount" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="supplier_net_amount" />
											</td>
											<td>
												<select class="select-input w-100"
													formControlName="payable_account_type">
													<option value="">Select</option>
													<option value="client">Client</option>
													<option value="airline">Airline</option>
													<option value="supplier">Supplier</option>
													<option value="employee">Employee</option>
													<option value="general">General</option>
												</select>
											</td>

											<td style="text-align: left;"
												[ngClass]="{'error-box': invoice.controls.payable_account_name.dirty && !invoice.controls.payable_account_name.valid}">
												<ng-autocomplete [data]="payablesArr"
													formControlName="payable_account_name" [searchKeyword]="keyword"
													(selected)="selectAccount($event,'payable_code', i, true)"
													(inputChanged)="payableSearch($event, i, true)"
													(inputFocused)='onFocused($event)'
													(inputCleared)="selectAccount($event,'payable_code', i, true)"
													[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
												</ng-autocomplete>

												<ng-template #itemTemplate let-item>
													<a innerHTML='<span class="colr-1">{{item?.name}}
														({{item?.account_code}})</span>'>
													</a>
												</ng-template>

												<ng-template #notFoundTemplate let-notFound>
													<div [innerHTML]="notFound"></div>
												</ng-template>

											</td>
										</tr>
									</ng-container>
								</ng-container>
							</tbody>
						</table>
					</div>

				</form>
				<form [formGroup]="productService.VisaForm" *ngIf="productService.VisaList != undefined">
					<div>
						<table class="table table-hovered table-bordered top-miscellaneous-bar w-100">

							<thead>
								<tr>
									<th style="width: 70px;">SL No.</th>
									<th style="width: 270px;">INVOICE NUMBER</th>
									<th>CLIENT NET AMOUNT</th>
									<th>SUPPLIRT NET AMOUNT</th>
									<th style=" width: 75px;">Payable Type</th>
									<th style=" width: 130px;">Payable</th>
								</tr>
							</thead>

							<tbody formArrayName="VisaList">
								<ng-container *ngFor="let invoice of DynamicFormControls.controls; let i = index">
									<ng-container [formGroupName]="i">
										<tr class=" _table_row">
											<td style="width: 30px; " class="text-center">
												{{i+1}}
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="invoice_no" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="client_net_amount" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="supplier_net_amount" />
											</td>
											<td>
												<select class="select-input w-100"
													formControlName="payable_account_type">
													<option value="">Select</option>
													<option value="client">Client</option>
													<option value="airline">Airline</option>
													<option value="supplier">Supplier</option>
													<option value="employee">Employee</option>
													<option value="general">General</option>
												</select>
											</td>

											<td style="text-align: left;"
												[ngClass]="{'error-box': invoice.controls.payable_account_name.dirty && !invoice.controls.payable_account_name.valid}">
												<ng-autocomplete [data]="payablesArr"
													formControlName="payable_account_name" [searchKeyword]="keyword"
													(selected)="selectAccount($event,'payable_code', i, true)"
													(inputChanged)="payableSearch($event, i, true)"
													(inputFocused)='onFocused($event)'
													(inputCleared)="selectAccount($event,'payable_code', i, true)"
													[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
												</ng-autocomplete>

												<ng-template #itemTemplate let-item>
													<a innerHTML='<span class="colr-1">{{item?.name}}
														({{item?.account_code}})</span>'>
													</a>
												</ng-template>

												<ng-template #notFoundTemplate let-notFound>
													<div [innerHTML]="notFound"></div>
												</ng-template>

											</td>
										</tr>
									</ng-container>
								</ng-container>
							</tbody>
						</table>
					</div>

				</form>
				<form [formGroup]="productService.TourForm" *ngIf="productService.TourList != undefined">
					<div>
						<table class="table table-hovered table-bordered top-miscellaneous-bar w-100">

							<thead>
								<tr>
									<th style="width: 70px;">SL No.</th>
									<th style="width: 270px;">INVOICE NUMBER</th>
									<th>CLIENT NET AMOUNT</th>
									<th>SUPPLIRT NET AMOUNT</th>
									
									<th style=" width: 75px;">Payable Type</th>
									<th style=" width: 130px;">Payable</th>
								</tr>
							</thead>

							<tbody formArrayName="TourList">
								<ng-container *ngFor="let invoice of DynamicFormControls.controls; let i = index">
									<ng-container [formGroupName]="i">
										<tr class=" _table_row">
											<td style="width: 30px; " class="text-center">
												{{i+1}}
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="invoice_no" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="client_net_amount" />
											</td>
											<td>
												<input style="min-width: -webkit-fill-available;"
													class="field-input w-100" formControlName="supplier_net_amount" />
											</td>
											<td>
												<select class="select-input w-100"
													formControlName="payable_account_type">
													<option value="">Select</option>
													<option value="client">Client</option>
													<option value="airline">Airline</option>
													<option value="supplier">Supplier</option>
													<option value="employee">Employee</option>
													<option value="general">General</option>
												</select>
											</td>

											<td style="text-align: left;"
												[ngClass]="{'error-box': invoice.controls.payable_account_name.dirty && !invoice.controls.payable_account_name.valid}">
												<ng-autocomplete [data]="payablesArr"
													formControlName="payable_account_name" [searchKeyword]="keyword"
													(selected)="selectAccount($event,'payable_code', i, true)"
													(inputChanged)="payableSearch($event, i, true)"
													(inputFocused)='onFocused($event)'
													(inputCleared)="selectAccount($event,'payable_code', i, true)"
													[itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
												</ng-autocomplete>

												<ng-template #itemTemplate let-item>
													<a innerHTML='<span class="colr-1">{{item?.name}}
														({{item?.account_code}})</span>'>
													</a>
												</ng-template>

												<ng-template #notFoundTemplate let-notFound>
													<div [innerHTML]="notFound"></div>
												</ng-template>

											</td>
										</tr>
									</ng-container>
								</ng-container>
							</tbody>
						</table>
					</div>

				</form> -->
				<!-- <app-consolidate-fare-summary [productService]="productService"></app-consolidate-fare-summary> -->
				<div class="total-details consolidate-total-details">
					<table width="100%" class="table table-hovered table-bordered">
						<tfoot>
							<tr *ngFor="let invoice of productService.fareList let i = index">
								<td class="inner-supplier-table" style="width: 40px;text-align: center;border-right:0 " colspan="4"></td>
								<td class="inner-supplier-table" style="width: 270px; text-align: right; border-left:0">TOTAL</td>
								<td class="inner-supplier-table" style="width: 180px; text-align: right; ">{{productService?.totalFareModel[i]?.client_sub_total | number : '1.2-2'}}</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
			<div class="custom-table">

				<div class="table-actions d-flex justify-content-between">
					<div class="action d-flex flex-column col-7  mt-3">
						<span *ngIf="productService.invoiceForm.controls.narration_1.value">
						   <span class="text-grey">Narration 1 :</span> {{productService.invoiceForm.controls.narration_1.value}}
						</span>
						<span *ngIf="productService.invoiceForm.controls.narration_2.value">
							<span class="text-grey">Narration 2 :</span> {{productService.invoiceForm.controls.narration_2.value}}
						</span>
						<span *ngIf="productService.invoiceForm.controls.narration_3.value">
							<span class="text-grey">Narration 3 :</span> {{productService.invoiceForm.controls.narration_3.value}}
						</span>
						<span *ngIf="productService.invoiceForm.controls.narration_4.value">
							<span class="text-grey">Narration 4 :</span>  {{productService.invoiceForm.controls.narration_4.value}}
						</span>
					</div>
					<div class="action d-flex flex-column col-5 p-0" *ngIf="productService.consolidationType == 'event'">
						<table class="table table-hovered  mb-0" style="border-top:0;">
							<tbody>
								<!-- GST ON TOTAL -->
								<tr *ngIf="isTenantIndian">
									<td class="text-right" >
										<div class="d-flex justify-content-between">
											<div class="align-items-center mr-2">
								
												<label class="mb-0" title="">GST <small>(on Total)</small></label>
												<div class="d-flex justify-content-between" >
													<div class="mr-1">HSN</div>
													<input 
														(input)="calculateSC()"
													class="traveller-input-field  text-right" type="text" formControlName="hsn" oninput="this.value = this.value.toUpperCase()" />
												</div>
											</div>
								
										</div>
									</td>
									<td class="pr-0">
										<div class="d-flex justify-content-between">
								
											<ng-container 
												>
												<div style="width:15%" class="align-items-center mr-2">
													<label class="mb-0">Rate:</label>
													<select 
														class="inner-select-top w-100"
														type="number" 
														formControlName="c_gst_conf"
														(change)="calculateSC()"
														>
														<option value= 0 >0</option>
														<option value= 5 >5</option>
														<option value=12 >12</option>
														<option value=18 >18</option>
														<option value=28 >28</option>
													</select>
												</div>
								
												<div style="width:24%" class="align-items-center mr-2">
													<label class="mb-0">CGST</label>
													<input disabled formControlName="c_cgst"  class="traveller-input-field text-right"
														type="number" />
												</div>
								
												<div style="width:24%" class="align-items-center mr-2">
													<label class="mb-0 ml-1 mr-1">SGST</label>
													<input disabled formControlName="c_sgst" class="traveller-input-field text-right"
														type="number" />
												</div>
								
												<div style="width:24%" class="align-items-center mr-2">
													<label class="mb-0">IGST</label>
													<input disabled formControlName="c_igst" class="traveller-input-field text-right"
														type="number" />
												</div>
												<div style="width:57%" class="align-items-center mr-2">
													<label class="mb-0">&nbsp;</label>
													<input disabled formControlName="c_net_gst" class="traveller-input-field text-right"
													(input)="calculateSC()"
														type="number" />
												</div>
											</ng-container>
								
										</div>
									</td>
								</tr>
								<!-- SERVICE CHARGE -->
								<tr>
									<td class="text-right" >
										
									</td>
									<td class="pr-0">
										<div class="d-flex justify-content-end">
											<div style="width:39%"  class="align-items-center mr-3 text-right">
												<strong>Service Charge</strong>
											</div>
											<div style="width:24%" class="align-items-center mr-2">
												<select 
													class="inner-select-top w-100"
													type="number" 
													formControlName="c_sc1_type"
													(change)="calculateSC()"
													>
													<option value="VL" >VL</option>
													<option value="P" >%</option>
												</select>
											</div>
											<div style="width:24%" class="align-items-center mr-2">
												
												<input 
													class="traveller-input-field  text-right" type="number" formControlName="c_sc1_value" 
													(input)="calculateSC()"
													oninput="this.value = this.value.toUpperCase()" />
											</div>
											
											<div style="width:57%" class="align-items-center mr-2">
												<input disabled formControlName="c_sc1_amount" class="traveller-input-field text-right"
													type="number" />
											</div>
										</div>
									</td>
								</tr>
								<!-- GST on SERVICE CHARGE -->
								<tr *ngIf="isTenantIndian">
									<td class="text-right" >
										<div class="d-flex justify-content-between">
											<div class="align-items-center mr-2">
								
												<label class="mb-0" title="">GST <small>(on SC)</small></label>
												<div class="d-flex justify-content-between" >
													<div class="mr-1">HSN</div>
													<input 
													class="traveller-input-field  text-right" type="text" formControlName="hsn_2" oninput="this.value = this.value.toUpperCase()" />
												</div>
											</div>
								
										</div>
									</td>
									<td class="pr-0">
										<div class="d-flex justify-content-between">
								
											<ng-container 
												*ngIf="isTenantIndian">
												<div style="width:15%" class="align-items-center mr-2">
													<label class="mb-0">Rate:</label>
													<select 
														class="inner-select-top w-100"
														type="number" 
														formControlName="c_gst_conf_2"
														(change)="calculateSC()"
														>
														<option value= 0 >0</option>
														<option value= 5 >5</option>
														<option value=12 >12</option>
														<option value=18 >18</option>
														<option value=28 >28</option>
													</select>
												</div>
								
												<div style="width:24%" class="align-items-center mr-2">
													<label class="mb-0">CGST</label>
													<input disabled formControlName="c_cgst_2" class="traveller-input-field text-right"
														type="number" />
												</div>
								
												<div style="width:24%" class="align-items-center mr-2">
													<label class="mb-0 ml-1 mr-1">SGST</label>
													<input disabled formControlName="c_sgst_2" class="traveller-input-field text-right"
														type="number" />
												</div>
								
												<div style="width:24%" class="align-items-center mr-2">
													<label class="mb-0">IGST</label>
													<input disabled formControlName="c_igst_2" class="traveller-input-field text-right"
														type="number" />
												</div>
												<div style="width:57%" class="align-items-center mr-2">
													<label class="mb-0">&nbsp;</label>
													<input disabled formControlName="c_net_gst_2" class="traveller-input-field text-right"
														type="number" />
												</div>
											</ng-container>
								
										</div>
									</td>
								</tr>
								<!-- TCS CHARGE -->
								<tr>
									<td class="text-right" >
										
									</td>
									<td class="pr-0">
										<div class="d-flex justify-content-end">
											<div style="width:63%" class="align-items-center mr-4 text-right">
												<strong>TCS</strong>
											</div>
											<div style="width:24%" class="align-items-center mr-2">
												<select 
													class="inner-select-top w-100"
													type="number" 
													formControlName="tcs_rate"
													(change)="calculateSC()"
													>
													<option value="0" >0%</option>
													<option value="5" >5%</option>
													<option value="20" >20%</option>
												</select>
											</div>
											
											<div style="width:57%" class="align-items-center mr-2">
												<input disabled formControlName="tcs_amount" class="traveller-input-field text-right"
													type="number" />
											</div>
										</div>
									</td>
								</tr>

								<!-- NET TOTAL -->
								<tr>
									<td class="text-right" >
										
									</td>
									<td class="pr-0">
										<div class="d-flex justify-content-end">
											<div style="width:63%" class="align-items-center mr-4 text-right">
												<strong>Net Total</strong>
											</div>
											<div style="width:24%" class="align-items-center mr-2">
											</div>
											
											<div style="width:57%" class="align-items-center mr-2">
												<input disabled formControlName="total" class="traveller-input-field text-right"
													type="number" />
											</div>
										</div>
									</td>
								</tr>
								
							</tbody>
						</table>
					</div>
				</div>
				
		
				</div>
		</div>
	</div>

	<div class="btn-field bottom-btns-container full-width-btn-container  d-flex align-item-center justify-content-end ml-0 mr-0">  
		<ng-container *ngIf="invoiceMode == 'save'">
			<div class="w-100 d-flex align-item-center">
				<div class="ml-auto">
			<div class="w-25" *ngIf="invoiceMode=='save'">
				</div>
				<button type="button" class="btn-style bg-purple" (click)="saveProductInvoice()"> Save</button>
			</div>
			</div>
		</ng-container> 
		<ng-container *ngIf="invoiceMode == 'view'">
			<div class="w-100 d-flex align-item-center">
	 
				
				<div class="ml-auto">
					
					<span style="position: relative; display: inline-block;" class="mr-3">
						<button type="button" class="btn-style bg-denim" (click)="clickToPrintOptions()">
							Print
						</button>
						<span class="form-invoice-btn"  *ngIf="showPrintOptions">
							<button type="button" *ngIf="checkEInvoice==false || isTesoyBoult==false"
							(click)="openVoucher(invoice_no)" class="btn-style bg-denim mr-2">Invoice</button>
				

						</span>
					</span>
					
					<button type="button" class="btn-style bg-brown mr-3" (click)="viewJournal(invoice_no)" id="journal_ms_invoice_btn">
						Journal
					</button>
					<button type="button" class="btn-style bg-denim mr-3" (click)="downloadInv()" id="journal_ms_invoice_btn">
						Download Memo Inv
					</button>
					<button *ngIf="invoiceMode == 'view'" type="button" class="btn-style bg-green" (click)="getExcelData()" id="excel_download_btn">
						Download Summary
					</button>

					
					
				</div>
			</div>
			
		</ng-container>
	</div>


	
</form>