<!-- <div class="message-wrapper" *ngIf="showMessage">
	<div class="update-info animated fadeInDown {{alertType}}">
		<div class="container">
			<div class="row">
				<div class="col-11">
					<h5>
						{{message}} <a *ngIf="submitResponse[0] != undefined" class="invoice_no ml-2"
							[routerLink]="['/transactions/payment/update', submitResponse[0].payment_id]">{{submitResponse[0].payment_code}}</a>
						.
					</h5>
				</div>
				<div class="col-1">
					<button type="button" class="close" (click)="hideError()" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="ledger-container pb-20 ">
	<ng-container>
		<div class="row tab-nav pl-0 pr-0">
			<div class="col-sm-4 ">
			  <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
				  <li [routerLink]="['/']"
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> Banking</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li [routerLink]="['/']"
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> Payment</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li class="text-grey text-capitalize cursor text-primary transition"> Create</li>
			  </ul>
		  </div>
		</div>

		<div class="custom-form-container text-left">
			<form [formGroup]="paymentService.paymentForm">
				<div class="custom-form">

					<div class="text-field-group d-flex align-items-center">
						<div class="select-field text-input-field mr-3">
							<select class="input-field" formControlName="accountCodeFrom" tabIndex="1">
								<option value="">Select</option>
								<ng-container *ngFor="let item of generalMasterList">
									<option *ngIf="item.account_code != ''" value="{{item.account_code}}">{{item.name}}
									</option>
								</ng-container>
							</select>
							<label>Cash / Bank</label>
						</div>
						<div class="text-input-field">
							<input oninput="this.value = this.value.toUpperCase()" tabIndex="2" class="input-field"
								type="date" formControlName="paymentDate" autocomplete="off" />
							<label>Generated Date</label>
						</div>

					</div>

					<div class="text-field-group d-flex align-items-center"
						*ngIf="paymentService.paymentEntries.length > 0">
						<div class="select-field text-input-field mr-3">
							<input oninput="this.value = this.value.toUpperCase()" class="input-field" type="text"
								readonly formControlName="payment_id" />
							<label>Payment payment Code</label>
						</div>
						<div class="text-input-field">
							<input oninput="this.value = this.value.toUpperCase()" class="input-field"
								formControlName="amount" pattern="^\d*(\.\d{0,8})?$" type="text" readonly />
							<label>Amount</label>
						</div>
					</div>

					<span class="info alert-warning" *ngIf="paymentService.paymentEntries.length == 0">* To Save
						Payment,
						you must add atleast 1 entry.</span>

				</div>
				<div class="mt-5"
					*ngIf="paymentService.paymentEntries != undefined && paymentService.paymentEntries.length > 0">
					<div class="">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>Code</th>
									<th>Sub Ledger</th>
									<th>Account</th>
									<th>Cheque</th>
									<th>Amount</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let payment of paymentService.paymentEntries">
									<td>{{payment.account_code_to}}</td>
									<td>{{payment.sub_ledger_code_1}}</td>
									<td>{{payment?.account_code_to_value}}</td>
									<td>{{payment.cheque_no}}</td>
									<td>{{payment.amount | number : '1.2-2'}}</td>
									<td class="edit-row" (click)="editEntry(payment)">
										<i class="fa fa-edit"></i>
									</td>
								</tr>
								<tr>
									<td colspan="5" class="grand">Total Amount</td>
									<td class="grand">₹ {{paymentService.paymentForm.value.amount}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="action-box d-flex justify-content-center mt-3 mb-4 ">
					<button type="button" id="saveBtn" class="btn-style bg-green mr-3" tabIndex="4" #saveButton
						(keyup.enter)="submit()" (click)="submit()">Save</button>
					<button type="button" class="btn-style bg-blue mr-3" tabIndex="3"
						[disabled]="paymentService.paymentForm.controls.paymentDate.invalid || paymentService.paymentForm.controls.accountCodeFrom.invalid"
						[ngClass]="{disbaledBtn: paymentService.paymentForm.controls.paymentDate.invalid || paymentService.paymentForm.controls.accountCodeFrom.invalid}"
						(click)="addRecord(true,paymentService.paymentForm.value)">Add Entry</button>
					<button type="button" class="btn-style bg-red" (click)="reset()">Reset</button>
				</div>
			</form>
			<app-add-payment-record [isUpdate]="false" (callExitEntry)="exitEntry($event)"></app-add-payment-record>
			
		</div>
	</ng-container>
</div> -->

<app-v2-create-payment></app-v2-create-payment>