import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConsolidateInvoiceSearchRoutingModule } from './consolidate-invoice-routing.module';
import { ConsolidateInvoiceSearchComponent } from './consolidate-invoice.component'; 
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [ConsolidateInvoiceSearchComponent],
  imports: [
    CommonModule,
    ConsolidateInvoiceSearchRoutingModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [ConsolidateInvoiceSearchComponent]
})
export class ConsolidateInvoiceSearchModule { }
