
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
	<div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
	  <div class="d-flex flex-y-center">
		<img
		  src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
			alertType=='success-box' ? 'green-sucess.svg' : 
			alertType=='warning-box' ? 'red-warning.svg' : 
			null }}"
		  width="20px"
		  height="20px"
		  alt="alert-logo"
		  class="mr-20"
		/>
		<div class="ml-10">
		  <h4 class="m-0 text-size-18 text-bold"
		  [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
			{{alertType === "error-box"
			? "Error"
			: alertType === "warning-box"
			? "Warning"
			: alertType === "success-box"
			? "Success"
			: null}}
		  </h4>
		  <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
		   {{message}}
		  </small>
		</div>
	  </div>
	  <img
		src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
		alt="close-btn"
		(click)="hideError()"
		className=" close-btn"
	  />
	</div>
  </div>
  
<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<button type="button" hidden #openSuccessModal data-toggle="modal" data-target="#successModal"></button>
			<!-- Modal -->
    <div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
        <div class="modal-dialog transaction-creation-modal">
            <!-- Modal content-->
            <div class="modal-content"  *ngIf="submitResponse && submitResponse[0] != undefined">
                <div class="modal-header">
                    <span class="messageFont">
                        <span>
                            {{message}} 
                        </span>
                            <a *ngIf="submitResponse[0].receipt_code != ''" 
                                href="javascript:void(0)"	
                                (click)="openVoucher(submitResponse[0].receipt_code)">
                                {{submitResponse[0].receipt_code}}
                            </a>
							<a *ngIf="submitResponse[0].receipt_code != ''" [title]="copyELementTitle" (click)="copytext(submitResponse[0].receipt_code)">

								<i   class="fa fa-copy cursor-pointer"></i>
							</a>
                        </span>
                        <button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
                    </div>
                <div class="modal-body">
                    <p>Do you want to create new Receipt or view newly created Receipt ?</p>
                </div>
                <div class="modal-footer justify-content-center">
                    <button 
                        tabIndex="1" 
                        tabindex="1"
                        type="button" 
                        class="btn btn-primary bg-blue" 
                        (click)="viewReceipt(submitResponse[0].receipt_code)">
                        View
                    </button>
                    <button 
                        tabIndex="2" 
                        tabindex="2"
                        type="button" 
                        class="btn btn-primary btn-grey" 
                        (click)="openVoucher(submitResponse[0].receipt_code)">
                        Print
                    </button>
                    <button 
                        tabIndex="3" 
                        tabindex="3"
                        type="button" 
                        class="btn btn-primary bg-green"  
                        (click)="closeSuccesModal()">
                        Create New
                    </button>
                </div>
            </div>
        </div>
    </div>


  <div class="ledger-container"  [ngClass]="{'d-none': !newReceipt}">
	<ng-container>
		<div class="row tab-nav pl-0 pr-0">
			<div class="col-sm-12 ">
			  <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
				  <li  
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> Banking</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li  
					  class="text-grey text-capitalize cursor  hover-text-primary transition"> Receipt</li>
				  <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
				  <li class="text-grey text-capitalize cursor text-primary transition"> Create</li>
			  </ul>
		  </div>
		
		</div>
		<div class="row tab-nav pl-0 pr-0">

		</div>
		<!-- <div class="d-flex justify-content-between">
			<div  class="heading-box mt-4 mb-3" style="width: 600px;">
				<div class="heading">UPDATE JV</div>
				<span class="hr-line"></span>
			</div>
				<div class="action-box mt-4">
					<button type="button" class="btn-style" (click)="closeWindow()">Close</button>
				</div>
		</div> -->
		<form #form="ngForm" [formGroup]="receiptService.receiptEntryForm" [tabIndex]="isModalOpened ? '-1' : '' " >
			<div class="pl-15 p-15 pt-3 custom-form form-container w-100 mb-4">
                <div class="row">
                    <div class="col-sm-2 text-field-group d-flex align-items-center  pb-3" >
						<div class="text-input-field">
							<input formControlName="receipt_date" tabIndex="0" class="input-field" type="date" 
								min="{{minDate}}" 
								max="{{maxDate}}"
								placeholder="MM/DD/YYYY" autocomplete="off" tabIndex="1"
								[ngClass]="{'error-box': !receiptService.receiptEntryForm.controls.receipt_date.valid}"/>
							<label>Receipt Date</label>
						</div>
						
					</div>

					<div class="col-sm-2 text-field-group d-flex align-items-center  pb-3" >
						<div class="text-input-field multiselect-dropdown-container">
							<!-- <select class="input-field" formControlName="account_code_to" attr.tabIndex="2" tabIndex="2">
								<option value="">Select</option>
								<ng-container *ngFor="let item of generalMasterList">
									<option *ngIf="item.account_code != ''" value="{{item.account_code}}">{{item.name}}
									</option>
								</ng-container>
							</select> -->
							<label>Cash / Bank</label>
							<div class="ng-autocomplete custom-ngc" (focus)="focusBankAc($event)">
								<input attr.tabIndex="2" tabIndex="2"
									type="text" #accountAC oninput="this.value = this.value.toUpperCase()" 
									class="autocomplete-container" name="account_code_to_name" 
									formControlName="account_code_to_name"
									(input)="getGeneralMaster($event)"
									[nzAutocomplete]="accountCodeToAc"
								/>
		
								<nz-autocomplete #accountCodeToAc [nzBackfill]="true"  (selectionChange)="selectBank($event,'account_code_to')">
									<nz-auto-option  class="colr-1" *ngFor="let item of generalMasterList" [nzValue]="item" [nzLabel]="item.name">
										<a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
									</nz-auto-option>   
									<nz-auto-option *ngIf="showCreateButton && currentMaster == 'general'" class="colr-1" nzValue="" style="text-align: center"
                                        nzLabel="Create New">
										<span>Create New</span>                          
									</nz-auto-option>                      
								</nz-autocomplete>

							</div>	
						</div>
					</div>

                    <div class="col-sm-2 text-field-group d-flex align-items-center  pb-3" *ngIf="searchId" >
						<div class="text-input-field">
							<input class="input-field" type="text" readonly formControlName="receipt_code"  autocomplete="off"/>
							<label>Receipt Code</label>
						</div>
						
					</div>
                </div>
				<div class="">
					
					<!-- <div class="table-head">Entries</div> -->
					<table class="table table-bordered">
						<thead>
							<tr>
                                <th style="width: 25px;">S.no</th>
								<th>Account Type</th>
								<th>Code</th>
								<th>Sub Ledger Type</th>
								<th>Sub Ledger</th>
								<th *ngIf="newReceiptView">Ref Type</th>
								<th>{{ newReceiptView ? 'Ref No' : 'Cheque'}}</th>
								<th>{{ newReceiptView ? 'Ref Date' : 'Cheque Date'}}</th>
								<th class="text-left">Amount</th>
								<!-- <th style="width: 100px;" class="text-left">Charges</th>
								<th style="width: 100px;" class="text-left">Gst Rate</th>
								<th class="text-left">Gst On Charges</th> -->
								<th>Actions</th>
							</tr>
						</thead>
						<tbody class="fixed-tbody-height " formArrayName="receiptArray">
							<tr *ngFor="let receiptEntry of DynamicFormControls.controls; let i = index;" [formGroupName]="i">
								<td style="width: 25px;">{{i+1}}</td>

								<td>
									<select class="input-field" #accountType formControlName="account_code_from_type" (change)="resetSelection('account_code_from',i)" attr.tabIndex="{{i*perRowInputCount+1+previousTabCount}}" tabIndex="{{i*perRowInputCount+1+previousTabCount}}">
										<option value="client">Client</option>
										<option value="airline">Airline</option>
										<option value="supplier">Supplier</option>
										<option value="general">General</option>
									</select>
								</td>
								
								<td>
									<div class="ng-autocomplete custom-ngc"  (focus)="focusAccount($event,i)">
										<input attr.tabIndex="{{i*perRowInputCount+2+previousTabCount}}" 
											tabIndex="{{i*perRowInputCount+2+previousTabCount}}"
											type="text" #accountAC oninput="this.value = this.value.toUpperCase()" 
											class="autocomplete-container" name="account_code_from_name" 
											formControlName="account_code_from_name"
											(input)="onChangeSearch($event,i)"
											[nzAutocomplete]="accountCodeFrom"
										/>
				
										<nz-autocomplete #accountCodeFrom [nzBackfill]="true"  (selectionChange)="selectAccount($event,'account_code_from',i)">
											<nz-auto-option  class="colr-1" *ngFor="let item of accountList[i]" [nzValue]="item" [nzLabel]="item.name">
												<a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
											</nz-auto-option>   
											<nz-auto-option *ngIf="showCreateButton && currentMaster == receiptEntry.value.account_code_from_type" class="colr-1" nzValue="" style="text-align: center"
												nzLabel="Create New">
												<span>Create New</span>                          
											</nz-auto-option>                          
										</nz-autocomplete>
									</div>									
								</td>
							<td>
								<select class="input-field" formControlName="sub_ledger_code_1_type" (change)="resetSelection('sub_ledger_code_1',i)" attr.tabIndex="{{i*perRowInputCount+3+previousTabCount}}" 
								    tabIndex="{{i*perRowInputCount+3+previousTabCount}}">
									<option value="">None</option>
									<option value="client">Client</option>
									<option value="airline">Airline</option>
									<option value="supplier">Supplier</option>
									<option value="employee">Employee</option>
									<option value="general">General</option>
								</select>
							</td>
								<td>
									<div class="ng-autocomplete custom-ngc" attr.tabIndex="{{i*perRowInputCount+4+previousTabCount}}" 
									tabIndex="{{i*perRowInputCount+4+previousTabCount}}" (focus)="focusSubLedger($event,i)" >
										<input
												type="text" #subLedgerAC oninput="this.value = this.value.toUpperCase()" 
												class="autocomplete-container" name="sub_ledger_code_1_name" 
												formControlName="sub_ledger_code_1_name"
												(input)="ledgerSearch($event,i)"
												[nzAutocomplete]="sub_ledger_code_1_ac"
											/>
					
										<nz-autocomplete #sub_ledger_code_1_ac [nzBackfill]="true"  (selectionChange)="selectAccount($event,'sub_ledger_code_1',i)">
											<nz-auto-option  class="colr-1" *ngFor="let item of ledgerList[i]" [nzValue]="item" [nzLabel]="item.name">
												<a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
											</nz-auto-option>                      
										</nz-autocomplete>
									</div>
								</td>
								<td *ngIf="newReceiptView">
									<select class="input-field" formControlName="reference_type" attr.tabIndex="{{i*perRowInputCount+5+previousTabCount}}" 
										tabIndex="{{i*perRowInputCount+5+previousTabCount}}">
										<option value="">None</option>
										<option value="cheque">cheque</option>
										<option value="rtgs">rtgs</option>
										<option value="imps">imps</option>
										<option value="neft">neft</option>
										<option value="credit_card">Credit Card</option>
										<option value="debit_card">Debit Card</option>
										<option value="upi">upi</option>
										<option value="cash">cash</option>
										<option value="others">Others</option>
									</select>
								</td>
								<td>
									<input oninput="this.value = this.value.toUpperCase()"  class="input-field" type="text"
									formControlName="reference_no" 
									attr.tabIndex="{{i*perRowInputCount+ (newReceiptView ? 6 : 5) +previousTabCount}}" 
								tabIndex="{{i*perRowInputCount+ (newReceiptView ? 6 : 5) +previousTabCount}}" />
									
								</td>

								<td>
									<input oninput="this.value = this.value.toUpperCase()"  class="input-field" type="date"
									formControlName="reference_date" autocomplete="off"
									[ngClass]="{'error-field':receiptEntry['controls'].reference_date.invalid && receiptEntry['controls'].reference_date.dirty,
									'success-field' :receiptEntry['controls'].reference_date.valid && receiptEntry['controls'].reference_date.dirty}" attr.tabIndex="{{i*perRowInputCount+(newReceiptView ? 7 : 6)+previousTabCount}}" 
									tabIndex="{{i*perRowInputCount+(newReceiptView ? 7 : 6)+previousTabCount}}" />
									
								</td>


								<td>
									<input oninput="this.value = this.value.toUpperCase()"  class="input-field" type="text"
										#amountField formControlName="amount" pattern="^\d*(\.\d{0,8})?$" (input)="calculateDiff()"
										[ngClass]="{'error-field': (receiptEntry['controls'].amount.invalid && receiptEntry['controls'].amount.dirty) || receiptService.receiptEntryForm.value.amount < 1,
									'success-field' :receiptEntry['controls'].amount.valid && receiptEntry['controls'].amount.dirty}" attr.tabIndex="{{i*perRowInputCount+(newReceiptView ? 8 : 7)+previousTabCount}}" 
									tabIndex="{{i*perRowInputCount+(newReceiptView ? 8 : 7)+previousTabCount}}" />
								</td>
								
								<!-- <td>
									<input oninput="this.value = this.value.toUpperCase()"  class="input-field" type="text"
										#chargesField formControlName="charges" pattern="^\d*(\.\d{0,8})?$" (input)="calculateGstOnCharges($event, i)"
										[ngClass]="{'error-field': (receiptEntry['controls'].charges.invalid && receiptEntry['controls'].charges.dirty) || receiptService.receiptEntryForm.value.charges < 1,
									'success-field' :receiptEntry['controls'].charges.valid && receiptEntry['controls'].charges.dirty}" attr.tabIndex="{{i*10+8+previousTabCount}}" 
									tabIndex="{{i*10+8+previousTabCount}}" />
								</td>
								
								<td>
									<input oninput="this.value = this.value.toUpperCase()"  class="input-field" type="text"
										#gstRateField formControlName="gst_rate" pattern="^\d*(\.\d{0,8})?$" disabled
										[ngClass]="{'error-field': (receiptEntry['controls'].gst_rate.invalid && receiptEntry['controls'].gst_rate.dirty) || receiptService.receiptEntryForm.value.gst_rate < 1,
									'success-field' :receiptEntry['controls'].gst_rate.valid && receiptEntry['controls'].gst_rate.dirty}" attr.tabIndex="{{i*10+9+previousTabCount}}" 
									tabIndex="{{i*10+9+previousTabCount}}" />
								</td>

								<td>
									<input oninput="this.value = this.value.toUpperCase()"  class="input-field" type="text"
										#gstOnChargesField formControlName="gst_on_charges" pattern="^\d*(\.\d{0,8})?$" disabled
										[ngClass]="{'error-field': (receiptEntry['controls'].gst_on_charges.invalid && receiptEntry['controls'].gst_on_charges.dirty),
									'success-field' :receiptEntry['controls'].gst_on_charges.valid && receiptEntry['controls'].gst_on_charges.dirty}" attr.tabIndex="{{i*10+10+previousTabCount}}" 
									tabIndex="{{i*10+10+previousTabCount}}" />
								</td> -->

								<td class="edit-row text-center">
                                    <button  title="Add Row" class="custom-table-btn bg-white border-0 p-0 mr-2 text-bold hoverableShadow transition cursor" type="button"  (click)="addRow()"
									id="add_row_receipt_btn_{{i}}"
									>
									<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-row.svg" alt="" width="20px">
									
								  </button>
									<button  title="Delete Row" class="custom-table-btn bg-white border-0 p-0 mr-2 text-bold hoverableShadow transition cursor" type="button" (click)="removeReceiptRow(i)"
									id="remove_row_receipt_btn_{{i}}"
									>
									<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-delete.svg" alt="" width="20px">
									
								  </button>
									<button  title="View Adjusted Invoices" class="custom-table-btn bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button" (click)="viewAdjustedInvoices()"
									id="adjusted_invoices_receipt_btn_{{i}}"
									>
									<img src="{{commonService.aws_asset_url}}/_assets/icons/dark-mode/green-cirlcle-edit.svg" alt="" width="20px">
									
								  </button>
								</td>

							</tr>
						</tbody>
						<tbody>
							<tr>
							<td colspan="6"></td>
							<td>
								<div class="d-flex justify-content-center text-bold">
									SUBTOTAL:
								</div>
							</td>
							<td> <span class="text-bold">{{totalAmount}}</span></td>
							<td></td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
							<td colspan="9" class="text-right text-black pt-3 pb-3">
								<br />
								<div class="text-input-field w-50 mt-3 mb-2">
									<input class="input-field" attr.tabIndex="{{receiptService.receiptArray.length*7+1+previousTabCount}}" tabIndex="{{receiptService.receiptArray.length*7+1+previousTabCount}}" type="text" formControlName="narration_5" autocomplete="off" />
									<label>Narration</label>
								</div>
								<!-- <br /> -->
							</td>
							</tr>
						</tfoot>
						<!-- <tfoot>
							<tr>
							<td colspan="5" class="text-right text-black">Total Amount</td>
                            <td colspan="4" class="text-black">Rs. 50,0000</td>
							</tr>
						</tfoot> -->
					</table>

					<!-- <div class="fare-diff-col d-flex justify-content-between align-items-center">
						
						<div class="text-field-group">
							<div class="text-input-field mt-0">
								<button type="button" class="btn-style text-success mr-3" ><i class="fa fa-plus fa fa-plus-square text-success"></i> Row</button>
							</div>
						</div>

						<div class="text-field-group d-flex">
							<span class="info alert-warning" >* To Save JV, Difference Should be zero (0).</span>
						</div>
					</div> -->
				

				
				</div>
			</div>
			<div class="btn-field bottom-btns-container full-width-btn-container d-flex align-item-center justify-content-end  ml-0 mr-0">  
				<ng-container *ngIf="searchId">
				
					<button type="button" class="btn-style bg-cyan mr-3" 
					*hasAccess="{'key' : 'F0004013'}"
					(click)="openConfirModal('update')" attr.tabIndex="{{receiptService.receiptArray.length*7+2+previousTabCount}}" tabIndex="{{receiptService.receiptArray.length*7+2+previousTabCount}}" id="update_receipt_btn">Modify</button>
					<button type="button" class="btn-style bg-red mr-3" 
					*hasAccess="{'key' : 'F0004014'}"
					(click)="openConfirModal('delete')" attr.tabIndex="{{receiptService.receiptArray.length*7+3+previousTabCount}}" tabIndex="{{receiptService.receiptArray.length*7+3+previousTabCount}}" id="delete_receipt_btn">Delete</button>
					
					<button type="button" class="btn-style bg-denim mr-3" (click)="openVoucher(searchId)">Print</button> 
					<button type="button" class="btn-style bg-brown mr-3" (click)="viewJournal(searchId)" id="journal_receipt_btn">
						Journal
					</button>
					<button type="button" class="btn-style bg-indigo mr-3" id="receipt_activity_log" (click)="openActivity()">Activity</button> 
					<button type="button" class="btn-style bg-rusty-red text-white mr-3"
					attr.tabIndex="{{receiptService.receiptArray.length*7+4+previousTabCount}}" 
					tabIndex="{{receiptService.receiptArray.length*7+4+previousTabCount}}"
					(click)="openSendEmailModal()" id="receipt_send_mail_btn">Email</button>
					<button type="button" class="btn-style bg-blue" *ngIf="showAdjustbtn" id="adjust-balance-button" (click)="openAdjustmentModal()">Adjustment</button>
				</ng-container>
				<ng-container *ngIf="!searchId">
					<button type="button" class="btn-style bg-green ml-3 mr-3" (click)="resetReceipt()">Reset</button>
					<button type="button" class="btn-style bg-purple " (click)="openConfirModal('save')" attr.tabIndex="{{receiptService.receiptArray.length*7+2+previousTabCount}}" tabIndex="{{receiptService.receiptArray.length*7+2+previousTabCount}}" id="save_receipt_btn">Save</button>
					
				</ng-container>
			</div>
		</form>

	</ng-container>

</div>

<button type="button" hidden #confirmModal data-toggle="modal" data-target="#confirmationModal"></button>

<div class="modal fade" id="confirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" #closeConfirmModal data-dismiss="modal" aria-label="Close" id="confirm_close_Modal_{{confirmationModalType}}_btn" (click)="resetCurrentModalType()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>" {{confirmationModalType}} this entry " </b> ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-style mr-2" (click)="closeConfirModal()">No</button>
                <button type="button" class="btn-style bg-purple"
					id="confirm_Opened_Modal_{{confirmationModalType}}_btn"
                    (click)="confirmationModalType=='save'?submit(receiptService.receiptEntryForm.value):confirmationModalType=='update'?updateAndExit(receiptService.receiptEntryForm.value):confirmationModalType=='delete'?deleteReceipt(receiptService.receiptEntryForm.value):''">Yes</button>
            </div>
        </div>
    </div>
</div>



 <!-- Email Modal for sending mail -->
 <button
 hidden
 #openEmailModal
 data-target="#emailModalInReceipt"
 data-toggle="modal"
 ></button>
 <div
 class="modal"
 id="emailModalInReceipt"
 role="dialog"
 aria-labelledby="emailModalLabel"
 data-backdrop="false"
 >
   <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header">
         <div class="d-flex justify-content-center">
           <h4 class="modal-title">Send Email</h4>
         </div>
         <button
           type=" button"
           class="close"
           #closeSendEmailModal
           data-dismiss="modal"
           aria-label="Close"
         >
           <span aria-hidden="true">&times;</span>
         </button>
       </div>
       <div class="modal-body emailSenders">
         <!-- <div class="d-flex justify-content-end align-item-center">
                           <span *ngIf="!ccEmail" (click)="showCCEmail()" class="text-size-14 mb-0 cursor  mr-2">Cc</span>
                           <span *ngIf="!bccEmail" (click)="showBCCEmail()" class="text-size-14 mb-0 cursor">Bcc</span>
                       </div> -->
         <form [formGroup]="sendEmailForm" style="margin-top: -10px">
           <div class="form-group text-input-field">
             <textarea
               class="input-field ng-pristine ng-valid ng-touched text-size-12"
               id="toEmail"
               rows="1"
               formControlName="toEmails"
               required
             ></textarea>
             <label for="To" class="mb-0">To</label>
           </div>
           <p class="text-right mt-0">
             <!-- <label for="To" class="text-size-14 mb-0">To</label> -->
             <small *ngIf="sendEmailForm.controls.toEmails.invalid">
               <span
                 *ngIf="sendEmailForm.controls.toEmails.errors?.required"
                 style="color: red"
               >
                 *To Email is required.
			 </span>
			</small>
			</p>

           <div class="form-group text-input-field mt-0">
             <textarea
               class="input-field ng-pristine ng-valid ng-touched text-size-12"
               id="ccEmail"
			   style="max-height: 45px;"
               formControlName="ccEmails"
             ></textarea>
             <label for="Cc" class="mb-0">Cc</label>
           </div>

           <div class="form-group text-input-field">
             <textarea
               class="input-field ng-pristine ng-valid ng-touched text-size-12"
               id="bccEmail"
			   style="max-height: 45px;"
               formControlName="bccEmails"
             ></textarea>
             <label for="Bcc" class="mb-0">Bcc</label>
           </div>
		
		  <div class="text-input-field">
		   <input formControlName="subject"  class="input-field text-size-12" type="text" 
		   autocomplete="off" />
		   <label>Subject</label>
	   </div>
	   <div class="form-group text-input-field">
		<textarea
		  class="input-field ng-pristine ng-valid ng-touched text-size-12"
		  rows="5"
		  formControlName="body"
		></textarea>
		<label for="body" class="mb-0">Mail Body</label>
		<input type="file" (change)="handleAttachment($event)" />

	  </div>
           <div class=" mt-3 d-flex justify-content-center align-item-center">
             <button type="button" class="btn-style mr-3" data-dismiss="modal">
               Cancel
             </button>
             <button
               type="button"
               class="btn-style bg-green"
               (click)="callTosendEmail()"
             >
               Send
             </button>
           </div>
         </form>
       </div>
     </div>
   </div>
 </div>


<div>
</div>


<button hidden #openMasterCreationModal data-target="#masterCreationModal"data-toggle="modal"></button>
<div
  class="modal"
  id="masterCreationModal"
  role="dialog"
  aria-labelledby="masterModalLabel"
  data-backdrop="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex justify-content-center">
          <h4 class="modal-title">Create {{currentMaster}}</h4>
        </div>
        <button
          type=" button"
          class="close"
          #closeMasterCreationModal
          data-dismiss="modal"
          aria-label="Close"
		  (click)="closeMasterModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
	    <app-master-creation [masterType]="currentMaster" [masterCreationModal]="masterCreationModal" [currentMasterName]="currentMasterName" 
		(callSetFormData)="setFormValues($event)" (closeModal)="closeMasterModal()"
		></app-master-creation>
     </div>
    </div>
</div>