<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
<div class="ledger-container"  [ngClass]="{ 'ledger-print-section': isPrint == 'true' }">
 
 
  <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
    <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
      <div class="d-flex flex-y-center">
        <img
          src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
            alertType=='success-box' ? 'green-sucess.svg' : 
            alertType=='warning-box' ? 'red-warning.svg' : 
            null }}"
          width="20px"
          height="20px"
          alt="alert-logo"
          class="mr-20"
        />
        <div class="ml-10">
          <h4 class="m-0 text-size-18 text-bold"
          [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
            {{alertType === "error-box"
            ? "Error"
            : alertType === "warning-box"
            ? "Warning"
            : alertType === "success-box"
            ? "Success"
            : null}}
          </h4>
          <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
           {{message}}
          </small>
        </div>
      </div>
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
        alt="close-btn"
        (click)="hideError()"
        className=" close-btn"
      />
    </div>
  </div>

  <div *ngIf="leaderData != undefined">
    <div class="" id="print-section">
      <div>
        <div style="background: #fff!Important;"  class="row leader-header tab-nav pl-0 pr-0">
          <div class="col-sm-4 pr-0">
            <div *ngIf="isPrint">
            </div>
            <ul
              class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0"
            >
              <li
                class="text-grey text-capitalize cursor text-primary transition"
                [ngClass]="{ 'd-none': isPrint == 'false' }"
                [ngClass]="{ 'd-none': isPrint == 'true' }"
              >
               Balance Adjustment
              </li>
            </ul>
          </div>
          <div class="col-sm-4 text-center">
            <h4 style="font-size: 17px;" *ngIf="isPrint" class="text-uppercase text-bold text-black mt-2">
              Ledger Statement<br>
            </h4>
            <span class="rec-date d-block text-center">
              <div class="noWrap justify-content-around">
                <ng-container>
                  <div class="cname text-bold">
                    <span  [ngClass]="{ 'font-size-15': isPrint == 'true' }"  *ngIf="duration.start_date" 
                      >From</span
                    >
                    <h5 style="margin-right: 5px;" class="mb-0 d-inline">
                      <span [ngClass]="{ 'font-size-15': isPrint == 'true' }" 
                        class="date text-bold text-orange"
                        *ngIf="duration.start_date"
                      >
                        {{ duration.start_date }}
                      </span>
                      <span [ngClass]="{ 'font-size-15': isPrint == 'true' }"  class="date text-bold" *ngIf="duration.start_date">
                        To
                      </span>
                      <span [ngClass]="{ 'font-size-15': isPrint == 'true' }"  class="date text-bold text-orange">
                        {{ duration.end_date }}
                      </span>
                    </h5>
                  </div>
                </ng-container>
              </div>
            </span>
          </div>
          <div class="col-sm-4 d-flex align-item-center justify-content-end">
            <div class="text-right mt-2" *ngIf="isPrint">
              <span class="d-block text-black" [ngClass]="{ 'font-size-12': isPrint == 'true' }">{{currentDate | date : 'dd/MM/yyyy'}}</span>
              <span class="d-block text-grey"  [ngClass]="{ 'font-size-12': isPrint == 'true' }"> {{currentTime}}</span>
            </div>
			<div
			class="page-search-box input-group d-flex align-item-center mr-10" 
      [ngClass]="{ 'd-none': isPrint == 'true' }"
			style="height: fit-content; text-align: left"
		  >
			<input
			  type="text"
			  class="form-control"
			  [(ngModel)]="onSearch"
			  oninput="this.value = this.value.toUpperCase()"
			  (ngModelChange)="this.onSearchFilter.next($event)"
			  placeholder="search"
			  style="text-align: left"
			/>
		  </div>
            <div class="mt-2 align-item-center">
                    
            </div>
          </div>
        </div>

      </div>
      <div
        class="table-section"
        [ngClass]="{ 'table-print-section': isPrint == 'true' }"
      >
        <table class="table table striped" >
          <thead #tableHeader tabIndex="0">
            <tr *ngIf="isPrint" style="border-bottom: none!important;">
              <th colspan="7" class="page-top-space">
              </th>
            </tr>
            <tr class="transition">
              <th class="text-left date" style="max-width: 105px">Date</th>

              <th style="max-width: 175px " class="text-left doc-no">
                <span class="text-bold"
                  >Doc No
                  <i
                    *ngIf="ascSortRef"
                    (click)="refSort()"
                    class="fas fa-sort-alpha-up"
                  ></i>
                  <i
                    *ngIf="!ascSortRef"
                    (click)="refSort()"
                    class="fas fa-sort-alpha-down-alt"
                  ></i
                ></span>
              </th>
              <th class="text-left particular">Account Description</th>
              <th class="text-right voucher"><span class="text-center d-block text-bold"> Voucher</span></th>
              <th class="text-right debit" style="min-width: 180px; max-width: 190px">
                <span class="text-right d-block text-bold"> Debit</span>
               
              </th>
              <th class="text-right credit" style="min-width: 180px; max-width: 190px">
                <span class="text-right d-block text-bold">  Credit</span>
              </th>
              <th class="text-right balance" style="max-width: 155px">
               <span class="text-right d-block text-bold" > Balance</span>
              </th>
              <th class="text-right" style="max-width: 150px">
                <span class="d-block text-bold" > Adjusted Amount</span>
               </th>
               <th class="text-right" style="max-width: 150px">
                <span class="d-block text-bold" > Remaining Amount</span>
               </th>
               <th class="text-center" style="max-width: 155px">
                <span class="d-block text-bold" > Actions</span>
               </th>
            </tr>
          </thead>
          <tbody
            class="scroll-body main-panel"
            [ngStyle]="{ height: innerHeight }"
            (window:keydown)="onKeyDown($event)"
          >
            <ng-container
              *ngFor="let data of leaderData['all_clients_records']"
            >
              <ng-container
                *ngFor="
                  let client_record of data['client_records'];
                  let i = index
                "
              >
                <span class="client-name"
                  >{{ client_record["aggregated_display_name"] }}
                </span>
               
                <tr class="table-row-break"
                  *ngFor="let record of ledgerArr; let j = index"
                  [attr.tabIndex]="i + j"
                  id="rowIndex_{{ i }}_{{ j }}"
                  
                  [ngClass]="{
                    borderOrange: record?.amount_debit > 0,
                    borderGreen: record?.amount_credit > 0
                  }"
                >

                  <td
                  (click)="moveToIndex(i, j)"
                  style="max-width: 105px"
                  class="text-left text-grey date"
                >
                  {{ record?.date | date: "dd/MM/yyyy" }}
                </td>
                <td
                  style="max-width: 175px "
                  (click)="moveToIndex(i, j)"
                  class="text-left transition text-bold cursor doc-no refer"
                >
                  <a
                    [ngClass]="{
                      ' underline-none':
                        record.reference != null &&
                        record.reference != undefined
                    }"
                    >{{ record?.reference }}</a
                  >
                  <a class="d-block" *ngIf="record?.x_field_1">{{record?.x_field_1}}</a>
                </td>
                <td (click)="moveToIndex(i, j)" class="text-left text-grey particular">
                  <ng-container *ngFor="let narration of record.narration">
                    <ng-container *ngIf="narration.trim() != '' &&  narration.trim() != '|'">
                      <span class="text-grey d-block">{{ narration }} </span>
                    </ng-container>
                  </ng-container>
                </td>
                <td  class="text-right text-grey voucher">
                  <span class="text-center d-block">
                    {{ referenceTypeName[record?.reference_type] ? referenceTypeName[record?.reference_type] :  record?.reference_type }}
                  </span>
                </td>
                <td
                  (click)="moveToIndex(i, j)"
                  class="text-right text-red debit"
                  style="min-width: 180px; max-width: 190px"
                >
                  {{
                    record.amount_debit && record.amount_debit < 0
                      ? record.amount_debit * -1 
                      : record.amount_debit | number :'1.2-2'
                  }}
                </td>
                <td
                  (click)="moveToIndex(i, j)"
                  class="text-right text-green credit"
                  style="min-width: 180px; max-width: 190px"
                >
                  {{
                    record.amount_credit && record.amount_credit < 0
                      ? record.amount_credit * -1
                      : record.amount_credit | number :'1.2-2'
                  }}
                </td>
                <td
                  (click)="moveToIndex(i, j)"
                  class="text-right text-blue balance"
                  style="max-width: 155px"
                >
                  {{ record?.balance | number :'1.2-2'}}
                </td>
                <td
                (click)="moveToIndex(i, j)"
                class="text-right text-blue"
                style="max-width: 150px"
              >
                {{ record?.adjustment_amount ? ( record?.adjustment_amount | number :'1.2-2' ) : (0 | number :'1.2-2')}}
              </td>
              <td
              (click)="moveToIndex(i, j)"
              class="text-right text-blue"
              style="max-width: 150px"
            >
              {{ record?.transaction_remaining_amount ? ( record?.transaction_remaining_amount | number :'1.2-2' ) : (0 | number :'1.2-2')}}
            </td>
             
              <td class="edit-row text-center" style="max-width: 155px">
                  <button  title="Delete Row" class="custom-table-btn bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button" 
                   (click)="openConfirModal('delete', record)"
                    id="remove_row_receipt_btn_{{i}}" >
                    <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-delete.svg" alt="" width="20px">
                </button>
              </td>
                </tr>
              </ng-container>
            </ng-container>
      
            <tr *ngIf="!ledgerArr">
              <div class="w-100 text-center" style="padding: 20px">
                <span
                  ><img
                    class="not-found-img"
                    src="{{commonService.aws_asset_url}}/_assets/images/problem.png"
                /></span>
                <br />
                <span class="not-found-text">No result found</span>
              </div>
            </tr>
          </tbody>
          
        </table>
      
      </div>
      <div class="balance-box">
        <div></div>
        <div class="balance-container">
          <div class="bal-item text-bold">
            <span>Balance</span>
            <span class="bal-amount debit-amt text-bold" >
              {{
                totalBalance &&
                totalBalance < 0
                  ? (totalBalance * -1 | number: "1.2-2")
                  : (totalBalance | number: "1.2-2")
              }}
            </span>
          </div>
          <div class="bal-item text-bold">
            <span>Total {{referenceType}} Amount</span>
            <span class="bal-amount debit-amt text-bold" *ngIf="transactionAmount != 0">
              {{ transactionAmount | number: "1.2-2" }}
            </span>
          </div>

          <div class="bal-item text-bold">
            <span>Total Remaining Amount</span>
            <span class="bal-amount debit-amt text-bold" *ngIf="transactionAmount != 0">
              {{ deletedAmount | number: "1.2-2" }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button type="button" hidden #confirmModal data-toggle="modal" data-target="#confirmationModal"></button>

<div class="modal fade" id="confirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" #closeConfirmModal data-dismiss="modal" aria-label="Close" id="confirm_close_Modal_{{confirmationModalType}}_btn" (click)="resetCurrentModalType()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure to <b>" {{confirmationModalType}} this adjustment " </b> ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn-style mr-2" (click)="closeConfirmationModal()">No</button>
                <button type="button" class="btn-style bg-purple"
					            id="confirm_Opened_Modal_{{confirmationModalType}}_btn"
                    (click)="deleteAdjustment()">Yes</button>
            </div>
        </div>
    </div>
</div>
