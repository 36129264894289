import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../../transactions/store/actions';
import { UpdateAirlineComponent } from '../update-airline/update-airline.component';
import { TabService } from 'src/app/shared/services/tab.service';
import { Tab } from 'src/app/shared/model/tab.model';
@Component({
	selector: 'app-view-airline',
	templateUrl: './view-airline.component.html',
	styleUrls: ['./view-airline.component.scss']
})
export class ViewAirlineComponent implements OnInit {
	viewAllAirline: any;
	viewAirline: any;
	innerHeight: string;
	navigationSubscription;
	advancedSearchSubscription : Subscription;
	reInitialise = false;


	fromRoot = true;
	selector: string = '.main-panel';
	fromIndex: any = 0;
	toIndex: any = 30;
	onSearch;
	onSearchFilter = new Subject<string>();
	
	currentIndex=0
	@ViewChild('tableHeader', {static:true}) tableHeader : ElementRef
	isAdvancedSearchOpen: boolean = false;
	searchInputValue: any = "";
	duration: any;
	loader: boolean = false;
	queryParams: any;

	constructor(
		public commonService: CommonServiceService,
		private route: ActivatedRoute,
		private store: Store<InitialState>,
		private router: Router,
		private tabService : TabService
		) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	}
	ngOnInit() {

		// this.getGeneral()
		let queryParams = JSON.parse(JSON.stringify(this.route.queryParams['value']))
		if (queryParams.account_code != undefined) {
			localStorage.setItem('activeAirlineQueryParams',JSON.stringify(queryParams))
		}
		queryParams['limit'] = 100;
        queryParams['offset'] = this.fromIndex;
        this.search(queryParams)
        this.queryParams = queryParams
		this.reInitialise = true
		this.onSearchFilter.pipe(
			debounceTime(300),
			distinctUntilChanged())
			.subscribe(value => {
			  this.filterData(value)
		});

		
	}
	ngAfterViewInit(){
		this.advancedSearchSubscription = this.commonService.advancedSearch.subscribe(status=>{
			console.log('Is modal opened : ',status)
			this.isAdvancedSearchOpen = status
		})
	}
	
	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}

	openAdvanceSearchModal(){
		this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'airline'}));	
	}

	async filterData(value) {
        this.searchInputValue = value
        if (value.length >= 0) {
            this.queryParams['freetext'] = value
            let queryParams = JSON.parse(JSON.stringify(this.queryParams))
            this.queryParams = queryParams
			this.fromIndex=0
            queryParams['limit'] = 100;
            queryParams['offset'] = this.fromIndex;
            let resp = await this.getData(queryParams)
            if(resp){
                this.viewAirline = resp['data']
				let firstRow = document.getElementById('rowIndex_1') as HTMLElement
				if(firstRow){
					firstRow.scrollIntoView()
				}

            }
           
        this.setHeight(window.innerHeight)
        } else {
            delete this.queryParams['freetext']
            this.search(this.queryParams)
            this.setHeight(window.innerHeight)
        }
    }

	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
		if (this.advancedSearchSubscription) {
			this.advancedSearchSubscription.unsubscribe()
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setHeight(event.target.innerHeight)
	}


	async search(values) {
		// this.spinner.show()
		this.loader = true
		let resp = await this.getData(values)
		if(resp){
			this.loader = false
			this.viewAirline = resp['data']
			this.duration = resp['duration']
			this.viewAllAirline = resp['data']
			this.focusOnTableHeader()
			this.currentIndex = 0
			
			this.setHeight(window.innerHeight)
		}
	}

	getData(values){
		return new Promise(async (resolve, reject) => {
			this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/airline`, values).subscribe(res => {
				resolve(res)
			},err=> {
				resolve(null)
			})
		})
	}

	setHeight(height) {
		if (this.viewAirline != undefined) {
			if (height == undefined) {
				height = window.innerHeight
			}
			if (this.viewAirline.length <= 9) {
				this.innerHeight = height - 180 + 'px';
			}
			else {
				this.innerHeight = height - 147 + 'px';
			}
		}
	}

	open_airline(airline_id, airline_code, airline_name){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/master/airline/update-airline/${airline_id}`
		if(window.location.protocol != 'file:'){

			let client = airline_id
			if(client) {
				this.tabService.addTab(
					new Tab(UpdateAirlineComponent, `A-${airline_code}`, { airline_id: airline_id }, airline_name )
					);
				}
			
			else {
		    	window.open(path,'_blank')
			}
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
						hashPath : path
					}
				  });
				window.dispatchEvent(customeEventHandler)
			}
	}


	async onScrollDown(event) {
		console.log('scroled')
		this.fromIndex +=100
		// let toIndex = Number(this.toIndex) + 10
		let queryParams = JSON.parse(JSON.stringify(this.queryParams))
		queryParams['limit'] = 100;
		queryParams['offset'] = this.fromIndex;
		let resp = await this.getData(queryParams)
		if(resp){
			this.viewAirline = this.viewAirline.concat(resp['data'])
			this.setHeight(window.innerHeight)
		}
	  }
	  onScrollUp(event) {
	  }


	// keyboard integration
	// Add #tableHeader on table header

	focusOnTableHeader() :void{
		this.tableHeader.nativeElement.focus()
	}

	// @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false){
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.currentIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex+1}`).focus()
			this.currentIndex = this.currentIndex+1
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.currentIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex-1}`).focus()
			this.currentIndex = this.currentIndex-1
		}
	}

	moveToIndex(i){
		document.getElementById(`rowIndex_${i+1}`).focus()
		this.currentIndex = i+1
	}
}
