<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<div *ngIf="valid && paymentData"  class="pdf-print-view" [innerHTML]="paymentData">
</div>

<div style="display: flex; justify-content:center;align-items: center;">
  <div style="margin-top:20px; text-align: center;" class="d-flex justify-content-center actionbtn mr-2">
      <button class="btn-style bg-green" (click)="downloadPdf(paymentId)">Print</button>
  </div>
  <div *ngIf="showPartyName" style="margin-top:20px; text-align: center;" class="d-flex justify-content-center actionbtn">
      <button class="btn-style bg-water-blue" (click)="openBillingDetails()">Edit Party</button>
  </div>
</div>

<button type="button" hidden #openBillingDetailsModal data-toggle="modal" data-target="#billingDetailsModal"></button>
  <div
    class="modal"
    id="billingDetailsModal"
    aria-labelledby="billingDetailsModal"
    data-backdrop="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="margin-top:100px;">
        <div class="modal-header">
          <div class="d-flex justify-content-center">
            <h4 class="modal-title">Update Billing Details</h4>
          </div>
          <button
            type=" button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="updateBillingDetailsForm" >
            <div>
              <div class="form-group text-input-field">
                <textarea
                  class="input-field ng-pristine ng-valid ng-touched text-size-12"
                  rows="5"
                  id="billing_details"
                  formControlName="display_billing_details"
                ></textarea>
                <label for="billing_details" class="mb-0">Billing Details</label>
              </div>
            </div>
            
            <div class="mt-3 d-flex justify-content-center align-item-center">
              <button type="button" class="btn-style mr-2" data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                class="btn-style bg-green"
                [disabled]="!updateBillingDetailsForm.valid"
                (click)="updateDisplayBilling()">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  