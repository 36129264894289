import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/app/transactions/payment/services/payment.service';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { GlobalVariable } from 'src/app/shared/global-variable';

@Component({
	selector: 'app-ledger-filter-search',
	templateUrl: './ledger-filter-search.component.html',
	styleUrls: ['./ledger-filter-search.component.scss']
})

export class LedgerFilterSearchComponent implements OnInit {  
	modalRef: BsModalRef;
	ledgerFilterForm: FormGroup;
	clientArr: any;
	salesPersonArr: any;
	keyword = 'name';
	cityKeyword = 'city';

	ledgerList: any;
	groupByledgerList: any;
	cityArr: any;
	all = {
		name : "All",
		account_code : ""
	}

	minDate = "2019-04-01"
	maxDate = moment().format('YYYY-MM-DD')
	allGroupArr:any = []
	// @ViewChild('accounttype',{static:true}) accounttype;
	@ViewChild('accountAC',{static:true}) accountAC;
	@ViewChild('clientGroup',{static:true}) clientGroup;

	@ViewChild('subledgerAC',{static:true}) subledgerAC;
	@ViewChild('salesprAC',{static:true}) salesprAC;
	@ViewChild('cityAC',{static:true}) cityAC;
	@ViewChild('dpYMD2',{static:true}) dpYMD2;
	@ViewChild('dpYMD1',{static:true}) dpYMD1;
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
	@ViewChild('closeModal', {static : true})  closeModal : ElementRef
	constructor(
		public paymentService: PaymentService,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private router: Router,private store: Store<InitialState>) {
			this.store.select('sharedReducers').subscribe((items)=>{
				if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
					this.maxDate = items.financeYear.end_date
				}
				
		})
	}

	ngOnInit() {		
		this.ledgerFilterForm = this.formBuilder.group({
			account_type: ['client'],
			client: [''],
			client_name: [''],
			group_name:[''],
			group:[''],
			sales_person: [''],
			sales_person_name: [''],
			start_date: [this.minDate ],
			end_date: [this.maxDate],
			pay_rec_date :[this.maxDate],
			credit_note_date :[this.maxDate],
			category: [''],
			subledger_type: [''],
			sub_ledger_code: [''],
			sub_ledger_code_name: [''],
			balance_type: ['true'],
			city_id: [''],
			viewMode: ['normal'],
			group_by_subledger_type: [''],
			group_by_sub_ledger: [''],
			grouping : [false]
		})
		this.ledgerList = []
		this.ledgerList.push(this.all)
		this.getAllGroups()
		this.setFormData()

	}

	setFormData() {
		
		let queryParams = localStorage.getItem('activeLedgerQueryParams')
		if (queryParams != null) {
			let parsedParams = JSON.parse(queryParams)
			this.ledgerFilterForm.controls.account_type.setValue(parsedParams.account_type ? parsedParams.account_type : "client")
			this.getClient(parsedParams.client ? parsedParams.client : "",true)
			// this.ledgerFilterForm.controls.client.setValue(parsedParams.client ? parsedParams.client : "")
			this.getSalesPerson(parsedParams.sales_person ? parsedParams.sales_person : "", true)
			this.ledgerFilterForm.controls.start_date.setValue(moment(this.minDate).format('YYYY-MM-DD') )
			this.ledgerFilterForm.controls.end_date.setValue(moment(this.maxDate).format('YYYY-MM-DD'))
			this.ledgerFilterForm.controls.category.setValue(parsedParams.category ? parsedParams.category : "")
			this.ledgerFilterForm.controls.subledger_type.setValue(parsedParams.subledger_type ? parsedParams.subledger_type : "")
			this.getLedgers(parsedParams.sub_ledger_code ? parsedParams.sub_ledger_code : "",true)
			this.ledgerFilterForm.controls.balance_type.setValue(parsedParams.bf_balance ? parsedParams.bf_balance : "true")
			// this.ledgerFilterForm.controls.city_id.setValue(parsedParams.city_id)
			this.ledgerFilterForm.controls.viewMode.setValue(parsedParams.viewMode ? parsedParams.viewMode : 'normal')
		}
	  }

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'customModal' })
		);
	}

	closeModalFn(){
		this.closeModal.nativeElement.click()
	}
	getClient(value,bindValue = false) {
		if (value.length > 2) {
			let searchType = this.ledgerFilterForm.value.account_type
			this.commonService.getOkount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.clientArr = res
				if(bindValue && this.clientArr.length>0){
					this.ledgerFilterForm.controls['client'].setValue(this.clientArr[0].account_code)
					this.ledgerFilterForm.controls['client_name'].setValue(this.clientArr[0].name)
				}
			}, err => {

			})
		}
	}
	getSalesPerson(value,bindValue=false) {
		if (value.length > 2) {
			this.commonService.getOkount(`employee/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.salesPersonArr = res
				if(bindValue && this.salesPersonArr.length>0){
					this.ledgerFilterForm.controls['sales_person'].setValue(this.salesPersonArr[0].account_code)
					this.ledgerFilterForm.controls['sales_person_name'].setValue(this.salesPersonArr[0].name)
				}
			}, err => {

			})
		}
	}
	getCity(value) {
		if (value.length > 2) {
			this.commonService.getOkount(`location/allcity?city_name=${value.toUpperCase()}`).subscribe(res => {
				this.cityArr = res
			}, err => {

			})
		}
	}

	
	reset() {
		
		let startDate = moment('2021-04-01').format('YYYY-MM-DD')
		let endDate = moment().format('YYYY-MM-DD')

		this.ledgerFilterForm = this.formBuilder.group({
			account_type: ['client'],
			client: [''],
			client_name: [''],
			group_name:[''],
			group:[''],
			sales_person: [''],
			sales_person_name: [''],
			start_date: [this.minDate],
			end_date: [this.maxDate],
			pay_rec_date :[this.maxDate],
			credit_note_date :[this.maxDate],
			category: [''],
			subledger_type: [''],
			sub_ledger_code: [''],
			sub_ledger_code_name: [''],
			balance_type: ['true'],
			city_id: [''],
			viewMode: ['normal'],
			group_by_subledger_type: [''],
			group_by_sub_ledger: [''],
			grouping : [false]
		})
		this.ledgerList = []
		this.ledgerList.push(this.all)
		localStorage.removeItem('activeLedgerQueryParams')
	}
	clearData(formControl){
		this.ledgerFilterForm.controls[formControl].setValue("")
		this.ledgerFilterForm.controls.client.setValidators(Validators.required)
	}

	clearGroup(event,formControl){
		if(event.target.value!='client'){
			this.ledgerFilterForm.controls[formControl].setValue("")
			this.ledgerFilterForm.controls['viewMode'].setValue("normal")
		}
	}

	updateViewMode(event){
		if(event.target.value!='')
		{
			this.ledgerFilterForm.controls['viewMode'].setValue('summary')
		}
		else {this.ledgerFilterForm.controls['viewMode'].setValue('normal')}
	}




	search(values) {
	
		try {
			if(this.ledgerFilterForm.controls.group && this.ledgerFilterForm.controls.group.value.length==0)this.ledgerFilterForm.controls.client.setValidators(Validators.required)
		if (this.ledgerFilterForm.invalid || (values.client == "" && this.ledgerFilterForm.controls.group && this.ledgerFilterForm.controls.group.value.length==0)) {
			return false
		}
		else{
			GlobalVariable.openModalStatus = false;
			if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
				values.start_date = ""
			}
			else {
				values.start_date = moment(values.start_date).format('YYYY-MM-DD')
			}
			if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date" || values.end_date == "Invalid date") {
				values.end_date = ""
			}
			else {
				values.end_date = moment(values.end_date).format('YYYY-MM-DD')
			}
			if (values.credit_note_date == null || values.credit_note_date == "" || values.credit_note_date == "Invalid Date" || values.credit_note_date == "Invalid date") {
				values.credit_note_date = ""
			}
			else {
				values.credit_note_date = moment(values.credit_note_date).format('YYYY-MM-DD')
			}
			if (values.pay_rec_date == null || values.pay_rec_date == "" || values.pay_rec_date == "Invalid Date" || values.pay_rec_date == "Invalid date") {
				values.pay_rec_date = ""
			}
			else {
				values.pay_rec_date = moment(values.pay_rec_date).format('YYYY-MM-DD')
			}
	
			let clientCode= values.client
			if (values.client.account_code != undefined) {
				clientCode= values.client.account_code
			} 
			let salesPersonCode= values.sales_person
			if (values.sales_person.account_code != undefined) {
				salesPersonCode= values.sales_person.account_code
			} 
			let subLedgerCode= values.sub_ledger_code
			if (values.sub_ledger_code.account_code != undefined) {
				subLedgerCode= values.sub_ledger_code.account_code
			} 
	
			if (values.sub_ledger_code.name == "All") {
				subLedgerCode= 'All'
			} 
	
			let cityId= values.city_id
			if (values.city_id.id != undefined) {
				cityId= values.city_id.id
			} 
			let groupBySubLedgerCode= values.group_by_sub_ledger
			if (values.viewMode == 'summary') {
				values.grouping = false
				groupBySubLedgerCode = 'all'
				clientCode = ''
			}
			if(values.grouping && values.viewMode != "detailed" ) {
				values.viewMode = 'subgroup';
			}

			//client=1&start_date=2020-01-01&end_date=2020-04-10&sub_ledger_code=C0051&city_id=1&group_by_sub_ledger=C0051
			
			this.closeModalFn()
			this.router.navigateByUrl(`display/account-book/ledger/${values.viewMode}-ledger?viewMode=${values.viewMode}&sub_ledger_code=${subLedgerCode}&start_date=${values.start_date}&end_date=${values.end_date}&city_id=${cityId}&client=${clientCode}&group_by_sub_ledger=${groupBySubLedgerCode}&sales_person=${salesPersonCode}&bf_balance=${values.balance_type}&account_type=${values.account_type}&subledger_type=${values.subledger_type}&grouping=${values.grouping}&group=${values.group}&credit_note_date=${values.credit_note_date}&pay_rec_date=${values.pay_rec_date}` )
			
			// /display/statement-account/outstanding-report
			// this.reset()

		}
		} catch (error) {
			console.log(error)
		}
	}
	getFilteredData(queryParams) {
		this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/reports/ledger`, queryParams).subscribe(res => {
			console.log(res);
		}, err => {
			console.error(err);
		})
	}


	getLedgers(value,bindValue = false) {
		try {
			let searchType = this.ledgerFilterForm.value.subledger_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.paymentService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
					this.ledgerList = res;
					if(bindValue && this.ledgerList.length>0){
						this.ledgerFilterForm.controls['sub_ledger_code'].setValue(this.ledgerList[0].account_code)
						this.ledgerFilterForm.controls['sub_ledger_code_name'].setValue(this.ledgerList[0].name)
					}
					this.ledgerList.unshift(this.all)
				});
			}
		} catch (error) {

		}
	}
	getLedgersGroupBy(value) {
		try {
			let searchType = this.ledgerFilterForm.value.group_by_subledger_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.paymentService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
					this.groupByledgerList = res;
				});
			}
		} catch (error) {

		}
	}

	getAllGroups(){
        this.commonService.getOkount('v3account/getAllClientGroupName').subscribe(res => {
            this.allGroupArr = res
			console.log('allgroupArr',this.allGroupArr)
        }, err => {

        })
    }


	

	selectAccount(value,formControlName){
		try {
			this.ledgerFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}

	selectGroup(value,formControlName){
		try {
			this.ledgerFilterForm.controls[formControlName].setValue(value['id'])
			
		} catch (error) {

		}
	}

	getValue(v){
		console.log(v)
	}


	ngAfterViewInit(){
		this.commonService.selectedSearchInput.subscribe(index=>{
			// if (index == 1) {
			// 	this.focusaccounttype()
			// }
			if(index == 7 || (index==6 && this.ledgerFilterForm.controls && this.ledgerFilterForm.controls.account_type && this.ledgerFilterForm.controls.account_type.value!='client')){
				this.focusAccountAc()
			}

			if(index == 10){
				this.focusSalesprAc()
			}

			if(index == 9){
				this.focusSubledgerAc()
			}
			if(index == 12){
				this.focusCityAc()
			}

		})
	}

	// focusaccounttype() {
	// 	this.accounttype.focus();
	// }

	focusAccountAc(){
		this.accountAC.focus();
	}

	focusClientGroup(){
		this.clientGroup.focus();
	}



	focusSalesprAc(){
		this.salesprAC.focus();
	}


	focusSubledgerAc(){
		this.subledgerAC.focus();
	}

	focusCityAc(){
		this.cityAC.focus();
	}

	clickSearchButton(){
		this.searchButton.nativeElement.click()
	}

}
