<div class="ledger-container p-0" style="margin-top: -3px;">
    <div class="table-container  docs-tbl">
        <div class="table fixed-tbody-height">

            <nz-table class="table"
                #gstData [nzData]="gstArr && gstArr['data']"
                [nzLoading]="loader"
                [nzFrontPagination]="false"
            >
                <thead>
                    <tr>
                        <th style="width: 210px;min-width: 210px;" class="text-left">Nature of Document</th>
                        <th style="width: 170px;min-width: 170px;" class="text-left">Sr. No. From</th>
                        <th                       class="text-left">Sr. No. To</th>
                        <th                       class="text-right">Total Number</th>
                        <th style="width: 100px;min-width: 100px;" class="text-right">Cancelled</th>
                    </tr>
                </thead>

                <ng-container *ngIf="gstData && gstData['data'] && gstData['data'].length>0 else noResult">
                    <tbody class="main-panel" (window:keydown)="onKeyDown($event)" [ngStyle]="{height: 'calc(100vh - 181px)'}">
                        <tr class="field-table-input" *ngFor="let data of gstArr['data'] let i = index"
                            [attr.tabIndex]="i+1" id="rowIndex_{{i+1}}">
                            <td style="text-decoration: none; width: 210px;min-width: 210px; color:#737373;" class="text-left text-grey refer ellipse-text">
                                {{data?.nature_of_documents}}</td>
                            <td style="width: 170px;min-width: 170px;text-align: justify!important;" class="text-left text-black font-500">{{data?.from_reference}}</td>
                            <td style="text-align: justify!important;" class="text-left text-black font-500">{{data?.to_reference}}</td>
                            <td class="text-right text-black font-500">{{data?.total_number}}</td>
                            <td style="width: 100px;min-width: 100px;" class="text-right text-grey">{{data?.total_cancelled}}</td>
    
                        </tr>
                    </tbody>
                    <tfoot *ngIf="gstArr && gstArr['summary']" class="table-tfoot-bg">
                        <tr>
                            <td style="width: 210px;min-width: 210px;" class="">
                              <span class="font-500" >Total rec.: </span>
                            </td>
                            <td style="width: 170px;min-width: 170px;" class=""> <span class="text-right font-500">{{gstArr['summary']?.total_record }}</span></td>
                            <td class=""></td> 
                            <td class="text-right grnd-total">{{gstArr['summary']?.total_number }}</td>
                            <td style="width: 100px;min-width: 100px;" class="text-right grnd-total">{{gstArr['summary']?.total_cancelled }}</td>
                        </tr>
                    </tfoot>
                </ng-container>

                <ng-template #noResult>
                    <div *ngIf="!loader" class="w-100 text-center" style="padding: 20px;">
                        <nz-empty nzNotFoundContent="No result Found"></nz-empty>
                    </div>
                </ng-template>
            
            </nz-table>
        </div>
    </div>

</div>