import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { CommonServiceService } from '../../common-service.service';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';

@Component({
	selector: 'app-flown-report-search',
	templateUrl: './flown-report-search.component.html',
	styleUrls: ['../global-search.component.scss']
})
export class FlownReportSearchComponent implements OnInit {
	modalRef: BsModalRef;
	invoiceFilterForm: FormGroup;
	invoiceArr: any;
	clientArr: any;
	keyword = 'name';
	@ViewChild('accountAC', { static: true }) accountAC;
	@ViewChild('searchButton', { static: true }) searchButton: ElementRef;
	minDate = "2023-04-01"
    maxDate = moment().format('YYYY-MM-DD')
	constructor(
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private router: Router, 
		private store: Store<InitialState>) {
			this.store.select('sharedReducers').subscribe((items)=>{
				if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
					this.minDate = items.financeYear.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				} else {
					let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
					if(!current_financial_year) {
						current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
					}
					this.minDate = current_financial_year.start_date
					this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
					            moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
				}	  
			  })
	}

	ngOnInit() {

		// this.spinner.show();
		// setTimeout(() => {
		// 	this.spinner.hide();
		// }, 1000);

		this.invoiceFilterForm = this.formBuilder.group({
			account_type: ['client'],
			account_code: [''],
			account_name: [''],
			inv_start_date: [this.minDate, this.checkDate('inv_start_date')],
			inv_end_date: [this.maxDate, this.checkDate('inv_end_date')],
			travel_start_date: [this.minDate],
			travel_end_date: [this.maxDate],
			include_invoice: [true],
			include_credit_note: [true]
		})
		this.setFormData()
	}

	checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}

	focusAccountAc() {
		this.accountAC.focus();
	}




	setFormData() {
		let queryParams = localStorage.getItem('activeFlownReportQueryParams')
		if (queryParams != null) {
			let parsedParams = JSON.parse(queryParams)
			console.log('Active Query Params', parsedParams)
            this.getClient(parsedParams.account_code ? parsedParams.account_code : "",true)
			// this.invoiceFilterForm.controls.client.setValue(parsedParams.client)
			if (parsedParams.inv_start_date != "") {
				this.invoiceFilterForm.controls.inv_start_date.setValue(moment(parsedParams.inv_start_date).format('YYYY-MM-DD'))
			}
			if(parsedParams.inv_end_date != ""){
				this.invoiceFilterForm.controls.inv_end_date.setValue(moment(parsedParams.inv_end_date).format('YYYY-MM-DD'))
			}

			if (parsedParams.travel_start_date != "") {
				this.invoiceFilterForm.controls.travel_start_date.setValue(moment(parsedParams.travel_start_date).format('YYYY-MM-DD'))
			}

			if(parsedParams.travel_end_date != ""){
				this.invoiceFilterForm.controls.travel_end_date.setValue(moment(parsedParams.travel_end_date).format('YYYY-MM-DD'))
			}
			
			this.invoiceFilterForm.controls.include_invoice.setValue(parsedParams.include_invoice?parsedParams.include_invoice:true)
			this.invoiceFilterForm.controls.include_credit_note.setValue(parsedParams.include_credit_note?parsedParams.include_credit_note:true)
			this.invoiceFilterForm.controls.account_type.setValue(parsedParams.account_type?parsedParams.account_type:"client")
		}
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({}, { class: 'customModal' })
		);
	}

	getClient(value,bindValue) {
		if (value.length > 2) {
			let searchType = this.invoiceFilterForm.value.account_type
			this.commonService.getOkount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.clientArr = res
				if(bindValue && this.clientArr.length>0){
					this.invoiceFilterForm.controls['account_code'].setValue(this.clientArr[0].account_code)
					this.invoiceFilterForm.controls['account_name'].setValue(this.clientArr[0].name)
				}
			
			}, err => {

			})
		}
	}

	reset() {
		GlobalVariable.openModalStatus = false;
		this.invoiceFilterForm = this.formBuilder.group({
			account_type: ['client'],
			account_code: [''],
			account_name: [''],
			inv_start_date: [''],
			inv_end_date: [''],
			travel_start_date: [''],
			travel_end_date: [''],
			include_invoice: [true],
			include_credit_note: [true]
		})
	}

	search(values) {
		GlobalVariable.openModalStatus = false;
		if (values.inv_start_date == null || values.inv_start_date == "" || values.inv_start_date == "Invalid Date" || values.inv_start_date == "Invalid date") {
			values.inv_start_date = ""
		}
		else {
			values.inv_start_date = moment(values.inv_start_date).format('YYYY-MM-DD')
		}
		if (values.inv_end_date == null || values.inv_end_date == "" || values.inv_end_date == "Invalid Date" || values.inv_end_date == "Invalid date") {
			values.inv_end_date = ""
		}
		else {
			values.inv_end_date = moment(values.inv_end_date).format('YYYY-MM-DD')
		}

		if (values.travel_start_date == null || values.travel_start_date == "" || values.travel_start_date == "Invalid Date" || values.travel_start_date == "Invalid date") {
			values.travel_start_date = ""
		}
		else {
			values.travel_start_date = moment(values.travel_start_date).format('YYYY-MM-DD')
		}
		if (values.travel_end_date == null || values.travel_end_date == "" || values.travel_end_date == "Invalid Date" || values.travel_end_date == "Invalid date") {
			values.travel_end_date = ""
		}
		else {
			values.travel_end_date = moment(values.travel_end_date).format('YYYY-MM-DD')
		}

		debugger

		let account_code = values.account_code
		if (values.account_code.account_code != undefined) {
			account_code = values.account_code.account_code
		}
		this.router.navigateByUrl(`report/flown-report?account_type=${values.account_type}&account_code=${account_code}&inv_start_date=${values.inv_start_date}&inv_end_date=${values.inv_end_date}&travel_start_date=${values.travel_start_date}&travel_end_date=${values.travel_end_date}&include_invoice=${values.include_invoice}&include_credit_note=${values.include_credit_note}`)
		// this.reset()
		// this.commonService.getRequestWithQueryParams(`${environment.okountUrl}/invoice/filter`,values).subscribe(async (res)=>{
		// 	console.log(res)
		// 	debugger
		// 	let invoiceData = {
		// 		type : 'invoice',
		// 		invoiceData : res
		// 	}
		// 	await this.commonService.setData(invoiceData)

		// })
	}

	ngAfterViewInit() {
		this.commonService.selectedSearchInput.subscribe(index => {
			if (index == 8) {
				this.focusAccountAc()
			}
		})
	}

	clickSearchButton() {
		this.searchButton.nativeElement.click()
	}
	clearData(formControl) {
		this.invoiceFilterForm.controls[formControl].setValue("")
	}



selectAccount(value,formControlName){
try {
this.invoiceFilterForm.controls[formControlName].setValue(value.account_code)
} catch (error) {

}
}

}
