
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
  <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
    <div class="d-flex flex-y-center">
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
          alertType=='success-box' ? 'green-sucess.svg' : 
          alertType=='warning-box' ? 'red-warning.svg' : 
          null }}"
        width="20px"
        height="20px"
        alt="alert-logo"
        class="mr-20"
      />
      <div class="ml-10">
        <h4 class="m-0 text-size-18 text-bold"
        [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
          {{alertType === "error-box"
          ? "Error"
          : alertType === "warning-box"
          ? "Warning"
          : alertType === "success-box"
          ? "Success"
          : null}}
        </h4>
        <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
         {{message}}
        </small>
      </div>
    </div>
    <img
      src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
      alt="close-btn"
      (click)="hideError()"
      className=" close-btn"
    />
  </div>
</div>

<div class="ledger-container  ">
  <div class="row tab-nav pl-0 pr-0">
    <div class="col-sm-12 ">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Master</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Bank</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> View Bank</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li class="text-grey text-capitalize cursor text-primary transition"> Edit Bank</li>
      </ul>
  </div>
  
</div>


    <form [formGroup]="bankForm" #form="ngForm">
      <div class="custom-form form-container mt-4 pl-15 pr-15" style="    width: 600px;
      margin: 20px 0;">
      <div class="text-field-group d-flex">
        <div class="select-field text-input-field mr-2 col-10 p-0">
          <select class="input-field h-100" oninput="this.value = this.value.toUpperCase()" tabIndex="1" (change)="setHierarchy($event.target.value,false)"
            formControlName="parent_id" [ngClass]="{'error-field': (!bankForm.controls.parent_id.valid && submitted),
            'success-field' : bankForm.controls.parent_id.valid && bankForm.controls.parent_id.dirty}">
            <option selected disabled>SELECT</option>
            <option *ngFor="let item of groupList" value="{{item.id}}">{{item.name}}</option>
          </select>
          <label
            [ngClass]="{'error-label': bankForm.controls.parent_id.invalid && bankForm.controls.parent_id.dirty || (!bankForm.controls.parent_id.valid && submitted)}">Group
            <ng-container
              *ngIf="bankForm.controls.parent_id.invalid && bankForm.controls.parent_id.dirty || (!bankForm.controls.parent_id.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Group Required" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="2"
            formControlName="hierarchy" type="text" />
          <label>
            Hierarchy
          </label>
        </div>
      </div>
      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="3"
            formControlName="account_name" type="text" [ngClass]="{'error-field': bankForm.controls.account_name.invalid && bankForm.controls.account_name.dirty || (!bankForm.controls.account_name.valid && submitted) ,
              'success-field' : bankForm.controls.account_name.valid && bankForm.controls.account_name.dirty}" />
          <label
            [ngClass]="{'error-label': bankForm.controls.account_name.invalid && bankForm.controls.account_name.dirty || (!bankForm.controls.account_name.valid && submitted)}">Account

            <ng-container
              *ngIf="bankForm.controls.account_name.invalid && bankForm.controls.account_name.dirty || (!bankForm.controls.account_name.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid telephone Number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>


        <div style="width: 150px;" class="text-input-field mr-2" *ngIf="generalData?.account_code">
          <input class="input-field" [value]="generalData?.account_code"  type="text" readonly />
          <label>Account Code</label>
        </div>
      </div>

      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-3">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="account_number" tabIndex="14"
            formControlName="account_number" type="text" />
          <label>A/c Number</label>
        </div>

        <div class="text-input-field">
            <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="ifsc_code" tabIndex="13"
            formControlName="ifsc_code" type="text" />
            <label>IFSC</label>
        </div>
      </div>

      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="5"
            formControlName="state_id" (change)="getCities($event.target.value)">
            <option *ngFor="let state of stateArr" value="{{state.location_id}}">{{state.name}}</option>
          </select>
          <label>State</label>
        </div>

        <div class="text-input-field">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="6"
            formControlName="city_id" (change)="getCityName($event.target.value)">
            <option *ngFor="let city of cityArr" value="{{city.location_id}}">{{city.name}}</option>
          </select>
          <label>City</label>
        </div>
      </div>

      <div class="text-field-group d-flex">

        <div class="text-input-field mr-2" *ngIf="isTenantIndian">
          <input class="input-field" onblur="this.value = this.value.toUpperCase()" name="gst_number" tabIndex="5"
            formControlName="gst_number" type="text" maxlength="15"
            pattern="^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$"
            [ngClass]="{'error-field': bankForm.controls.gst_number.invalid && bankForm.controls.gst_number.dirty || (!bankForm.controls.gst_number.valid && submitted),
              'success-field' : bankForm.controls.gst_number.valid && bankForm.controls.gst_number.dirty}" />
          <label
            [ngClass]="{'error-label': bankForm.controls.gst_number.invalid && bankForm.controls.gst_number.dirty} || (!bankForm.controls.gst_number.valid && submitted)">
            GST NUMBER
            <ng-container
              *ngIf="bankForm.controls.gst_number.invalid && bankForm.controls.gst_number.dirty || (!bankForm.controls.gst_number.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid GST Number ( ex: 12ABCDE1234F1Z3)"
                aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field mr-2" *ngIf="!isTenantIndian">
          <input class="input-field" onblur="this.value = this.value.toUpperCase()" name="vat_number" tabIndex="5"
            formControlName="vat_number" type="text" maxlength="20"
            [ngClass]="{'error-field': bankForm.controls.vat_number.invalid && bankForm.controls.vat_number.dirty || (!bankForm.controls.vat_number.valid && submitted),
              'success-field' : bankForm.controls.vat_number.valid && bankForm.controls.vat_number.dirty}" />
          <label
            [ngClass]="{'error-label': bankForm.controls.vat_number.invalid && bankForm.controls.vat_number.dirty} || (!bankForm.controls.vat_number.valid && submitted)">
            {{vatAlternetname}} NUMBER
          </label>
        </div>
      
        <div class="text-input-field mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="balance" tabIndex="7"
            formControlName="balance" pattern="^\d*(\.\d{0,8})?$" type="text" [ngClass]="{'error-field': bankForm.controls.balance.invalid && bankForm.controls.balance.dirty,
              'success-field' : bankForm.controls.balance.valid && bankForm.controls.balance.dirty}" />
          <label
            [ngClass]="{'error-label': bankForm.controls.balance.invalid && bankForm.controls.balance.dirty}">Opening
            Balance
            <ng-container *ngIf="bankForm.controls.balance.invalid && bankForm.controls.balance.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid opening balance" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="select-field text-input-field mr-2">
          <select class="input-field h-100" tabIndex="8" formControlName="balance_type">
            <option value="debit">DR</option>
            <option value="credit">CR</option>
          </select>
        </div>

        <div class="select-field text-input-field">
          <select class="input-field h-100" tabIndex="9" formControlName="status">
            <option value="active">ACTIVE</option>
            <option value="inactive">INACTIVE</option>
          </select>
          <label>Status</label>
        </div>

      </div>
      
      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="swift_code"
                  formControlName="swift_code" type="text" />
          <label>SWIFT CODE</label>
        </div>

      </div>

      <span class="info alert-warning">
        <input type="checkbox" (change)="openBankUpdateInTransactionConfirmModal()" [checked]="checkBankBox"> <span class="ml-2">Mark this bank Primary Bank and details will be visible on all the print vouchers.</span>
      </span>
      <!-- <div class="select-field text-input-field" *ngIf="isHQCompany">
        <label for="selectedCompany" style="font-size: 13px; font-weight: 500!important;">Company</label>
        <ng-container *ngFor="let item of companies">
            <div *ngIf="item?.associate_company_id!==null" class="input-fiel" style="display: flex;align-items: center;">
              <input class="radio-ui mr-2" type="checkbox"  (change)="handleCompanyChange($event,item?.tenant_company_name)"  />
              <label class="radio-label" style="transform: none;font-size: 12px;margin-bottom: 0;" >{{item?.tenant_company_name}}</label>
            </div>
        </ng-container>
      </div> -->
      <div  class="select-field text-input-field" *ngIf="isHQBranch">
        <label for="selectedBranch" style="font-size: 13px; font-weight: 500!important;">Branch</label>
        <ng-container *ngFor="let item of branches">
          <div *ngIf="item?.branch_type=='BRANCH'" class="input-fiel" style="font-size: 13px; font-weight: 500!important;">
            <input class="radio-ui mr-2"  type="checkbox"  (change)="handleValueChange($event,item?.branch_name)"  />
            <label class="radio-label" style="transform: none;font-size: 12px;margin-bottom: 0;" >{{item?.branch_name}}</label>
          </div>
    </ng-container>
      </div>
      

    </div>
      <div class="btn-field bottom-btns-container full-width-btn-container d-flex align-item-center justify-content-end ml-0 mr-0">  
        <button type="button" class="btn-style bg-green mr-3" tabIndex="7">Reset</button>  
          <button type="button" class="btn-style bg-cyan mr-3" tabIndex="10"
          (click)="openUpdateConfirmModal()">Modify</button> 
       
        <button type="button" class="btn-style bg-red mr-3" (click)="openDeleteConfirmModal()"
          tabIndex="11">Delete</button>
        <button type="button" class="btn-style bg-purple-iris mr-3" tabIndex="12"
        (click)="openConfiguration(generalId)">Configuration</button>
        <button type="button" class="btn-style bg-indigo " id="general_activity_log" (click)="openActivity()">Activity</button> 

      </div>
    </form>
</div>


<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateconfirmationModal"></button>
<div id="updateconfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeUpdateModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        Update {{bankForm?.value?.account_name}} ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" tabIndex="14" attr.tabIndex="14"
          data-dismiss="modal">Close</button>
          <button type="button" class="btn-style bg-green" (click)="checkBalance()" data-dismiss="modal" tabIndex="13" attr.tabIndex="13">Yes</button>
      </div>
    </div>
  </div>
</div>

<button type="button" hidden #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button>

<div id="deleteConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeDeleteModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <p>Are you sure to delete ‎this entry ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style" tabIndex="14" attr.tabIndex="14" (click)="closeDeleteConfirmModal()"
          data-dismiss="modal">No</button>
          <button type="button" class="btn-style bg-red" tabIndex="13" attr.tabIndex="13" (click)="deleteGeneral()"
            data-dismiss="modal">Yes</button>
      </div>
    </div>
  </div>
</div>

<button type="button" hidden #addBankToPolicyConfirmation data-toggle="modal" data-target="#addBankToPolicyConfirmationModal"></button>

<div id="addBankToPolicyConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeBankUpdationModal  class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to use this bank in all vouchers?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style" tabIndex="14" attr.tabIndex="14" (click)="closeBankUpdateInTransactionConfirmModal()"
          data-dismiss="modal">No</button>
          <button type="button" class="btn-style bg-red" tabIndex="13" attr.tabIndex="13" (click)="updateBankInAllTransactions()"
            data-dismiss="modal">Yes</button>
      </div>
    </div>
  </div>
</div>

<button type="button" hidden #confirmOBModal data-toggle="modal" data-target="#updateOBModal"></button>
<div id="updateOBModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeOBModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        There is a mismatch of amount {{remainingAmount}} in your opening balance. It will be adjusted in the Opening Balance Adjustment account. Would you like to proceed?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" 
        data-dismiss="modal" (click)="closeOBConfirmModal()">Close</button>
        <button type="button" class="btn-style bg-green" (click)="updateOpeningBalancecombine()" >UPDATE</button>
      </div>
    </div>
  </div>
</div>