import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './shared/login/login.component';
import { AuthGuardService } from './shared/auth-guard.service';
import { UpdatePasswordComponent } from './user-management/update-password/update-password.component';
import { MasterSettingComponent } from './shared/master-setting/master-setting.component';



const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'master', loadChildren: './master/master.module#MasterModule',canActivate:[AuthGuardService]},
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule',canActivate:[AuthGuardService]},
  { path: 'transactions', loadChildren: './transactions/transactions.module#TransactionsModule'},
  { path: 'utilities', loadChildren: './utilities/utilities.module#UtilitiesModule',canActivate:[AuthGuardService]},
  { path: 'report', loadChildren: './report/report.module#ReportModule',canActivate:[AuthGuardService]},
  { path: 'user-management', loadChildren: './user-management/user-management.module#UserManagementModule',canActivate:[AuthGuardService]},
  { path: 'display', loadChildren: './display/display.module#DisplayModule',canActivate:[AuthGuardService]},
  { path: 'print', loadChildren: './display/display.module#DisplayModule'},
  { path: 'setup', loadChildren: './setup/setup.module#SetupModule',canActivate:[AuthGuardService]},
  { path: 'search', loadChildren: './shared/global-search/global-search.module#GlobalSearchModule',canActivate:[AuthGuardService]},
  { path: 'print-pdf', loadChildren: './print-pdf/print-pdf.module#PrintPdfModule'},
  { path: 'email-screen', loadChildren: './email-screen/email-screen.module#EmailScreenModule',canActivate:[AuthGuardService]},
  { path: 'login', component: LoginComponent},
  { path: 'update-password', component: UpdatePasswordComponent},
  { path: 'activity', loadChildren: './activity/activity.module#ActivityModule',canActivate:[AuthGuardService]},
  { path: 'master/setting/:master/:masterName/:accountCode', component: MasterSettingComponent,canActivate:[AuthGuardService]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
