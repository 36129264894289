import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonServiceService } from '../../common-service.service';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';
import { GlobalVariable } from '../../global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/reducer';

@Component({
  selector: 'app-banking-search',
  templateUrl: './banking-search.component.html',
  styleUrls: ['../global-search.component.scss']
})
export class BankingSearchComponent implements OnInit {
	@ViewChild('searchButton',{static:true}) searchButton : ElementRef;
  @ViewChild('salesprAC',{static:true}) salesprAC;
	bankingFilterForm: FormGroup;
  salesPersonArr: any;
	keyword = 'name';
	minDate = "2023-04-01"
  maxDate = moment().format('YYYY-MM-DD')
  constructor(
		public commonService: CommonServiceService,
    private formBuilder: FormBuilder,
    private router: Router,
    private store: Store<InitialState>
  ) { 
    this.store.select('sharedReducers').subscribe((items)=>{
      if(items.financeYear && items.financeYear.start_date && items.financeYear.end_date){
        this.minDate = items.financeYear.start_date
        this.maxDate = this.commonService.getFinancialSession(moment(items.financeYear.end_date)) < this.commonService.getFinancialSession(moment()) ? 
                    moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      } else {
        let current_financial_year =  JSON.parse(sessionStorage.getItem('current_financial_year'))
        if(!current_financial_year) {
          current_financial_year = JSON.parse(localStorage.getItem('current_financial_year'));
        }
        this.minDate = current_financial_year.start_date
        this.maxDate = this.commonService.getFinancialSession(moment(current_financial_year.end_date)) < this.commonService.getFinancialSession(moment()) ? 
                    moment(items.financeYear.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      }
      
  })
  }

  ngOnInit() {
    this.bankingFilterForm = this.formBuilder.group({
			start_date: [this.minDate, this.checkDate('start_date')],
			end_date: [this.maxDate, this.checkDate('end_date')],
      account_code: [''],
      account_name: [''],
      viewMode: ['cleared']
		})

    this.setFormData()

  }

  checkDate(formControlName){
		return (control: AbstractControl) => {
			let valid = true
			if(!moment(control.value, 'YYYY-MM-DD').isValid()) {
				valid = false
			} else {
				if(this){
					if (moment(control.value).isBefore(moment(this.minDate, 'YYYY-MM-DD')) || moment(control.value).isAfter(moment(this.maxDate, 'YYYY-MM-DD'))) {
						valid = false
					}
				}
			}
			
			return !valid ? {[formControlName]: {value: control.value}} : null;
		};
	}

  setFormData (){
    
      let queryParams = localStorage.getItem('activeBankReconcilationQueryParams')
      if (queryParams != null) {
        let parsedParams = JSON.parse(queryParams)
        console.log('Active Query Params', parsedParams)
        // this.invoiceFilterForm.controls.client.setValue(parsedParams.client)
          this.getSalesPerson(parsedParams.account_code ? parsedParams.account_code : "",true)
          this.bankingFilterForm.controls.start_date.setValue(moment(this.minDate).format('YYYY-MM-DD'))
          this.bankingFilterForm.controls.end_date.setValue(moment(this.maxDate).format('YYYY-MM-DD'))
          this.bankingFilterForm.controls.viewMode.setValue(parsedParams.viewMode?parsedParams.viewMode:'cleared')
        
        }
  }
  getSalesPerson(value,bindValue=false) {
    debugger
		if (value.length > 2) {
			this.commonService.getOkount(`general/bank?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.salesPersonArr = res
        if(bindValue && this.salesPersonArr.length>0){
          this.bankingFilterForm.controls['account_code'].setValue(this.salesPersonArr[0].account_code)
          this.bankingFilterForm.controls['account_name'].setValue(this.salesPersonArr[0].name)
        }
			}, err => {

			})
		}
	}

  ngAfterViewInit(){
		this.commonService.selectedSearchInput.subscribe(index=>{
			if(index == 3){
				this.focusSalesprAc()
			}
		})
  }
  
	focusSalesprAc(){
		this.salesprAC.focus();
  }
  
  search(values){
    debugger
    GlobalVariable.openModalStatus = false;
    if (values.start_date == null || values.start_date == "" || values.start_date == "Invalid Date" || values.start_date == "Invalid date") {
      values.start_date = ""
    }
    else {
      values.start_date = moment(values.start_date).format('YYYY-MM-DD')
    }
    if (values.end_date == null || values.end_date == "" || values.end_date == "Invalid Date" || values.end_date == "Invalid date") {
      values.end_date = ""
    }

    else {
      values.end_date = moment(values.end_date).format('YYYY-MM-DD')
    }

    let bank_code= values.account_code
      if (values.account_code.account_code != undefined) {
      bank_code= values.account_code.account_code

    } 

     this.router.navigateByUrl(`/utilities/banking/view-banking?account_code=${bank_code}&start_date=${values.start_date}&end_date=${values.end_date}`)
    //  this.reset()
   }

   reset() {
    GlobalVariable.openModalStatus = false;
		this.bankingFilterForm.reset()
  }
  clickSearchButton(){
		this.searchButton.nativeElement.click()
  }


	selectAccount(value,formControlName){
		try {
			this.bankingFilterForm.controls[formControlName].setValue(value.account_code)
		} catch (error) {

		}
	}
  
}
