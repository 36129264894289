import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../../transactions/store/actions';
import { Tab } from 'src/app/shared/model/tab.model';
import { TabService } from 'src/app/shared/services/tab.service';
import { UpdateGeneralComponent } from '../update-general/update-general.component';
@Component({
	selector: 'app-view-general',
	templateUrl: './view-general.component.html',
	styleUrls: ['./view-general.component.scss']
})
export class ViewGeneralComponent implements OnInit {
	viewAllGeneral: any;
	viewGeneral: any;
	innerHeight: string;
	navigationSubscription
	reInitialise=false;

	fromRoot = true;
	selector: string = '.main-panel';
	fromIndex: any = 0;
	toIndex: any = 30;
	onSearch;
	onSearchFilter = new Subject<string>();

	isAdvancedSearchOpen: boolean = false;
	currentIndex=0
	@ViewChild('tableHeader', {static:true}) tableHeader : ElementRef
	searchInputValue: any = "";
	duration: any;
	loader: boolean = false;
	queryParams: any;
	constructor(
		public commonService: CommonServiceService,
		private route: ActivatedRoute,
		private store: Store<InitialState>,
		private router: Router,
		private tabService : TabService) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	}

	ngOnInit() {
		let queryParams = JSON.parse(JSON.stringify(this.route.queryParams['value']))
		if (queryParams.account_code != undefined) {
			localStorage.setItem('activeGeneralQueryParams',JSON.stringify(queryParams))
			this.search(queryParams)
		} 
		queryParams['limit'] = 100;
        queryParams['offset'] = this.fromIndex;
        this.search(queryParams)
        this.queryParams = queryParams
		this.reInitialise=true
		this.onSearchFilter.pipe(
			debounceTime(300),
			distinctUntilChanged())
			.subscribe(value => {
			  this.filterData(value)
		});
	}
	openAdvanceSearchModal(){
		this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'general'}));	
	}
	async filterData(value) {
        this.searchInputValue = value
        if (value.length >= 0) {
            this.queryParams['freetext'] = value
            let queryParams = JSON.parse(JSON.stringify(this.queryParams))
            this.queryParams = queryParams
            this.fromIndex=0
            queryParams['limit'] = 100;
            queryParams['offset'] = this.fromIndex;
            this.loader = true
            let resp = await this.getData(queryParams)
            this.loader = false
            if(resp){
                this.viewGeneral = resp['data']
                let firstRow = document.getElementById('rowIndex_1') as HTMLElement
				if(firstRow){
					firstRow.scrollIntoView()
				}
            }
           
        this.setHeight(window.innerHeight)
        } else {
            delete this.queryParams['freetext']
            this.search(this.queryParams)
            this.setHeight(window.innerHeight)
        }
    }

	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}
	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setHeight(event.target.innerHeight)
	}

	async search(values) {
		// this.spinner.show()
		this.loader = true
		let resp = await this.getData(values)
		if(resp){
			this.loader = false
			this.viewGeneral = resp['data']
			this.duration = resp['duration']
			this.viewAllGeneral = resp['data']
			this.focusOnTableHeader()
			this.currentIndex = 0
			this.setHeight(window.innerHeight)
		}
	}

	getData(values){
		return new Promise(async (resolve, reject) => {
			this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/general?sub_type=chart_account`, values).subscribe(res => {
				resolve(res)
			},err=> {
				resolve(null)
			})
		})
	}

	setHeight(height) {
		if (this.viewGeneral != undefined) {
			if (height == undefined) {
				height = window.innerHeight
			}
			if (this.viewGeneral.length <= 9) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = height - 147 + 'px';
			}
		}
	}

	open_general(general_id, general_code, general_name){
		let  path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/master/general/update-general/${general_id}`
		if(window.location.protocol != 'file:'){
			let general = general_id
			if(general) {
				this.tabService.addTab(
					new Tab(UpdateGeneralComponent, `G-${general_code}`, { general_id: general_id }, general_name)
					);
			} else {
				window.open(path,'_blank')
			}
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

	async onScrollDown(event) {
		console.log('scroled')
		this.fromIndex +=100
		// let toIndex = Number(this.toIndex) + 10
		let queryParams = JSON.parse(JSON.stringify(this.queryParams))
		queryParams['limit'] = 100;
		queryParams['offset'] = this.fromIndex;
		let resp = await this.getData(queryParams)
		if(resp){
			this.viewGeneral = this.viewGeneral.concat(resp['data'])
			this.setHeight(window.innerHeight)
		}
	}
	  onScrollUp(event) {
	  }

	  focusOnTableHeader(){
		if(this.tableHeader != undefined){
			this.tableHeader.nativeElement.focus()
		}
	}
	
	// @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.currentIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex+1}`).focus()
			this.currentIndex = this.currentIndex+1
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.currentIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex-1}`).focus()
			this.currentIndex = this.currentIndex-1
		}
	}
	
	moveToIndex(i){
		document.getElementById(`rowIndex_${i+1}`).focus()
		this.currentIndex = i+1
	}

}
