import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../../transactions/store/actions';
import { UpdateEmployeeComponent } from '../update-employee/update-employee.component';
import { Tab } from 'src/app/shared/model/tab.model';
import { TabService } from 'src/app/shared/services/tab.service';
@Component({
	selector: 'app-view-employee',
	templateUrl: './view-employee.component.html',
	styleUrls: ['./view-employee.component.scss']
})
export class ViewEmployeeComponent implements OnInit {

	viewEmployee: any;
	innerHeight: string;
	navigationSubscription
	reInitialise = false;

	fromRoot = true;
	selector: string = '.main-panel';
	fromIndex: any = 0;
	toIndex: any = 30;
	onSearch;
	onSearchFilter = new Subject<string>();
	viewAllEmployee: any;

	isAdvancedSearchOpen: boolean = false;
	currentIndex=0
	@ViewChild('tableHeader', {static:true}) tableHeader : ElementRef
	searchInputValue: any = "";
	duration: any;
	queryParams: any;


	constructor(
		public commonService: CommonServiceService,
		private route: ActivatedRoute,
		private store: Store<InitialState>,
		private router: Router, 
		private tabService: TabService) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	}
	ngOnInit() {
		debugger
		let queryParams = JSON.parse(JSON.stringify(this.route.queryParams['value']))
		if (queryParams.account_code != undefined) {
			localStorage.setItem('activeEmployeeQueryParams',JSON.stringify(queryParams))
		} 
		queryParams['limit'] = 100;
        queryParams['offset'] = this.fromIndex;
		this.search(queryParams)
		this.queryParams = queryParams

		this.reInitialise = true
		this.onSearchFilter.pipe(
			debounceTime(300),
			distinctUntilChanged())
			.subscribe(value => {
			  this.filterData(value)
		});
	}

	openAdvanceSearchModal(){
		this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'employee'}));	
	}

	async filterData(value) {
		this.searchInputValue = value
        let tempArr = Object.assign([], this.viewAllEmployee)
        if (value.length >= 0) {
            this.queryParams['freetext'] = value
            let queryParams = JSON.parse(JSON.stringify(this.queryParams))
            this.queryParams = queryParams
            this.fromIndex=0
            queryParams['limit'] = 100;
            queryParams['offset'] = this.fromIndex;
            
            let resp = await this.getData(queryParams)
            
            if(resp){
                this.viewEmployee = resp['data']
                let firstRow = document.getElementById('rowIndex_1') as HTMLElement
				if(firstRow){
					firstRow.scrollIntoView()
				}
            }
           
        this.setHeight(window.innerHeight)
        } else {
            delete this.queryParams['freetext']
            this.search(this.queryParams)
            this.setHeight(window.innerHeight)
		}
	}

	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}
	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.setHeight(event.target.innerHeight)
	}

	async search(values) {
		// this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/corporate_employee`, values).subscribe(res => {
			let res = await this.getData(values)
			if(res){
				this.viewEmployee = res['data']
				this.duration = res['duration']
				this.viewAllEmployee = res['data']
				this.focusOnTableHeader()
				this.currentIndex = 0
				this.setHeight(window.innerHeight)
			}
			
			// this.currentIndex = 0
			// for (let i = 0; i <= 100; i++) {
			// 	const element = this.viewAllEmployee[i];
			// 	if (element != undefined) {
			// 		this.toIndex = i
			// 		this.viewEmployee.push(element)
			// 	}
			// }
		// })
	}


	getData(values){
		return new Promise(async (resolve, reject) => {
			this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/globalsearch/corporate_employee`, values).subscribe(res => {
				resolve(res)
			},err=> {
				resolve(null)
			})
		})
	}

	setHeight(height) {
		if (this.viewEmployee != undefined) {
			if (height == undefined) {
				height = window.innerHeight
			}
			if (this.viewEmployee.length <= 9) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = height - 147 + 'px';
			}
		}
	}

	open_employee(employee_id, employee_code, employee_name){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/master/employee/update-employee/${employee_id}`
		if(window.location.protocol != 'file:'){
			if(employee_id) {
				this.tabService.addTab(
					new Tab(UpdateEmployeeComponent, `E-${employee_code}`, { employee_id: employee_id }, employee_name)
					);
			} else {
				window.open(path,'_blank')
			}
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

	async onScrollDown(event) {
		console.log('scroled')
    this.fromIndex +=100
    // let toIndex = Number(this.toIndex) + 10
    let queryParams = JSON.parse(JSON.stringify(this.queryParams))
    queryParams['limit'] = 100;
    queryParams['offset'] = this.fromIndex;
    let resp = await this.getData(queryParams)
    if(resp){
        this.viewEmployee = this.viewEmployee.concat(resp['data'])
        this.setHeight(window.innerHeight)
    }
	  }
	  onScrollUp(event) {
	  }
	  focusOnTableHeader(){
		if(this.tableHeader != undefined){
			this.tableHeader.nativeElement.focus()
		}
	}
	
	// @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.currentIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex+1}`).focus()
			this.currentIndex = this.currentIndex+1
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.currentIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex-1}`).focus()
			this.currentIndex = this.currentIndex-1
		}
	}
	
	moveToIndex(i){
		document.getElementById(`rowIndex_${i+1}`).focus()
		this.currentIndex = i+1
	}

}
