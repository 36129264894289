import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DownloadService } from 'src/app/shared/download.service';
import * as moment from 'moment-timezone';
import { GlobalVariable } from 'src/app/shared/global-variable';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../../transactions/store/actions';
import { Tab } from 'src/app/shared/model/tab.model';
import { TabService } from 'src/app/shared/services/tab.service';
import { NormalLedgerComponent } from '../../account-book/ledger/normal-ledger/normal-ledger.component';
import * as FileSaver from 'file-saver';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'app-outstanding-report',
  templateUrl: './outstanding-report.component.html',
  styleUrls: ['./outstanding-report.component.scss']
})
export class OutstandingReportComponent implements OnInit {

	
	p=0;
	q=0;
	outStandingData: any;
	allOutstandingData: any;
	navigationSubscription;
	reInitialise: boolean;
	currentDate =  moment().format('YYYY-MM-DD')
	isPrint: any;

	innerHeight: string;
	currentIndex=0
	@ViewChild('tableHeader', {static:true}) tableHeader : ElementRef
	duration: any;
	waterMark;
	queryParams: any;
	loader: boolean = false;
	onSearch;
	onSearchFilter = new Subject<string>();

	constructor(
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private downloadService : DownloadService,
		private store: Store<InitialState>,
		private tabService : TabService


	) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
	}

	ngOnInit() {

		let queryParams = this.route.queryParams['value']
		this.queryParams = queryParams;
		if (queryParams.client != undefined) {
			localStorage.setItem('activeOutStandingQueryParams',JSON.stringify(queryParams))
			this.isPrint = queryParams.print

			this.getFilteredData(queryParams)
		} else {
			this.getData()
		}
		this.onSearchFilter.pipe(
			debounceTime(300),
			distinctUntilChanged())
			.subscribe(value => {
			  this.filterData(value)
		});
		this.reInitialise = true

	}
	initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}
	openAdvanceSearchModal(){
		this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'outstanding'}));	
	}

	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.outStandingData != undefined) {
			this.setHeight(this.p,this.q,event.target.innerHeight)
		}
	}


	//ALL DATA
	getData() {
		this.loader = true;
		this.commonService.getRequestNoloader(`${environment.okountUrl}/reports/outstanding`).subscribe(res => {
			this.outStandingData = res
			this.allOutstandingData = res['data']
			this.loader = false;
			if (this.outStandingData != undefined) {
				this.setHeight(0,0,window.innerHeight)
			}
		}, err => {
			this.loader = false;
			console.error(err)
		})
	}

	//FILTERED DATA
	getFilteredData(queryParams) {
		this.loader = true;
		this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/reports/outstanding`, queryParams).subscribe(res => {
			this.duration = res['duration']
			this.outStandingData = res
			this.allOutstandingData = res['data']

			if(res && res['company_details'] && res['company_details'].tenant_logo) {
				this.waterMark = `url('${res['company_details'].tenant_logo}')`
			}
			this.loader = false;
			if (this.outStandingData != undefined) {
				this.setHeight(0,0,window.innerHeight)
			}
		}, err => {
			this.loader = false;
			console.error(err);
		})
	}
	setHeight(pIndex,innerPIndex,height){
		if (height == undefined) {
			height = window.innerHeight
		}
		pIndex = Number(pIndex)
		innerPIndex = Number(innerPIndex)
		this.p = pIndex
		this.q = innerPIndex

		let parent = 0
		if (pIndex > 0) {
			parent = pIndex - 1
		}
		if (this.outStandingData['data'].length > 0) {
			if (this.outStandingData['data'].length <= 3) {
				this.innerHeight = 'auto';
			}
			else {
				this.innerHeight = height - 140 + 'px';
			}
		}
		else {
			this.innerHeight = height - 140 + 'px';
		}
	}

	openLedger(account_code){
		let queryParams = Object.assign({},this.route.queryParams['value'])
        
		if (queryParams.client == undefined) {
			queryParams = {
				"client" : ""
			}
		}
		
		if (account_code != undefined) {
			queryParams['client'] = account_code
		}
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/display/account-book/ledger/normal-ledger?client=${account_code}&start_date=${queryParams.start_date}&end_date=${queryParams.end_date}`
		if(window.location.protocol != 'file:'){
			if(account_code) {
				this.tabService.addTab(
					new Tab(NormalLedgerComponent, `Ledger-${account_code}`, { client: account_code , viewMode : 'view', 
					start_date: queryParams.start_date, end_date: queryParams.end_date , bf_balance:true 
				}, `Normal Ledger - ${account_code}`)
				);
			} else {
				window.open(path,"_blank");
			}
		}
		else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}

		// http://localhost:4200/#/display/account-book/ledger/normal-ledger?client=C0065
		// window.open(`/#/display/account-book/ledger/normal-ledger?{queryParams: queryParams}`, "_blank");
		// this.router.navigate(['/display/account-book/ledger/normal-ledger'], { queryParams: queryParams });
	}

	exportAsXLSX(fromDate, toDate):void {
		this.spinner.show()
		let xlsxData = [];
		let totalObj = {
			opening_balance:0,
			total_debit:0,
			total_credit:0,
			outstanding:0
		}
		this.outStandingData.data.forEach(element => {			
			let obj = { };
			let openingBalance = element.opening_balance ? parseFloat(element.opening_balance) : 0;
			let totalDebit = element.total_debit ? parseFloat(element.total_debit) : 0;
			let totalCredit = element.total_credit ? parseFloat(element.total_credit) : 0;
			let outstanding = element.outstanding ? parseFloat(element.outstanding) : 0;
			obj["Account Code"] = `${element.account_code}`;
			obj["Account"] = element.display_name;
			obj["Opening Balance"] = Number(openingBalance.toFixed(2));
			obj["Debit"] = Number(totalDebit.toFixed(2));
			obj['Credit'] = Number(totalCredit.toFixed(2));
			obj["Balance"] = Number(outstanding.toFixed(2));
			if(element.branch || element.branch=='') obj["Branch"] = element.branch?element.branch:'HQ';
			xlsxData.push(obj);
			totalObj.opening_balance += openingBalance;
			totalObj.total_debit += totalDebit;
			totalObj.total_credit += totalCredit;
			totalObj.outstanding += outstanding;
			
		});

		xlsxData.push({
			"Account Code": "TOTAL",
			"Opening Balance": Number(totalObj.opening_balance.toFixed(2)),
			"Debit": Number(totalObj.total_debit.toFixed(2)),
			"Credit": Number(totalObj.total_credit.toFixed(2)),
			"Balance": Number(totalObj.outstanding.toFixed(2)),
		})
		this.downloadService.exportAsXLSX(`CLIENT OUTSTANDING ${fromDate} - ${toDate}`, xlsxData);
		// this.xlsxDownloadService.exportAsExcelFile(xlsxData, `TDS REPORT ${fromDate} - ${toDate}`);
		this.spinner.hide()
	}


	downloadPdf(start_date, end_date) {
		this.commonService.downloadPDF(`${environment.okountUrl}/reports/outstanding/download`, this.queryParams).subscribe((fileBlob:any) =>{
			let fileName = `CLIENT OUTSTANDING REPORT ${start_date} - ${end_date}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in getInvoice due to : ', error)
	}

	callPdfGeneratorApi(fromDate, toDate){
		debugger;
		window.scrollTo(0,0)
		this.spinner.show()
		let url = 'print-pdf/pdf-client-outstanding'
		let queryString = location.hash.split('?')[1]
		if (queryString != undefined) {
			url = `${url}?${queryString}`
		}
		this.downloadService.callPdfGeneratorApi(`CLIENT OUTSTANDING ${fromDate} - ${toDate}.pdf`,url,{})	
		this.spinner.hide()
	}


	// keyboard integration
	// Add #tableHeader on table header

	focusOnTableHeader(){
		if(this.tableHeader != undefined){
			this.tableHeader.nativeElement.focus()
		}
	}
	
	// @HostListener('window:keydown', ['$event'])
	onKeyDown(e: any) {
		console.log('openModalStatus : ' + GlobalVariable.openModalStatus)
		if (GlobalVariable.openModalStatus == false && GlobalVariable.openGlobalSearch == false) {
			if (e.keyCode == 40) {
				e.preventDefault();
				this.moveToNext()
			} else if (e.keyCode === 38) {
				e.preventDefault();
				this.moveToPrev()
			}
		}
	}
	moveToNext(){
		if (document.getElementById(`rowIndex_${this.currentIndex+1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex+1}`).focus()
			this.currentIndex = this.currentIndex+1
		}
	}
	moveToPrev(){
		if (document.getElementById(`rowIndex_${this.currentIndex-1}`) != null) {
			document.getElementById(`rowIndex_${this.currentIndex-1}`).focus()
			this.currentIndex = this.currentIndex-1
		}
	}
	
	moveToIndex(i){
		document.getElementById(`rowIndex_${i+1}`).focus()
		this.currentIndex = i+1
	}

	filterData(value) {
		let tempArr = Object.assign([], this.allOutstandingData)
		if(value.length > 3){
			let lowerCaseFilter = value.toLowerCase()
			let upperCaseFilter = value.toUpperCase()
			
			this.outStandingData['data'] = tempArr.filter(
				clientData => {
					if (	
							(
								clientData.account != undefined && 
								clientData.account != null && 
								(	
									clientData.account.includes(value) || 
									clientData.account.includes(upperCaseFilter) ||
									clientData.account.includes(lowerCaseFilter)
								)
							) ||
							(
								clientData.display_name != undefined && 
								clientData.display_name != null && 
								(	
									clientData.display_name.includes(value) || 
									clientData.display_name.includes(upperCaseFilter) ||
									clientData.display_name.includes(lowerCaseFilter)
								)
							) ||
							(
								clientData.total_credit != undefined && 
								clientData.total_credit != null && 
								(	
									clientData.total_credit.toString().includes(value) || 
									clientData.total_credit.toString().includes(upperCaseFilter) ||
									clientData.total_credit.toString().includes(lowerCaseFilter)
								)
							) ||
							(
								clientData.total_debit != undefined && 
								clientData.total_debit != null && 
								(	
									clientData.total_debit.toString().includes(value) || 
									clientData.total_debit.toString().includes(upperCaseFilter) ||
									clientData.total_debit.toString().includes(lowerCaseFilter)
								)
							) || 
							(
								clientData.outstanding != undefined && 
								clientData.outstanding != null && 
								(	
									clientData.outstanding.toString().includes(value) || 
									clientData.outstanding.toString().includes(upperCaseFilter) ||
									clientData.outstanding.toString().includes(lowerCaseFilter)
								)
							)
						) {
						return clientData
					}
				}
			)
		}else{
			this.outStandingData['data'] = [];
			for (let i = 0; i <= this.allOutstandingData.length; i++) {
				const element = this.allOutstandingData[i];
				if (element != undefined) {
					this.outStandingData.data.push(element)
				}
			}
		}
		

	}

}
