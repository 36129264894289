import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddEntryProformaComponent } from './add-entry-proforma/add-entry-proforma.component';
import { ProformaInvoiceComponent } from './proforma-invoice.component';
import { PrintProformaComponent } from './print-proforma/print-proforma.component';


const routes: Routes = [

	{
		path: 'proforma', component: ProformaInvoiceComponent
	}, 
	{
		path: 'proforma/:mode/:invoice', component: AddEntryProformaComponent
	},
	{
		path: 'proforma/invoice/:mode/:transactionType', component: AddEntryProformaComponent
	},
	{
		path: 'proforma-print', component: PrintProformaComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ProformaInvoiceRoutingModule { }
