import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { LoaderService } from 'src/app/shared/loader.service';
import { ActivatedRoute } from '@angular/router';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { InvoiceRefundService } from './services/invoice-refund.service';
import { SectorRefundService } from './services/sector-refund.service';
import { InvoiceRequestBuilderRefundService } from './services/invoice-request-builder-refund.service';
import { TotalFareRefundModel } from './models/models/total-fare-refund-model';
import { TotalFareModel } from '../../invoice/flight/models/total-fare-model';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
	selector: 'app-flight-refund',
	templateUrl: './flight-refund.component.html',
	styleUrls: ['./flight-refund.component.scss']
})
export class FlightRefundComponent implements OnInit {
	@Input() data;

	keyword = 'name';
	airlineArr: any;
	employeeList: any;

	expandTraveller: boolean = false
	maximizedSection: boolean = false

	crdIdArr: any = [];
	retrievedData: any;
	supplierArr: any = [];

	selectedSupplier: any = [];
	@Output() callProductEvent: EventEmitter<any> = new EventEmitter();


	// invoiceMode = 'save';
	// invoice_no;
	@Input() invoiceMode;
    @Input() invoice_no;
	invoiceUUID: any;
	isPreviousYear: any;

	saveProducSubscription : Subscription;
	@Input() invoice_root_data
	//KEYBOARD FOCUS VARS
	@ViewChild('airlineAC',{static : true}) airlineAC;
	@ViewChild('subLedgerAC',{static : true}) subLedgerAC;
	isManual: boolean;
	retrievalConfig: any;
	@Input() sectorService;
	isTenantIndian: boolean = true;


	constructor(
		private invoiceService: InvoiceRefundService,
		// public sectorService: SectorRefundService,
		public requestBuilder: InvoiceRequestBuilderRefundService,
		private loaderService: LoaderService,
		private route: ActivatedRoute,
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
	) {
		this.route.paramMap.subscribe(params => {
			this.invoice_no = params['params'].invoice
			this.invoiceMode = params['params'].mode
		});
	}

	async ngOnInit() {
		this.isTenantIndian = this.commonService.checkTenantIndian()
		this.spinner.show()
		this.retrievalConfig = [{
			crs_id: "WEBSITE",
			display_name: "OTHER",
			display_key: "other",
			url: "",
			invioceType: "X",
			},
			{
			  crs_id: "1A",
			  display_name: "OTHER",
			  display_key: "other",
			  url: "",
			  invioceType: "B",
			},
			{
			  crs_id: "1G",
			  display_name: "OTHER",
			  display_key: "other",
			  url: "",
			  invioceType: "B",
		  }];
		this.isPreviousYear = this.route.queryParams['value']['isPreviousYear']
		this.isManual = this.route.queryParams['value']['isManual'] ? true : false
		let res = await this.getCrsId();
		let loggedInUser = JSON.parse(localStorage.okountUserInfo)
		this.sectorService.invoiceForm.controls.employee_name.setValue(loggedInUser.name)
		this.sectorService.invoiceForm.controls.employee.setValue(loggedInUser.accountcode)
		if ((this.invoiceMode == 'create' || this.invoiceMode == 'view') && this.invoice_no != undefined) {
			if (this.invoiceMode == 'create') {
				this.getCreditNote(this.invoice_no, 'flight')
			} else if (this.invoiceMode == 'view') {
				this.getInvoiceData(this.invoice_no, 'flight')
			}
			// this.getSupplier('website')
		} else {
			this.getSupplier('website')
		}

		this.saveProducSubscription = this.sectorService.saveProductSourceMethod.subscribe(status => {
			if (status == true) {
				this.saveProductInvoice()
			}
		}, error => {
		})


		this.sectorService.callToLoadFlightRefund.subscribe(res => {
			if (res['status'] == true && res['invoice_no'] && res['invoice_no']!=undefined) {
				this.invoice_no = res['invoice_no']
				this.sectorService.loadFlightRefund.next({
					'status':false,
					"invoice_no":undefined
				})
				this.getCreditNote(this.invoice_no, 'flight')
			}
		}, error => {
		})


		this.spinner.hide()


	}
	ngOnDestroy() {
		this.saveProducSubscription.unsubscribe();
	}
	
	focusAirlineAc(){
		if (this.airlineAC != undefined && this.airlineAC.focus) {
			this.airlineAC.focus()
		}
	}

	focusSubledgerAc(){
		if (this.subLedgerAC != undefined && this.subLedgerAC.focus) {
			this.subLedgerAC.focus()
		}
	}
	getData(searchId: any, type: any) {
		try {
			this.invoiceService.getMethod(`creditNote?credit_note_no=${searchId}&type=${type}`).subscribe(async (res) => {
				let creditNoteData = res['data'][0]
				this.getCreditNote(creditNoteData.invoice_code, 'flight')
			})
		} catch (error) {

		}
	}


	getInvoiceData(searchId: any, type: any) {
		try {
			this.sectorService.fareList = []
			this.sectorService.totalFareModel = []
			this.invoiceService.getMethod(`creditNote?credit_note_no=${searchId}&type=${type}`).subscribe(async (res) => {
				let refResponse = await this.getAllReferenceData(searchId)
				let creditNoteData = res['data'][0];
				let obj = {
					invoice_no : creditNoteData.credit_note_code,
					type : 'normal'
				}
				this.sectorService.fareList.push(obj)
				let crsId = creditNoteData['products'][0]['json_doc'].crs_id_value ? creditNoteData['products'][0]['json_doc'].crs_id_value : 'WEBSITE'
				let supplierRes = await this.getSupplier(crsId)
				let bindRes = await this.sectorService.bindCreditNoteData(creditNoteData['products'][0]['json_doc'], this.sectorService.fareList.length - 1)
				this.saveProductInvoice()	
				this.loaderService.hide()
				this.spinner.hide()
			}, err => {
				this.loaderService.hide()
				this.spinner.hide()
				console.log(err)
			})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
			this.spinner.hide()
		}
	}
	getCreditNoteDepricated(searchId: any, type: any) {
		try {
			this.sectorService.fareList = []
			this.sectorService.totalFareModel = []
			let url = `invoice?invoiceNumber=${searchId}&type=${type}`
			if (this.isPreviousYear != undefined && this.isPreviousYear == 'true') {
				url = `invoice/previousInvioce?invoice_no=${searchId}&type=${type}`
			}
			if(this.route.queryParams && this.route.queryParams['value']['bookingRef']){
				url +=`&booking_ref_no=${this.route.queryParams['value']['bookingRef']}`
			}
			this.invoiceService.getMethod(url).subscribe(async (res) => {
				// this.invoiceUUID = creditNoteData.invoice_uuid
				// this.sectorService.fareList[1] =  creditNoteData.credit_note_code
				// await this.sectorService.bindCreditNoteData(creditNoteData['products'][0]['json_doc'],)	
				let invoiceData = res['data'][0];
				let obj = {
					invoice_no : invoiceData.invoice_no,
					type : invoiceData.invoice_type ? invoiceData.invoice_type : 'normal'
				}
				this.sectorService.fareList.push(obj)
				let crsId = invoiceData['products'][0]['json_doc'].crs_id_value ? invoiceData['products'][0]['json_doc'].crs_id_value : 'WEBSITE'
				let supplierRes = await this.getSupplier(crsId)
				let bindRes = await this.sectorService.bindInvoiceData(invoiceData['products'][0]['json_doc'], 0, 'invoice')
				// this.saveProductInvoice()
				this.invoiceService.getMethod(`creditNote?credit_note_no=${searchId}&type=${type}`).subscribe(async (res) => {
					let creditNoteData = res['data']
					// this.invoiceUUID = invoiceData.invoice_uuid
					for (const key in creditNoteData) {
						if (creditNoteData.hasOwnProperty(key)) {
							const element = creditNoteData[key];
							let fareListobj = {
								invoice_no : element.credit_note_code,
								type : element.invoice_type ? element.invoice_type : 'normal'
							}
							this.sectorService.fareList.push(fareListobj)
							let bindRes = await this.sectorService.bindInvoiceData(element['products'][0]['json_doc'], Number(key) + 1, 'refund')
						}
					}
					// let lastCreditNote = creditNoteData[creditNoteData.length-1]
					this.sectorService.fareList.push('Credit Note')
					let bindResponse = await this.sectorService.bindCreditNoteData(invoiceData['products'][0]['json_doc'], this.sectorService.fareList.length - 1)
					// this.getSupplier(this.sectorService.invoiceForm.controls.crsId.value)
					this.saveProductInvoice()
					this.spinner.hide()
					this.loaderService.hide()
				}, err => {
					this.spinner.hide()
					this.loaderService.hide()
					console.log(err)
				})
			}, err => {
				this.spinner.hide()
				this.loaderService.hide()
				console.log(err)
			})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
			this.spinner.hide()
		}
	}
	getCreditNote(searchId: any, type: any,callForMisInvoice=true) {
		try {
			this.sectorService.fareList = []
			this.sectorService.totalFareModel = []
			let url = `invoice?invoiceNumber=${searchId}&type=${type}`
			if (this.isPreviousYear != undefined && this.isPreviousYear == 'true') {
				url = `invoice/previousInvioce?invoice_no=${searchId}&type=${type}`
				if (this.isManual == true) {
					url = `invoice/previousInvioce?invoice_no=${searchId}&type=${type}&isManual=true`
				}
				if(this.route.queryParams && this.route.queryParams['value']['bookingRef']){
					url +=`&booking_ref_no=${this.route.queryParams['value']['bookingRef']}`
				}

			}
			this.invoiceService.getMethod(url).subscribe(async (res) => {
				// this.invoiceUUID = creditNoteData.invoice_uuid
				// this.sectorService.fareList[1] =  creditNoteData.credit_note_code
				// await this.sectorService.bindCreditNoteData(creditNoteData['products'][0]['json_doc'],)	
				if (res['data'].length > 0) {
					let invoiceData = res['data'][0];
				let obj = {
					invoice_no: invoiceData.invoice_no,
					type: invoiceData.invoice_type ? invoiceData.invoice_type : 'normal'
				}
				this.sectorService.affiliate_code = invoiceData.client.account_code ? invoiceData.client.account_code.toUpperCase() : ''
				this.sectorService.fareList.push(obj)
				let crsId = invoiceData['products'][0]['json_doc'].crs_id_value ? invoiceData['products'][0]['json_doc'].crs_id_value : 'WEBSITE'
				let selectedRetreivalId = invoiceData['products'][0]['json_doc'].supplier ? invoiceData['products'][0]['json_doc'].supplier : ''
				let supplierRes = await this.getSupplier(crsId)
				this.onSupplierChange(selectedRetreivalId)
				let bindRes = await this.sectorService.bindInvoiceData(invoiceData['products'][0]['json_doc'], 0, 'invoice')
				// this.saveProductInvoice()
				let refResponse = await this.getAllReferenceData(searchId)
				this.sectorService.fareList.push('Credit Note')
				let bindResp = await this.sectorService.bindCreditNoteData(invoiceData['products'][0]['json_doc'], this.sectorService.fareList.length - 1)
				// this.getSupplier(this.sectorService.invoiceForm.controls.crsId.value)
				this.saveProductInvoice()
				}else if(callForMisInvoice){
					this.isManual = true
					this.isPreviousYear = "true"
					this.getCreditNote(searchId.split('-').pop(),"flight",false)
				}
				
				this.spinner.hide()
				this.loaderService.hide()

			}, err => {
				this.spinner.hide()
				this.loaderService.hide()
				console.log(err)
			})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
			this.spinner.hide()
			this.loaderService.hide()
		}
	}

	getAllReferenceData(referenceCode){
		return new Promise((resolve, reject) => {
			this.commonService.getRequestNoloader(`${environment.okountUrl}/invoice/getAllReferenceData?reference_code=${referenceCode}`).subscribe(async (res)=>{
				let responseData = res['data']
				for (const key in responseData) {
					if (responseData.hasOwnProperty(key)) {
						const data = responseData[key];
						if (data['transaction_type'] == 'invoice') {
							let fareItem = this.sectorService.fareList.filter(option =>{
								return option.invoice == data.invoice_no
							})
							if (fareItem.length ==0) {
							let obj = {
								invoice_no : data.invoice_no,
								type : data.invoice_type
							}
							this.sectorService.fareList.push(obj)
							console.log('Invoice list in getAllReference invoice : ',this.sectorService.fareList)
							
							}
						}else{
							let fareItem = this.sectorService.fareList.filter(option =>{
								return option.invoice == data.credit_note_code
							})
							if (fareItem.length ==0) {
							let obj = {
								invoice_no : data.credit_note_code,
								type : 'credit_note'
							}
							this.sectorService.fareList.push(obj)
							console.log('Invoice list in getAllReference credit_note : ',this.sectorService.fareList)
							
							}
						}
						this.sectorService.invoiceForm.controls.airline.setValue(data['products'][0]['json_doc'].airline)
						let res = await this.sectorService.bindInvoiceDataV2(data['products'][0]['json_doc'], key,data['transaction_type'] )
					}
				}
				resolve(res)
			},err=>{

			})
		})
	}




	getCrsId() {
		return new Promise((resolve, reject) => {
		  this.commonService
			.getRequest(
			  `${environment.IBEurl}/edge/portal-configuration/configuration/get-flight-supplier-urls`
			)
			.subscribe(
			  (res: any) => {
				this.setSupplierUrls(res)
				resolve("");
			  },
			  (err) => {
				this.setSupplierUrls(this.commonService.portalConfiguration)
				console.log("Error while getting CRS IDs : ", err);
				resolve("");
			  }
			);
		});
	  }

	setSupplierUrls (retrievalConfig){
		this.crdIdArr = [];
		this.retrievalConfig = this.retrievalConfig.concat(retrievalConfig);
		this.sectorService.retrievalConfig =  this.retrievalConfig
		for (const data of retrievalConfig) {
		  console.log(data.crs_id);
		  if (
			data.crs_id != null &&
			this.crdIdArr.indexOf(data.crs_id) == -1
		  ) {
			this.crdIdArr.push(data.crs_id);
		  }
		  if (retrievalConfig.indexOf(data) == 0) {
			this.sectorService.invoiceForm.controls.crsId.setValue(
			  "WEBSITE"
			);
		  }
		}
	  }
	getSupplier(crsId) {
		return new Promise((resolve, reject) => {
			try {
				this.supplierArr = [];
				for (const crs of this.retrievalConfig) {
				  if (
					crs && crsId && 
					crs.crs_id &&
					crs.crs_id == crsId.toUpperCase()
				  ) {
					this.supplierArr.push(crs);
				  }
				}
				if (this.supplierArr.length > 0) {
				  this.onSupplierChange(this.supplierArr[0].display_name);
				  this.sectorService.invoiceForm.controls.supplier.setValue(
					this.supplierArr[0].display_name
					  ? this.supplierArr[0].display_name
					  : ""
				  );
				}
		
				if (crsId == "WEBSITE") {
				  this.ledgerSearch("CC001A",true);
				} else {
				  this.ledgerSearch("CC001B",true);
				}
				resolve("");
			  } catch (error) {
				resolve("");
				console.log("Error while getting Suppliers : ", error);
			  }
		})
	}


	onSupplierChange(value) {
		try {
			this.selectedSupplier = this.supplierArr.filter(option => {
				if (option.display_name == value) {
					this.sectorService.invoiceForm.controls.invoiceType.setValue(option.type ? option.type : 'X')
					this.onInvoiceTypeChange(option.type ? option.type : 'X')
				}
				return option.display_name == value
			})
			this.saveProductInvoice()
		} catch (error) {
			console.log('Error occured while selecting supplier : ', error)
		}
	}

	async onInvoiceTypeChange(value){
		try {
			let status;
			if (value == 'C' && this.sectorService.travellerForm.value.travellerList.length > 0) {
				 status = await this.sectorService.updateTravellersValidators('txtbookingSupplier','required',value)
			}else{
				 status = await this.sectorService.updateTravellersValidators('txtbookingSupplier','null',value)
			}
			console.log(status)
		} catch (error) {
			console.log('Error occured while selecting invoice Type : ', error)
		}
	}

	onTravellerBlur(event) {
		this.sectorService.bindTraveller(event.target.value)
	}

	retrieveBooking() {
		try {
			this.spinner.show();
			this.loaderService.show()
			let supplierUrl = this.selectedSupplier[0].url
			let pnr = this.sectorService.invoiceForm.controls.pnr.value
			const lthis = this
			let url = `${supplierUrl}?pnr=${pnr}`
			this.invoiceService.retrievePNR(url).subscribe(async (res : any) => {
				let data = res
				if (data.pnr != null && data.sector != null) {
					lthis.retrievedData = data;
					console.log(lthis.retrievedData)
					let dataBinded = await this.sectorService.bindRetrievedData(lthis.retrievedData)
					await this.calculateFareFromApi(0)
					this.spinner.hide();
					this.loaderService.hide()

				}
				else {
					this.spinner.hide();
					this.loaderService.hide()
					throw new Error("Null PNR or missing sector information");
				}

			}, err => {
				this.spinner.hide();
				this.loaderService.hide()
				throw new Error(JSON.stringify(err));
			});
		} catch (error) {
			console.log('Error in pnr retrieval : ', error)
		}
	}


	async saveProductInvoice() {
		// console.table(this.sectorService.travellerForm.value.travellerList)
		// console.table(this.sectorService.sectorForm.value)
		// console.table(this.sectorService.invoiceForm.value)
		if (this.invoiceMode == 'view') {
			this.updateProduct()
		} else {
			let product_json_doc = this.requestBuilder.createInvoiceProduct(this.sectorService.travellerForm.value, this.sectorService.sectorForm.value, this.sectorService.invoiceForm.value)
			let crsId = await this.requestBuilder.getCRSID(this.sectorService.invoiceForm.value, this.selectedSupplier[0] ? this.selectedSupplier[0].acode : '')
			product_json_doc['crs_id'] = crsId;
			product_json_doc['crs_id_value'] = this.sectorService.invoiceForm.controls.crsId.value;
			product_json_doc['airline'] = this.sectorService.invoiceForm.controls.airline.value;
			product_json_doc['supplier'] = this.sectorService.invoiceForm.controls.supplier.value;
			product_json_doc['invoiceType'] = this.sectorService.invoiceForm.controls.invoiceType.value;
			product_json_doc['fareBasis'] = this.sectorService.invoiceForm.controls.fareBasis.value;
			product_json_doc['dealCode'] = this.sectorService.invoiceForm.controls.dealCode.value;
			product_json_doc['booking_ref_no'] = this.sectorService.invoiceForm.controls.booking_ref.value;
			product_json_doc['nonRefundable'] = this.invoice_root_data.nonRefundable;
			// product_json_doc['boooking_supplier'] = this.selectedSupplier[0].scode;
			let total_fare = {};
			for (const obj in this.sectorService.totalFareModel[this.sectorService.totalFareModel.length-1]) { 
				  if(obj != "0") { 
					total_fare[obj] = `${this.sectorService.totalFareModel[this.sectorService.totalFareModel.length-1][obj]}`
			    }				 
			}
			product_json_doc['total_fare'] = total_fare;

			let requestObj = {
				travellers: this.sectorService.travellerForm.value,
				sectors: this.sectorService.sectorForm.value,
				invoiceForm: this.sectorService.invoiceForm.value
			}
			let saveTravellerRes = await this.saveTravellers(requestObj)
			let narration = ''
			if (saveTravellerRes != '' && saveTravellerRes != undefined) {
				narration = saveTravellerRes['narration_1'] ? saveTravellerRes['narration_1'] : ''
			}
			// await this.calculateFareFromApi(this.sectorService.fareList.length-1)
			let fareModel = this.sectorService.totalFareModel[this.sectorService.fareList.length - 1] ? this.sectorService.totalFareModel[this.sectorService.fareList.length - 1] : this.sectorService.totalFareModel[0]
			let productObj = await this.requestBuilder.createProductObj(product_json_doc, fareModel, narration)
			
			let product_list =  []
			product_list[0] = productObj
			sessionStorage.removeItem('product_list')
			sessionStorage.setItem('product_list', JSON.stringify(product_list))
			// this.sectorService.totalFareModel[1] = new TotalFareRefundModel(null)
			// this.sectorService.resetFormData()
			// this.sectorService.invoiceForm.controls.crsId.setValue('website')
			// this.sectorService.invoiceForm.controls.supplier.setValue(this.supplierArr[0].id ? this.supplierArr[0].id : '')
			// // await this.ngOnInit()

			this.callProductEvent.emit(productObj)
		}

	}

	async updateProduct() {
		let product_json_doc = this.requestBuilder.createInvoiceProduct(this.sectorService.travellerForm.value, this.sectorService.sectorForm.value, this.sectorService.invoiceForm.value)
		let crsId = await this.requestBuilder.getCRSID(this.sectorService.invoiceForm.value, this.selectedSupplier[0] ? this.selectedSupplier[0].acode : '')
		product_json_doc['crs_id'] = crsId;
		product_json_doc["crs_id_value"] = this.sectorService.invoiceForm.value.crsId;
		product_json_doc['airline'] = this.sectorService.invoiceForm.controls.airline.value;
		product_json_doc['supplier'] = this.sectorService.invoiceForm.controls.supplier.value;
		product_json_doc['invoiceType'] = this.sectorService.invoiceForm.controls.invoiceType.value;
		product_json_doc['fareBasis'] = this.sectorService.invoiceForm.controls.fareBasis.value;
		product_json_doc['dealCode'] = this.sectorService.invoiceForm.controls.dealCode.value;
		product_json_doc['booking_ref_no'] = this.sectorService.invoiceForm.controls.booking_ref.value;
		product_json_doc['nonRefundable'] = this.invoice_root_data.nonRefundable;

		let total_fare = {};
			for (const obj in this.sectorService.totalFareModel[this.sectorService.totalFareModel.length-1]) { 
				  if(obj != "0") { 
					total_fare[obj] = `${this.sectorService.totalFareModel[this.sectorService.totalFareModel.length-1][obj]}`
			    }				 
			}
			product_json_doc['total_fare'] = total_fare;
		let requestObj = {
			travellers: this.sectorService.travellerForm.value,
			sectors: this.sectorService.sectorForm.value,
			invoiceForm: this.sectorService.invoiceForm.value
		}
		let saveTravellerRes = await this.saveTravellers(requestObj)
		let narration = ''
		if (saveTravellerRes != '' && saveTravellerRes != undefined) {
			narration = saveTravellerRes['narration_1'] ? saveTravellerRes['narration_1'] : ''
		}
		let productObj = await this.requestBuilder.createProductObj(product_json_doc, this.sectorService.totalFareModel[0], narration)
		
		let product_list = []
		product_list[0] = productObj
		sessionStorage.removeItem('product_list')
		sessionStorage.setItem('product_list', JSON.stringify(product_list))
		this.callProductEvent.emit(productObj)
	}

	calculateFareFromApi(i) {
		let url = `creditNote/fare`
		if (i == 0) {
			url = `invoice/fare`
		}
		let fareObj = this.requestBuilder.createFareObj(this.sectorService.travellerForm.value,this.sectorService.invoiceForm.value,this.sectorService.affiliate_code,this.invoice_root_data.invdate,this.invoice_root_data.invdate,'',this.invoice_root_data.rcm_applicable)
		this.invoiceService.postMethod(url, fareObj).subscribe(res => {
			this.sectorService.totalFareModel[i] = new TotalFareRefundModel(res); //FIX ME
		},
		err => {

		})
	}

	async saveTravellers(value) {
		let travellersObj = await this.requestBuilder.createTravellers(value.travellers)
		let sectorObj = await this.requestBuilder.createSectorsObj(value.sectors, value.invoiceForm)

		if (this.invoiceUUID != undefined) {
			let requestObj = {
				"json_doc": travellersObj,
				"sectors": sectorObj,
				"uuid": this.invoiceUUID
			}
			return new Promise((resolve, reject) => {
				this.invoiceService.postMethod('invoice/traveller/save', requestObj).subscribe(res => {
					let response = res
					resolve(response)
					console.log('Travellers save response - SUCCESS : ', res)
				}, err => {
					resolve('')
					console.log('Travellers save response - ERROR : ', err)
				})
			});
			// this.invoiceService.postMethod('invoice/traveller/save',requestObj).subscribe(res=>{
			// 	console.log('Travellers save response - SUCCESS : ',res)
			// },err=>{
			// 	console.log('Travellers save response - ERROR : ',err)
			// })
		}
	}


	getLedgers(url) {
		this.invoiceService.getMethod(url).subscribe(res => {
			this.sectorService.ledgerList = res
		}, err => {

		})
	}

	getAccounts(value) {
		this.sectorService.getAccounts(value)
	}



	onFocused(e) {

	}

	closeMaxModule() {
		this.commonService.minimizeModule(true)
	}

	employeeSearch(value,withStatus) {
		try {
			// let searchType = this.sectorService.invoiceForm.controls.sub_ledger_code_1_type.value;
			if (value.length > 2) {
				let status = '';
				if(withStatus){
					status = '&status=active'
				}
				this.invoiceService.searchAccount(`employee/autocomplete?name_like=${value.toUpperCase()}${status}`).subscribe(res => {
					this.employeeList = res;
				}, error => {
				});
			}
		} catch (error) {

		}

	}

	airlineSearch(event,withStatus) {
		let value = event.target.value;
		try {
			// let searchType = this.sectorService.invoiceForm.controls.sub_ledger_code_1_type.value;
			if (value.length > 2) {
				let status = '';
				if(withStatus){
					status = '&status=active'
				}
				this.invoiceService.searchAccount(`airline/autocomplete?name_like=${value.toUpperCase()}${status}`).subscribe(res => {
					this.airlineArr = res;
				}, error => {
				});
			}
		} catch (error) {

		}

	}

	ledgerSearch(event,withStatus) {
		let value = event;
		if(event && event.target && event.target.value) {
			value = event.target.value;
		}
		try {
			let searchType = this.sectorService.invoiceForm.controls.sub_ledger_code_1_type.value;
			if (value.length > 2 && searchType != null && searchType != '') {
				let status = '';
				if(withStatus){
					status = '&status=active'
				}
				this.invoiceService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`).subscribe(res => {
					this.sectorService['ledgerList'] = res;
				}, error => {
				});
			}
		} catch (error) {

		}
	}

	selectAccount(nzEvent, formControlName) {
		let nzValue = nzEvent.nzValue;
        let value = nzValue;
		try {
			if(value && value.account_code) {
				this.sectorService.invoiceForm.controls[formControlName].setValue(value.account_code)
			}
		} catch (error) {

		}
	}



}
