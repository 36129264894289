import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver'
import { LoaderService } from 'src/app/shared/loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-print-adm-acm',
  templateUrl: './print-adm-acm.component.html',
  styleUrls: ['./print-adm-acm.component.scss']
})
export class PrintAdmAcmComponent implements OnInit {
  admAcmData: any;
  admAcmId: any;
  valid: boolean;
  @Input() reference_code;
  @Input() preFetchedData;
  @Output() callSaveFetchedData: EventEmitter<any> = new EventEmitter();

  constructor(
    public commonService: CommonServiceService,
    private loaderService: LoaderService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
  ) { 

    this.route.paramMap.subscribe(params => {
			this.admAcmId = params['params'].transactionNumber
			if (this.admAcmId != undefined) {
				document.title = `${this.admAcmId}`
			}
		});
  }

  ngOnInit(): void {
	if(!this.preFetchedData){
		if(!this.admAcmId){
			this.admAcmId = this.reference_code
		}
		this.getAdmAcmData(this.admAcmId)
	}else{
		this.admAcmData = this.preFetchedData
		this.valid = true
	}
    
  }

  getAdmAcmData(searchId: any) {
		try {
			this.commonService.getRequest(`${environment.okountUrl}/adm-acm/download?acm_adm_code=${searchId}&preview=true`).subscribe(async (res) => {
        this.valid = true;
				this.admAcmData = this.transform(res)
			},
				err => {
					this.loaderService.hide()
				})
		} catch (error) {
			console.log('Error occured in gettting data due to : ', error)
		}
	}

	transform(html) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}

  downloadPdf(searchId: any) {
		this.commonService.downloadPDF(`${environment.okountUrl}/adm-acm/download?acm_adm_code=${searchId}&preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `${searchId}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
	   })
	} catch (error) {
		console.log('Error occured in download pdf due to : ', error)
	}
}
