import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
	pwdForm: any;

	showMessage: boolean = false;
	alertType = '';
	message: string = ``;

	constructor(private fb: FormBuilder, public commonService: CommonServiceService, private router: Router) {
		this.pwdForm = this.fb.group({
			oldPassword: [""],
			newPassword: ["", Validators.required],
			confirm_password: ["", Validators.required]
		}, { validator: this.checkPasswords });
	}

	ngOnInit() {

	}


	checkPasswords(group: FormGroup) { // here we have the 'passwords' group
		let pass = group.controls.newPassword.value;
		let confirmPass = group.controls.confirm_password.value;
		return pass === confirmPass ? null : { notSame: true }
	}
	get newPassword() {
		return this.pwdForm.controls.newPassword;
	}
	get confirm_password() {
		return this.pwdForm.controls.confirm_password;
	}

	changePassword(values) {
		this.showMessage = false
		debugger
		if (!this.pwdForm.valid) {
			window.scrollTo(0, 0)
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = "All fields are madatory"
			return false
		}
		var obj = {
			"password": values.password,
		}
		if (values.newPass != values.currentPass) {
			
			return false;
		}
		else {
			this.commonService.postRequest(`${environment.okountUrl}/user/resetpassword`, values).subscribe(
				res => {
					this.showMessage = true
					this.alertType = 'success-box';
					this.message = "Password updated. Login Again."
					setTimeout(() => {
						this.logout()
					}, 1000);
	
				},
				err => {
					this.showMessage = true
					this.alertType = 'error-message';
					this.message = "Failed to update password."
				}
			);
		}

	}
	logout() {
		localStorage.removeItem("token")
		localStorage.removeItem("userInfo")
		this.commonService.showLogout(false)
		window.location.href = environment.accountManagement+"/signin"
	}



}
