import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportRoutingModule } from './report-routing.module';
import { ReportComponent } from './report.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from '../shared/shared.module';
import { FlownReportComponent } from './flown-report/flown-report.component';
import { EInvListComponent } from './e-inv-list/e-inv-list.component';
import { PsrReportComponent } from './psr-report/psr-report.component';
import { UnpaidReportComponent } from './unpaid-report/unpaid-report.component';
import { PsrDetailedReportComponent } from './psr-detailed-report/psr-detailed-report.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewBalanceSheetComponent } from './balance-sheet/view-balance-sheet/view-balance-sheet.component';

import { AdjustBalanceComponent } from './adjust-balance/adjust-balance.component';
import { ClientUnpaidReportComponent } from './client-unpaid-report/client-unpaid-report.component';
import { ViewAdjustedInvoiceComponent } from './adjust-balance/view-adjusted-invoice/view-adjusted-invoice.component';
import { AdjustB2csDataComponent } from './adjust-b2cs-data/adjust-b2cs-data.component';
import { MisReportComponent } from './mis-report/mis-report.component';
import { ClientSalesReportComponent } from './client-sales-report/client-sales-report.component';
import { TdsReportDetailedComponent } from './tds-report-detailed/tds-report-detailed.component';
import { TcsReportDetailedComponent } from './tcs-report-detailed/tcs-report-detailed.component';
import { MisReportV2Component } from './mis-report-v2/mis-report-v2.component';
import { VerticalPNLComponent } from './vertical-pnl/vertical-pnl.component';


@NgModule({
  declarations: [ReportComponent, FlownReportComponent,EInvListComponent, PsrReportComponent, UnpaidReportComponent, PsrDetailedReportComponent,ViewBalanceSheetComponent, AdjustBalanceComponent, ClientUnpaidReportComponent, ViewAdjustedInvoiceComponent, AdjustB2csDataComponent, MisReportComponent,ClientSalesReportComponent, TdsReportDetailedComponent,TcsReportDetailedComponent, MisReportV2Component,VerticalPNLComponent],
  imports: [
    CommonModule,
    ReportRoutingModule,
    NgxPaginationModule,
    SharedModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ReportModule { }
