import { Component, OnInit, ViewChild, ElementRef, QueryList, ViewChildren, Input } from '@angular/core';
import { PaymentV2Service } from '../services/paymentv2.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment'
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { FormBuilder, FormArray, Validators, NgForm ,FormGroup, AbstractControl} from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';

@Component({
  selector: 'app-v2-create-payment',
  templateUrl: './v2-create-payment.component.html',
  styleUrls: ['./v2-create-payment.component.scss'],
  providers:[PaymentV2Service]
})
export class V2CreatePaymentComponent implements OnInit {
  @Input() data;
  keyword = 'name';
  generalMasterList: any;
  accountList = []
  ledgerList = []
  mode = "save";
  searchId;
  showMessage: boolean = false;
  alertType = '';
  message: string = ``;
  submitResponse
  paymentData = []
  previousTabCount = 2
  confirmationModalType = ''
  newPayment: boolean = true;
  @ViewChild("openMasterCreationModal", { static: true }) masterCreationModal : ElementRef;
  showCreateButton: boolean = false;
  currentMaster: any
  currentMasterName ='';
  currentFormControlName;
  totalAmount: number;
	attachmentFile: File | null = null;


  @ViewChild('bankAC', { static: true }) bankAC;
  @ViewChild('confirmModal',{static:true}) openConfirmationModal : ElementRef
	@ViewChild('closeConfirmModal',{static:true}) closeConfirmationModal : ElementRef
  @ViewChildren('accountAc') accountAc: QueryList<ElementRef>;
  @ViewChildren('subLedgerAC') subLedgerAC: QueryList<ElementRef>;
	@ViewChild('otherAccountAC',{static : true}) otherAccountAC;
	@ViewChild('accountType',{static:true}) accountType : ElementRef
  @ViewChild('closeSendEmailModal') closebutton;
	@ViewChild('openEmailModal') openEmailModal;
  @ViewChild("openSuccessModal", { static: true }) openSuccessModal;
  shortcuts: ShortcutInput[] = [];
  sendEmailForm:FormGroup
  minDate;
  maxDate;

	copyELementTitle = 'Click to Copy'
  showAdjustbtn: boolean = false;
  financialYearDiff = false;
  constructor(
    public paymentService: PaymentV2Service,
		private spinner: NgxSpinnerService,
		public commonService: CommonServiceService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router
  ) { 
    this.route.paramMap.subscribe((params) => {
      this.mode = params["params"].mode;
      this.searchId = params["params"].id;
    });


    this.sendEmailForm = this.fb.group({
			toEmails: ["",Validators.required],
			bccEmails: [''],
			ccEmails:[''],
      subject:[''],
      body:['']
		})
  }

  async ngOnInit() {
    if(this.data) {
      this.searchId = this.data.paymentRef,
      this.mode = this.data.mode
     }
     
    this.paymentService.initialiseFormData({});

    let empConfiguration = this.commonService.employeeConfiguration;
    if(!empConfiguration || empConfiguration.length == 0){
      let loggedInUser = JSON.parse(localStorage.okountUserInfo);
      empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
    }

    let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
	
    this.paymentDateValidation()

    // this.getGeneralMaster("general/bank");
    if (this.mode == "view") {
      this.getPaymentDetails();
    } else {
      this.paymentService.paymentForm.controls.payment_date.setValue(
        moment().format("YYYY-MM-DD")
      );
      this.paymentService.paymentForm.controls.payment_code.setValue("");
      this.paymentService.addRow(this.paymentService.paymentForm.value);
    }

    let queryParams = this.route.queryParams['value'];
    if(queryParams && queryParams['ubr_id']) {
      this.getUbrData(queryParams['ubr_id']);
   } 
  }

  validateDate(){
		return (control: AbstractControl) => {
			let valid = true
			if(this){
				if (moment(control.value).isBefore(this.minDate) || moment(control.value).isAfter(this.maxDate)) {
					if(this.paymentData && this.paymentData.length > 0 && this.paymentData[0].payment_date && moment(control.value,'YYYY-MM-DD').isSame(moment(this.paymentData[0].payment_date,'YYYY-MM-DD'))){
						valid = true
					}else{
						valid = false
					}
				}
			}
			return !valid ? {payment_date: {value: control.value}} : null;
		};
	}


  checkDateOfEmployee() {
    try {
      this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
      this.maxDate = moment().format('YYYY-MM-DD')

      let config = this.commonService.getConfigurationByKey('payment')
			if(config){
				let dateConfig = config['value'].filter(data => data.key == `minimum_${this.mode == 'view' ? 'update' : 'creation'}_date`)
				let daysConfig = config['value'].filter(data => data.key == `minimum_${this.mode == 'view' ? 'update' : 'creation'}_days`)
				let dateWithMinDate = moment(dateConfig ? dateConfig[0].value : '')
        let dateWithMinDays = dateConfig && daysConfig[0].value ? moment().subtract(Number(daysConfig[0].value), 'days') : dateWithMinDate
				if(dateWithMinDays.isBefore(dateWithMinDate)){
					this.minDate = dateWithMinDate.format('YYYY-MM-DD')
				}else{
					this.minDate = dateWithMinDays.format('YYYY-MM-DD')
				}
			}
      this.paymentService.paymentForm.controls.payment_date.setValidators([Validators.required,this.validateDate()])
      this.paymentService.paymentForm.controls.payment_date.updateValueAndValidity()
		} catch (error) {
			
		}
  }


  ngAfterViewInit(){

		this.shortcuts.push(			
			{
				key: "alt + s",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Save Payment Entry",
				description: "Save Payment Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("save_payment_btn")){

				document.getElementById("save_payment_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + e",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Update Payment Entry",
				description: "Update Payment Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("update_payment_btn")){

				document.getElementById("update_payment_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + d",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Payment Entry",
				description: "Delete Payment Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("delete_payment_btn")){

				document.getElementById("delete_payment_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + j",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Open Journal",
				description: "Open Journal",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("journal_payment_btn")){

				document.getElementById("journal_payment_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + plus",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Add Row",
				description: "Add row",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById(`add_row_payment_btn_${this.paymentService.paymentArray.length-1}`)){

				document.getElementById(`add_row_payment_btn_${this.paymentService.paymentArray.length-1}`).click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + l",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "View Activity Log",
				description: "View Activity Log",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById('payment_activity_log')){

				document.getElementById('payment_activity_log').click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + -",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Row",
				description: "Delete Row",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById(`remove_row_payment_btn_${this.paymentService.paymentArray.length-1}`)){

				document.getElementById(`remove_row_payment_btn_${this.paymentService.paymentArray.length-1}`).click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + y",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On Yes Button",
				description: "Clicked On Yes Button",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById(`confirm_Opened_Modal_${this.confirmationModalType}_btn`)){

				document.getElementById(`confirm_Opened_Modal_${this.confirmationModalType}_btn`).click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
      this.confirmationModalType = ''
					},
					preventDefault: true
			},
      {
				key: "alt + n",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On No Button",
				description: "Clicked On No Button",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById(`confirm_close_Modal_${this.confirmationModalType}_btn`)){

				document.getElementById(`confirm_close_Modal_${this.confirmationModalType}_btn`).click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
      this.confirmationModalType = ''
					},
					preventDefault: true
			},
      {
				key: "f10",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Send mail",
				description: "Send mail",
				command: (e)=>{
				console.log(e)	
			if(document.getElementById('payment_send_mail_btn') && this.searchId && this.searchId.length>0){
				document.getElementById('payment_send_mail_btn').click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
	  )
	}

  resetCurrentModalType(){
    this.confirmationModalType = ''
  }
  getPaymentDetails() {
    this.paymentService
      .getMethod(`paymententryv3?payment_code=${this.searchId}`)
      .subscribe(
        async (res: any) => {
          if (res[0] != undefined) {
            this.paymentData = res;
           
            if(this.paymentData && this.paymentData != undefined) {
              this.checkPaymentFullyAdjustedOrNot(this.searchId, this.paymentData)
            }

            console.log(res);
            await this.setPaymentData();
            this.calculateDiff()

          }
        },
        (err) => {}
      );
  }

  hideError() {
    this.showMessage = false
  }

  setPaymentData() {
    this.paymentService.paymentForm.controls.payment_date.setValue(
      moment(this.paymentData[0].payment_date).format("YYYY-MM-DD")
    );
    this.paymentService.paymentForm.controls.account_code_from.setValue(
      this.paymentData[0].account_code_from
    );
    this.paymentService.paymentForm.controls.narration_5.setValue(
      this.paymentData[0].narration_5
    );
    this.paymentService.paymentForm.controls.payment_code.setValue(
      this.paymentData[0].payment_code
    );

   if(this.paymentData[0].hasOwnProperty("account_code_from_data") && this.paymentData[0].account_code_from_data.name ){
    this.paymentService.paymentForm.controls.account_code_from_name.setValue(
      this.paymentData[0].account_code_from_data.name
    );
   }else{
    let url = `general/autocomplete?name_like=${this.paymentData[0].account_code_from}`
    this.paymentService.getMethod(url).subscribe(res => {
			this.generalMasterList = res
      if(this.generalMasterList.length>0){
        this.paymentService.paymentForm.controls.account_code_from_name.setValue(
          this.generalMasterList[0].name
        );
      }
		}, err => {

		})
   }   

    for (let data of this.paymentData) {
      if(!data.account_code_to_name){
        data.account_code_to_name = data.hasOwnProperty("account_code_to_data") && data.account_code_to_data.name ?data.account_code_to_data.name:'';
        data.account_code_from_name = data.hasOwnProperty("account_code_from_data") && data.account_code_from_data.name ?data.account_code_from_data.name:'';
      }
      this.paymentService.addRow(data);
    }
  }

  getGeneralMaster(event,withStatus) {
    let name_like = event.target.value;
    this.currentMasterName = name_like;
    this.currentFormControlName = 'account_code_from_name'
    let status = ''
    if(withStatus){
      status = '&status=active'
    }
    let url = `general/autocomplete?name_like=${name_like}${status}`
		this.paymentService.getMethod(url).subscribe(res => {
			this.generalMasterList = res
      if(!this.generalMasterList || this.generalMasterList.length == 0) {
        this.showCreateButton = true;
        this.currentMaster = 'general'
       }else {
         this.showCreateButton = false;
         this.currentMaster = ''
       }
		}, err => {

		})
	}

  deletePayment(paymentData){
    this.closeConfirModal()
    this.spinner.show();
    this.paymentService.deleteMethod(`paymententryv3?payment_code=${paymentData['payment_code']}`).subscribe(
      (res) => {
      this.submitResponse = ''
      this.showMessage = true
      this.alertType = 'success-box';
      this.message = `Payment deleted successfully`
      this.paymentService.initialiseFormData({})
      this.spinner.hide();
      },
      (err) => {
        this.showMessage = true
      this.alertType = 'error-box';
      this.message = `Failed to delete payment.`
      this.spinner.hide();
      }
    );
  }

  openSendEmailModal(){
    let primary_email = ''
    let secondary_email = ''
    let subject = `PAYMENT NO. ${this.searchId}`
    let body = `Dear Travel Partner,\n\nPlease find Attached Payment ${this.searchId}`
    body+=`\n\nPlease do not hesitate to contact us if you have any query or concern about attached document\n\nit's been a pleasure to serving you\n\nBest Regards\n\n${this.paymentData[0].accounting_company?.name}\nAccounts Team`
    if(this.paymentData[0].accounting_company && this.paymentData[0]?.accounting_company.telephone){
			body += `\nContact Us on ${this.paymentData[0]?.accounting_company.telephone}`
		}
    if(this.paymentData && this.paymentData.length>0 && this.paymentData[0].account_code_to_data){
      let clinetData =  this.paymentData[0].account_code_to_data
      primary_email = clinetData.hasOwnProperty('primary_email')?clinetData.primary_email:''
      secondary_email = clinetData.hasOwnProperty('secondary_email')?clinetData.secondary_email:''
    }
    this.sendEmailForm.controls.toEmails.setValue(primary_email)
    this.sendEmailForm.controls.bccEmails.setValue("")
    this.sendEmailForm.controls.ccEmails.setValue(secondary_email.split(',').join('\n'))
    this.sendEmailForm.controls.subject.setValue(subject)
    this.sendEmailForm.controls.body.setValue(body)
    this.openEmailModal.nativeElement.click();
	}
  callTosendEmail(){
  	
		let toEmails = this.sendEmailForm.value.toEmails
		let bccEmails = this.sendEmailForm.value.bccEmails?.split(",")
		let ccEmails = this.sendEmailForm.value.ccEmails?.split(",")
    let subject = this.sendEmailForm.value.subject
    let body = this.sendEmailForm.value.body

		if(toEmails){
		  toEmails = toEmails.split(",")
      let clientName = this.paymentData && this.paymentData.length>0 && this.paymentData[0].account_code_to_data && this.paymentData[0].account_code_to_data.name?this.paymentData[0].account_code_to_data.name:''

		  window.scrollTo(0, 0)
		  let emails = {
			to_addresses:toEmails,
			cc_addresses:ccEmails,
			bcc_addresses:bccEmails
		  }
		  let pageUrl = `transactions/payment/print/${this.searchId}`
      const formData = new FormData();
			formData.append('email',JSON.stringify(emails))
			formData.append('name',clientName)
			formData.append('page_url',pageUrl)
			formData.append('subject',subject)
			formData.append('email_body',body)
			formData.append('document_num',this.searchId)
			if(this.attachmentFile){
				formData.append('attachmentFile',this.attachmentFile)
			}
			let data = formData
		  this.commonService.fileUpload(`${environment.okountUrl}/send_mail?transaction_type=payment`,data).subscribe((res : any)=> {
			console.log(res)
			this.showMessage = true;
			this.alertType = 'success-box'
			this.message = res.msg
			this.closebutton.nativeElement.click();
		  },err => {
			this.showMessage = true
			this.alertType = "error-box"
			this.message = err.msg
			this.closebutton.nativeElement.click();
			
		  })
		}
	  }

  resetPayment(){
    this.paymentService.paymentForm.reset()
  }

  selectBank(nzEvent, formControlName) {
    let nzValue = nzEvent && nzEvent.nzValue;
    
		if(nzValue) {
      let value = nzValue;
      try {
        this.paymentService.paymentForm.controls[formControlName].setValue(value.account_code);
      } catch (error) {}
    }
    else {
       this.paymentService.paymentForm.controls[`${formControlName}_name`].setValue(this.currentMasterName);
       this.masterCreationModal.nativeElement.click()
       return false;
    }
  }

  focusBankAc(e) {
		if (this.bankAC != undefined && this.bankAC.focus) {
			this.bankAC.focus()
		}
	}

  openActivity(){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/payment/${this.searchId}`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}


  removePaymentRow(i){
    console.log("removing payment row num: ",i+1)
    this.paymentService.paymentForm.get("paymentArray").removeAt(i);
  }

  addRow(){
    this.paymentService.addRow(this.paymentService.paymentForm.value)
  }


  async submit(formValue) {
        this.closeConfirModal()
        !this.financialYearDiff && this.checkDateOfEmployee()
        const invalid = [];
        const controls = this.paymentService.paymentForm.controls;
        for (const name in controls) {
          if (controls[name].invalid && name != "paymentArray") {
            invalid.push(name);
          }
        }
        if(invalid.length > 0) {
          window.scroll(0,0)
          this.showMessage = true;
          this.alertType = 'error-box';
          this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
          this.spinner.hide()
          return false
        }
        console.log("submited form value", formValue);
        let loggedInUser = JSON.parse(localStorage.getItem("okountUserInfo"));
        for (var data of formValue.paymentArray) {
          data.account_code_from = formValue['account_code_from']
          data.narration_5 = formValue['narration_5']
        }
        let requestData = {
          payment_code: formValue.payment_code,
          payment_date: formValue.payment_date,
          payment_array: formValue.paymentArray,
          last_modified_by: loggedInUser.id,
          last_modified_by_name: loggedInUser.name,
          last_modified_by_code: loggedInUser.accountcode,
        };
      this.spinner.show();
      this.paymentService.postMethod(`paymententryv3`, requestData).subscribe(
        (res) => {
        this.submitResponse = res
        this.showMessage = false
        this.alertType = 'success-box';
        this.message = `Payment created with `
        this.newPayment = false
        // alert(JSON.stringify(this.submitResponse))
        
        this.openSuccessModal.nativeElement.click()
        this.paymentService.initialiseFormData({})
        this.paymentService.addRow({});
        this.spinner.hide();
        let queryParams = this.route.queryParams['value']
        if(this.submitResponse && this.submitResponse.length>0 && queryParams && queryParams['ubr_id']) {
          this.updateReconcillation(queryParams, this.submitResponse[0])
        }
        },
        (err) => {
          this.showMessage = true
        this.alertType = 'error-box';
        this.message = `Failed to create payment.`
        if( err['error']['message']){
          this.message = err['error']['message']
        }
        this.spinner.hide();
        }
      );
    
  }

  async updateAndExit(formValue){
      this.closeConfirModal()
      !this.financialYearDiff && this.checkDateOfEmployee()
      const invalid = [];
      const controls = this.paymentService.paymentForm.controls;
      for (const name in controls) {
        if (controls[name].invalid && name != "paymentArray") {
          invalid.push(name);
        }
      }
      if(invalid.length > 0) {
        window.scroll(0,0)
        this.showMessage = true;
        this.alertType = 'error-box';
        this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
        this.spinner.hide()
        return false
      }
      console.log("submited form value", formValue);
        let loggedInUser = JSON.parse(localStorage.getItem("okountUserInfo"));
        for (var data of formValue.paymentArray) {
          data.account_code_from = formValue['account_code_from']
          data.narration_5 = formValue['narration_5']
        }
        let requestData = {
          payment_code: formValue.payment_code,
          payment_date: formValue.payment_date,
          payment_array: formValue.paymentArray,
          last_modified_by: loggedInUser.id,
          last_modified_by_name: loggedInUser.name,
          last_modified_by_code: loggedInUser.accountcode,
        };
      this.spinner.show();
      this.paymentService.putMethod(`paymententryv3/${this.searchId}`, requestData).subscribe(
        (res) => {
        let response = res;
        this.showMessage = true
        this.alertType = 'success-box';
        this.message = `Payment updated.`
        console.log("Response : ", response);
        // this.getEntries()
        this.spinner.hide();
        },
        (err) => {
        // this.getEntries()
        this.showMessage = true
        this.alertType = 'error-box';
        this.message = `Failed to updated payment.`
        if( err['error']['message']){
          this.message = err['error']['message']
        }
        this.spinner.hide();
        }
      );
    }

    viewJournal(invoice_no) {
      let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/view-invoice-journal/${invoice_no}`;
      window.open(url, "_blank");
    }

    focusTab(tabIndex) {
      this.commonService.selectedInput.next(tabIndex);
    }
  
    openVoucher(searchId) {
      let path = `${
        environment.name == "v3-stage" || environment.name == "v3-prod"
          ? "/v3/"
          : "/"
      }#/transactions/payment/print/${searchId}`;
  
      if (window.location.protocol != "file:") {
        window.open(path, "_blank");
      } else {
        var customeEventHandler = new CustomEvent("handle-window-open", {
          detail: {
            hashPath: path,
          },
        });
        window.dispatchEvent(customeEventHandler);
      }
    }
  
  

	ledgerSearch(event,i,withStatus){
    let value = event.target.value;
		try {
      let searchType = this.paymentService.paymentForm.controls.paymentArray.controls[i].value.sub_ledger_code_1_type
			if (value.length > 2 && searchType != null && searchType != '') {
        let status = ''
        if(withStatus){
          status = '&status=active'
        }
				this.paymentService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`).subscribe(res => {
				this.ledgerList[i] = res;
				});
			}
		} catch (error) {

		}

	}

	selectAccount(nzEvent,formControlName,i){
    let nzValue = nzEvent && nzEvent.nzValue;
    if(nzValue) {
      try {
        let value = nzValue;
        this.paymentService.paymentForm.controls.paymentArray.controls[i].controls[formControlName].setValue(value.account_code)
      } catch (error) {
      }
    } else {
        this.paymentService.paymentForm.controls.paymentArray.controls[i].controls[`${formControlName}_name`].setValue(this.currentMasterName);
        this.masterCreationModal.nativeElement.click()
        return false;
      }
	}

	onChangeSearch(event,i,withStatus) {
    let value = event.target.value;
		try {
			let searchType = this.paymentService.paymentForm.controls.paymentArray.controls[i].value.account_code_to_type
			if (value.length > 2 && searchType != null && searchType != '') {
        this.currentMasterName = value;
        this.currentFormControlName = 'account_code_to_name'
        let status = ''
        if(withStatus){
          status = '&status=active'
        }
				this.paymentService.searchAccount(`${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`).subscribe(res => {
				this.accountList[i] = res;
        if(!this.accountList[i] || this.accountList[i].length == 0) {
            this.showCreateButton = true;
            this.currentMaster = this.paymentService.paymentForm.controls.paymentArray.controls[i].value.account_code_to_type;
           } else {
            this.showCreateButton = false;
            this.currentMaster = '';
           }
				});
			}
		} catch (error) {

		}
	}

  focusAccount(e:Event,index): void {
		e.stopPropagation();
    if (this.accountAc["_results"][index]) {
			this.accountAc["_results"][index].focus()
		}
	}

	focusSubLedger(e:Event,index): void {
		e.stopPropagation();
		if (this.subLedgerAC["_results"][index]) {
			this.subLedgerAC["_results"][index].focus()
		}
	}

  onFocused(e) {

	}


  openConfirModal(confirmationModalType){
    if(this.commonService.gstFiledDate) {
      let dateToCheckCondition = confirmationModalType == 'save' ? moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.paymentService.paymentForm.controls.payment_date.value).format('YYYY-MM-DD')) : (moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.paymentData[0].payment_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.paymentService.paymentForm.controls.payment_date.value).format('YYYY-MM-DD'))) ;
			if(dateToCheckCondition) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = `Not Allowed to ${confirmationModalType} the invoice.`
				return false;
			}
		}
    this.confirmationModalType = confirmationModalType
    this.openConfirmationModal.nativeElement.click()
}

  closeConfirModal(){
    this.closeConfirmationModal.nativeElement.click()
  }



  get DynamicFormControls() {
		return <FormArray>this.paymentService.paymentForm.get("paymentArray");
	}

  closeSuccesModal(){
		this.showMessage = false;
		this.alertType = 'success-box';
    this.newPayment = true
		this.message = ``
		this.openSuccessModal.nativeElement.click()
	}

	copytext(val: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.copyELementTitle = 'Copied'
	}

  viewPayment(paymentCode) {
    this.router.navigateByUrl(`transactions/payment/view/${paymentCode}`)
  }

  openAdjustmentModal() {    
    let data = this.paymentData[0]
    let path = `${
      environment.name == "v3-stage" || environment.name == "v3-prod"
        ? "/v3/"
        : "/"
    }#/report/adjust-balance?account_code=${data.account_code_to}&adjustment_reference=${data.payment_code}&reference_type=payment`;
   
    if(window.location.protocol != 'file:') {
      window.open(path, '_blank')
    }
  }


  viewAdjustedInvoices() {    
    let data = this.paymentData && this.paymentData.length > 0 && this.paymentData[0] 
    let path = `${
      environment.name == "v3-stage" || environment.name == "v3-prod"
        ? "/v3/"
        : "/"
    }#/report/adjusted-invoices?account_code=${data.account_code_to}&adjustment_reference=${data.payment_code}&reference_type=payment`;
   
    if(window.location.protocol != 'file:') {
      window.open(path, '_blank')
    }
  }


  checkPaymentFullyAdjustedOrNot(searchId, paymentData) {
    let clientCode = paymentData && paymentData.length>0 && paymentData[0].account_code_from_data && paymentData[0].account_code_from_data.account_code? paymentData[0].account_code_from_data.account_code:''

    let paymentAmount = 0
    if(paymentData && paymentData.length > 0) {
      for(let item of paymentData) {
        paymentAmount += Number(item.amount)
      }
    }
    
		this.commonService
      .getOkount(`payment_adjustment/get-reference-data?account_code=${clientCode}&reference=${searchId}`)
		  .subscribe(
			async (res: any) => {
        if(res && res['data'] && res['data']['adjustment_amount']) {
          if(paymentAmount != res['data']['adjustment_amount']) {
            this.showAdjustbtn = true
          }
        }else {
          this.showAdjustbtn = true
        }
			}, err => {
        console.error(err)
      })
      this.spinner.hide()
	}

  getUbrData(ubr_id) {
    this.commonService
    .getOkount(`bank_reconciliation/ubr/data?ubr_id=${ubr_id}`)
    .subscribe(
      async (res: any) => {
        if(res && res['data']) {
          let data = res['data']
          if(data.value_date) {
            let valueDate = moment(data.value_date).format('YYYY-MM-DD')
            this.paymentService.paymentForm.controls.payment_date.setValue(valueDate)
          }
          console.log(data);
          
          if(data.bank_code) {
            this.paymentService.paymentForm.controls.account_code_from.setValue(data.bank_code)
          }
          if(data.bank_name) {
            this.paymentService.paymentForm.controls.account_code_from_name.setValue(data.bank_name)
          }
          if(data.transaction_amount){
            let paymentArrayControls = this.paymentService.paymentForm.controls.paymentArray.controls
            if(paymentArrayControls && paymentArrayControls.length>0){
              for(let item of paymentArrayControls) {
                item.controls.amount.setValue(data.transaction_amount)
              }
            }
          }        
        }
    }, err => {
      console.error(err)
    })
  }

  updateReconcillation(queryParams, response){
    let requestData = {
      transaction_ref_no: response.payment_code,
      narration_2: response.narration_2,
      id: queryParams['ubr_id'],
      v3_amount: queryParams.v3_amount
    };
    this.paymentService.postMethod(`bank_reconciliation/update`, requestData).subscribe(
      (res) => {
      window.close();      
      },
      (err) => {
      })
    }

    setFormValues(data) {
      let name = 'account_name'
  
      if(this.currentMaster == 'supplier' || this.currentMaster == 'client'){
        name = 'name'
      }
      this.showMessage = true;
      this.alertType = 'success-box';
      this.message = `${this.currentMaster} created successfully with account code. : ${data.account_code}`
      if(this.currentFormControlName  == 'account_code_from_name') {
        if(data.account_code) {
          this.paymentService.paymentForm.controls.account_code_from.setValue(data.account_code)
        }
        if(data.account_name) {
          this.paymentService.paymentForm.controls.account_code_from_name.setValue(data[name])
        }
      }else {
        this.paymentService.paymentForm.controls.paymentArray.controls[0].controls.account_code_to.setValue(data.account_code)
        this.paymentService.paymentForm.controls.paymentArray.controls[0].controls.account_code_to_name.setValue(data[name])
      }
      
      this.showCreateButton = false;
     } 

     calculateDiff(){
      let amount = 0
      if (this.paymentService.paymentForm.controls.paymentArray.controls.length > 0) {
        let controls = this.paymentService.paymentForm.controls.paymentArray.controls
        for (let index = 0; index < controls.length; index++) {
          const element = controls[index];
          amount += Number(element.controls.amount.value)
        }
      }
      this.totalAmount = Number(amount.toFixed(2))
     }
     handleAttachment(event: any) {
      this.attachmentFile = event.target.files[0];
      }

    paymentDateValidation() {
        let financeYearResponse = this.commonService.checkFinancialYearValidation();
        this.financialYearDiff = financeYearResponse.isFinancialYearDiff;
        this.minDate = financeYearResponse.minDate;
        this.maxDate = financeYearResponse.maxDate;

        this.paymentService.paymentForm.controls.payment_date.setValue(this.maxDate);
        this.paymentService.paymentForm.controls.payment_date.setValidators([Validators.required, this.validateDate()]);
        this.paymentService.paymentForm.controls.payment_date.updateValueAndValidity()
      }    

      resetSelection(formControlName, index){
        this.paymentService.paymentForm.controls.paymentArray.controls[index].controls[formControlName].setValue('')
        this.paymentService.paymentForm.controls.paymentArray.controls[index].controls[`${formControlName}_name`].setValue('')
        if(formControlName=='sub_ledger_code_1'){
          this.paymentService.ledgerList = []
        }else{
          this.paymentService.accountList = []
        }
      }
}
