import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import { DownloadService } from 'src/app/shared/download.service';
import { Store } from '@ngrx/store';
import { InitialState } from "src/app/store/reducer";
import { OpenAdvanceSearchModal } from '../../../transactions/store/actions';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as FileSaver from 'file-saver'; 

@Component({
  selector: 'app-gst-report',
  templateUrl: './gst-report.component.html',
  styleUrls: ['./gst-report.component.scss']
})
export class GstReportComponent implements OnInit {
  gstPageSubscription: Subscription;
  activePage: any;
  queryParams: any;
  currentDate = moment().format('YYYY-MM-DD')
  company_details: any;
  allGstB2BArr: any;
  gstB2BArr: any;
  allGstB2CSArr : any;
  gstB2CSArr: any;
  allGstDOCSArr: any;
  gstDOCSArr: any;
  allGstHSNArr: any;
  gstHSNArr: any;
  allGstCDNRArr: any;
  gstCdnrArr: any;
  duration: any;
  isPrint: any;
  gstCDNRData: any;
  isError: boolean = false;
  showMessage: boolean = false;
  onSearch;
  onSearchFilter = new Subject<string>();
  navigationSubscription: any;
  reInitialise: any;
  loader: boolean = false;
  alertType = ''
  fromIndex: any = 0;
	toIndex: any = 30;
  message: string = ``;

  constructor(
    public commonService: CommonServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private downloadService: DownloadService,
    private store: Store<InitialState>

  ) { 
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  initialiseInvites() {
    if (this.reInitialise) {
      this.ngOnInit()
    }
  }
  

  ngOnInit() {
    this.isError = false
    let queryParams = JSON.parse(JSON.stringify(this.route.queryParams['value']))
    this.reInitialise = true
    
    this.loadData(queryParams)
    this.queryParams = queryParams;

   this.onSearchFilter.pipe(
    debounceTime(300),
    distinctUntilChanged())
    .subscribe(value => {
      this.filterData(value)
  });
  }

  ngOnDestroy() {
    if(this.gstPageSubscription){
      this.gstPageSubscription.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
  
  filterData(value){
    if(this.queryParams['gst_type']=='cdnr'){
      this.filterCdnrData(value)
    }else if(this.queryParams['gst_type']=='b2cs'){
      this.filterB2CSData(value)
    }else if(this.queryParams['gst_type']=='docs'){
      this.filterDOCSData(value)
    }else if(this.queryParams['gst_type']=='hsn'){
      this.filterHSNData(value)
    }else{
      this.getDataAll(value)
    }
  }

  async filterB2BData(value) {
    if (value.length >= 0) {
        this.queryParams['freetext'] = value
        let queryParams = JSON.parse(JSON.stringify(this.queryParams))
        this.queryParams = queryParams
        this.fromIndex=0
        queryParams['limit'] = 30;
        queryParams['offset'] = this.fromIndex;
        this.loader = true
        let resp = await this.newGetDataB2B('b2b', queryParams, false)
        this.loader = false
        if(resp){
            this.gstB2BArr = resp;
            let firstRow = document.getElementById('rowIndex_1') as HTMLElement
            if(firstRow){
              firstRow.scrollIntoView()
            }
        }
    } else {
        delete this.queryParams['freetext']
        this.searchB2bData(this.queryParams)
    }
  }

  OldfilterB2BData(value: any) {
    let tempArr = JSON.parse(JSON.stringify(this.allGstB2BArr['data']))
    let lowerCaseFilter = value.toLowerCase()
    let upperCaseFilter = value.toUpperCase()
    this.gstB2BArr['data'] = tempArr.filter(
      data => {
        if (	
            
            (
              data.reference != undefined && 
              data.reference != null && 
              (	
                data.reference.toString().includes(value) || 
                data.reference.toString().includes(upperCaseFilter) ||
                data.reference.toString().includes(lowerCaseFilter)
              )
            ) || 
            (
              data.place_of_supply != undefined && 
              data.place_of_supply != null && 
              (	
                data.place_of_supply.toString().includes(value) || 
                data.place_of_supply.toString().includes(upperCaseFilter) ||
                data.place_of_supply.toString().includes(lowerCaseFilter)
              )
            ) ||
            (
              data.gst_number != undefined && 
              data.gst_number != null && 
              (	
                data.gst_number.toString().includes(value) || 
                data.gst_number.toString().includes(upperCaseFilter) ||
                data.gst_number.toString().includes(lowerCaseFilter)
              )
            ) ||
            (
              data.receiver_name != undefined && 
              data.receiver_name != null && 
              (	
                data.receiver_name.toString().includes(value) || 
                data.receiver_name.toString().includes(upperCaseFilter) ||
                data.receiver_name.toString().includes(lowerCaseFilter)
              )
            ) ||
            (
              data.invoice_date != undefined && 
              data.invoice_date != null && 
              ((	
                data.invoice_date.toString().includes(value) || 
                data.invoice_date.toString().includes(upperCaseFilter) ||
                data.invoice_date.toString().includes(lowerCaseFilter)
              ) || 
              moment(data.invoice_date, 'YYYY-MM-DD').format('DD-MMM-YYYY').toUpperCase().indexOf(upperCaseFilter)>=0 )

            )

          ) {
          return data
        }
      }
    )
    this.calculateB2BSummary()
  }
  filterB2CSData(value: any) {
    let tempArr = JSON.parse(JSON.stringify(this.allGstB2CSArr['data']))
    let lowerCaseFilter = value.toLowerCase()
    let upperCaseFilter = value.toUpperCase()
    this.gstB2CSArr['data'] = tempArr.filter(
      data => {
        if (	
            
            
            (
              data.place_of_supply != undefined && 
              data.place_of_supply != null && 
              (	
                data.place_of_supply.toString().includes(value) || 
                data.place_of_supply.toString().includes(upperCaseFilter) ||
                data.place_of_supply.toString().includes(lowerCaseFilter)
              )
            ) ||

            (
              data.rate != undefined && 
              data.rate != null && 
              (	
                data.rate.toString().includes(value) || 
                data.rate.toString().includes(upperCaseFilter) ||
                data.rate.toString().includes(lowerCaseFilter)
              )
            )
          ) {
          return data
        }
      }
    )
    this.calculateB2CSSummary()
  }
 
  async filterCdnrData(value) {
    if (value.length >= 0) {
        this.queryParams['freetext'] = value
        let queryParams = JSON.parse(JSON.stringify(this.queryParams))
        this.queryParams = queryParams
        this.fromIndex=0
        queryParams['limit'] = 30;
        queryParams['offset'] = this.fromIndex;
        this.loader = true
        let resp = await this.newGetDataCdnr('cdnr', queryParams, false)
        this.loader = false
        if(resp){
            this.gstCdnrArr = resp;
            let firstRow = document.getElementById('rowIndex_1') as HTMLElement
            if(firstRow){
              firstRow.scrollIntoView()
            }
        }
    } else {
        delete this.queryParams['freetext']
        this.searchCdnrData(this.queryParams)
    }
  }

  filterHSNData(value: any) {
    let tempArr = JSON.parse(JSON.stringify(this.allGstHSNArr['data']))
    let lowerCaseFilter = value.toLowerCase()
    let upperCaseFilter = value.toUpperCase()
    this.gstHSNArr['data'] = tempArr.filter(
      data => {
        if (	
            
            (
              data.hsn != undefined && 
              data.hsn != null && 
              (	
                data.hsn.toString().includes(value) || 
                data.hsn.toString().includes(upperCaseFilter) ||
                data.hsn.toString().includes(lowerCaseFilter)
              )
            ) 

          ) {
          return data
        }
      }
    )
    this.calculateHSNSummary()
  }
  filterDOCSData(value: any) {
    throw new Error('Method not implemented.');
  }

  async loadData(queryParams){
    console.log("queryParams",queryParams)
    this.queryParams = queryParams
    if(queryParams){
      this.loader = true;
      if(queryParams.gst_type == 'cdnr'){
        this.activePage = 'cdnr'
        this.fromIndex = 0;
        queryParams['limit'] = 30;
        queryParams['offset'] = this.fromIndex;
        
        let resp = await this.searchCdnrData(queryParams)
      }else if(queryParams.gst_type == 'b2cs'){
        this.activePage = 'b2cs'
        let resp = await this.getDataB2CS(false)
      }else if(queryParams.gst_type == 'docs'){
        this.activePage = 'docs'
        let resp = await this.getDataDOCS(false)
      }else if(queryParams.gst_type == 'hsn'){
        this.activePage = 'hsn'
        let resp = await this.getDataHSN(false)
      }else{
        this.activePage = 'all'
        this.fromIndex = 0;
        queryParams['limit'] = 30;
        queryParams['offset'] = this.fromIndex;

        let resp = await this.getDataAll(queryParams)
      }
      this.loader = false;
    }
  }

  calculateB2BSummary(){
    let cess_amount = 0;
    let invoice_value = 0;
    let taxable_amount = 0;
    let total_record = 0
    let receiver_count = 0
    let uniqueGStMap = new Map()
    if(this.gstB2BArr['data'] && this.gstB2BArr['data'].length > 0){
        total_record = this.gstB2BArr['data'].length
        for (const data of this.gstB2BArr['data']) {
        uniqueGStMap.set(data.gst_number,1)
        cess_amount += data.cess_amount && !isNaN(Number(data.cess_amount)) ? Number(data.cess_amount) : 0
        invoice_value += data.invoice_value && !isNaN(Number(data.invoice_value)) ? Number(data.invoice_value) : 0
        taxable_amount += data.sum && !isNaN(Number(data.sum)) ? Number(data.sum) : 0
        }
        receiver_count = uniqueGStMap.size

    }
    this.gstB2BArr.summary={
        cess_amount,invoice_value,taxable_amount,total_record,receiver_count
    }
  }

  calculateB2CSSummary(){
    let cess_amount = 0;
    let invoice_value = 0;
    let taxable_amount = 0;
    let total_record = 0
    let receiver_count = 0
    let uniqueGStMap = new Map()
    if(this.gstB2CSArr['data'] && this.gstB2CSArr['data'].length > 0){
        total_record = this.gstB2CSArr['data'].length
        for (const data of this.gstB2CSArr['data']) {
        uniqueGStMap.set(data.gst_number,1)
        cess_amount += data.cess_amount && !isNaN(Number(data.cess_amount)) ? Number(data.cess_amount) : 0
        invoice_value += data.invoice_value && !isNaN(Number(data.invoice_value)) ? Number(data.invoice_value) : 0
        taxable_amount += data.taxable_value && !isNaN(Number(data.taxable_value)) ? Number(data.taxable_value) : 0
        }
        receiver_count = uniqueGStMap.size

    }
    this.gstB2CSArr.summary={
        cess_amount,invoice_value,taxable_amount,total_record,receiver_count
    }
  }

  calculateHSNSummary(){
    let total_value = 0;
    let taxable_value = 0;
    let integrated_tax_amount = 0;
    let central_tax_amount = 0;
    let state_tax_amount = 0;
    let cess_amount = 0;
    let total_record = 0;
    let receiver_count = 0;
    let uniqueHSNMap = new Map()
    if(this.gstHSNArr['data'] && this.gstHSNArr['data'].length > 0){
        total_record = this.gstHSNArr['data'].length
        for (const data of this.gstHSNArr['data']) {
        uniqueHSNMap.set(data.hsr,1)
        total_value += data.total_value && !isNaN(Number(data.total_value)) ? Number(data.total_value) : 0
        taxable_value += data.taxable_value && !isNaN(Number(data.taxable_value)) ? Number(data.taxable_value) : 0
        integrated_tax_amount += data.integrated_tax_amount && !isNaN(Number(data.integrated_tax_amount)) ? Number(data.integrated_tax_amount) : 0
        central_tax_amount += data.central_tax_amount && !isNaN(Number(data.central_tax_amount)) ? Number(data.central_tax_amount) : 0
        state_tax_amount += data.state_tax_amount && !isNaN(Number(data.state_tax_amount)) ? Number(data.state_tax_amount) : 0
        cess_amount += data.cess_amount && !isNaN(Number(data.cess_amount)) ? Number(data.cess_amount) : 0
        }
        receiver_count = uniqueHSNMap.size

    }
    this.gstHSNArr.summary={
      total_record,total_value,taxable_value,integrated_tax_amount,central_tax_amount,state_tax_amount,cess_amount
    }
  }

  

  openPage(pageName,gstReportType) {
    if (this.queryParams != null) {
      let start_date = this.queryParams.start_date ? this.queryParams.start_date : ''
      let end_date = this.queryParams.end_date ? this.queryParams.end_date : ''
      this.queryParams['gst_type'] = gstReportType
      
      this.loadData(this.queryParams)
      // this.router.navigateByUrl(`/display/statutory-report/gst-report/${pageName}-report?start_date=${start_date}&end_date=${end_date}&gst_type=${gstReportType}`)
    }
  }

  openAdvanceSearchModal(){
    this.store.dispatch(new OpenAdvanceSearchModal({status: true,title : 'gstreport'}));	
  }

  newGetDataB2B(gstType, query , getSummary) {
    query['gst_type'] = gstType
    if(getSummary) {
      query['summary'] = true;
    }
    return new Promise(async (resolve, reject) => {
      this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/reports/gst`, query).subscribe(res => {
        resolve(res)
      }, err => {
        resolve(null)
      })
    })
  }

  newGetDataCdnr(gstType, query , getSummary) {
    query['gst_type'] = gstType
    if(getSummary) {
      query['summary'] = true;
    }
    return new Promise(async (resolve, reject) => {
      this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/reports/gst`, query).subscribe(res => {
        resolve(res)
      }, err => {
        resolve(null)
      })
    })
  }

  async getDataB2B(gstType,isDownload=false) {
    this.loader = true;
    
    let query = {
      start_date: this.queryParams.start_date,
      end_date: this.queryParams.end_date,
      gst_type: this.queryParams.gst_type
    }
    query['gst_type'] = gstType;
    return new Promise(async (resolve, reject) => {
      this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/reports/gst`, query).subscribe(res => {
        if(!isDownload){
          this.duration = res['duration']
          this.allGstB2BArr = res
          this.gstB2BArr = JSON.parse(JSON.stringify(res)) 
        }
        this.loader = false;
        resolve(res)
      
      }, err => {
        resolve(null)
        this.loader = false;
      })
    })
  }


  async searchB2bData(values) {
		let reqObj = Object.assign({},values)
		
		this.loader = true;
    if(this.activePage == 'b2b') {
      let resp = await this.newGetDataB2B(this.activePage ,reqObj, false)
      let summaryResp = await this.newGetDataB2B(this.activePage ,reqObj, true)
      
      if(resp){
        this.loader = false;
        this.duration = resp['duration']
        this.gstB2BArr = resp
        this.gstB2BArr['summary'] = summaryResp;

      }
    } 
	}

  async getDataAll(values){
    let reqObj = Object.assign({},values)
		
		this.loader = true;
    if(this.activePage == 'all') {
      let resp = await this.newGetDataB2B(this.activePage ,reqObj, false)
      let summaryResp = await this.newGetDataB2B(this.activePage ,reqObj, true)
      if(resp){
        this.loader = false;
        this.duration = resp['duration']
        this.gstB2BArr = resp
        this.gstB2BArr['summary'] = summaryResp;
        this.checkError(resp)
      }

      }
    } 

  async searchCdnrData(values) {
		let reqObj = Object.assign({},values)
		
		this.loader = true;
    if(this.activePage == 'cdnr') {
      let resp = await this.newGetDataCdnr(this.activePage ,reqObj, false)
      let summaryResp = await this.newGetDataCdnr(this.activePage ,reqObj, true)
      
      if(resp){
        this.loader = false;
        this.duration = resp['duration']
        this.gstCdnrArr = resp
        this.gstCdnrArr['summary'] = summaryResp;

      }
    } 
	}

  async onScrollDownB2B() {
    this.fromIndex +=30
		let queryParams = JSON.parse(JSON.stringify(this.queryParams))
		queryParams['limit'] = 30;
		queryParams['offset'] = this.fromIndex;
		let resp = await this.newGetDataB2B('b2b', queryParams, false)
		if(resp && resp['data']){
			this.gstB2BArr['data'] = this.gstB2BArr['data'].concat(resp['data'])
      // this.gstB2BArr['summary'] = resp['summary']
		}
  }


  async onScrollDownCDNR() {
    this.fromIndex +=30
		let queryParams = JSON.parse(JSON.stringify(this.queryParams))
		queryParams['limit'] = 30;
		queryParams['offset'] = this.fromIndex;
		let resp = await this.newGetDataCdnr('cdnr', queryParams, false)
		if(resp && resp['data']){
			this.gstB2BArr['data'] = this.gstCDNRData['data'].concat(resp['data'])
		}
  }

  getDataB2CS(isDownload) {
    this.loader = true;
    let reqObj = {
      start_date: this.queryParams.start_date,
      end_date: this.queryParams.end_date,
      gst_type: 'b2cs'
    }
    return new Promise(async (resolve, reject) => {
      this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/reports/gst/b2cs`,reqObj).subscribe(res => {
        if(!isDownload){
          this.duration = res['duration']
          this.allGstB2CSArr = res
          this.gstB2CSArr = JSON.parse(JSON.stringify(res))
        }
        this.loader = false;
        resolve(res)
      }, err => {
        this.loader = false;
        resolve(null)
      })
    })
  }

  getDataCDNR(isDownload) {
    this.loader = true;
    let reqObj = {
      start_date: this.queryParams.start_date,
      end_date: this.queryParams.end_date,
      gst_type: 'cdnr'
    }
    return new Promise(async (resolve, reject) => {
      this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/reports/gst`,reqObj).subscribe(res => {
        if(!isDownload){
          this.duration = res['duration']
          this.allGstCDNRArr = res
          this.gstCdnrArr = JSON.parse(JSON.stringify(res))
        }
        this.loader = false;
        resolve(res)
      }, err => {
        this.loader = false;
        resolve(null)
      })
    })
  }

  getDataDOCS(isDownload) {
    this.loader = true;
    let reqObj = {
      start_date: this.queryParams.start_date,
      end_date: this.queryParams.end_date,
      gst_type: this.queryParams.gst_type
    }
    return new Promise(async (resolve, reject) => {
      this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/reports/gst/docs`, reqObj).subscribe(res => {
        if(!isDownload){
          this.duration = res['duration']
          this.allGstDOCSArr = res
          this.gstDOCSArr = JSON.parse(JSON.stringify(res))
        }
        
        this.loader = false;
        resolve(res)
      }, err => {
        this.loader = false;
        resolve(null)
      })
    })
  }
  getDataHSN(isDownload) {
    this.loader = true;
    let reqObj = {
      start_date: this.queryParams.start_date,
      end_date: this.queryParams.end_date,
      gst_type: this.queryParams.gst_type
    }
    return new Promise(async (resolve, reject) => {
      this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/reports/gst/hsn`, reqObj).subscribe(res => {
        if(!isDownload){
          this.duration = res['duration']
          this.allGstHSNArr = res
          this.gstHSNArr = JSON.parse(JSON.stringify(res))
        }
        this.loader = false;
        resolve(res)
      }, err => {
        this.loader = false;
        resolve(null)
      })
    })
  }

  async exportAsXLSXGST(version) {
    // if(this.isError==true){ 
    //   window.scroll(0,0)
    //   this.showMessage = true;
    //   this.alertType = 'error-box';
    //   this.message = `GSTR1 Download is disabled ! Please fix transactions highlighted in red.`
    //   // this.spinner.hide()
    //   return false
    // }

    let originalData = await this.getDataB2B('all',true)
    let b2bData = await this.getDataB2B('b2b',true)
    let cdnrData = await this.getDataCDNR(true)
    let b2csData = await  this.getDataB2CS(true)
    let hsnData = await this.getDataHSN(true)
    let docsData = await this.getDataDOCS(true)
    let exempData = await this.getDataB2B('exemp',true)

    let xlsData = []
    let xlsOriginal = await this.setGstXlsData(originalData,true)
    xlsData.push({
      "sheet" : 'ORIGINAL',
      "data" :  xlsOriginal
    })
    if(version == '1' || !version){

      let xlsB2b = await this.setGstXlsData(b2bData)
      xlsData.push({
        "sheet" : 'B2B',
        "data" :  xlsB2b
      })
  
      let xlsB2CS = await this.setB2CSXlsData(b2csData)
      xlsData.push({
        "sheet" : 'B2CS',
        "data" :  xlsB2CS
      })
  
      let xlsCDNR  = await this.setGstXlsData(cdnrData)
      xlsData.push({
        "sheet" : 'CDNR',
        "data" :  xlsCDNR
      })
      
     
  
      let xlsHSN = await this.setHSNXlsData(hsnData)
  
      xlsData.push({
        "sheet" : 'HSN',
        "data" :  xlsHSN
      })
  
      let xlsDOCS = await this.setDOCSXlsData(docsData)
  
      xlsData.push({
        "sheet" : 'DOCS',
        "data" :  xlsDOCS
      })
    }
    if(version == '2'){

      let xlsB2b = await this.setGstXlsDataV2(b2bData)
      xlsData.push({
        "sheet" : 'b2b,sez,de',
        "data" :  xlsB2b
      })
  
      let xlsB2CS = await this.setB2CSXlsDataV2(b2csData)
      xlsData.push({
        "sheet" : 'b2cs',
        "data" :  xlsB2CS
      })
  
      let xlsCDNR  = await this.setCDNRXlsDataV2(cdnrData)
      xlsData.push({
        "sheet" : 'cdnr',
        "data" :  xlsCDNR
      })
      
     
  
      let xlsHSN = await this.setHSNXlsDataV2(hsnData)
  
      xlsData.push({
        "sheet" : 'hsn',
        "data" :  xlsHSN
      })
  
      let xlsDOCS = await this.setDOCSXlsDataV2(docsData)
  
      xlsData.push({
        "sheet" : 'docs',
        "data" :  xlsDOCS
      })
      let xlsExemp = await this.setExempXlsDataV2(exempData)
  
      xlsData.push({
        "sheet" : 'exemp',
        "data" :  xlsExemp
      })
    }



    let {from_date,to_date} = this.duration
    this.downloadService.exportAsXLSXGST(
        `GSTR1 ${moment(from_date).format('YYYY-MM-DD')} - ${moment(to_date).format('YYYY-MM-DD')}`,xlsData
        );
  }

  setGstXlsData(xlData,isOriginal = false){
    let xlsOriginal = [];
    for (const element of xlData['data']) {
      let obj = {};
      obj["GSTIN/UIN of Recipient"] = element.gst_number ? element.gst_number : '';
      obj["Receiver Name"] = element.receiver_name;
      obj["Invoice Number"] = element.reference;
      obj["Invoice date"] = moment(element.invoice_date).format('DD-MMM-YYYY')
      obj["transaction_type"]=element.transaction_type?element.transaction_type:''
      obj["Invoice Value"] = element.invoice_value ? Number(Number(element.invoice_value).toFixed(2)) : 0;
      obj["Place of Supply"] = element.place_of_supply;
      obj["Reverse Charge"] = element.reverse_charge;
      obj["Applicable % of Tax Rate"] = element.c_gst_rate_sc ? Number(Number(element.c_gst_rate_sc).toFixed(2)) : 0;
      obj["Invoice Type"] = element.invoice_type;
      obj["E-Commerce GSTIN"] = element.e_commerce_gstin;
      obj["Rate"] = element.rate ? Number(Number(element.rate).toFixed(2)) : 0;
      obj["HSN"] = element.hsn_sac ? element.hsn_sac : '';
      if(isOriginal) obj["SC 1"] = element.sc1_amount ? Number(Number(element.sc1_amount).toFixed(2)) : 0;
      obj["CGST"] = element.cgst_amount ? Number(Number(element.cgst_amount).toFixed(2)) : 0;
      obj["SGST"] = element.sgst_amount ? Number(Number(element.sgst_amount).toFixed(2)) : 0;
      obj["IGST"] = element.igst_amount ? Number(Number(element.igst_amount).toFixed(2)) : 0;
      if(isOriginal) {
        obj["SC 2"] = element.sc2_amount ? Number(Number(element.sc2_amount).toFixed(2)) : 0;
        obj["SC 3"] = element.sc3_amount ? Number(Number(element.sc3_amount).toFixed(2)) : 0;
        obj["GST ON SC 2,3"] = element.x_gst_amount ? Number(Number(element.x_gst_amount).toFixed(2)) : 0;
      }
      obj["Taxable Value"] = element.sum ? Number(Number(element.sum).toFixed(2)) : 0;
      obj["Cess Amount"] = element.cess_amount ? Number(Number(element.cess_amount).toFixed(2)) : 0;
      obj["Invoice Amount"] = element.invoice_amount ? Number(Number(element.invoice_amount).toFixed(2)) : 0;

      if(element.hasOwnProperty('non_refundable_to_client')) {
        obj["Non Refundable"] = element.non_refundable_to_client === true ? "YES" : "NO";
      }
      xlsOriginal.push(obj);
    }

    if(xlData && xlData['summary']){
      let obj = {};
      obj["Invoice Number"] =  "TOTAL REC";
      obj["Invoice Value"] =  xlData['summary'].invoice_value ? Number(Number(xlData['summary'].invoice_value).toFixed(2)) : 0;
      obj["CGST"] = xlData['summary'].cgst_amount ? Number(Number(xlData['summary'].cgst_amount).toFixed(2)) : 0;
      obj["SGST"] = xlData['summary'].sgst_amount ? Number(Number(xlData['summary'].sgst_amount).toFixed(2)) : 0;
      obj["IGST"] = xlData['summary'].igst_amount ? Number(Number(xlData['summary'].igst_amount).toFixed(2)) : 0;
      obj["Taxable Value"] =  xlData['summary'].taxable_amount ? Number(Number(xlData['summary'].taxable_amount).toFixed(2)) : 0;
      xlsOriginal.push(obj)
    }
    return xlsOriginal
  }
 
  setGstXlsDataV2(xlData,isOriginal = false){
    let xlsOriginal = [];
    for (const element of xlData['data']) {
      let obj = {};
      obj["GSTIN/UIN of Recipient"] = element.gst_number ? element.gst_number : '';
      obj["Receiver Name"] = element.receiver_name;
      obj["Invoice Number"] = element.reference;
      obj["Invoice date"] = moment(element.invoice_date).format('DD-MMM-YY')
      obj["Invoice Value"] = element.invoice_value ? Number(Number(element.invoice_value).toFixed(2)) : 0;
      obj["Place of Supply"] = element.place_of_supply;
      obj["Reverse Charge"] = element.reverse_charge;
      obj["Applicable % of Tax Rate"] = '';
      obj["Invoice Type"] = element.invoice_type;
      obj["E-Commerce GSTIN"] = element.e_commerce_gstin;
      obj["Rate"] = element.rate ? Number(Number(element.rate).toFixed(2)) : 0;
      obj["HSN"] = element.hsn_sac  || '';
      obj["Taxable Value"] = element.sum ? Number(Number(element.sum).toFixed(2)) : 0;
      obj["Cess Amount"] = element.cess_amount ? Number(Number(element.cess_amount).toFixed(2)) : 0;
      xlsOriginal.push(obj);
    }

    if(xlData && xlData['summary']){
      let obj = {};
      obj["Invoice Number"] =  "TOTAL REC";
      obj["Invoice Value"] =  xlData['summary'].invoice_value ? Number(Number(xlData['summary'].invoice_value).toFixed(2)) : 0;
      obj["Taxable Value"] =  xlData['summary'].taxable_amount ? Number(Number(xlData['summary'].taxable_amount).toFixed(2)) : 0;
      xlsOriginal.push(obj)
    }
    return xlsOriginal
  }

  setCDNRXlsDataV2(xlData){
    let xlsOriginal = [];
    for (const element of xlData['data']) {
      let obj = {};
      obj["GSTIN/UIN of Recipient"] = element.gst_number ? element.gst_number : '';
      obj["Receiver Name"] = element.receiver_name;
      obj["Note Number"] = element.reference;
      obj["Note Date"] = moment(element.invoice_date).format('DD-MMM-YY')
      obj["Note Type"] = '';
      obj["Place of Supply"] = element.place_of_supply;
      obj["Reverse Charge"] = element.reverse_charge;
      obj["Note Supply Type"] = element.invoice_type;
      obj["Note Value"] = element.invoice_value ? Number(Number(element.invoice_value).toFixed(2)) : 0;
      obj["Applicable % of Tax Rate"] = '';
      obj["Rate"] = element.rate ? Number(Number(element.rate).toFixed(2)) : 0;
      obj["HSN"] = element.hsn_sac || '';
      obj["Taxable Value"] = element.sum ? Number(Number(element.sum).toFixed(2)) : 0;
      obj["Cess Amount"] = element.cess_amount ? Number(Number(element.cess_amount).toFixed(2)) : 0;
      xlsOriginal.push(obj);
    }

    if(xlData && xlData['summary']){
      let obj = {};
      obj["Note Number"] =  "TOTAL REC";
      obj["Note Value"] =  xlData['summary'].invoice_value ? Number(Number(xlData['summary'].invoice_value).toFixed(2)) : 0;
      obj["Taxable Value"] =  xlData['summary'].taxable_amount ? Number(Number(xlData['summary'].taxable_amount).toFixed(2)) : 0;
      xlsOriginal.push(obj)
    }
    return xlsOriginal
  }

  setB2CSXlsData(xlData){
    let xlsB2CS = [];
    for (const element of xlData['data']) {
      let obj = {};
      obj["Type"] = `${element.type}`;
      obj["Place of Supply"] = element.place_of_supply;
      obj["Rate"] = element.rate ? Number(Number(element.rate).toFixed(2)) : 0;
      obj["Taxable Value"] = element.taxable_value ? Number(Number(element.taxable_value).toFixed(2)) : 0;
      obj["Cess Amount"] = element.cess_amount ? Number(Number(element.cess_amount).toFixed(2)) : 0;
      obj["E-Commerce GSTIN"] = element.e_commerce_gstin;

      xlsB2CS.push(obj);
    }

    if(xlData && xlData['summary']){
      let obj = {};
      obj["Type"] =  "TOTAL";
      obj["Taxable Value"] =  xlData['summary'].taxable_amount ? Number(Number(xlData['summary'].taxable_amount).toFixed(2)) : 0;
      xlsB2CS.push(obj)
    }

    return xlsB2CS

  }
  setB2CSXlsDataV2(xlData){
    let xlsB2CS = [];
    for (const element of xlData['data']) {
      let obj = {};
      obj["Type"] = `${element.type}`;
      obj["Place of Supply"] = element.place_of_supply;
      obj["Applicable % of Tax Rate"] = '';
      obj["Rate"] = element.rate ? Number(Number(element.rate).toFixed(2)) : 0;
      obj["Taxable Value"] = element.taxable_value ? Number(Number(element.taxable_value).toFixed(2)) : 0;
      obj["Cess Amount"] = element.cess_amount ? Number(Number(element.cess_amount).toFixed(2)) : 0;
      obj["E-Commerce GSTIN"] = element.e_commerce_gstin;

      xlsB2CS.push(obj);
    }

    if(xlData && xlData['summary']){
      let obj = {};
      obj["Type"] =  "TOTAL";
      obj["Taxable Value"] =  xlData['summary'].taxable_amount ? Number(Number(xlData['summary'].taxable_amount).toFixed(2)) : 0;
      xlsB2CS.push(obj)
    }

    return xlsB2CS

  }

  setHSNXlsData(xlData){
    let xlsHSN = [];
    for (const element of xlData['data']){  
      let obj = {};
      obj["HSN"] = `${element.hsn ? element.hsn : ""}`;
      obj["Description"] = `${element.description}`;
      obj["UQC"] = `${element.ucq}` ? (isNaN(element.ucq) ? element.ucq : Number(Number(element.ucq).toFixed(2))) : 0;
      obj["Total Quantity"] = `${element.quantity}` ? Number(Number(element.quantity).toFixed(2)) : 0;
      obj["Rate"] = `${element.rate}` ? Number(Number(element.rate).toFixed(2)) : 0;
      obj["Total Value"] = `${element.total_value}` ? Number(Number(element.total_value).toFixed(2)) : 0;
      obj["Taxable Value"] = `${element.taxable_value}` ? Number(Number(element.taxable_value).toFixed(2)) : 0;
      obj["Integrated Tax Amount"] = `${element.integrated_tax_amount}` ? Number(Number(element.integrated_tax_amount).toFixed(2)) : 0;
      obj["Central Tax Amount"] = `${element.central_tax_amount}` ? Number(Number(element.central_tax_amount).toFixed(2)) : 0;
      obj["State/UT Tax Amount"] = `${element.state_tax_amount}` ? Number(Number(element.state_tax_amount).toFixed(2)) : 0;
      obj["Cess Amount"] = `${element.cess_amount}` ? Number(Number(element.cess_amount).toFixed(2)) : 0;

      xlsHSN.push(obj)
      
    }
    xlData && xlData['data'] && xlData['data'].forEach(element => {
     
    });
    if(xlData && xlData['summary']){
      let obj = {};
      obj["HSN"] =  "TOTAL";
      obj["Total Quantity"] =  xlData['summary'].quantity ? Number(Number(xlData['summary'].quantity).toFixed(2)) : 0;
      obj["Total Value"] =  xlData['summary'].total_value ? Number(Number(xlData['summary'].total_value).toFixed(2)) : 0;
      obj["Taxable Value"] =  xlData['summary'].taxable_value ? Number(Number(xlData['summary'].taxable_value).toFixed(2)) : 0;
      obj["Total Value"] =  xlData['summary'].total_value ? Number(Number(xlData['summary'].total_value).toFixed(2)) : 0;
      obj["Integrated Tax Amount"] =  xlData['summary'].integrated_tax_amount ? Number(Number(xlData['summary'].integrated_tax_amount).toFixed(2)) : 0;
      obj["Central Tax Amount"] =  xlData['summary'].central_tax_amount ? Number(Number(xlData['summary'].central_tax_amount).toFixed(2)) : 0;
      obj["State/UT Tax Amount"] =  xlData['summary'].state_tax_amount ? Number(Number(xlData['summary'].state_tax_amount).toFixed(2)) : 0;
      xlsHSN.push(obj)
    }
    return xlsHSN
  }
  setHSNXlsDataV2(xlData){
    let xlsHSN = [];
    for (const element of xlData['data']){  
      let obj = {};
      obj["HSN"] = `${element.hsn ? element.hsn : ""}`;
      obj["Description"] = `${element.description}`;
      obj["UQC"] = element.ucq ? `${element.ucq}`: 'OTH-OTHERS';
      obj["Total Quantity"] = `${element.quantity}` ? Number(Number(element.quantity).toFixed(2)) : 0;
      obj["Total Value"] = `${element.total_value}` ? Number(Number(element.total_value).toFixed(2)) : 0;
      obj["Rate"] = `${element.rate}` ? Number(Number(element.rate).toFixed(2)) : 0;
      obj["Taxable Value"] = `${element.taxable_value}` ? Number(Number(element.taxable_value).toFixed(2)) : 0;
      obj["Integrated Tax Amount"] = `${element.integrated_tax_amount}` ? Number(Number(element.integrated_tax_amount).toFixed(2)) : 0;
      obj["Central Tax Amount"] = `${element.central_tax_amount}` ? Number(Number(element.central_tax_amount).toFixed(2)) : 0;
      obj["State/UT Tax Amount"] = `${element.state_tax_amount}` ? Number(Number(element.state_tax_amount).toFixed(2)) : 0;
      obj["Cess Amount"] = `${element.cess_amount}` ? Number(Number(element.cess_amount).toFixed(2)) : 0;

      xlsHSN.push(obj)
      
    }
    xlData && xlData['data'] && xlData['data'].forEach(element => {
     
    });
    if(xlData && xlData['summary']){
      let obj = {};
      obj["HSN"] =  "TOTAL";
      obj["Total Quantity"] =  xlData['summary'].quantity ? Number(Number(xlData['summary'].quantity).toFixed(2)) : 0;
      obj["Total Value"] =  xlData['summary'].total_value ? Number(Number(xlData['summary'].total_value).toFixed(2)) : 0;
      obj["Taxable Value"] =  xlData['summary'].taxable_value ? Number(Number(xlData['summary'].taxable_value).toFixed(2)) : 0;
      obj["Total Value"] =  xlData['summary'].total_value ? Number(Number(xlData['summary'].total_value).toFixed(2)) : 0;
      obj["Integrated Tax Amount"] =  xlData['summary'].integrated_tax_amount ? Number(Number(xlData['summary'].integrated_tax_amount).toFixed(2)) : 0;
      obj["Central Tax Amount"] =  xlData['summary'].central_tax_amount ? Number(Number(xlData['summary'].central_tax_amount).toFixed(2)) : 0;
      obj["State/UT Tax Amount"] =  xlData['summary'].state_tax_amount ? Number(Number(xlData['summary'].state_tax_amount).toFixed(2)) : 0;
      xlsHSN.push(obj)
    }
    return xlsHSN
  }

  setDOCSXlsData(xlData){
    let xlsDOCS = [];
    for (const element of xlData['data']) {
      let obj = {};
      obj["Nature of Document"] = `${element.nature_of_documents}`;
      obj["SR. NO. FROM"] = `${element.from_reference}`;
      obj["SR. NO. TO"] = `${element.to_reference}`;
      obj["TOTAL NUMBER"] = element.total_number ? Number(Number(element.total_number).toFixed(2)) : 0;
      obj["CANCELLED"] = element.total_cancelled ? Number(Number(element.total_cancelled).toFixed(2)) : 0;
      xlsDOCS.push(obj);
    }
    

    if(xlData && xlData['summary']){
      let obj = {};
      obj["Nature of Document"] =  "TOTAL";
      obj["TOTAL NUMBER"] =  xlData['summary'].total_number ? Number(Number(xlData['summary'].total_number).toFixed(2)) : 0;
      obj["CANCELLED"] =  xlData['summary'].total_cancelled ? Number(Number(xlData['summary'].total_cancelled).toFixed(2)) : 0;
      xlsDOCS.push(obj)
    }
    return xlsDOCS
  }
  setDOCSXlsDataV2(xlData){
    let xlsDOCS = [];
    for (const element of xlData['data']) {
      let obj = {};
      obj["Nature of Document"] = `${element.nature_of_documents}`;
      obj["Sr. No. From"] = `${element.from_reference}`;
      obj["Sr. No. To"] = `${element.to_reference}`;
      obj["Total Number"] = element.total_number ? Number(Number(element.total_number).toFixed(2)) : 0;
      obj["Cancelled"] = element.total_cancelled ? Number(Number(element.total_cancelled).toFixed(2)) : 0;
      xlsDOCS.push(obj);
    }
    

    if(xlData && xlData['summary']){
      let obj = {};
      obj["Nature of Document"] =  "TOTAL";
      obj["Total Number"] =  xlData['summary'].total_number ? Number(Number(xlData['summary'].total_number).toFixed(2)) : 0;
      obj["Cancelled"] =  xlData['summary'].total_cancelled ? Number(Number(xlData['summary'].total_cancelled).toFixed(2)) : 0;
      xlsDOCS.push(obj)
    }
    return xlsDOCS
  }

  setExempXlsDataV2(xlData){
    let xlsExemp = [];
    for (const element of xlData['data']) {
      let obj = {};
      obj["Description"] = `${element.description}`;
      obj["Nil Rated Supplies"] = element.nil_rated ? Number(Number(element.nil_rated).toFixed(2)) : 0;
      obj["Exempted(other than nil rated/non GST supply)"] = element.non_nil_non_suppplier ? Number(Number(element.non_nil_non_suppplier).toFixed(2)) : 0;
      obj["Non-GST Supplies"] = element.non_gst_supply ? Number(Number(element.non_gst_supply).toFixed(2)) : 0;
      xlsExemp.push(obj);
    }
    
    return xlsExemp
  }

  checkError(resp){
    let data  = resp['data']
    let errorData = data.find(el=>el.place_of_supply=='-'||el.hsn_sac=='')
  console.log("errorData",errorData)
  this.isError = errorData && Object.keys(errorData).length>0?true:false
    
  }
  
  reloadPage() {
    window.location.reload();
}

  hideError() {
    this.showMessage = false
  }

  downloadGDFile(){
    let queryParams = JSON.parse(JSON.stringify(this.route.queryParams['value']))
    console.log("queryParams",queryParams);
    this.commonService.downloadPDF(`${environment.okountUrl}/reports/gst-v2/gstr1v2?start_date=${queryParams.start_date}&end_date=${queryParams.end_date}`).subscribe((fileBlob:any) =>{
        let fileName = `GST-REPORT-${Date.now()}.xlsx` 			
        let blob = new Blob([fileBlob], { 
          type: 'application/xlsx'
        });
        FileSaver.saveAs(blob, fileName);
	   })
  }

  downloadB3GDFile(){
    let queryParams = JSON.parse(JSON.stringify(this.route.queryParams['value']))
    console.log("queryParams",queryParams);
    this.commonService.downloadPDF(`${environment.okountUrl}/reports/gst-3b?start_date=${queryParams.start_date}&end_date=${queryParams.end_date}`).subscribe((fileBlob:any) =>{
        let fileName = `GST-B3-REPORT-${Date.now()}.xlsx` 		
        let blob = new Blob([fileBlob], { 
          type: 'application/xlsx'
        });
        FileSaver.saveAs(blob, fileName);
	   })
  }	

  downloadData(){
    let queryParams = JSON.parse(JSON.stringify(this.route.queryParams['value']))
    console.log("queryParams",queryParams);
    this.commonService.downloadPDF(`${environment.okountUrl}/reports/gst/excelData?start_date=${queryParams.start_date}&end_date=${queryParams.end_date}`).subscribe((fileBlob:any) =>{
        let fileName = `GST-DATA-${Date.now()}.xlsx` 			
        let blob = new Blob([fileBlob], { 
          type: 'application/xlsx'
        });
        FileSaver.saveAs(blob, fileName);
	   })
  }

  async download3bFile() {
    let reqObj = Object.assign({}, this.queryParams)
		
		this.loader = true;
    let resp = await this.get3bGstData(reqObj)
    if(resp && resp['data'] && resp['data'].length> 0) {
        let xlsxData = []
        let allArray = []
        resp['data'].forEach(data => {
          let obj = {};
          obj["Object Name"] = data.object_name;
          obj["Reconciled"] = data.reconciled;
          obj["Supplier GSTN"] = data.gst_number;
          obj["Supplier"] = data.supplier;
          obj["POS"] = data.place_of_supply;
          obj["Reference"] = data.reference;
            
          obj["Date"] = moment(data.invoice_date).format('DD-MMM-YYYY');
          obj["Entry"] = moment(data.invoice_date).format('DD-MMM-YYYY');

          obj["Type"] = data.type;
          obj["Reverse"] = data.reverse_charge;
          obj["Pre GST"] = data.pre_gst;
          obj["Against"] = data.against;
          obj["Against Date"] = data.against_date;
          obj["Net Amount"] = data.net_amount;
          obj["Rate"] = data.rate;
          obj["Taxable Value"] = data.taxable_value;
            
          obj["IGST"] = data.integrated_tax_amount;
          obj["CGST"] = data.central_tax_amount;
          obj["SGST"] = data.state_tax_amount;
          obj["Cess"] = data.cess;
          obj["GST Total"] = data.gst_total;
            
          obj["ITC %"] = data.itc_percentage;
          obj["Branch"] = '';
        
          allArray.push(obj);
          
        });

        xlsxData.push({
          "sheet" : 'All Purchase',
          "data" :  allArray
        });

        let gstPurchase = resp['data'].filter(item => Number(item.taxable_value) > 0)  
        let purchaseArray = []
        gstPurchase.forEach(data => {
          let obj = {};
          obj["Object Name"] = data.object_name;
          obj["Reconciled"] = data.reconciled;
          obj["Supplier GSTN"] = data.gst_number;
          obj["Supplier"] = data.supplier;
          obj["POS"] = data.place_of_supply;
          obj["Reference"] = data.reference;
            
          obj["Date"] = moment(data.invoice_date).format('DD-MMM-YYYY');
          obj["Entry"] = moment(data.invoice_date).format('DD-MMM-YYYY');

          obj["Type"] = data.type;
          obj["Reverse"] = data.reverse_charge;
          obj["Pre GST"] = data.pre_gst;
          obj["Against"] = data.against;
          obj["Against Date"] = data.against_date;
          obj["Net Amount"] = data.net_amount;
          obj["Rate"] = data.rate;
          obj["Taxable Value"] = data.taxable_value;
            
          obj["IGST"] = data.integrated_tax_amount;
          obj["CGST"] = data.central_tax_amount;
          obj["SGST"] = data.state_tax_amount;
          obj["Cess"] = data.cess;
          obj["GST Total"] = data.gst_total;
            
          obj["ITC %"] = data.itc_percentage;
          obj["Branch"] = '';
        
          purchaseArray.push(obj);
        });

        xlsxData.push({
          "sheet" : 'GST Purchase',
          "data" :  purchaseArray
        });

        this.downloadService.exportAsXLSXGST(`GST 3B REPORT :`, xlsxData);
	  }

    this.loader = false;
  }

  get3bGstData(query) {
    return new Promise(async (resolve, reject) => {
      this.commonService.getRequestWithQueryParamsNoLoader(`${environment.okountUrl}/reports/gst/3b`, query).subscribe(res => {
        resolve(res)
      }, err => {
        resolve(null)
      })
    })
  }
_
}
