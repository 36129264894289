<form [formGroup]="outstandingFilterForm">

  <div class="flexbox aff-info">
    <div class="flexbox aff-info-inner">

      <div class="flexbox">
        <div class="select-field text-input-fiel">
          <select class="input-field" formControlName="account_type" searchtabindex="1">
            <option value="client">Client</option>
            <option value="airline">Airline</option>
            <option value="supplier">Supplier</option>
            <option value="employee">Employee</option>
            <option value="general">General</option>
            <option value="all">ALL</option>
          </select>
          <label for="subLedger">Account Type</label>

        </div>
        <div class="input-fiel form-group ng-field" searchtabindex="2" (focus)="focusAccountAc()">
          <label class="ngauto-label" for="affname">Account</label>
          <div class="ng-autocomplete">
            <ng-autocomplete #accountAC [data]="clientArr" (inputCleared)="clearData('client')"
              [searchKeyword]="keyword" (inputChanged)='getClient($event,false)' historyIdentifier="clientArr"
              [itemTemplate]="itemTemplate" formControlName="client_name"
              (selected)="selectOutStandingAccount($event,'client')" [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                                ({{item.account_code}})</span><br />
                            <span class="colr-2">{{item.primary_email}}</span><br />'>
              </a>
            </ng-template>
            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="flexbox mt-3">
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="3" formControlName="start_date" id="from_date" type="date"
               min="{{minDate}}" max="{{maxDate}}"
               [ngClass]="{'error-box': !outstandingFilterForm.controls.start_date.valid}"
              />
          <label for="from_date">Starting Date</label>
        </div>
        <div class="input-fiel">
          <input class="input-ui" searchtabindex="4" formControlName="end_date" id="to_date" type="date"
              min="{{minDate}}" max="{{maxDate}}"
              [ngClass]="{'error-box': !outstandingFilterForm.controls.end_date.valid}"
              />
          <label for="to_date">Ending Date</label>
        </div>

      </div>


      <div class="flexBox d-flex mt-3">
        <div class="input-fiel" searchtabindex="5" (focus)="focusSalesprAc()">
          <label class="ngauto-label" for="affname">Sales Person</label>
          <div class="ng-autocomplete">
            <ng-autocomplete #salesprAC [data]="salesPersonArr" (inputCleared)="clearData('sales_person')"
              [searchKeyword]="keyword" (inputChanged)='getSalesPerson($event,false)' [itemTemplate]="itemTemplate"
              formControlName="sales_person_name" (selected)="selectOutStandingAccount($event,'sales_person')"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}
                                  ({{item.account_code}})</span><br />
                              <span class="colr-2">{{item.primary_email}}</span><br />'>
              </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
        <div class="input-fiel">
          <input class="input-ui" id="categoryList" type="text" searchtabindex="6" formControlName="category"
            placeholder="Category" /><label for="categoryList">Category</label>
        </div>
      </div>



      <div class="flexBox d-flex mt-3">
        <div class="select-field text-input-fiel">
          <select class="input-field" searchtabindex="7" formControlName="subleger_type">
            <option value="client">Client</option>
            <option value="airline">Airline</option>
            <option value="supplier">Supplier</option>
            <option value="employee">Employee</option>
            <option value="general">General</option>
          </select>
          <label for="subLedger">Sub Ledger Type</label>

        </div>

        <div class="input-fiel" searchtabindex="8" (focus)="focusSubledgerAc()">
          <label class="ngauto-label" for="uuid">Sub Ledger</label>
          <div class="ng-autocomplete">
            <ng-autocomplete #subledgerAC [data]="ledgerList" [searchKeyword]="keyword"
              (inputChanged)='getLedgers($event,false)' [itemTemplate]="itemTemplate" formControlName="subledger_name"
              (selected)="selectOutStandingAccount($event,'subledger')" [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>


            <ng-template #itemTemplate let-item>
              <a innerHTML='<span class="colr-1">{{item.name}}({{item.account_code}})</span><br />'> </a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>

      </div>
      <div class="flexBox d-flex mt-3">
        <div class="input-fiel" (focus)="focusCityAc()" searchtabindex="9">
          <label class="ngauto-label" id="citylabel">City</label>
          <div class="ng-autocomplete">
            <ng-autocomplete #cityAC formControlName="city_name" [data]="cityArr" [searchKeyword]="cityKeyword"
              (inputChanged)='getCity($event,false)' [itemTemplate]="cityItem" (selected)="selectCity($event,'city_id')"
              [notFoundTemplate]="cityNotFoundTemplate">
            </ng-autocomplete>
            <ng-template #cityItem let-cityDetail>
              <a innerHTML='<span class="colr-1">{{cityDetail.city}} ({{cityDetail.country}})</span><br />'> </a>
            </ng-template>

            <ng-template #cityNotFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>

        
          <div class="select-field text-input-fiel">
            <select class="input-field"  formControlName="selectedBranch" id="selectedBranch">
            <option value="">Select</option>
            <option value="">None</option>
            <option value="all">All</option>
            <option *ngFor="let item of branches" value="{{item.tenant_id}}">{{item?.branch_name ? item.branch_name : "HQ"}}
            </option>
            </select>
            <label for="selectedBranch">Branch</label>
          </div>

      </div>





      <!-- TODO : Make sure REPORT STYLE Feature works in back-end AND Fix FRONT-END after that ENABLE it  -->
      <!-- <span class="sub-head">Report Style</span>

      <div class="flexBox d-flex mt-1 ml-2 mr-2 justify-content-between">
        <div class="input-fiel">
          <label class="radio-label" for="normalView">Normal</label>
          <input class="radio-ui" id="normalView" type="radio" name="viewMode" value="normal" searchtabindex="10"
            formControlName="viewMode" />
        </div>

        <div class="input-fiel">
          <label class="radio-label" for="summaryView">Summary</label>
          <input class="radio-ui" id="summaryView" type="radio" name="viewMode" value="summary" searchtabindex="11"
            formControlName="viewMode" />
        </div>

        <div class="input-fiel">
          <label class="radio-label" for="detailedView">Detailed</label>
          <input class="radio-ui" id="detailedView" type="radio" name="viewMode" value="detailed" searchtabindex="12"
            formControlName="viewMode" />
        </div>
      </div> -->

      <!-- TODO : Make sure Exclude Feature works in back-end then ENABLE it from front-end -->
      <!-- <span class="sub-head ">Exclude</span>

      <div class="flexBox d-flex mt-1 ml-2 mr-2 justify-content-between">
        <div class="input-fiel">
          <label class="radio-label" for="uuid">Invoice</label>
          <input class="radio-ui" id="uuid" type="checkbox" name="" searchtabindex="13" formControlName="client_id" />
        </div>

        <div class="input-fiel">
          <label class="radio-label" for="genid">Credit Note</label>
          <input class="radio-ui" id="genid" type="checkbox" name="" searchtabindex="14" formControlName="general" />
        </div>

        <div class="input-fiel">
          <label class="radio-label" for="empid">Payment</label>
          <input class="radio-ui" id="empid" type="checkbox" name="" searchtabindex="15" formControlName="employee" />
        </div>

        <div class="input-fiel">
          <label class="radio-label" for="airid">Receipt</label>
          <input class="radio-ui" id="airid" type="checkbox" name="" searchtabindex="16" formControlName="airline" />
        </div>

        <div class="input-fiel">
          <label class="radio-label" for="supid">JV</label>
          <input class="radio-ui" id="supid" type="checkbox" name="" searchtabindex="17" formControlName="supplier" />
        </div>
      </div> -->


      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" data-dismiss="modal"
            searchtabindex="10" #searchButton (keyup.enter)="clickSearchButton()"
            [disabled]="outstandingFilterForm.invalid"
            (click)="search(outstandingFilterForm.value)">
            Search</button>
          <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
        </div>
      </div>

    </div>

  </div>
  <!-- </div> -->
</form>