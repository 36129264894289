
<div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
  <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
    <div class="d-flex flex-y-center">
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
          alertType=='success-box' ? 'green-sucess.svg' : 
          alertType=='warning-box' ? 'red-warning.svg' : 
          null }}"
        width="20px"
        height="20px"
        alt="alert-logo"
        class="mr-20"
      />
      <div class="ml-10">
        <h4 class="m-0 text-size-18 text-bold"
        [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
          {{alertType === "error-box"
          ? "Error"
          : alertType === "warning-box"
          ? "Warning"
          : alertType === "success-box"
          ? "Success"
          : null}}
        </h4>
        <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
         {{message}}
        </small>
      </div>
    </div>
    <img
      src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
      alt="close-btn"
      (click)="hideError()"
      className=" close-btn"
    />
  </div>
</div>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<button type="button" hidden #openSuccessModal data-toggle="modal" data-target="#successModal"></button>
    <!-- Modal -->
  <div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
      <div class="modal-dialog transaction-creation-modal">
          <!-- Modal content-->
          <div class="modal-content"  *ngIf="invoice_no">
              <div class="modal-header">
                  <span class="messageFont">
                      <span>
                          {{message}} 
                      </span>
                          <a *ngIf="invoice_no != ''" 
                              href="javascript:void(0)"	
                              (click)="viewInvoice(invoice_no)">
                              {{invoice_no}}
                          </a>
                          <a *ngIf="invoice_no != ''" [title]="copyELementTitle" (click)="copytext(invoice_no)">

                            <i  class="fa fa-copy cursor-pointer"></i>
                          </a>
                      </span>
                      <button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
                  </div>
              <div class="modal-body">
                  <p>Do you want to create new invoice or  view newly created invoice ?</p>
              </div>
              <div class="modal-footer justify-content-center">
                  <button 
                      tabIndex="1" 
                      tabindex="1"
                      type="button" 
                      class="btn btn-primary bg-blue" 
                      (click)="viewInvoice(invoice_no)">
                      View
                  </button>
                  <button 
                      tabIndex="2" 
                      tabindex="2"
                      type="button" 
                      class="btn btn-primary bg-green"  
                      (click)="closeSuccesModal()">
                      Create New
                  </button>
              </div>
          </div>
      </div>
  </div>

<div class="ledger-container" [ngClass]="{'d-none': !newInvoice}" >
 <ng-container>
     <div class="row tab-nav pl-0 pr-0">
         <!-- <div class="col-sm-4 pl-0">
             <ul class="list-inline list-unstyled p-0 m-0">
                 <li class="active page-title text-uppercase cursor"> View Client</li>
             </ul>
         </div> -->
         <div class="col-sm-12">
           <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
               <li  
                   class="text-grey text-capitalize cursor  hover-text-primary transition"> Invoice</li>
               <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
               <li  
                   class="text-grey text-capitalize cursor  hover-text-primary transition"> Purchase</li>
               <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
               <li class="text-grey text-capitalize cursor  hover-text-primary transition"> {{invoiceMode}}</li>
           </ul>
       </div>
     </div>
     <!-- <div class="d-flex justify-content-between">
         <div  class="heading-box mt-4 mb-3" style="width: 600px;">
             <div class="heading">UPDATE JV</div>
             <span class="hr-line"></span>
         </div>
             <div class="action-box mt-4">
                 <button type="button" class="btn-style" (click)="closeWindow()">Close</button>
             </div>
     </div> -->
     <form [formGroup]="purchaseInvoiceForm">
         <div class="pl-15 p-15 pt-3 custom-form form-container w-100 mb-4">
             <div class="d-flex">
                 <div class="text-field-group d-flex align-items-center  pb-3 mr-3" style="width: 15%;" >
                     <div class="text-input-field">
                          <select class="input-field" attr.tabIndex="1" tabIndex="1"  formControlName="account_type"
                           style="width: 100%;"
                           [ngClass]="{'validateError': !purchaseInvoiceForm.controls.account_type.valid}"
                           >
                              <option value="client">Client</option>
                              <option value="supplier">Supplier</option>
                              <option value="airline">Airline</option>
                              <option value="general">General</option>
                          </select>
                         <label>Account Type<span class="text-danger">*</span></label>
                     </div>
                     
                 </div>

                 <div class="text-field-group d-flex align-items-center  pb-3 mr-3" style="width: 15%;" >
                  <div class="text-input-field multiselect-dropdown-container">
                      
                      <label>Account</label>
                      <div class="ng-autocomplete custom-ngc" (focus)="focusClientAc()">
                          <input attr.tabIndex="2" tabIndex="2"
                              [ngClass]="{'validateError': !purchaseInvoiceForm.controls.account_code_name.valid}"
                              type="text" #accountAC oninput="this.value = this.value.toUpperCase()" 
                              class="input-field  input-container autocomplete-container" name="account_code_name" 
                              formControlName="account_code_name" 
                              (input)="getAccounts($event)"
                              [nzAutocomplete]="accountCode"
                          />
  
                          <nz-autocomplete #accountCode [nzBackfill]="true"  (selectionChange)="selectAccount($event,'account_code', null)">
                              <nz-auto-option  class="colr-1" *ngFor="let item of accountList" [nzValue]="item" [nzLabel]="item.name">
                                  <a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
                              </nz-auto-option>   

                              <nz-auto-option *ngIf="showCreateButton && currentMaster != 'general'" class="colr-1" nzValue="" style="text-align: center"
                                nzLabel="Create New">
                                <span>Create New</span>                          
                              </nz-auto-option>   
                          </nz-autocomplete>

                      </div>	
                  </div>
              </div>

              <div class="text-field-group d-flex align-items-center  pb-3 mr-3" style="width: 8%;" >
                  <div class="text-input-field">
                      <input formControlName="invoice_date" attr.tabIndex="3" tabIndex="3"  class="input-field" type="date" 
                          min="{{minDate}}" 
                          max="{{maxDate}}"
                          placeholder="MM/DD/YYYY" autocomplete="off"
                          [ngClass]="{'validateError': !purchaseInvoiceForm.controls.invoice_date.valid}"/>
                      <label>Invoice Date<span class="text-danger">*</span></label>
                  </div>
                  
              </div>

              <div class="text-field-group d-flex align-items-center  pb-3 mr-3" style="width: 169px;">
                <div class="text-input-field">
                    <input formControlName="supp_ref_number" attr.tabIndex="4" tabIndex="4" class="input-field" type="text"/>
                    <label>Supplier Ref</label>
                </div>
              </div>


              <div class="text-field-group d-flex align-items-center  pb-3 mr-3" style="width: 169px;" *ngIf="invoiceMode == 'view'">
                  <div class="text-input-field">
                      <input formControlName="invoice_number" class="input-field" type="text" readonly/>
                      <label>Invoice Number<span class="text-danger">*</span></label>
                  </div>
              </div>


              <div class="text-field-group d-flex align-items-center  pb-3 mr-3" style="width: 15%;" >
                  <div class="text-input-field multiselect-dropdown-container">
                      
                      <label>Employee</label>
                      <div class="ng-autocomplete custom-ngc" (focus)="focusClientAc()">
                          <input disabled
                              type="text" #accountAC oninput="this.value = this.value.toUpperCase()" 
                              class="input-container autocomplete-container" name="employee_code_name" 
                              formControlName="employee_code_name" 
                              (input)="getAccounts($event)"
                              [nzAutocomplete]="accountCode"
                          />
  
                          <nz-autocomplete #accountCode [nzBackfill]="true">
                              <nz-auto-option  class="colr-1" *ngFor="let item of accountList" [nzValue]="item" [nzLabel]="item.name">
                                  <a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
                              </nz-auto-option>   
                          </nz-autocomplete>

                      </div>	
                  </div>
              </div>
              <div class="text-field-group d-flex align-items-center  pb-3 mr-3" style="width: 15%;" >
                <div class="text-input-field">
                  <select class="input-field" formControlName="subledger_type"
                  style="width: 100%;"
                  [ngClass]="{'validateError': !purchaseInvoiceForm.controls.subledger_type.valid}">
								<option value="">Select</option>
								<option value="client">Client</option>
								<option value="airline">Airline</option>
								<option value="supplier">Supplier</option>
								<option value="general">General</option>
							</select>
              <label>Sub Ledger Type</label>
                </div>
            </div>
            <div class="text-field-group d-flex align-items-center  pb-3 mr-3" style="width: 15%;" >
          <div class="text-input-field multiselect-dropdown-container">
                      
                <label>Sub-ledger</label>
               
            <div class="ng-autocomplete custom-ngc" (focus)="focusSubledgerAc()">
              <input 
                  type="text" #subLedgerAC oninput="this.value = this.value.toUpperCase()" 
                  class="input-field  input-container autocomplete-container" name="subledger_code_name" 
                  formControlName="subledger_code_name" 
                  (input)="ledgerSearch($event)"
                  [nzAutocomplete]="subledgerCode"
              />

              <nz-autocomplete #subledgerCode [nzBackfill]="true"  (selectionChange)="selectAccount($event,'subledger_code', null)">
                  <nz-auto-option  class="colr-1" *ngFor="let item of subledgeraccountList" [nzValue]="item" [nzLabel]="item.name">
                      <a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
                  </nz-auto-option>   

                  <nz-auto-option *ngIf="showCreateButton && currentMaster != 'general'" class="colr-1" nzValue="" style="text-align: center"
                    nzLabel="Create New">
                    <span>Create New</span>                          
                  </nz-auto-option>   
              </nz-autocomplete>

          </div>

                  
              </div>
          </div>
          <div class="text-field-group d-flex align-items-center  pb-3 mr-3" style="width: 10%;" *ngIf="purchaseInvoiceForm.value.transaction_method=='refund'">
            <div class="text-input-field">
                <input formControlName="crn_against" class="input-field" type="text" readonly/>
                <label>Reference Number</label>
            </div>
        </div>
              
               

             </div>

             <div>
              <table class="table table-bordered" width="100%" cellspacing="0" cellpadding="0" style="table-layout: fixed;">
                <thead>
                    <tr>
                        <th style="width: 10px;max-width: 10px;">S.No.</th>
                        <th style="width: 200px;max-width: 200px;">Details</th>
                        <th style="width: 150px;max-width: 150px;">General Ledger</th>
                        <th style="width: 32px;max-width: 32px;" class="text-right" >Quantity</th>
                        <th style="width: 40px;max-width: 40px;" class="text-right" >Rate</th>
                        <th style="width: 30px;max-width: 30px;" class="text-right">Gst Rate</th>
                        <th style="width: 30px;max-width: 30px;" class="text-right" >TDS Rate</th>
                        <th style="width: 70px;max-width: 70px;" class="text-right">Amount</th>
                        <th style="width: 30px;max-width: 30px;" class="text-center">Action</th>
                    </tr>
                </thead>
                <tbody formArrayName="serviceArray">
                    <tr *ngFor="let invoiceEntry of DynamicFormControls.controls; let i = index;" [formGroupName]="i">
                         <td>{{i+1}}</td>
                         <td> 
                             <input attr.tabIndex="{{i*6+5}}" tabIndex="{{i*6+5}}" class="inner-input-ui" oninput="this.value = this.value.toUpperCase()" 
                                 class="inner-input-ui" type="text" formControlName="description" />
                         </td>
                         <td>
                             <div class="ng-autocomplete custom-ngc" (focus)="focusClientAc()">
                                 <input attr.tabIndex="{{i*6+6}}" tabIndex="{{i*6+6}}"
                                     type="text" #accountAC oninput="this.value = this.value.toUpperCase()" 
                                     class="input-container autocomplete-container" name="general_ledger_code_name"
                                     formControlName="general_ledger_code_name"
                                     (input)="generalSearch($event)"
                                     [nzAutocomplete]="accountCode"
                                 />
         
                                 <nz-autocomplete #accountCode [nzBackfill]="true"  (selectionChange)="selectAccount($event,'general_ledger_code', i)">
                                     <nz-auto-option  class="colr-1" *ngFor="let item of ledgerList" [nzValue]="item" [nzLabel]="item.name">
                                         <a innerHTML='<span class="colr-1">{{item?.name}}({{item?.account_code}})</span><br />'></a>                            
                                     </nz-auto-option>   

                                     <nz-auto-option *ngIf="showCreateButton && currentMaster == 'general'" class="colr-1" nzValue="" style="text-align: center"
                                        nzLabel="Create New">
                                        <span>Create New</span>                          
                                      </nz-auto-option>   

                                 </nz-autocomplete>
                             </div>
                        </td>

                        <td> 
                         <input attr.tabIndex="{{i*6+7}}" tabIndex="{{i*6+7}}" 
                             class="inner-input-ui text-right" type="number" formControlName="quantity" (input)="calculateAmount(i, 'quantity')"/>
                         </td>
                         <td> 
                             <input  attr.tabIndex="{{i*6+8}}" tabIndex="{{i*6+8}}" class="inner-input-ui" oninput="this.value = this.value.toUpperCase()" 
                                 class="inner-input-ui text-right" type="text" formControlName="rate" (input)="calculateAmount(i, 'rate')"/>
                         </td>
                         <td> 
                             <input  attr.tabIndex="{{i*6+9}}" tabIndex="{{i*6+9}}" class="inner-input-ui" oninput="this.value = this.value.toUpperCase()" 
                                 class="inner-input-ui text-right" type="text" formControlName="gst_rate" (input)="calculateAmount(i, 'gst_rate')"/>
                         </td>
                         <td> 
                             <input  attr.tabIndex="{{i*6+10}}" tabIndex="{{i*6+10}}" class="inner-input-ui" oninput="this.value = this.value.toUpperCase()" 
                                 class="inner-input-ui text-right" type="text" formControlName="tds_rate" (input)="calculateAmount(i, 'tds_rate')"/>
                         </td>
                         <td> 
                             <input class="inner-input-ui" oninput="this.value = this.value.toUpperCase()" 
                                 class="inner-input-ui text-right" type="text" disabled formControlName="amount" />
                         </td>
                         <td class="edit-row text-center">
                            <button  title="Delete" class="custom-table-btn bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button"
                            (click)="removeInvoiceRow(i)" id="remove_jv_row_btn_{{i}}">
                            <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-delete.svg" alt="" width="20px">
                            
                          </button>
                         </td>
                    </tr>
                </tbody>
                <!-- <tfoot>
                    <tr>
                        <td colspan="3"></td>
                        <td class="text-black font-weight-normal text-right" style="padding : 10px">Difference</td>
                        <td class="text-black font-weight-normal text-right"  style="padding : 10px"  [ngClass]="{'text-danger': purchaseInvoiceForm.value.amountDiff  != 0,'text-success' : purchaseInvoiceForm.value.amountDiff  == 0}">
                            <span>{{purchaseInvoiceForm.value.amountDiff | number : '1.2-2'}}</span>
                        </td>
                        <td class="text-black font-weight-normal text-right" style="padding : 10px">Total</td>
                        <td class="text-black font-weight-normal text-right"  style="padding : 10px">
                            {{purchaseInvoiceForm.value.totalDebit | number : '1.2-2'}}
                        </td>
                        <td class="text-black font-weight-normal text-right"  style="padding : 10px">
                            {{purchaseInvoiceForm.value.totalCredit | number : '1.2-2'}}
                       </td>
                        <td colspan="3"></td>
                    </tr>
                </tfoot> -->
            </table>

            <div class="fare-diff-col d-flex justify-content-between align-items-center">
                
                <div class="text-field-group">
                    <div class="text-input-field mt-2">
                        <button type="button" class="btn-style text-white mr-3" (click)="addInvoiceRow()" id="add_jv_row_btn"><i class="fa fa-plus fa fa-plus-square text-white"></i> Row</button>
                    </div>
                </div>

            </div>
             </div>

             <div class="custom-table mt-3">
                  <div class="table-actions d-flex justify-content-between">
                    <div></div>
                      
                    <div class="action d-flex flex-column">
                        <div class="subtotal">
                          
                          <div class="total-box" *ngIf="purchaseInvoiceForm.value.sub_total">
                              <span class="title-type" >SubTotal</span>
                              <span class="result-type">
                                {{purchaseInvoiceForm.value.sub_total| currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
                              </span>
                          </div>
      
                          <div class="total-box" *ngIf="purchaseInvoiceForm.value.gst_total">
                            <span class="title-type" >GST</span>
                              <span class="result-type">
                                {{purchaseInvoiceForm.value.gst_total| currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
                            </span>
                          </div>
            
                          <div class="total-box" *ngIf="purchaseInvoiceForm.value.tds_total">
                            <span class="title-type" >TDS</span>
                              <span class="result-type">
                                {{purchaseInvoiceForm.value.tds_total| currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
                            </span>
                          </div>

                          <div class="total-box" *ngIf="purchaseInvoiceForm.value.total">
                            <span class="title-type" >Total Amount</span>
                              <span class="result-type">
                                {{purchaseInvoiceForm.value.total| currency: currencyCode :'symbol':'1.0' : nativeLanguageCode}}
                            </span>
                          </div>

                        </div>
                      </div>
                      
                  </div>
              </div>

         </div>
         <div class="btn-field bottom-btns-container full-width-btn-container d-flex align-item-center justify-content-end  ml-0 mr-0">  

          <ng-container *ngIf="invoiceMode == 'save'">
            <button type="button" class="btn-style bg-green mr-3" (click)="reset()">Reset</button>
            <button *ngIf="invoiceMode == 'save' " type="button" class="btn-style bg-cyan mr-3"
               [disabled]="purchaseInvoiceForm.invalid"  [ngClass]="{disbaledBtn:purchaseInvoiceForm.invalid}"
               (click)="createSubmit(purchaseInvoiceForm.value)" id="create_inv_btn">Save</button>
               <span style="position: relative; display: inline-block;" *ngIf="isAadesh==false">
                <div class="d-flex align-items-center flex-row" style="margin-left: 14px;">
                  <button (click)="toggleMenu()" class="hamburger-menu">
                    &#9776; <!-- Hamburger icon -->
                  </button>
                </div>
                
                <span class="rcm-invoice-btn" *ngIf="menuVisible">
                  <div class="d-flex align-item-center">
                    <input  formControlName="rcm_applicable" id="rcm_applicable" type="checkbox"  />
                    <label for="rcm_applicable" class="mb-0 ml-10 clientRefLbl">RCM Applicable</label>
                  </div>
      
                  <!-- <div class="d-flex align-item-center">
                    <input formControlName="reverse_tds" id="reverse_tds" type="checkbox"  />
                    <label for="reverse_tds" class="mb-0 ml-10 clientRefLbl">Reverse TDS</label>
                  </div> -->
                </span>
              </span>
          </ng-container>

          <ng-container *ngIf="invoiceMode == 'view'">
              <button type="button" class="btn-style bg-cyan mr-3"
              [disabled]="purchaseInvoiceForm.invalid"  [ngClass]="{disbaledBtn:purchaseInvoiceForm.invalid}"
              (click)="openUpdateConfirmModal()" id="modify_jv_btn">Modify</button>

              <button type="button" class="btn-style bg-red mr-3" (click)="openDeleteConfirmModal()" attr.tabIndex="{{lastInputIndex+2}}" tabIndex="{{lastInputIndex+2}}" id="delete_jv_btn" >Delete</button>
              <button type="button" class="btn-style bg-brown mr-3" (click)="viewJournal(invoice_no)" attr.tabIndex="{{lastInputIndex+2}}" tabIndex="{{lastInputIndex+2}}" id="open_jv_btn" >Journal</button>
              <button type="button" *ngIf="(!purchaseInvoiceForm.value.transaction_method || purchaseInvoiceForm.value.transaction_method!=='refund')" 
			(click)="createCreditNote(invoice_no)" class="btn-style bg-blue-ivy mr-3" id="create_credit_note_btn">Credit Note</button>


              <button type="button" attr.tabIndex="{{lastInputIndex+3}}" tabIndex="{{lastInputIndex+3}}" (click)="openActivity()" class="btn-style bg-indigo mr-3" id="jv_activity_log_btn">Activity
              </button>
              <span style="position: relative; display: inline-block;" *ngIf="isAadesh==false">
                <div class="d-flex align-items-center flex-row" style="margin-left: 14px;">
                  <button (click)="toggleMenu()" class="hamburger-menu">
                    &#9776; <!-- Hamburger icon -->
                  </button>
                </div>
                
                <span class="rcm-invoice-btn" *ngIf="menuVisible">
                  <div class="d-flex align-item-center">
                    <input  formControlName="rcm_applicable" id="rcm_applicable" type="checkbox"  />
                    <label for="rcm_applicable" class="mb-0 ml-10 clientRefLbl">RCM Applicable</label>
                  </div>
      
                  <!-- <div class="d-flex align-item-center">
                    <input formControlName="reverse_tds" id="reverse_tds" type="checkbox"  />
                    <label for="reverse_tds" class="mb-0 ml-10 clientRefLbl">Reverse TDS</label>
                  </div> -->
                </span>
              </span>

              <!-- <button type="button" attr.tabIndex="{{lastInputIndex+3}}" tabIndex="{{lastInputIndex+3}}"(click)="openSendEmailModal()" class="btn-style bg-blue-ivy" id="jv_email_btn">
                  Email
              </button> -->
          </ng-container>
         </div>
     </form>

 </ng-container>

</div>

<button type="button" hidden  #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button >

 <div id="deleteConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
   <div class="modal-dialog">
       <!-- Modal content-->
       <div class="modal-content">
           <div class="modal-header">
               <h4 class="modal-title">Confirmation</h4>
               <button type="button" #closeDeleteModal class="close" data-dismiss="modal" (click)="resetCurrentModalType()">&times;</button>
           </div>
           <div class="modal-body">
               <p>Are you sure to delete ‎this entry ?</p>
           </div>
           <div class="modal-footer">
               <button type="button" class="btn-style" attr.tabIndex="{{lastInputIndex+6}}" tabIndex="{{lastInputIndex+6}}"
                   (click)="closeDeleteConfirmModal()" data-dismiss="modal">No</button>
                   <button type="button" class="btn-style bg-red" attr.tabIndex="{{lastInputIndex+5}}" tabIndex="{{lastInputIndex+5}}"  (click)="deleteInvoice(invoice_no)" data-dismiss="modal">Yes</button>
           </div>
       </div>
   </div>
</div>

<button type="button" hidden   #confirmUpdateModal data-toggle="modal" data-target="#updateConfirmationModal"></button >

<div id="updateConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
   <div class="modal-dialog">
       <!-- Modal content-->
       <div class="modal-content">
           <div class="modal-header">
               <h4 class="modal-title">Confirmation</h4>
               <button type="button" #closeUpdateModal class="close" data-dismiss="modal" (click)="resetCurrentModalType()">&times;</button>
           </div>
           <div class="modal-body">
               <p>Are you sure to update ‎this entry ?</p>
           </div>
           <div class="modal-footer">
               <button type="button" class="btn-style" attr.tabIndex="{{lastInputIndex+8}}" tabIndex="{{lastInputIndex+8}}"
                   (click)="closeUpdateConfirmModal()" data-dismiss="modal">No</button>
                   <button type="button" class="btn-style bg-green" attr.tabIndex="{{lastInputIndex+7}}" tabIndex="{{lastInputIndex+7}}" (click)="updateSubmit(purchaseInvoiceForm.value)"
                      data-dismiss="modal">Yes</button>
           </div>
       </div>
   </div>
</div>


<button hidden #openMasterCreationModal data-target="#masterCreationModal"data-toggle="modal"></button>
<div
  class="modal"
  id="masterCreationModal"
  role="dialog"
  aria-labelledby="masterModalLabel"
  data-backdrop="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex justify-content-center">
          <h4 class="modal-title">Create {{currentMaster}}</h4>
        </div>
        <button
          type=" button"
          class="close"
          #closeMasterCreationModal
          data-dismiss="modal"
          aria-label="Close"
		      (click)="closeMasterModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
	    <app-master-creation [masterType]="currentMaster" [masterCreationModal]="masterCreationModal" [currentMasterName]="currentMasterInputName" 
		    (callSetFormData)="setFormValues($event)" (closeModal)="closeMasterModal()"
		></app-master-creation>
     </div>
    </div>
</div>

 <button
 hidden
 #openEmailModal
 data-target="#emailModalOfInvoice"
 data-toggle="modal"
></button>
 <div
   class="modal"
   id="emailModalOfInvoice"
   role="dialog"
   aria-labelledby="emailModalLabel"
   data-backdrop="false"
 >
   <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header">
         <div class="d-flex justify-content-center">
           <h4 class="modal-title">Send Email</h4>
         </div>
         <button
           type=" button"
           class="close"
           #closeSendEmailModal
           data-dismiss="modal"
           aria-label="Close"
         >
           <span aria-hidden="true">&times;</span>
         </button>
       </div>
       <div class="modal-body emailSenders">
         JV
         <form [formGroup]="sendEmailForm" style="margin-top: -10px">
           <div class="form-group text-input-field">
             <textarea
               class="input-field ng-pristine ng-valid ng-touched text-size-12"
               id="toEmail"
               rows="1"
               formControlName="toEmails"
               required
             ></textarea>
             <label for="To" class="mb-0">To</label>
           </div>
           <div class="d-flex justify-content-end">
             <div *ngIf="sendEmailForm.controls.toEmails.invalid">
               <div
                 *ngIf="sendEmailForm.controls.toEmails.errors?.required"
                 style="color: red"
               >
                 To Email is required.
               </div>
             </div>
           </div>

           <div class="form-group text-input-field ">
             <textarea
               class="input-field ng-pristine ng-valid ng-touched text-size-12"
               id="ccEmail"
               style="max-height: 45px;"
               formControlName="ccEmails"
             ></textarea>
             <label for="Cc" class="mb-0">Cc</label>
           </div>

           <div class="form-group text-input-field">
             <textarea
               class="input-field ng-pristine ng-valid ng-touched text-size-12"
               id="bccEmail"
               style="max-height: 45px;"
               formControlName="bccEmails"
             ></textarea>
             <label for="Bcc" class="mb-0">Bcc</label>
           </div>
           <div class="text-input-field">
               <input formControlName="subject"  class="input-field text-size-12" type="text" 
               autocomplete="off" />
               <label>Subject</label>
           </div>
           <div class="form-group text-input-field">
            <textarea
              class="input-field ng-pristine ng-valid ng-touched text-size-12"
              rows="5"
              formControlName="body"
            ></textarea>
            <label for="body" class="mb-0">Mail Body</label>
            <input type="file" (change)="handleAttachment($event)" />

          </div>
           <div class="mt-3 d-flex justify-content-center align-item-center">
             <button type="button" class="btn-style mr-2" data-dismiss="modal">
               Cancel
             </button>
             <button
               type="button"
               class="btn-style bg-green"
               (click)="callTosendEmail()"
             >
               Send
             </button>
           </div>
         </form>
       </div>
     </div>
   </div>
 </div>
