
import { Component, OnInit } from '@angular/core';


@Component({
	selector: 'app-airline',
	templateUrl: './airline.component.html',
	styleUrls: ['./airline.component.scss']
})
export class AirlineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

