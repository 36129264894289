<form [formGroup]="roleFilterForm">
  <div class="flexbox aff-info justify-content-around">
    <div class="flexbox aff-info-inner">
      <div class="flexBox d-flex ">

        <div class="input-fiel">
          <input class="input-ui" searchtabindex="1" id="uiname" oninput="this.value = this.value.toUpperCase()"
            type="text" formControlName="name" placeholder="Name" /><label for="uiname">Role</label>
        </div>
      </div>
      <div class="d-flex mt-5 justify-content-center">
        <div class="input-fiel">
          <button id="submitSearchForm" type="button" class="btn-style bg-purple mr-3" type="button"
            data-dismiss="modal" searchtabindex="2" #searchButton (keyup.enter)="clickSearchButton()"
            (click)="search(roleFilterForm.value)">
            Search</button>
          <button type="button" class="btn-style bg-green" id="resetSearchForm" (click)="reset()">RESET</button>
        </div>
      </div>
    </div>
  </div>
</form>