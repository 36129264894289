import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { SectorService } from '../services/sector.service';
@Component({
  selector: 'app-gst-detail',
  templateUrl: './gst-detail.component.html',
  styleUrls: ['./gst-detail.component.scss']
})
export class GstDetailComponent implements OnInit {

  constructor(private fb: FormBuilder ,public sectorService : SectorService) { }

  ngOnInit(): void {
  
  }




}
