import { Component, Input, OnInit } from '@angular/core';
import { CookiesService } from 'src/app/shared/cookies.service';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from 'src/app/shared/common-service.service';

@Component({
  selector: 'app-consolidate-fare-summary',
  templateUrl: './consolidate-fare-summary.component.html',
  styleUrls: ['./consolidate-fare-summary.component.scss']
})
export class ConsolidateFareSummaryComponent implements OnInit {
  @Input() productService;
  isTenantIndian: boolean = false;
  constructor(private cookieService : CookiesService,
	public commonService: CommonServiceService
	) { }

  ngOnInit(): void {
	this.isTenantIndian = this.commonService.checkTenantIndian()
  }


  viewPrintScreen(invoice,i){
		if (invoice != 'Credit Note' && invoice != 'New Invoice') {
			// if (i==0) {
			// 	window.open(`/#/transactions/invoice-view;searchId=${invoice};type=flight`, "_blank");
			// }else{
			// 	window.open(`/#/transactions/print-credit-note/${invoice};type=flight`, "_blank");
			// }
			let path = ''
      path = `#/transactions/consolidate/print;searchId=${invoice};type=flight`
		
			path = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}${path}`
			window.open(path,'_blank')

		}
	}

}
